import { Component, Injectable, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  EventResourceAssignment,
  EventTemplate,
  EventTemplateSecurityResource,
  EventTemplateSecurityResourceAction,
  ResourceType,
} from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter, BuildTextFilter } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CreateEventTemplateDialog } from '../../dialogs';
import { EventTemplateService } from '../../services';
import { ResourceFilter } from '../events/filters/resource.filter';
import { TagsSelectFilter } from '../events/filters/tags.filter';

@Injectable({ providedIn: 'any' })
export class EventTemplatesSearchFilter extends BuildSearchFilter('EventTemplatesSearchFilter', {
  properties: ['title', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Injectable({ providedIn: 'any' })
export class EventTemplatesTitleFilter extends BuildTextFilter('EventTemplatesTitleFilter', {
  label: 'Title',
  shortLabel: 'Title',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW],
  properties: ['title'],
}) {}

@Injectable({ providedIn: 'any' })
export class EventTemplatesDescriptionFilter extends BuildTextFilter('EventTemplatesDescriptionFilter', {
  label: 'Description',
  shortLabel: 'Description',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW],
  properties: ['description'],
}) {}

@Component({
  selector: 'greco-event-templates-page',
  templateUrl: './event-templates.page.html',
  styleUrls: ['./event-templates.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EventTemplatesPage implements OnChanges, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private eventTemplateSvc: EventTemplateService,
    private resourceFilter: ResourceFilter,
    private tagFilter: TagsSelectFilter,
    private comSecSvc: CommunitySecurityService
  ) {}

  private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() get community() {
    return this._community$.value;
  }
  set community(community) {
    this._community$.next(community);
    this.resourceFilter.communityId = community?.id;
    this.tagFilter.communityId = community?.id;
  }

  canCreateTemplate$ = this._community$.pipe(
    switchMap(async com =>
      com
        ? await this.comSecSvc.hasAccess(
            com.id,
            EventTemplateSecurityResource.key,
            EventTemplateSecurityResourceAction.CREATE
          )
        : false
    )
  );

  canDeleteTemplate$ = this._community$.pipe(
    switchMap(async com =>
      com
        ? await this.comSecSvc.hasAccess(
            com.id,
            EventTemplateSecurityResource.key,
            EventTemplateSecurityResourceAction.DELETE
          )
        : false
    )
  );

  filterOptions = [
    EventTemplatesSearchFilter,
    EventTemplatesDescriptionFilter,
    EventTemplatesTitleFilter,
    ResourceFilter,
    TagsSelectFilter,
  ];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });

  pagination: null | IPaginationMeta = null;

  eventTemplates$ = combineLatest([this._community$, this.filters$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([community, filters, pagination]) => {
      if (filters.queryObject['s']) {
        filters.queryObject['s'] = filters.queryObject['s']?.replace('resources.id', 'resourceAssignments.resourceId');
      }
      return community ? await this.eventTemplateSvc.paginate(filters, community.id, pagination) : null;
    }),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    map(data => {
      data.map(
        eventTemplate =>
          (eventTemplate.resourceAssignments = eventTemplate.resourceAssignments?.filter(
            data => data.resource?.type !== ResourceType.ZOOM
          ))
      );
      return data;
    }),
    tap(() => (this.loading = false))
  );

  loading = true;

  async createEventTemplates() {
    const communityId = this.community?.id;
    if (!communityId) return;

    const dialog = this.dialog.open(CreateEventTemplateDialog, {
      data: { communityId },
      width: '750px',
      maxWidth: '90%',
    });
    const result = await toPromise(dialog.afterClosed());
    if (result) {
      this.refresh();
      this.openEventTemplates(result);
    }
  }

  async deleteEventTemplate(eventTemplate: EventTemplate) {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to remove <strong>${eventTemplate.title}</strong> from your event templates.</p><p>This action is NOT reversible!</p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      await this.eventTemplateSvc.deleteEventTemplate(eventTemplate.id);
      this.snacks.open('Template Deleted!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      this.refresh();
    }
    this.refresh();
  }

  async openEventTemplates(eventTemplate: EventTemplate) {
    return this.router.navigate([eventTemplate?.id], {
      relativeTo: this.route,
    });
  }

  filterPerson(assignments: EventResourceAssignment[]) {
    return assignments.filter(assignment => (assignment?.resourceTagId ? true : false));
  }

  ngOnDestroy() {
    this.filters$.complete();
    this.page$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community.previousValue !== changes.community.currentValue) {
      this.refresh();
    }
  }

  private refresh() {
    this.filters$.next(this.filters$.value);
  }
}
