import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ApiHttpInterceptor } from './interceptors';
import { BaseUrlToken } from './tokens';

@NgModule({ imports: [CommonModule, MatSnackBarModule] })
export class ApiUtilsModule {
  static forRoot(baseUrl: string) {
    return {
      ngModule: ApiUtilsModule,
      providers: [
        {
          provide: BaseUrlToken,
          useValue: baseUrl,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiHttpInterceptor,
          multi: true,
        },
      ],
    };
  }
}
