import { CurrencyPipe } from '@angular/common';
import { Component, DEFAULT_CURRENCY_CODE, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CalendarEvent, EventSeries } from '@greco/booking-events';
import { PaymentMethod } from '@greco/finance-payments';
import { Tax } from '@greco/finance-tax';
import { User } from '@greco/identity-users';
import { BookingPreview } from '@greco/nestjs-booking-events';
import { UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { AccountTaxService } from '@greco/ngx-finance-tax';
import { PropertyListener } from '@greco/property-listener-util';
import { PurchaseItem } from '@greco/sales-purchases';
import moment from 'moment';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
@Component({
  selector: 'greco-multi-booking-payment-preview',
  templateUrl: './multi-booking-payment-preview.component.html',
  styleUrls: ['./multi-booking-payment-preview.component.scss'],
})
export class MultiBookingPaymentPreviewComponent {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD',
    private paymentMethodSvc: UserPaymentMethodService,
    private accTaxSvc: AccountTaxService
  ) {}

  @Input() user!: User;
  @PropertyListener('user') user$ = new BehaviorSubject(this.user);
  @PropertyListener('previews') previews$ = new BehaviorSubject<{ user: User; preview?: BookingPreview }[] | undefined>(
    undefined
  );
  @Input() previews: { user: User; preview?: BookingPreview }[] = [];
  @PropertyListener('event') event$ = new BehaviorSubject<CalendarEvent | undefined>(undefined);
  @Input() event?: CalendarEvent | EventSeries;
  @Input() hasCancellationFee = true;

  @Output() selectedPaymentMethod = new EventEmitter();

  accountTaxes$ = this.event$.pipe(
    switchMap(async event => {
      return event ? await this.accTaxSvc.getAccountTaxes(event.community.financeAccountId) : [];
    })
  );

  private _currency = new CurrencyPipe(this._locale, this._defaultCurrencyCode);

  paymentMethodControl = new FormControl(null);

  readonly hasPaymentMethod$ = combineLatest([
    this.user$,
    this.paymentMethodControl.valueChanges.pipe(startWith(null)),
  ]).pipe(
    switchMap(async ([user, selectedPaymentMethod]) => {
      if (selectedPaymentMethod) return true;

      const paymentMethod = user ? await this.paymentMethodSvc.getDefault(user.id, true) : null;
      return !!paymentMethod && paymentMethod.model !== 'bank';
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  selectedPaymentMethod$ = this.paymentMethodControl.valueChanges.pipe(
    startWith<PaymentMethod | null, PaymentMethod | null>(null),
    switchMap(pm => {
      if (pm) return of(pm);
      return this.user$.pipe(
        switchMap(user => (user ? this.paymentMethodSvc.getDefault(user.id, true) : of(null))),
        map(pm => pm ?? null)
      );
    }),
    tap(paymentMethod => this.selectedPaymentMethod.emit(paymentMethod)),
    distinctUntilChanged((prev, next) => prev?.id === next?.id)
  );

  items$ = combineLatest([this.previews$, this.event$, this.accountTaxes$]).pipe(
    map(([previews, event, accountTaxes]) => {
      if (!previews) return undefined;
      const purchaseItems: PurchaseItem[] = [];
      let subtotal = 0;
      const appliedTaxes: { tax: Tax; total: number; taxNumber: string }[] = [];
      let taxTotal = 0;
      let total = 0;
      for (const preview of previews) {
        const user = preview.user;
        const purchase = preview.preview?.purchase?.purchase;
        subtotal += purchase?.subtotal || 0;
        taxTotal += purchase?.tax || 0;
        total += purchase?.total || 0;

        const items: PurchaseItem[] =
          purchase?.items ||
          (preview && preview.preview
            ? [
                {
                  quantity: 1,
                  price: 0,
                  displayName: `${event?.title} - ${moment
                    .utc(event?.startDate)
                    .tz(event?.timezone || 'America/Toronto')
                    .format('ddd MMM Do, LT')}`,
                  description: preview.preview?.booking.bookingOption.title,
                  photoURL: event?.resourceAssignments[0]?.resource?.photoURL,
                } as PurchaseItem,
              ]
            : []);
        for (const item of items) {
          purchaseItems.push({ ...item, user } as PurchaseItem);
          item.taxes?.forEach(tax => {
            if (appliedTaxes.some(aTax => aTax.tax.id === tax.id)) {
              const index = appliedTaxes.findIndex(aTax => aTax.tax.id === tax.id);
              appliedTaxes[index].total += ((item.quantity * item.price) / 100) * (tax.percentage / 100);
            } else {
              appliedTaxes.push({
                tax: tax,
                total: ((item.quantity * item.price) / 100) * (tax.percentage / 100),
                taxNumber: accountTaxes?.find(accTax => accTax.taxId === tax.id)?.taxNumber || '',
              });
            }
          });
        }
      }
      return { purchaseItems, subtotal, taxTotal, total, appliedTaxes };
    }),
    map(data => {
      if (!data) return [];
      const items = data.purchaseItems;
      const subtotal = data.subtotal;
      const tax = data.taxTotal;
      const total = data.total;
      const appliedTaxes = data.appliedTaxes;
      return [
        ...items.map(item => ({
          id: item.id,
          amount: this._currency.transform((item.quantity * item.price) / 100),
          unitPrice: this._currency.transform(item.price / 100),
          quantity: item.quantity,
          saleCategory: item.saleCategory,
          description: {
            user: (item as any).user,
            displayName: item.displayName,
            description: item.description,
            photoURL: item.photoURL,
          },
        })),
        ...[
          {
            description: null,
            quantity: null,
            unitPrice: '',
            unitPriceClass: 'font-bold',
            amount: this._currency.transform(subtotal / 100),
            amountClass: 'subtotal font-bold',
          },
          //Individual Taxes
          ...appliedTaxes?.map(appliedTax => {
            return {
              description: null,
              descriptionClass: 'no-borders',
              quantity: null,
              quantityClass: 'no-borders',
              unitPrice:
                (appliedTax.tax.abbreviation || appliedTax.tax.title) +
                ' ' +
                appliedTax.tax.percentage +
                '%' +
                (appliedTax.taxNumber ? ' (' + appliedTax.taxNumber + ')' : ''),
              unitPriceClass: 'no-borders',
              amount: appliedTax.total ? this._currency.transform(appliedTax.total) : '-',
              amountClass: 'no-borders',
            };
          }),
          //Total Taxes
          {
            description: null,
            quantity: null,
            unitPrice: '',
            unitPriceClass: 'font-bold',
            amount: tax ? this._currency.transform(tax / 100) : '-',
            amountClass: 'tax font-bold',
          },
          {
            description: null,
            quantity: null,
            unitPrice: '',
            unitPriceClass: 'strong',
            amount: this._currency.transform(total / 100),
            amountClass: 'total strong',
          },
        ],
      ];
    })
  );
}
