import { Injectable } from '@angular/core';
import { SelectFilter } from '@greco/ngx-filters';

// The order matters for the drop-down
const ALL_PURCHASE_TYPES = [
  'SubscriptionStartPurchaseEntity',
  'SubscriptionRenewalPurchaseEntity',
  'SubscriptionUpdatePurchaseEntity',
  'SubscriptionCancellationPurchaseEntity',
  'ProductPurchaseEntity',
  'CustomChargePurchaseEntity',
  'BookingPurchaseEntity',
  'BookingCancellationPurchaseEntity',
  'CoursePurchaseEntity',
  'EventVideoPurchaseEntity',
  'CommunityVideoUnlockPurchaseEntity',
  'CollectionVideoPurchaseEntity',
  'FundUserBalancePurchaseEntity',
];

@Injectable({ providedIn: 'any' })
export class PurchaseTypeFilter extends SelectFilter {
  constructor() {
    super('PurchaseTypeFilter', {
      label: 'Purchase Type',
      properties: ['type'],
      shortLabel: 'Type',
      description: 'Type of Purchase',
    });
  }

  getValueLabel(value: string | string[]): string {
    return Array.isArray(value)
      ? value.map(status => this.getSingleValueLabel(status)).join(', ')
      : this.getSingleValueLabel(value);
  }

  getValueOptions(search?: string): string[] {
    return search
      ? ALL_PURCHASE_TYPES.filter(type =>
          this.getSingleValueLabel(type).toLowerCase().startsWith(search.toLocaleLowerCase())
        )
      : ALL_PURCHASE_TYPES;
  }

  serializeValue(value: string | string[]): string {
    return Array.isArray(value) ? value.map(type => type).join(',') : value;
  }

  deserializeValue(serializedValue: string): string[] {
    return serializedValue.split(',').filter(status => ALL_PURCHASE_TYPES.includes(status)) as string[];
  }

  private getSingleValueLabel(value: string): string {
    switch (value) {
      case 'SubscriptionStartPurchaseEntity':
        return 'Subscription Start';
      case 'SubscriptionRenewalPurchaseEntity':
        return 'Subscription Renewal';
      case 'SubscriptionUpdatePurchaseEntity':
        return 'Subscription Update';
      case 'SubscriptionCancellationPurchaseEntity':
        return 'Subscription Cancellation';
      case 'ProductPurchaseEntity':
        return 'One-Time Product';
      case 'CustomChargePurchaseEntity':
        return 'Custom Charge';
      case 'BookingPurchaseEntity':
        return 'Booking';
      case 'BookingCancellationPurchaseEntity':
        return 'Booking Cancellation';
      case 'CoursePurchaseEntity':
        return 'Course Booking';
      case 'EventVideoPurchaseEntity':
        return 'Event Replay Unlock';
      case 'CommunityVideoUnlockPurchaseEntity':
        return 'Community Video Unlock';
      case 'CollectionVideoPurchaseEntity':
        return 'Collection Video Unlock';
      case 'FundUserBalancePurchaseEntity':
        return 'Fund User Balance';
      case 'PurchaseEntity':
        return 'Other';
      default:
        return value;
    }
  }
}
