<greco-table [data]="(perks$ | async) || []">
  <greco-perk-badge
    *grecoTableCol="''; let groupedPerk; fitContent: true"
    [shape]="groupedPerk.perk.badge.shape || 'squircle'"
    [icon]="groupedPerk.perk.badge.icon || ''"
    [text]="groupedPerk.perk.badge.text"
    [color]="groupedPerk.perk.badge.color"
    [small]="true"
  ></greco-perk-badge>

  <p *grecoTableCol="'Perk'; let groupedPerk">{{ groupedPerk.perk?.title }}</p>
  <p *grecoTableCol="'Community'; let groupedPerk">{{ groupedPerk.perk.community?.name }}</p>
  <p *grecoTableCol="'Quantity'; let groupedPerk">{{ groupedPerk.quantity }}</p>
  <p *grecoTableCol="'Reusable'; let groupedPerk">{{ groupedPerk.reusable }}</p>
  <p *grecoTableCol="'Expiry'; let groupedPerk">{{ groupedPerk.expiry }}</p>

  <p *grecoTableCol="'Granted By'; let userperk" [matTooltip]="userperk.grantedBy">
    <ng-container *ngIf="userperk.grantedBy | test : '^subitm_'; else fromPurchase">Subscription</ng-container>

    <ng-template #fromPurchase>
      <ng-container *ngIf="userperk.grantedBy | test : '^puritm_'; else fromStaff">One-Time Purchase</ng-container>
    </ng-template>

    <ng-template #fromStaff>
      {{ userperk?.grantedByUser?.displayName || (userperk.grantedBy ? 'Staff' : '') }}
    </ng-template>
  </p>

  <ng-container *grecoTableCol="''; let groupedPerk; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="canRevokePerks$ | async">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button
        mat-menu-item
        [disabled]="
          (groupedPerk.perk.community?.id !== communityId && !(userSvc.user$ | async)?.isSuperAdmin) || !canRevokePerks
        "
        (click)="_removePerks(groupedPerk.perk, groupedPerk.expiry, groupedPerk.reusable)"
      >
        <mat-icon>delete</mat-icon>
        <span>Remove Perk(s)</span>
      </button>
    </mat-menu>
  </ng-container>
</greco-table>
