<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <section>
      <h3 style="margin-bottom: 8px">Booking Option Details</h3>

      <div style="display: flex; margin-top: -16px">
        <!-- Color -->
        <div matPrefix style="margin-bottom: 8px; position: relative">
          <mat-form-field style="width: 48px" appearance="standard">
            <input #colorInput matInput type="color" required formControlName="color" />
            <mat-icon
              style="
                position: absolute;
                top: 12px;
                left: 4px;
                width: 100%;
                height: 100%;
                color: white;
                pointer-events: none;
              "
            >
              palette
            </mat-icon>
          </mat-form-field>
        </div>

        <!-- Title -->
        <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
          <mat-label>Title</mat-label>
          <input matInput required formControlName="title" />
        </mat-form-field>
      </div>

      <!-- Icon -->
      <mat-form-field appearance="standard">
        <mat-label style="margin: 0 8px">Icon</mat-label>

        <div style="display: flex; gap: 4px">
          <mat-icon>{{ formGroup.value.icon }}</mat-icon>
          <input matInput formControlName="icon" />
        </div>
      </mat-form-field>

      <a href="https://fonts.google.com/icons" target="_blank">
        <span style="font-size: x-small">Icons Reference</span>
      </a>

      <!-- Description (Optional) -->
      <mat-form-field appearance="standard">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="3"></textarea>
      </mat-form-field>

      <!-- Tags -->
      <greco-tags-input formControlName="tags" [required]="true" [communityId]="data.communityId"> </greco-tags-input>

      <!-- Available to everyone -->
      <div style="margin-top: 16px">
        <mat-checkbox formControlName="availableToAnyone" color="primary">Available to everyone</mat-checkbox>
      </div>

      <div style="margin-top: 16px">
        <mat-checkbox formControlName="isCourseOption" color="primary">Is course option</mat-checkbox>
      </div>
    </section>

    <section>
      <h3 style="margin-bottom: 8px">Booking</h3>

      <mat-form-field appearance="standard">
        <mat-label>Booking Window</mat-label>
        <greco-minutes-input formControlName="bookingWindow" [required]="true"></greco-minutes-input>
      </mat-form-field>

      <!-- Price (Optional) -->
      <mat-form-field appearance="standard">
        <mat-label>Booking Price</mat-label>
        <input matInput formControlName="price" currencyMask [options]="currencyMaskConfig" />
        <mat-icon
          matSuffix
          matTooltip="
            Price charged to the user at the time of booking. This is not the price of the booking option perk that must be used to book. The user receives the perks by purchasing them as part of products.
          "
        >
          info
        </mat-icon>
      </mat-form-field>

      <!--Sale Category-->
      <mat-form-field
        *ngIf="accountId && scBookingVisible"
        style="width: 100%"
        floatLabel="always"
        appearance="standard"
      >
        <mat-label>Sales Category</mat-label>

        <greco-sale-category-input
          formControlName="saleCategoryBook"
          [required]="false"
          [accountId]="accountId"
        ></greco-sale-category-input>
      </mat-form-field>

      <div style="margin-top: 16px">
        <mat-checkbox formControlName="allowPendingBookings" color="primary">Allow pending bookings</mat-checkbox>
      </div>

      <mat-checkbox
        color="primary"
        style="margin-top: 24px"
        labelPosition="before"
        formControlName="allowBoost"
        [style.margin-bottom]="formGroup.get('allowBoost')?.value ? '-8px' : '16px'"
        (change)="$event.checked && formGroup.get('maxBoost')?.setValue(null)"
      >
        Allow Booking Window Boosting
      </mat-checkbox>

      <mat-form-field
        *ngIf="formGroup.get('allowBoost')?.value"
        class="ignore"
        appearance="standard"
        style="margin-bottom: 16px"
      >
        <mat-label>Boosts Allowed (24hr/boost)</mat-label>
        <input
          matInput
          min="0"
          step="1"
          type="number"
          formControlName="maxBoost"
          [readonly]="formGroup.value.isCourseOption"
        />
        <mat-hint *ngIf="formGroup.value.maxBoost; else noBoost">
          Extra {{ 24 * formGroup.value.maxBoost }}hrs ({{ formGroup.value.maxBoost }} days) on top of booking window.
        </mat-hint>

        <ng-template #noBoost>
          <mat-hint> Leave blank to allow unlimited booking window boosting. </mat-hint>
        </ng-template>
      </mat-form-field>

      <!-- Taxes -->
      <mat-form-field *ngIf="accountId" appearance="standard" floatLabel="always" style="width: 100%">
        <mat-label>Taxes</mat-label>
        <greco-account-taxes-picker
          formControlName="bookingTaxes"
          [accountId]="accountId"
          [disabled]="ignoreTaxes.checked"
        >
          <mat-checkbox #ignoreTaxes formControlName="bookingIgnoreTaxes" color="primary">No Taxes</mat-checkbox>
        </greco-account-taxes-picker>
      </mat-form-field>
    </section>

    <section>
      <div style="display: flex; align-items: center; justify-content: space-between">
        <h3 style="margin-bottom: 8px">Late Cancellation Policy</h3>
        <mat-checkbox labelPosition="before" formControlName="cancellation" color="primary">Enable</mat-checkbox>
      </div>

      <!-- Cancellation enabled -->
      <!-- <div>
      </div> -->

      <ng-container *ngIf="formGroup.value.cancellation">
        <!-- Refund on cancellation -->
        <!-- <div style="margin-top: 8px"></div> -->

        <!-- Cancellation Window -->
        <mat-form-field appearance="standard">
          <mat-label>Cancellation Cut-Off Window</mat-label>
          <greco-minutes-input formControlName="cancellationWindow" [required]="true"></greco-minutes-input>
        </mat-form-field>

        <!-- Cancellation Cost -->
        <mat-form-field appearance="standard">
          <mat-label>Late Cancellation Cost</mat-label>
          <input matInput formControlName="cancellationPrice" currencyMask [options]="currencyMaskConfig" />
          <mat-checkbox
            matSuffix
            color="primary"
            style="margin-left: 8px"
            formControlName="refund"
            matTooltip="Refunds the Booking Cost & Perk Used"
          >
            Refund booking
          </mat-checkbox>
        </mat-form-field>

        <!--Sale Category-->
        <mat-form-field
          *ngIf="accountId && scCancelVisible"
          style="width: 100%"
          floatLabel="always"
          appearance="standard"
        >
          <mat-label>Sales Category</mat-label>
          <greco-sale-category-input
            formControlName="saleCategoryCancel"
            [required]="false"
            [accountId]="accountId"
          ></greco-sale-category-input>
        </mat-form-field>

        <!-- Taxes -->
        <mat-form-field *ngIf="accountId" appearance="standard" floatLabel="always" style="width: 100%">
          <mat-label>Taxes</mat-label>
          <greco-account-taxes-picker
            formControlName="cancellationTaxes"
            [accountId]="accountId"
            [disabled]="ignoreTaxes.checked"
          >
            <mat-checkbox #ignoreTaxes formControlName="cancellationIgnoreTaxes" color="primary">No Taxes</mat-checkbox>
          </greco-account-taxes-picker>
        </mat-form-field>
      </ng-container>
    </section>
  </form>
</greco-simple-dialog>
