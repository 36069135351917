<div class="container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar id="app-toolbar">
    <div id="header-start" class="mr2">
      <ng-content select="[header-start]"></ng-content>
    </div>
    <ng-container *ngIf="!mobileQuery.matches; else mobileToolbarContent">
      <img
        *ngIf="options?.logo || ((themeSvc.theme | async) === 'dark' && options?.logoDark)"
        class="logo clickable py1"
        [src]="(themeSvc.theme | async) === 'dark' ? options?.logoDark : options?.logo"
        (error)="options.logo = null"
        routerLink="/"
      />
      <nav #nav mat-tab-nav-bar class="tab-links ml2" (window:resize)="nav.updatePagination()">
        <a mat-tab-link *ngFor="let link of links$ | async" [routerLink]="link.url" [active]="activeLink?.url === link.url">
          {{ link.name }}
        </a>
      </nav>
    </ng-container>
    <ng-template #mobileToolbarContent>
      <button *ngIf="(links$ | async)?.length" mat-icon-button (click)="snav.toggle()">
        <mat-icon>{{ snav?.opened ? 'expand_less' : 'menu' }}</mat-icon>
      </button>
      <div class="ml1 py1 flex flex-auto" *ngIf="activeLink?.name === 'Dashboard' && (options?.logo || ((themeSvc.theme | async) === 'dark' && options?.logoDark)); else justText">
        <img style="max-width: 40vw;" [src]="(themeSvc.theme | async) === 'dark' ? options?.logoDark : options?.logo" (error)="options.logo = null" />
      </div>
      <ng-template #justText>
        <div class="ml2" style="flex: 1; overflow: hidden;">
          <h1 class="mat-title" style="overflow: hidden; text-overflow: ellipsis;">{{ activeLink?.name }}</h1>
        </div>
      </ng-template>
    </ng-template>
    <div id="header-end" class="mr2">
      <ng-content select="[header-end]"></ng-content>
    </div>
  </mat-toolbar>
  <mat-sidenav-container id="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav class="p2" [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <img *ngIf="options?.logo" class="logo clickable" [src]="options?.logo" (error)="options.logo = null" routerLink="/" (click)="snav.close()" />
        <a mat-list-item [class.primary-color]="activeLink?.url === link.url" [routerLink]="link.url" (click)="snav.close()" *ngFor="let link of links$ | async">{{ link.name }}</a>
      </mat-nav-list>
    </mat-sidenav>
    <ng-content select="[snav]"></ng-content>

    <mat-sidenav-content [@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : null">
      <ng-content select="[content-top]"></ng-content>
      <!-- <div *ngIf="!mobileQuery.matches" class="flex items-center px2">
        <button *ngIf="activeLink?.url" mat-icon-button (click)="back()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <greco-super-title class="my2" [class.ml2]="activeLink" [text]="activeLink?.name"></greco-super-title>
      </div> -->
      <div id="content-outlet">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
