<greco-table [data]="payouts" [loading]="loading">
  <div
    *grecoTableCol="''; let payout; fitContent: true"
    [matTooltip]="payout.id"
    style="display: flex; align-items: center"
  >
    <img style="width: 22px; min-width: 22px" src="assets/stripe-logo.ico" />
  </div>

  <p *grecoTableCol="'Destination'; let payout">{{payout.destination.bank_name}} •••• {{payout.destination.last4}}</p>

  <p *grecoTableCol="'Date'; let payout;" [matTooltip]="'Initiated on ' + (payout.created * 1000 | date)">
    <span style="font-size: 75%">Estimated Arrival</span>
    <br />
    <span>{{payout.arrival_date * 1000 | date}}</span>
  </p>

  <mat-chip-list *grecoTableCol="'Status'; let payout">
    <mat-chip>{{payout.status.replace('_', ' ') | titlecase}}</mat-chip>
  </mat-chip-list>

  <p *grecoTableCol="'Amount'; let payout; alignRight: true;">
    <strong>{{payout.amount / 100 | currency}}</strong>
  </p>
</greco-table>

<div style="padding: 16px">
  <button
    mat-stroked-button
    style="margin-left: auto"
    [disabled]="loading || !hasMore"
    (click)="loadPayouts(payouts[payouts.length - 1]?.id)"
  >
    Load more
  </button>
</div>
