import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { Subscription } from '@greco/sales-subscriptions';
import { SubscriptionsService } from '../../services';

@Component({
  templateUrl: './set-sold-by.dialog.html',
})
export class SetSubscriptionSoldByDialog implements OnInit {
  readonly simpleDialogData: DialogData = {
    title: 'Sold by',
    subtitle: "Update the 'Sold by' property of this subscription.",
    hideDefaultButton: true,
  };

  soldByFormControl = new FormControl(null);

  constructor(
    private susbcriptionSvc: SubscriptionsService,
    @Inject(MAT_DIALOG_DATA) private subscription: Subscription,
    private matDialogRef: MatDialogRef<SetSubscriptionSoldByDialog>
  ) {}

  ngOnInit() {
    setTimeout(() => this.soldByFormControl.reset(this.subscription.soldBy));
  }

  cancel() {
    this.matDialogRef.close();
  }

  async submit() {
    try {
      const update = await this.susbcriptionSvc.setSoldBy(
        this.subscription.id,
        this.soldByFormControl.value?.id ?? null
      );
      this.matDialogRef.close(update);
    } catch (err) {
      console.error(err);
    }
  }
}
