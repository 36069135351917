import { Component } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { User } from '@greco/identity-users';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { AdminService } from '../../services';

@Component({
  selector: 'greco-grant-super-admin',
  templateUrl: './grant-super-admin.dialog.html',
})
export class GrantSuperAdminDialog {
  constructor(private adminService: AdminService, private snacks: MatSnackBar) {}

  dialogData: DialogData = {
    title: 'Grant Administrator Rights',
    subtitle: 'Search and select all the users you wish to make administrators',
    showCloseButton: false,
    buttons: [
      {
        label: 'Cancel',
        role: 'cancel',
      },
      {
        label: 'Confirm',
        role: 'confirm',
        resultFn: async () => {
          try {
            await this.grantSelectedUsers();
            this.snacks.open('Selected users granted administrator rights!', 'Ok', {
              panelClass: 'mat-primary',
            });
            return true;
          } catch (err) {
            return false;
          }
        },
      },
    ],
  };

  selectedUsers: User[] = [];

  searchQuery$ = new BehaviorSubject<null | string>(null);
  users$: Observable<User[]> = this.searchQuery$.pipe(
    debounceTime(300),
    switchMap(qry => this.adminService.searchNonAdmins(qry as string))
  );

  onChange(value?: string) {
    this.searchQuery$.next(value || '');
  }

  selectUser(event: MatAutocompleteSelectedEvent) {
    const selectedUser = event.option.value;
    if (
      this.selectedUsers.some(user => {
        return user.id === selectedUser.id;
      })
    ) {
      this.snacks.open('Selected user has already been added!', 'Ok', {
        panelClass: 'mat-warn',
      });
      return;
    }

    this.selectedUsers = [...this.selectedUsers, selectedUser];
  }

  removeUser(selectedUser: User) {
    const tmp = [];
    for (const user of this.selectedUsers) if (user.id !== selectedUser.id) tmp.push(user);
    this.selectedUsers = tmp;
  }

  async grantSelectedUsers() {
    if (this.selectedUsers.length === 0)
      throw new Error("You haven't selected any users! No users have been granted administrator rights.");
    for (const user of this.selectedUsers) await this.adminService.grantSuperAdmin(user.id);
  }
}
