<greco-collapsible-section
  [header]="{
    title: 'Account Details',
    icon: 'info'
  }"
>
  <greco-form-save-buttons
    header
    [form]="formGroup"
    [saveAction]="onSave"
    [resetValue]="initialValue"
  ></greco-form-save-buttons>
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email Address</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input matInput formControlName="phone" type="tel" mask="(000) 000-0000" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Address</mat-label>
      <greco-address-input [strict]="true" required formControlName="address"></greco-address-input>
    </mat-form-field>
  </form>
</greco-collapsible-section>
