export enum RecurrencePeriod {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export interface SubscriptionRecurrence {
  frequency: number;
  period: RecurrencePeriod;
  cycles?: number;
}
