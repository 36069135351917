import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { VideoUnlock } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'root' })
export class VideoUnlockService {
  constructor(private http: HttpClient) {}

  private baseUrl = '/api/video-unlocks';

  async getUserUnlockedVideosLimit(limit = 3): Promise<VideoUnlock[]> {
    return await toPromise(
      this.http.get<VideoUnlock[]>(`/api/event-videos-unlock/user-unlocked`, {
        params: {
          limit: limit.toString(),
        },
      })
    );
  }

  // @Get('unlocked')
  async getUserUnlockedVideos(
    query: RequestQueryBuilder,
    userId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<VideoUnlock>> {
    return await toPromise(
      this.http.get<PaginatedDto<VideoUnlock>>(`${this.baseUrl}/unlocked`, {
        params: {
          ...query.queryObject,
          userId: userId,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
}
