import { SecurityResource } from '@greco/security';

export const TILES_GROUP_SECURITY_RESOURCE = 'tiles-group';
export enum TilesGroupSecurityResourceAction {
  UPDATE = 'UPDATE',
}

export const TilesGroupSecurityResource: SecurityResource = {
  key: TILES_GROUP_SECURITY_RESOURCE,
  module: 'tilesGroup',

  title: 'Manage Group Tiles Quick Access',
  description: 'Update quick access for group tiles',

  context: class {
    id?: string;
    communityId?: string;
  },

  actions: [
    {
      key: TilesGroupSecurityResourceAction.UPDATE,
      title: 'Update tiles group',
      description: 'Abiliity to update Tiles Group.',
    },
  ],
};
