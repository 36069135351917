import { User } from '@greco/identity-users';
import { BaseDto } from '@greco/util-dto';

export enum PaymentDisputeStatus {
  OPEN = 'OPEN',
  ACCEPTED = 'ACCEPTED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  WON = 'WON',
  LOST = 'LOST',
}

export class CreatePaymentDisputeDto extends BaseDto {
  billingAddressFormatted?: string;
  externalId!: string;
  paymentId!: string;
  accountId!: string;
  userId!: string;
  user?: User;
  accessActivityLog?: string | null;
  cancellationPolicyDisclosure?: string | null;
  cancellationRebuttal?: string | null;
  customerEmailAddress?: string | null;
  customerName?: string | null;
  duplicateChargeExplanation?: string | null;
  duplicateChargeId?: string | null;
  reason!: string;
  evidanceSubmissionDueBy!: Date;
  status!: PaymentDisputeStatus;
}
