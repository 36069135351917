import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SuperTitleModule } from '../../components/super-title/super-title.module';
import { MenuLayoutComponent } from './menu-layout.component';

@NgModule({
  imports: [CommonModule, MatToolbarModule, MatButtonModule, MatIconModule, MatSidenavModule, MatListModule, MatTabsModule, SuperTitleModule, RouterModule],
  exports: [MenuLayoutComponent],
  declarations: [MenuLayoutComponent]
})
export class MenuLayoutModule {}
