<greco-simple-dialog [data]="dialogData">
  <ng-container *grecoLet="isMobile$ | async as mobile">
    <webcam
      *ngIf="!webcamImage"
      [width]="mobile ? 250 : 533 "
      [trigger]="trigger"
      [captureImageData]="true"
      (imageCapture)="handleImage($event)"
      mirrorImage="always"
    ></webcam>

    <div *ngIf="webcamImage">
      <img [src]="webcamImage.imageAsDataUrl" [width]="mobile ? 250 : 533" class="webcam" />
    </div>
  </ng-container>

  <div style="margin-top: 12px; display: flex; justify-content: flex-end; gap: 8px">
    <button *ngIf="!webcamImage" mat-button color="primary" (click)="triggerSnapshot()">Take Photo</button>
    <button *ngIf="!webcamImage" mat-button color="primary" (click)="cancel()">Cancel</button>
  </div>

  <div style="margin-top: 12px; display: flex; justify-content: flex-end; gap: 8px">
    <button *ngIf="webcamImage" mat-button color="primary" (click)="keepSnapshot()">Use Photo</button>
    <button *ngIf="webcamImage" mat-button color="primary" (click)="clearSnapshot()">Retake Photo</button>
  </div>
</greco-simple-dialog>
