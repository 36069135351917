import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { first } from 'rxjs/operators';

@Component({
  selector: 'greco-simple-dialog',
  templateUrl: 'simple.dialog.html',
  styleUrls: ['simple.dialog.scss'],
})
export class SimpleDialog {
  @Input() public data: DialogData;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: DialogData, public dialogRef: MatDialogRef<SimpleDialog>) {
    this.data = {
      ...this.dialogData,
      buttons: this.dialogData?.buttons || [{ label: 'Ok', role: 'ok' }],
    }; // Initial value
  }

  async close(dialogResult?: any) {
    const resultFn = this.data.buttons?.find(b => b.role === dialogResult)?.resultFn;
    this.dialogRef.close(resultFn ? await resultFn() : dialogResult);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static async confirmation(dialogSvc: MatDialog, content: string): Promise<boolean> {
    const dialog = dialogSvc.open(SimpleDialog, {
      data: {
        content,
        title: 'Confirmation',
        buttons: [
          { label: 'Cancel', role: 'cancel' },
          { label: 'Confirm', role: 'confirm' },
        ],
      },
    });

    const confirmation = await dialog.afterClosed().pipe(first()).toPromise();
    return confirmation === 'confirm';
  }
}
