import { Pipe, PipeTransform } from '@angular/core';
import { ProductVariantRecurrence } from '@greco/sales-products';
import { SubscriptionRecurrence } from '@greco/sales-subscriptions';
import { VariantPriceLabel } from '@greco/ngx-sales-products';

@Pipe({ name: 'grecoRecurrence' })
export class RecurrenceLabelPipe implements PipeTransform {
  transform(recurrence?: SubscriptionRecurrence | ProductVariantRecurrence) {
    if (!recurrence) return '';
    return new VariantPriceLabel('').formatFrequencyString(recurrence);
  }
}
