<greco-collapsible-section
  *grecoLet="canUpdateResource$ | async as canUpdateResource"
  [header]="{ title: 'Trainer Resource Details', icon: 'info' }"
  [disabled]="true"
>
  <greco-form-save-buttons
    *ngIf="canUpdateResource"
    header
    [form]="formGroup"
    [saveAction]="onSave"
    [resetValue]="initialValue"
  ></greco-form-save-buttons>

  <p *ngIf="resource.disabled">
    This resource is disabled. It will not be selectable
    <a> and the linked user cannot access their schedule</a>.
  </p>

  <form [formGroup]="formGroup">
    <mat-form-field appearance="standard">
      <greco-smart-img matPrefix class="avatar" [round]="true" [src]="$any(resource)?.user?.photoURL"></greco-smart-img>
      <mat-label>Display Name</mat-label>
      <input [readonly]="resource.disabled" matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" [readonly]="resource.disabled"></textarea>
    </mat-form-field>

    <!-- Tags -->
    <mat-form-field>
      <mat-label>Tags</mat-label>
      <greco-resource-tag-picker
        [communityId]="resource.community.id"
        formControlName="resourceTags"
      ></greco-resource-tag-picker>
    </mat-form-field>

    <!-- Is Filterable -->
    <mat-checkbox style="margin-bottom: 16px" [disabled]="resource.disabled" formControlName="isFilterable">
      Members can use this trainer as a filter when booking
    </mat-checkbox>
  </form>

  <!-- Enable/Disable Resource -->
  <button
    mat-button
    *ngIf="canUpdateResource"
    [ngClass]='resource.disabled ? "enabled" : "disabled"'
    [matTooltip]="'Click this to Enable/Disable the resource'"
    (click)="setDisable()"
  >
    {{ resource.disabled ? 'Re-Enable' : 'Disable' }}
  </button>
</greco-collapsible-section>
