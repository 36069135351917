<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput required formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput required formControlName="email" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input matInput required type="tel" mask="(000) 000-0000" formControlName="phoneNumber" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Address</mat-label>
      <greco-address-input
        [zipRequired]="false"
        [required]="true"
        [strict]="true"
        formControlName="address"
      ></greco-address-input>
    </mat-form-field>
  </form>
  <!-- <form [formGroup]="formGroup"  style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input placeholder="Write a descriptive role title..." matInput type="text" required formControlName="title" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea
        matInput
        [matTextareaAutosize]="true"
        formControlName="description"
        placeholder="Describe who this role should be assigned to..."
      ></textarea>
    </mat-form-field>

    <mat-checkbox formControlName="assignable"> Assignable in Studios </mat-checkbox>
  </form> -->
</greco-simple-dialog>
