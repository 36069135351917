import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  Booking,
  BookingStatus,
  CalendarEvent,
  CourseRegistration,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventSeries,
  getCancellationPolicyInnerHtml,
} from '@greco/booking-events';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { ResponsePreviewDialog } from '@greco/ngx-typeform';
import { PropertyListener } from '@greco/property-listener-util';
import { FormResponse } from '@greco/typeform';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import moment from 'moment';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BookingService, CourseService } from '../../services';

@Component({
  selector: 'greco-course-bookings-table',
  templateUrl: './course-bookings-table.component.html',
  styleUrls: ['./course-bookings-table.component.scss'],
})
export class CourseBookingsTableComponent implements OnDestroy {
  moment = moment;
  now = new Date();

  constructor(
    private courseSvc: CourseService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private comSecSvc: CommunitySecurityService,
    private userSvc: UserService,
    private bookingSvc: BookingService
  ) {}

  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>(1);
  @Input() event!: CalendarEvent | EventSeries;

  @Output() rowClick = new EventEmitter<Booking>();

  @Input() hideEvent?: boolean;
  @Input() hideDate?: boolean;

  @Input() showSpotNumber = false;

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId?: string;

  @Input() allowNoCommunityId = false;

  @PropertyListener('filters') private _filters$ = new BehaviorSubject<RequestQueryBuilder | undefined>(undefined);
  @Input() filters?: RequestQueryBuilder;

  canCancelBookings$ = combineLatest([this._communityId$, this._event$]).pipe(
    switchMap(async ([com, event]) => {
      if (!event) return false;
      const user = await this.userSvc.getSelf();
      return com
        ? (await this.comSecSvc.hasAccess(
            com,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CANCEL
          )) || event?.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  canNoShow$ = combineLatest([this._communityId$, this._event$]).pipe(
    switchMap(async ([com, event]) => {
      if (!event) return false;
      const user = await this.userSvc.getSelf();
      return com
        ? (await this.comSecSvc.hasAccess(
            com,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.NO_SHOW
          )) || event?.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  canCheckIn$ = combineLatest([this._communityId$, this._event$]).pipe(
    switchMap(async ([com, event]) => {
      if (!event) return false;
      const user = await this.userSvc.getSelf();
      return com
        ? (await this.comSecSvc.hasAccess(
            com,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CHECK_IN
          )) || event?.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  canCancelBookingsFreeOfCharge$ = combineLatest([this._communityId$, this._event$]).pipe(
    switchMap(async ([com, event]) =>
      com && event
        ? await this.comSecSvc.hasAccess(
            com,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CANCEL_FREE_OF_CHARGE
          )
        : false
    ),
    shareReplay(1)
  );

  canManageUser$ = combineLatest([this._communityId$, this._event$]).pipe(
    switchMap(async ([com, event]) =>
      com && event ? await this.comSecSvc.hasAccess(com, ContactResource.key, ContactResourceAction.READ) : false
    ),
    shareReplay(1)
  );

  canDoAnything$ = combineLatest([
    this.canCancelBookings$,
    this.canNoShow$,
    this.canCheckIn$,
    this.canCancelBookingsFreeOfCharge$,
    this.canManageUser$,
  ]).pipe(map(access => access.some(a => !!a)));

  loading = true;
  metadata?: IPaginationMeta;

  private refresh$ = new BehaviorSubject(null);

  readonly pagination$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  readonly courseBookings$ = combineLatest([this._event$, this._filters$, this.pagination$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([event, _filters, pagination]) =>
      event || this.allowNoCommunityId
        ? await this.courseSvc.coursePaginate(
            RequestQueryBuilder.create({
              search: { status: { $in: [BookingStatus.CONFIRMED, BookingStatus.PENDING] } },
            }).sortBy({
              field: 'created',
              order: 'DESC',
            }),
            event.id,
            pagination
          )
        : null
    ),
    tap(data => (this.metadata = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  async cancel(course: CourseRegistration, freeOfCharge: boolean) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Cancel Booking',
        subtitle: 'Are you sure you want to cancel this booking?',
        content:
          !freeOfCharge && course.bookingOption
            ? `
              <p><strong>The following cancellation policy applies</strong>:</p>
              ${getCancellationPolicyInnerHtml(course?.bookingOption)}
            `
            : null,
        buttons: [
          { label: 'No, Keep the Booking', role: 'no' },
          { label: 'Yes, Cancel the Booking', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.courseSvc.cancel(course.id);
      // await this.bookingSvc.cancel(booking.id, freeOfCharge);
      this.snacks.open('Booking Cancelled', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      this._filters$.next(this._filters$.value);
    }
  }

  async noShow(booking: Booking) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Mark as No-Show',
        subtitle: 'Are you sure you want to mark this booking as no-show?',
        content: `
            <p><strong>The following cancellation policy applies</strong>:</p>
            ${getCancellationPolicyInnerHtml(booking.bookingOption)}
          `,
        buttons: [
          { label: 'No, Keep the Booking', role: 'no' },
          { label: 'Yes, No-Show the Booking', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.bookingSvc.noShow(booking.id);
      this.snacks.open('Booking No-Show', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      this._filters$.next(this._filters$.value);
    }
  }

  async checkIn(booking: Booking) {
    await this.bookingSvc.checkIn(booking.id);
    this.snacks.open('User Checked-In!', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
    this._filters$.next(this._filters$.value);
  }

  async confirm(course: Booking) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Booking',
        subtitle: 'Are you sure you want to confirm this booking?',
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.bookingSvc.confirmPendingCourse(course.id);
      this.snacks.open('Booking Confirmed', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      this._filters$.next(this._filters$.value);
    }
  }

  previewFormResponse(response: FormResponse, formTitle: string) {
    this.matDialog.open(ResponsePreviewDialog, { data: { response, formTitle } });
  }

  ngOnDestroy() {
    this._communityId$.complete();
    this._filters$.complete();
    this.pagination$.complete();
  }

  public refresh() {
    this.refresh$.next(null);
  }
}
