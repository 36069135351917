import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import {
  BookingStatus,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventDetails,
  EventSeries,
  EventSeriesSecurityResource,
  EventSeriesSecurityResourceAction,
} from '@greco/booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { CourseBookingsTableComponent } from '../../components';
import { AddAttendeeDialog } from '../../dialogs';
import { CourseService } from '../../services';

@Component({
  selector: 'greco-series-details-page',
  templateUrl: './series-details.page.html',
  styleUrls: ['./series-details.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SeriesDetailsPage {
  constructor(
    private matDialog: MatDialog,
    private userSvc: UserService,
    private courseSvc: CourseService,
    private comSecSvc: CommunitySecurityService
  ) {}

  @PropertyListener('series') private _series$ = new ReplaySubject<EventSeries>(1);
  @Input() series!: EventSeries;

  @Output() refreshed = new EventEmitter();
  @ViewChild(CourseBookingsTableComponent) courseBookingTable!: CourseBookingsTableComponent;

  canUpdateSeries$ = this._series$.pipe(
    switchMap(async series => {
      return series
        ? await this.comSecSvc.hasAccess(
            series.community.id,
            EventSeriesSecurityResource.key,
            EventSeriesSecurityResourceAction.UPDATE
          )
        : false;
    })
  );

  readonly canViewBookings$ = this._series$.pipe(
    switchMap(async event => {
      const user = await this.userSvc.getSelf();
      return event
        ? (await this.comSecSvc.hasAccess(
            event.community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.LIST
          )) || event.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  readonly canCreateBooking$ = this._series$.pipe(
    switchMap(async event => {
      const user = await this.userSvc.getSelf();
      return event
        ? (await this.comSecSvc.hasAccess(
            event.community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CREATE
          )) || event.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  readonly hasAttendees$ = this._series$.pipe(
    switchMap(async series => {
      return series
        ? await this.courseSvc.getRegistrationByCourse(
            new RequestQueryBuilder().search({
              courseId: series.id,
              status: { $in: [BookingStatus.CHECKED_IN, BookingStatus.CONFIRMED, BookingStatus.PENDING] },
            }),
            series.id,
            { limit: 1 }
          )
        : null;
    }),
    map(data => data?.items.length)
  );

  readonly attendeeFilters$ = this._series$.pipe(
    map(event =>
      new RequestQueryBuilder().search({
        status: { $in: [BookingStatus.CHECKED_IN, BookingStatus.CONFIRMED, BookingStatus.PENDING] },
        'event.id': (event as EventSeries).id,
      })
    )
  );

  async addAttendee() {
    const event = this.series;
    const dialog = this.matDialog.open(AddAttendeeDialog, {
      data: { event: event as EventDetails, series: event },
      width: '750px',
      maxWidth: '90%',
    });
    const after = await toPromise(dialog.afterClosed());
    if (after) {
      this._series$.next(this.series);
      this.courseBookingTable.refresh();
    }
  }
}
