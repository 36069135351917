<h2 style="margin: 0">Enter the Tax Number</h2>
<form [formGroup]="formGroup">
  <mat-form-field appearance="standard" style="width: 100%">
    <mat-label>Tax Number</mat-label>
    <input matInput required type="text" formControlName="taxNumber" placeholder="Provide appropriate tax number" />
  </mat-form-field>
</form>
<div style="display: flex; gap: 8px">
  <button style="flex: 1" type="button" mat-stroked-button (click)="close()">
    <span>Cancel</span>
  </button>
  <button
    style="flex: 1"
    type="submit"
    mat-stroked-button
    color="primary"
    (click)="update()"
    [disabled]="formGroup.invalid"
  >
    <span>Confirm</span>
  </button>
</div>
