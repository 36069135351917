import { PRODUCT_CONDITION_TYPE_UNAVAILABLE } from '@greco/sales-products';
import { ProductConditionForm } from '../dialogs';

export const PRODUCT_CONDITION_CONFIG_UNAVAILABLE: ProductConditionForm = {
  type: PRODUCT_CONDITION_TYPE_UNAVAILABLE,
  label: 'Always Unavailable',
  matIcon: 'do_not_disturb_on',
  getConditionDetails: () => 'Always Unavailable',
  getConditionShortDetails: () => 'Unavailable',
};
