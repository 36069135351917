/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { PaginatedQueryParams, PaginationMetadata } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { AgreementStatus, UserCommunityAgreement } from '@greco/community-agreements';
import { PropertyListener } from '@greco/property-listener-util';
import { RecurrencePeriod } from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import * as fxp from 'fast-xml-parser';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { PreviewAgreementDialog, SignAgreementDialog } from '../../dialogs';
import { UserCommunityAgreementsService } from '../../services';

require('tools/fonts/SourceSansPro-bold');
require('tools/fonts/SourceSansPro-bolditalic');
require('tools/fonts/SourceSansPro-italic');
require('tools/fonts/SourceSansPro-normal');

@Component({
  selector: 'greco-user-agreements-table',
  templateUrl: './user-agreements-table.component.html',
  styleUrls: ['./user-agreements-table.component.scss'],
})
export class UserAgreementsTableComponent implements OnDestroy {
  constructor(
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private agreementsSvc: UserCommunityAgreementsService
  ) {}

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @PropertyListener('filters') private _filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  @Input() filters?: RequestQueryBuilder;

  @PropertyListener('paginatedParams') paginatedParams$ = new BehaviorSubject<PaginatedQueryParams>({
    page: 1,
    limit: 5,
  });

  @Output() rowClick = new EventEmitter<any>();

  loading = false;
  currentPagination: PaginationMetadata | null = null;

  private _refresh$ = new BehaviorSubject<null>(null);
  private _userId$ = new BehaviorSubject<string | null>(null);
  private _pageSizes$ = new BehaviorSubject<number[]>([5, 10, 20, 50]);

  agreements$ = combineLatest([this._userId$, this.paginatedParams$, this._filters$, this._refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    debounceTime(500),
    switchMap(async ([userId, params, filters]) => {
      const pagination = await this.agreementsSvc.paginateUserAgreements(filters, userId || '', params);
      this.currentPagination = pagination.meta;
      return pagination;
    }),
    map(data => data.items),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  @Input() set pageSizes(sizes: number[]) {
    this._pageSizes$.next(sizes?.length ? sizes : [5, 10, 20, 50]);
    const paginatedParams = this.paginatedParams$.value;
    // if (!this._pageSizes$.value.includes(paginatedParams.limit || 0)) {
    this.paginatedParams$.next({ ...paginatedParams, limit: this.pageSizes[0] });
    // }
  }
  get pageSizes() {
    return this._pageSizes$.value;
  }

  @Input() set userId(userId: string | null | undefined) {
    this._userId$.next(userId || null);
  }
  get userId() {
    return this._userId$.value;
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  canView(agreement: UserCommunityAgreement) {
    if (agreement.status !== AgreementStatus.SIGNED) {
      this.snacks.open('You are unable to view this agreement until it is signed!', 'Ok', {
        panelClass: 'mat-warn',
        duration: 10000,
      });
    } else {
      this.rowClick.next(agreement);
      this.previewPDF(agreement);
    }
  }

  async signAgreement(agreement: UserCommunityAgreement) {
    const result = await toPromise(this.dialog.open(SignAgreementDialog, { data: agreement }).afterClosed());
    if (result) this._refresh$.next(null);
  }

  downloadPDF(agreement: UserCommunityAgreement) {
    this.createPDF(agreement).then(pdf => {
      pdf.save(agreement.signedAgreementTitle);
    });
  }

  previewPDF(agreement: UserCommunityAgreement) {
    this.createPDF(agreement).then(pdf => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { pdf: pdf, title: agreement.signedAgreementTitle };
      this.dialog.open(PreviewAgreementDialog, dialogConfig);
    });
  }

  async createPDF(agreement: UserCommunityAgreement): Promise<jsPDF> {
    const details = await this.agreementsSvc.getAgreementDetails(agreement.id);

    const _correlationId =
      details.userAgreement_bookingId ||
      details.userAgreement_subscriptionId ||
      details.userAgreement_purchaseId ||
      agreement.agreementId;

    const pdf = new jsPDF();

    pdf.setFont('SourceSansPro');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const pageInfo = new PageInfo(10, 15, 10, '');
    pdf.setProperties({
      title: agreement.signedAgreementTitle,
    });
    pdf.addImage('assets/lf3/logo.png', 'PNG', 10, 10, (4 * pdfHeight) / 22, pdfHeight / 22);

    pdf.setFontSize(10);
    pdf.setFont('SourceSansPro', 'bold');
    const titleLines = pdf.splitTextToSize(agreement.signedAgreementTitle, pdfWidth - 80);
    pdf.text(titleLines, pdfWidth - 10, pageInfo.y, { align: 'right' });
    pageInfo.y += pdf.getTextDimensions(titleLines).h + 1;

    pdf.setFont('SourceSansPro', 'normal');

    const headerTextLines = [];

    if (details) {
      if (details.bookingOption) {
        headerTextLines.push(
          details.community_name,
          details.event_title,
          `${new Date(details.event_startDate).toLocaleString()} - ${new Date(details.event_endDate).toLocaleString()}`,
          details.bookingOption
        );
      } else if (details.purchaseItem_displayName || details.subscriptionItem_displayName) {
        headerTextLines.push(
          details.community_name,
          details.purchaseItem_displayName,
          details.purchaseItem_description,
          details.subscriptionItem_displayName,
          details.subscriptionItem_description,
          details.upgradeVariant_value ? `Upgrades to ${details.upgradeVariant_value}` : null
        );

        if (details.purchase_total || details.subscription_total) {
          headerTextLines.push(
            `Subtotal: $${((details.subscription_subtotal ?? details.purchase_subtotal) / 100).toFixed(2)}`
          );
          headerTextLines.push(`Taxes: $${((details.subscription_tax ?? details.purchase_tax) / 100).toFixed(2)}`);
          headerTextLines.push(`Total: $${((details.subscription_total ?? details.purchase_total) / 100).toFixed(2)}`);

          if (details.recurrenceFrequency) {
            let recurrenceText = '';
            const period = () => {
              if (details.recurrencePeriod === RecurrencePeriod.Daily) return 'day';
              else return details.recurrencePeriod.substring(0, details.recurrencePeriod.length - 2);
            };

            recurrenceText += `Billed every ${
              details.recurrenceFrequency > 1 ? `${details.recurrenceFrequency} ` : ''
            }${period().toLowerCase()}${details.recurrenceFrequency > 1 ? 's' : ''}`;

            if (details.recurrenceCycles) {
              recurrenceText += ` for ${details.recurrenceFrequency} ${period().toLowerCase()}${
                details.recurrenceFrequency > 1 ? 's' : ''
              }`;
            }

            headerTextLines.push(
              recurrenceText,
              `${details.paymentMethod_model.toUpperCase()} ${details.paymentMethod_label}`
            );
          }
        }
      }
    }

    headerTextLines.push(`Date signed: ${agreement.created.toLocaleString()}`);
    this.addHeaderText(headerTextLines, pdf, pageInfo, 'right');

    await this.agreementsSvc
      .getUserDetails(agreement.userId)
      .then(user => {
        if (user) {
          const phoneNumber =
            user.phoneNumber?.length === 10
              ? '(' +
                user.phoneNumber.substring(0, 3) +
                ') ' +
                user.phoneNumber.substring(3, 6) +
                '-' +
                user.phoneNumber.substring(6)
              : user.phoneNumber;

          this.addHeaderText(
            [user.displayName, phoneNumber, user.email, user.address?.formatted],
            pdf,
            pageInfo,
            'left'
          );
        }
      })
      .catch(err => console.error(`Error fetching user details for PDF: ${err}`));

    const options = {
      preserveOrder: true,
      ignoreAttributes: false,
      trimValues: false,
    };
    const parser = new fxp.XMLParser(options);
    let agreementText = agreement.signedAgreementText;
    if (agreement.signature !== null) {
      agreementText += `<figure class="image"><img src="${agreement.signature}"></figure>`;
    }
    const text = agreementText.split('&nbsp;').join('');
    let obj = parser.parse(text);

    // for old agreements with no html, add basic paragraph wrapper
    if (obj.length === 0) {
      obj = parser.parse('<p>' + text + '</p>');
    }
    await this.traversal(obj, pdf, pageInfo);
    return pdf;
  }

  async traversal(obj: any, pdf: jsPDF, pageInfo: PageInfo) {
    for (const [tag, value] of Object.entries(obj)) {
      switch (tag) {
        case '#text':
          this.addText(pageInfo, pdf, value);
          break;
        case 'table':
          this.addTable(pageInfo, pdf, value);
          break;
        default:
          if (Object.prototype.hasOwnProperty.call(<any>value, 'img')) {
            await this.addImage(pageInfo, pdf, (<any>value)[':@']['@_src']);
          }
          if ((Array.isArray(value) && value.length === 0) || tag == ':@') {
            return;
          }
          this.addTagEffect(tag, pdf, pageInfo);
          await this.traversal(value, pdf, pageInfo);
          this.removeTagEffect(tag, pdf, pageInfo);
      }
    }
  }

  private addHeaderText(
    text: (string | null | undefined)[],
    pdf: jsPDF,
    pageInfo: PageInfo,
    alignment: 'left' | 'right'
  ) {
    const pageWidth = pdf.internal.pageSize.getWidth();
    text
      .filter(userValue => userValue !== undefined && userValue !== null)
      .forEach((value, index, array) => {
        if (value !== undefined && value !== null) {
          const text = pdf.splitTextToSize(value, pageWidth - 80);
          pdf.text(text, alignment === 'left' ? 10 : pageWidth - 10, pageInfo.y, { align: alignment });
          if (index === array.length - 1) pageInfo.y += pdf.getTextDimensions(text).h + 15;
          else pageInfo.y += pdf.getTextDimensions(text).h + 1;
        }
      });
  }

  private addText(pageInfo: PageInfo, pdf: jsPDF, value: any) {
    let strValue = pageInfo.addonStr + <string>value;
    if (pageInfo.addonStr !== '• ') pageInfo.addonStr = ''; // Only remove duplicate addonStr if it's not a bullet point.
    pdf.setFontSize(10);

    //text not new line, must continue from x
    if (pageInfo.x !== 10) {
      const text = pdf.splitTextToSize(strValue, pdf.internal.pageSize.getWidth() - pageInfo.margin * 2 - pageInfo.x);
      if (text) {
        pdf.text(text[0], pageInfo.x + pageInfo.margin, pageInfo.y - (pdf.getTextDimensions(text[0]).h + 1));
        //remove the one line of text added from x
        strValue = text.slice(1, text.length).join(' ');

        if (text.length === 1) {
          pageInfo.x +=
            pageInfo.x + pdf.getTextDimensions(text[0]).w > pdf.internal.pageSize.getWidth()
              ? 10
              : pdf.getTextDimensions(text[0]).w;
        } else if (text[0] === '') {
          //no text fit keep all string and reset to original x
          // strValue = pageInfo.addonStr + <string>value;
          pageInfo.x = 10;
        }
      }
    }

    //add rest of text line by line
    const text = pdf.splitTextToSize(strValue, pdf.internal.pageSize.getWidth() - pageInfo.margin * 2);
    if (text[0] === '' && text.length === 1) {
      return;
    }
    for (let i = 0; i < text.length; i++) {
      if (pageInfo.y + pdf.getTextDimensions(text[i]).h > pdf.internal.pageSize.getHeight() - 10) {
        pageInfo.y = 15;
        pdf.addPage();
      }
      pdf.text(text[i], pageInfo.margin, pageInfo.y);
      pageInfo.y += pdf.getTextDimensions(text[i]).h + 1;
      pageInfo.x = pdf.getTextDimensions(text[i]).w;
    }
  }

  private addTable(pageInfo: PageInfo, pdf: jsPDF, value: any) {
    const tableContents = [{ '?xml': [{ '#text': '' }], ':@': { '@_version': '1.0' } }, ...(<any[]>value)];
    const table = document.createElement('table');
    const builder = new fxp.XMLBuilder({ preserveOrder: true });
    const xml = builder.build(tableContents);
    table.innerHTML = xml;
    table.id = 'tabletest';
    document.body.appendChild(table);

    autoTable(pdf, {
      startY: pageInfo.y,
      margin: 10,
      html: '#tabletest',
    });
    pageInfo.y = (pdf as any).lastAutoTable.finalY + 10;
    document.body.removeChild(table);
  }

  private async addImage(pageInfo: PageInfo, pdf: jsPDF, src: string) {
    const img = new Image();
    img.src = src;
    await img.decode();
    img.height = (img.height * (pdf.internal.pageSize.getWidth() - 20)) / img.width;
    img.width = pdf.internal.pageSize.getWidth() - 20;

    if (pageInfo.y + img.height > pdf.internal.pageSize.getHeight() - 10) {
      pdf.addPage();
      pageInfo.y = 15;

      //image too tall, scale down
      if (pageInfo.y > pdf.internal.pageSize.getHeight() - 10) {
        img.width = (img.width * (pdf.internal.pageSize.getHeight() - 20)) / img.height;
        img.height = pdf.internal.pageSize.getHeight() - 20;
      }
    }
    pdf.addImage(img, 'png', pageInfo.x, pageInfo.y, img.width, img.height);
    pageInfo.y += img.height + 10;
  }

  addTagEffect(tag: string, pdf: jsPDF, pageInfo: PageInfo) {
    const textStyle = pdf.getFont().fontStyle;
    let resultStyle = '';
    switch (tag) {
      case 'h2':
        pdf.setFontSize(20);
        break;
      case 'h3':
        pdf.setFontSize(18);
        break;
      case 'h4':
        pdf.setFontSize(16);
        break;
      case 'p':
        pdf.setFontSize(12);
        break;
      case 'strong':
        resultStyle = textStyle.replace('italic', 'bolditalic').replace('normal', 'bold');
        pdf.setFont('SourceSansPro', resultStyle);
        break;
      case 'i':
        resultStyle = textStyle.replace('bold', 'bolditalic').replace('normal', 'italic');
        pdf.setFont('SourceSansPro', resultStyle);
        break;
      case 'blockquote':
        pageInfo.margin = 30;
        break;
      case 'ul':
        pdf.setFontSize(12);
        pageInfo.addonStr = '• ';
        pageInfo.margin += 10;
        break;
      case 'ol':
        pdf.setFontSize(12);
        pageInfo.margin += 10;
        pageInfo.isOL = true;
        break;
      case 'li':
        if (pageInfo.isOL) {
          pageInfo.count++;
          pageInfo.addonStr = pageInfo.count + '. ';
        }
        break;
    }
  }

  removeTagEffect(tag: string, pdf: jsPDF, pageInfo: PageInfo) {
    const textStyle = pdf.getFont().fontStyle;
    let resultStyle = '';
    switch (tag) {
      case 'h2':
      case 'h3':
      case 'h4':
      case 'p':
      case 'img':
        pageInfo.y += 5;
        pageInfo.x = 10;
        break;
      case 'strong':
        resultStyle = textStyle.replace('bolditalic', 'italic').replace('bold', 'normal');
        pdf.setFont('SourceSansPro', resultStyle);
        break;
      case 'i':
        resultStyle = textStyle.replace('bolditalic', 'Bold').replace('italic', 'normal');
        pdf.setFont('SourceSansPro', resultStyle);
        break;
      case 'blockquote':
        pageInfo.margin = 10;
        break;
      case 'ul':
      case 'ol':
        pageInfo.x = 10;
        pageInfo.y += 5;
        pageInfo.margin -= 10;
        pageInfo.addonStr = '';
        pageInfo.isOL = false;
        break;
      case 'li':
        pageInfo.x = 10;
        break;
    }
  }

  ngOnDestroy() {
    this._userId$.complete();
    this._pageSizes$.complete();
    this.paginatedParams$.complete();
  }
}

class PageInfo {
  x: number;
  y: number;
  margin: number;
  addonStr: string;
  count: number;
  isOL: boolean;
  constructor(x: number, y: number, margin: number, addonStr: string) {
    this.x = x;
    this.y = y;
    this.margin = margin;
    this.addonStr = addonStr;
    this.count = 0;
    this.isOL = false;
  }
}
