import { Directive, EventEmitter, HostListener, Input, Optional, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuItem } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { ConnectFormDialog } from '../dialogs';

@Directive({
  exportAs: ' ',
  selector: `
    button[mat-stroked-button][grecoConnectTypeformForm],
    button[mat-raised-button][grecoConnectTypeformForm],
    button[mat-flat-button][grecoConnectTypeformForm],
    button[mat-icon-button][grecoConnectTypeformForm],
    button[mat-menu-item][grecoConnectTypeformForm],
    button[mat-mini-fab][grecoConnectTypeformForm],
    button[mat-button][grecoConnectTypeformForm],
    button[mat-fab][grecoConnectTypeformForms]
  `,
})
export class ConnectFormDirective {
  constructor(
    @Optional() private _button: MatButton,
    @Optional() private _item: MatMenuItem,
    private matDialog: MatDialog,
    private snacks: MatSnackBar
  ) {}

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() success = new EventEmitter<void>();

  @Input() accountId!: string;

  private get host() {
    return this._button || this._item;
  }

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent) {
    event.stopImmediatePropagation();

    this.host.disabled = true;

    try {
      const dialog = this.matDialog.open(ConnectFormDialog, { data: this.accountId, width: '400px', maxWidth: '80vw' });
      const result = await toPromise(dialog.afterClosed());
      if (result) this.success.emit();
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
    }

    this.host.disabled = false;
  }
  /* TODO:
    On click:
      - Disable button
      - Open dialog that lists all forms form typeform account (already connected forms should be disabled)
      - Confirm click:
        - Disable confirm button (show spinner))
        - Call add form API that will register a webhook and update the form to include 'user_id' hidden field
        - Show result snackbar
        - If failed:
          - Re-enable confirm button (hide spinner)
        - If success:
          - Close dialog
      - Dialog closed:
        - Re-enable button
  */
}
