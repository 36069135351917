<form [formGroup]="formGroup">
  <mat-form-field appearance="standard" style="width: 29em !important; margin-bottom: 25px">
    <mat-label>Sales Category</mat-label>
    <mat-select formControlName="saleCategory">
      <mat-option
        *ngFor="let category of salesCategories$ | async"
        [value]="category.label"
        (onSelectionChange)="selectedSaleCategory = category"
        [matTooltip]="category.id"
      >
        {{ category.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
