import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { LetModule } from '@greco/ngx-let-util';
import { PerksModule } from '@greco/ngx-sales-perks';
import { TextFitModule } from '@greco/ngx-textfit-util';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { IconPickerModule } from '@greco/ui-icon-picker';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { TableModule } from '@greco/ui-table';
import { DynamicFormsMaterialUIModule } from '@ng-dynamic-forms/ui-material';
import { NgxCurrencyModule } from 'ngx-currency';
import {
  AddCouponAssignmentComponent,
  CouponAssignmentForm,
  CouponAssignmentTableComponent,
  CouponsTableComponent,
  COUPON_ASSIGNMENT_FORMS,
} from './components';
import {
  AddCouponAssignmentDialogComponent,
  AddCouponAssignmentFormContainerDirective,
  CreateCouponDialog,
  GrantCouponDialog,
} from './dialogs';
import { DisplayCouponStatsDialog } from './dialogs/display-coupon-stats/display-coupon-stats.dialog';
import { CouponPage, CouponsPage } from './pages';
import { CouponService } from './services';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    PerksModule,
    RouterModule,
    ReactiveFormsModule,
    NgxCurrencyModule,

    // Material
    MatFormFieldModule,
    MatBadgeModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatStepperModule,
    MatSlideToggleModule,

    // Greco
    BoxModule,
    FiltersModule,
    TableModule,
    TextFitModule,
    SimpleDialogModule,
    LetModule,
    ContactsModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,
    DynamicFormsMaterialUIModule,
    IconPickerModule,
  ],
  providers: [CouponService],
  declarations: [
    CreateCouponDialog,
    CouponPage,
    CouponsPage,
    CouponsTableComponent,
    GrantCouponDialog,
    CouponAssignmentTableComponent,
    AddCouponAssignmentComponent,
    AddCouponAssignmentDialogComponent,
    AddCouponAssignmentFormContainerDirective,
    DisplayCouponStatsDialog,
  ],
  exports: [CreateCouponDialog, CouponPage, CouponsPage],
})
export class CouponsModule {
  static forRoot(couponAssignmentForms: CouponAssignmentForm[]) {
    return {
      ngModule: CouponsModule,
      providers: [{ provide: COUPON_ASSIGNMENT_FORMS, useValue: couponAssignmentForms }],
    };
  }
}
