import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Tag } from '@greco/booking-events';
import { SelectFilter } from '@greco/ngx-filters';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { TagService } from '../../../services';

@Injectable({ providedIn: 'any' })
export class ResourceEventTagsSelectFilter extends SelectFilter {
  public titleCase = new TitleCasePipe();
  constructor(public tagService: TagService) {
    super('ResourceEventTagsSelectFilter', {
      label: 'Tag',
      properties: ['tags.id'],
      shortLabel: 'Tag',
      description: '',
    });
    this.allowedOperators = [CondOperator.EQUALS];
  }

  public communityIds?: string[];

  getValueLabel(value: Tag | Tag[]): string {
    return Array.isArray(value)
      ? value.map(tag => this.titleCase.transform(tag.label)).join(', ')
      : this.titleCase.transform(value.label);
  }

  async getValueOptions(search?: string): Promise<Tag[]> {
    if (!this.communityIds) return [];
    const tags = await this.tagService.paginateCommunities(
      new RequestQueryBuilder().search({
        label: { $contL: search },
      }),
      this.communityIds,
      {
        page: 1,
        limit: search ? 2 : 100,
      }
    );
    return tags.items;
  }

  serializeValue(value: Tag | Tag[]): string {
    return Array.isArray(value) ? value.map(tag => tag.id).join(',') : value.id;
  }

  async deserializeValue(serializedValue: string): Promise<Tag[]> {
    const ids = serializedValue.split(',');
    if (!ids.length) return [];
    return await Promise.all(
      ids.map(id => {
        return this.tagService.getOne(id);
      })
    );
  }

  compareWith(value1: Tag, value2: Tag) {
    return value1?.id === value2?.id;
  }
}
