import { BreakpointObserver } from '@angular/cdk/layout';
import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Injectable, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';

import {
  Booking,
  BookingOption,
  bookingOptionCancellationPolicyLabel,
  BookingRequirement,
  BookingStatus,
  calculateBoosterRequirements,
  CalendarEvent,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  getCancellationPolicyInnerHtml,
  isAvailableNow,
  PersonResource,
  ResourceType,
  TypeformBookingRequirement,
} from '@greco/booking-events';
import { AgreementType, UserAgreementDto } from '@greco/community-agreements';
import { User } from '@greco/identity-users';
import { BookingPreview, CoursePreview } from '@greco/nestjs-booking-events';
import { CommunityAgreementsService } from '@greco/ngx-community-agreements';
import { BuildTextFilter } from '@greco/ngx-filters';
import { PaymentMethodDialogs, UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { SignatureService } from '@greco/ngx-identity-users';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import * as moment from 'moment';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { MinutesPipe } from '../../../pipes';
import { BookingOptionService, BookingService, EventService } from '../../../services';
import { EventCommunityAgreementsUsageService } from '../../../services/event-community-agreements-usage.service';

@Injectable({ providedIn: 'root' })
class AgreementTitleFilter extends BuildTextFilter('AgreementTitleFilter', {
  label: 'Title',
  shortLabel: 'Title',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['agreement.title'],
}) {}

@Component({
  selector: 'greco-preview-booking-page',
  templateUrl: './preview-booking.page.html',
  styleUrls: ['./preview-booking.page.scss'],
})
export class PreviewBookingPage implements OnDestroy {
  constructor(
    private signatureSvc: SignatureService,
    private securitySrv: SecurityService,
    private paymentMethodDialogs: PaymentMethodDialogs,
    private paymentMethodSvc: UserPaymentMethodService,
    private bookingOptionSvc: BookingOptionService,
    private breakpoints: BreakpointObserver,
    private bookingSvc: BookingService,
    private formBuilder: FormBuilder,
    private eventSvc: EventService,
    private matDialog: MatDialog,
    private userSvc: UserService,
    private snacks: MatSnackBar,
    private router: Router,
    private agreementUsageSvc: EventCommunityAgreementsUsageService,
    private agreementsSvc: CommunityAgreementsService,
    private dialog: MatDialog,
    private formGroup: FormBuilder
  ) {}

  refresh$ = new BehaviorSubject(null);
  statusFilter = false;
  filterOptions$ = [AgreementTitleFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  paginationMeta?: IPaginationMeta;

  @Output() refreshed = new EventEmitter<void>();
  isMobile$ = this.breakpoints.observe('(max-width: 600px)').pipe(
    map(({ matches }) => matches),
    shareReplay(1)
  );

  signature: string | null = null;

  currentUserId$ = this.userSvc.getUserId();

  @Output() booked = new EventEmitter<Booking>();

  @Input() footerInPage = false;

  @PropertyListener('user') private _user$ = new ReplaySubject<User>(1);
  @Input() user!: User;

  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>(1);
  @Input() event!: CalendarEvent;

  @Input() allowPendingBooking = false;
  @PropertyListener('allowPendingBooking') private allowPendingBooking$ = new BehaviorSubject(this.allowPendingBooking);

  @Input() preventRedirect = false;

  readonly trainers$ = this._event$.pipe(
    map(
      event =>
        (event?.resourceAssignments?.reduce((acc, assignment) => {
          if (assignment?.resource?.type === ResourceType.PERSON) acc.push(assignment.resource);
          return acc;
        }, [] as PersonResource[]) || []) as PersonResource[]
    ),
    shareReplay(1)
  );

  readonly photoUrls$ = this.trainers$.pipe(
    map(trainers => {
      const photoUrls = trainers.map(trainer => trainer.photoURL as string).filter(url => !!url);
      return photoUrls?.length ? photoUrls : ['assets/lf3/icon_square_pad.png'];
    })
  );

  bookingType = [
    { label: 'Book now', value: true },
    { label: 'Prebook', value: false },
  ];

  @PropertyListener('selectedBookingOption') private _selectedBookingOption$ = new ReplaySubject<BookingOption>(1);
  selectedBookingOption?: BookingOption;

  @PropertyListener('selectedBookingType') private _selectedBookingType$ = new ReplaySubject<{
    label: string;
    value: boolean;
  }>(1);
  selectedBookingType: { label: string; value: boolean } = this.bookingType[0];
  message$ = new BehaviorSubject<string>('Selected');
  agreementData$ = new BehaviorSubject<UserAgreementDto[]>([]);

  form = this.formGroup.group({});
  controlsConfigDetails: { [key: string]: any } = {};

  private requirementValues$ = new BehaviorSubject<{ [id: string]: string }>({});
  typeformRequirements$ = this._event$.pipe(
    map(event => event.requirements.filter(req => req.type === 'typeform') as TypeformBookingRequirement[])
  );

  readonly canBookComplimentary$ = combineLatest([this._user$, this._event$]).pipe(
    switchMap(async ([user, event]) => {
      if (!user || !event) return false;
      return await this.securitySrv.hasAccess(
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.COMPLIMENTARY,
        { userId: user.id, communityId: event.community.id }
      );
    })
  );

  loading = true;
  confirming = false;

  // private readonly emailVerified$ = this._user$.pipe(
  //   map((user) => user?.emailVerified || false)
  // );

  private _firstRun = true;

  startedWithPaymentMethod?: boolean;
  private startedWithEmailVerified?: boolean;

  readonly hasPaymentMethod$ = combineLatest([this._user$, this.refreshed.pipe(startWith(null))]).pipe(
    switchMap(async ([user]) => {
      const paymentMethod = user ? await this.paymentMethodSvc.getDefault(user.id, true) : null;
      if (paymentMethod && paymentMethod.model !== 'bank') return paymentMethod;
      else return null;
    }),
    distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
    tap(pm => this.paymentMethodControl.setValue(pm || null)),
    map(pm => !!pm),
    shareReplay(1)
  );

  paymentMethodControl = this.formBuilder.control([null]);

  readonly booking$ = combineLatest([this._event$, this._user$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([event, user]) =>
      event && user
        ? await this.bookingSvc.paginate(
            new RequestQueryBuilder().search({
              'user.id': user.id,
              'event.id': event.id,
              status: {
                $in: [BookingStatus.CONFIRMED, BookingStatus.CHECKED_IN, BookingStatus.PENDING],
              },
            }),
            event.community.id,
            { limit: 1 }
          )
        : null
    ),
    map(data => data?.items?.[0] || null)
  );

  readonly requiredBoosts$ = combineLatest([this._event$, this._selectedBookingOption$]).pipe(
    map(([event, bookingOption]) => {
      if (!event || !bookingOption) return -1;
      if (bookingOption.id === 'prk_complimentarybooking') return 0;
      return calculateBoosterRequirements(event, bookingOption);
    }),
    shareReplay(1)
  );

  readonly requirements$ = combineLatest([
    this._event$,
    this._selectedBookingOption$,
    this.requiredBoosts$,
    this.hasPaymentMethod$,
    // this.emailVerified$,
    this.userSvc.getUserId(),
  ]).pipe(
    tap(([_event, _bookingOption, _requiredBoosts, hasPaymentMethod, _emailVerified]) => {
      if (this._firstRun) {
        this._firstRun = false;
        this.startedWithPaymentMethod = !!hasPaymentMethod;
        // this.startedWithEmailVerified = !!emailVerified;
      }
    }),
    map(
      ([
        event,
        bookingOption,
        requiredBoosts,
        hasPaymentMethod,
        // emailVerified,
        _currentUserId,
      ]) => {
        return [
          // Cancellation Policy
          ...(bookingOption
            ? [
                {
                  icon: 'confirmation_number',
                  title: 'Your Booking Option',
                  subtitle:
                    bookingOption.title +
                    (bookingOption.bookingWindow
                      ? `(${new MinutesPipe().transform(bookingOption.bookingWindow)})`
                      : '') +
                    (bookingOption.price
                      ? ' | ' + new CurrencyPipe('en-CA').transform(bookingOption.price / 100) + ' Booking Fee'
                      : '') +
                    (requiredBoosts
                      ? ' | ' + requiredBoosts + ' Booking Window Booster' + (requiredBoosts === 1 ? '' : 's')
                      : ''),
                  buttonLabel: 'Review',
                  completed: true,
                  buttonAction: async () =>
                    toPromise(
                      this.matDialog
                        .open(SimpleDialog, {
                          maxWidth: 700,
                          data: {
                            showCloseButton: false,
                            title: 'Your Booking Option',
                            subtitle:
                              bookingOption.title +
                              ' (' +
                              new MinutesPipe().transform(bookingOption.bookingWindow) +
                              ')' +
                              (bookingOption.price
                                ? ' | ' +
                                  new CurrencyPipe('en-CA').transform(bookingOption.price / 100) +
                                  ' Booking Fee'
                                : '') +
                              (requiredBoosts
                                ? ' | ' + requiredBoosts + ' Booking Window Booster' + (requiredBoosts === 1 ? '' : 's')
                                : ''),
                            content: bookingOption.description,
                          } as DialogData,
                        })
                        .afterClosed()
                    ),
                },
              ]
            : []),

          // Cancellation Policy
          ...(bookingOption?.cancellation
            ? [
                {
                  icon: 'info',
                  title: 'Cancellation Policy',
                  subtitle: bookingOptionCancellationPolicyLabel(bookingOption),
                  buttonLabel: 'Review',
                  completed: true,
                  buttonAction: async () =>
                    toPromise(
                      this.matDialog
                        .open(SimpleDialog, {
                          maxWidth: 700,
                          data: {
                            showCloseButton: false,
                            title: 'Cancellation Policy',
                            subtitle: `${event.title} - ${moment(event.startDate).format('MMM Do, hh:mm A')}`,
                            content: getCancellationPolicyInnerHtml(bookingOption),
                          } as DialogData,
                        })
                        .afterClosed()
                    ),
                },
              ]
            : []),

          // Email Verification
          // ...(!this.startedWithEmailVerified && this.user.id === currentUserId
          //   ? [
          //       {
          //         icon: 'email',
          //         title: 'Email Address',
          //         subtitle:
          //           'Please verify your email address prior to booking.',
          //         buttonLabel: emailVerified ? null : 'Verify',
          //         completed: emailVerified,
          //         buttonAction: async () => {
          //           try {
          //             // await this.userSvc.sendEmailVerification();
          //             this.snacks.open(
          //               `Email verification sent to ${this.user.contactEmail}`,
          //               'Ok',
          //               {
          //                 panelClass: 'mat-primary',
          //                 duration: 2500,
          //               }
          //             );
          //           } catch (err) {
          //             console.error(err);
          //             this.snacks.open(
          //               'Something went wrong. Please try again later.',
          //               'Ok',
          //               { duration: 3000 }
          //             );
          //           }
          //         },
          //       },
          //     ]
          //   : []),

          // Payment Method
          ...(!this.startedWithPaymentMethod &&
          bookingOption?.id !== 'prk_complimentarybooking' &&
          (bookingOption.price || bookingOption.cancellationPrice || requiredBoosts)
            ? [
                {
                  icon: 'payment',
                  title: 'Add a Payment Method',
                  subtitle: 'Your card will never be charged without your authorization.',
                  buttonLabel: hasPaymentMethod ? null : 'Add',
                  completed: hasPaymentMethod,
                  buttonAction: async () => {
                    await this.paymentMethodDialogs.addCreditCard(this.user.id);
                    this.user = { ...this.user };
                  },
                },
              ]
            : []),
        ];
      }
    ),
    shareReplay(1)
  );
  bookingOptionsIds?: string[] | [];
  readonly bookingOptions$ = combineLatest([
    this._user$,
    this._event$,
    this.canBookComplimentary$,
    this.allowPendingBooking$,
  ]).pipe(
    switchMap(async ([user, event, _canBookComplimentary, allowPendingBooking]) => {
      return [
        event,
        user && event
          ? await this.eventSvc.getBookingOptionsByEvent(event.id, allowPendingBooking ? user.id : undefined)
          : [],
      ] as [CalendarEvent, (BookingOption & { userPerks?: any })[]];
    }),
    map(([event, bookingOptions]) => {
      const options = bookingOptions
        .filter(opt => isAvailableNow(event, opt))
        .sort((a, b) => {
          // Complimentary at the end
          if (a.id === 'prk_complimentarybooking') return 1;
          if (b.id === 'prk_complimentarybooking') return -1;

          // Free before cost
          if (!!a.price !== !!b.price) return a.price ? 1 : -1;

          // Less costly
          if (a.price && b.price) return a.price - b.price;

          // Unlimited before consumable
          if (a?.userPerks?.hasUnlimited !== b?.userPerks?.hasUnlimited) {
            return a?.userPerks?.hasUnlimited ? -1 : 1;
          }

          return a.title.localeCompare(b.title);
        });
      const filteredOptions = options.some(opt => !opt.price && opt.id !== 'prk_complimentarybooking')
        ? options.filter(opt => !opt.price)
        : options;
      this.bookingOptionsIds = filteredOptions.map(opt => opt.id);

      return filteredOptions;
    }),
    shareReplay(1),
    tap(options => (this.selectedBookingOption = options[0])), // this is the default.
    tap(options => {
      this.message$.next(this.selectedBookingType.label + ' + ' + this.selectedBookingOption?.title || '');
      return (this.bookingOptionsIds = options.map(opt => opt.id));
    })
  );

  complimentaryOption$ = this.canBookComplimentary$.pipe(
    switchMap(async can => (can ? await this.bookingOptionSvc.getComplimentaryOption() : null))
  );

  readonly availableIn$ = combineLatest([this._event$, this._selectedBookingOption$, this.requiredBoosts$]).pipe(
    map(([event, bookingOption, requiredBoosts]) => {
      if (!event || !bookingOption || requiredBoosts === -1) return null;
      if (!bookingOption.maxBoost || requiredBoosts <= bookingOption.maxBoost) return 'Now';
      return moment().to(
        moment(event.startDate)
          .subtract(bookingOption.bookingWindow, 'minutes')
          .subtract(bookingOption.maxBoost === -1 ? 0 : bookingOption.maxBoost, 'days')
      );
    })
  );

  readonly canBook$ = combineLatest([
    this._event$,
    this._selectedBookingOption$,
    this.requiredBoosts$,
    this.requirements$,
  ]).pipe(
    map(([event, bookingOption, requiredBoosts, requirements]) => {
      if (!event || !bookingOption || requiredBoosts === -1) return false;
      if (requiredBoosts && bookingOption.maxBoost === -1) return false;
      if (bookingOption.maxBoost !== -1 && requiredBoosts > (bookingOption.maxBoost || Infinity)) return false;
      if (requiredBoosts && bookingOption.maxBoost === -1) return false;
      return requirements.every(req => req.completed);
    })
  );

  loaded = false;
  readonly seriesAgrUsage$ = combineLatest([this.filters$, this.pagination$, this._event$, this.refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = false))),
    debounceTime(500),
    switchMap(async ([filters, options, event]) => {
      const agreements = await this.agreementUsageSvc.paginateEventAgreementUsages(event.id, options, filters);
      return agreements;
    }),
    tap(agreements => {
      if (this.loaded == false) {
        agreements.items.forEach(agreement => {
          switch (agreement.agreement?.agreementType) {
            case AgreementType.AUTO_CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [true, Validators.requiredTrue];
              this.agreementData$.value.push({
                agreementId: agreement.agreementId,
                signed: true,
              });
              break;
            }
            case AgreementType.CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData$.value.push({
                agreementId: agreement.agreementId,
                signed: false,
              });
              break;
            }
            case AgreementType.DIGITAL_SIGNATURE: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData$.value.push({
                agreementId: agreement.agreementId,
                signed: false,
              });
              break;
            }
          }
        });
      }
      this.loaded = true;
      this.form = this.formGroup.group({ ...this.controlsConfigDetails });
    }),
    tap(({ meta }) => setTimeout(() => (this.paginationMeta = meta))),
    map(({ items }) => items),

    tap(() => setTimeout(() => (this.loading = false)))
  );

  readonly preview$: Observable<BookingPreview | null> = combineLatest([
    this.booking$,
    this._user$,
    this._event$,
    this._selectedBookingOption$,
    this._selectedBookingType$,
    this.requirementValues$,
    this.paymentMethodControl.valueChanges.pipe(startWith(null)),
    this.agreementData$,
  ]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([booking, user, event, bookingOption, bookingType, reqs, pm, agreements]) => {
      const requirements = Object.entries((reqs as { [id: string]: string }) || {}).reduce(
        (acc, [key, value]) => [...acc, { requirementId: key, value }],
        [] as { requirementId: string; value: string }[]
      );
      return !booking && user && event && bookingOption
        ? await this.bookingSvc
            .preview({
              bookingOptionId: bookingOption.id,
              paymentMethodId: pm?.id,
              applyBalance: true,
              eventId: event.id,
              userId: user.id,
              bookedById: user.id,
              useBookedByPerks: true,
              requirements,
              completed: bookingType.value,
              agreements,
            })
            .catch(() => null)
        : null;
    }),
    tap(() => (this.loading = false)),
    tap(async preview => {
      if (preview?.errors.includes('Event already full')) {
        await this.joinWaitlist();
      }
    })
  );

  private readonly _onDestroy$ = new Subject<void>();

  readonly compareBookingOptions = (a?: BookingOption, b?: BookingOption) => a?.id === b?.id;

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._user$.complete();
    this._event$.complete();
    this._selectedBookingOption$.complete();
  }

  setCheck(usageId: string, agreementId: string) {
    const checked = this.form.get(usageId)?.value;
    this.form.patchValue({ [`${usageId}`]: !checked });
    const index = this.agreementData$.value.findIndex(agreement => agreement.agreementId == agreementId);
    this.agreementData$.value[index] = { agreementId, signed: !checked };
    this.agreementData$.next(this.agreementData$.value);
  }

  setSignature(event: string, usageId: string, agreementId: string) {
    const index = this.agreementData$.value.findIndex(agreement => agreement.agreementId == agreementId);
    this.signature = event;
    if (event != '') {
      this.form.patchValue({ [`${usageId}`]: true });
      this.agreementData$.value[index] = { agreementId, signed: true };
    } else {
      this.form.patchValue({ [`${usageId}`]: false });
      this.agreementData$.value[index] = { agreementId, signed: false };
    }
    this.agreementData$.next(this.agreementData$.value);
  }

  requirementFilledIn(requirement: BookingRequirement, value: string) {
    this.requirementValues$.next({
      ...this.requirementValues$.value,
      [requirement.id]: value,
    });
  }

  async confirmRegistration(preview: CoursePreview | null) {
    if (this.confirming) return;

    this.confirming = true;

    try {
      if (!preview) return;

      if (preview.warnings?.length) {
        const dialog = this.matDialog.open(SimpleDialog, {
          data: {
            title: 'Warnings:',
            content: preview.warnings.map(warn => `<p>${warn}</p>`).join(''),
            buttons: [
              { label: 'No, I want to make some changes first', role: 'no' },
              { label: 'Yes, Confirm the Booking', role: 'yes' },
            ],
          } as DialogData,
        });

        if ((await toPromise(dialog.afterClosed())) === 'no') {
          this.confirming = false;
          return;
        }
      }

      const requirements = Object.entries(this.requirementValues$.value || {}).reduce(
        (acc, [key, value]) => [...acc, { requirementId: key, value }],
        [] as { requirementId: string; value: string }[]
      );

      //Create signature the sigunature here
      this.signAgreement();

      const booking = await this.bookingSvc.confirm({
        booking: {
          ...preview.dto,
          requirements,
          agreements: this.agreementData$.value,
        },
        userAgreements: this.agreementData$.value,
        purchaseHash: preview.purchase?.hash,
        bookingHash: preview.hash,
      });

      if (booking.status == BookingStatus.CONFIRMED) {
        if (!this.preventRedirect) await this.router.navigate(['/']);
        this.snacks.open(this.preventRedirect ? 'Attendee added!' : 'Event added to your schedule!', 'Ok', {
          duration: 6000,
          panelClass: 'mat-primary',
        });
        this.booked.emit(booking);
      } else {
        this.snacks.open(
          this.preventRedirect
            ? 'Oops, failed to confirm this booking! ' + (booking.purchases?.[0]?.failureReason || '')
            : 'Oops, failed to confirm your booking! ' + (booking.purchases?.[0]?.failureReason || ''),
          'Ok',
          { duration: 10000, panelClass: 'mat-warn' }
        );
      }
    } catch (err: any) {
      const errMessage: string = err.error.message;
      console.error(err);
      this.snacks.open(
        this.preventRedirect
          ? 'Oops, failed to confirm this booking! ' + errMessage
          : 'Oops, failed to confirm your booking! ' + errMessage,
        'Ok',
        { duration: 10000, panelClass: 'mat-warn' }
      );
      if (errMessage.includes('Event already full')) {
        await this.joinWaitlist();
      }
    }
    this.confirming = false;
  }

  async confirmBooking(preview: BookingPreview | null) {
    if (this.confirming) return;

    this.confirming = true;

    try {
      if (!preview) return;

      if (preview.warnings?.length) {
        const dialog = this.matDialog.open(SimpleDialog, {
          data: {
            title: 'Warnings:',
            content: preview.warnings.map(warn => `<p>${warn}</p>`).join(''),
            buttons: [
              { label: 'No, I want to make some changes first', role: 'no' },
              { label: 'Yes, Confirm the Booking', role: 'yes' },
            ],
          } as DialogData,
        });

        if ((await toPromise(dialog.afterClosed())) === 'no') {
          this.confirming = false;
          return;
        }
      }

      const requirements = Object.entries(this.requirementValues$.value || {}).reduce(
        (acc, [key, value]) => [...acc, { requirementId: key, value }],
        [] as { requirementId: string; value: string }[]
      );

      //Create signature the sigunature here
      this.signAgreement();

      const booking = await this.bookingSvc.confirm({
        booking: {
          ...preview.dto,
          requirements,
          agreements: this.agreementData$.value,
        },
        userAgreements: this.agreementData$.value,
        purchaseHash: preview.purchase?.hash,
        bookingHash: preview.hash,
      });

      if (booking.status == BookingStatus.CONFIRMED || booking.status == BookingStatus.PENDING) {
        if (!this.preventRedirect) await this.router.navigate(['/']);
        this.snacks.open(this.preventRedirect ? 'Attendee added!' : 'Event added to your schedule!', 'Ok', {
          duration: 6000,
          panelClass: 'mat-primary',
        });
        this.booked.emit(booking);
      } else {
        this.snacks.open(
          this.preventRedirect
            ? 'Oops, failed to confirm this booking! ' + (booking.purchases?.[0]?.failureReason || '')
            : 'Oops, failed to confirm your booking! ' + (booking.purchases?.[0]?.failureReason || ''),
          'Ok',
          { duration: 10000, panelClass: 'mat-warn' }
        );
      }
    } catch (err: any) {
      const errMessage: string = err.error.message;
      console.error(err);
      this.snacks.open(
        this.preventRedirect
          ? 'Oops, failed to confirm this booking! ' + errMessage
          : 'Oops, failed to confirm your booking! ' + errMessage,
        'Ok',
        { duration: 10000, panelClass: 'mat-warn' }
      );
      if (errMessage.includes('Event already full')) {
        await this.joinWaitlist();
      }
    }
    this.confirming = false;
  }

  async joinWaitlist() {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        title: 'Join Waitlist',
        showCloseButton: false,
        subtitle: `${this.event.title} - ${moment(this.event.startDate).format('ll hh:mm A')}`,
        content: 'The event is currently fully booked. Join the waitlist to be notified as soon as a spot opens up.',
        buttons: [
          { label: "No, I don't want to join the waitlist", role: 'no' },
          { label: 'Yes, add me to the waitlist', role: 'yes' },
        ],
      } as DialogData,
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const userId = await toPromise(this.userSvc.getUserId());
      if (!userId) return;

      await this.bookingSvc.joinWaitlist({ userId, eventId: this.event.id });
      if (!this.preventRedirect) await this.router.navigate(['/']);
      this.snacks.open('Added to waitlist!', 'Ok', {
        duration: 2500,
        panelClass: 'mat-primary',
      });
    }
  }

  recievePerk(perk: BookingOption) {
    this.selectedBookingOption = perk;
    this.message$.next(this.selectedBookingType.label + ' + ' + this.selectedBookingOption?.title);
  }
  async signAgreement() {
    if (this.user && this.signature) {
      let signature: any = null;

      try {
        signature = await this.signatureSvc.getOne(this.user?.id);
      } catch (err) {
        console.log('No signature found for user, creating default now');
      }

      if (signature) {
        if (this.signature !== signature.signature) await this.signatureSvc.update(this.user?.id, this.signature);
      } else
        await this.signatureSvc.create({
          userId: this.user.id,
          signature: this.signature,
        });
    }
  }
}
