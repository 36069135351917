<mat-form-field appearance="fill" [matMenuTriggerFor]="preview">
  <button mat-icon-button matPrefix style="margin-right: 4px">
    <mat-icon>shopping_bag</mat-icon>
  </button>
  <mat-label>Your Cart</mat-label>
  <input matInput [value]="cart.total | currency" />
  <mat-icon matSuffix>chevron_right</mat-icon>
</mat-form-field>

<mat-menu #preview="matMenu"> <greco-cart-preview [cart]="cart"></greco-cart-preview> </mat-menu>
