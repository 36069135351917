<div>
  <div style="display: flex; align-items: center; justify-content: space-between">
    <h2 style="margin-bottom: 2px; margin-right: 32px">{{data?.title || 'Delete'}}</h2>
    <button type="button" mat-icon-button (click)="dismiss('cancel')">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div
    style="
      border-radius: 4px;
      margin: 16px 0;
      padding: 8px 16px;
      background-color: rgba(206, 14, 46, 0.3);
      color: #ce0e2d;
      display: flex;
      align-items: center;
    "
  >
    <mat-icon>warning</mat-icon>
    <p style="margin-bottom: 0; margin-left: 16px">{{data?.message}}</p>
  </div>

  <div *ngIf="data?.objectDescription">
    <p><small>{{ (data?.objectType || 'object') | titlecase }}</small></p>
    <p>{{data.objectDescription}}</p>
  </div>

  <div style="margin-top: 16px; display: flex; align-items: center; justify-content: flex-end">
    <button mat-button (click)="dismiss('cancel')">Cancel</button>
    <button mat-flat-button color="primary" style="margin-left: 8px" (click)="dismiss('confirm')">Process</button>
  </div>
</div>
