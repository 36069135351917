import { Injectable } from '@angular/core';
import { ToggleFilter } from '@greco/ngx-filters';
import { ResourcesService } from '../../../services';

@Injectable({ providedIn: 'any' })
export class ResourceEventRequestSubstitutionFilter extends ToggleFilter {
  constructor(public resourceService: ResourcesService) {
    super('ResourceEventRequestSubstitutionFilter', {
      label: 'Requesting Substitution',
      description: '',
      shortLabel: 'Requesting Substitution',
      properties: ['requestingSubstitution'],
    });
  }

  public communityId!: string;
  getValueLabel(value: any): string {
    return value ? 'Requesting Substitution' : 'No Substitution Needed';
  }
  getValueOptions(search?: string): any[] | Promise<any[]> {
    if (search?.toLowerCase() === 'req') {
      return [true];
    } else if (search?.toLowerCase() === 'all') {
      return [false];
    } else {
      return [];
    }
  }

  serializeValue(value: any): string {
    return value.toString();
  }
  deserializeValue(serializedValue: string) {
    return Boolean(serializedValue);
  }
}
