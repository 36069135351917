<!-- <greco-collapsible-section
  id="default-categories-section"
  [header]="{title:'Default Sale Categories', icon:'information'}"
  [expanded]="true"
>
  <greco-table *grecoLet="accountId$ | async as accountId" [data]="forms">
    <mat-icon *grecoTableCol="''; let form; fitContent: true">{{form.matIcon}}</mat-icon>

    <p *grecoTableCol="'Purchase Type'; let form">{{form.label}}</p>

    <ng-container *grecoTableCol="'Sale Category';">
      <mat-form-field appearance="standard" class="default-sale-category-mat-form-field">
        <greco-sale-category-input placeholder="Sale Category"></greco-sale-category-input>
      </mat-form-field>
    </ng-container>
  </greco-table>

  <div *grecoLet="accountId$ | async as accountId" style="display: flex; gap: 8px; flex-direction: column">
    <div *ngFor="let form of forms">
      <greco-default-sale-category
        *ngIf="accountId"
        [defaultCategoryForm]="form"
        [accountId]="accountId"
        style="width: 100%"
      ></greco-default-sale-category>
    </div>
  </div>
</greco-collapsible-section> -->

<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)=" filters$.next($event[1]); onFilterApplied()"
>
  <div style="display: flex; align-items: center; justify-content: flex-end; height: 32px">
    <mat-menu #categoryMenu>
      <ng-content></ng-content>

      <button *ngIf="(canCreate$| async)" mat-menu-item color="primary" (click)="create()">
        <mat-icon>add</mat-icon>
        <span>New Category</span>
      </button>

      <button mat-menu-item (click)="openDefaultSaleCategoriesDialog()">
        <mat-icon>task_alt</mat-icon>
        <span>Manage Defaults</span>
      </button>
      <button
        *ngIf="canUpdate$ | async"
        mat-menu-item
        (click)="syncDefaults()"
        matTooltip="Sync current defaults on existing (blank) purchase item categories"
      >
        <mat-icon>sync</mat-icon>
        <span>Sync Defaults</span>
      </button>
    </mat-menu>

    <button mat-stroked-button [matMenuTriggerFor]="categoryMenu" color="primary" style="height: 32px">
      Quick Actions
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
</greco-filter-bar>

<div *ngIf="canRead$ | async">
  <greco-sale-categories-table
    #categoriesTable
    [accountId]="accountId$ | async"
    [queryBuilder]="filters$ | async"
    (rowClick)="navigate($event)"
  ></greco-sale-categories-table>
</div>
