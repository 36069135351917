import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'greco-multi-avatar',
  templateUrl: './multi-avatar.component.html',
  styleUrls: ['./multi-avatar.component.scss'],
})
export class MultiAvatarComponent {
  constructor(private ref: ElementRef) {}

  /** @hidden */
  URLS: string[] = [];

  @Input()
  size = 64;

  @HostBinding('style.min-width.px')
  @HostBinding('style.min-height.px')
  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  get hostSize() {
    return Math.max(0, this.size - 8);
  }

  @Input() color = 'primary';
  @HostBinding('style.border-color') get borderColor() {
    switch (this.color) {
      case 'primary':
      case 'accent':
        return `var(--${this.color}-color)`;
      default:
        return this.color;
    }
  }

  private _images: string[] = [];
  @Input() get images() {
    return this._images;
  }
  set images(images: string[]) {
    this._images = images;
    this.updateURLS();
  }

  private updateURLS() {
    this.URLS = (this.images || []).filter(img => typeof img === 'string' && !!img).slice(0, 4);
    if (!this.URLS.length) this.URLS = ['assets/lf3/icon_square_pad.png'];

    const imageContainer = this.ref.nativeElement.querySelector('.image-container') as HTMLDivElement;
    if (imageContainer) imageContainer.style.gridTemplateColumns = this.URLS.length <= 1 ? '1fr' : '1fr 1fr';
  }
}
