import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CountrySelectModule } from '../country-select/country-select.module';
import { AddressInputComponent } from './address-input.component';

export * from './address-input.component';

@NgModule({
  declarations: [AddressInputComponent],
  exports: [AddressInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    GooglePlaceModule,
    MatDividerModule,
    CountrySelectModule.forRoot('en')
  ]
})
export class AddressInputModule {}
