<ng-container *ngIf="link?.startsWith('http'); else internal">
  <a
    #navLink
    [href]="link"
    target="_blank"
    [class]="'nav-link' + (color ? ' theme-' + color : '') + (full ? ' full' : '')"
    [color]="color"
    mat-button
    [matTooltip]="tooltip || ''"
    [matTooltipShowDelay]="tooltipDelay"
    matTooltipPosition="right"
    [disabled]="disabled"
    (click)="disabled && disabledAction ? disabledAction() : $event.stopImmediatePropagation()"
  >
    <mat-icon *ngIf="icon">{{
      navLink._elementRef.nativeElement.classList.contains('active') ? activeIcon || icon : icon
    }}</mat-icon>
    <ng-container *ngIf="full">{{ label }}</ng-container>
  </a>
</ng-container>
<ng-template #internal>
  <a
    #navLink
    [class]="'nav-link' + (color ? ' theme-' + color : '') + (full ? ' full' : '')"
    [color]="color"
    mat-button
    [routerLink]="disabled ? '/' : link"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: exact }"
    [matTooltip]="tooltip || ''"
    [matTooltipShowDelay]="tooltipDelay"
    matTooltipPosition="right"
    [disabled]="disabled"
    (click)="disabled && disabledAction ? disabledAction() : $event.stopImmediatePropagation()"
  >
    <mat-icon *ngIf="icon">{{
      navLink._elementRef.nativeElement.classList.contains('active') ? activeIcon || icon : icon
    }}</mat-icon>
    <ng-container *ngIf="full">{{ label }}</ng-container>
  </a>
</ng-template>
