import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'greco-confirm-add-attendee',
  templateUrl: './confirm-add-attendee.dialog.html',
  styleUrls: ['./confirm-add-attendee.dialog.scss'],
})
export class ConfirmAddAttendeeDialog {
  @Input() data: ConfirmAddAttendeeDialogData;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfirmAddAttendeeDialogData,
    private ref: MatDialogRef<ConfirmAddAttendeeDialog>
  ) {
    this.data = { ...data };
  }

  dismiss(role: 'confirm' | 'cancel') {
    this.ref.close(role);
  }
}

export interface ConfirmAddAttendeeDialogData {
  title: string;
  userName: string;
  eventTitle: string;
}
