import { SecurityResource } from '@greco/security';

export const COMMUNITY_VIDEOS_SECURITY_RESOURCE = 'community_videos';

export enum CommunityVideoSecurityResourceAction {
  READ = 'READ',
  UPLOAD = 'UPLOAD',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  ARCHIVE = 'ARCHIVE',
}

export const CommunityVideoSecurityResource: SecurityResource = {
  key: COMMUNITY_VIDEOS_SECURITY_RESOURCE,
  module: 'videos',

  title: 'Community Videos',
  description: 'Manage community videos',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CommunityVideoSecurityResourceAction.READ,
      title: 'Read videos in a community',
      description: 'Ability to read videos in a community',
    },
    {
      key: CommunityVideoSecurityResourceAction.UPLOAD,
      title: 'Upload videos to community',
      description: 'Ability to upload videos to a community',
    },
    {
      key: CommunityVideoSecurityResourceAction.UPDATE,
      title: 'Update videos in community',
      description: 'Ability to update videos in a community',
    },
    {
      key: CommunityVideoSecurityResourceAction.REMOVE,
      title: 'Remove videos from community',
      description: 'Ability to remove videos from a community',
    },
    {
      key: CommunityVideoSecurityResourceAction.ARCHIVE,
      title: 'Archive videos in community',
      description: 'Ability to archive videos in a community',
    },
  ],
};
