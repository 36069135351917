import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'grecoMinutes' })
export class MinutesPipe implements PipeTransform {
  transform(minutes: number) {
    if (!minutes) return '-';

    const hours = (minutes || 0) / 60;
    const days = hours / 24;

    if (!days.toString().includes('.')) return `${days} Day${days > 1 ? 's' : ''}`;
    if (!hours.toString().includes('.')) return `${hours} Hour${hours > 1 ? 's' : ''}`;
    return `${minutes} Minute${minutes > 1 ? 's' : ''}`;
  }
}
