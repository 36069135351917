import { Pipe, PipeTransform } from '@angular/core';
import { VariantPerk, VariantPerkExpiryEvent, VariantPerkGranted } from '@greco/sales-products';

@Pipe({
  name: 'perks',
})
export class VariantPerkPipe implements PipeTransform {
  transform(variantPerk: VariantPerk, arg: 'granted' | 'expiry' | 'expiryDelay'): string {
    if (arg === 'granted') return this._granted(variantPerk.granted);
    else if (arg === 'expiryDelay')
      return this._expiryDelay(variantPerk.expiryEvent, variantPerk.expiryNotificationDelay);
    else return this._expiry(variantPerk.expiryEvent, variantPerk.expiryDelay);
  }

  private _expiry(expiryEvent: VariantPerkExpiryEvent[], expiryDelay: number | null): string {
    let result = '';
    if (expiryEvent?.length) result += expiryEvent.map(elem => `on ${elem}`).join(', ');
    if (expiryEvent?.length && expiryDelay) result += ', ';
    if (expiryDelay) result += `${expiryDelay} days after granted`;
    if (!expiryEvent?.length && !expiryDelay) result += '-';
    return result;
  }

  private _expiryDelay(expiryEvent: VariantPerkExpiryEvent[], expiryDelay: number | null): string {
    let result = '';
    if (expiryEvent?.length) result += expiryEvent.map(elem => `on ${elem}`).join(', ');
    if (expiryEvent?.length && expiryDelay) result += ', ';
    if (expiryDelay) result += `${expiryDelay} days before expiry`;
    if (!expiryEvent?.length && !expiryDelay) result += '-';
    return result;
  }

  private _granted(granted: VariantPerkGranted[]): string {
    return granted
      .map(
        elem =>
          `on ${elem === VariantPerkGranted.Renewal ? 'subscription ' : ''}${elem} ${
            elem === VariantPerkGranted.Initial ? 'purchase' : ''
          }`
      )
      .join(', ');
  }
}
