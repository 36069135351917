<ng-container *ngIf="canRead$ | async">
  <ng-container *grecoLet="(pendingRequests$ | async) || [] as pendingRequests">
    <ng-container *grecoLet="(pendingOwned$ | async) || [] as pendingOwned">
      <greco-collapsible-section
        *ngIf="pendingRequests?.length || pendingOwned?.length"
        [header]="{
          title: 'Requests',
          icon: pendingRequests?.length ? 'email' : 'drafts',
          badge: $any(pendingRequests?.length)
        }"
        class="linked-accounts-section"
        [disabled]="false"
        [expanded]="expanded"
      >
        <greco-linked-accounts-requests-table-mobile
          [user]="user"
          [ownedLinks]="pendingOwned || []"
          [grantedLinks]="pendingRequests || []"
          (refresh)="refresh()"
          [loading]="loading"
        ></greco-linked-accounts-requests-table-mobile>
      </greco-collapsible-section>
    </ng-container>

    <!-- Managed Accounts and non-mobile requests -->
    <greco-collapsible-section
      [header]="{ title: 'Managed Accounts', icon: 'supervisor_account' }"
      class="linked-accounts-section"
      [expanded]="expanded"
    >
      <button
        *ngIf="(canCreate$ | async) && user.email"
        mat-flat-button
        header
        color="primary"
        (click)="$event.stopImmediatePropagation(); newLink()"
      >
        <mat-icon>group_add</mat-icon>
        Add New
      </button>

      <mat-tab-group>
        <!-- Managed -->
        <mat-tab label="Managed" *ngIf="user.email">
          <greco-linked-accounts-owned-table
            *grecoLet="(ownedLinks$ | async) || [] as ownedLinks"
            [user]="user"
            [ownedLinks]="ownedLinks || []"
            (refresh)="refresh()"
            [loading]="loading"
            [communityId]="communityId"
          ></greco-linked-accounts-owned-table>
        </mat-tab>

        <!-- Granted -->
        <mat-tab label="Granted">
          <greco-linked-accounts-granted-table
            *grecoLet="(grantedLinks$ | async) || [] as grantedLinks"
            [user]="user"
            [grantedLinks]="grantedLinks || []"
            [communityId]="communityId"
            (refresh)="refresh()"
            [loading]="loading"
          ></greco-linked-accounts-granted-table>
        </mat-tab>
      </mat-tab-group>
    </greco-collapsible-section>
  </ng-container>
</ng-container>
