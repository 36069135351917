import { SecurityResource } from '@greco/security';

export enum SubscriptionResourceAction {
  CREATE = 'CREATE',
  READ = 'READ',
  SEE_STATS = 'SEE_STATS',
  UPDATE = 'UPDATE',
  CANCEL = 'CANCEL',
  PRORATE = 'PRORATE',
  START_LATER = 'START_LATER',
  IGNORE_ENROLMENT_FEE = 'IGNORE_ENROLMENT_FEE',
  IGNORE_PAYMENT_FREEZE = 'IGNORE_PAYMENT_FREEZE',
  MANAGE_SOLD_BY = 'MANAGE_SOLD_BY',
  MANAGE_REFERRED_BY = 'MANAGE_REFERRED_BY',
  MANAGE_TRANSFERRED_FROM = 'MANAGE_TRANSFERRED_FROM',
  MANAGE_MINIMUM_COMMITMENT = 'MANAGE_MINIMUM_COMMITMENT',
  BULK_START = 'BULK_START',
  BULK_CANCEL = 'BULK_CANCEL',
  BULK_UPDATE = 'BULK_UPDATE',
  ZERO_DOLLAR_SUBSCRIPTION = 'ZERO_DOLLAR_SUBSCRIPTION',
  FREEZE = 'FREEZE',
}

export const SubscriptionResource: SecurityResource = {
  key: 'finance_purchasing_subscription',
  module: 'Finance',

  title: 'Subscription',
  description: 'Manage subscriptions',

  context: class {
    id?: string;
    userId?: string;
    accountId?: string;
    createdById?: string;
  },

  actions: [
    {
      key: SubscriptionResourceAction.CREATE,
      title: 'Create Subscription',
      description: 'Ability to create subscription',
    },
    {
      key: SubscriptionResourceAction.READ,
      title: 'Read',
      description: 'Ability to read/view subscriptions',
    },
    {
      key: SubscriptionResourceAction.SEE_STATS,
      title: 'See Stats',
      description: 'Ability to see stats at the top of the subscriptions page',
    },
    {
      key: SubscriptionResourceAction.UPDATE,
      title: 'Update Subscription',
      description: 'Ability to update subscription',
    },
    {
      key: SubscriptionResourceAction.CANCEL,
      title: 'Cancel',
      description: 'Ability to cancel subscriptions',
    },
    {
      key: SubscriptionResourceAction.PRORATE,
      title: 'Prorate Subscription',
      description: 'Ability to prorate subscription',
    },
    {
      key: SubscriptionResourceAction.START_LATER,
      title: 'Start later',
      description: 'Ability to start a subscription later',
    },
    {
      key: SubscriptionResourceAction.IGNORE_ENROLMENT_FEE,
      title: 'Ignore initiation fee',
      description: 'Ability to start a subscription without the configured initiation fee',
    },
    {
      key: SubscriptionResourceAction.IGNORE_PAYMENT_FREEZE,
      title: 'Ignore payment freeze',
      description: 'Ability to start a subscription and ignore the payment freeze',
    },
    {
      key: SubscriptionResourceAction.MANAGE_SOLD_BY,
      title: 'Manage Sold By',
      description: "Ability to manage (set/update) the 'Sold By' property of subscriptions",
    },
    {
      key: SubscriptionResourceAction.MANAGE_REFERRED_BY,
      title: 'Manage Referred By',
      description: "Ability to manage (set/update) the 'Referred By' property of subscriptions",
    },
    {
      key: SubscriptionResourceAction.MANAGE_TRANSFERRED_FROM,
      title: 'Manage Transferred From',
      description: "Ability to manage (set/update) the 'Transferred From' property of subscriptions",
    },
    {
      key: SubscriptionResourceAction.MANAGE_MINIMUM_COMMITMENT,
      title: 'Manage Minimum Commitment',
      description: "Ability to manage (set/update) the 'Minimum Commitment' property of subscriptions",
    },
    {
      key: SubscriptionResourceAction.BULK_START,
      title: 'Bulk Start',
      description: 'Ability to bulk start subscriptions',
    },
    {
      key: SubscriptionResourceAction.BULK_CANCEL,
      title: 'Bulk Cancel',
      description: 'Ability to bulk cancel subscriptions',
    },
    {
      key: SubscriptionResourceAction.BULK_UPDATE,
      title: 'Bulk Update',
      description: 'Ability to bulk update subscriptions',
    },
    {
      key: SubscriptionResourceAction.ZERO_DOLLAR_SUBSCRIPTION,
      title: 'Skip Payment Method ($0 Subscription)',
      description: 'Ability to create $0 subscriptions without a payment method',
    },
    {
      key: SubscriptionResourceAction.FREEZE,
      title: 'Freeze/Unfreeze Subscriptions',
      description: 'Ability to freeze and unfreeze subscriptions',
    },
  ],
};
