import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarUtils,
  CalendarWeekViewComponent,
  DateAdapter,
  getWeekViewPeriod,
} from 'angular-calendar';
import { GetWeekViewArgs, WeekView } from 'calendar-utils';

interface GroupedDayView extends WeekView {
  groups: string[];
  groupsMetadata: string[];
}

interface GetGroupedDayViewArgs extends GetWeekViewArgs {
  groupBy: (evt: CalendarEvent) => { label: string; metadata: string }[];
  sortGroups?: (a: string, b: string) => 1 | -1;
}

@Injectable()
export class GroupedDayViewCalendarUtils extends CalendarUtils {
  getWeekView({ groupBy, sortGroups, ...args }: GetGroupedDayViewArgs): GroupedDayView {
    const events = args.events || [];
    if (!events.length) return { ...super.getWeekView(args), groups: [], groupsMetadata: [] };

    const groups: string[] = [];
    const groupsMetadata: string[] = [];

    const grouped = events.reduce((acc, event) => {
      const gs = groupBy(event);
      gs.forEach(group => {
        if (!groups.includes(group.label)) {
          groups.push(group.label);
          groupsMetadata.push(group.metadata);
        }
        acc[group.label] = [...(acc[group.label] || []), event];
      });
      return acc;
    }, {} as { [group: string]: CalendarEvent[] });

    const { period } = super.getWeekView(args);
    const view: GroupedDayView = { period, groups, groupsMetadata, allDayEventRows: [], hourColumns: [] };

    if (sortGroups) groups.sort((a, b) => sortGroups(a, b));

    groups.forEach((group, groupIndex) => {
      const events = grouped[group];
      const columnView = super.getWeekView({ ...args, events });

      view.hourColumns.push(columnView.hourColumns[0]);
      columnView.allDayEventRows.forEach(({ row }, rowIndex) => {
        view.allDayEventRows[rowIndex] = view.allDayEventRows[rowIndex] || { row: [] };
        view.allDayEventRows[rowIndex].row.push({ ...row[0], offset: groupIndex, span: 1 });
      });
    });

    return view;
  }
}

@Component({
  selector: 'greco-grouped-day-view',
  templateUrl: './grouped-day-view.component.html',
  styleUrls: ['./grouped-day-view.component.scss'],
  providers: [GroupedDayViewCalendarUtils],
})
export class GroupedDayViewComponent extends CalendarWeekViewComponent implements OnChanges {
  constructor(
    cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter,
    @Inject(LOCALE_ID) locale: string,
    protected utils: GroupedDayViewCalendarUtils
  ) {
    super(cdr, utils, locale, dateAdapter);
  }

  daysInWeek = 1;
  view = super.view as GroupedDayView;

  @Input() groupBy!: (evt: CalendarEvent) => { label: string; metadata: string }[];
  @Input() sortGroups?: (a: string, b: string) => -1 | 1;

  @Output() hourSegmentClicked = new EventEmitter<{
    date: Date;
    sourceEvent: MouseEvent;
    group?: { label: string; metadata: string };
  }>();

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.groupBy || changes.sortGroups) {
      this.refreshBody();
      this.emitBeforeViewRender();
    }
  }

  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return super.getDayColumnWidth(eventRowContainer);
    // return Math.floor(eventRowContainer.offsetWidth / (this.view.groups.length || 1));
  }

  protected getWeekView(events: CalendarEvent[]) {
    return this.utils.getWeekView({
      events,
      groupBy: this.groupBy,
      viewDate: this.viewDate,
      precision: this.precision,
      excluded: this.excludeDays,
      sortGroups: this.sortGroups,
      weekendDays: this.weekendDays,
      absolutePositionedEvents: true,
      weekStartsOn: this.weekStartsOn,
      hourSegments: this.hourSegments,
      segmentHeight: this.hourSegmentHeight,
      dayEnd: { hour: this.dayEndHour, minute: this.dayEndMinute },
      dayStart: { hour: this.dayStartHour, minute: this.dayStartMinute },
      ...getWeekViewPeriod(this.dateAdapter, this.viewDate, this.weekStartsOn, this.excludeDays, this.daysInWeek),
    });
  }
}
