<div class="split-div">
  <div #primary class="mat-button mat-primary" style="display: none"></div>

  <div class="room-image-container">
    <div class="room-image" [style.cursor]="cursorStyle" [style.display]="room?.imageURL ? '' : 'none'">
      <ng-container *grecoLet="drawSpots$ | async"></ng-container>
      <canvas #canvas (click)="canvasClick($event)" (mousemove)="onCanvas($event)" (mouseleave)="hoverSpot()"> </canvas>
    </div>
  </div>

  <ng-container *ngIf="room && room.spots">
    <mat-radio-group (change)="value = $event.value" style="flex: 1">
      <greco-table
        *grecoLet="bookedSpotIds$ | async as bookedSpotIds"
        [data]="room.spots"
        [hideEmptyPlaceholder]="true"
        (mouseleave)="hoverSpot()"
        (rowClick)="!bookedSpotIds?.includes($event.id) ? selectSpot($event) : ''"
      >
        <ng-container *grecoTableCol="''; fitContent: true; let spot; let index = index">
          <span [style.color]="bookedSpotIds?.includes(spot.id) ? 'lightgray' : ''" (mouseenter)="hoverSpot(spot)">
            {{ spot.spotNumber }}
          </span>
        </ng-container>

        <ng-container *grecoTableCol="'Name'; fitContent: true; let spot; let index = index">
          <mat-form-field style="max-width: 20vw" appearance="standard" (mouseenter)="hoverSpot(spot)">
            <input
              *grecoLet="bookedSpotIds?.includes(spot.id) as bookedSpot"
              #nameInput
              matInput
              type="text"
              readonly="true"
              [style.color]="bookedSpotIds?.includes(spot.id) ? 'lightgray' : ''"
              [placeholder]="'Spot ' + spot.spotNumber"
              [value]="spot.name"
            />
          </mat-form-field>
        </ng-container>

        <ng-container *grecoTableCol="'Description'; let spot; let index = index">
          <mat-form-field appearance="standard" (mouseenter)="hoverSpot(spot)">
            <input
              *grecoLet="bookedSpotIds?.includes(spot.id) as bookedSpot"
              #descriptionInput
              matInput
              type="text"
              readonly="true"
              [style.color]="bookedSpot ? 'lightgray' : ''"
              [placeholder]="spot.name || 'Spot ' + spot.spotNumber"
              [value]="spot.description"
            />
          </mat-form-field>
        </ng-container>

        <ng-container *grecoTableCol="'Select Spot'; fitContent: true; stickyEnd: true; let spot; let index = index">
          <mat-radio-button
            *ngIf="!bookedSpotIds?.includes(spot.id)"
            color="primary"
            [value]="spot.id"
            [checked]="spot.id === selectedSpotId"
            (click)="selectSpot(spot); $event.preventDefault()"
            (mouseenter)="hoverSpot(spot)"
          >
          </mat-radio-button>
        </ng-container>
      </greco-table>
    </mat-radio-group>
  </ng-container>
</div>
