import { BaseDto } from '@greco/util-dto';
import { IsArray, IsEnum, IsObject } from 'class-validator';
import { Product } from '../product';
import { AddonType, ProductAddon } from '../product-addon';

export class UpgradeAddonDto extends BaseDto {
  @IsEnum(AddonType)
  type: AddonType.Upgrade;

  @IsArray()
  @IsObject({ each: true })
  upgradeCandidates: Product[];
}

export interface UpgradeProductAddon extends ProductAddon {
  upgradeCandidates: Product[];
}
