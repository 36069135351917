import { animate, group, query as q, style, transition, trigger } from '@angular/animations';
import { Pipe, PipeTransform } from '@angular/core';

const query = (style, animate, optional = { optional: true }) => q(style, animate, optional);

const fade = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
  query(':enter', [style({ opacity: 0 })]),
  group([
    query(':leave', [animate('0.3s ease-out', style({ opacity: 0 }))]),
    query(':enter', [style({ opacity: 0 }), animate('0.3s ease-out', style({ opacity: 1 }))])
  ])
];

const fadeInFromDirection = direction => [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
  group([
    query(':enter', [
      style({
        transform: `translateX(${direction === 'backward' ? '-' : ''}15%)`,
        opacity: 0
      }),
      animate('0.3s ease-out', style({ transform: 'translateX(0%)', opacity: 1 }))
    ]),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('0.3s ease-out', style({ opacity: 0 }))])
  ])
];

export const routerTransition = trigger('routerTransition', [
  transition('* => inital', fade),
  transition('* => section', fade),
  transition('* => forward', fadeInFromDirection('forward')),
  transition('* => backward', fadeInFromDirection('backward'))
]);

@Pipe({ name: 'routeTransition' })
export class RouteTransitionPipe implements PipeTransform {
  private previousPath = '';
  transform(path: string) {
    let transitionName = 'section';
    console.log({ path });
    const isSame = this.previousPath === path;
    const isBackward = this.previousPath.startsWith(path);
    const isForward = path.startsWith(this.previousPath);

    if (isSame) {
      transitionName = 'none';
    } else if (isBackward && isForward) {
      transitionName = 'initial';
    } else if (isBackward) {
      transitionName = 'backward';
    } else if (isForward) {
      transitionName = 'forward';
    }

    this.previousPath = path;

    return transitionName;
  }
}
