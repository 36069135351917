<ng-container *grecoLet="canDelete$ | async as canDelete">
  <greco-table [data]="(calendars$ | async) || []" [highlight]="true" (rowClick)="rowClick.emit($event)">
    <ng-container *grecoTableCol="''; let calendar; fitContent: true">
      <img class="calendar-image" [matTooltip]="calendar.id" *ngIf="calendar.imageUrl" [src]="calendar.imageUrl" />
      <mat-icon *ngIf="calendar.deleted" [matTooltip]="calendar.id" color="warn">
        {{ calendar.icon || 'event_busy' }}
      </mat-icon>

      <mat-icon *ngIf="!calendar.deleted" [matTooltip]="calendar.id">
        {{ calendar.icon || 'calendar_today' }}
      </mat-icon>
    </ng-container>

    <ng-container *grecoTableCol="'Title'; let calendar; fitContent: true">
      <div [style.font-style]="calendar.deleted ? 'italic' : 'normal'">
        {{ calendar.deleted ? '[Archived] ' : '' }}{{ calendar.title }}
      </div>
    </ng-container>

    <ng-container *grecoTableCol="'Visibility'; let calendar; fitContent: true">
      {{ calendar.private ? 'Private' : '-' }}
    </ng-container>

    <ng-container *grecoTableCol="'Templates'; let calendar">
      <mat-chip-list>
        <mat-chip *ngFor="let template of calendar.eventTemplates">{{ template.title }}</mat-chip>
      </mat-chip-list>
    </ng-container>

    <ng-container *grecoTableCol="'Staff'; let calendar">
      <mat-chip-list>
        <mat-chip *ngFor="let staff of calendar.staff" style="padding-left: 6px">
          <div style="width: 24px; height: 24px">
            <greco-smart-img [src]="staff?.photoURL || 'assets/lf3/icon_square_pad.png'" [round]="true">
            </greco-smart-img>
          </div>

          <!-- Name -->
          <span style="margin-left: 6px">{{ staff?.friendlyName || staff?.displayName || staff?.email }}</span>
        </mat-chip>
      </mat-chip-list>
    </ng-container>

    <ng-container *ngIf="canDelete">
      <ng-container *grecoTableCol="''; let calendar; fitContent: true; stickyEnd: true">
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <ng-container let calendar>
            <button mat-menu-item (click)="calendar.deleted ? restoreCalendar(calendar) : archiveCalendar(calendar)">
              <mat-icon>{{ calendar.deleted ? 'restore_from_trash' : 'archive' }}</mat-icon>
              <span>{{ calendar.deleted ? 'Restore' : 'Archive' }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
  </greco-table>
</ng-container>

<mat-paginator
  *ngIf="metadata?.totalItems"
  showFirstLastButtons
  [pageSizeOptions]="[10, 20, 50]"
  [length]="metadata!.totalItems || 0"
  [pageSize]="metadata!.itemsPerPage || 10"
  (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
