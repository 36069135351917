import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import type { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ReplaySubject } from 'rxjs';
import type { Stripe as StripeAPI } from 'stripe';

export const STRIPE_PUBLISHABLE_KEY = new InjectionToken<string>('STRIPE_PUBLISHABLE_KEY');
export interface PadMetaData {
  payment_method: string;
  status: string;
  next_action?: string;
}

export interface ContentData {
  title: string;
  content: string;
}
@Injectable()
export class StripeJsService implements OnDestroy {
  private stripe: Stripe | null = null;
  private _loaded = new ReplaySubject<void>(1);

  constructor(@Inject(STRIPE_PUBLISHABLE_KEY) publishableKey: string, private http: HttpClient) {
    loadStripe(publishableKey).then(instance => {
      this.stripe = instance;
      this._loaded.next();
    });
  }

  async elements() {
    await toPromise(this._loaded);
    return this.stripe?.elements();
  }

  private async instance() {
    await toPromise(this._loaded);
    return this.stripe;
  }

  async createCreditCardPaymentMethod(
    id: string, //user or account
    type: string,
    card: StripeCardNumberElement,
    cardHolderName: string
  ): Promise<{ externalId: string; label: string; details?: string; model: string; cardHolderName: string }> {
    const clientSecret = await this.getSetupIntentClientSecret(id, type);
    const paymentMethodId = await this.confirmCardSetup(card, clientSecret);
    const paymentMethod = await this.getPaymentMethodData(paymentMethodId);

    return {
      externalId: paymentMethod.id,
      model: paymentMethod.card?.brand || 'credit_card',
      label: paymentMethod.card ? '**** ' + paymentMethod.card.last4 : 'Credit Card',
      details: paymentMethod.card
        ? `${paymentMethod.card.exp_month.toString().padStart(2, '0')}/${paymentMethod.card.exp_year
            .toString()
            .slice(2)}`
        : '',
      cardHolderName,
    };
  }

  private async getSetupIntentClientSecret(
    id: string, //user or account
    type: string
  ): Promise<string> {
    const intent = await toPromise(
      this.http.get<{ client_secret: string }>('/api/stripe/setupIntent/' + id, { params: { type } })
    );
    return intent?.client_secret;
  }

  private async confirmCardSetup(card: StripeCardNumberElement, clientSecret: string): Promise<string> {
    const stripe = await this.instance();
    if (!stripe) throw new Error();

    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: { card },
    });

    if (error) throw error;
    if (!setupIntent?.payment_method) throw new Error('Unable to create payment method');

    return typeof setupIntent.payment_method === 'string'
      ? setupIntent.payment_method
      : (setupIntent.payment_method as any).id;
  }

  private async getPaymentMethodData(paymentMethodId: string): Promise<StripeAPI.PaymentMethod> {
    return await toPromise(this.http.get<StripeAPI.PaymentMethod>(`/api/stripe/paymentMethod/${paymentMethodId}`));
  }

  ngOnDestroy() {
    this._loaded.complete();
  }

  //BANK ACCOUNT
  async createBankAccountPaymentMethod(
    accountHoldersName: string,
    email: string,
    id: string, //user or account
    type: string
  ): Promise<{
    externalId: string;
    label: string;
    model: string;
    externalData: PadMetaData;
    cardHolderName?: string | null;
  }> {
    const clientSecret = await this.getPadSetupIntentClientSecret(id, type);
    const paymentMethodIntent = await this.confirmPADSetup(accountHoldersName, email, clientSecret);
    const paymentMethod = await this.getPaymentMethodData(paymentMethodIntent.payment_method);

    return {
      externalId: paymentMethod.id,
      model: 'bank',
      label: paymentMethod.acss_debit?.bank_name + ' ' + paymentMethod.acss_debit?.last4,
      externalData: {
        status: paymentMethodIntent.status,
        payment_method: paymentMethodIntent.payment_method,
        next_action: paymentMethodIntent.next_action,
      },
      cardHolderName: paymentMethod.billing_details?.name || null,
    };
  }
  private async getPadSetupIntentClientSecret(id: string, type: string): Promise<string> {
    //id is for user or account
    const intent = await toPromise(
      this.http.get<{ client_secret: string; mandate: any }>('/api/stripe/padSetupIntent/' + id, { params: { type } })
    );
    return intent?.client_secret;
  }
  private async confirmPADSetup(accountHolderName: string, email: string, clientSecret: string): Promise<PadMetaData> {
    const stripe = await this.instance();
    if (!stripe) throw new Error();
    const { error, setupIntent } = await stripe.confirmAcssDebitSetup(
      clientSecret,
      {
        payment_method: {
          billing_details: {
            name: accountHolderName,
            email: email,
          },
        },
      },
      { skipMandate: false }
    );

    if (error) throw error;

    if (!setupIntent?.payment_method) throw new Error('Unable to create payment method');
    return {
      payment_method:
        typeof setupIntent.payment_method === 'string'
          ? setupIntent.payment_method
          : (setupIntent.payment_method as any).id,
      status: setupIntent.status,

      next_action: setupIntent.next_action?.type,
    };
  }

  async displayContent(externalData: PadMetaData): Promise<ContentData> {
    if (
      (externalData.status.match('succeeded') && externalData.next_action === null) ||
      externalData.next_action === undefined
    ) {
      return {
        title: 'Bank Account Succesfully Added',
        content: 'Thank you for your adding your bank account with us!',
      };
    } else if (externalData.status.match('requires_action') && externalData.next_action != null) {
      return {
        title: 'Bank Account Needs Further Verification',
        content:
          'Thank you for your adding your bank Account with us! Please kindly follow the instructions that have been sent to your billing email to confirm the bank details',
      };
    } else {
      return {
        title: 'Bank Account failed to Add',
        content:
          'Sorry, we were not able to authenticate your bank account credentails. You can try again later or contact our support team for assistance',
      };
    }
  }
}
