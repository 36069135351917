import { Injectable } from '@angular/core';
import { BuildDateFilter } from '@greco/ngx-filters';
import { SubscriptionActionType } from '@greco/sales-subscriptions';
import { CondOperator, SFields } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'any' })
export class SubscriptionRenewalDateFilter extends BuildDateFilter('SubscriptionRenewalDateFilter', {
  label: 'Renewal Date',
  shortLabel: 'Renewal',
  description: '',
  properties: ['SubscriptionActionEntity.timestamp'],
}) {
  getPropertySField(property: string, operator: CondOperator, value: Date | Date[]): SFields {
    return {
      ['$and' as any]: [
        { 'SubscriptionActionEntity.type': SubscriptionActionType.RENEWAL },
        super.getPropertySField(property, operator, value),
      ],
    };
  }
}
