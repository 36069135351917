import { Pipe, PipeTransform } from '@angular/core';
import { PurchaseItem } from '@greco/sales-purchases';

@Pipe({ name: 'filterCoupon' })
export class FilterCouponPipe implements PipeTransform {
  transform(items: PurchaseItem[], showCoupons?: boolean) {
    if (!showCoupons) {
      return items.filter(item => item.type !== 'coupon');
    } else {
      return items.filter(item => item.type === 'coupon');
    }
  }
}
