<div class="title-section">
  <h2 class="title">
    <mat-icon *ngIf="collection.icon">{{ collection.icon }}</mat-icon>
    <span>{{ collection.label }}</span>
  </h2>

  <button mat-button class="view-more-button" (click)="viewMore()">
    View More
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div class="products-swiper">
  <swiper
    [slidesPerView]="'auto'"
    [spaceBetween]="16"
    [navigation]="true"
    [slidesOffsetBefore]="mobileView ? 16 : 32"
    [slidesOffsetAfter]="mobileView ? 16 : 32"
  >
    <ng-template swiperSlide *ngFor="let product of products$ | async">
      <greco-product-card
        [userId]="userId"
        [product]="product"
        [userConditionContext]="userConditionContext"
        [linkedAccounts]="linkedAccounts"
      ></greco-product-card>
    </ng-template>
  </swiper>
</div>
