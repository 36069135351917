import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { Account, AccountResource, AccountResourceAction, AccountRoyalty } from '@greco/finance-accounts';
import { SecurityService } from '@greco/ngx-security-util';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { AddRoyaltiesDialog } from '../../dialogs';
import { AccountService } from '../../services';

@Component({
  selector: 'greco-account-royalties',
  templateUrl: './account-royalties.component.html',
  styleUrls: ['./account-royalties.component.scss'],
})
export class AccountRoyaltiesComponent implements OnChanges, OnInit {
  constructor(
    private dialogs: MatDialog,
    private accountSvc: AccountService,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService
  ) {
    this.royalties = [];
  }

  @Input() account!: Account;

  canManageAccount = false;

  addingRoyalties = false;
  royalties: AccountRoyalty[];

  async ngOnInit() {
    const account = await this.accountSvc.getAccountWithRoyalties(this.account.id);
    if (!account) {
      console.error('error retrieving account');
    }
    const royalties = account.royalties;
    if (!royalties) {
      console.error('error retrieving royalties');
    }

    //const royaltiesb = royalties.map(item => { return { id: item.id, percentage: item.percentage, destination: item.destination.name }});
    royalties.forEach(item => this.royalties.push(item));
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.account?.previousValue?.id !== changes.account.currentValue?.id) {
      this.canManageAccount = await this.securitySvc.hasAccess(AccountResource.key, AccountResourceAction.CONFIGURE, {
        accountId: this.account?.id,
      });
    }
  }

  async addRoyalties() {
    this.addingRoyalties = true;

    try {
      const dialog = this.dialogs.open(AddRoyaltiesDialog, { data: this.account });
      const result = await toPromise(dialog.afterClosed());
      if (result?.id) {
        this.royalties = this.royalties.concat(result);
      }
    } catch (err) {
      console.error(err);
    }

    this.addingRoyalties = false;
  }

  async removeRoyalties(royalty: AccountRoyalty) {
    const confirmation = await toPromise(
      this.dialogs
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to remove a <strong>${royalty.percentage}%</strong> automated royalty from ${this.account.name} to ${royalty.destination.name}.</p><br><p>Are you sure you want to continue?</p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      try {
        await this.accountSvc.removeRoyalty(this.account.id, royalty.id);
        this.royalties = this.royalties.filter(({ id }) => id !== royalty.id);

        this.snacks.open('Deleted!', 'Ok', { duration: 2000 });
      } catch (err: any) {
        this.snacks.open(err, 'Ok', { duration: 3000 });
        console.error(err);
      }
    }
  }
}
