import { Injectable } from '@angular/core';
import { SelectFilter } from '@greco/ngx-filters';
import { CondOperator, SFields } from '@nestjsx/crud-request';

type BookingFilterByStatus =
  | 'CONFIRMED'
  | 'CANCELLED'
  | 'CHECKED_IN'
  | 'LATE_CANCELLED'
  | 'PENDING'
  | 'VOIDED'
  | 'NO_SHOW';

@Injectable({ providedIn: 'any' })
export class BookingFilterByStatusFilter extends SelectFilter {
  constructor() {
    super('BookingFilterByStatusFilter', { description: '', label: 'Status', shortLabel: 'Status', properties: ['_'] });
  }

  private allStatus: BookingFilterByStatus[] = [
    'CONFIRMED',
    'CANCELLED',
    'CHECKED_IN',
    'LATE_CANCELLED',
    'PENDING',
    'VOIDED',
    'NO_SHOW',
  ];

  getValueLabel(value: BookingFilterByStatus | BookingFilterByStatus[]): string {
    return Array.isArray(value) ? value.join(', ') : value;
  }

  getValueOptions(search?: string): BookingFilterByStatus[] {
    if (!search) return this.allStatus;
    return this.allStatus.filter(a => a.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

  serializeValue(value: BookingFilterByStatus | BookingFilterByStatus[]): string {
    return Array.isArray(value) ? value.join(',') : value;
  }

  deserializeValue(serializedValue: string) {
    return serializedValue.split(',').filter(v => this.allStatus.includes(v as any));
  }

  getPropertySField(_property: string, operator: CondOperator, value: BookingFilterByStatus[]): SFields {
    return operator === CondOperator.EQUALS
      ? {
          $or: [
            ...(value.includes('CONFIRMED') ? [{ status: 'CONFIRMED' }] : []),
            ...(value.includes('CANCELLED') ? [{ status: 'CANCELLED' }] : []),
            ...(value.includes('CHECKED_IN') ? [{ status: 'CHECKED_IN' }] : []),
            ...(value.includes('LATE_CANCELLED') ? [{ status: 'LATE_CANCELLED' }] : []),
            ...(value.includes('PENDING') ? [{ status: 'PENDING' }] : []),
            ...(value.includes('VOIDED') ? [{ status: 'VOIDED' }] : []),
            ...(value.includes('NO_SHOW') ? [{ status: 'NO_SHOW' }] : []),
          ],
        }
      : operator === CondOperator.NOT_EQUALS
      ? {
          $or: [
            {
              $and: [
                ...(value.includes('CONFIRMED') ? [{ $or: [{ status: { $ne: 'CONFIRMED' } }] }] : []),
                ...(value.includes('CANCELLED') ? [{ $or: [{ status: { $ne: 'CANCELLED' } }] }] : []),
                ...(value.includes('CHECKED_IN') ? [{ $or: [{ status: { $ne: 'CHECKED_IN' } }] }] : []),
                ...(value.includes('LATE_CANCELLED') ? [{ $or: [{ status: { $ne: 'LATE_CANCELLED' } }] }] : []),
                ...(value.includes('PENDING') ? [{ $or: [{ status: { $ne: 'PENDING' } }] }] : []),
                ...(value.includes('VOIDED') ? [{ $or: [{ status: { $ne: 'VOIDED' } }] }] : []),
                ...(value.includes('NO_SHOW') ? [{ $or: [{ status: { $ne: 'NO_SHOW' } }] }] : []),
              ],
            },
          ],
        }
      : {};
  }
}
