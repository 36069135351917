import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductCollection } from '@greco/sales-products';

@Component({
  selector: 'greco-collections-grid',
  templateUrl: './collections-grid.component.html',
  styleUrls: ['./collections-grid.component.scss'],
})
export class CollectionsGridComponent {
  @Output() collectionClicked = new EventEmitter<ProductCollection>();
  @Input() collections: ProductCollection[] = [];
}
