import { Pipe, PipeTransform } from '@angular/core';
import { RecurrencePeriod } from '@greco/sales-subscriptions';

@Pipe({ name: 'grecoPerkRecurrence' })
export class RecurrenceLabelPipe implements PipeTransform {
  transform(recurrence?: any) {
    if (!recurrence) return '';
    const test = `${recurrence.frequency === 1 ? '' : recurrence.frequency} ${this.getPeriodLabel(recurrence.period)}${
      recurrence.frequency > 1 ? 's' : ''
    }`;
    return test;
  }

  private getPeriodLabel(period: RecurrencePeriod) {
    switch (period) {
      case RecurrencePeriod.Daily:
        return 'Day';
      case RecurrencePeriod.Monthly:
        return 'Month';
      case RecurrencePeriod.Weekly:
        return 'Week';
      case RecurrencePeriod.Yearly:
        return 'Year';
    }
  }
}

@Pipe({ name: 'grecoFrequency' })
export class FrequencyPipe implements PipeTransform {
  transform(frequency: number, period: RecurrencePeriod) {
    const label = this.getPeriodLabel(period);
    return frequency > 1 ? frequency + ' ' + label + 's' : label;
  }

  private getPeriodLabel(period: RecurrencePeriod) {
    switch (period) {
      case RecurrencePeriod.Daily:
        return 'Day';
      case RecurrencePeriod.Monthly:
        return 'Month';
      case RecurrencePeriod.Weekly:
        return 'Week';
      case RecurrencePeriod.Yearly:
        return 'Year';
    }
  }
}
