import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { toPromise } from '@greco-fit/util';
import { CouponAssignment } from '@greco/coupon';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';

import { AddCouponAssignmentDialogComponent, AddCouponAssignmentDialogData } from '../../dialogs';
import { CouponAssignmentForm, COUPON_ASSIGNMENT_FORMS } from './coupon-assignment-forms.token';

@Component({
  selector: 'greco-add-assignment-button',
  templateUrl: './add-assignment-button.component.html',
  styleUrls: ['./add-assignment-button.component.scss'],
})
export class AddCouponAssignmentComponent {
  constructor(
    @Inject(COUPON_ASSIGNMENT_FORMS) public readonly forms: CouponAssignmentForm[],
    private matDialog: MatDialog
  ) {}

  @Input() hideIcon = false;
  @Input() onlyIcon = false;
  @Input() color = 'primary';

  @PropertyListener('couponId') private _couponId$ = new BehaviorSubject<string | null>(null);
  @Input() couponId!: string | null;

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string | null>(null);
  @Input() communityId!: string | null;

  @Output() saved = new EventEmitter<undefined | CouponAssignment>(true);

  @Output() refresh = new EventEmitter();

  @ViewChild(MatMenuTrigger) private _menu?: MatMenuTrigger;

  @Input() disabled = false;

  async open(form: CouponAssignmentForm) {
    if (this._menu?.menuOpen) this._menu.closeMenu();

    await toPromise(
      this.matDialog
        .open(AddCouponAssignmentDialogComponent, {
          width: '512px',
          maxWidth: 'calc(80vw)',
          data: {
            communityId: this.communityId,
            couponId: this.couponId,
            type: form.type,
            label: form.label,
            form: form.form,
          } as AddCouponAssignmentDialogData,
        })
        .afterClosed()
    );
    this.refresh.emit();
  }

  openMenu() {
    this._menu?.openMenu();
  }
}
