import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { DefaultSaleCategoryDto } from '@greco/nestjs-sales-purchases';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { DefaultSaleCategoryResource, DefaultSaleCategoryResourceAction } from '@greco/sales-purchases';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DefaultSaleCategoryDefinitions } from '../../default-sale-category.token';
import { SaleCategoryService } from '../../services';

@Component({
  selector: 'greco-default-sale-category',
  templateUrl: './default-sale-category.component.html',
  styleUrls: ['./default-sale-category.component.scss'],
})
export class DefaultSaleCategoryComponent implements OnInit, OnDestroy {
  constructor(
    private categorySvc: SaleCategoryService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('defaultCategoryForm') defaultCategoryForm$ = new ReplaySubject<DefaultSaleCategoryDefinitions>(1);
  @Input() defaultCategoryForm!: DefaultSaleCategoryDefinitions;
  @PropertyListener('accountId') accountId$ = new ReplaySubject<string>(1);
  @Input() accountId!: string;

  readonly canSetDefault$ = this.accountId$.pipe(
    switchMap(async accountId => {
      return accountId
        ? await this.securitySvc.hasAccess(
            DefaultSaleCategoryResource.key,
            DefaultSaleCategoryResourceAction.SET_DEFAULT,
            { accountId }
          )
        : null;
    })
  );

  resetValue: any = { saleCategory: null };
  refresh$ = new BehaviorSubject(null);
  accountDefault$ = combineLatest([this.accountId$, this.defaultCategoryForm$, this.refresh$]).pipe(
    switchMap(async ([accountId, dcf]) => {
      if (!accountId || !dcf) return null;
      const accountDefault = await this.categorySvc.getAccountDefaultByType(accountId, dcf.type);
      return accountDefault;
    }),
    tap(accountDefault => {
      this.resetValue = { saleCategory: accountDefault?.saleCategory || null };
      this.form.reset({ saleCategory: accountDefault?.saleCategory || null });
      this.form.markAsPristine();
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );
  form = this.formBuilder.group({
    saleCategory: [null, Validators.required],
  });

  readonly saving$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    this.form.markAsPristine();
  }

  ngOnDestroy() {
    this.saving$.complete();
  }
  async save() {
    this.saving$.next(true);
    if (!this.accountId || !this.defaultCategoryForm) throw new Error('Something went wrong!');
    try {
      let data: DefaultSaleCategoryDto | null = null;
      const accountDefault = await toPromise(this.accountDefault$);
      if (this.form.value.saleCategory && this.form.value.saleCategory?.id !== accountDefault?.saleCategoryId)
        data = { type: this.defaultCategoryForm.type, saleCategoryId: this.form.value.saleCategory.id };
      if (!data || !Object.keys(data).length) throw new Error("You haven't made any changes");
      await this.categorySvc.setAccountDefault(this.accountId, data);
      this.reset();
      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.refresh$.next(null);
    } catch (err) {
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }
    this.saving$.next(false);
  }

  private async reset() {
    this.resetValue = {
      saleCategory: (await toPromise(this.accountDefault$))?.saleCategory || null,
    };
    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }
}
