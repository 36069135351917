<greco-table [data]="selected" [hideEmptyPlaceholder]="true">
  <ng-container *grecoTableCol="''; fitContent: true; let spot; let index = index">
    <span> {{ spot.spotNumber || index }}</span>
  </ng-container>

  <ng-container *grecoTableCol="'Name'; let spot; let index = index">
    <mat-form-field appearance="standard">
      <input
        #nameInput
        matInput
        required
        type="text"
        autocomplete="off"
        [placeholder]="'Spot ' + spot.spotNumber"
        [value]="spot.name"
        (keyup)="editName(nameInput.value, index)"
      />
    </mat-form-field>
  </ng-container>

  <ng-container *grecoTableCol="'Description'; let spot; let index = index">
    <mat-form-field appearance="standard">
      <input
        #descriptionInput
        matInput
        type="text"
        autocomplete="off"
        placeholder="Short description"
        [value]="spot.description"
        (keyup)="editDescription(descriptionInput.value, index)"
      />
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="room?.imageURL">
    <ng-container *grecoTableCol="'Configured?'; let spot; fitContent: true">
      <mat-icon *ngIf="spot.x">check_circle</mat-icon>
      <mat-icon *ngIf="!spot.x">radio_button_unchecked</mat-icon>
    </ng-container>
  </ng-container>

  <ng-container *grecoTableCol="''; fitContent: true; let index = index">
    <button
      type="button"
      mat-icon-button
      [disabled]="selected.length <= 1"
      (click)="$event.stopImmediatePropagation(); removeFromSelected(index)"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </ng-container>
</greco-table>

<button
  *ngIf="canUpdate || fromCreate"
  mat-button
  type="button"
  style="width: 100%; margin-top: 12px"
  (click)="addToSelected('', '')"
>
  <mat-icon>add_circle</mat-icon>
  <span>New spot</span>
</button>
