<greco-simple-dialog [data]="dialogData" *grecoLet="loadedVariants$ | async as variants">
  <form *ngIf="variants?.length; else noVariants">
    <ng-container *ngFor="let variant of variants">
      <mat-form-field appearance="outline" floatLabel="always" *ngIf="controls.get(variant.variantId) as control">
        <mat-label>{{variant.title}}</mat-label>
        <input matInput type="number" [formControl]="control" [min]="0" [max]="variant.quantity" />
      </mat-form-field>
    </ng-container>
  </form>
  <ng-template #noVariants>
    <p>No applicable product variants to return!</p>
  </ng-template>
</greco-simple-dialog>
