<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Commitment End Date</mat-label>
      <input #input matInput required formControlName="date" [matDatepicker]="picker" (click)="picker.open()" />

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>

  <div style="display: grid; justify-content: flex-end">
    <div style="display: flex; gap: 12px">
      <button mat-stroked-button type="button" color="primary" (click)="removeDate()">
        <mat-progress-spinner
          *ngIf="processing"
          diameter="18"
          mode="indeterminate"
          style="filter: grayscale(1) opacity(0.5)"
        ></mat-progress-spinner>

        <span>Remove Commitment</span>
      </button>

      <button mat-stroked-button type="button" color="primary" style="flex: 1" (click)="submitUpdate()">
        <mat-progress-spinner
          *ngIf="processing"
          diameter="18"
          mode="indeterminate"
          style="filter: grayscale(1) opacity(0.5)"
        ></mat-progress-spinner>

        <span>Confirm</span>
      </button>
    </div>
  </div>
</greco-simple-dialog>
