<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()
  "
>
  <button mat-stroked-button color="primary" style="height: 32px; line-height: 16px" (click)="createAcount()">
    <mat-icon>add</mat-icon>
    <span>Create Account</span>
  </button>
</greco-filter-bar>

<greco-table [data]="(accounts$ | async) || []" [highlight]="true" [loading]="loading" (rowClick)="rowClick($event)">
  <mat-icon *grecoTableCol="''; fitContent: true; let account" [matTooltip]="account.id">account_balance</mat-icon>

  <p *grecoTableCol="'Account'; let account"><strong>{{account.name}}</strong></p>

  <mat-chip-list *grecoTableCol="'Gateways'; let account">
    <mat-chip *ngFor="let gateway of account.paymentGateways"> {{gateway.gateway}} </mat-chip>
  </mat-chip-list>

  <p *grecoTableCol="'Balance'; let account">
    {{account.balance?.FINALIZED ? ((account.balance.FINALIZED / 100) | currency) : ''}}
  </p>

  <mat-icon *grecoTableCol="'Payouts'; let account">
    {{account.stripe && account.payout?.enabled ? 'check' : ''}}
  </mat-icon>

  <p *grecoTableCol="'Royalties'; let account">
    <ng-container *grecoLet="account.royalties | pluck: 'percentage' | sum as percentage">
      {{percentage ? percentage + '%' : ''}}
    </ng-container>
  </p>

  <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let account">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button mat-menu-item [routerLink]="account.id">
        <mat-icon>open_in_new</mat-icon>
        View Details
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
