import { Component } from '@angular/core';
import { UserEventsFavoritesService } from '@greco/ngx-booking-events';

@Component({
  selector: 'greco-dashboard-favorites',
  templateUrl: './dashboard-favorites.component.html',
  styleUrls: ['./dashboard-favorites.component.scss'],
})
export class DashboardFavoritesComponent {
  constructor(public dashboardFavoritesSvc: UserEventsFavoritesService) {}
}
