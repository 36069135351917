import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import type { CreatePurchaseDto, PreviewCustomChargeDto } from '@greco/nestjs-sales-purchases';
import { Purchase } from '@greco/sales-purchases';
import { CustomChargeServiceModule } from './custom-charge.module';

@Injectable({ providedIn: CustomChargeServiceModule })
export class CustomChargeService {
  constructor(private http: HttpClient) {}

  // @Post('preview')
  async preview(
    dto: PreviewCustomChargeDto
  ): Promise<{ purchase: Purchase; dto: CreatePurchaseDto<any>; hash: string }> {
    return await toPromise(
      this.http.post<{ purchase: Purchase; dto: CreatePurchaseDto<any>; hash: string }>(
        '/api/custom-charges/preview',
        dto
      )
    );
  }
}
