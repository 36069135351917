<div #widthDiv>
  <div *ngIf="user" style="display: flex; flex-direction: column; justify-content: space-between; width: 100%">
    <signature-pad
      #sigPad
      *ngIf="signaturePadOptions"
      style="flex: 1; border-bottom: 1px solid grey; margin-bottom: 12px"
      [options]="signaturePadOptions"
      (onEndEvent)="drawComplete()"
    >
    </signature-pad>

    <div style="flex: 1">
      <div style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap">
        <p style="margin-top: -8px">
          <small>
            <i>{{ forPurchase ? 'Please sign here' : 'Current Signature' }}</i>
          </small>
        </p>
        <div class="buttons" style="display: flex; flex-direction: row; flex-wrap: wrap">
          <button mat-icon-button *ngIf="!cleared" style="height: 32px; margin-top: -2px" (click)="clearSignature()">
            <mat-icon style="margin-bottom: -2px">close</mat-icon>
          </button>
          <button *ngIf="existingSig && forPurchase" mat-stroked-button [disabled]="sameSig" (click)="reuseSignature()">
            Reuse Last
          </button>

          <button
            *ngIf="!forPurchase"
            mat-flat-button
            color="primary"
            [disabled]="sameSig || cleared"
            (click)="saveSignature()"
          >
            {{ saving ? '' : 'Update Signature' }}
            <mat-icon *ngIf="saving" class="spin">cached</mat-icon>
          </button>
          <button *ngIf="user.displayName || user.friendlyName" mat-stroked-button (click)="drawSignature()">
            Generate
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="!user">
  <p>You must be logged in to sign this!</p>
</ng-container>
