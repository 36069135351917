import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { createWidget } from '@typeform/embed';

@Component({
  selector: 'greco-typeform-embed-page',
  template: '',
  styleUrls: ['./typeform-embed.page.scss'],
})
export class TypeformEmbedPage {
  constructor(elemRef: ElementRef, private route: ActivatedRoute) {
    const config = this.route.snapshot.data.typeform;
    if (config && config.id) {
      createWidget(config.id, { container: elemRef.nativeElement, ...config.options });
    }
  }
}
