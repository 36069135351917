import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { UserAgreementDto } from '@greco/community-agreements';
import type { CheckoutPreview, PreviewCheckoutDto } from '@greco/nestjs-sales-products';
import { PurchaseService } from '@greco/ngx-sales-purchases';
import { SubscriptionsService } from '@greco/ngx-sales-subscriptions';
import { Purchase } from '@greco/sales-purchases';
import { Subscription } from '@greco/sales-subscriptions';

@Injectable()
export class CheckoutService {
  constructor(
    private http: HttpClient,
    private purchaseSvc: PurchaseService,
    private subscriptionSvc: SubscriptionsService
  ) {}

  // @Post()
  async preview(dto: PreviewCheckoutDto): Promise<CheckoutPreview> {
    return await toPromise(this.http.post<CheckoutPreview>('/api/checkout', dto));
  }

  async complete(
    preview: CheckoutPreview,
    data: UserAgreementDto[],
    referredById?: string,
    transferredFromId?: string
  ): Promise<Subscription | Purchase> {
    if ('subscription' in preview) {
      return await this.subscriptionSvc.createSubscription(
        { ...preview.dto, referredById, transferredFromId },
        preview.hash,
        data
      );
    } else return await this.purchaseSvc.createPurchase({ ...preview.dto, referredById }, preview.hash, data);
  }
}
