<input
  matInput
  type="text"
  [matAutocomplete]="autocomplete"
  [value]="searchQuery$ | async"
  [disabled]="disabled"
  [required]="required"
  [placeholder]="placeholder"
  (input)="searchQuery$.next($event.target.value)"
  (blur)="value = searchQuery$.value ? value : null"
/>

<mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="value = $event.option.value || null">
  <mat-option *ngFor="let option of options$ | async" [value]="option" [disabled]="option.disabled || false">
    <ng-container *ngIf="content; else base">
      <ng-container *ngTemplateOutlet="content.template; context: { $implicit: option, query: searchQuery$.value }"></ng-container>
    </ng-container>
    <ng-template #base>
      <div>{{ option.label }}</div>
    </ng-template>
  </mat-option>
</mat-autocomplete>
