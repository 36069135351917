import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

export interface DateRangeOption {
  label: string;
  from: Date;
  to: Date;
}

@Component({
  selector: 'greco-date-range-field',
  templateUrl: './date-range-field.component.html',
  styleUrls: ['./date-range-field.component.scss']
})
export class DateRangeFieldComponent implements OnInit {
  @ViewChild('select') select: MatSelect;

  @Output() rangeChange: EventEmitter<DateRangeOption> = new EventEmitter<DateRangeOption>();

  @Input() label = 'Date Range';
  @Input() options: DateRangeOption[] = [];
  @Input() set value(value: DateRangeOption) {
    // Set 'from' and 'to' values in timeout, since otherwise 'select' does not exist yet in the 'ngZone'
    setTimeout(() => {
      this.from$.next(value.from);
      this.to$.next(value.to);
    });
  }

  private _allowCustom = true;
  @Input() set allowCustom(value: boolean) {
    this._allowCustom = coerceBooleanProperty(value);
  }
  get allowCustom() {
    return this._allowCustom;
  }

  private _allowEmpty = true;
  @Input() set allowEmpty(value: boolean) {
    this._allowEmpty = coerceBooleanProperty(value);
  }
  get allowEmpty() {
    return this._allowEmpty;
  }

  public from$: Subject<Date> = new ReplaySubject(1);
  public to$: Subject<Date> = new ReplaySubject(1);

  ngOnInit() {
    // Emit Date Range Changes
    combineLatest([this.from$, this.to$])
      .pipe(
        distinctUntilChanged(([from, to], [from1, to1]) => from === from1 && to === to1),
        filter(([from, to]) => !!from && !!to),
        debounceTime(100) // Debounce values so that a change to both at the same time is reflected after the 'to' has been set (otherwise it uses today as the default)
      )
      .subscribe(([from, to]) => {
        const option = this.getOption(from, to);
        this.rangeChange.next(option || { label: 'Custom Date', from: moment(from).startOf('day').toDate(), to: moment(to).startOf('day').toDate() });
        // eslint-disable-next-line no-constant-condition
        if ((this.select && this.select.value !== option) || '_custom') this.select.value = option || '_custom';
      });
  }

  async selectChanged(event: { source: MatSelect; value: DateRangeOption }, rangeInput: MatDateRangeInput<Date>) {
    if (!event.value) {
      this.from$.next(null);
      this.to$.next(null);
      this.rangeChange.next(null);
    } else if (!event.value.from || !event.value.to) {
      rangeInput.rangePicker.open();
      // eslint-disable-next-line no-constant-condition
    } else if (this.select.value !== this.getOption(event.value?.from, event.value?.to) || '_custom') {
      this.from$.next(event.value?.from);
      this.to$.next(event.value?.to);
    }
  }

  clear() {
    this.from$.next(null);
    this.to$.next(null);
    this.select.value = null;
  }

  fromChanged(event) {
    this.from$.next(event);
  }

  toChanged(event) {
    this.to$.next(event);
  }

  private getOption(from: Date, to: Date) {
    return this.options.find(o => moment(o.from).startOf('day').isSame(moment(from).startOf('day')) && moment(o.to).endOf('day').isSame(moment(to).endOf('day')));
  }
}
