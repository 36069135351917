import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { TypeformBookingRequirement } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { TypeformService } from '@greco/ngx-typeform';
import { PropertyListener } from '@greco/property-listener-util';
import { createPopup } from '@typeform/embed';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'greco-typeform-requirement',
  templateUrl: './typeform-requirement.component.html',
  styleUrls: ['./typeform-requirement.component.scss'],
})
export class TypeformRequirementComponent implements OnInit {
  constructor(private typeformSvc: TypeformService, private breakpointObs: BreakpointObserver) {}

  @Output() filled = new EventEmitter<{ responseId: string }>();

  @PropertyListener('user') private _user$ = new ReplaySubject<User>();
  @Input() user!: User;

  @PropertyListener('requirement') private _requirement$ = new ReplaySubject<TypeformBookingRequirement>();
  @Input() requirement!: TypeformBookingRequirement;

  previousSubmission$ = combineLatest([this._user$, this._requirement$]).pipe(
    filter(([user, req]) => !!user && !!req),
    switchMap(async ([user, req]) =>
      req.reusable ? await this.typeformSvc.getMostRecentResponse(user.id, req.form.formId) : null
    ),
    tap(res => {
      if (res) this.useResponse(res.id);
    })
  );

  response$ = new BehaviorSubject<string | null>(null);

  isMobile$ = this.breakpointObs.observe('(max-width: 600px)');

  async filIn() {
    await new Promise<void>(res =>
      createPopup(this.requirement.form.formId, {
        size: 80,
        autoClose: 1000,
        hidden: {
          user_id: this.user.id,
          user_email: this.user.contactEmail,
        },
        onSubmit: ({ responseId }) => {
          this.useResponse(responseId);
          res();
        },
      }).open()
    );
  }

  useResponse(responseId: string) {
    this.response$.next(responseId);
    this.filled.next({ responseId });
  }
  async ngOnInit(): Promise<void> {
    const previous = await toPromise(this.previousSubmission$);

    if (previous) this.useResponse(previous.id);
  }
}
