import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  Resource,
  ResourceAssignment,
  ResourceAssignmentStatus,
  ResourceAvailabilityStatus,
  ResourceType,
} from '@greco/booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ResourcesService } from '../../services';

@Component({
  selector: 'greco-assignment-picker',
  templateUrl: './assignment-picker.component.html',
  styleUrls: ['./assignment-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: AssignmentPickerComponent }],
})
export class AssignmentPickerComponent
  implements OnDestroy, MatFormFieldControl<ResourceAssignment>, ControlValueAccessor
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private resourceSvc: ResourcesService,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  private static _id = 1;
  private static _controlType = 'greco-assignment-picker';

  private _onTouched?: () => void;
  private _onChange?: (value: ResourceAssignment | null) => void;

  @ViewChild(MatInput) private _input?: MatInput;

  @Output() resourceChanged = new EventEmitter<Resource | null>();

  @Input() availability: { [key: string]: ResourceAvailabilityStatus } = {};

  private _value: ResourceAssignment | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
    this.search$.next('');

    this._onChange?.(value);
    this.stateChanges.next();
    this.resourceChanged.emit(value?.resource);
  }

  @Input() filterType: {
    type: ResourceType | string | null;
    limit: number;
    exclude: ResourceType[] | string[] | null;
  } = {
    type: null,
    limit: Number.MAX_SAFE_INTEGER,
    exclude: null,
  };

  @HostBinding() readonly id = `${AssignmentPickerComponent._controlType}-${AssignmentPickerComponent._id++}`;
  readonly controlType = AssignmentPickerComponent._controlType;

  stateChanges = new Subject<void>();

  private _communityId$ = new BehaviorSubject<string | null>(null);
  @Input() set communityId(communityId) {
    this._communityId$.next(communityId);
    this.stateChanges.next();
  }
  get communityId() {
    return this._communityId$.value;
  }

  private _placeholder = '';
  @Input() get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return (this._input ? this._input.empty : true) && !this.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _disabled = false;
  @Input() get disabled() {
    return this._disabled;
  }
  set disabled(disabled) {
    this._disabled = disabled;
    this.stateChanges.next();
  }

  private _required = false;
  @Input() get required() {
    return this._required;
  }
  set required(required) {
    this._required = required;
    this.stateChanges.next();
  }

  get errorState() {
    return false;
  }

  readonly autofilled = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  readonly search$ = new BehaviorSubject<string>('');
  readonly resources$: Observable<Resource[]> = combineLatest([this._communityId$, this.search$]).pipe(
    debounceTime(250),
    switchMap(async ([communityId, search]) =>
      communityId ? await this.resourceSvc.paginateResources(this._getRequestQueryBuilder(search), communityId) : null
    ),
    map(data => {
      if (data?.items) {
        return this.filterType.limit && data.items.length > this.filterType.limit
          ? data.items.splice(this.filterType.limit)
          : data.items;
      } else return [];
    }),
    map(data => data.filter(resource => (this.value ? resource.id !== this.value.resourceId : true)))
  );

  ngOnDestroy() {
    this.search$.complete();
    this.stateChanges.complete();
    this._communityId$.complete();
  }

  private _getRequestQueryBuilder(search: string): RequestQueryBuilder {
    const query = new RequestQueryBuilder();

    query.search({
      ...(search ? { name: { $contL: search } } : {}),
      ...(this.filterType.exclude ? { type: { $notin: this.filterType.exclude } } : {}),
      ...(this.filterType.type ? { type: { $eq: this.filterType.type } } : {}),
      ...{ disabled: { $eq: false } },
    });

    return query;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-assignment-picker-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  select(resource: Resource) {
    this.value = {
      resource,
      resourceStatus: ResourceAssignmentStatus.CONFIRMED,
    } as ResourceAssignment;
  }

  remove() {
    this.value = null;
    this.search$.next('');
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus?.();
  }

  writeValue(value: ResourceAssignment | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: ResourceAssignment | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }
}
