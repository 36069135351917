import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { Tile, TileGroup, UpdateTileDto, UpdateTilesGroupDto } from '@greco/tiles';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';

export interface TileFavorite {
  id: string;
  communityId: string;
  community?: Community;
}
@Injectable({
  providedIn: 'root',
})
export class TilesService {
  private tileFavoritesSource = new BehaviorSubject<TileFavorite[]>([]);
  public tileFavorites$ = this.tileFavoritesSource.asObservable();
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {}
  // @Get()
  async paginate(
    query: RequestQueryBuilder,
    communityId?: string,
    pagination?: Partial<PaginatedQueryParams>
    // tileId?: string
  ) {
    return await toPromise(
      this.http.get<PaginatedDto<Tile>>('/api/tiles', {
        params: {
          ...query.queryObject,
          // communityId,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  async get(tileId: string) {
    return await toPromise(this.http.get<Tile>(`/api/tiles/${tileId}`));
  }

  async create(communityId: string, data: FormData) {
    return await toPromise(this.http.post<Tile>(`/api/tiles/${communityId}`, data));
  }

  /* Update Tile */
  async updateTile(id: string, data: UpdateTileDto) {
    return await toPromise(this.http.put<Tile>(`/api/tiles/${id}`, data));
  }

  /* Update Tile Image*/
  async updateTileImage(id: string, data: FormData) {
    return await toPromise(this.http.post<Tile>(`/api/tiles/${id}/image`, data));
  }

  /** Remove previous Tile Image on updation from storage */
  async removeProductImage(id: string | undefined) {
    return await toPromise(this.http.patch<Tile>(`/api/tiles/${id}/image`, {}));
  }
  /* Get Group by Id*/
  async getGroupById(id: string) {
    return await toPromise(this.http.get<TileGroup>(`/api/tilesGroup/groupId/${id}`));
  }

  /* Get Group by Community */
  async getGroupsByCommunity(communityId: string, all = false) {
    return await toPromise(
      this.http.get<(TileGroup & { tiles: Tile[] })[]>(`/api/tilesGroup/communityId/${communityId}`, {
        params: { all: all ? 'true' : 'false' },
      })
    );
  }

  // @Get()
  async paginatedGroups(query: RequestQueryBuilder, communityId?: string, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<TileGroup>>('/api/tilesGroup', {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
  // update Tilegroup
  async updateTileGroup(id: string, data: UpdateTilesGroupDto) {
    return await toPromise(this.http.put<TileGroup>(`/api/tilesGroup/${id}`, data));
  }

  async delete(tileId: string) {
    await toPromise(this.http.delete<Tile>(`/api/tiles/${tileId}`));
    this.removeFavorite(tileId);
  }

  async removeFavorite(tileId: string) {
    const favoriteTiles: TileFavorite[] = JSON.parse(localStorage.getItem('favoriteTilesArray') || '[]'); // Get the current favorites

    if (!favoriteTiles.length) return;

    const isFavorite = favoriteTiles.some(tile => tile.id === tileId); // Check if tile is one of the favorites
    if (isFavorite) {
      const newFavorites = favoriteTiles.filter(tile => tile.id !== tileId); // Compute the new array of favorites (without the one to be removed)
      localStorage.setItem('favoriteTilesArray', JSON.stringify(newFavorites)); // Save new favorites array
    }
  }

  isFavorited(id: string) {
    const favoriteTilesArrayStr = localStorage.getItem('favoriteTilesArray');

    const favoriteTilesArray: TileFavorite[] = favoriteTilesArrayStr
      ? (JSON.parse(favoriteTilesArrayStr) as TileFavorite[])
      : [];
    this.tileFavoritesSource.next(favoriteTilesArray);
    return favoriteTilesArray.some(tile => tile.id === id);
  }
  async getFavorites() {
    const favoriteTilesArrayStr = localStorage.getItem('favoriteTilesArray');

    const favoriteTilesArray: TileFavorite[] = favoriteTilesArrayStr
      ? (JSON.parse(favoriteTilesArrayStr) as TileFavorite[])
      : [];

    const tiles: Tile[] = [];

    for (const favoriteTile of favoriteTilesArray) {
      const tile = await toPromise(
        this.http.get<Tile>(`/api/tiles/${favoriteTile.id}`, {
          headers: {
            'X-GrecoIgnoreErrors': 'true',
          },
        })
      ).catch(() => null);
      // if (!tile) {
      //   const resultArray = favoriteTilesArray.splice(parseInt(favoriteTile), 1);
      //   localStorage.removeItem('favoriteTilesArray');
      //   localStorage.setItem('favoriteTilesArray', JSON.stringify(resultArray));
      // }
      if (tile && tile.status == true) {
        tiles.push(tile);
      }
    }

    if (tiles.length == 0) {
      /*If there are no eligible tiles to display for the user, erase the local storage item
      /to clean it up */

      localStorage.removeItem('favoriteTilesArray');
      this.router.navigate([''], { relativeTo: this.route });
    } else {
      localStorage.setItem(
        'favoriteTilesArray',
        JSON.stringify(
          tiles.map(tile => {
            return { id: tile.id, communityId: tile.communityId };
          })
        )
      );
    }

    const data = {
      items: tiles,
    };
    // this.tileFavoritesSource.next(tiles);
    return data;
  }

  async toggleFavorite(id: string, communityId: string, community: Community | undefined) {
    const favoriteTilesArrayStr = localStorage.getItem('favoriteTilesArray');

    const favoriteTilesArray: TileFavorite[] = favoriteTilesArrayStr
      ? (JSON.parse(favoriteTilesArrayStr) as TileFavorite[])
      : [];

    for (const index of favoriteTilesArray.keys()) {
      if (favoriteTilesArray[index].id == id) {
        favoriteTilesArray.splice(index, 1);
        localStorage.setItem('favoriteTilesArray', JSON.stringify(favoriteTilesArray));
        return;
      }
    }

    const newFavorite: TileFavorite = {
      id,
      communityId,
      community,
    };

    favoriteTilesArray.push(newFavorite);

    localStorage.setItem('favoriteTilesArray', JSON.stringify(favoriteTilesArray));
  }
}
