import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextFitDirective } from './text-fit.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TextFitDirective],
  exports: [TextFitDirective],
})
export class TextFitModule {}
