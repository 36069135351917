<greco-item *ngIf="user$ | async as user" [lines]="false">
  <mat-icon item-start>email</mat-icon>

  <div style="width: 100%">
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">
      <strong>Email Address</strong>
    </p>

    <p
      matTooltip="Please verify your email address prior to booking."
      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
    >
      <small style="color: var(--warn-color)">Please verify your email address prior to booking.</small>
    </p>
  </div>

  <div item-end style="display: flex; align-items: center; justify-content: center">
    <button
      *ngIf="!user.emailVerified"
      style="height: 32px; width: 100%; margin-left: auto; padding: 0 12px"
      mat-button
      color="accent"
      (click)="refresh()"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <button
      *ngIf="!user.emailVerified"
      style="height: 32px; width: 100%; margin-left: auto; padding: 0 12px"
      mat-button
      color="accent"
      (click)="verifyEmailAddress(user)"
    >
      <span>Resend Verification Email</span>
    </button>

    <mat-icon *ngIf="user.emailVerified" style="color: var(--success-color)">check_circle</mat-icon>
  </div>
</greco-item>
