import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { SecurityService } from '@greco/ngx-security-util';
import { PurchaseResource, PurchaseResourceAction, Refund } from '@greco/sales-purchases';
import { CurrencyMaskConfig } from 'ngx-currency';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-undo-refund-purchase-dialog',
  templateUrl: './undo-refund.dialog.html',
  styleUrls: ['./undo-refund.dialog.scss'],
})
export class UndoRefundPurchaseDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { refund: Refund },
    private dialogRef: MatDialogRef<UndoRefundPurchaseDialog>,
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {}

  readonly dialogData: DialogData = {
    title: 'Reverse User Balance Refund',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  readonly maxUndo: number =
    (this.data.refund.balanceRefundedAmount - (this.data.refund.balanceUndoneAmount || 0)) / 100;

  formGroup = this.formBuilder.group({
    balanceUndoneAmount: [
      {
        value: (this.data.refund.balanceRefundedAmount - (this.data.refund.balanceUndoneAmount || 0)) / 100,
        disabled: false,
      },
      [Validators.min(0), Validators.max(this.maxUndo)],
    ],
  });

  processing = false;

  canUndoRefundToBalance$ = this.securitySvc.hasAccess(
    PurchaseResource.key,
    PurchaseResourceAction.UNDO_REFUND_TO_BALANCE,
    {},
    true
  );

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      if (!this.formGroup.value.balanceUndoneAmount) return;
      if (this.formGroup.value.balanceUndoneAmount > this.maxUndo) {
        // TODO(adaoust): Add additional validation.
        this.snacks.open('Refund reversal limit exceeded.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        return;
      }

      const balanceUndoneAmount = Math.round(this.formGroup.value.balanceUndoneAmount * 100);

      const refund = await this.purchaseSvc.undoRefund(this.data.refund.id, balanceUndoneAmount);

      if (!refund.balanceUndoneAmount) {
        console.error('Refund could not be reversed');
        this.snacks.open('Purchase could not be refunded', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      } else {
        this.snacks.open('Refund reversal successful!', 'Ok', { duration: 3000 });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
    this.close({
      submit: true,
      balanceUndoneAmount: this.formGroup.value.balanceUndoneAmount,
    });
  }
}
