import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

export type GrecoDateComparePipeOperator = 'lt' | 'lte' | 'eq' | 'gte' | 'gt';

@Pipe({ name: 'grecoDateCompare' })
export class GrecoDateComparePipe implements PipeTransform {
  transform(date1: Date, operator: GrecoDateComparePipeOperator = 'eq', date2: Date = new Date()) {
    if (!date1 || !date2) return false;

    const mDate1 = moment(date1),
      mDate2 = moment(date2);

    try {
      switch (operator) {
        case 'lt':
          return mDate1.isBefore(mDate2);
        case 'lte':
          return mDate1.isSameOrBefore(mDate2);
        case 'eq':
          return mDate1.isSame(mDate2);
        case 'gte':
          return mDate1.isSameOrAfter(mDate2);
        case 'gt':
          return mDate1.isAfter(mDate2);
        default:
          return false;
      }
    } catch {
      return false;
    }
  }
}
