import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { EventAccount, EventConflictingBookingDetails, EventWithUserDetails } from '@greco/booking-events2';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { AlertConfig, AlertType } from '@greco/ui-alert';
import moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'alt-event-schedule-conflict-alert',
  templateUrl: './event-schedule-conflict-alert.component.html',
  styleUrls: ['./event-schedule-conflict-alert.component.scss'],
})
export class EventScheduleConflictAlertComponent {
  constructor(private breakpointObserver: BreakpointObserver, public eventSvc: EventService) {}

  @PropertyListener('user') private user$ = new BehaviorSubject<User | null>(null);
  @Input() user?: User | null;

  @PropertyListener('event') private event$ = new BehaviorSubject<EventWithUserDetails | null>(null);
  @Input() event?: EventWithUserDetails;

  @PropertyListener('eventAccounts') private eventAccounts$ = new BehaviorSubject<EventAccount[]>([]);
  @Input() eventAccounts: EventAccount[] = [];

  isMobile$ = this.breakpointObserver.observe('(max-width: 399px)').pipe(map(result => result.matches));

  alerts$ = combineLatest([this.user$, this.event$, this.eventAccounts$]).pipe(
    map(([user, event, eventAccounts]) => {
      if (!user || !event) return [];

      const alerts: AlertConfig[] = [];

      const eventAccount = eventAccounts.find(account => account.user.id === user.id);
      if (!eventAccount) return [];

      eventAccount.conflictingBookings.forEach(booking => {
        alerts.push({
          title: 'Scheduling Conflict - ' + booking.eventTitle,
          type: AlertType.DANGER,
          description:
            eventAccounts?.length > 1
              ? eventAccount.user.displayName +
                ' is currently booked into the ' +
                booking.eventTitle +
                ' on ' +
                this.getEventTime(booking) +
                ", which overlaps with this event! Please cancel the event they do not plan to attend so they don't incur a No Show fee."
              : 'You are already booked into ' +
                booking.eventTitle +
                ' on ' +
                this.getEventTime(booking) +
                ", which overlaps with this event! Please cancel the event you do not plan to attend so you don't incur a No Show fee",
        });
      });

      return alerts;
    }),
    tap(alerts => {
      if (!this.user) return;

      const eventAlerts = this.eventSvc.alerts$.value;
      const userAlerts = eventAlerts.find(alert => alert.userId === this.user?.id);

      if (!userAlerts && alerts?.length) {
        this.eventSvc.alerts$.next([
          ...eventAlerts,
          {
            userId: this.user.id,
            scheduleConflict: true,
          },
        ]);
      } else if (userAlerts && alerts?.length) {
        const otherUserAlerts = eventAlerts.filter(alert => alert.userId !== this.user?.id);
        this.eventSvc.alerts$.next([
          ...otherUserAlerts,
          {
            ...userAlerts,
            scheduleConflict: true,
          },
        ]);
      } else if (!userAlerts && !alerts?.length) {
        this.eventSvc.alerts$.next([
          ...eventAlerts,
          {
            userId: this.user.id,
            scheduleConflict: false,
          },
        ]);
      } else if (userAlerts && !alerts?.length) {
        const otherUserAlerts = eventAlerts.filter(alert => alert.userId !== this.user?.id);
        this.eventSvc.alerts$.next([
          ...otherUserAlerts,
          {
            ...userAlerts,
            scheduleConflict: false,
          },
        ]);
      }
    })
  );

  getEventTime(booking: EventConflictingBookingDetails) {
    const start = booking.eventTime;
    const end = moment(booking.eventTime).add(booking.eventDuration, 'minutes');
    return (
      moment(start).format('MMM DD') +
      ' between ' +
      moment(start).format('h:mm') +
      ' and ' +
      moment(end).format('h:mm A')
    );
  }
}
