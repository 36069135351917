export function groupBy<T>(collection: T[], predicate: (item: T) => string): { [key: string]: T[] } {
  const result: { [key: string]: T[] } = {};

  for (const item of collection) {
    const key = predicate(item);
    result[key] = (result[key] || []).concat(item);
  }

  return result;
}
