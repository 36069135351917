import { Pipe, PipeTransform } from '@angular/core';
import { Subscription, SubscriptionActionType, SubscriptionStatus } from '@greco/sales-subscriptions';
import moment from 'moment';
import { SubscriptionActionsService } from '../services';

@Pipe({ name: 'nextRenewal' })
export class NextRenewalPipe implements PipeTransform {
  constructor(private actionSvc: SubscriptionActionsService) {}
  async transform(subscription: Subscription) {
    const nextAction = await this.actionSvc.getNextAction(subscription.id);
    if (!nextAction) {
      const actions = await this.actionSvc.getAllActions(subscription.id);
      const currentAction = actions.find(
        a => a.subscriptionId == subscription.id && a.type == SubscriptionActionType.CANCELLATION
      );
      const time = moment(currentAction?.timestamp).format('ll');
      if (subscription.status === SubscriptionStatus.CANCELLED) {
        return 'Expired on ' + time;
      }
      return '-';
    }

    const timestamp = moment(nextAction.timestamp).format('ll');
    switch (nextAction.type) {
      case SubscriptionActionType.RENEWAL:
        return 'Next Cycle on ' + timestamp;

      case SubscriptionActionType.CANCELLATION:
      case SubscriptionActionType.COMPLETION:
        return 'Expiring on ' + timestamp;

      case SubscriptionActionType.START:
        return 'Starting on ' + timestamp;

      case SubscriptionActionType.UPDATE:
        return 'Updating on ' + timestamp;

      case SubscriptionActionType.FREEZE:
        return 'Freezing on ' + timestamp;

      case SubscriptionActionType.UNFREEZE:
        return 'Unfreezing on ' + timestamp;

      default:
        return '-';
    }
  }
}
