import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { ArrayUtils, toPromise } from '@greco-fit/util';
import { Booking, BookingStatus, CalendarEvent } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { BookingService } from '@greco/ngx-booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { AccountLinkingService } from '@greco/web-account-linking';
import moment from 'moment';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class DashboardBookingsService {
  constructor(
    private http: HttpClient,
    private userSvc: UserService,
    private bookingSvc: BookingService,
    private contactService: ContactService,
    private linkedAccountSvc: AccountLinkingService
  ) {}

  bookings = new Map<number, Booking[]>();
  loadingBookings = false;

  selectedEvent$ = new ReplaySubject<(CalendarEvent & { userBooking?: Booking }) | null>(1);
  selectedDate$ = new ReplaySubject<Date | null>(1);
  communities$ = new ReplaySubject<Community[]>(1);
  community$ = new ReplaySubject<Community>(1);
  activeDate$ = new ReplaySubject<[Date, MatCalendar<Date>]>(1);
  user$ = this.userSvc.user$;

  waitlistEvents$ = combineLatest([this.user$, this.community$]).pipe(
    switchMap(([user, _community]) => {
      if (!user?.id) return [];
      return this.bookingSvc.getUserWaitlistedEvents(user.id /*community.id */);
    })
  );

  bookings$: Observable<Booking[] | null> = combineLatest([
    this.userSvc.authUser$.pipe(
      tap(async user => {
        this.bookings.clear();

        const communities = (await this.contactService.getUserContacts(user?.uid)).map(contact => contact.community);
        this.communities$.next(communities);

        if (communities.length) {
          this.community$.next(communities.length > 1 ? communities.find(c => c.id !== 'com_greco') : communities[0]);
        }
      })
    ),
    this.community$, // .pipe(tap(() => this.bookings.clear())),
    this.selectedDate$.pipe(startWith(null)),
    this.activeDate$.pipe(startWith([null, null])),
  ]).pipe(
    tap(() => (this.loadingBookings = true)),
    tap(async ([user, _community, _selectedDate, [date, calendar]]) =>
      date && calendar && !this.bookings.has(moment(date).startOf('month').toDate().getTime())
        ? this.getMonthBookings(user, date, calendar)
        : null
    ),
    switchMap(async ([user, community, date]) =>
      date
        ? this.getBookingsBetweenAt(date)
        : [
            ...((await this.getNextBookings(user, community.id, 3)) || []),
            //...(community?.parent?.id ? (await this.getNextBookings(community.parent.id, 3)) || [] : []),
          ].sort((a, b) => moment(a.event.startDate).diff(moment(b.event.startDate)))
    ),
    map(bookings =>
      bookings.map(booking => ({
        ...booking,
        event: {
          ...booking.event,
          resourceAssignments: booking.event.resourceAssignments
            .filter(assignment => assignment.resource?.type === 'ROOM' || assignment.resource?.type === 'PERSON')
            .sort((a, b) => {
              // Put types rooms first, then people
              if (a.resource?.type === 'ROOM' && b.resource?.type === 'PERSON') return -1;
              if (a.resource?.type === 'PERSON' && b.resource?.type === 'ROOM') return 1;
              return 0;
            }),
        },
      }))
    ),
    tap(() => (this.loadingBookings = false)),
    shareReplay(1)
  );

  userBookings$ = combineLatest([this.user$, this.bookings$]).pipe(
    map(([user, bookings]) => bookings?.filter(booking => booking.user.id === user?.id))
  );

  linkedBookings$ = combineLatest([this.user$, this.bookings$]).pipe(
    map(([user, bookings]) => bookings?.filter(booking => booking.user.id !== user?.id)),
    map(bookings => {
      if (!bookings) return {};
      return ArrayUtils.groupBy(bookings, booking => booking.user.id);
    }),
    map(grouped =>
      Object.keys(grouped).reduce((acc, userId) => {
        acc.push({
          userId,
          bookings: grouped[userId],
        });
        return acc;
      }, [] as { userId: string; bookings: Booking[] }[])
    )
  );

  async getMonthBookings(user: any, date: Date, calendar: any) {
    const bookings = await this.bookingSvc.getByDate({
      userId: user?.uid,
      communityId: undefined, // community.id,
      startDate: moment(date).startOf('month').toDate(),
      endDate: moment(date).endOf('month').toDate(),
      includeLinkedAccountBookings: true,
    });

    // if (user?.uid) {
    //   const linkedAccounts = await this.linkedAccountSvc.getChildAccounts(user.uid);

    //   await Promise.all(
    //     linkedAccounts.map(async account => {
    //       const accountBookings = await this.bookingSvc.getByDate({
    //         userId: account?.id,
    //         communityId: undefined, // community.id,
    //         startDate: moment(date).startOf('month').toDate(),
    //         endDate: moment(date).endOf('month').toDate(),
    //       });

    //       accountBookings.forEach(booking => bookings.push(booking));
    //     })
    //   );
    // }

    // Also include parent bookings if the community is a child
    // if (community?.parent?.id) {
    //   const parentBookings = await this.bookingSvc.getByDate({
    //     userId: user?.uid,
    //     communityId: community.parent.id,
    //     startDate: moment(date).startOf('month').toDate(),
    //     endDate: moment(date).endOf('month').toDate(),
    //   });
    //   bookings.push(...parentBookings);
    // }

    // Iterate over each day in the month and add bookings to the map
    Array.from({ length: moment().daysInMonth() }, (_, i) =>
      moment(date).startOf('month').add(i, 'days').toDate()
    ).forEach(day => {
      this.bookings.set(
        day.getTime(),
        bookings.filter(
          b =>
            [BookingStatus.CONFIRMED, BookingStatus.PENDING, BookingStatus.CHECKED_IN].includes(b.status as any) &&
            moment(b.event.startDate).isSame(day, 'day')
        )
      );
    });

    calendar.monthView._init();
  }

  async getNextBookings(user: any, _communityId: string, limit: number = 3) {
    const bookings: Booking[] = [];

    const userBookings = await toPromise(
      this.http.get<Booking[]>(`/api/bookings/events/current-user-bookings`, {
        params: { /*communityId,*/ limit: limit.toString() },
      })
    );

    userBookings.forEach(booking => bookings.push(booking));

    return bookings;
  }

  getBookingsBetweenAt(startDate: Date) {
    return this.bookings.get(startDate.getTime()) || [];
  }

  hasBooking = (date: Date, view: 'month' | 'year' | 'multi-year') => {
    return view === 'month' && this.bookings.get(date.getTime())?.length ? ['has-bookings'] : [];
  };
}
