import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { ProductAgreementUsage } from '@greco/sales-products';

@Injectable()
export class ProductAgreementAddonsService {
  constructor(private http: HttpClient) {}

  async getManyByProdAddonId(prodAddonId: string) {
    return await toPromise(
      this.http.get<ProductAgreementUsage[]>(`/api/product_agreement_usage`, { params: { prodAddonId } })
    );
  }
}
