import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, NgZone, OnInit, Optional, Self } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Schedule } from '@greco-fit/scaffolding';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as moment from 'moment';
import { RRule } from 'rrule';
import { delay, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AbstractField } from '../abstract-field';

// tslint:disable-next-line: variable-name
const FrequencyValidator: ValidatorFn = control => (control.value ? (['once', 'yearly', 'monthly', 'weekly', 'daily'].includes(control.value) ? null : { frequency: true }) : null);
// tslint:disable-next-line: variable-name
const RRuleValidator: ValidatorFn = control => {
  if (!control.value) return null;
  try {
    RRule.fromString(control.value);
    return null;
  } catch {
    return { rrule: true };
  }
};

function getFormErrors(form: FormGroup): ValidationErrors {
  return Object.entries(form.controls || {}).reduce((acc, [key, ctrl]) => ({ ...acc, ...(Object.keys(ctrl.errors || {}).length ? { [key]: ctrl.errors } : {}) }), {});
}

@Component({
  selector: 'greco-schedule-input',
  templateUrl: './schedule-input.component.html',
  styleUrls: ['./schedule-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ScheduleInputComponent }]
})
export class ScheduleInputComponent extends AbstractField<Schedule> implements OnInit {
  controlType = 'schedule-input';

  get empty() {
    return !this.getFocusElement()?.value;
  }

  get errorState() {
    const rootErrors = this.ngControl && !this.ngControl.pristine && Object.keys(this.ngControl.errors || {}).length > 0 ? this.ngControl.errors : {};
    const formErrors = this.form.dirty ? getFormErrors(this.form) : {};
    const errors = { ...rootErrors, ...formErrors };
    return Object.keys(errors).length > 0 ? errors : null;
  }

  form = this.formBuiler.group({
    start: [null],
    frequency: ['once', [Validators.required, FrequencyValidator]],
    until: [null],
    rrule: [null, RRuleValidator]
  });

  expanded$ = this.form.get('frequency').valueChanges.pipe(
    startWith(this.form.value.frequency),
    map(frequency => frequency !== 'once')
  );

  constructor(@Optional() @Self() ngControl: NgControl, focusMonitor: FocusMonitor, private ref: ElementRef, private formBuiler: FormBuilder, private zone: NgZone) {
    super(ngControl, focusMonitor);

    const valueOf = (v: Schedule) => (v ? '' + (v.date?.toMillis() || null) + (v.frequency || null) + (v.rrule || null) + (v.until?.toMillis() || null) : null);

    this.valueWritten
      .pipe(
        takeUntil(this._onDestroy),
        delay(0),
        distinctUntilChanged((a, b) => valueOf(a) === valueOf(b)),
        tap(value => {
          this.form.reset({
            start: value?.date?.toDate() || null,
            frequency: value?.frequency || 'once',
            until: value?.until?.toDate() || null,
            rrule: value?.rrule
          });
          this.ngControl?.reset?.(value);
        }),
        switchMap(() => this.form.valueChanges),
        tap(value =>
          this._updateValue(
            value.start && value.frequency
              ? {
                  until: value.until ? firebase.firestore.Timestamp.fromDate(moment(value.until).startOf('day').toDate()) : null,
                  date: firebase.firestore.Timestamp.fromDate(moment(value.start).startOf('day').toDate()),
                  frequency: value.frequency,
                  rrule: value.rrule || null
                }
              : null
          )
        )
      )
      .subscribe();
  }

  protected getFocusElement() {
    return this.ref.nativeElement.querySelector('#main-input');
  }
}
