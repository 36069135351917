import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { Signature } from '@greco/identity-users';
import { CreateSignatureDto } from '@greco/nestjs-identity-users';

@Injectable()
export class SignatureService {
  constructor(private http: HttpClient) {}

  // @Get(':signature')
  async getOne(userId: string, ignoreError = true): Promise<Signature> {
    return toPromise(
      this.http.get<Signature>(`/api/signature/${userId}`, {
        headers: { 'X-GrecoIgnoreErrors': '' + ignoreError },
      })
    );
  }

  // @Post()
  async create(dto: CreateSignatureDto): Promise<Signature> {
    return await toPromise(this.http.post<Signature>('/api/signature', dto));
  }

  // @Put(:userId)
  async update(userId: string, signature: string): Promise<Signature> {
    return toPromise(this.http.put<Signature>(`/api/signature/${userId}`, signature));
  }
}
