import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { DefaultSaleCategoryDto } from '@greco/nestjs-sales-purchases';
import type { CreateSaleCategoryDto, UpdateSaleCategoryDto } from '@greco/sales-purchases';
import { DefaultSaleCategory, SaleCategory } from '@greco/sales-purchases';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { Pagination } from 'nestjs-typeorm-paginate';

@Injectable()
export class SaleCategoryService {
  constructor(private http: HttpClient) {}

  //@Get()
  async getMany(accountId?: string, search?: string) {
    return toPromise(
      this.http.get<SaleCategory[]>('/api/sale-category', {
        params: { accountId: accountId || '', search: search || '' },
      })
    );
  }

  //@Get('secured')
  async getManySecured(accountId?: string, search?: string) {
    return toPromise(
      this.http.get<SaleCategory[]>('/api/sale-category/secured', {
        params: { accountId: accountId || '', search: search || '' },
      })
    );
  }

  //@Get(default/:accountId)
  async getAccountDefaults(accountId: string) {
    return toPromise(this.http.get<DefaultSaleCategory[]>(`/api/sale-category/default/${accountId}`));
  }
  //@Get(default/:accountId/:type)
  async getAccountDefaultByType(accountId: string, type: string) {
    return toPromise(this.http.get<DefaultSaleCategory>(`/api/sale-category/default/${accountId}/${type}`));
  }

  //@Get(:saleCategoryId)
  async getSaleCategory(saleCategoryId: string) {
    return toPromise(this.http.get<SaleCategory>('/api/sale-category/' + saleCategoryId));
  }

  //Get(paginate/:accountId)
  paginate(
    query: RequestQueryBuilder,
    accountId: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<SaleCategory>> {
    return toPromise(
      this.http.get<PaginatedDto<SaleCategory>>(`/api/sale-category/paginate/${accountId}`, {
        params: {
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get(filterStaff)
  async filterStaff(query: string, excludedIds?: string[], communityId?: string): Promise<User[]> {
    const qb = RequestQueryBuilder.create({
      search: {
        $and: [
          { $or: [{ displayName: { $contL: query } }, { email: { $contL: query } }] },
          ...(excludedIds?.length ? [{ id: { $notin: excludedIds } }] : []),
        ],
      },
    });
    const data = await toPromise(
      this.http.get<Pagination<User>>('/api/sale-category/filterStaff', {
        params: {
          ...qb.queryObject,
          ...(communityId ? { communityId } : {}),
          limit: '10',
          page: '1',
        },
      })
    );

    return data.items;
  }

  // @Post()
  async createSaleCategory(dto: CreateSaleCategoryDto): Promise<SaleCategory> {
    return await toPromise(this.http.post<SaleCategory>(`/api/sale-category`, dto));
  }

  // @Post(default/:accountId)
  async setAccountDefault(accountId: string, dto: DefaultSaleCategoryDto): Promise<DefaultSaleCategory> {
    return await toPromise(this.http.post<DefaultSaleCategory>(`/api/sale-category/default/${accountId}`, dto));
  }

  // @Patch(:saleCategoryId)
  async updateSaleCategory(saleCategoryId: string, dto: UpdateSaleCategoryDto): Promise<SaleCategory> {
    return await toPromise(this.http.patch<SaleCategory>(`/api/sale-category/${saleCategoryId}`, dto));
  }
  //@Patch()
  async syncDefaults(accountId: string) {
    return await toPromise(this.http.patch(`/api/sale-category`, { accountId }));
  }
}
