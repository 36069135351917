import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bodyExpansion } from '@greco-fit/scaffolding/ngx';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import type { UpdateUserDto } from '@greco/nestjs-identity-users-util';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { heightExpansion } from '@greco/ui-animations';
import { BehaviorSubject, combineLatest, interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WebcamDialog } from '../../dialogs';

@Component({
  selector: 'greco-user-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  animations: [bodyExpansion, heightExpansion],
})
export class UserProfileDetailsComponent implements OnChanges {
  constructor(
    private formBuilder: FormBuilder,
    private userSvc: UserService,
    private snacks: MatSnackBar,
    private dialog: MatDialog
  ) {}

  @Input() preventReload = false;
  @Input() readonly = false;
  @Input() alwaysOpen = false;

  @Input() danger = false;
  @Input() label = 'Profile Information';

  @PropertyListener('user') user$ = new BehaviorSubject<User | undefined>(undefined);
  @Input() user!: User;
  @Output() saved = new EventEmitter();

  @HostBinding('class.expanded') public expanded = false;

  isUser$ = combineLatest([this.userSvc.user$, this.user$]).pipe(
    map(([u0, u1]) => {
      return u0?.id === u1?.id && !u0?.isSuperAdmin;
    })
  );

  nowDate = new Date();

  resetValue: any = {
    name: '',
    email: '',
    friendlyName: '',
    phoneNumber: '',
    address: null,
    birthday: null,
    gender: null,
    genderOther: null,
    photoURL: null,
    emergencyContactName: null,
    emergencyPhoneNumber: '',
    // emergencyContactEmail: null,
    emergencyContactRelationship: null,
  };

  profilePictureFile?: File;
  form = this.formBuilder.group({
    name: ['', Validators.required],
    email: [''],
    friendlyName: [''],
    phoneNumber: ['', Validators.required],
    address: [null, Validators.required],
    birthday: [null, Validators.required],
    gender: [null, Validators.required],
    genderOther: [null],
    photoURL: [null],
    emergencyContactName: [null, Validators.required],
    emergencyPhoneNumber: ['', Validators.required],
    // emergencyContactEmail: [null],
    emergencyContactRelationship: [null, Validators.required],
  });

  missingFields$ = combineLatest([
    interval(1000).pipe(startWith(-1)),
    this.form.valueChanges.pipe(startWith(null)),
    this.form.statusChanges.pipe(startWith(null)),
  ]).pipe(
    map(() => {
      const {
        name,
        phoneNumber,
        address,
        birthday,
        gender,
        genderOther,
        emergencyContactName,
        emergencyPhoneNumber,
        emergencyContactRelationship,
      } = this.form.controls;

      const result = [];

      if (name.touched && name.invalid) result.push('Name');
      if (phoneNumber.touched && phoneNumber.invalid) result.push('Phone Number');
      if (address.touched && address.invalid) result.push('Address');
      if (birthday.touched && birthday.invalid) result.push('Birthday');
      if ((gender.touched && gender.invalid) || (gender.value === 'Other' && genderOther.invalid))
        result.push('Gender');
      if (emergencyContactName.touched && emergencyContactName.invalid) result.push('Emergency Contact Name');
      if (emergencyPhoneNumber.touched && emergencyPhoneNumber.invalid) result.push('Emergency Phone Number');
      if (emergencyContactRelationship.touched && emergencyContactRelationship.invalid)
        result.push('Emergency Contact Relationship');

      return result.join(', ');
    })
  );

  save = async () => {
    if (this.readonly) return;

    try {
      const data: UpdateUserDto = {};

      if (this.form.value.name !== this.user?.displayName) {
        data.displayName = this.form.value.name;
      }

      if (this.form.value.friendlyName !== (this.user?.friendlyName || '')) {
        data.friendlyName = this.form.value.friendlyName;
      }

      if (this.form.value.phoneNumber !== (this.user?.phoneNumber || '')) {
        data.phoneNumber = this.form.value.phoneNumber;
      }

      if ((this.form.value.address?.formatted || '') !== (this.user?.address?.formatted || '')) {
        data.address = this.form.value.address;
      }

      if (this.form.value.birthday?.valueOf() !== this.user?.birthday?.valueOf()) {
        data.birthday = this.form.value.birthday;
      }

      const gender =
        this.form.value.gender?.valueOf() === 'Other'
          ? this.form.value.genderOther?.valueOf() || this.form.value.gender?.valueOf()
          : this.form.value.gender?.valueOf();
      if (gender !== this.user?.gender?.valueOf()) {
        data.gender = gender;
      }

      if (this.form.value.emergencyContactName !== (this.user?.emergencyContactName || '')) {
        data.emergencyContactName = this.form.value.emergencyContactName;
      }

      if (this.form.value.emergencyPhoneNumber !== (this.user?.emergencyPhoneNumber || '')) {
        data.emergencyPhoneNumber = this.form.value.emergencyPhoneNumber;
      }

      // if (this.form.value.emergencyContactEmail !== (this.user?.emergencyContactEmail || '')) {
      //   data.emergencyContactEmail = this.form.value.emergencyContactEmail;
      // }

      if (this.form.value.emergencyContactRelationship !== (this.user?.emergencyContactRelationship || '')) {
        data.emergencyContactRelationship = this.form.value.emergencyContactRelationship;
      }

      const shouldUpdate = Object.keys(data).length > 0;
      if (!shouldUpdate && !this.profilePictureFile) return;

      if (shouldUpdate) {
        this.user = await this.userSvc.update(this.user.id, data);
      }

      if (this.profilePictureFile) {
        const formData = new FormData();
        formData.append('file', this.profilePictureFile);
        this.user = await this.userSvc.uploadUserPicture(this.user.id, formData);
      }

      this.saved.emit(this.user);
      this.reset();

      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });

      if (!this.preventReload) location.reload();
    } catch (err) {
      this.snacks.open('' + 'Please input complete info.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.previousValue !== changes.user?.currentValue) {
      this.reset();
    }
  }

  private reset() {
    this.nowDate = new Date();
    this.resetValue = {
      name: this.user?.displayName,
      email: this.user?.email || this.user?.contactEmail,
      friendlyName: this.user?.friendlyName || '',
      phoneNumber: this.user?.phoneNumber || '',
      address: this.user?.address || null,
      gender: this.user?.gender
        ? ['Male', 'Female', 'Non-binary', 'Transgender', 'Intersex', 'Prefer not to say'].includes(this.user?.gender)
          ? this.user?.gender
          : 'Other'
        : null,
      genderOther: this.user?.gender
        ? ['Male', 'Female', 'Non-binary', 'Transgender', 'Intersex', 'Prefer not to say'].includes(this.user?.gender)
          ? null
          : this.user?.gender
        : null,
      birthday: this.user?.birthday || null,
      photoURL: this.user?.photoURL || null,
      emergencyContactName: this.user?.emergencyContactName || null,
      emergencyPhoneNumber: this.user?.emergencyPhoneNumber || '',
      // emergencyContactEmail: this.user?.emergencyContactEmail || null,
      emergencyContactRelationship: this.user?.emergencyContactRelationship || null,
    };

    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }

  setProfilePicture(file: File | null) {
    if (file) {
      this.profilePictureFile = file;
      this.form.setValue({ ...this.form.value, photoURL: URL.createObjectURL(file) });
    } else {
      this.profilePictureFile = undefined;
      this.form.setValue({ ...this.form.value, photoURL: this.user?.photoURL || null });
    }
  }

  async takePhoto() {
    const image = await toPromise(this.dialog.open(WebcamDialog).afterClosed());
    this.setProfilePicture(image);
  }
}
