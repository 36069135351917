import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { SafePipeModule } from 'safe-pipe';
import { InfoCardImageComponent } from './components/image/image.component';
import { InfoCardSelectComponent } from './components/select/select.component';
import { InfoCardComponent } from './info-card.component';

@NgModule({
  imports: [CommonModule, MatRippleModule, RouterModule, MatMenuModule, MatCheckboxModule, MatRadioModule, MatIconModule, SafePipeModule],
  declarations: [InfoCardComponent, InfoCardImageComponent, InfoCardSelectComponent],
  exports: [InfoCardComponent, InfoCardImageComponent, InfoCardSelectComponent]
})
export class InfoCardComponentModule {}
