import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';

export enum AgreementType {
  CHECKBOX = 'CHECKBOX',
  AUTO_CHECKBOX = 'AUTO_CHECKBOX',
  DIGITAL_SIGNATURE = 'DIGITAL_SIGNATURE',
}

export enum AgreementStatus {
  SIGNED = 'SIGNED',
  UNSIGNED = 'UNSIGNED',
}

export interface CommunityAgreement {
  id: string;
  communityId: string;
  community?: Community;
  title: string;
  text: string;
  agreementType: AgreementType;
  archivedDate?: Date;
  created: Date;
}

export interface CommunityAgreementUsage {
  //This will be used and extended by modules that want to use agreements, instead of using agreements directly
  id: string;
  type: string;
  agreementId: string;
  agreement?: CommunityAgreement;
  url: string;
  label: string;
  created: Date;
}

export interface UserCommunityAgreement {
  id: string;
  created: Date;
  type: string;

  archivedOn?: Date | null;

  status: AgreementStatus;

  userId: string;
  user?: User;

  signedAgreementTitle: string;
  signedAgreementText: string;
  signature?: string;

  agreementId: string;
  agreement?: CommunityAgreement;

  details?: any;
}
