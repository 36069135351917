<!--Unlocked Videos-->
<greco-collapsible-section
  id="videos_section"
  [header]="{ title: 'My Videos', icon: 'reset_tv' }"
  [expanded]="expanded"
>
  <ng-container header>
    <button
      style="height: 32px; background-color: var(--accent2-color); color: black"
      mat-flat-button
      [matMenuTriggerFor]="menu"
      (click)="$event.stopImmediatePropagation()"
    >
      <mat-icon>add</mat-icon>
      Get Videos
    </button>
    <mat-menu #menu>
      <button mat-menu-item routerLink="/workouts/go" matTooltip="Go to LF3 Go">LF3 Go</button>
      <button mat-menu-item routerLink="/workouts/collections" matTooltip="Go to Programs">Programs</button>
    </mat-menu>
  </ng-container>
  <greco-filter-bar [hideSeparator]="true" [filterOptions]="filterOptions" (changed)="filters$.next($event[1])">
  </greco-filter-bar>
  <greco-user-video-table *ngIf="filters$ | async as query" [query]="query" [user]="user"></greco-user-video-table>
</greco-collapsible-section>
