<ng-container *grecoLet="courses$ | async as courses">
  <greco-collapsible-section [header]="{ title: 'Available', icon: 'event_available' }" [expanded]="true">
    <div class="grid">
      <ng-container *grecoLet="courses?.availableToRegister as availableToRegister">
        <ng-container *ngIf="availableToRegister?.length; else noCourses">
          <ng-container *ngFor="let course of availableToRegister">
            <greco-courses-card [course]="course.course" [registrations]="course.registrations"></greco-courses-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </greco-collapsible-section>

  <greco-collapsible-section [header]="{ title: 'My Bookings', icon: 'perm_contact_calendar' }" [expanded]="false">
    <div class="grid">
      <ng-container *grecoLet="courses?.myBookings as myBookings">
        <ng-container *ngIf="myBookings?.length; else noCourses">
          <ng-container *ngFor="let course of myBookings">
            <greco-courses-card [course]="course.course" [registrations]="course.registrations"></greco-courses-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </greco-collapsible-section>

  <greco-collapsible-section [header]="{ title: 'Fully Booked', icon: 'event_busy' }" [expanded]="false">
    <div class="grid">
      <ng-container *grecoLet="courses?.fullyBooked as fullyBooked">
        <ng-container *ngIf="fullyBooked?.length; else noCourses">
          <ng-container *ngFor="let course of fullyBooked">
            <greco-courses-card [course]="course.course" [registrations]="course.registrations"></greco-courses-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </greco-collapsible-section>
</ng-container>

<ng-template #noCourses>
  <div style="display: flex">
    <mat-icon>error_outline</mat-icon>
    <div style="display: flex; flex-direction: column; justify-content: center; margin-left: 8px">
      <i>There are no courses to display</i>
    </div>
  </div>
</ng-template>
