import { BaseDto } from '@greco/util-dto';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class AddCollectionVideoDto extends BaseDto {
  @IsString()
  @IsNotEmpty()
  subCollectionId: string;

  @IsString()
  @IsNotEmpty()
  communityVideoId: string;

  @IsString()
  @IsOptional()
  titleOverride?: string;

  @IsString()
  @IsOptional()
  descriptionOverride?: string;
}
