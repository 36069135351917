import { User } from '@greco/identity-users';
export enum AccountLinkStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
}

export enum AccountLinkPrivilege {
  ALL = 'ALL',
  USE_PERKS = 'USE_PERKS',
  // BOOK = 'BOOK',
  // USE_PAYMENT_METHODS = 'USE_PAYMENT_METHODS',
  // ACCESS_SUB_ACCOUNTS = 'ACCESS_SUB_ACCOUNTS'
}
export interface AccountLink {
  id: string;
  status: AccountLinkStatus;
  //the user that has given access to their account
  accountId: string;
  account?: User;
  //the user that has been given access to the above account
  accessorId: string;
  accessor?: User;

  canUsePerks: boolean;
  // canBook: boolean
  // canPurchase: boolean
  // canAccessChildren: boolean
}
