<ng-container *grecoLet="selectedSpot$ | async as selectedSpot">
  <greco-item
    *ngIf="event?.requirements?.spotBooking?.spotBookingEnabled && event?.requirements?.spotBooking?.room"
    [lines]="false"
  >
    <mat-icon item-start>person_pin</mat-icon>

    <div style="width: 100%">
      <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">
        <strong>{{ event?.requirements?.spotBooking?.room?.name }} - Spot Selection</strong>
      </p>

      <p
        *ngIf="!selectedSpot; else spotSelected"
        matTooltip="Please select a spot prior to booking."
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
      >
        <small style="color: var(--warn-color)">* Select a spot prior to booking</small>
      </p>

      <ng-template #spotSelected>
        <p
          matTooltip="Please complete your profile prior to booking."
          style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
        >
          <small>
            You've selected
            {{
              selectedSpot?.spotName ||
                ((selectedSpot?.spotNumber || 0) > 0 ? 'Spot ' + selectedSpot?.spotNumber : 'general')
            }}
          </small>
        </p>
      </ng-template>
    </div>

    <div item-end style="display: flex; gap: 8px; align-items: center">
      <button
        mat-button
        style="height: 32px; width: 100%; margin-left: auto; padding: 0 12px"
        [color]="selectedSpot ? 'primary' : 'accent'"
        (click)="emitBottomSheetOpened(spotSelectionSheet)"
      >
        {{ selectedSpot ? 'Change' : 'Select' }} Spot
      </button>

      <mat-icon *ngIf="selectedSpot" style="color: var(--success-color)"> check_circle </mat-icon>
    </div>
  </greco-item>

  <ng-template #spotSelectionSheet>
    <div class="header">
      <h2 #header style="margin-bottom: 0">Select a Spot</h2>

      <button mat-icon-button (click)="bottomSheet.dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <alt-room-spot-picker
      [event]="event"
      [selectedSpotId]="selectedSpot?.spotId"
      (spotSelected)="selectedSpotUpdated($event)"
    ></alt-room-spot-picker>

    <button *ngIf="selectedSpotId" mat-flat-button class="confirm" color="primary" (click)="bottomSheet.dismiss()">
      Confirm Selection
    </button>
  </ng-template>
</ng-container>
