import { SecurityResource } from '@greco/security';

export enum UserPaymentMethodResourceAction {
  READ = 'READ',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET_DEFAULT = 'SET_DEFAULT',
}

export const UserPaymentMethodResource: SecurityResource = {
  key: 'finance_payments_payment_method',
  module: 'Finance',

  title: 'User Payment Method',
  description: 'Manage User payment methods',

  context: class {
    userId?: string;
  },

  actions: [
    { key: UserPaymentMethodResourceAction.READ, title: 'Read', description: 'Ability to read/view payment methods' },
    { key: UserPaymentMethodResourceAction.ADD, title: 'Add', description: 'Ability to add a new payment method' },
    { key: UserPaymentMethodResourceAction.REMOVE, title: 'Remove', description: 'Ability to remove a payment method' },
    {
      key: UserPaymentMethodResourceAction.SET_DEFAULT,
      title: 'Set Default',
      description: "Ability to specify a user's default payment method",
    },
  ],
};
