<div style="position: sticky; top: 56px; z-index: 11">
  <greco-filter-bar
    #filterBar
    [calendars]="true"
    [hideSeparator]="true"
    [filterOptions]="filterOptions"
    (changed)="filters$.next($event[1])"
    (loaded)="setInitialFilters(filterBar)"
    style="background-color: var(--background-color, white)"
  >
    <greco-calendar-picker
      calendarPicker
      style="height: 45px; margin-top: -36px; margin-right: 12px; pointer-events: all"
      [selectAll]="true"
      [showCommunity]="true"
      [placeholder]="'All Calendars'"
      [calendarIdToAdd]="calendarIdToAdd"
      [communityId]="communityIds$ | async"
      [initialCalendarIds]="initialCalendars"
      (refresh)="refresh()"
    ></greco-calendar-picker>

    <div
      *grecoLet="calendarsAndCommunities$ | async as calendarsAndCommunities"
      style="display: flex; align-items: center; justify-content: flex-end; height: 32px"
    >
      <!-- Community Level -->
      <mat-menu #communityMenu xPosition="before">
        <p style="margin: 8px 16px">
          <small><b>Communities</b></small>
        </p>

        <ng-container *ngIf="calendarsAndCommunities?.length || 0 > 0; else noCommunities">
          <ng-container *ngFor="let selection of calendarsAndCommunities">
            <button mat-menu-item [matMenuTriggerFor]="calendarMenu">
              <span>{{ selection.community.name }}</span>
            </button>

            <!-- Calendar Level -->
            <mat-menu #calendarMenu>
              <p style="margin: 8px 16px">
                <small><b>Calendars</b></small>
              </p>

              <ng-container *ngIf="selection.calendars?.length; else noCalendars">
                <ng-container *ngFor="let calendar of selection.calendars">
                  <button
                    *ngIf="calendar.communityId === selection.community.id"
                    mat-menu-item
                    [matMenuTriggerFor]="templateMenu"
                  >
                    <span>{{calendar.title}}</span>
                  </button>

                  <!-- Template Level -->
                  <mat-menu #templateMenu xPosition="before">
                    <p style="margin: 8px 16px">
                      <small><b>Templates</b></small>
                    </p>
                    <ng-container *ngIf="calendar?.eventTemplates?.length || 0 > 0; else noTemplates">
                      <ng-container *ngFor="let template of calendar.eventTemplates">
                        <!-- If the tempalte has 1 tag don't show tag level -->
                        <ng-container
                          *ngIf="template.resourceTags && sharedTags(selection.community.id, template).length === 1; else multipleTags"
                        >
                          <button
                            mat-menu-item
                            (click)="createEventFromTemplate(selection.community.id, template.id, calendar.id, sharedTags(selection.community.id, template)[0])"
                          >
                            <span>{{template.title}}</span>
                          </button>
                        </ng-container>
                        <ng-template #multipleTags>
                          <button mat-menu-item [mat-menu-trigger-for]="tagMenu">
                            <span>{{template.title}}</span>
                          </button>

                          <!-- ResourceTag Level -->
                          <mat-menu #tagMenu xPosition="before">
                            <p style="margin: 8px 16px">
                              <small><b>Tags</b></small>
                            </p>
                            <ng-container *ngFor="let resourceTag of sharedTags(selection.community.id, template)">
                              <ng-container *ngIf="resourceHasTag(selection.community.id, resourceTag)">
                                <button
                                  mat-menu-item
                                  (click)="createEventFromTemplate(selection.community.id, template.id, calendar.id, resourceTag)"
                                >
                                  <span>{{resourceTag.label}}</span>
                                </button>
                              </ng-container>
                            </ng-container>
                          </mat-menu>
                        </ng-template>
                      </ng-container>
                    </ng-container>

                    <ng-template #noTemplates>
                      <p style="margin: 18px 18px 12px; padding-right: 12px"><i>No Templates</i></p>
                    </ng-template>
                  </mat-menu>
                </ng-container>
              </ng-container>

              <ng-template #noCalendars>
                <p style="margin: 18px 18px 12px; padding-right: 12px"><i>No Calendars</i></p>
              </ng-template>
            </mat-menu>
          </ng-container>
        </ng-container>

        <ng-template #noCommunities>
          <p style="margin: 18px 18px 12px; padding-right: 12px"><i>No Communities</i></p>
        </ng-template>
      </mat-menu>

      <div style="display: flex; flex-direction: row">
        <button mat-stroked-button [matMenuTriggerFor]="communityMenu" style="height: 32px" color="primary">
          New Event
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </div>
    </div>
  </greco-filter-bar>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>

<ng-container *grecoLet="calendarsAndCommunities$ | async as calendarsAndCommunities">
  <div
    #communityMenuInlineTrigger="matMenuTrigger"
    [matMenuTriggerFor]="communityMenuInline"
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
  ></div>
  <mat-menu #communityMenuInline>
    <p style="margin: 8px 16px">
      <small><b>Communities</b></small>
    </p>
    <ng-container *ngIf="calendarsAndCommunities?.length || 0 > 0; else noCommunities">
      <ng-container *ngFor="let selection of calendarsAndCommunities">
        <button mat-menu-item [matMenuTriggerFor]="calendarMenu">
          <span>{{ selection.community.name }}</span>
        </button>

        <!-- Calendar Level -->
        <mat-menu #calendarMenu>
          <p style="margin: 8px 16px">
            <small><b>Calendars</b></small>
          </p>

          <ng-container *ngIf="selection.calendars?.length; else noCalendars">
            <ng-container *ngFor="let calendar of selection.calendars">
              <button
                *ngIf="calendar.communityId === selection.community.id"
                mat-menu-item
                [matMenuTriggerFor]="templateMenu"
              >
                <span>{{calendar.title}}</span>
              </button>

              <!-- Template Level -->
              <mat-menu #templateMenu xPosition="before">
                <p style="margin: 8px 16px">
                  <small><b>Templates</b></small>
                </p>
                <ng-container *ngIf="calendar?.eventTemplates?.length || 0 > 0; else noTemplates">
                  <ng-container *ngFor="let template of calendar.eventTemplates">
                    <!-- If the tempalte has 1 tag don't show tag level -->
                    <ng-container
                      *ngIf="template.resourceTags && sharedTags(selection.community.id, template).length === 1; else multipleTags"
                    >
                      <button
                        mat-menu-item
                        (click)="createEventFromTemplate(selection.community.id, template.id, calendar.id, sharedTags(selection.community.id, template)[0])"
                      >
                        <span>{{template.title}}</span>
                      </button>
                    </ng-container>
                    <ng-template #multipleTags>
                      <button mat-menu-item [mat-menu-trigger-for]="tagMenu">
                        <span>{{template.title}}</span>
                      </button>

                      <!-- ResourceTag Level -->
                      <mat-menu #tagMenu xPosition="before">
                        <p style="margin: 8px 16px">
                          <small><b>Tags</b></small>
                        </p>
                        <ng-container *ngFor="let resourceTag of sharedTags(selection.community.id, template)">
                          <ng-container *ngIf="resourceHasTag(selection.community.id, resourceTag)">
                            <button
                              mat-menu-item
                              (click)="createEventFromTemplate(selection.community.id, template.id, calendar.id, resourceTag)"
                            >
                              <span>{{resourceTag.label}}</span>
                            </button>
                          </ng-container>
                        </ng-container>
                      </mat-menu>
                    </ng-template>
                  </ng-container>
                </ng-container>

                <ng-template #noTemplates>
                  <p style="margin: 18px 18px 12px; padding-right: 12px"><i>No Templates</i></p>
                </ng-template>
              </mat-menu>
            </ng-container>
          </ng-container>

          <ng-template #noCalendars>
            <p style="margin: 18px 18px 12px; padding-right: 12px"><i>No Calendars</i></p>
          </ng-template>
        </mat-menu>
      </ng-container>
    </ng-container>

    <ng-template #noCommunities>
      <p style="margin: 18px 18px 12px; padding-right: 12px"><i>No Communities</i></p>
    </ng-template>
  </mat-menu>

  <greco-events-calendar
    [dayViewGroupBy]="GROUP_BY"
    [startingView]="STARTING_VIEW"
    [fromResourceScheduling]="true"
    [events]="(events$ | async) || []"
    [resourceIds]="(resourceIds$ | async) || []"
    (dateRange)="dates$.next($event)"
    (eventClicked)="eventClicked($event)"
    (mousemove)="positionTrigger($event)"
    (dateClicked)="dateClicked = $event[0];
      communityMenuInlineTrigger.openMenu()"
  ></greco-events-calendar>
</ng-container>
