<!-- @Output() eventClicked = new EventEmitter<CalendarEvent>(true); -->

<!-- @Input() dayCellTemplate?: TemplateRef<any>; -->
<!-- @Input() eventCardTemplate?: TemplateRef<any>; -->
<ng-template #eventListTemplate let-events="events" let-isOpen="isOpen" let-eventClicked="eventClicked">
  <div [@heightExpansion]="isOpen && events.length ? 'expanded' : 'collapsed'">
    <greco-table
      [data]="events || []"
      [highlight]="true"
      [hideEmptyPlaceholder]="true"
      (rowClick)="openBookingDetails($event.meta)"
    >
      <greco-multi-avatar
        *grecoTableCol="''; let event; fitContent: true"
        [style.color]="event.color?.primary"
        [matTooltip]="event.meta.id"
        [images]="getPersonResources(event.meta?.event?.resourceAssignments) | pluck : 'photoURL'"
        [size]="32"
      >
      </greco-multi-avatar>

      <div
        *grecoTableCol="'Event'; let event"
        [class.strikethrough]="
          event.meta?.status === 'CANCELLED' ||
          event.meta?.status === 'NO-SHOW' ||
          event.meta?.status === 'LATE_CANCELLED'
        "
      >
        <h3
          style="margin-bottom: 0"
          *grecoLet="getPersonResources(event.meta?.event?.resourceAssignments) | pluck : 'name' as resources"
        >
          {{ event.title + (resources?.length ? ' with ' + resources?.join(', ') : '') }}
        </h3>
        <p style="margin-bottom: 0">{{ event.start | date : 'shortTime' }} - {{ event.end | date : 'shortTime' }}</p>
      </div>

      <p *grecoTableCol="'Booked-For'; let event">
        <mat-chip-list #chipList aria-label="displayName">
          <mat-chip>
            <div style="width: 24px; height: 24px; margin-right: 8px; margin-left: -4px">
              <greco-user-avatar
                [showOnlineStatus]="false"
                [user]="matchBooking(event.id).user"
                style="width: 24px; height: 24px"
              ></greco-user-avatar>
            </div>
            <ng-container *ngIf="matchBooking(event.id).user.displayName; else noDisplayName">
              {{ matchBooking(event.id).user.displayName }}
            </ng-container>
            <ng-template #noDisplayName>{{ matchBooking(event.id).user.email }}</ng-template>
          </mat-chip>
        </mat-chip-list>
      </p>

      <p *grecoTableCol="'Booked-By'; let event">
        <mat-chip-list #chipList aria-label="displayName">
          <mat-chip>
            <div style="width: 24px; height: 24px; margin-right: 8px; margin-left: -4px">
              <greco-user-avatar
                [showOnlineStatus]="false"
                [user]="matchBooking(event.id).createdBy || null"
                style="width: 24px; height: 24px"
              ></greco-user-avatar>
            </div>
            <ng-container *ngIf="matchBooking(event.id).createdBy?.displayName; else noDisplayName">
              {{ matchBooking(event.id).createdBy?.displayName }}
            </ng-container>
            <ng-template #noDisplayName>{{ matchBooking(event.id).createdBy?.email }}</ng-template>
          </mat-chip>
        </mat-chip-list>
      </p>

      <mat-chip-list *grecoTableCol="'Calendar'; let event">
        <mat-chip style="padding-left: 6px">
          <div style="width: 24px; height: 24px">
            <mat-icon>{{ event.meta.event.calendar?.icon || 'calendar_month' }}</mat-icon>
          </div>
          <span style="margin-left: 6px">{{ event.meta.event.calendar.title }}</span>
        </mat-chip>
      </mat-chip-list>

      <mat-chip-list *grecoTableCol="'Room'; let event">
        <ng-container *grecoLet="getRoom(event.meta?.event) as room">
          <mat-chip *ngIf="room" style="padding-left: 6px" [matTooltip]="room.description || room.name">
            <div style="width: 24px; height: 24px">
              <mat-icon>{{ room.icon || 'living' }}</mat-icon>
            </div>
            <span style="margin-left: 6px">
              {{ room?.name + (event.meta.spotId ? getSpotName(room, event.meta.spotId) : '') }}
            </span>
          </mat-chip>
        </ng-container>
      </mat-chip-list>

      <!-- <div *grecoTableCol="'Booking Option'; let event" style="display: flex; align-items: center; gap: 4px">
        <greco-perk-badge
          [small]="true"
          [shape]="event.meta?.bookingOption?.badge?.shape"
          [icon]="event.meta?.bookingOption?.badge?.icon"
          [color]="event.meta?.bookingOption?.badge?.color"
          [text]="event.meta?.bookingOption?.badge?.text"
          style="margin-right: 8px"
        ></greco-perk-badge>
        <p style="margin-bottom: 0" *ngIf="event.meta?.bookingOption">
          {{ event.meta?.bookingOption?.title }}
        </p>
        <mat-icon
          *ngIf="!event.meta?.bookingOptionConsumed"
          style="width: 18px; height: 18px; font-size: 18px"
          matTooltip="The perk used for this booking can be reused!"
          >restart_alt</mat-icon
        >
      </div> -->

      <!-- <div *grecoTableCol="'Boosters Used'; let event" style="display: flex; align-items: center; gap: 4px">
        <ng-container *ngIf="event.meta?.boostersUsed + event.meta?.boostersPurchased; else noBoosters">
          <greco-perk-badge
            *grecoLet="(boosterConfig$ | async)?.perk as boosterConfig"
            [small]="true"
            [shape]="boosterConfig?.badge?.shape"
            [icon]="boosterConfig?.badge?.icon"
            [color]="boosterConfig?.badge?.color || null"
            [text]="boosterConfig?.badge?.text"
            [quantity]="event.meta?.boostersUsed + event.meta?.boostersPurchased"
            style="margin-right: 8px"
          ></greco-perk-badge>
        </ng-container>
        <ng-template #noBoosters><p>-</p></ng-template>
      </div> -->

      <mat-chip-list *grecoTableCol="'Status'; let event">
        <mat-chip
          [style.background-color]="
            event.meta.status === 'CANCELLED' ||
            event.meta?.status === 'NO-SHOW' ||
            event.meta?.status === 'LATE_CANCELLED'
              ? 'var(--warn-color)'
              : ''
          "
          [style.color]="
            event.meta.status === 'CANCELLED' ||
            event.meta?.status === 'NO-SHOW' ||
            event.meta?.status === 'LATE_CANCELLED'
              ? 'white'
              : ''
          "
        >
          {{ event.meta?.status?.replace('CONFIRMED', 'Booked')?.replace('_', ' ') | titlecase }}
          <!-- <div *ngIf="event?.meta?.status == 'CONFIRMED'; then confirmed; else other"></div>
          <ng-template #confirmed>{{ event.meta?.status?.replace('CONFIRMED', 'Booked') | titlecase }}</ng-template>
          <ng-template #other>{{ event.meta?.status?.replace('_', ' ') | titlecase }}</ng-template> -->
        </mat-chip>
      </mat-chip-list>

      <ng-container *grecoTableCol="''; let event; fitContent: true; stickyEnd: true">
        <div style="display: flex; gap: 8px; align-items: center; justify-content: flex-end">
          <ng-container *ngIf="(mobileBreakpoint$ | async)?.matches; else notMobile">
            <button
              mat-icon-button
              (click)="$event.stopImmediatePropagation(); cancelBooking(event.meta)"
              *ngIf="event.meta.status === 'CONFIRMED' && moment(event.meta.event.startDate).isAfter(now)"
              color="warn"
            >
              <mat-icon>event_busy</mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="
                (event.meta.status === 'CONFIRMED' || event.meta.status === 'CHECKED_IN') &&
                (event.meta.event.zoomMeetingId || event.meta.event.zoomEvent?.meetingID) &&
                moment(event.meta.event.startDate).subtract(10, 'minutes').isBefore(now) &&
                moment(event.meta.event.startDate).add(10, 'minutes').isAfter(now)
              "
              (click)="$event.stopImmediatePropagation(); join(event.meta)"
              color="primary"
            >
              <mat-icon>videocam</mat-icon>
            </button>
            <button mat-icon-button *ngIf="event.meta.status !== 'CONFIRMED'">
              <mat-icon>info</mat-icon>
            </button>
          </ng-container>
          <ng-template #notMobile>
            <button
              *ngIf="event.meta.status === 'CONFIRMED' && moment(event.meta.event.startDate).isAfter(now)"
              mat-icon-button
              [disabled]="cancelling[event.id]"
              (click)="$event.stopImmediatePropagation(); cancelBooking(event.meta)"
              color="warn"
            >
              <mat-icon [class.spin]="cancelling[event.id]">{{
                cancelling[event.id] ? 'loop' : 'event_busy'
              }}</mat-icon>
            </button>
            <button
              *ngIf="
                (event.meta.status === 'CONFIRMED' || event.meta.status === 'CHECKED_IN') &&
                (event.meta.event.zoomMeetingId || event.meta.event.zoomEvent?.meetingID) &&
                moment(event.meta.event.startDate).subtract(10, 'minutes').isBefore(now) &&
                moment(event.meta.event.startDate).add(10, 'minutes').isAfter(now)
              "
              mat-flat-button
              (click)="$event.stopImmediatePropagation(); join(event.meta)"
              style="height: 32px"
              color="primary"
            >
              <mat-icon>videocam</mat-icon>
              <span>Join</span>
            </button>
            <button mat-icon-button>
              <mat-icon>info</mat-icon>
            </button>
          </ng-template>
        </div>
      </ng-container>
    </greco-table>
  </div>
</ng-template>

<!-- TODO: Event click -->
<!-- TODO: Event list template -->
<greco-box id="user-bookings-calendar">
  <greco-calendar
    [loading]="loading"
    [view]="MONTH_VIEW"
    [hideDayView]="true"
    [hideWeekView]="true"
    [events]="(calendarEvents$ | async) || []"
    (dateRange)="dateRange$.next($event)"
    [eventListTemplate]="eventListTemplate"
    showChildEventsToggle="true"
    (showChildEventsChanged)="showChildEvents = $event"
  ></greco-calendar>
</greco-box>
