<greco-simple-dialog [data]="dialogData">
  <ng-container>
    <greco-table [data]="data.coupon.grantedCoupons || []" [highlight]="true">
      <p *grecoTableCol="'Expiry Date'; let grantedCoupons">Date</p>
      <mat-chip-list *grecoTableCol="'Granted To User'; let grantedCoupons">
        <mat-chip *ngIf="grantedCoupons.allUsers" style="padding-left: 8px"
          >{{ grantedCoupons.allUsers + ' Coupon(s) b/w All Users' }}</mat-chip
        >
        <!-- <mat-chip *ngIf="grantedCoupons.individualUser" style="padding-left: 8px"
      >{{ grantedCoupons.individualUser + ' Coupon(s) to Individual(s)' }}</mat-chip
    > -->
        <mat-chip *ngIf="grantedCoupons.unlimitedAssignment" style="padding-left: 8px"
          >{{ grantedCoupons.unlimitedAssignment + ' Unlimited Assignment(s)' }}</mat-chip
        >
      </mat-chip-list>
    </greco-table>
  </ng-container>
</greco-simple-dialog>
