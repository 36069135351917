import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEvent } from '@greco/booking-events';
import { BookingService } from '../../services';

@Component({
  selector: 'greco-add-to-waitlist-dialog',
  templateUrl: './add-to-waitlist.dialog.html',
})
export class AddToWaitlistDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public event: CalendarEvent,
    private ref: MatDialogRef<AddToWaitlistDialog>,
    private bookingSvc: BookingService
  ) {}

  contactControl = new FormControl(null);

  cancel() {
    this.ref.close();
  }

  async add() {
    const userId = this.contactControl.value?.user?.id;
    if (!userId) return;

    try {
      await this.bookingSvc.joinWaitlist({ eventId: this.event.id, userId });
    } catch (err) {
      console.error(err);
    }

    this.ref.close(true);
  }
}
