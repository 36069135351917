import { Pipe, PipeTransform } from '@angular/core';
import { Filter } from '../models';

@Pipe({ name: 'filterImageUrl' })
export class FilterImageUrlPipe implements PipeTransform {
  transform(filter: Filter, value: any): string | null | undefined {
    return 'getImageUrl' in filter && typeof (filter as any)['getImageUrl'] === 'function'
      ? (filter as any)['getImageUrl'](value)
      : null;
  }
}
