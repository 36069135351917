<ng-container *ngIf="user$ | async as user; else welcome">
  <greco-new-dashboard></greco-new-dashboard>
  <!-- <ng-template #webVersion>
    <greco-dashboard-header [name]="user.friendlyName || user.displayName"> </greco-dashboard-header>
    <router-outlet></router-outlet>
  </ng-template> -->
</ng-container>

<ng-template #welcome>
  <ng-container *ngIf="!loading">
    <div #scrollAnchor></div>
    <greco-sign-in>
      <greco-box class="sign-in-box">
        <greco-sign-in-form
          [showBackButton]="false"
          [config]="{
            startOnRegister: false,
            onSuccess: signInSuccess,
            beforeSignIn: beforeSignIn,
            externalRegistrationLink: community?.externalRegistrationLink,
            externalRegistrationLabel: community?.externalRegistrationLabel
          }"
        >
          <div *ngIf="hasTerms || hasPrivacy">
            <p style="font-size: 80%; margin-bottom: 0; text-align: center">
              By proceeding to use the platform you agree to the following
              <a *ngIf="hasTerms" class="clickable primary-color" (click)="legalService.openTerms()">
                Terms of Service
              </a>
              <ng-container *ngIf="hasTerms && hasPrivacy"> and </ng-container>
              <a *ngIf="hasPrivacy" class="clickable primary-color" (click)="legalService.openPrivacy()">
                Privacy Policy
              </a>
            </p>
          </div>
        </greco-sign-in-form>
      </greco-box>
    </greco-sign-in>

    <div class="page-footer" *ngIf="!fromReferral">
      <greco-user-browse-communities
        callToActionText="Select"
        selectedCallToActionText="Selected"
        [selectedCommunity]="community?.id"
        (communityClick)="communitySelected($event); scrollAnchor.scrollIntoView({ behavior: 'smooth' })"
      ></greco-user-browse-communities>
    </div>
  </ng-container>
</ng-template>
