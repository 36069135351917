<mat-form-field class="filter-input" appearance="standard" (click)="searchInput.focus()">
  <div style="padding-left: 8px">
    <mat-chip-list #chipList>
      <!-- style="background-color: transparent; border: 1px dashed var(--border-color, lightgrey); padding-right: 8px" -->
      <mat-chip *ngIf="calendars" style="border: 1px solid transparent; padding-right: 8px">
        <ng-content select="[calendarPicker]"></ng-content>
      </mat-chip>
      <mat-chip *ngIf="saleCategories" style="border: 1px solid transparent; padding-right: 8px">
        <ng-content select="[saleCategoryPicker]"></ng-content>
      </mat-chip>

      <ng-content select="[slot='start']"></ng-content>

      <mat-chip
        *ngFor="let value of values; let index = index"
        style="border: 1px solid transparent"
        removable
        selectable
        [style.cursor]="value.filter.canOpen ? 'pointer' : ''"
        (click)="updateFilter(index)"
        (removed)="removeFilter(index)"
      >
        <span style="max-width: 30vw; overflow: hidden; text-overflow: ellipsis" [matTooltip]="value.filter.label">
          {{ value.filter | filterValueLabel : value.value }}
        </span>

        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <ng-container *ngFor="let filterOption of filterOptions; let index = index">
        <mat-chip
          *ngIf="(isMobile$ | async) !== true && filterOption.label !== 'Search' && !filterOptionInValues(filterOption)"
          style="background-color: transparent; border: 1px dashed var(--border-color, lightgrey); padding-right: 8px"
          selectable
          (click)="optionSelected(filterOption, null)"
        >
          <span
            style="color: rgba(14, 15, 15, 0.42); max-width: 30vw; overflow: hidden; text-overflow: ellipsis"
            [matTooltip]="filterOption.label"
          >
            {{ filterOption.shortLabel }}
          </span>

          <mat-icon
            style="
              color: rgba(14, 15, 15, 0.42);
              margin-left: 4px;
              line-height: 18px;
              width: 18px;
              height: 18px;
              font-size: 18px;
            "
          >
            add_circle
          </mat-icon>
        </mat-chip>
      </ng-container>

      <div style="width: 100%">
        <mat-icon
          style="
            margin-left: 8px;
            margin-right: 4px;
            color: rgba(14, 15, 15, 0.42);
            width: 20px;
            height: 20px;
            font-size: 20px;
          "
        >
          search
        </mat-icon>

        <input
          #searchInput
          matInput
          placeholder="Search"
          [matAutocomplete]="autocomplete"
          [matChipInputFor]="chipList"
        />
      </div>
    </mat-chip-list>
  </div>

  <mat-autocomplete
    autoActiveFirstOption
    #autocomplete="matAutocomplete"
    (optionSelected)="optionSelected($event.option.value[0], $event.option.value[1]); searchInput.value = ''"
  >
    <ng-container *ngFor="let filterOption of filterOptions">
      <mat-option
        *ngIf="!searchInput.value && filterOption.canOpen"
        class="filter-option"
        [value]="[filterOption, null]"
      >
        {{ filterOption.label }}

        <small style="padding-right: 4px">
          <em [innerHTML]="filterOption.description | safe : 'html'"></em>
        </small>
      </mat-option>

      <ng-container *ngIf="searchInput.value">
        <ng-container *ngIf="filterOption | filterValueOptions : searchInput.value | async as options">
          <ng-container *ngIf="options.length">
            <!-- <mat-optgroup *ngIf="options.length > 1" [label]="filterOption.label"> -->
            <mat-option *ngFor="let optionValue of options" [value]="[filterOption, optionValue]" class="filter-option">
              {{ filterOption.label }}
              <small style="padding-right: 4px">
                <em *ngIf="filterOption.overwriteDescriptionOnSearch">
                  {{ filterOption | filterValueLabel : optionValue }}
                </em>

                <em
                  *ngIf="!filterOption.overwriteDescriptionOnSearch"
                  [innerHTML]="filterOption.description | safe : 'html'"
                ></em>
              </small>
            </mat-option>
            <!-- </mat-optgroup> -->

            <!-- <ng-container *ngIf="options.length === 1">
              <mat-option
                *ngFor="let optionValue of options"
                [value]="[filterOption, optionValue]"
                class="filter-option"
              >
                <span>{{ filterOption.label }}</span>
                <small style="padding-right: 4px">
                  <em *ngIf="filterOption.overwriteDescriptionOnSearch">
                    {{ filterOption | filterValueLabel: optionValue }}
                  </em>
                  <em
                    *ngIf="!filterOption.overwriteDescriptionOnSearch"
                    [innerHTML]="filterOption.description | safe: 'html'"
                  ></em>
                </small>
              </mat-option>
            </ng-container> -->
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>

<div class="end-content" [class.hide-separator]="hideSeparator">
  <ng-container *ngIf="filterOptions | diff : (filterOptions | filterBy : ['label'] : 'Search') as filters">
    <button *ngIf="filters.length" mat-icon-button [matMenuTriggerFor]="filterMenu">
      <mat-icon>filter_list</mat-icon>
    </button>

    <mat-menu #filterMenu="matMenu">
      <button
        style="min-width: 200px"
        mat-menu-item
        *ngFor="let filterOption of filters"
        (click)="optionSelected(filterOption, null)"
      >
        {{ filterOption.label }}
      </button>
    </mat-menu>
  </ng-container>

  <ng-content></ng-content>
</div>
