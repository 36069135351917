import { Component, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { CommunityService } from '@greco/ngx-identity-communities';
import { PropertyListener } from '@greco/property-listener-util';
import { ProductVariant, RecurrencePeriod } from '@greco/sales-products';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

export const PeriodValidator: ValidatorFn = control =>
  control.value ? (Object.values(RecurrencePeriod).includes(control.value) ? null : { period: true }) : null;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-variant-price',
  templateUrl: './variant-price.component.html',
  styleUrls: ['./variant-price.component.scss'],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: VariantPriceComponent,
    },
  ],
})
export class VariantPriceComponent implements OnDestroy, ControlValueAccessor {
  constructor(private formBuilder: FormBuilder, private communitySvc: CommunityService) {
    this.form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      setTimeout(() => this.onChanged?.(this.value));
    });

    this.form
      .get('autoUpgradeEnabled')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(value => {
        if (value) {
          this.form.get('autoUpgradeVariant')?.enable();
        } else {
          this.form.get('autoUpgradeVariant')?.reset();
          this.form.get('autoUpgradeVariant')?.disable();
        }
      });

    this.form
      .get('enrolmentFeeEnabled')
      ?.valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(value => {
        if (value) {
          this.form.get('enrolmentFee')?.enable();
          this.form.get('enrolmentFeeName')?.enable();
          this.form.get('enrolmentFeeDescription')?.enable();
          this.form.get('enrolmentFeeSaleCategory')?.enable();
          this.form.get('enrolmentFee')?.setValue(1);
          this.form.get('enrolmentFeeName')?.setValue('Initiation Fee');
          this.form
            .get('enrolmentFeeDescription')
            ?.setValue('Initiation fee, applicable only first time when purchasing this item');
        } else {
          this.form.get('enrolmentFee')?.reset();
          this.form.get('enrolmentFeeName')?.reset();
          this.form.get('enrolmentFeeDescription')?.reset();
          this.form.get('enrolmentFeeSaleCategory')?.reset();
          this.form.get('enrolmentFee')?.disable();
          this.form.get('enrolmentFeeName')?.disable();
          this.form.get('enrolmentFeeDescription')?.disable();
          this.form.get('enrolmentFeeSaleCategory')?.disable();
        }
      });
  }

  @Input() isActive = false;

  @PropertyListener('communityId') private communityId$ = new BehaviorSubject<string | null>(null);
  @Input() communityId!: string;

  readonly accountId$ = this.communityId$.pipe(
    switchMap(async communityId => {
      const community = communityId ? await this.communitySvc.getCommunity(communityId) : undefined;
      return community ? community.financeAccount.id : '';
    })
  );

  @Input() variantId?: string;
  @Input() accountId!: string;

  form = this.formBuilder.group({
    recurrence: ['once', Validators.required],
    price: [0, [Validators.required, Validators.min(0)]],
    frequency: [1, [Validators.required, Validators.min(1)]],
    period: [RecurrencePeriod.Daily, [Validators.required, PeriodValidator]],
    cycles: [1, [Validators.required, Validators.min(1)]],
    paymentTypes: [['card'], Validators.required],
    autoUpgradeEnabled: [false],
    autoUpgradeVariant: [null],
    enrolmentFee: [1, Validators.min(0.01)],
    enrolmentFeeEnabled: [false],
    enrolmentFeeName: ['Initiation Fee', Validators.minLength(1)],
    enrolmentFeeDescription: [
      'Initiation fee, applicable only first time when purchasing this item',
      Validators.minLength(1),
    ],
    enrolmentFeeSaleCategory: [null],
    taxes: [],
    ignoreTaxes: [false],
  });

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  panelOpenState = false;

  private onChanged?: (
    value: Omit<
      ProductVariant,
      | 'id'
      | 'status'
      | 'title'
      | 'priority'
      | 'productId'
      | 'image'
      | 'variantOpts'
      | 'variantPerks'
      | 'transfers'
      | 'isHidden'
      | 'minimumCommitment'
    > | null
  ) => void;
  private onTouched?: () => void;

  private _onDestroy$ = new Subject<void>();

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(
    value: Omit<
      ProductVariant,
      | 'id'
      | 'status'
      | 'title'
      | 'priority'
      | 'productId'
      | 'image'
      | 'variantOpts'
      | 'variantPerks'
      | 'transfers'
      | 'isHidden'
    >
  ): void {
    this.value = value;
  }

  registerOnChange(
    fn: (
      value: Omit<
        ProductVariant,
        | 'id'
        | 'status'
        | 'title'
        | 'priority'
        | 'productId'
        | 'image'
        | 'variantOpts'
        | 'variantPerks'
        | 'transfers'
        | 'isHidden'
        | 'minimumCommitment'
      > | null
    ) => void
  ): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  touched() {
    this.onTouched?.();
  }

  get value(): Omit<
    ProductVariant,
    | 'id'
    | 'status'
    | 'title'
    | 'priority'
    | 'productId'
    | 'image'
    | 'variantOpts'
    | 'variantPerks'
    | 'transfers'
    | 'isHidden'
    | 'minimumCommitment'
  > | null {
    return this.form.valid
      ? {
          paymentTypes: this.form.value.paymentTypes,
          price: Math.round(this.form.value.price * 100),
          enrolmentFee: this.form.value.enrolmentFeeEnabled ? Math.round(this.form.value.enrolmentFee * 100) : null,
          recurrence:
            this.form.value.recurrence === 'indefiniteRecurrence' || this.form.value.recurrence === 'fixedRecurrence'
              ? {
                  frequency: this.form.value.frequency,
                  period: this.form.value.period,
                  cycles: this.form.value.recurrence === 'fixedRecurrence' ? this.form.value.cycles : null,
                }
              : null,
          autoUpgradeVariantId:
            this.form.value.recurrence === 'fixedRecurrence' && this.form.value.autoUpgradeEnabled
              ? this.form.value.autoUpgradeVariant?.id || null
              : null,
          autoUpgradeVariant:
            this.form.value.recurrence === 'fixedRecurrence' && this.form.value.autoUpgradeEnabled
              ? this.form.value.autoUpgradeVariant
              : null,
          enrolmentFeeEnabled: this.form.value.enrolmentFeeEnabled,
          enrolmentFeeName: this.form.value.enrolmentFeeEnabled ? this.form.value.enrolmentFeeName : null,
          enrolmentFeeDescription: this.form.value.enrolmentFeeDescription
            ? this.form.value.enrolmentFeeDescription
            : null,
          enrolmentFeeSaleCategory: this.form.value.enrolmentFeeEnabled
            ? this.form.value.enrolmentFeeSaleCategory
            : null,
          taxes: this.form.value.taxes || [],
          ignoreTaxes: this.form.value.ignoreTaxes || false,
        }
      : null;
  }

  set value(
    value: Omit<
      ProductVariant,
      | 'id'
      | 'status'
      | 'title'
      | 'priority'
      | 'productId'
      | 'image'
      | 'variantOpts'
      | 'variantPerks'
      | 'transfers'
      | 'isHidden'
      | 'minimumCommitment'
    > | null
  ) {
    this.form.setValue(
      {
        recurrence:
          value?.recurrence?.frequency && value?.recurrence?.period && value?.recurrence?.cycles
            ? 'fixedRecurrence'
            : value?.recurrence?.frequency && value?.recurrence?.period
            ? 'indefiniteRecurrence'
            : 'once',
        price: value?.price ? value.price / 100 : 0,
        enrolmentFeeName: value?.enrolmentFeeName ?? null,
        enrolmentFeeDescription: value?.enrolmentFeeDescription ?? null,
        enrolmentFeeSaleCategory: value?.enrolmentFeeSaleCategory ?? null,
        enrolmentFee: value?.enrolmentFee != null ? value?.enrolmentFee / 100 : null,
        frequency: value?.recurrence && value?.recurrence.frequency ? value?.recurrence.frequency : 1,
        period: value?.recurrence && value?.recurrence.period ? value?.recurrence.period : RecurrencePeriod.Daily,
        cycles: value?.recurrence && value?.recurrence.cycles ? value?.recurrence.cycles : 1,
        paymentTypes: value?.paymentTypes || ['card'],
        autoUpgradeVariant: value?.autoUpgradeVariant || null,
        autoUpgradeEnabled: !!value?.autoUpgradeVariant,
        enrolmentFeeEnabled: value?.enrolmentFee != null || value?.enrolmentFeeName != null,
        taxes: value?.taxes || [],
        ignoreTaxes: !!value?.ignoreTaxes,
      },
      { emitEvent: false }
    );
  }
}
