<ng-container *ngIf="user$ | async as user">
  <div class="page-content">
    <greco-user-alerts class="dashboard-alerts" [user]="user"></greco-user-alerts>
    <ng-template #bookings>
      <div style="display: flex; gap: 16px">
        <greco-user-bookings-calendar
          *ngIf="(isMobile$ | async) === false"
          style="flex: 2"
          [user]="user"
        ></greco-user-bookings-calendar>
        <greco-event-list style="flex: 1" [user]="user"></greco-event-list>
      </div>
    </ng-template>
    <ng-template #videos>
      <greco-unlocked-gallery [user]="user"></greco-unlocked-gallery>
    </ng-template>
    <greco-tabs
      style="margin-bottom: 16px"
      [tabs]="[
        {
          iconStart: 'confirmation_number',
          label: 'Bookings',
          template: bookings
        },
        {
          iconStart: 'video_library',
          label: 'Videos',
          template: videos
        }
      ]"
    ></greco-tabs>
  </div>

  <div class="page-footer">
    <greco-user-browse-communities
      callToActionText="Subscribe"
      (communityClick)="joinCommunity($event)"
    ></greco-user-browse-communities>
  </div>
</ng-container>
