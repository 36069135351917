<greco-simple-dialog [data]="dialogData" class="create-custom-purchase-dialog">
  <form [formGroup]="form">
    <mat-vertical-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>Select User</ng-template>
        <mat-form-field appearance="standard">
          <mat-label>User</mat-label>
          <greco-contact-picker [withUser]="true" formControlName="contact" [communityId]="communityId">
          </greco-contact-picker>
        </mat-form-field>
        <div>
          <button [disabled]="!form.value.contact" mat-button matStepperNext>Select Product</button>
        </div>
      </mat-step>

      <ng-container *ngIf="form.value.contact">
        <mat-step [stepControl]="form">
          <ng-template matStepLabel>Select Product</ng-template>
          <mat-form-field appearance="standard">
            <mat-label>Product</mat-label>
            <greco-product-variant-picker
              placeholder="Start typing to search for products..."
              [communityId]="communityId"
              [required]="true"
              [showRestrictedVariants]="(canSellRestrictedVariants$ | async) || false"
              formControlName="variant"
            ></greco-product-variant-picker>
          </mat-form-field>
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <button *ngIf="form.value.variant" mat-button matStepperNext>Preview Purchase</button>
            <button
              *ngIf="form.value.variant"
              mat-button
              (click)="copyCheckoutUrlToClipboard(form.value.variant, form.value.contact.user.id)"
            >
              Copy Link
            </button>
          </div>
        </mat-step>
      </ng-container>

      <ng-container *ngIf="form.value.contact && form.value.variant">
        <mat-step>
          <ng-template matStepLabel>Checkout</ng-template>
          <greco-checkout-page
            [isStaff]="true"
            [user]="billedTo"
            [purchasedBy]="billedTo"
            [preventRedirect]="true"
            [variantId]="form.value.variant?.id"
            [productId]="form.value.variant?.productId"
            (purchaseSuccess)="close()"
          ></greco-checkout-page>
        </mat-step>
      </ng-container>
    </mat-vertical-stepper>
  </form>
</greco-simple-dialog>
