import { BaseDto } from '@greco/util-dto';
import { Type } from 'class-transformer';
import { IsDefined, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { TilesGroupDto } from './tile-group.dto';

export class CreateTilesDto extends BaseDto {
  @IsString()
  @IsNotEmpty()
  communityId!: string;

  @IsString()
  @IsNotEmpty()
  label!: string;

  @IsString()
  @IsOptional()
  imageUrl!: string;

  @IsString()
  @IsOptional()
  url?: string;

  // @IsBoolean()
  @IsNotEmpty()
  status!: boolean;

  @Type(() => TilesGroupDto)
  @IsOptional()
  tileGroup?: TilesGroupDto;

  @IsString()
  @IsOptional()
  tileGroupId?: string | null;

  @IsDefined()
  priority!: number;
}
