import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-user-finder',
  templateUrl: './user-finder.component.html',
  styleUrls: ['./user-finder.component.scss'],
})
export class UserFinderComponent {
  constructor(private userSvc: UserService) {}

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId!: string | null;

  @Input() mode: 'refer' | 'transfer' = 'refer';

  @Output() userSelected = new EventEmitter<string>();

  selectedUser: User | null = null;

  searchQuery$ = new BehaviorSubject<string>('');

  users$ = combineLatest([this.searchQuery$, this._communityId$]).pipe(
    debounceTime(500),
    switchMap(async ([search, communityId]) =>
      communityId && search.length >= 7
        ? await this.userSvc.paginatePublic(
            RequestQueryBuilder.create({
              search: {
                $and: [{ email: { [CondOperator.STARTS]: search } }],
              },
            }),
            { limit: 10 },
            false,
            communityId
          )
        : null
    ),
    map(data => data?.items ?? [])
  );

  selectUser(user: User) {
    this.selectedUser = user;
    this.userSelected.emit(this.selectedUser.id);
  }

  emailSelected(email: string) {
    if (email.includes('@') && email.includes('.')) this.userSelected.emit(email);
  }

  clearUser() {
    this.selectedUser = null;
    this.userSelected.emit('');
  }
}
