<mat-form-field class="date-range-picker" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-select #select (selectionChange)="selectChanged($event, rangeInput)">
    <mat-option *ngIf="allowEmpty"> - All Time - </mat-option>
    <mat-option *ngFor="let option of options" [value]="option">{{ option.label }}</mat-option>
    <mat-option *ngIf="allowCustom" value="_custom">Custom Date</mat-option>
  </mat-select>
  <mat-date-range-input #rangeInput [rangePicker]="picker">
    <input matStartDate [value]="from$ | async" placeholder="From" (dateChange)="fromChanged($event.value)" />
    <input matEndDate [value]="to$ | async" placeholder="To" (dateChange)="toChanged($event.value)" />
  </mat-date-range-input>
  <mat-datepicker-toggle *ngIf="allowCustom" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
