import { SaleCategory } from '@greco/sales-purchases';
import {
  DynamicFormControlLayout,
  DynamicFormValueControlModel,
  DynamicFormValueControlModelConfig,
  serializable,
} from '@ng-dynamic-forms/core';

export const DYNAMIC_FORM_CONTROL_TYPE_CATEGORY_PICKER = 'category-picker';

export interface DynamicCategoryPickerModelConfig extends DynamicFormValueControlModelConfig<SaleCategory> {
  communityId?: string;
}

export class DynamicCategoryPickerModel extends DynamicFormValueControlModel<SaleCategory> {
  @serializable() readonly type = DYNAMIC_FORM_CONTROL_TYPE_CATEGORY_PICKER;

  @serializable() communityId?: string;
  @serializable() placeholder!: string;

  constructor(config: DynamicCategoryPickerModelConfig, layout?: DynamicFormControlLayout) {
    super(config, layout);
    this.communityId = config.communityId;
  }

  getAdditional(key: string, defaultValue?: any) {
    if (key === 'isFormFieldControl') return true;
    else return super.getAdditional(key, defaultValue);
  }
}
