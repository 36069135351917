import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { TableModule } from '@greco/ui-table';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import { InvoicesTableComponent } from './components';
import { InvoicePage, InvoicesPage } from './pages';
import { TimestampPipe } from './pipes';
import { GrecoPriceService, InvoiceService } from './services';
import { GrecoStudioService } from './services/studio.service';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,

    // Material
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatDividerModule,

    // Greco
    LetModule,
    TableModule,
    JoinPipeModule,
    SimpleDialogModule,
    CollapsibleSectionModule,

    // Other
    NgPipesModule,
    SafePipeModule,
  ],
  providers: [InvoiceService, GrecoPriceService, GrecoStudioService],
  declarations: [
    // Pipes
    TimestampPipe,

    // Components
    InvoicesTableComponent,

    // Pages
    InvoicePage,
    InvoicesPage,
  ],
  exports: [
    // Components
    InvoicesTableComponent,

    // Pages
    InvoicePage,
    InvoicesPage,
  ],
})
export class InvoicesModule {}
