import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { CommunityServiceModule } from '@greco/ngx-identity-communities';
import { CommunitySecurityServiceModule } from '@greco/ngx-identity-community-staff-util';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { UsersModule } from '@greco/ngx-identity-users';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { AlertModule } from '@greco/ui-alert';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { EditorModule } from '@greco/ui-editor';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { TableModule } from '@greco/ui-table';
import { SafePipeModule } from 'safe-pipe';
import {
  CommunityAgreementDetailsComponent,
  CommunityAgreementPickerComponent,
  CommunityAgreementsTableComponent,
  UserAgreementsTableComponent,
} from './components';
import {
  ArchiveAgreementDialog,
  CreateAgreementDialog,
  CreateUserAgreementDialog,
  PreviewAgreementDialog,
  PreviewAgreementLinksDialog,
  SignAgreementDialog,
} from './dialogs';
import { AgreementPage, AgreementsPage } from './pages';
import {
  CommunityAgreementsService,
  CommunityAgreementsUsageService,
  UserCommunityAgreementsService,
} from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    // Material
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatCardModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatInputModule,
    MatPaginatorModule,

    // Greco
    TableModule,
    FiltersModule,
    JoinPipeModule,
    SimpleDialogModule,
    FormSaveButtonsModule,
    CommunityServiceModule,
    CollapsibleSectionModule,
    CommunitySecurityServiceModule,
    ContactsModule,
    LetModule,
    EditorModule,
    UsersModule,
    AlertModule,

    // Other
    SafePipeModule,
  ],
  providers: [CommunityAgreementsService, CommunityAgreementsUsageService, UserCommunityAgreementsService],
  declarations: [
    //Pages
    AgreementsPage,
    AgreementPage,

    //Components
    CommunityAgreementsTableComponent,
    CommunityAgreementDetailsComponent,
    CommunityAgreementPickerComponent,
    UserAgreementsTableComponent,

    //Dialogs
    CreateAgreementDialog,
    CreateUserAgreementDialog,
    PreviewAgreementDialog,
    PreviewAgreementLinksDialog,
    SignAgreementDialog,
    ArchiveAgreementDialog,
  ],
  exports: [
    AgreementsPage,
    AgreementPage,
    CreateUserAgreementDialog,
    CommunityAgreementPickerComponent,
    UserAgreementsTableComponent,
    PreviewAgreementDialog,
  ],
})
export class FeatureCommunityAgreementsModule {}
