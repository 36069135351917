<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()"
>
  <button mat-stroked-button color="primary" style="height: 32px; line-height: 16px" (click)="grantSuperAdmin()">
    <mat-icon>add</mat-icon>
    <span>Add Administrators</span>
  </button>
</greco-filter-bar>

<greco-table [data]="(admins$ | async) || []" [loading]="loading">
  <mat-icon *grecoTableCol="''; fitContent: true; let admin" [matTooltip]="admin.id"> badge </mat-icon>

  <p *grecoTableCol="'User'; let admin"><strong>{{ admin.displayName }}</strong></p>

  <p
    *grecoTableCol="'Email'; let admin"
    [matTooltip]="admin.emailVerified ? 'Email is Verified!' : 'Email is not Verified!'"
  >
    {{ admin.email }}
  </p>

  <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let admin">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button mat-menu-item (click)="removeSuperAdmin(admin)">
        <mat-icon>person_remove</mat-icon>
        Remove Admin
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
