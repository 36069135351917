<ng-container *grecoLet="preview$ | async as preview">
  <ng-container *grecoLet="_overridePaymentMethod$ | async">
    <ng-container *grecoLet="_selectedBookingOption$ | async as selectedBookingOption">
      <!-- Header -->
      <div class="header">
        <div style="display: flex; flex-direction: column; gap: 12px">
          <div style="display: flex; gap: 12px">
            <ng-container *ngIf="!event.imageURL">
              <greco-multi-avatar
                [images]="(photoUrls$ | async) || ['assets/lf3/icon_square_pad.png']"
                [size]="54"
              ></greco-multi-avatar>
            </ng-container>
            <ng-container *ngIf="event.imageURL">
              <greco-smart-img
                [src]="event.imageURL"
                [border]="true"
                borderColor="primary"
                round
                style="height: 59px; width: 59px"
              ></greco-smart-img>
            </ng-container>

            <div class="title-container">
              <div class="community">
                <mat-icon>place</mat-icon>
                <span
                  ><strong>{{ event.community.name }}</strong></span
                >
              </div>

              <h2 class="title">{{ event.title }}</h2>
            </div>
          </div>

          <ng-container *grecoLet="eventResources$ | async as eventResources">
            <div *ngIf="eventResources?.length" style="display: flex; text-align: left; margin-left: 2px">
              <small style="margin-top: 2px; margin-right: 4px">With: </small>
              <div style="display: inline-block; overflow: wrap" *ngFor="let resource of eventResources; let i = index">
                <div *ngIf="resource.type == 'PERSON'" style="display: flex">
                  <small *ngIf="i === (eventResources?.length || 0) - 1 && i !== 0" style="margin-top: 2px">
                    and&nbsp;
                  </small>
                  <div style="height: 24px; width: 24px; margin-top: -2px; margin-right: 3px">
                    <greco-smart-img
                      [src]="resource.photoURL"
                      [round]="true"
                      [border]="true"
                      borderColor="primary"
                    ></greco-smart-img>
                  </div>
                  {{ resource | pluck : 'name' }}
                  <small *ngIf="i < (eventResources?.length || 0) - 1">,&nbsp;&nbsp;</small>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="title-container" style="margin-top: -24px">
          <div class="time">
            <mat-icon>event</mat-icon>
            <div style="display: flex; flex-direction: column">
              <strong style="margin-right: 4px">Starts:</strong>
              <strong style="margin-right: 4px">Ends:</strong>
            </div>

            <div style="display: flex; flex-direction: column">
              <span>{{ event.startDate | date : 'MMM dd' }}</span>
              <span>{{ event.endDate | date : 'MMM dd' }}</span>
            </div>
          </div>
          <div class="duration">
            <mat-icon>timer</mat-icon>
            <span>
              {{ event.duration >= 60 ? (event.duration / 60 | floor) + 'h' : '' }}
              {{ event.duration === 60 ? '' : (event.duration % 60) + 'm' }}
            </span>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="section">
        <div class="description" [innerHTML]="event.description || '' | safe : 'html'"></div>
      </div>

      <!-- WEEK Calendar -->
      <div style="display: flex; flex-direction: row; justify-content: center">
        <greco-course-week [course]="event" [showAsBoxes]="true"></greco-course-week>
      </div>

      <div *ngIf="user" class="section requirements-list">
        <!-- Booking Option Picker -->
        <greco-preview-booking-booking-option-picker
          *grecoLet="canUsePerks$ | async as canUsePerks"
          [user]="user"
          [event]="event"
          [bookedBy]="bookedBy"
          [onlyAvailablePerks]="!allowPendingBookings"
          (selected)="_selectedBookingOption$.next($event)"
          (useBookedByPerks)="useBookedByPerks$.next($event)"
          [disableUserPerks]="!canUsePerks"
        >
        </greco-preview-booking-booking-option-picker>

        <!-- Cancellation Policy -->
        <greco-cancellation-policy-requirement
          *ngIf="selectedBookingOption && selectedBookingOption?.cancellation"
          [bookingOption]="selectedBookingOption"
          [event]="event"
        ></greco-cancellation-policy-requirement>

        <!-- Email Verification -->
        <ng-container *ngIf="currentUserId$ | async as currentUserId">
          <greco-email-verification-requirement
            *ngIf="user && user.id === currentUserId && !user.emailVerified"
          ></greco-email-verification-requirement>
        </ng-container>

        <!-- Payment Method -->

        <ng-container *grecoLet="hasPaymentMethod$ | async as hasPaymentMethod">
          <greco-payment-method-requirement
            *ngIf="hasPaymentMethod === false"
            [user]="user"
            (refresh)="paymentMethodControl.setValue(null)"
            [allowBankPayments]="false"
          ></greco-payment-method-requirement>
        </ng-container>

        <!-- Other 'Extensions' (typeform) -->
        <greco-typeform-requirement
          *ngFor="let req of typeformRequirements$ | async"
          [requirement]="req"
          [user]="user"
          (filled)="requirementFilledIn(req, $event.responseId)"
        ></greco-typeform-requirement>
      </div>

      <!-- Agreements -->
      <ng-container *grecoLet="seriesAgrUsage$ | async as seriesAgrUsage" style="margin-top: 8px">
        <div [formGroup]="agreementsForm">
          <ng-container *ngFor="let usage of seriesAgrUsage$ | async; index as index">
            <greco-collapsible-section
              *grecoLet="canLeaveUnsigned$ | async as canLeaveUnsigned"
              id="agreements"
              #agreementEx
              [expanded]="false"
              [header]="{ title: usage.agreement?.title || '' }"
              [class]="index === 0 ? 'agreements-top' : 'agreements'"
              (click)="$event.stopImmediatePropagation()"
            >
              <div style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
                <span [innerHtml]="usage.agreement?.text || '' | safe : 'html'"></span>
              </div>

              <!-- put checkbox/signature in header if not expanded -->
              <div *ngIf="!agreementEx.expanded" subheader style="display: flex; flex-direction: column">
                <mat-checkbox
                  *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                  [checked]="agreementsForm.get(usage.id)?.value"
                  [required]="true"
                  color="primary"
                  (click)="
                    setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()
                  "
                  [formControlName]="usage.id"
                  >I have read and agree to the terms of the agreement</mat-checkbox
                >

                <mat-checkbox
                  *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                  [checked]="agreementsForm.get(usage.id)?.value"
                  [required]="true"
                  color="primary"
                  [formControlName]="usage.id"
                  (click)="
                    setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()
                  "
                  >I have read and agree to the terms of the agreement</mat-checkbox
                >

                <ng-container *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'">
                  <span *ngIf="!agreementsForm.get(usage.id)?.value" style="color: var(--warn-color)"
                    >* Signature Required</span
                  >
                  <em *ngIf="agreementsForm.get(usage.id)?.value">Signed</em>
                </ng-container>
              </div>

              <mat-checkbox
                *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                [checked]="agreementsForm.get(usage.id)?.value"
                [required]="true"
                style="padding: 8px"
                color="primary"
                [formControlName]="usage.id"
                (click)="setCheck(usage.id, usage.agreementId); $event.preventDefault()"
                >I have read and agree to the terms of the agreement</mat-checkbox
              >

              <mat-checkbox
                *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                [checked]="agreementsForm.get(usage.id)?.value"
                [required]="true"
                style="padding: 8px"
                color="primary"
                [formControlName]="usage.id"
                (click)="setCheck(usage.id, usage.agreementId); $event.preventDefault()"
                >I have read and agree to the terms of the agreement</mat-checkbox
              >
              <greco-signature
                *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'"
                [user]="bookedBy || user"
                [forPurchase]="true"
                [formControlName]="usage.id"
                ngDefaultControl
                (signatureChange)="setSignature($event, usage.id, usage.agreementId)"
              >
              </greco-signature>
            </greco-collapsible-section>

            <greco-alert
              *ngIf="hideSignLater"
              [config]="{
                title: 'Signature Required',
                type: $any('WARN'),
                description:
                  (user.displayName || 'This member') +
                  ' will have to sign this agreement before booking into other events.'
              }"
            ></greco-alert>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
