import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { UserService } from '@greco/ngx-identity-auth';
import { JoinCommunityDialog, UserBrowseCommunitiesComponent } from '@greco/ngx-identity-communities';
import { LegalService } from '@greco/ngx-platform-legal';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { map, shareReplay, tap } from 'rxjs/operators';

@Component({
  selector: 'greco-dashboard-schedule',
  templateUrl: './dashboard-schedule.component.html',
  styleUrls: ['./dashboard-schedule.component.scss'],
})
export class DashboardScheduleComponent implements OnInit {
  @ViewChild(UserBrowseCommunitiesComponent) private communityBrowser?: UserBrowseCommunitiesComponent;

  loading = true;
  user$ = this.userService.user$.pipe(tap(() => (this.loading = false)));

  mobile = true;
  isMobile$ = this.breakpointObs.observe('(max-width: 700px)').pipe(
    map(({ matches }) => matches),
    tap(matches => (this.mobile = matches)),
    shareReplay(1)
  );

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    public legalService: LegalService,
    private crumbsService: BreadcrumbService,
    private breakpointObs: BreakpointObserver
  ) {}

  ngOnInit() {
    this.crumbsService.set([]);
  }

  async joinCommunity(community: Community) {
    if (community?.id) {
      await toPromise(this.dialog.open(JoinCommunityDialog, { data: { communityId: community.id } }).beforeClosed());
      await this.communityBrowser?.refresh();
    }
  }
}
