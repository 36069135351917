import { Component, Input } from '@angular/core';
import { Community } from '@greco/identity-communities';
import { PropertyListener } from '@greco/property-listener-util';
import { Video } from '@greco/videos';
import { ReplaySubject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-video-admin-page',
  templateUrl: './video-admin.page.html',
  styleUrls: ['./video-admin.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class VideoAdminPage {
  @PropertyListener('video') video$ = new ReplaySubject<Video>(1);
  @Input() video!: Video;
  @Input() publishedOn?: Date | null | undefined;
  @Input() community?: Community;
}
