<mat-form-field
  [appearance]="model.getAdditional('appearance', 'legacy')"
  [color]="model.getAdditional('color', 'primary')"
  [ngClass]="getClass('grid', 'control')"
  [floatLabel]="model.getAdditional('floatLabel', 'auto')"
  [formGroup]="group"
>
  <mat-label [innerHTML]="model.label"></mat-label>
  <greco-product-variant-picker
    [formControlName]="model.id"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"
    [communityId]="model.communityId"
    [placeholder]="model.placeholder"
    [required]="model.required"
    [value]="model.value"
    [onlyProducts]="model.onlyProducts || false"
    [multiselect]="model.multiselect || false"
    [showAllVariants]="model.showAllVariants || false"
    [onlyRecurring]="model.onlyRecurring || false"
    [productIdsToExclude]="model.productIdsToExclude || []"
    [variantIdsToExclude]="model.variantIdsToExclude || []"
  ></greco-product-variant-picker>
  <mat-hint *ngIf="model.hint !== null" align="start" [innerHTML]="model.hint"></mat-hint>

  <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
</mat-form-field>
