<greco-simple-dialog [data]="dialogData">
  <div
    id="manage-booking-tags-container"
    style="
      max-height: 60vh;
      overflow-y: auto;
      position: relative;
      border-radius: 8px;
      overflow-x: hidden;
      border: 1px solid rgba(14, 15, 15, 0.12);
    "
  >
    <greco-table [data]="(tags$ | async) || []" [loading]="loading">
      <mat-icon *grecoTableCol="''; fitContent: true">tag</mat-icon>

      <p *grecoTableCol="'Tag'; let tag">{{ tag.label }}</p>

      <div *grecoTableCol="'Filterable'; fitContent: true; alignCenter: true; stickyEnd: true; let tag">
        <mat-checkbox
          [disabled]="!!updating[tag.id]"
          [checked]="!tag.hideFromFilters"
          (change)="updateFilterable(tag)"
        ></mat-checkbox>
      </div>
    </greco-table>

    <div style="position: sticky; bottom: 0; z-index: 11; border-top: 1px solid rgba(14, 15, 15, 0.12)">
      <mat-paginator pageSize="20" [length]="totalItems" (page)="page$.next($event.pageIndex + 1)"></mat-paginator>
    </div>
  </div>
</greco-simple-dialog>
