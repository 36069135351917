import { SecurityResource } from '@greco/security';

export const SALE_CATEGORY_SECURITY_RESOURCE = 'finance_purchasing_sale_category';

export enum SaleCategoryResourceAction {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
}

export const SaleCategoryResource: SecurityResource = {
  key: SALE_CATEGORY_SECURITY_RESOURCE,
  module: 'Finance',

  title: 'Sale Category',
  description: 'Manage sale categories',

  context: class {
    communityId?: string;
  },

  actions: [
    { key: SaleCategoryResourceAction.CREATE, title: 'Create', description: 'Ability to create a new sale category' },
    { key: SaleCategoryResourceAction.READ, title: 'Read', description: 'Ability to read sale category data' },
    {
      key: SaleCategoryResourceAction.UPDATE,
      title: 'Update',
      description: 'Ability to update existing sale categories',
    },
  ],
};
