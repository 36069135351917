export interface TokenizedValue {
  value: string;
  tokens: SubstitutionToken[];
}

export interface SubstitutionToken {
  key?: string;
  value?: string;
}

export function substitutedValue(data: TokenizedValue) {
  return data.tokens.reduce((acc, cur) => acc.replace(new RegExp('{{' + cur.key + '}}', 'g'), cur.value), data.value);
}
