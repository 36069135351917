import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { SecurityService } from '@greco/ngx-security-util';
import { Purchase, PurchaseResource, PurchaseResourceAction, PurchaseStatus } from '@greco/sales-purchases';
import { CurrencyMaskConfig } from 'ngx-currency';
import { RETURN_TO_INVENTORY_DIALOG } from '../../return-to-inventory.token';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-void-purchase-dialog',
  templateUrl: './void.dialog.html',
  styleUrls: ['./void.dialog.scss'],
})
export class VoidPurchaseDialog {
  voidReasons = ['Bad Debt', 'Admin Error'].sort();

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly purchase: Purchase,
    private dialogRef: MatDialogRef<VoidPurchaseDialog>,
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    @Inject(RETURN_TO_INVENTORY_DIALOG) public readonly returnInventoryDialog: any
  ) {}

  readonly dialogData: DialogData = {
    title: 'Void Purchase',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  formGroup = this.formBuilder.group({
    voidReason: ['', [Validators.required]],
    returnToInventory: [false],
  });

  processing = false;

  canVoid$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.VOID, {}, true);

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      const purchase = await this.purchaseSvc.void(this.purchase.id, this.formGroup.value.voidReason);

      if (purchase.status !== PurchaseStatus.VOIDED) {
        console.error('Purchase could not be voided');
        this.snacks.open('Purchase could not be voided', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      } else {
        if (this.formGroup.value.returnToInventory) {
          const inventoryDialog = this.matDialog.open(this.returnInventoryDialog, {
            data: {
              purchaseId: purchase.id,
              variants: purchase.items
                .filter(item => (item as any).variantId)
                .map(item => {
                  return {
                    variantId: (item as any).variantId,
                    quantity: item.quantity,
                    title: item.displayName,
                    variant: (item as any).variant,
                  };
                }),
            },
          });
          await toPromise(inventoryDialog.afterClosed());
        }
        this.snacks.open('Voided successfully!', 'Ok', { duration: 3000 });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
    this.close({
      submit: true,
    });
  }
}
