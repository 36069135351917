import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Tag } from '@greco/booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class TagService {
  constructor(private http: HttpClient) {}

  // @Patch(':tagId')
  async updateTag(tagId: string, hideFromFilters: boolean) {
    await toPromise(this.http.patch<void>(`/api/event-tags/${tagId}`, { hideFromFilters }));
  }

  // @Get(':tagId')
  async getOne(tagId: string) {
    return await toPromise(this.http.get<Tag>(`/api/event-tags/${tagId}`));
  }

  // @Get()
  async paginate(query: RequestQueryBuilder, communityId?: string, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<Tag>>('/api/event-tags', {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  async paginateCommunities(
    query: RequestQueryBuilder,
    communityIds: string[],
    pagination?: Partial<PaginatedQueryParams>
  ) {
    return await toPromise(
      this.http.get<PaginatedDto<Tag>>('/api/event-tags/communities/tags', {
        params: {
          ...{ communityIds: [''].concat(communityIds) },
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
}
