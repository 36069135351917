import { Component, Input } from '@angular/core';
import { AlertConfig, AlertType } from '@greco/ui-alert';

@Component({
  selector: 'greco-frozen-subscription-alert',
  templateUrl: './frozen-subscription-alert.component.html',
  styleUrls: ['./frozen-subscription-alert.component.scss'],
})
export class FrozenSubscriptionAlertComponent {
  @Input() isUser = true;
  @Input() displayStyle: 'banner' | 'card' = 'banner';

  userAlert: AlertConfig = {
    title: 'You have a frozen membership: your booking options may be limited.',
    icon: 'warning',
    type: AlertType.WARN,
    description:
      'While a membership is frozen, you lose access to whatever perks are associated with it. Please contact support if you wish to unfreeze your membership.',
  };

  staffAlert: AlertConfig = {
    title: 'Member has a frozen subscription: their booking options may be limited.',
    type: AlertType.WARN,
    icon: 'warning',
    description: 'While a membership is frozen, the member loses access to whatever perks are associated with it.',
  };
}
