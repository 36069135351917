import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import type { TagDto } from '@greco/nestjs-booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { BookingOptionService, TagService } from '../../services';

@Component({
  selector: 'greco-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TagsInputComponent }],
})
export class TagsInputComponent implements MatFormFieldControl<TagDto[]>, ControlValueAccessor, OnDestroy {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef,
    private tagSvc: TagService,
    private bookingOptSvc: BookingOptionService
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  autofilled?: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static nextId = 0;
  private _placeholder = '';
  private touched = false;
  private _required = false;
  private _disabled = false;
  private _tableEnabled = false;
  private _value: TagDto[] | null = null;

  private _communityId$ = new BehaviorSubject<string | null>(null);
  @Input() get communityId() {
    return this._communityId$.value;
  }
  set communityId(communityId) {
    this._communityId$.next(communityId);
  }
  _availableAsCourse$ = new BehaviorSubject<boolean>(false);

  get availableAsCourse() {
    return this._availableAsCourse$.value;
  }
  @Input() set availableAsCourse(availableAsCourse: boolean) {
    this._availableAsCourse$.next(availableAsCourse);
  }

  stateChanges = new Subject<void>();

  searchValue$ = new BehaviorSubject<string>('');
  tags$ = combineLatest([this.searchValue$, this._communityId$]).pipe(
    debounceTime(250),
    switchMap(async ([search, communityId]) =>
      communityId ? await this.tagSvc.paginate(this._getQueryBuilder(search), communityId, {}) : null
    ),
    map(data => data?.items || [])
  );

  options$ = combineLatest([
    this.stateChanges.pipe(startWith(null)),
    this._communityId$,
    this._availableAsCourse$,
  ]).pipe(
    debounceTime(300),
    switchMap(async ([_, communityId, _availableAsCourse]) => {
      if (!communityId) return;
      const ids = this.value ? this.value.filter(tag => !!tag.id).map(tag => tag.id as string) : [];
      if (!ids.length) return;
      const query = new RequestQueryBuilder();
      query.search({
        'tags.id': { $in: ids },
        isCourseOption: { $eq: this.availableAsCourse },
      });
      // provide a dynamic value for isCourseOption in the search
      // query.setFilter({ field: 'isCourseOption', operator: '$eq', value: false });

      return await this.bookingOptSvc.paginate(query, communityId, { limit: 100 });
    }),
    map(data => data?.items || []),
    shareReplay(1)
  );

  areThereOptions$ = combineLatest([this.options$]).pipe(
    debounceTime(300),
    map(([data]) => (data.length ? true : false))
  );

  controlType = 'greco-tags-input';

  @ViewChild('input') private input?: ElementRef;

  @Input() get value() {
    return this._value;
  }
  set value(v) {
    this._value = v?.map(tag => ({ ...tag, communityId: this.communityId || '' })) || null;
    this.onChanged(this._value);
    this.stateChanges.next();
  }

  @HostBinding() id = `greco-tags-input-${TagsInputComponent.nextId++}`;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input() allowedToCreateTag = true;

  focused = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChanged = (_value: any) => {};

  get empty() {
    return !this.value?.length && !this.searchValue$.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input() get tableEnabled(): boolean {
    return this._tableEnabled;
  }
  set tableEnabled(value: boolean) {
    this._tableEnabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return false;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('greco-tags-input-container');
    if (controlElement) {
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick(_: MouseEvent): void {
    if (this.input) {
      (this.input.nativeElement as HTMLInputElement).focus();
    }
  }

  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFocusIn(_: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  removeTag(tag: TagDto) {
    // TODO: testesttesttest
    if (!this.value) return;
    this.value = this.value.filter(t => {
      if (tag.id) {
        return t != tag;
      } else if (!tag.id) {
        return true;
      } else {
        return t.id != tag.id;
      }
    });
  }

  remove(tag: TagDto) {
    this.value = this.value?.filter(t => t.label != tag.label) || null;
  }

  optionSelection(event: MatAutocompleteSelectedEvent) {
    if (!this.value?.find(t => event.option.value.label.toLowerCase() === t.label.toLowerCase())) {
      this.value = [...(this.value || []), { ...event.option.value, communityId: this.communityId }];
    }
    this.searchValue$.next('');
    // TODO: unfocus input field after selecting option in order to load table
  }

  async createTag(label: string) {
    const query = new RequestQueryBuilder();
    const ids = this.value ? this.value.filter(t => !!t.id).map(t => t.id as string) : [];
    query.search({
      ...(label && { label: { $eq: label } }),
      ...(ids.length ? { id: { $notin: ids } } : {}),
    });

    const tags = this.communityId ? (await this.tagSvc.paginate(query, this.communityId, {})).items : null;
    if (tags?.length) this.value = [...(this.value || []), { ...tags[0], communityId: this.communityId || '' }];
    else if (!this.value?.find(t => t.label?.toLowerCase() === label.toLowerCase())) {
      this.value = [...(this.value || []), { label, communityId: this.communityId || '' }];
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._communityId$.complete();
  }

  private _getQueryBuilder(search: string) {
    const query = new RequestQueryBuilder();
    const ids = this.value ? this.value.filter(t => !!t.id).map(t => t.id as string) : [];
    query.search({
      ...(search && { label: { $contL: search } }),
      ...(ids.length ? { id: { $notin: ids } } : {}),
    });
    return query;
  }
}
