import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DropDownButtonComponent } from './drop-down-button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule],
  declarations: [DropDownButtonComponent],
  exports: [DropDownButtonComponent, MatMenuModule]
})
export class DropDownButtonModule {}
