import { SecurityResource } from '@greco/security';

export const EVENT_RESOURCE_TAG_SECURITY_RESOURCE = 'booking_event_resource_tag';

export enum EventResourceTagSecurityResourceAction {
  MANAGE = 'MANAGE',
}

export const EventResourceTagSecurityResource: SecurityResource = {
  key: EVENT_RESOURCE_TAG_SECURITY_RESOURCE,
  module: 'booking',

  title: 'Event Resource Tag',
  description: 'Manage event resource tags',

  context: class {
    resourceTagId?: string;
    communityId?: string;
  },

  actions: [
    {
      key: EventResourceTagSecurityResourceAction.MANAGE,
      title: 'Manage',
      description: 'Ability to manage event resource tags',
    },
  ],
};
