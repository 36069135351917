import { Component } from '@angular/core';
import { UserService } from '@greco/ngx-identity-auth';
import { SubscriptionFreezeService } from '@greco/ngx-sales-subscriptions';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-dashboard-frozen-subscriptions',
  templateUrl: './dashboard-frozen-subscriptions.component.html',
  styleUrls: ['./dashboard-frozen-subscriptions.component.scss'],
})
export class DashboardFrozenSubscriptionsComponent {
  constructor(private freezeSvc: SubscriptionFreezeService, private userSvc: UserService) {}

  frozenSubscriptions$ = combineLatest([this.userSvc.user$]).pipe(
    switchMap(async ([user]) => (user ? await this.freezeSvc.getFrozenSubscriptions(user.id) : []))
  );
}
