import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Account } from '@greco/finance-accounts';
import { AccountService } from '../../services';

@Component({
  selector: 'greco-add-royalties-dialog',
  templateUrl: './add-royalties.dialog.html',
  styles: ['mat-form-field { width: 100%; }'],
})
export class AddRoyaltiesDialog {
  constructor(
    private snackbars: MatSnackBar,
    private formBuilder: FormBuilder,
    private accountSvc: AccountService,
    private dialogRef: MatDialogRef<AddRoyaltiesDialog>,
    @Inject(MAT_DIALOG_DATA) public account: Account
  ) {}

  readonly dialogData = {
    title: 'Account Royalties',
    subtitle: 'Add a royalties configuration',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  form = this.formBuilder.group({
    destination: [null, Validators.required],
    percentage: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
  });

  processing = false;

  close() {
    this.dialogRef.close(null);
  }

  async submit() {
    this.processing = true;

    try {
      const {
        percentage,
        destination: { id: destinationAccountId },
      } = this.form.value;
      const royalty = await this.accountSvc.addRoyalties(this.account.id, { destinationAccountId, percentage });
      if (!royalty) {
        throw 'Unable to add Royalty';
      }

      this.snackbars.open('Added account royalties!', 'Ok', { duration: 2000 });
      this.dialogRef.close(royalty);
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
  }
}
