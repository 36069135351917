<div>
  <h2 style="margin: 0">User Balance Adjustment</h2>
  <p>{{data.account.name}}</p>

  <div style="display: grid; grid-template-columns: 1fr auto 1fr; margin-top: 24px; margin-bottom: 16px">
    <div style="text-align: center; padding: 8px">
      <h4 style="margin-bottom: 8px">Current Balance</h4>
      <p style="margin: 0">{{(currentBalance || 0) / 100 | currency}}</p>
    </div>

    <div style="border-left: 1px solid lightgray"></div>

    <div style="text-align: center; padding: 8px">
      <h4 style="margin-bottom: 8px">New Balance</h4>
      <p
        *grecoLet="newBalance$ | async as balance"
        style="margin: 0"
        [matTooltip]="!balance ? '' : (balance > 0 ? 'Credit that can be applied on future purchases.' : '')"
      >
        {{balance === null || balance === undefined ? '-' : (balance / 100 | currency)}}
        <mat-icon *ngIf="balance !== null && balance !== undefined" style="font-size: 12px; width: 12px; height: 12px"
          >info</mat-icon
        >
      </p>
    </div>
  </div>

  <form [formGroup]="form">
    <!-- Amount (positive/negative) -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Amount</mat-label>
      <input
        matInput
        autocomplete="off"
        type="text"
        required
        formControlName="amount"
        currencyMask
        [options]="currencyMaskConfig"
      />
      <mat-hint>A positive amount indicates a credit that can be used on future purchases.</mat-hint>
    </mat-form-field>

    <!-- Note -->
    <mat-form-field appearance="standard" style="width: 100%; margin-top: 16px">
      <mat-label>Note</mat-label>
      <input matInput required formControlName="note" />
      <mat-hint>Additional information about your adjustment.</mat-hint>
    </mat-form-field>
  </form>

  <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 8px; margin-top: 32px">
    <button type="button" style="height: 32px; line-height: 16px" mat-stroked-button (click)="close()">Cancel</button>
    <button
      type="button"
      style="height: 32px; line-height: 16px"
      mat-stroked-button
      color="primary"
      [disabled]="form.invalid || submitting"
      (click)="submit()"
    >
      <span *ngIf="!submitting">Apply to balance</span>
      <mat-icon *ngIf="submitting" class="spin">loop</mat-icon>
    </button>
  </div>
</div>
