import { Component, Input } from '@angular/core';
import { Community } from '@greco/identity-communities';

@Component({
  selector: 'greco-community-card',
  templateUrl: './community-card.component.html',
  styleUrls: ['./community-card.component.scss'],
})
export class CommunityCardComponent {
  @Input() googleMapsKey = 'AIzaSyBxVmVtXTm4_SjzgaB7KYrruNE7FlR8jeI';
  @Input() community!: Community;
  @Input() callToActionText: string | null = null;
  @Input() selectedCallToActionText: string | null = null;
  @Input() selected = false;
}
