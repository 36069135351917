import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-profile-completion-requirement',
  templateUrl: './profile-completion.component.html',
  styleUrls: ['./profile-completion.component.scss'],
})
export class ProfileCompletionRequirementComponent {
  @Input() userId!: string;
  @PropertyListener('userId') private userId$ = new BehaviorSubject<string | null>(null);

  @Output() refreshed = new EventEmitter<void>();

  constructor(private userSvc: UserService, public bottomSheet: MatBottomSheet) {}

  refresh$ = new BehaviorSubject<void>(undefined);

  user$ = combineLatest([this.userId$, this.refresh$]).pipe(
    switchMap(([userId]) => this.userSvc.getUserId(userId)),
    switchMap(userId => (userId ? this.userSvc.getUser(userId) : of(null))),
    shareReplay(1)
  );

  refresh() {
    this.refresh$.next();
    this.refreshed.emit();
  }
}
