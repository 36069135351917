<greco-collapsible-section
  [expanded]="false"
  #paymentMethodSection
  id="payment-methods-section"
  [header]="{ title: 'Payment Methods', icon: 'payment' }"
  [disabled]="!hasReadAccess"
>
  <greco-add-payment-method-button
    *ngIf="hasAddAccess"
    header
    [accountId]="account.id"
    (saved)="$event && refresh() && paymentMethodSection.expand(true)"
    (click)="$event.stopImmediatePropagation()"
  ></greco-add-payment-method-button>
  <mat-radio-group [value]="defaultPaymentMethod || ''" (change)="selectDefaultPaymentMethod($event.value)">
    <greco-table [data]="paymentMethods" [highlight]="true" [loading]="loading">
      <!-- Icon -->
      <ng-container *grecoTableCol="''; let paymentMethod; fitContent: true">
        <div [matTooltip]="paymentMethod.id" style="display: flex; align-items: center; justify-content: center">
          <img style="width: 36px; min-width: 36px" [src]="'assets/payment-methods/' + paymentMethod.model + '.png'" />
        </div>
      </ng-container>

      <!-- Label -->
      <div *grecoTableCol="'Label'; let paymentMethod" style="line-height: 16px">
        <div>{{ paymentMethod.model | titlecase }}&nbsp;&nbsp;{{ paymentMethod.label }}</div>
        <div *ngIf="paymentMethod.details" style="font-size: 75%">Expires&nbsp;&nbsp;{{ paymentMethod.details }}</div>
      </div>

      <!-- Tags -->
      <mat-chip-list *grecoTableCol="''; let paymentMethod">
        <mat-chip *ngIf="usedPaymentMethods?.includes(paymentMethod.id)">In Use</mat-chip>
      </mat-chip-list>

      <!-- Top up Default -->
      <ng-container *grecoTableCol="'Default'; let paymentMethod">
        <mat-radio-button color="primary" [value]="paymentMethod.id"></mat-radio-button>
      </ng-container>

      <!-- Menu -->
      <ng-container *ngIf="hasRemoveAccess || hasSetDefaultAccess">
        <ng-container *grecoTableCol="''; let paymentMethod; fitContent: true; stickyEnd: true">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <button
              mat-menu-item
              [disabled]="paymentMethod.id === defaultPaymentMethod || !hasSetDefaultAccess"
              (click)="selectDefaultPaymentMethod(paymentMethod.id)"
            >
              <mat-icon>credit_score</mat-icon>
              <span>Set default</span>
            </button>

            <button
              mat-menu-item
              [disabled]="
                paymentMethod.id === defaultPaymentMethod ||
                !hasRemoveAccess ||
                usedPaymentMethods?.includes(paymentMethod.id)
              "
              (click)="removePaymentMethod(paymentMethod.id)"
            >
              <mat-icon>delete_forever</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>
  </mat-radio-group>
</greco-collapsible-section>
