import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { GrecoRouteModifier } from '../routing';

@Injectable({ providedIn: 'root' })
export class AutoNavigateResolver implements Resolve<void> {
  constructor(private router: Router) {}

  static async resolveRoute(route: ActivatedRouteSnapshot, router: Router, state: RouterStateSnapshot) {
    console.log('Auto Navigate:', { route });
    const targets = AutoNavigateResolver.data(route);
    console.log(`Attempting automatic navigation to [${targets.map(t => "'/" + t.join('/') + "'").join(', ')}]`);

    for (const target of targets) {
      console.log(`Attempting automatic navigation to '/${target.join('/')}'`);
      if ('/' + target.join('/') === state.url) {
        console.log(`Navigation Cancelled Already There`);
        break;
      }
      const navigation = await router.navigate(target).catch(() => false);
      console.log(`Navigation ${navigation ? 'succeeded' : 'failed'}`);
      if (navigation) break;
    }
  }

  static modifier = (...routes: string[]): GrecoRouteModifier => route => ({
    ...route,
    resolve: { ...route.resolve, autoNavigate: AutoNavigateResolver },
    data: { ...route.data, autoNavigate: routes }
  });

  private static data(route: ActivatedRouteSnapshot): string[][] {
    const targets = route.data?.autoNavigate;
    if (!Array.isArray(targets)) return [];

    const base = route.pathFromRoot.reduce(
      (acc, p) => [...acc, ...(p.url?.length > 0 ? p.url.map(u => u.path) : [])],
      []
    );
    return targets
      .filter(target => typeof target === 'string')
      .map(target => [...base, ...(target === '' ? [] : [target])]);
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = '/' + route.pathFromRoot.reduce((acc, p) => [...acc, ...p.url.map(u => u.path)], []).join('/');
    if (url === state.url.replace(/\?.*/, '')) await AutoNavigateResolver.resolveRoute(route, this.router, state);
  }
}
