import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { PaginatedQueryParams, PaginationMetadata } from '@greco-fit/nest-utils';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { SecurityService } from '@greco/ngx-security-util';
import { SaleCategory, SaleCategoryResource, SaleCategoryResourceAction } from '@greco/sales-purchases';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SaleCategoryDialog } from '../../dialogs';
import { SaleCategoryService } from '../../services';

@Component({
  selector: 'greco-sale-categories-table',
  templateUrl: './sale-categories-table.component.html',
  styleUrls: ['./sale-categories-table.component.scss'],
})
export class SaleCategoriesTableComponent implements OnDestroy {
  constructor(
    private categorySvc: SaleCategoryService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService
  ) {}

  @Output() rowClick = new EventEmitter<any>();

  @Input() set pageSizes(sizes: number[]) {
    this._pageSizes$.next(sizes?.length ? sizes : [10, 20, 50]);
    const paginatedParams = this.paginatedParams$.value;
    if (!this._pageSizes$.value.includes(paginatedParams.limit || 0)) {
      this.paginatedParams$.next({ ...paginatedParams, limit: this.pageSizes[0] });
    }
  }
  get pageSizes() {
    return this._pageSizes$.value;
  }

  @Input() set queryBuilder(queryBuilder: RequestQueryBuilder | null) {
    this._queryBuilder$.next(queryBuilder || new RequestQueryBuilder());
  }
  get queryBuilder() {
    return this._queryBuilder$.value;
  }

  @Input() set accountId(accountId: string | null) {
    this._accountId$.next(accountId);
  }
  get accountId() {
    return this._accountId$.value;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private _pageSizes$ = new BehaviorSubject<number[]>([10, 20, 50]);
  private _queryBuilder$ = new BehaviorSubject(new RequestQueryBuilder());
  private _accountId$ = new BehaviorSubject<string | null>(null);

  loading = true;
  currentPagination: PaginationMetadata | null = null;
  paginatedParams$ = new BehaviorSubject<PaginatedQueryParams>({
    page: 1,
    limit: 10,
  });

  readonly canUpdate$ = this._accountId$.pipe(
    switchMap(async accountId => {
      return accountId
        ? await this.securitySvc.hasAccess(SaleCategoryResource.key, SaleCategoryResourceAction.UPDATE, {
            accountId: accountId,
          })
        : false;
    })
  );

  categories$ = combineLatest([this._queryBuilder$, this.paginatedParams$, this._accountId$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),

    switchMap(async ([queryBuilder, params, accountId]) => {
      return accountId ? await this.categorySvc.paginate(queryBuilder, accountId, params) : null;
    }),
    tap(data => setTimeout(() => (this.currentPagination = data?.meta || null))),
    map(data => data?.items || []),

    tap(() => setTimeout(() => (this.loading = false)))
  );

  ngOnDestroy() {
    this._pageSizes$.complete();
    this._queryBuilder$.complete();
    this._accountId$.complete();
    this.paginatedParams$.complete();
  }

  refresh() {
    this.paginatedParams$.next(this.paginatedParams$.value);
  }

  async update(category: SaleCategory) {
    if (!this.accountId) return;
    const dialog = this.matDialog.open(SaleCategoryDialog, {
      data: { accountId: this.accountId, saleCategory: category },
    });
    await toPromise(dialog.afterClosed());
    this.refresh();
  }

  async archive(category: SaleCategory) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Archive',
        content: `This will prevent the category (${category.label}) from being added to any products in the future.`,
        buttons: [
          { label: "No, Don't Archive", role: 'no' },
          { label: 'Yes, Archive', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      try {
        await this.categorySvc.updateSaleCategory(category.id, { archivedOn: new Date() });
        this.snacks.open('Sale category archived', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err: any) {
        this.snacks.open(err?.error?.message, 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
      }
    }
    this.refresh();
  }

  async unarchive(category: SaleCategory) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Update',
        content: `This will allow the category (${category.label}) to be added to products.`,
        buttons: [
          { label: "No, Don't Unarchive", role: 'no' },
          { label: 'Yes, Unarchive', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const spinnerDialog = this.matDialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;
      try {
        await this.categorySvc.updateSaleCategory(category.id, { archivedOn: null });
        this.snacks.open('Sale category updated', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err) {
        this.snacks.open('Something went wrong!', 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
      }
      spinnerDialog.close();
    }
    this.refresh();
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
