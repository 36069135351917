import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgreementType } from '@greco/community-agreements';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CommunityAgreementsService } from '../../services';
@Component({
  selector: 'greco-create-agreement',
  templateUrl: './create-agreement.dialog.html',
  styleUrls: ['./create-agreement.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateAgreementDialog implements OnInit, OnDestroy {
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: FormBuilder,
    private security: CommunitySecurityService,
    private agreementService: CommunityAgreementsService,
    private snacks: MatSnackBar
  ) {}

  private _onDestroy$ = new Subject<void>();
  agreementTypeArray = [AgreementType.CHECKBOX, AgreementType.AUTO_CHECKBOX, AgreementType.DIGITAL_SIGNATURE];
  @ViewChild(SimpleDialog) private container!: SimpleDialog;

  formGroup = this.fb.group({
    title: ['', Validators.required],
    text: ['', Validators.required],
    agreementType: ['', Validators.required],
  });
  valueChanged(text: string) {
    if (this.formGroup.get('text')?.value !== text) {
      this.formGroup.get('text')?.setValue(text);
      this.formGroup.markAsDirty();
    }
  }
  ngOnInit() {
    this.formGroup.valueChanges
      .pipe(
        startWith(this.formGroup.value),
        takeUntil(this._onDestroy$),
        map(() => this.container?.data?.buttons?.find(btn => btn.role === 'create'))
      )
      .subscribe(button => button && ((button as any).disabled = this.formGroup.invalid));
  }

  getResult = async () => {
    try {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const role = await this.agreementService.createAgreement({
        communityId: this.data.communityId,
        title: this.formGroup.value.title,
        text: this.formGroup.value.text,
        agreementType: this.formGroup.value.agreementType,
        created: new Date(new Date().toLocaleString('en-US', { timeZone: localTimeZone })),
      });

      this.snacks.open('New agreement created!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });

      return role;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
