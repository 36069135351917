import { SaleCategory } from '@greco/sales-purchases';
import { IsEnum, IsInt, IsString } from 'class-validator';
import {
  ProductCondition,
  ProductConditionContext,
  ProductConditionDto,
  ProductConditionEvaluation,
  ProductConditionOperator,
  ProductConditionsRegistry,
} from '../user-availability-addon';

export const PRODUCT_CONDITION_TYPE_PURCHASE_CATEGORY_COUNT = 'purchase-category-count';

export interface PurchaseCategoryProductCondition extends ProductCondition {
  saleCategoryId: string;
  saleCategory?: SaleCategory;

  limit: number;
  operator: ProductConditionOperator;
}

export class PurchaseCategoryProductConditionDto extends ProductConditionDto {
  @IsString()
  saleCategoryId: string;

  @IsInt()
  limit: number;

  @IsEnum(ProductConditionOperator)
  operator: ProductConditionOperator;
}

ProductConditionsRegistry.registerCondition(
  PRODUCT_CONDITION_TYPE_PURCHASE_CATEGORY_COUNT,
  PurchaseCategoryProductConditionDto,
  (condition: PurchaseCategoryProductCondition, ctx: ProductConditionContext) =>
    evaluatePurchaseCondition(condition, ctx)
);

function evaluatePurchaseCondition(
  condition: PurchaseCategoryProductCondition,
  ctx: ProductConditionContext
): ProductConditionEvaluation {
  const purchases =
    ctx.categoryPurchases && ctx.categoryPurchases[condition.saleCategoryId]
      ? ctx.categoryPurchases[condition.saleCategoryId]
      : 0;

  const limit = condition.limit;
  const difference = limit - purchases;
  const title = condition.saleCategory?.label || condition.saleCategoryId;

  let result = false;
  let message = '';
  let error = '';

  switch (condition.operator) {
    case ProductConditionOperator.EQUALS:
      if (purchases === limit) {
        result = true;
        message = `After this purchase, you would be passed the purchase limit of ${limit} for ${title}.`;
      } else {
        result = false;
        error = `You need to have ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
    case ProductConditionOperator.GREATER_THAN:
      if (purchases > limit) {
        result = true;
      } else {
        result = false;
        error = `You need to have more than ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
    case ProductConditionOperator.GREATER_THAN_EQUALS:
      if (purchases >= limit) {
        result = true;
      } else {
        result = false;
        error = `You need to have at least ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
    case ProductConditionOperator.LESS_THAN:
      if (purchases < limit) {
        result = true;
        if (difference <= 0 || purchases === limit - 1)
          message = `After this purchase, you will be passed the purchase limit of ${limit} for ${title}.`;
        else
          message = `After this purchase, you will have ${difference} of ${limit} purchase${
            limit === 1 ? '' : 's'
          } left for ${title}.`;
      } else {
        result = false;
        error = `You need to have less than ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
    case ProductConditionOperator.LESS_THAN_EQUALS:
      if (purchases <= limit) {
        result = true;
        if (difference <= 0)
          message = `After this purchase, you will be passed the purchase limit of ${limit} for ${title}.`;
        else message = `After this purchase, you will have ${difference} of ${limit} purchases left for ${title}.`;
      } else {
        result = false;
        error = `You cannot of more than ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
  }

  return { result, message, error };
}
