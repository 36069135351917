import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { PerksModule } from '@greco/ngx-sales-perks';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import {} from '@vimeo/player';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  SelectFromSourceComponent,
  UnlockedGalleryComponent,
  UserVideoTableComponent,
  VideoCardComponent,
  VideoPlayerComponent,
  VideoPlayerWrapperComponent,
  VideoTagComponent,
} from './components';
import { VideoWatchProgressBarComponent } from './components/video-watch-progress-bar/video-watch-progress-bar.component';
import { VideoSelectComponent, VideoSendingDialog, VideoTagInputComponent, WatchVideoComponent } from './dialogs';
import { VideoAdminPage } from './pages';
import { DurationPipe } from './pipes';
import { UserVideoWatchTimeService, VideosService, VideoTagService, VideoUnlockService } from './services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    SimpleDialogModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    SafePipeModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatDatepickerModule,
    // Misc
    NgPipesModule,
    NgxCurrencyModule,
    SafePipeModule,

    //greco
    TableModule,
    PerksModule,
    FiltersModule,
    SimpleDialogModule,
    CollapsibleSectionModule,
    FormsModule,
    FormSaveButtonsModule,
    BoxModule,
    SmartImgModule,
    UserAvatarModule,
    PurchasesModule,
    PaymentsModule,
    JoinPipeModule,
    LetModule,
  ],
  declarations: [
    WatchVideoComponent,
    VideoTagInputComponent,
    VideoSendingDialog,
    VideoCardComponent,
    VideoAdminPage,
    SelectFromSourceComponent,
    VideoSelectComponent,
    VideoTagComponent,
    UserVideoTableComponent,
    UnlockedGalleryComponent,
    DurationPipe,
    VideoPlayerComponent,
    VideoPlayerWrapperComponent,
    VideoWatchProgressBarComponent,
  ],
  providers: [VideosService, VideoTagService, VideoUnlockService, UserVideoWatchTimeService],
  exports: [
    WatchVideoComponent,
    VideoSendingDialog,
    VideoCardComponent,
    VideoAdminPage,
    SelectFromSourceComponent,
    VideoSelectComponent,
    VideoTagComponent,
    UserVideoTableComponent,
    UnlockedGalleryComponent,
    DurationPipe,
    VideoPlayerWrapperComponent,
    VideoWatchProgressBarComponent,
  ],
})
export class VideosModule {
  static forRoot() {
    return {
      ngModule: VideosModule,
      providers: [],
    };
  }
}
