<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()"
>
  <div style="display: flex; align-items: center; justify-content: flex-end; height: 32px">
    <button
      mat-stroked-button
      *ngIf="canCreateResource$ | async"
      color="primary"
      style="height: 32px; line-height: 16px"
      (click)="createResource()"
    >
      <span>New Zoom</span>
    </button>
  </div>
</greco-filter-bar>

<greco-table
  [data]="(resources$ | async) || []"
  [highlight]="true"
  [loading]="loading"
  (rowClick)="openResource($event)"
>
  <div *grecoTableCol="''; let resource; fitContent: true" [matTooltip]="resource.id">
    <mat-icon> videocam </mat-icon>
  </div>

  <p *grecoTableCol="'Display Name'; let resource">{{resource.name}}</p>
  <a *grecoTableCol="'Calendar'; let resource" (click)="viewInCalendar(resource)"> View in Calendar </a>
  <mat-chip *grecoTableCol="'Status'; let resource"> {{ resource.disabled ? "Disabled" : "Enabled" }} </mat-chip>

  <ng-container *grecoTableCol="''; let resource; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button mat-menu-item (click)="openResource(resource);">
        <mat-icon>open_in_new</mat-icon>
        <span>View Details</span>
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
