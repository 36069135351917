import { SecurityResource } from '@greco/security';

export const BOOKING_OPTION_SECURITY_RESOURCE = 'booking_event_booking_option';

export enum BookingOptionSecurityResourceAction {
  MANAGE = 'MANAGE',
}

export const BookingOptionSecurityResource: SecurityResource = {
  key: BOOKING_OPTION_SECURITY_RESOURCE,
  module: 'booking',

  title: 'Event Booking Option',
  description: 'Manage event booking options',

  context: class {
    communityId?: string;
    bookingOptionId?: string;
  },

  actions: [
    {
      key: BookingOptionSecurityResourceAction.MANAGE,
      title: 'Manage',
      description: 'Ability to manage event booking',
    }, //
  ],
};
