import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { EventSeries } from '@greco/booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { combineLatest, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CourseService } from '../../services';

@Component({
  selector: 'greco-courses-card',
  templateUrl: './courses-card.component.html',
  styleUrls: ['./courses-card.component.scss'],
})
export class CoursesCardComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private courseSvc: CourseService,
    private userSvc: UserService
  ) {}
  @PropertyListener('course') private _course$ = new ReplaySubject<EventSeries>(1);
  @Input() course?: EventSeries;
  @Input() registrations = 0;
  @Input() userRegistrations = 0;

  currentUserId = this.userSvc.getUserId();

  canRegister$ = combineLatest([this.currentUserId, this._course$]).pipe(
    switchMap(async ([userId, course]) => {
      const registration = userId && course ? await this.courseSvc.getOneRegistration(userId, course.id) : null;
      return registration ? false : true;
    })
  );

  async goToDetails() {
    const registered = await toPromise(this.canRegister$);
    if (!registered) await this.router.navigate(['/'], { relativeTo: this.route });

    if (this.course) await this.router.navigate([this.course.id], { relativeTo: this.route });
  }
}
