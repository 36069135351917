import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { AddVariantPerkDto } from '@greco/nestjs-sales-products';
import { PerkService } from '@greco/ngx-sales-perks';
import { Perk } from '@greco/sales-perks';
import { Product, ProductVariant, VariantPerkExpiryEvent, VariantPerkGranted } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { VariantsService } from '../../services';

const IntegerValidator: ValidatorFn = control =>
  control.value ? (Number.isInteger(control.value) ? null : { integer: true }) : null;

@Component({
  selector: 'greco-add-variant-perk',
  templateUrl: './add-variant-perk.dialog.html',
  styleUrls: ['./add-variant-perk.dialog.scss'],
})
export class AddVariantPerkDialog implements OnInit {
  constructor(
    private snacks: MatSnackBar,
    private perkSvc: PerkService,
    private formBuilder: FormBuilder,
    private variantsService: VariantsService,
    private dialogRef: MatDialogRef<AddVariantPerkDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { product: Product; variant: ProductVariant }
  ) {}

  perks?: Perk[];

  dialogData: DialogData = {
    title: 'Add Perk',
    subtitle: 'Fill out the necessary fields to add a perk to this variant',
    hideDefaultButton: true,
  };

  form = this.formBuilder.group({
    perk: [null, Validators.required],
    quantity: [1, [Validators.required, Validators.min(1), IntegerValidator]],
    granted: [null, [Validators.required, Validators.minLength(1)]],
    expiryDelay: [null, [Validators.min(1), IntegerValidator]],
    expiryEvent: [null, Validators.minLength(1)],
    transferable: [false],
    reusable: [false],
    freezable: [true],
    expiryNotification: [false],
    expiryNotificationDays: [2, Validators.min(1)],
  });

  processing = false;

  perkSearchQuery$ = new BehaviorSubject<null | string>(null);
  perks$: Observable<Perk[]> = this.perkSearchQuery$.pipe(
    debounceTime(300),
    switchMap(async qry => {
      const query = new RequestQueryBuilder();
      query.search({ title: { $contL: qry || '' } });
      return (await this.perkSvc.paginate(query, this.data.product.community.id)).items;
    })
  );

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;

    try {
      const data = {
        perkId: this.form.value.perk?.id,
        variantId: this.data.variant.id,
        quantity: this.form.value.quantity,
        granted: this.form.value.granted as VariantPerkGranted[],
        expiryDelay: this.form.value.expiryDelay,
        expiryEvent: this.form.value.expiryEvent as VariantPerkExpiryEvent[],
        expiryNotificationDelay: this.form.value.expiryNotificationDays,
        transferable: this.form.value.transferable,
        freezable: this.form.value.freezable,
        reusable: this.form.value.reusable,
      } as AddVariantPerkDto;

      const variantPerk = this.variantsService.addVariantPerk({
        productId: this.data.product.id,
        variantId: this.data.variant.id,
        data,
      });

      this.snacks.open('Variant Perk created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.close(variantPerk);
    } catch (err: any) {
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
      this.close(null);
    }

    this.processing = false;
  }

  onChange(value?: string) {
    this.perkSearchQuery$.next(value || '');
  }

  displayPerk(perk: Perk) {
    return perk?.title;
  }

  async ngOnInit() {
    const page = await this.perkSvc.paginate(new RequestQueryBuilder(), this.data.product.community.id);
    this.perks = page.items;
  }
}
