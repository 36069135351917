import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SafePipeModule } from 'safe-pipe';
import { EmbedComponent } from './components/embed/embed.component';

export * from './components/embed/embed.component';

@NgModule({
  imports: [CommonModule, SafePipeModule, RouterModule],
  exports: [EmbedComponent],
  declarations: [EmbedComponent],
})
export class UiEmbedModule {}
