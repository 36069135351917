import { Component, Injectable, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Account } from '@greco/finance-accounts';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CreateAccountDialog } from '../../dialogs';
import { AccountService } from '../../services';

@Injectable()
export class AccountNameFilter extends BuildSearchFilter('AccountNameFilter', {
  properties: ['name'],
  propertyLabels: ['Account Name'],
}) {}

@Component({
  selector: 'greco-accounts-page',
  templateUrl: './accounts.page.html',
  styleUrls: ['./accounts.page.scss'],
})
export class AccountsPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountSvc: AccountService,
    private dialog: MatDialog
  ) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  filterOptions = [AccountNameFilter];

  loading = true;
  pagination: null | IPaginationMeta = null;

  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  accounts$ = combineLatest([this.page$, this.filters$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    debounceTime(500),
    switchMap(([page, filters]) => this.accountSvc.paginateAccounts(filters, page)),
    tap(({ meta }) => setTimeout(() => (this.pagination = meta))),
    map(({ items }) => items),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  async rowClick(account: Account) {
    await this.router.navigate([account.id], { relativeTo: this.route });
  }

  async createAcount() {
    const result = await toPromise(this.dialog.open(CreateAccountDialog).afterClosed());
    if (result?.id) await this.router.navigate([result.id], { relativeTo: this.route });
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
