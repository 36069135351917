import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AccountLink,
  AccountLinkingSecurityResource,
  AccountLinkingSecurityResourceAction,
} from '@greco/account-linking';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountLinkingService } from '../../services';

@Component({
  selector: 'greco-linked-accounts-requests-table-mobile',
  templateUrl: './linked-accounts-requests-table.component.html',
  styleUrls: ['./linked-accounts-requests-table.component.scss'],
})
export class MobileLinkedAccountsRequestsTableComponent implements OnInit {
  constructor(
    private userSvc: UserService,
    private snackbar: MatSnackBar,
    private linkingSvc: AccountLinkingService,
    private comSecuritySvc: CommunitySecurityService
  ) {}

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;

  @Input() loading = false;

  @Output() refresh = new EventEmitter();
  @Input() ownedLinks: AccountLink[] = [];
  @Input() grantedLinks: AccountLink[] = [];

  activeTab: 'inbox' | 'outbox' = 'inbox';

  signedInUser$ = this.userSvc.user$;
  isUser$ = combineLatest([this.user$, this.signedInUser$]).pipe(
    switchMap(async ([user, signedInUser]) => {
      if (!user || !signedInUser) return false;
      return user.id === signedInUser.id;
    })
  );

  canRevoke$ = combineLatest([this.isUser$, this.signedInUser$]).pipe(
    switchMap(async ([isUser]) => {
      if (isUser) return true;
      else
        return (
          await this.comSecuritySvc.communitiesWithAccess(
            AccountLinkingSecurityResource.key,
            AccountLinkingSecurityResourceAction.REVOKE
          )
        )?.length > 0
          ? true
          : false;
    })
  );

  canUpdate$ = combineLatest([this.isUser$, this.signedInUser$]).pipe(
    switchMap(async ([isUser]) => {
      if (isUser) return true;
      else
        return (
          await this.comSecuritySvc.communitiesWithAccess(
            AccountLinkingSecurityResource.key,
            AccountLinkingSecurityResourceAction.UPDATE
          )
        )?.length > 0
          ? true
          : false;
    })
  );

  async revokeAccess(link: AccountLink) {
    const response = await this.linkingSvc.revokeAccess(link.id);
    if (response) {
      this.snackbar.open('Link Removed!', '', { duration: 5000, panelClass: 'mat-primary' });
      this.refresh.emit(link);
    }
  }

  async accept(link: AccountLink) {
    const response = await this.linkingSvc.accept(link.id);
    if (response) this.refresh.emit();
  }

  ngOnInit(): void {
    console.log(this.grantedLinks?.length, this.ownedLinks?.length);
    if (!this.grantedLinks?.length && this.ownedLinks?.length) {
      this.activeTab = 'outbox';
    }
  }
}
