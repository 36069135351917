import { SaleCategory } from '@greco/sales-purchases';
import { IsBoolean, IsString } from 'class-validator';
import {
  ProductCondition,
  ProductConditionContext,
  ProductConditionDto,
  ProductConditionEvaluation,
  ProductConditionsRegistry,
} from '../user-availability-addon';

export const PRODUCT_CONDITION_TYPE_SUBSCRIPTION_SALE_CATEGORY = 'subscription-sale-category';

export interface SubscriptionSaleCategoryProductCondition extends ProductCondition {
  saleCategoryId: string;
  saleCategory?: SaleCategory;
  hasSubscription: boolean;
}

export class SubscriptionSaleCategoryProductConditionDto extends ProductConditionDto {
  @IsString()
  saleCategoryId: string;

  @IsBoolean()
  hasSubscription: boolean;
}

function evaluateSubscriptionCondition(
  condition: SubscriptionSaleCategoryProductCondition,
  ctx: ProductConditionContext
): ProductConditionEvaluation {
  const subscriptions: number =
    ctx.categorySubscriptions && ctx.categorySubscriptions[condition.saleCategoryId]
      ? ctx.categorySubscriptions[condition.saleCategoryId]
      : 0;
  if (!condition.hasSubscription) {
    if (subscriptions > 0)
      return { result: false, error: `You cannot be subscribed to "${condition.saleCategory?.label}".` };
    else return { result: true };
  } else if (subscriptions <= 0)
    return { result: false, error: `You have to be subscribed to "${condition.saleCategory?.label}".` };
  else return { result: true };
}

ProductConditionsRegistry.registerCondition(
  PRODUCT_CONDITION_TYPE_SUBSCRIPTION_SALE_CATEGORY,
  SubscriptionSaleCategoryProductConditionDto,
  (condition: SubscriptionSaleCategoryProductCondition, ctx: ProductConditionContext) =>
    evaluateSubscriptionCondition(condition, ctx)
);
