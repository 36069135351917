import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { Subscription } from '@greco/sales-subscriptions';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-update-minimum-commitment-dialog',
  templateUrl: './update-minimum-commitment.dialog.html',
})
export class UpdateMinimumCommitmentDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private subscriptionSvc: SubscriptionsService,
    private dialogRef: MatDialogRef<UpdateMinimumCommitmentDialog>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { subscription: Subscription }
  ) {
    this.form.patchValue({ date: this.data.subscription.minimumCommitmentDate });
  }

  processing = false;

  selectedDate = new Date();

  dialogData: DialogData = {
    title: "Update This Subscription's Minimum Commitment",
    subtitle: 'Select a new minimum commitment date',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  form = this.formBuilder.group({
    date: [Validators.required],
  });

  async submitUpdate() {
    try {
      if (this.form.value.date) {
        this.processing = true;

        const subscription = await this.subscriptionSvc.updateMinimumCommitment(
          this.data.subscription.id,
          this.form.value.date
        );

        this.snacks.open('Subscription updated successfully', 'Ok', { duration: 6000, panelClass: 'mat-primary' });
        this.processing = false;
        this.dialogRef.close(subscription);
      } else {
        this.processing = false;
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      this.processing = false;
      this.snacks.open('Subscription failed to update', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
    }
  }

  async removeDate() {
    try {
      this.processing = true;

      const subscription = await this.subscriptionSvc.updateMinimumCommitment(this.data.subscription.id, null);

      this.snacks.open('Subscription updated successfully', 'Ok', { duration: 6000, panelClass: 'mat-primary' });
      this.processing = false;
      this.dialogRef.close(subscription);
    } catch (error) {
      console.error(error);
      this.processing = false;
      this.snacks.open('Subscription failed to update', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
    }
  }
}
