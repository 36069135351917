<div class="configure-condition-container">
  <h2>{{ _dialogTitle }}</h2>

  <form *ngIf="_formGroup" [formGroup]="_formGroup">
    <dynamic-material-form [group]="_formGroup" [model]="_formModel"></dynamic-material-form>
  </form>

  <div style="display: flex; align-items: center">
    <button mat-stroked-button style="flex: 1; margin-right: 16px" [disabled]="saving$ | async" (click)="cancel()">
      <span>Cancel</span>
    </button>

    <button mat-flat-button color="primary" style="flex: 1" [disabled]="cantSubmit$ | async" (click)="submit()">
      <span>Confirm</span>
      <mat-icon *ngIf="saving$ | async" class="spin">loop</mat-icon>
    </button>
  </div>
</div>
