import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'isToday' })
export class IsTodayPipe implements PipeTransform {
  transform(value: Date) {
    if (!value) return false;

    const date = moment(value);
    if (!date.isValid()) return false;

    return date.isSame(moment(), 'day');
  }
}
