<div style="display: flex; align-items: center">
  <div *ngIf="!!value" style="width: 24px; height: 24px; margin-right: 8px">
    <greco-user-avatar [showOnlineStatus]="false" [user]="value" style="width: 24px; height: 24px"></greco-user-avatar>
  </div>

  <input
    matInput
    type="text"
    [disabled]="disabled"
    [required]="required"
    [matAutocomplete]="auto"
    class="user-picker-input"
    [placeholder]="placeholder"
    (input)="searchTerm$.next($any($event.target).value)"
    (blur)="touched()"
  />
</div>

<mat-autocomplete
  autoActiveFirstOption
  #auto="matAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="optionSelected($event)"
>
  <mat-option *ngFor="let user of users$ | async" [value]="user">
    <div style="display: flex; align-items: center">
      <div style="width: 24px; height: 24px; margin-right: 8px">
        <greco-user-avatar
          [showOnlineStatus]="false"
          [user]="user"
          style="width: 24px; height: 24px"
        ></greco-user-avatar>
      </div>
      <div>
        <ng-container *ngIf="user.displayName; else noDisplayName">
          {{ user.displayName }} <small>({{ user.email }})</small>
        </ng-container>
        <ng-template #noDisplayName>{{ user.email }}</ng-template>
      </div>
    </div>
  </mat-option>
</mat-autocomplete>
