import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BoxModule } from '@greco/ui-box';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { EditorModule } from '@greco/ui-editor';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { SafePipeModule } from 'safe-pipe';
import { LegalPreviewComponent } from './components/legal-preview/legal-preview.component';
import { EditLegalPage } from './pages/edit-legal/edit-legal.page';
import { LegalPage } from './pages/legal/legal.page';

export * from './pages/edit-legal/edit-legal.page';
export * from './pages/legal/legal.page';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    SimpleDialogModule,
    BoxModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    SafePipeModule,
    EditorModule,
    FormSaveButtonsModule,
  ],
  exports: [LegalPage, LegalPreviewComponent, EditLegalPage],
  declarations: [LegalPreviewComponent, LegalPage, EditLegalPage],
})
export class LegalModule {}
