import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormSaveButtonsComponent } from './components/form-save-buttons.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  exports: [FormSaveButtonsComponent],
  declarations: [FormSaveButtonsComponent],
})
export class FormSaveButtonsModule {}
