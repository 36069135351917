<greco-table [data]="conditions" *ngIf="conditions.length">
  <mat-icon *grecoTableCol="''; let condition; fitContent: true" [matTooltip]="condition.id || ''">sell</mat-icon>

  <p *grecoTableCol="'Details'; let condition">
    {{ condition | productConditionDetails }}
  </p>

  <ng-container *grecoTableCol="''; let condition; let index = index; fitContent: true; stickyEnd: true">
    <div style="display: flex; align-items: center">
      <ng-container *ngIf="_confirmingRemoval === index">
        <button
          mat-flat-button
          type="button"
          color="warn"
          style="height: 32px; line-height: 16px; margin-right: 8px"
          (click)="removeCondition(index)"
        >
          Confirm
        </button>

        <button mat-icon-button type="button" (click)="_confirmingRemoval = null">
          <mat-icon>highlight_off</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngIf="_confirmingRemoval !== index">
        <button
          mat-icon-button
          type="button"
          color="warn"
          style="margin-right: 8px"
          (click)="_confirmingRemoval = index"
        >
          <mat-icon>delete</mat-icon>
        </button>

        <button
          *ngIf="_editableMap[condition.type]"
          mat-icon-button
          type="button"
          (click)="updateConditionConfiguration(index, condition)"
        >
          <mat-icon>create</mat-icon>
        </button>
      </ng-container>
    </div>
  </ng-container>
</greco-table>

<ng-container *ngIf="conditions.length">
  <div *ngIf="!_addingCondition" style="height: 32px; padding: 8px">
    <button mat-button style="width: 100%; height: 100%" type="button" (click)="_addingCondition = true">
      <mat-icon
        style="--icon-size: 20px; height: var(--icon-size); width: var(--icon-size); font-size: var(--icon-size)"
      >
        add_circle_outline
      </mat-icon>
      <span>Add Condition</span>
    </button>
  </div>

  <div
    *ngIf="_addingCondition"
    style="
      height: 47px;
      border-bottom: 1px solid rgba(14, 15, 15, 0.12);
      padding: 0 32px;
      display: flex;
      align-items: center;
      gap: 24px;
    "
  >
    <mat-icon style="color: var(--primary-color); width: 20px; height: 20px; font-size: 20px">sell</mat-icon>

    <mat-form-field style="height: 100%; flex: 1" appearance="standard" id="test">
      <mat-select #typeSelect placeholder="Select a condition type">
        <mat-option *ngFor="let form of forms" [value]="form">{{ form.label }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div style="border-left: 1px solid rgba(14, 15, 15, 0.12); display: flex; align-items: center; padding-left: 12px">
      <button
        mat-flat-button
        type="button"
        color="primary"
        style="height: 32px; line-height: 16px"
        [disabled]="!typeSelect.value"
        (click)="addCondition(typeSelect.value)"
      >
        Add
      </button>

      <button mat-icon-button type="button" style="margin-left: 8px" (click)="_addingCondition = false">
        <mat-icon>highlight_off</mat-icon>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!conditions.length">
  <div style="padding: 16px 32px">
    <button mat-flat-button color="primary" type="button" style="margin: auto" [matMenuTriggerFor]="typesMenu">
      <mat-icon>add</mat-icon>
      <span>Add Condition</span>
    </button>

    <mat-menu #typesMenu>
      <button mat-menu-item *ngFor="let form of forms" (click)="addCondition(form)">
        <mat-icon>{{ form.matIcon }}</mat-icon>
        <span>{{ form.label }}</span>
      </button>
    </mat-menu>
  </div>
</ng-container>
