import { CondOperator } from '@nestjsx/crud-request';
import { Filter, FilterOptions } from './filter';

export abstract class MultiAutocompleteFilter extends Filter {
  type: 'multiautocomplete' = 'multiautocomplete';

  constructor(typeName: string, options: Omit<FilterOptions, 'type' | 'allowedOperators'>) {
    super(typeName, { ...options, type: 'select', hideCond: true, allowedOperators: [CondOperator.IN] });
  }

  abstract getImageUrl(value: any): string | null | undefined;
  abstract getAutocompleteHtml(value: any): string;

  compareWith(value1: any, value2: any): boolean {
    return value1 === value2;
  }
}
