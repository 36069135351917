import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TimezoneModule } from '@greco/feature-timezone';
import { GrecoCalendarModule } from '@greco/ngx-calendar';
import { FiltersModule } from '@greco/ngx-filters';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { GrecoFinanceFeatureTaxModule } from '@greco/ngx-finance-tax';
import { CommunitiesModule } from '@greco/ngx-identity-communities';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { UsersModule } from '@greco/ngx-identity-users';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { PerksModule } from '@greco/ngx-sales-perks';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { TypeformModule } from '@greco/ngx-typeform';
import { ZoomModule } from '@greco/ngx-zoom';
import { AlertModule } from '@greco/ui-alert';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { EditorModule } from '@greco/ui-editor';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { IconPickerModule } from '@greco/ui-icon-picker';
import { ImageUploadModule } from '@greco/ui-image-upload';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  AddBookingToCalendarButtonComponent,
  AssignmentPickerComponent,
  AvailabilityStatusComponent,
  BookingOptionBookingAndCancellationFormComponent,
  BookingOptionDetailsFormComponent,
  BookingOptionsTableComponent,
  BookingsTableComponent,
  CalendarDetailsComponent,
  CalendarDetailsInputComponent,
  CalendarPickerComponent,
  CalendarsTableComponent,
  CalendarTemplateDetailsComponent,
  CommunityCalendarPickerComponent,
  CourseBookingsTableComponent,
  CoursesCardComponent,
  CoursesGridComponent,
  CoursesSearchComponent,
  CourseWeekComponent,
  EventCommunityAgreementUsageComponent,
  EventDetailsComponent,
  EventDetailsInputComponent,
  EventFromTemplateInputComponent,
  EventListGroupComponent,
  EventResourceAssignmentComponent,
  EventsCalendarComponent,
  EventTemplatesDetailsComponent,
  EventTemplatesInputComponent,
  MinutesInputComponent,
  PersonResourceInputComponent,
  ResourceTagPickerComponent,
  RoomSpotPickerComponent,
  RoomSpotsComponent,
  RoomSpotShowerComponent,
  ScheduleContentComponent,
  SelectCommunityAgrrementComponent,
  TagsInputComponent,
  UserBookingsCalendarComponent,
  UserEventListComponent,
  UserEventListItemComponent,
  UserEventsDateFiltersComponent,
  UserEventsFiltersComponent,
  WaitlistTableComponent,
  ZoomResourceInputComponent,
} from './components';
import { PreBookingDirective } from './components/event-from-template-input/pre-booking.directive';
import { SeriesScheduleInputComponent } from './components/series-schedule-input/series-schedule-input.component';
import {
  AddAttendeeDialog,
  AddBookingOptionDialog,
  AddToWaitlistDialog,
  BookingInfoDialog,
  BusyResourcesDialog,
  ConfigureRoomSpotsDialog,
  CreateCalendarDialog,
  CreateEventDialog,
  CreateEventFromTemplateDialog,
  CreateEventTemplateDialog,
  CreateOptionDialog,
  CreateResourceDialog,
  CreateRoomResourceDialog,
  CreateTagDialog,
  DateRangePickerDialog,
  DeleteUserFromEventDialog,
  LinkAgreementDialog,
  ManageBookingTagsDialog,
  ManageTagsDialog,
  RescheduleEventDialog,
  ReviewInstancesDialog,
  SpecifyConfirmationsDialog,
  SpecifySubstitutionsDialog,
  UpdateAssignmentsDialog,
  UpdateSeriesFromEventDialog,
  UpdateTagDialog,
  UserAddGuestDialog,
  ViewCommunityAgreementDialog,
} from './dialogs';
import { AccountCompletionDialog } from './dialogs/account-completion/account-completion.dialog';
import { ConfirmAddAttendeeDialog } from './dialogs/confirm-add-attendee/confirm-add-attendee.dialog';
import {
  BookabilityPage,
  BookingOptionPage,
  BookingOptionRequirementComponent,
  BookingsPage,
  CalendarPage,
  CalendarsPage,
  CancellationPolicyRequirementComponent,
  EmailVerificationRequirementComponent,
  EventPage,
  EventsPage,
  EventTemplatePage,
  EventTemplatesPage,
  LockedVideosPage,
  NewEventPage,
  PaymentMethodRequirementComponent,
  PreBookingOptionPickerComponent,
  PreviewBookingPage,
  ProfileCompletionRequirementComponent,
  ResourceEventsPage,
  RoomResourcePage,
  RoomResourcesPage,
  SchedulePage,
  SeriesDetailsPage,
  SeriesPage,
  TrainerResourcePage,
  TrainerResourcesPage,
  TypeformRequirementComponent,
  UserCoursePage,
  UserCoursesPage,
  UserEventsPage,
  ZoomResourcePage,
  ZoomResourcesPage,
} from './pages';
import { MultiBookingPaymentPreviewComponent } from './pages/user/multi-booking-payment-preview/multi-booking-payment-preview.component';
import { MultiBookingPreviewComponent } from './pages/user/multi-booking-preview/multi-booking-preview.component';
import { PreviewBookingBookingOptionPickerComponent } from './pages/user/preview-booking-2/booking-option-picker/booking-option-picker.component';
import { PreviewBookingEventDetailsComponent } from './pages/user/preview-booking-2/event-details/event-details.component';
import { PreviewBooking2Page } from './pages/user/preview-booking-2/preview-booking-2.page';
import { BookingOptionItemComponent } from './pages/user/preview-booking/booking-option-item/booking-option-item.component';
import { BookingPreviewBookingOptionPickerComponent } from './pages/user/preview-booking/booking-option-picker/booking-option-picker.component';
import { PreviewCourseComponent } from './pages/user/user-course/preview-course/preview-course.component';
import {
  BookingIconPipe,
  BookingIncludesResource,
  CancellationPolicyLabelPipe,
  FilterBookingRequirementsPipe,
  IsInTheFuturePipe,
  IsInThePastPipe,
  IsSameDayPipe,
  IsTodayPipe,
  MinutesPipe,
  RecurrenceSplitPipe,
  SeriesStatusPipe,
  WeekdayChipPipe,
} from './pipes';
import { InUndoWindow } from './pipes/in-undo-window.pipe';
import { ZoomAuthenticationResolver } from './resolvers';
import {
  BookingOptionService,
  BookingService,
  CalendarService,
  CourseService,
  EventService,
  EventTemplateService,
  ResourcesService,
  ResourceTagService,
  TagService,
  UserEventsFavoritesService,
  UserEventsService,
} from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    // Misc
    NgPipesModule,
    SafePipeModule,
    NgxCurrencyModule,
    NgxMaskModule,
    MomentModule,
    QRCodeModule,

    // Material
    UsersModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatRadioModule,
    MatSidenavModule,
    MatBottomSheetModule,
    MatBadgeModule,
    OverlayModule,

    // Greco
    BoxModule,
    LetModule,
    ZoomModule,
    PerksModule,
    TableModule,
    FiltersModule,
    SmartImgModule,
    TypeformModule,
    TimezoneModule,
    ContactsModule,
    JoinPipeModule,
    PaymentsModule,
    PurchasesModule,
    CommunitiesModule,
    SimpleDialogModule,
    GrecoCalendarModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,
    UserAvatarModule,
    EditorModule,
    ImageUploadModule,
    IconPickerModule,
    GrecoFinanceFeatureTaxModule,
    FormsModule,
    AlertModule,
  ],
  providers: [
    TagService,
    EventService,
    CourseService,
    BookingService,
    ResourcesService,
    ResourceTagService,
    BookingOptionService,
    ZoomAuthenticationResolver,
    CalendarService,
    EventTemplateService,
    UserEventsService,
    UserEventsFavoritesService,
  ],
  declarations: [
    PreBookingDirective,

    PreviewBooking2Page,
    BookingPreviewBookingOptionPickerComponent,
    PreviewBookingEventDetailsComponent,
    PreviewBookingBookingOptionPickerComponent,
    PreBookingOptionPickerComponent,

    // Pipes
    IsTodayPipe,
    MinutesPipe,
    IsSameDayPipe,
    WeekdayChipPipe,
    InUndoWindow,
    IsInThePastPipe,
    IsInTheFuturePipe,
    BookingIconPipe,
    BookingIncludesResource,
    SeriesStatusPipe,
    RecurrenceSplitPipe,
    CancellationPolicyLabelPipe,
    FilterBookingRequirementsPipe,

    // Components
    AddBookingToCalendarButtonComponent,
    TypeformRequirementComponent,
    PaymentMethodRequirementComponent,
    BookingOptionRequirementComponent,
    EmailVerificationRequirementComponent,
    CancellationPolicyRequirementComponent,
    ProfileCompletionRequirementComponent,
    TagsInputComponent,
    RoomSpotPickerComponent,
    RoomSpotShowerComponent,
    RoomSpotsComponent,
    EventListGroupComponent,
    MinutesInputComponent,
    EventDetailsComponent,
    BookingsTableComponent,
    UserEventListComponent,
    WaitlistTableComponent,
    EventsCalendarComponent,
    AssignmentPickerComponent,
    ResourceTagPickerComponent,
    UserEventListItemComponent,
    EventDetailsInputComponent,
    UserEventsFiltersComponent,
    BookingOptionItemComponent,
    AvailabilityStatusComponent,
    BookingOptionsTableComponent,
    SeriesScheduleInputComponent,
    EventTemplatesInputComponent,
    EventTemplatesDetailsComponent,
    EventFromTemplateInputComponent,
    UserBookingsCalendarComponent,
    UserEventsDateFiltersComponent,
    UserEventsDateFiltersComponent,
    EventResourceAssignmentComponent,
    BookingOptionDetailsFormComponent,
    BookingOptionBookingAndCancellationFormComponent,
    EventCommunityAgreementUsageComponent,
    CoursesSearchComponent,
    CoursesGridComponent,
    CoursesCardComponent,
    CourseWeekComponent,
    CalendarsTableComponent,
    CalendarDetailsComponent,
    CalendarDetailsInputComponent,
    CommunityCalendarPickerComponent,
    CalendarPickerComponent,
    CalendarTemplateDetailsComponent,
    CourseBookingsTableComponent,
    ScheduleContentComponent,
    //UserWorkOutLibraryFiltersComponent,
    //LockedGalleryComponent,

    // Pages
    EventPage,
    EventsPage,
    NewEventPage,
    BookingsPage,
    SeriesPage,
    SeriesDetailsPage,
    EventTemplatesPage,
    EventTemplatePage,
    TrainerResourcePage,
    TrainerResourcesPage,
    RoomResourcePage,
    RoomResourcesPage,
    ZoomResourcePage,
    ZoomResourcesPage,
    UserEventsPage,
    UserCoursesPage,
    UserCoursePage,
    BookabilityPage,
    BookingOptionPage,
    ResourceEventsPage,
    PreviewBookingPage,
    LockedVideosPage,
    CalendarsPage,
    CalendarPage,
    SchedulePage,

    // Dialogs
    CreateRoomResourceDialog,
    ConfigureRoomSpotsDialog,
    CreateEventDialog,
    AddAttendeeDialog,
    ConfirmAddAttendeeDialog,
    CreateOptionDialog,
    CreateEventTemplateDialog,
    CreateEventFromTemplateDialog,
    AddToWaitlistDialog,
    CreateResourceDialog,
    DateRangePickerDialog,
    RescheduleEventDialog,
    ReviewInstancesDialog,
    BookingInfoDialog,
    CreateTagDialog,
    ManageTagsDialog,
    UpdateTagDialog,
    CreateCalendarDialog,
    PersonResourceInputComponent,
    ZoomResourceInputComponent,
    LinkAgreementDialog,
    SelectCommunityAgrrementComponent,
    ViewCommunityAgreementDialog,
    SpecifySubstitutionsDialog,
    SpecifyConfirmationsDialog,
    UserCoursePage,
    UpdateAssignmentsDialog,
    DeleteUserFromEventDialog,
    MultiBookingPreviewComponent,
    MultiBookingPaymentPreviewComponent,
    UserAddGuestDialog,
    PreviewCourseComponent,
    BusyResourcesDialog,
    AddBookingOptionDialog,
    AccountCompletionDialog,
    ConfirmAddAttendeeDialog,
    UpdateSeriesFromEventDialog,
    ManageBookingTagsDialog,
  ],
  exports: [
    PreviewBooking2Page,
    // Components
    UserBookingsCalendarComponent,
    UserEventsDateFiltersComponent,
    BookingsTableComponent,
    EventCommunityAgreementUsageComponent,
    EventDetailsComponent,
    EventDetailsInputComponent,
    PreviewBookingEventDetailsComponent,
    PreBookingOptionPickerComponent,
    AddBookingToCalendarButtonComponent,

    // Pages
    EventPage,
    EventsPage,
    NewEventPage,
    SeriesPage,
    SeriesDetailsPage,
    BookingsPage,
    EventTemplatesPage,
    EventTemplatePage,
    TrainerResourcePage,
    TrainerResourcesPage,
    RoomResourcePage,
    RoomResourcesPage,
    ZoomResourcePage,
    ZoomResourcesPage,
    UserEventsPage,
    UserCoursesPage,
    UserCoursePage,
    BookabilityPage,
    BookingOptionPage,
    ResourceEventsPage,
    PreviewBookingPage,
    LockedVideosPage,
    CalendarsPage,
    CalendarPage,
    SchedulePage,

    // Dialogs
    CreateOptionDialog,
    CreateOptionDialog,
    CreateTagDialog,
    UpdateTagDialog,
    ManageTagsDialog,
    BookingInfoDialog,
    LinkAgreementDialog,
    ViewCommunityAgreementDialog,
    MultiBookingPreviewComponent,
    AddBookingOptionDialog,

    //Pipes
    IsInThePastPipe,
    IsSameDayPipe,
    IsTodayPipe,
  ],
})
export class EventsModule {
  static forRoot() {
    return {
      ngModule: EventsModule,
      providers: [],
    };
  }
}
