import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { CreatePromotionDto, Promotion, UpdatePromotionDto } from '@greco/promotions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PromotionsDialog } from '../dialogs/promotions/promotions.dialog';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  async showPromotions() {
    return toPromise(
      combineLatest([this.userService.user$, this.getUserPromotions$()]).pipe(
        map(
          ([user, prms]) =>
            [
              user,
              prms.map(p => ({ ...p, seen: localStorage.getItem('promosSeen')?.split(';').includes(p.id) || false })),
            ] as [User, (Promotion & { seen: boolean })[]]
        ),
        filter(([user, prms]: [User, (Promotion & { seen: boolean })[]]) => user && !!prms.length),
        tap(([_user, prms]) => {
          localStorage.setItem('promosSeen', prms.map(p => p.id).join(';'));
          if (prms.some(p => !p.seen))
            this.dialog.open(PromotionsDialog, { data: { promotions: prms }, width: '950px', maxWidth: '90%' });
          this.snackbar
            .open(prms.map(p => p.title).join('; '), 'View Offer' + (prms.length !== 1 ? 's' : ''), {
              panelClass: 'mat-accent',
            })
            .onAction()
            .subscribe(() =>
              this.dialog.open(PromotionsDialog, { data: { promotions: prms }, width: '950px', maxWidth: '90%' })
            );
        })
      )
    );
  }

  getPromotion(promotionId: string) {
    return toPromise(this.http.get<Promotion>('/api/promotions/' + promotionId));
  }

  async create(createPromotionDto: CreatePromotionDto): Promise<Promotion> {
    return toPromise(this.http.post<Promotion>('/api/promotions/create', createPromotionDto));
  }

  async update(updatePromotionDto: UpdatePromotionDto): Promise<Promotion> {
    return toPromise(this.http.post<Promotion>('/api/promotions/update', updatePromotionDto));
  }

  async paginatedCommunityPromotions(
    query: RequestQueryBuilder,
    communityId: string,
    pagination?: Partial<PaginatedQueryParams>
  ) {
    return await toPromise(
      this.http.get<PaginatedDto<Promotion>>('/api/promotions', {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  getUserPromotions$(): Observable<Promotion[]> {
    return this.userService.user$.pipe(
      switchMap(user =>
        this.http.get<Promotion[]>('/api/promotions/userPromotions', {
          params: { ...(user ? { userId: user?.id } : {}) },
        })
      )
    );
  }

  async getUserPromotions(): Promise<Promotion[]> {
    return toPromise(this.http.get<Promotion[]>('/api/promotions/userPromotions'));
  }
}
