import { Type } from 'class-transformer';
import { IsOptional } from 'class-validator';
import { ProductCondition, ProductConditionDto, ProductConditionsRegistry } from '../user-availability-addon';

export const PRODUCT_CONDITION_TYPE_DATE_RANGE = 'date-range';

export interface DateRangeProductCondition extends ProductCondition {
  availableFrom?: Date;
  availableUntil?: Date;
}

export class DateRangeProductConditionDto extends ProductConditionDto {
  @IsOptional()
  @Type(() => Date)
  availableFrom?: Date;

  @IsOptional()
  @Type(() => Date)
  availableUntil?: Date;
}

ProductConditionsRegistry.registerCondition(
  PRODUCT_CONDITION_TYPE_DATE_RANGE,
  DateRangeProductConditionDto,
  (condition: DateRangeProductCondition) => {
    const lowerBoundary = condition.availableFrom?.getTime() ?? 0;
    const upperBoundary = condition.availableUntil?.getTime() ?? Infinity;

    const now = Date.now();
    const result = lowerBoundary <= now && now < upperBoundary;
    return { result, error: !result ? 'This product is not available at this time.' : null };
  }
);
