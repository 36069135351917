import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { AccountTax, Tax } from '@greco/finance-tax';
import { AddAccountTaxDto, CreateAccountTaxDto, UpdateAccountTaxDto } from '@greco/nestjs-finance-tax';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class AccountTaxService {
  constructor(private http: HttpClient) {}

  async paginate(query: RequestQueryBuilder, accountId: string, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<AccountTax>>(`/api/taxes/account/paginate/${accountId}`, {
        params: {
          ...query.queryObject,
          ...(accountId && { accountId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  async getOne(taxId: string, financeAccountId: string) {
    return await toPromise(this.http.get<AccountTax>(`/api/taxes/account`, { params: { taxId, financeAccountId } }));
  }

  async getAccountTaxes(financeAccountId: string) {
    return await toPromise(this.http.get<AccountTax[]>(`/api/taxes/account/${financeAccountId}`));
  }
  async getAccountDefaultTaxes(financeAccountId: string) {
    return await toPromise(this.http.get<Tax[]>(`/api/taxes/account/${financeAccountId}/defaults`));
  }
  async createTax(dto: CreateAccountTaxDto) {
    return await toPromise(this.http.post<AccountTax>(`/api/taxes/account/create`, dto));
  }

  async addTax(dto: AddAccountTaxDto) {
    return await toPromise(this.http.post<AccountTax>(`/api/taxes/account/add`, dto));
  }

  async updateTax(accountId: string, dto: UpdateAccountTaxDto) {
    return await toPromise(this.http.put<AccountTax>(`/api/taxes/account/${accountId}`, dto));
  }
  async archiveTax(taxId: string, accountId: string) {
    return await toPromise(this.http.delete(`/api/taxes/account/${accountId}/${taxId}`));
  }
}
