import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CalendarEvent } from '@greco/booking-events';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { UserEventsFavoritesService, UserEventsService } from '../../../services';

@Component({
  selector: 'greco-user-events-page',
  templateUrl: './user-events.page.html',
  styleUrls: ['./user-events.page.scss'],
})
export class UserEventsPage {
  moment = moment;
  today = moment(new Date()).startOf('day').toDate();
  isMobile$ = this.breakpointObserver.observe('(max-width: 780px)').pipe(map(bps => bps.matches));

  constructor(
    public favoriteSvc: UserEventsFavoritesService,
    public userEventsSvc: UserEventsService,
    private breakpointObserver: BreakpointObserver,
    public bottomSheet: MatBottomSheet
  ) {}

  checkFavorite(favorites: any, event: CalendarEvent) {
    if (favorites?.has(event.title)) this.removeFavorite(event);
    else this.addFavorite(event);
  }

  removeFavorite(event: CalendarEvent) {
    this.favoriteSvc.unfavorite(event.title);
  }

  addFavorite(event: CalendarEvent) {
    this.favoriteSvc.addFavorite(event.title, {
      title: event.title,
      imageUrl: event.imageUrl || event.calendar?.imageUrl || 'assets/greco/placeholder.png',
      url: '/workouts?calendar=' + event.calendar?.id + '&' + event.tags?.map((tag: any) => 'tag=' + tag.id).join('&'),
    });
  }
}
