import { SecurityResource } from '@greco/security';

export const REPORTS_SECURITY_RESOURCE = 'reports';

export enum ReportsSecurityResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const ReportsSecurityResource: SecurityResource = {
  key: REPORTS_SECURITY_RESOURCE,
  module: 'reports',

  title: 'Reports',
  description: 'Manage Reports',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: ReportsSecurityResourceAction.CREATE,
      title: 'Create Reports',
      description: 'Ability to create Reports',
    },
    {
      key: ReportsSecurityResourceAction.READ,
      title: 'Read Reports',
      description: 'Ability to read Reports',
    },
    {
      key: ReportsSecurityResourceAction.UPDATE,
      title: 'Update Reports',
      description: 'Ability to update Reports',
    },
    {
      key: ReportsSecurityResourceAction.DELETE,
      title: 'Delete Reports',
      description: 'Ability to delete Reports',
    },
  ],
};
