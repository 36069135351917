import { CondOperator, SFields } from '@nestjsx/crud-request';
import { Filter, FilterOptions } from './filter';

export abstract class SelectFilter extends Filter {
  type: 'select' = 'select';

  constructor(typeName: string, options: Omit<FilterOptions, 'type' | 'allowedOperators'>) {
    super(typeName, { ...options, type: 'select', allowedOperators: [CondOperator.EQUALS, CondOperator.NOT_EQUALS] });
  }

  getImageUrl(_value: any): string | null | undefined {
    return null;
  }

  getPropertySField(property: string, operator: CondOperator, value: any[]): SFields {
    return {
      [property]: {
        [operator === CondOperator.EQUALS ? '$in' : '$notin']: value.map(v => this.serializeValue(v)),
      },
    };
  }

  compareWith(value1: any, value2: any): boolean {
    return value1 === value2;
  }
}
