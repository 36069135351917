import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'restock' })
export class RestockPipe implements PipeTransform {
  transform(quantity?: number, currentValue = 0): string {
    if (!quantity) {
      return '';
    }
    const resultStart = quantity > 0 ? ' (+' : ' (';

    return resultStart + quantity + ' = ' + (currentValue + quantity) + ')';
  }
}
