import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TypeformService } from '../services';

@Injectable()
export class TypeformAuthorizationResolver implements Resolve<void> {
  constructor(private typeformSvc: TypeformService, private snacks: MatSnackBar, private router: Router) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const { code, state } = route.queryParams;

    if (code) {
      try {
        await this.typeformSvc.authorize({ code, state, redirectUri: this.typeformSvc.getRedirectUri() });
        this.snacks.open('Typeform account connected!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err) {
        this.snacks.open('Error connecting Typeform account', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        console.error('TypeformAuthorizationResolver', err);
      }
    }

    await this.router.navigateByUrl(state ? `/admin/community/${state}/apps` : '/admin/platform/apps');
  }
}
