<form [formGroup]="_form">
  <mat-form-field appearance="standard" style="margin-top: -10px">
    <mat-label>Title</mat-label>
    <input matInput required type="text" formControlName="title" placeholder="Provide appropriate title" />
  </mat-form-field>

  <mat-form-field appearance="standard" style="margin-top: -10px">
    <mat-label>Abbreviation</mat-label>
    <input matInput type="text" formControlName="abbreviation" placeholder="Provide appropriate tax abbreviation" />
  </mat-form-field>

  <mat-form-field appearance="standard" style="margin-top: -10px">
    <mat-label>Description</mat-label>
    <input matInput type="text" placeholder="Provide appropriate description" formControlName="description" />
  </mat-form-field>

  <mat-form-field appearance="standard" style="margin-top: -10px">
    <mat-label>Percentage</mat-label>
    <input
      matInput
      required
      placeholder="Specify tax percentage"
      formControlName="percentage"
      currencyMask
      [options]="currencyMaskConfig"
    />
  </mat-form-field>

  <mat-form-field appearance="standard" style="margin-top: -10px">
    <mat-label>Tax Number</mat-label>
    <input matInput type="text" formControlName="taxNumber" placeholder="Provide appropriate tax number" required />
  </mat-form-field>

  <mat-checkbox style="margin: 15px 0px" formControlName="isDefault" color="primary">Set Tax as Default</mat-checkbox>
</form>
