import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Promotion } from '@greco/promotions';
import { PromotionsService } from '../../services';

@Component({
  selector: 'greco-active-promotions',
  templateUrl: './active-promotions.page.html',
  styleUrls: ['./active-promotions.page.scss'],
})
export class ActivePromotionsPage {
  private _promotions: Promotion[] = [];
  @Input() set promotions(value: Promotion[]) {
    this._promotions = value;
    this.form.setValue({
      promos: this._promotions,
    });
  }
  get promotions() {
    return this._promotions;
  }

  form = this.fb.group({
    promos: [[]],
  });

  constructor(private promotionsSvc: PromotionsService, private fb: FormBuilder) {
    if (!this.promotions.length) {
      this.promotionsSvc.getUserPromotions$().subscribe(promos => {
        this.promotions = promos;
      });
    }
  }
}
