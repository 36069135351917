import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { ContactService } from '@greco/ngx-identity-contacts';

@Component({
  selector: 'greco-user-subscribed-communities',
  templateUrl: './subscribed-communities.component.html',
  styleUrls: ['./subscribed-communities.component.scss'],
})
export class SubscribedCommunitiesComponent implements OnChanges {
  constructor(private contactSvc: ContactService) {}

  @Input() user!: User;

  @Output() saved = new EventEmitter();

  communities: Community[] = [];

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.user.previousValue !== changes.user.currentValue) {
      const subscriptions = this.user ? await this.contactSvc.getUserContacts(this.user.id) : [];
      this.communities = subscriptions.map(sub => sub.community);
    }
  }
}
