<greco-box style="margin: 24px 32px">
  <greco-editor
    [class.dirty]="formGroup.dirty"
    [data]="{
    value: formGroup.get('text')?.value || ''
  }"
    (valueChanged)="valueChanged($event.value)"
  ></greco-editor>
  <greco-form-save-buttons
    [form]="formGroup"
    [resetValue]="resetValue"
    [saveAction]="save"
    class="save-buttons"
    [processing]="processing"
    style="top: 5px !important; right: 50px !important"
  ></greco-form-save-buttons>
</greco-box>
