<!-- Toolbar Component -->
<mat-toolbar matRipple [matRippleTrigger]="homeLink" [matRippleRadius]="100">
  <button
    *ngIf="config.links.length"
    class="toolbar-app-button"
    [class.with-links-overlay]="!mobileQuery.matches && !drawer.opened"
    mat-icon-button
    (click)="drawer.toggle()"
  >
    <mat-icon>apps</mat-icon>
  </button>
  <a
    id="homelink"
    #homeLink
    routerLink="/"
    routerLinkActive="disable-clicks"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <ng-container *ngIf="config?.logo; else showTitle;">
      <img class="toolbar-logo" [src]="config?.logo" />
    </ng-container>
    <ng-template #showTitle>
      <h2 class="toolbar-title">{{config?.title || 'Platform'}}</h2>
    </ng-template>
  </a>
  <ng-container *ngIf="config?.subtitle && !mobileQuery.matches">
    <h2 class="toolbar-subtitle">{{config.subtitle}}</h2>
  </ng-container>
  <div class="toolbar-content">
    <ng-content select="[slot='toolbar-content']"></ng-content>
  </div>
  <div class="toolbar-end">
    <ng-content select="[slot='toolbar-end']"></ng-content>
  </div>
</mat-toolbar>

<!-- Drawer & Content Component -->
<mat-drawer-container class="container" autosize>
  <mat-drawer
    #drawer
    class="sidenav"
    [class.mobile]="mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
  >
    <button class="close-drawer-button" mat-icon-button (click)="drawer.close()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <h4>{{config.title}}</h4>
    <a
      #homeButton
      class="nav-link theme-primary"
      color="primary"
      mat-button
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <mat-icon
        >{{homeButton._elementRef.nativeElement.classList.contains('active') ? 'fitness_center' :
        'arrow_back'}}</mat-icon
      >
      Dashboard
    </a>
    <ng-container *ngFor="let linkGroup of config.links | groupBy: 'group' | keyvalue">
      <h4 *ngIf="linkGroup.key" style="margin-top: 24px">{{linkGroup.key}}</h4>
      <a
        [class]="'nav-link' + (link.color ? ' theme-' + link.color : '')"
        *ngFor="let link of $any(linkGroup.value)"
        [color]="link.color"
        mat-button
        [matTooltip]="link.description"
        matTooltipShowDelay="500"
        matTooltipPosition="right"
        [routerLink]="link.url"
        routerLinkActive="active"
      >
        <mat-icon *ngIf="link.icon">{{link.icon}}</mat-icon>
        {{link.label}}
      </a>
    </ng-container>
  </mat-drawer>
  <div class="links-overlay" *ngIf="!mobileQuery.matches && !drawer.opened">
    <a
      #homeButton
      class="links-overlay-button theme-primary"
      color="primary"
      mat-button
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      matTooltip="Dashboard"
      matTooltipPosition="right"
    >
      <mat-icon>fitness_center</mat-icon>
    </a>
    <a
      *ngFor="let link of config.links"
      [class]="'links-overlay-button' + (link.color ? ' theme-' + link.color : '')"
      [color]="link.color"
      mat-button
      [routerLink]="'/booking'"
      routerLinkActive="active"
      [matTooltip]="link.label"
      matTooltipPosition="right"
      [routerLink]="link.url"
      routerLinkActive="active"
    >
      <mat-icon>{{link.icon || 'link'}}</mat-icon>
    </a>
  </div>

  <mat-progress-bar [class.hide]="!showProgressBar" mode="indeterminate"></mat-progress-bar>

  <main
    class="main-outlet"
    [class.with-links-overlay]="!mobileQuery.matches && !drawer.opened"
    [@routerTransition]="test(routerOutlet)"
  >
    <router-outlet #routerOutlet="outlet"></router-outlet>
  </main>
</mat-drawer-container>
