<!-- Ensure options are loaded for attendee pre-add -->
<ng-container *grecoLet="options$ | async as options"> </ng-container>
<mat-form-field appearance="standard" style="width: 100%">
  <mat-label>Booking Tags</mat-label>
  <mat-chip-list #tags aria-label="Tag selection" [required]="required">
    <mat-chip *ngFor="let tag of value" [selectable]="true" [removable]="!disabled" (removed)="remove(tag)">
      {{ $any(tag).label || tag }}
      <mat-icon *ngIf="!disabled" matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #input
      [disabled]="disabled"
      [required]="'true'"
      (input)="searchValue$.next(input.value)"
      (focus)="onFocusIn($event)"
      (blur)="onFocusOut($event)"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [matChipInputFor]="tags"
      [matChipInputSeparatorKeyCodes]="[]"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelection($event); input.value = ''"
      [autoActiveFirstOption]="true"
    >
      <mat-option #matOption *ngFor="let tag of tags$ | async" [value]="tag"> {{ tag.label }} </mat-option>
    </mat-autocomplete>
    <button
      mat-icon-button
      type="button"
      color="primary"
      *ngIf="input.value && allowedToCreateTag"
      (click)="createTag(input.value); input.value = ''"
      matTooltip="Create new tag with this label"
    >
      <mat-icon>new_label</mat-icon>
    </button>
  </mat-chip-list>
  <div matSuffix>
    <ng-content> </ng-content>
  </div>
</mat-form-field>

<!-- Booking Options Table -->
<greco-table
  *ngIf="tableEnabled"
  [data]="(options$ | async) || []"
  [style.display]="(options$ | async)?.length ? 'block' : 'none'"
  style="
    border-left: 1px solid var(--border-color, lightgrey);
    border-right: 1px solid var(--border-color, lightgrey);
    border-bottom: 1px solid var(--border-color, lightgrey);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  "
>
  <greco-perk-badge
    *grecoTableCol="''; let bookingOption; fitContent: true"
    [small]="true"
    [matTooltip]="bookingOption.id"
    [text]="bookingOption.badge.text"
    [icon]="bookingOption.badge.icon"
    [color]="bookingOption.badge.color"
    [shape]="bookingOption.badge.shape"
  ></greco-perk-badge>

  <p *grecoTableCol="'Booking Option'; let bookingOption" [matTooltip]="bookingOption.title">
    {{ bookingOption.title }}
  </p>

  <p
    *grecoTableCol="'Booking Window'; let bookingOption"
    [matTooltip]="bookingOption.bookingWindow ? (bookingOption.bookingWindow | grecoMinutes) : ''"
  >
    {{ bookingOption.bookingWindow | grecoMinutes }}
  </p>

  <p
    *grecoTableCol="'Max Boost'; let bookingOption"
    [matTooltip]="
      bookingOption.maxBoost ? bookingOption.maxBoost + ' Boosts (' + bookingOption.maxBoost * 24 + 'hr)' : ''
    "
  >
    {{
      bookingOption.maxBoost === -1
        ? '-'
        : bookingOption.maxBoost
        ? bookingOption.maxBoost + ' Boost (' + bookingOption.maxBoost * 24 + 'hr)'
        : 'Unlimited'
    }}
  </p>

  <!-- <p *grecoTableCol="'Cancellation Policy'; let bookingOption">TODO:</p> -->

  <mat-chip-list *grecoTableCol="'Tags'; let bookingOption">
    <mat-chip *ngFor="let tag of bookingOption.tags">
      {{ tag.label }}
    </mat-chip>
  </mat-chip-list>

  <p *grecoTableCol="'Per Event Cost'; let bookingOption; alignRight: true">
    {{ bookingOption.price ? (bookingOption.price / 100 | currency) : '-' }}
  </p>

  <mat-icon *grecoTableCol="'Available to Everyone'; let bookingOption; alignCenter: true">
    {{ bookingOption.availableToAnyone ? 'check_circle' : '' }}
  </mat-icon>
</greco-table>
