import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Product, ProductVariant, VariantTransfer } from '@greco/sales-products';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { VariantTransferService } from '../../services';

@Component({
  selector: 'greco-variant-transfers-table',
  templateUrl: './variant-transfers-table.component.html',
  styleUrls: ['./variant-transfers-table.component.scss'],
})
export class VariantTransfersTableComponent implements OnDestroy {
  constructor(private variantTransferSvc: VariantTransferService, private dialogs: MatDialog) {}

  @Input() product?: Product;
  @Input() set variant(variant: ProductVariant | null) {
    this._variant$.next(variant);
  }
  get variant() {
    return this._variant$.value;
  }

  loading = true;
  pagination?: IPaginationMeta;

  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });
  private _variant$ = new BehaviorSubject<ProductVariant | null>(null);

  transfers$ = combineLatest([this._variant$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([variant, pagination]) => {
      if (!variant) return null;
      return await this.variantTransferSvc.paginate(variant.productId, variant.id, pagination);
    }),
    tap(data => (this.pagination = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  ngOnDestroy() {
    this._variant$.complete();
  }

  async remove(transfer: VariantTransfer) {
    if (this.product && this.variant) {
      const confirmation = await SimpleDialog.confirmation(
        this.dialogs,
        `<p>You are about to remove the transfer configuration for $${(transfer.amount / 100).toFixed(2)} between ${
          transfer.sourceAccount.name
        } and ${transfer.destinationAccount.name}.</p><p>Are you sure you want to continue?</p>`
      );
      if (confirmation) {
        await this.variantTransferSvc.remove(this.product.id, this.variant.id, transfer.id);
        this.refresh();
      }
    }
  }

  refresh() {
    this.page$.next(this.page$.value);
  }
}
