import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { PaymentMethod } from '@greco/finance-payments';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AccountPaymentMethodService } from '../../services';

@Component({
  selector: 'greco-select-account-payment-method',
  templateUrl: './select-account-payment-method.component.html',
  styleUrls: ['./select-account-payment-method.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SelectAccountPaymentMethodComponent }],
})
export class SelectAccountPaymentMethodComponent
  implements MatFormFieldControl<PaymentMethod>, ControlValueAccessor, AfterViewInit, OnDestroy
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private paymentMethodSvc: AccountPaymentMethodService,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this.formControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => {
      this.valueChange.emit(value);
      this._onChange?.(value);
    });
  }

  private static _id = 0;

  @Output() openedChange = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<PaymentMethod | null>();

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  private _onDestroy = new Subject<void>();

  stateChanges = new Subject<void>();

  formControl = this.formBuilder.control({ value: null, disabled: true });

  private _accountId$ = new BehaviorSubject<string | null>(null);
  accountDefaultPaymentMethod?: string;
  options$ = this._accountId$.pipe(
    switchMap(async accountId => {
      if (!accountId) {
        this.accountDefaultPaymentMethod = undefined;
        return [];
      }

      const [defaultPaymentMethod, paymentMethods] = await Promise.all([
        this.paymentMethodSvc.getDefault(accountId, true),
        this.paymentMethodSvc.getAll(accountId),
      ]);

      const paymentMethod = paymentMethods.find(pm => pm.id === defaultPaymentMethod?.id) || null;
      this.accountDefaultPaymentMethod = paymentMethod?.id;
      this.value = this.value || paymentMethod || null;

      return paymentMethods;
    })
  );

  @ViewChild(MatSelect) private _input?: MatSelect;

  @HostBinding() readonly id = `select-acount-payment-method-${SelectAccountPaymentMethodComponent._id++}`;
  readonly controlType = 'select-account-payment-method';

  @Input() set accountId(accountId: string | null) {
    this.value = null;
    this._accountId$.next(accountId);
    this.disabled = !this._accountId$.value;
  }

  @Input() set value(value: PaymentMethod | null) {
    this.formControl.setValue(value);
    this.stateChanges.next();
  }
  get value() {
    return this.formControl.value;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.formControl.setValidators(this._required ? [Validators.required] : []);
    this.stateChanges.next();
  }
  get reqired() {
    return this._required;
  }

  private _disabled = true;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled) || !this._accountId$.value;
    this._disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.formControl.invalid && this.formControl.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  ngAfterViewInit() {
    if (this._input) {
      const onTouched = this._input._onTouched;
      this._input.openedChange.pipe(takeUntil(this._onDestroy)).subscribe(opened => this.openedChange.emit(opened));
      this._input.registerOnTouched(() => {
        onTouched?.();
        this._onTouched?.();
        return {};
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.example-tel-input-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: PaymentMethod | null): void {
    this.value = value;
  }

  registerOnChange(fn: (_: PaymentMethod | null) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  refreshPaymentMethods() {
    this.accountId = this._accountId$.value;
  }

  compareWith(option: PaymentMethod | null, selection: PaymentMethod | null): boolean {
    return option?.id === selection?.id;
  }

  open() {
    this._input?.open();
  }
}
