<!-- TODO(adaoust): Prevent unsubscription from communities where you have an active perk (Shown as 'Active Member') -->
<greco-collapsible-section [header]="{ title: 'My Communities', icon: 'groups' }">
  <div class="community-grid">
    <greco-community-card
      *ngFor="let community of communities"
      [community]="community"
      [selected]="true"
      matTooltip="You have active perks for this community"
      callToActionText="Leave Community"
      selectedCallToActionText="Active Member"
    ></greco-community-card>
  </div>
</greco-collapsible-section>
