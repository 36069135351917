import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { Country } from '../../country';
import { COUNTRIES_DB, COUNTRIES_DB_DE, COUNTRIES_DB_ES, COUNTRIES_DB_FR, COUNTRIES_DB_IT } from '../../i18n';
import { MatSelectCountryLangToken } from './tokens';

@Component({
  selector: 'greco-country-input',
  templateUrl: 'country-input.component.html',
  styleUrls: ['country-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryInputComponent),
      multi: true,
    },
  ],
})
export class CountryInputComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() appearance?: MatFormFieldAppearance;
  @Input() countries?: Country[];
  @Input() label?: string;
  @Input() placeHolder = 'Country';
  @Input() required?: boolean;
  @Input() disabled?: boolean;
  @Input() nullable?: boolean;
  @Input() readonly?: boolean;
  @Input() class?: string;
  @Input() itemsLoadSize?: number;
  @Input() loading?: boolean;
  @Input() showRequiredHint = false;

  @ViewChild('countryAutocomplete') statesAutocompleteRef!: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCountrySelected: EventEmitter<Country> = new EventEmitter<Country>();

  public filteredOptions?: Country[];
  public db?: Country[];
  public loadingDB?: boolean;
  public debounceTime = 300;
  public filterString = '';

  private modelChanged: Subject<string> = new Subject<string>();
  private subscription?: Subscription;

  // tslint:disable-next-line: variable-name
  private _value?: Country;

  constructor(@Inject(forwardRef(() => MatSelectCountryLangToken)) public i18n: string) {}

  get value(): Country | undefined {
    return this._value;
  }

  @Input()
  set value(value: Country | undefined) {
    this._value = value || undefined;
    this.propagateChange(this._value);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  propagateChange = (_: any) => {};

  async ngOnInit() {
    this.subscription = this.modelChanged.pipe(startWith(''), debounceTime(this.debounceTime)).subscribe(value => {
      this.filterString = value;
      this._filter(value);
    });
    if (!this.countries) {
      this.loadingDB = true;
      this._importLang(this.i18n);
      this.loadingDB = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.country) {
      if (changes.country.currentValue) {
        const newValue = changes.country.currentValue.toUpperCase();
        this.value = this.countries?.find(
          country =>
            country.name.toUpperCase() === newValue ||
            country.alpha2Code === newValue ||
            country.alpha3Code === newValue ||
            country.numericCode === newValue
        );
      } else {
        this.value = undefined;
      }
    }
  }

  onBlur(inputValue: MatInput) {
    if (this.value) {
      if (this.nullable || !inputValue.value) {
        this.value = undefined;
        this.onCountrySelected.emit(undefined);
      } else if (this.value.name !== inputValue.value) {
        inputValue.value = this.value.name;
      }
    }
  }

  onOptionsSelected($event: MatAutocompleteSelectedEvent) {
    this.value = this.countries?.find(country => country.name === $event.option.value);
    this.onCountrySelected.emit(this.value);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  autocompleteScroll() {
    if (this.itemsLoadSize) {
      setTimeout(() => {
        if (this.statesAutocompleteRef && this.autocompleteTrigger && this.statesAutocompleteRef.panel) {
          fromEvent(this.statesAutocompleteRef.panel.nativeElement, 'scroll')
            .pipe(takeUntil(this.autocompleteTrigger.panelClosingActions))
            .subscribe(() => {
              const scrollTop = this.statesAutocompleteRef.panel.nativeElement.scrollTop;
              const scrollHeight = this.statesAutocompleteRef.panel.nativeElement.scrollHeight;
              const elementHeight = this.statesAutocompleteRef.panel.nativeElement.clientHeight;
              const atBottom = scrollHeight === scrollTop + elementHeight;
              if (atBottom) {
                // fetch more data if not filtered
                if (this.filterString === '') {
                  const fromIndex = this.filteredOptions?.length;
                  const toIndex: number = +(this.filteredOptions || []).length + +(this.itemsLoadSize || 0);
                  this.filteredOptions = [
                    ...(this.filteredOptions || []),
                    ...(this.countries?.slice(fromIndex, toIndex) || []),
                  ];
                }
              }
            });
        }
      });
    }
  }

  inputChanged(value: string): void {
    this.modelChanged.next(value);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private _importLang(i18n: string) {
    switch (i18n) {
      case 'de':
        this.countries = COUNTRIES_DB_DE;
        return COUNTRIES_DB_DE;
      case 'it':
        this.countries = COUNTRIES_DB_IT;
        return COUNTRIES_DB_IT;
      case 'es':
        this.countries = COUNTRIES_DB_ES;
        return COUNTRIES_DB_ES;
      case 'fr':
        this.countries = COUNTRIES_DB_FR;
        return COUNTRIES_DB_FR;
      default:
        this.countries = COUNTRIES_DB;
        return COUNTRIES_DB;
    }
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    if (this.itemsLoadSize && filterValue === '') this.filteredOptions = this.countries?.slice(0, this.itemsLoadSize);
    else {
      this.filteredOptions = this.countries?.filter(
        (option: Country) =>
          option.name.toLowerCase().includes(filterValue) ||
          option.alpha2Code.toLowerCase().includes(filterValue) ||
          option.alpha3Code.toLowerCase().includes(filterValue)
      );
    }
  }
}
