import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { TableModule } from '@greco/ui-table';
import { NgxCurrencyModule } from 'ngx-currency';
import { AccountTaxesPickerComponent } from './component';
import { AddCommunityCustomTaxComponent } from './component/add-community-custom-tax/add-community-custom-tax.component';
import { SelectTaxDialog, UpdateAccountTaxDialog, UpdateTaxDialog } from './dialogs';
import { TaxesPage } from './pages';
import { AccountTaxService, TaxService } from './services';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatChipsModule,
    CollapsibleSectionModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    MatDialogModule,
    TableModule,
    MatTooltipModule,
    MatPaginatorModule,
  ],
  providers: [TaxService, AccountTaxService],
  exports: [SelectTaxDialog, AccountTaxesPickerComponent, UpdateTaxDialog, TaxesPage, UpdateAccountTaxDialog],
  declarations: [
    SelectTaxDialog,
    AddCommunityCustomTaxComponent,
    AccountTaxesPickerComponent,
    UpdateTaxDialog,
    TaxesPage,
    UpdateAccountTaxDialog,
  ],
})
export class GrecoFinanceFeatureTaxModule {}
