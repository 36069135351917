import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CommunitySecurityServiceModule } from '@greco/ngx-identity-community-staff-util';
import { LetModule } from '@greco/ngx-let-util';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { TableModule } from '@greco/ui-table';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import {
  AccountLinkPermissionsComponent,
  ChildAccountPickerComponent,
  LinkedAccountsGrantedTableComponent,
  LinkedAccountsOwnedTableComponent,
  LinkedAccountsRequestsTableComponent,
  LinkedAccountsSectionComponent,
  MobileLinkedAccountsRequestsTableComponent,
} from './components';
import { LinkAccountDialog, UpdateAccountLinkDialog, UserAddGuestDialog } from './dialogs';
import { UserAccountLinkingPage } from './pages';
import { AccountLinkingService } from './services';
@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    // Material
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatTabsModule,
    MatChipsModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatBadgeModule,

    // Greco
    BoxModule,
    SimpleDialogModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,
    CollapsibleSectionModule,
    CommunitySecurityServiceModule,
    TableModule,
    UserAvatarModule,
    LetModule,
  ],
  providers: [AccountLinkingService],
  declarations: [
    UserAccountLinkingPage,
    LinkedAccountsSectionComponent,
    LinkedAccountsOwnedTableComponent,
    LinkedAccountsGrantedTableComponent,
    LinkedAccountsRequestsTableComponent,
    LinkAccountDialog,
    UpdateAccountLinkDialog,
    UserAddGuestDialog,
    AccountLinkPermissionsComponent,
    ChildAccountPickerComponent,
    UserAddGuestDialog,
    MobileLinkedAccountsRequestsTableComponent,
  ],
  exports: [UserAccountLinkingPage, LinkedAccountsSectionComponent, UserAddGuestDialog],
})
export class AccountLinkingModule {}
