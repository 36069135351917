<div style="display: flex; flex-direction: column; gap: 12px">
  <greco-alert
    *ngFor="let agreement of unsignedAgreements$ | async"
    [config]="{
      title: 'Your \'' + agreement.signedAgreementTitle + '\' agreement needs to be signed',
      type: $any('DANGER')
    }"
  >
    <button mat-button slot="buttons" color="error" (click)="signAgreement(agreement)">Sign</button>
  </greco-alert>
</div>
