import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Invoice } from '@greco/finance-invoices';
import { InvoiceService } from '../../services';

@Component({
  selector: 'greco-invoices-page',
  templateUrl: './invoices.page.html',
  styleUrls: ['./invoices.page.scss'],
})
export class InvoicesPage implements OnChanges {
  constructor(private svc: InvoiceService, private router: Router, private route: ActivatedRoute) {}

  migration = {
    start: new Date(2020, 5, 1, 2).getTime(),
    end: new Date(2020, 5, 1, 6).getTime(),
    start2: new Date(2020, 6, 30, 0).getTime(),
    end2: new Date(2020, 6, 30, 3).getTime(),
    start3: new Date(2020, 7, 1, 1).getTime(),
    end3: new Date(2020, 7, 1, 4).getTime(),
  };

  @Input() accountId!: string;

  invoices: Invoice[] = [];

  loading = false;
  reachedEnd = false;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.accountId.currentValue !== changes.accountId.previousValue) {
      await this.init();
    }
  }

  async openInvoice(invoice: Invoice) {
    await this.router.navigate([invoice.id], { relativeTo: this.route });
  }

  async loadNextPage() {
    this.loading = true;
    const length = this.invoices.length;

    const studio = await this.svc.getStudioID(this.accountId);
    const startAfter = this.invoices.length ? this.invoices[this.invoices.length - 1].id : undefined;
    this.invoices = [...this.invoices, ...(await this.svc.getInvoices({ studio, startAfter }))];

    this.reachedEnd = this.invoices.length === length;
    this.loading = false;
  }

  private async init() {
    this.loading = true;

    const studio = await this.svc.getStudioID(this.accountId);
    this.invoices = await this.svc.getInvoices({ studio });

    this.loading = false;
  }
}
