import { Component, Injectable, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  CommunityAgreement,
  CommunityAgreementSecurityActions,
  CommunityAgreementSecurityResource,
} from '@greco/community-agreements';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter, BuildTextFilter } from '@greco/ngx-filters';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { PreviewAgreementLinksDialog } from '../../dialogs';
import { CommunityAgreementsService } from '../../services';

@Injectable({ providedIn: 'root' })
export class AgreementTitleFilter extends BuildTextFilter('AgreementTitleFilter', {
  label: 'Title',
  shortLabel: 'Title',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['title'],
}) {}

@Injectable({ providedIn: 'root' })
export class AgreementSearchFilter extends BuildSearchFilter('AgreementSearchFilter', {
  properties: ['title'],
  propertyLabels: ['Title'],
}) {}

@Component({
  selector: 'greco-community-agreements-table',
  templateUrl: './community-agreements-table.component.html',
  styleUrls: ['./community-agreements-table.component.scss'],
})
export class CommunityAgreementsTableComponent implements OnChanges {
  color = 'primary';
  checked = false;

  constructor(
    private agreementService: CommunityAgreementsService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('community') community$ = new BehaviorSubject<Community | null>(null);
  @Input() community!: Community;

  @PropertyListener('statusFilter')
  private _statusFilter$ = new BehaviorSubject<boolean>(false);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  statusFilter = false;
  loading = false;
  filterOptions$ = [AgreementSearchFilter, AgreementTitleFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  paginationMeta?: IPaginationMeta;
  refresh$ = new BehaviorSubject(null);

  agreements$ = combineLatest([this.filters$, this.pagination$, this._statusFilter$, this.refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    debounceTime(500),
    switchMap(([filters, options, statusFilter]) =>
      this.agreementService.paginate(this.community.id, statusFilter, options, filters)
    ),
    tap(({ meta }) => setTimeout(() => (this.paginationMeta = meta))),
    map(({ items }) => items),

    tap(() => setTimeout(() => (this.loading = false)))
  );

  canArchive$ = this.community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.ARCHIVE,
            { communityId: community.id }
          )
        : false;
    })
  );

  canRead$ = this.community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.READ,
            { communityId: community.id }
          )
        : false;
    })
  );

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.community.previousValue !== changes.community.currentValue) {
      this.filters$.next(this.filters$.value);
    }
  }

  openAgreement(agreement: CommunityAgreement) {
    return this.router.navigate([agreement.id], { relativeTo: this.route });
  }

  async archiveAgreement(agreement: CommunityAgreement) {
    const usage = await this.agreementService.canArchiveAgreement(agreement.id);
    if (usage.length > 0) {
      const dialog = await toPromise(
        this.dialog
          .open(PreviewAgreementLinksDialog, {
            data: {
              title: `Agreement is in use and can't be archived!`,
              subtitle: 'Please disable agreement on following products',
              links: usage,
            },
          })
          .afterClosed()
      );
      if (dialog === 'retry') this.archiveAgreement(agreement);
    } else {
      const confirmation = await toPromise(
        this.dialog
          .open(SimpleDialog, {
            data: {
              title: 'Confirmation',
              content: `<p>You are about to archive the agreement - <strong>${agreement.title}</strong>.</p><p><small><b>Are you sure you want to continue?</b></small></p>`,
              buttons: [
                { label: 'Cancel', role: 'cancel' },
                { label: 'Confirm', role: 'confirm' },
              ],
            },
          })
          .afterClosed()
      );

      if (confirmation === 'confirm') {
        // try {
        await this.agreementService.archiveAgreement(agreement.id);
        this.snacks.open(agreement.title + ' archived successfully!', 'Ok', { duration: 5000 });
        this.refresh$.next(null);
        // } catch (error: any) {
        //   this.dialog.open(PreviewAgreementLinksDialog, {
        //     data: {
        //       title: error.error.title,
        //       subtitle: error.error.subtitle,
        //       links: error.error.links,
        //     },
        //   });
        // }
        // if (archiveResult) {
        //   this.snacks.open(agreement.title + ' archived successfully!', 'Ok', { duration: 5000 });
        //   this.refresh$.next(null);
        // } else {
        //   const usageDialog = this.dialog.open(SimpleDialog, {
        //     data: {
        //       title: 'Delete Agreement Usage',
        //       content: `<p><strong>${agreement.title}</strong> has following usage.</p><p>Please verify & delete individual agreement usage in order to archive this agreement.</p><br><p><b>This action is NOT reversible!</b></p>`,
        //       buttons: [
        //         { label: 'Cancel', role: 'cancel' },
        //         { label: 'Archive', role: 'archive' },
        //       ],
        //     },
        //   });
        //   const removeUsage = await toPromise(usageDialog.afterClosed());
        //   if (removeUsage === 'archive') {
        //     usageDialog.close();
        //     const archiveAgreement = await this.agreementService.archiveAgreement(agreement.id);
        //     if (archiveAgreement) {
        //       this.snacks.open(agreement.title + ' archived successfully!', 'Ok', { duration: 3000 });
        //       this.refresh$.next(null);
        //     } else this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3000 });
        //   }
        // }
      }
    }
  }

  async restoreAgreement(agreement: CommunityAgreement) {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to restore the agreement - <strong>${agreement.title}</strong>.</p><p><small><b>Are you sure you want to continue?</b></small></p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      const restoreResult = await this.agreementService.restoreAgreement(agreement);
      if (restoreResult) {
        this.snacks.open(agreement.title + ' restored successfully!', 'Ok', { duration: 5000 });
        this.refresh$.next(null);
      }
    }
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  toggleArchived() {
    this.statusFilter = !this.statusFilter;
  }
}
