import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserPaymentMethodService } from '../../services/user-payment-methods.service';

@Component({
  selector: 'greco-update-payment-method-dialog',
  templateUrl: './update-payment-method.dialog.html',
  styleUrls: ['./update-payment-method.dialog.scss'],
})
export class UpdatePaymentMethodDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private paymentMethodSvc: UserPaymentMethodService,
    private dialogRef: MatDialogRef<UpdatePaymentMethodDialog>,
    @Inject(MAT_DIALOG_DATA) private data: { paymentMethodId: string; expiry: string }
  ) {}

  saving = false;

  form = this.formBuilder.group({
    month: [Number(this.data.expiry.substring(0, 2)), Validators.required],
    year: [Number(this.data.expiry.substring(3)), Validators.required],
  });

  close() {
    this.dialogRef.close();
  }

  async save() {
    this.saving = true;

    try {
      const month = this.form.value.month;
      const year = this.form.value.year;

      const result = await this.paymentMethodSvc.updateExpiry(
        this.data.paymentMethodId,
        '' + (month < 10 ? '0' + month : month) + '/' + (year < 10 ? '0' + year : year)
      );
      if (result) this.snacks.open('Expiry Updated!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });

      this.dialogRef.close('updated');
    } catch (err: any) {
      console.error(err);
      this.snacks.open('Error adding payment method! ' + (err?.message ? err.message : err), 'Ok', {
        duration: 6000,
        panelClass: 'mat-warn',
      });

      this.dialogRef.close();
    }
  }
}
