<h2 style="margin: 0">Update Platform Tax</h2>
<form [formGroup]="formGroup">
  <mat-form-field appearance="standard" style="width: 100%">
    <mat-label>Title</mat-label>
    <input matInput required type="text" formControlName="title" placeholder="Provide appropriate title" />
  </mat-form-field>

  <mat-form-field appearance="standard" style="width: 100%">
    <mat-label>Abbreviation</mat-label>
    <input matInput type="text" formControlName="abbreviation" placeholder="Provide appropriate tax abbreviation" />
  </mat-form-field>

  <mat-form-field appearance="standard" style="width: 100%">
    <mat-label>Description</mat-label>
    <input matInput type="text" placeholder="Provide appropriate description" formControlName="description" />
  </mat-form-field>

  <mat-form-field appearance="standard" style="width: 100%">
    <mat-label>Percentage</mat-label>
    <input
      matInput
      required
      placeholder="Specify tax percentage"
      formControlName="percentage"
      currencyMask
      [options]="currencyMaskConfig"
    />
  </mat-form-field>
</form>
<div style="display: flex; gap: 8px">
  <button style="flex: 1" type="button" mat-stroked-button (click)="close()">
    <span>Cancel</span>
  </button>
  <button
    style="flex: 1"
    type="submit"
    mat-stroked-button
    color="primary"
    (click)="update()"
    [disabled]="formGroup.invalid"
  >
    <span>Confirm</span>
  </button>
</div>
