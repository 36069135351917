import { Component, Input } from '@angular/core';
import { AccountLinkStatus } from '@greco/account-linking';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { ProductConditionService } from '@greco/ngx-sales-products';
import { AccountLinkingService } from '@greco/web-account-linking';
import { distinctUntilChanged, shareReplay, switchMap } from 'rxjs/operators';

export interface ProductsFilters {
  collection?: string;
  communities?: string[];
  search?: string;
}

@Component({
  selector: 'greco-products',
  templateUrl: './products.page.html',
  styleUrls: ['./products.page.scss'],
})
export class ProductsPage {
  constructor(
    private userSvc: UserService,
    private conditionSvc: ProductConditionService,
    private linkSvc: AccountLinkingService
  ) {}

  @Input() user?: User;
  @Input() filters!: ProductsFilters;

  userConditionContext$ = this.userSvc.getUserId().pipe(
    distinctUntilChanged(),
    switchMap(userId => this.conditionSvc.getConditionContext(userId)),
    shareReplay(1)
  );

  linkedAccounts$ = this.userSvc.getUserId().pipe(
    distinctUntilChanged(),
    switchMap(async userId =>
      userId
        ? (await this.linkSvc.getPrivilegeLinksForAccessor(userId))?.filter(
            link => link.status === AccountLinkStatus.ACTIVE
          )
        : []
    )
  );

  communitiesChanged(communities: Community[]) {
    this.filters = { ...this.filters, communities: communities.map(com => com.id) };
  }
}
