import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, InjectionToken, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbService } from './breadcrumbs.service';

export const TAB_DEPENDENCIES = new InjectionToken<any>('TAB_DEPENDENCIES');

@Component({
  selector: 'greco-tab-routes',
  templateUrl: './tab-routes.component.html',
  styleUrls: ['./tab-routes.component.scss'],
})
export class TabRoutesComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private breadcrumb: BreadcrumbService,
    private router: Router,
    private breakpointObs: BreakpointObserver
  ) {}

  mobileBreakpoint$ = this.breakpointObs.observe('(max-width: 600px)');
  @Input() moreBreakpoint = 5;

  @Input() pageTitle = '';
  @Input() forceShowTitle = false;

  private tabs$ = new BehaviorSubject<Tab[]>([]);
  @Input() set tabs(tabs: Tab[]) {
    this.tabs$.next(tabs || []);
  }
  get tabs() {
    return this.tabs$.value;
  }

  crumbs$ = this.breadcrumb.listen();

  basePath$ = this.route.url.pipe(map(() => this.getBasePath(this.route.pathFromRoot)));
  base$ = combineLatest([this.basePath$, this.tabs$]).pipe(
    map(([basePath, tabs]) => {
      const parts = this.router.routerState.snapshot.url.split('/').filter(part => !!part);
      return tabs
        .map(tab => ({
          ...tab,
          size: parts.filter(part => [basePath, tab.routerLink].includes(part)).length,
        }))
        .sort((a, b) => b.size - a.size)[0];
    })
  );

  ngOnInit() {
    setTimeout(() => {
      // Force a resize for the tabs, on Alex's machine the underline is missaligned on load.
      window.dispatchEvent(new Event('resize'));
    });
  }

  ngOnDestroy() {
    this.tabs$.complete();
  }

  private getBasePath(routes: ActivatedRoute[]) {
    return ['/', ...routes.reduce((acc, path) => [...acc, ...path.snapshot.url.map(url => url.path)], [] as string[])];
  }
}

export interface Tab {
  label: string;
  subtitle?: string;
  active?: boolean;
  routerLink: string | string[];
  iconStart?: string;
  iconEnd?: string;
}
