import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PropertyListener } from '@greco/property-listener-util';
import { Timezone, userDefaultTimezone } from '@greco/timezone';
import { MatDatetimepicker } from '@mat-datetimepicker/core';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimezoneSelectComponent } from '../timezone-select/timezone-select.component';

export interface DatetimeTimezoneValue {
  date: Date;
  timezone: Timezone;
}

@Component({
  selector: 'greco-datetime-timezone-input',
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DatetimeTimezoneComponent }],
})
export class DatetimeTimezoneComponent
  implements AfterViewInit, OnDestroy, MatFormFieldControl<DatetimeTimezoneValue>, ControlValueAccessor
{
  constructor(
    private _elementRef: ElementRef,
    private formBuilder: FormBuilder,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (ngControl) ngControl.valueAccessor = this;
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.stateChanges.next());
  }

  private static ID = 0;
  private _touched$ = new Subject<void>();
  private _onDestroy$ = new Subject<void>();

  _form = this.formBuilder.group({
    date: [null, Validators.required],
    timezone: [userDefaultTimezone(), Validators.required],
  });

  @Input() initialStartDate?: Date | null;

  @Input() minimumStartDate?: Date;
  stateChanges = new Subject<void>();
  controlType = 'greco-datetime-timezone-input';

  @HostBinding() id = `${this.controlType}-${DatetimeTimezoneComponent.ID++}`;

  @ViewChild(MatDatetimepicker) public datetimePicker?: MatDatetimepicker<Date>;

  @ViewChild(MatInput) private _input?: MatInput;
  @ViewChild(TimezoneSelectComponent) private _timezoneSelect?: TimezoneSelectComponent;

  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  set value(value: DatetimeTimezoneValue | null) {
    if (value?.date && value.timezone) {
      const formattedDate = moment(value.date).tz(value.timezone).format('YYYY-MM-DD HH:mm:ss');
      this._form.setValue({ timezone: value.timezone, date: moment(formattedDate).toDate() });
    } else if (value?.timezone) {
      this._form.setValue({ date: null, timezone: value.timezone });
    } else {
      this._form.setValue({ date: null, timezone: null });
    }
  }
  get value() {
    if (!this._form.value.date && this.initialStartDate) {
      this._form.setValue({ ...this._form.value, date: this.initialStartDate });
    }
    const value: Partial<DatetimeTimezoneValue> = this._form.value;
    if (!value.date || !value.timezone) return null;

    const formattedDate = moment(value.date).format('YYYY-MM-DD HH:mm:ss');
    return {
      timezone: value.timezone,
      date: moment.tz(formattedDate, value.timezone).toDate(),
    };
  }

  get focused() {
    return !!(this._input?.focused || this._timezoneSelect?.focused);
  }

  get empty() {
    return !this._input?.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState() {
    return this._form.invalid && this._form.touched;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-datetime-timezone-input-container');
    if (controlElement) controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(): void {
    this._input?.onContainerClick();
  }

  writeValue(value: DatetimeTimezoneValue | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => fn(this.value));
  }

  registerOnTouched(fn: any): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(() => fn());
  }

  @PropertyListener('required')
  @PropertyListener('disabled')
  @PropertyListener('placeholder')
  private _updateState() {
    this.stateChanges.next();

    if (this.disabled) this._form.disable();
    else this._form.enable();

    this._form.updateValueAndValidity();
  }

  ngAfterViewInit() {
    this._timezoneSelect?.registerOnTouched(() => {
      this._touched$.next();
      return {};
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._touched$.complete();
    this.stateChanges.complete();
  }
}
