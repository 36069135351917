import { BaseDto } from '@greco/util-dto';
import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class CreateVideoCollectionDto extends BaseDto {
  @IsString()
  @IsOptional()
  icon?: string;

  @IsString()
  @IsNotEmpty()
  title: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsNotEmpty()
  communityId: string;
}
