<greco-collapsible-section
  id="royalties-section"
  [disabled]="!royalties.length"
  [header]="{ title: 'Royalties', icon: 'paid' }"
>
  <button
    header
    mat-flat-button
    *ngIf="canManageAccount"
    id="add-royalties-button"
    color="primary"
    (click)="$event.stopImmediatePropagation(); addRoyalties()"
  >
    <mat-icon>add</mat-icon>
    <span>Royalties</span>
  </button>

  <greco-table *ngIf="royalties.length" [data]="royalties">
    <mat-icon *grecoTableCol="''; let royalty; fitContent: true" [matTooltip]="royalty.id">attach_money</mat-icon>

    <p *grecoTableCol="'Destination'; let royalty" [matTooltip]="royalty.destination.id">
      {{ royalty.destination.name }}
    </p>

    <p *grecoTableCol="'Percentage'; let royalty" [matTooltip]="royalty.percentage">{{ royalty.percentage }}%</p>

    <ng-container *ngIf="canManageAccount">
      <ng-container *grecoTableCol="''; let royalty; fitContent: true; stickyEnd: true">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <button mat-menu-item (click)="removeRoyalties(royalty)">
            <mat-icon>delete</mat-icon>
            <span>Remove</span>
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
  </greco-table>
</greco-collapsible-section>
