<greco-simple-dialog [data]="dialogData">
  <div class="split-div">
    <div>
      <div class="room-image">
        <canvas #canvas (click)="imageClick($event)"></canvas>
      </div>
      <i *ngIf="listening">Select the top left and then bottom right corners of your spot</i>
    </div>

    <mat-divider [vertical]="true"></mat-divider>

    <div class="spots-container">
      <h3>Spots</h3>
      <greco-table [data]="data.spots" style="min-width: 308px">
        <p *grecoTableCol="''; let spot; fitContent: true;">{{spot.number}}</p>

        <p *grecoTableCol="'Name'; let spot">{{spot.name}}</p>

        <div *grecoTableCol="'Position'; let spot" class="spots">
          <ng-container *ngIf="!configs[spot.id]; else withConfig">
            <button mat-flat-button *ngIf="listeningId !== spot.id" (click)="setCoordinates(spot.id)">
              Set Coordinates
            </button>
            <button mat-icon-button *ngIf="listeningId === spot.id" [disabled]="true">
              <mat-icon class="spin">loop</mat-icon>
            </button>
          </ng-container>

          <ng-template #withConfig>
            <div class="input-container" style="min-width: 72px">
              <span>
                X:
                <input
                  #x
                  matInput
                  type="number"
                  min="0"
                  step="1"
                  [value]="configs[spot.id].x"
                  (change)="drawSpots(spot.id, 'x', x.value)"
                />
              </span>
              <span>
                Y:
                <input
                  #y
                  matInput
                  type="number"
                  min="0"
                  step="1"
                  [value]="configs[spot.id].y"
                  (change)="drawSpots(spot.id, 'y', y.value)"
                />
              </span>
            </div>
            <div class="input-container">
              <p style="margin: 2px 2px 0px 0">Width:</p>
              <p style="margin: 2px 3px 2px 0">Height:</p>
            </div>
            <div class="input-container">
              <input
                #width
                matInput
                type="number"
                min="0"
                step="1"
                [value]="configs[spot.id].width"
                (change)="drawSpots(spot.id, 'w', width.value)"
              />
              <input
                #height
                matInput
                type="number"
                min="0"
                step="1"
                [value]="configs[spot.id].height"
                (change)="drawSpots(spot.id, 'h', height.value)"
              />
            </div>
          </ng-template>

          <ng-container *grecoLet="listeningId === spot.id as listeningToThis">
            <button mat-icon-button class="icon-button" *ngIf="configs[spot.id]" [disabled]="listeningToThis">
              <mat-icon [class]="listeningToThis ? 'spin' : ''" (click)="setCoordinates(spot.id)">
                {{listeningToThis ? 'loop' : 'edit'}}
              </mat-icon>
            </button>
          </ng-container>
        </div>
      </greco-table>
    </div>
  </div>
</greco-simple-dialog>
