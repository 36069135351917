import { Component, Injectable, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { BuildSearchFilter, Filter } from '@greco/ngx-filters';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { GrantSuperAdminDialog } from '../../dialogs';
import { AdminService } from '../../services';

@Injectable()
export class AdminSearchFilter extends BuildSearchFilter('AdminSearchFilter', {
  properties: ['email', 'displayName'],
  propertyLabels: ['Email Address', 'Display Name'],
}) {}

@Component({
  selector: 'greco-admin-page',
  templateUrl: './admin.page.html',
})
export class AdminPageComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private adminSvc: AdminService, private dialog: MatDialog, private snacks: MatSnackBar) {}

  filterOptions: Type<Filter>[] = [AdminSearchFilter];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });

  admins$ = combineLatest([this.filters$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(([queryBuilder, { page, limit }]) => this.adminSvc.paginateSuperAdmins(queryBuilder, page, limit)),
    tap(({ meta }) => (this.pagination = meta)),
    map(({ items }) => items),

    tap(() => (this.loading = false))
  );

  pagination: null | IPaginationMeta = null;

  loading = true;

  async grantSuperAdmin() {
    const dialog = await toPromise(
      this.dialog
        .open(GrantSuperAdminDialog, {
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
    );
    if (dialog) await this.reload();
  }

  async removeSuperAdmin(admin: User) {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `
              <p>You are about to remove <strong>${admin.displayName}</strong> from the platform administrators.</p>
              <p><strong>Are you sure you want to continue?</strong></p>
            `,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      try {
        await this.adminSvc.removeSuperAdmin(admin.id);
        this.snacks.open('Administrator Removed!', 'Ok', {
          panelClass: 'mat-primary',
        });
        await this.reload();
      } catch (err) {
        this.snacks.open("Can't remove yourself!", 'Ok', {
          panelClass: 'mat-warn',
        });
      }
    }
  }
  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
  private async reload() {
    this.page$.next(this.page$.value);
  }
}
