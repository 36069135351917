import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  Type,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { PaymentMethod, TerminalResource, TerminalResourceAction } from '@greco/finance-payments';
import { SecurityService } from '@greco/ngx-security-util';
import { BehaviorSubject, Subject, combineLatest, from } from 'rxjs';
import { first, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { UserPaymentMethodService } from '../../services';

export const PAYMENT_METHOD_DIALOGS = new InjectionToken<{ label: string; icon: string; dialog: Type<unknown> }[]>(
  'PAYMENT_METHOD_DIALOGS'
);

export interface PaymentMethodValidator {
  validatePaymentMethod(paymentMethod: PaymentMethod): boolean | Promise<boolean>;
}

export const PAYMENT_METHOD_VALIDATORS = new InjectionToken<PaymentMethodValidator | PaymentMethodValidator[]>(
  'PAYMENT_METHOD_VALIDATORS'
);

@Component({
  selector: 'greco-select-payment-method',
  templateUrl: './select-payment-method.html',
  styleUrls: ['./select-payment-method.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SelectPaymentMethodComponent }],
})
export class SelectPaymentMethodComponent
  implements MatFormFieldControl<PaymentMethod>, ControlValueAccessor, AfterViewInit, OnDestroy
{
  constructor(
    @Optional()
    @Inject(PAYMENT_METHOD_VALIDATORS)
    validators: PaymentMethodValidator | PaymentMethodValidator[] | null,
    @Optional()
    @Inject(PAYMENT_METHOD_DIALOGS)
    public extraDialogs: null | { label: string; icon: string; dialog: Type<unknown> }[],
    @Optional() @Self() public ngControl: NgControl,
    private paymentMethodSvc: UserPaymentMethodService,
    private securitySvc: SecurityService,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef,
    public dialogs: MatDialog
  ) {
    if (validators) {
      this.validators = Array.isArray(validators) ? validators : [validators];
    }

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this.formControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => {
      this.valueChange.emit(value);
      this._onChange?.(value);
    });
  }

  private static _id = 0;
  private validators: PaymentMethodValidator[] = [];

  @Output() openedChange = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<PaymentMethod | null>();
  @Input() allowBankPayments = false;

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  private _onDestroy = new Subject<void>();

  stateChanges = new Subject<void>();

  formControl = this.formBuilder.control({ value: null, disabled: true });

  canManageTerminals$ = from(
    this.securitySvc.hasAccess(TerminalResource.key, TerminalResourceAction.MANAGE_TERMINALS, {}, true)
  ).pipe(shareReplay(1));

  private _allowTerminals$ = new BehaviorSubject<boolean>(false);
  private _onlyTerminals$ = new BehaviorSubject<boolean>(false);

  private _userId$ = new BehaviorSubject<string | null>(null);

  userDefaultPaymentMethod?: string;
  options$ = combineLatest([this._userId$, this._allowTerminals$, this._onlyTerminals$]).pipe(
    switchMap(async ([userId, allowTerminals, onlyTerminals]) => {
      if (!userId) {
        this.userDefaultPaymentMethod = undefined;
        return [];
      }

      const [defaultPaymentMethod, paymentMethods] = await Promise.all([
        this.paymentMethodSvc.getDefault(userId, true),
        this.paymentMethodSvc.getAll(userId, allowTerminals || onlyTerminals),
      ]);

      const allowedPaymentMethods = paymentMethods.filter(
        pm => this.allowBankPayments || (pm.model !== 'bank' && (!onlyTerminals || pm.model === 'terminal'))
      );

      const paymentMethod = allowedPaymentMethods.find(pm => pm.id === defaultPaymentMethod?.id) || null;
      this.userDefaultPaymentMethod = paymentMethod?.id;
      this.value =
        this.value || paymentMethod || allowedPaymentMethods.filter(pm => pm.model !== 'terminal')[0] || null;

      return allowedPaymentMethods.sort((a, b) =>
        a.model != b.model ? (a.model === 'terminal' ? 1 : -1) : b.created.getTime() - a.created.getTime()
      );
    }),
    switchMap(async paymentMethods => {
      const result: PaymentMethod[] = [];

      for (const paymentMethod of paymentMethods) {
        let isValid = true;

        for (const validator of this.validators) {
          isValid = await validator.validatePaymentMethod(paymentMethod);
          if (!isValid) break;
        }

        if (isValid) result.push(paymentMethod);
      }

      return result;
    })
  );

  @ViewChild(MatSelect) private _input?: MatSelect;

  @HostBinding() readonly id = `select-payment-method-${SelectPaymentMethodComponent._id++}`;
  readonly controlType = 'select-payment-method';

  @Input() set allowTerminals(allow: boolean) {
    this.value = null;
    this._allowTerminals$.next(allow);
  }
  get allowTerminals() {
    return this._allowTerminals$.value;
  }

  @Input() set onlyTerminals(allow: boolean) {
    this.value = null;
    this._onlyTerminals$.next(allow);
  }
  get onlyTerminals() {
    return this._onlyTerminals$.value;
  }

  @Input() set userId(userId: string | null) {
    this.value = null;
    this._userId$.next(userId);
    this.disabled = !this._userId$.value;
  }

  @Input() set value(value: PaymentMethod | null) {
    this.formControl.setValue(value);
    this.stateChanges.next();
  }
  get value() {
    return this.formControl.value;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.formControl.setValidators(this._required ? [Validators.required] : []);
    this.stateChanges.next();
  }
  get reqired() {
    return this._required;
  }

  private _disabled = true;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled) || !this._userId$.value;
    this._disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.formControl.invalid && this.formControl.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  ngAfterViewInit() {
    if (this._input) {
      const onTouched = this._input._onTouched;
      this._input.openedChange.pipe(takeUntil(this._onDestroy)).subscribe(opened => this.openedChange.emit(opened));
      this._input.registerOnTouched(() => {
        onTouched?.();
        this._onTouched?.();
        return {};
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.example-tel-input-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: PaymentMethod | null): void {
    this.value = value;
  }

  registerOnChange(fn: (_: PaymentMethod | null) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  refreshPaymentMethods() {
    this.userId = this._userId$.value;
  }

  compareWith(option: PaymentMethod | null, selection: PaymentMethod | null): boolean {
    return option?.id === selection?.id;
  }

  open() {
    this._input?.open();
  }

  openExtra(dialog: any) {
    this.dialogs
      .open(dialog)
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.refreshPaymentMethods());
  }
}
