import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractField<T> implements OnDestroy, OnInit, ControlValueAccessor, MatFormFieldControl<T> {
  private static _ID = 0;

  @Input() get value() {
    return this._value;
  }
  set value(value) {
    // this.writeValue(value);
    this._updateValue(value);
  }

  @Input() get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }
  set required(required) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input() get disabled() {
    return this._disabled;
  }
  set disabled(disabled) {
    this._disabled = coerceBooleanProperty(disabled);
    this.inputDisabledChanged.next(this._disabled);
    this.stateChanges.next();
  }

  get empty(): boolean {
    return !this.value;
  }

  get errorState(): any {
    if (this.ngControl) return !this.ngControl.pristine && Object.keys(this.ngControl.errors || {}).length > 0;
    return this.required && this.empty;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  private get _controlType() {
    return this.controlType;
  }

  constructor(@Optional() @Self() public ngControl: NgControl, private focusMonitor: FocusMonitor) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  abstract controlType: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @HostBinding() id = `${this._controlType}-${AbstractField._ID++}`;

  focused = false;

  readonly stateChanges = new Subject<void>();

  private _value: T;

  private _placeholder: string = null;
  private _required = false;
  private _disabled = false;

  protected readonly valueWritten = new Subject<T>();
  protected readonly inputDisabledChanged = new Subject<boolean>();

  protected _onDestroy = new Subject<void>();

  private _onChange: (value: T) => any;
  private _onTouch: () => any;

  protected abstract getFocusElement(): HTMLElement;

  ngOnInit() {
    const element = this.getFocusElement();
    if (element) {
      this.focusMonitor
        .monitor(element, true)
        .pipe(takeUntil(this._onDestroy))
        .subscribe(origin => {
          this.focused = !!origin;
          this.stateChanges.next();
        });
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.stateChanges.complete();
    this.valueWritten.complete();
    this.inputDisabledChanged.complete();
  }

  setDescribedByIds(ids: string[]): void {
    const element = this.getFocusElement();
    element?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.getFocusElement()?.focus();
    }
  }

  writeValue(value: T): void {
    this._value = value;
    this.valueWritten.next(this._value);
    // this.stateChanges.next();
  }

  registerOnChange(fn: (value: T) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  protected _updateValue(value: T) {
    this._value = value;
    this.stateChanges.next();
    this._onChange?.(value);
    this._onTouch?.();
  }
}
