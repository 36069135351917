<greco-item *ngIf="!interactive" [lines]="false">
  <mat-icon item-start style="margin: 0 4px">event_busy</mat-icon>

  <div *grecoLet="subtitle$ | async as subtitle" style="width: 100%">
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: -4px">
      <strong>Cancellation Policy</strong>
    </p>

    <p
      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0; margin-top: -4px"
      [innerHTML]="subtitle"
    ></p>
  </div>

  <div item-end>
    <button
      mat-button
      color="primary"
      style="height: 32px; margin-left: auto; padding: 0 12px"
      (click)="emitBottomSheetOpened(cancellationDetailsSheet)"
    >
      Details
    </button>
  </div>

  <ng-template #cancellationDetailsSheet>
    <div style="display: flex; gap: 12px; justify-content: space-between; align-items: center">
      <h2 #header style="margin-bottom: 0">Cancellation Policy</h2>
      <button mat-icon-button (click)="bottomSheet.dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <b> {{ eventDetails$ | async }} </b>

    <p [innerHTML]="cancellationPolicyDetails$ | async"></p>
  </ng-template>
</greco-item>

<div
  *ngIf="interactive"
  style="display: flex; cursor: pointer; align-items: center; gap: 8px"
  (click)="bottomSheet.open(cancellationDetailsSheet)"
>
  <mat-icon item-start style="margin: 0 4px">event_busy</mat-icon>

  <div *grecoLet="subtitle$ | async as subtitle" style="width: 100%">
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: -4px">
      <small>
        <strong>Cancellation Policy</strong>
      </small>
    </p>

    <p
      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0; margin-top: -4px"
      [innerHTML]="subtitle"
    ></p>
  </div>

  <ng-template #cancellationDetailsSheet>
    <div style="display: flex; gap: 12px; justify-content: space-between; align-items: center">
      <h2 #header style="margin-bottom: 0">Cancellation Policy</h2>
      <button mat-icon-button (click)="bottomSheet.dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <b> {{ eventDetails$ | async }} </b>

    <p [innerHTML]="cancellationPolicyDetails$ | async"></p>
  </ng-template>
</div>
