<div *ngIf="formGroup" id="default-sale-categories-dialog-container">
  <h2>Default Sale Categories</h2>
  <p>Map a default sale category for each type of purchase.</p>

  <greco-table [data]="forms" [formGroup]="formGroup">
    <mat-icon *grecoTableCol="''; let form; fitContent: true">{{form.matIcon}}</mat-icon>

    <p *grecoTableCol="'Purchase Type'; let form">{{form.label}}</p>

    <mat-icon *grecoTableCol="''; fitContent: true">trending_flat</mat-icon>

    <ng-container *grecoTableCol="'Sale Category'; let index = index" formArrayName="defaults">
      <mat-form-field appearance="standard" class="default-sale-category-mat-form-field">
        <greco-sale-category-input
          placeholder="Sale Category"
          [formControlName]="index"
          [accountId]="accountId"
        ></greco-sale-category-input>
      </mat-form-field>
    </ng-container>
  </greco-table>

  <div id="buttons">
    <button mat-stroked-button [disabled]="saving" (click)="close()">Cancel</button>

    <button mat-stroked-button color="primary" [disabled]="saving" (click)="save()">
      <mat-icon [class.spin]="saving">{{saving ? 'loop':'save'}}</mat-icon>
      <span>Save</span>
    </button>
  </div>
</div>
