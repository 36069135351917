import { Injectable } from '@angular/core';
import { PlatformLayoutConfig } from './platform.layout';

@Injectable({
  providedIn: 'root',
})
// TODO: Need to get app provide to get title
export class PlatformService {
  public config: PlatformLayoutConfig = {
    title: 'LF3',
    logo: 'assets/lf3/icon_square_pad.png',
    subtitle: null, // 'Administration',
    // logoDark: 'assets/greco/logo-dark.svg'
    links: [
      // {
      //   group: '',
      //   label: 'Classes & Appointments',
      //   description: 'Book a workout or an appointment',
      //   icon: 'today',
      //   url: '/booking',
      //   color: 'primary'
      // },
      // {
      //   group: '',
      //   label: 'Workout Gallery',
      //   description: 'Watch an on-demand workout or live replay',
      //   icon: 'video_library',
      //   url: '/video',
      //   color: 'accent'
      // },
      // {
      //   group: '',
      //   label: 'Workout Beats',
      //   description: 'Listen to the curated workout beats',
      //   icon: 'library_music',
      //   url: '/music',
      //   color: 'warn'
      // },
      // {
      //   group: '',
      //   label: 'LF3 Shop & Memberships',
      //   description: 'Shop for credits, products and memberships',
      //   icon: 'shopping_bag',
      //   url: '/shop',
      //   color: null
      // },
      // {
      //   group: 'Limited Time Only!',
      //   label: 'Referral Promotion',
      //   description: 'Win huge discounts, when you refer friends!',
      //   icon: 'local_offer',
      //   url: '/promo',
      //   color: 'accent'
      // }
    ],
  };
}
