import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { UpdateCouponDto } from '@greco/api-coupons';
import { Coupon, CouponSecurityAction, CouponSecurityResource, DiscountType } from '@greco/coupon';
import { SecurityService } from '@greco/ngx-security-util';
import { CollapsibleSectionController } from '@greco/ui-collapsible-section';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GrantCouponDialog } from '../../dialogs';
import { CouponService } from '../../services';

@Component({
  selector: 'greco-coupon-page',
  templateUrl: './coupon.page.html',
  styleUrls: ['./coupon.page.scss'],
  viewProviders: [CollapsibleSectionController],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CouponPage implements OnInit {
  readonly flatAmountMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  readonly percentageMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '',
    suffix: '%',
    thousands: ',',
    inputMode: 0,
  };

  constructor(
    private couponSvc: CouponService,
    private snacks: MatSnackBar,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService
  ) {}

  private _communityId$ = new BehaviorSubject<string | null>(null);
  @Input() get communityId() {
    return this._communityId$.value;
  }
  set communityId(communityId: string | null) {
    this._communityId$.next(communityId);
  }

  @Input() coupon!: Coupon;

  manualRefresh = new BehaviorSubject(null);

  @Output() refresh = new EventEmitter();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  processing = false;
  updating = false;
  discountTypeArray = [DiscountType.FLAT_AMOUNT, DiscountType.PERCENTAGE];
  tempExpiryDate: any;
  discountUnit!: string;
  couponArchived = false;

  formGroup = this.formBuilder.group({
    title: ['', Validators.required],
    shortTitle: [''],
    description: [''],
    discountType: ['', Validators.required],
    discountAmount: ['', Validators.required],
    availableInSubCommunities: [''],
    allProducts: [''],
    color: [''],
    icon: [''],
    shape: [''],
    text: [''],
    created: [''],
  });

  resetValue: any = {};

  // readonly canRead$ = combineLatest([this._communityId$, this.manualRefresh]).pipe(
  readonly canRead$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.READ, {
            communityId,
          })
        : false;
    })
  );

  readonly canCreate$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.CREATE, {
            communityId,
          })
        : false;
    })
  );

  readonly canUpdate$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.UPDATE, {
            communityId,
          })
        : false;
    })
  );

  readonly canArchive$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.ARCHIVE, {
            communityId,
          })
        : false;
    })
  );

  readonly canGrant$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.GRANT, {
            communityId,
          })
        : false;
    })
  );

  update = async () => {
    this.processing = true;

    try {
      const data: UpdateCouponDto = {
        badge: {
          text: this.formGroup.value.title,
          color: this.formGroup.value.color,
          shape: this.formGroup.value.shape,
          icon: this.formGroup.value.icon,
        },
        title: this.formGroup.value.title,
        availableToAnyone: this.formGroup.value.availableToAnyone,
      };

      if (this.formGroup.value.title !== this.coupon.title) {
        data.title = this.formGroup.value.title;
      }

      if (this.formGroup.value.shortTitle !== this.coupon.shortTitle) {
        data.shortTitle = this.formGroup.value.shortTitle;
      }

      if (this.formGroup.value.description !== this.coupon.description) {
        if (this.formGroup.value.description === null || this.formGroup.value.description === undefined) {
          data.description = '';
        } else {
          data.description = this.formGroup.value.description;
        }
      }

      if (this.formGroup.value.discountType !== this.coupon.discountType) {
        data.discountType = this.formGroup.value.discountType;
      }

      if (this.formGroup.value.discountAmount !== this.coupon.discountAmount) {
        data.discountAmount =
          this.formGroup.value.discountType == DiscountType.FLAT_AMOUNT
            ? this.formGroup.value.discountAmount * 100
            : this.formGroup.value.discountAmount;
      }

      if (this.formGroup.value.availableToAnyone !== this.coupon.availableToAnyone) {
        if (this.formGroup.value !== true) {
          data.availableToAnyone = false;
        } else {
          data.availableToAnyone = true;
        }
      }

      if (this.formGroup.value.allProducts !== this.coupon.allProducts) {
        data.allProducts = this.formGroup.value.allProducts;
      }

      if (this.formGroup.value.badge !== this.coupon.badge) {
        data.badge = {
          text: this.formGroup.value.title,
          color: this.formGroup.value.color,
          shape: this.formGroup.value.shape,
          icon: this.formGroup.value.icon,
        };
      }

      this.coupon = await this.couponSvc.update(this.coupon.id, data);
      this.reset();

      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      this.snacks.open('' + err, 'Ok', { duration: 3500, panelClass: 'mat-warn' });
      console.error(err);
    }

    this.processing = false;
    this.refresh.emit();
  };

  async _grantCoupon(coupon: Coupon) {
    await toPromise(
      this.dialog
        .open(GrantCouponDialog, {
          data: {
            communityId: this.communityId,
            selectedCoupon: coupon,
          },
          width: '600px',
          maxWidth: '90%',
        })
        .afterClosed()
    );
  }

  async archive(coupon: Coupon) {
    const dialog = this.dialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: `Confirm Archive of ${coupon.title}`,
        content: `This will prevent the coupon from being granted to any products/sales category in the future.`,
        buttons: [
          { label: "No, Don't Archive", role: 'no' },
          { label: 'Yes, Archive', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      try {
        await this.couponSvc.archive(coupon.id);
        this.snacks.open('Coupon archived', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
        window.location.reload();
      } catch (err: any) {
        this.snacks.open(err?.error?.message, 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
      }
    }
  }

  async restore(coupon: Coupon) {
    const dialog = this.dialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Restore',
        content: `This will allow the coupon (${coupon.title}) to be granted to products/sales categories.`,
        buttons: [
          { label: "No, Don't Unarchive", role: 'no' },
          { label: 'Yes, Unarchive', role: 'yes' },
        ],
      },
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      const spinnerDialog = this.dialog.open(MatSpinner, { width: 'auto', maxWidth: '400px' });
      spinnerDialog.disableClose = true;
      try {
        await this.couponSvc.restore(coupon.id);
        this.snacks.open('Coupon Restored', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
        window.location.reload();
      } catch (err) {
        this.snacks.open('Something went wrong!', 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
      }
      spinnerDialog.close();
    }
  }

  private async reset() {
    this.assignValues();
    this.formGroup.reset(this.resetValue);
    this.formGroup.markAsPristine();
  }

  private async assignValues() {
    this.resetValue = {
      title: this.coupon.title,
      shortTitle: this.coupon.shortTitle,
      description: this.coupon.description,
      discountType: this.coupon.discountType,
      discountAmount:
        this.coupon.discountType == DiscountType.FLAT_AMOUNT
          ? this.coupon.discountAmount / 100
          : this.coupon.discountAmount,
      created: this.coupon.created,
      communityId: this.coupon?.community?.id,
      availableInSubCommunities: this.coupon.availableInSubCommunities,
      allProducts: this.coupon.allProducts,
      text: this.coupon.badge.text,
      icon: this.coupon.badge.icon,
      color: this.coupon.badge.color,
      shape: this.coupon.badge.shape,
      archivedOn: this.coupon.archivedOn,
    };
    this.discountUnit = this.coupon.discountType;
    // this.couponArchived = this.coupon.archivedDate != null ? true : false;
  }

  async ngOnInit() {
    if (this.coupon.archivedOn) this.formGroup.disable();
    await this.assignValues();
    this.coupon.discountType;
    console.log('In oninit - coupon details page - ', this.coupon);
    this.formGroup.patchValue(this.resetValue);
  }
}
