import { Component, Input } from '@angular/core';

@Component({
  selector: 'greco-super-title',
  templateUrl: './super-title.component.html',
  styleUrls: ['./super-title.component.scss']
})
export class SuperTitleComponent {
  @Input() text: string;
}
