import { SecurityResource } from '@greco/security';

export enum AccountPaymentMethodResourceAction {
  READ = 'READ',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET_DEFAULT = 'SET_DEFAULT',
}

export const AccountPaymentMethodResource: SecurityResource = {
  key: 'finance_payments_account_payment_method',
  module: 'Finance',

  title: 'Account Payment Method',
  description: 'Manage Account payment methods',

  context: class {
    accountId?: string;
    communityId?: string;
  },

  actions: [
    {
      key: AccountPaymentMethodResourceAction.READ,
      title: 'Read',
      description: 'Ability to read/view payment methods from a finance account',
    },
    {
      key: AccountPaymentMethodResourceAction.ADD,
      title: 'Add',
      description: 'Ability to add a new payment method to a finance account',
    },
    {
      key: AccountPaymentMethodResourceAction.REMOVE,
      title: 'Remove',
      description: 'Ability to remove a payment method from a finance account',
    },
    {
      key: AccountPaymentMethodResourceAction.SET_DEFAULT,
      title: 'Set Default',
      description: "Ability to specify a finance account's default payment method",
    },
  ],
};
