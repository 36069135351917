<ng-container *grecoLet="preview$ | async as preview">
  <div class="content">
    <greco-box>
      <!-- Header -->
      <div class="header">
        <div style="display: flex; gap: 12px">
          <greco-multi-avatar
            [images]="(photoUrls$ | async) || ['assets/lf3/icon_square_pad.png']"
            [size]="54"
          ></greco-multi-avatar>

          <div class="title-container">
            <div class="community">
              <mat-icon>place</mat-icon>
              <span><strong>{{ event.community.name }}</strong></span>
              <span style="margin-left: 8px; display: flex; align-items: center">
                <mat-icon style="width: 14px; height: 14px; font-size: 14px">calendar_month</mat-icon>
                <strong>{{ event.calendar?.title }}</strong>
              </span>
            </div>

            <h2 class="title">{{ event.title }}</h2>
          </div>
        </div>

        <div class="title-container">
          <div class="time">
            <mat-icon>event</mat-icon>
            <span>
              <strong>
                {{event.startDate | date: 'MMM dd,'}} {{ event.startDate | date: 'shortTime' }} - {{ event.duration
                }}mins
              </strong>
            </span>
          </div>

          <div *ngIf="event.resourceAssignments?.length" style="text-align: left">
            <small>With </small>
            <div
              style="display: inline-block; overflow: wrap"
              *ngFor="let assignment of event.resourceAssignments; let i = index"
            >
              <div *ngIf='assignment.resource?.type === "PERSON"'>
                {{assignment.resource | pluck: 'name'}}
                <small *ngIf="i < event.resourceAssignments.length - 1">,&nbsp;&nbsp;</small>
              </div>
            </div>

            <!-- This can make it so that there are icons beside the trainer names. Just remove comment tags and {{assignment.resource | pluck: 'name'}}
            <div style="display: flex; flex-flow: column wrap">
              <div
                *ngFor="let assignment of event.resourceAssignments; let i = index"
                style="display: flex; gap: 12px; text-align: left; margin-right: 12px; margin-bottom: 3px"
              >
                <greco-multi-avatar [size]="30" [images]="[assignment.resource | pluck: 'photoURL']"></greco-multi-avatar>
                {{resource | pluck: 'name'}}
              </div>
            </div>-->
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="section" *ngIf="event.description">
        <div class="description" [innerHTML]="event.description | safe: 'html'"></div>
      </div>

      <!-- Requirements List -->
      <div *ngIf="selectedBookingOption" class="section requirements-list">
        <greco-booking-preview-booking-option-picker
          [complimentaryOption]="complimentaryOption$ | async"
          [bookingOptions]="(bookingOptions$ | async) || []"
          [showBoosters]="selectedBookingType.value"
          [value]="selectedBookingOption"
          [event]="event"
        ></greco-booking-preview-booking-option-picker>

        <!-- Booking Option -->
        <greco-booking-option-requirement
          [bookinOption]="selectedBookingOption"
          [requiredBoosts]="(requiredBoosts$ | async) || 0"
        ></greco-booking-option-requirement>

        <!-- Cancellation Policy -->
        <greco-cancellation-policy-requirement
          *ngIf="selectedBookingOption.cancellation"
          [bookingOption]="selectedBookingOption"
          [event]="event"
        ></greco-cancellation-policy-requirement>

        <!-- Email Verification -->
        <!-- <ng-container *ngIf="currentUserId$ | async as currentUserId">
          <greco-email-verification-requirement
            *ngIf="user.id === currentUserId && !user.emailVerified"
            (refreshed)="refreshed.emit()"
          ></greco-email-verification-requirement>
        </ng-container> -->

        <!-- Payment Method -->
        <ng-container *grecoLet="requiredBoosts$ | async as boosts">
          <ng-container *grecoLet="hasPaymentMethod$ | async as hasPaymentMethod">
            <greco-payment-method-requirement
              *ngIf="hasPaymentMethod === false && (selectedBookingOption?.price || selectedBookingOption?.cancellationPrice || boosts)"
              [user]="user"
              (refresh)="refreshed.emit()"
              [allowBankPayments]="false"
            ></greco-payment-method-requirement>
          </ng-container>
        </ng-container>

        <!-- Other 'Extensions' (typeform) -->
        <greco-typeform-requirement
          *ngFor="let req of typeformRequirements$ | async"
          [requirement]="req"
          [user]="user"
          (filled)="requirementFilledIn(req, $event.responseId)"
        ></greco-typeform-requirement>
      </div>

      <div>
        <greco-perks-preview
          *ngIf="bookingOptionsIds?.length"
          (perksEmitter)="recievePerk($event)"
          [userId]="user.id"
          [perkIds]="bookingOptionsIds || []"
          [perkTypes]="['BOOKING_OPTION']"
          [isSelected]="selectedBookingOption"
        ></greco-perks-preview>
      </div>

      <!-- <div *ngIf="preview && preview.errors && preview.errors.length" style="padding-left: 12px; margin-top: 12px">
        <p
          *ngFor="let error of preview.errors"
          style="font-size: small; font-style: italic; display: flex; align-items: center"
        >
          <mat-icon style="font-size: 16px; width: 16px; height: 16px; margin-right: 4px">report_problem</mat-icon>
          <span>{{error}}</span>
        </p>
      </div>

      <div *ngIf="preview && preview.warnings && preview.warnings.length" style="padding-left: 12px; margin-top: 12px">
        <p
          *ngFor="let warning of preview.warnings"
          style="font-size: small; font-style: italic; display: flex; align-items: center"
        >
          <mat-icon style="font-size: 16px; width: 16px; height: 16px; margin-right: 4px">priority_high</mat-icon>
          <span>{{warning}}</span>
        </p>
      </div> -->
    </greco-box>

    <greco-collapsible-section
      *ngIf="(seriesAgrUsage$ | async)?.length"
      [header]="{ title: 'Agreements', icon: 'receipt_long' }"
    >
      <!-- Agreements -->

      <div [formGroup]="form">
        <ng-container *ngFor="let usage of seriesAgrUsage$ | async">
          <greco-collapsible-section
            #agreementEx
            [header]="{ title: usage.agreement?.title || ''}"
            [expanded]="false"
            (click)="$event.stopImmediatePropagation()"
          >
            <div style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
              <span [innerHtml]="(usage.agreement?.text || '') | safe:'html'"></span>
            </div>

            <!-- put checkbox/signature in header if not expanded -->
            <ng-container header *ngIf="!agreementEx.expanded">
              <mat-checkbox
                *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                [checked]="this.form.get(usage.id)?.value"
                [required]="true"
                color="primary"
                (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
                [formControlName]="usage.id"
                >I have read and agree to the terms of the agreement</mat-checkbox
              >

              <mat-checkbox
                *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                [checked]="this.form.get(usage.id)?.value"
                [required]="true"
                color="primary"
                [formControlName]="usage.id"
                (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
                >I have read and agree to the terms of the agreement</mat-checkbox
              >

              <ng-container *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'">
                <span *ngIf="!this.form.get(usage.id)?.value " style="color: var(--warn-color)"
                  >* Signature Required</span
                >
                <i *ngIf="this.form.get(usage.id)?.value">Signed</i>
              </ng-container>
            </ng-container>

            <mat-checkbox
              *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
              [checked]="this.form.get(usage.id)?.value"
              [required]="true"
              color="primary"
              [formControlName]="usage.id"
              (click)="setCheck(usage.id, usage.agreementId);  $event.preventDefault()"
              >I have read and agree to the terms of the agreement</mat-checkbox
            >

            <mat-checkbox
              *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
              [checked]="this.form.get(usage.id)?.value"
              [required]="true"
              color="primary"
              [formControlName]="usage.id"
              (click)="setCheck(usage.id, usage.agreementId); $event.preventDefault()"
              >I have read and agree to the terms of the agreement</mat-checkbox
            >
            <greco-signature
              *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'"
              [user]="user"
              [forPurchase]="true"
              [formControlName]="usage.id"
              ngDefaultControl
              (signatureChange)="setSignature($event, usage.id, usage.agreementId)"
            >
            </greco-signature>
          </greco-collapsible-section>
        </ng-container>
      </div>
    </greco-collapsible-section>

    <ng-container *ngIf="preview">
      <greco-collapsible-section
        *ngIf="preview.purchase"
        id="payment-section"
        [header]="{ title: 'Payment', icon: 'receipt_long' }"
      >
        <greco-purchase-preview
          [purchase]="preview.purchase.purchase"
          [communityId]="event.community.id"
          [showHeader]="false"
          [showTotals]="true"
        ></greco-purchase-preview>

        <div
          *ngIf="user && preview && preview.purchase && (preview.purchase.purchase.total > preview.purchase.purchase.balanceUsed)"
          class="payment-method-container"
        >
          <mat-form-field appearance="standard" class="payment-method-picker-form-field">
            <mat-label *ngIf="!paymentMethodControl.value"><i>Add a Payment Method</i></mat-label>
            <greco-select-payment-method
              #selectPaymentMethodInput
              [formControl]="paymentMethodControl"
              [userId]="user.id"
              [required]="true"
              [allowBankPayments]="false"
            ></greco-select-payment-method>
            <greco-add-payment-method-button
              matSuffix
              [onlyIcon]="true"
              [userId]="user.id"
              (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
            ></greco-add-payment-method-button>
          </mat-form-field>
        </div>
      </greco-collapsible-section>
    </ng-container>
  </div>

  <ng-container *ngIf="bookingOptions$ | async as bookingOptions">
    <ng-container *grecoLet="booking$ | async as booking">
      <ng-container *ngIf="!booking">
        <div class="footer" [class.in-page]="footerInPage" *ngIf="bookingOptions.length">
          <mat-divider></mat-divider>

          <div class="footer-content" *grecoLet="requiredBoosts$ | async as requiredBoosts">
            <!-- Booking Type Options  e.g. ...Book now, Prebook-->
            <div style="font-weight: bold; cursor: pointer" (click)="bookingTypePicker.open()">
              {{bookingTypePicker.value?.label}}
            </div>

            <div style="margin-right: 16px">
              <mat-select
                #bookingTypePicker
                [value]="selectedBookingType ? selectedBookingType : bookingType[0]"
                (valueChange)="selectedBookingType = $event;"
              >
                <mat-option *ngFor="let option of bookingType" [value]="option"> {{option.label}}</mat-option>
              </mat-select>
            </div>

            <div style="padding: 10px"><a>You selected : </a><b>{{message$ | async}}</b></div>
            <!-- Remove after  -->
            <ng-container *grecoLet="canBook$ | async as canBook">
              <ng-container *grecoLet="availableIn$ | async as availableIn">
                <button
                  mat-flat-button
                  color="primary"
                  [disabled]="!canBook || !preview || !!preview.errors?.length || confirming || loading  || !form.valid"
                  [matTooltip]=" canBook ? '' : availableIn ? ('Available ' + availableIn) :  ''"
                  (click)="confirmBooking(preview)"
                >
                  <span *grecoLet="isMobile$ | async as isMobile">{{isMobile ? 'Confirm' : 'Confirm Booking'}}</span>
                  <mat-icon *ngIf="confirming" style="margin-left: 4px" class="spin">loop</mat-icon>
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div style="display: flex; align-items: center; padding: 0 8px; margin-top: 16px">
        <mat-icon color="info" style="margin-right: 8px">check</mat-icon><a>You selected : </a
        ><b>{{message$ | async}}</b>
      </div>
      <div style="padding: 0 8px; margin-top: 16px; min-height: 200px">
        <div
          *ngFor="let error of preview?.errors"
          style="display: flex; align-items: center; padding: 0 8px; margin-top: 16px"
        >
          <mat-icon color="warn" style="margin-right: 8px">error_outline</mat-icon>
          <i>{{error}}</i>
        </div>
      </div>
      <div *ngIf="booking" style="display: flex; align-items: center; padding: 0 8px; margin-top: 16px">
        <mat-icon color="warn" style="margin-right: 8px">error_outline</mat-icon>
        <i>User already booked</i>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
