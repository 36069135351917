import { BaseDto } from '@greco/util-dto';
import { Expose } from 'class-transformer';
import { IsArray, IsBoolean, IsHexColor, IsInt, IsNotEmpty, IsOptional, IsString, Min } from 'class-validator';

export class UpdateCommunityVideoUnlockOptionDto extends BaseDto {
  @Expose()
  @IsHexColor()
  hexColor: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  title: string;

  @Expose()
  @IsString()
  @IsOptional()
  description?: string;

  @Min(0)
  @IsInt()
  @Expose()
  price: number;

  @Min(0)
  @IsInt()
  @Expose()
  rentalPeriod: number;

  @Expose()
  @IsArray()
  @IsString({ each: true })
  videoTagIds: string[];

  @Expose()
  @IsString()
  @IsOptional()
  icon?: string;

  @Expose()
  @IsBoolean()
  availableToAnyone: boolean;
}
