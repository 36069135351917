import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { CreateEventUserAgreementDto } from '@greco/community-agreements';

@Injectable({
  providedIn: 'root',
})
export class EventUserAgreementService {
  constructor(private http: HttpClient) {}

  async create(dto: CreateEventUserAgreementDto) {
    return await toPromise(this.http.post('/api/event-user-agreement', dto));
  }

  async getAgreementsByEvent(eventId: string): Promise<string> {
    return await (await toPromise(this.http.get(`/api/event-user-agreement/${eventId}`))).toString();
  }
}
