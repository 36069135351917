import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { UserService } from '@greco/ngx-identity-auth';

@Component({
  selector: 'greco-fit-change-password',
  templateUrl: './change-password.dialog.html',
  styleUrls: ['./change-password.dialog.scss'],
})
export class ChangePasswordDialog {
  error = false;
  errorString = '';
  form = this.formBuilder.group({
    old: ['', Validators.required],
    new: ['', Validators.required],
    confirm: ['', Validators.required],
  });
  dialogData: DialogData = {
    title: 'Change Password',
    showCloseButton: false,
  };
  public oldPasswordVisible = false;
  public newPasswordVisible = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordDialog>,
    private snacks: MatSnackBar,
    private userSvc: UserService
  ) {}

  async submit() {
    this.error = false;
    this.errorString = '';
    const oldPassword = this.form.get('old')?.value;
    const newPassword = this.form.get('new')?.value;
    const confirmPassword = this.form.get('confirm')?.value;

    if (!newPassword || !confirmPassword || !oldPassword) {
      this.error = true;
      this.errorString += 'All fields must be entered. ';
    } else if (newPassword !== confirmPassword) {
      this.error = true;
      this.errorString += 'Passwords must match. ';
    }
    if (oldPassword === newPassword) {
      this.error = true;
      this.errorString += 'New password cannot be the same as current password. ';
    }
    if (newPassword.length < 6 || confirmPassword.length < 6) {
      this.error = true;
      this.errorString += 'Passwords must be at least 6 characters long';
    }

    if (this.error) return;
    const response = await this.userSvc.changePassword(oldPassword, newPassword);
    this.snacks.open(response, 'Ok', { duration: 3000 });
    this.dialogRef.close();
  }
}
