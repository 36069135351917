<ng-container [formGroup]="_internalForm">
  <mat-chip-list #chiplist formControlName="value">
    <mat-chip
      *ngFor="let collection of _internalForm.value.value"
      [selectable]="true"
      [removable]="!disabled"
      (removed)="remove(collection)"
    >
      <mat-icon
        *ngIf="collection.icon"
        style="width: 18px; height: 18px; line-height: 18px; font-size: 18px; margin-right: 8px"
      >
        {{ collection.icon }}
      </mat-icon>
      <span>{{ collection.label }}</span>
      <mat-icon *ngIf="!disabled" matChipRemove>cancel</mat-icon>
    </mat-chip>

    <input
      matInput
      [required]="required"
      [placeholder]="placeholder"
      [matChipInputFor]="chiplist"
      formControlName="searchQuery"
      [matChipInputAddOnBlur]="false"
      [matAutocomplete]="autocomplete"
      [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
      (matChipInputTokenEnd)="add($event)"
      (focusout)="focused = false"
      (focus)="focused = true"
    />
  </mat-chip-list>

  <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="optionSelection($event)">
    <mat-option *ngFor="let collection of collections$ | async" [value]="collection">
      <mat-icon>{{ collection.icon }}</mat-icon>
      <span>{{ collection.label }}</span>
    </mat-option>
  </mat-autocomplete>
</ng-container>
