import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BoxModule } from '@greco/ui-box';
import { StatBoxComponent, StatsGridComponent } from './components';
import { StatsService } from './services';

@NgModule({
  imports: [CommonModule, BoxModule],
  declarations: [StatsGridComponent, StatBoxComponent],
  exports: [StatsGridComponent],
  providers: [StatsService],
})
export class StatsDisplayModule {}
