import { Injectable } from '@angular/core';
import { SelectFilter } from '@greco/ngx-filters';

export enum ResourceSubstitutionFilterType {
  RESOURCE_CONFIRMED = 'Resource Confirmed',
  NEEDS_SUBSTITUTION = 'Resource Requesting Substitution',
  NEEDS_RESOURCE = 'Resource Needed',
}

@Injectable({ providedIn: 'any' })
export class ResourceSubstitutionFilter extends SelectFilter {
  constructor() {
    super('ResourceSubstitutionFilter', {
      label: 'Resource Substitution',
      description: '',
      shortLabel: 'Resource Substitution',
      properties: ['resourceSubstitution'],
      hideCond: true,
    });
  }

  getValueLabel(value: any): string {
    const confirmed = value.includes(ResourceSubstitutionFilterType.RESOURCE_CONFIRMED);
    const needsSubstitution = value.includes(ResourceSubstitutionFilterType.NEEDS_SUBSTITUTION);
    const needsResource = value.includes(ResourceSubstitutionFilterType.NEEDS_RESOURCE);

    if (confirmed && needsSubstitution && needsResource) {
      return 'All Events';
    } else if (confirmed && needsSubstitution && !needsResource) {
      return 'Requesting Substitution';
    } else if (confirmed && !needsSubstitution && needsResource) {
      return 'Resource Needed';
    } else if (!confirmed && needsSubstitution && needsResource) {
      return 'Substitution or Resource Needed';
    }

    return value;
  }

  getValueOptions(): any[] | Promise<any[]> {
    return [
      ResourceSubstitutionFilterType.RESOURCE_CONFIRMED, // Resource is assigned and no substitution needed (Confirmed Events)
      ResourceSubstitutionFilterType.NEEDS_SUBSTITUTION, // Resource is requesting substitution
      ResourceSubstitutionFilterType.NEEDS_RESOURCE, // Empty resource assignment needs a resource to be added
    ];
  }

  serializeValue(value: any): string {
    return value.toString();
  }
  deserializeValue(serializedValue: string) {
    return serializedValue.split(',');
  }
}
