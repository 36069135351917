import { Component, Input } from '@angular/core';
import { Calendar } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'greco-calendar-page',
  templateUrl: './calendar.page.html',
  styleUrls: ['./calendar.page.scss'],
})
export class CalendarPage {
  filterOptions = [];
  readonly query$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  private _community!: Community;
  @Input() set community(community: Community) {
    this._community = community;
  }
  get community() {
    return this._community;
  }

  // @PropertyListener('calendarId') private calendarId$ = new BehaviorSubject<string | null>(null);
  @Input() calendar!: Calendar | null;
}
