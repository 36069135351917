<span class="drop-down-label">{{ text }}</span>
<span class="drop-down-arrow">
  <mat-icon>arrow_drop_down</mat-icon>
</span>

<button #trigger class="trigger" [matMenuTriggerFor]="menu"></button>
<mat-menu #menu>
  <button mat-menu-item *ngFor="let option of options" (click)="toggle(option.value, $event)">
    <mat-checkbox *ngIf="multi" [checked]="selected.includes(option.value)" class="info-card-select-checkbox">
      <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ option.text }}</span>
    </mat-checkbox>

    <mat-radio-button *ngIf="!multi" [checked]="selected.includes(option.value)" class="info-card-select-radio">
      <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ option.text }}</span>
    </mat-radio-button>
  </button>
</mat-menu>
