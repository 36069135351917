import { SecurityResource } from '@greco/security';

export const EVENT_RESOURCE_SECURITY_RESOURCE = 'booking_event_resource';

export enum EventResourceSecurityResourceAction {
  DOUBLE_BOOK = 'DOUBLE_BOOK',

  // Manage Trainer Resources
  CREATE_TRAINER = 'CREATE_TRAINER',
  READ_TRAINER = 'READ_TRAINER',
  UPDATE_TRAINER = 'UPDATE_TRAINER',

  // Manage Zoom Resources
  CREATE_ZOOM = 'CREATE_ZOOM',
  READ_ZOOM = 'READ_ZOOM',
  UPDATE_ZOOM = 'UPDATE_ZOOM',

  // Manage Room Resources
  CREATE_ROOM = 'CREATE_ROOM',
  READ_ROOM = 'READ_ROOM',
  UPDATE_ROOM = 'UPDATE_ROOM',
}

export const EventResourceSecurityResource: SecurityResource = {
  key: EVENT_RESOURCE_SECURITY_RESOURCE,
  module: 'booking',

  title: 'Event Resource',
  description: 'Manage event resources',

  context: class {
    resourceId?: string;
    communityId?: string;
  },

  actions: [
    {
      key: EventResourceSecurityResourceAction.DOUBLE_BOOK,
      title: 'Double Book',
      description: 'Ability to double book resources',
    },
    // TRAINER RESOURCES
    {
      key: EventResourceSecurityResourceAction.CREATE_TRAINER,
      title: 'Create Trainer',
      description: 'Permission to Manage Trainer Resources',
    },
    {
      key: EventResourceSecurityResourceAction.READ_TRAINER,
      title: 'Read Trainer',
      description: 'Permission to Manage Trainer Resources',
    },
    {
      key: EventResourceSecurityResourceAction.UPDATE_TRAINER,
      title: 'Update Trainer',
      description: 'Permission to Manage Trainer Resources',
    },
    // ROOM RESOURCES
    {
      key: EventResourceSecurityResourceAction.CREATE_ROOM,
      title: 'Create Room',
      description: 'Permission to Manage Room Resources',
    },
    {
      key: EventResourceSecurityResourceAction.READ_ROOM,
      title: 'Read Room',
      description: 'Permission to Manage Room Resources',
    },
    {
      key: EventResourceSecurityResourceAction.UPDATE_ROOM,
      title: 'Update Room',
      description: 'Permission to Manage Room Resources',
    },
    // ZOOM RESOURCES
    {
      key: EventResourceSecurityResourceAction.CREATE_ZOOM,
      title: 'Create Zoom',
      description: 'Permission to Manage Zoom Resources',
    },
    {
      key: EventResourceSecurityResourceAction.READ_ZOOM,
      title: 'Read Zoom',
      description: 'Permission to Manage Zoom Resources',
    },
    {
      key: EventResourceSecurityResourceAction.UPDATE_ZOOM,
      title: 'Update Zoom',
      description: 'Permission to Manage Zoom Resources',
    },
  ],
};
