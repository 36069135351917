<greco-simple-dialog [data]="dialogData">
  <greco-table [data]="data.resourceAssignments">
    <ng-container *grecoTableCol="''; let assignment; fitContent: true" style="margin-right: -42px">
      <div
        *ngIf="assignment?.resource?.photoURL || assignment?.resource?.type === 'PERSON'; else noPhoto"
        style="width: 24px; height: 24px"
      >
        <greco-smart-img [src]="assignment?.resource?.photoURL || 'assets/lf3/icon_square_pad.png'" [round]="true">
        </greco-smart-img>
      </div>
      <ng-template #noPhoto>
        <mat-icon>
          {{ assignment?.resource?.icon ? assignment?.resource?.icon : { PERSON: 'account_circle', ROOM: 'chair', OTHER:
          'texture', ZOOM: 'videocam' }[assignment?.resource?.type + ''] }}
        </mat-icon>
      </ng-template>
    </ng-container>

    <ng-container *grecoTableCol="'Resource Name'; let assignment"> {{assignment.resource.name}} </ng-container>

    <ng-container *grecoTableCol="'Resource Tag'; let assignment">
      <span [innerHTML]="(assignment?.resourceTag?.label || '<i>No Tag</i>') | safe: 'html'"></span>
    </ng-container>
  </greco-table>
  <p *ngIf="(canDoubleBook$ | async) === false" style="margin-top: 4px">
    <i>You don't have the permissions to double book a resource</i>
  </p>

  <div style="margin-top: 12px; display: flex; justify-content: flex-end; gap: 8px">
    <button mat-button (click)="cancel()">Cancel</button>
    <button *ngIf="canDoubleBook$ | async" mat-button (click)="continue()">Assign Anyway</button>
  </div>
</greco-simple-dialog>
