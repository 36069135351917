import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DiscountType } from '@greco/coupon';
import { SecurityService } from '@greco/ngx-security-util';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CouponService } from '../../services';

@Component({
  selector: 'greco-create-coupon',
  templateUrl: './create-coupon.dialog.html',
  styleUrls: ['./create-coupon.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateCouponDialog implements OnInit, OnDestroy {
  readonly flatAmountMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  readonly percentageMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '',
    suffix: '%',
    thousands: ',',
    inputMode: 0,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private dialogRef: MatDialogRef<CreateCouponDialog>,
    private fb: FormBuilder,
    private securitySvc: SecurityService,
    private couponSvc: CouponService,
    private snacks: MatSnackBar
  ) {}

  @ViewChild(SimpleDialog) private container!: SimpleDialog;

  formGroup = this.fb.group({
    title: ['', Validators.required],
    shortTitle: [''],
    description: [''],
    discountType: ['', Validators.required],
    discountAmount: ['0', Validators.required],
    availableInSubCommunities: [false],
    allProducts: [false],
    color: [''],
    icon: ['', Validators.required],
  });

  coponResetValue: any = null;
  creatingCoupon: any;
  discountUnit = DiscountType.FLAT_AMOUNT;
  submitting = false;
  discountTypeArray = [DiscountType.FLAT_AMOUNT, DiscountType.PERCENTAGE];
  private _onDestroy$ = new Subject<void>();
  refresh$ = new BehaviorSubject(null);

  ngOnInit() {
    this.formGroup.valueChanges
      .pipe(
        startWith(this.formGroup.value),
        takeUntil(this._onDestroy$),
        map(() => this.container?.data?.buttons?.find(btn => btn.role === 'create'))
      )
      .subscribe(button => button && ((button as any).disabled = this.formGroup.invalid));
  }

  getResult = async () => {
    this.submitting = true;
    try {
      const newCoupon = {
        communityId: this.data.communityId,
        title: this.formGroup.value.title,
        shortTitle:
          this.formGroup.value.shortTitle == '' ? this.formGroup.value.title : this.formGroup.value.shortTitle,
        description: this.formGroup.value.description,
        discountType: this.formGroup.value.discountType,
        discountAmount:
          this.formGroup.value.discountType == DiscountType.FLAT_AMOUNT
            ? this.formGroup.value.discountAmount * 100
            : this.formGroup.value.discountAmount,
        availableInSubCommunities: false,
        module: 'shop',
        badge: {
          text: this.formGroup.value.title,
          color: '#04577a',
          shape: 'squircle',
          icon: this.formGroup.value.icon,
        },
        allProducts: this.formGroup.value.allProducts,
      };
      const coupon = await this.couponSvc.create(newCoupon);
      this.submitting = false;
      this.dialogRef.close(coupon);
      if (coupon) {
        this.snacks.open(`Coupon - ${this.formGroup.value.title} created successfully!`, 'Ok', {
          duration: 3000,
          panelClass: 'mat-primary',
        });
      } else {
        this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
      }
    } catch (err) {
      console.error(err);
    }
  };

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
