/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogData } from '@greco-fit/scaffolding';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'greco-preview-agreement-dialog',
  templateUrl: './preview-agreement.dialog.html',
  styleUrls: ['./preview-agreement.dialog.scss'],
})
export class PreviewAgreementDialog {
  pdfData;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pdf: jsPDF;
      title: string;
    },
    private mdDialogRef: MatDialogRef<PreviewAgreementDialog>,
    private _sanitizer: DomSanitizer
  ) {
    const pdfOutput = data.pdf.output('bloburl');
    this.pdfData = this._sanitizer.bypassSecurityTrustResourceUrl(pdfOutput.toString());
  }

  dialogData: DialogData = {
    showCloseButton: false,
    title: this.data.title,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      { label: 'Download', role: 'submit', resultFn: this.downloadPdf.bind(this) },
    ],
  };

  downloadPdf() {
    this.data.pdf.save(this.data.title);
  }
}
