import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'greco-confirm',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.scss'],
})
export class ConfirmDialog {
  @Input() data: ConfirmData;

  constructor(@Inject(MAT_DIALOG_DATA) data: ConfirmData, private ref: MatDialogRef<ConfirmDialog>) {
    this.data = { ...data };
  }

  dismiss(role: 'confirm' | 'cancel') {
    this.ref.close(role);
  }
}

export interface ConfirmData {
  title: string;
  message: string;
  objectType: string;
  objectDescription: string;
}
