<h2 style="margin: 0">Create Tax</h2>
<form [formGroup]="formGroup">
  <div style="margin: 10px 0px 25px 0px; display: flex; flex-direction: column">
    <greco-add-community-custom-tax
      formControlName="customTax"
      #addTaxComponent
      [accountId]="accountId"
    ></greco-add-community-custom-tax>
  </div>

  <div style="display: flex; gap: 8px">
    <button style="flex: 1" type="button" mat-stroked-button (click)="close()">
      <span>Cancel</span>
    </button>
    <button
      style="flex: 1"
      type="submit"
      mat-stroked-button
      color="primary"
      (click)="addTax(addTaxComponent._form.value)"
      [disabled]="addTaxComponent._form.invalid"
    >
      <span>Confirm</span>
    </button>
  </div>
</form>
