import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { SafePipeModule } from 'safe-pipe';
import { SwiperModule } from 'swiper/angular';
import { SignInComponent } from './sign-in/sign-in.component';
import { TestimonialCardComponent } from './testimonial-card/testimonial-card.component';
import { TestimonialCarouselComponent } from './testimonial-carousel/testimonial-carousel.component';
import { WelcomeComponent } from './welcome/welcome.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    SwiperModule,
    SafePipeModule,
  ],
  exports: [SignInComponent, TestimonialCarouselComponent, TestimonialCardComponent, WelcomeComponent], // Remember to export components used in storybook
  declarations: [SignInComponent, TestimonialCarouselComponent, TestimonialCardComponent, WelcomeComponent],
})
export class WelcomeModule {}
