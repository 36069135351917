<div>
  <div style="margin-bottom: 16px">
    <h2 style="margin-bottom: 8px">Remove Perk ({{data.perk.title}})</h2>
    <p style="margin: 0">
      You are about to remove <b>{{data.perk.title}}</b> for {{data.userName}}! Do you want to Proceed?
    </p>
  </div>

  <div style="margin-top: 16px; display: grid; grid-template-columns: 1fr 1fr; gap: 8px">
    <button type="button" mat-stroked-button style="height: 32px; line-height: 16px" (click)="close()">Cancel</button>
    <button type="button" color="primary" mat-stroked-button style="height: 32px; line-height: 16px" (click)="submit()">
      <span *ngIf="!submitting">Remove {{data.perk.title}}</span>
      <mat-icon *ngIf="submitting" class="spin">loop</mat-icon>
    </button>
  </div>
</div>
