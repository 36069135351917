<div
  class="background-shape"
  [ngSwitch]="shape"
  [style.--color]="color || '' | safe : 'style'"
  [matBadge]="quantity === inf ? '∞' : quantity === 1 ? null : quantity"
  matBadgePosition="below"
  @fadeInOut
>
  <ng-container *ngSwitchCase="'square'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <defs></defs>
      <rect width="100" height="100" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'circle'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <defs></defs>
      <circle cx="50" cy="50" r="50" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'rounded-star'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 96.1"
      style="enable-background: new 0 0 100 96.1"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        d="M47.5,91.2l-19.3,4.7c-6.4,1.5-12.6-3-13.1-9.5l-1.5-19.8c-0.1-1.7-0.7-3.3-1.5-4.8L1.6,44.8c-3.4-5.6-1.1-12.9,5-15.4
L25,21.8c1.6-0.6,3-1.7,4.1-2.9L41.9,3.8c4.3-5,12-5,16.2,0L71,18.9c1.1,1.3,2.5,2.3,4.1,2.9l18.4,7.6c6.1,2.5,8.5,9.8,5,15.4
L88,61.7c-0.9,1.4-1.4,3.1-1.5,4.8l-1.5,19.8c-0.5,6.5-6.7,11.1-13.1,9.5l-19.3-4.7C50.9,90.8,49.1,90.8,47.5,91.2z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'pentagon'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 95.1"
      style="enable-background: new 0 0 100 95.1"
      xml:space="preserve"
    >
      <defs></defs>
      <polygon points="50,0 0,36.3 19.1,95.1 80.9,95.1 100,36.3 " />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'hexagon'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 86.6 100"
      style="enable-background: new 0 0 86.6 100"
      xml:space="preserve"
    >
      <defs></defs>
      <polygon points="86.6,75 86.6,25 43.3,0 0,25 0,75 43.3,100 " />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'rounded-square'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        d="M74.5,100h-49C11.4,100,0,88.6,0,74.5v-49C0,11.4,11.4,0,25.5,0h49C88.6,0,100,11.4,100,25.5v49C100,88.6,88.6,100,74.5,100
      z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'diamond'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <defs></defs>
      <rect x="14.6" y="14.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 50 -20.7107)" width="70.7" height="70.7" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'egg'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 85 100"
      style="enable-background: new 0 0 85 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path d="M85,60c0,27.6-16.4,40-42.5,40S0,87.6,0,60S16.4,0,42.5,0S85,32.4,85,60z" />
    </svg>
  </ng-container>

  <ng-container *ngSwitchCase="'squircle'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <style type="text/css">
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
        }
      </style>
      <defs></defs>
      <path
        class="st0"
        d="M50,100H0l28.6,0L50,100 M100,100H50l21.3,0L100,100 M50,0C14,0-0.1,14,0,50c-0.1,36,14,50,50,50
c36,0,50.1-14,50-50C100.1,14,86,0,50,0 M28.6,0L0,0h50L28.6,0 M71.3,0L50,0h50L71.3,0"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'badge'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 96.1 100"
      style="enable-background: new 0 0 96.1 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        d="M42,2c3.6-2.6,8.4-2.6,12,0c8.6,6.3,6.7,5.7,17.4,5.6c4.4,0,8.4,2.8,9.7,7.1c3.2,10.1,2.1,8.6,10.7,14.8
c3.6,2.6,5.1,7.2,3.7,11.4c-3.3,10.1-3.3,8.1,0,18.2c1.4,4.2-0.1,8.8-3.7,11.4c-8.6,6.2-7.5,4.6-10.7,14.8c-1.4,4.2-5.3,7.1-9.7,7.1
c-10.6,0-8.8-0.6-17.4,5.6c-3.6,2.6-8.4,2.6-12,0c-8.6-6.3-6.7-5.7-17.4-5.6c-4.4,0-8.4-2.8-9.7-7.1c-3.2-10.1-2.1-8.6-10.7-14.8
C0.6,68-0.9,63.3,0.5,59.1c3.3-10.1,3.3-8.1,0-18.2c-1.4-4.2,0.1-8.8,3.7-11.4c8.6-6.2,7.5-4.6,10.7-14.8c1.4-4.2,5.3-7.1,9.7-7.1
C35.3,7.6,33.4,8.3,42,2z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'heart'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <style type="text/css">
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
        }
      </style>
      <defs></defs>
      <path
        class="st0"
        d="M73.9,0C64.7,0,55.3,4.8,50,14.8C44.6,4.7,35.3,0,26.2,0C12.9,0,0,9.9,0,28.1C0,49.3,23.2,71,50,100
      c26.8-29,50-50.7,50-71.9C100,9.9,87.1,0,73.9,0"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'shield-1'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 85 100"
      style="enable-background: new 0 0 85 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        d="M42.5,0C26.6,11.9,16.6,12.5,0,12.5v48.1C0,79.7,15.1,84.7,42.5,100C69.9,84.7,85,79.7,85,60.6V12.5
      C68.4,12.5,58.4,11.9,42.5,0z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'shield-2'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 91.7 100"
      style="enable-background: new 0 0 91.7 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        d="M45.8,0C33.4,12.5,14.6,16.7,0,16.7C0,52.4,21.1,83.7,45.8,100c24.7-16.3,45.8-47.6,45.8-83.3C77,16.7,58.3,12.5,45.8,0z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'shield-3'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 85 100"
      style="enable-background: new 0 0 85 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        d="M42.5,0C26.3,0,9.8,5.1,0,12.5v48.1C0,79.7,15.1,84.7,42.5,100C69.9,84.7,85,79.7,85,60.6V12.5C75.2,5.1,58.7,0,42.5,0z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'shield-4'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 91.7 100"
      style="enable-background: new 0 0 91.7 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        d="M0,16.7C0,52.4,21.1,83.8,45.8,100c24.7-16.2,45.8-47.6,45.8-83.3C79.9,7,61.9,0,45.8,0C29.8,0,11.8,7,0,16.7z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'flower'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <style type="text/css">
        .st0 {
          fill-rule: evenodd;
          clip-rule: evenodd;
        }
      </style>
      <defs></defs>
      <path
        class="st0"
        d="M50,100c-4.8,0-9.2-1.9-12.6-5.3c-1.6-1.6-2.8-3.5-3.7-5.6c-6.4,2.7-14.1,1.2-19.1-3.7
      c-3.4-3.4-5.2-7.8-5.1-12.6c0-2.3,0.5-4.5,1.3-6.6C4.4,63.6,0,57.4,0,50c0-7.3,4.5-13.6,10.8-16.2c-2.7-6.4-1.4-13.9,3.8-19.1
      c4.9-4.9,12.8-6.4,19.1-3.8c0.8-2,2.1-3.9,3.6-5.5C40.8,1.9,45.2,0,50,0c7.4,0,13.6,4.4,16.2,10.9c6.4-2.6,14.2-1.2,19.1,3.8
      c5.2,5.2,6.5,12.6,3.8,19.1C95.5,36.3,100,42.6,100,50c0,7.4-4.4,13.6-10.9,16.2c2.7,6.4,1.4,13.9-3.8,19.1
      c-4.9,4.9-12.8,6.4-19.1,3.8C63.6,95.6,57.4,100,50,100z"
      />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'drop'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 85 100"
      style="enable-background: new 0 0 85 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path d="M42.5,0C16.6,30,0,48.7,0,67c0,18.2,19,33,42.5,33S85,85.2,85,67C85,48.7,68.4,30,42.5,0z" />
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'gear'">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background: new 0 0 100 100"
      xml:space="preserve"
    >
      <defs></defs>
      <path
        d="M100,56.7V43.3c-6.9-2.4-11.2-3.1-13.4-8.4v0c-2.2-5.3,0.4-8.9,3.5-15.4l-9.5-9.5c-6.5,3.1-10.1,5.7-15.4,3.5h0
      c-5.3-2.2-6-6.6-8.4-13.4H43.3c-2.4,6.8-3.1,11.2-8.4,13.4h0C29.6,15.6,26,13,19.4,9.9l-9.5,9.5c3.1,6.5,5.7,10.1,3.5,15.4
      c-2.2,5.3-6.6,6-13.4,8.4v13.5c6.8,2.4,11.2,3.1,13.4,8.4c2.2,5.3-0.5,9-3.5,15.4l9.5,9.5c6.5-3.1,10.1-5.7,15.4-3.5h0
      c5.3,2.2,6,6.6,8.4,13.4h13.5c2.4-6.8,3.1-11.2,8.4-13.4h0c5.3-2.2,8.8,0.4,15.4,3.5l9.5-9.5c-3.1-6.5-5.7-10.1-3.5-15.4
      C88.8,59.9,93.2,59.2,100,56.7z"
      />
    </svg>
  </ng-container>
</div>
<div @fadeInOut class="text-overlay">
  <mat-icon *ngIf="icon; else showText">{{ icon }}</mat-icon>
  <ng-template #showText>
    <h4 [grecoTextFit]="true">{{ text }}</h4>
  </ng-template>
</div>
