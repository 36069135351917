<ng-container *ngIf="event">
  <div class="details-container">
    <div class="title-container">
      <greco-multi-avatar [images]="photoUrls" [size]="54" [color]="event.color || 'primary'"></greco-multi-avatar>

      <div>
        <div class="community">
          <mat-icon>place</mat-icon>
          <span>{{ event.community.name }} </span>
        </div>

        <h3 style="margin: 0">{{ event.title }}</h3>
      </div>
    </div>

    <div>
      <div class="time">
        <mat-icon>event</mat-icon>
        <span style="font-weight: bold">
          {{ event.startDate | date : 'MMM dd,' }} {{ event.startDate | date : 'shortTime' }} - {{ event.duration }}mins
        </span>
      </div>

      <ng-container *grecoLet="getPersonAssignments(event.resourceAssignments) as assignments">
        <div *ngIf="assignments?.length" style="text-align: right">
          <small>w/ </small>
          <div style="display: inline-block; overflow: wrap" *ngFor="let assignment of assignments; let i = index">
            <div *ngIf="assignment?.resource?.type == 'PERSON'">
              {{ assignment?.resource | pluck : 'name' }}
              <small *ngIf="i < (assignments?.length || 0) - 1">,&nbsp;&nbsp;</small>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *grecoLet="getRoomAssignment(event.resourceAssignments) as room">
        <div *ngIf="room" class="room" style="text-align: right">
          <mat-icon>{{ room.icon || 'living' }}</mat-icon>
          {{ room.name }}
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="event.description">
    <div class="description" [innerHTML]="event.description | safe : 'html'"></div>
  </div>
</ng-container>
