import { Component, Input } from '@angular/core';
import { Tile } from '@greco/tiles';

@Component({
  selector: 'greco-tile-detailspage',
  templateUrl: './tile-details.page.html',
  styleUrls: ['./tile-details.page.scss'],
})
export class TileDetailsPage {
  @Input() tile!: Tile;
}
