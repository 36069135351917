import { Injectable } from '@angular/core';
import { Resource } from '@greco/booking-events';
import { MultiAutocompleteFilter } from '@greco/ngx-filters';
import { CondOperator, RequestQueryBuilder, SFields } from '@nestjsx/crud-request';
import { ResourcesService } from '../../../services';

@Injectable({ providedIn: 'any' })
export class BookingResourceFilter extends MultiAutocompleteFilter {
  constructor(private resourceSvc: ResourcesService) {
    super('BookingResourceFilter', {
      description: '',
      label: 'Resource',
      properties: ['event.resourceAssignments.resourceId'],
      shortLabel: 'Resource',
      canOpen: true,
      onlyOne: true,
    });
  }

  public communityId?: string;

  getImageUrl(value: Resource) {
    return value?.photoURL ?? 'assets/lf3/icon.png';
  }

  getAutocompleteHtml(value: Resource): string {
    return value.name;
  }

  getValueLabel(value: Resource | Resource[]): string {
    if ((value as Resource[])?.length) {
      return (value as Resource[])?.map(tag => tag.name).join(', ');
    } else return (value as Resource)?.name;
  }

  async getValueOptions(search?: string): Promise<Resource[]> {
    if (!this.communityId) return [];

    const resources = await this.resourceSvc.paginateResources(
      new RequestQueryBuilder().search({
        $and: [{ 'user.id': { $notnull: true } }, { name: { $contL: search } }],
      }),
      this.communityId,
      { limit: 10 }
    );

    return resources.items;
  }

  serializeValue(value: Resource[]): string {
    return value.map(resource => resource.id).join(',');
  }

  async deserializeValue(serializedValue: string) {
    const ids = serializedValue.split(',');
    const resources: Resource[] = [];

    await Promise.all(
      ids.map(async id => {
        const resource = await this.resourceSvc.getResource(id);
        if (resource) resources.push(resource);
      })
    );

    return resources?.length ? resources : null;
  }

  getPropertySField(property: string, operator: CondOperator, value: Resource[]): SFields {
    return { [property]: { [operator]: value.map(resource => resource?.id) } };
  }
}
