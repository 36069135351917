import { Component, Input } from '@angular/core';
import { User } from '@greco/identity-users';

@Component({
  selector: 'greco-user-account-linking-page',
  templateUrl: './user-account-linking.page.html',
  styleUrls: ['./user-account-linking.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UserAccountLinkingPage {
  @Input() user!: User;
}
