import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ZoomService } from '@greco/ngx-zoom';

@Component({
  selector: 'greco-zoom-resource-input',
  templateUrl: './zoom-resource-input.component.html',
  styleUrls: ['./zoom-resource-input.component.scss'],
})
export class ZoomResourceInputComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private zoomService: ZoomService
  ) {}

  formGroup = this.formBuilder.group({
    name: ['', Validators.required],
  });

  async create() {
    try {
      window.open(this.zoomService.getInstallUrl(this.data.communityId, this.formGroup.value.name), '_self');
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { panelClass: 'mat-warn' });
      return null;
    }
    return;
  }
}
