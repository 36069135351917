import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Tax } from '@greco/finance-tax';
import { TaxDto } from '@greco/nestjs-finance-tax';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class TaxService {
  constructor(private http: HttpClient) {}

  async getTaxes() {
    return await toPromise(this.http.get<Tax[]>('/api/taxes'));
  }

  async paginateTaxes(query: RequestQueryBuilder, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<Tax>>(`/api/taxes/paginate`, {
        params: {
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  async getOne(taxId: string) {
    return await toPromise(this.http.get<Tax>(`/api/taxes/${taxId}`));
  }
  async createTax(dto: TaxDto) {
    return await toPromise(this.http.post<Tax>(`/api/taxes`, dto));
  }

  async updateTax(taxId: string, dto: TaxDto) {
    return await toPromise(this.http.post<Tax>(`/api/taxes/${taxId}`, dto));
  }
}
