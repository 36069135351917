import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { EventSecurityResource, EventSecurityResourceAction } from '@greco/booking-events';
import {
  CommunityVideoSecurityResource,
  CommunityVideoSecurityResourceAction,
  CommunityVideoUnlockOptionSecurityResource,
  CommunityVideoUnlockOptionSecurityResourceAction,
} from '@greco/community-videos';
import { AccountResource, AccountResourceAction } from '@greco/finance-accounts';
import { TransactionResource, TransactionResourceAction } from '@greco/finance-transactions';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { APP_CONFIG, AppConfig } from '@greco/ngx-app-config';
import { ResourcesService } from '@greco/ngx-booking-events';
import { AccountService } from '@greco/ngx-finance-accounts';
import { UserService } from '@greco/ngx-identity-auth';
import { LegalService } from '@greco/ngx-platform-legal';
import { PromotionsService } from '@greco/ngx-promotions';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EventService } from '@greco/ngx-route-booking';
import { SecurityService } from '@greco/ngx-security-util';
import { CreateQuickPurchaseDialog } from '@greco/ngx-shop';
import { ReportsSecurityResource, ReportsSecurityResourceAction } from '@greco/reports';
import { PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import { SubscriptionResource, SubscriptionResourceAction } from '@greco/sales-subscriptions';
import { fadeIn } from '@greco/ui-animations';
import { PlatformLayout, PlatformLink } from '@greco/ui-platform-layout';
import { VideoCollectionSecurityResource, VideoCollectionSecurityResourceAction } from '@greco/video-library';
//import { VideoPerkSecurityResource, VideoPerkSecurityResourceAction } from '@greco/videos';
import { combineLatest, from, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'greco-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
  animations: [fadeIn],
})
export class PlatformComponent implements OnInit, AfterViewInit {
  user$: Observable<User | null> = this.userSvc.user$.pipe(shareReplay(1));

  canCreatePurchaseAccounts$ = this.user$.pipe(
    switchMap(u => {
      return u
        ? this.accountSvc.accountsWithAccess(PurchaseResource.key, PurchaseResourceAction.PURCHASE_AS_USER)
        : of([]);
    }),
    map(accounts => accounts.filter(a => !!(a as any).community?.name)),
    shareReplay(1)
  );

  canReadContacts$ = from(
    this.securityService.hasAccess(ContactResource.key, ContactResourceAction.READ, {}, true)
  ).pipe(shareReplay(1));
  canReadPurchases$ = from(
    this.securityService.hasAccess(PurchaseResource.key, PurchaseResourceAction.READ, {}, true)
  ).pipe(shareReplay(1));
  canReadSubscriptions$ = from(
    this.securityService.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.READ, {}, true)
  ).pipe(shareReplay(1));
  canReadSchedule$ = from(
    this.securityService.hasAccess(EventSecurityResource.key, EventSecurityResourceAction.READ_PRIVATE, {}, true)
  ).pipe(shareReplay(1));
  canReadTransactions$ = from(
    this.securityService.hasAccess(TransactionResource.key, TransactionResourceAction.READ, {}, true)
  ).pipe(shareReplay(1));
  isResource$ = from(this.isResource()).pipe(shareReplay(1));
  canReadVideos$ = from(
    this.securityService.hasAccess(
      CommunityVideoSecurityResource.key,
      CommunityVideoSecurityResourceAction.READ,
      {},
      true
    )
  );
  canReadCollections$ = from(
    this.securityService.hasAccess(
      VideoCollectionSecurityResource.key,
      VideoCollectionSecurityResourceAction.READ,
      {},
      true
    )
  );
  canReadVideoUnlockOptions$ = from(
    this.securityService.hasAccess(
      CommunityVideoUnlockOptionSecurityResource.key,
      CommunityVideoUnlockOptionSecurityResourceAction.READ,
      {},
      true
    )
  );

  canReadReports$ = from(
    this.securityService.hasAccess(ReportsSecurityResource.key, ReportsSecurityResourceAction.READ, {}, true)
  ).pipe(shareReplay(1));

  isStaff$ = combineLatest([
    this.canReadContacts$,
    this.canReadPurchases$,
    this.canReadSchedule$,
    this.canReadTransactions$,
    this.isResource$,
    this.canReadVideos$,
    this.canReadCollections$,
    this.canReadVideoUnlockOptions$,
    this.canReadReports$,
  ]).pipe(
    map(accessChecks => accessChecks.some(a => a)),
    shareReplay(1)
  );

  adminRoute$ = combineLatest([
    this.canReadContacts$,
    this.canReadPurchases$,
    this.canReadSchedule$,
    this.canReadTransactions$,
    this.isResource$,
    this.canReadSubscriptions$,
    this.canReadVideos$,
    this.canReadCollections$,
    this.canReadVideoUnlockOptions$,
    this.canReadReports$,
  ]).pipe(
    map(
      ([
        canReadContacts,
        canReadPurchases,
        canReadSchedule,
        canReadTransactions,
        isResource,
        canReadSubscriptions,
        canReadVideos,
        canReadCollections,
        canReadVideoUnlockOptions,
        canReadReports,
      ]) => {
        if (canReadContacts) return '/admin/community';
        if (canReadPurchases || canReadSubscriptions) return '/admin/sales';
        if (canReadSchedule) return '/admin/scheduling';
        if (canReadTransactions) return '/admin/accounting';
        if (isResource) return '/admin/resource';
        if (canReadVideos || canReadCollections || canReadVideoUnlockOptions) return '/admin/videos';
        if (canReadReports) return '/admin/reports';
        return '';
      }
    )
  );

  userLinks$: Observable<PlatformLink[]> = this.user$.pipe(
    map(user => this.appConfig.userLinks(user, this.openSignIn.bind(this)))
  );

  adminLinks$: Observable<PlatformLink[]> = combineLatest([
    this.canAccessWithOneOf(
      {
        group: '',
        label: 'Community & Staff',
        description: '',
        icon: 'groups',
        url: '/admin/community',
        color: 'accent',
      },
      [[ContactResource.key, ContactResourceAction.READ]]
    ),
    this.canAccessWithOneOf(
      {
        group: '',
        label: 'Sales & Services',
        description: '',
        icon: 'storefront',
        url: '/admin/sales',
        color: null,
      },
      [
        [PurchaseResource.key, PurchaseResourceAction.READ],
        [SubscriptionResource.key, SubscriptionResourceAction.READ],
      ]
    ),
    this.canAccessWithOneOf(
      {
        group: '',
        label: 'Booking & Scheduling',
        description: '',
        icon: 'event',
        url: '/admin/scheduling',
        color: 'primary',
      },
      [[EventSecurityResource.key, EventSecurityResourceAction.READ_PRIVATE]]
    ),
    this.canAccessWithOneOf(
      {
        group: '',
        label: 'Video Library',
        description: '',
        icon: 'video_settings',
        url: '/admin/videos',
        color: 'primary',
      },
      [
        [CommunityVideoSecurityResource.key, CommunityVideoSecurityResourceAction.READ],
        [VideoCollectionSecurityResource.key, VideoCollectionSecurityResourceAction.READ],
        [CommunityVideoUnlockOptionSecurityResource.key, CommunityVideoUnlockOptionSecurityResourceAction.READ],
      ]
    ),
    this.canAccessResourceScheduling({
      group: '',
      label: 'Resource Scheduling',
      description: '',
      icon: 'perm_contact_calendar',
      url: '/admin/resource',
      color: 'primary',
    }),
    this.canAccessWithOneOf(
      {
        group: '',
        label: 'Account Transactions',
        description: '',
        icon: 'payments',
        url: '/admin/accounting',
        color: 'warn',
      },
      [
        [TransactionResource.key, TransactionResourceAction.VIEW_FINANCIAL_REPORTS],
        [TransactionResource.key, TransactionResourceAction.READ],
      ]
    ),
    this.canAccessWithOneOf(
      {
        group: '',
        label: 'Custom Reports',
        description: '',
        icon: 'summarize',
        url: '/admin/reports',
        color: 'primary',
      },
      [[ReportsSecurityResource.key, ReportsSecurityResourceAction.READ]]
    ),

    of(
      this.appConfig.name !== 'Altea'
        ? {
            group: 'Miscellaneous',
            label: 'Staff Shop',
            description: '',
            icon: 'shopping_cart',
            url: '/admin/lf3-shop',
            color: null,
          }
        : null
    ),

    of(
      this.appConfig.name === 'Altea'
        ? {
            group: 'Miscellaneous',
            label: 'Marketing & CRM',
            description: '',
            icon: 'contact_mail',
            url: 'https://alteaactive.activehosted.com/',
            color: null,
          }
        : null
    ),

    this.canAccessWithOneOf(
      {
        group: 'Platform',
        label: 'Finance Accounts',
        description: '',
        icon: 'account_balance',
        url: '/admin/accounts',
        color: null,
      },
      [[AccountResource.key, AccountResourceAction.CREATE]] // Bogus resource, so that only super-admins are allowed
    ),
    this.canAccessWithOneOf(
      {
        group: 'Platform',
        label: 'Platform Administration',
        description: '',
        icon: 'settings',
        url: '/admin/platform',
        color: null,
      },
      [[AccountResource.key, AccountResourceAction.CREATE]] // Bogus resource, so that only super-admins are allowed
    ),
  ]).pipe(
    map(links => {
      return links.filter(l => l) as PlatformLink[];
    })
  );

  @ViewChild(PlatformLayout) platform!: PlatformLayout;

  adminView = false;
  hideNavigation = false;
  hideEndContent = false;
  hideBackButton = false;

  constructor(
    private securityService: SecurityService,
    private resourceSvc: ResourcesService,
    private route: ActivatedRoute,
    private userSvc: UserService,
    private legal: LegalService,
    private router: Router,
    private title: Title,
    private promotionsSvc: PromotionsService,
    private dialog: MatDialog,
    private accountSvc: AccountService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private eventService: EventService
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(evt => {
          this.adminView = (evt as NavigationEnd).url.startsWith('/admin');
        }),
        map(() => this.route),
        map(route => {
          const dataObs = [route.snapshot];
          while (route.firstChild) {
            route = route.firstChild;
            dataObs.push(route.snapshot);
          }
          return [route, dataObs] as [ActivatedRoute, ActivatedRouteSnapshot[]];
        }),
        filter(([route]) => route.outlet === 'primary')
      )
      .subscribe(([_, allRouteData]) => {
        this.hideNavigation = allRouteData.some(r => r.data.hideNavigation) ?? false;
        this.hideEndContent = allRouteData.some(r => r.data.hideEndContent) ?? false;
        this.hideBackButton = allRouteData.some(r => r.data.hideBackButton) ?? false;
        if (localStorage.getItem('drawer-open') === 'true' && !this.hideNavigation) this.platform.drawer.open();
        this.title.setTitle(
          allRouteData
            .map(d => d.routeConfig?.data?.title?.replace(/\$APP/g, this.appConfig.routeTitlePrefix || 'App'))
            .filter(t => t)
            .join(' | ')
        );
      });
  }

  async ngOnInit() {
    await Promise.all([
      this.promotionsSvc.showPromotions(),
      // toPromise(
      //   this.userSvc.user$.pipe(
      //     filter(u => !!u),
      //     switchMap(u =>
      //       this.eventService.getEvents(
      //         [new Date(), moment(new Date()).add(3, 'days').toDate()],
      //         [],
      //         [],
      //         [],
      //         u as any,
      //         true
      //       )
      //     )
      //   )
      // ),
      // toPromise(this.eventService.getCalendarTypes()),
    ]);
  }

  ngAfterViewInit() {
    this.platform.drawer.closedStart.subscribe(() => {
      if (!this.hideNavigation) localStorage.setItem('drawer-open', 'false');
    });
    this.platform.drawer.openedStart.subscribe(() => {
      if (!this.hideNavigation) localStorage.setItem('drawer-open', 'true');
    });
  }

  openQuickPurchase(accountId: string, communityId: string) {
    this.dialog.open(CreateQuickPurchaseDialog, { data: { accountId, communityId }, width: '750px', maxWidth: '90%' });
  }

  openSignIn() {
    console.log('sign-in!');
  }

  openTerms() {
    this.legal.openTerms();
  }

  openPrivacy() {
    this.legal.openPrivacy();
  }

  async canAccessWithOneOf(link: PlatformLink, permissions?: [resource: string, action: string][]) {
    const allowed = permissions
      ? await toPromise(
          combineLatest(
            permissions?.map(([r, a]) => {
              return this.securityService.hasAccess(r, a, {}, true);
            })
          ).pipe(map(accessChecks => accessChecks.some(a => a)))
        )
      : true;
    return allowed ? link : null;
  }

  async canAccessResourceScheduling(link: PlatformLink) {
    return (await this.isResource()) ? link : null;
  }

  async isResource() {
    const user = await toPromise(this.user$);
    if (!user) return false;

    if (user.isSuperAdmin) return true;

    const resourceGroup = await this.resourceSvc.getResourceGroupWithAccess(user.id);
    return !!resourceGroup?.length;
  }
}
