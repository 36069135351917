import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import {
  AccountLink,
  AccountLinkStatus,
  AccountLinkingSecurityResource,
  AccountLinkingSecurityResourceAction,
} from '@greco/account-linking';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LinkAccountDialog } from '../../dialogs';
import { AccountLinkingService } from '../../services';

@Component({
  selector: 'greco-linked-accounts-section',
  templateUrl: './linked-accounts-section.component.html',
  styleUrls: ['./linked-accounts-section.component.scss'],
})
export class LinkedAccountsSectionComponent {
  constructor(
    private userSvc: UserService,
    private matDialog: MatDialog,
    private linkingSvc: AccountLinkingService,
    private comSecuritySvc: CommunitySecurityService
  ) {}

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;

  @Input() disabled = false;
  @Input() expanded = true;
  @Input() communityId?: string;

  signedInUser$ = this.userSvc.user$;

  isUser$ = combineLatest([this.user$, this.signedInUser$]).pipe(
    switchMap(async ([user, signedInUser]) => {
      if (!user || !signedInUser) return false;
      return user.id === signedInUser.id;
    })
  );

  canRead$ = combineLatest([this.isUser$, this.signedInUser$]).pipe(
    switchMap(async ([isUser]) => {
      if (isUser) return true;
      else
        return (
          await this.comSecuritySvc.communitiesWithAccess(
            AccountLinkingSecurityResource.key,
            AccountLinkingSecurityResourceAction.READ
          )
        )?.length > 0
          ? true
          : false;
    })
  );

  canCreate$ = combineLatest([this.isUser$, this.signedInUser$]).pipe(
    switchMap(async ([isUser]) => {
      if (isUser) return true;
      else {
        const canCreateLink = await this.comSecuritySvc.communitiesWithAccess(
          AccountLinkingSecurityResource.key,
          AccountLinkingSecurityResourceAction.CREATE_LINK
        );

        const canRequestLink = await this.comSecuritySvc.communitiesWithAccess(
          AccountLinkingSecurityResource.key,
          AccountLinkingSecurityResourceAction.REQUEST
        );

        const canCreateChild = await this.comSecuritySvc.communitiesWithAccess(
          AccountLinkingSecurityResource.key,
          AccountLinkingSecurityResourceAction.CREATE_CHILD
        );

        return canCreateLink?.length || canRequestLink?.length || canCreateChild?.length;
      }
    })
  );

  refresh$ = new BehaviorSubject(null);
  loading = false;

  readonly grantedLinks$ = combineLatest([this.user$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([user]) => (user ? await this.linkingSvc.getGivenLinksByAccount(user.id) : [])),
    tap(() => (this.loading = false))
  );

  readonly ownedLinks$ = combineLatest([this.user$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([user]) => (user ? await this.linkingSvc.getPrivilegeLinksForAccessor(user.id) : [])),
    tap(() => (this.loading = false))
  );

  readonly pendingOwned$: Observable<AccountLink[]> = this.ownedLinks$.pipe(
    switchMap(async links => links.filter(link => link.status === AccountLinkStatus.PENDING))
  );
  readonly pendingRequests$: Observable<AccountLink[]> = this.grantedLinks$.pipe(
    switchMap(async links => links.filter(link => link.status === AccountLinkStatus.PENDING))
  );

  async newLink() {
    const dialog = this.matDialog.open(LinkAccountDialog, {
      data: { accessor: this.user },
      width: '600px',
      maxWidth: '90%',
    });
    await toPromise(dialog.afterClosed());
    this.refresh();
  }

  refresh() {
    this.refresh$.next(null);
  }
}
