import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Event, EventAgreementUsage, EventSeries, EventType } from '@greco/booking-events';
import { AgreementUsageByEventDto } from '@greco/nestjs-booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}

@Injectable({
  providedIn: 'root',
})
export class EventCommunityAgreementsUsageService {
  constructor(private http: HttpClient) {}

  async paginateEventAgreementUsages(
    eventId: string,
    pagination: IPaginationOptions,
    queryBuilder: RequestQueryBuilder
  ): Promise<PaginatedDto<EventAgreementUsage>> {
    const response = await toPromise(
      this.http.get<PaginatedDto<EventAgreementUsage>>(`/api/event_agreement_usage/paginate`, {
        params: {
          eventId,
          ...paginationQueryParams(pagination),
          ...queryBuilder.queryObject,
        },
      })
    );
    return response;
  }

  async linkAgreementToEvent(event: Event | EventSeries, agreementId: string) {
    const communityId = event.community.id;
    const eventId = `${event.id.split('_')[0]}_${event.id.split('_')[1]}`;
    let url = `/admin/scheduling/${communityId}/${eventId}`;
    if (event.type === EventType.EVENT_SERIES || event.type === EventType.EVENT_SERIES_INSTANCE) {
      url = `/admin/scheduling/${communityId}/series/${eventId}`;
    }
    return toPromise(
      this.http.post<AgreementUsageByEventDto>(`/api/event_agreement_usage`, {
        eventId: eventId,
        agreementId,
        url: url,
        label: event.title,
        type: 'event',
      })
    );
  }

  async deleteEventAgreementUsage(usageId: string) {
    return toPromise(this.http.delete<any | null>(`/api/event_agreement_usage/${usageId}`));
  }

  //SERIES

  // async paginateSeriesAgreementUsages(
  //   eventId: string,
  //   pagination: IPaginationOptions,
  //   queryBuilder: RequestQueryBuilder
  // ): Promise<PaginatedDto<SeriesAgreementUsage>> {
  //   return await toPromise(
  //     this.http.get<PaginatedDto<SeriesAgreementUsage>>(`/api/event_agreement_usage/paginate/series`, {
  //       params: {
  //         eventId,
  //         ...paginationQueryParams(pagination),
  //         ...queryBuilder.queryObject,
  //       },
  //     })
  //   );
  // }

  // async linkAgreementToSeries(series: EventSeries, agreementId: string) {
  //   const communityId = series.community.id;
  //   const seriesId = series.id;
  //   return toPromise(
  //     this.http.post<AgreementUsageBySeriesDto>(`/api/event_agreement_usage/series`, {
  //       seriesId: seriesId,
  //       agreementId,
  //       url: `/admin/scheduling/${communityId}/series/${seriesId}`,
  //       label: series.title,
  //       type: 'series',
  //       seriesData: series,
  //     })
  //   );
  // }

  // async deleteSeriesAgreementUsage(usageId: string) {
  //   return toPromise(this.http.delete<any | null>(`/api/event_agreement_usage/series/${usageId}`));
  // }
}
