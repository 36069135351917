<h1 style="text-align: center">LF3 MEMBERSHIP/TRAINING AGREEMENT</h1>
<p>
  You understand the membership you have purchased includes benefits (perks) outlined below at your home
  studio/community and/or the online studio. By booking these services you understand and agree to abide by all policies
  outlined below.
</p>
<p>&nbsp;</p>
<a></a><a></a>
<table style="width: 100%; border-spacing: 0; border-collapse: collapse; margin-right: auto; margin-left: auto">
  <tbody>
    <tr style="height: 0pt">
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 153pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>Membership</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 75pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>Legacy On Demand</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 82.5pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>Hybrid All-Access</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 84pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>Unlimited LIVE</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 69.8pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>TRAIN FOR LIFE 6</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 76.5pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>TRAIN FOR LIFE 5</p>
      </td>
    </tr>
    <tr style="height: 22pt">
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 153pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>Price</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 75pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">$29.96</p>
        <p style="text-align: center">/ 4 weeks</p>
      </td>
      <td
        colspan="4"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 312.8pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">$99.96 / 4 weeks</p>
      </td>
    </tr>
    <tr style="height: 0pt">
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 153pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p># In-Studio Workouts</p>
        <p>(at home location only)</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 75pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">none</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 82.5pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">UNLIMITED</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 84pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">UNLIMITED</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 69.8pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>6/ week</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 76.5pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>5/ week</p>
      </td>
    </tr>
    <tr style="height: 22pt">
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 153pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p># Online LIVE Workouts</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 75pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">none</p>
      </td>
      <td
        colspan="4"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 312.8pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">UNLIMITED</p>
      </td>
    </tr>
    <tr style="height: 22pt">
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 153pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>Booking Window</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 75pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">n/a</p>
      </td>
      <td
        colspan="4"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 312.8pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">3 Day In-Studio | 7-Day Online</p>
      </td>
    </tr>
    <tr style="height: 22pt">
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 153pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>LIVE Replays Access/ LF3 On Demand Access</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 75pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">none</p>
      </td>
      <td
        colspan="4"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 312.8pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">YES</p>
      </td>
    </tr>
    <tr style="height: 22pt">
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 153pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>Legacy On Demand Library Access</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 75pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">Yes</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 82.5pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">YES</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 84pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>YES</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 69.8pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>NO</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 76.5pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>NO</p>
      </td>
    </tr>
    <tr style="height: 22pt">
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 153pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p>Evolt 360 Scan</p>
      </td>
      <td
        colspan="1"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 75pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">none</p>
      </td>
      <td
        colspan="4"
        rowspan="1"
        style="
          border-right-style: solid;
          padding: 5pt 5pt 5pt 5pt;
          border-bottom-color: #000000;
          border-top-width: 1pt;
          border-right-width: 1pt;
          border-left-color: #000000;
          vertical-align: top;
          border-right-color: #000000;
          border-left-width: 1pt;
          border-top-style: solid;
          border-left-style: solid;
          border-bottom-width: 1pt;
          width: 312.8pt;
          border-top-color: #000000;
          border-bottom-style: solid;
        "
      >
        <p style="text-align: center">1 SCAN</p>
      </td>
    </tr>
  </tbody>
</table>

<p>&nbsp;</p>
<p>
  MEMBERSHIP UPGRADES:<span style="font-weight: 400"
    >&nbsp;You can upgrade your membership at any time through the platform. All upgrades are immediate and are prorated
    so that any used time you had remaining on your previous subscription will be applied to your new subscription. You
    will just need to pay the difference and your subscription will be upgraded immediately.</span
  >
</p>
<p>
  AUTO-RENEWAL:
  <span style="font-weight: 400"
    >Upon expiry of this Agreement the Membership shall auto-renew under the same terms, conditions, and payment
    frequency you agreed to. The total payment may be increased according to membership fees at the time of renewal, at
    the full discretion of LF3. This contract shall continue to auto-renew until cancelled, in compliance with our
    Cancellation Policy below.</span
  >
</p>
<p>
  <strong>SUBSCRIPTION CANCELLATION POLICY:</strong>&nbsp;Members have the right to cancel any contract/membership and
  receive a refund within 10 days as per the Consumer Protection Act. Any LF3 Method Classes (Online &amp; In-Studio),
  Personal Training, Small Group, Partner Training, and other services completed will be charged at the current single
  session/single credit/drop-in rate. The remaining balance will be refunded. Following this 10-day period, the member
  is fully liable and responsible for payment of the Membership with or without the completion of the services allowed.
  In order to cancel your membership, you must submit a cancellation request through our cancellation form here:
  <a
    href="https://www.google.com/url?q=https://bit.ly/3cdle8L&amp;sa=D&amp;source=editors&amp;ust=1632764214020000&amp;usg=AOvVaw1mzNdg2vZIKVPQN0U_rE_t"
    target="_blank"
    >https://bit.ly/3cdle8L</a
  >. Cancellation requests will not be processed unless the cancellation form is submitted.
</p>

<ul>
  <li>
    <strong>LEGACY ON DEMAND MEMBERSHIP</strong>&nbsp;has a 28-day cancellation policy. You can cancel your membership
    at any time by submitting your request here:
    <a
      href="https://www.google.com/url?q=https://bit.ly/3cdle8L&amp;sa=D&amp;source=editors&amp;ust=1632764214020000&amp;usg=AOvVaw1mzNdg2vZIKVPQN0U_rE_t"
      target="_blank"
      >https://bit.ly/3cdle8L</a
    >. &nbsp;Any payments due within 28 days of receipt of your cancellation request will be charged as scheduled, but
    no further payments will be processed as long as we receive your cancellation request by submitting the above form.
    You have 28 days from the effective date of your cancellation to access the remainder of your online services. Paid
    in full memberships are non-refundable and automatically renew upon the expiration date unless your completed
    cancellation form is received 28 days in advance of your expiration date.
  </li>
  <li>
    <strong>ALL OTHER MEMBERSHIPS</strong>&nbsp;has a minimum commitment of 12 weeks. After the first 12 weeks, your
    membership will continue on the payment frequency you selected. Any payments due within 28 days of receipt of your
    cancellation request will be charged as scheduled, but no further payments will be processed as long as your home
    studio receives your submitted cancellation request by filling out the form here:
    <a
      href="https://www.google.com/url?q=https://bit.ly/3cdle8L&amp;sa=D&amp;source=editors&amp;ust=1632764214021000&amp;usg=AOvVaw2OPZuJ-4gggk_9j10TwQQI"
      target="_blank"
      >https://bit.ly/3cdle8L</a
    >. You have 28 days from the effective date of your cancellation to access the remainder of your in-studio and
    online services.
  </li>
</ul>

<p>
  SUBSCRIPTION DOWNGRADES:<span style="font-weight: 400"
    >&nbsp;You can downgrade your membership at any time through the platform or by requesting a downgrade from your
    home studio once your minimum commitment period has been fulfilled (as outlined in our cancellation policy above).
    The downgrade will take place at the end of your current billing cycle. You will retain access to your current
    membership until the end of the billing cycle.
  </span>
</p>
<p>
  CLASS CREDIT PACKAGE/ CLASS PACKAGE REDEMPTION:<span style="font-weight: 400"
    >&nbsp;Your class credits/ packages can be applied for access to LF3 classes with a 3 day (72 hour) in advance
    booking window. Your class credits/ packages do not expire and can be shared with family and friends. Your class
    credits/ packages are nonrefundable. Late cancellation and No Show Fees apply to every booking. Please note that
    these credits are purchased with a particular home studio and are non-transferable between studios/communities. You
    agree to be fully liable and responsible for payment of the services with or without the completion of the service
    allowed.</span
  >
</p>
<p>
  PERSONAL/PARTNER/SMALL GROUP TRAINING SESSIONS/CREDITS REDEMPTION<span style="font-weight: 400"
    >: These credits/sessions can be purchased upfront or by payment plan. By purchasing these sessions you agree to the
    payment terms and understand that if you purchase sessions in installments, you are responsible for every payment
    agreed to under this training option. These credits are non-refundable after purchase and cannot be shared due to
    the personalized nature of the service. Please note that these credits are purchased with a particular home studio,
    not a particular Trainer. Please note that these Sessions/Credits can not be transferred to other studios or
    redeemed for monetary value after purchase. You understand that you are fully liable and responsible for payment of
    the services with or without the completion of the service allowed.</span
  >
</p>
<p>
  LATE CANCELLATION - CLASS POLICY:<span style="font-weight: 400"
    >&nbsp;Please note that due to the limited availability of spots in each class we require a minimum of 12 hours
    notice prior to the class start time to cancel any class booking. If you fail to cancel within 12 hours or do not
    show for a class, you will be charged a $25 late cancellation fee. We are preparing your station exclusively based
    on the questions you answered while scheduling your booking, so please be respectful by showing up to all classes
    you register for or early cancelling your attendance at least 12 hours in advance.</span
  >
</p>
<p>
  LATE CANCELLATION- PERSONAL/PARTNER/SMALL GROUP TRAINING SESSIONS/CREDITS POLICY:<span style="font-weight: 400"
    >&nbsp;All cancellations must be received at least 24 hours in advance or you will forfeit your session.</span
  >
</p>
<p>
  NO SHOW - CLASS POLICY:<span style="font-weight: 400"
    >&nbsp;If you do not show to a class that you are scheduled for, a $25 No Show Fee will apply if you used a
    membership to book the class. If you do not show to a class that you are scheduled for using a Class credit, you
    will lose the class credit. By scheduling a class you agree to this.</span
  >
</p>
<p>
  NO SHOW - PERSONAL/PARTNER/SMALL GROUP TRAINING POLICY:<span style="font-weight: 400"
    >&nbsp;If you do not show for any session, you will forfeit your session. By scheduling a session you agree to
    this.</span
  >
</p>
<p>
  <strong>MEMBERSHIP FREEZES/HOLDS: </strong>Due to the flexible nature of our memberships - you can train with them
  anywhere - there are no freezes on memberships except in the case of an injury or other medical condition with a valid
  doctor's note. Please submit your medical hold request here:
  <a
    href="https://www.google.com/url?q=https://bit.ly/3cdle8L&amp;sa=D&amp;source=editors&amp;ust=1632764214022000&amp;usg=AOvVaw38UpkhLXAZMg_IxBSgtZVo"
    target="_blank"
    >https://bit.ly/3cdle8L</a
  >.
</p>
<p>
  YOUR RESPONSIBILITIES:<span style="font-weight: 400"
    >&nbsp;By purchasing this Membership/Service you agree to a) pay us all Fees when due, irrespective of your use of
    the training sessions; b) all of the terms and conditions which are set out in this Agreement; c) follow our rules
    and regulations (“Rules”) which may be posted at the Facility from time to time; and d) immediately notify us of any
    change of address or of any other changes to the personal information provided herein.</span
  >
</p>
<p>
  TRAINING SESSIONS:<span style="font-weight: 400"
    >&nbsp;Each training session will include a combination of sustained cardiovascular activity and strength training.
    Training sessions shall be classes in which a group of clients shall train simultaneously. Personal training may be
    private one on one sessions or in a small group. The following terms and conditions apply to your training:
  </span>
</p>

<ul>
  <li>
    “Training Program” means all aspects of your Monthly Program or Annual Program as designed and communicated to you
    by LF3 including nutrition, resistance training and sustained cardiovascular training.
  </li>
  <li>Your Training Program shall commence on the date this Agreement is signed.</li>
  <li>
    Arrive five minutes prior to each scheduled session. &nbsp;If you arrive late for a session, the session will still
    finish at the originally scheduled time.
  </li>
  <li>All sessions shall be 45 minutes in length unless otherwise agreed or specified on the class schedule.</li>
  <li>
    If you feel dizzy, lightheaded, or nauseous or if you experience pain or discomfort at any time during your
    training, you agree to immediately stop the activity in which you are participating and inform your personal trainer
    of your condition.
  </li>
  <li>
    If during the course of your training, you experience any change to your health or physical condition which might
    affect your ability to exercise safely and with minimal risk of injury, you agree to immediately notify your
    personal trainer of such changes.
  </li>
  <li>
    You are not obliged to perform or participate in any activity unless you wish to do so and you have the right, at
    all times, to decline participation in a training session.
  </li>
  <li>
    LF3 may cancel your training at any time if, in the opinion of the personal trainer, there is a lack of commitment
    to the training or a failure to comply with the terms of this Agreement. &nbsp;In the event that this occurs, LF3
    will refund the remainder of any unused sessions.
  </li>
</ul>

<p>
  PRE-AUTHORIZED PAYMENT AGREEMENT:<span style="font-weight: 400"
    >&nbsp;You hereby authorize and direct LF3 to present transactions for payment against your bank or credit card
    account. &nbsp;In consideration of LF3 acting as directed, you agree that LF3’s treatment of each payment and its
    rights to it shall be the same as if it were personally signed by you. The pre-authorized payment shall be drawn on
    your account to cover all fees, including Monthly Fees, account balances, and default charges, as well as any other
    amounts to which LF3 is entitled under this Agreement.
  </span>
</p>
<p>
  PAYMENT OBLIGATIONS ABSOLUTE:<span style="font-weight: 400"
    >&nbsp;You are obliged to pay us all fees owing under this Agreement throughout the duration of your Training
    Program. We will not reduce, discount, or cancel your obligations because you do not use the facility. You may
    terminate this Agreement by abiding by our cancellation policy outlined above and by paying any and all fees
    incurred, including but not limited to late fees, return fees, collection fees, etc.</span
  >
</p>
<p>
  DEFAULT:<span style="font-weight: 400"
    >&nbsp;If you breach any term or condition of this Agreement, or if you do not pay an installment on a payment date,
    you will be in default. Your right to receive sessions will be suspended immediately and you will be subject to a
    service charge in the amount of $25.00. &nbsp;We may then, if we choose, also immediately cancel your training
    sessions and keep any amounts you have paid to us. &nbsp;If we later agree to accept payment from you, you must
    still fulfill all your remaining responsibilities under this Agreement and we may require you to pay any legal
    and/or collection fees and charges incurred by LF3 in collecting your overdue payments from you.</span
  >
</p>
<p>
  PLATFORM ACCESS:<span style="font-weight: 400"
    >&nbsp;We will issue you an account on our LF3 Fit Platform which you will need to book and buy services. The
    account is for your personal use only. If someone else uses the account we may cancel your membership immediately
    with no refund.
  </span>
</p>
<p>
  RULES AND REGULATIONS:<span style="font-weight: 400"
    >&nbsp;Our rules may be posted in the facility. They may change from time to time. The rules are for your benefit
    and protection and must be complied with by all members. We reserve the right to cancel or suspend your membership,
    without refund, if you or your guest: a) fail to follow our rules, b) cause a nuisance or disturbance, c) commit any
    illegal or immoral acts, or d) if we feel that your actions may endanger yourself or others. &nbsp;If your
    membership is suspended, your obligation to make payments under this Agreement continues as scheduled. We do not
    allow any business activity or solicitation at the Facility. You agree to pay us all revenues received by you or
    your guest if you violate this policy (including our legal fees and court costs).
  </span>
</p>
<p>
  RESERVATION OF RIGHTS:<span style="font-weight: 400"
    >&nbsp;We reserve the right to cancel any membership without cause, in which event you will be entitled to a refund
    of unused prepaid installments, if any. We reserve the right to change our hours of operation at any time and to
    change the cost of, add, modify and/or eliminate any program, equipment, activity, or class of service. We will use
    our reasonable best efforts to maintain the existing services and facilities at the Facility as of the date of this
    Agreement. Classes and equipment are available based on sufficient demand. If the facility is unavailable for use
    temporarily, you hereby agree to use an alternate facility and/or services provided by LF3 during this period. In
    the event that it is necessary to use another facility, LF3 will provide you with at least one week’s advance
    written notice of the dates upon which the alternate arrangements will start and end, the location of the alternate
    facility, and the services to be made available to you there.</span
  >
</p>
<p>
  ENTIRE AGREEMENT/AMENDMENT:<span style="font-weight: 400"
    >&nbsp;This Agreement, as to its subject matter, exclusively and completely states the rights, duties, and
    obligations of the parties and supersedes all prior and contemporaneous representations, letters, proposals,
    discussions, and understandings by or between the parties. This Agreement may only be amended in a writing signed by
    both parties.
  </span>
</p>
<p>
  ASSIGNMENT:<span style="font-weight: 400"
    >&nbsp;This Agreement is personal to you. &nbsp;You may not assign, sell or transfer this Agreement or your rights
    under it to anyone else without the written approval of management, and any such attempted sale, assignment, or
    transfer will be ineffective and will result in the immediate cancellation of this Agreement without refund. A $50
    fee will apply if this Agreement is transferred. &nbsp;</span
  >
</p>
<p>
  ASSIGNMENT BY LF3:
  <span style="font-weight: 400"
    >We may assign this Agreement to another company or person at our discretion, and the term “LF3” includes any
    assignee, who will have all our rights and powers under this Agreement. &nbsp;If any claims are brought against us
    under this Agreement, after we have assigned it, we reserve the right to raise any defenses available under this
    Agreement. Your entitlement to damages or recovery in any claims brought under this Agreement shall not exceed
    amounts paid by you under this Agreement.
  </span>
</p>
<p>
  INDEPENDENT CONTRACTORS:<span style="font-weight: 400"
    >&nbsp;The services of independent contractors (such as chiropractors, physiotherapists, etc,) may be available at
    the Facility. These services are not offered by LF3 under nor do they form part of this Agreement. You must make a
    separate agreement with an independent contractor if you wish to purchase his/her services.</span
  >
</p>
<p>
  LIMITATION OF LIABILITY:<span style="font-weight: 400"
    >&nbsp;LF3’s liability for damages under this Agreement, whether arising in contract (including fundamental breach
    or under an indemnification obligation), tort (including negligence), or otherwise, shall not exceed the amount paid
    by you to LF3 in the previous twelve (12) months from the date of the events giving rise to such damages, even if
    LF3 has been advised of the possibility of such damages. In no event shall LF3 (including its contractors,
    directors, employees, and agents) be liable for any indirect, incidental, or consequential damages of any kind,
    including without limitation, damages arising from lost business, lost savings, lost data, lost profits, medical
    malpractice, bodily injury or death and regardless of the cause and whether arising in contract (including
    fundamental breach), tort (including negligence), or otherwise, even if LF3 has been advised of the possibility of
    such damages and, by signing this Agreement, you hereby indemnify the licensor from any and all such
    liability.</span
  >
</p>
<p>
  WAIVER/SEVERABILITY:<span style="font-weight: 400"
    >&nbsp; Any waiver, in whole or in part, of any provision of this Agreement, will not be considered to be a waiver
    of any other provision. &nbsp;If any term of this Agreement is found to be unenforceable or invalid for any reason,
    all other terms will remain in full force and effect.</span
  >
</p>
<p>
  CONSUMER PROTECTION ACT:
  <span style="font-weight: 400"
    >Members have the right to cancel any contract/membership/subscription and receive a refund within 10 days as per
    the CPA below. Any LF3 Method Classes, Personal Training, Small Group, Partner Training, and other services
    completed will be charged at the current single session/drop-in rate. The remaining balance will be refunded.
    Following this 10-day period, the member is fully liable and responsible for payment of the Membership with or
    without the completion of the service allowed.</span
  >
</p>
<p>
  Your Rights under the Consumer Protection Act, 2002:
  <span style="font-weight: 400"
    >You may cancel this agreement at any time during the period that ends ten (10) days after the later of the day you
    receive a written copy of the agreement and the day all the services are available. &nbsp;You do not need to give
    the supplier a reason for canceling during this 10-day period. In addition, there are grounds that allow you to
    cancel this agreement. &nbsp;You may also have other rights, duties, and remedies at law. &nbsp;For more
    information, you may contact the Ministry of Consumer and Business Services. To cancel this agreement, you must give
    notice of cancellation to the supplier, at the address set out in the agreement, by any means that allows you to
    prove the date on which you gave notice. &nbsp;If no address is set out in the agreement, use any address of the
    supplier that is on record with the Government of Ontario or the Government of Canada or is known by you. If you
    cancel this agreement, the supplier has fifteen (15) days to refund any payment you have made and return to you all
    goods delivered under a trade-in arrangement (or refund an amount equal to the trade-in allowance).</span
  >
</p>
