<greco-simple-dialog [data]="_dialogData">
  <form [formGroup]="_form">
    <mat-form-field appearance="standard">
      <mat-label>Name</mat-label>
      <input matInput required placeholder="Room name" formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Description" formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field floatLabel="always">
      <mat-label>Icon</mat-label>
      <greco-icon-picker placeholder="Pick an icon" formControlName="icon"></greco-icon-picker>
    </mat-form-field>

    <!-- Room Images -->
    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
      <mat-label>Image</mat-label>
      <greco-image-upload (click)="_form.markAsDirty()" formControlName="roomImage"></greco-image-upload>
    </mat-form-field>

    <div class="spots">
      <div style="margin-bottom: 4px">
        <div style="display: flex">
          <mat-icon style="margin: 6px 8px -2px 0px">chair</mat-icon>
          <h3>Spots ({{_form.value.spots.length || 1}})</h3>
        </div>
        <p>Add "spots" to your room for users to select when they book an event.</p>
      </div>

      <greco-room-spots [fromCreate]="true" formControlName="spots"></greco-room-spots>
    </div>
  </form>

  <div class="buttons">
    <button mat-stroked-button type="button" (click)="close()">Cancel</button>
    <button
      mat-stroked-button
      type="button"
      color="primary"
      [disabled]="_form.invalid || submitting || allSpotsHaveNames()"
      (click)="submit()"
    >
      <mat-icon *ngIf="submitting" class="spin">loop</mat-icon>Confirm
    </button>
  </div>
</greco-simple-dialog>
