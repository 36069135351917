import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { SelectFilter } from '@greco/ngx-filters';
import { SubscriptionStatus } from '@greco/sales-subscriptions';

@Injectable({ providedIn: 'any' })
export class SubscriptionStatusFilter extends SelectFilter {
  private titleCase = new TitleCasePipe();

  constructor() {
    super('SubscriptionStatusFilter', {
      label: 'Subscription Status',
      properties: ['status'],
      shortLabel: 'Status',
      description: '',
    });
  }

  getValueLabel(value: SubscriptionStatus | SubscriptionStatus[]): string {
    return Array.isArray(value)
      ? value.map(status => this.titleCase.transform(status)).join(', ')
      : this.titleCase.transform(value);
  }

  getValueOptions(search?: string): SubscriptionStatus[] {
    const statuses = Object.values(SubscriptionStatus);
    return search ? statuses.filter(status => status.toLowerCase().includes(search.toLowerCase())) : statuses;
  }

  serializeValue(value: SubscriptionStatus | SubscriptionStatus[]): string {
    return Array.isArray(value) ? value.map(status => status).join(',') : value;
  }

  deserializeValue(serializedValue: string): SubscriptionStatus[] {
    const keys = Object.keys(SubscriptionStatus);
    return serializedValue.split(',').filter(status => keys.includes(status)) as SubscriptionStatus[];
  }
}
