import { BaseDto } from '@greco/util-dto';
import { Expose } from 'class-transformer';
import { IsArray, IsOptional, IsString } from 'class-validator';

export class UpdateVideoDto extends BaseDto {
  @Expose()
  @IsString()
  @IsOptional()
  title?: string;

  @Expose()
  @IsString()
  @IsOptional()
  description?: string;

  @Expose()
  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  videoTagIds?: string[];
}
