<greco-alert-card
  [clickable]="true"
  [small]="small"
  (click)="openPurchaseSummary(OutstandingPurchase)"
  color="error"
  title="Outstanding Payment"
  [subtitle]="
    (OutstandingPurchase.total / 100 | currency) +
    ' ' +
    (OutstandingPurchase.items | pluck : 'displayName').join(', ') +
    ' (' +
    (OutstandingPurchase.created | date : 'MMM d, y, h:mm a') +
    ')'
  "
></greco-alert-card>
