import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { LetModule } from '@greco/ngx-let-util';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { IconPickerModule } from '@greco/ui-icon-picker';
import { ImageUploadModule } from '@greco/ui-image-upload';
import { TableModule } from '@greco/ui-table';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { TileDetailsComponent } from './components/tile-details/tile-details.component';
import { TileComponent } from './components/tile/tile.component';
import { TilesGridComponent } from './components/tiles-grid/tiles-grid.component';
import { TilesInputComponent } from './components/tiles-input/tiles-input.component';
import { TilesTableComponent } from './components/tiles-table/tiles-table.component';
import { CreateTileComponent } from './dialogs/create-tile/create-tile.dialog';
import { ManageTileGroupDialogComponent } from './dialogs/manage-tile-groups/manage-tile-group.dialog';
import { TileDetailsPage } from './pages/tile-details/tile-details.page';
import { TilesViewPage } from './pages/tiles-view/tiles-view.page';
import { TilesPage } from './pages/tiles/tiles.page';

@NgModule({
  imports: [
    LetModule,
    RouterModule,
    ImageUploadModule,
    MatSnackBarModule,
    UserAvatarModule,
    ReactiveFormsModule,
    MatInputModule,
    FormSaveButtonsModule,
    CommonModule,
    TableModule,
    MatIconModule,
    MatTooltipModule,
    FiltersModule,
    MatButtonModule,
    MatGridListModule,
    MatCardModule,
    SimpleDialogModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatPaginatorModule,
    MatChipsModule,
    MatMenuModule,
    MatAutocompleteModule,
    CollapsibleSectionModule,
    IconPickerModule,
  ],

  declarations: [
    TilesPage,
    TileDetailsPage,
    TileDetailsComponent,
    TilesInputComponent,
    TilesTableComponent,
    CreateTileComponent,
    TilesViewPage,
    TileComponent,
    TilesGridComponent,
    ManageTileGroupDialogComponent,
  ],
  exports: [TilesPage, TileDetailsPage, TilesViewPage],
})
export class TilesModule {}
