<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field appearance="standard">
      <mat-label>Destination Account</mat-label>
      <greco-account-search-component
        formControlName="destination"
        [excluded]="[account]"
      ></greco-account-search-component>
    </mat-form-field>

    <mat-form-field apperance="standard">
      <mat-label>Percentage</mat-label>
      <input matInput type="number" formControlName="percentage" />
      <span matSuffix>%</span>
    </mat-form-field>

    <!-- Buttons -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button mat-stroked-button type="submit" color="primary" [disabled]="form.invalid || processing" style="flex: 1">
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="spin">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
