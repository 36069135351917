import { Injectable } from '@angular/core';
import { BookingOption } from '@greco/booking-events';
import { AutocompleteFilter } from '@greco/ngx-filters';
import { CondOperator, RequestQueryBuilder, SFields } from '@nestjsx/crud-request';
import { BookingOptionService } from '../../../services';

@Injectable({ providedIn: 'any' })
export class BookingOptionFilter extends AutocompleteFilter {
  constructor(private bookingOptionSvc: BookingOptionService) {
    super('BookingOptionFilter', {
      properties: ['bookingOption.id'],
      label: 'Booking Option',
      shortLabel: 'Option',
      description: '',
      canOpen: true,
    });
  }

  public communityId?: string;

  getImageUrl(_value: BookingOption) {
    return null;
  }

  getAutocompleteHtml(value: BookingOption): string {
    return value.title;
  }

  getValueLabel(value: BookingOption): string {
    return value?.title;
  }

  async getValueOptions(search?: string): Promise<BookingOption[]> {
    if (!this.communityId) return [];

    const options = await this.bookingOptionSvc.paginate(
      new RequestQueryBuilder().search({
        $and: [
          {
            $or: [
              {
                title: { $contL: search },
              },
              {
                shortTitle: { $contL: search },
              },
            ],
          },
        ],
      }),
      this.communityId,
      { limit: 5 }
    );

    return options.items;
  }

  serializeValue(value: BookingOption): string {
    return value.id;
  }

  async deserializeValue(serializedValue: string) {
    return await this.bookingOptionSvc.getOne(serializedValue);
  }

  getPropertySField(property: string, operator: CondOperator, value: BookingOption): SFields {
    return { [property]: { [operator]: value.id } };
  }
}
