import { Component, Input } from '@angular/core';

@Component({
  selector: 'greco-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  // @Input() signInTitle?: string;
  // @Input() subtitle?: string;
  // @Input() description?: string;
  // @Input() testimonialTitle?: string;
}
