<greco-shop-filters
  (communitiesChanged)="communitiesChanged($event)"
  [userId]="user?.id"
  [communities]="[]"
  [collections]="[]"
></greco-shop-filters>

<ng-container *grecoLet="userConditionContext$ | async as conditionsContext">
  <greco-product-grid
    [filters]="filters"
    [userId]="user?.id"
    [userConditionContext]="conditionsContext || undefined"
    [linkedAccounts]="(linkedAccounts$ | async) || []"
  ></greco-product-grid>
</ng-container>
