<ng-container *grecoLet="canUpdate$ | async as canUpdate">
  <greco-collapsible-section #details [header]="{ title: 'Details', icon: 'info' }">
    <greco-form-save-buttons
      header
      *ngIf="details.expanded && canUpdate"
      [form]="detailsForm"
      [saveAction]="saveDetails"
      [resetValue]="resetValue"
      (formReset)="resetDetails()"
    ></greco-form-save-buttons>

    <form [formGroup]="detailsForm">
      <greco-calendar-details-input
        [communityId]="calendar?.community?.id || ''"
        [calendar]="calendar"
        [readonly]="!canUpdate"
        [initialUrls]="calendar?.imageUrl ? [$any(calendar?.imageUrl)] : []"
        [required]="false"
        formControlName="calendarDetails"
      ></greco-calendar-details-input>

      <!-- Staff Picker -->
      <mat-form-field appearance="standard">
        <mat-label>Grant Access</mat-label>
        <mat-chip-list #chipList aria-label="Staff selection" [disabled]="!canUpdate">
          <mat-chip *ngFor="let staff of staffAssignments" (removed)="removeStaff(staff)">
            <div style="width: 24px; height: 24px; margin-right: 8px; margin-left: -4px">
              <greco-user-avatar
                [showOnlineStatus]="false"
                [user]="staff"
                style="width: 24px; height: 24px"
              ></greco-user-avatar>
            </div>
            <ng-container *ngIf="staff.displayName; else noDisplayName">
              {{ staff.displayName }}
            </ng-container>
            <ng-template #noDisplayName>{{ staff.email }}</ng-template>
            <button mat-icon-button matChipRemove style="margin-top: -18px">
              <mat-icon style="width: 18px; height: 18px; line-height: 18px; font-size: 18px">cancel</mat-icon>
            </button>
          </mat-chip>
          <input
            [placeholder]="canUpdate ? 'Find user to add..' : ''"
            #staffInput
            [readonly]="!canUpdate"
            [formControl]="staffCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedStaff($event)">
          <mat-option *ngFor="let user of filteredStaff | async" [value]="user">
            <div style="display: flex; align-items: center">
              <div style="width: 24px; height: 24px; margin-right: 8px">
                <greco-user-avatar
                  [showOnlineStatus]="false"
                  [user]="user"
                  style="width: 24px; height: 24px"
                ></greco-user-avatar>
              </div>
              <div>
                <ng-container *ngIf="user.displayName; else noDisplayName">
                  {{ user.displayName }} <small>({{ user.email }})</small>
                </ng-container>
                <ng-template #noDisplayName>{{ user.email }}</ng-template>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
        <mat-hint>
          Access granted here makes this calendar available to the person according to their role assignments.
        </mat-hint>
      </mat-form-field>

      <!-- Managers -->
      <mat-form-field appearance="standard">
        <mat-label>Manager Emails</mat-label>
        <mat-chip-list #chipListManager aria-label="Calendar Managers" [disabled]="!canUpdate">
          <mat-chip *ngFor="let email of managerEmails" (removed)="removeManager(email)">
            <ng-container *ngIf="email">{{ email }}</ng-container>
            <button mat-icon-button matChipRemove style="margin-top: -18px">
              <mat-icon style="width: 18px; height: 18px; line-height: 18px; font-size: 18px">cancel</mat-icon>
            </button>
          </mat-chip>

          <input
            #input
            #managerInput
            type="email"
            [readonly]="!canUpdate"
            [formControl]="managerCtrl"
            [matChipInputFor]="chipListManager"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [placeholder]="canUpdate ? 'Assign new manager..' : ''"
            [matTooltip]="'List of emails that will be notified when a substitution request is made'"
            (keyup.enter)="selectedManager(input.value)"
          />

          <button mat-flat-button [matTooltip]="'Add email'" (click)="selectedManager(input.value)">
            <mat-icon style="font-size: 24px">attach_email</mat-icon>
          </button>
        </mat-chip-list>
        <mat-hint style="color: var(--warn-color)" *ngIf="!validEmail"> <i>Invalid email</i></mat-hint>
      </mat-form-field>
    </form>
  </greco-collapsible-section>
</ng-container>
