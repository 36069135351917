import { InjectionToken } from '@angular/core';

export interface DefaultSaleCategoryDefinitions {
  label: string;
  matIcon: string;
  type: string;
}

export const DEFAULT_SALE_CATEGORY_DEFINITIONS = new InjectionToken<DefaultSaleCategoryDefinitions[]>(
  'DEFAULT_SALE_CATEGORY_DEFINITIONS'
);
