<div class="header">
  <h1>
    <img id="logo" src="assets/lf3/icon.png" />
    Upcoming Classes
  </h1>
  <div class="now">
    {{ now$ | async | date : 'EEEE, LLLL d - h:mm a' }}
  </div>
</div>

<ng-container *grecoLet="eventsAndDates$ | async as eventsAndDates">
  <ng-container *ngFor="let dateWithEvents of eventsAndDates">
    <div class="date-header">
      <h2>{{ dateWithEvents.date | date : 'EEEE, MMMM d' }}</h2>
    </div>

    <div class="content">
      <div *ngFor="let event of dateWithEvents?.events || []; trackBy: trackById; let index = index" class="event">
        <div *grecoLet="getTrainers(event) as trainers" class="top-content">
          <div class="event-info">
            <h2>{{ event.title }}</h2>
            <p>{{ event.startDate | date : 'shortTime' }} - {{ event.endDate | date : 'shortTime' }}</p>
          </div>

          <div class="more-info">
            <p *grecoLet="getRooms(event)[0] as room">{{ room?.name || event.calendar }}</p>

            <div>
              <p *ngIf="event.maxCapacity - event.bookings; else waitlist" style="white-space: nowrap">
                {{ event.maxCapacity - event.bookings }} / {{ event.maxCapacity }} Spots Left
              </p>

              <ng-template #waitlist>
                <p>Waitlist</p>
              </ng-template>
            </div>
          </div>

          <div class="event-trainers" *ngIf="trainers?.length">
            <greco-multi-avatar
              [size]="40"
              [color]="'transparent'"
              [images]="(trainers | pluck : 'photoURL') || ['assets/lf3/icon_square_pad.png']"
            ></greco-multi-avatar>

            <div class="info">
              <p>
                <strong>
                  {{ trainers && trainers?.length ? (trainers | pluck : 'name')[0] : '' }}
                </strong>
              </p>

              <!-- <p
                *ngIf="trainers && trainers?.length ? (trainers | pluck : 'description').join(', ') : '' as description"
              >
                <small>
                  {{ description }}
                </small>
              </p> -->
            </div>
          </div>

          <div
            class="starting-in"
            *ngIf="
              moment(event.startDate)
                .subtract(1, 'hour')
                .isBefore(now$ | async)
            "
          >
            <p>Starting {{ moment(event.startDate) | amTimeAgo }}</p>
          </div>
        </div>

        <!-- <ng-container *ngIf="room$ | async as room">
          <div class="room-bookings" *ngIf="index === 0">
            <greco-room-spot-shower
              *ngIf="room"
              [room]="room"
              [event]="event"
              [drawEmptySpots]="false"
            ></greco-room-spot-shower>
          </div>
        </ng-container> -->
      </div>
    </div>
  </ng-container>
</ng-container>

<div class="footer">
  <div class="url">
    <mat-icon>language</mat-icon>
    <h3>{{ url }}</h3>
  </div>

  <div class="book-now">
    <div>
      <h3>See Schedule</h3>
      <p style="margin-top: -4px; color: dimgray">..or book now!</p>
    </div>
    <qrcode [qrdata]="url + '/workouts'" [elementType]="'svg'" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</div>
