<greco-box>
  <ng-container *grecoLet="CommunityAgreement$ | async as data">
    <greco-filter-bar
      [hideSeparator]="true"
      [filterOptions]="filterOptions"
      (changed)="filters$.next($event[1])"
      style="position: static"
    >
    </greco-filter-bar>

    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <greco-table [highlight]="true" [data]="data || []">
      <!--checkbox-->
      <ng-container *grecoTableCol="''; let item; fitContent: true; sticky: true">
        <mat-checkbox
          color="primary"
          id="{{ item.id }}"
          matInput
          [checked]="item.includedUsage || item.checked || item.linkedUsage"
          [disabled]="item.includedUsage || item.linkedUsage"
          (change)="itemChecked(item, $event.checked)"
        ></mat-checkbox>
      </ng-container>

      <!-- video title -->
      <ng-container>
        <p *grecoTableCol="'Title'; let item">
          {{ item.commAgreement.title }}
        </p>
      </ng-container>

      <!--Agreement Type-->
      <ng-container>
        <p *grecoTableCol="'Type'; let item" [matTooltip]="'Agreement acceptance by'">
          <span [ngSwitch]="item.commAgreement.agreementType">
            <mat-chip *ngSwitchCase="'CHECKBOX'">
              <mat-icon>check_box_outline_blank</mat-icon> <span>Checkbox</span>
            </mat-chip>

            <mat-chip *ngSwitchCase="'AUTO_CHECKBOX'">
              <mat-icon>check_box</mat-icon> <span>Auto Checkbox</span>
            </mat-chip>

            <mat-chip *ngSwitchCase="'DIGITAL_SIGNATURE'"> <mat-icon>edit</mat-icon> <span>Signature</span> </mat-chip>
          </span>
        </p>
      </ng-container>

      <!-- Date -->
      <ng-container>
        <p *grecoTableCol="'Created Date'; let item" [matTooltip]="'Agreement created on'">
          {{ item.commAgreement.created | date }}
        </p>
      </ng-container>

      <ng-container>
        <button
          *grecoTableCol="'Preview'; let item"
          mat-icon-button
          matTooltip="Preview Agreement"
          matTooltipClass="mt3"
          (click)="viewAgreement(item)"
        >
          <mat-icon>visibility</mat-icon>
        </button>
      </ng-container>
    </greco-table>

    <mat-paginator
      *ngIf="pagination?.totalItems"
      showFirstLastButtons
      [length]="pagination!.totalItems || 0"
      [pageSize]="pagination!.itemsPerPage || 10"
      [pageSizeOptions]="[10, 20, 50]"
      (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
    ></mat-paginator>
  </ng-container>
</greco-box>
<div style="display: flex; flex-direction: row; margin-top: 16px">
  <div style="width: 100%"></div>
  <div style="display: flex; flex-direction: row; gap: 16px">
    <mat-chip style="white-space: nowrap" *ngIf="checked.length"
      >{{ checked.length }} selected
      <button class="clear" mat-icon-button (click)="clear()" matTooltip="Click to clear selected videos">
        <mat-icon style="width: 8px">clear</mat-icon>
      </button>
    </mat-chip>
    <button mat-stroked-button *ngIf="checked.length" color="primary" (click)="linkAgreements()">
      <mat-icon>note_add</mat-icon>Add Agreement(s)
    </button>
  </div>
</div>
