<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <!-- Profile Picture -->
    <mat-form-field id="picture-form-field" floatLabel="always" appearance="standard" style="width: 100%">
      <greco-user-avatar matPrefix size="small" [showOnlineStatus]="false" [user]="form.value"></greco-user-avatar>

      <mat-label style="padding-left: 24px">Profile Picture</mat-label>
      <input
        style="padding-left: 16px"
        matInput
        readonly
        placeholder="Upload new photo"
        [value]="profilePictureFile?.name || ''"
      />

      <input
        #fileInput
        style="display: none"
        type="file"
        accept="image/*"
        (change)="setProfilePicture($any($event.target).files[0])"
      />

      <div matSuffix style="display: flex">
        <button mat-button color="primary" (click)="fileInput.click()">Select File</button>
        <button mat-button color="primary" (click)="takePhoto()">Use Webcam</button>
      </div>
    </mat-form-field>
    <small><mat-error *ngIf="error">{{errorString}}</mat-error></small>
  </form>
  <div class="buttons">
    <button mat-flat-button (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!profilePictureFile">Submit</button>
  </div>
</greco-simple-dialog>
