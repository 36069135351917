import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-tabs-layout',
  templateUrl: './tabs-layout.component.html',
  styleUrls: ['./tabs-layout.component.scss']
})
export class TabsLayoutComponent {
  @Input() text: string;

  private _tabs = new BehaviorSubject<Tab[]>([]);
  @Input() set tabs(tabs: Tab[]) {
    this._tabs.next(tabs);
  }
  get tabs() {
    return this._tabs.value;
  }

  replaceUrl$ = this.router.events.pipe(
    map(() => this.router.url),
    startWith(this.router.url),
    distinctUntilChanged<string>(),
    switchMap(url => this._tabs.pipe(map(tabs => tabs.some(tab => tab.routerLink && url.endsWith(typeof tab.routerLink === 'string' ? tab.routerLink : tab.routerLink.join('/'))))))
  );

  constructor(private router: Router) {}
}

export interface Tab {
  label: string;
  routerLink?: string | string[];
  disabled?: boolean;
}
