<greco-item [lines]="false">
  <mat-icon item-start>assignment</mat-icon>

  <div style="width: 100%">
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">
      <strong>{{ requirement.title }}</strong>
    </p>
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">
      <small [style.color]="requirement.required && !(response$ | async) ? 'var(--warn-color)' : 'black'">
        {{
          (previousSubmission$ | async) && (response$ | async)
            ? 'Reusing your last submission'
            : requirement.required && !(response$ | async)
            ? '* Submission Required'
            : 'Thank you for submitting your response'
        }}
      </small>
    </p>
  </div>

  <div item-end class="end-container" [class.completed]="response$ | async">
    <div class="buttons">
      <button
        style="height: 32px; margin-left: auto; padding: 0 12px"
        mat-button
        color="accent"
        matTooltip="Re-fill the form"
        (click)="filIn()"
      >
        <span>Fill-Out</span>
      </button>
    </div>

    <div class="completed-icon" style="margin-right: 8px">
      <mat-icon style="color: var(--success-color)">check_circle</mat-icon>
    </div>
  </div>
</greco-item>
