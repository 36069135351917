import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionComponent } from './collapsible-section/collapsible-section.component';

@NgModule({
  imports: [CommonModule, MatBadgeModule, MatIconModule, MatButtonModule, BoxModule, MatChipsModule],
  declarations: [CollapsibleSectionComponent],
  exports: [CollapsibleSectionComponent],
})
export class CollapsibleSectionModule {}
