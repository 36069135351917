import { SecurityResource } from '@greco/security';

export enum UserBalanceResourceAction {
  READ = 'READ',
  CREATE_TRANSACTION = 'CREATE_TRANSACTION',
  MANUALLY_ADJUST = 'MANUALLY_ADJUST',
}

export const UserBalanceResource: SecurityResource = {
  key: 'finance_user_balance',
  module: 'Finance',

  title: 'User Balance',
  description: 'Manage user balances',

  context: class {
    userId?: string;
    accountId?: string;
  },
  actions: [
    {
      key: UserBalanceResourceAction.READ,
      title: "Read user's balance",
      description: 'Returns a users current balance',
    },
    {
      key: UserBalanceResourceAction.CREATE_TRANSACTION,
      title: 'Fund user balance',
      description: 'Fund balance transaction',
    },
    {
      key: UserBalanceResourceAction.MANUALLY_ADJUST,
      title: 'Manually adjust balance',
      description: "Manually adjust a user's balance",
    },
  ],
};
