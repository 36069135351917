<mat-datetimepicker
  #startDatetimePicker
  type="datetime"
  [openOnFocus]="true"
  [timeInterval]="5"
  [disabled]="disabled"
></mat-datetimepicker>

<form [formGroup]="_form" class="greco-datetime-timezone-input-container">
  <input
    #datetimeInput
    matInput
    [matDatetimepicker]="startDatetimePicker"
    autocomplete="false"
    [required]="required"
    [readonly]="true"
    formControlName="date"
    [min]="minimumStartDate"
    [disabled]="disabled"
  />

  <greco-timezone-select
    [required]="required"
    formControlName="timezone"
    (click)="$event.stopImmediatePropagation()"
  ></greco-timezone-select>
</form>
