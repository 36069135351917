<ng-container *ngIf="userId">
  <div style="padding: 16px 16px 0; display: flex; align-items: center; gap: 8px">
    <mat-form-field appearance="outline" style="flex: 1">
      <mat-label>Community</mat-label>
      <mat-select [formControl]="accountSelect">
        <mat-option *ngFor="let balance of _userBalances$ | async" [value]="balance.accountId">
          {{ balance.account.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" style="flex: 1">
      <mat-label>Current Balance</mat-label>
      <input matInput readonly [value]="((_currentAccountBalance$ | async) || 0) / 100 | currency" />
    </mat-form-field>
  </div>

  <greco-table [data]="(_balanceHistory$ | async) || []" [loading]="_loading">
    <mat-icon *grecoTableCol="''; let transaction; fitContent: true">toll</mat-icon>

    <p *grecoTableCol="'Details'; let transaction" [matTooltip]="transaction.description">
      {{ transaction.title }}
    </p>

    <p *grecoTableCol="'Modified By'; let transaction" [matTooltip]="transaction.modifiedById">
      {{ transaction?.modifiedBy?.displayName || 'System' }}
    </p>

    <p *grecoTableCol="'Date'; let transaction" [matTooltip]="(transaction.created | date : 'full') || ''">
      {{ transaction.created | date }}
    </p>

    <p *grecoTableCol="'Amount'; let transaction; alignRight: true" style="font-weight: bold">
      {{ transaction.amount / 100 | currency }}
    </p>
  </greco-table>

  <mat-paginator
    *ngIf="_meta?.totalItems"
    showFirstLastButtons
    [length]="_meta!.totalItems || 0"
    [pageSize]="_meta!.itemsPerPage || 5"
    [pageSizeOptions]="[5]"
    (page)="_pagination$.next($event.pageIndex + 1)"
    [pageIndex]="this.pageIndex"
  ></mat-paginator>
</ng-container>
