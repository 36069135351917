import { Pipe, PipeTransform } from '@angular/core';
import { Purchase } from '@greco/sales-purchases';

@Pipe({ name: 'purchaseTax' })
export class PurchaseTaxPipe implements PipeTransform {
  transform(purchase: Purchase): number {
    const subtotal = purchase.items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const taxRate = purchase.taxes.reduce((acc, tax) => acc + tax.percentage / 100, 0);
    return Math.round(subtotal * taxRate);
  }
}
