<greco-table
  [highlight]="true"
  [loading]="loading"
  [data]="(courseBookings$ | async) || []"
  (rowClick)="rowClick.emit($event)"
>
  <mat-icon *grecoTableCol="''; let booking; fitContent: true" [matTooltip]="booking.id">
    {{ booking | bookingIcon }}
  </mat-icon>

  <!-- showSpotNumber -->
  <ng-container *ngIf="showSpotNumber">
    <p *grecoTableCol="'Spot'; fitContent: true; let index = index">
      #{{ ((metadata?.currentPage || 1) - 1) * (metadata?.itemsPerPage || 0) + index + 1 }}
    </p>
  </ng-container>

  <ng-container *ngIf="!communityId">
    <p *grecoTableCol="'Community'; let booking" [matTooltip]="booking.event.community.name">
      {{ booking.event.community.name }}
    </p>
  </ng-container>

  <!-- Event -->
  <ng-container *ngIf="!hideEvent">
    <p *grecoTableCol="'Event'; let booking" [matTooltip]="booking.event.title">{{ booking.event.title }}</p>
  </ng-container>

  <!-- Date -->
  <ng-container *ngIf="!hideDate">
    <p *grecoTableCol="'Event Date'; let booking" [matTooltip]="'Booked: ' + (booking.created | date : 'MMM d, h:mma')">
      {{ booking.event?.startDate | date : 'MMM d, h:mma' }}
    </p>
  </ng-container>

  <!-- User -->
  <div
    *grecoTableCol="'User'; let booking"
    [matTooltip]="booking.user?.contactEmail"
    style="display: flex; align-items: center; gap: 8px"
  >
    <greco-multi-avatar
      *ngIf="booking.user?.photoURL"
      [images]="[booking.user.photoURL]"
      [size]="28"
    ></greco-multi-avatar>

    <div style="display: flex; flex-direction: column">
      <span *ngIf="booking.user?.id">
        <a
          [routerLink]="['/admin/community', communityId, booking.user.id]"
          (click)="$event.stopImmediatePropagation()"
        >
          {{ booking.user.displayName }}
        </a>
      </span>
      <p
        *ngIf="booking.bookedById !== booking.user.id && hideEvent"
        style="margin: 0; line-height: 12px"
        [matTooltip]="booking.bookedBy?.contactEmail"
      >
        <small *ngIf="booking.registeredBy?.displayName">
          <i>(Purchased By {{ booking.registeredBy.displayName }})</i>
        </small>
      </p>
    </div>
  </div>

  <!-- Requirements -->
  <mat-chip-list *grecoTableCol="'Forms & Requirements'; let booking">
    <mat-chip
      *ngFor="let sub of booking.course.requirements | grecoFilterBookingRequirementSubmissions"
      [matTooltip]="sub.response | typeformResponseTooltip | grecoJoin : ', '"
      style="cursor: pointer"
      (click)="previewFormResponse(sub.response.response, sub.form.formTitle)"
    >
      {{ sub.title }}
    </mat-chip>
  </mat-chip-list>

  <!-- Booking Option -->
  <p
    *grecoTableCol="'Booking Option'; let booking"
    style="display: flex; gap: 4px; align-items: center"
    [matTooltip]="
      (booking.bookingOptionConsumed
        ? ''
        : 'This booking option is reusable and was not consumed for this booking. ( ') +
      booking.bookingOptionId +
      (booking.bookingOptionConsumed ? '' : ')')
    "
  >
    {{ booking.bookingOption.title }}
    <small *ngIf="booking.boostersUsed + booking.boostersPurchased"
      >&nbsp;(+{{ booking.boostersUsed + booking.boostersPurchased }} Boosts)</small
    >
    <mat-icon *ngIf="!booking.bookingOptionConsumed" style="width: 18px; height: 18px; font-size: 18px">
      restart_alt
    </mat-icon>
  </p>

  <mat-chip-list *grecoTableCol="'Status'; let booking; fitContent: true">
    <mat-chip
      [style.background-color]="
        booking.status === 'CANCELLED' || booking.status === 'NO-SHOW' || booking.status === 'LATE_CANCELLED'
          ? 'var(--warn-color)'
          : ''
      "
      [style.color]="
        booking.status === 'CANCELLED' || booking.status === 'NO-SHOW' || booking.status === 'LATE_CANCELLED'
          ? 'white'
          : ''
      "
      >{{ booking.status.replace('CONFIRMED', 'Booked') | removeUnderscores | titlecase }}</mat-chip
    >
  </mat-chip-list>

  <!-- Options -->
  <ng-container *ngIf="canDoAnything$ | async">
    <ng-container *grecoTableCol="''; let booking; fitContent: true; stickyEnd: true">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <!-- Manage User -->
        <button
          *ngIf="canManageUser$ | async"
          mat-menu-item
          [routerLink]="['/admin', 'community', communityId, booking.user.id]"
        >
          <mat-icon>people</mat-icon>
          <span>Manage User</span>
        </button>

        <ng-container *ngIf="booking.status === 'PENDING'">
          <!-- Confirm -->
          <ng-container>
            <button mat-menu-item (click)="confirm(booking)">
              <mat-icon>how_to_reg</mat-icon>
              <span>Confirm</span>
            </button>
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="booking.status === 'CONFIRMED' || booking.status === 'CHECKED_IN' || booking.status === 'PENDING'"
        >
          <!-- Cancel -->
          <ng-container *ngIf="canCancelBookings$ | async">
            <button mat-menu-item (click)="cancel(booking, false)">
              <mat-icon>event_busy</mat-icon>
              <span>Cancel</span>
            </button>
          </ng-container>

          <!-- Cancel (Free of Charge) -->
          <ng-container *ngIf="canCancelBookingsFreeOfCharge$ | async">
            <button *ngIf="canCancelBookingsFreeOfCharge$ | async" mat-menu-item (click)="cancel(booking, true)">
              <mat-icon>event_busy</mat-icon>
              <span>Cancel (Free of Charge)</span>
            </button>
          </ng-container>

          <!-- No-Show -->
          <ng-container *ngIf="canNoShow$ | async">
            <button
              *ngIf="moment(booking.event?.startDate).subtract(10, 'minutes').isBefore(now)"
              mat-menu-item
              (click)="noShow(booking)"
            >
              <mat-icon>person_off</mat-icon>
              <span>No-Show</span>
            </button>
          </ng-container>
        </ng-container>

        <!-- TODO: <button mat-menu-item disabled>Check-In</button> -->
      </mat-menu>
    </ng-container>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="metadata?.totalItems"
  showFirstLastButtons
  [pageSizeOptions]="[10, 20, 50]"
  [length]="metadata!.totalItems || 0"
  [pageSize]="metadata!.itemsPerPage || 10"
  (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
