import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({ selector: 'greco-info-card-select', templateUrl: './select.component.html', styleUrls: ['./select.component.scss'] })
export class InfoCardSelectComponent {
  @Output() changed = new EventEmitter<string[]>(true);

  @ViewChild(MatMenuTrigger) private trigger: MatMenuTrigger;

  private _options: { value: string; text: string }[];
  @Input() get options() {
    return this._options;
  }
  set options(options) {
    this._options = options;
    if (this.multi) options?.forEach(option => this.toggle(option.value, null));
    else this.toggle(options?.[0]?.value, null);
  }

  @Input() multi: boolean;

  selected: string[] = [];

  get text() {
    return this.options?.reduce((acc, option) => [...acc, ...(this.selected.includes(option.value) ? [option.text] : [])], [])?.join(', ') || '';
  }

  toggle(option: string, event: MouseEvent) {
    if (this.multi) {
      event?.stopPropagation?.();
      this.selected = this.selected.includes(option) ? (this.selected.length > 1 ? this.selected.filter(o => o !== option) : this.selected) : [...this.selected, option];
      this.changed.emit([...this.selected]);
    } else if (!this.selected.includes(option)) {
      this.selected = [option];
      this.changed.emit([...this.selected]);
    }
  }

  open() {
    this.trigger.openMenu();
  }
}
