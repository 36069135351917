import { Pipe, PipeTransform } from '@angular/core';
import { ProductVariant } from '@greco/sales-products';

@Pipe({ name: 'grecoVariantTransfers' })
export class VariantTransfersPipe implements PipeTransform {
  transform(variant: ProductVariant, productAccount: string): string {
    const { pay, receive } = (variant?.transfers || []).reduce(
      (acc, transfer) => {
        if (transfer.sourceAccount.id === productAccount) acc.pay += transfer.amount;
        else acc.receive += transfer.amount;
        return acc;
      },
      { pay: 0, receive: 0 }
    );

    if (pay && receive)
      return `Pay <strong>$${(pay / 100).toFixed(2)}</strong> and receive <strong>$${(receive / 100).toFixed(
        2
      )}</strong>`;
    else if (receive) return `Receive <strong>$${(receive / 100).toFixed(2)}</strong>`;
    else if (pay) return `Pay <strong>$${(pay / 100).toFixed(2)}</strong>`;
    else return '-';
  }
}
