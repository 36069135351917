<greco-collapsible-section
  #section
  [header]="{ title: 'Account Information', icon: 'info' }"
  [disabled]="user.email ? false : true"
  [expanded]="user.email ? true : false"
>
  <ng-content header select="[header]"></ng-content>
  <greco-form-save-buttons
    *ngIf="section.expanded && !readonly"
    header
    [form]="form"
    [saveAction]="save"
    [resetValue]="resetValue"
    (formReset)="form.markAsPristine()"
  ></greco-form-save-buttons>

  <form [formGroup]="form">
    <mat-form-field appearance="standard">
      <mat-label>Email Address</mat-label>
      <input matInput required formControlName="email" [readonly]="readonly" />
      <mat-error>A valid email address is required</mat-error>
    </mat-form-field>
    <!-- <mat-form-field>
    <mat-label>Password</mat-label>
    <input matInput />
  </mat-form-field> -->
  </form>

  <div style="display: flex; gap: 8px; flex-wrap: wrap">
    <!--<button
      style="flex: 1"
      mat-stroked-button
      color="primary"
      *ngIf="(isSignedIn$ | async) && readonly"
      (click)="changeEmail()"
    >
      Change Email
    </button>-->

    <button
      style="flex: 1; margin-bottom: 8px"
      mat-stroked-button
      color="primary"
      *ngIf="isSignedIn$ | async"
      (click)="changePassword()"
    >
      Change Password
    </button>
  </div>

  <ng-content></ng-content>
</greco-collapsible-section>
