import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { DatetimeTimezoneComponent, TimezoneSelectComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
  ],
  declarations: [TimezoneSelectComponent, DatetimeTimezoneComponent],
  exports: [TimezoneSelectComponent, DatetimeTimezoneComponent],
})
export class TimezoneModule {}
