import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Booking, CalendarEvent, WaitlistItem } from '@greco/booking-events';
import { DataExport } from '@greco/data-exports';
import type {
  BookDto,
  BookingConfirmationDto,
  BookingPreview,
  QueryBookingsByDateDto,
  WaitlistDto,
} from '@greco/nestjs-booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

const _waitlistCache = new Map<string, boolean>();
const _datedBookingsCache = new Map<string, Booking[]>();

@Injectable()
export class BookingService {
  constructor(private http: HttpClient) {}

  // @Get()
  async paginate(query: RequestQueryBuilder, communityId?: string, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<Booking>>('/api/bookings', {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 20).toString(),
        },
      })
    );
  }
  // @Get
  async getById(id: string) {
    return await toPromise(this.http.get<Booking>('api/bookings/booking/' + id));
  }
  //  @Get()
  async getByUser(userId: string) {
    return await toPromise(this.http.get<Booking[]>('/api/bookings/events/' + userId));
  }

  async getByEvent(eventId: string) {
    // Could be cached.
    return await toPromise(this.http.get<Booking[]>('/api/bookings/event/' + eventId));
  }

  // @Get('by-date')
  async getByDate(dto: QueryBookingsByDateDto) {
    const key = `${dto.startDate.toISOString()}_${dto.endDate.toISOString()}_${dto.userId}_${dto.communityId}`;
    if (_datedBookingsCache.has(key)) return _datedBookingsCache.get(key) as Booking[];

    const bookings = await toPromise(
      this.http.get<Booking[]>('/api/bookings/by-date', {
        params: {
          endDate: dto.endDate.toISOString(),
          startDate: dto.startDate.toISOString(),
          ...(dto.userId && { userId: dto.userId }),
          ...(dto.communityId && { communityId: dto.communityId }),
          ...(dto.statuses?.length ? { statuses: dto.statuses.join(',') } : {}),
          ...(dto.includeLinkedAccountBookings ? { includeLinkedAccountBookings: 'true' } : {}),
        },
      })
    );

    _datedBookingsCache.set(key, bookings);
    return bookings;
  }

  // @Post('preview')
  async preview(dto: BookDto, skipErrorHandler = false) {
    return await toPromise(
      this.http.post<BookingPreview>('/api/bookings/preview', dto, {
        headers: { 'X-GrecoIgnoreErrors': skipErrorHandler.toString() },
      })
    );
  }

  // @Post('preview-multiple')
  async previewMultiple(dtos: BookDto[], skipErrorHandler = false) {
    return await toPromise(
      this.http.post<BookingPreview[]>(
        '/api/bookings/preview-multiple',
        { dtos },
        {
          headers: { 'X-GrecoIgnoreErrors': skipErrorHandler.toString() },
        }
      )
    );
  }

  // @Post(':bookingId/preview-booking-option-changed')
  async previewBookingOptionChanged(dto: BookDto, bookingId: string, skipErrorHandler = false) {
    return await toPromise(
      this.http.post<BookingPreview>(`/api/bookings/${bookingId}/preview-booking-option-changed`, dto, {
        headers: { 'X-GrecoIgnoreErrors': skipErrorHandler.toString() },
      })
    );
  }

  // @Post(':bookingId/confirm-pending-booking')
  async confirmPendingBooking(preview: BookingConfirmationDto, bookingId: string) {
    const booking = await toPromise(
      this.http.post<Booking>(`/api/bookings/${bookingId}/confirm-pending-booking`, preview)
    );

    const cacheKey = `${preview.booking.eventId}_${preview.booking.userId}`;
    _waitlistCache.set(cacheKey, false);
    _datedBookingsCache.clear();

    return booking;
  }

  // @Post('confirm')
  async confirm(preview: BookingConfirmationDto) {
    const booking = await toPromise(this.http.post<Booking>('/api/bookings/confirm', preview));

    const cacheKey = `${preview.booking.eventId}_${preview.booking.userId}`;
    _waitlistCache.set(cacheKey, false);
    _datedBookingsCache.clear();

    return booking;
  }

  // @Post('confirm/multiple')
  async confirmMultiple(previews: BookingConfirmationDto[], requiredPendingBookings?: number) {
    const bookings = await toPromise(
      this.http.post<Booking[]>('/api/bookings/confirm/multiple', { dtos: previews, requiredPendingBookings })
    );

    for (const preview of previews) {
      const cacheKey = `${preview.booking.eventId}_${preview.booking.userId}`;
      _waitlistCache.set(cacheKey, false);
    }
    _datedBookingsCache.clear();

    return bookings;
  }

  // @Get(':bookingId')
  async getOne(bookingId: string) {
    return await toPromise(this.http.get<Booking>(`/api/bookings/${bookingId}`));
  }

  async updateSpot(bookingId: string, spotId: string) {
    return await toPromise(this.http.post<Booking>(`/api/bookings/${bookingId}/update-spot/${spotId}`, {}));
  }

  //@Post(':eventId/spots')
  async autoAssignSpots(eventId: string) {
    return await toPromise(this.http.post(`/api/bookings/${eventId}/spots`, {}));
  }

  async confirmPending(bookingId: string) {
    return await toPromise(this.http.get<Booking>(`/api/bookings/${bookingId}/confirm`));
  }

  async confirmPendingCourse(courseId: string) {
    return await toPromise(this.http.get<Booking>(`/api/bookings/${courseId}/confirmCourse`));
  }

  // @Post(':bookingId/cancel')
  async cancel(bookingId: string, freeOfCharge?: boolean) {
    _datedBookingsCache.clear();
    return await toPromise(
      this.http.post<Booking>(`/api/bookings/${bookingId}/cancel`, null, {
        params: {
          freeOfCharge: (freeOfCharge || false).toString(),
        },
      })
    );
  }

  async noShow(bookingId: string) {
    _datedBookingsCache.clear();
    return await toPromise(this.http.post<Booking>(`/api/bookings/${bookingId}/no-show`, null, {}));
  }

  async checkIn(bookingId: string) {
    _datedBookingsCache.clear();
    return await toPromise(this.http.post<Booking>(`/api/bookings/${bookingId}/check-in`, null, {}));
  }

  async undoCheckIn(bookingId: string) {
    _datedBookingsCache.clear();
    return await toPromise(this.http.post<Booking>(`/api/bookings/${bookingId}/undo-check-in`, null, {}));
  }

  // @Post('waitlist')
  async joinWaitlist(dto: WaitlistDto) {
    const item = await toPromise(this.http.post<WaitlistItem>('/api/bookings/waitlist', dto));

    const cacheKey = `${dto.eventId}_${dto.userId}`;
    _waitlistCache.set(cacheKey, true);

    return item;
  }

  // @Post('waitlist/multiple')
  async joinWaitlistMultiple(eventId: string, userIds: string[]) {
    const items = await toPromise(
      this.http.post<WaitlistItem[]>('/api/bookings/waitlist/multiple', { eventId, userIds })
    );
    for (const userId of userIds) {
      const cacheKey = `${eventId}_${userId}`;
      _waitlistCache.set(cacheKey, true);
    }

    return items;
  }
  // @Delete('/api/events/{eventId}/leave?userId={}')
  async removeFromWaitlist(communityId: string, dto: WaitlistDto) {
    await toPromise(this.http.delete(`/api/events/${communityId}/${dto.eventId}/leave?userId=${dto.userId}`));
  }

  // @Get('waitlist/:eventId/:userId')
  private async inWaitlist(eventId: string, userId: string) {
    return await toPromise(this.http.get<boolean>(`/api/bookings/waitlist/${eventId}/${userId}`));
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async userInWaitlist(eventId: string, userId: string): Promise<boolean> {
    const key = `${eventId}_${userId}`;
    if (_waitlistCache.has(key)) return _waitlistCache.get(key) as boolean;

    const isInWailist = await this.inWaitlist(eventId, userId);

    _waitlistCache.set(key, isInWailist);
    return isInWailist;
  }

  getUserWaitlistedEvents(userId: string, communityId?: string) {
    return toPromise(
      this.http.get<CalendarEvent[]>(`/api/bookings/user-waitlist/${userId}/${communityId || undefined}`)
    );
  }

  getUserWaitlist(userId: string) {
    return toPromise(this.http.get<string[]>(`/api/bookings/waitlist/${userId}`));
  }

  // Get('export')
  async export(queryBuilder: RequestQueryBuilder, communityId: string) {
    return await toPromise(
      this.http.get<DataExport>('/api/bookings/export', {
        params: { ...queryBuilder.queryObject, communityId },
      })
    );
  }

  async getIcs(bookingId: string) {
    return await toPromise(this.http.get<{ ics: string; filename: string }>(`/api/bookings/${bookingId}/ics`));
  }
}
