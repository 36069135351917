/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { AccountLink } from '@greco/account-linking';
import { BookingTotal, CreateMultiBookingDto, Event, EventAccount, EventWithUserDetails } from '@greco/booking-events2';
import { CommunityAgreementSecurityActions, CommunityAgreementSecurityResource } from '@greco/community-agreements';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { AddAttendeeDialog } from '@greco/ngx-booking-events';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { UserAddGuestDialog } from '@greco/web-account-linking';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'alt-event',
  templateUrl: './event.page.html',
  styleUrls: ['./event.page.scss'],
})
export class EventPage implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    public eventService: EventService,
    public bottomSheet: MatBottomSheet,
    private securitySvc: SecurityService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.eventService.reset();

    this.bookingUser = this.route.snapshot.data.user;
    this.eventService.userId$.next(this.bookingUser?.id);

    this.eventId = this.route.snapshot.params.eventId;
    this.eventService.eventId$.next(this.eventId);
  }

  @PropertyListener('isStaffView') isStaffView$ = new BehaviorSubject<boolean>(false);
  @Input() isStaffView = true;

  @Input() showNavbar = false;

  eventId!: string;

  bookingUser: User | null = null;

  paymentMethodControl = new FormControl(null);

  confirming = false;

  tabsAnimationDuration = '250ms';

  moment = moment;
  now = new Date();

  private _paymentMethodSubscription$!: Subscription;

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(breakPoints => breakPoints.matches));

  canLeaveUnsigned$ = combineLatest([this.eventService.event$, this.isStaffView$]).pipe(
    switchMap(async ([event, isStaff]) => {
      if (isStaff) {
        const user = await this.userSvc.getSelf();
        const accountId = event.location?.id;

        return await this.securitySvc.hasAccess(
          CommunityAgreementSecurityResource.key,
          CommunityAgreementSecurityActions.LEAVE_UNSIGNED,
          {
            userId: user?.id,
            accountId: accountId,
            createdById: user?.id,
          }
        );
      } else return false;
    })
  );

  async ngOnInit() {
    this._paymentMethodSubscription$ = this.eventService.paymentMethodToUse$.subscribe(paymentMethod => {
      this.paymentMethodControl.setValue(paymentMethod, { emitEvent: false });
    });

    this.eventService.isStaffView$.next(this.isStaffView);
  }

  ngOnDestroy() {
    this._paymentMethodSubscription$?.unsubscribe();
  }

  async confirmBooking(event: Event, bookingTotals: BookingTotal[], addAnother?: boolean) {
    this.confirming = true;

    const agreements = this.eventService.agreementSubmissions$.value;
    await Promise.all(
      this.eventService.bookings$.value.map(async booking => {
        if (!this.confirming) return;

        const leftUnsigned = agreements
          .filter(agreement => agreement.userId === booking.userId)
          .some(agreement => agreement.unsigned && !agreement.signed);

        if (leftUnsigned) {
          const user = this.eventService.accounts$.value.find(account => account.user.id === booking.userId)?.user;
          if (!user) return;

          const dialog = this.dialog.open(SimpleDialog, {
            data: {
              showCloseButton: false,
              title: 'Unsigned Agreements',
              content:
                user.displayName +
                ' has an agreement on this booking that is currently unsigned. Let ' +
                user.displayName +
                ' know that before completing any bookings or purchases, they will need to ensure all their agreements are signed',
              buttons: [
                { label: 'Cancel', role: 'no' },
                { label: 'Confirm', role: 'yes', color: 'primary' },
              ],
            } as DialogData,
            width: '500px',
            maxWidth: '100%',
          });

          if ((await toPromise(dialog.afterClosed())) === 'no') {
            this.confirming = false;
          }
        }
      })
    );

    if (!this.confirming) return;

    try {
      const paymentMethod = this.eventService.paymentMethodToUse$.value;

      const agreements = this.eventService.agreementSubmissions$.value;
      const typeformSubmissions = this.eventService.typeformSubmissions$.value;

      const bookingsInfo = await toPromise(this.eventService.bookingsInfo$);
      if (!bookingsInfo) throw new Error('No Bookings to confirm!');

      const bookingBoosters = await toPromise(this.eventService.bookingsBoosterInfo$);

      const dto: CreateMultiBookingDto = {
        bookings: bookingsInfo.map(booking => {
          const boosterInfo = bookingBoosters.find(booster => booster.userId === booking.userId);

          return {
            userId: booking.userId,
            bookingOptionId: booking.bookingOptionId,
            bookingOptionUserId: booking.bookingOptionUserId,
            paymentMethodId: paymentMethod?.id,
            spotId: booking.spotId,
            eventId: event.id,
            isPending: booking.option?.isPending ?? false,
            agreements: agreements.filter(agreement => agreement.userId === booking.userId),
            typeformSubmissions: typeformSubmissions.filter(form => form.userId === booking.userId),
            boostersToConsume: boosterInfo?.boostersToConsume || 0,
            boostersToPurchase: boosterInfo?.boostersToPurchase || 0,
            total: bookingTotals?.find(total => total.userId === booking.userId)?.total || 0,
          };
        }),
        paymentMethodId: paymentMethod?.id || '',
        total: this.eventService.total$.value,
        bookedById: this.bookingUser!.id,
        eventId: event.id,
      };

      const bookings = await this.eventService.confirmBooking(dto);

      if (bookings?.length) {
        this.snackbar.open('Your booking has been confirmed.', 'Ok', { duration: 2500, panelClass: 'mat-primary' });

        if (addAnother) {
          this.dialog.open(AddAttendeeDialog, {
            data: { event: { ...event, community: { id: event.location.id } } },
            width: '750px',
            maxWidth: '90%',
          });
        }

        this.eventService.forceRefresh$.next(true);

        this.router.navigate(['../'], { relativeTo: this.route });
      }
    } catch (err) {
      //TODO: Handle error
    }

    this.confirming = false;
  }

  async joinWaitlist(event: Event) {
    this.confirming = true;
    try {
      const userIds = this.eventService.bookings$.value.map(booking => booking.userId);
      await this.eventService.joinWaitlistMultiple(event.id, userIds);
    } catch (err) {
      console.error(err);
    }

    this.confirming = false;
  }

  swapBooking(previousIndex: number, event: EventWithUserDetails, next: EventAccount) {
    const accountWithBookingOptions = next.canUsePerks && next.bookingOptions.length ? next : event.accounts[0];
    let bookingOptions = accountWithBookingOptions.bookingOptions;

    if (accountWithBookingOptions.user.id !== next.user.id) {
      bookingOptions = bookingOptions.filter(option => option.transferable || option.transferableReusable);
    }

    this.eventService.swapBooking(this.eventService.bookings$.value[previousIndex].userId, {
      userId: next.user.id,
      eventId: event?.id || '',
      bookingOptionId: bookingOptions[0]?.id || '',
      bookingOptionUserId: bookingOptions[0]?.userId || '',
    });
  }

  async addGuest(user: User, event: EventWithUserDetails) {
    const dialog = this.dialog.open(UserAddGuestDialog, {
      data: {
        user,
        communityId: event.location.id,
      },
    });

    const response: AccountLink = await toPromise(dialog.afterClosed());
    if (response) {
      this.eventService.accounts$.next([
        ...this.eventService.accounts$.value,
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          user: response.account!,
          userStatusInfo: 'available',
          bookingOptions: [],
          conflictingBookings: [],
          canUsePerks: false,
          booking: undefined,
          boosters: [],
          failedPayments: [],
          pendingBookingOptions: [],
        },
      ]);

      this.eventService.addBooking({
        userId: response.accountId,
        eventId: event.id,
        bookingOptionId:
          event.accounts[0].bookingOptions.filter(option => option.transferable || option.transferableReusable)[0]
            ?.id || '',
        bookingOptionUserId: user.id,
      });
    }
  }

  addBooking(event: EventWithUserDetails, accountToAdd: EventAccount) {
    const accountWithBookingOptions =
      accountToAdd.canUsePerks && accountToAdd.bookingOptions.length ? accountToAdd : event.accounts[0];

    let bookingOptions = accountWithBookingOptions.bookingOptions;

    if (accountWithBookingOptions.user.id !== accountToAdd.user.id) {
      bookingOptions = bookingOptions.filter(option => option.transferable || option.transferableReusable);
    }

    this.eventService.addBooking({
      userId: accountToAdd.user.id,
      eventId: event?.id || '',
      bookingOptionId: bookingOptions[0]?.id || '',
      bookingOptionUserId: bookingOptions[0]?.userId || '',
    });
  }

  updatePaymentMethod(paymentMethod: PaymentMethod | null) {
    if (!paymentMethod) return;
    if (
      !this.eventService.paymentMethodToUse$.value ||
      this.eventService.paymentMethodToUse$.value.id !== paymentMethod.id
    ) {
      this.eventService.paymentMethodToUse$.next(paymentMethod);
    }
  }

  navigateBack() {
    if ((this.location.getState() as any)?.navigationId > 1) this.location.back();
    else this.router.navigate(['..'], { relativeTo: this.route });
  }

  async cancelAllBookings() {
    this.confirming = true;

    try {
      const attendees = this.eventService.attendees$.value.filter(
        booking => booking.status === 'PENDING' || booking.status === 'CONFIRMED'
      );

      for (const booking of attendees) {
        await this.eventService.cancelBooking(booking.id);
      }
    } catch (err) {
      console.error(err);
    }

    this.confirming = false;
  }

  async removeAllFromWaitlist(event: EventWithUserDetails) {
    this.confirming = true;

    try {
      const waitlist = this.eventService.waitlist$.value;
      for (const user of waitlist) {
        await this.eventService.removeFromWaitlist(event.location.id, event.id, user.id);
      }
    } catch (err) {
      console.error(err);
    }

    this.confirming = false;
  }
}
