<ng-container *grecoLet="typeformAccount$ | async as typeformAccount">
  <greco-collapsible-section [header]="{ title: 'Typeform Account', icon: 'assignment' }">
    <div header style="display: flex; align-items: center" *ngIf="typeformAccount">
      <ng-container matSuffix *ngIf="isSuperAdmin$ | async">
        <a
          *grecoLet="installUrl$ | async as installUrl"
          style="height: 32px; line-height: 16px; margin-right: 16px"
          [href]="installUrl"
          color="primary"
          mat-stroked-button
        >
          <mat-icon *ngIf="loading" class="spin">loop</mat-icon>
          <span>Re-authenticate</span>
        </a>
      </ng-container>
      <button
        mat-flat-button
        color="primary"
        grecoConnectTypeformForm
        [accountId]="typeformAccount.id"
        style="height: 32px; line-height: 16px"
        (success)="refresh()"
      >
        Connect Form
      </button>
    </div>

    <div style="padding: 0 16px">
      <mat-form-field appearance="standard">
        <mat-label>Typeform Account</mat-label>

        <input matInput required readonly [value]="typeformAccount?.email || ''" />

        <ng-container matSuffix *ngIf="!typeformAccount">
          <a
            *grecoLet="installUrl$ | async as installUrl"
            [disabled]="loading || !installUrl"
            style="height: 32px; line-height: 16px; margin-right: 16px"
            [href]="installUrl"
            color="primary"
            mat-flat-button
          >
            <mat-icon *ngIf="loading" class="spin">loop</mat-icon>
            <span>Connect</span>
          </a>
        </ng-container>
      </mat-form-field>
    </div>

    <greco-table [data]="(forms$ | async) || []">
      <mat-icon *grecoTableCol="''; let form; fitContent: true">assignment</mat-icon>
      <p *grecoTableCol="'Title'; let form">{{ form.formTitle }}</p>
    </greco-table>
  </greco-collapsible-section>
</ng-container>
