import { SecurityResource } from '@greco/security';

export enum CommunityResourceAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export const CommunityResource: SecurityResource = {
  key: 'identity_community',
  module: 'Identity',

  title: 'Community',
  description: 'Manage community and its settings',

  context: class {
    communityId?: string;
    parentId?: string;
  },

  actions: [
    { key: CommunityResourceAction.CREATE, title: 'Create', description: 'Ability to create a new community' },
    { key: CommunityResourceAction.UPDATE, title: 'Update', description: 'Ability to update a community' },
  ],
};
