<greco-simple-dialog #dialog [data]="dialogData">
  <greco-table style="margin: 0 -24px" [data]="(tileGroups$ | async) || []" [highlight]="true" [loading]="loading">
    <ng-container>
      <ng-container *grecoTableCol="'Group Name'; let tileGroup; let index = index">
        <mat-form-field appearance="standard" class="tile-group-mat-form-field">
          <input matInput type="text" placeholder="GroupName" [formControl]="tileGroup.nameFormControl" />
        </mat-form-field>
      </ng-container>
    </ng-container>

    <ng-container>
      <ng-container *grecoTableCol="'Group Icon'; let tileGroup; let index = index">
        <mat-form-field appearance="standard" class="tile-group-mat-form-field">
          <greco-icon-picker [formControl]="tileGroup.iconFormControl"></greco-icon-picker>
        </mat-form-field>
      </ng-container>
    </ng-container>
    <ng-container>
      <ng-container *grecoTableCol="'Priority' let tileGroup; let index = index; fitContent: true">
        <mat-form-field appearance="standard" class="tile-group-mat-form-field" style="width: fit-content">
          <input
            matInput
            type="number"
            placeholder="Priority"
            [formControl]="tileGroup.priorityFormControl"
            style="width: 48px"
          />
        </mat-form-field>
      </ng-container>
    </ng-container>

    <!-- Status  -->
    <ng-container>
      <p
        *grecoTableCol="'Visible To User'; let tileGroup;"
        [matTooltip]="tileGroup.group.enabled ? 'Visible': 'Not Visible'"
      >
        <mat-slide-toggle
          #slider
          color="primary"
          [checked]="tileGroup.group.enabled"
          (change)="updateStatus(tileGroup.group,$event)"
        ></mat-slide-toggle>
      </p>
    </ng-container>

    <!-- Group Link  -->
    <ng-container>
      <p *grecoTableCol="'Group Link'; let tileGroup;" matTooltip="copy group link">
        <button (click)="$event.stopImmediatePropagation(); copyTileGroupUrlToClipboard(tileGroup)" mat-icon-button>
          <mat-icon>link</mat-icon>
        </button>
      </p>
    </ng-container>

    <!-- update btns section -->

    <div *grecoTableCol="''; let tileGroup; let index = index; stickyEnd:true; fitContent: true">
      <button
        (click)="updateTileGroup(tileGroup)"
        mat-icon-button
        [disabled]="tileGroup.nameFormControl.invalid || tileGroup.iconFormControl.invalid 
        || tileGroup.priorityFormControl.invalid|| (tileGroup.nameFormControl.pristine && tileGroup.iconFormControl.pristine)
        || (tileGroup.nameFormControl.value === tileGroup.group.name && tileGroup.iconFormControl.value === tileGroup.group.icon 
        && tileGroup.priorityFormControl.value === tileGroup.group.priority)"
      >
        <mat-icon>save</mat-icon>
      </button>

      <button
        (click)="resetTileGroup(tileGroup)"
        mat-icon-button
        *ngIf=" tileGroup.nameFormControl.value !== tileGroup.group.name || tileGroup.iconFormControl.value !== tileGroup.group.icon 
        || tileGroup.group.priorty !== tileGroup.priorityFormControl.value"
      >
        <mat-icon> restart_alt</mat-icon>
      </button>
    </div>
  </greco-table>
</greco-simple-dialog>
