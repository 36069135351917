import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from 'ckeditor5-build-classic-base64-upload-adapter';

export interface TokenizedValue {
  value: string;
  tokens?: SubstitutionToken[];
}

export interface SubstitutionToken {
  key?: string;
  value?: string;
}

export function substitutedValue(data: TokenizedValue) {
  return data.tokens?.reduce(
    (acc, cur) => acc.replace(new RegExp('{{' + cur.key + '}}', 'g'), cur.value || ''),
    data.value
  );
}

@Component({
  selector: 'greco-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent {
  private static defaultText = '<i>Start Typing here..</i>';

  @Output() valueChanged: EventEmitter<TokenizedValue> = new EventEmitter<TokenizedValue>();
  @Input() showSubstitutions = false;
  @Input() customDisabled = false;

  _data: TokenizedValue = { tokens: [], value: EditorComponent.defaultText };
  @Input() set data(value: TokenizedValue) {
    this._data = value;
    this.currentValue = this._data?.value;
  }
  get data() {
    return this._data;
  }
  @Input() config = {
    mediaEmbed: {
      previewsInData: true, // Necessary for video embeds
    },
    // toolbar: [ 'heading', 'bold', 'italic', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'imageUpload',  'link' ]
  };

  editor = ClassicEditor;

  private currentValue: string = this.data?.value;

  addSub() {
    this.data.tokens?.push({});
    this.emitChange();
  }

  removeSub(sub: SubstitutionToken) {
    const index = this.data.tokens?.indexOf(sub) || -1;
    if (index >= 0) {
      this.data.tokens?.splice(index, 1);
      this.emitChange();
    }
  }

  editorChanged({ editor }: ChangeEvent) {
    this.currentValue = editor.getData();
    this.emitChange();
  }

  emitChange() {
    this.valueChanged.emit({ tokens: this.data.tokens || [], value: this.currentValue });
  }
}
