<ng-container *grecoLet="canRead$ | async as canRead">
  <ng-container *grecoLet="canCreate$ | async as canCreate">
    <ng-container *grecoLet="canArchive$ | async as canArchive">
      <ng-container *grecoLet="canGrant$ | async as canGrant">
        <ng-container *grecoLet="canUpdate$ | async as canUpdate">
          <ng-container *ngIf="canRead">
            <greco-collapsible-section #section [header]="{ title: 'Coupon Details', icon: 'info' }" [expanded]="false">
              <ng-container header>
                <mat-chip [matMenuTriggerFor]="menu" style="cursor: pointer" *ngIf="canGrant || canArchive">
                  <span>Actions</span>
                  <mat-icon style="font-size: 22px; width: 22px; height: 22px; margin-bottom: -2px">
                    arrow_drop_down
                  </mat-icon>
                </mat-chip>
                <mat-menu #menu>
                  <button
                    mat-menu-item
                    [disabled]="coupon.community?.id !== communityId"
                    (click)="_grantCoupon(coupon)"
                    *ngIf="canGrant && !coupon.archivedOn"
                  >
                    <mat-icon>person_add</mat-icon>
                    <span>Grant Coupon</span>
                  </button>
                  <button
                    *ngIf="canArchive"
                    mat-menu-item
                    (click)="coupon.archivedOn ? restore(coupon) : archive(coupon)"
                  >
                    <mat-icon>{{ coupon.archivedOn ? 'restore' : 'archive' }}</mat-icon>
                    <span>{{ coupon.archivedOn ? 'Restore' : 'Archive' }}</span>
                  </button>
                </mat-menu>
              </ng-container>

              <greco-form-save-buttons
                *ngIf="section.expanded"
                header
                [form]="formGroup"
                [saveAction]="update"
                [resetValue]="resetValue"
                (formReset)="formGroup.markAsPristine()"
              ></greco-form-save-buttons>

              <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
                <mat-form-field>
                  <mat-label>Title</mat-label>
                  <input
                    placeholder="This title will be visible in the shop and on purchases"
                    matInput
                    type="text"
                    required
                    formControlName="title"
                    [readonly]="processing || !canUpdate || couponArchived"
                  />
                </mat-form-field>

                <mat-form-field class="hidden">
                  <mat-label>Short Title</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="shortTitle"
                    [readonly]="processing || !canUpdate || couponArchived"
                  />
                </mat-form-field>

                <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
                  <mat-label>Icon</mat-label>
                  <greco-icon-picker
                    formControlName="icon"
                    [required]="true"
                    [disabled]="!canUpdate || (coupon.archivedOn ? true : false)"
                    >{{formGroup.value.icon}}</greco-icon-picker
                  >
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Description</mat-label>
                  <textarea
                    matInput
                    [matTextareaAutosize]="true"
                    formControlName="description"
                    [readonly]="processing || !canUpdate || couponArchived"
                  ></textarea>
                </mat-form-field>

                <mat-form-field style="margin: 4px">
                  <mat-label>Discount Type</mat-label>
                  <mat-select
                    required
                    style="margin: 2px"
                    formControlName="discountType"
                    (valueChange)="discountValue.value=''"
                    [(value)]="discountUnit"
                    [disabled]="!canUpdate || (coupon.archivedOn ? true : false)"
                  >
                    <mat-option *ngFor="let type of discountTypeArray" [value]="type.replace(' ','_').toUpperCase()"
                      >{{ type.replace('_', ' ') | titlecase }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Discount {{ coupon.discountType.replace('_', ' ') | titlecase }}</mat-label>
                  <input
                    placeholder="Specify a discount amount"
                    matInput
                    required
                    formControlName="discountAmount"
                    currencyMask
                    [options]="discountUnit=='FLAT_AMOUNT' ? flatAmountMaskConfig : percentageMaskConfig"
                    #discountValue
                    [disabled]="!canUpdate || couponArchived"
                    [readonly]="!canUpdate || couponArchived"
                  />
                </mat-form-field>

                <!-- <mat-form-field>
                  <mat-label>Created Date</mat-label>
                  <input matInput type="datetime" [disabled]="true" [readonly]="true" formControlName="created" />
                </mat-form-field> -->

                <div style="display: flex; flex-direction: column; gap: 8px 24px; flex-wrap: wrap; margin-top: 15px">
                  <mat-checkbox
                    formControlName="allProducts"
                    color="primary"
                    [checked]="coupon.allProducts"
                    [disabled]="!canUpdate  || (coupon.archivedOn ? true : false)"
                    >Available To All Products</mat-checkbox
                  >
                  <!-- <mat-checkbox
        formControlName="availableInSubCommunities"
        [checked]="coupon.availableInSubCommunities"
        color="primary"
        >Available In Sub-Communities</mat-checkbox
      > -->
                </div>
              </form>
            </greco-collapsible-section>

            <greco-coupon-assignment-table [coupon]="coupon" *ngIf="canRead"></greco-coupon-assignment-table>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
