import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Booking, CourseRegistration } from '@greco/booking-events';
import type { BookDto, CourseConfirmationDto, CoursePreview } from '@greco/nestjs-booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

const _waitlistCache = new Map<string, boolean>();
const _datedBookingsCache = new Map<string, Booking[]>();

@Injectable()
export class CourseService {
  constructor(private http: HttpClient) {}

  // @Get()
  async getRegistrationByCourse(
    query: RequestQueryBuilder,
    courseId?: string,
    pagination?: Partial<PaginatedQueryParams>
  ) {
    return await toPromise(
      this.http.get<PaginatedDto<CourseRegistration>>('/api/course', {
        params: {
          ...query.queryObject,
          ...(courseId && { courseId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get()
  async getAllRegistrationsByCourseId(courseId: string): Promise<CourseRegistration[]> {
    return await toPromise(this.http.get<CourseRegistration[]>(`/api/course/registrations/${courseId}`));
  }

  async checkForBookingClash(courseId: string, userId: string): Promise<boolean> {
    return await toPromise(this.http.get<boolean>(`/api/course/clash/${courseId}/${userId}`));
  }

  async coursePaginate(query: RequestQueryBuilder, courseId: string, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<CourseRegistration>>('/api/course/getRegistrationByCourse', {
        params: {
          ...query.queryObject,
          ...(courseId && { courseId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Post('preview')
  async preview(dto: BookDto, skipErrorHandler = false) {
    return await toPromise(
      this.http.post<CoursePreview>('/api/course/preview', dto, {
        headers: { 'X-GrecoIgnoreErrors': skipErrorHandler.toString() },
      })
    );
  }

  // @Post('confirm')
  async confirmRegistration(preview: CourseConfirmationDto) {
    const booking = await toPromise(this.http.post<CourseRegistration>('/api/course/confirmRegistration', preview));

    const cacheKey = `${preview.booking.eventId}_${preview.booking.userId}`;
    _waitlistCache.set(cacheKey, false);
    _datedBookingsCache.clear();

    return booking;
  }

  // @Post('confirm/multiple')
  async confirmMultiple(previews: CourseConfirmationDto[]) {
    const bookings = await toPromise(
      this.http.post<Booking[]>('/api/course/confirmRegistration/multiple', { dtos: previews })
    );
    for (const preview of previews) {
      const cacheKey = `${preview.booking.eventId}_${preview.booking.userId}`;
      _waitlistCache.set(cacheKey, false);
    }
    _datedBookingsCache.clear();

    return bookings;
  }

  // Post(':courseId/image')
  async uploadCourseImage(id: string, data: FormData): Promise<string> {
    const result = await toPromise(this.http.post<{ imageURL: string }>(`/api/course/${id}/image`, data));
    return result.imageURL;
  }

  // Post(':courseId/image')
  async removeCourseImage(id: string): Promise<boolean> {
    return await toPromise(this.http.delete<boolean>(`/api/course/${id}/removeImage`));
  }

  //@Post(':courseId/cancel')
  async cancel(courseRegId: string, freeOfCharge?: boolean) {
    return await toPromise(
      this.http.post<CourseRegistration>(`/api/course/${courseRegId}/cancel`, null, {
        params: {
          freeOfCharge: (freeOfCharge || false).toString(),
        },
      })
    );
  }

  async getOneRegistration(userId: string, courseId: string) {
    const response = await toPromise(
      this.http.get<any>(`/api/course/registration`, {
        params: {
          ...(courseId && { courseId }),
          ...(userId && { userId }),
        },
      })
    );

    return response;
  }

  async getOneByCourseRegistrationId(courseRegistrationId: string) {
    const response = await toPromise(
      this.http.get<CourseRegistration>(`/api/course/courseRegistration/${courseRegistrationId}`, {
        params: {
          ...(courseRegistrationId && { courseRegistrationId }),
        },
      })
    );
    return response;
  }

  // All these calls can be implemented in the future for more course functionality
  // @Get('by-date')
  // async getByDate(dto: QueryBookingsByDateDto) {
  //   const key = `${dto.startDate.toISOString()}_${dto.endDate.toISOString()}_${dto.userId}_${dto.communityId}`;
  //   if (_datedBookingsCache.has(key)) return _datedBookingsCache.get(key) as Booking[];

  //   const bookings = await toPromise(
  //     this.http.get<Booking[]>('/api/bookings/by-date', {
  //       params: {
  //         endDate: dto.endDate.toISOString(),
  //         startDate: dto.startDate.toISOString(),
  //         ...(dto.userId && { userId: dto.userId }),
  //         ...(dto.communityId && { communityId: dto.communityId }),
  //       },
  //     })
  //   );

  //   _datedBookingsCache.set(key, bookings);
  //   return bookings;
  // }

  // @Get(':bookingId')
  // async getOne(bookingId: string) {
  //   return await toPromise(this.http.get<Booking>(`/api/bookings/${bookingId}`));
  // }

  // async noShow(bookingId: string) {
  //   _datedBookingsCache.clear();
  //   return await toPromise(this.http.post<Booking>(`/api/bookings/${bookingId}/no-show`, null, {}));
  // }

  // async checkIn(bookingId: string) {
  //   _datedBookingsCache.clear();
  //   return await toPromise(this.http.post<Booking>(`/api/bookings/${bookingId}/check-in`, null, {}));
  // }

  // @Post('waitlist')
  // async joinWaitlist(dto: WaitlistDto) {
  //   const item = await toPromise(this.http.post<WaitlistItem>('/api/bookings/waitlist', dto));

  //   const cacheKey = `${dto.eventId}_${dto.userId}`;
  //   _waitlistCache.set(cacheKey, true);

  //   return item;
  // }

  // @Get('waitlist/:eventId/:userId')
  // private async inWaitlist(eventId: string, userId: string) {
  //   return await toPromise(this.http.get<boolean>(`/api/bookings/waitlist/${eventId}/${userId}`));
  // }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  // async userInWaitlist(eventId: string, userId: string): Promise<boolean> {
  //   const key = `${eventId}_${userId}`;
  //   if (_waitlistCache.has(key)) return _waitlistCache.get(key) as boolean;

  //   const isInWailist = await this.inWaitlist(eventId, userId);

  //   _waitlistCache.set(key, isInWailist);
  //   return isInWailist;
  // }

  // getUserWaitlist(userId: string) {
  //   return toPromise(this.http.get<string[]>(`/api/bookings/waitlist/${userId}`));
  // }
}
