<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <mat-horizontal-stepper labelPosition="bottom" #stepper>
      <mat-step>
        <ng-template matStepLabel>Start</ng-template>

        <ng-container *ngTemplateOutlet="dateStepTemplate; context: { $implicit: 'start' }"></ng-container>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>End</ng-template>

        <ng-container *ngTemplateOutlet="dateStepTemplate; context: { $implicit: 'end' }"></ng-container>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Reason</ng-template>
        <div [style.display]="'block'">
          <mat-form-field style="margin-top: 6px" appearance="outline" class="custom">
            <mat-label>Reason</mat-label>
            <mat-select required formControlName="reason" [disabled]="disableReason">
              <mat-option *ngFor="let reason of updateReasons" [value]="reason">{{ reason }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-step>

      <ng-template #dateStepTemplate let-startOrEnd>
        <ng-container *grecoLet="(startOrEnd === 'start' ? disableStart : disableEnd) as disabled">
          <div style="display: flex; align-items: flex; gap: 8px; margin-top: 6px">
            <!-- Update Type  -->
            <mat-radio-group
              [formControlName]="startOrEnd + 'DateRadioButton'"
              aria-label="Choose a date: "
              [disabled]="disabled || false"
            >
              <mat-radio-button
                *ngIf="startOrEnd === 'end'"
                style="margin-left: 16px"
                color="primary"
                value="indefinite"
                (click)="setDate(startOrEnd, 'indefinite')"
              >
                Indefinite
              </mat-radio-button>
              <mat-radio-button
                *ngIf="startOrEnd === 'start' || (startOrEnd ==='end' && formGroup.value.startDate && formGroup.value.startDate.getTime() < now)"
                style="margin-left: 16px"
                color="primary"
                value="now"
                (click)="setDate(startOrEnd, 'now')"
              >
                Now
              </mat-radio-button>
              <mat-radio-button
                *ngIf="startOrEnd === 'start' && subscription?.periodEnd"
                style="margin-left: 16px"
                color="primary"
                value="endOfPeriod"
                (click)="setDate(startOrEnd, 'endOfPeriod')"
              >
                At the end of current period
              </mat-radio-button>
              <mat-radio-button style="margin: 10px" color="primary" value="custom"> Custom Date </mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- Update Date  -->
          <div>
            <mat-form-field
              *ngIf="formGroup.value[startOrEnd + 'DateRadioButton'] == 'custom'"
              style="margin-top: 6px"
              appearance="fill"
            >
              <mat-label>Choose a date</mat-label>
              <input
                matInput
                [min]="minimalDate"
                [max]="maximalDate"
                [matDatepicker]="picker"
                (click)="picker.open()"
                [formControlName]="startOrEnd + 'Date'"
                (dateChange)="setCustomDate(startOrEnd, $event.value)"
                [disabled]="disabled || false"
              />
              <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="disabled || false"></mat-datepicker-toggle>
              <mat-datepicker #picker [disabled]="disabled || false"></mat-datepicker>
            </mat-form-field>
          </div>
        </ng-container>
        <button mat-stroked-button matStepperNext style="margin-left: auto; margin-top: 16px">
          Continue
          <mat-icon>chevron_right</mat-icon>
        </button>
      </ng-template>
    </mat-horizontal-stepper>
  </form>

  <button
    mat-flat-button
    [disabled]="processing || !formGroup.value.startDate || !formGroup.value.reason"
    type="button"
    (click)="submit()"
    color="primary"
    style="margin-left: auto; margin-top: 16px; position: sticky; bottom: 0; z-index: 999"
    [style.display]="(stepper.steps.length - 1) === stepper.selectedIndex ? 'block' : 'none'"
  >
    <span>Confirm Change</span>
    <mat-progress-spinner
      diameter="18"
      mode="indeterminate"
      *ngIf="processing"
      style="filter: grayscale(1) opacity(0.5)"
    ></mat-progress-spinner>
    <mat-icon>check</mat-icon>
  </button>
</greco-simple-dialog>
