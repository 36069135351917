import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableModule } from '@greco/ui-table';
import { CalendarCommonModule, CalendarDayModule, CalendarMonthModule, CalendarWeekModule } from 'angular-calendar';
import { CalendarComponent, GroupedDayViewComponent } from './components';
import { ResponsiveDateFormatter } from './providers';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    // Angular
    CommonModule,

    // Material
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatButtonToggleModule,

    // Angular Calendar
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,

    // Greco
    TableModule,
    MatSlideToggleModule,
    FormsModule,

    // Other
  ],
  declarations: [
    // Components
    CalendarComponent,
    GroupedDayViewComponent,
  ],
  exports: [
    // Components
    CalendarComponent,
  ],
  providers: [ResponsiveDateFormatter],
})
export class GrecoCalendarModule {}
