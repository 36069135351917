import { Component, Injectable, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Coupon, CouponSecurityAction, CouponSecurityResource } from '@greco/coupon';

import { BuildSearchFilter, ToggleFilter } from '@greco/ngx-filters';
import { PerkBadgeEditDialog } from '@greco/ngx-sales-perks';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CreateCouponDialog, DisplayCouponStatsDialog, GrantCouponDialog } from '../../dialogs';
import { CouponService } from '../../services';

@Injectable({ providedIn: 'any' })
export class CouponSearchFilter extends BuildSearchFilter('CouponSearchFilter', {
  properties: ['title', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Injectable({ providedIn: 'any' })
export class CouponToggleArchivedFilter extends ToggleFilter {
  constructor() {
    super('CouponToggleArchivedFilter', {
      label: 'Show Archived',
      shortLabel: 'Show Archived',
      description: '',
      properties: ['deleted'],
    });
  }

  getValueLabel(value: any): string {
    return value ? 'Show Archived' : 'Hide Archived';
  }
  getValueOptions(search?: string | undefined): any[] | Promise<any[]> {
    if (search?.toLowerCase() === 'archived') {
      return [true];
    } else if (search?.toLowerCase() === 'active') {
      return [false];
    } else {
      return [];
    }
  }
  serializeValue(value: any): string {
    return value.toString();
  }
  deserializeValue(serializedValue: string) {
    return new Boolean(serializedValue);
  }
}

@Component({
  selector: 'greco-coupons-table',
  templateUrl: './coupons-table.component.html',
  styleUrls: ['./coupons-table.component.scss'],
})
export class CouponsTableComponent implements OnDestroy {
  constructor(
    private couponSvc: CouponService,
    private route: ActivatedRoute,
    private snacks: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private securitySvc: SecurityService
  ) {}

  @Input() communityId: string | null = null;
  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string | null>(null);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  filterOptions = [CouponSearchFilter, CouponToggleArchivedFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  paginationMeta?: null | IPaginationMeta = null;
  refresh$ = new BehaviorSubject(null);
  loading = true;

  coupons$ = combineLatest([this._communityId$, this.pagination$, this.filters$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([communityId, pagination, filters]) => {
      // Removing deleted from parsed.search and setting the value to parsed.includeDeleted
      if (filters) {
        const parsedFilters = JSON.parse(filters?.queryObject.s || '{}');
        if (parsedFilters.$and) {
          const deletedFilter = parsedFilters.$and.findIndex((item: { deleted: any }) => !!item.deleted);
          if (deletedFilter != -1 && parsedFilters.$and[deletedFilter].deleted.$eq === true)
            filters?.setIncludeDeleted(1);
          if (deletedFilter != -1) parsedFilters.$and.splice(deletedFilter);
          filters.queryObject.s = JSON.stringify(parsedFilters);
        }
      }

      return communityId ? await this.couponSvc.paginate(communityId, pagination, filters) : null;
    }),
    tap(data => (this.paginationMeta = data?.meta || null)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  readonly canRead$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.READ, {
            communityId,
          })
        : false;
    })
  );

  readonly canCreate$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.CREATE, {
            communityId,
          })
        : false;
    })
  );

  readonly canUpdate$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.UPDATE, {
            communityId,
          })
        : false;
    })
  );

  readonly canArchive$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.ARCHIVE, {
            communityId,
          })
        : false;
    })
  );

  readonly canGrant$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.GRANT, {
            communityId,
          })
        : false;
    })
  );

  async createCoupon() {
    const coupon = await toPromise(
      this.dialog
        .open(CreateCouponDialog, { data: { communityId: this.communityId }, width: '600px', maxWidth: '90%' })
        .afterClosed()
    );
    if (coupon?.id) this.router.navigate([coupon.id], { relativeTo: this.route });
  }

  async _grantCoupon(coupon?: Coupon) {
    await toPromise(
      this.dialog
        .open(GrantCouponDialog, {
          data: {
            communityId: this.communityId,
            selectedCoupon: coupon,
          },
          width: '600px',
          maxWidth: '90%',
        })
        .afterClosed()
    );
    this.refresh$.next(null);
  }

  async _viewCoupon(coupon: Coupon) {
    await this.router.navigate([coupon.id], { relativeTo: this.route });
  }

  getProductCount(couponAssignments: any[]) {
    return couponAssignments.filter(coupon => coupon.productId !== null && coupon.productId !== undefined).length;
  }

  getSaleCategoryCount(couponAssignments: any[]) {
    return couponAssignments.filter(coupon => coupon.saleCategoryId != null && coupon.saleCategoryId !== undefined)
      .length;
  }

  async _viewCouponsStats(coupon: any) {
    this.dialog
      .open(DisplayCouponStatsDialog, {
        data: {
          coupon: coupon,
        },
        width: '400px',
        maxWidth: '90%',
      })
      .afterClosed();
  }

  async updatePerkBadge(coupon: Coupon) {
    const dialogRef = this.dialog.open(PerkBadgeEditDialog, {
      data: {
        coupon,
      },
      width: '750px',
      maxWidth: '90%',
    });
    await toPromise(dialogRef.afterClosed());
  }

  async archive(coupon: Coupon) {
    const dialog = this.dialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: `Confirm Archive of ${coupon.title}`,
        content: `This will prevent the coupon from being granted to any products/sales category in the future.`,
        buttons: [
          { label: "No, Don't Archive", role: 'no' },
          { label: 'Yes, Archive', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      try {
        await this.couponSvc.archive(coupon.id);
        this.snacks.open('Coupon archived', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err: any) {
        this.snacks.open(err?.error?.message, 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
      }
    }
    this.refresh$.next(null);
  }

  async restore(coupon: Coupon) {
    const dialog = this.dialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: `Confirm Restoration of ${coupon.title}`,
        content: `This will allow the coupon to be granted to products/sales categories.`,
        buttons: [
          { label: "No, Don't Unarchive", role: 'no' },
          { label: 'Yes, Unarchive', role: 'yes' },
        ],
      },
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      try {
        await this.couponSvc.restore(coupon.id);
        this.snacks.open('Coupon Restored', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err: any) {
        this.snacks.open(err?.error?.message, 'Ok!', { duration: 2500, panelClass: 'mat-warn' });
      }
    }
    this.refresh$.next(null);
  }

  ngOnDestroy() {
    this._communityId$.complete();
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
