import { Pipe, PipeTransform } from '@angular/core';
import { VariantOption } from '@greco/sales-products';

@Pipe({ name: 'options' })
export class VariantOptionPipe implements PipeTransform {
  transform(variantOptions: VariantOption[], productOptionId: string): string {
    let result = '-';
    variantOptions.forEach(variantOption => {
      if (variantOption.optionId === productOptionId) result = variantOption.value;
    });
    return result;
  }
}
