<greco-simple-dialog [data]="dialogData" *grecoLet="inventory$ | async as inventory">
  <greco-table *ngIf="inventory; else loading" [data]=" [inventory] | inventory">
    <p *grecoTableCol="'Variant'; let inventory">{{ inventory.variant | variantTitle }}</p>
    <p *grecoTableCol="'Lifetime Stock'; let inventory">{{ inventory.totalStock }}</p>
    <p *grecoTableCol="'Lifetime Sold'; let inventory">{{ inventory.quantitySold || 0 }}</p>
    <p *grecoTableCol="'Available'; let inventory">
      {{ inventory.availableInventoryCount || 0 }}
      <span>{{ formGroup.get('restock')?.value | restock : inventory.availableInventoryCount || 0 }}</span>
    </p>
    <span *grecoTableCol="''; let inventory; stickyEnd: true; fitContent: true" style="width: 48px">
      <ng-container>
        <button
          *ngIf="formGroup.value.restock === null"
          mat-icon-button
          type="button"
          color="primary"
          style="
            border-radius: 100%;
            background-color: var(--primary-color);
            --icon-size: 28px;
            height: var(--icon-size);
            width: var(--icon-size);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
          (click)="addRestock()"
        >
          <span style="color: white; font-size: 12px">+/-</span>
        </button>
        <form
          [formGroup]="formGroup"
          *ngIf="formGroup.value.restock !== null"
          style="display: flex; flex-direction: row; justify-content: space-between; gap: 4px"
        >
          <input matInput style="width: 48px; text-align: center" type="number" formControlName="restock" />
          <button mat-icon-button type="button" color="warn" (click)="removeRestock()">
            <mat-icon
              style="
                color: var(--warn-color);
                --icon-size: 28px;
                height: var(--icon-size);
                width: var(--icon-size);
                font-size: var(--icon-size);
              "
              >cancel</mat-icon
            >
          </button>
        </form>
      </ng-container>
    </span>
  </greco-table>
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-template>
</greco-simple-dialog>
