import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DefaultSaleCategoryDefinitions, DEFAULT_SALE_CATEGORY_DEFINITIONS } from '../../default-sale-category.token';
import { SaleCategoryService } from '../../services';

@Component({
  templateUrl: './default-sale-categories.dialog.html',
  styleUrls: ['./default-sale-categories.dialog.scss'],
})
export class DefaultSaleCategoriesDialog {
  saving = false;

  formGroup?: FormGroup;

  constructor(
    @Inject(DEFAULT_SALE_CATEGORY_DEFINITIONS) public readonly forms: DefaultSaleCategoryDefinitions[],
    @Inject(MAT_DIALOG_DATA) public accountId: string,
    private dialogRef: MatDialogRef<DefaultSaleCategoriesDialog>,
    private categorySvc: SaleCategoryService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {
    if (!accountId) throw new Error('Missing Account ID');
    this.initFormGroup();
  }

  private async initFormGroup() {
    const accountDefaults = await this.categorySvc.getAccountDefaults(this.accountId);
    this.formGroup = this.formBuilder.group({
      defaults: this.formBuilder.array(
        this.forms.map(form => {
          return accountDefaults.find(d => d.type === form.type)?.saleCategory || null;
        })
      ),
    });
  }

  close() {
    this.dialogRef.close();
  }

  async save() {
    this.saving = true;

    try {
      if (this.formGroup && this.forms.length) {
        const saveDefault = (type: string, saleCategoryId: string) => {
          return this.categorySvc.setAccountDefault(this.accountId, { type, saleCategoryId });
        };

        await Promise.all(
          this.forms.map((form, i) => {
            return saveDefault(form.type, this.formGroup?.value.defaults[i]?.id || null);
          })
        );
      }

      this.snacks.open('Sale category mappings saved!', 'Ok', { duration: 4000, panelClass: 'mat-primary' });
      this.close();
    } catch (err) {
      console.error(err);
    }

    this.saving = false;
  }
}
