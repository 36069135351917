import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Tax } from '@greco/finance-tax';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AccountTaxService } from '../../services';
@Component({
  selector: 'greco-account-taxes-picker',
  templateUrl: './account-taxes-picker.component.html',
  styleUrls: ['./account-taxes-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: AccountTaxesPickerComponent }],
})
export class AccountTaxesPickerComponent implements OnDestroy {
  private static ID = 0;
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef,
    private taxSvc: AccountTaxService
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }
  @PropertyListener('accountId') accountId$ = new BehaviorSubject<string | null>(null);
  @Input() accountId!: string;
  private onDestroy$ = new Subject<void>();

  _form = this.formBuilder.group({
    search: [null],
    taxes: [null],
  });

  get currentSearchValue() {
    return (this._form.get('search')?.value as string) || null;
  }

  readonly taxes$ = combineLatest([
    this.accountId$,
    this.taxesControl.valueChanges.pipe(startWith(null)),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._form.get('search')!.valueChanges.pipe(startWith(null)),
  ]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    switchMap(async ([accountId, current, search]) => {
      return accountId
        ? (
            await this.taxSvc.paginate(
              new RequestQueryBuilder().search({
                ...(search && { ['tax.title']: { $contL: search } }),
                ...(current?.length && { taxId: { $notin: current.map((tax: Tax) => tax.id) } }),
              }),
              accountId
            )
          ).items.map(accTax => accTax.tax)
        : [];
    })
  );

  @ViewChild(MatInput) private matInput?: MatInput;
  @ViewChild(MatAutocompleteTrigger) private trigger?: MatAutocompleteTrigger;

  private get taxesControl() {
    return this._form.get('taxes') as AbstractControl;
  }

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-account-taxes-picker';
  @HostBinding() readonly id = `${AccountTaxesPickerComponent.ID++}-${this.controlType}`;

  set value(value: Tax[]) {
    const currentValue = this.taxesControl.value;
    if (currentValue !== value) {
      this.taxesControl.setValue(value);
      this._form.get('search')?.setValue('');
      this.stateChanges.next();
    }
  }
  get value(): Tax[] {
    return this.taxesControl.value;
  }

  get focused() {
    return !!this.matInput?.focused;
  }

  get empty() {
    const control = this._form.get('taxes');
    return !!control?.value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    this.taxesControl.setValidators(this._required ? [Validators.required, Validators.minLength(1)] : []);
    this._form.updateValueAndValidity();

    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;

    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.taxesControl.invalid && this._form.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-account-taxes-picker-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.matInput?.onContainerClick();
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.taxesControl.valueChanges.pipe(startWith(null), takeUntil(this.onDestroy$)).subscribe(() => fn(this.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_fn: any) {}

  remove(tax: Tax) {
    this.value = this.value?.filter(t => t.id != tax.id) || null;
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (!this.value?.find(t => event.option.value.title.toLowerCase() === t.title.toLowerCase())) {
      this.value = [...(this.value || []), event.option.value];
    }
    this._form.get('search')?.setValue('');
    this.openAutocomplete();
  }

  openAutocomplete() {
    window.requestAnimationFrame(() => {
      this.trigger?.openPanel();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.stateChanges.complete();
  }
}
