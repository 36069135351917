<ng-container *ngIf="community && (canReadCalendar$ | async)">
  <greco-filter-bar [hideSeparator]="true" [filterOptions]="filterOptions$" (changed)="query$.next($event[1])">
    <button
      *ngIf="canCreateCalendar$ | async;"
      mat-stroked-button
      color="primary"
      style="height: 32px"
      (click)="createCalendar()"
    >
      <span>New Calendar</span>
    </button>
  </greco-filter-bar>

  <greco-calendars-table
    [communityId]="community.id || ''"
    [filters]="query$.value"
    (rowClick)="openCalendar($event)"
  ></greco-calendars-table>
</ng-container>
