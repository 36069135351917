<greco-box [ngStyle]="{ border: noBorder ? 'none' : '' }">
  <div class="section" [class.disabled]="disabled">
    <div class="section-header" (click)="expand()">
      <div class="header">
        <h2
          *ngIf="header && !header.small"
          style="margin: 16px 0"
          [matBadge]="header.badge"
          [matBadgeHidden]="!header.badge"
          [matBadgeColor]="$any(header.badgeColor || 'primary')"
        >
          <mat-icon *ngIf="header.icon" [color]="header.iconColor">{{ header.icon }}</mat-icon>
          <mat-icon *ngIf="innerSection">format_list_bulleted</mat-icon>

          {{ header.title }}
          <mat-chip *ngIf="header.date">{{ header.date | date }}</mat-chip>
        </h2>

        <h4
          *ngIf="header && header.small && !header.itemized"
          [matBadge]="header.badge"
          [matBadgeHidden]="!header.badge"
          [matBadgeSize]="header.small ? 'small' : 'medium'"
          [matBadgeColor]="$any(header.badgeColor || 'primary')"
        >
          <mat-icon *ngIf="header.icon" [color]="header.iconColor">{{ header.icon }}</mat-icon>
          {{ header.title }}
        </h4>
        <div *ngIf="header && header.small && header.itemized" style="display: flex; flex-direction: row">
          <mat-icon
            *ngIf="header.icon"
            style="width: 20px; height: 20px; font-size: 20px; margin-left: 8px"
            [color]="header.iconColor"
            >{{ header.icon }}</mat-icon
          >
          <p
            style="margin: 0; padding: 0; text-align: center"
            [matBadge]="header.badge"
            [matBadgeHidden]="!header.badge"
            [matBadgeColor]="$any(header.badgeColor || 'primary')"
          >
            <span style="margin-left: 24px">{{ header.title }}</span>
          </p>
        </div>

        <ng-content select="[subheader]"></ng-content>
      </div>

      <div style="flex: 1; display: flex; align-items: center; justify-content: flex-end; gap: 8px">
        <ng-content select="[header]"></ng-content>

        <button mat-icon-button *ngIf="!disabled">
          <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
      </div>
    </div>

    <div class="section-content" [@heightExpansion]="expanded ? 'expanded' : 'collapsed'">
      <ng-content></ng-content>
    </div>
  </div>
</greco-box>
