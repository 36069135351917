import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Reader } from '@stripe/terminal-js';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { TerminalService } from '../../services';

@Component({
  selector: 'greco-manage-terminals',
  templateUrl: './manage-terminals.dialog.html',
  styleUrls: ['./manage-terminals.dialog.scss'],
})
export class ManageTerminalsDialog implements OnDestroy {
  private refresh$ = new BehaviorSubject<null>(null);

  private terminals$ = this.refresh$.pipe(
    switchMap(() => this.terminalSvc.getTerminalPaymentMethods()),
    shareReplay(1)
  );

  private readers$ = this.refresh$.pipe(
    switchMap(() => this.terminalSvc.discoverReaders()),
    tap(readers => console.log({ readers })),
    catchError(() => of(<Reader[]>[])),
    shareReplay(1)
  );

  readonly terminalsWithStatus$ = this.terminals$.pipe(
    switchMap(terminals =>
      this.readers$.pipe(
        map(readers =>
          terminals.map(terminal => ({
            ...terminal,
            status: readers.find(reader => reader.id === terminal.externalId)?.status ?? 'offline',
          }))
        )
      )
    )
  );

  readonly readersToConnect$ = this.readers$.pipe(
    switchMap(readers =>
      this.terminals$.pipe(
        map(terminals => readers.filter(reader => !terminals.some(term => term.externalId === reader.id)))
      )
    )
  );

  constructor(private dialogRef: MatDialogRef<ManageTerminalsDialog>, private terminalSvc: TerminalService) {}

  ngOnDestroy() {
    this.refresh$.complete();
  }

  close() {
    this.dialogRef.close();
  }

  refresh() {
    this.refresh$.next(null);
  }

  async connectReader(reader: Reader) {
    try {
      await this.terminalSvc.addReaderAsPaymentMethod(reader.id);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
  }

  async updateReaderLabel(id: string) {
    await this.terminalSvc.updateReaderLabel(id).then(() => this.refresh());
  }
}
