<greco-collapsible-section
  *grecoLet="canUpdateRoom$ | async as canUpdateRoom"
  [header]="{ title: 'Room Details', icon: 'info' }"
  [disabled]="true"
>
  <button
    *ngIf="canUpdateRoom"
    header
    mat-stroked-button
    [ngClass]='room.disabled ? "enabled" : "disabled"'
    [matTooltip]="'Click this to Enable/Disable the room'"
    (click)="setDisable()"
  >
    {{room.disabled ? 'Re-Enable' : 'Disable'}}
  </button>

  <greco-form-save-buttons
    header
    *ngIf="canUpdateRoom"
    [form]="form"
    [saveAction]="onSave"
    [resetValue]="initialValue"
  ></greco-form-save-buttons>

  <p *ngIf="room.disabled">This resource is disabled. It will not be selectable</p>

  <form [formGroup]="form">
    <mat-form-field appearance="standard">
      <mat-label>Display Name</mat-label>
      <input [readonly]="room.disabled" matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" [readonly]="room.disabled"></textarea>
    </mat-form-field>

    <mat-form-field floatLabel="always">
      <mat-label>Icon</mat-label>
      <greco-icon-picker
        placeholder="Pick an icon"
        [disabled]="room.disabled"
        formControlName="icon"
      ></greco-icon-picker>
    </mat-form-field>

    <!-- Room Images -->
    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
      <mat-label>Image</mat-label>
      <greco-image-upload
        [urls]="roomImageURL"
        (click)="form.markAsDirty()"
        formControlName="roomImage"
      ></greco-image-upload>
    </mat-form-field>

    <div class="spots">
      <div style="display: flex; justify-content: space-between; margin-bottom: 4px">
        <div style="display: flex">
          <mat-icon style="margin: 6px 8px -2px 0px">chair</mat-icon>
          <h3>Spots ({{form.value.spots.length || room.spotCount }})</h3>
        </div>

        <button *ngIf="canUpdateRoom && room.imageURL" mat-stroked-button (click)="configureSpots()">
          Configure Spots
        </button>
      </div>

      <greco-room-spots [room]="room" [canUpdate]="canUpdateRoom || false" formControlName="spots"></greco-room-spots>
    </div>
  </form>
</greco-collapsible-section>
