import { Perk } from '@greco/sales-perks';

export enum DiscountType {
  PERCENTAGE = 'PERCENTAGE',
  FLAT_AMOUNT = 'FLAT_AMOUNT',
}

export interface Coupon extends Perk {
  discountType: DiscountType;
  discountAmount: number;
  created: Date;
  allProducts: boolean;
  archivedOn?: Date;
}

export interface CouponAssignment {
  id: string;
  couponId: string;
  coupon?: Coupon;
  type: string;
  created: Date;
}
