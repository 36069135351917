import { Component, ContentChild, HostListener, Input } from '@angular/core';
import { Params } from '@angular/router';
import { InfoCardSelectComponent } from './components/select/select.component';

@Component({
  selector: 'greco-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent {
  @ContentChild(InfoCardSelectComponent) select: InfoCardSelectComponent;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() description: string;

  @Input() routerLink: string | any[];
  @Input() queryParams: Params;

  @HostListener('click') click() {
    this.select?.open?.();
  }

  get clickable() {
    return !!this.routerLink || !!this.select;
  }
}
