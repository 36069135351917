import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteSelectComponent } from './autocomplete-select.component';
import { AutocompleteSelectContentDirective } from './autocomplete-select.directive';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatAutocompleteModule],
  declarations: [AutocompleteSelectComponent, AutocompleteSelectContentDirective],
  exports: [AutocompleteSelectComponent, AutocompleteSelectContentDirective]
})
export class AutocompleteSelectComponentModule {}
