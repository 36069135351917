<greco-box
  [class]="color"
  [style]="(small ? 'min-height: 120px;' : '') + (clickable ? 'cursor:pointer;' : '')"
  (click)="$event.stopImmediatePropagation(); clicked($event)"
>
  <div [class]="small ? 'container-small' : 'container'">
    <div class="icon" [style]="small ? 'width:100%' : ''">
      <mat-icon [class]="'icon-' + color">{{ icon || 'warning' }}</mat-icon>
    </div>
    <div class="info" [style]="small ? 'margin-bottom: 8px' : ''">
      <div class="heading">
        <h3>{{ title }}</h3>
      </div>
      <div class="sub-heading">{{ subtitle }}</div>
    </div>
    <ng-content></ng-content>
    <ng-content select="[action]"></ng-content>
  </div>
</greco-box>
