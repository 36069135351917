import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ResourceType, ZoomResource } from '@greco/booking-events';
import { ZoomService } from '@greco/ngx-zoom';
import { ResourcesService } from '../services';
@Injectable({
  providedIn: 'root',
})
export class ZoomAuthenticationResolver implements Resolve<ZoomResource | undefined> {
  constructor(private resourcesSvc: ResourcesService, private zoomSvc: ZoomService, private router: Router) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const { code, state } = route.queryParams;
    if (!code || !state) return;

    const data = JSON.parse(state);
    const resource = await this.resourcesSvc.createZoomResource({
      redirect_uri: this.zoomSvc.redirectUrl,
      communityId: data.communityId,
      type: ResourceType.ZOOM,
      name: data.displayName,
      authCode: code,
    });

    if (resource) {
      await this.router.navigateByUrl(`/admin/scheduling/${data.communityId}/resources/${resource.id}`);
    }

    return resource;
  }
}
