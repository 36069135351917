<div class="stats-container" *grecoLet="_isMobile$ | async as isMobile">
  <ng-container *ngIf="failedPurchases$ | async as failedPurchases">
    <greco-user-outstanding-purchase-alert
      [small]="false"
      class="item"
      *ngFor="let failedPurchase of failedPurchases.items"
      [OutstandingPurchase]="failedPurchase"
    ></greco-user-outstanding-purchase-alert>
  </ng-container>
  <ng-container *ngIf="paymentMethods$ | async as expiringMethods">
    <greco-user-expiring-payment-method-alert
      [small]="false"
      class="item"
      *ngFor="let method of expiringMethods"
      [paymentMethod]="method.paymentMethod"
      [expired]="method.expired"
      [expiresOn]="method.expiresOn"
    ></greco-user-expiring-payment-method-alert>
  </ng-container>
</div>
