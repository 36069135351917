import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Account } from '@greco/finance-accounts';
import type { Stripe } from 'stripe';
import { PayoutService } from '../../services';

@Component({
  selector: 'greco-stripe-payouts-page',
  templateUrl: './payouts.page.html',
  styleUrls: ['./payouts.page.scss'],
})
export class StripePayoutsPage implements OnChanges {
  constructor(private payoutSvc: PayoutService) {}

  @Input() account!: Account;

  payouts: Stripe.Payout[] = [];
  hasMore = false;

  loading = false;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.account.previousValue !== changes.account.currentValue) {
      await this.loadPayouts();
    }
  }

  async loadPayouts(startAfter?: string): Promise<void> {
    this.loading = true;

    if (!startAfter) this.payouts = [];
    const data = await this.payoutSvc.paginatePayouts(this.account, startAfter);

    this.payouts = [...this.payouts, ...data.items];
    this.hasMore = data.hasMore;

    this.loading = false;
  }
}
