import { SecurityResource } from '@greco/security';

export const TILES_SECURITY_RESOURCE = 'tiles';
export enum TileSecurityResourceAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const TilesSecurityResource: SecurityResource = {
  key: TILES_SECURITY_RESOURCE,
  module: 'tiles',

  title: 'Quick Access Tiles',
  description: 'Manage quick access tiles',

  context: class {
    id?: string;
    communityId?: string;
  },

  actions: [
    { key: TileSecurityResourceAction.CREATE, title: 'Create tiles', description: 'Ability to create Tiles.' },
    { key: TileSecurityResourceAction.UPDATE, title: 'Update tiles', description: 'Abiliity to update Tiles.' },
    { key: TileSecurityResourceAction.DELETE, title: 'Delete tiles', description: 'Ability to delete Tiles.' },
  ],
};
