import { SecurityResource } from '@greco/security';

export const CALENDAR_SECURITY_RESOURCE = 'calendar';

export enum CalendarSecurityResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const CalendarSecurityResource: SecurityResource = {
  key: CALENDAR_SECURITY_RESOURCE,
  module: 'booking',

  title: 'Calendar',
  description: 'Community Calendars',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CalendarSecurityResourceAction.CREATE,
      title: 'Create calendars',
      description: 'Ability to create a calendar',
    },
    {
      key: CalendarSecurityResourceAction.UPDATE,
      title: 'Update calendars',
      description: 'Ability to change calendar details',
    },
    {
      key: CalendarSecurityResourceAction.DELETE,
      title: 'delete calendars',
      description: 'Ability to delete a calendar',
    },
    { key: CalendarSecurityResourceAction.READ, title: 'Read Calendars', description: 'Ability to view calendars ' },
  ],
};
