import { CondOperator } from '@nestjsx/crud-request';
import { FilterOptions } from './filter';
import { BuildTextFilter } from './text-filter';

export function BuildSearchFilter(
  typeName: string,
  options: Pick<FilterOptions, 'properties'> & { propertyLabels: string[] }
) {
  let description = 'Text search';
  for (let index = 0; index < options.propertyLabels.length; index++) {
    if (index === 0) description += ' in ';

    description += `<strong>${options.propertyLabels[index]}</strong>`;

    if (index === options.propertyLabels.length - 2) description += ' and ';
    else if (index !== options.propertyLabels.length - 1) description += ', ';
  }

  return class extends BuildTextFilter(typeName, {
    ...options,
    description,
    canOpen: false,
    label: 'Search',
    shortLabel: 'Search',
    overwriteDescriptionOnSearch: false,
    allowedOperators: [CondOperator.CONTAINS_LOW],
  }) {};
}
