<div style="display: flex; flex-direction: row; gap: 4px">
  <mat-icon>{{ defaultCategoryForm.matIcon }}</mat-icon>
  <h3 style="padding-top: 3px">{{ defaultCategoryForm.label }}</h3>
</div>

<form [formGroup]="form" style="width: 100%; margin-top: -12px" *grecoLet="accountDefault$ | async">
  <mat-form-field
    floatLabel="always"
    appearance="standard"
    class="default-sale-category-form-field"
    *grecoLet="canSetDefault$ | async as canSetDefault"
  >
    <mat-label>Sale Category</mat-label>

    <greco-sale-category-input
      style="width: 100%"
      formControlName="saleCategory"
      [required]="true"
      [accountId]="accountId"
      [disabled]="!canSetDefault"
    ></greco-sale-category-input>

    <button
      *ngIf="canSetDefault && form.dirty"
      matSuffix
      mat-button
      color="primary"
      style="height: 32px; line-height: 16px"
      [disabled]="saving$ | async"
      (click)="save()"
    >
      <span>Save</span>
      <mat-icon *ngIf="saving$ | async" class="spin">loop</mat-icon>
    </button>
  </mat-form-field>
</form>
