import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { Pagination } from 'nestjs-typeorm-paginate';
import { UserService } from './users';

@Injectable()
export class AdminService {
  constructor(private http: HttpClient, private userSvc: UserService) {}

  async paginateSuperAdmins(query: RequestQueryBuilder, page: number, limit: number): Promise<Pagination<User>> {
    return await toPromise(
      this.http.get<Pagination<User>>('/api/admins/paginate', {
        params: query.setPage(page).setLimit(limit).queryObject,
      })
    );
  }

  async searchNonAdmins(qry: string) {
    const { items } = await this.userSvc.paginate(
      RequestQueryBuilder.create({
        search: {
          $and: [
            {
              $or: [
                {
                  displayName: { $contL: qry },
                },
                {
                  email: { $contL: qry },
                },
              ],
            },
            {
              isSuperAdmin: false,
            },
          ],
        },
      })
    );
    return items;
  }

  grantSuperAdmin(userId: string): Promise<User> {
    return toPromise(this.http.post<User>(`/api/admins/grant/${userId}`, {}));
  }

  removeSuperAdmin(userId: string): Promise<User> {
    return toPromise(this.http.post<User>(`/api/admins/remove/${userId}`, {}));
  }
}
