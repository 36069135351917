<form [formGroup]="_form">
  <div class="fields-section">
    <h3>Series Recurrence Period</h3>
    <!-- Start Date -->
    <mat-form-field appearance="standard">
      <mat-label>Start Date</mat-label>
      <input
        matInput
        [max]="maxStartDate"
        [disabled]="!isNew"
        [readonly]="readonly"
        [required]="required"
        [matDatepicker]="startDatePicker"
        formControlName="startDate"
        (dateChange)="datesUpdated($event)"
      />
      <mat-datepicker-toggle matSuffix [for]="startDatePicker" [disabled]="readonly || !isNew"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker [disabled]="readonly"> </mat-datepicker>
    </mat-form-field>

    <!-- End Date -->
    <mat-form-field appearance="standard">
      <mat-label>End Date<em>(Optional)</em></mat-label>
      <input
        matInput
        [min]="minEndDate"
        [readonly]="readonly"
        [matDatepicker]="endDatePicker"
        formControlName="endDate"
        (dateChange)="datesUpdated(null, $event)"
      />
      <mat-datepicker-toggle [disabled]="readonly" matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker [disabled]="readonly"></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="fields-section">
    <h3>Weekly Recurrence Schedule</h3>
    <mat-form-field appearance="standard">
      <mat-label>Timezone</mat-label>
      <greco-timezone-select [required]="required" formControlName="timezone"></greco-timezone-select>
    </mat-form-field>

    <mat-form-field *ngFor="let day of weekDays" appearance="standard">
      <div style="display: flex">
        <mat-label>{{ day | titlecase }}</mat-label>
        <span style="color: var(--warn-color)" *ngIf="day == dayRequired ? true : false">*</span>
      </div>
      <mat-chip-list #dayChipList aria-label="Time Selection">
        <mat-chip
          *ngFor="let dayTime of _form.get(day)?.value; let index = index"
          [selectable]="'true'"
          [removable]="'true'"
          [disabled]="readonly"
          (removed)="removeTime(day, index)"
        >
          {{ dayTime | date : 'shortTime' }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          #dayInput
          matInput
          [readonly]="readonly"
          (dateInput)="dayInput.value = ''"
          [matDatetimepicker]="timePicker"
          [matChipInputFor]="dayChipList"
          [matChipInputSeparatorKeyCodes]="[188, 13]"
          autocomplete="false"
          style="color: transparent; user-select: none; pointer-events: none"
          [required]="day == dayRequired ? true : false"
        />
      </mat-chip-list>
      <mat-datetimepicker
        #timePicker
        type="time"
        [openOnFocus]="true"
        [disabled]="readonly"
        [timeInterval]="5"
        (selectedChanged)="
          !_form.get(day)?.value?.includes($event) &&
            _form.get(day)?.setValue((_form.get(day)?.value || []).concat($event))
        "
      ></mat-datetimepicker>
      <mat-datetimepicker-toggle [disabled]="readonly" [for]="timePicker" matSuffix></mat-datetimepicker-toggle>
    </mat-form-field>
  </div>

  <div style="margin-top: 16px">
    <mat-checkbox
      [disabled]="!_form.value.endDate || readonly"
      formControlName="availableAsCourse"
      color="primary"
      (click)="clearCourseValues()"
    >
      Available as Course
    </mat-checkbox>
  </div>

  <div style="margin-top: 16px">
    <mat-checkbox
      [disabled]="!_form.value.availableAsCourse || readonly"
      [checked]="_form.value.availableAsInstances"
      formControlName="availableAsInstances"
      color="primary"
    >
      Available as Individual Instances
    </mat-checkbox>
  </div>

  <!-- Course Images -->
  <mat-form-field *ngIf="_form.value.availableAsCourse" appearance="standard" floatLabel="always" style="width: 100%">
    <mat-label>Image</mat-label>
    <greco-image-upload
      [disabled]="readonly"
      [urls]="courseImageURL"
      (click)="_form.markAsDirty()"
      formControlName="courseImage"
    ></greco-image-upload>
  </mat-form-field>
</form>
