import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { User } from '@greco/identity-users';
import { combineLatest, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AccountLinkingService } from '../../services';
@Component({
  selector: 'greco-child-account-picker',
  templateUrl: './child-account-picker.component.html',
  styleUrls: ['./child-account-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ChildAccountPickerComponent }],
})
export class ChildAccountPickerComponent implements OnDestroy {
  private static ID = 0;
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef,
    private linkingSvc: AccountLinkingService
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  private onDestroy$ = new Subject<void>();

  _form = this.formBuilder.group({
    search: [null, Validators.email],
    child: [null],
  });

  hasResults = false;

  get currentSearchValue() {
    return (this._form.get('search')?.value as string) || null;
  }

  readonly search$ = combineLatest([
    this.childControl.valueChanges.pipe(startWith(null)),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._form.get('search')!.valueChanges.pipe(startWith(null)),
  ]).pipe(
    switchMap(async ([_current, search]) => {
      let results: User[] = [];
      if (search) {
        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]/i;
        const valid = expression.test(search);

        if (valid) results = await this.linkingSvc.findChildAccounts(search);
      }
      this.hasResults = !!results.length;
      return results;
    })
  );

  @ViewChild(MatInput) private matInput?: MatInput;

  private get childControl() {
    return this._form.get('child') as AbstractControl;
  }

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-child-account-picker';
  @HostBinding() readonly id = `${ChildAccountPickerComponent.ID++}-${this.controlType}`;

  _inputValue: User | null = null;

  set value(value: User | null) {
    const currentValue = this.childControl.value;
    if (currentValue !== value) {
      this.childControl.setValue(value);
      if (value) this._inputValue = value;
      this.stateChanges.next();
    }
  }
  get value(): User | null {
    return this.childControl.value;
  }

  get focused() {
    return !!this.matInput?.focused;
  }

  get empty() {
    const control = this._form.get('icon');
    return !!control?.value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    this.childControl.setValidators(this._required ? [Validators.required, Validators.minLength(1)] : []);
    this._form.updateValueAndValidity();

    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;

    this.childControl;

    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.childControl.invalid && this._form.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-child-account-picker-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.matInput?.onContainerClick();
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.childControl.valueChanges.pipe(startWith(null), takeUntil(this.onDestroy$)).subscribe(() => fn(this.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_fn: any) {}

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
  }

  remove() {
    this.value = null;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.stateChanges.complete();
  }
  childSelected(child: User) {
    this.value = child;
  }
}
