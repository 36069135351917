import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Event, SpotDetails } from '@greco/booking-events2';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventService } from '../../../services';

@Component({
  selector: 'alt-spot-selection-requirement',
  templateUrl: './spot-selection.component.html',
  styleUrls: ['./spot-selection.component.scss'],
})
export class SpotSelectionRequirementComponent {
  constructor(private eventSvc: EventService, public bottomSheet: MatBottomSheet) {}

  @PropertyListener('user') private user$ = new BehaviorSubject<User | null>(null);
  @Input() user?: User;

  @Input() event?: Event;

  @Output() bottomSheetOpened = new EventEmitter<TemplateRef<any>>();

  selectedSpotId: string | null = null;

  selectedSpot$ = combineLatest([this.user$, this.eventSvc.bookingsInfo$]).pipe(
    map(([user, bookings]) => {
      if (!user || !bookings?.length) return null;

      const userBooking = bookings.find(booking => booking.userId === user.id);
      if (!userBooking) return null;

      if (userBooking?.spotId === 'general') {
        return {
          spotId: 'general',
          spotName: 'General',
          spotNumber: 0,
          spotDescription: 'Let staff assign a spot for you',
          userId: user.id,
        } as SpotDetails;
      }

      const spot =
        this.event?.requirements.spotBooking?.room?.spots?.find(spot => spot.id === userBooking.spotId) ?? null;
      if (!spot) return null;

      return {
        spotId: spot.id,
        spotName: spot.name,
        spotNumber: spot.spotNumber,
        spotDescription: spot?.description,
        userId: user.id,
      } as SpotDetails;
    })
  );

  selectedSpotUpdated(spot: SpotDetails) {
    const bookings = this.eventSvc.bookings$.value;
    const userBooking = bookings.find(booking => booking.userId === this.user?.id);
    if (!userBooking) return;

    userBooking.spotId = spot.spotId;

    const bookingIndex = bookings.findIndex(booking => booking.userId === this.user?.id);

    bookings[bookingIndex] = userBooking;
    this.eventSvc.bookings$.next([...bookings]);

    this.selectedSpotId = spot.spotId;
  }

  emitBottomSheetOpened(bottomSheetRef: TemplateRef<any>) {
    this.bottomSheetOpened.emit(bottomSheetRef);
  }
}
