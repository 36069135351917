import { BaseDto } from '@greco/util-dto';
import { Expose } from 'class-transformer';
import { IsArray, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class CreateVideoAndInitiateUploadDto extends BaseDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  source: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  title: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  fileSize: number;

  @Expose()
  @IsString()
  @IsOptional()
  description?: string;

  @Expose()
  @IsArray()
  @IsString({ each: true })
  videoTagIds: string[];
}
