import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { AuthorizeTypeformDto, FormConfigEntity, FormEntity, TypeformResponseEntity } from '@greco/nestjs-typeform';
import { CommunityFormType } from '@greco/typeform';
import { TypeformConfig, TYPEFORM_CONFIG } from '../typeform.tokens';

@Injectable()
export class TypeformService {
  constructor(@Inject(TYPEFORM_CONFIG) private config: TypeformConfig, private http: HttpClient) {}

  // @Get(':userId/recent')
  async getMostRecentResponse(userId: string, formId: string) {
    return await toPromise(
      this.http.get<TypeformResponseEntity>(`/api/typeform/responses/${userId}/recent`, { params: { formId } })
    );
  }

  getRedirectUri() {
    return this._join(window.location.origin, this.config.relativeRedirectUrl);
  }

  getInstallURL(communityId: string | null) {
    const redirect = this.getRedirectUri();
    const state = communityId ? `&state=${communityId}` : '';
    const scope = 'offline+accounts:read+forms:read+forms:write+responses:read+webhooks:read+webhooks:write';
    return `https://api.typeform.com/oauth/authorize?client_id=${this.config.clientId}&redirect_uri=${redirect}&scope=${scope}${state}`;
  }

  // @Post('authorize')
  async authorize(dto: AuthorizeTypeformDto): Promise<void> {
    return await toPromise(this.http.post<void>(`/api/typeform/authorize`, dto));
  }

  // @Get('account')
  async getAccount(communityId: string | null) {
    return await toPromise(
      this.http.get<{ id: string; email: string }>('/api/typeform/account', {
        params: {
          ...(communityId && { communityId }),
        },
      })
    );
  }

  // @Get(':accountId/forms')
  async getForms(accountId: string) {
    return await toPromise(this.http.get<{ id: string; title: string }[]>(`/api/typeform/${accountId}/forms`));
  }

  // @Post(':accountId/connect/:formId')
  async connectForm(accountId: string, formId: string) {
    return await toPromise(this.http.post<any>(`/api/typeform/${accountId}/connect/${formId}`, {}));
  }

  // @Get(':accountId/connected-forms')
  async getConnectedForms(accountId: string) {
    return await toPromise(this.http.get<FormEntity[]>(`/api/typeform/${accountId}/connected-forms`));
  }

  // @Post('responseReceived')
  async responseReceived(formId: string, accountId: string, responseId: string) {
    return await toPromise(
      this.http.post<boolean>(`/api/typeform/responses/responseReceived`, { formId, accountId, responseId })
    );
  }

  async getCommunityTypeformConfig(communityId: string, type: CommunityFormType) {
    return await toPromise(
      this.http.get<FormEntity | null>(`/api/typeform/${communityId}`, {
        params: { type },
      })
    );
  }

  async saveCommunityTypeformConfig(communityId: string, newValue: string | null, type: CommunityFormType) {
    return await toPromise(
      this.http.post<FormConfigEntity>('/api/typeform/typeformConfig', { communityId, newValue, type })
    );
  }

  private _join(a: string, b: string): string {
    return (a.endsWith('/') ? a : a + '/') + this._trimLeft(b);
  }

  private _trimLeft(value: string): string {
    let result = '' + value;

    while (result.startsWith('/')) {
      const [_, ...rest] = result;
      result = rest.join('');
    }

    return result;
  }
}
