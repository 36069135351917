import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this.formControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => this._onChange?.(value));
  }

  private static _id = 0;

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  private _onDestroy = new Subject<void>();

  stateChanges = new Subject<void>();

  formControl = this.formBuilder.array([{ value: null }, { value: null }]);

  @ViewChild(MatInput) private _input?: MatInput;

  readonly controlType = 'greco-date-range-picker';
  @HostBinding() readonly id = `${this.controlType}-${DateRangePickerComponent._id++}`;

  @Input() set value(value: [Date, Date] | null) {
    this.formControl.controls[0].setValue(value?.[0] || null);
    this.formControl.controls[1].setValue(value?.[1] || null);
    this.stateChanges.next();
  }
  get value() {
    return this.formControl.value;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.formControl.setValidators(this._required ? [Validators.required] : []);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = true;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this._disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.formControl.invalid && this.formControl.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  ngAfterViewInit() {
    if (this._input) {
      const _focusChanged = this._input._focusChanged;
      this._input._focusChanged = (isFocused: boolean) => {
        if (isFocused) this._onTouched?.();
        return _focusChanged?.(isFocused);
      };
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-date-range-picker-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: [Date, Date] | null): void {
    this.value = value;
  }

  registerOnChange(fn: (_: [Date, Date] | null) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }
}
