import { Injectable } from '@angular/core';
import { Contact } from '@greco/identity-contacts';
import { AutocompleteFilter } from '@greco/ngx-filters';
import { ContactService } from '@greco/ngx-identity-contacts';
import { CondOperator, RequestQueryBuilder, SFields } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'any' })
export class UserPerkUserFilter extends AutocompleteFilter {
  constructor(private contactSvc: ContactService) {
    super('UserPerkUserFilter', {
      description: '',
      label: 'User',
      properties: ['user.id'],
      shortLabel: 'User',
      canOpen: true,
      onlyOne: true,
    });
  }

  public communityId?: string;

  getImageUrl(value: Contact) {
    return value?.user?.photoURL ?? 'assets/lf3/icon.png';
  }

  getAutocompleteHtml(value: Contact): string {
    return value.user ? `${value.user.displayName} <small>(${value.email})</small>` : value.email;
  }

  getValueLabel(value: Contact): string {
    return value?.displayName;
  }

  async getValueOptions(search?: string): Promise<Contact[]> {
    if (!this.communityId) return [];

    const contacts = await this.contactSvc.paginateContacts(
      new RequestQueryBuilder().search({
        $and: [
          { 'user.id': { $notnull: true } },
          { $or: [{ email: { $contL: search } }, { displayName: { $contL: search } }] },
        ],
      }),
      this.communityId,
      { limit: 5 }
    );

    return contacts.items;
  }

  serializeValue(value: Contact): string {
    return value.id;
  }

  async deserializeValue(serializedValue: string) {
    return await this.contactSvc.getContact(serializedValue);
  }

  getPropertySField(property: string, operator: CondOperator, value: Contact): SFields {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { [property]: { [operator]: value.user!.id } };
  }
}
