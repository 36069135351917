export enum RecurrencePeriod {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export interface ProductVariantRecurrence {
  frequency: number;
  period: RecurrencePeriod;
  cycles: number | null;
}

export interface ProductVariantMinimumCommitment {
  length: number;
  period: RecurrencePeriod;
}

export function formatRecurrence(recurrence: ProductVariantRecurrence): string {
  const period = getFormattedPeriod(recurrence.period);
  if (recurrence.frequency === 1) return `Every ${period}`;
  return `Every ${recurrence.frequency} ${period}s`;
}

export function getFormattedPeriod(period: RecurrencePeriod): string {
  switch (period) {
    case RecurrencePeriod.Daily:
      return 'day';
    case RecurrencePeriod.Monthly:
      return 'month';
    case RecurrencePeriod.Weekly:
      return 'week';
    case RecurrencePeriod.Yearly:
      return 'year';
  }
}
