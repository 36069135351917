<greco-table [data]="invoices" [loading]="loading" (rowClick)="openInvoice($event)" [highlight]="true">
  <mat-icon *grecoTableCol="''; let invoice; fitContent: true" [matTooltip]="invoice.id">receipt</mat-icon>

  <p *grecoTableCol="'Customer'; let invoice">
    <span>{{invoice.displayName}}</span>
    <br />
    <small style="font-size: 75%">{{invoice.email}}</small>
  </p>

  <p *grecoTableCol="'Invoice'; let invoice">
    <span>
      <span>{{ (invoice.data.total || 0) / 100 | currency }}</span>

      <span style="margin-left: 8px">
        <mat-chip-list style="display: inline-block">
          <mat-chip style="font-size: 75%; min-height: 0; padding: 10px 12px">
            {{ invoice.data?.status === 'paid' && invoice.data.total < 0 ? 'Balance Credited' : (invoice.data?.status +
            (invoice.data?.status === 'open' && invoice.data?.charge ? ' | ' + invoice.data?.charge?.status : '') |
            titlecase) }}
          </mat-chip>

          <mat-chip *ngIf="invoice.data.charge?.refunded" style="font-size: 75%; min-height: 0; padding: 10px 12px">
            <span>Refunded</span>
          </mat-chip>

          <mat-chip
            *ngIf="
              invoice.data.charge?.amount_refunded > 0 && invoice.data.charge?.amount_refunded < invoice.data.charge?.amount
            "
            style="font-size: 75%; min-height: 0; padding: 10px 12px"
          >
            <span>Partial Refund</span>
          </mat-chip>

          <!-- <mat-chip style="font-size: 75%; min-height: 0; padding: 10px 12px" *ngIf="transaction.covid">
            <ion-label>Covid</ion-label>
          </mat-chip> -->

          <mat-chip *ngIf="invoice.notes?.length" style="font-size: 75%; min-height: 0; padding: 10px 12px">
            <span>Notes</span>
          </mat-chip>

          <mat-chip
            style="font-size: 75%; min-height: 0; padding: 10px 12px"
            *ngIf="
              ((invoice.data.create?._seconds * 1000) >= migration.start && (invoice.data.create?._seconds * 1000) <= migration.end) ||
              ((invoice.data.create?._seconds * 1000) >= migration.start2 &&
              (invoice.data.create?._seconds * 1000) <= migration.end2) ||
              ((invoice.data.create?._seconds * 1000) >= migration.start3 && (invoice.data.create?._seconds * 1000) <= migration.end3)
            "
          >
            <span>System Update</span>
          </mat-chip>
        </mat-chip-list>
      </span>
    </span>
    <br />
    <span style="font-size: 75%">{{ invoice.data.lines.data[0]?.description }}</span>
  </p>

  <mat-chip-list *grecoTableCol="'Date'; let invoice">
    <mat-chip>
      {{(invoice.data.created._seconds * 1000) | date}} {{(invoice.data.created._seconds * 1000) | date: 'shortTime'}}
    </mat-chip>
  </mat-chip-list>

  <ng-container *grecoTableCol="''; let invoice; stickyEnd: true; fitContent: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <a mat-menu-item [routerLink]="invoice.id">
        <mat-icon>open_in_new</mat-icon>
        <span>View Details</span>
      </a>

      <button mat-menu-item disabled>
        <mat-icon>description</mat-icon>
        <span>TODO: Release Corporate Fees</span>
        <!-- ...(!invoice.voidFees && user.hasAbility('admin-refund-voidFees')
        ? [
            {
              label: 'Release Corporate Fees',
              icon: 'document-text-outline',
              action: () => this.voidFees(invoice.id),
            },
          ]
        : []), -->
      </button>

      <a mat-menu-item *ngIf="invoice.data.hosted_invoice_url" target="_blank" [href]="invoice.data.hosted_invoice_url">
        <mat-icon>description</mat-icon>
        <span>View Invoice</span>
      </a>

      <a
        mat-menu-item
        *ngIf="invoice.data.charge?.receipt_url"
        target="_blank"
        [href]="invoice.data.charge?.receipt_url"
      >
        <mat-icon>description</mat-icon>
        <span>View Receipt</span>
      </a>

      <button mat-menu-item disabled>
        <mat-icon>undo</mat-icon>
        <span>TODO: Issue Refund</span>
        <!-- ...(invoice.data.charge &&
    !invoice.data.charge.refunded &&
    (user.hasAbility('admin-refund-invoice') ||
      (invoice.studio && user.hasStudioAbility('studio-refund-invoice', invoice.studio)))
      ? [
          {
            label: 'Issue Refund',
            icon: 'arrow-undo-circle-outline',
            action: () => this.initiateRefund(invoice),
          },
        ]
      : []), -->
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<div style="padding: 16px">
  <button style="margin-left: auto" mat-button [disabled]="loading || reachedEnd" (click)="loadNextPage()">
    Load more
  </button>
</div>
