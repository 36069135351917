import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Resource, ResourceType, RoomResource, ZoomResource } from '@greco/booking-events';
import type {
  CreatePersonResourceDto,
  CreateRoomResourceDto,
  CreateZoomResourceDto,
  EditResourceInfoDto,
  EditRoomResourceInfoDto,
} from '@greco/nestjs-booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

const TEN_MINUTES_IN_MS = 60 * 60 * 1000;

@Injectable()
export class ResourcesService {
  constructor(private http: HttpClient) {}

  // @Get('access')
  async resourcesWithAccess() {
    return await toPromise(this.http.get<Resource[]>('/api/resources/access'));
  }

  async getResource(resourceId: string) {
    return await toPromise(this.http.get<Resource>(`/api/resources/${resourceId}`));
  }

  async getRoomResource(roomId: string) {
    return await toPromise(this.http.get<RoomResource>(`/api/resources/room/${roomId}`));
  }

  async paginateResources(
    query: RequestQueryBuilder,
    communityId?: string,
    pagination?: Partial<PaginatedQueryParams>,
    type?: ResourceType
  ) {
    return await toPromise(
      this.http.get<PaginatedDto<Resource>>(`/api/resources`, {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          ...(type && { type }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 500).toString(),
        },
      })
    );
  }

  async getAllCommunityResources(query: RequestQueryBuilder, communityId: string) {
    const key = `COMMUNITY_RESOURCES_${JSON.stringify(query.search || {})}_${communityId}`;

    const fromCache = this._getCachedCommunityResources(key);
    if (fromCache !== null) return fromCache;

    const resources = await this._getAllCommunityResources(query, communityId);

    this._updateCommunityResourcesCache(key, resources);

    return resources;
  }

  private _getCachedCommunityResources(key: string): Resource[] | null {
    try {
      const cache = JSON.parse(localStorage.getItem(key) || '{}');

      const expires = cache?.expires ?? 0;
      if (expires <= Date.now()) return null;

      return cache?.resources ?? null;
    } catch {
      return null;
    }
  }

  private _updateCommunityResourcesCache(key: string, resources: Resource[]) {
    try {
      const cache = { resources, expires: Date.now() + TEN_MINUTES_IN_MS };
      localStorage.setItem(key, JSON.stringify(cache));
    } catch {
      /* noop */
    }
  }

  private async _getAllCommunityResources(query: RequestQueryBuilder, communityId: string) {
    const results: Resource[] = [];

    const limit = 500;
    let page: PaginatedDto<Resource> | null = await this.paginateResources(query, communityId, { limit });

    while (page) {
      results.push(...page.items);

      const pageIndex: number | null = page.meta.currentPage >= page.meta.totalPages ? null : page.meta.currentPage + 1;
      page = pageIndex !== null ? await this.paginateResources(query, communityId, { limit, page: pageIndex }) : null;
    }

    return results;
  }

  async createPersonResource(resourceDto: CreatePersonResourceDto) {
    return await toPromise(this.http.post<Resource>(`/api/resources`, resourceDto));
  }

  async createRoomResource(dto: CreateRoomResourceDto) {
    return await toPromise(this.http.post<RoomResource>('/api/resources', dto));
  }

  // Post(':roomId/image')
  async uploadRoomImage(roomId: string, data: FormData): Promise<string> {
    const result = await toPromise(this.http.post<{ imageURL: string }>(`/api/resources/${roomId}/image`, data));
    return result.imageURL;
  }

  // Post(':roomId/image')
  async removeRoomImage(roomId: string): Promise<boolean> {
    return await toPromise(this.http.delete<boolean>(`/api/resources/${roomId}/removeImage`));
  }

  // @Put(':resourceId')
  async updateResource(resourceId: string, dto: EditResourceInfoDto) {
    return await toPromise(this.http.put<Resource>(`/api/resources/${resourceId}`, dto));
  }

  // @Put(':resourceId')
  async updateRoomResource(resourceId: string, dto: EditRoomResourceInfoDto) {
    return await toPromise(this.http.put<RoomResource>(`/api/resources/room/${resourceId}`, dto));
  }

  // @Get('groups/:groupId')
  async getResourceGroup(groupId: string) {
    return await toPromise(this.http.get<Resource[]>(`/api/resources/groups/${groupId}`));
  }
  async getResourceGroupWithAccess(groupId: string) {
    return await toPromise(this.http.get<Resource[]>(`/api/resources/groups/${groupId}/access`));
  }

  //@Post('zoom')
  async createZoomResource(data: CreateZoomResourceDto) {
    return await toPromise(this.http.post<ZoomResource>(`/api/resources/zoom`, data));
  }
}
