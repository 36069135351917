import { Payment } from './payment';

export interface OnFailedDto {
  retry: boolean;
}

export abstract class PaymentHandler {
  abstract onPaid(payment: Payment): Promise<void>;

  abstract onPendingPayment(payment: Payment): Promise<void>;

  abstract onPreAuthorized(payment: Payment): Promise<void>;

  abstract onFailed(payment: Payment): Promise<{ nextRetryDate: null | Date }>;

  abstract onRefunded(payment: Payment): Promise<void>;

  abstract onVoided(payment: Payment): Promise<void>;
}
