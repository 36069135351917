import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { ProductConditionDto } from '@greco/sales-products';
import { DynamicFormModel, DynamicFormService } from '@ng-dynamic-forms/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { delay, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { ProductConditionForm } from './product-condition-forms.token';

export interface ConfigureConditionDialogData {
  communityId: string;

  form: ProductConditionForm;
  condition?: ProductConditionDto;
}

@Component({
  selector: 'greco-configure-condition-dialog',
  templateUrl: './configure-condition.dialog.html',
  styleUrls: ['./configure-condition.dialog.scss'],
})
export class ConfigureConditionDialog implements OnInit, OnDestroy {
  constructor(
    private formSvc: DynamicFormService,
    private dialogRef: MatDialogRef<ConfigureConditionDialog>,
    @Inject(MAT_DIALOG_DATA) private data: ConfigureConditionDialogData
  ) {}

  readonly _dialogTitle = `Configure '${this.data.form.label}' Condition`;

  readonly _formModel: DynamicFormModel = this.data.form.getFormModel?.(this.data.communityId) || [];

  readonly _formGroup: FormGroup = this.formSvc.createFormGroup(this._formModel || []);

  private readonly valueAndValid$: Observable<{ value: any; valid: boolean }> = this._formGroup.valueChanges.pipe(
    startWith(this._formGroup.value),
    map(value => ({ value, valid: this._formGroup.valid })),
    shareReplay(1)
  );

  private readonly isValid$: Observable<boolean> = this.valueAndValid$.pipe(
    map(({ valid }) => valid),
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly saving$ = new BehaviorSubject<boolean>(false);

  readonly cantSubmit$ = combineLatest([this.isValid$, this.saving$]).pipe(
    map(([valid, saving]) => !valid || saving),
    delay(0)
  );

  ngOnInit() {
    if (!this._formModel.length) this.cancel();

    if (this.data.condition) {
      console.log(this.data.form.getFormValue?.(this.data.condition));
      this._formGroup.reset(this.data.form.getFormValue?.(this.data.condition) || this.data.condition);
    }
  }

  ngOnDestroy() {
    this.saving$.complete();
  }

  cancel() {
    this.dialogRef.close();
  }

  async submit() {
    this.saving$.next(true);
    const { value, valid } = await toPromise(this.valueAndValid$);
    if (valid) {
      this.dialogRef.close(this.data.form.getDtoFromFormValue ? this.data.form.getDtoFromFormValue(value) : value);
    } else {
      this.saving$.next(false);
    }
  }
}
