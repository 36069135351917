import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import moment from 'moment';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-bulk-start-subscription-dialog',
  templateUrl: './bulk-start-subscription-dialog.html',
  styleUrls: ['./bulk-start-subscription-dialog.scss'],
})
export class BulkStartSubscriptionDialog {
  @PropertyListener('data') private data$ = new ReplaySubject();
  @Input() public data: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    public dialogRef: MatDialogRef<BulkStartSubscriptionDialog>,
    private userSvc: UserService,
    private subscriptionSvc: SubscriptionsService,
    private snacks: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.data = {
      ...this.dialogData,
      hideDefaultButton: true,
      showCloseButton: false,
    };
  }

  processing = false;

  formGroup = this.fb.group({
    dateSelection: [moment().toDate(), Validators.required],
    dateRadioButton: ['now'],
  });

  minimalDate = moment().add(1, 'days').toDate();
  maximalDate = moment().add(5, 'years').toDate();

  loading$ = new BehaviorSubject<boolean>(true);

  validBulkSubscriptionInfo$ = this.data$.pipe(
    tap(() => this.loading$.next(true)),
    switchMap(async (data: any) => {
      return await this.subscriptionSvc.getValidBulkSubscriptionData(
        data?.filters?.subscriptions,
        data?.filters?.actions
      );
    }),
    tap(() => {
      this.loading$.next(false);
    })
  );

  formValue$ = this.formGroup.valueChanges.pipe(
    map(() => {
      if (this.formGroup.value.dateRadioButton === 'now') this.formGroup.value.dateSelection = moment().toDate();
    })
  );

  async confirmation(content: string) {
    this.processing = true;
    try {
      const currentUserId = await toPromise(this.userSvc.getUserId());
      if (content === 'confirm') {
        await this.subscriptionSvc.bulkStartSubscription(this.data.accountId, this.data.filters, {
          startDate: this.formGroup.value.dateSelection,
          createdById: currentUserId,
        });

        this.snacks.open('Bulk subscription start executed, email notification pending.', 'Ok', {
          duration: 6000,
          panelClass: 'mat-primary',
        });
        this.processing = false;

        this.dialogRef.close();
      }
    } catch (err) {
      this.snacks.open('Subscription could not be started', 'Ok', { duration: 6000, panelClass: 'mat-warn' });

      this.processing = false;
    }

    return this.dialogRef.close();
  }
}
