import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Community } from '@greco/identity-communities';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { CommunityService } from '../../services';

@Component({
  selector: 'greco-sub-communit-page',
  templateUrl: './sub-community.page.html',
  styleUrls: ['./sub-community.page.scss'],
})
export class SubCommunityPage implements OnChanges {
  constructor(
    private formBuilder: FormBuilder,
    private communitySvc: CommunityService,
    private snacks: MatSnackBar,
    private clipboard: ClipboardService
  ) {}

  @Input() community!: Community;
  @Output() communityChanged = new EventEmitter<Community>();

  resetValue: any = null;
  formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    address: [null, Validators.required],
    phoneNumber: ['', Validators.required],
  });

  welcomeResetValue: any = null;
  welcomeFormGroup = this.formBuilder.group({
    welcomeContent: [''],
    invitationId: [''],
    externalRegistrationLink: [''],
    externalRegistrationLabel: [''],
    browsable: [true],
    online: [false],
    inStudio: [false],
    pt: [false],
  });

  paymentSettingsResetValue: any = null;
  paymentSettingsForm = this.formBuilder.group({
    gracePeriod: [null],
  });

  save = async () => {
    try {
      const update = await this.communitySvc.updateCommunity(this.community.id, {
        name: this.formGroup.value.name,
        email: this.formGroup.value.email,
        address: this.formGroup.value.address,
        phoneNumber: this.formGroup.value.phoneNumber,
      });
      console.log(this.community.browsable);
      this.community.name = update.name;
      this.community.email = update.email;
      this.community.address = update.address;
      this.community.phoneNumber = update.phoneNumber;

      this.reset();

      this.snacks.open('Changes Saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
    }
  };
  welcomeSave = async () => {
    try {
      const update = await this.communitySvc.updateCommunity(this.community.id, {
        welcomeContent: this.welcomeFormGroup.value.welcomeContent,
        invitationId: this.welcomeFormGroup.value.invitationId,
        externalRegistrationLink: (this.welcomeFormGroup.value.externalRegistrationLink as string)
          .replace('https://', '')
          .replace('http://', ''),
        externalRegistrationLabel: this.welcomeFormGroup.value.externalRegistrationLabel,
        browsable: this.welcomeFormGroup.value.browsable,
        tags: [
          ...(this.welcomeFormGroup.value.online ? ['online'] : []),
          ...(this.welcomeFormGroup.value.inStudio ? ['in-studio'] : []),
          ...(this.welcomeFormGroup.value.pt ? ['pt'] : []),
        ],
      });

      this.community.welcomeContent = update.welcomeContent;
      this.community.invitationId = update.invitationId;
      this.community.externalRegistrationLink = update.externalRegistrationLink;
      this.community.externalRegistrationLabel = update.externalRegistrationLabel;
      this.community.browsable = update.browsable;
      this.community.tags = update.tags;
      this.resetWelcome();

      this.snacks.open('Changes Saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
    }
  };

  paymentSettingsSave = async () => {
    try {
      const update = await this.communitySvc.updateCommunity(this.community.id, {
        gracePeriod: Number(this.paymentSettingsForm.value.gracePeriod),
      });
      this.community.gracePeriod = update.gracePeriod;
      this.resetPaymentSettings();

      this.snacks.open('Changes Saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community.currentValue !== changes.community.previousValue) {
      this.reset();
      this.resetWelcome();
      this.resetPaymentSettings();
      this.communityChanged.emit(changes.community.currentValue);
    }
  }

  welcomeChanged(text: string) {
    if (this.welcomeFormGroup.get('welcomeContent')?.value !== text) {
      this.welcomeFormGroup.get('welcomeContent')?.setValue(text);
      this.welcomeFormGroup.markAsDirty();
    }
  }

  copyInvitationLink() {
    this.clipboard.copy(
      window.location.host + '/' + encodeURIComponent(this.community.invitationId || this.community.id),
      'Link'
    );
  }

  copyRegistrationLink() {
    if (this.community.externalRegistrationLink) {
      this.clipboard.copy(encodeURI(this.community.externalRegistrationLink), 'Link');
    }
  }

  private reset() {
    this.resetValue = {
      name: this.community?.name || '',
      email: this.community?.email || '',
      address: this.community?.address || null,
      phoneNumber: this.community?.phoneNumber || '',
    };

    this.formGroup.reset(this.resetValue);
  }

  private resetWelcome() {
    this.welcomeResetValue = {
      welcomeContent: this.community?.welcomeContent || '',
      invitationId: this.community?.invitationId || this.community?.id || '',
      externalRegistrationLink: this.community?.externalRegistrationLink || '',
      externalRegistrationLabel: this.community?.externalRegistrationLabel || '',
      browsable: this.community?.browsable || false,
      online: this.community?.tags?.includes('online') || false,
      inStudio: this.community?.tags?.includes('in-studio') || false,
      pt: this.community?.tags?.includes('pt') || false,
    };

    this.welcomeFormGroup.reset(this.welcomeResetValue);
  }

  private resetPaymentSettings() {
    this.paymentSettingsResetValue = {
      gracePeriod: Number(this.community?.gracePeriod) || null,
    };
    this.paymentSettingsForm.reset(this.paymentSettingsResetValue);
  }
}
