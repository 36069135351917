<div class="content">
  <div
    *ngFor="let userPerk of userPerks"
    class="perk-box"
    [matTooltip]="
      userPerk.details.reusable
        ? 'This perk is reusable, it does not get consumed when used!'
        : 'This perk gets consumed when used!'
    "
  >
    <greco-perk-badge
      [text]="userPerk.perk.badge.text"
      [icon]="userPerk.perk.badge.icon || ''"
      [color]="userPerk.perk.badge.color + 'bb'"
      [shape]="userPerk.perk.badge.shape || 'squircle'"
    ></greco-perk-badge>

    <h3 class="title">{{ userPerk.perk.title }}</h3>

    <div class="details">
      <span class="available">
        Available:
        <div
          *grecoLet="userPerk.details.reusable as reusable"
          class="count"
          [style.margin-bottom]="reusable ? '-8px' : ''"
        >
          <ng-container *ngIf="reusable; else quantity">
            <mat-icon>all_inclusive</mat-icon>
          </ng-container>

          <ng-template #quantity>{{ userPerk.details.quantity }}</ng-template>
        </div>
      </span>

      <div class="line"></div>

      {{ userPerk.details.expiry ? 'Expires: ' + userPerk.details.expiry : 'Does not expire' }}
    </div>
  </div>
</div>
