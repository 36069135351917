import { Component, Input, OnDestroy } from '@angular/core';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { TypeformService } from '../../services';

@Component({
  selector: 'greco-typeform-authorization-section',
  templateUrl: './authorization-section.component.html',
  styleUrls: ['./authorization-section.component.scss'],
})
export class TypeformAuhtorizationSectionComponent implements OnDestroy {
  constructor(private typeformSvc: TypeformService, private userSvc: UserService) {}

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string | null>();
  @Input() communityId?: string | null;

  loading = true;
  isSuperAdmin$ = this.userSvc.user$.pipe(map(u => u?.isSuperAdmin));

  readonly installUrl$ = this._communityId$.pipe(map(communityId => this.typeformSvc.getInstallURL(communityId)));
  readonly typeformAccount$ = this._communityId$.pipe(
    tap(() => (this.loading = true)),
    switchMap(communityId => this.typeformSvc.getAccount(communityId)),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  private _refresh$ = new BehaviorSubject(null);
  readonly forms$ = combineLatest([this.typeformAccount$, this._refresh$]).pipe(
    switchMap(async ([account]) => (account ? await this.typeformSvc.getConnectedForms(account.id) : []))
  );

  refresh() {
    this._refresh$.next(null);
  }

  ngOnDestroy() {
    this._refresh$.complete();
  }
}
