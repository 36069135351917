<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div style="display: flex; align-items: flex-end">
      <!-- Variant Perk -->
      <mat-form-field>
        <mat-label>Perk</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Number"
          matInput
          formControlName="perk"
          [matAutocomplete]="auto"
          (input)="onChange($any($event.target)?.value)"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayPerk">
          <mat-option *ngFor="let perk of perks$ | async" [value]="perk"> {{ perk.title }} </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Transferrable -->
      <mat-form-field style="flex: 0">
        <input matInput style="display: none" />
        <mat-checkbox
          matSuffix
          color="primary"
          style="margin-left: 12px"
          formControlName="transferable"
          labelPosition="before"
          matTooltip="Checking this box, makes the perk transferrable from the purchasing user to another."
        >
          Transferable
        </mat-checkbox>
      </mat-form-field>
    </div>

    <!-- Quantity -->
    <mat-form-field>
      <mat-label>Quantity</mat-label>
      <input matInput required type="number" min="1" step="1" formControlName="quantity" />
      <mat-checkbox
        matSuffix
        color="primary"
        style="margin-left: 12px"
        formControlName="reusable"
        labelPosition="before"
        matTooltip="Checking this box, makes it so the perks granted can be reused an unlimited amount of times. The perks do not get consumed on use."
        >Unlimited Uses</mat-checkbox
      >
    </mat-form-field>

    <!-- Granted -->
    <mat-form-field>
      <mat-label>Granted</mat-label>
      <mat-select required multiple formControlName="granted">
        <mat-option value="INITIAL">On Initial Purchase</mat-option>
        <mat-option value="RENEWAL">On Subscription Renewal</mat-option>
      </mat-select>
    </mat-form-field>

    <h3>Expiry Events (First to Occur)</h3>

    <!-- Expiry Delay -->
    <mat-form-field>
      <mat-label>Expiry Delay (Number of Days After Granted)</mat-label>
      <input matInput type="number" min="1" step="1" formControlName="expiryDelay" />
      <mat-checkbox matSuffix color="primary" labelPosition="before" formControlName="expiryNotification">
        Send Expiry Notification
      </mat-checkbox>
    </mat-form-field>

    <mat-form-field *ngIf="form.value.expiryNotification" appearance="standard" style="width: 100%">
      <mat-label>
        Notify User {{ form.value.expiryNotificationDays }} Day{{ form.value.expiryNotificationDays > 1 ? 's' : ''}}
        Before Expiry
      </mat-label>
      <input
        matInput
        type="number"
        step="1"
        min="1"
        formControlName="expiryNotificationDays"
        [max]="form.value.expiry - 1"
      />
    </mat-form-field>

    <!-- Expiry Event -->
    <mat-form-field>
      <mat-label>Expiry Event (For Recurring Prices)</mat-label>
      <mat-select multiple formControlName="expiryEvent">
        <mat-option value="CANCELLATION">On Subscription Expiry</mat-option>
        <mat-option value="RENEWAL">On Subscription Renewal</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Freezable -->
    <mat-form-field>
      <mat-label>Freezability (For Recurring Prices)</mat-label>
      <mat-select required formControlName="freezable">
        <mat-option [value]="true">Unusable During Freeze</mat-option>
        <mat-option [value]="false">Usable During Freeze</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Buttons -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button mat-stroked-button type="submit" color="primary" [disabled]="form.invalid || processing" style="flex: 1">
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
