import { Component, Input, OnDestroy } from '@angular/core';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { AccountLink } from '@greco/account-linking';
import { PropertyListener } from '@greco/property-listener-util';
import { ProductConditionContext } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ProductsFilters } from '../../pages';
import { ShopService } from '../../services';

@Component({
  selector: 'greco-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss'],
})
export class ProductGridComponent implements OnDestroy {
  constructor(private shopSvc: ShopService) {}

  @Input() userId?: string;
  @Input() linkedAccounts?: AccountLink[];

  @PropertyListener('filters') private _filters$ = new ReplaySubject<ProductsFilters>(1);
  @Input() filters!: ProductsFilters;

  @Input() userConditionContext?: ProductConditionContext;

  loading = true;
  pagination?: IPaginationMeta;

  readonly page$ = new BehaviorSubject<PaginatedQueryParams>({});
  readonly products$ = combineLatest([this._filters$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([filters, page]) => {
      if (!filters) return null;
      return await this.shopSvc.paginateAllProducts(
        new RequestQueryBuilder().search({
          $and: [
            ...(filters.collection ? [{ 'collections.id': filters.collection }] : []),
            ...(filters.communities?.length ? [{ 'community.id': { $in: filters.communities } }] : []),
            ...(filters.search
              ? [{ $or: [{ title: { $contL: filters.search } }, { description: { $contL: filters.search } }] }]
              : []),
          ],
        }),
        page
      );
    }),
    tap(data => (this.pagination = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  ngOnDestroy() {
    this._filters$.complete();
  }
}
