import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { toPromise } from '@greco-fit/util';
import { Tax } from '@greco/finance-tax';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UpdateTaxDialog } from '../../dialogs';
import { TaxService } from '../../services';

@Component({
  selector: 'greco-taxes-page',
  templateUrl: './taxes.page.html',
  styleUrls: ['./taxes.page.scss'],
})
export class TaxesPage {
  constructor(private taxSvc: TaxService, private matDialog: MatDialog) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  pagination: null | IPaginationMeta = null;
  refresh$ = new BehaviorSubject(null);
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });
  taxes$ = combineLatest([this.page$, this.refresh$]).pipe(
    switchMap(async ([page]) => await this.taxSvc.paginateTaxes(new RequestQueryBuilder(), page)),
    tap(({ meta }) => (this.pagination = meta)),
    map(({ items }) => items)
  );

  async updateTax(tax: Tax) {
    const dialog = this.matDialog.open(UpdateTaxDialog, {
      data: { tax },
    });
    const result = await toPromise(dialog.afterClosed());
    if (result) this.refresh$.next(null);
  }
}
