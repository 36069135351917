import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tax } from '@greco/finance-tax';
import { TaxDto } from '@greco/nestjs-finance-tax';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject } from 'rxjs';
import { AccountTaxService, TaxService } from '../../services';

@Component({
  selector: 'greco-update-tax-dialog',
  templateUrl: './update-tax.dialog.html',
  styleUrls: ['./update-tax.dialog.scss'],
})
export class UpdateTaxDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { tax: Tax },
    private ref: MatDialogRef<UpdateTaxDialog>,
    private formBuilder: FormBuilder,
    private accountTaxSvc: AccountTaxService,
    private taxSvc: TaxService,
    private snacks: MatSnackBar
  ) {
    this.tax = data?.tax || null;
  }

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '',
    suffix: '%',
    thousands: ',',
    inputMode: 0,
    min: 0,
    max: 100,
  };

  formGroup = this.formBuilder.group({
    title: [null, Validators.required],
    abbreviation: [null, Validators.required],
    description: [null],
    percentage: [null, Validators.required],
  });
  private _tax$: BehaviorSubject<Tax | null> = new BehaviorSubject<Tax | null>(null);
  get tax() {
    return this._tax$.value;
  }
  set tax(value: Tax | null) {
    this._tax$.next(value);
    this.formGroup.setValue({
      title: value?.title,
      abbreviation: value?.abbreviation,
      description: value?.description,
      percentage: value?.percentage,
    });
  }

  close(tax?: Tax) {
    this.ref.close(tax);
  }

  async update() {
    if (!this.tax) return;
    const value = this.formGroup.value;
    const dto: TaxDto = {
      title: value.title,
      abbreviation: value.abbreviation,
      description: value.description,
      percentage: value.percentage,
    };
    const tax = await this.taxSvc.updateTax(this.tax.id, dto);
    if (tax)
      this.snacks.open(`Tax "${tax.title}" updated!`, 'Ok', {
        duration: 5000,
        panelClass: 'mat-primary',
      });
    this.close(tax);
  }
}
