<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <mat-form-field>
      <greco-event-resource-assignment
        [communityId]="data.communityId"
        [eventId]="data.event.id"
        [startDate]="data.event.startDate"
        [duration]="data.event.duration"
        [disabled]="data.disableChanges"
        formControlName="resourceAssignments"
      ></greco-event-resource-assignment>
    </mat-form-field>
  </form>

  <div style="display: flex; justify-content: flex-end">
    <button mat-flat-button (click)="cancel()" [disabled]="loading">Cancel</button>
    <button
      *ngIf="(assignmentsForSub?.length || 0) > 1 && data.fromResourceScheduling"
      style="margin-left: 8px"
      mat-flat-button
      [disabled]="loading"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon *ngIf="loading" class="spin" style="margin-top: 4px"> loop </mat-icon>
      Substitute
    </button>

    <button
      *ngIf="(assignmentsForSub?.length || 0) <= 1 || !data.fromResourceScheduling"
      mat-flat-button
      (click)="confirm()"
      [disabled]="loading"
      style="margin-left: 8px"
    >
      <mat-icon *ngIf="loading" class="spin" style="margin-top: 4px"> loop </mat-icon>
      Confirm
    </button>
  </div>

  <mat-menu #menu>
    <button *ngFor="let assignment of assignmentsForSub" mat-menu-item (click)="substitute(assignment)">
      {{assignment?.resource?.name || 'Resource needed'}} - {{assignment?.resourceTag?.label}}
    </button>
  </mat-menu>
</greco-simple-dialog>
