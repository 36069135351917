<button
  *ngIf="(canRefund$ | async) || (canVoid$ | async) || (canRetry$ | async) || (canManageSoldBy$ | async)"
  mat-icon-button
  [matMenuTriggerFor]="menu"
  (click)="$event.stopImmediatePropagation()"
  [disabled]="disabled"
>
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu>
  <a mat-menu-item [routerLink]="purchase.id" *ngIf="showOpenOption">
    <mat-icon>open_in_new</mat-icon>
    <span>View Details</span>
  </a>

  <button
    mat-menu-item
    *ngIf="(purchase.status === 'PAID' || purchase.status === 'PARTIALLY_REFUNDED') && (canRefund$ | async)"
    (click)="refundPurchase(purchase)"
  >
    <mat-icon>undo</mat-icon>
    <span>Refund</span>
  </button>

  <button mat-menu-item *ngIf="purchase.status === 'FAILED' && (canVoid$ | async)" (click)="voidPurchase(purchase)">
    <mat-icon>block</mat-icon>
    <span>Void</span>
  </button>

  <button
    mat-menu-item
    *ngIf="purchase.status === 'FAILED' && (canRetry$ | async)"
    (click)="retryFailedPurchase(purchase)"
  >
    <mat-icon>refresh</mat-icon>
    <span>Retry</span>
  </button>

  <button
    mat-menu-item
    *ngIf="(purchase.status === 'PAID' || purchase.status === 'PARTIALLY_REFUNDED') && (isSuper$ | async) === true"
    (click)="sendAdhocEmailReceipt(purchase)"
  >
    <mat-icon>forward_to_inbox</mat-icon>
    <span>Send Receipt</span>
  </button>

  <button
    mat-menu-item
    *ngIf="purchase.status === 'FAILED' && (isSuper$ | async) === true"
    (click)="sendPurchaseFailedEmail(purchase)"
  >
    <mat-icon>forward_to_inbox</mat-icon>
    <span>Resend Email</span>
  </button>

  <button mat-menu-item *ngIf="canManageSoldBy$ | async" (click)="setSoldBy(purchase)">
    <mat-icon>manage_accounts</mat-icon>
    <span>Update Sold By</span>
  </button>

  <button mat-menu-item *ngIf="canManageReferredBy$ | async" (click)="setReferredBy(purchase)">
    <mat-icon>manage_accounts</mat-icon>
    <span>Update Referred By</span>
  </button>

  <ng-content select="[mat-menu-item]"></ng-content>
</mat-menu>
