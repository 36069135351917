import { Component, Injectable, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  CalendarEvent,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventWaitlistSecurityResource,
  EventWaitListSecurityResourceAction,
} from '@greco/booking-events';

import { BuildSearchFilter } from '@greco/ngx-filters';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DeleteUserFromEventDialog } from '../../dialogs';
import { AddToWaitlistDialog } from '../../dialogs/add-to-waitlist/add-to-waitlist.dialog';
import { EventService } from '../../services';

@Injectable({ providedIn: 'any' })
class WaitlistSearchFilter extends BuildSearchFilter('WaitlistSearchFilter', {
  properties: ['user.displayName', 'email'],
  propertyLabels: ['Display Name', 'Email Address'],
}) {}

@Component({
  selector: 'greco-waitlist-table',
  templateUrl: './waitlist-table.component.html',
  styleUrls: ['./waitlist-table.component.scss'],
})
export class WaitlistTableComponent implements OnDestroy {
  constructor(
    private userSvc: UserService,
    private eventSvc: EventService,
    private matDialog: MatDialog,
    private comSecSvc: CommunitySecurityService,

    private dialog: MatDialog
  ) {}

  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>(1);
  @Input() event!: CalendarEvent;

  // canCreateBooking$ = this._event$.pipe(
  //   switchMap(async event =>
  //     event
  //       ? await this.comSecSvc.hasAccess(
  //           event.community.id,
  //           EventBookingSecurityResource.key,
  //           EventBookingSecurityResourceAction.CREATE
  //         )
  //       : false
  //   ),
  //   shareReplay(1)
  // );

  canCreateBooking$ = this._event$.pipe(
    switchMap(async event => {
      if (!event) return false;
      return (
        await Promise.all([
          this.comSecSvc.hasAccess(
            event.community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CREATE
          ),
          this.userSvc
            .getSelf()
            .then(
              user => user && event.resourceAssignments?.some(assignment => assignment.resource?.groupId === user.id)
            ),
        ])
      ).some(a => !!a);
    }),
    shareReplay(1)
  );

  canRemoveUserFromEvent$ = this._event$.pipe(
    switchMap(async event =>
      event
        ? await this.comSecSvc.hasAccess(
            event.community.id,
            EventWaitlistSecurityResource.key,
            EventWaitListSecurityResourceAction.DELETE
          )
        : false
    )
  );

  filterOptions = [WaitlistSearchFilter];

  loading = true;
  metadata?: IPaginationMeta;

  readonly pagination$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});
  refresh$ = new BehaviorSubject<null>(null);
  readonly query$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  readonly waitlist$ = combineLatest([this._event$, this.pagination$, this.query$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([event, pagination, query]) =>
      event ? await this.eventSvc.getWaitlist(event.id, query, pagination) : null
    ),
    tap(data => (this.metadata = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  ngOnDestroy() {
    this.query$.complete();
    this._event$.complete();
    this.pagination$.complete();
  }

  async addToWaitlist() {
    const dialog = this.matDialog.open(AddToWaitlistDialog, { data: this.event });
    if ((await toPromise(dialog.afterClosed())) === true) this.pagination$.next(this.pagination$.value);
  }

  async openDialog(communityId: string, userId: string, eventId: string) {
    const _dialog = toPromise(
      this.dialog
        .open(DeleteUserFromEventDialog, {
          data: { communityId, userId, eventId },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
        .pipe(first())
    );

    if (await _dialog) {
      this.refresh$.next(null);
    }
  }
}
