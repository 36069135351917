import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Account } from '@greco/finance-accounts';
import { InitiateExportDialog } from '@greco/ngx-data-exports';
import { FilterBarComponent } from '@greco/ngx-filters';
import { UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  CustomChargeResource,
  CustomChargeResourceAction,
  Purchase,
  PurchaseResource,
  PurchaseResourceAction,
} from '@greco/sales-purchases';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { PurchasesTableComponent, SaleCategoryPickerComponent } from '../../components';
import { CreateCustomChargeDialog } from '../../dialogs';
import { PurchaseService } from '../../services';
import {
  PurchaseCreatedDateFilter,
  PurchaseSalesCategoryFilter,
  PurchaseSalesStatusFilter,
  PurchaseSearchFilter,
  PurchaseSoldByFilter,
  PurchaseTypeFilter,
  PurchaseUserFilter,
} from './filters';

@Component({
  selector: 'greco-purchases-page',
  templateUrl: './purchases.page.html',
  styleUrls: ['./purchases.page.scss'],
})
export class PurchasesPage implements OnChanges, OnDestroy, AfterViewInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private purchaseSvc: PurchaseService,
    private securitySvc: SecurityService,
    private userFilter: PurchaseUserFilter,
    private soldByFilter: PurchaseSoldByFilter,
    private categoryFilter: PurchaseSalesCategoryFilter,
    private userPaymentMethodsSvc: UserPaymentMethodService
  ) {}

  @ViewChild(FilterBarComponent) private filterBar?: FilterBarComponent;

  @ViewChild(PurchasesTableComponent) table?: PurchasesTableComponent;
  @ViewChild('saleCategoryPicker') saleCategoryPicker!: SaleCategoryPickerComponent;

  @Input() account!: Account;
  @Input() communityId!: string;
  @Input() productVariant!: TemplateRef<any>;

  private _productVariantIds: string[] = [];
  @Input() set productVariantIds(ids: string[]) {
    this._productVariantIds = ids?.filter(Boolean) ?? [];
  }
  get productVariantIds() {
    return this._productVariantIds;
  }

  form = this.formBuilder.group({
    variants: [[], Validators.minLength(1)],
  });

  userId: string | undefined = undefined;

  filterOptions = [
    PurchaseSearchFilter,
    PurchaseUserFilter,
    PurchaseSoldByFilter,
    PurchaseCreatedDateFilter,
    PurchaseTypeFilter,
    PurchaseSalesStatusFilter,
  ];

  canCustomCharge$ = this.securitySvc.hasAccess(CustomChargeResource.key, CustomChargeResourceAction.CREATE, {}, true);
  canSeeStats$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.SEE_STATS, {}, true);
  canBulkVoid$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.BULK_VOID, {}, true);
  canBulkRetry$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.BULK_RETRY, {}, true);

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  private _statsRefresh$ = new BehaviorSubject<number>(1);
  private onDestroy$ = new Subject<void>();

  stats$ = combineLatest([this.filters$, this._statsRefresh$, this.canSeeStats$]).pipe(
    debounceTime(500),
    switchMap(([filters, _, canSeeStats]) => {
      if (filters.queryObject.s.includes('user.id')) {
        const userIdIndex = filters.queryObject.s.indexOf('user.id');
        this.userId = filters.queryObject.s.substring(userIdIndex + 18, userIdIndex + 46);
      }

      if (!canSeeStats) return of(null);
      const saleCategories = this.saleCategoryPicker.selected$.value;
      const queryBuilder = new RequestQueryBuilder();
      queryBuilder.search({
        $and: [
          ...JSON.parse(filters.queryObject.s).$and,
          {
            'saleCategory.id': {
              $in: saleCategories.length ? saleCategories.map(sc => sc.id) : ['not_a_sale_category'],
            },
          },
        ],
      });
      return this.purchaseSvc.getStats(queryBuilder, { accountId: this.account.id });
    })
  );

  @PropertyListener('communityId')
  private _communityUpdated() {
    this.userFilter.communityId = this.communityId;
    this.categoryFilter.accountId = this.account.id;
    this.soldByFilter.communityId = this.communityId;

    if (this.filterBar) {
      this.filterBar.values = [];
    }
  }

  async createCustomCharge() {
    const dialog = this.dialog.open(CreateCustomChargeDialog, {
      data: { accountId: this.account.id, communityId: this.communityId },
      width: '750px',
      maxWidth: '90%',
    });
    await toPromise(dialog.afterClosed());
    await this.refresh();
  }

  exportPaymentMethods() {
    InitiateExportDialog.open(this.dialog, {
      processorId: 'PaymentMethodsDataExportProcessor',
      initiateExport: () => {
        return this.userPaymentMethodsSvc.exportPaymentMethods({ communityId: this.communityId }, this.filters$.value);
      },
    });
  }

  async openPurchase(purchase: Purchase) {
    await this.router.navigate([purchase.id], { relativeTo: this.route });
  }

  async refresh() {
    this.table?.refresh();
    this._statsRefresh$.next(this._statsRefresh$.value + 1);
  }

  ngAfterViewInit() {
    this.saleCategoryPicker.selected$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this._statsRefresh$.next(this._statsRefresh$.value + 1);
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.account?.previousValue !== changes.account?.currentValue) {
      await this.refresh();
    }
  }

  ngOnDestroy() {
    this.filters$.complete();
    this._statsRefresh$.complete();

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
