import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { EventTemplate } from '@greco/booking-events';
import { CreateEventTemplateDto, UpdateEventTemplateDetailsDto } from '@greco/nestjs-booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
@Injectable()
export class EventTemplateService {
  constructor(private http: HttpClient) {}

  // @Post()
  async createEventTemplate(dto: CreateEventTemplateDto & { imageUrl?: File }) {
    const fd = new FormData();
    if (dto.imageUrl) fd.append('file', dto.imageUrl[0]);
    fd.append('data', JSON.stringify(dto));
    return await toPromise(this.http.post<EventTemplate>('/api/event-templates', fd));
  }

  // @Get(':templateId')
  async getOne(templateId: string) {
    return await toPromise(this.http.get<EventTemplate>(`/api/event-templates/${templateId}`));
  }

  async getTemplatesByCalendar(calendarId: string) {
    return await toPromise(this.http.get<EventTemplate[]>(`/api/event-templates/${calendarId}`));
  }

  // All the templates(NO PAGINATION)
  async getAllEventTemplates(communityId: string) {
    return await toPromise(this.http.get<EventTemplate[]>(`/api/event-templates/list/${communityId}`));
  }

  // @Put(':templateId/details')
  async updateEventTemplateDetails(templateId: string, dto: UpdateEventTemplateDetailsDto & { imageUrl?: File }) {
    const fd = new FormData();
    if (dto.imageUrl) fd.append('file', dto.imageUrl[0]);
    fd.append('data', JSON.stringify(dto));
    return await toPromise(this.http.put<EventTemplate>(`/api/event-templates/${templateId}/details`, fd));
  }

  // @Delete(':templateId')
  async deleteEventTemplate(templateId: string) {
    return await toPromise(this.http.delete<EventTemplate>(`/api/event-templates/${templateId}`));
  }

  // @Get()
  async paginate(query: RequestQueryBuilder, communityId: string, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<EventTemplate>>('/api/event-templates', {
        params: {
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          communityId,
        },
      })
    );
  }
}
