import { Pipe, PipeTransform } from '@angular/core';
import { ProductStatus, ProductVariantInventory } from '@greco/sales-products';

@Pipe({ name: 'inventory' })
export class InventoryPipe implements PipeTransform {
  transform(inventories: ProductVariantInventory[]): ProductVariantInventory[] {
    return inventories.filter(inventory => inventory?.variant?.status !== ProductStatus.Archived);
  }
}

@Pipe({ name: 'variantInventory' })
export class VariantInventoryPipe implements PipeTransform {
  transform(variantId: string, inventories: ProductVariantInventory[]): ProductVariantInventory | undefined {
    return inventories?.find(inventory => inventory?.variantId === variantId);
  }
}
