import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'greco-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ImageUploadComponent }],
})
export class ImageUploadComponent implements MatFormFieldControl<File[]>, ControlValueAccessor, OnDestroy {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef,
    private snack: MatSnackBar,
    private dialog: MatDialog
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'greco-image-upload';

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  stateChanges = new Subject<void>();

  @Output() isTouched = new EventEmitter<boolean>();

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `${ImageUploadComponent._controlType}-${ImageUploadComponent._id++}`;
  readonly controlType = ImageUploadComponent._controlType;

  private _value: File[] | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: File[] | null) {
    this._value = value ? [...value] : null;
    this._onChange?.(value);
    this.stateChanges.next();
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  private _acceptFile = false;
  @Input() get acceptFile() {
    return this._acceptFile;
  }
  set acceptFile(acceptFile: boolean) {
    this._acceptFile = acceptFile;
  }

  private _urls: string[] = [];
  @Input() get urls() {
    return this._urls;
  }
  set urls(urls: string[]) {
    this._urls = urls;
  }

  private _documents: string | null = '';
  @Input() get documents() {
    return this._documents;
  }
  set documents(documents: string | null) {
    this._documents = documents;
  }

  private _multiple = false;
  @Input() set multiple(multiple: boolean) {
    this._multiple = coerceBooleanProperty(multiple);
    this.stateChanges.next();
  }
  get multiple() {
    return this._multiple;
  }

  get errorState() {
    return (
      !!this.ngControl?.touched &&
      (!!Object.keys(this.ngControl?.errors || {}).length || (this.required && !this.value))
    );
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.image-upload-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: File[] | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: File[] | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  addImage(files: File[] | null): void {
    this.touched();
    if (files && files.length) {
      for (const file of files) {
        this.urls.push(URL.createObjectURL(file));
        if (this.value) this.value = [...this.value, file];
        else this.value = [file];
      }
      if (this.value) this.value = [...this.value];
      this.isTouched.emit(true);
    }
  }

  removeImage(url: string) {
    this.touched();
    const index = this.urls.indexOf(url);
    this.urls.splice(index, 1);
    if (index === 0) this.value = null;
    else if (this.value) this.value.splice(index, 1);
    if (this.value) this.value = [...this.value];
    this.isTouched.emit(true);
    this.stateChanges.next();
  }

  addFile(files: File[] | null): void {
    this.touched();
    if (files && files.length) {
      for (const file of files) {
        this.urls.push(URL.createObjectURL(file));
        if (this.value) this.value = [...this.value, file];
        else this.value = [file];
      }
      if (this.value) this.value = [...this.value];
    }
    this.isTouched.emit(true);
  }

  removeFile(url: string) {
    this.touched();
    const index = this.urls.indexOf(url);
    this.urls.splice(index, 1);
    if (index === 0) this.value = null;
    else if (this.value) this.value.splice(index, 1);
    this.isTouched.emit(true);
    this.stateChanges.next();
  }

  viewFile(filePath: string) {
    this.dialog.open(SimpleDialog, {
      data: {
        content: `<iframe type="application/pdf" height="1000" width="1000" src="${filePath}#toolbar=0"> </iframe>`,
        showCloseButton: false,
        buttons: [
          { label: 'Close', role: 'close' },
          { label: 'Download', role: 'submit', resultFn: () => window.open(filePath) },
        ],
      },
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
