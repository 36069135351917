import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { LetModule } from '@greco/ngx-let-util';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { AlertModule } from '@greco/ui-alert';
import { BoxModule } from '@greco/ui-box';
import { NgPipesModule } from 'ngx-pipes';
import {
  UserAlertsComponent,
  UserExpiringPaymentMethodAlertComponent,
  UserOutstandingPurchaseAlertComponent,
} from './components';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    NgPipesModule,
    RouterModule,
    AlertModule,
    BoxModule,
    PurchasesModule,
    PaymentsModule,
    LetModule,
  ],
  declarations: [UserExpiringPaymentMethodAlertComponent, UserOutstandingPurchaseAlertComponent, UserAlertsComponent],
  exports: [UserExpiringPaymentMethodAlertComponent, UserOutstandingPurchaseAlertComponent, UserAlertsComponent],
})
export class UserAlertsModule {}
