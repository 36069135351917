import { SecurityResource } from '@greco/security';

export const COMMUNITY_VIDEO_UNLOCK_OPTION_SECURITY_RESOURCE = 'community_video_unlock_option';

export enum CommunityVideoUnlockOptionSecurityResourceAction {
  READ = 'READ',
  MANAGE = 'MANAGE',
}

export const CommunityVideoUnlockOptionSecurityResource: SecurityResource = {
  key: COMMUNITY_VIDEO_UNLOCK_OPTION_SECURITY_RESOURCE,
  module: 'videos',

  title: 'Community Video Unlock Options',
  description: 'Manage community video unlock options',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CommunityVideoUnlockOptionSecurityResourceAction.READ,
      title: 'Read Community Video Unlock Options',
      description: 'Ability to read video unlock options for a community',
    },
    {
      key: CommunityVideoUnlockOptionSecurityResourceAction.MANAGE,
      title: 'Manage Community Video Unlock Options',
      description: 'Ability to manage video unlock options for a community',
    },
  ],
};
