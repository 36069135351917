import { SecurityResource } from '@greco/security';

export const COMMUNITY_AGREEMENT_SECURITY_RESOURCE = 'community_agreements';

export enum CommunityAgreementSecurityActions {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  ARCHIVE = 'ARCHIVE',
  EXECUTE = 'EXECUTE',
  LEAVE_UNSIGNED = 'LEAVE_UNSIGNED',
}

export const CommunityAgreementSecurityResource: SecurityResource = {
  key: COMMUNITY_AGREEMENT_SECURITY_RESOURCE,
  module: 'community',
  title: 'Community Agreements',
  description: 'Manage community agreement options',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CommunityAgreementSecurityActions.READ,
      title: 'Read agreements in a community',
      description: 'Ability to read agreements in a community',
    },
    {
      key: CommunityAgreementSecurityActions.CREATE,
      title: 'Create agreements to a community',
      description: 'Ability to create a new agreement in a community',
    },
    {
      key: CommunityAgreementSecurityActions.UPDATE,
      title: 'Update agreeements in a community',
      description: 'Ability to update agreements in a community',
    },
    {
      key: CommunityAgreementSecurityActions.ARCHIVE,
      title: 'Archive agreements from a community',
      description: 'Ability to archive an unused agreement in a community',
    },
    {
      key: CommunityAgreementSecurityActions.EXECUTE,
      title: 'Execute user agreeemnts in a community',
      description: 'Ability to execute an agreement for a user in a community',
    },
    {
      key: CommunityAgreementSecurityActions.LEAVE_UNSIGNED,
      title: 'Leave agreements unsigned',
      description: "Ability to leave a memeber's agreement unsigned when creating a user agreement",
    },
  ],
};
