import { Injectable } from '@angular/core';
import { SelectFilter } from '@greco/ngx-filters';
import { SaleCategory } from '@greco/sales-purchases';
import { CondOperator, SFields } from '@nestjsx/crud-request';
import { SaleCategoryService } from '../../../services';

@Injectable({ providedIn: 'any' })
export class PurchaseSalesCategoryFilter extends SelectFilter {
  constructor(private saleCategorySvc: SaleCategoryService) {
    super('PurchaseSalesCategoryFilter', {
      label: 'Sales Category',
      shortLabel: 'Sales Category',
      description: 'Purchase Sales Category',
      properties: ['saleCategory.id'],
    });
  }

  public accountId?: string;

  getValueLabel(value: SaleCategory | SaleCategory[]): string {
    return Array.isArray(value) ? value.map(v => v.label).join(', ') : value.label;
  }

  async getValueOptions(search?: string): Promise<any[]> {
    if (!this.accountId) return [];
    const categories = await this.saleCategorySvc.getMany(this.accountId, search);
    return search ? categories.slice(0, 5) : categories;
  }

  serializeValue(value: SaleCategory | SaleCategory[]): string {
    return Array.isArray(value) ? value.map(v => v.id).join(',') : value.id;
  }

  async deserializeValue(serializedValue: string) {
    const categoryIds = serializedValue.split(',');
    if (!categoryIds.length) return [];

    const categories = await Promise.all(categoryIds.map(id => this.saleCategorySvc.getSaleCategory(id)));
    return categories.filter(category => !!category);
  }

  getPropertySField(property: string, operator: CondOperator, value: SaleCategory[]): SFields {
    return {
      [property]: {
        [operator === CondOperator.EQUALS ? '$in' : '$notin']: value.map(v => v.id),
      },
    };
  }
}
