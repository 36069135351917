import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  Pipe,
  PipeTransform,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Directive({
  selector: '[grecoTableCol]',
  exportAs: 'grecoTableCol',
})
export class TableColumnDirective {
  id = uuid();
  @Input('grecoTableCol') title?: string;
  @Input('grecoTableColSticky') sticky = false;
  @Input('grecoTableColStickyEnd') stickyEnd = false;
  @Input('grecoTableColFitContent') fitContent = false;
  @Input('grecoTableColAlignRight') alignRight = false;
  @Input('grecoTableColAlignCenter') alignCenter = false;
  @Input('grecoTableColSorting') sorting?: string;

  @Input('grecoTableColStyle') style?: (item: any, index: number) => NgStyle['ngStyle'];

  constructor(public templateRef: TemplateRef<TableColumnDirective>) {}
}

@Pipe({ name: 'memo' })
export class MemoPipe implements PipeTransform {
  transform(value: (...args: any[]) => any, ...args: any[]) {
    return value(...args);
  }
}

@Component({
  selector: 'greco-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(TableColumnDirective) colTemplates!: QueryList<TableColumnDirective>;
  @Input() data: any[] = [];
  @Input() hideEmptyPlaceholder = false;
  @Input() loading = false;
  @Input() draggable = false;
  @Input() customDragPreview = false;
  @Output() dragStart = new EventEmitter();

  @Input() rowStyle?: (item: any, index: number) => NgStyle['ngStyle'];

  @Output() rowClick = new EventEmitter();
  @Output() dropEvent = new EventEmitter();
  @Input() @HostBinding('class.highlight') highlight = false;

  @Output() sortChange = new EventEmitter<Sort>(true);
  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatTable) table?: MatTable<any>;

  @Input() activeSort?: Sort;

  hoveredRow?: number;

  private _onDestroy$ = new Subject<void>();

  getColumnIds() {
    return this.colTemplates.map(c => c.id);
  }

  ngAfterViewInit() {
    if (this.sort) {
      this.sort.sortChange.pipe(takeUntil(this._onDestroy$)).subscribe(sort => {
        this.sortChange.emit(sort);
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  drop(event: CdkDragDrop<any[], any>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    this.table?.renderRows();
    this.dropEvent.emit({ event: event, data: this.data });
  }

  outputDragStart(event: CdkDragStart) {
    this.dragStart.emit(event.source.data);
  }
}
