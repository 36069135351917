import { SecurityResource } from '@greco/security';

export const COMMUNITY_TAX_SECURITY_RESOURCE = 'community_tax';

export enum CommunityTaxSecurityActions {
  READ = 'READ',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const CommunityTaxSecurityResource: SecurityResource = {
  key: COMMUNITY_TAX_SECURITY_RESOURCE,
  module: 'Finance',
  title: 'Community Taxes',
  description: 'Manage Community Taxes',

  context: class {
    accountId!: string;
  },

  actions: [
    {
      key: CommunityTaxSecurityActions.READ,
      title: 'Read Community Taxes',
      description: 'Ability to read taxes in a community',
    },
    {
      key: CommunityTaxSecurityActions.ADD,
      title: 'Add Community Taxes',
      description: 'Ability to add platform taxes to a community',
    },
    {
      key: CommunityTaxSecurityActions.UPDATE,
      title: 'Update Community Taxes',
      description: 'Ability to update default taxes in a community',
    },
    {
      key: CommunityTaxSecurityActions.DELETE,
      title: 'Delete Community Taxes',
      description: 'Ability to delete taxes from a community',
    },
  ],
};
