import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { CalendarEvent, EventSecurityResource, EventSecurityResourceAction, EventSeries } from '@greco/booking-events';
import { CommunityAgreementsService } from '@greco/ngx-community-agreements';
import { BuildTextFilter } from '@greco/ngx-filters';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AgreementSearchFilter } from 'libs/greco/identity/feature-community-agreements/src/lib/components/community-agreements-table/community-agreements-table.component';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { LinkAgreementDialog } from '../../dialogs/link-agreement/link-agreement.dialog';
import { ViewCommunityAgreementDialog } from '../../dialogs/view-community-agreement/view-community-agreement.dialog';
import { EventCommunityAgreementsUsageService } from '../../services/event-community-agreements-usage.service';

@Injectable({ providedIn: 'root' })
export class CommunityAgreementTitleFilter extends BuildTextFilter('AgreementTitleFilter', {
  label: 'Title',
  shortLabel: 'Title',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['agreement.title'],
}) {}

@Component({
  selector: 'greco-event-community-agreement-usage',
  templateUrl: './event-community-agreement-usage.component.html',
  styleUrls: ['./event-community-agreement-usage.component.scss'],
})
export class EventCommunityAgreementUsageComponent {
  constructor(
    private agreementsSvc: CommunityAgreementsService,
    private agreementUsageSvc: EventCommunityAgreementsUsageService,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService
  ) {}

  private _event$ = new BehaviorSubject<CalendarEvent | EventSeries | null>(null);
  refresh$ = new BehaviorSubject(null);
  statusFilter = false;
  loading = false;
  linkedIds: string[] = [];
  filterOptions$ = [AgreementSearchFilter, CommunityAgreementTitleFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  paginationMeta?: IPaginationMeta;
  linkedAgrIds!: string[];

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string>(1);
  @Input() communityId!: string;

  @Input() set event(event) {
    this._event$.next(event);
    this.refresh$.next(null);
  }

  get event() {
    return this._event$.value;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  eventCommAgrs$ = combineLatest([this.filters$, this.pagination$, this._event$, this.refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    debounceTime(500),
    switchMap(([filters, options, event]) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.agreementUsageSvc.paginateEventAgreementUsages(event!.id, options, filters);
    }),
    tap(({ meta }) => setTimeout(() => (this.paginationMeta = meta))),
    map(({ items }) => items),

    tap(() => setTimeout(() => (this.loading = false))),

    tap(response => {
      response.forEach((element: any) => {
        this.linkedIds.push(element.agreementId);
      });
    })

    // map(item => this.linkedIds.push(item.agreeemntId))
  );

  canRead$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(EventSecurityResource.key, EventSecurityResourceAction.READ_PRIVATE, {
            communityId: communityId,
          })
        : false;
    })
  );

  canUpdate$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(EventSecurityResource.key, EventSecurityResourceAction.UPDATE, {
            communityId: communityId,
          })
        : false;
    })
  );

  readonly communityAgreements$ = combineLatest([this._communityId$]).pipe(
    switchMap(async ([communityId]) => {
      return await this.agreementsSvc.getMany(communityId);
    })
  );

  async linkAgreement() {
    if (this.event) {
      const community = this.event.community;
      const dialog = this.dialog.open(LinkAgreementDialog, {
        data: {
          community: community,
          linkedIds: this.linkedIds,
          eventId: this._event$.value?.id,
        },
        width: '1000px',
        maxWidth: '90%',
      });

      const response = await toPromise(dialog.afterClosed());

      if (response?.length) {
        let success = 0;
        let failure = 0;
        for (const item of response) {
          try {
            await this.agreementUsageSvc.linkAgreementToEvent(this.event, item.id);
            success++;
          } catch (err) {
            console.error(err);
            failure++;
          }
        }
        this.snacks.open(
          'Successfully linked ' + success + ' agreements.',
          failure === 0 ? 'Ok!' : failure + ' errors!',
          {
            duration: 2500,
            panelClass: failure === 0 ? 'mat-primary' : 'mat-warn',
          }
        );
        this.refresh$.next(null);
      }
    }
  }

  async viewAgreement(agreementId: string) {
    const getAgreement = await this.agreementsSvc.getOne(agreementId);
    this.dialog.open(ViewCommunityAgreementDialog, {
      data: { commAgreement: { commAgreement: getAgreement } },
      width: '750px',
      maxWidth: '90%',
    });
  }

  async deleteAgreementUsage(usageId: string) {
    const response = await this.agreementUsageSvc.deleteEventAgreementUsage(usageId);
    if (!response)
      this.snacks.open('Something went wrong! Please try again.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    else {
      this.snacks.open('Agreement usage removed from event', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.refresh$.next(null);
    }
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
