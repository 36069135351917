<form [formGroup]="_form">
  <div class="fields-section">
    <div class="color-and-title">
      <!-- Title -->
      <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
        <mat-label>Title</mat-label>
        <div style="display: flex; flex-direction: row">
          <input
            matInput
            type="text"
            [required]="required"
            [readonly]="readonly"
            required
            formControlName="title"
            style="width: 100%"
          />
          <mat-checkbox color="primary" formControlName="private" [required]="false">Private</mat-checkbox>
        </div>
      </mat-form-field>
    </div>

    <mat-form-field class="ignore" style="margin-bottom: 16px">
      <mat-label> Group </mat-label>
      <input matInput formControlName="group" style="width: 100%" />
      <mat-hint>Logical grouping of calendars to help members book.</mat-hint>
    </mat-form-field>

    <mat-form-field floatLabel="always">
      <mat-label>Image</mat-label>
      <greco-image-upload
        [required]="false"
        formControlName="image"
        [multiple]="false"
        [urls]="initialUrls"
        [disabled]="readonly"
      ></greco-image-upload>
    </mat-form-field>
    <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard" floatLabel="always">
      <mat-label>Icon</mat-label>
      <greco-icon-picker formControlName="icon" [disabled]="readonly"></greco-icon-picker>
    </mat-form-field>
  </div>
</form>
