import {
  PRODUCT_CONDITION_TYPE_SUBSCRIPTION_SALE_CATEGORY,
  SubscriptionSaleCategoryProductCondition,
} from '@greco/sales-products';
import { SaleCategory } from '@greco/sales-purchases';
import { DynamicSelectModel } from '@ng-dynamic-forms/core';
import { ProductConditionForm } from '../dialogs';
import { DynamicCategoryPickerModel } from '../forms';

export const PRODUCT_CONDITION_CONFIG_SUBSCRIPTION_SALE_CATEGORY: ProductConditionForm = {
  label: 'Active Subscription (Sale Category)',
  type: PRODUCT_CONDITION_TYPE_SUBSCRIPTION_SALE_CATEGORY,
  matIcon: 'autorenew',
  getConditionDetails({ saleCategory, hasSubscription }: SubscriptionSaleCategoryProductCondition) {
    return `Available if user ${hasSubscription ? 'has' : 'does not have'} active subscription within "${
      saleCategory?.label
    }"`;
  },
  getConditionShortDetails({ saleCategory, hasSubscription }: SubscriptionSaleCategoryProductCondition) {
    return `${hasSubscription ? 'Has' : 'No'} Active Subscription Within: ${saleCategory?.label}`;
  },
  getDtoFromFormValue({ saleCategory, hasSubscription }: { saleCategory: SaleCategory; hasSubscription: boolean }) {
    return {
      saleCategoryId: saleCategory.id,
      saleCategory,
      hasSubscription,
    };
  },
  getFormModel(communityId: string) {
    return [
      new DynamicSelectModel({
        id: 'hasSubscription',
        label: 'Validation',
        required: true,
        options: [
          { label: 'Does Not Have Subscription', value: false },
          { label: 'Have Subscription', value: true },
        ],
        errorMessages: {
          required: 'Select a valid operator',
        },
        validators: {
          required: null,
        },
      }),
      new DynamicCategoryPickerModel({
        id: 'saleCategory',
        communityId: communityId,
        label: 'Subscription Category',
        required: true,
        errorMessages: {
          required: 'Select a valid category',
        },
        validators: {
          required: null,
        },
      }),
    ];
  },
};
