<greco-simple-dialog [data]="dialogData">
  <div style="display: flex; gap: 24px; align-items: center">
    <mat-icon>payment</mat-icon>
    <greco-select-payment-method
      [required]="true"
      [userId]="data.user?.id || null"
      [value]="data.subscription.paymentMethod || null"
      (valueChange)="updateSubscriptionPaymentMethod(data.subscription, $event)"
      [allowBankPayments]="data.subscription | allowBankPayments"
    ></greco-select-payment-method>
  </div>
</greco-simple-dialog>
