<ng-container *grecoLet="isMobile$ | async as isMobile">
  <ng-container *grecoLet="parent$ | async as bookedBy">
    <ng-container *grecoLet="linkedAccounts$ | async as linkedAccounts" style="height: 100%">
      <ng-container *grecoLet="hasBookingOptionError$ | async as hasBookingOptionError">
        <div style="display: flex; flex-direction: row; width: 100%; gap: 32px">
          <div class="bookings" [style.width]="isMobile || forceMobile ? '100%' : '60%'">
            <ng-container *ngIf="previews.length; else noBookings">
              <div *ngFor="let booking of previews; let index = index" style="margin-bottom: 16px">
                <greco-collapsible-section
                  *grecoLet="checkForMultiBookingOptionError(hasBookingOptionError?.indexes || [], index) as beError"
                  [header]="{ title: 'Booking For ' + booking?.user?.displayName, icon: 'confirmation_number' }"
                  [expanded]="index === previews.length - 1"
                  [disabled]="previews.length <= 1 && !isMobile"
                  #section
                >
                  <div
                    header
                    id="warning"
                    *ngIf="
                      !section.expanded &&
                      (previews[index].preview?.errors?.length || previews[index].preview?.warnings?.length || beError || !previews[index].preview?.dto?.completed === false)
                      "
                  >
                    <small>
                      <!-- errors -->
                      <span
                        *ngIf="previews[index].preview?.errors?.length"
                        [innerHTML]="$any(previews[index].preview).errors[0] | safe: 'html'"
                        style="color: var(--warn-color)"
                      ></span>

                      <!-- warnings -->
                      <span
                        *ngIf="previews[index].preview?.warnings?.length"
                        [innerHTML]="$any(previews[index].preview).warnings[0] | safe: 'html'"
                        style="color: var(--warn-color)"
                      ></span>

                      <span
                        *ngIf="!previews[index].preview?.errors?.length && allowPendingBookings"
                        style="color: var(--warn-color)"
                      >
                        Since the selected booking option isn't available, the booking will remain pending until
                        checked-in.
                      </span>
                      <span style="color: var(--warn-color)" *ngIf="beError">Perks are not sufficient</span>
                    </small>
                  </div>

                  <!-- errors/warnings -->
                  <mat-icon
                    header
                    *ngIf="booking.preview?.errors?.length || booking.preview?.warnings?.length ; else completed"
                    color="warn"
                  >
                    error_outline
                  </mat-icon>

                  <ng-template #completed>
                    <mat-icon
                      header
                      style="color: var(--success-color)"
                      *ngIf="previews[index].preview && !previews[index].preview!.errors.length"
                    >
                      check_circle
                    </mat-icon>
                  </ng-template>

                  <div
                    header
                    [matTooltip]="
                        previews.length <= 1
                          ? 'You must have at least one other booking in order to discard this'
                          : 'Discard this booking'
                      "
                  >
                    <button
                      [disabled]="previews.length <= 1"
                      mat-icon-button
                      (click)="$event.stopImmediatePropagation()"
                      [matMenuTriggerFor]="discardMenu"
                    >
                      <mat-icon>more_vertical</mat-icon>
                      <mat-menu #discardMenu>
                        <button
                          mat-menu-item
                          (click)="$event.stopImmediatePropagation(); removeUser(booking.user)"
                          [matTooltip]="
                              previews.length <= 1
                                ? 'You must have at least one other booking in order to discard this'
                                : 'Discard this booking'
                            "
                          [disabled]="previews.length <= 1"
                          color="warn"
                        >
                          <mat-icon>delete</mat-icon>Discard
                        </button>
                      </mat-menu>
                    </button>
                  </div>

                  <greco-preview-course
                    [event]="event"
                    [selectedBookingOption]="selectedBookingOption"
                    [user]="booking.user"
                    [allowPendingBookings]="allowPendingBookings"
                    [bookedBy]="bookedBy || user"
                    [overridePaymentMethod]="selectedPaymentMethod"
                    (previewUpdated)="$event ? previewChanged(index, $event) : {}"
                    (signaturesUpdated)="$event ? signaturesChanged(index, $event) : {}"
                    (requirementsUpdated)="$event ? requirementsChanged(index, $event) : {}"
                    [_stats]="_stats"
                  ></greco-preview-course>

                  <!-- errors/warnings -->
                  <div
                    *ngIf="previews[index].preview?.errors?.length || previews[index].preview?.warnings?.length || previews[index].preview?.dto?.completed === false"
                  >
                    <!-- errors -->
                    <div
                      id="error"
                      *ngFor="let error of booking.preview?.errors"
                      style="display: flex; align-items: center; padding: 0px 16px 8px; margin-top: 4px"
                    >
                      <mat-icon color="warn" style="margin-right: 8px">error_outline</mat-icon>
                      <span [innerHTML]="error | safe: 'html'"></span>
                    </div>

                    <!-- warnings -->
                    <div
                      id="warning"
                      *ngFor="let wr of booking.preview?.warnings"
                      style="display: flex; align-items: center; padding: 0px 16px 8px; margin-top: 4px"
                    >
                      <mat-icon color="warn" style="margin-right: 8px">error_outline</mat-icon>
                      <span [innerHTML]="wr | safe: 'html'"></span>
                    </div>

                    <div
                      *ngIf="!booking.preview?.errors?.length &&  allowPendingBookings"
                      style="display: flex; align-items: center; padding: 0px 16px 8px; margin-top: 16px"
                    >
                      <mat-icon color="warn" style="margin-right: 8px">info</mat-icon>
                      <i>
                        Since the selected booking option isn't available, the booking will remain pending until
                        checked-in.
                      </i>
                    </div>
                  </div>
                </greco-collapsible-section>
              </div>
            </ng-container>

            <ng-template #noBookings>
              <div style="margin-bottom: 16px">
                <greco-collapsible-section
                  style="height: 372px"
                  [mat-menu-trigger-for]="selectUserMenu"
                  [disabled]="true"
                  [expanded]="true"
                  [header]="{ title: event.title, icon: 'event' }"
                >
                  <div header>
                    <p style="margin-bottom: 0; text-align: right">
                      <mat-icon style="width: 16px; height: 16px; font-size: 16px">place</mat-icon>
                      <span>{{ event.community.name }}</span>
                    </p>
                    <p>
                      {{ event.startDate | date }}, {{ event.startDate | date: 'shortTime' }} - {{ event.duration }}mins
                    </p>
                  </div>

                  <div style="display: flex; justify-content: center; width: 100%; height: 100%">
                    <button
                      *ngIf="linkedAccounts?.length"
                      mat-flat-button
                      color="primary"
                      [mat-menu-trigger-for]="selectUserMenu"
                      style="max-height: 32px; margin-top: 137px; margin-bottom: 137px"
                      (click)="$event.stopImmediatePropagation()"
                    >
                      Select an Account
                    </button>

                    <button
                      *ngIf="!linkedAccounts?.length"
                      mat-flat-button
                      color="primary"
                      style="max-height: 32px; margin-top: 137px; margin-bottom: 137px"
                      (click)="$event.stopImmediatePropagation(); addGuest()"
                    >
                      Add a Guest
                    </button>
                  </div>
                </greco-collapsible-section>
              </div>
            </ng-template>
            <ng-container *ngIf="!isMobile && !forceMobile; else paymentPreview"></ng-container>
          </div>

          <div class="sidebar" [class.in-page]="footerInPage" *ngIf="!isMobile && !forceMobile">
            <div class="sidebar-content">
              <ng-container *ngIf="isMobile  else paymentPreview"></ng-container>
            </div>
          </div>

          <ng-template #paymentPreview>
            <greco-collapsible-section
              [header]="{ title: 'Payment', icon: 'receipt_long' }"
              [disabled]="true"
              style="width: 100%"
            >
              <greco-multi-booking-payment-preview
                [user]="bookedBy || user"
                [previews]="previews"
                [event]="event"
                (selectedPaymentMethod)="selectedPaymentMethodChanged($event)"
              ></greco-multi-booking-payment-preview>
            </greco-collapsible-section>
          </ng-template>
        </div>

        <ng-container *ngIf="bookingOptions$ | async as bookingOptions">
          <div class="footer" [class.in-page]="footerInPage" *ngIf="bookingOptions.length">
            <div class="footer-content" *grecoLet="requiredBoosts$ | async as requiredBoosts">
              <div style="display: flex; flex-direction: row; width: 100%; justify-content: flex-end; gap: 16px">
                <ng-container *grecoLet="isValid$ | async as isValid">
                  <ng-container *grecoLet="canBook$ | async as canBook">
                    <ng-container *grecoLet="availableIn$ | async as availableIn">
                      <div
                        *grecoLet="remainingCapacity$ | async as remainingCapacity"
                        style="display: flex; flex-direction: row; gap: 16px"
                      >
                        <mat-chip-list>
                          <mat-chip
                            style="min-width: 64px"
                            [class]="
                              previews.length > (remainingCapacity || 0) || (remainingCapacity === 0 && previews.length === 0)
                                ? 'capacityReached'
                                : 'capacityGood'
                            "
                            matTooltip="Spots Available"
                          >
                            <mat-icon
                              *ngIf="!isMobile && previews.length > (remainingCapacity || 0)"
                              style="margin-right: 4px"
                              >error_outline</mat-icon
                            >

                            <small style="margin-bottom: 0; margin-top: 0; line-height: 16px">
                              <span *ngIf="previews.length">
                                {{ previews.length }}
                                <span> {{ isMobile|| forceMobile ? '/' : ' selected of ' }} </span>
                              </span>
                              {{ remainingCapacity }}
                              <span> {{ isMobile || forceMobile ? 'spots' : 'spots available' }} </span>
                            </small>
                          </mat-chip>
                        </mat-chip-list>

                        <button
                          mat-flat-button
                          style="min-width: 128px"
                          color="primary"
                          matTooltip="Select a linked user"
                          (click)="$event.stopImmediatePropagation()"
                          [mat-menu-trigger-for]="selectUserMenu"
                          #menuTrigger="matMenuTrigger"
                        >
                          Add Another
                          <mat-icon>expand_circle_down</mat-icon>
                        </button>

                        <button mat-flat-button class="buttons" color="primary" (click)="addGuest()">
                          {{ isMobile || forceMobile ? 'Guest' : 'Add Guest' }}
                          <mat-icon>add_circle_outline</mat-icon>
                        </button>

                        <button
                          mat-flat-button
                          color="primary"
                          [disabled]="!isValid || !canBook || !previews.length || confirming || hasBookingOptionError?.error"
                          [matTooltip]=" canBook ? '' : availableIn ? ('Available ' + availableIn) :  ''"
                          (click)="confirmRegistration()"
                        >
                          <span>{{isMobile || forceMobile ? 'Confirm' : 'Confirm Booking'}}</span>
                          <mat-icon *ngIf="confirming" style="margin-left: 4px" class="spin">loop</mat-icon>
                        </button>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

              <!-- end of footer content -->
            </div>
          </div>
        </ng-container>

        <mat-menu #selectUserMenu>
          <ng-container *ngFor="let data of linkedAccounts">
            <div
              *ngIf="data.link.status === 'ACTIVE'"
              [matTooltip]="clashes[data.link.accountId] ? 'This person is booked into another event that overlaps with this event!' : ''"
            >
              <button
                mat-menu-item
                style="display: flex; justify-content: space-between; padding-right: 0"
                [disabled]="clashes[data.link.accountId] || data.selected || data.booked"
                (click)="selected(data.link.account!)"
              >
                <p *ngIf="data.link.account?.id !== user.id; else yourself">
                  {{ data.link.account?.displayName }}<span *ngIf="data.booked"> (Already Booked)</span>
                </p>

                <ng-template #yourself>
                  <p>Yourself <span *ngIf="data.booked"> (Already Booked)</span></p>
                </ng-template>

                <mat-icon *ngIf="clashes[data.link.accountId]" style="margin: 12px 16px">error_outlined</mat-icon>
              </button>
            </div>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
