import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Account } from '@greco/finance-accounts';
import {
  AccountPaymentMethodResource,
  AccountPaymentMethodResourceAction,
  PaymentMethod,
} from '@greco/finance-payments';
import { AccountPaymentMethodService } from '@greco/ngx-finance-payments';
import { SecurityService } from '@greco/ngx-security-util';

@Component({
  selector: 'greco-account-payment-methods',
  templateUrl: './account-payment-methods.component.html',
  styleUrls: ['./account-payment-methods.component.scss'],
})
export class AccountPaymentMethodsComponent implements OnChanges {
  constructor(private paymentMethodSvc: AccountPaymentMethodService, private securitySvc: SecurityService) {}

  @Input() account!: Account;

  @Output() refreshed = new EventEmitter();

  hasRemoveAccess = false;
  hasReadAccess = false;
  hasAddAccess = false;
  hasSetDefaultAccess = false;

  loading = true;
  paymentMethods: PaymentMethod[] = [];
  defaultPaymentMethod?: string;
  usedPaymentMethods?: string[];

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.account?.currentValue !== changes.account?.previousValue) {
      await this.refresh();
    }
  }

  async selectDefaultPaymentMethod(paymentMethodId: string) {
    this.defaultPaymentMethod = paymentMethodId;
    await this.paymentMethodSvc.setDefault(paymentMethodId);
    await this.refresh();
  }

  async refresh() {
    this.refreshed.emit(null);
    await Promise.all([
      this.loadDefaultPaymentMethod(),
      this.loadPaymentMethods(),
      this.loadUsedPaymentMethods(),
      this.loadAccess(),
    ]);
  }

  async removePaymentMethod(paymentMethodId: string) {
    await this.paymentMethodSvc.removePaymentMethod(paymentMethodId);
    await this.refresh();
  }

  private async loadDefaultPaymentMethod() {
    if (this.account) {
      this.defaultPaymentMethod = (
        await this.paymentMethodSvc.getDefault(this.account.id, true).catch(() => undefined)
      )?.id;
    } else {
      this.defaultPaymentMethod = undefined;
    }
  }

  private async loadPaymentMethods() {
    setTimeout(() => (this.loading = true));

    if (this.account) {
      this.paymentMethods = await this.paymentMethodSvc.getAll(this.account.id).catch(() => []);
    } else {
      this.paymentMethods = [];
    }

    setTimeout(() => (this.loading = false));
  }

  private async loadUsedPaymentMethods() {
    if (this.account) this.usedPaymentMethods = await this.paymentMethodSvc.getUsedPaymentMethods(this.account.id);
    else this.usedPaymentMethods = [];
  }

  private async loadAccess() {
    if (this.account) {
      this.hasRemoveAccess = await this.securitySvc.hasAccess(
        AccountPaymentMethodResource.key,
        AccountPaymentMethodResourceAction.REMOVE,
        { accountId: this.account.id }
      );
      this.hasReadAccess = await this.securitySvc.hasAccess(
        AccountPaymentMethodResource.key,
        AccountPaymentMethodResourceAction.READ,
        { accountId: this.account.id }
      );
      this.hasAddAccess = await this.securitySvc.hasAccess(
        AccountPaymentMethodResource.key,
        AccountPaymentMethodResourceAction.ADD,
        { accountId: this.account.id }
      );
      this.hasSetDefaultAccess = await this.securitySvc.hasAccess(
        AccountPaymentMethodResource.key,
        AccountPaymentMethodResourceAction.SET_DEFAULT,
        { accountId: this.account.id }
      );
    } else {
      this.hasRemoveAccess = false;
      this.hasAddAccess = false;
      this.hasReadAccess = false;
      this.hasSetDefaultAccess = false;
    }
  }
}
