import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Address, COUNTRIES_DB } from '@greco-fit/scaffolding';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { Subject, Subscription } from 'rxjs';
import { bodyExpansion } from '../../animations';

@Component({
  selector: 'greco-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: AddressInputComponent }],
  animations: [bodyExpansion]
})
export class AddressInputComponent implements ControlValueAccessor, MatFormFieldControl<Address>, OnDestroy {
  // tslint:disable-next-line: variable-name
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  // tslint:disable-next-line: variable-name
  static ngAcceptInputType_required: boolean | string | null | undefined;

  get empty() {
    const { formatted, line1, line2, postalCode, state, country, city } = this.value || {};
    return !formatted && !line1 && !line2 && !postalCode && !state && !country && !city;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty || !!this.value?.formatted;
  }
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): Address | null {
    return {
      ...this.form.value,
      formatted: [
        this.form.get('line1').value,
        this.form.get('city').value,
        [this.form.get('state').value, this.form.get('postalCode').value].filter(i => !!i).join(' '),
        this.form.get('country').value?.name,
        this.form.get('line2').value ? '(' + this.form.get('line2').value + ')' : null
      ]
        .map(i => i?.trim())
        .filter(i => !!i)
        .join(', ')
    };
  }
  set value(address: Address | null) {
    if (address && this.form.value !== address) {
      this.dirty = true;
      this.form.setValue(address);
      if (this.form.invalid) setTimeout(() => (this.expanded = true), 2000);
    }
    this.stateChanges.next();
    this.onChange(this.value);
  }

  get errorState(): boolean {
    return this.required ? this.form.invalid && this.dirty : false;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      line1: ['', Validators.required],
      line2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.required],
      country: ['', Validators.required],
      formatted: ['']
    });

    this.formChangesSub = this.form.valueChanges.subscribe(value => {
      this.value = value;
      const errors = {
        ...this.form.get('line1').errors,
        ...this.form.get('city').errors,
        ...this.form.get('state').errors,
        ...this.form.get('postalCode').errors,
        ...this.form.get('country').errors
      };
      this.ngControl?.control?.setErrors(Object.keys(errors)?.length ? errors : null);
    });

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  @ViewChild('street') streetInput: HTMLInputElement;
  @ViewChild('street2') street2Input: HTMLInputElement;

  @HostBinding('class.expanded') public expanded = false;

  public form: FormGroup;
  public stateChanges = new Subject<void>();
  public id: string;
  public focused: boolean;
  public dirty = false;
  public formChangesSub: Subscription;

  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {};

  mapAddress(address: GoogleAddress): Address {
    if (address) {
      const components =
        address?.address_components
          ?.filter(c =>
            c.types.some(t0 =>
              ['street_number', 'route', 'locality', 'administrative_area_level_1', 'country', 'postal_code'].some(
                t1 => t1 === t0
              )
            )
          )
          .reduce((acc, cur) => ({ ...acc, [cur.types[0]]: { short: cur.short_name, long: cur.long_name } }), {}) ||
        null;
      this.expanded = true;
      return {
        formatted: address?.formatted_address || null,
        line1: [components['street_number']?.long, components['route']?.long].join(' ').trim() || null,
        line2: components['street2'] || null,
        city: components['locality']?.long || null,
        state: components['administrative_area_level_1']?.long || null,
        postalCode: components['postal_code']?.long || null,
        country: COUNTRIES_DB.find(c => c.alpha2Code === components['country']?.short) || null
      };
    } else return null;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.formChangesSub.unsubscribe();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  writeValue(address: Address | null): void {
    this.value = address;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_fn: any): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDescribedByIds(_ids: string[]): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onContainerClick() {}
}
