import { Injectable, OnDestroy, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

export type AppTheme = 'light' | 'dark';

@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnDestroy {
  public theme: BehaviorSubject<AppTheme> = new BehaviorSubject<AppTheme>('light');

  private sub: Subscription;
  private prev: AppTheme = this.theme.value;

  constructor(private rendererFactory: RendererFactory2) {
    const renderer = this.rendererFactory.createRenderer(null, null);
    this.sub = this.theme.subscribe(theme => {
      if (this.prev) renderer.removeClass(document.body, 'theme-' + this.prev);
      if (theme) {
        renderer.addClass(document.body, 'theme-' + theme);
        this.prev = theme;
      }
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTheme() {
    return this.theme.value;
  }

  setTheme(theme: AppTheme) {
    this.theme.next(theme);
  }

  toggleTheme() {
    this.theme.next(this.theme.value === 'dark' ? 'light' : 'dark');
  }
}
