<div id="details-section">
  <div id="iframe-container">
    <iframe
      *ngIf="video"
      [src]="video.embedUrl | safe: 'resourceUrl'"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    ></iframe>
  </div>
</div>

<div class="page-main">
  <greco-collapsible-section [header]="{ icon: 'ondemand_video', title: video.title || '' }" [disabled]="true">
    <div header>
      <ng-content select="[header]"></ng-content>
    </div>
    <div style="display: flex; gap: 16px; flex-wrap: wrap; word-wrap: break-word">
      <greco-box style="flex: 1; height: 100%; position: relative; display: flex">
        <img
          *ngIf="video"
          [src]="video.thumbnailUrl || '/assets/lf3/logo.png' | safe: 'resourceUrl'"
          alt="Thumbnail"
          style="width: 120px; aspect-ratio: 16 9; object-fit: cover; border-radius: 4px"
        />

        <div style="margin-left: 12px" *ngIf="video">
          <h3 style="margin-bottom: 12px">
            <strong>{{ video.title }}</strong>
          </h3>
          <mat-chip-list>
            <greco-video-tag
              [admin]="true"
              [tag]="tag"
              *ngFor="let tag of video.tags"
              [community]="community"
            ></greco-video-tag>
          </mat-chip-list>
        </div>
      </greco-box>

      <div style="flex: 2">
        <ng-container *ngIf="video">
          <h3 style="margin: 0">Description:</h3>
          <div
            style="
              padding: 12px 8px;
              gap: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
            "
            [style.border-bottom]="''"
          >
            <div style="display: flex; gap: 12px">
              <div style="display: flex; flex-direction: column; justify-content: space-evenly">
                <div style="display: flex; align-items: center; font-size: 14px">
                  <p style="margin: 0">{{ video.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div style="padding-top: 16px; display: flex; flex-direction: column; gap: 16px">
        <div *ngIf="publishedOn" style="display: flex; align-items: center">
          <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
            >event</mat-icon
          >
          <strong matTooltip="Video Publish Date"> Publish: {{publishedOn ? (publishedOn | date) : 'Never' }} </strong>
        </div>
        <div style="display: flex; align-items: center">
          <mat-icon style="line-height: 14px; font-size: 14px; width: 14px; height: 14px; margin-right: 4px"
            >event</mat-icon
          >
          <strong matTooltip="Video Upload Date">Uploaded: {{video.created | date }}</strong>
        </div>
      </div>
    </div>
  </greco-collapsible-section>
  <br />
  <ng-content select="[extras]"></ng-content>
</div>
