import { Pipe, PipeTransform } from '@angular/core';
import { ProductVariantInventory } from '@greco/sales-products';

@Pipe({ name: 'variantInventory' })
export class GetVariantInventoryPipe implements PipeTransform {
  transform(selectedVariantId?: string, inventories: ProductVariantInventory[] = []) {
    if (!selectedVariantId || !inventories) return undefined;

    return inventories.find(inv => inv?.variantId === selectedVariantId && inv?.enabled);
  }
}
