import { CondOperator, SFields } from '@nestjsx/crud-request';
import * as moment from 'moment';
import { Filter, FilterOptions } from './filter';

export class DateFilter extends Filter {
  constructor(typeName: string, options: Omit<FilterOptions, 'type' | 'allowedOperators'>) {
    super(typeName, {
      ...options,
      type: 'date',
      allowedOperators: [
        CondOperator.EQUALS,
        CondOperator.BETWEEN,
        CondOperator.GREATER_THAN_EQUALS,
        CondOperator.LOWER_THAN,
      ],
    });
  }

  type: 'date' = 'date';

  getValueOptions(search?: string): Date[] {
    try {
      const date = moment(search, false);
      return date.isValid() ? [date.toDate()] : [];
    } catch {
      return [];
    }
  }

  getValueLabel(value: Date | Date[]): string {
    return Array.isArray(value)
      ? `${moment(value[0]).format('MMMM Do')}  -  ${moment(value[1]).format('MMMM Do, YYYY')}`
      : moment(value).format('MMMM Do, YYYY');
  }

  serializeValue(value: Date | Date[]): string {
    return (Array.isArray(value) ? value : [value]).map(date => date.toISOString()).join(',');
  }

  deserializeValue(serializedValue: string): Date | Date[] {
    if (serializedValue.includes(',')) {
      const dates = serializedValue.split(',').map(v => moment(v, true));
      if (dates.some(date => !date.isValid())) throw new Error();
      return dates.map(date => date.toDate());
    }

    const date = moment(serializedValue, true);
    if (!date.isValid()) throw new Error();
    return date.toDate();
  }

  getPropertySField(property: string, operator: CondOperator, value: Date | Date[]): SFields {
    function queryFormat(value: moment.Moment): string {
      return value.clone().startOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss');
    }

    switch (operator) {
      case CondOperator.EQUALS: {
        if (Array.isArray(value)) throw new Error();
        return {
          ['$and' as any]: [
            { [property]: { [CondOperator.GREATER_THAN_EQUALS]: queryFormat(moment(value)) } },
            { [property]: { [CondOperator.LOWER_THAN]: queryFormat(moment(value).add(1, 'day')) } },
          ],
        };
      }

      case CondOperator.GREATER_THAN_EQUALS: {
        if (Array.isArray(value)) throw new Error();
        return { [property]: { [operator]: queryFormat(moment(value)) } };
      }

      case CondOperator.LOWER_THAN: {
        if (Array.isArray(value)) throw new Error();
        return { [property]: { [operator]: queryFormat(moment(value)) } };
      }

      case CondOperator.BETWEEN: {
        if (!Array.isArray(value) || value.length !== 2) throw new Error();
        return {
          ['$and' as any]: [
            { [property]: { [CondOperator.GREATER_THAN_EQUALS]: queryFormat(moment(value[0])) } },
            { [property]: { [CondOperator.LOWER_THAN]: queryFormat(moment(value[1]).add(1, 'day')) } },
          ],
        };
      }

      default: {
        return {
          [property]: { [operator]: queryFormat(moment(Array.isArray(value) ? value[0] : value)) },
        };
      }
    }
  }
}

export function BuildDateFilter(typeName: string, options: Omit<FilterOptions, 'type' | 'allowedOperators'>) {
  return class extends DateFilter {
    constructor() {
      super(typeName, options);
    }
  };
}
