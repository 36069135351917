import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SafePipeModule } from 'safe-pipe';
import { SimpleDialog } from './dialogs/simple/simple.dialog';

export * from './dialogs/simple/simple.dialog';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, SafePipeModule, MatIconModule],
  exports: [SimpleDialog],
  declarations: [SimpleDialog],
})
export class SimpleDialogModule {}
