import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuperTitleComponent } from './super-title.component';

@NgModule({
  declarations: [SuperTitleComponent],
  exports: [SuperTitleComponent],
  imports: [CommonModule]
})
export class SuperTitleModule {}
