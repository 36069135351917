import { Component, Input, ViewChild } from '@angular/core';
import { VariantsService } from '@greco/ngx-sales-products';
import { Product, ProductImage, ProductVariant } from '@greco/sales-products';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'greco-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
})
export class ProductImagesComponent {
  constructor(private variantSvc: VariantsService) {}
  @Input() product!: Product;
  @Input() selectedVariant!: ProductVariant | undefined;

  @ViewChild(SwiperComponent) swiper?: SwiperComponent;

  selectedImage: ProductImage | undefined;
  currentVariantImage: ProductImage | undefined = this.selectedVariant?.image;

  selectImage(image: ProductImage) {
    if (this.selectedVariant) {
      this.currentVariantImage = this.selectedVariant?.image;
      this.selectedVariant = undefined;
    }
    this.selectedImage = image;
  }
}
