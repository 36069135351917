<ng-container *ngIf="userBookingOptions$ | async as userBookingOptions"></ng-container>
<ng-container *ngIf="bookedByBookingOptions$ | async as bookedByBookingOptions"></ng-container>
<ng-container *ngIf="updateInstanceCount$ | async as updateInstanceCount"></ng-container>

<div *ngIf="selectedBookingOption && !readonly" style="display: flex; flex-direction: row">
  <div
    class="my-perks-checkbox-container"
    *ngIf="_bookedBy && _user?.id !== _bookedBy?.id"
    [matTooltip]="
      disableUserPerks ? 'You do not have permission to use their perks' : 'Toggle between your perks or theirs'
    "
  >
    <mat-checkbox
      color="primary"
      [checked]="!bookedByPerksAreSelected"
      [disabled]="disableUserPerks"
      (change)="toggleBookedByPerks()"
    >
      Use {{ _user ? _user.displayName + "'s" : 'their' }} perks
    </mat-checkbox>
  </div>

  <div class="complimentary-checkbox-container" *ngIf="complimentaryBookingOption">
    <ng-container *grecoLet="canCreatePending$ | async as canCreatePending">
      <ng-container *grecoLet="canCreatePendingFromSubscription$ | async as canCreatePendingFromSub">
        <mat-checkbox
          color="primary"
          [checked]="complimentaryIsSelected"
          (change)="toggleComplimentary(canCreatePending || false, canCreatePendingFromSub || false)"
        >
          Complimentary
        </mat-checkbox>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="container" *grecoLet="bookingOptions$ | async as bookingOptions">
  <div class="grid" [class.disabled]="complimentaryIsSelected" [class.border]="expanded">
    <ng-container
      *ngIf="bookingOptions"
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ $implicit: selectedBookingOption, showDetailsLink: true }"
    ></ng-container>

    <button
      mat-icon-button
      color="accent"
      *ngIf="!readonly && bookingOptions && bookingOptions.length > 1 && !complimentaryIsSelected"
      (click)="expanded = !expanded"
    >
      <mat-icon>swap_vert</mat-icon>
    </button>
  </div>

  <div *ngIf="bookingOptions && bookingOptions.length > 1">
    <div [@heightExpansion]="expanded ? 'expanded' : 'collapsed'">
      <ng-container *ngFor="let option of bookingOptions">
        <ng-container
          *grecoLet="((bookedByPerksAreSelected && _bookedBy ? _bookedByStats : _stats) || {})[option.id] as stat"
        >
          <ng-container *grecoLet="canCreatePending$ | async as canCreatePending">
            <ng-container *grecoLet="canCreatePendingFromSubscription$ | async as canCreatePendingFromSub">
              <div
                *ngIf="option.id !== selectedBookingOption?.id"
                class="grid {{ $any(option).disabled ? 'disabled' : 'hover' }}"
                (click)="
                  $any(option).disabled
                    ? ''
                    : selectBookingOption(canCreatePending || false, canCreatePendingFromSub || false, stat, option)
                "
              >
                <ng-container
                  [ngTemplateOutlet]="template"
                  [ngTemplateOutletContext]="{ $implicit: option }"
                ></ng-container>

                <button mat-icon-button [disabled]="$any(option).disabled">
                  <mat-icon>arrow_right</mat-icon>
                </button>
              </div></ng-container
            >
          </ng-container>
        </ng-container>
        <mat-divider></mat-divider>
      </ng-container>
    </div>
  </div>

  <ng-template #template let-option let-showDetailsLink="showDetailsLink">
    <ng-container *ngIf="option || complimentaryIsSelected; else noOption">
      <div class="perk-info">
        <mat-icon class="main-icon">confirmation_number</mat-icon>
        <!-- <div class="badge">
          <greco-perk-badge style="user-select: none" [badge]="option.badge" [small]="true"></greco-perk-badge>
        </div> -->

        <div>
          <h3 [matTooltip]="option.title">{{ option.title }}</h3>

          <div style="display: grid; grid-template-columns: auto 1fr; font-size: smaller">
            <p
              *grecoLet="option.bookingWindow | grecoMinutes as bookingWindow"
              [matTooltip]="(bookingWindow | test : '-') ? '' : bookingWindow + ' Booking Window'"
            >
              <span>{{ option.price / 100 | currency }}</span>
              <span>{{ _boosters[option.id] ? ' + ' + _boosters[option.id] + ' Boosters' : '' }}</span>
            </p>

            <a
              class="details-link"
              *ngIf="showDetailsLink && !complimentaryIsSelected"
              (click)="openInfoDialog(option)"
            >
              <span>Details</span>
              <mat-icon>chevron_right</mat-icon>
            </a>
          </div>
        </div>
      </div>

      <ng-container
        *grecoLet="((bookedByPerksAreSelected && _bookedBy ? _bookedByStats : _stats) || {})[option.id] as stat"
      >
        <div
          class="available"
          [matTooltip]="(stat?.consumable || '0') + ' Available Now ' + (stat?.reusable || '0') + ' Reusable'"
        >
          <h5>Available</h5>

          <p *ngIf="!complimentaryIsSelected">
            <span *ngIf="stat?.consumable">{{ stat!.consumable }}</span>
            <span *ngIf="stat?.consumable && stat?.reusable">&nbsp;+&nbsp;</span>
            <span *ngIf="stat?.reusable">{{ stat!.reusable }}<mat-icon>replay</mat-icon></span>
            <span *ngIf="!stat?.consumable && !stat?.reusable">-</span>
          </p>
        </div>

        <div class="subscriptions">
          <h5>Subscriptions</h5>
          <p *ngIf="complimentaryIsSelected || !stat?.subscriptions?.length">-</p>

          <p
            *ngFor="let sub of stat?.subscriptions || []"
            matTooltipClass="custom-tooltip"
            [matTooltip]="
              sub.title +
              '&#13;' +
              sub.quantity +
              ' Every ' +
              (sub.recurrence.frequency | grecoFrequency : sub.recurrence.period) +
              ' (Renews on ' +
              (sub.nextRenewal | date : 'mediumDate') +
              ')'
            "
          >
            {{ sub.title }}
          </p>
        </div>

        <div class="used">
          <h5>Used</h5>
          <p>{{ stat?.used || '-' }}</p>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
<ng-template #noOption>
  <p style="color: var(--warn-color); grid-column: 1 / 10" *ngIf="selectedBookingOptionLoaded">
    <ng-container *ngIf="closestBookingWindowInHours === -1; else someWindowSet">
      You do not have enough perks to book this event. Head to our
      <a href="/shop" style="color: var(--accent-color)">Shop</a> to purchase or get in touch with a team member for
      help with your booking.
    </ng-container>
    <ng-template #someWindowSet>
      You are currently outside the booking window. Booking will be open in {{ bookingAvailableIn
      }}<span *ngIf="closestBookingWindowInHours && closestBookingWindowInHours !== -1"
        >&nbsp;({{ closestBookingWindowInHours / 24 | round }}day{{
          (closestBookingWindowInHours / 24 | round) === 1 ? '' : 's'
        }}
        before the class starts)</span
      >.
    </ng-template>
  </p>
</ng-template>
