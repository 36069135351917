import { InjectionToken } from '@angular/core';
import { ProductCondition, ProductConditionDto } from '@greco/sales-products';
import { DynamicFormModel } from '@ng-dynamic-forms/core';

export interface ProductConditionForm {
  label: string;
  matIcon: string;
  type: string;

  getConditionDetails(condition: ProductCondition): string;
  getConditionShortDetails(condition: ProductCondition): string;

  getFormModel?(communityId: string): DynamicFormModel;
  getFormValue?(condition: ProductConditionDto): Record<string, unknown> | null;

  getDtoFromFormValue?(formValue: Record<string, unknown>): Record<string, unknown>;
}

export const PRODUCT_CONDITION_FORMS = new InjectionToken<ProductConditionForm[]>('PRODUCT_CONDITION_FORMS');
