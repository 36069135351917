import { SecurityResource } from '@greco/security';

export const PAYMENT_DISPUTE_SECURITY_RESOURCE = 'payment_dispute';

export enum PaymentDisputeSecurityActions {
  READ = 'READ',
  UPDATE = 'UPDATE',
  SUBMIT = 'SUBMIT',
}

export const PaymentDisputeSecurityResource: SecurityResource = {
  key: PAYMENT_DISPUTE_SECURITY_RESOURCE,
  module: 'Finance',
  title: 'Payment Disputes',
  description: 'Manage payment dispute options',

  context: class {
    accountId?: string;
  },

  actions: [
    {
      key: PaymentDisputeSecurityActions.READ,
      title: 'Read',
      description: 'Ability to read payment disputes in a community',
    },
    {
      key: PaymentDisputeSecurityActions.UPDATE,
      title: 'Update',
      description: 'Ability to update payment disputes in a community',
    },
    {
      key: PaymentDisputeSecurityActions.SUBMIT,
      title: 'Submit',
      description: 'Ability to submit dispute responses and close disputes for a community',
    },
  ],
};
