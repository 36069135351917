<greco-alert-card
  [clickable]="true"
  (click)="navigate()"
  [small]="small"
  [class]="expired ? 'error' : 'warn'"
  [color]="expired ? 'error' : 'warn'"
  [title]="expired ? 'Payment Method Expired' : 'Payment Method Expiring Soon'"
  [subtitle]="
    expired ? paymentMethod.label + ' is expired' : paymentMethod.label + ' expires on ' + paymentMethod.details
  "
></greco-alert-card>
