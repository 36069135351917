import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import type {
  AddVariantPerkDto,
  CreateVariantDto,
  UpdateVariantDto,
  UpdateVariantPerkDto,
} from '@greco/nestjs-sales-products';
import { ProductVariant, VariantPerk } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class VariantsService {
  constructor(private http: HttpClient) {}

  // @Get(':productId/variants')
  async paginateVariants(
    { productId, pagination }: { productId: string; pagination?: PaginatedQueryParams },
    query?: RequestQueryBuilder
  ) {
    return await toPromise(
      this.http.get<PaginatedDto<ProductVariant>>(`/api/products/${productId}/variants`, {
        params: {
          ...query?.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get(':productId/variants/:variantId')
  async getOneVariant({ productId, variantId }: { productId: string; variantId: string }) {
    return await toPromise(this.http.get<ProductVariant>(`/api/products/${productId}/variants/${variantId}`));
  }

  // @Get('variants/:variantId')
  async getOneVariantWithoutProduct(variantId: string) {
    return await toPromise(this.http.get<ProductVariant>(`/api/products/variants/${variantId}`));
  }

  // @Post(':productId/variants')
  async createVariant({ productId, data }: { productId: string; data: CreateVariantDto }) {
    return await toPromise(this.http.post<ProductVariant>(`/api/products/${productId}/variants`, data));
  }

  // @Put(':productId/variants/:variantId')
  async updateVariant({
    productId,
    variantId,
    data,
  }: {
    productId: string;
    variantId: string;
    data: UpdateVariantDto;
  }) {
    return await toPromise(
      this.http.post<ProductVariant>(`/api/products/${productId}/variants/${variantId}`, { data })
    );
  }

  async activateVariant({ productId, variantId }: { productId: string; variantId: string }) {
    return await toPromise(
      this.http.post<ProductVariant>(`/api/products/${productId}/variants/${variantId}/activate`, {})
    );
  }

  async archiveVariant({ productId, variantId }: { productId: string; variantId: string }) {
    return await toPromise(
      this.http.post<ProductVariant>(`/api/products/${productId}/variants/${variantId}/archive`, {})
    );
  }

  // @Post(':productId/variants/:variantId/duplicate')
  async duplicateVariant(productId: string, variantId: string) {
    return await toPromise(
      this.http.post<ProductVariant>(`/api/products/${productId}/variants/${variantId}/duplicate`, {})
    );
  }

  // @Post(':productId/variants/:variantId/perks')
  async addVariantPerk({
    productId,
    variantId,
    data,
  }: {
    productId: string;
    variantId: string;
    data: AddVariantPerkDto;
  }) {
    return await toPromise(this.http.post<VariantPerk>(`/api/products/${productId}/variants/${variantId}/perks`, data));
  }

  // @Delete(':productId/variants/:variantId/perks/:perkId')
  async removeVariantPerk({
    productId,
    variantId,
    variantPerkId,
  }: {
    productId: string;
    variantId: string;
    variantPerkId: string;
  }) {
    return await toPromise(
      this.http.delete<any>(`/api/products/${productId}/variants/${variantId}/perks/${variantPerkId}`)
    );
  }

  // @Put(':productId/variants/:variantId/perks/:perkId')
  async updateVariantPerk({
    productId,
    variantId,
    variantPerkId,
    data,
  }: {
    productId: string;
    variantId: string;
    variantPerkId: string;
    data: UpdateVariantPerkDto;
  }) {
    return await toPromise(
      this.http.put<VariantPerk>(`/api/products/${productId}/variants/${variantId}/perks/${variantPerkId}`, data)
    );
  }

  // @Post(':productId/variants/:variantId/perks/:variantPerkId/approve')
  async activateVariantPerk({
    productId,
    variantId,
    variantPerkId,
    retroactivelyGrant,
  }: {
    productId: string;
    variantId: string;
    variantPerkId: string;
    retroactivelyGrant?: boolean;
  }) {
    return await toPromise(
      this.http.post<VariantPerk>(`/api/products/${productId}/variants/${variantId}/perks/${variantPerkId}/activate`, {
        retroactivelyGrant,
      })
    );
  }

  // @Post(':productId/variants/:variantId/perks/:perkId/archive')
  async archiveVariantPerk({
    productId,
    variantId,
    variantPerkId,
  }: {
    productId: string;
    variantId: string;
    variantPerkId: string;
  }) {
    return await toPromise(
      this.http.post<VariantPerk>(`/api/products/${productId}/variants/${variantId}/perks/${variantPerkId}/archive`, {})
    );
  }

  // @Get(':productId/variants/:variantId/subscriptions-without-perk-count')
  getProductVariantSubscriptionCount(productId: string, variantId: string, variantPerkId: string) {
    return toPromise(
      this.http.get<number>(
        `/api/products/${productId}/variants/${variantId}/perks/${variantPerkId}/subscriptions-without-perk-count`
      )
    );
  }
}
