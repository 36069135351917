import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'greco-form-save-buttons',
  templateUrl: './form-save-buttons.component.html',
  styleUrls: ['./form-save-buttons.component.scss'],
})
export class FormSaveButtonsComponent {
  @Input() form: FormGroup = new FormGroup({});
  @Input() resetValue: any;
  @Input() saveAction: (form: FormGroup) => void | Promise<void> = console.log;

  @Output() formReset = new EventEmitter<any>();

  @Input() processing?: boolean;
  public loading = false;

  reset() {
    this.form.reset(this.resetValue);
    this.formReset.emit(this.resetValue);
  }

  async submit() {
    try {
      this.loading = true;
      await this.saveAction(this.form);
    } finally {
      this.loading = false;
    }
  }
}
