import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'greco-form-picker-dialog',
  templateUrl: './form-picker.dialog.html',
})
export class FormPickerDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) init: { reusable: boolean; required: boolean },
    private dialogRef: MatDialogRef<FormPickerDialog>
  ) {
    this.reusable = init.reusable;
    this.required = init.required;
  }

  reusable = true;
  required = true;

  close() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close({ reusable: this.reusable, required: this.required });
  }
}
