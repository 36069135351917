import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

export interface LetViewContext<T = any> {
  $implicit: T;
  grecoLet: T;
}

@Directive({ selector: '[grecoLet]' })
export class LetDirective<T> implements OnDestroy {
  // tslint:disable-next-line: variable-name
  static ngTemplateGuard_grecoLet: 'binding';

  private _viewRef: EmbeddedViewRef<LetViewContext<T>>;
  private _templateRef: TemplateRef<LetViewContext<T>>;

  private readonly _context: LetViewContext<T> = { $implicit: null, grecoLet: null };

  public static ngTemplateContextGuard<T>(dir: LetDirective<T>, ctx: any): ctx is LetViewContext<T> {
    return true;
  }

  @Input() set grecoLet(value: T) {
    this._context.$implicit = this._context.grecoLet = value;
    this._updateView();
  }

  constructor(private viewContainerRef: ViewContainerRef, templateRef: TemplateRef<LetViewContext<T>>) {
    this._templateRef = templateRef;
  }

  ngOnDestroy() {
    this.viewContainerRef.clear();
  }

  private _updateView() {
    if (!this._viewRef) {
      this._viewRef = this.viewContainerRef.createEmbeddedView<LetViewContext<T>>(this._templateRef, this._context);
    }
  }
}
