import { Account } from '@greco/finance-accounts';
import { Payment } from '@greco/finance-payments';
import { Tax } from '@greco/finance-tax';
import { User } from '@greco/identity-users';

export enum AccountChargeStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',

  PAID = 'PAID',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',

  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PROCESSING_REFUND = 'PROCESSING_REFUND',

  VOIDED = 'VOIDED',
  PROCESSING_VOID = 'PROCESSING_VOID',
}

export interface AccountCharge {
  id: string;

  sourceAccountId: string;

  sourceAccount?: Account;

  destAccountId: string;

  destAccount?: Account;

  type: string;

  status: AccountChargeStatus;

  failureReason: string | null;

  failureDate: null | Date;

  completedOn: null | Date;

  createdById: null | string;

  createdBy?: User | null;

  created: Date;

  modified: Date;

  items: AccountChargeItem[];

  total: number;

  subtotal: number;

  tax: number;

  taxes: Tax[];

  paymentId: null | string;

  payment?: null | Payment;

  ignoredEmails?: string[];
}

export interface AccountChargeItem {
  id: string;

  title: string;

  price: number;

  quantity: number;
}
