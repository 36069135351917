import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PropertyListener } from '@greco/property-listener-util';
import { CommunityFormType } from '@greco/typeform';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { TypeformService } from '../../services';

@Component({
  selector: 'greco-community-typeform-selector',
  templateUrl: './community-typeform.component.html',
  styleUrls: ['./community-typeform.component.scss'],
})
export class GrecoCommunityTypeformSelectComponent implements OnInit, OnDestroy {
  constructor(private typeformSvc: TypeformService, private snackBar: MatSnackBar) {}

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string | null>();
  @Input() communityId?: string | null;

  loading = true;
  newForm: string | null = null;
  selectedTypeform: string | null = null;

  private _refresh$ = new BehaviorSubject(null);

  readonly typeformAccount$ = this._communityId$.pipe(
    tap(() => (this.loading = true)),
    switchMap(communityId => this.typeformSvc.getAccount(communityId)),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  readonly selectedTypeform$ = this._communityId$.pipe(
    tap(() => (this.loading = true)),
    switchMap(communityId =>
      this.typeformSvc.getCommunityTypeformConfig(communityId || '', CommunityFormType.SUBSCRIPTION_CANCEL)
    ),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  readonly forms$ = combineLatest([this.typeformAccount$, this._refresh$]).pipe(
    switchMap(async ([account]) => (account ? await this.typeformSvc.getConnectedForms(account.id) : []))
  );

  async saveForm() {
    if (!this.communityId) return;

    this.loading = true;

    try {
      await this.typeformSvc.saveCommunityTypeformConfig(
        this.communityId,
        this.newForm,
        CommunityFormType.SUBSCRIPTION_CANCEL
      );

      this.selectedTypeform = this.newForm;
      this.snackBar.open('Form configuration saved successfully', 'Ok', {
        duration: 2000,
        panelClass: 'mat-primary',
      });
    } catch (err) {
      this.snackBar.open('There was an error saving your configuration', 'Ok', {
        duration: 2000,
        panelClass: 'mat-primary',
      });
    }

    this.loading = false;
  }

  refresh() {
    this._refresh$.next(null);
  }

  ngOnDestroy() {
    this._refresh$.complete();
  }

  async ngOnInit() {
    const communityId = this.communityId || '';
    const item = await this.typeformSvc.getCommunityTypeformConfig(communityId, CommunityFormType.SUBSCRIPTION_CANCEL);
    this.selectedTypeform = item?.id || null;
    this.newForm = item?.id || null;
  }
}
