import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export type Favorite = { imageUrl: string; title: string; url?: string; tags?: string[] };

@Injectable()
export class UserEventsFavoritesService {
  constructor() {
    this.favorites = new Map<string, Favorite>(JSON.parse(localStorage.getItem('dashboard_favorites') || '[]'));
    this.favorites$.next(this.favorites);
    this.entries$.next(this.getEntries());
  }

  private favorites: Map<string, Favorite>;

  favorites$ = new ReplaySubject<Map<string, Favorite>>(1);
  entries$ = new ReplaySubject<[string, Favorite][]>(1);

  private getEntries() {
    const entries = [];
    for (const entry of this.favorites.entries()) {
      entries.push(entry);
    }
    return entries;
  }

  addFavorite(id: string, favorite: Favorite) {
    this.favorites.set(id, favorite);
    localStorage.setItem('dashboard_favorites', JSON.stringify([...this.favorites]));
    this.favorites$.next(this.favorites);
    this.entries$.next(this.getEntries());
  }

  unfavorite(id: string) {
    const deleted = this.favorites.delete(id);
    if (deleted) {
      localStorage.setItem('dashboard_favorites', JSON.stringify([...this.favorites]));
      this.favorites$.next(this.favorites);
      this.entries$.next(this.getEntries());
    }
    return deleted;
  }
}
