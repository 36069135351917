<ng-container *grecoLet="canCreate$ | async as canCreate">
  <ng-container *grecoLet="canExecute$ | async as canExecute">
    <greco-community-agreements-table [community]="community">
      <button
        mat-stroked-button
        filterbar
        color="primary"
        style="height: 32px"
        [matMenuTriggerFor]="agreement"
        *ngIf="canCreate || canExecute"
      >
        <span>Quick Actions</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #agreement>
        <button *ngIf="canCreate" mat-menu-item (click)="createAgreement()">
          <mat-icon>add</mat-icon>
          <span>Create Agreement</span>
        </button>
        <button *ngIf="canExecute" mat-menu-item (click)="createUserAgreement()">
          <mat-icon>history_edu</mat-icon>
          <span>Execute Agreement</span>
        </button>
      </mat-menu>
    </greco-community-agreements-table>
  </ng-container>
</ng-container>
