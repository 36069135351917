import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import {
  ProductCondition,
  ProductConditionContext,
  ProductConditionEvaluations,
  ProductConditionsRegistry,
  UserAvailabilityAddon,
} from '@greco/sales-products';

@Injectable()
export class ProductConditionService {
  constructor(private http: HttpClient) {}

  evaluateOne(condition: ProductCondition, ctx: ProductConditionContext) {
    return ProductConditionsRegistry.evaluateOne(condition, ctx);
  }

  evaluateAll(extension: UserAvailabilityAddon, ctx: ProductConditionContext): ProductConditionEvaluations {
    if (extension.enabled && extension.conditions && extension.conditions.length) {
      return ProductConditionsRegistry.evaluateAll(extension.conditions, ctx);
    } else return { result: true, messages: [], errors: [] };
  }

  getConditionContext(userId?: string) {
    return toPromise(
      this.http.get<ProductConditionContext>(`/api/products/context`, { params: { ...(userId && { userId }) } })
    );
  }
}
