<greco-table [data]="items || []" [rowStyle]="rowStyle" class="with-totals">
  <!-- [class.with-totals]="showTotals"
  [class.with-payment]="!!purchase.payment?.paymentMethod"
  [class.with-balance]="!!purchase.balanceUsed" -->

  <div *grecoTableCol="'Description'; let item; style: columnStyle" class="item-description">
    <ng-container *ngIf="item.description">
      <div class="item-image">
        <greco-smart-img
          class="userImageToExport"
          [src]="item.description.photoURL || 'assets/lf3/icon_square_pad.png'"
        ></greco-smart-img>
      </div>

      <div>
        <small *ngIf="showUsername">{{ item.description.user.displayName }} </small>
        <h3>{{ item.description.displayName }}</h3>
        <small>
          {{ item.description.description }}
          <small *ngIf="item.type === 'coupon'" style="color: var(--warn-color)">
            <strong> On initial purchase only </strong>
          </small>

          <small
            *ngIf="
              item.description.cycles && item.type !== 'coupon' && item.description.displayName !== 'Prorate deduction'
            "
            style="color: var(--warn-color)"
          >
            <strong>
              Total subscription length:
              {{ item.description.cycles * item.description.frequency }}
              {{
                item.description.period === 'DAILY'
                  ? 'day'
                  : item.description.period === 'WEEKLY'
                  ? 'week'
                  : item.description.period === 'MONTHLY'
                  ? 'month'
                  : item.description.period === 'YEARLY'
                  ? 'year'
                  : 'unknown'
              }}{{ item.description.cycles * item.description.frequency == 1 ? '' : 's' }}
            </strong>
            <em *ngIf="item.description.cycles > 1">
              (1 initial payment + {{ item.description.cycles - 1 }} renewal
              {{ item.description.cycles - 1 > 1 ? 's every' : ' after' }}
              {{ item.description.frequency }}
              {{
                item.description.period === 'DAILY'
                  ? 'day'
                  : item.description.period === 'WEEKLY'
                  ? 'week'
                  : item.description.period === 'MONTHLY'
                  ? 'month'
                  : item.description.period === 'YEARLY'
                  ? 'year'
                  : 'unknown'
              }}
              {{ item.description.frequency == 1 ? '' : 's' }})
            </em>
          </small>
        </small>

        <p *ngIf="item.description.minimumCommitmentLength" style="color: var(--warn-color)">
          <small>
            <strong>
              Minimum Commitment:
              {{ item.description.minimumCommitmentLength }}
              {{
                item.description.minimumCommitmentPeriod === 'DAILY'
                  ? 'day'
                  : item.description.minimumCommitmentPeriod === 'WEEKLY'
                  ? 'week'
                  : item.description.minimumCommitmentPeriod === 'MONTHLY'
                  ? 'month'
                  : item.description.minimumCommitmentPeriod === 'YEARLY'
                  ? 'year'
                  : 'unknown'
              }}{{ item.description.minimumCommitmentLength == 1 ? '' : 's' }}
            </strong>
          </small>
        </p>

        <div>
          <small *ngIf="item.variant?.autoUpgradeVariant">
            Product will auto-upgrade to {{ item.variant.autoUpgradeVariant.variantOpts[0].value }} ({{
              item.variant.autoUpgradeVariant.price / 100 | currency
            }}
            / {{ item.variant.autoUpgradeVariant.recurrence | grecoRecurrence }}) at the end of subscription
          </small>
        </div>
        <div *ngIf="inventories?.get(item.variant?.id) as variantInventory">
          <greco-alert
            *ngIf="
              !variantInventory.inventory?.enabled ||
              (variantInventory.inventory?.availableInventoryCount || 0) < variantInventory.itemQuantity
            "
            [config]="{
              title: variantInventory.addon?.outOfStockMessage || 'Out of Stock',
              type: $any('DANGER')
            }"
          ></greco-alert>
        </div>
      </div>

      <mat-divider></mat-divider>
    </ng-container>
  </div>

  <ng-container *ngIf="edit">
    <p *grecoTableCol="'Sale Category'; let item; style: columnStyle; alignRight: true" [class]="item.descriptionClass">
      <ng-template #regularColumn>
        <mat-chip *ngIf="item.saleCategory">{{ item.saleCategory.label }}</mat-chip>
      </ng-template>
      <ng-container *ngIf="categoryData && item.id && (canUpdate$ | async) && edit; else regularColumn">
        <mat-chip *ngIf="!disabledEdit; else disableEdit" [mat-menu-trigger-for]="menu">
          {{ item.saleCategory?.label || 'None' }}
        </mat-chip>

        <ng-template #disableEdit>
          <mat-chip>
            {{ item.saleCategory?.label || 'None' }}
          </mat-chip>
        </ng-template>

        <mat-menu #menu style="display: flex; flex-direction: column">
          <button
            mat-flat-button
            *ngFor="let categoryOption of categoryData"
            (click)="updateSaleCategory(item, categoryOption)"
          >
            {{ categoryOption.label }}
          </button>
        </mat-menu>
      </ng-container>
    </p>
  </ng-container>

  <p *grecoTableCol="'Qty'; let item; style: columnStyle; alignRight: true" [class]="item.quantityClass">
    {{ item.quantity }}
  </p>

  <p *grecoTableCol="'Unit Price'; let item; style: columnStyle; alignRight: true" [class]="item.unitPriceClass">
    {{ item.unitPrice }}
  </p>

  <div
    *grecoTableCol="'Amount'; let item; style: columnStyle; alignRight: true; stickyEnd: true; fitContent: true"
    [class]="item.amountClass"
  >
    <img
      style="max-width: 38px; max-height: 24px; min-height: 16px; vertical-align: middle"
      *ngIf="item.amountType === 'img'"
      [src]="item.amount"
    />
    <ng-container *ngIf="item.amountType !== 'img'">{{ item.amount }}</ng-container>
  </div>
</greco-table>
