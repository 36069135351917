import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { VideoTag } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({
  providedIn: 'root',
})
export class VideoTagService {
  constructor(private http: HttpClient) {}

  // @Get(':tagId')
  async getOne(tagId: string) {
    return await toPromise(this.http.get<VideoTag>(`/api/video-tags/${tagId}`));
  }
  async getTags(): Promise<VideoTag[]> {
    return await toPromise(this.http.get<VideoTag[]>(`/api/video-tags/all`));
  }

  // @Post()
  async create(label: string) {
    return await toPromise(this.http.post<VideoTag>(`/api/video-tags`, { label: label } /*as VideoTagDto*/));
  }

  // @Get()
  async paginate(query: RequestQueryBuilder, communityId?: string, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<VideoTag>>('/api/video-tags', {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
}
