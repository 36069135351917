import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { BookingOption, CalendarEvent, Tag, UserBookingOptionStats } from '@greco/booking-events';
import type { CreateBookingOptionDto, UpdateBookingOptionDto } from '@greco/nestjs-booking-events';
import { Perk } from '@greco/sales-perks';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class BookingOptionService {
  constructor(private http: HttpClient) {}

  async getComplimentaryOption() {
    return await toPromise(
      this.http.get<{ complimentary: BookingOption }>('/api/events-config').pipe(map(config => config.complimentary))
    );
  }

  // @Get('events/config')
  getBoosterConfig() {
    return this.http.get<{ boost: { perk: Perk; window: number } }>(`/api/events-config`).pipe(
      map(c => c.boost),
      shareReplay(1)
    );
  }

  // @Get('for-user/:userId')
  async getUserBookingOptions(userId: string) {
    return await toPromise(this.http.get<BookingOption[]>(`/api/booking-options/for-user/${userId}`));
  }

  // @Get('for-user/:userId/tags')
  async getUserBookingOptionTags(userId: string) {
    return await toPromise(this.http.get<Tag[]>(`/api/booking-options/for-user/${userId}/tags`));
  }

  // @Get()
  async paginate(
    query: RequestQueryBuilder,
    communityId?: string,
    pagination?: Partial<PaginatedQueryParams>
  ): Promise<PaginatedDto<BookingOption>> {
    return await toPromise(
      this.http.get<PaginatedDto<BookingOption>>('/api/booking-options', {
        params: {
          ...query.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get(':optionId')
  async getOne(optionId: string): Promise<BookingOption> {
    return await toPromise(this.http.get<BookingOption>(`/api/booking-options/${optionId}`));
  }

  async getEventsByBookingOption(bookingOptionId: string) {
    return await toPromise(this.http.get<CalendarEvent[]>(`/api/booking-options/${bookingOptionId}/events`));
  }

  async getBookingOptionsAvailableToEveryone(): Promise<BookingOption[]> {
    return await toPromise(this.http.get<BookingOption[]>(`/api/booking-options/available`));
  }

  // @Post()
  async create(dto: CreateBookingOptionDto): Promise<BookingOption> {
    return await toPromise(this.http.post<BookingOption>('/api/booking-options', dto));
  }

  // @Put(':optionId')
  async update(optionId: string, dto: UpdateBookingOptionDto): Promise<BookingOption> {
    return await toPromise(this.http.put<BookingOption>(`/api/booking-options/${optionId}`, dto));
  }

  // @Delete(':optionId')
  async removeBookingOption(optionId: string) {
    return await toPromise(this.http.delete<any>(`/api/booking-options/${optionId}`));
  }

  // @Get('user-stats')
  async getUserBookingOptionStats(userId: string, optionIds: string[]): Promise<UserBookingOptionStats[]> {
    return await toPromise(
      this.http.get<UserBookingOptionStats[]>('/api/booking-options/user-stats', {
        params: {
          userId,
          optionIds,
        },
      })
    );
  }
}
