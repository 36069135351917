<greco-simple-dialog [data]="dialogData">
  <p>Are you sure you want to void the ${{purchase.total / 100}} purchase of {{purchase.user.displayName}}?</p>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-form-field appearance="standard">
      <mat-label>Void Reason</mat-label>
      <mat-select formControlName="voidReason" [required]="true" [disabled]="processing">
        <mat-option *ngFor="let reason of voidReasons" [value]="reason">{{ reason }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox
      *ngIf="purchase.type === 'ProductPurchaseEntity' 
  || purchase.type === 'SubscriptionStartPurchaseEntity' 
  || purchase.type === 'SubscriptionUpdatePurchaseEntity'"
      style="margin-bottom: 16px"
      formControlName="returnToInventory"
      [disabled]="processing"
    >
      Return item(s) to inventory if applicable
    </mat-checkbox>

    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        [disabled]="formGroup.invalid || processing"
        style="flex: 1"
      >
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
