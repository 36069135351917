<greco-box class="box">
  <div class="image-section">
    <ng-content select="[header]"></ng-content>
    <img
      *ngIf="video.thumbnailUrl"
      [src]="video.thumbnailUrl | safe : 'resourceUrl'"
      alt="Thumbnail not found"
      class="thumb"
    />
    <div *ngIf="(progress || 0) / (video.duration || 1) > 0.9" class="watched">
      <mat-icon>done_all<mat-icon>done_all</mat-icon></mat-icon>
    </div>
    <img *ngIf="!video.thumbnailUrl" [src]="'/assets/lf3/logo.png'" class="thumb" />

    <div class="time-bar">
      <p style="align-self: center">
        {{ video.duration ? (video.duration | grecoDuration) : '' }}
      </p>
    </div>
  </div>
  <greco-video-watch-progress-bar
    class="video-progress"
    *ngIf="progress"
    [duration]="video.duration || 0"
    [progress]="progress"
    style="margin-top: -16px; overflow: hidden; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px"
  ></greco-video-watch-progress-bar>
  <div style="display: flex; flex-direction: row; width: 100%">
    <h4 style="margin-bottom: 0; line-height: 20px; width: 100%">
      <strong>{{ title ? title : video.title }} </strong>
    </h4>
    <ng-content select="[lock]"></ng-content>
  </div>
  <mat-chip-list>
    <greco-video-tag
      *ngFor="let tag of video.tags"
      [admin]="false"
      [icon]="'smart_display'"
      [tag]="tag"
    ></greco-video-tag>
  </mat-chip-list>
  <ng-content select="[body]"></ng-content>
  <ng-content select="[footer]"></ng-content>
</greco-box>
