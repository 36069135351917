import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DataExportsModule } from '@greco/ngx-data-exports';
import { FiltersModule } from '@greco/ngx-filters';
import { AccountsModule } from '@greco/ngx-finance-accounts';
import { InvoicesModule } from '@greco/ngx-finance-invoices';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { HighlightPipeModule } from '@greco/ngx-highlight-pipe';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { UsersModule } from '@greco/ngx-identity-users';
import { JoinPipeModule } from '@greco/ngx-join-pipe';
import { LetModule } from '@greco/ngx-let-util';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { StatsDisplayModule } from '@greco/ngx-sales-stats';
import { AlertModule } from '@greco/ui-alert';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { TabsModule } from '@greco/ui-tabs';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  FrozenSubscriptionAlertComponent,
  SubscriptionActionOptionsMenuComponent,
  SubscriptionComTableComponent,
  SubscriptionHistoryComponent,
  SubscriptionInfoSectionComponent,
  SubscriptionOptionsComponent,
  SubscriptionOptionsMenuComponent,
  SubscriptionPreviewComponent,
  SubscriptionScheduleSectionComponent,
  SubscriptionsTableComponent,
} from './components';
import {
  CancelSubscriptionDialog,
  FreezeSubscriptionDialog,
  SetSubscriptionReferredByDialog,
  SetSubscriptionSoldByDialog,
  SetSubscriptionTransferredFromDialog,
  SUBSCRIPTION_HANDLER_FORMS,
  UpdateMinimumCommitmentDialog,
  UpdateSubscribedByDialog,
  UpdateSubscriptionDialog,
  UpdateSubscriptionHandlerForm,
} from './dialogs';
import { BulkCancelSubscriptionDialog } from './dialogs/bulk-cancel-subscription-dialog/bulk-cancel-subscription-dialog';
import { BulkStartSubscriptionDialog } from './dialogs/bulk-start-subscription-dialog/bulk-start-subscription-dialog';
import { BulkUpdateSubscriptionDialog } from './dialogs/bulk-update-subscription-dialog/bulk-update-subscription.dialog';
import { ChangePaymentMethodDialog } from './dialogs/change-payment-method/change-payment-method.dialog';
import { ConfirmDialogModule } from './dialogs/confirm';
import { SubscriptionScheduleDialog } from './dialogs/subscription-schedule/subscription-schedule.dialog';
import { ExportSubscriptionsDirective } from './directives';
import { SubscriptionPage, SubscriptionsPage } from './pages';
import { AllowBankPaymentsPipe, NextRenewalPipe } from './pipes';
import { FilterCouponPipe } from './pipes/subscription-item-coupon.pipe';
import { SubscriptionActionsService, SubscriptionFreezeService, SubscriptionsService } from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    // // Internal
    // PurchaseServiceModule,
    // CustomChargeServiceModule,

    // Greco
    StatsDisplayModule,
    LetModule,
    BoxModule,
    TabsModule,
    TableModule,
    UsersModule,
    FiltersModule,
    InvoicesModule,
    SmartImgModule,
    AccountsModule,
    ContactsModule,
    PaymentsModule,
    DataExportsModule,
    SimpleDialogModule,
    ConfirmDialogModule,
    HighlightPipeModule,
    CollapsibleSectionModule,
    PurchasesModule,
    AlertModule,

    // Material
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatRadioModule,
    MatStepperModule,
    MatProgressSpinnerModule,

    // Other
    NgPipesModule,
    SafePipeModule,
    NgxCurrencyModule,
    JoinPipeModule,

    // // Pipes
    // VariantPriceLabel,
    // RecurrenceLabelPipe,
  ],
  declarations: [
    //Components
    SubscriptionComTableComponent,
    SubscriptionPreviewComponent,
    SubscriptionInfoSectionComponent,
    SubscriptionOptionsMenuComponent,
    SubscriptionScheduleSectionComponent,
    SubscriptionActionOptionsMenuComponent,
    SubscriptionsTableComponent,
    SubscriptionHistoryComponent,
    SubscriptionOptionsComponent,
    FrozenSubscriptionAlertComponent,

    //Dialogs
    CancelSubscriptionDialog,
    UpdateSubscriptionDialog,
    BulkStartSubscriptionDialog,
    BulkCancelSubscriptionDialog,
    BulkUpdateSubscriptionDialog,
    UpdateSubscribedByDialog,
    SetSubscriptionSoldByDialog,
    FreezeSubscriptionDialog,
    SetSubscriptionTransferredFromDialog,
    SetSubscriptionReferredByDialog,
    UpdateMinimumCommitmentDialog,

    // Pipes
    NextRenewalPipe,
    AllowBankPaymentsPipe,
    FilterCouponPipe,

    //Pages
    SubscriptionsPage,
    SubscriptionPage,

    //Directives
    ExportSubscriptionsDirective,
    SubscriptionScheduleDialog,
    ChangePaymentMethodDialog,
  ],
  exports: [
    SubscriptionsTableComponent,
    NextRenewalPipe,
    AllowBankPaymentsPipe,
    SubscriptionsPage,
    SubscriptionComTableComponent,
    SubscriptionOptionsComponent,
    SubscriptionPage,
    UpdateSubscriptionDialog,
    SubscriptionPreviewComponent,
    ExportSubscriptionsDirective,
    SubscriptionScheduleSectionComponent,
    SubscriptionInfoSectionComponent,
    FrozenSubscriptionAlertComponent,
  ],
  providers: [SubscriptionsService, SubscriptionActionsService, SubscriptionFreezeService],
})
export class SubscriptionsModule {
  static forRoot(handlerForms: UpdateSubscriptionHandlerForm[]): ModuleWithProviders<SubscriptionsModule> {
    return {
      ngModule: SubscriptionsModule,
      providers: [{ provide: SUBSCRIPTION_HANDLER_FORMS, useValue: handlerForms }],
    };
  }
}
