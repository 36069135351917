import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@greco/property-listener-util';
import { heightExpansion } from '@greco/ui-animations';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
  CalendarView,
} from 'angular-calendar';
import * as moment from 'moment';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ResponsiveDateFormatter } from '../../providers';

@Component({
  selector: 'greco-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [heightExpansion],
  providers: [{ provide: CalendarDateFormatter, useClass: ResponsiveDateFormatter }],
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private router: Router, private route: ActivatedRoute, private elemRef: ElementRef) {
    combineLatest([this._syncWithUrl$, this.dateRange])
      .pipe(
        takeUntil(this._onDestroy$),
        filter(([sync]) => sync)
      )
      .subscribe(() =>
        this.router.navigate([], {
          queryParamsHandling: 'merge',
          queryParams: { viewDate: this.viewDate.toISOString(), view: this.view },
        })
      );
  }

  /* end community */
  private _onDestroy$ = new Subject<void>();

  @Output() dateClicked = new EventEmitter<[Date, undefined] | [Date, string]>();

  private _lastDateRange?: [Date, Date];
  @Output() dateRange = new EventEmitter<[Date, Date]>(true);

  @Output() eventClicked = new EventEmitter<CalendarEvent>(true);

  @Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>(true);

  @Input() refreshCalendarEmitter = new Subject<void>();

  private _syncWithUrl$ = new BehaviorSubject<boolean>(false);
  @Input() get syncWithUrl() {
    return this._syncWithUrl$.value;
  }
  set syncWithUrl(syncWithUrl) {
    this._syncWithUrl$.next(syncWithUrl);
  }

  @Input() loading = false;

  private _events: CalendarEvent[] = [];
  @Input() get events() {
    return this._events;
  }
  set events(events) {
    this._events = events;

    const start = events.reduce((acc, evt) => (acc > evt.start.getHours() ? evt.start.getHours() : acc), 24);
    this.dayStartHour = Math.max(start > 5 ? 5 : start - 1, 0);

    const end = events.reduce((acc, evt) => (acc < (evt.end?.getHours() || 24) ? evt.end?.getHours() || 24 : acc), 0);
    this.dayEndHour = Math.min(end < 21 ? 21 : end + 1, 24);
  }

  private _view = CalendarView.Week;
  @Input() get view() {
    return this._view;
  }
  set view(view) {
    if (view) {
      this._view = view;
      this.emitDateRange();
    }
  }

  private _viewDate = new Date();
  @Input() get viewDate() {
    return this._viewDate;
  }
  set viewDate(viewDate) {
    this._viewDate = viewDate;
    this.emitDateRange();
  }

  showViewToggle = true;
  @Input() hideDayView = false;
  @Input() hideWeekView = false;
  @Input() hideMonthView = false;

  @Input() dayCellTemplate?: TemplateRef<any>;
  @Input() eventListTemplate?: TemplateRef<any>;
  @Input() eventCardTemplate?: TemplateRef<any>;

  dayStartHour = 0;
  dayEndHour = 24;

  activeDayIsOpen = true;

  @Input() dayViewGroupBy?: (event: CalendarEvent) => { label: string; metadata: string }[];
  @Input() sortGroups?: (a: string, b: string) => 1 | -1;

  private _showChildEventsToggle = false;
  @Input() set showChildEventsToggle(value: BooleanInput) {
    this._showChildEventsToggle = coerceBooleanProperty(value);
  }
  get showChildEventsToggle() {
    return this._showChildEventsToggle;
  }

  @Output() showChildEventsChanged = new EventEmitter<boolean>();
  showChildEvents = false;
  @PropertyListener('showChildEvents')
  _toggleChildEvents() {
    this.showChildEventsChanged.emit(this.showChildEvents);
  }

  @PropertyListener('hideDayView')
  @PropertyListener('hideWeekView')
  @PropertyListener('hideMonthView')
  private _updateShowViewToggle() {
    this.showViewToggle = [this.hideDayView, this.hideWeekView, this.hideMonthView].filter(a => !a).length > 1;
  }

  dayClicked(day: CalendarMonthViewDay) {
    const isSameDay = moment(this.viewDate).isSame(day.date, 'day');
    if (!isSameDay) {
      this.viewDate = day.date;
      this.activeDayIsOpen = !!day.events.length;
    } else if (day.events.length) {
      this.activeDayIsOpen = !this.activeDayIsOpen;
    }
  }

  test(...params: any[]) {
    console.log(...params);
  }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this._onDestroy$)).subscribe(params => {
      if (params.view && Object.values(CalendarView).includes(params.view)) {
        this.view = params.view;
      }

      if (params.viewDate && moment(params.viewDate).isValid()) {
        this.viewDate = moment(params.viewDate).toDate();
      }
    });
  }

  ngAfterViewInit() {
    this.scrollToCurrentView();
  }

  scrollToCurrentView() {
    if (this.view === CalendarView.Week || CalendarView.Day) {
      this.elemRef.nativeElement.querySelector('.cal-current-time-marker')?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  changeCustomDateView(date: MatDatepickerInputEvent<Date>) {
    if (date.value) {
      this.viewDate = date.value;
      this.emitDateRange();
    }
  }

  private emitDateRange() {
    const dateRange: [Date, Date] = [
      moment(this.viewDate).startOf(this.view).toDate(),
      moment(this.viewDate).endOf(this.view).toDate(),
    ];

    const format = (range?: [Date, Date]) => (range ? range.map(date => date.toISOString()).join() : '');
    if (format(dateRange) !== format(this._lastDateRange)) {
      this._lastDateRange = dateRange;
      this.dateRange.emit(dateRange);
    }
  }
}

// import {
//   AfterViewInit,
//   Component,
//   ElementRef,
//   EventEmitter,
//   Input,
//   OnDestroy,
//   OnInit,
//   Output,
//   TemplateRef,
// } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
// import { ActivatedRoute, Router } from '@angular/router';
// import { toPromise } from '@greco-fit/util';
// import { Community } from '@greco/identity-communities';
// import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
// import { PropertyListener } from '@greco/property-listener-util';
// import { heightExpansion } from '@greco/ui-animations';
// import {
//   CalendarDateFormatter,
//   CalendarEvent,
//   CalendarEventTimesChangedEvent,
//   CalendarMonthViewDay,
//   CalendarView,
// } from 'angular-calendar';
// // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
// import { CreateEventDialog } from 'libs/greco/booking/feature-events/src/lib/dialogs/create-event';
// import * as moment from 'moment';
// import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
// import { filter, takeUntil } from 'rxjs/operators';
// import { ResponsiveDateFormatter } from '../../providers';

// @Component({
//   selector: 'greco-calendar',
//   templateUrl: './calendar.component.html',
//   styleUrls: ['./calendar.component.scss'],
//   animations: [heightExpansion],
//   providers: [{ provide: CalendarDateFormatter, useClass: ResponsiveDateFormatter }],
// })
// export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
//   dates$: any;
//   resourceFilter: any;
//   tagFilter: any;
//   constructor(
//     private router: Router,
//     private route: ActivatedRoute,
//     private elemRef: ElementRef,
//     private dialog: MatDialog,
//     private comSecSvc: CommunitySecurityService
//   ) {
//     combineLatest([this._syncWithUrl$, this.dateRange])
//       .pipe(
//         takeUntil(this._onDestroy$),
//         filter(([sync]) => sync)
//       )
//       .subscribe(() =>
//         this.router.navigate([], {
//           queryParamsHandling: 'merge',
//           queryParams: { viewDate: this.viewDate.toISOString(), view: this.view },
//         })
//       );
//   }
//   /* community */
//   private _community$ = new BehaviorSubject<Community | null>(null);
//   @Input() get community() {
//     return this._community$.value;
//   }
//   set community(community) {
//     this._community$.next(community);
//     this.resourceFilter.communityId = community?.id || '';
//     this.tagFilter.communityId = community?.id || '';
//   }
//   @Output() eventTimesChanged = new EventEmitter<CalendarEventTimesChangedEvent>(true);

//   @Input() refreshCalendarEmitter = new Subject<void>();
//   /* canCreateEvents$ = this._community$.pipe(
//     switchMap(async community =>
//       community
//         ? await this.comSecSvc.hasAccess(community.id, EventSecurityResource.key, EventSecurityResourceAction.CREATE)
//         : false
//     )
//   );
//   /* end community */
//   private _onDestroy$ = new Subject<void>();

//   private _lastDateRange?: [Date, Date];
//   @Output() dateRange = new EventEmitter<[Date, Date]>(true);

//   @Output() eventClicked = new EventEmitter<CalendarEvent>(true);

//   private _syncWithUrl$ = new BehaviorSubject<boolean>(false);
//   @Input() get syncWithUrl() {
//     return this._syncWithUrl$.value;
//   }
//   set syncWithUrl(syncWithUrl) {
//     this._syncWithUrl$.next(syncWithUrl);
//   }

//   @Input() loading = false;

//   private _events: CalendarEvent[] = [];
//   @Input() get events() {
//     return this._events;
//   }
//   set events(events) {
//     this._events = events;

//     const start = events.reduce((acc, evt) => (acc > evt.start.getHours() ? evt.start.getHours() : acc), 24);
//     this.dayStartHour = Math.max(start > 5 ? 5 : start - 1, 0);

//     const end = events.reduce((acc, evt) => (acc < (evt.end?.getHours() || 24) ? evt.end?.getHours() || 24 : acc), 0);
//     this.dayEndHour = Math.min(end < 21 ? 21 : end + 1, 24);
//   }

//   private _view = CalendarView.Week;
//   @Input() get view() {
//     return this._view;
//   }
//   set view(view) {
//     this._view = view;
//     this.emitDateRange();
//   }

//   private _viewDate = new Date();
//   @Input() get viewDate() {
//     return this._viewDate;
//   }
//   set viewDate(viewDate) {
//     this._viewDate = viewDate;
//     this.emitDateRange();
//   }

//   showViewToggle = true;
//   @Input() hideDayView = false;
//   @Input() hideWeekView = false;
//   @Input() hideMonthView = false;

//   @Input() dayCellTemplate?: TemplateRef<any>;
//   @Input() eventListTemplate?: TemplateRef<any>;
//   @Input() eventCardTemplate?: TemplateRef<any>;

//   dayStartHour = 0;
//   dayEndHour = 24;

//   activeDayIsOpen = true;

//   @Input() dayViewGroupBy?: (event: CalendarEvent) => { label: string; metadata: string }[];
//   @Input() sortGroups?: (a: string, b: string) => 1 | -1;

//   @PropertyListener('hideDayView')
//   @PropertyListener('hideWeekView')
//   @PropertyListener('hideMonthView')
//   private _updateShowViewToggle() {
//     this.showViewToggle = [this.hideDayView, this.hideWeekView, this.hideMonthView].filter(a => !a).length > 1;
//   }

//   async createEvent() {
//     console.log('testttttt', event);
//     //if (!this.community?.id) return;

//     await toPromise(
//       this.dialog
//         .open(CreateEventDialog, {
//           data: { communityId: 'com_greco' },
//           width: '750px',
//           maxWidth: '90%',
//         })
//         .afterClosed()
//     );
//     this.refresh();
//   }

//   private refresh() {
//     this.dates$.next(this.dates$.value);
//   }

//   dayClicked(day: CalendarMonthViewDay) {
//     console.log('dayyyyy');
//     const isSameDay = moment(this.viewDate).isSame(day.date, 'day');
//     if (!isSameDay) {
//       this.viewDate = day.date;
//       this.activeDayIsOpen = !!day.events.length;
//     } else if (day.events.length) {
//       this.activeDayIsOpen = !this.activeDayIsOpen;
//     }
//   }

//   ngOnInit() {
//     this.route.queryParams.pipe(takeUntil(this._onDestroy$)).subscribe(params => {
//       if (params.view && Object.values(CalendarView).includes(params.view)) {
//         this.view = params.view;
//       }

//       if (params.viewDate && moment(params.viewDate).isValid()) {
//         this.viewDate = moment(params.viewDate).toDate();
//       }
//     });
//   }

//   ngAfterViewInit() {
//     this.scrollToCurrentView();
//   }

//   scrollToCurrentView() {
//     if (this.view === CalendarView.Week || CalendarView.Day) {
//       this.elemRef.nativeElement.querySelector('.cal-current-time-marker')?.scrollIntoView({ behavior: 'smooth' });
//     }
//   }

//   ngOnDestroy() {
//     this._onDestroy$.next();
//     this._onDestroy$.complete();
//   }

//   private emitDateRange() {
//     const dateRange: [Date, Date] = [
//       moment(this.viewDate).startOf(this.view).toDate(),
//       moment(this.viewDate).endOf(this.view).toDate(),
//     ];

//     const format = (range?: [Date, Date]) => (range ? range.map(date => date.toISOString()).join() : '');
//     if (format(dateRange) !== format(this._lastDateRange)) {
//       this._lastDateRange = dateRange;
//       this.dateRange.emit(dateRange);
//     }
//   }
// }
