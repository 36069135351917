import { Component, Input } from '@angular/core';
import { ArrayUtils } from '@greco-fit/util';
import { ContactService } from '@greco/ngx-identity-contacts';
import { PropertyListener } from '@greco/property-listener-util';
import { Perk, UserPerk } from '@greco/sales-perks';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface UserPerkDetails {
  perk: Perk;
  details: {
    expiry: string | null;
    reusable: boolean;
    quantity: number;
  };
}

@Component({
  selector: 'greco-user-perks-grid',
  templateUrl: './user-perks-grid.component.html',
  styleUrls: ['./user-perks-grid.component.scss'],
})
export class UserPerksGridComponent {
  constructor(private contactSvc: ContactService) {}

  @PropertyListener('userId') private userId$ = new BehaviorSubject<string>('');
  @Input() userId?: string | null;

  @PropertyListener('perks') private perks$ = new BehaviorSubject<UserPerk[]>([]);
  @Input() perks?: UserPerk[] | null;

  readonly userPerks$: Observable<UserPerkDetails[]> = combineLatest([this.userId$, this.perks$]).pipe(
    switchMap(async ([userId, perks]) => {
      if (!userId) return [];

      const contacts = await this.contactSvc.getUserContacts(userId);

      return perks.filter(perk => {
        return perk.user || contacts.some(contact => contact.community.id === perk.perk.community?.id);
      });
    }),
    map(userPerks => {
      if (!userPerks.length) return [];

      const groupedByPerk = ArrayUtils.groupBy(
        userPerks,
        userPerk => userPerk.perk.id + userPerk.consumable + userPerk.expiryDate?.toDateString() + userPerk.transferable
      );

      return Object.values(groupedByPerk)
        .map(userPerks => {
          const perk = userPerks[0].perk;
          const grouped = ArrayUtils.groupBy(
            userPerks,
            userPerk => `${userPerk.consumable}${userPerk.expiryDate ? moment(userPerk.expiryDate).fromNow() : 'null'}`
          );

          const perkDetails = Object.values(grouped).map(group => ({
            expiry: group[0]?.expiryDate ? moment(group[0].expiryDate).format('MMM D, yyyy') : null,
            reusable: !group[0].consumable,
            quantity: group.length,
          }));

          return { perk, details: perkDetails[0] };
        })
        .sort((a, b) => {
          if (!a.perk.community || !b.perk.community) return a.perk.community ? -1 : 1;
          return (
            a.perk.community.name.localeCompare(b.perk.community.name || '') || a.perk.title.localeCompare(b.perk.title)
          );
        });
    })
  );

  readonly communitiesAndUserPerks$ = this.userPerks$.pipe(
    map(userPerks => {
      const groupedPerks = ArrayUtils.groupBy(userPerks, userPerk => userPerk.perk.community?.id || '');
      return Object.keys(groupedPerks).map(communityId => ({
        community: groupedPerks[communityId][0].perk.community,
        perks: groupedPerks[communityId],
      }));
    })
  );

  readonly communitiesAndGroupedUserPerks$ = this.communitiesAndUserPerks$.pipe(
    map(communitiesAndUserPerks => {
      return communitiesAndUserPerks.map(community => {
        const moduleGroupedUserPerks = ArrayUtils.groupBy(community.perks, userPerk => userPerk.perk.module);
        const moduleGroups = Object.keys(moduleGroupedUserPerks)
          .map(key => ({
            module: key.replace(/_/g, ' '),
            perks: moduleGroupedUserPerks[key],
          }))
          .sort((a, b) => (a.module < b.module ? -1 : 1));

        return {
          community: community.community,
          groups: moduleGroups,
        };
      });
    })
  );
}
