import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

export enum AlertType {
  WARN = 'WARN',
  DANGER = 'DANGER',
  INFO = 'INFO',
  PRIMARY = 'PRIMARY',
  SUCCESS = 'SUCCESS',
}

export interface AlertConfig {
  title: string;
  type: AlertType;
  icon?: string;
  description?: string;
}

@Component({
  selector: 'greco-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver) {}

  @Input() config!: AlertConfig;

  color = '';
  expanded = false;

  isMobile$ = this.breakpointObserver.observe('(max-width: 399px)').pipe(map(result => result.matches));

  toggleExpanded() {
    if (this.config?.description) this.expanded = !this.expanded;
  }

  ngOnInit() {
    switch (this.config.type) {
      case AlertType.WARN:
        this.color = 'warn';
        break;
      case AlertType.DANGER:
        this.color = 'danger';
        break;
      case AlertType.SUCCESS:
        this.color = 'success';
        break;
      default:
        this.color = 'info';
        break;
    }

    if (!this.config.icon) {
      switch (this.config.type) {
        case AlertType.WARN:
          this.config.icon = 'warning';
          break;
        case AlertType.DANGER:
          this.config.icon = 'error';
          break;
        case AlertType.SUCCESS:
          this.config.icon = 'check_circle';
          break;
        default:
          this.config.icon = 'info';
          break;
      }
    }
  }
}
