import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BookingOptionDetails, Event } from '@greco/booking-events2';
import { PropertyListener } from '@greco/property-listener-util';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'alt-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.scss'],
})
export class CancellationPolicyComponent {
  constructor(public bottomSheet: MatBottomSheet) {}

  @PropertyListener('event') private event$ = new BehaviorSubject<Event | null>(null);
  @Input() event?: Event;

  @PropertyListener('bookingOption') private bookingOption$ = new BehaviorSubject<BookingOptionDetails | null>(null);
  @Input() bookingOption?: BookingOptionDetails;

  @Input() interactive = false;

  @Output() bottomSheetOpened = new EventEmitter<TemplateRef<any>>();

  eventDetails$ = this.event$.pipe(
    map(event => {
      if (!event) return '';
      return `${event.title} - ${moment(event.startTime).format('MMM Do, hh:mm A')}`;
    })
  );

  subtitle$ = this.bookingOption$.pipe(
    map(option => {
      if (!option) return;

      if (!option.cancellation) return 'Anytime, no penalty';

      let label = '<small>';

      if (option.cancellationWindow) {
        label += '<strong><u>' + this.formatMinutes(option.cancellationWindow) + ' Before Event </u></strong>';
      }

      if (option.cancellationPrice) {
        if (label) label += ' or ';
        label += '$' + (option.cancellationPrice / 100).toFixed(2) + ' Fee';
      }

      if (!option.refund) {
        label += label ? ' (No Refund)' : 'No Refund';
      }

      return label + '</small>';
    })
  );

  cancellationPolicyDetails$ = this.bookingOption$.pipe(
    map(option => {
      if (!option) return;
      let html = '';

      if (option.cancellationWindow) {
        const formatted = this.formatMinutes(option.cancellationWindow).toLowerCase();

        html += `<p>Please note that due to the limited availability of spots in each event we require a minimum of ${formatted} notice prior to the event start time to cancel any booking. If you do not attend the event or fail to cancel within ${formatted}, you will forfeit your perks and/or purchase${
          option.cancellationPrice
            ? ` and you will be charged a $${(option.cancellationPrice / 100).toFixed(2)}+tx cancellation fee`
            : ''
        }.</p>`;
      }

      html += `<p>If for some reason you are unable to attend, please cancel your booking so that another member can take your place.</p>`;

      return html;
    })
  );

  formatMinutes(minutes: number) {
    const hours = minutes / 60;
    const days = hours / 24;

    if (days % 1 === 0) return days + ' Day' + (!days || days > 1 ? 's' : '');

    if (hours % 1 === 0) return hours + ' Hour' + (!hours || hours > 1 ? 's' : '');

    return minutes + ' Minute' + (!minutes || minutes > 1 ? 's' : '');
  }

  emitBottomSheetOpened(bottomSheetRef: TemplateRef<any>) {
    this.bottomSheetOpened.emit(bottomSheetRef);
  }
}
