<greco-collapsible-section
  *grecoLet="canUpdateResource$ | async as canUpdateResource"
  [header]="{ title: 'Zoom Resource Details', icon: 'info' }"
  [disabled]="true"
>
  <greco-form-save-buttons
    *ngIf="canUpdateResource"
    header
    [form]="formGroup"
    [saveAction]="onSave"
    [resetValue]="initialValue"
  ></greco-form-save-buttons>

  <p *ngIf="resource.disabled">This resource is disabled. It will not be selectable</p>

  <form [formGroup]="formGroup">
    <mat-form-field appearance="standard">
      <greco-smart-img matPrefix class="avatar" [round]="true" [src]="'assets/zoom-icon-logo.png'"></greco-smart-img>
      <mat-label>Display Name</mat-label>
      <input [readonly]="resource.disabled" matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" [readonly]="resource.disabled"></textarea>
    </mat-form-field>
  </form>

  <!-- Enable/Disable Resource -->
  <button
    *ngIf="canUpdateResource"
    mat-button
    [disabled]="(canUpdateResource$ | async) === false"
    [ngClass]='resource.disabled ? "enabled" : "disabled"'
    [matTooltip]="'Click this to Enable/Disable the resource'"
    (click)="setDisable()"
  >
    {{ resource.disabled ? 'Re-Enable' : 'Disable' }}
  </button>
</greco-collapsible-section>
