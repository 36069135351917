import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ArrayUtils, toPromise } from '@greco-fit/util';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Perk, PerkResource, PerkResourceAction } from '@greco/sales-perks';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { RemovePerkDialog } from '../../dialogs';
import { PerkService } from '../../services';

interface GroupedUserPerks {
  perk: Perk;
  groups: {
    expiry: string;
    reusable: string;
    quantity: number;
    frozen: boolean;
  }[];
}

@Component({
  selector: 'greco-user-perks-table',
  templateUrl: './user-perks-table.component.html',
  styleUrls: ['./user-perks-table.component.scss'],
})
export class UserPerksTableComponent {
  constructor(
    public userSvc: UserService,
    private matDialog: MatDialog,
    private perksService: PerkService,
    private contactSvc: ContactService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId?: string;

  @Input() canRevokePerks = true;

  @PropertyListener('userId') private _userId$ = new ReplaySubject<string>(1);
  @Input() userId?: string;

  private _userPerks$ = this._userId$.pipe(
    switchMap(async userId => {
      const [userPerks, contacts] = await Promise.all([
        (async () => (userId ? await this.perksService.getUserPerks(userId, true) : []))(),
        (async () => (userId ? await this.contactSvc.getUserContacts(userId) : []))(),
      ]);

      return userPerks.filter(up => {
        return up.user || contacts.some(cnt => cnt.community.id === up.perk.community?.id);
      });
    }),
    shareReplay(1)
  );

  perks$: Observable<GroupedUserPerks[]> = this._userPerks$.pipe(
    map(userPerks => {
      const groupedByPerk = ArrayUtils.groupBy(
        userPerks,
        userPerk => userPerk.perk.id + ((userPerk as any)?.grantedByUser?.id || '')
      );

      return Object.values(groupedByPerk)
        .map(userPerks => {
          const perk = userPerks[0].perk;
          const grouped = ArrayUtils.groupBy(
            userPerks,
            userPerk =>
              `${userPerk.consumable}${userPerk.expiryDate ? moment(userPerk.expiryDate).format('MMM D, yyyy') : '-'}${
                userPerk.expiryNotificationDate ? moment(userPerk.expiryNotificationDate).format('MMM D, yyyy') : '-'
              }`
          );

          return {
            perk,
            groups: Object.values(grouped).map(group => {
              return {
                expiry: group[0]?.expiryDate ? moment(group[0].expiryDate).format('MMM D, yyyy') : 'No Expiry',
                expiryNotification: group[0]?.expiryNotificationDate
                  ? moment(group[0].expiryNotificationDate).format('MMM D, yyyy')
                  : '-',
                reusable: !group[0].consumable ? 'Yes' : 'No',
                quantity: group.length,
                frozen: group[0].frozen,
                grantedByUser: (group as any)[0]?.grantedByUser,
              };
            }),
          };
        })
        .sort((a, b) => a.perk.community?.name.localeCompare(b.perk.community?.name || '') || 1);
    }),
    map(groupedUserPerks =>
      groupedUserPerks.reduce(
        (acc, group) => [...acc, ...group.groups.map(g => ({ ...g, perk: group.perk }))],
        [] as any[]
      )
    )
  );

  canRevokePerks$ = combineLatest([this._communityId$, this._userId$]).pipe(
    switchMap(async ([communityId, userId]) => {
      return await this.securitySvc.hasAccess(PerkResource.key, PerkResourceAction.REVOKE, {
        userId: userId,
        communityId: communityId,
      });
    })
  );

  refresh() {
    this._userId$.next(this.userId);
  }

  async _removePerks(perk: Perk, expiry?: string, expiryNotification?: string, reusable?: string) {
    const dialog = this.matDialog.open(RemovePerkDialog, {
      data: {
        reusable: reusable === 'Yes',
        userId: this.userId,
        expiry,
        expiryNotification,
        perk,
      },
    });

    const result = await toPromise(dialog.afterClosed());
    if (result) this.refresh();
  }
}
