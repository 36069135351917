<ng-container *ngIf="showBackBtn">
  <a *ngIf="backBtnRoute?.length; else locationBtn" mat-icon-button [routerLink]="backBtnRoute">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <ng-template #locationBtn>
    <button mat-icon-button (click)="back()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </ng-template>
</ng-container>
<greco-super-title [text]="text"></greco-super-title>
