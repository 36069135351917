import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { EventsModule } from '@greco/ngx-booking-events';
import { AuthModule } from '@greco/ngx-identity-auth';
import { CommunitiesModule, CommunityServiceModule } from '@greco/ngx-identity-communities';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { LetModule } from '@greco/ngx-let-util';
import { StripePaymentGatewayModule } from '@greco/ngx-stripe-payment-gateway';
import { UserAlertsModule } from '@greco/ngx-user-alerts';
import { VideosModule } from '@greco/ngx-videos';
import { AlertModule } from '@greco/ui-alert';
import { BoxModule } from '@greco/ui-box';
import { TabsModule } from '@greco/ui-tabs';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { WelcomeModule } from '@greco/ui-welcome';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SwiperModule } from 'swiper/angular';
import {
  DashboardBookingCardComponent,
  DashboardBookingsComponent,
  DashboardFavoritesComponent,
  DashboardHeaderComponent,
  DashboardScheduleComponent,
  DashboardTilesComponent,
  DashboardUnsignedAgreementsComponent,
  DashboardVideosComponent,
  EventListComponent,
  PaymentMethodPickerComponent,
  PromoBoxComponent,
} from './components';
import { ReferralGuard } from './guards/referral.guard';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { NewDashboardPage } from './pages/new-dashboard/new-dashboard.page';
import { WelcomeFormResolver } from './resolvers/welcome-form.resolver';
import { DashboardBookingsService } from './services/dashboard-bookings.service';
import { DashboardTilesService } from './services/dashboard-tiles.service';
import { DashboardVideosService } from './services/dashboard-videos.service';
import { ReferralService } from './services/referral.service';

export * from './components/dashboard-schedule/dashboard-schedule.component';
export * from './pages/dashboard/dashboard.page';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDividerModule,
    MatChipsModule,
    MomentModule,
    BoxModule,
    MatIconModule,
    MatButtonModule,
    CommunitiesModule,
    WelcomeModule,
    AuthModule,
    CommunityServiceModule,
    UserAvatarModule,
    MatRippleModule,
    MatBottomSheetModule,
    MatProgressSpinnerModule,
    UserAlertsModule,
    AlertModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatRadioModule,
    ContactsModule,
    EventsModule,
    VideosModule,
    TabsModule,
    EventsModule,
    SwiperModule,
    NgPipesModule,
    MatSelectModule,
    MatDatepickerModule,
    OverlayModule,
    LetModule,
    MatBottomSheetModule,
    StripePaymentGatewayModule,
  ],
  exports: [DashboardPage, DashboardScheduleComponent],
  providers: [
    ReferralService,
    WelcomeFormResolver,
    ReferralGuard,
    DashboardBookingsService,
    DashboardVideosService,
    DashboardTilesService,
    PaymentMethodPickerComponent,
  ],
  declarations: [
    // Pages
    DashboardPage,
    NewDashboardPage,

    // Components
    DashboardHeaderComponent,
    DashboardBookingsComponent,
    DashboardScheduleComponent,
    DashboardBookingCardComponent,
    DashboardFavoritesComponent,
    DashboardTilesComponent,
    DashboardVideosComponent,
    EventListComponent,
    PaymentMethodPickerComponent,
    PromoBoxComponent,
    DashboardUnsignedAgreementsComponent,
  ],
})
export class DashboardModule {}
