<ng-container *grecoLet="addons$ | async as addons">
  <greco-collapsible-section
    id="product-addons-section"
    [disabled]="!addons?.length"
    [header]="{ title: 'Extensions', icon: 'extension' }"
  >
    <greco-table
      *ngIf="addons?.length"
      [highlight]="true"
      [data]="addons || []"
      (rowClick)="updateAddonConfiguration($event)"
    >
      <mat-icon *grecoTableCol="''; let addon; fitContent: true" [matTooltip]="addon.id">extension</mat-icon>

      <p *grecoTableCol="'Name'; let addon" [matTooltip]="addon.type">{{ addon.type | titlecase }}</p>

      <ng-container *grecoTableCol="'Details'; let addon">
        <p *ngIf="!addon.id">-</p>
        <ng-container *ngIf="addon.id" [ngSwitch]="addon.type">
          <mat-chip-list *ngSwitchCase="'SHOP'" style="line-height: 16px">
            <mat-chip *ngFor="let collection of addon.collections">
              <mat-icon
                *ngIf="collection.icon"
                style="width: 18px; height: 18px; line-height: 18px; font-size: 18px; margin-right: 8px"
              >
                {{ collection.icon }}
              </mat-icon>
              <span>{{ collection.label }}</span>
            </mat-chip>
          </mat-chip-list>

          <p *ngSwitchCase="'AGREEMENT'">{{ addon.title }}</p>
          <mat-chip-list *ngSwitchCase="'AGREEMENT'" style="line-height: 16px">
            <mat-chip
              *ngFor="let productAgreement of agreementTags$ | async"
              [matTooltip]="productAgreement.agreement!.text | striphtml"
            >
              <span [ngSwitch]="productAgreement.agreement?.agreementType">
                <mat-icon *ngSwitchCase="'CHECKBOX'">check_box_outline_blank</mat-icon>
                <mat-icon *ngSwitchCase="'AUTO_CHECKBOX'">check_box</mat-icon>
                <mat-icon *ngSwitchCase="'DIGITAL_SIGNATURE'">edit</mat-icon>
              </span>
              <span>{{ productAgreement.agreement?.title }}</span>
            </mat-chip>
          </mat-chip-list>

          <mat-chip-list *ngSwitchCase="'USER AVAILABILITY'" style="line-height: 16px">
            <mat-chip *ngFor="let condition of addon.conditions">
              <span>{{ condition | productConditionDetails : 'short' }}</span>
            </mat-chip>
          </mat-chip-list>

          <mat-chip-list *ngSwitchCase="'SELF-SERVE UPDATE'" style="line-height: 16px">
            <mat-chip *ngFor="let product of addon.upgradeCandidates">
              <span>{{ product.title }}</span>
            </mat-chip>
          </mat-chip-list>

          <mat-chip-list *ngSwitchCase="'INVENTORY'" style="line-height: 16px">
            <ng-container *ngFor="let inventory of addon.inventories; let index = index">
              <mat-chip *ngIf="index < 2 || addon.inventories.length < 4">
                <span>{{ '(' + inventory.availableInventoryCount + ') ' + (inventory.variant | variantTitle) }}</span>
              </mat-chip>
              <mat-chip *ngIf="index === 2 && addon.inventories.length > 3">
                <span>+{{ addon.inventories.length - 2 }} more</span>
              </mat-chip>
            </ng-container>
            <p *ngIf="!addon?.inventories?.length">-</p>
          </mat-chip-list>
        </ng-container>
      </ng-container>

      <p
        *grecoTableCol="'Last Modified'; let addon"
        [matTooltip]="addon.id ? (addon.modified | date) + ' ' + (addon.modified | date : 'shortTime') : ''"
      >
        {{ addon.id ? (addon.modified | date) : '-' }}
      </p>

      <mat-icon *grecoTableCol="'Enabled'; let addon; fitContent: true; alignCenter: true">
        {{ addon.enabled ? 'check_circle' : '' }}
      </mat-icon>

      <ng-container *grecoTableCol="''; let addon; fitContent: true; stickyEnd: true">
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <button *ngIf="addon.id" mat-menu-item (click)="updateAddonStatus(addon)">
            <mat-icon>{{ addon.enabled ? 'hide_source' : 'check_circle' }}</mat-icon>
            <span>{{ addon.enabled ? 'Disable' : 'Enable' }}</span>
          </button>

          <button mat-menu-item (click)="updateAddonConfiguration(addon)">
            <mat-icon>create</mat-icon>
            <span>Edit Configuration</span>
          </button>
        </mat-menu>
      </ng-container>
    </greco-table>
  </greco-collapsible-section>
</ng-container>
