import { Injectable } from '@angular/core';
import { Contact } from '@greco/identity-contacts';
import { MultiAutocompleteFilter } from '@greco/ngx-filters';
import { ContactService } from '@greco/ngx-identity-contacts';
import { CondOperator, RequestQueryBuilder, SFields } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'any' })
export class PurchaseUserFilter extends MultiAutocompleteFilter {
  constructor(private contactSvc: ContactService) {
    super('PurchaseUserFilter', {
      description: '',
      label: 'Customer',
      properties: ['user.id'],
      shortLabel: 'Customer',
      canOpen: true,
    });
  }

  public communityId?: string;

  getImageUrl(value: Contact) {
    return value?.user?.photoURL ?? 'assets/lf3/icon.png';
  }

  getAutocompleteHtml(value: Contact): string {
    return value.user ? `${value.user.displayName} <small>(${value.email})</small>` : value.email;
  }

  getValueLabel(value: Contact | Contact[]): string {
    if ((value as Contact[])?.length) {
      return (value as Contact[])?.map(contact => contact.displayName).join(', ') || '';
    } else return (value as Contact).displayName;
  }

  async getValueOptions(search?: string): Promise<Contact[]> {
    if (!this.communityId) return [];

    const contacts = await this.contactSvc.paginateContacts(
      new RequestQueryBuilder().search({
        $and: [
          { 'user.id': { $notnull: true } },
          { $or: [{ email: { $contL: search } }, { displayName: { $contL: search } }] },
        ],
      }),
      this.communityId,
      { limit: 5 }
    );

    return contacts.items;
  }

  serializeValue(value: Contact[]): string {
    return value.map(contact => contact.id).join(',');
  }

  async deserializeValue(serializedValue: string) {
    const ids = serializedValue.split(',');
    const contacts: Contact[] = [];

    await Promise.all(
      ids.map(async id => {
        const contact = await this.contactSvc.getContact(id);
        if (contact) contacts.push(contact);
      })
    );

    return contacts?.length ? contacts : null;
  }

  getPropertySField(property: string, operator: CondOperator, value: Contact[]): SFields {
    return value?.length ? { [property]: { [operator]: value.map(contact => contact.user?.id) } } : {};
  }
}
