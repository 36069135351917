import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { PAYMENT_HANDLERS, PaymentHandler, PurchaseService } from './purchases.service';

@NgModule({
  imports: [CommonModule],
  providers: [PurchaseService],
})
export class PurchaseServiceModule {
  static forRoot(paymentHandlers?: Type<PaymentHandler>[]): ModuleWithProviders<PurchaseServiceModule> {
    return {
      ngModule: PurchaseServiceModule,
      providers: [{ provide: PAYMENT_HANDLERS, useValue: paymentHandlers ?? [] }, PurchaseService],
    };
  }
}
