import { SecurityResource } from '@greco/security';
import { PurchaseStatus } from '../models';

export enum PurchaseResourceAction {
  READ = 'READ',
  SEE_STATS = 'SEE_STATS',
  REFUND_TO_BALANCE = 'REFUND_TO_BALANCE',
  UNDO_REFUND_TO_BALANCE = 'UNDO_REFUND_TO_BALANCE',
  REFUND_TO_PAYMENT_METHOD = 'REFUND_TO_PAYMENT_METHOD',
  RETRY = 'RETRY',
  VOID = 'VOID',
  PURCHASE_AS_USER = 'PURCHASE_AS_USER',
  ZERO_DOLLAR_PURCHASE = 'ZERO_DOLLAR_PURCHASE',
  IGNORE_PAYMENT_FREEZE = 'IGNORE_PAYMENT_FREEZE',
  MANAGE_SOLD_BY = 'MANAGE_SOLD_BY',
  MANAGE_REFERRED_BY = 'MANAGE_REFERRED_BY',
  BULK_VOID = 'BULK_VOID',
  BULK_RETRY = 'BULK_RETRY',
  PURCHASE_OUT_OF_STOCK = 'PURCHASE_OUT_OF_STOCK',
}

export const PurchaseResource: SecurityResource = {
  key: 'finance_purchasing_purchase',
  module: 'Finance',
  title: 'Purchase',
  description: 'Manage purchases',

  context: class {
    id?: string;
    status?: PurchaseStatus;

    userId?: string;
    accountId?: string;
    createdById?: string;
  },

  actions: [
    { key: PurchaseResourceAction.READ, title: 'Read', description: 'Ability to read/view purchases' },
    {
      key: PurchaseResourceAction.SEE_STATS,
      title: 'See Stats',
      description: 'Ability to see stats at the top of the purchases page',
    },
    {
      key: PurchaseResourceAction.REFUND_TO_BALANCE,
      title: 'Refund to balance',
      description: 'Ability to refund a purchase to balance',
    },
    {
      key: PurchaseResourceAction.UNDO_REFUND_TO_BALANCE,
      title: 'Undo Refund to balance',
      description: 'Ability to undo a refund to balance',
    },
    {
      key: PurchaseResourceAction.REFUND_TO_PAYMENT_METHOD,
      title: 'Refund to payment method',
      description: 'Ability to refund a purchase payment method',
    },
    { key: PurchaseResourceAction.RETRY, title: 'Retry', description: 'Ability to retry a failed purchase' },
    { key: PurchaseResourceAction.VOID, title: 'Void', description: 'Ability to void a purchase' },
    {
      key: PurchaseResourceAction.PURCHASE_AS_USER,
      title: 'Purchase as User',
      description: 'Ability to purchase as another user',
    },
    {
      key: PurchaseResourceAction.ZERO_DOLLAR_PURCHASE,
      title: 'Skip Payment Method ($0 Purchases)',
      description: 'Ability to create $0 purchases without a payment method',
    },
    {
      key: PurchaseResourceAction.IGNORE_PAYMENT_FREEZE,
      title: 'Ignore payment freeze',
      description: 'Ability to confirm a purchase and ignore the payment freeze',
    },
    {
      key: PurchaseResourceAction.MANAGE_SOLD_BY,
      title: 'Manage Sold By',
      description: "Ability to manage (set/update) the 'Sold By' property of purchases",
    },
    {
      key: PurchaseResourceAction.MANAGE_REFERRED_BY,
      title: 'Manage Referred By',
      description: "Ability to manage (set/update) the 'Referred By' property of purchases",
    },
    { key: PurchaseResourceAction.BULK_VOID, title: 'Bulk Void', description: 'Ability to bulk void purchases' },
    {
      key: PurchaseResourceAction.BULK_RETRY,
      title: 'Bulk Retry',
      description: 'Ability to bulk retry failed purchases',
    },
    {
      key: PurchaseResourceAction.PURCHASE_OUT_OF_STOCK,
      title: 'Purchase Out of Stock',
      description: 'Ability to purchase out of stock products',
    },
  ],
};
