<mat-form-field
  *grecoLet="options$ | async as saleCategories"
  appearance="standard"
  class="picker"
  style="width: 200px"
>
  <div *grecoLet="selected$ | async as selectedSaleCategories" style="display: flex; flex-direction: row-reverse">
    <mat-select
      *grecoLet="selectedSaleCategoryIds$ | async as selectedSaleCategoryIds"
      #categorySelect
      multiple
      [value]="selectedSaleCategoryIds"
      placeholder="Select Sale Category"
      (valueChange)="onChanged($event, saleCategories || [])"
    >
      <mat-select-trigger>
        <span *ngFor="let saleCategory of selectedSaleCategories; let last = last">
          {{ saleCategory.label + (last ? '' : ', ') }}
        </span>
      </mat-select-trigger>
      <div style="display: flex; gap: 24px; margin: 8px 16px; color: rgba(14, 15, 15, 0.87)">
        <mat-checkbox
          [(ngModel)]="allSelected"
          [ngModelOptions]="{ standalone: true }"
          (change)="toggleAllSelection(categorySelect)"
          >{{ allSelected ? 'Deselect All' : 'Select All' }}</mat-checkbox
        >
        <mat-checkbox *ngIf="showUncategorizedOption" [checked]="showUncategorized" (change)="toggleUncategorized()"
          >Include Uncategorized</mat-checkbox
        >
      </div>
      <mat-divider></mat-divider>
      <mat-option *ngFor="let saleCategory of saleCategories" [value]="saleCategory.id">
        {{ ' ' + saleCategory.label }}
      </mat-option>
    </mat-select>
  </div>
</mat-form-field>
