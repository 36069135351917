import { Component, Input } from '@angular/core';

@Component({
  selector: 'greco-promo-box',
  templateUrl: './promo-box.component.html',
  styleUrls: ['./promo-box.component.scss'],
})
export class PromoBoxComponent {
  @Input() promoTitle?: string;
  @Input() subtitle?: string;
  @Input() icon?: string;
}
