<greco-simple-dialog [data]="dialogData">
  <p>
    Please specify the amount to refund for this purchase. You may refund to the payment method of the customer or
    directly to their balance, but no more than the amount left to refund.
  </p>
  <p>
    Amount {{ maxRefundPositive ? 'Refundable' : 'to Reclaim' }}:
    <strong>{{ (maxRefund) * (maxRefundPositive ? 1 : -1) | currency }}</strong>
    <br />
    <span *ngIf="!maxRefundPositive" style="font-size: 12px; line-height: 0">
      Because this purchase was negative, the amount was automatically added to balance. Use this refund dialog to undo
      this transaction up to the amount added to balance.
    </span>
  </p>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-form-field *ngIf="(canRefundToBalance$ | async)" appearance="standard">
      <mat-label>Balance Refund Amount</mat-label>
      <input
        matInput
        currencyMask
        type="text"
        formControlName="balanceRefundAmount"
        [readonly]="processing"
        [options]="currencyMaskConfig"
      />
      <mat-error
        *ngIf="formGroup.get('balanceRefundAmount')?.errors && formGroup.get('balanceRefundAmount')?.hasError('invalid')"
      >
        Amount exceeded the maximum refund <span *ngIf="!maxRefundPositive">reclaim</span> limit of
        <strong>
          {{ (maxRefund - (formGroup.get('paymentRefundAmount')?.value || 0)) * (maxRefundPositive ? 1 : -1) | currency
          }}
        </strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="canRefundToPayment$ | async" appearance="standard">
      <mat-label>
        Payment {{ maxRefundPositive ? 'Refund' : 'Reclaim' }} Amount ( Max Amount :
        <strong> {{ getMinValue([ maxPaymentRefund, maxRefund])| currency }} </strong>)
      </mat-label>

      <input
        matInput
        type="text"
        formControlName="paymentRefundAmount"
        currencyMask
        [options]="currencyMaskConfig"
        [readonly]="processing"
      />

      <mat-error
        *ngIf="formGroup.get('paymentRefundAmount')?.errors && formGroup.get('paymentRefundAmount')?.hasError('max')"
      >
        Amount exceeded the maximum refund limit of
        <strong> {{ getMinValue([ maxPaymentRefund, maxRefund]) | currency }} </strong>.</mat-error
      >
    </mat-form-field>

    <mat-form-field *ngIf="isInteracPayment" appearance="standard" floatLabel="always">
      <mat-label>Terminal</mat-label>
      <greco-select-payment-method
        [placeholder]="'Select terminal'"
        [formControl]="terminalControl"
        [userId]="purchase.user.id"
        [onlyTerminals]="true"
        [required]="true"
      ></greco-select-payment-method>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Reason for Refund</mat-label>
      <mat-select formControlName="refundReason" [required]="true" [disabled]="processing">
        <mat-option *ngFor="let reason of refundReasons" [value]="reason">{{ reason }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox
      *ngIf="purchase.type === 'ProductPurchaseEntity' 
    || purchase.type === 'SubscriptionStartPurchaseEntity' 
    || purchase.type === 'SubscriptionUpdatePurchaseEntity'"
      style="margin-bottom: 16px"
      formControlName="returnToInventory"
      [disabled]="processing"
    >
      Return item(s) to inventory if applicable
    </mat-checkbox>

    <div
      *ngIf="isInteracPayment && (canRefundToPayment$ | async)"
      style="
        display: flex;
        align-items: center;

        padding: 8px 16px;
        border-radius: 8px;
        margin-bottom: 16px;

        color: rgb(233, 119, 21);
        background-color: rgba(233, 119, 21, 0.2);
      "
    >
      <mat-icon>warning</mat-icon>

      <p style="margin-bottom: 0; margin-left: 16px">
        This purchase was paid with a <strong>debit/interac</strong> card. Refunds MUST be processed in-person using the
        same debit card used for payment.
      </p>
    </div>

    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">Cancel</button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        [disabled]="formGroup.invalid || processing || (isInteracPayment && formGroup.value.paymentRefundAmount ? !terminalControl.value : false)"
        style="flex: 1"
      >
        Confirm
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
