import { Pipe, PipeTransform } from '@angular/core';
import { RRule } from 'rrule';

@Pipe({ name: 'weekdayChip' })
export class WeekdayChipPipe implements PipeTransform {
  transform(rules: RRule[]) {
    if (rules.length == 0) return '';

    let chipString = '';

    const days = {
      0: 'MON - ',
      1: 'TUE - ',
      2: 'WED - ',
      3: 'THU - ',
      4: 'FRI - ',
      5: 'SAT - ',
      6: 'SUN - ',
    } as any;

    chipString = chipString.concat(days[rules[0].options.byweekday[0]]);

    const ruleStrings = rules.map(rule => {
      let hour = rule.options.byhour[0];
      const minute = rule.options.byminute[0];

      let minuteStr = '';
      let hourStr = '';
      let tod = '';

      if (minute < 10) {
        minuteStr = '0' + minute.toString();
      } else {
        minuteStr = minute.toString();
      }

      if (hour == 12) {
        tod = 'PM';
      }

      if (hour > 12) {
        hour = hour - 12;
        tod = 'PM';
      } else if (hour < 12) {
        if (hour == 0) {
          hour = 12;
        }
        tod = 'AM';
      }

      if (hour < 10) {
        hourStr = '0' + hour.toString();
      } else {
        hourStr = hour.toString();
      }
      const time = `${hourStr}:${minuteStr} ${tod}`;
      return time;

      // chipString = chipString.concat(`${time}, `);
    });

    return chipString + ruleStrings.join(', ');
  }
}
