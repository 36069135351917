import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { Invoice } from '@greco/finance-invoices';
import type { InvoiceFilters } from '@greco/nestjs-finance-invoices-util';

@Injectable()
export class InvoiceService {
  constructor(private http: HttpClient) {}

  // @Get('user/:userId')
  async paginateUserInvoices(userId: string, startAfter?: string) {
    return await toPromise(
      this.http.get<Invoice[]>(`/api/invoices/user/${userId}`, {
        params: {
          ...(startAfter && { startAfter }),
        },
      })
    );
  }

  async getInvoice(studioId: string, invoiceId: string): Promise<Invoice> {
    return toPromise(this.http.get<Invoice>(`/api/invoices/${studioId}/${invoiceId}`));
  }

  async getStudioID(accountID: string) {
    return toPromise(this.http.get<string>(`/api/invoices/studio/${accountID}`));
  }

  async getInvoices(params: InvoiceFilters): Promise<Invoice[]> {
    return toPromise(
      this.http.get<Invoice[]>(`/api/invoices/${params.studio}`, {
        //   params: { limit: params.limit?.toString() }
        params: {
          ...(params.limit != undefined ? { limit: params.limit?.toString() } : {}),
          ...(params.includeNotes != undefined ? { includeNotes: '' + params.includeNotes } : {}),
          ...(params.exclude0Dollar != undefined ? { exclude0Dollar: '' + params.exclude0Dollar } : {}),
          ...(params.dateFrom != undefined ? { dateFrom: params.dateFrom?.toISOString() } : {}),
          ...(params.dateTo != undefined ? { dateTo: params.dateTo?.toISOString() } : {}),
          ...(params.startAfter && { startAfter: params.startAfter }),
        },
      })
    );
  }

  async exportUserInvoices(userId: string): Promise<void> {
    const { url } = await toPromise(this.http.post<{ url: string }>(`/api/invoices/user/${userId}`, {}));

    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', `Billing History.csv`);
    anchor.setAttribute('target', '_blank');

    anchor.click();
    anchor.remove();
  }
}
