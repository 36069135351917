<div class="tile" style="cursor: pointer">
  <div class="favorite" (mouseenter)="favoriteHover = true" (mouseleave)="favoriteHover = false">
    <mat-icon
      (click)="toggleFavorite(tile.id, tile.communityId, tile.community)"
      [style.opacity]="favoriteHover ? 1 : 0.5"
      >{{ (favorited$ | async) ? (favoriteHover ? 'bookmark_remove' : 'bookmark_added') : 'bookmark_add' }}</mat-icon
    >
  </div>
  <a [href]="tile.url">
    <div>
      <div class="image-container">
        <img [src]="tile.imageUrl" />
      </div>
      <div class="content">
        <h4>{{ tile.label }}</h4>
        <p>
          <mat-icon>place</mat-icon>
          <small>{{ tile.community?.name }}</small>
        </p>
      </div>
    </div>
  </a>
</div>
