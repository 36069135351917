import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SafePipeModule } from 'safe-pipe';
import { ImageUploadComponent } from './components';

@NgModule({
  imports: [CommonModule, SafePipeModule, MatIconModule, MatButtonModule, MatChipsModule],
  exports: [ImageUploadComponent],
  declarations: [ImageUploadComponent],
})
export class ImageUploadModule {}
