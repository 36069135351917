// import { SecurityResource } from '@greco/security';

export enum UserResourceAction {
  READ = 'READ',
  UPDATE = 'UPDATE',
  MANAGE_AUTH = 'MANAGE_AUTH',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export const UserResource = {
  key: 'identity_user',
  module: 'Identity',

  title: 'Platform User',
  description: 'Platform User Profile & Authentication',

  context: class {
    userId?: string;
  },

  actions: [
    { key: UserResourceAction.READ, title: 'Read', description: 'Ability to read/view user information' },
    { key: UserResourceAction.UPDATE, title: 'Update', description: 'Ability to update user information' },
    {
      key: UserResourceAction.MANAGE_AUTH,
      title: 'Manage Auth',
      description: 'Ability to manager user authentication',
    },
    {
      key: UserResourceAction.CREATE,
      title: 'Create',
      description: 'Ability to create a new user accountwith email, name, phone number',
    },
    { key: UserResourceAction.DELETE, title: 'Delete', description: 'Ability to delete user accounts' },
  ],
} as const;
