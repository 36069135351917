import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UtilModule } from '@greco-fit/util/ngx';
import { BoxModule } from '@greco/ui-box';
import { AlertCardComponent, AlertComponent } from './components';

@NgModule({
  imports: [CommonModule, BoxModule, MatIconModule, UtilModule],
  declarations: [AlertCardComponent, AlertComponent],
  exports: [AlertCardComponent, AlertComponent],
})
export class AlertModule {}
