import { Injectable } from '@angular/core';
import { BuildDateFilter } from '@greco/ngx-filters';

@Injectable({ providedIn: 'any' })
export class UserPerkGrantedDateFilter extends BuildDateFilter('GrantedDateFilter', {
  label: 'Granted Date',
  shortLabel: 'Granted Date',
  description: '',
  properties: ['created'],
}) {}
