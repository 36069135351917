<div style="margin-bottom: 16px">
  <h2 style="margin-bottom: 8px">Credit Card</h2>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Card Holder Name</mat-label>
      <input [required]="true" matInput formControlName="cardHolderName" />
    </mat-form-field>
  </form>
  <p><small>Card Details</small></p>
  <div class="card-container">
    <div [id]="'cardNumber-element-' + cardElemId"></div>
  </div>
  <div style="display: flex; gap: 12px; width: 100%; margin-top: -32px">
    <div class="card-container">
      <div [id]="'cardExpiry-element-' + cardElemId"></div>
    </div>
    <div class="card-container">
      <div [id]="'cardCvc-element-' + cardElemId"></div>
    </div>
  </div>
</div>
