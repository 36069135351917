import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UtilModule } from '@greco-fit/util/ngx';
import { ScheduleInputComponent } from './schedule-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatChipsModule,
    MatSelectModule,
    MatIconModule,
    UtilModule,
    MatSelectModule,
    MatDividerModule,
    MatButtonModule
  ],
  declarations: [ScheduleInputComponent],
  exports: [ScheduleInputComponent]
})
export class ScheduleInputModule {}
