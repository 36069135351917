import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Account } from '@greco/finance-accounts';
import { AccountService } from '../../services';

@Component({
  selector: 'greco-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  @Input() account!: Account;
  @Output() detailsUpdated = new EventEmitter<Account>();

  initialValue: any;
  formGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.required],
    address: [null, Validators.required],
  });

  public saving = false;

  onSave = async () => {
    try {
      this.saving = true;
      this.account = await this.accountSvc.updateAccount(this.account.id, this.formGroup.value);
      this.detailsUpdated.emit(this.formGroup.value);
      this.initialValue = this.formGroup.value;
      this.formGroup.markAsPristine();
    } finally {
      this.saving = false;
    }
  };

  constructor(private fb: FormBuilder, private accountSvc: AccountService) {}

  ngOnInit(): void {
    this.initialValue = {
      name: this.account.name,
      email: this.account.email,
      phone: this.account.phone,
      address: this.account.address,
    };

    this.formGroup.reset(this.initialValue);

    // TODO(adaoust): Check if account details can be updated
    // const canUpdateDetails = await toPromise(this.canUpdateDetails$);
    // if (!canUpdateDetails) this.formGroup.disable();
  }
}
