import { Component, Input } from '@angular/core';
import { User } from '@greco/identity-users';

@Component({
  selector: 'greco-manage-user-auth',
  templateUrl: './manage-user-auth.component.html',
  styleUrls: ['./manage-user-auth.component.scss'],
})
export class ManageUserAuthComponent {
  @Input() user!: User;
}
