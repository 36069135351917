import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Resource, ResourceTag } from '@greco/booking-events';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ResourceTagService } from '../../services/resource-tag.service';

@Component({
  selector: 'greco-resource-tag-picker',
  templateUrl: './resource-tag-picker.component.html',
  styleUrls: ['./resource-tag-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ResourceTagPickerComponent }],
})
export class ResourceTagPickerComponent implements MatFormFieldControl<ResourceTag[]>, ControlValueAccessor, OnDestroy {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private resourceTagSvc: ResourceTagService,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'greco-resource-tag-picker';

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  stateChanges = new Subject<void>();

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `${ResourceTagPickerComponent._controlType}-${ResourceTagPickerComponent._id++}`;
  readonly controlType = ResourceTagPickerComponent._controlType;

  private _communityId$ = new BehaviorSubject<string | null>(null);
  searchQuery$ = new BehaviorSubject<string>('');

  allTags: ResourceTag[] | null = null;

  allTags$ = this._communityId$.pipe(
    switchMap(async communityId => {
      if (!communityId) return null;
      return this.resourceTagSvc.getResourceTags(communityId);
    }),
    tap(tags => {
      tags?.sort((a, b) => a.label.localeCompare(b.label));
      this.allTags = [];
      tags?.forEach(tag => this.allTags?.push(tag));
    }),
    shareReplay(1)
  );

  resourceTags$ = combineLatest([this.searchQuery$, this.allTags$]).pipe(
    map(([searchQuery, tags]) => {
      if (!tags) return null;
      else return tags.filter(tag => tag.label.toLowerCase().includes(searchQuery.toLowerCase()));
    }),
    shareReplay(1)
  );

  @Input() get communityId() {
    return this._communityId$.value;
  }
  set communityId(communityId: string | null) {
    this._communityId$.next(communityId);
  }

  private _value: ResourceTag[] | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: ResourceTag[] | null) {
    this._value = value;

    if (!value || !value?.length) {
      this.selectedTags = [];
      this.selectedTagIds = [];
    } else if (value?.length) {
      // This is used to reset the resource array for display when a reset button is clicked
      if (value.length !== this.selectedTags.length) {
        this.selectedTags = [];
        this.selectedTagIds = [];
        value.forEach(item => {
          this.selectedTags.push(item);
          this.selectedTagIds.push(item.id);
        });
      }
    }
    this._onChange?.(value);
    this.stateChanges.next();
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.focused || !!this._input?.value || (this.value?.length && !this._input?.value) || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return (
      !!this.ngControl?.touched &&
      (!!Object.keys(this.ngControl?.errors || {}).length || (this.required && !this.value))
    );
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  selectedTags: ResourceTag[] = [];
  selectedTagIds: string[] = [];

  select(tag: ResourceTag) {
    this.selectedTagIds.includes(tag.id) ? this.removeFromSelectedTags(tag) : this.addToSelectedTags(tag);
  }

  addToSelectedTags(tag: ResourceTag) {
    this.selectedTags.push(tag);
    this.selectedTagIds.push(tag.id);
    this.value = [...this.selectedTags];
  }

  removeFromSelectedTags(tag: ResourceTag) {
    const index = this.selectedTagIds.findIndex(t => tag.id === t);
    this.selectedTags.splice(index, 1);
    this.selectedTagIds.splice(index, 1);
    this.value = [...this.selectedTags];
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.resource-tag-picker-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: ResourceTag[] | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: ResourceTag[] | Resource[] | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.searchQuery$.complete();
    this._communityId$.complete();
  }
}
