import { Injectable } from '@angular/core';
import { ToggleFilter } from '@greco/ngx-filters';

@Injectable({ providedIn: 'any' })
export class IncludePrivateFilter extends ToggleFilter {
  constructor() {
    super('IncludePrivateFilter', {
      label: 'Include Private',
      description: 'Include Private',
      shortLabel: 'Include Private',
      properties: ['private'],
    });
  }

  getValueLabel(value: any): string {
    return value ? 'Include Private' : 'Only Public';
  }

  getValueOptions(search?: string): any[] | Promise<any[]> {
    if (search?.toLowerCase() === 'private') {
      return [true];
    } else if (search?.toLowerCase() === 'public') {
      return [false];
    } else {
      return [];
    }
  }

  serializeValue(value: any): string {
    return value.toString();
  }

  deserializeValue(serializedValue: string) {
    return new Boolean(serializedValue);
  }
}
