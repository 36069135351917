import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import type { QueryStatsDto } from '@greco/nestjs-sales-stats';
import { SalesStat } from '@greco/sales-stats';

@Injectable()
export class StatsService {
  constructor(private http: HttpClient) {}

  async queryStats(dto: QueryStatsDto) {
    return await toPromise(
      this.http.get<SalesStat[]>(`/api/stats/query`, {
        params: {
          referenceId: dto.referenceId,
          ...(dto.endDate ? { endDate: dto.endDate.toISOString() } : {}),
          ...(dto.startDate ? { startDate: dto.startDate.toISOString() } : {}),
        },
      })
    );
  }
}
