import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Contact } from '@greco/identity-contacts';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'greco-qr-codes-dialog',
  templateUrl: 'qr-codes.dialog.html',
  styleUrls: ['qr-codes.dialog.scss'],
})
export class QRCodesDialog {
  contacts: Contact[] = [];

  constructor(
    private dialog: MatDialog,
    private userSvc: UserService,
    public dialogRef: MatDialogRef<QRCodesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { userId?: string; contact?: Contact }
  ) {
    if (data.userId) this.userSvc.getUserContacts(data.userId).then(contacts => (this.contacts = contacts));
    else if (data.contact) this.contacts = [data.contact];
  }

  notNull = (value: Contact, _index: number, _array: Contact[]): boolean => {
    return value.memberNumber !== null;
  };
}
