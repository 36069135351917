/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { AccountLinkStatus } from '@greco/account-linking';
import { Contact } from '@greco/identity-contacts';
import { User } from '@greco/identity-users';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { SecurityService } from '@greco/ngx-security-util';
import { Product, ProductVariant, VariantResource, VariantResourceAction } from '@greco/sales-products';
import { AccountLinkingService } from '@greco/web-account-linking';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-fit-create-quick-purchase',
  templateUrl: './create-quick-purchase.dialog.html',
  styleUrls: ['./create-quick-purchase.dialog.scss'],
})
export class CreateQuickPurchaseDialog implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<CreateQuickPurchaseDialog>,
    private formBuilder: FormBuilder,
    private router: Router,
    private clipboardSvc: ClipboardService,
    private linkSvc: AccountLinkingService,
    private securitySvc: SecurityService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      variant: ProductVariant;
      communityId: string;
      accountId: Product;
      contact?: Contact;
    }
  ) {
    this.communityId = data.communityId;
    if (data.contact) {
      this.form.setValue({ contact: data.contact, variant: null });
    }
    this.canSellHiddenVariants$ = this.securitySvc.hasAccess(VariantResource.key, VariantResourceAction.SELL_HIDDEN, {
      communityId: this.communityId,
    });
  }

  private onDestroy$ = new Subject<void>();
  communityId: string;

  billedTo?: User;

  dialogData: DialogData = {
    title: 'Quick Purchase',
    subtitle: 'Checkout a product on the behalf of a user.',
    showCloseButton: false,
    hideDefaultButton: true,
  };

  form = this.formBuilder.group({
    contact: [null, Validators.required],
    variant: [null, Validators.required],
  });

  canSellHiddenVariants$: Promise<boolean> | null = null;

  async submit() {
    if (this.form.valid) {
      const userId = this.form.get('contact')!.value.id;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const variantId = this.form.get('variant')!.value.id;
      const redirect = await this.router.navigate(['shop/checkout'], { queryParams: { items: variantId, userId } });
      if (redirect) {
        this.dialogRef.close();
      } else {
        this.dialogData.subtitle = 'Sorry! Unable to create a purchase, try again or contact Support';
        this.form.reset();
      }
    }
  }

  async close() {
    return this.dialogRef.close();
  }

  async ngOnInit() {
    this.form.valueChanges.pipe(startWith(this.form.value), takeUntil(this.onDestroy$)).subscribe(async () => {
      const contact = this.form.value.contact as Contact;
      this.billedTo = undefined;

      // This setTimeout is required for weird timing issues. The accounts for the user and the purchasedBy update seperately so
      //  this makes sure to wait for them both to be updated by the above line before giving them new values
      setTimeout(async () => {
        if (contact?.user!.email) {
          this.billedTo = contact.user;
          return;
        } else {
          const parents = (await this.linkSvc.getGivenLinksByAccount(contact?.user!.id))
            ?.filter(link => link.status === AccountLinkStatus.ACTIVE)
            .map(link => link.accessor!);

          if (parents.length) {
            this.billedTo = parents[0];
            return;
          } else {
            this.billedTo = undefined;
          }
        }
      }, 200);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  copyCheckoutUrlToClipboard(variant: ProductVariant, userId: string) {
    this.clipboardSvc.copy(
      `${window.location.origin}/shop/checkout?items=${variant.id}&productId=${variant.productId}&userId=${userId}`,
      'Checkout URL'
    );
  }
}
