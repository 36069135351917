import { Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { CommunityService } from '@greco/ngx-identity-communities';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  PurchaseItemResource,
  SaleCategory,
  SaleCategoryResource,
  SaleCategoryResourceAction,
} from '@greco/sales-purchases';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SaleCategoriesTableComponent } from '../../components';
import { DefaultSaleCategoryDefinitions, DEFAULT_SALE_CATEGORY_DEFINITIONS } from '../../default-sale-category.token';
import { DefaultSaleCategoriesDialog, SaleCategoryDialog } from '../../dialogs';
import { SaleCategoryService } from '../../services';
import { CategorySearchFilter } from './filters';

@Component({
  selector: 'greco-sale-categories-page',
  templateUrl: './sale-categories.page.html',
  styleUrls: ['./sale-categories.page.scss'],
})
export class SaleCategoriesPage implements OnChanges, OnDestroy {
  constructor(
    @Inject(DEFAULT_SALE_CATEGORY_DEFINITIONS) public readonly forms: DefaultSaleCategoryDefinitions[],
    private dialog: MatDialog,
    private securitySvc: SecurityService,
    private communitySvc: CommunityService,
    private categorySvc: SaleCategoryService,
    private snacks: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @PropertyListener('communityId') communityId$ = new BehaviorSubject<string | null>(null);
  @Input() communityId!: string;

  readonly accountId$ = this.communityId$.pipe(
    switchMap(async communityId => {
      const community = communityId ? await this.communitySvc.getCommunity(communityId) : null;
      return community ? community.financeAccountId : null;
    })
  );

  readonly canRead$ = this.accountId$.pipe(
    switchMap(async account => {
      return account
        ? await this.securitySvc.hasAccess(SaleCategoryResource.key, SaleCategoryResourceAction.READ, {
            accountId: account,
          })
        : false;
    })
  );

  readonly canCreate$ = this.accountId$.pipe(
    switchMap(async account => {
      return account
        ? await this.securitySvc.hasAccess(SaleCategoryResource.key, SaleCategoryResourceAction.CREATE, {
            accountId: account,
          })
        : false;
    })
  );
  readonly canUpdate$ = this.accountId$.pipe(
    switchMap(async account => {
      return account
        ? await this.securitySvc.hasAccess(PurchaseItemResource.key, 'UPDATE', {
            accountId: account,
          })
        : false;
    })
  );

  @ViewChild(SaleCategoriesTableComponent) table?: SaleCategoriesTableComponent;

  filterOptions = [CategorySearchFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.communityId.previousValue !== changes.communityId.currentValue) {
      await this.refresh();
    }
  }

  ngOnDestroy() {
    this.filters$.complete();
  }

  async refresh() {
    this.table?.refresh();
  }

  async create() {
    const accountId = await toPromise(this.accountId$);
    if (!accountId) return;
    const dialog = this.dialog.open(SaleCategoryDialog, {
      data: { accountId },
    });
    await toPromise(dialog.afterClosed());
    this.refresh();
  }

  async openDefaultSaleCategoriesDialog() {
    const accountId = await toPromise(this.accountId$);
    this.dialog.open(DefaultSaleCategoriesDialog, { data: accountId });
  }

  async syncDefaults() {
    const accountId = await toPromise(this.accountId$);
    if (!accountId) return;
    const spinner = this.dialog.open(MatSpinner);
    try {
      await this.categorySvc.syncDefaults(accountId);
      this.snacks.open('Sync Successful', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong', 'Oops!', { duration: 2500, panelClass: 'mat-warn' });
    }
    spinner.close();
  }

  onFilterApplied() {
    this.table?.onFilterApplied();
  }

  async navigate(saleCategory: SaleCategory) {
    await this.router.navigate([saleCategory.id], { relativeTo: this.route });
  }
}
