import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClipboardService } from './services/clipboard.service';

export { ClipboardService };

@NgModule({
  imports: [CommonModule, MatSnackBarModule],
  providers: [ClipboardService],
})
export class ClipboardModule {}
