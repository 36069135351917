import { SecurityResource } from '@greco/security';

export enum VariantResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  SELL_HIDDEN = 'SELL_HIDDEN',
  MANAGE_INVENTORY = 'MANAGE_INVENTORY',
}

export const VariantResource: SecurityResource = {
  key: 'sales_product',
  module: 'Sales',

  title: 'Variant',
  description: 'Manage variants',

  context: class {
    variantId?: string;
    productId?: string;
    communityId?: string;
  },

  actions: [
    { key: VariantResourceAction.READ, title: 'Read variants', description: 'Ability to read variants' },
    { key: VariantResourceAction.CREATE, title: 'Create variants', description: 'Ability to create variants' },
    { key: VariantResourceAction.UPDATE, title: 'Update variants', description: 'Ability to update variants' },
    {
      key: VariantResourceAction.SELL_HIDDEN,
      title: 'Sell hidden variants',
      description: 'Ability to sell hidden Variants',
    },
    {
      key: VariantResourceAction.MANAGE_INVENTORY,
      title: 'Manage inventory',
      description: 'Ability to manage inventory',
    },
  ],
};
