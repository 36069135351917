<div
  *grecoLet="booking$ | async as booking"
  (click)="!booking && bookNow()"
  style="display: flex; gap: 12px; align-items: center; justify-content: space-between; flex-wrap: wrap"
>
  <div style="display: flex; gap: 12px; align-items: center">
    <greco-multi-avatar
      style="flex-shrink: 0"
      [style.width]="(isMobile$ | async)?.matches ? '28px' : '36px'"
      [style.height]="(isMobile$ | async)?.matches ? '28px' : '36px'"
      [images]="(photoUrls$ | async) || ['assets/lf3/icon_square_pad.png']"
      [size]="44"
      [color]="event.color || 'primary'"
    ></greco-multi-avatar>
    <div *grecoLet="trainers$ | async as trainers" style="display: flex; flex-direction: column">
      <h3 style="margin-bottom: 0">
        {{ event.title }} <small *ngIf="trainers?.length">with</small>
        {{ trainers && trainers?.length ? (trainers | pluck : 'name').join(', ') : '' }}
      </h3>
      <mat-chip-list>
        <mat-chip>
          <mat-icon>event</mat-icon>
          {{ event.startDate | date : 'shortTime' }} - {{ event.duration }}mins
        </mat-chip>
        <mat-chip> <mat-icon>place</mat-icon>{{ event.community.name }} </mat-chip>
        <mat-chip *ngIf="!(isMobile$ | async)?.matches">
          <mat-icon>{{ event.calendar?.icon || 'calendar_month' }}</mat-icon>
          {{ event.calendar?.title }}
        </mat-chip>
        <ng-container *grecoLet="getRoom(event) as room">
          <mat-chip *ngIf="room && !(isMobile$ | async)?.matches">
            <mat-icon>{{ room.icon || 'living' }}</mat-icon>
            {{ room.name }}
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
  </div>
  <div
    style="display: flex; gap: 12px"
    (mouseenter)="hoveringPerk = true"
    (mouseleave)="hoveringPerk = false"
    [style.width]="(isMobile$ | async)?.matches ? '100%' : 'auto'"
  >
    <div
      class="perk-badge-container"
      style="pointer-events: none"
      *ngIf="booking?.bookingOption || bookingOption as bOpt"
      [style.border-color]="bOpt.badge.color | safe : 'style'"
    >
      <div class="perk-tooltip" [style.opacity]="hoveringPerk ? '1' : '0'">
        <h3 class="title">Book with: {{ bOpt.title }}</h3>
        <div class="subtitle" *ngIf="requiredBoosts$ | async as requiredBoosts">
          <ng-container *ngIf="bOpt.price"> + {{ bOpt.price / 100 | currency }} </ng-container>
        </div>
        <div class="subtitle" *ngIf="requiredBoosts$ | async as requiredBoosts">
          <ng-container *ngIf="bOpt.maxBoost && requiredBoosts > bOpt.maxBoost; else withBoosts">
            Available {{ availableIn$ | async }}
          </ng-container>
          <ng-template #withBoosts>
            + {{ requiredBoosts }} booking window booster{{ requiredBoosts > 1 ? 's' : '' }}
          </ng-template>
        </div>
        <div class="subtitle"></div>
      </div>

      <div style="display: flex; gap: 12px; align-items: center; margin-top: 8px">
        <greco-perk-badge
          class="event-item-booking-option"
          [shape]="bOpt.badge.shape"
          [color]="bOpt.badge.color"
          [icon]="bOpt.badge.icon"
          [text]="bOpt.badge.text"
          [small]="true"
        ></greco-perk-badge>
        <ng-container *ngIf="!booking && bOpt.maxBoost !== -1">
          <greco-perk-badge
            *ngIf="requiredBoosts$ | async as requiredBoosts"
            class="event-item-booking-option"
            shape="rounded-star"
            icon="more_time"
            color="#685a8f"
            [quantity]="requiredBoosts"
            [small]="true"
          ></greco-perk-badge>
        </ng-container>
      </div>
    </div>

    <a
      *ngIf="booking; else noBooking"
      mat-stroked-button
      color="primary"
      [routerLink]="['/']"
      [style.width]="(isMobile$ | async)?.matches ? '100%' : 'auto'"
    >
      Already Booked <mat-icon>launch</mat-icon>
    </a>
    <ng-template #noBooking>
      <button
        mat-raised-button
        color="primary"
        [disabled]="(canBook$ | async) !== true"
        style="display: flex; flex-direction: column"
        [style.width]="(isMobile$ | async)?.matches ? '100%' : 'auto'"
      >
        <div class="btn-text-container">
          <ng-container *grecoLet="event.maxCapacity - $any(event).bookings as spotsLeft">
            <p>
              <span>{{
                event.maxCapacity > $any(event).bookings
                  ? 'Book Now'
                  : (inWaitlist$ | async)
                  ? 'Already in Waitlist'
                  : 'Join Waitlist'
              }}</span>

              <small
                *ngIf="(isMobile$ | async)?.matches && event.community?.id !== 'com_greco' && (spotsLeft || 0) > 0"
              >
                (Only <strong>{{ spotsLeft }}</strong> spot{{ spotsLeft === 1 ? '' : 's' }} left)
              </small>
            </p>

            <p *ngIf="!(isMobile$ | async)?.matches && event.community?.id !== 'com_greco' && (spotsLeft || 0) > 0">
              <small>
                Only <strong>{{ spotsLeft }}</strong> spot{{ spotsLeft === 1 ? '' : 's' }} left!
              </small>
            </p>
          </ng-container>
        </div>
      </button>
    </ng-template>
    <button
      mat-flat-button
      color="primary"
      *ngIf="booking"
      (click)="$event.stopImmediatePropagation(); bookAnother()"
      matTooltip="Book a different user or guest"
    >
      Book Another
    </button>
  </div>
</div>
