<greco-simple-dialog [data]="dialogData">
  <greco-table [data]="data.assignments">
    <!-- Resource Image -->
    <div *grecoTableCol="''; let assignment; fitContent: true" style="width: 24px; height: 24px; margin-right: 7px">
      <greco-smart-img
        style="margin-top: 6px"
        [round]="true"
        [src]="assignment.resource?.photoURL || 'assets/lf3/icon_square_pad.png'"
      ></greco-smart-img>
    </div>

    <!-- Resource Name -->
    <ng-container *grecoTableCol="'Resource Name'; let assignment"> {{ assignment.resource.name }} </ng-container>

    <!-- Resource Tag -->
    <ng-container *grecoTableCol="'Resource Tag'; let assignment"> {{ assignment.resourceTag.label }} </ng-container>

    <!-- Notify Resource -->
    <ng-container *grecoTableCol="'Notify?'; let assignment; fitContent: true">
      <div style="display: flex; justify-content: center">
        <mat-checkbox
          color="primary"
          [checked]="emails.includes(assignment.resource.user.email)"
          (click)="$event.preventDefault(); select(assignment.resource)"
        ></mat-checkbox>
      </div>
    </ng-container>
  </greco-table>

  <div *ngIf="this.data.assignments.length > 1" style="margin-top: 12px; display: flex; justify-content: flex-end">
    Select All
    <mat-checkbox
      color="primary"
      style="margin: -2px 42px 0 12px"
      [checked]="allSelected"
      (click)="allSelected ? removeAll() : selectAll(); $event.preventDefault()"
    >
    </mat-checkbox>
  </div>

  <div style="margin-top: 12px; display: flex; justify-content: flex-end; gap: 8px">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button (click)="notify()">Confirm</button>
  </div>
</greco-simple-dialog>
