import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UrlEncodePipe } from './url-encode.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [UrlEncodePipe],
  exports: [UrlEncodePipe],
})
export class UrlEncodePipeModule {}
