import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { EventWithUserDetails } from '@greco/booking-events2';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { AlertConfig, AlertType } from '@greco/ui-alert';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EventService } from '../../../services';

@Component({
  selector: 'alt-event-missing-perks-alert',
  templateUrl: './event-missing-perks-alert.component.html',
  styleUrls: ['./event-missing-perks-alert.component.scss'],
})
export class EventMissingPerksAlertComponent {
  constructor(private breakpointObserver: BreakpointObserver, private eventSvc: EventService) {}

  @PropertyListener('event') private event$ = new BehaviorSubject<EventWithUserDetails | null>(null);
  @Input() event?: EventWithUserDetails;

  @PropertyListener('user') private user$ = new BehaviorSubject<User | null>(null);
  @Input() user?: User;

  isMobile$ = this.breakpointObserver.observe('(max-width: 399px)').pipe(map(result => result.matches));

  alert$ = combineLatest([this.event$, this.user$]).pipe(
    map(([event, user]) => {
      if (!event || !user) return null;

      return {
        title: 'Missing Perks',
        type: AlertType.DANGER,
        description: this.getAlertDescription(),
      } as AlertConfig;
    }),

    tap(alert => {
      if (!this.user) return;

      const eventAlerts = this.eventSvc.alerts$.value;
      const userAlerts = eventAlerts.find(alert => alert.userId === this.user?.id);

      if (!userAlerts && alert) {
        this.eventSvc.alerts$.next([
          ...eventAlerts,
          {
            userId: this.user.id,
            missingBookingOption: true,
          },
        ]);
      } else if (userAlerts && alert) {
        const otherUserAlerts = eventAlerts.filter(alert => alert.userId !== this.user?.id);
        this.eventSvc.alerts$.next([
          ...otherUserAlerts,
          {
            ...userAlerts,
            missingBookingOption: true,
          },
        ]);
      } else if (!userAlerts && !alert) {
        this.eventSvc.alerts$.next([
          ...eventAlerts,
          {
            userId: this.user.id,
            missingBookingOption: false,
          },
        ]);
      } else if (userAlerts && !alert) {
        const otherUserAlerts = eventAlerts.filter(alert => alert.userId !== this.user?.id);
        this.eventSvc.alerts$.next([
          ...otherUserAlerts,
          {
            ...userAlerts,
            missingBookingOption: false,
          },
        ]);
      }
    })
  );

  getAlertDescription() {
    return 'You do not have the perks required to book into this event. Head to our <a href="/shop" style="color: var(--accent-color)">Shop</a> to purchase items or get in touch with a team member to obtain any required perks';
  }
}
