import { Component, InjectionToken, Input, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CreateCouponAssignmentDto } from '@greco/api-coupons';

@Component({ selector: 'greco-abstract-coupon-assignment-form', template: '' })
export abstract class CouponAssignmentFormComponent {
  @Input() communityId!: string;
  @Input() couponId!: string;
  @Input() type!: string;

  abstract formGroup: FormGroup;
  abstract save(): { data: CreateCouponAssignmentDto[]; module: string };
}

export interface CouponAssignmentForm {
  label: string;
  matIcon: string;
  type: string;
  module: string;
  form: Type<CouponAssignmentFormComponent>;
}

export const COUPON_ASSIGNMENT_FORMS = new InjectionToken<CouponAssignmentForm[]>('COUPON_ASSIGNMENT_FORMS');
