import { Pipe, PipeTransform } from '@angular/core';
import { CommunityService } from '@greco/ngx-identity-communities';
import { Purchase } from '@greco/sales-purchases';

@Pipe({ name: 'grecoPurchaseCommunityId' })
export class PurchaseCommunityIdPipe implements PipeTransform {
  constructor(private communitySvc: CommunityService) {}

  transform(value: Purchase) {
    return this.communitySvc.getCommunityIdByAccountId(value.account.id);
  }
}
