import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { Tile, TileSecurityResourceAction, TilesSecurityResource, UpdateTileDto } from '@greco/tiles';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TilesService } from '../../services/tiles.service';
import { TileDetails } from '../tiles-input/tiles-input.component';

@Component({
  selector: 'greco-tile-details',
  templateUrl: './tile-details.component.html',
  styleUrls: ['./tile-details.component.scss'],
})
export class TileDetailsComponent {
  constructor(
    private formBuilder: FormBuilder,
    private tilesSvc: TilesService,
    private snacks: MatSnackBar,
    private comSvc: CommunitySecurityService
  ) {}

  private _tile$ = new BehaviorSubject<Tile | null>(null);

  initialImages: File[] = [];
  initialUrls: string[] = [];
  @Input() set tile(tile) {
    this._tile$.next(tile);

    setTimeout(() => {
      this.resetDetails();
    });
  }
  get tile() {
    return this._tile$.value;
  }
  saving = false;

  resetDetailsValue: TileDetails = {
    label: '',
    image: null,
    url: '',
    group: '',
    priority: 0,
  };
  canUpdateTile$ = this._tile$.pipe(
    switchMap(async tile =>
      tile
        ? await this.comSvc.hasAccess(tile.communityId, TilesSecurityResource.key, TileSecurityResourceAction.UPDATE)
        : false
    )
  );

  detailsForm = this.formBuilder.group({ tileDetails: [null, Validators.required] });

  saveDetails = async () => {
    this.saving = true;

    try {
      const data: UpdateTileDto = {
        communityId: '',
        status: false,
      };
      const detailsData = this.detailsForm.value.tileDetails;
      const tileId = this.tile?.id;
      if (!tileId) return;

      if (this.tile) {
        if (detailsData.label !== this.tile?.label) data.label = detailsData.label;
        if (detailsData.url !== this.tile?.url)
          data.url =
            detailsData.url && !detailsData.url.includes('https://') ? `https://${detailsData.url}` : detailsData.url;
        data.status = this.tile.status;
        if (detailsData.group !== this.tile?.tileGroup?.name) {
          data.tileGroup = { communityId: this.tile.communityId, name: detailsData.group };
        }
        data.communityId = this.tile.communityId;
        if (detailsData.priority !== this.tile?.priority) {
          data.priority = detailsData.priority;
        }
      }

      // *****************************Image Change Detection************************************
      const formImageName = detailsData.image[0].name;
      const tileImageName = this.tile?.imageUrl?.split('images/').pop() || '';

      let changedImages = false;
      if (formImageName.length !== tileImageName.length) changedImages = true;

      if (!changedImages) {
        if (formImageName !== tileImageName) changedImages = true;
      }

      if (changedImages) {
        const formData = new FormData();
        let uploadImage = false;

        // only upload new photos
        if (!tileImageName.includes(detailsData.image.name)) {
          uploadImage = true;
          formData.append('file', detailsData.image[0]);
        }
        // remove photos
        let imageToRemove: string | null | undefined;
        if (!formImageName.includes(this.tile?.imageUrl?.split('images/').pop() || '')) {
          imageToRemove = this.tile?.imageUrl;
        }
        if (imageToRemove) {
          await this.tilesSvc.removeProductImage(this.tile?.id);
        }
        if (uploadImage) this.tile = await this.tilesSvc.updateTileImage(tileId, formData);
      }

      // *****************************************************************************************

      if (Object.keys(data).length) this.tile = await this.tilesSvc.updateTile(tileId, data);

      this.snacks.open('Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }

    this.saving = false;
  };

  resetImages() {
    this.initialImages = [];
    this.initialUrls = [];
    if (this.tile?.imageUrl && this.tile?.imageUrl.length) {
      const file = new File([], this.tile.imageUrl.split('images/').pop() || '');
      this.initialImages.push(file);
      this.initialUrls.push(this.tile.imageUrl);
    }
  }

  resetDetails() {
    this.resetImages();
    this.resetDetailsValue = {
      label: this.tile?.label || '',
      image: this.initialImages || null,
      url: this.tile?.url || '',
      group: this.tile?.tileGroup?.name || '',
      priority: this.tile?.priority || 0,
    };
    setTimeout(() => {
      this.detailsForm.reset({ tileDetails: this.resetDetailsValue });
      this.detailsForm.markAsPristine();
    });

    if (this.tile) this.detailsForm.enable();
    else this.detailsForm.disable();
  }

  resetForm() {
    this.detailsForm.markAsPristine();
    this.resetImages();
  }
}
