import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import type { AccountPaymentMethod } from '@greco/finance-payments';
import type { CreateAccountPaymentMethodDto } from '@greco/nestjs-finance-payments';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';

@Injectable()
export class AccountPaymentMethodService {
  constructor(private http: HttpClient) {}

  // @Get()
  async paginate(accountId: string, query: PaginatedQueryParams): Promise<PaginatedDto<AccountPaymentMethod>> {
    return await toPromise(
      this.http.get<PaginatedDto<AccountPaymentMethod>>('/api/account-payment-methods', {
        params: {
          accountId: accountId,
          ...(query.page ? { page: query.page.toString() } : {}),
          ...(query.limit ? { limit: query.limit.toString() } : {}),
        },
      })
    );
  }

  async getAll(accountId: string): Promise<AccountPaymentMethod[]> {
    const paymentMethods: AccountPaymentMethod[] = [];
    let pagination: IPaginationMeta | null = null;

    do {
      const page: number = (pagination?.currentPage || 0) + 1;
      const data = await this.paginate(accountId, { limit: 100, page });

      paymentMethods.push(...data.items);
      pagination = data.meta;
    } while (paymentMethods.length < (pagination?.totalItems || 0));

    return paymentMethods;
  }

  //@Get('used/:accountId')
  getUsedPaymentMethods(accountId: string) {
    return toPromise(this.http.get<string[]>(`/api/account-payment-methods/used/${accountId}`));
  }

  // @Get('default/:accountId')
  async getDefault(accountId: string, ignoreError?: boolean): Promise<AccountPaymentMethod> {
    try {
      return await toPromise(
        this.http.get<AccountPaymentMethod>(`/api/account-payment-methods/default/${accountId}`, {
          headers: ignoreError ? { 'X-GrecoIgnoreErrors': 'true' } : {},
        })
      );
    } catch (err) {
      if (!ignoreError) throw err;
      return null as any;
    }
  }

  // @Get(':paymentMethodId')
  async getById(paymentMethodId: string): Promise<AccountPaymentMethod> {
    return await toPromise(this.http.get<AccountPaymentMethod>(`/api/account-payment-methods/${paymentMethodId}`));
  }

  // @Delete(':paymentMethodId')
  removePaymentMethod(paymentMethodId: string) {
    return toPromise(this.http.delete(`/api/account-payment-methods/${paymentMethodId}`));
  }

  // @Post()
  async create(body: CreateAccountPaymentMethodDto): Promise<AccountPaymentMethod> {
    return await toPromise(this.http.post<AccountPaymentMethod>('/api/account-payment-methods', body));
  }

  // @Put('default/:paymentMethodId')
  async setDefault(paymentMethodId: string): Promise<AccountPaymentMethod> {
    return await toPromise(
      this.http.put<AccountPaymentMethod>(`/api/account-payment-methods/default/${paymentMethodId}`, {})
    );
  }
}
