import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { Promotion } from '@greco/promotions';
import { Subscription } from 'rxjs';
import { PromotionsService } from '../../services';

@Component({
  selector: 'greco-add-product',
  templateUrl: './add-product.dialog.html',
  styleUrls: ['./add-product.dialog.scss'],
})
export class AddProductDialog implements OnInit, OnDestroy {
  form = this.fb.group({
    variants: [[], Validators.minLength(1)],
  });

  dialogData: DialogData = {
    title: 'Add a Product',
    subtitle: 'Selected variants will be added to the promotion',
    buttons: [
      {
        label: 'Cancel',
        role: 'cancel',
      },
      {
        label: 'Select',
        role: 'select',
        disabled: this.form.invalid,
        resultFn: () => this.submit(),
      },
    ],
  };

  formStatus: Subscription;

  constructor(
    private promotionsSvc: PromotionsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { promotion: Promotion }
  ) {
    this.formStatus = this.form.statusChanges.subscribe(f => {
      this.dialogData = {
        ...this.dialogData,
        buttons: [
          {
            label: 'Cancel',
            role: 'cancel',
          },
          {
            label: 'Select',
            role: 'select',
            disabled: f === 'INVALID',
            resultFn: () => this.submit(),
          },
        ],
      };
    });
  }

  async submit() {
    if (this.form.valid) {
      // TODO(adaoust): pdate promotion;
      console.log(this.form.value);
      await this.promotionsSvc.update({
        id: this.data.promotion.id,
        productVariantIds: [...this.data.promotion.productVariants, ...this.form.value.variants].map(p => p.id),
      });
    }
  }

  ngOnInit() {
    this.form.get('variants')?.patchValue(this.data.promotion.productVariants);
  }

  ngOnDestroy() {
    if (!this.formStatus.closed) this.formStatus.unsubscribe();
  }
}
