import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Community } from '@greco/identity-communities';
import { CommunityService } from '@greco/ngx-identity-communities';

@Injectable()
export class ReferralService {
  constructor(private snackbar: MatSnackBar, private communityService: CommunityService) {}

  setReferral(referral: string) {
    localStorage.setItem('referral', referral);
  }

  getReferral() {
    return localStorage.getItem('referral');
  }

  resetReferral() {
    localStorage.removeItem('referral');
  }

  async processReferral(referral: string): Promise<{ type: 'Community'; community: Community } | null> {
    try {
      const community = await this.communityService.getCommunity(referral);
      if (!community) throw new Error();
      return {
        type: 'Community',
        community,
      };
    } catch (error) {
      this.snackbar.open("Unable to process referral '" + referral + "'", 'Ok', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
      localStorage.removeItem('referral');
      return null;
    }
  }
}
