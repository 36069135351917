import { SecurityResource } from '@greco/security';

export enum PerkResourceAction {
  GRANT = 'GRANT',
  REVOKE = 'REVOKE',
  VIEW_USER_PERKS = 'VIEW_USER_PERKS',
}

export const PerkResource: SecurityResource = {
  key: 'sales_perks_perk',
  module: 'Sales',

  title: 'Perk',
  description: 'Manage user perks',

  context: class {
    perkId?: string;
    userId?: string;
    communityId?: string;
  },

  actions: [
    { key: PerkResourceAction.VIEW_USER_PERKS, title: 'View user perks', description: 'Ability to view user perks' },
    { key: PerkResourceAction.GRANT, title: 'Grant Perks', description: 'Ability to grant perks.' },
    { key: PerkResourceAction.REVOKE, title: 'Revoke Perks', description: 'Ability to revoke perks.' },
  ],
};
