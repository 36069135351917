<ng-container *ngIf="transfers$ | async as transfers">
  <!-- <ng-container *ngIf="transfers.length"> -->
  <greco-table [highlight]="true" [loading]="loading" [data]="transfers">
    <!-- (rowClick)="openContact($event)" -->
    <mat-icon *grecoTableCol="''; let transfer; fitContent: true" [matTooltip]="transfer.id">swap_horiz</mat-icon>

    <p *grecoTableCol="'Title'; let transfer" [matTooltip]="transfer.title">{{ transfer.title }}</p>

    <p *grecoTableCol="'Trigger'; let transfer" [matTooltip]="transfer.triggerEvents">
      {{ transfer | grecoVariantTransferTrigger }}
    </p>

    <p
      *grecoTableCol="'Amount'; let transfer; alignRight: true"
      [matTooltip]="(transfer.amount / 100 | currency) || ''"
    >
      {{ transfer.sourceAccount.id === product?.community?.financeAccount?.id ? 'Pay ' : 'Receive ' }}
      <strong>{{ transfer.amount / 100 | currency }}</strong>
    </p>

    <p *grecoTableCol="'Account'; let transfer">
      {{
        transfer.sourceAccount.id === product?.community?.financeAccount?.id
          ? transfer.destinationAccount.name
          : transfer.sourceAccount.name
      }}
    </p>

    <ng-container *ngIf="variant?.recurrence?.frequency">
      <p *grecoTableCol="'Reverse & Proration Behaviour'; let transfer">
        {{
          $any({
            NEVER: 'Never',
            ALWAYS: 'Always',
            CUSTOMER_REFUNDED: 'When Customer Refunded'
          })[transfer.prorateTrigger]
        }}
      </p>
    </ng-container>

    <p *grecoTableCol="'Apply Royalties'; let transfer; alignCenter: true">
      <mat-checkbox disabled [checked]="transfer.applyRoyalties"></mat-checkbox>
    </p>

    <ng-container *grecoTableCol="''; let transfer; fitContent: true; stickyEnd: true">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu>
        <button mat-menu-item (click)="remove(transfer)">
          <mat-icon>delete</mat-icon>
          <span>Remove</span>
        </button>
      </mat-menu>
    </ng-container>
  </greco-table>

  <mat-paginator
    *ngIf="pagination?.totalItems"
    showFirstLastButtons
    [pageSizeOptions]="[10, 20, 50]"
    [length]="pagination!.totalItems || 0"
    [pageSize]="pagination!.itemsPerPage || 10"
    style="border-top: 1px solid var(--border-color, lightgrey)"
    (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
  <!-- </ng-container> -->
</ng-container>
