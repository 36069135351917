<div
  style="width: 100%; background-color: #f5f5f5; min-height: 96px; border-radius: 12px"
  *grecoLet="isMobile$ | async as isMobile"
>
  <div style="padding: 12px; display: flex; gap: 12px" *grecoLet="booking.user as user">
    <greco-user-avatar *ngIf="!isMobile" [user]="user" [size]="'large'" [showOnlineStatus]="false"></greco-user-avatar>

    <div style="display: flex; flex-grow: 1">
      <div style="display: flex; flex-direction: column; justify-content: space-between; flex-grow: 1">
        <div style="display: flex; flex-direction: row-reverse; width: 100%; justify-content: space-between">
          <mat-icon *ngIf="!isMobile" class="status-icon">
            {{ booking.status === 'PENDING' ? 'lock_clock' : 'event_available' }}
          </mat-icon>

          <h3
            style="margin: 0; flex-grow: 1; font-size: 16px; z-index: 1; display: flex; align-items: center; gap: 8px"
            [style.margin-bottom]="isMobile ? '4px' : '0'"
          >
            <greco-user-avatar
              *ngIf="isMobile"
              class="avatar-mobile"
              [user]="user"
              [size]="'small'"
              [showOnlineStatus]="false"
            ></greco-user-avatar>
            <span>
              Booking for <strong>{{ user?.friendlyName || user?.displayName }}</strong>
            </span>
          </h3>
        </div>

        <!-- Info Buttons -->
        <div
          style="
            display: flex;
            gap: 8px;
            align-items: center;
            flex-direction: row;
            margin-top: 4px;
            flex-grow: 1;
            flex-wrap: wrap;
          "
        >
          <button
            mat-flat-button
            class="info-button"
            style="pointer-events: none"
            [disabled]="true"
            [style.width]="isMobile ? '100%' : 'auto'"
          >
            <mat-icon [style.color]="booking.status === 'PENDING' ? 'orange' : 'var(--success-color)'">
              {{ booking.status === 'PENDING' ? 'lock_clock' : 'event_available' }}
            </mat-icon>
            {{
              booking.status === 'CONFIRMED'
                ? 'Booked'
                : booking.status === 'PENDING'
                ? 'Pending Confirmation'
                : booking.status === 'CHECKED_IN'
                ? 'Checked-In'
                : 'Booked'
            }}
          </button>

          <button
            *ngIf="booking.bookingOption"
            mat-flat-button
            class="info-button"
            style="pointer-events: none"
            [disabled]="true"
            [style.width]="isMobile ? '100%' : 'auto'"
          >
            <mat-icon [style.color]="booking.bookingOption.badge.color">local_activity</mat-icon>

            <div
              *grecoLet="booking.useBookedByPerks ? booking.bookedBy : booking.user as optionUser"
              style="padding: 2px 0"
            >
              <p style="margin-bottom: 0; line-height: 16px; text-wrap: wrap; text-align: start">
                {{ booking.bookingOption.title }}
              </p>
              <p *ngIf="booking.user.id !== event.accounts[0].user.id" style="margin-bottom: 0; line-height: 16px">
                <small style="display: flex; align-items: center; gap: 4px; justify-content: center">
                  {{
                    isStaffView || optionUser?.id !== event.accounts![0].user.id
                      ? (optionUser?.friendlyName || optionUser?.displayName) + "'s"
                      : 'Your'
                  }}
                  Perk
                  <mat-icon style="width: 16px; height: 16px; font-size: 16px; line-height: 16px">
                    {{ optionUser?.id !== event.accounts![0].user.id ? 'supervisor_account' : 'swap_horizontal' }}
                  </mat-icon>
                </small>
              </p>
            </div>
          </button>

          <div
            [matTooltip]="
              !event.requirements.spotBooking?.spotBookingEnabled
                ? 'Spot booking is disabled for this event. Staff will assign spots for you shortly.'
                : ''
            "
          >
            <button
              *ngIf="event.requirements.spotBooking?.room"
              mat-flat-button
              class="info-button"
              [style.width]="isMobile ? '100%' : 'auto'"
              [style.pointer-events]="event.requirements.spotBooking?.spotBookingEnabled ? 'all' : 'none'"
              (click)="bottomSheet.open(spotSelectionSheet, { panelClass: 'bottom-sheet' })"
            >
              <mat-icon>person_pin</mat-icon>
              <span>{{ booking.spot?.name || 'Unassigned Spot' }}</span>

              <mat-icon
                *ngIf="event.requirements.spotBooking?.spotBookingEnabled"
                style="padding-left: 8px; margin: 0 -2px 0 2px; border-left: 1px solid lightgray"
              >
                edit
              </mat-icon>

              <mat-icon
                *ngIf="!event.requirements.spotBooking?.spotBookingEnabled"
                style="padding-left: 8px; margin: 0 -2px 0 2px; border-left: 1px solid lightgray"
              >
                lock
              </mat-icon>
            </button>
          </div>

          <ng-template #spotSelectionSheet>
            <div class="header">
              <h2 #header style="margin-bottom: 0">Select a Spot</h2>

              <button mat-icon-button (click)="bottomSheet.dismiss()">
                <mat-icon>close</mat-icon>
              </button>
            </div>

            <alt-room-spot-picker
              [event]="event"
              [selectedSpotId]="booking.spot?.id"
              (spotSelected)="updateSelectedSpot($event)"
            ></alt-room-spot-picker>

            <button
              *ngIf="selectedSpot?.spotId !== initialSpotId"
              mat-flat-button
              class="confirm"
              color="primary"
              (click)="updateSpot(booking); bottomSheet.dismiss()"
            >
              Confirm Selection
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- Card Body -->
  <div class="alert-container"></div>

  <!-- Card Footer -->
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding: 12px;
      gap: 4px;
      border-top: 2px dashed rgba(var(--primary-color-rgb), 0.15);
    "
  >
    <alt-cancellation-policy
      *ngIf="booking.bookingOption"
      [event]="event"
      [interactive]="true"
      [bookingOption]="$any(booking.bookingOption)"
    ></alt-cancellation-policy>

    <div class="card-icon" style="flex-grow: 1">
      <ng-container *ngIf="!isStaffView; else staffActionButtons" [ngTemplateOutlet]="userActionButtons"></ng-container>
    </div>
  </div>
</div>

<ng-template #userActionButtons>
  <div class="action-buttons">
    <button
      *ngIf="
        event.security?.canCancel &&
        (booking.status === 'PENDING' || booking.status === 'CONFIRMED') &&
        moment(booking.event.endDate).isAfter(now)
      "
      mat-flat-button
      color="warn"
      style="background-color: var(--warn-color) !important"
      (click)="cancel(false)"
      [disabled]="confirming"
    >
      {{
        booking.bookingOption.cancellation &&
        moment(booking.event.endDate).subtract(booking.bookingOption.cancellationWindow, 'minutes').isBefore(now)
          ? 'Late '
          : ''
      }}Cancel
    </button>

    <button
      *ngIf="
        (booking.status === 'CONFIRMED' || booking.status === 'CHECKED_IN') &&
        moment(booking.event.endDate).isBefore(now)
      "
      mat-stroked-button
      routerLink="/workouts-new"
      [queryParams]="{ tags: (booking.event.tags | createQueryParamFilters) }"
    >
      Book Again
      <mat-icon color="accent">restart_alt</mat-icon>
    </button>

    <button
      mat-stroked-button
      *ngIf="
        (booking.status === 'CONFIRMED' || booking.status === 'CHECKED_IN') &&
        (booking.event.zoomMeetingId || booking.event.zoomEvent?.meetingID) &&
        moment(booking.event.startDate).subtract(10, 'minutes').isBefore(now) &&
        moment(booking.event.startDate).add(10, 'minutes').isAfter(now)
      "
      (click)="$event.stopImmediatePropagation(); join(booking)"
    >
      Join <mat-icon color="primary">videocam</mat-icon>
    </button>

    <greco-add-booking-to-calendar-button
      [bookingId]="booking.id"
      *ngIf="booking.status === 'CONFIRMED' && moment(booking.event.startDate).isAfter(now)"
    ></greco-add-booking-to-calendar-button>
  </div>
</ng-template>

<ng-template #staffActionButtons>
  <div class="card-icon" *ngIf="event.security?.canCancel || event.security?.canCancelFreeOfCharge">
    <button #menuTrigger="matMenuTrigger" mat-icon-button [matMenuTriggerFor]="staffMenu" [disabled]="confirming">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <mat-menu #staffMenu="matMenu" xPosition="before">
    <button
      [disabled]="confirming"
      mat-menu-item
      *ngIf="
        event.security?.canCancel &&
        (booking.status === 'PENDING' || booking.status === 'CONFIRMED') &&
        moment(booking.event.endDate).isAfter(now)
      "
      (click)="$event.stopImmediatePropagation(); cancel(false)"
    >
      Cancel
    </button>

    <button
      *ngIf="
        event.security?.canCancelFreeOfCharge &&
        (booking.status === 'PENDING' || booking.status === 'CONFIRMED') &&
        moment(booking.event.endDate).isAfter(now)
      "
      mat-menu-item
      color="warn"
      (click)="$event.stopImmediatePropagation(); cancel(true)"
      [disabled]="confirming"
    >
      Cancel (Free of Charge)
    </button>
  </mat-menu>
</ng-template>
