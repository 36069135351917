<div class="button-bar" style="width: 100%">
  <ng-container *grecoLet="userConditionContext$ | async">
    <ng-container *grecoLet="availablityExtension$ | async">
      <ng-container *grecoLet="linkedAccounts$ | async as linkedAccounts">
        <div
          *grecoLet="conditionCanBuy$ | async as conditionCanBuy"
          [matTooltip]="conditionCanBuy?.result !== true ? (conditionCanBuy?.errors || [] | grecoJoin : ', ') : ''"
        >
          <!-- <button mat-stroked-button (click)="addToCart(select.value)">Add to Cart</button> -->
          <button
            style="width: 50%"
            mat-flat-button
            color="primary"
            [disabled]="conditionCanBuy?.result !== true || disabled"
            (click)="buyNow(selectedVariant)"
          >
            <mat-icon *ngIf="conditionCanBuy?.result !== true">lock</mat-icon>
            Buy Now
          </button>

          <greco-linked-account-buy-button
            style="width: 40%"
            *ngIf="linkedAccounts?.length"
            [userId]="userId"
            [selectedVariant]="selectedVariant"
            [linkedAccounts]="linkedAccounts || []"
            [conditionCanBuy]="conditionCanBuy?.result !== true || disabled"
          ></greco-linked-account-buy-button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
