<form [formGroup]="form">
  <div style="display: flex; margin-top: -16px">
    <!-- Color -->
    <div matPrefix style="margin-bottom: 8px; position: relative">
      <mat-form-field style="width: 48px" appearance="standard">
        <input #colorInput matInput type="color" required formControlName="color" />
        <mat-icon
          style="
            position: absolute;
            top: 11px;
            left: 4px;
            width: 100%;
            height: 100%;
            color: white;
            pointer-events: none;
          "
        >
          palette
        </mat-icon>
      </mat-form-field>
    </div>

    <!-- Title -->
    <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
      <mat-label>Title</mat-label>
      <input matInput required formControlName="title" />
    </mat-form-field>
  </div>

  <!-- Icon -->
  <mat-form-field appearance="standard">
    <mat-label style="margin: 0 8px">Icon</mat-label>

    <div style="display: flex; gap: 4px">
      <mat-icon>{{ form.value.icon }}</mat-icon>
      <input matInput formControlName="icon" />
    </div>
  </mat-form-field>

  <a href="https://fonts.google.com/icons" target="_blank">
    <span style="font-size: x-small">Icons Reference</span>
  </a>

  <!-- Description -->
  <mat-form-field style="margin-top: 8px">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description" rows="3"></textarea>
  </mat-form-field>

  <!-- Tags -->
  <greco-tags-input
    [required]="true"
    formControlName="tags"
    [communityId]="bookingOption?.community?.id || null"
  ></greco-tags-input>

  <!-- Available to Everyone -->
  <div style="margin-top: 16px">
    <mat-checkbox formControlName="availableToEveryone" color="primary">Available to everyone</mat-checkbox>
  </div>

  <!-- Is Course Option -->
  <div style="margin-top: 16px">
    <mat-checkbox formControlName="isCourseOption" color="primary">Is course option</mat-checkbox>
  </div>
</form>
