import { Pipe, PipeTransform } from '@angular/core';
import { RRule } from 'rrule';
@Pipe({ name: 'recurrenceSplit' })
export class RecurrenceSplitPipe implements PipeTransform {
  transform(recurrence: string[]) {
    const days = new Array(7).fill(null).map(() => [] as RRule[]);

    (recurrence || []).forEach(string => {
      const rule = RRule.fromString(string);
      days[rule.options.byweekday[0]].push(rule);
    });

    return days.filter(day => !!day.length);
  }
}
