<ng-container *grecoLet="canRead$ | async as canRead">
  <ng-container *grecoLet="canUpdate$ | async as canUpdate">
    <greco-collapsible-section
      id="agreements_section"
      [header]="{ title: 'Agreements', icon: 'assignment' }"
      [expanded]="false"
      *ngIf="canRead"
    >
      <button
        header
        mat-stroked-button
        color="primary"
        style="height: 32px; line-height: 16px; margin-left: 16px"
        (click)="linkAgreement(); $event.stopImmediatePropagation()"
        *ngIf="canUpdate"
      >
        <span>Add Agreement</span>
      </button>

      <greco-filter-bar
        *ngIf="canRead"
        [hideSeparator]="true"
        [filterOptions]="filterOptions$"
        (changed)="filters$.next($event[1]); onFilterApplied()"
        style="margin-left: -20px; margin-right: -20px"
      >
      </greco-filter-bar>

      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

      <ng-container *ngIf="canRead" style="margin-left: -20px; margin-right: -20px">
        <greco-table
          [data]="(eventCommAgrs$ | async) || []"
          [highlight]="true"
          #agreementsTable
          style="margin-left: -20px; margin-right: -20px"
        >
          <mat-icon *grecoTableCol="''; fitContent: true; let agreement" [matTooltip]="agreement.id"
            >event_note</mat-icon
          >

          <p *grecoTableCol="'Name'; let agreement" matTooltip="Agreement Title">
            <strong>{{ agreement.agreement.title }}</strong>
          </p>

          <p *grecoTableCol="'Created Date'; let agreement; sorting: 'created'" matTooltip="Date Agreement was Created">
            {{ agreement.created | date : 'longDate' }}
          </p>

          <p *grecoTableCol="'Type'; let agreement" [matTooltip]="'Agreement acceptance by'">
            <span [ngSwitch]="agreement.agreement.agreementType">
              <mat-chip *ngSwitchCase="'CHECKBOX'">
                <mat-icon>check_box_outline_blank</mat-icon> <span>Checkbox</span>
              </mat-chip>

              <mat-chip *ngSwitchCase="'AUTO_CHECKBOX'">
                <mat-icon>check_box</mat-icon> <span>Auto Checkbox</span>
              </mat-chip>

              <mat-chip *ngSwitchCase="'DIGITAL_SIGNATURE'">
                <mat-icon>edit</mat-icon> <span>Signature</span>
              </mat-chip>
            </span>
          </p>

          <ng-container *ngIf="canUpdate">
            <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let agreement">
              <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu>
                <button
                  mat-menu-item
                  matTooltip="Remove Agreement Usage"
                  matTooltipClass="mt3"
                  (click)="deleteAgreementUsage(agreement.id)"
                >
                  <mat-icon>delete</mat-icon>
                  Remove
                </button>
                <button
                  mat-menu-item
                  matTooltip="Preview Agreement"
                  matTooltipClass="mt3"
                  (click)="viewAgreement(agreement.agreementId)"
                >
                  <mat-icon>visibility</mat-icon>
                  Preview
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </greco-table>

        <mat-paginator
          *ngIf="paginationMeta?.totalItems"
          showFirstLastButtons
          [length]="paginationMeta!.totalItems || 0"
          [pageSize]="paginationMeta!.itemsPerPage || 10"
          [pageSizeOptions]="[10, 20, 50]"
          (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
        ></mat-paginator>
      </ng-container>
    </greco-collapsible-section>
  </ng-container>
</ng-container>
