<ng-container *ngIf="saleCategory && formGroup">
  <greco-collapsible-section
    [header]="{
    title:'Category Details',
    icon: 'category'
  }"
  >
    <greco-form-save-buttons
      header
      [form]="formGroup"
      [resetValue]="resetValue"
      [saveAction]="save"
      (formReset)="resetDetails()"
    ></greco-form-save-buttons>
    <form [formGroup]="formGroup">
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Category Label</mat-label>
        <input matInput formControlName="label" />
      </mat-form-field>

      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>General Ledger Code</mat-label>
        <input matInput formControlName="generalLedgerCode" />
      </mat-form-field>

      <!-- Staff Picker -->
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Grant Access</mat-label>
        <mat-chip-list #chipList aria-label="Staff selection">
          <mat-chip *ngFor="let staff of staffAssignments" (removed)="removeStaff(staff)">
            <div style="width: 24px; height: 24px; margin-right: 8px; margin-left: -4px">
              <greco-user-avatar
                [showOnlineStatus]="false"
                [user]="staff"
                style="width: 24px; height: 24px"
              ></greco-user-avatar>
            </div>
            <ng-container *ngIf="staff.displayName; else noDisplayName"> {{ staff.displayName }} </ng-container>
            <ng-template #noDisplayName>{{ staff.email }}</ng-template>
            <button mat-icon-button matChipRemove style="margin-top: -18px" (click)="formGroup.markAsDirty()">
              <mat-icon style="width: 18px; height: 18px; line-height: 18px; font-size: 18px">cancel</mat-icon>
            </button>
          </mat-chip>
          <input
            placeholder="Find user to add.."
            #staffInput
            [formControl]="staffCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedStaff($event)">
          <mat-option *ngFor="let user of filteredStaff | async" [value]="user">
            <div style="display: flex; align-items: center">
              <div style="width: 24px; height: 24px; margin-right: 8px">
                <greco-user-avatar
                  [showOnlineStatus]="false"
                  [user]="user"
                  style="width: 24px; height: 24px"
                ></greco-user-avatar>
              </div>
              <div>
                <ng-container *ngIf="user.displayName; else noDisplayName">
                  {{ user.displayName }} <small>({{ user.email }})</small>
                </ng-container>
                <ng-template #noDisplayName>{{ user.email }}</ng-template>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
        <mat-hint
          >Access granted here makes this sale category available to the person according to their role
          assignments.</mat-hint
        >
      </mat-form-field>
    </form>
  </greco-collapsible-section>
</ng-container>
