import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogData } from '@greco-fit/scaffolding';

@Component({
  selector: 'greco-error-dialog-page',
  templateUrl: 'error.dialog.html'
})
export class ErrorDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorDialogData, public dialogRef: MatDialogRef<ErrorDialog>) {}

  close(dialogResult?: any) {
    this.dialogRef.close(dialogResult);
  }
}
