import { Account } from '@greco/finance-accounts';
import { User } from '@greco/identity-users';

export interface PaymentMethod {
  id: string;
  gateway: string;
  model: string;
  type: string;
  label: string;
  details?: string;
  cardHolderName?: string;

  externalId: string;
  externalData: null | any;

  created: Date;
  modified: Date;
}

export interface UserPaymentMethod extends PaymentMethod {
  userId: string;
  user: User;
}

export interface AccountPaymentMethod extends PaymentMethod {
  financeAccountId: string;
  financeAccount: Account;
}

//stripe types
//card -> amex, cartes_bancaires, diners_club, discover, jcb, mastercard, visa, unionpay
//bank
