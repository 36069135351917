import { Component, Inject, Input, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { CommunityService } from '../../services';

@Component({
  selector: 'greco-join-community',
  templateUrl: './join-community.dialog.html',
  styleUrls: ['./join-community.dialog.scss'],
})
export class JoinCommunityDialog {
  @Input() googleMapsKey = 'AIzaSyBxVmVtXTm4_SjzgaB7KYrruNE7FlR8jeI';
  @Input() community!: Community;

  dialogData: DialogData | null = null;

  subscribe = async () => {
    const userId = (await toPromise(this.userSvc.user$))?.id;
    if (userId) {
      const result = await this.contactsSvc.subscribeUserToCommunity(this.community.id, userId);
      if (result) {
        const subscriptions = await this.contactsSvc.getUserContacts(userId);
        if (!subscriptions.length && this.community.browsable)
          /* TODO(adaoust): Replace with config about whether community leverages the onboarding form. */
          await this.router.navigate(['welcome', this.community.id]);
        else this.dialogRef.close(result);
      } else this.dialogRef.close(result);
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    private data: { communityId: string },
    private communitySvc: CommunityService,
    private contactsSvc: ContactService,
    private dialogRef: MatDialogRef<JoinCommunityDialog>,
    private userSvc: UserService,
    private router: Router
  ) {
    if (this.data.communityId) {
      this.communitySvc.getCommunity(data.communityId).then(community => {
        this.community = community;
        this.dialogData = {
          title: this.community?.name,
          contentInParagraph: false,
          buttons: [
            {
              label: 'Cancel',
              role: 'cancel',
            },
            {
              label: 'Subscribe',
              role: 'subscribe',
              resultFn: () => this.subscribe(),
            },
          ],
        };
      });
    }
  }
}
