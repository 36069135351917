import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ArrayUtils } from '@greco-fit/util';
import { SaleCategoryService } from '@greco/ngx-sales-purchases';
import { SecurityService } from '@greco/ngx-security-util';
import { Product, ProductStatus, ProductVariant, ProductVariantVisibility } from '@greco/sales-products';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ProductsService } from '../../services';

@Component({
  selector: 'greco-product-variant-picker',
  templateUrl: './product-variant-picker.component.html',
  styleUrls: ['./product-variant-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ProductVariantPickerComponent }],
})
export class ProductVariantPickerComponent
  implements
    MatFormFieldControl<ProductVariant | ProductVariant[] | Product | Product[]>,
    ControlValueAccessor,
    AfterViewInit,
    OnInit,
    OnDestroy
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private productSvc: ProductsService,
    private saleCategorySvc: SaleCategoryService,
    private _elementRef: ElementRef,
    private securitySvc: SecurityService
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'greco-product-variant-picker';

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  stateChanges = new Subject<void>();

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `${ProductVariantPickerComponent._controlType}-${ProductVariantPickerComponent._id++}`;
  readonly controlType = ProductVariantPickerComponent._controlType;

  @Input() selectionsView = false;
  @Input() multiselect = false;
  @Input() onlyProducts = false;
  @Input() showAllVariants = false;
  @Input() showRestrictedVariants = true;
  @Input() hideDrafts = false;
  @Input() private onlyRecurring = false;
  @Input() private productIdsToExclude: string[] = [];
  @Input() private variantIdsToExclude: string[] = [];

  @Output() selectedVariantChange = new EventEmitter<ProductVariant>();
  @Output() selectedValueChange = new EventEmitter<string[] | null>();

  private _communityId$ = new BehaviorSubject<string | null>(null);
  searchQuery$ = new BehaviorSubject<string>('');

  allProducts$ = combineLatest([this._communityId$, this.saleCategorySvc.getManySecured()]).pipe(
    switchMap(async ([communityId, saleCategories]) => {
      if (!communityId) return null;

      const filter = RequestQueryBuilder.create({
        search: {
          $and: [
            {
              ...(this.onlyRecurring && { 'variants.recurrenceFrequency': { $gt: 0 } }),
              ...(this.productIdsToExclude.length && { id: { $notin: this.productIdsToExclude } }),
            },
            {
              status: {
                $in: this.showAllVariants
                  ? this.hideDrafts
                    ? [ProductStatus.Active, ProductStatus.Archived]
                    : []
                  : [ProductStatus.Active],
              },
            },
          ],
        },
      });

      return await this.productSvc.paginateProducts(
        filter,
        communityId,
        { limit: 100 },
        saleCategories.map(sc => sc.id)
      );
    }),
    map(result =>
      result?.items?.map(item => {
        item.variants = item.variants
          .filter(
            variant =>
              !this.variantIdsToExclude.includes(variant.id) &&
              (!this.onlyRecurring || variant.recurrence?.frequency) &&
              (this.showAllVariants
                ? this.hideDrafts
                  ? variant.status !== ProductStatus.Draft
                  : true
                : variant.status === ProductStatus.Active) &&
              (this.showRestrictedVariants
                ? true
                : variant.visibility === ProductVariantVisibility.VISIBLE ||
                  variant.visibility === ProductVariantVisibility.HIDDEN)
          )
          .sort((a, b) => {
            if (a.status === b.status) {
              if (a.priority === b.priority) return a.id.localeCompare(b.id);
              else {
                return a.priority < b.priority ? 1 : -1;
              }
            }

            if (a.status === ProductStatus.Archived) return 1;
            if (a.status === ProductStatus.Draft) return b.status === ProductStatus.Active ? -1 : 1;

            return -1;
          });

        return item;
      })
    ),
    map(products => {
      const grouped = ArrayUtils.groupBy(products || [], product => product.saleCategory?.label || 'No Sales Category');
      const arr = Object.entries(grouped).map(([name, products]) => {
        return { name, products };
      });
      return arr;
    }),
    shareReplay(1)
  );

  products$ = combineLatest([this._communityId$, this.searchQuery$, this.saleCategorySvc.getManySecured()]).pipe(
    switchMap(async ([communityId, searchQuery, saleCategories]) => {
      if (!communityId) return null;
      const filter = RequestQueryBuilder.create({
        search: {
          $and: [
            {
              ...(this.onlyRecurring && { 'variants.recurrenceFrequency': { $gt: 0 } }),
              ...(this.productIdsToExclude.length && { id: { $notin: this.productIdsToExclude } }),
            },
            {
              $or: ['description', 'title'].map(property => ({
                [property]: {
                  [CondOperator.CONTAINS_LOW]: searchQuery,
                },
              })),
            },
            {
              status: {
                $in: this.showAllVariants
                  ? this.hideDrafts
                    ? [ProductStatus.Active, ProductStatus.Archived]
                    : []
                  : [ProductStatus.Active],
              },
            },
          ],
        },
      });

      return await this.productSvc.paginateProducts(
        filter,
        communityId,
        { limit: 100 },
        saleCategories.map(sc => sc.id)
      );
    }),
    map(result =>
      result?.items?.map(item => {
        item.variants = item.variants
          .filter(
            variant =>
              !this.variantIdsToExclude.includes(variant.id) &&
              (!this.onlyRecurring || variant.recurrence?.frequency) &&
              (this.showAllVariants
                ? this.hideDrafts
                  ? variant.status !== ProductStatus.Draft
                  : true
                : variant.status === ProductStatus.Active) &&
              (this.showRestrictedVariants
                ? true
                : variant.visibility === ProductVariantVisibility.VISIBLE ||
                  variant.visibility === ProductVariantVisibility.HIDDEN)
          )
          .sort((a, b) => {
            if (a.status === b.status) return a.id.localeCompare(b.id);

            if (a.status === ProductStatus.Archived) return 1;
            if (a.status === ProductStatus.Draft) return b.status === ProductStatus.Active ? -1 : 1;

            return -1;
          });
        return item;
      })
    ),
    shareReplay(1)
  );

  productsAndCategory$ = this.products$.pipe(
    map(products => {
      const filteredProducts = products?.filter(product => product.variants.length);
      const noCategory = '<i>None</i>';
      const grouped = ArrayUtils.groupBy(filteredProducts || [], product => product.saleCategory?.label || noCategory);
      return Object.entries(grouped)
        .map(([name, products]) => ({
          name,
          products: products.map(ps => {
            ps.variants = ps.variants.sort((a, b) => {
              return a.priority < b.priority ? 1 : -1;
            });
            return ps;
          }),
        }))
        .sort((a, b) => {
          if (a.name === noCategory) return 1;
          if (b.name === noCategory) return -1;
          return a.name.localeCompare(b.name);
        });
    }),
    shareReplay(1)
  );

  @Input() get communityId() {
    return this._communityId$.value;
  }
  set communityId(communityId: string | null) {
    this._communityId$.next(communityId);
  }

  private _value: ProductVariant | ProductVariant[] | Product | Product[] | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: ProductVariant | ProductVariant[] | Product | Product[] | null) {
    this._value = value;
    if (this._input) {
      if (!(value instanceof Array)) {
        const title = value?.title ? value.title : this.makeTitle(value as ProductVariant);
        this._input.value = title || this._input.value;
        this.searchQuery$.next(value ? '' : this._input.value);
      }
    }

    if (value instanceof Array) {
      if (value.length && value[0].id.substring(0, 5) === 'prod' && !this.selectedProductIds.length) {
        value.forEach(product => this.addToSelectedProducts(product as Product));
      } else if (value.length && value[0].id.substring(0, 7) === 'prodvrt' && !this.selectedVariantIds.length) {
        value.forEach(v => {
          const variant = v as ProductVariant;
          if (variant.product) this.addToSelectedVariants(variant.product, variant as ProductVariant);
        });
        this.emitValue();
      }
    }

    this._onChange?.(value);
    this.stateChanges.next();
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || !!this.value || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return (
      !!this.ngControl?.touched &&
      (!!Object.keys(this.ngControl?.errors || {}).length || (this.required && !this.value))
    );
  }

  activeStatus = ProductStatus.Active;
  justProducts$ = this.searchQuery$.pipe(map(value => !!value));
  selectedVariants: ProductVariant[] = [];
  selectedVariantIds: string[] = [];
  selectedProducts: Product[] = [];
  selectedProductIds: string[] = [];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  ngOnInit() {
    // TODO(adaoust): Other use cases should also pre-fill the input.
    if (this.onlyProducts) {
      const products = this.value as any;
      products.forEach((product: any) => {
        this.select(product, null);
      });
    }
  }

  select(product: Product, variant: ProductVariant | null) {
    if (!this.multiselect) {
      this.onlyProducts ? (this.value = product) : (this.value = variant);
      this.stateChanges.next();
    } else {
      if (this.onlyProducts) {
        this.selectedProductIds.includes(product.id)
          ? this.removeFromSelectedProducts(product)
          : this.addToSelectedProducts(product);
      } else {
        if (variant) {
          this.selectedVariantIds.includes(variant.id)
            ? this.removeFromSelectedVariants(variant)
            : this.addToSelectedVariants(product, variant);
        }
      }
    }
  }

  addToSelectedVariants(product: Product, variant: ProductVariant) {
    if (!this.showAllVariants && variant.status !== this.activeStatus) return;

    if (!this.selectedVariantIds.includes(variant.id)) {
      if (!variant.product) variant.product = product;
      this.selectedVariants.push(variant);
      this.selectedVariantIds.push(variant.id);
      this.value = this.selectedVariants;

      if (product.variants && this.allVariantsInProduct(product) && !this.selectedProductIds.includes(product.id))
        this.selectedProductIds.push(product.id);
      this.emitValue();
    }
  }

  removeFromSelectedVariants(variant: ProductVariant) {
    const index = this.selectedVariantIds.findIndex(v => variant.id === v);
    this.selectedVariants.splice(index, 1);
    this.selectedVariantIds.splice(index, 1);
    this.value = this.selectedVariants;

    if (variant.product) {
      if (this.selectedProductIds.includes(variant.product.id)) {
        const index = this.selectedProductIds.findIndex(p => variant.product?.id === p);
        this.selectedProductIds.splice(index, 1);
      }
    }
    this.emitValue();
  }

  addToSelectedProducts(product: Product) {
    if (!this.selectedProductIds.includes(product.id)) {
      this.selectedProducts.push(product);
      this.selectedProductIds.push(product.id);
    }
    this.value = this.selectedProducts;
  }

  removeFromSelectedProducts(product: Product) {
    const index = this.selectedProductIds.findIndex(p => product.id === p);
    this.selectedProducts.splice(index, 1);
    this.selectedProductIds.splice(index, 1);
    this.value = this.selectedProducts;
  }

  toggleAllVariants(product: Product) {
    if (this.selectedProductIds.includes(product.id)) {
      product.variants.forEach(variant => this.removeFromSelectedVariants(variant));
      const index = this.selectedProductIds.findIndex(p => product.id === p);
      this.selectedProducts.splice(index, 1);
      this.selectedProductIds.splice(index, 1);
    } else {
      product.variants.forEach(variant => {
        if (!this.selectedVariantIds.includes(variant.id)) this.addToSelectedVariants(product, variant);
      });
    }

    this.emitValue();
  }

  makeTitle(variant: ProductVariant): string {
    if (variant) {
      if (variant.variantOpts.length > 1) {
        let title = variant.product?.title ? variant.product.title + ' - ' : '';
        variant.variantOpts.forEach(option => (title += option.value + ', '));
        return title.slice(0, -2);
      } else return variant.variantOpts[0].value;
    } else return '';
  }

  productIndeterminate(product: Product): boolean {
    if (!this.allVariantsInProduct(product)) return product.variants.some(v => this.selectedVariantIds.includes(v.id));
    else return false;
  }

  allVariantsInProduct(product: Product) {
    let addProduct = true;
    product.variants.forEach(v => {
      if (!this.selectedVariantIds.includes(v.id) && v.status === this.activeStatus) addProduct = false;
    });
    return addProduct;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.product-variant-picker-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: ProductVariant | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: ProductVariant | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  emitVariant(variant: ProductVariant) {
    this.selectedVariantChange.emit(variant);
  }

  emitValue() {
    if (this.value && this.value instanceof Array) {
      const ids: string[] = [];
      this.value.forEach(item => ids.push(item.id));
      this.selectedValueChange.emit(ids);
    }
  }
  clearSelections() {
    if (this.selectionsView) {
      this.value = null;
      this.searchQuery$.next('');
      this.selectedProductIds = [];
      this.selectedProducts = [];
      this.selectedVariantIds = [];
      this.selectedVariants = [];

      this.selectedValueChange.emit(null);
    }
  }

  ngAfterViewInit() {
    if (this._input && !this._input.value) {
      if (!(this.value instanceof Array)) {
        const title = this.value?.title ? this.value.title : this.makeTitle(this.value as ProductVariant);
        this._input.value = title || this._input.value;
      }
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.searchQuery$.complete();
    this._communityId$.complete();
  }
}
