import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@greco/identity-users';
import { CommunityService } from '@greco/ngx-identity-communities';
import { ContactService } from '@greco/ngx-identity-contacts';
import { PropertyListener } from '@greco/property-listener-util';
import { TileGroup } from '@greco/tiles';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TileFavorite, TilesService } from '../../services/tiles.service';

@Component({
  selector: 'greco-tiles-view-page',
  templateUrl: './tiles-view.page.html',
  styleUrls: ['./tiles-view.page.scss'],
})
export class TilesViewPage {
  constructor(
    private contactSvc: ContactService,
    private communitySvc: CommunityService,
    private router: Router,
    private tileSvc: TilesService
  ) {}

  @PropertyListener('user') user$ = new BehaviorSubject<User | undefined>(undefined);
  @Input() user?: User;
  @Input() tileGroup?: TileGroup | null;

  communities$ = this.user$.pipe(
    switchMap(async user => (user ? await this.contactSvc.getUserContacts(user.id) : [])),
    map(contacts => contacts.map(contact => contact.community)),
    switchMap(async communities =>
      communities.length ? communities : [await this.communitySvc.getCommunity('com_greco')]
    )
  );

  async refresh() {
    if (this.router.url.includes('favorites')) {
      const favoriteTilesArrayStr = localStorage.getItem('favoriteTilesArray');

      const favoriteTilesArray: TileFavorite[] = favoriteTilesArrayStr
        ? (JSON.parse(favoriteTilesArrayStr) as TileFavorite[])
        : [];
      if (!favoriteTilesArray.length) {
        //favorite tab has delay before it refreshes, wait until it is gone
        await new Promise(res => setTimeout(res, 750));
        //if still on route after tab disappears, reroute
        if (this.router.url.includes('favorites')) this.router.navigate(['']);
      }
    }
  }
}
