<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <!-- Old -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Current Password</mat-label>
      <input
        [type]="oldPasswordVisible ? 'text' : 'password'"
        matInput
        required
        formControlName="old"
        placeholder="Current Password"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="oldPasswordVisible = !oldPasswordVisible"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="oldPasswordVisible"
      >
        <mat-icon>{{ oldPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>
    </mat-form-field>
    <!-- New -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>New Password</mat-label>
      <input
        [type]="newPasswordVisible ? 'text' : 'password'"
        matInput
        required
        formControlName="new"
        placeholder="New Password"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="newPasswordVisible = !newPasswordVisible"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="newPasswordVisible"
      >
        <mat-icon>{{ newPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
      </button>
    </mat-form-field>

    <!-- Confirm -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Confirm Password</mat-label>
      <input
        [type]="newPasswordVisible ? 'text' : 'password'"
        matInput
        required
        formControlName="confirm"
        placeholder="Confirm Password"
      />
    </mat-form-field>
    <small><mat-error *ngIf="error">{{errorString}}</mat-error></small>
  </form>
</greco-simple-dialog>
<button mat-flat-button color="primary" (click)="submit()">Change Password</button>
