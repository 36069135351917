import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { ChangeProfilePictureDialog } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-user-expiring-payment-method-alert',
  templateUrl: './user-expiring-payment-method-alert.component.html',
  styleUrls: ['./user-expiring-payment-method-alert.component.scss'],
})
export class UserExpiringPaymentMethodAlertComponent {
  constructor(private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {}
  @Input() small = false;
  @Input() user!: User;
  @PropertyListener('paymentMethod') paymentMethod$ = new BehaviorSubject<PaymentMethod | null>(null);
  @Input() paymentMethod!: PaymentMethod;
  @Input() expired = false;
  @Input() expiresOn!: Date;

  @Output() changes = new EventEmitter();

  subtitle$ = this.paymentMethod$.pipe(
    map(paymentMethod => {
      if (!paymentMethod) return '';
      return paymentMethod.label + ' expires soon';
    })
  );

  async navigate() {
    await this.router.navigate(['/account/purchases'], { relativeTo: this.route });
  }

  async upload() {
    const dialog = this.dialog.open(ChangeProfilePictureDialog, { data: { user: this.user } });
    const response = await toPromise(dialog.afterClosed());
    if (response) this.changes.emit(response);
  }
}
