<mat-chip-list #chipList>
  <mat-chip *ngIf="value?.resource">
    <div style="display: flex; flex-direction: row">
      <div
        *ngIf="value?.resource?.photoURL || value?.resource?.type === 'PERSON'; else noPhoto"
        style="width: 24px; height: 24px; margin: 0px -2px 0px -7px"
      >
        <greco-smart-img [src]="value?.resource?.photoURL || 'assets/lf3/icon_square_pad.png'" [round]="true">
        </greco-smart-img>
      </div>
      <ng-template #noPhoto>
        <mat-icon style="margin-right: 4px; margin-left: -4px">
          {{
            value?.resource?.icon
              ? value?.resource?.icon
              : { PERSON: 'account_circle', ROOM: 'living', OTHER: 'texture', ZOOM: 'videocam' }[
                  value?.resource?.type + ''
                ]
          }}
        </mat-icon>
      </ng-template>
      <div *ngIf="value && value.resource" style="margin: -8px 0px 0px 8px">
        <greco-availability-status [availability]="availability[value.resource.id]" [large]="true">
        </greco-availability-status>
      </div>
      <span style="margin-top: 3px">{{ value?.resource?.name }}</span>
    </div>
    <mat-icon
      *ngIf="!disabled"
      style="font-size: 18px; color: gray; margin: 7px -12px 0px 8px"
      (click)="remove(); input.value = ''"
    >
      cancel
    </mat-icon>
  </mat-chip>

  <input
    #input
    matInput
    type="text"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [matChipInputFor]="chipList"
    [matMenuTriggerFor]="resources"
    (input)="input.value !== '' ? search$.next(input.value) : (value = null)"
    (blur)="touched()"
  />
</mat-chip-list>

<mat-menu #resources>
  <ng-container *grecoLet="resources$ | async as resources">
    <ng-container *ngIf="resources?.length; else noneFound">
      <button *ngFor="let resource of resources" mat-menu-item (click)="select(resource)">
        <div style="display: flex; flex-direction: row">
          <div *ngIf="resource?.photoURL; else noPhoto" style="width: 24px; height: 24px; margin: 6px 8px 0px 0px">
            <greco-smart-img [src]="resource?.photoURL || 'assets/lf3/icon_square_pad.png'" [round]="true">
            </greco-smart-img>
          </div>
          <ng-template #noPhoto>
            <mat-icon style="margin-top: 12px; margin-right: 12px">
              {{
                resource?.icon
                  ? resource?.icon
                  : { PERSON: 'account_circle', ROOM: 'living', OTHER: 'texture', ZOOM: 'videocam' }[
                      resource?.type + ''
                    ]
              }}
            </mat-icon>
          </ng-template>
          <div *ngIf="resource" style="margin: 8px 2px 0 -2px">
            <greco-availability-status style="position: absolute" [availability]="availability[resource.id]">
            </greco-availability-status>
          </div>
          <span>{{ resource?.name }}</span>
        </div>
      </button>
    </ng-container>
  </ng-container>
  <ng-template #noneFound>
    <button mat-menu-item [disabled]="true">
      <i>{{ value ? 'No other resources found' : 'No resources found' }}</i>
    </button>
  </ng-template>
</mat-menu>
