import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';
import { GrecoRoute } from './route';
import { buildRoute } from './utils';

@NgModule()
export class GrecoRouterModule {
  private constructor() {
    throw new Error();
  }

  static forRoot(routes: GrecoRoute[], config?: ExtraOptions) {
    return RouterModule.forRoot(
      routes?.map(route => buildRoute(route)),
      config
    );
  }

  static forChild(routes: GrecoRoute[]) {
    return RouterModule.forChild(routes?.map(route => buildRoute(route)));
  }
}
