import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExposedError, Reader, Terminal } from '@stripe/terminal-js';

@Component({
  templateUrl: './terminal-error.dialog.html',
  styles: [
    `
      mat-icon.processing {
        animation: spin 1s infinite linear;
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    `,
  ],
})
export class TerminalErrorDialog {
  processing: false | string = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { error: ExposedError; terminal: Terminal; reader: Reader },
    private dialogRef: MatDialogRef<TerminalErrorDialog>
  ) {
    console.log('TerminalErrorDialog', data);
  }

  async clearTerminal() {
    this.processing = 'clear';

    const connectedReader = this.data.terminal.getConnectedReader();
    if (!connectedReader || connectedReader.id !== this.data.reader.id) {
      if (connectedReader) {
        const disconnedted = await this.data.terminal.disconnectReader();
        if ('error' in disconnedted) {
          this.close(false);
          return;
        }
      }

      const connected = await this.data.terminal.connectReader(this.data.reader, { fail_if_in_use: false });
      if ('error' in connected) {
        this.close(false);
        return;
      }
    }

    // const cleared = await this.data.terminal.clearReaderDisplay();
    // if ('error' in cleared) {
    //   this.close(false);
    //   return;
    // }

    this.close(true);
  }

  close(retry = false) {
    this.dialogRef.close(retry);
    this.processing = false;
  }
}
