import { Injectable } from '@angular/core';
import { BuildDateFilter } from '@greco/ngx-filters';

@Injectable({ providedIn: 'any' })
export class PaymentDisputeDueDateFilter extends BuildDateFilter('PaymentDisputeDueDateFilter', {
  label: 'Payment Dispute Due Date',
  shortLabel: 'Due By',
  description: '',
  properties: ['evidanceSubmissionDueBy'],
}) {}
