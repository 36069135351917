<div style="position: sticky; top: 56px; z-index: 10">
  <greco-filter-bar
    #filterBar
    [calendars]="true"
    [hideSeparator]="true"
    [filterOptions]="filterOptions$"
    (changed)="filters$.next($event[1])"
    (loaded)="setInitialFilters(filterBar)"
    style="background-color: var(--background-color, white)"
  >
    <greco-calendar-picker
      calendarPicker
      style="height: 45px; margin-top: -36px; margin-right: 12px; pointer-events: all"
      [showCommunity]="true"
      [communityId]="community?.id"
      [calendarIdToAdd]="calendarIdToAdd"
      [initialCalendarIds]="initialCalendars"
      (refresh)="refresh()"
    ></greco-calendar-picker>

    <div style="display: flex; align-items: center; justify-content: flex-end; height: 32px">
      <mat-menu #eventsMenu>
        <p style="margin: 8px 16px">
          <small><b>Calendars</b></small>
        </p>

        <ng-container *ngFor="let calendar of calendarsList$ | async">
          <ng-container *ngIf="calendar.eventTemplates?.length">
            <button mat-menu-item [matMenuTriggerFor]="templateMenu">
              <mat-icon>{{ calendar.icon || 'calendar_today' }}</mat-icon> {{ calendar.title }}
            </button>
          </ng-container>

          <mat-menu #templateMenu>
            <p style="margin: 8px 16px">
              <small><b>Templates</b></small>
            </p>

            <ng-container *ngFor="let template of calendar.eventTemplates">
              <button mat-menu-item (click)="createEventFromTemplate(template.id, calendar.id)">
                {{ template.title }}
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>

        <button *ngIf="canCreateCustomEvents$ | async" mat-menu-item color="primary" (click)="createEvent()">
          <mat-icon style="margin-top: -2px; margin-right: 8px">add</mat-icon>
          Custom Event
        </button>

        <ng-container *ngIf="canExportEvents$ | async">
          <p style="margin: 8px 16px">
            <small><b>Export</b></small>
          </p>

          <button mat-menu-item color="primary" (click)="exportEvents()">
            <mat-icon style="margin-top: -2px; margin-right: 8px">file_download</mat-icon>
            Export Events
          </button>
        </ng-container>

        <p style="margin: 8px 16px">
          <small><b>Other Actions</b></small>
        </p>

        <button mat-menu-item color="primary" (click)="generateSchedule()">
          <mat-icon style="margin-top: -2px; margin-right: 8px; transform: rotate(135deg)">link</mat-icon>
          Schedule Link
        </button>
      </mat-menu>

      <div style="display: flex; flex-direction: row" *grecoLet="canCreateEvents$ | async as canCreate">
        <ng-container *grecoLet="canCreateCustomEvents$ | async as canCreateCustom">
          <button
            *ngIf="canCreateCustom"
            mat-stroked-button
            color="primary"
            style="height: 32px"
            [style.border-top-right-radius]="canCreate ? '0px' : ''"
            [style.border-bottom-right-radius]="canCreate ? '0px' : ''"
            (click)="createEvent()"
          >
            New Event
          </button>
          <button
            *ngIf="canCreate"
            mat-stroked-button
            style="height: 32px"
            [style.width]="canCreateCustom ? '38px' : ''"
            [style.min-width]="canCreateCustom ? '38px' : ''"
            [style.border-left]="canCreateCustom ? 'none' : ''"
            [style.border-top-left-radius]="canCreateCustom ? '0px' : ''"
            [style.border-bottom-left-radius]="canCreateCustom ? '0px' : ''"
            color="primary"
            [matMenuTriggerFor]="eventsMenu"
          >
            {{ canCreateCustom ? '' : 'New Event'}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </greco-filter-bar>
</div>

<ng-container *grecoLet="canUpdateEvents$ | async as canUpdateEvents">
  <ng-container *grecoLet="canCreateBookings$ | async as canCreateBookings">
    <ng-container *grecoLet="canCancelEvents$ | async as canCancelEvents">
      <ng-container *grecoLet="events$ | async as events">
        <greco-events-calendar
          *ngIf="canCreateEvents$ | async; else cantCreateEvents"
          [loading]="loading"
          [draggable]="true"
          [events]="events || []"
          [sortGroups]="SORT_GROUPS"
          [dayViewGroupBy]="GROUP_BY"
          [startingView]="STARTING_VIEW"
          [canCancelEvents]="canCancelEvents || false"
          [canUpdateEvents]="canUpdateEvents || false"
          [canCreateBookings]="canCreateBookings || false"
          [communityId]="community ? community.id : ''"
          [refreshCalendarEmitter]="refreshCalendarEmitter"
          (dateRange)="dates$.next($event)"
          (eventClicked)="openEvent($event)"
          (eventTimesChanged)="eventTimesChanged($event)"
          (dateClicked)="createEvent($event[0], $event[1])"
        ></greco-events-calendar>

        <ng-template #cantCreateEvents>
          <greco-events-calendar
            [loading]="loading"
            [events]="events || []"
            [sortGroups]="SORT_GROUPS"
            [dayViewGroupBy]="GROUP_BY"
            [startingView]="STARTING_VIEW"
            [canCancelEvents]="canCancelEvents || false"
            [canUpdateEvents]="canUpdateEvents || false"
            [canCreateBookings]="canCreateBookings || false"
            (eventClicked)="openEvent($event)"
            (dateRange)="dates$.next($event)"
          ></greco-events-calendar>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
