import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status',
})
export class SeriesStatusPipe implements PipeTransform {
  transform(endDate: Date): string {
    const nowDate = new Date();
    if (!endDate) return 'Active';
    if (endDate.getTime() >= nowDate.getTime()) return 'Active';
    else return 'Inactive';
  }
}
