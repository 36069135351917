<greco-simple-dialog [data]="dialogData">
  <ng-container *grecoLet="canUpdateLink$ | async as canUpdateLink">
    <ng-container *grecoLet="signedInUser$ | async as signedInUser">
      <form [formGroup]="formGroup">
        <div style="display: flex; flex-direction: column; gap: 8px">
          <mat-slide-toggle matInput color="primary" [checked]="true" [disabled]="true">
            {{view === 'accessor' ? 'Request' : 'Grant'}} Ability to book events on {{view === 'accessor' ? 'their' :
            'your'}} behalf
          </mat-slide-toggle>
          <mat-slide-toggle matInput color="primary" [checked]="true" [disabled]="true">
            {{view === 'accessor' ? 'Request' : 'Grant'}} Ability to make purchases on {{view === 'accessor' ? 'their' :
            'your'}} behalf
          </mat-slide-toggle>
          <mat-slide-toggle matInput color="primary" formControlName="usePerks">
            {{view === 'accessor' ? 'Request' : 'Grant'}} Ability to use {{view === 'accessor' ? 'their' : 'your'}}
            perks on {{view === 'accessor' ? 'their' : 'your'}} behalf
          </mat-slide-toggle>
        </div>
        <!--invisible input to stop scroll bar and have dialog scale properly because of mat checkboxes-->
        <input matInput type="email" style="width: 100%; visibility: hidden" />
      </form>
    </ng-container>
  </ng-container>
</greco-simple-dialog>
