import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { LetDirective } from './directives';
import { HighlightPipe } from './pipes/highlight.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { NgxFirestoreConnector } from './services';

@NgModule({
  imports: [CommonModule, AngularFirestoreModule],
  declarations: [OrdinalPipe, LetDirective, HighlightPipe],
  exports: [OrdinalPipe, LetDirective, HighlightPipe],
  providers: [NgxFirestoreConnector]
})
export class UtilModule {}
