import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { BookingOptionDetails, EventAccount, EventWithUserDetails, SpotDetails } from '@greco/booking-events2';
import { UserCommunityAgreement } from '@greco/community-agreements';
import { User } from '@greco/identity-users';
import { SignAgreementDialog } from '@greco/ngx-community-agreements';
import { PropertyListener } from '@greco/property-listener-util';
import { AlertConfig, AlertType } from '@greco/ui-alert';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'alt-booking-card2',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss'],
})
export class BookingCard2Component {
  constructor(
    private dialog: MatDialog,
    public eventSvc: EventService,
    public bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild('bottomSheetContent', { static: false }) bottomSheetContent!: TemplateRef<void>;

  @PropertyListener('event') event$ = new BehaviorSubject<EventWithUserDetails | null>(null);
  @Input() event!: EventWithUserDetails;

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;

  @PropertyListener('spotId') spotId$ = new BehaviorSubject<string | null>(null);
  @Input() spotId?: string;

  @PropertyListener('waitlist') waitlist$ = new BehaviorSubject<User[]>([]);
  @Input() waitlist: User[] = [];

  @PropertyListener('isStaffView') isStaffView$ = new BehaviorSubject<boolean>(true);
  @Input() isStaffView = true;

  @PropertyListener('canLeaveUnsigned') canLeaveUnsigned$ = new BehaviorSubject<boolean>(false);
  @Input() canLeaveUnsigned = true;

  @Input() readonly = false;
  @Input() bookingCount = 1;
  @Input() selectedBookingOption?: BookingOptionDetails;
  @Input() eventAccounts: EventAccount[] = [];
  @Input() altAccounts: EventAccount[] = [];
  @Input() confirming = false;
  @Input() boostersActivated = false;

  @Input() allowRemoval = true;

  selectedSpot: SpotDetails | null = null;

  everyoneText = '';

  $bottomSheetRef?: Subscription;

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));

  spot$ = combineLatest([this.event$, this.spotId$]).pipe(
    map(([event, spotId]) => {
      if (!event || !spotId) return null;

      return event.requirements?.spotBooking?.room?.spots?.find(s => s.id === spotId);
    })
  );

  isWaitlisted$ = combineLatest([this.user$, this.waitlist$]).pipe(
    map(([user, waitlist]) => waitlist.some(w => w.id === user?.id))
  );

  pageData$ = combineLatest([this.user$, this.event$, this.spotId$, this.isStaffView$, this.canLeaveUnsigned$]).pipe(
    map(([user, event, spotId, isStaffView, canLeaveUnsigned]) => ({
      user,
      bookedBy: event?.accounts[0],
      event,
      spotId,
      isStaffView,
      canLeaveUnsigned,
    })),
    tap(
      data =>
        (this.everyoneText =
          `A purchase is required to book this event. Drop-in perks can be used if you don't have any available perks to book this event. Confirm your booking to complete your purchase or <a href="mailto:` +
          data?.event?.communityEmail +
          '">contact us</a> for help.')
    )
  );

  unsignedAgreementAlerts$ = combineLatest([this.user$, this.eventSvc.agreementSubmissions$, this.event$]).pipe(
    map(([user, agreementSubmissions, event]) => {
      if (!user) return;

      const alerts: (AlertConfig & { agreementId: string })[] = [];

      agreementSubmissions.forEach(submission => {
        if (submission.userId !== user.id) return;

        if (submission.unsigned) {
          const agreement = event?.requirements.agreements?.find(agreement => agreement.id === agreement.id);
          if (submission.userId === event?.accounts[0].user.id) {
            alerts.push({
              agreementId: submission.agreementId,
              title: agreement?.label + ' is Unsigned',
              type: AlertType.DANGER,
              description: 'Please make sure all agreements are signed before continuing',
            });
          } else {
            alerts.push({
              agreementId: submission.agreementId,
              title: agreement?.label + ' is Unsigned',
              type: AlertType.WARN,
              description:
                'Please make sure ' +
                event?.accounts.find(account => account.user.id === submission.userId)?.user.displayName +
                ' signs all their agreements before continuing',
            });
          }
        }
      });

      return alerts;
    })
  );

  bookingErrors$ = combineLatest([
    this.pageData$,
    this.eventSvc.agreementSubmissions$,
    this.eventSvc.typeformSubmissions$,
    this.eventSvc.profilesCompleted$,
  ]).pipe(
    map(([pageData, agreementSubmissions, typeformSubmissions, profilesCompleted]) => {
      const { user, bookedBy, event, spotId, isStaffView, canLeaveUnsigned } = pageData;

      let warning = false;
      let error = false;

      if (!user || !event) {
        return {
          warning,
          error,
        };
      }

      // Profile Completion
      if (
        user.id === event.accounts[0].user.id &&
        !user.address?.line1 &&
        !profilesCompleted.some(profile => profile.userId === user.id && profile.completed)
      ) {
        if (isStaffView) {
          warning = true;
        } else {
          error = true;
        }
      } else if (
        user.id !== event.accounts[0].user.id &&
        !user.address?.line1 &&
        !profilesCompleted.some(profile => profile.userId === user.id && profile.completed)
      ) {
        warning = true;
      }

      // Agreements
      if (event.requirements.agreements?.length) {
        const hasAgreementsToSign = !agreementSubmissions.filter(submission => submission.userId === user.id).length;
        const agreementsToSign = agreementSubmissions.filter(submission => submission.userId === user.id);

        if (hasAgreementsToSign || !agreementsToSign.every(submission => submission.signed)) {
          canLeaveUnsigned || (user.id !== bookedBy?.user?.id && user.contactEmail !== bookedBy?.user?.contactEmail)
            ? (warning = true)
            : (error = true);
        }
      }

      // Spot Booking
      if (event.requirements.spotBooking?.spotBookingEnabled && event.requirements.spotBooking.room && !spotId) {
        error = true;
      }

      // Typeform
      if (
        event.requirements.forms?.length &&
        !event?.requirements?.forms?.every(
          form =>
            !form.required ||
            typeformSubmissions.some(submission => submission.formId === form.id && submission.userId === user.id)
        )
      ) {
        error = true;
      } else if (
        event.requirements.forms?.length &&
        event?.requirements?.forms?.some(
          form =>
            !form.required &&
            !typeformSubmissions.some(submission => submission.formId === form.id && submission.userId === user.id)
        )
      ) {
        warning = true;
      }

      return {
        warning,
        error,
      };
    })
  );

  swapBooking(next: EventAccount) {
    const accountWithBookingOptions = next.canUsePerks && next.bookingOptions.length ? next : this.event.accounts[0];
    let bookingOptions = accountWithBookingOptions.bookingOptions;

    if (accountWithBookingOptions.user.id !== next.user.id) {
      bookingOptions = bookingOptions.filter(option => option.transferable || option.transferableReusable);
    }

    this.eventSvc.swapBooking(this.user.id, {
      userId: next.user.id,
      eventId: this.event.id,
      bookingOptionId: bookingOptions[0]?.id || '',
      bookingOptionUserId: bookingOptions[0]?.userId || '',
    });
  }

  removeBooking() {
    this.eventSvc.removeBooking(this.user.id);
  }

  openRequirementRef(ref: TemplateRef<any>) {
    const bottomSheetRef = this.bottomSheet.open(ref, { panelClass: 'bottom-sheet' });

    this.$bottomSheetRef = bottomSheetRef.afterDismissed().subscribe(() => {
      this.bottomSheet.open(this.bottomSheetContent, { panelClass: 'bottom-sheet' });
      this.$bottomSheetRef?.unsubscribe();
    });
  }

  async signAgreement(agreement: UserCommunityAgreement) {
    const result = await toPromise(this.dialog.open(SignAgreementDialog, { data: agreement }).afterClosed());
    if (result) {
      const unsignedAgreements = this.eventSvc.unsignedAgreements$.value;
      this.eventSvc.unsignedAgreements$.next(
        unsignedAgreements.filter(unsignedAgreement => unsignedAgreement.id !== agreement.id)
      );
    }
  }

  updateSelectedSpot(spot: SpotDetails) {
    this.selectedSpot = spot;
  }

  async updateSpot() {
    if (!this.selectedSpot) return;

    const bookings = this.eventSvc.bookings$.value;
    const userBooking = bookings.find(booking => booking.userId === this.user?.id);
    if (!userBooking) return;

    userBooking.spotId = this.selectedSpot.spotId;

    const bookingIndex = bookings.findIndex(booking => booking.userId === this.user?.id);

    bookings[bookingIndex] = userBooking;
    this.eventSvc.bookings$.next([...bookings]);

    this.selectedSpot = null;
  }

  async leaveWaitlist() {
    try {
      await this.eventSvc.removeFromWaitlist(this.event.location.id, this.event.id, this.user.id);
    } catch (err) {
      console.error(err);
    }
  }
}
