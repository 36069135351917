import { Injectable } from '@angular/core';
import { BuildDateFilter } from '@greco/ngx-filters';
import { CondOperator, SFields } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'any' })
export class SubscriptionMinimumCommitmentFilter extends BuildDateFilter('SubscriptionMinimumCommitmentFilter', {
  description: '',
  label: 'Commitment End Date',
  properties: ['minimumCommitmentDate'],
  shortLabel: 'Commitment',
}) {
  getPropertySField(property: string, operator: CondOperator, value: Date | Date[]): SFields {
    return super.getPropertySField(property, operator, value);
  }
}
