import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'createQueryParamFilters',
})
export class CreateQueryParamFiltersPipe implements PipeTransform {
  transform(objects: any[]): string {
    return objects.map(object => (object.label || object.title || 'Filter') + ':' + object.id).join(',');
  }
}
