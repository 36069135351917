import { Component } from '@angular/core';
import { Cart } from '@greco/sales-shop';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'greco-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
})
export class CartSummaryComponent {
  cart: Cart = { total: 0, items: [] };

  constructor(private cartService: CartService) {
    this.cart = this.cartService.get();
  }
}
