import { animate, query, style, transition, trigger } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { fadeIn } from '@greco/ui-animations';

export interface PlatformLink {
  group: string | null;
  icon: string | null;
  label: string | null;
  description: string | null;
  color: 'primary' | 'accent' | 'warn' | null;
  url: string | null;
  disabled?: boolean;
  disabledAction?: () => void;
}

export interface PlatformLayoutConfig {
  links: PlatformLink[];
}

@Component({
  selector: 'greco-platform-layout',
  templateUrl: './platform.layout.html',
  styleUrls: ['./platform.layout.scss'],
  animations: [
    fadeIn,
    trigger('routerTransition', [
      transition('* <=> *', [
        query(
          'main.main-outlet > :enter, main.main-outlet > :leave',
          [
            style({
              position: 'absolute',
              left: 0,
              width: '100%',
              opacity: 0,
            }),
          ],
          { optional: true }
        ),
        query('main.main-outlet > :enter', [animate('600ms ease', style({ opacity: 1 }))], { optional: true }),
      ]),
    ]),
  ],
})
export class PlatformLayout {
  @Input() showDefaultHomeLink = false;
  @Input() showDrawerTitle = false;
  @Input() logo?: string;
  @Input() layoutTitle?: string;
  @Input() subtitle?: string;
  @Input() links?: PlatformLink[];
  @Input() hideEndContent = false;
  @Input() hideBackButton = false;

  private _hideNavigation = false;
  @Input() set hideNavigation(value: boolean) {
    this._hideNavigation = value;
    if (this.drawer && this._hideNavigation) {
      setTimeout(() => this.drawer.close());
    }
  }
  get hideNavigation() {
    return this._hideNavigation;
  }

  public mobileQuery: MediaQueryList;

  @ViewChild(MatDrawer) drawer!: MatDrawer;

  private _mobileQueryListener: () => void;

  showProgressBar = false;

  constructor(private media: MediaMatcher, private changeDetectorRef: ChangeDetectorRef, private router: Router) {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationStart) this.showProgressBar = true;
      else if (evt instanceof NavigationEnd || evt instanceof NavigationError || evt instanceof NavigationCancel)
        this.showProgressBar = false;
    });
  }
}
