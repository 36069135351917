<greco-simple-dialog [data]="dialogData" class="create-custom-purchase-dialog">
  <form [formGroup]="form">
    <mat-vertical-stepper #stepper>
      <mat-step [stepControl]="form">
        <ng-template matStepLabel>Grant To</ng-template>
        <div style="display: flex; justify-content: space-around">
          <button
            mat-raised-button
            style="max-width: 40%"
            color="primary"
            (click)="$event.stopImmediatePropagation(); allUserSelected(); userPicker.disabled=!selectUsers; userPicker.required=false"
            matStepperNext
          >
            All Users
          </button>
          <button
            mat-raised-button
            style="max-width: 40%"
            color="primary"
            (click)="$event.stopImmediatePropagation(); pickUserSelected(); userPicker.disabled=!selectUsers; userPicker.required=true"
            matStepperNext
          >
            Pick a User
          </button>
        </div>
      </mat-step>

      <mat-step [optional]="!selectUsers">
        <ng-template matStepLabel>Select User</ng-template>

        <mat-form-field appearance="standard">
          <mat-label>User</mat-label>
          <greco-contact-picker [withUser]="true" formControlName="contact" [communityId]="communityId" #userPicker>
          </greco-contact-picker>

          <div *ngIf="!selectUsers" style="display: flex; align-items: center; padding: 0 8px">
            <mat-icon color="warn" style="margin-right: 6px; margin-top: 6px; font-size: 18px">error_outline</mat-icon>
            <i>The coupon will be asign to all users by default!</i>
          </div>
        </mat-form-field>

        <div style="display: flex; flex-direction: row">
          <button mat-button matStepperPrevious>Previous</button>
          <button mat-button matStepperNext>Select Coupon</button>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Select Coupon</ng-template>
        <mat-form-field appearance="standard" style="width: 100%">
          <mat-label>Coupon</mat-label>
          <mat-select formControlName="coupon" [(ngModel)]="selectedCoupon" (ngModelChange)="selectedCoupon">
            <mat-option *ngFor="let coupon of coupons$ | async" [value]="coupon.id"> {{coupon.title}} </mat-option>
          </mat-select>

          <mat-checkbox matSuffix color="primary" labelPosition="before" formControlName="transferable">
            Transferable
          </mat-checkbox>
        </mat-form-field>

        <div style="display: flex; flex-direction: row">
          <button mat-button matStepperPrevious>Previous</button>
          <button mat-button matStepperNext>Configure Coupon</button>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Configure Coupon</ng-template>
        <mat-form-field appearance="standard" style="width: 100%">
          <mat-label>Quantity</mat-label>
          <input matInput type="number" min="1" step="1" required formControlName="quantity" #unlimitedUseInput />
          <mat-checkbox
            matSuffix
            color="primary"
            labelPosition="before"
            formControlName="unlimited"
            (change)="disableQuantity(); unlimitedUseInput.disabled=unlimitedUse"
          >
            Unlimited Uses
          </mat-checkbox>
        </mat-form-field>

        <mat-form-field appearance="standard" style="width: 100%">
          <mat-label>Expires In (Number of Days)</mat-label>
          <input matInput type="number" step="1" min="0" formControlName="expiry" />
          <mat-hint *ngIf="expiresOn$ | async as expiresOn">Coupon will expire on {{expiresOn}}</mat-hint>
          <mat-checkbox matSuffix color="primary" labelPosition="before" formControlName="expiryNotification">
            Send Expiry Notification
          </mat-checkbox>
        </mat-form-field>

        <mat-form-field *ngIf="form.value.expiryNotification" appearance="standard" style="width: 100%">
          <mat-label>
            Notify User {{ form.value.expiryNotificationDays }} Day{{ form.value.expiryNotificationDays > 1 ? 's' : ''}}
            Before Expiry
          </mat-label>
          <input
            matInput
            type="number"
            step="1"
            min="1"
            formControlName="expiryNotificationDays"
            [max]="form.value.expiry - 1"
          />
          <mat-hint *ngIf="expiresNotificaion$ | async as expiresNotificaion">
            An expiry notification email will be sent on {{ expiresNotificaion }}
          </mat-hint>
        </mat-form-field>

        <div style="display: flex; flex-direction: row">
          <button mat-button matStepperPrevious>Previous</button>
        </div>
      </mat-step>
    </mat-vertical-stepper>

    <div style="display: flex; align-items: center; gap: 8px; margin-top: 20px">
      <button mat-stroked-button (click)="resetStepper(stepper)" style="flex: 1">
        <span>Close</span>
      </button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        [disabled]="form.invalid || submitting"
        (click)="submit()"
        style="flex: 1"
      >
        <span>Confirm</span>
        <mat-icon *ngIf="submitting" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
