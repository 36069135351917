import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormAnswer, FormResponse } from '@greco/typeform';
import { TypeformResponseTooltip } from '../../pipes';

@Component({
  selector: 'greco-response-preview-dialog',
  templateUrl: './response-preview.dialog.html',
  styleUrls: ['./response-preview.dialog.scss'],
})
export class ResponsePreviewDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { response: FormResponse; formTitle: string }) {}

  getField(id: string) {
    return this.data.response.definition.fields.find(f => f.id === id)?.title;
  }

  getLabel(answer: FormAnswer) {
    return new TypeformResponseTooltip().getAnswerLabel(answer);
  }
}
