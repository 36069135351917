import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetModule } from '@greco/ngx-let-util';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { TableModule } from '@greco/ui-table';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import {
  BankAccountPaymentFormComponent,
  CreditCardPaymentMethodFormComponent,
  StripeAccountFeeConfigurationComponent,
  StripePaymentGatewayConfigurationComponent,
} from './components';
import { ManageTerminalsDialog } from './dialogs';
import { TerminalErrorDialog } from './dialogs/terminal-error/terminal-error.dialog';
import { StripePayoutsPage } from './pages';
import { IsSamePipe } from './pipes';
import { StripeConnectRedirectResolver } from './resolvers';
import {
  CLIENT_ID_TOKEN,
  PayoutService,
  STRIPE_PUBLISHABLE_KEY,
  StripeConnectService,
  StripeFeesService,
  StripeJsService,
  TERMINAL_SERVICE_CONFIG,
  TerminalService,
  TerminalServiceConfig,
} from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // Material
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,

    // Greco
    LetModule,
    TableModule,
    CollapsibleSectionModule,

    // Other
    NgxMaskModule,
    NgxCurrencyModule,
  ],
  providers: [
    StripeConnectService,
    StripeConnectRedirectResolver,
    PayoutService,
    StripeJsService,
    StripeFeesService,
    TerminalService,
  ],
  declarations: [
    IsSamePipe,
    StripePayoutsPage,
    BankAccountPaymentFormComponent,
    CreditCardPaymentMethodFormComponent,
    StripeAccountFeeConfigurationComponent,
    StripePaymentGatewayConfigurationComponent,
    ManageTerminalsDialog,
    TerminalErrorDialog,
  ],
  exports: [
    StripePayoutsPage,
    BankAccountPaymentFormComponent,
    CreditCardPaymentMethodFormComponent,
    StripeAccountFeeConfigurationComponent,
    StripePaymentGatewayConfigurationComponent,
    ManageTerminalsDialog,
  ],
})
export class StripePaymentGatewayModule {
  static forRoot(
    clientId: string,
    publishableKey: string,
    terminalConfig?: TerminalServiceConfig
  ): ModuleWithProviders<StripePaymentGatewayModule> {
    return {
      ngModule: StripePaymentGatewayModule,
      providers: [
        { provide: CLIENT_ID_TOKEN, useValue: clientId },
        { provide: STRIPE_PUBLISHABLE_KEY, useValue: publishableKey },
        { provide: TERMINAL_SERVICE_CONFIG, useValue: terminalConfig ?? null },
      ],
    };
  }
}
