import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { VideoWatchTime } from '@greco/videos';

@Injectable({
  providedIn: 'root',
})
export class UserVideoWatchTimeService {
  constructor(private http: HttpClient) {}

  async getUserVideoWatchTime(videoId: string) {
    return await toPromise(this.http.get<VideoWatchTime>(`/api/video-watch-time/${videoId}`));
  }

  async getUserWatchHistory() {
    return await toPromise(this.http.get<VideoWatchTime>(`/api/video-watch-time`));
  }

  async updateVideoWatchTime(videoId: string, time: number, checkForComplete = false) {
    return await toPromise(
      this.http.post<VideoWatchTime>(`/api/video-watch-time`, {
        videoId: videoId,
        time: time,
        checkForComplete: checkForComplete,
      })
    );
  }
}
