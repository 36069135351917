<greco-simple-dialog
  [data]="{ title: 'Reset Password', content: 'Please enter your <strong>account\'s email address</strong> below to receive a password reset link via email.', showCloseButton: true, hideDefaultButton: true }"
>
  <form [formGroup]="formGroup" (ngSubmit)="submit()" style="display: flex; flex-direction: column">
    <div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Email</mat-label>
        <input
          cdkFocusInitial
          matInput
          placeholder="Email"
          [formControl]="$any(formGroup.get('email'))"
          [errorStateMatcher]="matcher"
        />
        <mat-error
          align="end"
          *ngIf="formGroup.get('email')?.hasError('email') && !formGroup.get('email')?.hasError('required')"
        >
          Please enter a valid email address
        </mat-error>
        <mat-error align="end" *ngIf="formGroup.get('email')?.hasError('required')">
          Email is <strong>required</strong>!
        </mat-error>
      </mat-form-field>
    </div>
    <button type="submit" mat-flat-button color="primary" style="width: 100%" [disabled]="!formGroup.valid">
      <mat-icon>mark_email_unread</mat-icon>
      Send Reset Link
    </button>
  </form>
</greco-simple-dialog>
