<ng-container *ngIf="community">
  <div class="info-grid">
    <greco-box class="clickable" matRipple (click)="copyCommunityLink()">
      <div class="box-header">
        <mat-icon class="icon background">groups</mat-icon>
        <h3>Community</h3>
        <mat-icon>link</mat-icon>
      </div>
      <p>{{community.name || '-'}}</p>
    </greco-box>

    <greco-box>
      <div class="box-header">
        <mat-icon class="icon background">alternate_email</mat-icon>
        <h3>Email</h3>
      </div>
      <p>{{community.email || '-'}}</p>
    </greco-box>

    <greco-box>
      <div class="box-header">
        <mat-icon class="icon background">phone</mat-icon>
        <h3>Phone Number</h3>
      </div>
      <p>{{community.phoneNumber}}</p>
    </greco-box>
  </div>

  <greco-box>
    <div class="box-header">
      <mat-icon class="icon">place</mat-icon>
      <h3>Address</h3>
    </div>
    <p>{{community.address?.formatted || '-'}}</p>
    <object
      class="map"
      type="text/html"
      [data]="
      'https://www.google.com/maps/embed/v1/place?q=place_id:' +
        community.address?.placeId +
        '&key=' +
        googleMapsKey | safe: 'resourceUrl'
    "
    ></object>
  </greco-box>

  <a mat-stroked-button color="primary" [routerLink]="community.id" *ngIf="canUpdateCommunityInfo">
    <mat-icon>create</mat-icon>
    <span style="margin-left: 8px">Update Community Info</span>
  </a>

  <div class="sub-communities" *ngIf="community.canBeParent && canUpdateCommunityInfo">
    <div style="display: flex; align-items: center; justify-content: space-between; padding-right: 16px">
      <h2>Sub-Communities</h2>

      <button
        *ngIf="canCreateSub$ | async"
        mat-stroked-button
        color="primary"
        (click)="createCommunity()"
        style="height: 32px; line-height: 16px"
      >
        <mat-icon>add</mat-icon>
        Create Sub-Community
      </button>
    </div>

    <greco-communities-table *ngIf="canUpdateCommunityInfo" [parent]="community.id"></greco-communities-table>
  </div>
</ng-container>
