<div *ngIf="booking" class="booking-card" (click)="selectedBookingChanged.emit(booking)">
  <div class="card-image">
    <button
      class="favourite-button"
      mat-icon-button
      [class.favorite]="favorites?.has($any(booking.event.title))"
      (click)="
        $event.stopImmediatePropagation();
        favorites?.has($any(booking.event.title))
          ? dashboardFavoritesSvc.unfavorite($any(booking.event.title))
          : addFavorite(booking.event)
      "
    >
      <mat-icon>{{ favorites?.has($any(booking.event.title)) ? 'favorite' : 'favorite' }}</mat-icon>
    </button>

    <img
      class="main-image"
      [src]="booking.event.imageUrl || booking.event.calendar?.imageUrl || 'assets/greco/placeholder.png'"
    />
  </div>

  <div class="card-content">
    <p class="time">
      {{ booking.event.startDate | date : 'MMM d' }} {{ booking.event.startDate | date : 'h:mm' }} -
      {{ booking.event.endDate | date : 'shortTime' }}

      <ng-container *ngIf="booking.event?.community?.name">
        <mat-icon
          style="
            vertical-align: middle;
            margin-bottom: 4px;
            margin-left: 4px;
            width: 14px;
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            color: var(--primary-color);
          "
        >
          location_on
        </mat-icon>

        {{ booking.event.community.name }}
      </ng-container>
    </p>

    <h2>
      <strong> {{ booking.event.title }} </strong>
    </h2>

    <p>
      <!-- {{booking.event.calendar?.title}} -->
      <ng-container *ngIf="booking.event.resourceAssignments?.length">
        <!-- - -->
        <ng-container *ngFor="let resourceAssignment of booking.event.resourceAssignments; let last = last">
          {{ resourceAssignment.resource?.name }}{{ last ? '' : ',' }}
        </ng-container>
      </ng-container>
    </p>

    <div class="action-buttons">
      <button mat-stroked-button>
        {{
          booking.status === 'CONFIRMED'
            ? 'Booked'
            : booking.status === 'PENDING'
            ? 'Pending Confirmation'
            : booking.status === 'CHECKED_IN'
            ? 'Checked-In'
            : 'Booked'
        }}

        <mat-icon color="accent">
          {{
            booking.status === 'CONFIRMED'
              ? 'check'
              : booking.status === 'PENDING'
              ? 'report'
              : booking.status === 'CHECKED_IN'
              ? 'done_all'
              : 'check'
          }}
        </mat-icon>
      </button>

      <button
        *ngIf="booking.status === 'CONFIRMED' && moment(booking.event.startDate).isAfter(now)"
        mat-stroked-button
        style="max-width: 36px; min-width: 36px"
        (click)="cancelBooking(booking); $event.stopImmediatePropagation()"
      >
        <mat-icon color="warn">event_busy</mat-icon>
      </button>

      <greco-add-booking-to-calendar-button
        [bookingId]="booking.id"
        *ngIf="booking.status === 'CONFIRMED' && moment(booking.event.startDate).isAfter(now)"
        style="margin-top: 6px"
      >
      </greco-add-booking-to-calendar-button>

      <button
        *ngIf="
          (booking.status === 'CONFIRMED' || booking.status === 'CHECKED_IN') &&
          moment(booking.event.endDate).isBefore(now)
        "
        mat-stroked-button
        [routerLink]="'/workouts'"
        [queryParams]="{ calendar: booking.event.calendar?.id, tag: (booking.event.tags | pluck : 'id') }"
      >
        Book Again
        <mat-icon color="accent">restart_alt</mat-icon>
      </button>

      <button
        *ngIf="
          (booking.status === 'CONFIRMED' || booking.status === 'CHECKED_IN') &&
          (booking.event.zoomMeetingId || booking.event.zoomEvent?.meetingID) &&
          moment(booking.event.startDate).subtract(10, 'minutes').isBefore(now) &&
          moment(booking.event.startDate).add(10, 'minutes').isAfter(now)
        "
        mat-stroked-button
        (click)="$event.stopImmediatePropagation(); join(booking)"
      >
        Join
        <mat-icon color="primary">videocam</mat-icon>
      </button>
    </div>
  </div>
</div>
