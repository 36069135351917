<greco-table [data]="(videoUnlocks$ | async) || []" [highlight]="true" (rowClick)="rowClick($event)">
  <!-- Thumbnail -->
  <div *grecoTableCol="''; fitContent: true; let unlock" [matTooltip]="unlock.video.title">
    <img
      *ngIf="unlock.video.thumbnailUrl"
      [src]="unlock.video.thumbnailUrl | safe : 'resourceUrl'"
      alt="Thumbnail not found"
      style="
        width: 56px;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        border: 0px solid #685a8f;
        border-radius: 2px;
        display: block;
      "
    />
    <mat-icon *ngIf="!unlock.video.thumbnailUrl"> smart_display </mat-icon>
  </div>

  <!-- Title -->
  <p *grecoTableCol="'Title'; let unlock" [matTooltip]="'Title'">
    <strong>{{
      unlock.eventVideo?.event?.title || unlock.collectionVideo?.titleOverride || unlock.video.title
    }}</strong>
  </p>
  <!-- Duration -->
  <ng-container>
    <p *grecoTableCol="'Duration'; let unlock" [matTooltip]="'Length of Video'">
      {{ unlock.video.duration | grecoDuration }}
    </p>
  </ng-container>

  <!-- Unlock Date -->
  <p *grecoTableCol="'Unlock Date'; let unlock" [matTooltip]="'Unlocked on ' + (unlock.created | date)">
    {{ unlock.created | date }}
  </p>

  <!-- Expires On -->
  <p *grecoTableCol="'Expires On'; let unlock" [matTooltip]="'Date video will no longer be unlocked'">
    {{ unlock.expiresOn ? (unlock.expiresOn | date) : 'Never' }}
  </p>
  <!-- Unlocked Via -->
  <p *grecoTableCol="'Unlocked Via'; let unlock" [matTooltip]="'Title'">
    {{ unlock.eventVideo ? 'LF3 GO' : unlock.collectionVideo ? 'Video Library' : 'Staff' }}
  </p>

  <!-- Video Tags -->
  <mat-chip-list *grecoTableCol="'Tags'; let unlock">
    <greco-video-tag *ngFor="let tag of unlock.video.tags" [tag]="tag" [admin]="false"></greco-video-tag>
  </mat-chip-list>

  <button
    *grecoTableCol="''; let unlock; fitContent: true"
    mat-stroked-button
    [disabled]="unlock.expiresOn && unlock.expiresOn < now"
    style="height: 32px; line-height: 16px"
    (click)="$event.stopImmediatePropagation(); watch(unlock)"
  >
    Watch
  </button>

  <!-- schedule 
  <ng-container>
    <div *grecoTableCol="''; let video; alignRight: true" [matTooltip]="'Schedule Feature Coming Soon'">
      <button
        mat-stroked-button
        style="height: 32px; line-height: 16; margin-left: auto"
        (click)="$event.stopImmediatePropagation(); schedule(video)"
        color="primary"
        [disabled]="true"
      >
        Schedule
      </button>
    </div>
  </ng-container>-->
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
