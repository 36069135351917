import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { categories, icons as iconJson } from '../../icons';
interface Icon {
  name: string;
  categories: string[];
  tags: string[];
}
@Component({
  selector: 'greco-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: IconPickerComponent }],
})
export class IconPickerComponent implements OnDestroy, OnInit {
  private static ID = 0;
  icons = iconJson;
  categories = categories;
  @PropertyListener('sortedIcons') sortedIcons$ = new BehaviorSubject<{ category: string; icons: Icon[] }[]>([]);
  sortedIcons: { category: string; icons: Icon[] }[] = [];
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  private onDestroy$ = new Subject<void>();

  _form = this.formBuilder.group({
    search: [null],
    icon: [null],
  });

  get currentSearchValue() {
    return (this._form.get('search')?.value as string) || null;
  }

  readonly icons$ = combineLatest([
    this.iconControl.valueChanges.pipe(startWith(null)),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._form.get('search')!.valueChanges.pipe(startWith(null)),
  ]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([current, search]) => {
      const icons = search ? this.findIcons(search, this.icons.icons) : this.icons.icons.map(icon => icon.name);
      return icons;
    })
  );

  readonly iconsSorted$ = combineLatest([
    this.iconControl.valueChanges.pipe(startWith(null)),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._form.get('search')!.valueChanges.pipe(startWith(null)),
    this.sortedIcons$,
  ]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([current, search, sortedIcons]) => {
      const data: { category: string; icons: string[] }[] = [];
      for (const item of sortedIcons) {
        const index = sortedIcons.findIndex(i => i.category === item.category);

        const icons = search
          ? this.findIcons(search, sortedIcons[index].icons)
          : sortedIcons[index].icons.map(icon => icon.name);
        if (icons.length) data.push({ category: item.category, icons });
      }
      return data;
    })
  );

  @ViewChild(MatInput) private matInput?: MatInput;

  private get iconControl() {
    return this._form.get('icon') as AbstractControl;
  }

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-icon-picker';
  @HostBinding() readonly id = `${IconPickerComponent.ID++}-${this.controlType}`;

  _inputValue = '';

  set value(value: string | null) {
    const currentValue = this.iconControl.value;
    if (currentValue !== value) {
      this.iconControl.setValue(value);
      if (value) this._inputValue = value;
      this.stateChanges.next();
    }
  }
  get value(): string | null {
    return this.iconControl.value;
  }

  get focused() {
    return !!this.matInput?.focused;
  }

  get empty() {
    const control = this._form.get('icon');
    return !!control?.value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    this.iconControl.setValidators(this._required ? [Validators.required, Validators.minLength(1)] : []);
    this._form.updateValueAndValidity();

    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;

    this.iconControl;

    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.iconControl.invalid && this._form.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-icon-picker-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.matInput?.onContainerClick();
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.iconControl.valueChanges.pipe(startWith(null), takeUntil(this.onDestroy$)).subscribe(() => fn(this.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_fn: any) {}

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
  }

  removeIcon() {
    this.value = null;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.stateChanges.complete();
  }
  findIcons(search: string, icons: Icon[]) {
    const results: string[] = [];
    for (const icon of icons) {
      if (icon.name.includes(search)) {
        results.push(icon.name);
        continue;
      }
      let found = false;
      for (const category of icon.categories) {
        if (category.includes(search)) {
          results.push(icon.name);
          found = true;
          break;
        }
      }
      if (found) continue;
      for (const tag of icon.tags) {
        if (tag.includes(search)) {
          results.push(icon.name);
          found = true;
          break;
        }
      }
      if (found) continue;
    }
    return results;
  }

  sortIcons(icons: Icon[]) {
    const data: { category: string; icons: Icon[] }[] = [];
    for (const icon of icons) {
      for (const category of icon.categories) {
        if (!data.some(item => item.category === icon.categories[0])) {
          data.push({ category, icons: [icon] });
        } else {
          const categoryIndex = data.findIndex(item => item.category === category);
          if (!data[categoryIndex].icons.some(item => item.name === icon.name)) {
            data[categoryIndex].icons.push(icon);
          }
        }
      }
    }
    return data;
  }
  ngOnInit(): void {
    this.sortedIcons = this.sortIcons(this.icons.icons);
  }
  iconSelected(icon: string) {
    this.value = icon;
  }
}
