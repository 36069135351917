import { BaseDto } from '@greco/util-dto';
import { Type } from 'class-transformer';
import { IsDate, IsInt, IsOptional, IsString } from 'class-validator';

export class UpdateVideoSubCollectionDto extends BaseDto {
  @IsString()
  @IsOptional()
  icon?: string;

  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsInt()
  @IsOptional()
  sortIndex?: number;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  availableOn?: Date | null;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  availableUntil?: Date | null;
}
