import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { CalendarDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import * as moment from 'moment';

@Injectable()
export class ResponsiveDateFormatter extends CalendarDateFormatter {
  constructor(dateAdapter: DateAdapter, private breakpoints: BreakpointObserver) {
    super(dateAdapter);
  }

  monthViewColumnHeader({ date }: DateFormatterParams): string {
    return this._format(date);
  }

  weekViewColumnHeader({ date }: DateFormatterParams): string {
    return this._format(date);
  }

  private _format(date: Date): string {
    const format = this.breakpoints.isMatched('(max-width: 700px)') ? 'ddd' : 'dddd';
    return moment(date).format(format);
  }
}
