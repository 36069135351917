import { Component, Input } from '@angular/core';
import { ArrayUtils } from '@greco-fit/util';
import type { QueryStatsDto } from '@greco/nestjs-sales-stats';
import { PropertyListener } from '@greco/property-listener-util';
import { SalesStat } from '@greco/sales-stats';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { StatsService } from '../../services';

@Component({
  selector: 'greco-stats-grid',
  templateUrl: './stats-grid.component.html',
  styleUrls: ['./stats-grid.component.scss'],
})
export class StatsGridComponent {
  @Input() referenceId?: string | null;

  @PropertyListener('referenceId')
  private readonly _referenceId$ = new BehaviorSubject<string | null | undefined>(null);

  readonly stats$ = this._referenceId$.pipe(
    distinctUntilChanged(),
    switchMap(async referenceId => (referenceId ? await this.statSvc.queryStats(this.getDto(referenceId)) : [])),
    map(stats => {
      const [previous, value] = this.extractMostRecentData(stats);
      return { value, delta: this.computeDelta(previous, value) };
    })
  );

  constructor(private statSvc: StatsService) {
    this.stats$.subscribe();
  }

  private getDto(referenceId: string): QueryStatsDto {
    return {
      referenceId,
      endDate: moment().add(1, 'day').startOf('day').toDate(),
      startDate: moment().subtract(1, 'day').startOf('day').toDate(),
    };
  }

  private extractMostRecentData(stats: SalesStat[]): [SalesStat | null, SalesStat | null] {
    const mostRecent = (stats: SalesStat[]): SalesStat | null => {
      if (!stats.length) return null;
      return stats.sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime())[0];
    };

    const grouped = ArrayUtils.groupBy(stats, stat => moment(stat.lastUpdated).format('YYYY-MM-DD'));
    const data = Object.entries(grouped).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: mostRecent(value) }),
      {} as { [key: string]: SalesStat | null }
    );

    return [
      data[moment().subtract(1, 'day').format('YYYY-MM-DD')] || null,
      data[moment().format('YYYY-MM-DD')] || null,
    ];
  }

  private computeDelta(
    previous: SalesStat | null,
    next: SalesStat | null
  ): Omit<SalesStat, 'id' | 'referenceId' | 'lastUpdated'> | null {
    if (!previous || !next) return null;

    const delta = (previous: number | null, next: number | null) => {
      return previous && next ? (next - previous) / previous : 0;
    };

    return {
      MRR: delta(previous.MRR, next.MRR),
      newSubs: delta(previous.newSubs, next.newSubs),
      activeSubs: delta(previous.activeSubs, next.activeSubs),
      newCancels: delta(previous.newCancels, next.newCancels),
      cancelCosts: delta(previous.cancelCosts, next.cancelCosts),
      lifetimeValue: delta(previous.lifetimeValue, next.lifetimeValue),
      churnedRevenue: delta(previous.churnedRevenue, next.churnedRevenue),
      subChurnedRate: delta(previous.subChurnedRate, next.subChurnedRate),
    };
  }
}
