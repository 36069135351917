import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DateInputDialogData {
  title: string;
  subtitle: string;
  buttons?: { label: string; role: string; color?: string }[];

  initial?: Date;
  min?: Date;
  max?: Date;
}

@Component({
  templateUrl: './date-input-dialog.component.html',
  styleUrls: ['./date-input-dialog.component.scss'],
})
export class DateInputDialog {
  public data: DateInputDialogData;

  _formControl = this.formBuilder.control(null, [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: DateInputDialogData,
    public dialogRef: MatDialogRef<DateInputDialog>,
    private formBuilder: FormBuilder
  ) {
    this.data = {
      ...this.dialogData,
      buttons: this.dialogData?.buttons || [{ label: 'Ok', role: 'ok' }],
    };

    if (this.data.initial) this._formControl.reset(this.data.initial);
  }

  close(dialogResult?: any) {
    this.dialogRef.close({ value: this._formControl.value, role: dialogResult });
  }
}
