<greco-collapsible-section [header]="{ title: 'Typeform Configuration', icon: 'live_help' }">
  <button
    *ngIf="newForm !== selectedTypeform"
    header
    mat-flat-button
    color="primary"
    style="height: 32px; line-height: 16px; margin-left: 8px"
    [disabled]="loading"
    (click)="$event.stopImmediatePropagation(); saveForm()"
  >
    <mat-icon *ngIf="loading" style="margin-left: 4px" class="spin">loop</mat-icon>
    Save
  </button>

  <mat-form-field style="margin-top: 10px">
    <mat-label>Cancel Subscription</mat-label>

    <mat-select [value]="selectedTypeform" (valueChange)="newForm = $event">
      <mat-option *ngFor="let form of forms$ | async" [value]="form.id">{{ form.formTitle }}</mat-option>
      <mat-option [value]="null"><i>Disable Cancel Subscription Form</i></mat-option>
    </mat-select>
  </mat-form-field>
</greco-collapsible-section>
