<ng-container *ngIf="canRead$ | async">
  <ng-container *grecoLet="canGrant$ | async as canGrant">
    <greco-filter-bar
      *grecoLet="canCreate$ | async as canCreate"
      [hideSeparator]="true"
      [filterOptions]="filterOptions"
      style="position: sticky; top: 56px; z-index: 10; background-color: var(--background-color, white)"
      (changed)="filters$.next($event[1]); onFilterApplied()"
    >
      <button mat-stroked-button filterbar color="primary" style="height: 32px" [matMenuTriggerFor]="agreement">
        <span>Quick Actions</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #agreement>
        <button mat-menu-item (click)="createCoupon()" *ngIf="canCreate">
          <mat-icon>add</mat-icon>
          <span>Create Coupon</span>
        </button>

        <button mat-menu-item (click)="_grantCoupon()" *ngIf="canGrant">
          <mat-icon>person_add</mat-icon>
          <span>Grant Coupon</span>
        </button>
      </mat-menu>
    </greco-filter-bar>

    <greco-table
      [data]="(coupons$ | async) || []"
      (rowClick)="_viewCoupon($event)"
      [loading]="loading"
      [highlight]="true"
    >
      <greco-perk-badge
        *grecoTableCol="''; let coupon; fitContent: true"
        [shape]="coupon.badge.shape || 'squircle'"
        [icon]="coupon.badge.icon || ''"
        [text]="coupon.badge.text"
        [color]="coupon.badge.color"
        [small]="true"
        [matTooltip]="coupon.id"
      ></greco-perk-badge>

      <p *grecoTableCol="'Title'; let coupon" matTooltip="Coupon Title">{{ coupon?.title | titlecase }}</p>

      <p *grecoTableCol="'Created'; let coupon">{{ coupon.created | date }}</p>

      <mat-chip-list *grecoTableCol="'Applies To'; let coupon">
        <ng-container *grecoLet="getProductCount(coupon.assignments) as productCount">
          <mat-chip *ngIf="coupon.allProducts == false && productCount" style="padding-left: 8px">
            {{ productCount + ' Products(s)' }}
          </mat-chip>
        </ng-container>

        <ng-container *grecoLet="getSaleCategoryCount(coupon.assignments) as saleCategoryCount">
          <mat-chip *ngIf="coupon.allProducts == false && saleCategoryCount" style="padding-left: 8px">
            {{ saleCategoryCount + ' Sale Category(ies)' }}
          </mat-chip>
        </ng-container>

        <mat-chip
          *ngIf="coupon.allProducts == true"
          [style.background-color]="'var(--primary-color)'"
          [style.color]="'White'"
          style="padding-left: 8px"
        >
          All Products
        </mat-chip>
      </mat-chip-list>

      <p *grecoTableCol="'Discount'; let coupon">
        <mat-chip
          [style.background-color]="coupon.discountType == 'FLAT_AMOUNT' ? 'var(--accent2-color)' : ''"
          [style.color]="''"
        >
          {{
            coupon.discountType == 'FLAT_AMOUNT'
              ? (coupon.discountAmount / 100 | currency)
              : (coupon.discountAmount / 100 | percent)
          }}
        </mat-chip>
      </p>

      <mat-chip-list *grecoTableCol="'Status'; let coupon; sorting: 'status'; fitContent: true">
        <div [ngSwitch]="coupon.deleted">
          <mat-chip *ngSwitchDefault [style.background-color]="'var(--warn-color)'" [style.color]="'white'">
            Archived
          </mat-chip>
          <mat-chip *ngSwitchCase="null" [style.background-color]="''" [style.color]="''">Active</mat-chip>
        </div>
      </mat-chip-list>

      <ng-container *grecoTableCol="''; let coupon; fitContent: true; stickyEnd: true">
        <ng-container *grecoLet="canArchive$ | async as canArchive">
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <button mat-menu-item (click)="$event.stopImmediatePropagation(); _viewCoupon(coupon)">
              <mat-icon>open_in_new</mat-icon>
              <span>View Details</span>
            </button>

            <button
              mat-menu-item
              (click)="$event.stopImmediatePropagation(); _grantCoupon(coupon)"
              *ngIf="canGrant && !coupon.deleted"
            >
              <mat-icon>person_add</mat-icon>
              <span>Grant Coupon</span>
            </button>

            <button
              mat-menu-item
              (click)="$event.stopImmediatePropagation(); _viewCouponsStats(coupon)"
              *ngIf="canArchive"
              [disabled]="true"
            >
              <mat-icon>account_tree</mat-icon>
              <span>View Statistics</span>
            </button>

            <button *ngIf="canArchive" mat-menu-item (click)="coupon.deleted ? restore(coupon) : archive(coupon)">
              <mat-icon>{{ coupon.deleted ? 'restore' : 'archive' }}</mat-icon>
              <span>{{ coupon.deleted ? 'Restore' : 'Archive' }}</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>
  </ng-container>

  <mat-paginator
    *ngIf="paginationMeta?.totalItems"
    showFirstLastButtons
    [length]="paginationMeta!.totalItems || 0"
    [pageSize]="paginationMeta!.itemsPerPage || 10"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</ng-container>
