import { Injectable } from '@angular/core';
import { SelectFilter } from '@greco/ngx-filters';
import { CondOperator, SFields } from '@nestjsx/crud-request';

type GrantedBy = 'Subscription' | 'One-Time Purchase' | 'Staff' | 'Other';

@Injectable({ providedIn: 'any' })
export class UserPerkGrantedByFilter extends SelectFilter {
  constructor() {
    super('UserPerkGrantedByFilter', {
      description: '',
      label: 'Granted By',
      properties: ['grantedBy'],
      shortLabel: 'Granted By',
      canOpen: true,
      onlyOne: true,
    });
  }

  private allGrantedBys: GrantedBy[] = ['One-Time Purchase', 'Subscription', 'Staff', 'Other'];

  getValueLabel(value: GrantedBy | GrantedBy[]): string {
    return Array.isArray(value) ? value.join(', ') : value;
  }

  async getValueOptions(search?: string): Promise<any[]> {
    if (!search) return this.allGrantedBys;
    return this.allGrantedBys.filter(v => v.toLowerCase().includes(search.toLowerCase()));
  }

  serializeValue(value: GrantedBy | GrantedBy[]): string {
    return Array.isArray(value) ? value.join(',') : value;
  }

  async deserializeValue(serializedValue: string) {
    return serializedValue.split(',').filter(v => this.allGrantedBys.includes(v as any));
  }

  getPropertySField(_property: string, operator: CondOperator, value: GrantedBy[]): SFields {
    return operator === CondOperator.EQUALS
      ? {
          $or: [
            ...(value.includes('One-Time Purchase') ? [{ grantedBy: { $startsL: 'puritm_' } }] : []),
            ...(value.includes('Subscription') ? [{ grantedBy: { $startsL: 'subitm_' } }] : []),
            ...(value.includes('Staff')
              ? [
                  {
                    $and: [
                      { grantedBy: { $notnull: true } },
                      { grantedBy: { $exclL: 'puritm_' } },
                      { grantedBy: { $exclL: 'subitm_' } },
                    ],
                  },
                ]
              : []),
            ...(value.includes('Other') ? [{ grantedBy: { $isnull: true } }] : []),
          ],
        }
      : operator === CondOperator.NOT_EQUALS
      ? {
          $or: [
            {
              $and: [
                ...(value.includes('One-Time Purchase') ? [{ grantedBy: { $exclL: 'puritm_' } }] : []),
                ...(value.includes('Subscription') ? [{ grantedBy: { $exclL: 'subitm_' } }] : []),
                ...(value.includes('Staff')
                  ? [
                      {
                        $or: [
                          { grantedBy: { $isnull: true } },
                          { grantedBy: { $startsL: 'puritm_' } },
                          { grantedBy: { $startsL: 'subitm_' } },
                        ],
                      },
                    ]
                  : []),
                ...(value.includes('Other') ? [{ grantedBy: { $notnull: true } }] : []),

                // ...(value.includes('Rented')
                //   ? [{ $or: [{ expiresOn: { $isnull: true } }, { expiresOn: { $lte: new Date().toISOString() } }] }]
                //   : []),
                // ...(value.includes('Unlocked') ? [{ expiresOn: { $notnull: true } }] : []),
              ],
            },
          ],
        }
      : {};
  }
}
