import { Component, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { PropertyListener } from '@greco/property-listener-util';
import { ReplaySubject, Subscription } from 'rxjs';

@Component({
  selector: 'greco-upgrade-configuration-form',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: UpgradeConfigurationFormComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: UpgradeConfigurationFormComponent, multi: true },
  ],
})
export class UpgradeConfigurationFormComponent implements ControlValueAccessor, Validator, OnDestroy {
  products: FormControl = new FormControl([]);

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string>();
  @Input() communityId = '';

  private listeners: Subscription[] = [];

  ngOnDestroy() {
    this.listeners.forEach(listener => listener.unsubscribe());
  }

  registerOnChange(fn: (value: any) => void) {
    this.listeners.push(this.products.valueChanges.subscribe(value => fn(value)));
  }

  writeValue = (val: any) => this.products.setValue(val, { emitEvent: false });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched = () => {};

  validate(_: AbstractControl): ValidationErrors | null {
    return this.products.valid ? null : { invalid: true };
  }
}
