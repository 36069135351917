<greco-simple-dialog [data]="simpleDialogData">
  <mat-form-field style="width: 100%; margin-top: 16px">
    <mat-label>Transferred From</mat-label>

    <greco-user-picker
      [formControl]="transferredFromFormControl"
      [showPhoto]="true"
      [communityId]="accountId"
      placeholder="Select who transferred this subscription"
    ></greco-user-picker>

    <button
      *ngIf="transferredFromFormControl.value"
      matSuffix
      mat-icon-button
      (click)="transferredFromFormControl.setValue(null)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div style="margin-top: 16px; display: flex; align-items: center; gap: 8px">
    <button style="flex: 1" mat-stroked-button type="button" (click)="cancel()">Cancel</button>
    <button style="flex: 1" mat-flat-button color="primary" type="button" (click)="submit()">Confirm</button>
  </div>
</greco-simple-dialog>
