import { Component, Input } from '@angular/core';

@Component({
  selector: 'greco-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrls: ['./testimonial-card.component.scss'],
})
export class TestimonialCardComponent {
  @Input() image: string;
  @Input() title: string;
  @Input() tags: string;
}
