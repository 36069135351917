<greco-simple-dialog [data]="dialogData">
  <mat-form-field style="width: 100%">
    <input
      #input
      type="text"
      placeholder="Search Users"
      aria-label="Number"
      matInput
      [matAutocomplete]="auto"
      (input)="onChange($any($event.target)?.value)"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectUser($event); input.value = ''">
      <mat-option *ngFor="let user of users$ | async" [value]="user"> {{ user.displayName || user.email }} </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <greco-table *ngIf="selectedUsers?.length" [data]="selectedUsers || []" [loading]="selectedUsers === null">
    <mat-icon *grecoTableCol="''; fitContent: true; let selectedUser" [matTooltip]="selectedUser.id"> badge </mat-icon>

    <p *grecoTableCol="'User'; let selectedUser">{{ selectedUser.displayName }}</p>

    <p
      *grecoTableCol="'Email'; let selectedUser"
      [matTooltip]="selectedUser.emailVerified ? 'Email is Verified!' : 'Email is not Verified!'"
    >
      {{ selectedUser.email }}
    </p>

    <ng-container *grecoTableCol="''; fitContent: true; let selectedUser">
      <button mat-icon-button (click)="removeUser(selectedUser)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </ng-container>
  </greco-table>
</greco-simple-dialog>
