import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EventsModule } from '@greco/ngx-booking-events';
import { FeatureCommunityAgreementsModule } from '@greco/ngx-community-agreements';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { UsersModule } from '@greco/ngx-identity-users';
import { LetModule } from '@greco/ngx-let-util';
import { GrecoRouterModule } from '@greco/ngx-routes-util';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { SubscriptionsModule } from '@greco/ngx-sales-subscriptions';
import { AlertModule } from '@greco/ui-alert';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { TableModule } from '@greco/ui-table';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { AccountLinkingModule } from '@greco/web-account-linking';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  AddBookingCardComponent,
  AgreementsRequirementComponent,
  AttendeeCardComponent,
  BookingCard2Component,
  BookingOptionPicker2Component,
  CancellationPolicyComponent,
  EmailVerificationRequirementComponent,
  EventFailedPaymentAlertComponent,
  EventMissingPerksAlertComponent,
  EventPaymentMethodAlertComponent,
  EventScheduleConflictAlertComponent,
  EventTimeAlertComponent,
  PaymentMethodRequirementComponent,
  ProfileCompletionRequirementComponent,
  RoomSpotPickerComponent,
  SpotSelectionRequirementComponent,
  TypeformRequirementComponent,
  WaitlistCardComponent,
} from './components';
import { EventDayPickerComponent } from './components/event-day-picker/event-day-picker.component';
import { EventItemComponent } from './components/event-item/event-item.component';
import { EventWrapperPage } from './pages';
import { EventPage } from './pages/event/event.page';
import { EventsPage } from './pages/events/events.page';
import { WorkoutsLayoutPage } from './pages/workouts-layout/workouts-layout.page';
import { CreateQueryParamFiltersPipe, FlattenAndCombineFiltersPipe, GetEventAccountPipe } from './pipes';
import { EventUserResolver } from './resolvers';
import { routes } from './route-booking.routes';
export { EventService } from './services';

@NgModule({
  imports: [
    CommonModule,
    GrecoRouterModule.forChild(routes),
    ReactiveFormsModule,
    NgPipesModule,
    LetModule,
    ClipboardModule,
    MatSelectModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    MatBadgeModule,
    MatChipsModule,
    MatBottomSheetModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatDividerModule,
    SafePipeModule,
    MatDividerModule,
    MatRadioModule,
    MatTabsModule,
    // Greco
    TableModule,
    PaymentsModule,
    FeatureCommunityAgreementsModule,
    PurchasesModule,
    UsersModule,
    BoxModule,
    UserAvatarModule,
    AccountLinkingModule,
    CollapsibleSectionModule,
    AlertModule,
    SubscriptionsModule,
    EventsModule,
  ],
  declarations: [
    // Pages
    WorkoutsLayoutPage,
    EventsPage,
    EventPage,
    EventWrapperPage,

    // Components
    // Event
    EventItemComponent,
    EventDayPickerComponent,
    AttendeeCardComponent,
    WaitlistCardComponent,
    AddBookingCardComponent,

    // Alerts
    EventMissingPerksAlertComponent,
    EventFailedPaymentAlertComponent,
    EventScheduleConflictAlertComponent,
    EventPaymentMethodAlertComponent,
    EventTimeAlertComponent,

    // Booking/Requirements
    BookingCard2Component,
    BookingOptionPicker2Component,
    AgreementsRequirementComponent,
    EmailVerificationRequirementComponent,
    PaymentMethodRequirementComponent,
    ProfileCompletionRequirementComponent,
    TypeformRequirementComponent,
    CancellationPolicyComponent,
    SpotSelectionRequirementComponent,
    RoomSpotPickerComponent,

    // Pipes
    FlattenAndCombineFiltersPipe,
    GetEventAccountPipe,
    CreateQueryParamFiltersPipe,
  ],
  providers: [EventUserResolver],
  exports: [EventPage],
})
export class RouteBookingModule {}
