<greco-simple-dialog [data]="dialogData">
  <mat-form-field appearance="standard">
    <i *ngIf="!_paymentMethodControl.value" style="position: absolute; transform: translateY(-2px)">
      <small>Add a Payment Method!</small>
    </i>

    <ng-container>
      <greco-select-payment-method
        #selectPaymentMethodInput
        [formControl]="_paymentMethodControl"
        [userId]="userId"
        [required]="true"
        [allowBankPayments]="false"
      ></greco-select-payment-method>

      <greco-add-payment-method-button
        matSuffix
        [onlyIcon]="true"
        [userId]="userId"
        (saved)="$event && selectPaymentMethodInput.refreshPaymentMethods()"
      ></greco-add-payment-method-button>
    </ng-container>
  </mat-form-field>
  <div style="display: grid; justify-content: flex-end">
    <button
      mat-stroked-button
      [disabled]="!_paymentMethodControl.valid"
      type="button"
      (click)="submitUpdate()"
      color="primary"
      style="flex: 1"
    >
      <span>Confirm</span>
      <mat-progress-spinner
        diameter="18"
        mode="indeterminate"
        *ngIf="processing"
        style="filter: grayscale(1) opacity(0.5)"
      ></mat-progress-spinner>
    </button>
  </div>
</greco-simple-dialog>
