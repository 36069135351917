import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';

export class UpdateTilesGroupDto {
  constructor(dto?: UpdateTilesGroupDto) {
    if (dto) Object.assign(this, dto);
  }
  @IsBoolean()
  @IsOptional()
  enabled?: boolean;

  @IsString()
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  icon?: string;

  @IsString()
  @IsOptional()
  communityId?: string;

  @IsNumber()
  @IsOptional()
  priority?: number;
}
