<div class="stats-container" *ngIf="stats$ | async as stats">
  <!-- <greco-box>
    <h3>Subtotal</h3>
    <p>{{stats ? (stats.subtotal / 100 | currency) : '-'}}</p>
    <p style="font-size: 75%">Amount before taxes</p>
  </greco-box> -->

  <greco-box>
    <h3>Taxes</h3>
    <p>{{ stats ? ((stats.taxes | values | sum) / 100 | currency) : '-' }}</p>
    <p style="font-size: 75%">Harmonized Sales Tax (HST)</p>
  </greco-box>

  <greco-box>
    <h3>Total</h3>
    <p>{{ stats ? (stats.total / 100 | currency): '-' }}</p>
    <p style="font-size: 75%">
      {{ stats ? ((stats.subtotal / 100 | currency) + ' before taxes') : 'Amount with taxes' }}
    </p>
  </greco-box>

  <greco-box>
    <h3>Refunded</h3>
    <p>{{ stats ? (stats.refunded / 100 | currency) : '-' }}</p>
    <p style="font-size: 75%">Amount refunded</p>
  </greco-box>
</div>

<greco-filter-bar
  [hideSeparator]="true"
  [saleCategories]="true"
  [filterOptions]="filterOptions"
  (changed)="purchasesTable.queryBuilder = $event[1]; filters$.next($event[1]); purchasesTable.onFilterApplied()"
>
  <greco-sale-category-picker
    #saleCategoryPicker
    saleCategoryPicker
    [accountId]="account.id"
    [showUncategorizedOption]="true"
  ></greco-sale-category-picker>

  <div class="variant" slot="start">
    <ng-container *ngTemplateOutlet="productVariant"></ng-container>
  </div>

  <div style="display: flex; align-items: center; justify-content: flex-end; height: 32px">
    <mat-menu #purchaseMenu>
      <ng-content></ng-content>

      <button *ngIf="(canCustomCharge$| async)" mat-menu-item color="primary" (click)="createCustomCharge()">
        <mat-icon>payment</mat-icon>
        <span>Custom Charge</span>
      </button>

      <ng-container *grecoLet="canBulkRetry$| async as canBulkRetry">
        <ng-container *grecoLet="canBulkVoid$| async as canBulkVoid">
          <mat-divider *ngIf="canBulkRetry || canBulkVoid"></mat-divider>

          <button *ngIf="canBulkRetry" mat-menu-item color="primary" (click)="purchasesTable.bulkRetry()">
            <mat-icon>refresh</mat-icon>
            <span>Bulk Retry</span>
          </button>

          <button *ngIf="canBulkVoid" mat-menu-item color="primary" (click)="purchasesTable.bulkVoid()">
            <mat-icon>block</mat-icon>
            <span>Bulk Void</span>
          </button>
        </ng-container>
      </ng-container>

      <mat-divider></mat-divider>

      <button
        mat-menu-item
        [grecoExportPurchases]="{ accountId: account.id, filters: filters$.value, saleCategories: saleCategoryPicker.selected$.value, variantIds: productVariantIds, userId: userId }"
      >
        <mat-icon>file_download</mat-icon>
        <span>Export Sales</span>
      </button>

      <button mat-menu-item color="primary" (click)="exportPaymentMethods()">
        <mat-icon>file_download</mat-icon>
        <span>Export Payment Methods</span>
      </button>
    </mat-menu>

    <button mat-stroked-button [matMenuTriggerFor]="purchaseMenu" color="primary" style="height: 32px">
      Quick Actions
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
</greco-filter-bar>

<greco-purchases-table
  #purchasesTable
  [isAdminPage]="true"
  [accountId]="account.id"
  [communityId]="communityId"
  [variantIds]="(productVariantIds || []).join(',')"
  [showUncategorized]="(saleCategoryPicker.showUncategorized)"
  [saleCategoryIds]="(saleCategoryPicker.selected$ | async | pluck: 'id').join(',') || null"
  (rowClick)="openPurchase($event)"
></greco-purchases-table>
