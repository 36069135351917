<table
  mat-table
  class="table"
  [dataSource]="data"
  matSort
  [matSortActive]="activeSort?.active || ''"
  [matSortDirection]="activeSort?.direction || ''"
  cdkDropList
  [cdkDropListData]="data"
  [cdkDropListDisabled]="!draggable"
  (cdkDropListDropped)="drop($event)"
>
  <ng-container
    *ngFor="let col of colTemplates"
    [matColumnDef]="col.id"
    [sticky]="col.sticky"
    [stickyEnd]="col.stickyEnd"
  >
    <!-- With Sorting -->
    <ng-container *ngIf="col.sorting; else noSort">
      <th
        mat-header-cell
        *matHeaderCellDef
        [mat-sort-header]="col.sorting"
        [style.text-align]="col.alignRight ? 'right' : col.alignCenter ? 'center' : ''"
      >
        {{ col.title }}
      </th>
    </ng-container>

    <!-- Without Sorting -->
    <ng-template #noSort>
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.text-align]="col.alignRight ? 'right' : col.alignCenter ? 'center' : ''"
      >
        {{ col.title }}
      </th>
    </ng-template>

    <td
      mat-cell
      *matCellDef="let item; let index = index"
      [class.fit-content]="col.fitContent"
      [ngStyle]="col.style ? (col.style | memo : item : index) : {}"
      [style.text-align]="col.alignRight ? 'right' : col.alignCenter ? 'center' : ''"
    >
      <ng-container
        *ngTemplateOutlet="
          col.templateRef;
          context: {
            $implicit: item,
            hovering: hoveredRow === index,
            colId: col.id,
            index: index
          }
        "
      ></ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="getColumnIds()"></tr>
  <tr
    mat-row
    cdkDrag
    [cdkDragData]="row"
    cdkDragBoundary=".table"
    *matRowDef="let row; let index = index; columns: getColumnIds()"
    [ngStyle]="rowStyle ? (rowStyle | memo : row : index) : {}"
    (mouseleave)="hoveredRow = undefined"
    (mouseenter)="hoveredRow = index"
    (click)="rowClick.emit(row)"
    (cdkDragStarted)="dragStart.emit($event)"
  >
    <!--If using the custom drag preview, make sure the parent component is listening to the table's dragStart event to get the data needed to insert into the custom preview-->
    <div *ngIf="customDragPreview">
      <ng-content select="[customDragPreview]" *cdkDragPreview></ng-content>
    </div>
  </tr>
</table>

<ng-container *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<ng-container *ngIf="!hideEmptyPlaceholder && !data?.length && !loading">
  <greco-empty-placeholder></greco-empty-placeholder>
</ng-container>
