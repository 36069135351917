import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { PaymentStatus } from '@greco/finance-payments';
import { Tax } from '@greco/finance-tax';
import { User } from '@greco/identity-users';
import { AccountTaxService } from '@greco/ngx-finance-tax';
import { UserService } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase, PurchaseStatus } from '@greco/sales-purchases';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { CustomChargeService, PurchaseService } from '../../services';

@Component({
  selector: 'greco-create-custom-charge-dialog',
  templateUrl: './create-custom-charge.dialog.html',
  styleUrls: ['./create-custom-charge.dialog.scss'],
})
export class CreateCustomChargeDialog implements OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) data: { accountId: string; communityId: string },
    private dialogRef: MatDialogRef<CreateCustomChargeDialog>,
    private customChargeSvc: CustomChargeService,
    private purchaseSvc: PurchaseService,
    private formBuilder: FormBuilder,
    private userSvc: UserService,
    private snacks: MatSnackBar,
    private accTaxSvc: AccountTaxService
  ) {
    this.accountId = data.accountId;
    this.communityId = data.communityId;
    this.form.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        startWith(this.form.value),
        switchMap(async () => {
          const value = this.form.value;

          if (!this.defaultTaxes?.length) {
            const accountTaxes = await this.accTaxSvc.getAccountDefaultTaxes(this.accountId);
            if (accountTaxes) this.defaultTaxes = accountTaxes;
          }

          if (this.defaultTaxes.length && value.taxesIncluded !== 'none' && !value.taxTypes?.length) {
            this.form.patchValue({ taxTypes: this.defaultTaxes });
          }

          if (!this.form.valid || !value.contact?.user) return null;

          return await this.customChargeSvc.preview({
            userId: value.contact.user.id,
            paymentMethodId: value.paymentMethod.id,

            accountId: this.accountId,
            amount: Math.round(value.amount * 100),
            title: value.title,
            description: value.description,
            saleCategoryId: value.saleCategory?.id,

            taxesIncluded: value.taxesIncluded === 'included',
            taxes:
              value.taxesIncluded === 'none'
                ? []
                : value.taxTypes?.length
                ? (value.taxTypes as Tax[]).map(({ id }) => id)
                : !this.defaultTaxes
                ? []
                : this.defaultTaxes.map(({ id }) => id),
          });
        })
      )
      .subscribe(preview => this.preview$.next(preview));
  }

  private onDestroy$ = new Subject<void>();

  @PropertyListener('accountId') private _accountId$ = new BehaviorSubject<string>('');
  @Input() accountId: string;

  communityId: string;

  defaultTaxes: Tax[] = [];

  accountTaxes$ = this._accountId$.pipe(
    switchMap(async accountId => {
      return accountId ? await this.accTaxSvc.getAccountTaxes(accountId) : [];
    })
  );

  dialogData: DialogData = {
    title: 'Custom Charge',
    subtitle: 'Charge a custom amount to any user',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  form = this.formBuilder.group({
    contact: [null, Validators.required],
    title: ['', Validators.required],
    description: [''],
    amount: [0, [Validators.required, Validators.min(1)]],
    taxesIncluded: ['excluded', Validators.required],
    taxTypes: [[]],
    paymentMethod: [null, Validators.required],
    saleCategory: [null, Validators.required],
  });

  preview$ = new BehaviorSubject<{ purchase: Purchase; dto: any; hash: string } | null>(null);

  taxes$ = combineLatest([this.preview$, this.accountTaxes$]).pipe(
    map(([preview, accountTaxes]) => {
      const taxes: { tax: Tax; amount: number; taxNumber: string }[] = [];

      preview?.purchase.items.forEach(item => {
        item.taxes?.forEach(itemTax => {
          if (taxes.some(tax => tax.tax.id === itemTax.id)) {
            const index = taxes.findIndex(tax => tax.tax.id === itemTax.id);
            taxes[index].amount += (item.price * item.quantity * itemTax.percentage) / 100;
          } else {
            taxes.push({
              tax: itemTax,
              amount: (item.price * item.quantity * itemTax.percentage) / 100,
              taxNumber: accountTaxes.find(accTax => accTax.taxId === itemTax.id)?.taxNumber || '',
            });
          }
        });
      });

      return taxes;
    })
  );

  processing = false;

  usersQuery$ = new BehaviorSubject<string>('');
  users$: Observable<User[]> = this.usersQuery$.pipe(
    debounceTime(250),
    switchMap(async query => (query ? await this.userSvc.searchUsers(query) : []))
  );

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.preview$.complete();
  }

  close() {
    this.dialogRef.close(null);
  }

  async submit() {
    this.processing = true;

    try {
      const preview = this.preview$.value;

      if (!preview) return;

      let purchase = await this.purchaseSvc.createPurchase(preview.dto, preview.hash, []);
      purchase = await this.purchaseSvc.handleTerminalPayment(purchase, this.dialogRef.afterClosed());

      if (purchase.status !== PurchaseStatus.PAID) {
        if (purchase.payment?.status === PaymentStatus.DELAYED_PROCESSING) {
          this.snacks.open('Custom charge will process in 3 or more business days', 'Ok', {
            duration: 2500,
            panelClass: 'mat-primary',
          });
        } else {
          console.error('Purchase could not be completed');
          this.snacks.open('Custom charge could not be completed', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        }
      } else {
        this.snacks.open('Successful custom charge!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
    }

    // await new Promise(res => setTimeout(res, 3000));

    this.processing = false;
    this.close();
  }
}
