<ng-container *ngIf="selectedVariant$ | async as selectedVariant">
  <ng-container *grecoLet="inventoryAddon$ | async as inventoryAddon">
    <ng-container *grecoLet="variantInventory$ | async as variantInventory">
      <div class="top-content" *ngIf="product">
        <!-- Left -->
        <h1 style="margin-bottom: 4px" class="show-mobile">{{ product.title }}</h1>
        <greco-product-images
          [product]="product"
          class="images"
          style="flex: 4; max-width: 100%"
          [selectedVariant]="selectedVariant"
        ></greco-product-images>

        <!-- Right -->
        <div class="details" style="flex: 5">
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <h1 style="margin-bottom: 4px" class="hide-mobile">{{ product.title }}</h1>
          </div>

          <greco-product-info [product]="product" [selectedVariant]="selectedVariant"></greco-product-info>

          <!-- Variant Selection -->
          <greco-product-variant-selection
            [product]="product"
            [selectedVariant]="selectedVariant"
            (selectedVariantChange)="selectedVariantId = $event.id"
            (validSelection)="canBuy = $event"
          ></greco-product-variant-selection>

          <div
            style="margin-bottom: 16px !important"
            *ngIf="!inventoryLoading && selectedVariant && inventoryAddon?.enabled && (inventoryAddon?.displayStockNumber || (variantInventory?.availableInventoryCount  || 0) <=0)"
          >
            <ng-container *ngIf="(variantInventory?.availableInventoryCount  || 0)> 0; else outOfStock">
              <greco-alert
                [config]="{title:  variantInventory?.availableInventoryCount + ' In Stock!' , type: $any('INFO')}"
              ></greco-alert>
            </ng-container>
            <ng-template #outOfStock>
              <greco-alert
                [config]="{title: inventoryAddon?.outOfStockMessage || 'Out of Stock!', type: $any('DANGER')}"
              ></greco-alert>
            </ng-template>
          </div>

          <!-- Buttons -->
          <greco-product-purchase-buttons
            [product]="product"
            [disabled]="!canBuy || (inventoryAddon?.enabled && (variantInventory?.availableInventoryCount || 0) <= 0)"
            [selectedVariant]="selectedVariant"
            [userId]="(user$ | async)?.id"
          ></greco-product-purchase-buttons>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-container *grecoLet="agreements | filterBy:['productAddOn.enabled']:true as enabledAgreements">
  <mat-tab-group *ngIf="enabledAgreements && enabledAgreements.length > 0">
    <mat-tab *ngFor="let usage of enabledAgreements" label="{{usage.agreement?.title}}">
      <div *ngIf="usage.agreement?.text" [innerHtml]="usage.agreement?.text || '' | safe:'html'"></div>
    </mat-tab>
  </mat-tab-group>
</ng-container>
