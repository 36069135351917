<div
  style="cursor: pointer; max-width: none"
  [matMenuTriggerFor]="menu"
  [style.height]="(options$ | async)?.length ? 'auto' : '12px'"
  [style.opacity]="disabled ? '0.8' : '1'"
  [style.pointer-events]="disabled || !(options$ | async)?.length ? 'none' : 'unset'"
>
  <div style="display: flex; align-items: center; gap: 16px; min-width: fit-content">
    <ng-container *ngIf="value; else empty">
      <ng-container *ngIf="value.model !== 'terminal'">
        <img style="width: 36px" [src]="'assets/payment-methods/' + $any(value).model + '.png'" />
        <span>
          {{ $any(value).model | titlecase }} <br />
          <i style="font-size: 75%; color: grey">{{$any(value).cardHolderName}}</i>
        </span>
        <span style="flex: 1">{{ value.label }}</span>
      </ng-container>

      <ng-container *ngIf="value.model === 'terminal'">
        <div style="width: 36px">
          <mat-icon style="color: inherit; display: block; margin: auto">contactless</mat-icon>
        </div>
        <div style="flex: 1">
          <p style="font-size: 16px; line-height: 16px; margin-bottom: 2px">{{ value.label }}</p>
          <p style="font-size: 12px; line-height: 12px; color: grey; margin-bottom: 0">
            Serial: {{ value.externalData?.serial_number || '-' }}
          </p>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #empty>
      <!-- <span style="margin-right: auto">{{placeholder || 'Select Payment Method'}}</span> -->
    </ng-template>

    <mat-icon *ngIf="!disabled && (options$ | async)?.length">arrow_drop_down</mat-icon>
  </div>
</div>

<mat-menu #menu xPosition="before">
  <ng-container *grecoLet="options$ | async as options">
    <button
      *ngFor="let option of options"
      mat-menu-item
      (click)="value = option"
      [disabled]="option.model === 'bank' && !allowBankPayments"
      [matTooltip]="option.model === 'bank' && !allowBankPayments ? 'This payment method is not allowed for this purchase' : ''"
    >
      <div style="display: flex; align-items: center; justify-content: flex-start; gap: 16px">
        <ng-container *ngIf="option.model === 'terminal'; else otherModels">
          <div style="width: 36px">
            <mat-icon style="color: inherit; display: block; margin: auto">contactless</mat-icon>
          </div>
          <div style="flex: 1">
            <p style="font-size: 16px; line-height: 16px; margin-bottom: 2px">{{ option.label }}</p>
            <p style="font-size: 12px; line-height: 12px; color: grey; margin-bottom: 0">
              Serial: {{ option.externalData?.serial_number || '-' }}
            </p>
          </div>
        </ng-container>

        <ng-template #otherModels>
          <img style="width: 36px" [src]="'assets/payment-methods/' + option.model + '.png'" />
          <span>{{option.model | titlecase}}</span>
          <span style="flex: 1">{{option.label}}</span>
          <span *ngIf="option.cardHolderName" style="color: grey">{{option.cardHolderName}}</span>
          <mat-chip-list *ngIf="option.id === userDefaultPaymentMethod">
            <mat-chip>Default</mat-chip>
          </mat-chip-list>
        </ng-template>
      </div>
    </button>

    <ng-container *ngIf="allowTerminals && (canManageTerminals$ | async)">
      <ng-container *ngFor="let dialog of extraDialogs">
        <mat-divider *ngIf="options?.length"></mat-divider>
        <button mat-menu-item (click)="$event.stopImmediatePropagation(); openExtra(dialog.dialog)">
          <div style="display: flex; gap: 16px; align-items: center; justify-content: space-between">
            <div style="font-weight: bold">{{ dialog.label }}</div>
            <mat-icon style="margin: 0; line-height: 18px; font-size: 18px; width: 18px; height: 18px; color: inherit">
              {{ dialog.icon }}
            </mat-icon>
          </div>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-menu>
