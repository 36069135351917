import { AfterViewInit, Component, Inject, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { toPromise } from '@greco-fit/util';
import { PropertyListener } from '@greco/property-listener-util';
import { AddonType, ProductConditionDto, UserAvailabilityAddonDto } from '@greco/sales-products';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  ConfigureConditionDialog,
  ConfigureConditionDialogData,
} from '../../../configure-condition/configure-condition.dialog';
import {
  ProductConditionForm,
  PRODUCT_CONDITION_FORMS,
} from '../../../configure-condition/product-condition-forms.token';

@Component({
  selector: 'greco-user-availability-configuration-form',
  templateUrl: './user-availability.component.html',
  styleUrls: ['./user-availability.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: UserAvailabilityFormComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: UserAvailabilityFormComponent, multi: true },
  ],
})
export class UserAvailabilityFormComponent implements ControlValueAccessor, Validator, AfterViewInit, OnDestroy {
  constructor(
    @Inject(PRODUCT_CONDITION_FORMS) public readonly forms: ProductConditionForm[],
    private matDialog: MatDialog
  ) {}

  @Input() communityId!: string;

  @ViewChildren(MatSelect) private selects?: QueryList<MatSelect>;

  conditions: ProductConditionDto[] = [];
  @PropertyListener('conditions') private conditions$ = new ReplaySubject<ProductConditionDto[]>(1);

  _addingCondition = false;
  _confirmingRemoval: null | number = null;

  readonly _editableMap: { [k: string]: boolean } = this.forms.reduce(
    (a, f) => ({ ...a, [f.type]: !!f.getFormModel }),
    {}
  );

  private onDestroy$ = new Subject();

  writeValue(value: UserAvailabilityAddonDto | null) {
    this.conditions = value?.conditions || [];
  }

  registerOnChange(fn: (value: UserAvailabilityAddonDto) => void) {
    this.conditions$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(conditions => fn({ type: AddonType.UserAvailability, conditions }));
  }

  registerOnTouched(_fn: () => void): void {
    // console.log('TODO: registerOnTouched', fn);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return null;
  }

  async addCondition(form: ProductConditionForm) {
    const model = form.getFormModel?.(this.communityId);
    if (model?.length) {
      const data: ConfigureConditionDialogData = { form, communityId: this.communityId };
      const dialog = this.matDialog.open(ConfigureConditionDialog, { data, width: '512px', maxWidth: 'calc(80vw)' });

      const result = await toPromise(dialog.afterClosed());
      if (result) {
        this._addingCondition = false;
        this.conditions = [...this.conditions, { ...result, type: form.type }];
      }
    } else {
      this._addingCondition = false;
      this.conditions = [...this.conditions, { type: form.type }];
    }
  }

  removeCondition(index: number) {
    this.conditions = this.conditions.filter((_, i) => i !== index);
    this._confirmingRemoval = null;
  }

  async updateConditionConfiguration(index: number, condition: ProductConditionDto) {
    const form = this.forms.find(f => f.type === condition.type);
    if (!form) return;

    const formModel = form.getFormModel?.(this.communityId);
    if (!formModel) return;

    const data: ConfigureConditionDialogData = { form, condition, communityId: this.communityId };
    const dialog = this.matDialog.open(ConfigureConditionDialog, { data, width: '512px', maxWidth: 'calc(80vw)' });

    const result = await toPromise(dialog.afterClosed());
    if (result) {
      this.conditions[index] = { ...result, type: form.type };
      this.conditions = [...this.conditions];
    }
  }

  ngAfterViewInit() {
    if (this.selects) {
      this.selects.changes
        .pipe(
          filter<QueryList<MatSelect>>(list => !!list.first),
          map(list => list.first)
        )
        .subscribe(select => setTimeout(() => select.open()));
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
