<greco-table [data]="(taxes$ | async) || []">
  <p *grecoTableCol="''; let tax; fitContent: true;" [matTooltip]="tax.id"><mat-icon>sell</mat-icon></p>
  <p *grecoTableCol="'Name'; let tax;">{{ tax.title }}</p>
  <p *grecoTableCol="'Abbreviation'; let tax;">{{ tax.abbreviation }}</p>
  <p *grecoTableCol="'Description'; let tax">{{ tax.description }}</p>
  <p *grecoTableCol="'Percentage'; let tax">{{ tax.percentage }} %</p>

  <ng-container *grecoTableCol="''; let tax; stickyEnd:true; fitContent:true">
    <button mat-icon-button (click)="updateTax(tax)">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
</greco-table>
<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="
    page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize });
  "
></mat-paginator>
