import { Component, EventEmitter, Inject, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { Contact } from '@greco/identity-contacts';
import { ContactService } from '../../services';

@Component({
  selector: 'greco-create-member-number',
  templateUrl: './create-member-number.component.html',
  styleUrls: ['./create-member-number.component.scss'],
})
export class CreateMemberNumberDialog implements OnChanges {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { contact: Contact },
    public dialogRef: MatDialogRef<CreateMemberNumberDialog>,
    private formBuilder: FormBuilder,
    private contactSvc: ContactService,
    private snacks: MatSnackBar
  ) {}

  @Output() saved = new EventEmitter();
  resetValue = { memberNumber: '' };
  form = this.formBuilder.group({
    memberNumber: [''],
  });
  dialogData: DialogData = {
    title: 'Create New Contact Member Number',
    subtitle: 'Please provide the credentials for the new member number',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: '' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          try {
            const contact: Contact = await this.contactSvc.editContactMemberNumber(this.data.contact.id, {
              memberNumber: this.form.value.memberNumber,
            });
            this.saved.emit();
            this.snacks.open('Member Number Updated!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
            return contact;
          } catch (err) {
            console.error(err);
            this.snacks.open('Unable to update Member Number', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
            return null;
          }
        },
      },
    ],
  };

  private reset() {
    this.resetValue = { memberNumber: this.data.contact.memberNumber || '' };
    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contact?.previousValue !== changes.contact?.currentValue) this.reset();
  }
}
