<div class="tabs-container">
  <div *ngIf="pageTitle" class="title-container">
    <greco-nav-title class="title" [showBackBtn]="false" [text]="pageTitle"></greco-nav-title>
  </div>

  <div class="tabs">
    <nav mat-tab-nav-bar *grecoLet="crumbs$ | async as crumbs" [class.crumbs]="crumbs.length > 1">
      <ng-container *ngIf="crumbs?.length > 1">
        <a
          *ngFor="let crumb of crumbs; let last = last"
          mat-tab-link
          [routerLink]="crumb.routerLink"
          queryParamsHandling="preserve"
          routerLinkActive="active-tab"
          [active]="last"
          [disabled]="last"
        >
          <mat-icon
            class="tab-icon"
            *ngIf="crumb.iconStart"
            [style.color]="last ? '' : 'rgba(14, 15, 15, 0.87)'"
            [style.opacity]="last ? '1' : '0.6'"
            [style.margin-right]="crumb.label || crumb.iconEnd ? '8px' : ''"
            >{{ crumb.iconStart }}</mat-icon
          >
          <span
            [style.color]="last ? '' : 'rgba(14, 15, 15, 0.87)'"
            [style.opacity]="last ? '1' : '0.6'"
            [innerHtml]="crumb.label | safe: 'html'"
          ></span>
          <mat-icon
            class="tab-icon"
            *ngIf="crumb.iconEnd"
            [style.color]="last ? '' : 'rgba(14, 15, 15, 0.87)'"
            [style.opacity]="last ? '1' : '0.6'"
            [style.margin-left]="crumb.label || crumb.iconStart ? '8px' : ''"
            >{{ crumb.iconEnd }}</mat-icon
          >
          <span *ngIf="!last" style="margin: 0 16px; color: rgba(14, 15, 15, 0.87); opacity: 0.6">/</span>
        </a>
      </ng-container>

      <ng-container *ngIf="!crumbs.length || crumbs.length <= 1">
        <a
          *ngFor="let tab of tabs$ | async"
          mat-tab-link
          [routerLink]="tab.routerLink"
          queryParamsHandling="preserve"
          routerLinkActive="active-tab"
          [routerLinkActiveOptions]="{ exact: true }"
          #rla="routerLinkActive"
          [active]="rla.isActive"
        >
          <mat-icon
            class="tab-icon"
            *ngIf="tab.iconStart"
            [style.margin-right]="tab.label || tab.iconEnd ? '8px' : ''"
            >{{ tab.iconStart }}</mat-icon
          >
          <span [innerHtml]="tab.label | safe: 'html'"></span>
          <mat-icon
            class="tab-icon"
            *ngIf="tab.iconEnd"
            [style.margin-left]="tab.label || tab.iconStart ? '8px' : ''"
            >{{ tab.iconEnd }}</mat-icon
          >
        </a>
      </ng-container>
    </nav>

    <div class="actions">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="content">
    <mat-card style="padding: 0; overflow: hidden">
      <router-outlet #outlet></router-outlet>
    </mat-card>
  </div>
</div>
