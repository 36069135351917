import { Component, HostBinding, Input } from '@angular/core';
import { Badge, BadgeShape } from '@greco/sales-perks';
import { fadeInOut } from '@greco/ui-animations';
import * as randomColor from 'random-color';

@Component({
  selector: 'greco-perk-badge',
  templateUrl: './perk-badge.component.html',
  styleUrls: ['./perk-badge.component.scss'],
  animations: [fadeInOut],
})
export class PerkBadgeComponent {
  inf = Infinity;

  @Input() set badge(badge: Badge) {
    this.shape = badge.shape;
    this.icon = badge.icon;
    this.text = badge.text;
    this.color = badge.color;
  }

  @Input() @HostBinding('class.small') small = false;
  @Input() @HostBinding('class.large') large = false;
  @Input() @HostBinding('class') shape?: BadgeShape = 'squircle';
  @Input() color: string | null = randomColor(0.7, 0.85).hexString();
  @Input() text?: string | null;
  @Input() icon?: string | null;
  @Input() quantity?: number | null;
}
