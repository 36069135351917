import { CurrencyPipe } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ProductVariant, ProductVariantRecurrence, RecurrencePeriod } from '@greco/sales-products';
import { SubscriptionRecurrence } from '@greco/sales-subscriptions';

@Pipe({
  name: 'variantPriceCost',
})
export class VariantPriceCostPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD'
  ) {}

  private _currency = new CurrencyPipe(this._locale, this._defaultCurrencyCode);

  transform(variant: ProductVariant): string {
    if (variant.recurrence?.frequency) {
      return variant.recurrence?.cycles
        ? this._currency.transform(variant.price / 100) + `/${this.formatFrequencyString(variant.recurrence)}`
        : (this._currency.transform((variant.price * this.getMultiplier(variant.recurrence)) / 100) || '') + `/week`;
    } else return this._currency.transform(variant.price / 100) || '';
  }

  formatFrequencyString(recurrence: SubscriptionRecurrence | ProductVariantRecurrence) {
    if (!recurrence) return '';
    return `${recurrence.frequency === 1 ? '' : recurrence.frequency + ' '}${this.getPeriodLabel(recurrence.period)}${
      recurrence.frequency > 1 ? 's' : ''
    }`;
  }

  private getPeriodLabel(period: RecurrencePeriod) {
    switch (period) {
      case RecurrencePeriod.Daily:
        return 'day';
      case RecurrencePeriod.Monthly:
        return 'month';
      case RecurrencePeriod.Weekly:
        return 'week';
      case RecurrencePeriod.Yearly:
        return 'year';
    }
  }

  private getMultiplier({ period, frequency }: ProductVariantRecurrence) {
    switch (period) {
      case RecurrencePeriod.Daily:
        return 7 / frequency;

      case RecurrencePeriod.Weekly:
        return 1 / frequency;

      case RecurrencePeriod.Monthly:
        return 1 / 4.34524 / frequency;

      case RecurrencePeriod.Yearly:
        return 1 / 52.1429 / frequency;

      default:
        return 0;
    }
  }
}
