import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@greco/identity-users';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase, PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'greco-user-outstanding-purchase-alert',
  templateUrl: './user-outstanding-purchase-alert.component.html',
  styleUrls: ['./user-outstanding-purchase-alert.component.scss'],
})
export class UserOutstandingPurchaseAlertComponent {
  constructor(private router: Router, private route: ActivatedRoute, private securitySvc: SecurityService) {}

  canVoid$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.VOID, {}, true);
  canRetry$ = this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.RETRY, {}, true);

  @Input() OutstandingPurchase!: Purchase;

  @Output() refreshPurchase = new EventEmitter<string>();

  @Input() small = false;

  @PropertyListener('OutstandingPurchase') private OutstandingPurchase$ = new BehaviorSubject<Purchase | null>(null);

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;

  async openPurchaseSummary(purchase: Purchase) {
    await this.router.navigate([`/account/purchase/${purchase.id}`], { relativeTo: this.route });
  }
}
