import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Video, VideoSourceRequestDto } from '@greco/videos';
import Player from '@vimeo/player';
import { UserVideoWatchTimeService } from '../../services';

@Component({
  selector: 'greco-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
  constructor(private watchTimeSvc: UserVideoWatchTimeService, private matDialog: MatDialog) {}

  @Input() video!: Video | VideoSourceRequestDto;
  @Input() watchTime = 0;
  player?: Player;

  lastUpdate: Date | null = null;
  refreshing = true;

  timeupdate(data: any, force = false, checkForComplete = false) {
    if (data) {
      this.watchTime = data.seconds;
      this.updateUserTime(force, checkForComplete);
    }
  }

  async ngAfterViewInit() {
    try {
      this.connectPlayer();
    } catch (err) {
      console.error(err);
    }
  }

  ngOnDestroy() {
    this.player?.off('ended');
    this.player?.off('play');
    this.player?.off('pause');
    this.player?.off('timeupdate');
    this.player?.off('seeked');
    this.player?.destroy();
  }

  async updateUserTime(force = false, checkForComplete = false) {
    if ((!force && this.lastUpdate && Date.now() - this.lastUpdate.getTime() < 15000) || !(this.video as Video)?.id) {
      return;
    } else {
      this.lastUpdate = new Date();
      await this.watchTimeSvc.updateVideoWatchTime((this.video as Video).id, this.watchTime, checkForComplete);
    }
  }

  connectPlayer() {
    if (this.video.source === 'vimeo') {
      this.player = new Player('video-player');
      this.player.on('play', data => {
        this.timeupdate(data, false);
      });
      this.player.on('ended', data => {
        this.timeupdate(data, true, true);
      });
      this.player.on('pause', data => {
        this.timeupdate(data, true);
      });
      this.player.on('timeupdate', data => {
        this.timeupdate(data);
      });
      this.player.on('seeked', data => {
        this.timeupdate(data, true);
      });
    }
  }
}
