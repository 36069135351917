import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { CouponService } from '../../services';

@Component({
  selector: 'greco-fit-display-coupon-stats',
  templateUrl: './display-coupon-stats.dialog.html',
  styleUrls: ['./display-coupon-stats.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DisplayCouponStatsDialog {
  constructor(
    private dialogRef: MatDialogRef<DisplayCouponStatsDialog>,
    private couponSvc: CouponService,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      coupon: any;
    }
  ) {
    this.grantedCoupons = data.coupon.grantedCoupons;
    this.couponTitle = data.coupon.title;
    this.dialogData.title += this.couponTitle;
  }

  grantedCoupons: {
    allUsers: number;
    individualUser: number;
    unlimitedAssignment: number;
  }[];
  couponTitle: string;

  dialogData: DialogData = {
    title: 'Coupon: ',
    subtitle: 'User Grant Statistics',
    showCloseButton: true,
    hideDefaultButton: true,
  };

  close() {
    this.dialogRef.close();
  }
}
