import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Community } from '@greco/identity-communities';
import { PropertyListener } from '@greco/property-listener-util';
import { Tile } from '@greco/tiles';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TilesService } from '../../services/tiles.service';

@Component({
  selector: 'greco-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private tileSvc: TilesService
  ) {}
  @Input() community!: Community;
  @PropertyListener('tile') tile$ = new BehaviorSubject<Tile | null>(null);

  favoriteHover = false;

  @Input() tile!: Tile;
  @Output() refresh = new EventEmitter();
  refresh$ = new BehaviorSubject(null);

  favorited$ = combineLatest([this.tile$, this.refresh$]).pipe(
    switchMap(async ([tile]) => {
      return tile ? this.tileSvc.isFavorited(tile.id) : false;
    })
  );

  toggleFavorite(id: string, communityId: string, community: Community | undefined) {
    this.tileSvc.toggleFavorite(id, communityId, community);
    this.refresh$.next(null);
    this.refresh.emit(id);
  }
}
