import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LegalService } from '../../services/legal.service';

@Component({
  selector: 'greco-edit-legal-page',
  templateUrl: './edit-legal.page.html',
  styleUrls: ['./edit-legal.page.scss'],
})
export class EditLegalPage implements OnInit {
  @Input() doc!: 'terms' | 'privacy';

  processing: boolean;
  formGroup = this.fb.group({ text: [''] });
  resetValue = {
    text: '',
  };

  constructor(private fb: FormBuilder, private legalService: LegalService) {
    this.processing = false;
  }

  async ngOnInit() {
    const value = {
      text: (this.doc === 'terms'
        ? await this.legalService.getTermsOfService()
        : await this.legalService.getPrivacyPolicy()
      )?.text,
    };
    this.resetValue = value;
    this.formGroup.setValue(value);
  }

  valueChanged(text: string) {
    if (this.formGroup.get('text')?.value !== text) {
      this.formGroup.get('text')?.setValue(text);
      this.formGroup.markAsDirty();
    }
  }

  save = async (formGroup: FormGroup) => {
    this.processing = true;
    try {
      if (this.doc === 'terms') await this.legalService.updateTermsOfService(formGroup.value.text);
      else await this.legalService.updatePrivacyPolicy(formGroup.value.text);
      this.resetValue = { text: formGroup.value.text };
      this.formGroup.reset(this.resetValue);
    } catch (error) {
      console.error(error);
    }
    this.processing = false;
  };
}
