<div id="dialog-header">
  <ng-content select="[before-header]"></ng-content>

  <div style="display: flex; align-items: center; gap: 16px">
    <div>
      <h2 mat-dialog-title [innerHtml]="data?.title || '' | safe : 'html'"></h2>
      <h3 *ngIf="data?.subtitle" [innerHtml]="data?.subtitle || '' | safe : 'html'">{{ data?.subtitle }}</h3>
    </div>
  </div>

  <ng-content select="[after-header]"></ng-content>
</div>

<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="fill">
    <mat-label>Choose a date</mat-label>
    <input matInput [min]="data.min" [max]="data.max" [matDatepicker]="picker" [formControl]="_formControl" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    *ngFor="let button of data.buttons"
    mat-button
    [color]="button.color || 'basic'"
    [disabled]="!_formControl.valid"
    (click)="close(button.role)"
  >
    {{ button.label }}
  </button>
</mat-dialog-actions>
