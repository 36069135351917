import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductVariant } from '@greco/sales-products';
import { Cart } from '@greco/sales-shop';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private snack: MatSnackBar) {}

  get(): Cart {
    return {
      total: Math.round(Math.random() * 1000),
      items:
        localStorage
          .getItem('cart')
          ?.split(';')
          .map(v => ({
            variant: {
              id: v,
            } as any,
            quantity: 1,
          })) || [],
    };
  }

  add(variant: ProductVariant) {
    const cartItems = localStorage.getItem('cart')?.split(';') || [];
    cartItems?.push(variant.id);
    localStorage.setItem('cart', cartItems?.join(';') || '');
    this.snack.open('Item added to cart!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    return cartItems;
  }

  remove(variant: ProductVariant) {
    const cartItems = localStorage.getItem('cart')?.split(';');
    if (cartItems && cartItems.indexOf(variant.id) >= 0) cartItems?.splice(cartItems.indexOf(variant.id), 1);
    localStorage.setItem('cart', cartItems?.join(';') || '');
    this.snack.open('Item removed from cart!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    return cartItems;
  }
}
