import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Cart } from '@greco/sales-shop';

@Component({
  selector: 'greco-cart-preview',
  templateUrl: './cart-preview.component.html',
  styleUrls: ['./cart-preview.component.scss'],
})
export class CartPreviewComponent {
  @Input() cart!: Cart;

  constructor(private router: Router) {}

  async openCart() {
    await this.router.navigate(['cart']);
  }

  async checkout() {
    await this.router.navigate(['checkout']);
  }
}
