<ng-container *grecoLet="bookingOption$ | async as bookingOption">
  <ng-container *grecoLet="availableBookingOptions$ | async as availableOptions">
    <ng-template *grecoLet="isMobile$ | async" #bottomSheetContent>
      <h2>Booking for {{ user?.friendlyName || user?.displayName }}</h2>

      <div class="container" [style.max-height]="'40vh'">
        <div style="padding-bottom: 8px; border-bottom: 2px dashed lightgray">
          <greco-item
            class="option-item select-item"
            *ngIf="bookingOption"
            [lines]="false"
            (click)="closeBottomSheet()"
          >
            <mat-icon item-start [style.color]="bookingOption.badge.color">local_activity</mat-icon>
            <ng-container *ngTemplateOutlet="optionDetails; context: { $implicit: bookingOption }"></ng-container>
            <mat-icon item-end>check_circle</mat-icon>
          </greco-item>
        </div>

        <div style="position: relative; max-height: 40vh; overflow-y: auto; padding: 8px 0">
          <!-- Complimentary -->
          <ng-container
            *ngIf="
              isStaffView && event?.security?.canBookComplimentary && bookingOption?.id !== 'prk_complimentarybooking'
            "
          >
            <ng-container *ngIf="availableOptions?.complimentaryOption as option">
              <ng-container *ngTemplateOutlet="optionRow; context: { $implicit: option }"></ng-container>
            </ng-container>
          </ng-container>

          <!-- User -->
          <ng-container *ngFor="let option of availableOptions?.userOptions">
            <ng-container *ngTemplateOutlet="optionRow; context: { $implicit: option }"></ng-container>
          </ng-container>

          <!-- Parent -->
          <ng-container *ngFor="let option of availableOptions?.parentOptions">
            <ng-container *ngTemplateOutlet="optionRow; context: { $implicit: option }"></ng-container>
          </ng-container>

          <!-- Pending -->
          <ng-container *ngIf="isStaffView && event?.security?.canBookPending">
            <greco-item
              *ngIf="availableOptions?.pendingOptions?.length"
              style="position: sticky; top: -8px; background-color: white; border-bottom: 2px dashed lightgray"
              [lines]="false"
            >
              <h2 style="margin: 4px 4px; padding: 0">Available for Pending Booking</h2>
            </greco-item>

            <ng-container *ngFor="let option of availableOptions?.pendingOptions">
              <ng-container *grecoLet="'lock-clock' as icon">
                <ng-container *ngTemplateOutlet="optionRow; context: { $implicit: option, icon: icon }"></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #optionRow let-option let-icon="icon">
  <greco-item class="option-item" [lines]="false" (click)="selectOption(option)">
    <mat-icon item-start [style.color]="option.badge.color">{{ icon ? icon : 'confirmation_number' }}</mat-icon>
    <ng-container *ngTemplateOutlet="optionDetails; context: { $implicit: option }"></ng-container>
    <mat-icon item-end>chevron_right</mat-icon>
  </greco-item>
</ng-template>

<ng-template #optionDetails let-option>
  <div *grecoLet="(option.userId | getEventAccount : eventAccounts || [])?.user as optionUser" class="option-container">
    <div class="option-title">
      <strong [matTooltip]="option.description || option.title">{{ option?.title }}</strong>

      <ng-container *ngIf="!mobile">
        <ng-container *ngTemplateOutlet="optionOwner; context: { $implicit: optionUser }"></ng-container>
      </ng-container>
    </div>

    <div
      class="details"
      *grecoLet="
        !option.userId || option.userId === user?.id
          ? option?.consumable || 0
          : option?.transferable || 0 as consumableCount
      "
    >
      <div *ngIf="mobile && user?.id !== eventAccounts![0].user.id" style="display: flex; column-gap: 12px">
        <ng-container *ngTemplateOutlet="optionOwner; context: { $implicit: optionUser }"></ng-container>

        <div *ngIf="mobile" class="divider"></div>
      </div>
      <ng-container
        *grecoLet="
          !option.userId || option.userId === user?.id
            ? option?.reusable || 0
            : option?.transferableReusable || 0 as reusableCount
        "
      >
        <div
          class="option-info"
          [matTooltip]="consumableCount + ' Available Now ' + (reusableCount || '0') + ' Reusable'"
        >
          <strong *ngIf="!option.userId || option.userId === user?.id; else transferable">Available</strong>
          <ng-template #transferable>
            <strong>Transferable</strong>
          </ng-template>

          <span *ngIf="consumableCount && !reusableCount">{{ consumableCount }}</span>
          <span *ngIf="reusableCount">{{ reusableCount }}<mat-icon>replay</mat-icon></span>
          <span *ngIf="!consumableCount && !reusableCount">-</span>
        </div>
      </ng-container>

      <div *ngIf="mobile" class="divider"></div>

      <div class="option-info">
        <strong *ngIf="!mobile">Price</strong>
        <span>{{ option.price / 100 | currency }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #optionOwner let-optionUser>
  <span
    *ngIf="optionUser?.id && (eventAccounts?.length || 0) > 1 && user?.id !== eventAccounts![0].user.id"
    style="display: flex; flex-direction: row; align-items: center; gap: 4px"
  >
    {{
      isStaffView || optionUser?.id !== eventAccounts![0].user.id
        ? (optionUser?.friendlyName || optionUser?.displayName || 'Guest') + "'s"
        : 'Your'
    }}
    perk
    <mat-icon
      *ngIf="optionUser?.id === eventAccounts![0].user.id"
      style="width: 16px; height: 16px; line-height: 16px; font-size: 16px"
    >
      swap_horizontal
    </mat-icon>
  </span>
</ng-template>

<div class="alert-container">
  <greco-alert
    *ngIf="areBoostersRequired && !boostersActivated"
    class="alert"
    style="width: 100%"
    [config]="{
      type: $any('DANGER'),
      title: 'Outside of Booking Window'
    }"
  >
    <button slot="buttons" mat-button style="color: var(--warn-color)" (click)="applyBoosters()">Apply Boosters</button>
  </greco-alert>

  <alt-event-missing-perks-alert
    *ngIf="!bookingOption"
    style="margin-bottom: 8px"
    [event]="event"
    [user]="user"
  ></alt-event-missing-perks-alert>

  <greco-alert
    *ngIf="alertConfig$ | async as alertConfig"
    #alertContainer
    class="alert"
    [config]="alertConfig"
  ></greco-alert>
</div>
