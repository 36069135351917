<ng-container *grecoLet="eventService.event$ | async as event">
  <ng-container *grecoLet="eventService.bookingsInfo$ | async as bookings">
    <ng-container *grecoLet="eventService.accounts$ | async as eventAccounts">
      <ng-container *grecoLet="eventService.alternateAccounts$ | async as alternateAccounts">
        <div *ngIf="showNavbar" class="nav-toolbar">
          <div [class.mobile]="isMobile$ | async">
            <button mat-icon-button (click)="navigateBack()">
              <mat-icon>arrow_back</mat-icon>
            </button>

            <div
              class="event-title"
              [class.mobile]="isMobile$ | async"
              [matTooltip]="event?.title || ''"
              [matTooltipDisabled]="!event || event.id !== eventId || ((isMobile$ | async) === false)"
            >
              <h2 [class.loading]="!event || event.id !== eventId">
                <span *ngIf="event?.id === eventId">{{ event?.title }}</span>
              </h2>
              <h2>
                <small *ngIf="event && event.id === eventId">
                  | {{ event?.startTime | date : 'MMMM d, h:mm a' }}
                </small>
              </h2>
            </div>
          </div>

          <mat-progress-bar
            *ngIf="!event || event.id !== eventId"
            mode="buffer"
            class="toolbar-loading"
          ></mat-progress-bar>
        </div>

        <ng-container *grecoLet="eventService.attendees$ | async as attendees">
          <ng-container *grecoLet="eventService.waitlist$ | async as waitlist">
            <div class="event-content">
              <ng-container *ngIf="event && event.id === eventId && bookings; else loading">
                <img
                  [src]="event.imageUrl || event.calendar.imageUrl || 'assets/greco/placeholder.png'"
                  alt="Event Image"
                  class="event-image"
                />

                <p class="spots-left">
                  <ng-container *ngIf="event.capacityRemaining; else noSpots">
                    {{ event.capacityRemaining }} Spot{{ event.capacityRemaining === 1 ? '' : 's' }} Left
                  </ng-container>

                  <ng-template #noSpots> Full </ng-template>
                </p>

                <ng-container *ngIf="event.requirements?.spotBooking?.room?.imageURL">
                  <p class="view-spots" (click)="bottomSheet.open(roomDetailsSheet, { panelClass: 'bottom-sheet' })">
                    View Room
                  </p>

                  <ng-template #roomDetailsSheet>
                    <div class="bottom-sheet-content">
                      <div class="sheet-header">
                        <h2 #header>{{ event.requirements.spotBooking?.room?.name || 'Room' }} Details</h2>

                        <button mat-icon-button (click)="bottomSheet.dismiss()">
                          <mat-icon>expand_more</mat-icon>
                        </button>
                      </div>

                      <div>
                        <alt-room-spot-picker [readonly]="true" [event]="event"> </alt-room-spot-picker>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>

                <div class="text-content">
                  <p class="time">
                    <mat-icon>location_on</mat-icon> {{ event.calendar.title }} - {{ event.location.label }}
                  </p>

                  <div *ngIf="event.trainers?.length" class="trainers">
                    <span>Trainer{{ event.trainers.length > 0 ? 's' : '' }}</span>

                    <mat-chip-list [selectable]="false">
                      <div class="trainer-chip" *ngFor="let trainer of event.trainers">
                        <img
                          *ngIf="$any(trainer).imageUrl || $any(trainer).user?.photoUrl"
                          [src]="$any(trainer).imageUrl || $any(trainer).user?.photoUrl"
                          alt="Trainer Image"
                          class="trainer-image"
                        />
                        {{ trainer.label }}
                      </div>
                    </mat-chip-list>
                  </div>

                  <p class="event-description" *ngIf="event.description">{{ event.description }}</p>
                </div>

                <div class="alerts">
                  <greco-frozen-subscription-alert
                    *ngIf="event.hasFrozenSubscription"
                    [isUser]="!isStaffView"
                    style="display: block; margin-bottom: 8px"
                  ></greco-frozen-subscription-alert>

                  <alt-event-time-alert [event]="event" [staffView]="isStaffView"></alt-event-time-alert>

                  <greco-alert
                    *ngIf="event.capacityRemaining === 0 || event!.capacityRemaining < bookings!.length"
                    style="display: block; margin-bottom: 8px"
                    [config]="$any({
                      title: 'Event is Full', 
                      type: 'INFO', 
                      icon: 'person_pin',  
                      description: event.capacityRemaining === 0 
                        ? 'This event is at capacity. However, you can join the waitlist if you would like to be notified when spots become available.' 
                        : 'You have more bookings than available spots left in the event. However, you can join the waitlist if you would like to be notified when spots become available, or you may adjust your bookings to match the event capacity.' 
                    })"
                  ></greco-alert>

                  <alt-event-failed-payment-alert [event]="event"></alt-event-failed-payment-alert>

                  <alt-event-payment-method-alert [event]="event"></alt-event-payment-method-alert>
                </div>

                <greco-collapsible-section
                  *ngIf="attendees?.length && (bookings.length || waitlist?.length); else attendeeItems"
                  [header]="{title: 'Existing Bookings', icon: 'event_available', badge: ''+attendees!.length}"
                >
                  <ng-container [ngTemplateOutlet]="attendeeItems"></ng-container>
                </greco-collapsible-section>

                <ng-template #attendeeItems>
                  <alt-attendee-card
                    *ngFor="let attendee of attendees"
                    [event]="event"
                    [booking]="attendee"
                    [isStaffView]="isStaffView"
                  ></alt-attendee-card>
                </ng-template>

                <greco-collapsible-section
                  *ngIf="bookings.length && (attendees?.length || waitlist?.length); else bookingItems"
                  [header]="{title: 'New Bookings', icon: 'edit_calendar', badge: ''+bookings!.length}"
                >
                  <ng-container [ngTemplateOutlet]="bookingItems"></ng-container>
                </greco-collapsible-section>

                <ng-template #bookingItems>
                  <alt-booking-card2
                    *ngFor="let booking of bookings; let last = last"
                    [event]="event"
                    [user]="booking.user!"
                    [spotId]="booking.spotId"
                    [confirming]="confirming"
                    [isStaffView]="isStaffView"
                    [waitlist]="waitlist || []"
                    [eventAccounts]="eventAccounts || []"
                    [bookingCount]="bookings?.length || 0"
                    [altAccounts]="alternateAccounts || []"
                    [selectedBookingOption]="booking.option"
                    [boostersActivated]="booking.boostersActivated || false"
                    [canLeaveUnsigned]="(canLeaveUnsigned$ | async) === true"
                    [allowRemoval]="!!attendees?.length || !!waitlist?.length"
                  >
                    <!-- [style.margin-bottom]="last ? '56px' :'0px'" -->
                  </alt-booking-card2>
                </ng-template>

                <ng-container
                  *ngIf="waitlist?.length && !bookings.length && !attendees?.length; else collapsibleWaitlist"
                >
                  <greco-collapsible-section
                    [header]="{title: 'Waitlist', icon: 'event_busy', badge: ''+ waitlist!.length}"
                    [expanded]="true"
                    [disabled]="true"
                  >
                    <alt-waitlist-card
                      *ngFor="let user of waitlist"
                      [event]="event"
                      [user]="user"
                      [eventAccounts]="eventAccounts || []"
                    ></alt-waitlist-card>
                  </greco-collapsible-section>
                </ng-container>

                <ng-template #collapsibleWaitlist>
                  <greco-collapsible-section
                    *ngIf="waitlist?.length && (attendees?.length || bookings?.length)"
                    [header]="{title: 'Waitlist', icon: 'event_busy', badge: ''+ waitlist!.length}"
                  >
                    <alt-waitlist-card
                      *ngFor="let user of waitlist"
                      [event]="event"
                      [user]="user"
                      [eventAccounts]="eventAccounts || []"
                    ></alt-waitlist-card>
                  </greco-collapsible-section>
                </ng-template>

                <alt-add-booking-card
                  *ngIf="!bookings.length"
                  [event]="event"
                  [eventAccounts]="eventAccounts || []"
                  [disabled]="!(isStaffView || event.startTime > now)"
                ></alt-add-booking-card>
              </ng-container>

              <ng-template #loading>
                <div class="img-placeholder"></div>
                <div class="content-placeholder"></div>
              </ng-template>
            </div>

            <div class="booking-footer" [style.--bottom]="'0px'">
              <ng-container *grecoLet="eventService.purchaseItems$ | async as items">
                <ng-container *ngIf="items && bookings; else noBookingsOrLoading">
                  <div *grecoLet="eventService.total$ | async as total" class="footer-content">
                    <div
                      *ngIf="bookings?.length"
                      style="display: flex; flex-direction: row; gap: 4px; cursor: pointer"
                      [style.cursor]="event!.capacityRemaining < bookings!.length ? 'default' : 'pointer'"
                      (click)="event!.capacityRemaining < bookings!.length ? {} :bottomSheet.open(bookingDetailsSheet, {panelClass: 'bottom-sheet'})"
                    >
                      <div class="footer-text" *grecoLet="eventService.paymentMethodToUse$ | async as paymentMethod">
                        <h3>
                          Total: {{items === null ? '' : event!.capacityRemaining < bookings!.length ? '---' : ((total
                          || 0)/100 | currency)}}
                        </h3>
                        <p style="margin-bottom: 0" *ngIf="!!paymentMethod; else selectPaymentMethod">
                          <small style="display: flex; align-items: center; gap: 4px">
                            <img style="width: 24px" [src]="'assets/payment-methods/' + paymentMethod.model + '.png'" />
                            <span style="color: var(--primary-color); line-height: 14px">
                              {{paymentMethod.label}}
                            </span>
                          </small>
                        </p>
                        <ng-template #selectPaymentMethod>
                          <ng-container *ngIf="eventService.paymentMethodError$ | async">
                            <p style="margin-bottom: 0">
                              <small style="display: flex; align-items: center; gap: 4px">
                                <span style="color: var(--warn-color); text-decoration: underline">
                                  Select payment method
                                </span>
                                <mat-icon
                                  style="line-height: 14px; width: 14px; height: 14px; font-size: 14px"
                                  color="warn"
                                  >error_outline</mat-icon
                                >
                              </small>
                            </p>
                          </ng-container>
                        </ng-template>
                      </div>
                      <button mat-icon-button>
                        <mat-icon>arrow_drop_down</mat-icon>
                      </button>
                    </div>

                    <ng-template #noBookings>
                      <div class="footer-text">
                        <p style="margin-bottom: 0">
                          <!-- Noop -->
                        </p>
                      </div>
                    </ng-template>

                    <ng-container *ngIf="bookings.length; else cancelOrWaitlist">
                      <div *grecoLet="isMobile$ | async as isMobile" class="book-buttons">
                        <mat-progress-spinner *ngIf="confirming" [diameter]="20" color="primary"></mat-progress-spinner>

                        <ng-container *grecoLet="eventService.bookingOptionErrors$ | async as bookingOptionErrors">
                          <ng-container *grecoLet="eventService.bookingTotals$ | async as bookingTotals">
                            <ng-container
                              *grecoLet="
                                (confirming 
                                  || !bookings?.length
                                  || !event?.security?.canBook
                                  || ((eventService.allRequirementsCompleted$ | async) !== true) 
                                  || ((eventService.allAlertsCompleted$ | async) !== true) 
                                  || !!bookingOptionErrors?.conflicts?.length
                                  || !!bookingOptionErrors?.transferableConflicts?.length
                                  || ((eventService.allBookingsAvailable$ | async) !== true)
                                  || (eventService.paymentMethodError$ | async)
                                  || (eventService.areAllBoostersActivated$ | async) !== true) as confirmDisabled
                              "
                            >
                              <!-- Staff Book -->
                              <button
                                *ngIf="!isMobile && isStaffView && event!.capacityRemaining >= bookings!.length"
                                mat-stroked-button
                                color="primary"
                                [disabled]="confirmDisabled"
                                [style.border]=" !confirmDisabled ? '1px solid var(--primary-color)' : ''"
                                (click)="confirmBooking(event!, bookingTotals || [], true)"
                              >
                                {{ total && total > 0 ? 'Purchase' :'Book' }} and Add Another
                              </button>

                              <!-- Book Button -->
                              <button
                                *ngIf="event!.capacityRemaining >= bookings!.length; else joinWaitlistButton"
                                mat-flat-button
                                color="primary"
                                [disabled]="confirmDisabled"
                                (click)="confirmBooking(event!, bookingTotals || [])"
                              >
                                <mat-icon *ngIf="confirming" class="spin">loop</mat-icon>

                                {{ total && total > 0 ? 'Purchase' :'Book' }} {{ ( !isMobile && bookings?.length === 1 ?
                                (bookings![0].user?.id === bookingUser?.id && !isStaffView) ? '' : ('for ' +
                                (bookings![0].user?.friendlyName || bookings![0].user?.displayName)) :
                                ((bookings?.length || 0) < 2 ? '' : '(' + bookings?.length + ')')) }}
                              </button>
                            </ng-container>
                          </ng-container>
                        </ng-container>

                        <ng-template #joinWaitlistButton>
                          <button
                            mat-flat-button
                            color="primary"
                            [disabled]="confirming"
                            (click)="joinWaitlist(event!)"
                          >
                            Join Waitlist {{ ( !isMobile && bookings?.length === 1 ? (bookings![0].user?.id ===
                            bookingUser?.id && !isStaffView) ? '' : ('for ' + (bookings![0].user?.friendlyName ||
                            bookings![0].user?.displayName)) : ((bookings?.length || 0) < 2 ? '' : '(' +
                            bookings?.length + ')')) }}
                          </button>
                        </ng-template>

                        <button
                          #menuTrigger="matMenuTrigger"
                          mat-flat-button
                          color="primary"
                          [disabled]="confirming || !(alternateAccounts?.length || event?.security?.canBookGuest)"
                          [matMenuTriggerFor]="linkedAccountsMenu"
                        >
                          <mat-icon>person_add</mat-icon>
                        </button>

                        <mat-menu class="linked-accounts-menu" #linkedAccountsMenu="matMenu" xPosition="before">
                          <!-- Children -->
                          <div
                            *ngFor="let account of alternateAccounts"
                            class="account-item"
                            (click)="
                              account.userStatusInfo === 'booked' 
                                ? $event.stopImmediatePropagation()
                                : addBooking(event!, account)
                            "
                          >
                            <h3>{{ account.user.friendlyName || account.user.displayName }}</h3>

                            <div *ngIf="account.userStatusInfo !== 'booked'; else booked">
                              <button
                                mat-icon-button
                                #swapMenuTrigger="matMenuTrigger"
                                [matMenuTriggerFor]="swapMenu"
                                (click)="
                                  $event.stopImmediatePropagation();
                                  (bookings?.length === 1 
                                    ? swapBooking(
                                      0,
                                      event!,
                                      account
                                    ) 
                                    : {});
                                    (bookings?.length === 1 ? menuTrigger.closeMenu() : {})
                                "
                              >
                                <mat-icon>social_distance</mat-icon>
                              </button>

                              <button mat-icon-button>
                                <mat-icon>person_add</mat-icon>
                              </button>

                              <mat-menu class="linked-accounts-menu" #swapMenu="matMenu" xPosition="before">
                                <div
                                  *ngFor="let booking of bookings"
                                  class="account-item"
                                  (click)="
                                    eventService.swapBooking(
                                      booking.userId,
                                      {
                                        userId: account.user.id,
                                        eventId: event?.id || '',
                                        bookingOptionId: $any(account.bookingOptions[0])?.id || '',
                                        bookingOptionUserId: $any(account.bookingOptions[0])?.userId || ''
                                      }
                                    );
                                    swapMenuTrigger.closeMenu(); menuTrigger.closeMenu();
                                  "
                                >
                                  <h3>Swap with {{ booking.user?.friendlyName || booking.user?.displayName }}</h3>
                                </div>

                                <p *ngIf="!bookings?.length" class="account-item" style="pointer-events: none">
                                  No users to swap
                                </p>
                              </mat-menu>
                            </div>

                            <ng-template #booked>
                              <mat-icon style="color: var(--success-color)" matTooltip="Already booked for this event!">
                                event_available
                              </mat-icon>
                            </ng-template>
                          </div>

                          <div
                            *ngIf="event?.security?.canBookGuest"
                            class="account-item"
                            (click)="addGuest(event!.accounts[0].user, event!)"
                          >
                            <h3>Invite Guest</h3>
                            <div>
                              <button mat-icon-button>
                                <mat-icon>person_add</mat-icon>
                              </button>
                            </div>
                          </div>
                        </mat-menu>
                      </div>
                    </ng-container>

                    <ng-template #cancelOrWaitlist>
                      <div style="width: 100%; display: flex; justify-content: center; gap: 8px">
                        <button
                          *ngIf="waitlist && waitlist.length"
                          mat-stroked-button
                          color="primary"
                          (click)="removeAllFromWaitlist(event!)"
                        >
                          Remove All From Waitlist
                        </button>
                        <button
                          *ngIf="attendees && attendees.length && event!.startTime > now"
                          mat-flat-button
                          color="warn"
                          (click)="cancelAllBookings()"
                          [disabled]="confirming"
                        >
                          Cancel All Bookings
                        </button>
                      </div>
                    </ng-template>
                  </div>

                  <ng-template #bookingDetailsSheet>
                    <div class="bottom-sheet-content">
                      <div class="sheet-header">
                        <h2 #header>Your Purchase</h2>
                        <button mat-icon-button (click)="bottomSheet.dismiss()">
                          <mat-icon>expand_more</mat-icon>
                        </button>
                      </div>

                      <div *grecoLet="eventService.total$ | async as total">
                        <greco-purchase-preview-table *ngIf="items?.length" [items]="items" [showUsername]="true">
                        </greco-purchase-preview-table>

                        <mat-divider *ngIf="items?.length"></mat-divider>

                        <div
                          style="width: 100%"
                          class="payment-method-container"
                          *grecoLet="eventService.userId$ | async as userId"
                        >
                          <mat-form-field
                            *ngIf="userId"
                            appearance="standard"
                            class="payment-method-picker-form-field"
                            style="width: 100%"
                          >
                            <mat-label *ngIf="!paymentMethodControl.value"><i>Add a Payment Method</i></mat-label>

                            <greco-select-payment-method
                              style="width: 100%"
                              #selectPaymentMethodInput
                              [formControl]="paymentMethodControl"
                              [userId]="userId"
                              [required]="true"
                              [allowBankPayments]="false"
                              (valueChange)="updatePaymentMethod($event)"
                            ></greco-select-payment-method>

                            <greco-add-payment-method-button
                              matSuffix
                              [onlyIcon]="true"
                              [userId]="userId"
                              [hiddenForms]="['Bank Account']"
                              (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
                            ></greco-add-payment-method-button>
                          </mat-form-field>
                        </div>

                        <small>Used to cover any cancellation fees for these bookings </small>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>

                <!-- Page is still loading, or there are no bookings with available options -->
                <ng-template #noBookingsOrLoading>
                  <div class="footer-content">
                    <p
                      *ngIf="bookings?.length === 0; else bookingsLoading"
                      style="margin-bottom: 0; width: 100%; text-align: center"
                    >
                      <span style="color: var(--warn-color)">No option to book this event!</span>
                    </p>

                    <ng-template #bookingsLoading>
                      <h2 class="bookings-placeholder"></h2>
                    </ng-template>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
