<form [formGroup]="_form">
  <div class="color-and-title">
    <!-- Color -->
    <div matPrefix class="color">
      <mat-form-field style="width: 48px" appearance="standard">
        <input #colorInput matInput type="color" [required]="required" [disabled]="readonly" formControlName="color" />
        <mat-icon class="color-icon">palette</mat-icon>
      </mat-form-field>
    </div>

    <!-- Title -->
    <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
      <mat-label>Title</mat-label>
      <input
        matInput
        type="text"
        [required]="required"
        [disabled]="readonly || (!customAccess && titleLocked)"
        formControlName="title"
      />
    </mat-form-field>
  </div>

  <!-- Event Image -->
  <mat-form-field floatLabel="always">
    <mat-label>Image</mat-label>
    <greco-image-upload
      formControlName="imageUrl"
      [required]="false"
      [multiple]="false"
      [urls]="initialImage ? [initialImage] : []"
      [disabled]="readonly || (!customAccess && imageUrlLocked)"
    ></greco-image-upload>
  </mat-form-field>

  <!-- Description -->
  <mat-form-field appearance="standard">
    <mat-label>Event Description</mat-label>
    <textarea
      matInput
      formControlName="description"
      rows="3"
      [disabled]="readonly || (!customAccess && descriptionLocked)"
    ></textarea>
  </mat-form-field>

  <!-- Calendar -->
  <div *grecoLet="calendars$ | async as calendars">
    <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
      <mat-label>Calendar</mat-label>
      <mat-select [required]="true" [disabled]="readonly || isCompleted" formControlName="calendarId">
        <ng-container *ngIf="calendars?.length; else noCalendars">
          <mat-option *ngFor="let calendar of calendars" [value]="calendar.id"> {{ calendar.title }}</mat-option>
        </ng-container>
        <ng-template #noCalendars>
          <mat-option [disabled]="true">
            <i>No Calendars Found (You may not have been granted access to a calendar)</i>
          </mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Event Start/Timezone -->
  <mat-form-field *ngIf="!forSeries" appearance="standard">
    <mat-label>Event Start</mat-label>
    <greco-datetime-timezone-input
      #startDatetimePicker
      [required]="true"
      [disabled]="readonly || isCompleted"
      formControlName="eventStart"
    ></greco-datetime-timezone-input>
  </mat-form-field>

  <!-- Duration -->
  <mat-form-field class="flex-1" appearance="standard">
    <mat-label>Duration</mat-label>
    <greco-minutes-input
      [required]="required"
      [disabled]="readonly || (!customAccess && durationLocked)"
      placeholder="Duration"
      formControlName="duration"
    ></greco-minutes-input>
  </mat-form-field>

  <!-- Room Resources-->
  <mat-form-field appearance="standard" *ngIf="communityId && !lockResources">
    <mat-label>Room</mat-label>
    <greco-assignment-picker
      [communityId]="communityId"
      [availability]="availability"
      [disabled]="lockResources || readonly || (!customAccess && resourcesLocked)"
      [filterType]="{ type: 'ROOM', limit: 10, exclude: null }"
      (resourceChanged)="updateCapacity($any($event))"
      placeholder="Room Resource"
      formControlName="roomAssignment"
    ></greco-assignment-picker>
    <mat-checkbox
      matSuffix
      color="primary"
      formControlName="enableUserSpotBooking"
      [disabled]="lockResources || readonly || (!customAccess && enableUserSpotBookingLocked)"
      >Allow users to book spots
    </mat-checkbox>
  </mat-form-field>

  <!-- Resources -->
  <mat-form-field appearance="standard" *ngIf="communityId && !lockResources">
    <mat-label>Resource Assignments</mat-label>
    <greco-event-resource-assignment
      #resourceAssignments
      *ngIf="communityId && !lockResources"
      [communityId]="communityId"
      [eventId]="eventId"
      [forSeries]="forSeries"
      [startDate]="_form.value.eventStart.date"
      [duration]="_form.value.duration"
      [disabled]="lockResources || readonly || (!customAccess && resourcesLocked)"
      (resourceAvailability)="availability = $event"
      formControlName="resourceAssignments"
    ></greco-event-resource-assignment>
  </mat-form-field>
  <mat-checkbox
    *ngIf="communityId && !lockResources"
    color="primary"
    [disabled]="resourcesLocked || readonly"
    formControlName="autoAssign"
  >
    Allow auto-assignment for substitutions
  </mat-checkbox>

  <!-- Tags -->
  <greco-tags-input
    *ngIf="communityId"
    [availableAsCourse]="availableAsCourse"
    [required]="required"
    [communityId]="communityId"
    [disabled]="readonly || (!customAccess && tagsLocked)"
    [tableEnabled]="true"
    formControlName="tags"
    placeholder="Tags"
  ></greco-tags-input>

  <div style="display: flex; flex-wrap: wrap; gap: 12px">
    <!-- Check-In Window -->
    <mat-form-field class="flex-1" appearance="standard" style="min-width: 300px">
      <mat-label>Booking Cut-Off Window</mat-label>
      <greco-minutes-input
        [required]="required"
        [disabled]="readonly || (!customAccess && checkInWindowLocked)"
        placeholder="Cut-Off Window"
        formControlName="checkInWindow"
      ></greco-minutes-input>
    </mat-form-field>

    <mat-form-field class="flex-1" appearance="standard">
      <mat-label>Attendee Capacity</mat-label>
      <input
        matInput
        type="number"
        min="0"
        step="1"
        placeholder="Max Capacity"
        formControlName="maxCapacity"
        [disabled]="readonly || (!customAccess && maxCapacityLocked)"
      />
      <mat-icon
        *ngIf="_form.value.roomAssignment && _form.value.maxCapacity > _form.value.roomAssignment?.resource.spotCount"
        matSuffix
        style="color: var(--warn-color)"
        [matTooltip]="
          'Attendee capacity is greater than room capacity (' + _form.value.roomAssignment.resource.spotCount + ')!'
        "
      >
        error_outlined
      </mat-icon>
    </mat-form-field>
  </div>

  <!-- Private -->
  <mat-form-field *ngIf="!lockResources" appearance="standard">
    <input matInput style="display: none" />
    <mat-checkbox formControlName="private" color="primary" [disabled]="readonly || (!customAccess && privateLocked)">
      Private Event
    </mat-checkbox>
  </mat-form-field>

  <!-- Resources For Zoom-->
  <mat-form-field appearance="standard" *ngIf="communityId && !lockResources">
    <mat-label>Zoom Resource</mat-label>
    <greco-assignment-picker
      [communityId]="communityId"
      [disabled]="lockResources || readonly || (!customAccess && resourcesLocked)"
      formControlName="zoomAssignment"
      placeholder="Zoom Resource"
      [filterType]="{ type: 'ZOOM', limit: 10, exclude: null }"
    ></greco-assignment-picker>
  </mat-form-field>

  <!--manual Input for meetingId-->
  <mat-form-field appearance="standard" *ngIf="communityId && !lockResources && !_form.value.zoomAssignment">
    <mat-label>Zoom Meeting ID</mat-label>
    <input
      matInput
      type="text"
      [disabled]="readonly"
      formControlName="zoomMeetingId"
      placeholder="Manually Input Zoom Meeting ID"
    />
  </mat-form-field>

  <!-- Typeform -->
  <mat-form-field appearance="standard">
    <mat-label>Typeform</mat-label>
    <greco-typeform-form-picker
      formControlName="typeform"
      [communityId]="communityId"
      [disabled]="readonly || (!customAccess && typeformLocked)"
      placeholder="Select one or multiple forms"
    ></greco-typeform-form-picker>
  </mat-form-field>
</form>
