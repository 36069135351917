import { SecurityResource } from '@greco/security';

export enum AccountLinkingSecurityResourceAction {
  READ = 'READ',
  CREATE_LINK = 'CREATE_LINK',
  UPDATE = 'UPDATE',
  REVOKE = 'REVOKE',
  REQUEST = 'REQUEST',
  CREATE_CHILD = 'CREATE_CHILD',
}

export const AccountLinkingSecurityResource: SecurityResource = {
  key: 'identity_account_linking',
  module: 'Identity',

  title: 'Account Linking',
  description: 'Linking User Accounts',

  context: class {
    communityId!: string;
  },

  actions: [
    {
      key: AccountLinkingSecurityResourceAction.READ,
      title: 'Read',
      description: 'Ability to read/view linked user accounts ',
    },
    {
      key: AccountLinkingSecurityResourceAction.CREATE_LINK,
      title: 'Create Link',
      description: 'Ability to create auto-approved account links',
    },
    {
      key: AccountLinkingSecurityResourceAction.CREATE_CHILD,
      title: 'Create Child',
      description: 'Ability to create linked child accounts',
    },
    {
      key: AccountLinkingSecurityResourceAction.REQUEST,
      title: 'Request Link',
      description: 'Ability to request account link between users',
    },
    {
      key: AccountLinkingSecurityResourceAction.UPDATE,
      title: 'Update',
      description: 'Ability to update permissions of user account links',
    },
    {
      key: AccountLinkingSecurityResourceAction.REVOKE,
      title: 'Revoke',
      description: 'Ability to revoke/remove links between user accounts',
    },
  ],
};
