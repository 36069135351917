<greco-table [data]="(subAction$ | async) || []" [highlight]="true">
  <!-- Status -->
  <mat-icon *grecoTableCol="''; fitContent: true; let subAction" [matTooltip]="subAction.id">autorenew</mat-icon>

  <!-- Status -->
  <p *grecoTableCol="'Status'; let subAction">{{ subAction.status }}</p>
  <!-- Type -->
  <p *grecoTableCol="'Type'; let subAction">{{ subAction.type }}</p>
  <!-- TimeStamp -->
  <p *grecoTableCol="'Timestamp'; let subAction">{{ subAction.timestamp }}</p>
</greco-table>
