import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import {
  AccountLink,
  AccountLinkingSecurityResource,
  AccountLinkingSecurityResourceAction,
  AccountLinkPrivilege,
  AccountLinkStatus,
} from '@greco/account-linking';
import { UserService as AuthUserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AccountLinkingService } from '../../services';

@Component({
  templateUrl: './update-link.dialog.html',
  styleUrls: ['./update-link.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UpdateAccountLinkDialog implements OnInit, OnDestroy {
  constructor(
    private authSvc: AuthUserService,
    private formBuilder: FormBuilder,
    private linkingSvc: AccountLinkingService,
    private comSecuritySvc: CommunitySecurityService,
    @Inject(MAT_DIALOG_DATA) private data: { link: AccountLink; view: 'accessor' | 'account' }
  ) {
    this.link = data.link;
    this.view = data.view;
  }
  signedInUser$ = this.authSvc.user$;
  private _onDestroy$ = new Subject<void>();

  @PropertyListener('link') link$ = new BehaviorSubject<AccountLink | null>(null);
  link!: AccountLink;
  view!: 'accessor' | 'account';
  formGroup = this.formBuilder.group({
    usePerks: [false],
  });

  canUpdateLink$ = combineLatest([this.link$, this.signedInUser$]).pipe(
    switchMap(async ([link, signedInUser]) => {
      if (!link) return false;
      if (link.accountId === signedInUser?.id) return true;
      if (link.status === AccountLinkStatus.PENDING && link.accessorId === signedInUser?.id) return true;
      const access = await this.comSecuritySvc.communitiesWithAccess(
        AccountLinkingSecurityResource.key,
        AccountLinkingSecurityResourceAction.UPDATE
      );
      if (access?.length) return true;
      else return false;
    }),
    tap(hasAccess => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = !hasAccess;
          return btn;
        }),
      };
    })
  );

  selectable = true;
  removable = true;

  _creating = false;

  now = new Date();
  dialogData: DialogData = {
    title: 'Update Account Link',
    subtitle: 'What permissions would you like to add or remove?',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Update',
        role: 'create',
        resultFn: async () => {
          return await this.updateLink();
        },
      },
    ],
  };

  async updateLink() {
    const privileges: AccountLinkPrivilege[] = [];
    if (this.formGroup.value.usePerks) privileges.push(AccountLinkPrivilege.USE_PERKS);
    return await this.linkingSvc.updateLink({ linkId: this.link.id, privileges });
  }

  async ngOnInit() {
    if (this.link) this.formGroup.setValue({ usePerks: this.link.canUsePerks });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
