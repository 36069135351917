<ng-container [ngSwitch]="availability">
  <div
    class="status"
    *ngSwitchCase="available"
    style="display: flex; flex-direction: row; justify-content: center; background-color: limegreen"
  >
    <mat-icon> check_circle </mat-icon>
  </div>
  <div
    class="status"
    *ngSwitchCase="tentative"
    style="display: flex; flex-direction: row; justify-content: center; background-color: black"
  >
    <mat-icon style="color: yellow"> error </mat-icon>
  </div>
  <div
    class="status"
    *ngSwitchCase="busy"
    style="display: flex; flex-direction: row; justify-content: center; background-color: var(--warn-color)"
  >
    <mat-icon> cancel </mat-icon>
  </div>
</ng-container>
