import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import {
  BookingStatus,
  CalendarEvent,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventResourceAssignment,
  EventSecurityResource,
  EventSecurityResourceAction,
  Resource,
  ResourceAssignmentStatus,
  ResourceTag,
  ResourceType,
} from '@greco/booking-events';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { CollapsibleSectionController } from '@greco/ui-collapsible-section';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { AddAttendeeDialog, SpecifySubstitutionsDialog } from '../../dialogs';
import { BookingService, EventService } from '../../services';

@Component({
  selector: 'greco-event-page',
  templateUrl: './event.page.html',
  styleUrls: ['./event.page.scss'],
  viewProviders: [CollapsibleSectionController],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EventPage implements OnDestroy, OnInit {
  bookingsSortAlphabetically = true;

  constructor(
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private bookingSvc: BookingService,
    private comSecSvc: CommunitySecurityService
  ) {}

  @Input() lockResources = false;
  @Input() selectedAssignment: EventResourceAssignment | null = null;

  @PropertyListener('selectedResource') private _selectedResource$ = new BehaviorSubject<Resource | null>(null);
  @Input() selectedResource: Resource | null = null;

  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>(1);
  @Input() event!: CalendarEvent;

  completed = false;
  eventCancelled = false;

  pending = ResourceAssignmentStatus.PENDING;
  confirmed = ResourceAssignmentStatus.CONFIRMED;
  requestingSubstitution = ResourceAssignmentStatus.REQUESTING_SUBSTITUTION;
  resourceInfo: EventResourceAssignment | undefined;
  sharedTags: ResourceTag[] | null = null;

  canUpdateEvent$ = this._event$.pipe(
    switchMap(async event => {
      const user = await this.userSvc.getSelf();
      return event
        ? (await this.comSecSvc.hasAccess(
            event.community.id,
            EventSecurityResource.key,
            EventSecurityResourceAction.UPDATE
          )) || event.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  readonly canManageSpots$ = this._event$.pipe(
    switchMap(async event => {
      if (!event) return false;
      return await this.comSecSvc.hasAccess(
        event.community.id,
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.MANAGE_SPOTS
      );
    })
  );

  canViewVideos$ = this.userSvc.user$.pipe(map(u => u?.isSuperAdmin));

  canCancelEvent$ = this._event$.pipe(
    switchMap(async event => {
      const user = await this.userSvc.getSelf();
      return event
        ? (await this.comSecSvc.hasAccess(
            event.community.id,
            EventSecurityResource.key,
            EventSecurityResourceAction.CANCEL
          )) || event.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  canViewContacts$ = this._event$.pipe(
    switchMap(async event => {
      return await this.comSecSvc.hasAccess(event.community.id, ContactResource.key, ContactResourceAction.READ);
    })
  );

  canViewBookings$ = this._event$.pipe(
    switchMap(async event => {
      const user = await this.userSvc.getSelf();

      return event
        ? (await this.comSecSvc.hasAccess(
            event.community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.LIST
          )) || event.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  canCreateBooking$ = this._event$.pipe(
    switchMap(async event => {
      const user = await this.userSvc.getSelf();
      return event
        ? (await this.comSecSvc.hasAccess(
            event.community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CREATE
          )) || event.resourceAssignments.some(assignment => assignment.resource?.groupId === user?.id)
        : false;
    }),
    shareReplay(1)
  );

  readonly hasAttendees$ = this._event$.pipe(
    switchMap(async event => {
      return event
        ? await this.bookingSvc.paginate(
            new RequestQueryBuilder().search({
              'event.id': event.id,
              status: {
                $in: [BookingStatus.CHECKED_IN, BookingStatus.CONFIRMED, BookingStatus.PENDING, BookingStatus.NO_SHOW],
              },
            }),
            event.community.id,
            { limit: 1 }
          )
        : null;
    }),
    map(data => !!data?.items?.length)
  );

  readonly attendeeFilters$ = this._event$.pipe(
    map(event =>
      new RequestQueryBuilder().search({
        status: {
          $in: [BookingStatus.CHECKED_IN, BookingStatus.CONFIRMED, BookingStatus.PENDING, BookingStatus.NO_SHOW],
        },
        'event.id': event.id,
      })
    )
  );

  readonly cancelledBookingsFilters$ = this._event$.pipe(
    map(event =>
      new RequestQueryBuilder().search({
        status: { $in: [BookingStatus.CANCELLED, BookingStatus.LATE_CANCELLED] },
        'event.id': event.id,
      })
    )
  );

  sharedTags$ = this._selectedResource$.pipe(
    map(resource => {
      const assignmentsForSubstitution = this.event.resourceAssignments.filter(
        resourceAssignment =>
          resourceAssignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION ||
          resourceAssignment.resourceStatus === ResourceAssignmentStatus.PENDING
      );
      const tags = assignmentsForSubstitution.reduce((acc, assignment) => {
        if (
          assignment.resourceTag &&
          resource?.resourceTags?.map(tag => tag.id).includes(assignment.resourceTag.id) &&
          !acc.map(resourceTag => resourceTag.id).includes(assignment.resourceTagId || '')
        )
          acc.push(assignment.resourceTag);
        return acc;
      }, [] as ResourceTag[]);
      this.sharedTags = tags;
      return tags;
    })
  );

  async addAttendee() {
    const dialog = this.matDialog.open(AddAttendeeDialog, {
      data: { event: this.event },
      width: '750px',
      maxWidth: '90%',
    });
    if (await toPromise(dialog.afterClosed())) {
      this.event = { ...this.event };
    }
  }

  async openSubstitutionDialog(resourceTag: ResourceTag) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        title: 'Confirm Substitution',
        content: 'Are you sure you want to substitute for the ' + resourceTag.label + ' tag?',
        buttons: [
          { label: 'Cancel', role: 'cancel' },
          {
            label: 'Confirm',
            role: 'confirm',
            resultFn: async () => {
              try {
                if (this.selectedResource && resourceTag) {
                  const pendingAssignment: EventResourceAssignment = {
                    id: '',
                    eventId: this.event.id,
                    resourceId: this.selectedResource.id,
                    resourceTagId: resourceTag.id,
                    resourceStatus: this.event.autoAssign
                      ? ResourceAssignmentStatus.CONFIRMED
                      : ResourceAssignmentStatus.PENDING,
                  };
                  const resourceAssignments = [...this.event.resourceAssignments, pendingAssignment];
                  const index = resourceAssignments.findIndex(
                    assignment =>
                      assignment.resourceTagId === resourceTag.id &&
                      (!assignment?.resourceId ||
                        (assignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION &&
                          this.event.autoAssign))
                  );
                  if (index !== -1) resourceAssignments.splice(index, 1);
                  await this.eventSvc.updateEventDetails(this.event.id, {
                    resourceAssignments,
                  });
                  this.selectedAssignment = pendingAssignment;
                }
                this.snacks.open('Substitution' + (this.event.autoAssign ? '' : ' request') + ' sent!', 'Ok', {
                  duration: 2500,
                  panelClass: 'mat-primary',
                });
              } catch (err) {
                console.error(err);
              }
            },
          },
        ],
      },
    });
    await toPromise(dialog.afterClosed());
  }

  async requestSubstitution() {
    const dialog = this.matDialog.open(SpecifySubstitutionsDialog, {
      data: {
        communityId: this.event.community.id,
        title: 'Request Substitution',
        content:
          'Are you sure you want to send a substitution request for the ' +
          this.selectedAssignment?.resourceTag?.label +
          ' tag?',
        resourceTags: [this.selectedAssignment?.resourceTag],
        resources: [this.selectedAssignment?.resource],
      },
    });
    const result = await toPromise(dialog.afterClosed());
    if (result !== 'cancel') {
      try {
        const assignments = this.event.resourceAssignments;
        const substitutionEmails = result || [];
        assignments.forEach(assignment => {
          if (this.selectedAssignment) {
            if (assignment.id === this.selectedAssignment.id) assignment.resourceStatus = this.requestingSubstitution;
          }
        });

        this.eventSvc.updateEventDetails(this.event.id, { resourceAssignments: assignments, substitutionEmails });
        this.snacks.open('Substitution request sent!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err) {
        console.error(err);
      }
    }
  }

  ngOnDestroy() {
    this._event$.complete();
  }

  async ngOnInit() {
    if (this.event) {
      if (this.event.startDate.getTime() + this.event.duration < Date.now()) {
        this.completed = true;
      } else this.completed = false;
    }
  }

  hasRoom() {
    return this.event.resourceAssignments.some(assignment => assignment.resource?.type === ResourceType.ROOM);
  }

  updateSeriesListener() {
    this.event = { ...this.event };
  }

  async autoAssignSpots() {
    await this.bookingSvc.autoAssignSpots(this.event.id);
    this._event$.next(this.event);
  }
}
