<form [formGroup]="form">
  <div style="display: flex">
    <div style="display: flex; flex: 1">
      <input
        [disabled]="disabled"
        #addressInput
        matInput
        type="search"
        placeholder="123 Street"
        formControlName="line1"
        ngx-google-places-autocomplete
        [options]="$any({ componentRestrictions: { country: 'CA' } })"
        (onAddressChange)="mapAddress($event)"
        (focus)="focused = true"
        (blur)="focused = false; onTouched()"
      />
      <mat-icon style="color: rgba(14, 15, 15, 0.54); margin-right: 8px">search</mat-icon>
    </div>
  </div>
  <div class="expanding-fields">
    <mat-divider [class.focused]="focused" [class.primary-border]="focused"></mat-divider>
    <div class="sub-form">
      <!-- <mat-form-field>
      <mat-label>Address Line 1</mat-label>
      <input
        #street
        matInput
        formControlName="line1"
        required
        [autocomplete]="strict ? '' : 'shipping address-line1'"
      />
    </mat-form-field> -->
      <mat-form-field>
        <mat-label>Apartment, suite, etc. (optional)</mat-label>
        <input
          #street2
          matInput
          formControlName="line2"
          autocomplete="shipping address-line2"
          [disabled]="disabled"
          (blur)="onTouched()"
        />
      </mat-form-field>
      <div style="display: flex; flex-wrap: wrap; gap: 0 8px">
        <mat-form-field style="flex: 1">
          <mat-label>City</mat-label>
          <input
            matInput
            formControlName="city"
            required
            [autocomplete]="strict ? '' : 'shipping address-level2'"
            (blur)="onTouched()"
          />
          <mat-hint
            *ngIf="form.controls.city.touched && form.controls.city.invalid"
            style="color: var(--danger-color, red)"
          >
            *Required
          </mat-hint>
        </mat-form-field>
        <mat-form-field style="flex: 1">
          <mat-label>State/Province</mat-label>
          <input
            matInput
            formControlName="state"
            required
            [autocomplete]="strict ? '' : 'shipping address-level1'"
            (blur)="onTouched()"
          />
          <mat-hint
            *ngIf="form.controls.state.touched && form.controls.state.invalid"
            style="color: var(--danger-color, red)"
          >
            *Required
          </mat-hint>
        </mat-form-field>
      </div>
      <div style="display: flex; flex-wrap: wrap; gap: 0 8px">
        <mat-form-field style="flex: 1">
          <mat-label>Zip/Postal Code</mat-label>
          <input
            [required]="zipRequired"
            matInput
            formControlName="postalCode"
            [autocomplete]="strict ? '' : 'shipping postal-code'"
            (blur)="onTouched()"
          />
          <mat-hint
            *ngIf="form.controls.postalCode.touched && form.controls.postalCode.invalid"
            style="color: var(--danger-color, red)"
          >
            *Required
          </mat-hint>
        </mat-form-field>
        <greco-country-input
          [disabled]="disabled"
          [value]="form.get('country')?.value"
          [required]="true"
          (onCountrySelected)="form.get('country')?.setValue($event)"
          (click)="form.controls.country.markAsTouched(); onTouched()"
          [showRequiredHint]="
            (form.controls.country.touched || form.controls.country.dirty) && form.controls.country.invalid
          "
          style="flex: 1"
        ></greco-country-input>
      </div>
    </div>
  </div>
</form>
