import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { COUNTRIES_DB } from '@greco-fit/scaffolding';
import { JoinPipeModule } from '@greco-fit/util/ngx';
import { CountrySelectComponent } from './country-select.component';
import { MatSelectCountryLangToken } from './tokens';

export type MatSelectCountrySupportedLanguages = 'en' | 'de' | 'fr' | 'es' | 'it';

@NgModule({
  declarations: [CountrySelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressBarModule,
    JoinPipeModule
  ],
  exports: [CountrySelectComponent]
})
export class CountrySelectModule {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.registerCountries();
  }

  static forRoot(i18n: MatSelectCountrySupportedLanguages): ModuleWithProviders<CountrySelectModule> {
    return {
      ngModule: CountrySelectModule,
      providers: [
        {
          provide: MatSelectCountryLangToken,
          useValue: i18n
        }
      ]
    };
  }

  registerCountries() {
    for (const country of COUNTRIES_DB) {
      const countryAlpha2Code = country.alpha2Code.toLowerCase();
      try {
        this.iconRegistry.addSvgIcon(
          countryAlpha2Code,
          this.sanitizer.bypassSecurityTrustResourceUrl(`assets/flags/svg/${countryAlpha2Code}.svg`)
        );
      } catch (err) {
        console.error('Error: icon not found for ' + countryAlpha2Code, err);
      }
    }
  }
}
