<ng-container *grecoLet="tags$ | async as tags">
  <ng-container *grecoLet="resources$ | async as resources">
    <div style="display: flex">
      <mat-chip-list #chipList style="white-space: nowrap; flex: 1">
        <ng-container *ngFor="let assignment of filterPerson(selected)">
          <mat-chip
            *ngIf="assignment.resource; else noResource"
            style="height: 42px"
            [mat-menu-trigger-for]="tagMenu"
            [disabled]="disabled && !!assignment.resource && !!assignment.id"
            [style.pointer-events]="disabled && !!assignment.resource && !!assignment.id ? 'none' : ''"
            (click)="selectAssignment(assignment)"
          >
            <div style="width: 32px; height: 32px">
              <greco-smart-img
                style="margin-left: -6px"
                [src]="assignment.resource?.photoURL || 'assets/lf3/icon_square_pad.png'"
                [round]="true"
              ></greco-smart-img>
            </div>
            <div *ngIf="assignment?.resource">
              <greco-availability-status [availability]="availability[assignment.resource.id]" [large]="true">
              </greco-availability-status>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 4px">
              <span style="margin-bottom: -1px; font-size: 10px; color: gray">
                {{ assignment?.resourceTag?.label || 'No Resource Tag' }}
              </span>
              <span style="margin-bottom: 4px"> {{ assignment?.resource?.name }} </span>
            </div>
            <ng-container [ngSwitch]="assignment.resourceStatus">
              <mat-icon *ngSwitchCase="confirmed">done</mat-icon>
              <mat-icon *ngSwitchCase="pending">hourglass_empty</mat-icon>
              <mat-icon *ngSwitchCase="requestingSubstitution">pending_actions</mat-icon>
            </ng-container>

            <mat-icon *ngIf="!disabled" [mat-menu-trigger-for]="options" (click)="$event.stopImmediatePropagation()">
              more_vert

              <!-- Options for event resource assignment -->
              <mat-menu #options xPosition="before">
                <button mat-menu-item [mat-menu-trigger-for]="status">
                  <mat-icon>trending_flat</mat-icon>
                  Update Status

                  <!-- Change status menu -->
                  <mat-menu #status>
                    <button mat-menu-item (click)="updateStatus(assignment, confirmed)">
                      <mat-icon>done</mat-icon>Confirmed
                    </button>
                    <button mat-menu-item (click)="updateStatus(assignment, pending)">
                      <mat-icon>hourglass_empty</mat-icon>Pending
                    </button>
                    <button mat-menu-item (click)="updateStatus(assignment, requestingSubstitution)">
                      <mat-icon>pending_actions</mat-icon>Request Substitution
                    </button>
                  </mat-menu>
                </button>
                <button mat-menu-item (click)="select(assignment.resourceTag || null, assignment.resource)">
                  <mat-icon>cancel</mat-icon>Remove
                </button>
              </mat-menu>
            </mat-icon>
          </mat-chip>

          <ng-template #noResource>
            <mat-chip
              style="height: 42px"
              [disabled]="disabled && !!assignment.resource && !assignment.id"
              [style.pointer-events]="disabled && !!assignment.resource && !assignment.id ? 'none' : ''"
              [matMenuTriggerFor]="tagMenu"
              (click)="selectTag(assignment.resourceTag)"
            >
              <div style="display: flex; flex-direction: column; margin-left: 4px">
                <span style="margin-bottom: -1px; font-size: 10px; color: gray">
                  {{ assignment?.resourceTag?.label || '' }}
                </span>
                <i> Resource Needed </i>
              </div>
              <mat-icon>pending_actions</mat-icon>
              <mat-icon *ngIf="!disabled" [mat-menu-trigger-for]="remove" (click)="$event.stopImmediatePropagation()">
                more_vert
              </mat-icon>
              <mat-menu #remove xPosition="before">
                <button mat-menu-item (click)="select(assignment.resourceTag || null, assignment.resource || null)">
                  <mat-icon>cancel</mat-icon>Remove
                </button>
              </mat-menu>
            </mat-chip>
          </ng-template>
        </ng-container>

        <input
          #input
          matInput
          type="text"
          autocomplete="off"
          [disabled]="disabled"
          (input)="searchQuery$.next($any($event).target.value)"
          [matMenuTriggerFor]="tagMenu"
          [matChipInputFor]="chipList"
        />
      </mat-chip-list>

      <div *ngIf="!disabled" style="display: flex; flex-direction: column; justify-content: space-evenly">
        <button mat-icon-button style="margin: -8px" [mat-menu-trigger-for]="emptyMenu">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <mat-menu #tagMenu (close)="searchQuery$.next(''); input.value = ''; selectedTag = null">
      <ng-template matMenuContent>
        <ng-container *grecoLet="tagsAndResources$ | async as tagsAndResources">
          <ng-container *ngIf="tagsAndResources?.length; else noResources">
            <button *ngFor="let grouping of tagsAndResources" mat-menu-item [mat-menu-trigger-for]="resourceMenu">
              <mat-icon style="margin-right: 12px">label</mat-icon>
              <span [innerHTML]="grouping.tag.label | safe : 'html'"></span>

              <mat-menu #resourceMenu>
                <button
                  *ngFor="let resource of grouping.resources"
                  mat-menu-item
                  style="overflow-y: visible"
                  [disabled]="emptyIds.includes(resource.id)"
                  [style.background-color]="
                    availability[resource.id] === busy || emptyIds.includes(resource.id)
                      ? 'var(--background-color-light)'
                      : ''
                  "
                  (click)="select(grouping.tag, resource); $event.stopImmediatePropagation()"
                >
                  <div
                    *grecoLet="availability[resource.id] === busy as unavailable"
                    style="display: flex; align-items: center"
                  >
                    <mat-checkbox
                      style="margin-right: 8px; margin-top: -1px"
                      color="primary"
                      [disableRipple]="true"
                      [disabled]="emptyIds.includes(resource.id)"
                      [checked]="selectedIds.includes(grouping.tag.id + '__' + resource.id)"
                      (click)="$event.preventDefault()"
                    ></mat-checkbox>

                    <div style="width: 24px; height: 24px; margin-right: 7px; position: relative">
                      <greco-availability-status
                        *ngIf="resource"
                        class="resource-availability-status"
                        [availability]="availability[resource.id]"
                      ></greco-availability-status>

                      <greco-smart-img
                        class="resource-smart-img"
                        [round]="true"
                        [disabled]="unavailable"
                        [src]="resource?.photoURL || 'assets/lf3/icon_square_pad.png'"
                      ></greco-smart-img>
                    </div>

                    <span
                      style="margin-left: 4px"
                      [innerHTML]="resource.name | safe : 'html'"
                      [style.color]="unavailable ? 'lightgray' : ''"
                    ></span>
                  </div>
                </button>

                <button
                  *ngIf="!disabled"
                  mat-menu-item
                  (click)="addToSelected(grouping.tag, null); $event.stopImmediatePropagation()"
                >
                  <mat-icon style="margin: -2px 2px 0 -4px">add</mat-icon>
                  Empty Assignment
                </button>
              </mat-menu>
            </button>
          </ng-container>
          <ng-template #noResources>
            <button mat-menu-item disabled="true">
              <i>No resources found</i>
            </button>
          </ng-template>
        </ng-container>
      </ng-template>
    </mat-menu>

    <mat-menu #emptyMenu (close)="searchQuery$.next(''); input.value = ''">
      <ng-container *ngIf="tags?.length; else noTags">
        <button
          *ngFor="let tag of tags"
          mat-menu-item
          (click)="addToSelected(tag, null); $event.stopImmediatePropagation()"
        >
          <mat-icon style="margin-right: 12px">label</mat-icon>
          <span [innerHTML]="tag.label | safe : 'html'"></span>
        </button>
      </ng-container>
      <ng-template #noTags>
        <button mat-menu-item [disabled]="true">
          <i>No tags configured</i>
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
</ng-container>
