<div class="booking-card">
  <div class="card-image">
    <greco-user-avatar [user]="user" [showOnlineStatus]="false" size="large"></greco-user-avatar>
  </div>
  <div class="card-content">
    <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
      <mat-icon class="status-icon">event_busy</mat-icon>
      <h2 style="z-index: 1">
        <strong>{{ user.friendlyName || user.displayName }}</strong>
      </h2>
    </div>

    <div class="action-buttons">
      <button
        mat-stroked-button
        [disabled]="confirming || event.capacityRemaining <= 0"
        (click)="bookNow(); booked.emit()"
      >
        Book Now
      </button>
      <button
        mat-flat-button
        color="primary"
        style="background-color: var(--primary-color) !important"
        (click)="leaveWaitlist()"
        [disabled]="confirming"
      >
        Leave Waitlist
      </button>
    </div>
  </div>
</div>
