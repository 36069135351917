<form [formGroup]="form">
  <div style="display: flex">
    <!-- Transfer Date -->
    <mat-form-field appearance="standard">
      <mat-label>Start Date</mat-label>
      <input matInput required formControlName="start" placeholder="Start Date" [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <!-- Frequency -->
    <mat-form-field appearance="standard" style="flex: 0">
      <mat-select required formControlName="frequency">
        <mat-option value="ONCE">Once</mat-option>
        <mat-option value="DAILY">Daily</mat-option>
        <mat-option value="WEEKLY">Weekly</mat-option>
        <mat-option value="MONTHLY">Monthly</mat-option>
        <mat-option value="YEARLY">Yearly</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container *ngIf="form.value.frequency !== 'ONCE'">
    <!-- Until -->
    <mat-form-field appearance="standard">
      <mat-label>Until</mat-label>
      <input matInput formControlName="until" placeholder="Until" [matDatepicker]="untilPicker" />
      <mat-datepicker-toggle matSuffix [for]="untilPicker"></mat-datepicker-toggle>
      <mat-datepicker #untilPicker></mat-datepicker>
    </mat-form-field>

    <!-- RRule -->
    <!-- <mat-form-field appearance="standard">
      <mat-label>Advanced Options</mat-label>
      <input matInput formControlName="rrule" placeholder="Advanced Options" />
    </mat-form-field> -->
  </ng-container>
</form>
