<div *ngFor="let communitiesAndGroupedUserPerks of communitiesAndGroupedUserPerks$ | async" class="container">
  <p>
    <mat-icon>location_on</mat-icon>
    {{ communitiesAndGroupedUserPerks?.community?.name || 'All Communities' }}
  </p>
  <greco-user-perk-box
    *ngFor="let module of communitiesAndGroupedUserPerks.groups"
    [type]="module.module"
    [userPerks]="module.perks"
  ></greco-user-perk-box>
</div>
