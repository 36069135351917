import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  EventResourceSecurityResource,
  EventResourceSecurityResourceAction,
  Resource,
  ResourceTag,
} from '@greco/booking-events';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ResourcesService } from '../../../services';

@Component({
  selector: 'greco-trainer-resource-page',
  templateUrl: './trainer-resource.page.html',
  styleUrls: ['./trainer-resource.page.scss'],
})
export class TrainerResourcePage implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private resourceSvc: ResourcesService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('resource') private resource$ = new BehaviorSubject<Resource | null>(null);
  @Input() resource!: Resource;

  saving = false;

  canUpdateResource$ = this.resource$.pipe(
    switchMap(async resource => {
      return resource
        ? await this.securitySvc.hasAccess(
            EventResourceSecurityResource.key,
            EventResourceSecurityResourceAction.UPDATE_TRAINER,
            { communityId: resource.community.id }
          )
        : false;
    })
  );

  initialValue: any;
  formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
    resourceTags: [[]],
    isFilterable: [true],
  });

  async ngOnInit() {
    this.resource = await this.resourceSvc.getResource(this.resource.id);
    this.initialValue = {
      name: this.resource.name,
      description: this.resource.description,
      resourceTags: this.resource.resourceTags,
      isFilterable: !this.resource.hideFromFilters,
    };
    this.formGroup.reset(this.initialValue);
    this.formGroup.markAsPristine();
  }

  onSave = async () => {
    this.saving = true;
    try {
      if (
        this.initialValue.name != this.formGroup.value.name ||
        this.initialValue.description != this.formGroup.value.description ||
        this.initialValue.resourceTags != this.formGroup.value.resourceTags ||
        this.initialValue.isFilterable != this.formGroup.value.isFilterable
      ) {
        this.resource = await this.resourceSvc.updateResource(this.resource.id, {
          name: this.formGroup.value.name,
          description: this.formGroup.value.description,
          hideFromFilters: !this.formGroup.value.isFilterable,
          resourceTagIds: (this.formGroup.value.resourceTags as ResourceTag[]).map(resourceTag => resourceTag.id),
        });
      }

      this.initialValue = {
        name: this.formGroup.value.name,
        description: this.formGroup.value.description,
        resourceTags: this.resource.resourceTags,
        isFilterable: !this.resource.hideFromFilters,
      };
      this.formGroup.reset(this.initialValue);
      this.formGroup.markAsPristine();
    } catch (err) {
      console.log(err);
    }

    this.saving = false;
  };

  async setDisable() {
    const isDisabled = this.resource.disabled;
    try {
      this.resource = await this.resourceSvc.updateResource(this.resource.id, {
        disabled: !isDisabled,
      });
    } catch (err) {
      console.log(err);
    }
  }
}
