import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { TabRouterData } from './tabs-breadcrumb.component';

@Injectable()
export class TabsBreadcrumbService {
  public updated: Subject<void> = new BehaviorSubject<void>(null);
  private _dataMap: Map<string, TabRouterData> = new Map<string, TabRouterData>();

  static getBasePath(route: ActivatedRoute) {
    return (
      '/' + route.snapshot.pathFromRoot.reduce((acc, path) => [...acc, ...path.url.map(url => url.path)], []).join('/')
    );
  }

  setData(route: ActivatedRoute, data: TabRouterData);
  setData(path: string, data: TabRouterData);
  setData(routeOrPath: string | ActivatedRoute, data: TabRouterData) {
    if (typeof routeOrPath === 'string') this._dataMap.set(routeOrPath, data);
    else this._dataMap.set(TabsBreadcrumbService.getBasePath(routeOrPath), data);
    this.updated.next();
  }

  getData(path: string, data: TabRouterData) {
    const dataConfig = this._dataMap.get(path);
    return dataConfig ? { ...data, ...dataConfig } : data;
  }
}
