import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import {
  PaymentDispute,
  PaymentDisputeSecurityActions,
  PaymentDisputeSecurityResource,
  PaymentDisputeStatus,
} from '@greco/finance-payments';
import { SecurityService } from '@greco/ngx-security-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PaymentDisputeService } from '../../services';

@Component({
  selector: 'greco-payment-dispute-response-form',
  templateUrl: './payment-dispute-response-form.component.html',
  styleUrls: ['./payment-dispute-response-form.component.scss'],
})
export class PaymentDisputeResponseFormComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private paymentDisputeSvc: PaymentDisputeService,
    private snack: MatSnackBar,
    private securitySvc: SecurityService
  ) {}

  // https://stripe.com/docs/file-upload
  readonly maxFileSizeStripeInBytes = 4194304; // 4 MB

  @Input() disabled = false;

  @Input() expanded = true;

  readonly _paymentDispute$ = new BehaviorSubject<PaymentDispute | null>(null);
  @Input() set paymentDispute(paymentDispute: PaymentDispute | null) {
    this._paymentDispute$.next(paymentDispute);
  }
  get paymentDispute() {
    return this._paymentDispute$.value;
  }

  readonly _accountId$ = new BehaviorSubject<string | null>(null);
  @Input() set accountId(accountId: string | null) {
    this._accountId$.next(accountId);
  }
  get accountId() {
    return this._accountId$.value;
  }

  billingAddress = '';

  serviceDocumentation: string[] = [];

  customerCommunication: string[] = [];

  customerSignature: string[] = [];

  receipt: string[] = [];

  cancellationPolicy: string[] = [];

  duplicateChargeDocumentation: string[] = [];

  refundPolicy: string[] = [];

  uncategorizedFile: string[] = [];

  isChanging = false;

  isSaving = false;

  loading = false;

  isNotOpen = false;

  paymentDisputeForm = this.formBuilder.group({
    /** Customer Information **/
    customerName: { value: '', disabled: true },
    customerEmail: { value: '', disabled: true },
    billingAddress: [null],
    customerPurchaseIP: [''],

    /** Purchase Description **/
    productDescription: [''],

    /** Cancellation Rebuttal **/
    cancellationRebuttal: [''],

    /** Refund Explanation **/
    refundRefusalExplanation: [''],

    /** Service Information **/
    serviceDate: [''],
    serviceDocumentation: [null],
    serviceDocumentationURL: [null],
    serviceDocumentationStripeFileId: [null],

    /** Customer Communication **/
    customerCommunication: [null],
    customerCommunicationURL: [null],
    customerCommunicationStripeFileId: [null],

    /** Customer Signature **/
    customerSignature: [null],
    customerSignatureURL: [null],
    customerSignatureStripeFileId: [null],

    /** Receipt **/
    receipt: [null],
    receiptURL: [null],
    receiptStripeFileId: [null],

    /** Cancellation Policy **/
    cancellationPolicy: [null],
    cancellationPolicyURL: [null],
    cancellationPolicyStripeFileId: [null],
    cancellationPolicyDisclosure: [''],

    /** Duplicate Charge **/
    duplicateChargeDocumentation: [null],
    duplicateChargeDocumentationURL: [null],
    duplicateChargeDocumentationStripeFileId: [null],
    duplicateChargeExplanation: [''],

    /** Refund Information **/
    refundPolicy: [null],
    refundPolicyURL: [null],
    refundPolicyStripeFileId: [null],
    refundPolicyDisclosure: [''],

    /** Misc File and Info **/
    uncategorizedFile: [null],
    uncategorizedFileURL: [null],
    uncategorizedFileStripeFileId: [null],
    uncategorizedText: [''],
  });

  canUpdatePaymentDispute$ = this._accountId$.pipe(
    filter(c => !!c),

    switchMap(async accountId => {
      return await this.securitySvc.hasAccess(
        PaymentDisputeSecurityResource.key,
        PaymentDisputeSecurityActions.UPDATE,
        {
          accountId: accountId,
        }
      );
    })
  );

  canSubmitPaymentDispute$ = this._accountId$.pipe(
    filter(c => !!c),

    switchMap(async accountId => {
      return await this.securitySvc.hasAccess(
        PaymentDisputeSecurityResource.key,
        PaymentDisputeSecurityActions.SUBMIT,
        {
          accountId: accountId,
        }
      );
    })
  );

  async onSave(paymentDisputeId: string) {
    const documents = new FormData();
    let isFileChanged = false;

    if (this.paymentDisputeForm.value.serviceDocumentation) {
      if (this.paymentDisputeForm.value.serviceDocumentation[0].size > this.maxFileSizeStripeInBytes) {
        return this.fileTooLarge('Service Documentation');
      }
      documents.append('serviceDocumentationURL', this.paymentDisputeForm.value.serviceDocumentation[0]);
      isFileChanged = true;
    }

    if (this.paymentDisputeForm.value.customerCommunication) {
      if (this.paymentDisputeForm.value.customerCommunication[0].size > this.maxFileSizeStripeInBytes) {
        return this.fileTooLarge('Customer Communication');
      }
      documents.append('customerCommunicationURL', this.paymentDisputeForm.value.customerCommunication[0]);
      isFileChanged = true;
    }

    if (this.paymentDisputeForm.value.customerSignature) {
      if (this.paymentDisputeForm.value.customerSignature[0].size > this.maxFileSizeStripeInBytes) {
        return this.fileTooLarge('Customer Signature');
      }
      documents.append('customerSignatureURL', this.paymentDisputeForm.value.customerSignature[0]);
      isFileChanged = true;
    }

    if (this.paymentDisputeForm.value.receipt) {
      if (this.paymentDisputeForm.value.receipt[0].size > this.maxFileSizeStripeInBytes) {
        return this.fileTooLarge('Receipt');
      }
      documents.append('receiptURL', this.paymentDisputeForm.value.receipt[0]);
      isFileChanged = true;
    }

    if (this.paymentDisputeForm.value.cancellationPolicy) {
      if (this.paymentDisputeForm.value.cancellationPolicy[0].size > this.maxFileSizeStripeInBytes) {
        return this.fileTooLarge('Cancellation Policy');
      }
      documents.append('cancellationPolicyURL', this.paymentDisputeForm.value.cancellationPolicy[0]);
      isFileChanged = true;
    }

    if (this.paymentDisputeForm.value.duplicateChargeDocumentation) {
      if (this.paymentDisputeForm.value.duplicateChargeDocumentation[0].size > this.maxFileSizeStripeInBytes) {
        return this.fileTooLarge('Duplicate Charge');
      }
      documents.append(
        'duplicateChargeDocumentationURL',
        this.paymentDisputeForm.value.duplicateChargeDocumentation[0]
      );
      isFileChanged = true;
    }

    if (this.paymentDisputeForm.value.refundPolicy) {
      if (this.paymentDisputeForm.value.refundPolicy[0].size > this.maxFileSizeStripeInBytes) {
        return this.fileTooLarge('Refund Policy');
      }
      documents.append('refundPolicyURL', this.paymentDisputeForm.value.refundPolicy[0]);
      isFileChanged = true;
    }

    if (this.paymentDisputeForm.value.uncategorizedFile) {
      if (this.paymentDisputeForm.value.uncategorizedFile[0].size > this.maxFileSizeStripeInBytes) {
        return this.fileTooLarge('Uncategorized');
      }
      documents.append('uncategorizedFileURL', this.paymentDisputeForm.value.uncategorizedFile[0]);
      isFileChanged = true;
    }

    if (isFileChanged) {
      const documentsData = await this.paymentDisputeSvc.uploadPaymentDisputeDoc(paymentDisputeId, documents);

      if (documentsData.serviceDocumentationURL) {
        this.paymentDisputeForm.patchValue({ serviceDocumentationURL: documentsData.serviceDocumentationURL });
      } else {
        this.paymentDisputeForm.patchValue({ serviceDocumentationURL: this.serviceDocumentation[0] });
      }

      if (documentsData.customerCommunicationURL) {
        this.paymentDisputeForm.patchValue({ customerCommunicationURL: documentsData.customerCommunicationURL });
      } else {
        this.paymentDisputeForm.patchValue({ customerCommunicationURL: this.customerCommunication[0] });
      }

      if (documentsData.customerSignatureURL) {
        this.paymentDisputeForm.patchValue({ customerSignatureURL: documentsData.customerSignatureURL });
      } else {
        this.paymentDisputeForm.patchValue({ customerSignatureURL: this.customerSignature[0] });
      }

      if (documentsData.receiptURL) {
        this.paymentDisputeForm.patchValue({ receiptURL: documentsData.receiptURL });
      } else {
        this.paymentDisputeForm.patchValue({ receiptURL: this.receipt[0] });
      }

      if (documentsData.cancellationPolicyURL) {
        this.paymentDisputeForm.patchValue({ cancellationPolicyURL: documentsData.cancellationPolicyURL });
      } else {
        this.paymentDisputeForm.patchValue({ cancellationPolicyURL: this.cancellationPolicy[0] });
      }

      if (documentsData.duplicateChargeDocumentationURL) {
        this.paymentDisputeForm.patchValue({
          duplicateChargeDocumentationURL: documentsData.duplicateChargeDocumentationURL,
        });
      } else {
        this.paymentDisputeForm.patchValue({ duplicateChargeDocumentationURL: this.duplicateChargeDocumentation[0] });
      }

      if (documentsData.refundPolicyURL) {
        this.paymentDisputeForm.patchValue({ refundPolicyURL: documentsData.refundPolicyURL });
      } else {
        this.paymentDisputeForm.patchValue({ refundPolicyURL: this.refundPolicy[0] });
      }

      if (documentsData.uncategorizedFileURL) {
        this.paymentDisputeForm.patchValue({ uncategorizedFileURL: documentsData.uncategorizedFileURL });
      } else {
        this.paymentDisputeForm.patchValue({ uncategorizedFileURL: this.uncategorizedFile[0] });
      }
    }

    if (!this.serviceDocumentation.length) {
      this.paymentDisputeForm.patchValue({ serviceDocumentationURL: null });
    }

    if (!this.customerCommunication.length) {
      this.paymentDisputeForm.patchValue({ customerCommunicationURL: null });
    }

    if (!this.customerSignature.length) {
      this.paymentDisputeForm.patchValue({ customerSignatureURL: null });
    }

    if (!this.receipt.length) {
      this.paymentDisputeForm.patchValue({ receiptURL: null });
    }

    if (!this.cancellationPolicy.length) {
      this.paymentDisputeForm.patchValue({ cancellationPolicyURL: null });
    }

    if (!this.duplicateChargeDocumentation.length) {
      this.paymentDisputeForm.patchValue({ duplicateChargeDocumentationURL: null });
    }

    if (!this.refundPolicy.length) {
      this.paymentDisputeForm.patchValue({ refundPolicyURL: null });
    }

    if (!this.uncategorizedFile.length) {
      this.paymentDisputeForm.patchValue({ uncategorizedFileURL: null });
    }

    try {
      const paymentDispute = await this.paymentDisputeSvc.savePaymentDispute(paymentDisputeId, {
        accountId: this.accountId,
        ...this.paymentDisputeForm.value,
      });
      this.isChanging = false;
      this.isSaving = false;
      this.isNotOpen = false;
      this.loading = false;
      this.paymentDisputeForm.enable();
      this.paymentDisputeForm.get('customerName')?.disable();
      this.paymentDisputeForm.get('customerEmail')?.disable();
      this._paymentDispute$.next(paymentDispute);
      this.snack.open('Response Form Saved!!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
    } catch (error) {
      this.isChanging = true;
      this.isSaving = false;
      this.isNotOpen = false;
      this.loading = false;
      this.paymentDisputeForm.enable();
      this.paymentDisputeForm.get('customerName')?.disable();
      this.paymentDisputeForm.get('customerEmail')?.disable();
    }
  }

  async onSubmit(paymentDispute: PaymentDispute) {
    this.isNotOpen = true;
    this.paymentDisputeForm.disable();
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to submit the information for <strong style="text-transform: capitalize; color: var(--warn-color);">${paymentDispute.reason}</strong> dispute.</p><p>This will submit the dispute AND disable the response form.</p><br><strong style="color: var(--warn-color);">This action is NOT reversible!</strong>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );
    if (confirmation === 'cancel' || !confirmation) {
      this.isNotOpen = false;
      this.paymentDisputeForm.enable();
      this.paymentDisputeForm.get('customerName')?.disable();
      this.paymentDisputeForm.get('customerEmail')?.disable();
    }
    if (confirmation === 'confirm') {
      this.loading = true;
      const submitedPaymentDispute = await this.paymentDisputeSvc.submitPaymentDispute(paymentDispute.id, {
        accountId: this.accountId,
        ...this.paymentDisputeForm.value,
      });
      if (submitedPaymentDispute) {
        const stripeSubmitedData = await this.paymentDisputeSvc.submitPaymentDisputeToStripe(paymentDispute.id);
        if (this.paymentDispute) this.paymentDispute.status = stripeSubmitedData.status;
        this.isNotOpen = true;
        this.loading = false;
        this.paymentDisputeForm.disable();
        this.snack.open('Response Form Submitted!!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
      }
    }
  }

  async closeDispute(paymentDispute: PaymentDispute) {
    this.isNotOpen = true;
    this.paymentDisputeForm.disable();
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to close <strong style="text-transform: capitalize; color: var(--warn-color);">${paymentDispute.reason}</strong> dispute.</p><p>This will close the dispute AND disable the response form.</p><br><strong style="color: var(--warn-color);">This action is NOT reversible!</strong>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'cancel' || !confirmation) {
      this.isNotOpen = false;
      this.paymentDisputeForm.enable();
      this.paymentDisputeForm.get('customerName')?.disable();
      this.paymentDisputeForm.get('customerEmail')?.disable();
    }

    if (confirmation === 'confirm') {
      this.loading = true;
      const paymentDisput = await this.paymentDisputeSvc.closeStripePaymentDispute(
        paymentDispute.id,
        this.accountId || ''
      );
      if (this.paymentDispute) this.paymentDispute.status = paymentDisput.status;
      this.isNotOpen = true;
      this.loading = false;
      this.paymentDisputeForm.disable();
      this.snack.open('Response Form Closed!!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
    }
  }

  async fileTooLarge(fileName: string) {
    const dialog = this.dialog.open(SimpleDialog, {
      data: {
        content: `File Size for <strong>${fileName}</strong> is too large`,
        buttons: [{ label: 'Okay', role: 'cancel' }],
      },
    });
    if (await toPromise(dialog.afterClosed())) {
      this.isChanging = true;
      this.isSaving = false;
      this.isNotOpen = false;
      this.loading = false;
      this.paymentDisputeForm.enable();
      this.paymentDisputeForm.get('customerName')?.disable();
      this.paymentDisputeForm.get('customerEmail')?.disable();
    }
  }

  ngOnInit(): void {
    if (this.paymentDispute) {
      if (this.paymentDispute.status != PaymentDisputeStatus.OPEN) {
        this.isNotOpen = true;
        this.paymentDisputeForm.disable();
      }

      this.paymentDisputeForm.patchValue({ customerName: this.paymentDispute.user?.displayName });

      this.paymentDisputeForm.patchValue({ customerEmail: this.paymentDispute.user?.email });

      if (this.paymentDispute.billingAddress?.formatted) {
        this.paymentDisputeForm.patchValue({ billingAddress: this.paymentDispute.billingAddress });
      } else if (this.paymentDispute.user?.address?.formatted) {
        this.paymentDisputeForm.patchValue({ billingAddress: this.paymentDispute.user?.address });
      } else {
        this.paymentDisputeForm.patchValue({ billingAddress: null });
      }

      this.paymentDisputeForm.patchValue({ customerPurchaseIP: this.paymentDispute.customerPurchaseIP });

      this.paymentDisputeForm.patchValue({ productDescription: this.paymentDispute.productDescription });

      this.paymentDisputeForm.patchValue({ cancellationRebuttal: this.paymentDispute.cancellationRebuttal });

      this.paymentDisputeForm.patchValue({ refundRefusalExplanation: this.paymentDispute.refundRefusalExplanation });

      this.paymentDisputeForm.patchValue({ serviceDate: this.paymentDispute.serviceDate });

      if (this.paymentDispute.serviceDocumentationURL) {
        this.serviceDocumentation = [this.paymentDispute.serviceDocumentationURL];
        this.paymentDisputeForm.patchValue({ serviceDocumentationURL: this.paymentDispute.serviceDocumentationURL });
      } else {
        this.serviceDocumentation = [];
      }

      if (this.paymentDispute.customerCommunicationURL) {
        this.customerCommunication = [this.paymentDispute.customerCommunicationURL];
        this.paymentDisputeForm.patchValue({ customerCommunicationURL: this.paymentDispute.customerCommunicationURL });
      } else {
        this.customerCommunication = [];
      }

      if (this.paymentDispute.customerSignatureURL) {
        this.customerSignature = [this.paymentDispute.customerSignatureURL];
        this.paymentDisputeForm.patchValue({ customerSignatureURL: this.paymentDispute.customerSignatureURL });
      } else {
        this.customerSignature = [];
      }

      if (this.paymentDispute.receiptURL) {
        this.receipt = [this.paymentDispute.receiptURL];
        this.paymentDisputeForm.patchValue({ receiptURL: this.paymentDispute.receiptURL });
      } else {
        this.receipt = [];
      }

      if (this.paymentDispute.cancellationPolicyURL) {
        this.cancellationPolicy = [this.paymentDispute.cancellationPolicyURL];
        this.paymentDisputeForm.patchValue({ cancellationPolicyURL: this.paymentDispute.cancellationPolicyURL });
      } else {
        this.cancellationPolicy = [];
      }

      this.paymentDisputeForm.patchValue({
        cancellationPolicyDisclosure: this.paymentDispute.cancellationPolicyDisclosure,
      });

      if (this.paymentDispute.duplicateChargeDocumentationURL) {
        this.duplicateChargeDocumentation = [this.paymentDispute.duplicateChargeDocumentationURL];
        this.paymentDisputeForm.patchValue({
          duplicateChargeDocumentationURL: this.paymentDispute.duplicateChargeDocumentationURL,
        });
      } else {
        this.duplicateChargeDocumentation = [];
      }

      this.paymentDisputeForm.patchValue({
        duplicateChargeExplanation: this.paymentDispute.duplicateChargeExplanation,
      });

      if (this.paymentDispute.refundPolicyURL) {
        this.refundPolicy = [this.paymentDispute.refundPolicyURL];
        this.paymentDisputeForm.patchValue({ refundPolicyURL: this.paymentDispute.refundPolicyURL });
      } else {
        this.refundPolicy = [];
      }

      this.paymentDisputeForm.patchValue({
        refundPolicyDisclosure: this.paymentDispute.refundPolicyDisclosure,
      });

      if (this.paymentDispute.uncategorizedFileURL) {
        this.uncategorizedFile = [this.paymentDispute.uncategorizedFileURL];
        this.paymentDisputeForm.patchValue({ uncategorizedFileURL: this.paymentDispute.uncategorizedFileURL });
      } else {
        this.uncategorizedFile = [];
      }

      this.paymentDisputeForm.patchValue({
        uncategorizedText: this.paymentDispute.uncategorizedText,
      });
    }
  }
}
