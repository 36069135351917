import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList } from '@angular/core';
import { MatMenuItem } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-drop-down-button',
  templateUrl: './drop-down-button.component.html',
  styleUrls: ['./drop-down-button.component.scss']
})
export class DropDownButtonComponent implements AfterViewInit, OnDestroy {
  @Output() btnClick = new EventEmitter<Event>();
  @Input() disabled: boolean;

  @ContentChildren(MatMenuItem, { descendants: true }) private _menuItems: QueryList<MatMenuItem>;

  showMenuButton = false;

  private _onDestroy = new Subject();

  ngAfterViewInit() {
    this.updateShowMenuButton();
    this._menuItems.changes.pipe(takeUntil(this._onDestroy)).subscribe(() => this.updateShowMenuButton());
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private updateShowMenuButton() {
    setTimeout(() => (this.showMenuButton = !!this._menuItems.length));
  }
}
