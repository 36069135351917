<div style="display: flex; flex-direction: row">
  <mat-chip-list #chipList style="white-space: nowrap" style="flex: 1">
    <mat-chip *ngFor="let tag of selectedTags" style="margin-right: 4px">
      {{ tag.label }}
      <mat-icon *ngIf="!disabled" (click)="select(tag)">cancel</mat-icon>
    </mat-chip>

    <input
      #input
      matInput
      type="text"
      autocomplete="off"
      [disabled]="disabled"
      [required]="required"
      [placeholder]="placeholder"
      class="product-variant-picker-input"
      (input)="searchQuery$.next($any($event).target.value)"
      (blur)="touched()"
      [matMenuTriggerFor]="tagsAndResources"
      [matChipInputFor]="chipList"
    />
  </mat-chip-list>

  <div style="display: flex; flex-direction: column; justify-content: flex-end">
    <button
      class="trigger"
      #trigger="matMenuTrigger"
      [disabled]="disabled"
      [matMenuTriggerFor]="tagsAndResources"
    ></button>
    <button
      mat-icon-button
      style="margin: -12px"
      [disabled]="disabled"
      (click)="trigger.openMenu(); searchQuery$.next(''); input.value = ''"
    >
      <mat-icon>manage_search</mat-icon>
    </button>
  </div>
</div>

<!-- Menu for filterable items -->
<mat-menu #tagsAndResources (close)="stateChanges.next(); searchQuery$.next(''); input.value = ''">
  <!-- Don't show resources if they're not to be included -->
  <ng-container *grecoLet="resourceTags$ | async as tags">
    <button
      *ngFor="let tag of tags"
      mat-menu-item
      [disabled]="disabled"
      (click)="select(tag); $event.stopImmediatePropagation()"
    >
      <mat-checkbox
        style="margin-right: 4px"
        color="primary"
        [checked]="selectedTagIds.includes(tag.id)"
        [disableRipple]="true"
        (click)="$event.preventDefault()"
      ></mat-checkbox>
      <span [innerHTML]="tag.label | safe : 'html'"></span>
    </button>
    <button *ngIf="!tags?.length" mat-menu-item disabled="true"><i>No Tags</i></button>
  </ng-container>
</mat-menu>
