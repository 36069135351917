import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[item-start]' })
export class GrecoItemStartDirective {}

@Component({
  selector: 'greco-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class GrecoItemComponent implements AfterViewInit, OnDestroy {
  private _onDestroy = new Subject();

  @Input() @HostBinding('class.lines') lines = true;

  @ContentChildren(GrecoItemStartDirective) private _startContent?: QueryList<any>;
  hasStartContent = true;

  ngAfterViewInit() {
    if (this._startContent) {
      this._startContent.changes
        .pipe(startWith(this._startContent))
        .pipe(takeUntil(this._onDestroy))
        .subscribe(content => setTimeout(() => (this.hasStartContent = !!content.length)));
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
