import { validateSync } from 'class-validator';

export abstract class BaseDto {
  static factory<T extends BaseDto>(this: new () => T, dto: T): T {
    const instance = new this();
    Object.assign(instance, dto);
    return instance;
  }

  static validatedFactory<T extends BaseDto>(this: new () => T, dto: T): T {
    const instance = new this();
    Object.assign(instance, dto);

    const errors = validateSync(instance);
    if (errors.length) throw new Error(errors.map(err => err.toString()).join('\n'));

    return instance;
  }
}
