/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { CalendarService } from '../../services';
@Component({
  selector: 'greco-create-calendar',
  templateUrl: './create-calendar.dialog.html',
  styleUrls: ['./create-calendar.dialog.scss'],
})
export class CreateCalendarDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private calendarSvc: CalendarService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {}

  private _onDestroy$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    title: ['', Validators.required],
    group: [null],
    icon: [null, Validators.required],
    image: [null],
    private: [false],
  });

  dialogData: DialogData = {
    title: 'Create New Calendar',
    subtitle: 'Provide the name of your new Calendar',
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          try {
            const data = this.formGroup.value;

            const formData = new FormData();
            formData.append('file', data.image[0]);
            formData.append('title', data.title || '');
            formData.append('group', data.group || '');
            formData.append('icon', data.icon || null);
            formData.append('private', data.private || false);
            formData.append('imageUrl', '');

            const calendar = await this.calendarSvc.create(this.data.communityId, formData);

            this.snacks.open('New Calendar Created', 'OK', { duration: 2500, panelClass: 'mat-primary' });
            return calendar;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
          return btn;
        }),
      };
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
