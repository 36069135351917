import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector, Optional, Type } from '@angular/core';
import type { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { UserAgreementDto } from '@greco/community-agreements';
import { DataExport } from '@greco/data-exports';
import { Payment, PaymentStatus } from '@greco/finance-payments';
import type { CreatePurchaseDto } from '@greco/nestjs-sales-purchases';
import { Purchase, PurchaseStats, PurchaseStatus, Refund } from '@greco/sales-purchases';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { Observable } from 'rxjs';

export interface PaymentHandler {
  handlePendingPayment(purchase: Purchase & { payment: Payment }, cancel$?: Observable<void>): Promise<void>;
  handleInteracRefund(
    purchase: Purchase & { payment: Payment },
    terminalId: string,
    refundAmount: number,
    cancel$?: Observable<void>
  ): Promise<string | null>;
}

export const PAYMENT_HANDLERS = new InjectionToken<Type<PaymentHandler>[]>('PAYMENT_HANDLERS');

@Injectable()
export class PurchaseService {
  constructor(
    private http: HttpClient,
    private injector: Injector,
    @Optional() @Inject(PAYMENT_HANDLERS) private paymentHandlers: null | Type<PaymentHandler>[]
  ) {}

  // @Get('stats')
  async getStats(
    queryBuilder: RequestQueryBuilder,
    filters?: { accountId?: string; userId?: string }
  ): Promise<PurchaseStats> {
    return await toPromise(
      this.http.get<PurchaseStats>('/api/purchases/stats', {
        params: { ...filters, ...queryBuilder.queryObject },
      })
    );
  }

  // @Post()
  async createPurchase(dto: CreatePurchaseDto<any>, hash: string, data: UserAgreementDto[]): Promise<Purchase> {
    return await toPromise(this.http.post<Purchase>('/api/purchases', { ...dto, hash, data }));
  }

  // @Post('export')
  async exportPurchases(
    queryBuilder: RequestQueryBuilder,
    filters?: { accountId?: string; userId?: string; variantIds?: string[] }
  ) {
    return await toPromise(
      this.http.post<DataExport>('/api/purchases/export', {
        ...filters,
        ...queryBuilder.queryObject,
      })
    );
  }

  // @Get()
  async paginate(
    queryBuilder: RequestQueryBuilder,
    pagination?: { limit?: number; page?: number },
    filters?: {
      accountId?: string;
      userId?: string;
      saleCategoryIds?: string[];
      variantIds?: string[];
      forCheckInStations?: boolean;
    },
    showUncategorized?: boolean,
    loadChildrenPurchases?: boolean
  ): Promise<PaginatedDto<Purchase>> {
    return await toPromise(
      this.http.get<PaginatedDto<Purchase>>('/api/purchases', {
        params: {
          ...filters,
          ...queryBuilder.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          showUncategorized: (showUncategorized || false).toString(),
          loadChildrenPurchases: (loadChildrenPurchases || false).toString(),
          forCheckInStations: (filters?.forCheckInStations || false).toString(),
        },
      })
    );
  }

  // @Get(':id')
  async getOne(purchaseId: string): Promise<Purchase> {
    return await toPromise(this.http.get<Purchase>(`/api/purchases/${purchaseId}`));
  }

  // @Get('subscription/:subscriptionId')
  async getOneFromSubscription(subscriptionId: string): Promise<Purchase> {
    return await toPromise(this.http.get<Purchase>(`/api/purchases/subscription/${subscriptionId}`));
  }

  async getPurchaseRefunds(purchaseId: string): Promise<Refund[]> {
    return await toPromise(this.http.get<Refund[]>(`/api/purchases/${purchaseId}/refunds`));
  }

  // @Post(':purchaseId/refund')
  async refund(
    purchaseId: string,
    balanceRefundAmount: number,
    paymentRefundAmount: number,
    refundReason?: string,
    externalId?: string
  ): Promise<Purchase> {
    return await toPromise(
      this.http.post<Purchase>(`/api/purchases/${purchaseId}/refund`, {
        balanceRefundAmount,
        paymentRefundAmount,
        refundReason,
        externalId,
      })
    );
  }

  // @Post('undo-refund/:refundId')
  async undoRefund(refundId: string, balanceUndoneAmount: number): Promise<Refund> {
    return await toPromise(
      this.http.post<Refund>(`/api/purchases/undo-refund/${refundId}`, {
        balanceUndoneAmount,
      })
    );
  }

  // @Post(':purchaseId/retry')
  async retryPayment(purchaseId: string, paymentMethodId?: string, useBalance?: boolean): Promise<Purchase> {
    return await toPromise(
      this.http.post<Purchase>(`/api/purchases/${purchaseId}/retry`, {
        useBalance,
        ...(paymentMethodId ? { paymentMethodId } : {}),
      })
    );
  }

  // @Get('bulk-retry')
  async getBulkRetryTotal(
    queryBuilder: RequestQueryBuilder,
    filters?: {
      accountId?: string;
      userId?: string;
      saleCategoryIds?: string[];

      variantIds?: string[];
    },
    showUncategorized?: boolean
  ): Promise<number> {
    return await toPromise(
      this.http.get<number>('/api/purchases/bulk-retry', {
        params: {
          ...filters,
          ...queryBuilder.queryObject,
          showUncategorized: (showUncategorized || false).toString(),
        },
      })
    );
  }

  // @Post('bulk-retry')
  async bulkRetry(
    queryBuilder: RequestQueryBuilder,
    filters?: {
      accountId?: string;
      userId?: string;
      saleCategoryIds?: string[];
      variantIds?: string[];
    },
    showUncategorized?: boolean,
    paymentMethods?: Record<string, string>
  ) {
    return await toPromise(
      this.http.post<{ errors: string[] }>(
        '/api/purchases/bulk-retry',
        {
          ...filters,
          ...queryBuilder.queryObject,
          showUncategorized: (showUncategorized || false).toString(),
          paymentMethods,
        },
        {
          params: {
            ...queryBuilder.queryObject,
          },
        }
      )
    );
  }

  async handleTerminalPayment(purchase: Purchase, cancel$?: Observable<void>) {
    if (
      purchase.status === PurchaseStatus.PENDING &&
      purchase.payment?.status === PaymentStatus.PENDING_PAYMENT &&
      purchase.payment?.paymentMethod?.model === 'terminal'
    ) {
      try {
        const result = await Promise.race([
          ...(cancel$ ? [toPromise(cancel$).then(() => false)] : []),
          this.handlePendingPayment(purchase as any, cancel$).then(() => true),
        ]);

        if (result) purchase = await this.complete(purchase.id); // 2. If success: complete purchase
        else throw null;
      } catch (err) {
        console.error(err);

        // 3. If not: void purchase
        purchase = await this.void(purchase.id, 'Terminal Payment Failed').catch(err => {
          console.error(err);
          return purchase;
        });
      }
    }

    return purchase;
  }

  async handleInteracRefund(purchase: Purchase, terminalId: string, refundAmount: number, cancel$?: Observable<void>) {
    if (purchase.payment?.gatewayMetadata?.payment_method_type === 'interac_present') {
      try {
        return await Promise.race([
          ...(cancel$ ? [toPromise(cancel$).then(() => null)] : []),
          (async () => {
            for (const handler of this.paymentHandlers ?? []) {
              const instance = this.injector.get(handler);

              const refundId = await instance.handleInteracRefund(purchase as any, terminalId, refundAmount, cancel$);
              if (refundId) return refundId;
            }

            return null;
          })(),
        ]);
      } catch (err) {
        console.error(err);
        throw err;
      }
    }

    return null;
  }

  // @Post(':purchaseId/retry')
  async complete(purchaseId: string): Promise<Purchase> {
    return await toPromise(this.http.post<Purchase>(`/api/purchases/${purchaseId}/complete`, {}));
  }

  // @Post(':purchaseId/send-purchase-receipt')
  async sendPurchaseReceipt(purchaseId: string) {
    return await toPromise(this.http.post<any>(`/api/purchases/${purchaseId}/send-purchase-receipt`, {}));
  }

  // @Post(':purchaseId/send-purchase-failed')
  async sendPurchaseFailedEmail(purchaseId: string) {
    return await toPromise(this.http.post<any>(`/api/purchases/${purchaseId}/send-purchase-failed`, {}));
  }

  // @Post(':purchaseId/void')
  async void(purchaseId: string, reason?: string): Promise<Purchase> {
    return await toPromise(
      this.http.post<Purchase>(`/api/purchases/${purchaseId}/void`, {
        reason,
      })
    );
  }

  // @Get('bulk-void')
  async getBulkVoidTotal(
    queryBuilder: RequestQueryBuilder,
    filters?: {
      accountId?: string;
      userId?: string;
      saleCategoryIds?: string[];

      variantIds?: string[];
    },
    showUncategorized?: boolean
  ): Promise<number> {
    return await toPromise(
      this.http.get<number>('/api/purchases/bulk-void', {
        params: {
          ...filters,
          ...queryBuilder.queryObject,
          showUncategorized: (showUncategorized || false).toString(),
        },
      })
    );
  }

  // @Post('bulk-void')
  async bulkVoid(
    queryBuilder: RequestQueryBuilder,
    reason: string,
    filters?: {
      accountId?: string;
      userId?: string;
      saleCategoryIds?: string[];
      variantIds?: string[];
    },
    showUncategorized?: boolean
  ) {
    return await toPromise(
      this.http.post<{ errors: string[] }>(
        '/api/purchases/bulk-void',
        {
          ...filters,
          ...queryBuilder.queryObject,
          showUncategorized: (showUncategorized || false).toString(),
          reason,
        },
        {
          params: {
            ...queryBuilder.queryObject,
          },
        }
      )
    );
  }

  // @Patch('item-update/:itemId')
  async updatePurchaseItemSaleCategory(itemId: string, categoryId: string) {
    return await toPromise(this.http.patch<any>(`/api/purchases/item-update/${itemId}`, { categoryId }));
  }

  async setSoldBy(purchaseId: string, soldById: string | null) {
    return await toPromise(this.http.post<Purchase>(`/api/purchases/${purchaseId}/sold-by`, { soldById }));
  }

  async setReferredBy(purchaseId: string, referredById: string | null) {
    return await toPromise(this.http.patch<Purchase>(`/api/purchases/${purchaseId}/referred-by`, { referredById }));
  }

  // @Post(':purchaseId/payment_method')
  async updatePaymentMethod(purchaseId: string, paymentMethodId: string): Promise<Purchase> {
    return await toPromise(
      this.http.post<Purchase>(`/api/purchases/${purchaseId}/payment_method`, { paymentMethodId })
    );
  }

  private async handlePendingPayment(purchase: Purchase & { payment: Payment }, cancel$?: Observable<void>) {
    for (const handler of this.paymentHandlers ?? []) {
      const instance = this.injector.get(handler);
      await instance.handlePendingPayment(purchase, cancel$);
    }
  }
}
