import { Component } from '@angular/core';
import { UserEventsFavoritesService } from '@greco/ngx-booking-events';
import { DashboardBookingsService } from '../../services/dashboard-bookings.service';
import { DashboardTilesService } from '../../services/dashboard-tiles.service';

@Component({
  selector: 'greco-dashboard-tiles',
  templateUrl: './dashboard-tiles.component.html',
  styleUrls: ['./dashboard-tiles.component.scss'],
})
export class DashboardTilesComponent {
  constructor(
    public dashboardTilesSvc: DashboardTilesService,
    public dashboardBookingsSvc: DashboardBookingsService,
    public dashboardFavoritesSvc: UserEventsFavoritesService
  ) {}
}
