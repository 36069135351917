import { Component, Input } from '@angular/core';
import { VariantsService } from '@greco/ngx-sales-products';
import { PropertyListener } from '@greco/property-listener-util';

import { Product, ProductVariant } from '@greco/sales-products';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent {
  constructor(private variantSvc: VariantsService) {}
  @Input() product!: Product;
  @PropertyListener('selectedVariant') private _selectedVariant$ = new BehaviorSubject<ProductVariant | null>(null);
  @Input() selectedVariant!: ProductVariant;

  autoUpgradeVariant = this._selectedVariant$.pipe(
    switchMap(async () => {
      const productId = this.product.id;
      const variantId = this.selectedVariant.autoUpgradeVariantId || '';
      return await this.variantSvc.getOneVariant({ productId, variantId });
    })
  );
}
