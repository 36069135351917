import { Component, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { Schedule, ScheduleFrequency } from '@greco/schedule';
import { RRule } from 'rrule';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const FrequencyValidator: ValidatorFn = control =>
  control.value ? (Object.values(ScheduleFrequency).includes(control.value) ? null : { frequency: true }) : null;

const RRuleValidator: ValidatorFn = control => {
  if (!control.value) return null;
  try {
    RRule.fromString(control.value);
    return null;
  } catch {
    return { rrule: true };
  }
};

@Component({
  selector: 'greco-schedule-input',
  templateUrl: './schedule-input.component.html',
  styleUrls: ['./schedule-input.component.scss'],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: ScheduleInputComponent,
    },
  ],
})
export class ScheduleInputComponent implements ControlValueAccessor, OnDestroy {
  constructor(private formBuiler: FormBuilder) {
    this.form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.onChanged?.(this.value));
  }

  form = this.formBuiler.group({
    start: [null, Validators.required],
    frequency: [ScheduleFrequency.DAILY, [Validators.required, FrequencyValidator]],
    until: [null],
    rrule: ['', RRuleValidator],
  });

  nowDate = new Date();

  get value() {
    return this.form.valid
      ? ({
          date: this.form.value.start,
          frequency: this.form.value.frequency,
          until: this.form.value.frequency === ScheduleFrequency.ONCE ? null : this.form.value.until,
          rrule: this.form.value.rrule || '',
        } as Schedule)
      : null;
  }
  set value(value: Schedule | null) {
    this.form.patchValue({
      start: value?.date || null,
      frequency: value?.frequency || ScheduleFrequency.DAILY,
      until: value?.until || null,
      rrule: value?.rrule || '',
    });
  }

  private onChanged?: (value: Schedule | null) => void;
  private onTouched?: () => void;

  private _onDestroy$ = new Subject<void>();

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: Schedule): void {
    this.value = value;
  }

  registerOnChange(fn: (value: Schedule | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  touched() {
    this.onTouched?.();
  }
}
