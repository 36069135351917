import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommunityService } from '@greco/ngx-identity-communities';
import { SaleCategoryService } from '@greco/ngx-sales-purchases';
import { PropertyListener } from '@greco/property-listener-util';
import { SaleCategory } from '@greco/sales-purchases';
import {
  DynamicFormControlComponent,
  DynamicFormControlCustomEvent,
  DynamicFormControlLayout,
  DynamicFormLayoutService,
  DynamicFormValidationService,
} from '@ng-dynamic-forms/core';
import { ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DynamicCategoryPickerModel } from '../../forms';

@Component({
  selector: 'greco-dynamic-form-category-picker',
  templateUrl: './dynamic-form-category-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormControlCategoryPickerComponent extends DynamicFormControlComponent {
  @Input() group!: FormGroup;
  @Input() layout!: DynamicFormControlLayout;
  @PropertyListener('model') model$ = new ReplaySubject<DynamicCategoryPickerModel>(1);
  @Input()
  model!: DynamicCategoryPickerModel;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<any> = new EventEmitter();

  @ViewChild(DynamicFormControlComponent) myCustomFormControlComponent!: DynamicFormControlCategoryPickerComponent;
  constructor(
    protected layoutService: DynamicFormLayoutService,
    protected validationService: DynamicFormValidationService,
    private categorySvc: SaleCategoryService,
    private communitySvc: CommunityService
  ) {
    super(layoutService, validationService);
  }

  readonly categories$ = this.model$.pipe(
    switchMap(async model => (model?.communityId ? await this.communitySvc.getCommunity(model.communityId) : null)),
    switchMap(async community =>
      community?.financeAccountId ? await this.categorySvc.getMany(community.financeAccountId) : []
    )
  );

  compareWith(s1: SaleCategory, s2: SaleCategory) {
    if (s1 && s2) {
      return s1.id === s2.id;
    }
    return false;
  }
}
