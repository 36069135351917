import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Injector, Input, OnInit, Output, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayUtils, toPromise } from '@greco-fit/util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { map } from 'rxjs/operators';
import { FilterDialog } from '../../dialogs';
import { Filter } from '../../models';
import { isCondOperator } from '../../utils';

export interface FilterBarValue {
  filter: Filter;
  operator: CondOperator;
  value: any;
}

@Component({
  selector: 'greco-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private injector: Injector,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {}

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));

  @Output() changed = new EventEmitter<[FilterBarValue[], RequestQueryBuilder]>();

  @Output() loaded = new EventEmitter();

  @Input() hideSeparator = false;

  @Input() calendars = false;
  @Input() saleCategories = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('filterOptions') set filterOptionTypes(types: Type<Filter>[]) {
    this.filterOptions = (types || []).map(FilterType => this.injector.get(FilterType));
  }

  filterOptions: Filter[] = [];

  private _values: FilterBarValue[] = [];

  get values() {
    return this._values;
  }
  set values(values: FilterBarValue[]) {
    this._values = values;
    this._emitChanges();
    this._updateQueryParams();
  }

  async optionSelected(filter: Filter, value: any | null) {
    if (!filter.allowedOperators.length) return;

    if (value !== null) {
      this.values = [
        ...this.values,
        { filter, value: filter.type === 'select' ? [value] : value, operator: filter.allowedOperators[0] },
      ];
    } else {
      const result = await toPromise(
        this.dialog.open(FilterDialog, { data: { filter, value, operator: null } }).afterClosed()
      );
      if (result !== undefined) {
        this.values = [...this.values, { filter, value: result.value, operator: result.operator }];
      }
    }
  }

  async updateFilter(index: number) {
    const { filter, value, operator } = this.values[index];
    if (!filter.canOpen) return;

    const result = await toPromise(this.dialog.open(FilterDialog, { data: { filter, value, operator } }).afterClosed());
    if (result !== undefined) {
      this.values[index] = { filter, value: result.value, operator: result.operator };
      this.values = [...this.values];
    }
  }

  removeFilter(index: number) {
    this.values.splice(index, 1);
    this.values = [...this.values];
  }

  private async _updateQueryParams() {
    const filters: string[] = [];
    for (const { filter, value, operator } of this.values) {
      filters.push(filter.formatAsQueryParam(operator, value));
    }

    const { filters: _, ...queryParams } = await toPromise(this.route.queryParams);
    await this.setLocalStorageValues(filters);
    await this.router.navigate([], {
      queryParams: { ...queryParams, ...(filters.length ? { filters } : {}) },
    });
  }

  private async _loadFromQueryParams() {
    let params: string[] = await toPromise(this.route.queryParams.pipe(map(({ filters }) => filters)));
    if (!Array.isArray(params)) params = typeof params === 'string' ? [params] : [];

    const paramValues: FilterBarValue[] = [];

    for (const param of params) {
      const match = param.match(/^\[(?<filter>.+)\]\|\|\[(?<operator>.+)\]\|\|\[(?<value>.+)\]$/);
      if (!match?.groups) continue;

      const { filter, operator, value } = match.groups;

      if (!isCondOperator(operator)) continue;

      const filterOption = this.filterOptions.find(opt => opt.typeName === filter);

      if (!filterOption) continue;

      try {
        const deserializedValue = await filterOption.deserializeValue(value);
        paramValues.push({ filter: filterOption, operator, value: deserializedValue });
      } catch (err) {
        console.warn(err);
      }
    }

    if (paramValues.length) {
      this.values = paramValues;
    } else {
      const storedValues = await this.getLocalStorageValues();
      if (storedValues.length) this.values = storedValues;
      else this.values = [];
    }

    this.loaded.emit();
  }

  async _emitChanges() {
    // FilterOptions
    let params: string[] = await toPromise(this.route.queryParams.pipe(map(({ filters }) => filters)));
    if (!Array.isArray(params)) params = typeof params === 'string' ? [params] : [];

    const values: FilterBarValue[] = [];

    for (const param of params) {
      const match = param.match(/^\[(?<filter>.+)\]\|\|\[(?<operator>.+)\]\|\|\[(?<value>.+)\]$/);
      if (!match?.groups) continue;

      const { filter, operator, value } = match.groups;
      if (!isCondOperator(operator)) continue;

      const filterOption = this.filterOptions.find(opt => opt.constructor.name === filter);
      if (!filterOption) continue;

      try {
        const deserializedValue = await filterOption.deserializeValue(value);
        values.push({ filter: filterOption, operator, value: deserializedValue });
      } catch (err) {
        console.warn(err);
      }
    }

    const queryBuilder = new RequestQueryBuilder();
    queryBuilder.search({
      $and: [
        ...this.values
          .filter(value => value.filter.type !== 'autocomplete')
          .map(value => value.filter.getQueryBuilderSearchOptions(value.operator, value.value)),

        ...Object.values(
          ArrayUtils.groupBy(
            this.values.filter(value => value.filter.type === 'autocomplete'),
            value => value.filter.typeName
          )
        ).map(values => ({
          $or: values.map(({ filter, operator, value }) => filter.getQueryBuilderSearchOptions(operator, value)),
        })),
      ],
    });

    this.changed.emit([this.values, queryBuilder]);
  }

  async getLocalStorageValues(): Promise<FilterBarValue[]> {
    const pageName = this.router.url.split('?')[0];
    const result = localStorage.getItem(pageName + '-filters');

    if (result) {
      const params: string[] = JSON.parse(result).params || [];
      const values: FilterBarValue[] = [];
      for (const param of params) {
        const match = param.match(/^\[(?<filter>.+)\]\|\|\[(?<operator>.+)\]\|\|\[(?<value>.+)\]$/);
        if (!match?.groups) continue;

        const { filter, operator, value } = match.groups;
        if (!isCondOperator(operator)) continue;

        const filterOption = this.filterOptions.find(opt => opt.constructor.name === filter);
        if (!filterOption) continue;
        try {
          const deserializedValue = await filterOption.deserializeValue(value);
          values.push({ filter: filterOption, operator, value: deserializedValue });
        } catch (err) {
          console.warn(err);
        }
      }
      return values;
    } else return [];
  }

  clearLocalStorageValues() {
    const pageName = this.router.url.split('?')[0];
    localStorage.removeItem(pageName + '-filters');
  }

  async setLocalStorageValues(params: string[]) {
    const pageName = this.router.url.split('?')[0];
    if (!Array.isArray(params)) params = typeof params === 'string' ? [params] : [];
    if (params.length) localStorage.setItem(pageName + '-filters', JSON.stringify({ params }));
    else this.clearLocalStorageValues();
  }

  filterOptionInValues(filter: Filter) {
    return this.values.some(value => value.filter.label === filter.label);
  }

  async ngOnInit() {
    await this._loadFromQueryParams();
  }
}
