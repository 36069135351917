import { Injectable } from '@angular/core';
import { BuildDateFilter } from '@greco/ngx-filters';

@Injectable({ providedIn: 'any' })
export class BookingEventStartDateFilter extends BuildDateFilter('BookingEventStartDateFilter', {
  label: 'Event Start',
  shortLabel: 'Start',
  description: '',
  properties: ['event.startDate'],
}) {}
