import { BaseDto } from '@greco/util-dto';
import { Type } from 'class-transformer';
import { IsArray, IsEnum, IsString, ValidateNested } from 'class-validator';
import { AddonType, ProductAddon } from '../product-addon';

export declare interface Class<T> extends Function {
  new (...args: any[]): T;
}

export interface UserAvailabilityAddon extends ProductAddon {
  conditions: ProductCondition[];
}

export class UserAvailabilityAddonDto extends BaseDto {
  @IsEnum(AddonType)
  type: AddonType.UserAvailability;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => ProductConditionDto)
  conditions: ProductConditionDto[];
}

export interface ProductCondition {
  id: string;
  type: string;

  addonId: string;
  addon?: UserAvailabilityAddon;
}

export abstract class ProductConditionDto extends BaseDto {
  @IsString()
  type: string;
}

type CountById = { [id: string]: number };

export class ProductConditionContext {
  productPurchases?: CountById;
  categoryPurchases?: CountById;

  productSubscriptions?: CountById;
  categorySubscriptions?: CountById;
}

export enum ProductConditionOperator {
  EQUALS = '=',
  GREATER_THAN = '>',
  GREATER_THAN_EQUALS = '>=',
  LESS_THAN = '<',
  LESS_THAN_EQUALS = '<=',
}
export class ProductConditionEvaluation {
  result: boolean;
  message?: string;
  error?: string;
}
export class ProductConditionEvaluations {
  result: boolean;
  messages: string[];
  errors: string[];
}

export type ConditionEvaluator<T extends ProductCondition> = (
  condition: T,
  ctx: ProductConditionContext
) => ProductConditionEvaluation;

export type ConditionDtoType = Class<ProductConditionDto>;

export class ProductConditionsRegistry {
  private static dtos: Record<string, ConditionDtoType> = {};

  private static evaluators: Record<string, ConditionEvaluator<ProductCondition>> = {};

  static registerCondition<T extends ProductCondition>(
    type: string,
    dto: ConditionDtoType,
    evaluator: ConditionEvaluator<T>
  ) {
    this.dtos[type] = dto;
    this.evaluators[type] = evaluator;
  }

  static getDto(type: string) {
    return this.dtos[type];
  }

  static evaluateOne(condition: ProductCondition, context: ProductConditionContext): ProductConditionEvaluation {
    try {
      if (!context) return { result: false, error: 'Missing condition context' };

      const evaluator = this.evaluators[condition.type];
      if (!evaluator) return { result: false, error: `Condition not registered: ${condition.id}` };

      return evaluator(condition, context);
    } catch {
      return { result: false, error: 'Unknown error' };
    }
  }

  static evaluateAll(conditions: ProductCondition[], ctx: ProductConditionContext): ProductConditionEvaluations {
    if (!ctx) return { result: false, errors: ['Missing condition context'], messages: [] };

    let result = true;
    const errors: string[] = [];
    const messages: string[] = [];

    conditions
      .map(condition => this.evaluateOne(condition, ctx))
      .forEach(({ result: r, error, message }) => {
        if (message) messages.push(message);
        if (error) errors.push(error);
        if (!r) result = false;
      });

    return { result, errors, messages };
  }
}
