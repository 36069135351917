import { SecurityResource } from '@greco/security';

export const AGREEMENT_USAGE_SECURITY_RESOURCE = 'agreements_usage';

export enum AgreementUsageSecurityActions {
  READ = 'READ',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export const AgreementUsageSecurityResource: SecurityResource = {
  key: AGREEMENT_USAGE_SECURITY_RESOURCE,
  module: 'community',
  title: 'Community Usage Agreements ',
  description: 'Manage community usage agreement options',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: AgreementUsageSecurityActions.READ,
      title: 'Read usage agreements in a community',
      description: 'Ability to read usage agreements in a community',
    },
    {
      key: AgreementUsageSecurityActions.CREATE,
      title: 'Create usage agreements to a community',
      description: 'Ability to create a new usage agreement in a community',
    },
    {
      key: AgreementUsageSecurityActions.DELETE,
      title: 'Delete usage agreements from a community',
      description: 'Ability to delete a usage agreement in a community',
    },
  ],
};
