<greco-simple-dialog [data]="data">
  <ng-container *grecoLet="formValue$ | async">
    <form [formGroup]="formGroup">
      <!-- Start Type  -->
      <div style="display: flex; align-items: flex; gap: 8px; margin-top: 6px; margin-bottom: 30px">
        <mat-radio-group formControlName="dateRadioButton" aria-label="Start Subscription: ">
          <mat-radio-button style="margin-left: 16px" color="primary" value="now">Now</mat-radio-button>
          <mat-radio-button style="margin-left: 16px" color="primary" value="future">In the Future</mat-radio-button>
        </mat-radio-group>
      </div>
      <div style="display: block; font-style: italic">
        <span strong> Your subscriptions start date is {{formGroup.value.dateSelection | date}}</span>
      </div>
      <div>
        <ng-template #loading>
          <mat-spinner [diameter]="20"></mat-spinner>
        </ng-template>
      </div>
      <!-- Start Date  -->

      <div>
        <mat-form-field *ngIf="formGroup.value.dateRadioButton == 'future'" style="margin-top: 6px" appearance="fill">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            [min]="minimalDate"
            [max]="maximalDate"
            [matDatepicker]="picker"
            (click)="picker.open()"
            formControlName="dateSelection"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div
        style="align-items: flex; gap: 8px; margin-top: 6px; margin-bottom: 30px"
        strong
        *ngIf="validBulkSubscriptionInfo$ | async as info; else loading;"
      >
        <div style="display: flex; align-items: center; padding: 0 8px; margin-top: 16px">
          <mat-icon color="info" style="margin-right: 8px; size: 10px">info</mat-icon>The number of selected items
          is:&nbsp; <b> {{data?.stats?.totalItems}}</b>.
        </div>
        <div style="display: flex; align-items: center; padding: 0 8px; margin-top: 16px">
          <mat-icon color="info" style="margin-right: 8px">info</mat-icon>The number of items that qualify for the
          operation: &nbsp;<b> {{$any(info)?.result}}</b>.
        </div>
      </div>
      <!-- Confirmation -->
      <div style="display: flex; align-items: center; gap: 8px">
        <button
          type="button"
          color="primary"
          style="flex: 1"
          mat-stroked-button
          (click)="confirmation('cancel')"
          [disabled]="loading$.value"
        >
          Cancel
        </button>
        <button
          type="button"
          color="primary"
          style="flex: 1"
          mat-stroked-button
          (click)="confirmation('confirm')"
          [disabled]="loading$.value || processing"
        >
          Confirm
        </button>
      </div>
    </form>
  </ng-container>
</greco-simple-dialog>
