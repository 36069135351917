import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CreateCouponAssignmentDto, UpdateCouponDto } from '@greco/api-coupons';
import { CouponAssignment } from '@greco/coupon';
import { ProductCouponAssignment } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}

@Injectable({
  providedIn: 'root',
})
export class CouponAssignmentService {
  constructor(private http: HttpClient) {}
  baseURL = '/api/coupon-assignments';
  async paginate(
    couponId: string,
    pagination: IPaginationOptions,
    queryBuilder: RequestQueryBuilder
  ): Promise<PaginatedDto<CouponAssignment>> {
    const paginated = await toPromise(
      this.http.get<PaginatedDto<CouponAssignment>>(`${this.baseURL}/paginate`, {
        params: {
          couponId,
          ...paginationQueryParams(pagination),
          ...queryBuilder.queryObject,
        },
      })
    );
    return paginated;
  }

  async getOne(assignmentId: string): Promise<ProductCouponAssignment> {
    return await toPromise(this.http.get<ProductCouponAssignment>(`${this.baseURL}/${assignmentId}`));
  }

  async create(dto: CreateCouponAssignmentDto[], module: string) {
    return await toPromise(this.http.post<ProductCouponAssignment[]>(`${this.baseURL}/${module}`, dto));
  }

  async archive(assignmentId: string) {
    return await toPromise(this.http.delete<ProductCouponAssignment>(`${this.baseURL}/${assignmentId}`));
  }

  async update(assignmentId: string, coupon: UpdateCouponDto) {
    return await toPromise(this.http.post<ProductCouponAssignment>(`${this.baseURL}/${assignmentId}`, coupon));
  }
}
