import { SecurityResource } from '@greco/security';

export const PurchaseItemResource: SecurityResource = {
  key: 'finance_purchasing_purchase_item',
  module: 'Finance',

  title: 'Purchase Item',
  description: 'Manage items in purchases',

  context: class {
    id?: string;
  },

  actions: [
    { key: 'READ', title: 'Read purchase items', description: '' },
    { key: 'DELETE', title: 'Delete purchase items', description: '' },
    {
      key: 'UPDATE',
      title: 'Update purchase item sale categories',
      description: 'Ability to retroactively update the sale category of purchase items',
    },
  ],
};
