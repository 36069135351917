<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="_filters$.next($event[1]); onFilterApplied()"
>
  <div style="display: flex; align-items: center; justify-content: flex-end; height: 32px">
    <mat-menu #tileMenu>
      <ng-content></ng-content>

      <button mat-menu-item color="primary" *ngIf="canCreateTile$ | async" (click)="createTile()">
        <mat-icon>add</mat-icon>
        <span>New Quick Access Tile</span>
      </button>

      <button mat-menu-item color="primary" *ngIf="canUpdateTileGroup$ | async" (click)="manageTileGroups()">
        <mat-icon>task_alt</mat-icon>
        <span>Manage Quick Access Groups</span>
      </button>
    </mat-menu>

    <button
      *ngIf="(canCreateTile$ | async) || (canUpdateTileGroup$ | async)"
      mat-stroked-button
      [matMenuTriggerFor]="tileMenu"
      color="primary"
      style="height: 32px; padding-left: 12px; padding-right: 8px"
    >
      Quick Actions
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
</greco-filter-bar>

<ng-container *grecoLet="canDeleteTile$ | async as canDeleteTile">
  <greco-table
    [data]="(tiles$ | async) || []"
    [highlight]="true"
    [loading]="loading"
    (rowClick)="openTileDetails($event)"
  >
    <ng-container *grecoTableCol="''; let tile; fitContent: true">
      <div class="img-container">
        <img [src]="tile.imageUrl" />
      </div>
    </ng-container>

    <p *grecoTableCol="'Label'; let tile">{{ tile.label }}</p>

    <p *grecoTableCol="'Group'; let tile" [matTooltip]="tile.tileGroup?.enabled ? 'Active' : 'Inactive'">
      {{ tile.tileGroup ? tile.tileGroup?.name : ' ' }}
    </p>
    <p *grecoTableCol="'Priority'; let tile; fitContent: true">{{ tile.priority }}</p>

    <mat-chip-list *grecoTableCol="'Status'; let tile">
      <mat-chip>{{ tile.status ? 'Enabled' : 'Disabled' }}</mat-chip>
    </mat-chip-list>

    <a *grecoTableCol="'Url'; let tile" [href]="tile.url" target="_blank" (click)="$event.stopImmediatePropagation()">
      {{ tile.url }}
    </a>

    <ng-container *grecoTableCol="''; let tile; fitContent: true; stickyEnd: true">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <a mat-menu-item [routerLink]="tile.id">
          <mat-icon>open_in_new</mat-icon>
          <span>View Details</span>
        </a>

        <button
          mat-menu-item
          (click)="checkEnableDisbale(tile.id, tile.status ? false : true)"
          *ngIf="canUpdateTile$ | async"
        >
          <mat-icon>{{ tile.status ? 'cancel' : 'check_circle' }}</mat-icon>
          <span>{{ tile.status ? 'Disable' : 'Enable' }}</span>
        </button>
        <button mat-menu-item (click)="deleteTile(tile)" *ngIf="canDeleteTile">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>
    </ng-container>
  </greco-table>
</ng-container>

<mat-paginator
  *ngIf="metadata?.totalItems"
  showFirstLastButtons
  [pageSizeOptions]="[10, 20, 50]"
  [length]="metadata!.totalItems || 0"
  [pageSize]="metadata!.itemsPerPage || 10"
  (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
