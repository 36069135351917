import { Component, Input } from '@angular/core';

@Component({
  selector: 'greco-empty-placeholder',
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss'],
})
export class EmptyPlaceholderComponent {
  @Input() text = 'Looks like there is nothing here!';
}
