import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FiltersModule } from '@greco/ngx-filters';
import { LetModule } from '@greco/ngx-let-util';
import { ProductsModule } from '@greco/ngx-sales-products';
import { ShopModule } from '@greco/ngx-shop';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { TableModule } from '@greco/ui-table';
import { NgPipesModule } from 'ngx-pipes';
import { SwiperModule } from 'swiper/angular';
import { PromotionsCarouselComponent } from './components/promotions-carousel/promotions-carousel.component';
import { AddProductDialog } from './dialogs/add-product/add-product.dialog';
import { CreatePromotionDialog } from './dialogs/create-promotion/create-promotion.dialog';
import { PromotionsDialog } from './dialogs/promotions/promotions.dialog';
import { ActivePromotionsPage } from './pages/active-promotions/active-promotions.page';
import { PromotionPage } from './pages/promotion/promotion.page';
import { PromotionsPage } from './pages/promotions/promotions.page';
import { PromotionResolver } from './resolvers';
import { PromotionsService } from './services/promotions.service';

export * from './dialogs';
export * from './pages';
export * from './resolvers';
export * from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSelectModule,
    MatChipsModule,

    // LF3
    SimpleDialogModule,
    TableModule,
    FiltersModule,
    CollapsibleSectionModule,
    ShopModule,
    ProductsModule,
    FormSaveButtonsModule,
    LetModule,

    // Misc
    SwiperModule,
    NgPipesModule,
  ],
  providers: [PromotionsService, PromotionResolver],
  declarations: [
    PromotionsCarouselComponent,
    PromotionsDialog,
    PromotionsPage,
    PromotionPage,
    CreatePromotionDialog,
    AddProductDialog,
    ActivePromotionsPage,
  ],
  exports: [
    PromotionsCarouselComponent,
    PromotionsDialog,
    PromotionsPage,
    PromotionPage,
    CreatePromotionDialog,
    ActivePromotionsPage,
  ],
})
export class PromotionsModule {}
