<form [formGroup]="formGroup">
  <!-- User Selection -->
  <mat-form-field>
    <mat-label>User</mat-label>
    <greco-contact-picker
      formControlName="contact"
      [withUser]="true"
      [communityId]="data.communityId"
    ></greco-contact-picker>
  </mat-form-field>

  <!-- Name -->
  <mat-form-field>
    <mat-label>Display Name</mat-label>
    <input matInput required formControlName="name" />
  </mat-form-field>

  <!-- Tags -->
  <mat-form-field>
    <mat-label>Tags</mat-label>
    <greco-resource-tag-picker
      [communityId]="data.communityId"
      formControlName="resourceTags"
    ></greco-resource-tag-picker>
  </mat-form-field>

  <!-- Description -->
  <mat-form-field>
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description"></textarea>
  </mat-form-field>
</form>
