<button
  style="width: 100%"
  mat-flat-button
  color="primary"
  matTooltip="Purchase for another user"
  (click)="$event.stopImmediatePropagation()"
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  [disabled]="conditionCanBuy"
>
  Buy For
  <mat-icon [style]="menuTrigger.menuOpen ? 'transform: rotate(180deg)' : ''">expand_circle_down</mat-icon>
</button>
<mat-menu #menu>
  <ng-container *ngFor="let link of linkedAccounts">
    <button
      *ngIf="link.status === 'ACTIVE'"
      mat-menu-item
      [disabled]="link.account?.id === userId"
      (click)="selected(link.account!)"
    >
      <p *ngIf="link.account?.id !== userId; else yourself">
        {{ link.account?.displayName }}
      </p>
      <ng-template #yourself>
        <p>Yourself</p>
      </ng-template>
    </button>
  </ng-container>
</mat-menu>
