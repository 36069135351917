<div class="on-demand-videos">
  <div class="on-demand-videos-header">
    <div>
      <h2>Your On Demand Workouts</h2>
      <p style="margin-top: -2px">Unlocked workouts are added here</p>
    </div>

    <a mat-button color="accent" class="view-all-link" routerLink="/workouts/go">View All</a>
  </div>

  <ng-container *ngIf="dashboardVideosSvc.videoUnlocks$ | async as videoUnlocks; else loadingVideoUnlocks">
    <div class="video-cards" *ngIf="videoUnlocks.length; else noVideoUnlocks">
      <div class="video-card" *ngFor="let videoUnlock of videoUnlocks" (click)="openVideo(videoUnlock)">
        <div class="card-image">
          <img
            class="main-image"
            [src]="videoUnlock.video?.thumbnailUrl ? videoUnlock.video?.thumbnailUrl : 'assets/greco/placeholder.png'"
          />
        </div>

        <div class="card-content">
          <p class="time">
            {{ videoUnlock.video?.duration | grecoDuration }}
            <ng-container *ngIf="$any(videoUnlock).eventVideo?.event.startDate">
              - {{ $any(videoUnlock).eventVideo?.event.startDate | date : 'E MMM d' }}
            </ng-container>
          </p>

          <h2>
            <strong> {{ videoUnlock.video?.title }} </strong>
          </h2>

          <p>
            <ng-container
              *ngFor="
                let resourceAssignment of $any(videoUnlock).eventVideo?.event.resourceAssignments;
                let last = last
              "
            >
              {{ resourceAssignment.resource?.name }} {{ last ? '' : ',' }}
            </ng-container>

            <span *ngIf="videoUnlock.expiresOn">
              {{ $any(videoUnlock).eventVideo?.event.resourceAssignments.length ? '- ' : '' }} Expires
              {{ moment(videoUnlock.expiresOn).fromNow() }}
            </span>
          </p>

          <div class="action-buttons">
            <button mat-stroked-button>Watch Workout</button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noVideoUnlocks>
      <div class="no-video">
        <div>
          <h2>
            <strong> No recently unlocked workouts </strong>
          </h2>

          <p>Browse the latest on-demand content!</p>
        </div>

        <div class="action-buttons">
          <button mat-raised-button color="accent" routerLink="/workouts/go">Browse Workouts</button>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loadingVideoUnlocks>
    <div class="loading-card"></div>
  </ng-template>
</div>
