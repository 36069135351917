import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookingOption } from '@greco/booking-events';
import { BadgeShape } from '@greco/sales-perks';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { PerkService } from '../../services/perk.service';

@Component({
  selector: 'greco-perks-preview',
  templateUrl: './perks-preview.component.html',
  styleUrls: ['./perks-preview.component.scss'],
})
export class PerksPreviewComponent {
  @Output() perksEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() isSelected?: BookingOption;

  constructor(private readonly perkSvc: PerkService) {}

  private _userId$ = new BehaviorSubject<string>('');
  @Input() get userId() {
    return this._userId$.value;
  }

  set userId(id: string) {
    this._userId$.next(id);
  }

  private _perkTypes$ = new BehaviorSubject<string[]>([]);
  @Input() get perkTypes() {
    return this._perkTypes$.value;
  }
  set perkTypes(perkTypes: string[]) {
    this._perkTypes$.next(perkTypes);
  }

  private _perkIds$ = new BehaviorSubject<string[]>([]);
  @Input() get perkIds() {
    return this._perkIds$.value;
  }
  set perkIds(perkIds: string[]) {
    this._perkIds$.next(perkIds);
  }

  private _filters$ = combineLatest([this._userId$, this._perkTypes$, this._perkIds$]).pipe(shareReplay(1));

  availablePerks$ = this._filters$.pipe(
    switchMap(async ([userid, perkTypes, perkIds]) => await this.perkSvc.availablePerks(userid, perkTypes, perkIds)),
    shareReplay(1)
  );
  perksBySubscription$ = this._filters$.pipe(
    switchMap(async ([userid, perkTypes, perkIds]) => await this.perkSvc.recurringPerks(userid, perkTypes, perkIds)),
    shareReplay(1)
  );

  groupedPerks$ = combineLatest([this.availablePerks$, this.perksBySubscription$]).pipe(
    map(([availablePerks, perksBySubscription]) => {
      const result: {
        id: string;
        title?: string;
        availableNow?: number;
        reusable?: number | 0;
        bySubscription?: any[];
        subscriptions?: { period: string; frequency: number; quantity: string; renewal: string }[] | [];
        badge?: {
          badgeText?: string;
          badgeColor: string;
          badgeIcon: string;
          badgeShape?: BadgeShape;
        };
      }[] = [];
      //Available now
      for (const available of availablePerks) {
        const index = result.findIndex(r => r.id === available.id);
        const badge = {
          badgeText: available.badgeText || '',
          badgeColor: available.badgeColor,
          badgeIcon: available.badgeIcon,
          badgeShape: available.badgeShape,
        };
        if (index !== -1) {
          if (available.consumable === 1) (result[index].availableNow || (0 as number)) + Number(available.count);
          else result[index].reusable = (result[index].reusable || (0 as number)) + Number(available.count);
        } else {
          if (available.consumable === 1)
            result.push({
              id: available.id,
              title: available.title,
              availableNow: available.count,
              badge: badge,
            });
          else
            result.push({
              id: available.id,
              title: available.title,
              reusable: available.count,
              badge: badge,
            });
        }
      }
      //Recurring
      for (const subscription of perksBySubscription) {
        const index = result.findIndex(p => p.id === subscription.id);
        const badge = {
          badgeText: subscription.badgeText || '',
          badgeColor: subscription.badgeColor,
          badgeIcon: subscription.badgeIcon,
          badgeShape: subscription.badgeShape,
        };

        if (index !== -1) {
          result[index].subscriptions = (result[index].subscriptions || [])?.concat({
            period: subscription.recurrencePeriod,
            frequency: subscription.recurrenceFrequency,
            quantity: subscription.count,
            renewal: subscription.renewal,
          });
          result[index].bySubscription = (result[index].bySubscription || [])?.concat(subscription.count);
        } else
          result.push({
            id: subscription.id,
            title: subscription.title,
            bySubscription: [subscription.count],
            subscriptions: [
              {
                period: subscription.recurrencePeriod,
                frequency: subscription.recurrenceFrequency,
                quantity: subscription.count,
                renewal: subscription.renewal,
              },
            ],
            badge: badge,
          });
      }
      return result;
    })
  );

  emitPerk(target: any) {
    this.perksEmitter.emit(target as BookingOption);
  }
}
