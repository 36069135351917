import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  Booking,
  BookingStatus,
  CalendarEvent,
  EventSeries,
  RoomResource,
  RoomResourceSpot,
} from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BookingService } from '../../services';

@Component({
  selector: 'greco-room-spot-shower',
  templateUrl: './room-spot-shower.component.html',
  styleUrls: ['./room-spot-shower.component.scss'],
})
export class RoomSpotShowerComponent implements AfterViewInit {
  constructor(private bookingSvc: BookingService, private breakpoints: BreakpointObserver) {}

  @ViewChild('primary') primary: ElementRef | null = null;
  primaryColor: string | null = null;

  @ViewChild('canvas', { static: true }) private canvas?: ElementRef<HTMLCanvasElement>;
  canvasContext: CanvasRenderingContext2D | null = null;
  image: HTMLImageElement | null = null;
  refreshCanvas$ = new BehaviorSubject<null>(null);

  spotImages: { [key: string]: string } = {};
  bookings: { [key: string]: Booking } = {};

  @Input() room: RoomResource | null = null;
  @PropertyListener('room') room$ = new BehaviorSubject(this.room);

  @Input() event!: CalendarEvent | EventSeries;
  @PropertyListener('event') private event$ = new BehaviorSubject(this.event);

  @Output() selectedSpot = new EventEmitter<RoomResourceSpot | null>();

  @Input() drawEmptySpots = true;

  _required = true;
  _readonly = false;

  bookedSpotIds$ = this.event$.pipe(
    switchMap(async event => {
      if (!event) return null;
      else {
        return await this.bookingSvc.paginate(
          new RequestQueryBuilder().search({
            'event.id': event.id,
            status: { $in: [BookingStatus.CONFIRMED, BookingStatus.CHECKED_IN, BookingStatus.PENDING] },
          }),
          event.community.id,
          { limit: 100 }
        );
      }
    }),
    map(result => result?.items),
    map(bookings => {
      if (!bookings) return null;

      const bookedSpots: string[] = [];
      bookings.forEach(booking => {
        if (booking.spotId) {
          bookedSpots.push(booking.spotId);
          this.bookings[booking.spotId] = booking;
          this.spotImages[booking.spotId] = booking.user?.photoURL || 'assets/lf3/icon_square_pad.png';
        }
      });

      return bookedSpots;
    })
  );

  drawSpots$ = combineLatest([this.bookedSpotIds$, this.room$, this.refreshCanvas$]).pipe(
    map(([bookedSpotIds, room, _refresh]) => {
      if (this.canvas && room?.imageURL) {
        this.canvasContext = this.canvas.nativeElement.getContext('2d');
        if (this.canvasContext) {
          this.image = new Image();
          this.image.src = room.imageURL;
          this.drawImageScaled(this.image, this.canvasContext);
        }
      } else return;

      this.room?.spots?.forEach(spot => {
        if (this.canvasContext && spot.x && spot.y && spot.width && spot.height) {
          if (bookedSpotIds?.includes(spot.id)) {
            const midx = (spot.x * 2 + spot.width) / 2;
            const midy = (spot.y * 2 + spot.height) / 2;
            this.drawCircularImage(this.spotImages[spot.id], midx, midy);
          } else {
            if (this.drawEmptySpots) {
              this.canvasContext.fillStyle = 'rgba(103, 146, 161, .5)';
              this.canvasContext?.fillRect(spot.x, spot.y, spot.width, spot.height);
            }
          }
        }
      });
    })
  );

  drawImageScaled(image: HTMLImageElement, canvasContext: CanvasRenderingContext2D) {
    const canvas = canvasContext.canvas;
    canvas.width = image.width;
    canvas.height = image.height;

    const wRatio = canvas.width / image.width;
    const scaledHeight = image.height * wRatio;

    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    canvasContext.drawImage(image, 0, 0, canvas.width, scaledHeight);
  }

  drawCircularImage(imageURL: string, x: number, y: number) {
    const context = this.canvasContext;

    if (context) {
      const r1 = 38;
      const r2 = 34;
      const r3 = 30;

      context.save();
      context.beginPath();
      context.arc(x, y, 20, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      context.fillStyle = this?.primaryColor || 'rgba(104, 90, 143, 1)';
      this.canvasContext?.fillRect(x - r1 / 2, y - r1 / 2, r1, r1);

      context.beginPath();
      context.arc(x, y, r2 / 2, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      context.fillStyle = 'rgba(255, 255, 255, 1)';
      this.canvasContext?.fillRect(x - r2 / 2, y - r2 / 2, r2, r2);

      context.beginPath();
      context.arc(x, y, r3 / 2, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      const image = new Image();
      image.src = imageURL;

      const imageWidthHeightRatio = image.width / image.height;
      let newWidth = r3;
      let newHeight = newWidth / imageWidthHeightRatio;

      if (newHeight < r3) {
        newHeight = r3;
        newWidth = newHeight * imageWidthHeightRatio;
      }

      let xoffset = 0;
      if (newWidth !== r3) xoffset = (newWidth - r3) / 2;

      context.drawImage(image, x - xoffset - r3 / 2, y - r3 / 2, newWidth, newHeight);

      context.restore();
    }
  }

  ngAfterViewInit() {
    this.primaryColor = getComputedStyle(this.primary?.nativeElement).color;
    this.refreshCanvas$.next(null);
  }
}
