import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, shareReplay } from 'rxjs/operators';
import { GrecoPrice, IGrecoPrice } from '../models';

@Injectable()
export class GrecoPriceService {
  constructor(private ngFire: AngularFirestore) {}

  private readonly _prices$ = this.ngFire
    .collection<IGrecoPrice>('prices')
    .valueChanges({ idField: 'id' })
    .pipe(shareReplay(1));

  readonly prices$ = this._prices$.pipe(
    map(prices => prices.map(price => new GrecoPrice(price))),
    shareReplay(1)
  );
}
