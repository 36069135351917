<mat-form-field
  [appearance]="model.getAdditional('appearance', 'legacy')"
  [color]="model.getAdditional('color', 'primary')"
  [ngClass]="getClass('grid', 'control')"
  [floatLabel]="model.getAdditional('floatLabel', 'auto')"
  [formGroup]="group"
>
  <mat-label [innerHTML]="model.label"></mat-label>
  <mat-select
    [formControlName]="model.id"
    *grecoLet="categories$ | async as categories"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"
    [placeholder]="model.placeholder"
    [required]="model.required"
    [value]="model.value"
    [compareWith]="compareWith"
  >
    <mat-option *ngFor="let category of categories" [value]="category"> {{ category.label }}</mat-option>
  </mat-select>
  <mat-hint *ngIf="model.hint !== null" align="start" [innerHTML]="model.hint"></mat-hint>

  <mat-error *ngFor="let message of errorMessages">{{ message }}</mat-error>
</mat-form-field>
