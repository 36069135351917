<div class="container" [class]="color + '-partial'" [style.max-height]="expanded ? '' : '40px'">
  <div
    *grecoLet="isMobile$ | async as isMobile"
    class="header"
    [style.cursor]="config.description ? 'pointer' : ''"
    (click)="toggleExpanded()"
  >
    <div class="title">
      <mat-icon [class]="color">
        {{ config.icon }}
      </mat-icon>

      <span class="title-text" [class]="color">{{ config.title }}</span>
    </div>

    <div class="actions">
      <div (click)="$event.stopImmediatePropagation()">
        <ng-content select="[slot='buttons']"></ng-content>
      </div>

      <mat-icon
        *ngIf="config.description"
        class="expand-arrow"
        [class]="color"
        [style.transform]="expanded ? 'rotate(-90deg)' : 'rotate(90deg)'"
      >
        chevron_right
      </mat-icon>
    </div>
  </div>

  <div *ngIf="config.description" class="content">
    <p [class]="color" [innerHTML]="config.description"></p>
  </div>
</div>
