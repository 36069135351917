<div *ngIf="value" class="image-block">
  <img style="margin-left: 0px" [src]="value.imageURL || '' | safe : 'resourceUrl'" />
  <div class="image-hover">
    <mat-icon (click)="value = null; stateChanges.next()">close</mat-icon>
  </div>
</div>
<div *ngIf="!value">
  <div class="image-block" #trigger="matMenuTrigger" [matMenuTriggerFor]="images">
    <mat-icon style="margin-top: 8px; margin-left: -40px" color="primary">add</mat-icon>
  </div>
</div>

<mat-menu #images (close)="stateChanges.next()">
  <div style="display: flex; flex-direction: row; margin-left: 5px; margin-right: 5px">
    <div class="image-block" *ngFor="let image of product?.images">
      <img [src]="image.imageURL" (click)="value = image" />
    </div>
    <div *ngIf="!product?.images || !product?.images?.length">
      <mat-option [disabled]="true"><i>This product has no images!</i></mat-option>
    </div>
  </div>
</mat-menu>
