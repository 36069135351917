<div class="create-payment-method-container">
  <div>
    <ng-template grecoFormContainer></ng-template>
  </div>

  <div style="display: flex; align-items: center">
    <button mat-stroked-button style="flex: 1; margin-right: 16px" [disabled]="saving" (click)="close()">
      <span>Cancel</span>
    </button>

    <button mat-flat-button color="primary" style="flex: 1" [disabled]="!isValid || saving" (click)="save()">
      <span>Confirm</span>
      <mat-icon *ngIf="saving" class="processing">loop</mat-icon>
    </button>
  </div>
</div>
