import {
  Product,
  ProductConditionOperator,
  PRODUCT_CONDITION_TYPE_PURCHASE_COUNT,
  PurchaseProductCondition,
} from '@greco/sales-products';
import { DynamicInputModel, DynamicSelectModel } from '@ng-dynamic-forms/core';
import { ProductConditionForm } from '../dialogs';
import { DynamicProductVariantPickerModel } from '../forms';

export const PRODUCT_CONDITION_CONFIG_PURCHASE_COUNT: ProductConditionForm = {
  type: PRODUCT_CONDITION_TYPE_PURCHASE_COUNT,
  label: 'Purchase Count (Product)',
  matIcon: 'receipt_long',

  getConditionDetails({ subject, operator, limit }: PurchaseProductCondition) {
    return `Available if user has purchase count of "${subject.title}" ${operator} ${limit}`;
  },

  getConditionShortDetails({ subject, operator, limit }: PurchaseProductCondition) {
    return `${subject.title} ${operator} ${limit}`;
  },

  getDtoFromFormValue({
    subject,
    operator,
    limit,
  }: {
    subject: Product;
    operator: ProductConditionOperator;
    limit: number;
  }) {
    return {
      subjectId: subject.id,
      subject,
      operator,
      limit,
    };
  },
  getFormModel(communityId: string) {
    return [
      new DynamicProductVariantPickerModel({
        id: 'subject',
        communityId: communityId,
        label: 'Purchase Product',
        required: true,
        onlyProducts: true,
        errorMessages: {
          required: 'Select a valid product',
        },
        validators: {
          required: null,
        },
        //options: [],
      }),

      new DynamicSelectModel({
        id: 'operator',
        label: 'Operator',
        required: true,
        options: [
          { label: 'equals', value: ProductConditionOperator.EQUALS },
          { label: 'greater than', value: ProductConditionOperator.GREATER_THAN },
          { label: 'greater than or equal to', value: ProductConditionOperator.GREATER_THAN_EQUALS },
          { label: 'less than', value: ProductConditionOperator.LESS_THAN },
          { label: 'less than or equal to', value: ProductConditionOperator.LESS_THAN_EQUALS },
        ],
        errorMessages: {
          required: 'Select a valid operator',
        },
        validators: {
          required: null,
        },
      }),

      new DynamicInputModel({
        id: 'limit',
        inputType: 'number',
        label: 'Limit',
        min: 0,
        required: true,
        errorMessages: {
          required: 'A limit >= 0 is required',
          min: 'A limit >= 0 is required',
        },
        validators: {
          required: null,
          min: 0,
        },
      }),
    ];
  },
};
