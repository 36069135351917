<mat-form-field
  *grecoLet="calendars$ | async as calendars"
  appearance="standard"
  class="picker"
  style="width: 200px; margin-right: -8px"
>
  <div *grecoLet="selectedCalendars$ | async as selectedCalendars" style="display: flex; flex-direction: row-reverse">
    <mat-select
      *grecoLet="selectedCalendarIds$ | async as selectedCalendarIds"
      #calendarSelect
      multiple
      style="margin-bottom: -18px; margin-top: 15px"
      [value]="selectedCalendarIds"
      [placeholder]="calendars?.length ? placeholder : 'No Calendars'"
      (valueChange)="onChanged($event)"
    >
      <mat-select-trigger>
        <span *ngFor="let calendar of selectedCalendars; let last = last">
          {{ calendar.title + (last ? '' : ', ') }}
        </span>
      </mat-select-trigger>
      <div style="margin: 8px 16px; color: rgba(14, 15, 15, 0.87)">
        <mat-checkbox
          [(ngModel)]="allSelected"
          [ngModelOptions]="{ standalone: true }"
          (change)="toggleAllSelection(calendarSelect)"
          >{{ allSelected ? 'Deselect All' : 'Select All' }}</mat-checkbox
        >
      </div>
      <mat-divider></mat-divider>
      <mat-option *ngFor="let calendar of calendars" [value]="calendar.id">
        <mat-icon>{{ calendar.icon || 'calendar_today' }}</mat-icon>
        {{ ' ' + calendar.title }}
        <span *ngIf="showCommunity" style="font-size: 75%">({{ calendar.community?.name }})</span>
      </mat-option>
    </mat-select>
  </div>
</mat-form-field>
