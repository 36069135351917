<ng-container *ngIf="user && event">
  <h1>Invite and Add a Guest</h1>
  <form [formGroup]="form">
    <mat-form-field appearance="standard" floatLabel="always">
      <mat-label>Display Name</mat-label>
      <input matInput type="text" formControlName="displayName" [required]="true" />
    </mat-form-field>
    <mat-form-field appearance="standard" floatLabel="always">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" [required]="true" />
    </mat-form-field>
  </form>
  <div class="buttons">
    <button
      mat-flat-button
      color="primary"
      style="margin-left: 16px"
      [disabled]="!isValid || _confirming"
      (click)="confirm()"
    >
      Invite
      <mat-icon *ngIf="_confirming" style="margin-left: 4px" class="spin">loop</mat-icon>
    </button>
    <button mat-flat-button (click)="cancel()" [disabled]="_confirming">Cancel</button>
  </div>
</ng-container>
