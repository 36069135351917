import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoUnlock } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { WatchVideoComponent } from '../../dialogs';
import { VideoUnlockService } from '../../services';

@Component({
  selector: 'greco-user-video-table',
  templateUrl: './user-video-table.component.html',
  styleUrls: ['./user-video-table.component.scss'],
})
export class UserVideoTableComponent implements OnDestroy {
  constructor(
    private vidUnlockSvc: VideoUnlockService,
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @PropertyListener('query') private _query$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  @Input() query?: RequestQueryBuilder;

  @Input() user?: User;

  loading = false;
  pagination?: IPaginationMeta;
  now: number = Date.now();
  page$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  videoUnlocks$: Observable<VideoUnlock[]> = combineLatest([this._query$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([query, page]) =>
      query && this.user ? await this.vidUnlockSvc.getUserUnlockedVideos(query, this.user.id, page) : null
    ),
    tap(data => (this.pagination = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );
  private _onDestroy$ = new Subject<void>();

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._query$.complete();
  }

  refresh() {
    this.page$.next(this.page$.value);
  }

  async watch(videoUnlock: VideoUnlock) {
    if (videoUnlock.video) {
      const dialog = this.matDialog.open(WatchVideoComponent, { data: {}, width: '750px', maxWidth: '90%' });
      const instance = dialog.componentInstance;
      instance.video = videoUnlock.video;
      instance.titleOverride = (videoUnlock.video as any).eventVideo
        ? (videoUnlock as any).eventVideo.event.title
        : (videoUnlock as any).collectionVideo
        ? (videoUnlock as any).collectionVideo.titleOverride
        : '';
    }
  }

  async schedule(videoUnlock: VideoUnlock) {
    console.log('schedule', videoUnlock.video);
  }

  async rowClick(videoUnlock: VideoUnlock) {
    if ((videoUnlock as any).eventVideoId)
      await this.router.navigate(['/workouts', 'go', (videoUnlock as any).eventVideoId]);
    else if ((videoUnlock as any).collectionVideoId)
      await this.router.navigate([
        '/workouts/collections',
        `${(videoUnlock as any).collectionVideo.collectionId}`,
        (videoUnlock as any).collectionVideoId,
      ]);
  }
}
