import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { User } from '@greco/identity-users';
import { SaleCategory } from '@greco/sales-purchases';
import { Observable } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { SaleCategoryService } from '../../services';

@Component({
  selector: 'greco-sale-category-page',
  templateUrl: './sale-category.page.html',
  styleUrls: ['./sale-category.page.scss'],
})
export class SaleCategoryPage implements OnInit {
  @Input() saleCategory!: SaleCategory;

  formGroup?: FormGroup;

  resetValue?: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredStaff: Observable<User[]>;
  staffCtrl = new FormControl();
  staffAssignments: User[] = [];

  @ViewChild('staffInput') staffInput: ElementRef<HTMLInputElement> | undefined;

  save = async () => {
    if (this.formGroup) {
      await this.saleCategorySvc.updateSaleCategory(this.saleCategory.id, {
        label: this.formGroup.value.label,
        generalLedgerCode: this.formGroup.value.generalLedgerCode,
        staff: this.staffAssignments.map(staff => staff.id) || [],
      });

      this.resetValue = this.formGroup.value;
      this.formGroup.markAsPristine();
      // TODO(adaoust): Update Label in Breadcrumbs
    }
  };

  constructor(private saleCategorySvc: SaleCategoryService, private formBuilder: FormBuilder) {
    this.filteredStaff = this.staffCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(300),
      switchMap((userSearch: string | null) =>
        this.saleCategorySvc.filterStaff(
          userSearch || '',
          this.staffAssignments.map(staff => staff.id),
          this.saleCategory?.accountId
        )
      )
    );
  }

  resetDetails() {
    this.resetValue = {
      label: this.saleCategory?.label || '',
      generalLedgerCode: this.saleCategory?.generalLedgerCode || '',
      staffAssignments: this.saleCategory?.staff || [],
    };

    this.staffAssignments = [...this.resetValue.staffAssignments];
    if (this.formGroup) {
      this.formGroup.reset(this.resetValue);
      this.formGroup.markAsPristine();
    }
  }

  removeStaff(user: User): void {
    const index = this.staffAssignments.indexOf(user);
    if (index >= 0) {
      this.staffAssignments.splice(index, 1);
      this.staffAssignments = [...this.staffAssignments];
      this.formGroup?.setValue({
        ...this.formGroup.value,
        staffAssignments: this.staffAssignments,
      });
    }
  }

  selectedStaff(event: MatAutocompleteSelectedEvent): void {
    this.staffAssignments = [...this.staffAssignments, event.option.value];
    this.formGroup?.setValue({
      ...this.formGroup?.value,
      staffAssignments: this.staffAssignments,
    });
    this.formGroup?.markAsDirty();
    if (this.staffInput) this.staffInput.nativeElement.value = '';
    this.staffCtrl.setValue(null);
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      label: [this.saleCategory.label || '', Validators.required],
      generalLedgerCode: [this.saleCategory.generalLedgerCode || ''],
      staffAssignments: [this.saleCategory?.staff || []],
    });
    this.staffAssignments = [...this.formGroup?.value.staffAssignments];
    this.resetValue = {
      label: [...this.saleCategory.label],
      staffAssignments: this.saleCategory?.staff ? [...this.saleCategory?.staff] : [],
    };
  }
}
