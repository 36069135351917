<div class="booking-card">
  <div class="card-content">
    <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between">
      <mat-icon class="status-icon">group_add</mat-icon>
      <h3 style="z-index: 1; font-size: 16px; margin-bottom: 8px">Add Another Booking</h3>
    </div>

    <div class="action-buttons">
      <button
        mat-flat-button
        color="primary"
        style="background-color: var(--primary-color) !important"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="linkedAccountsMenu"
        [disabled]="disabled"
      >
        Add Booking
        <mat-icon>person_add</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu class="linked-accounts-menu" #linkedAccountsMenu="matMenu" xPosition="before">
  <!-- Children -->
  <div
    *ngFor="let account of eventAccounts"
    class="account-item"
    (click)="account.userStatusInfo === 'booked' ? $event.stopImmediatePropagation() : addBooking(account)"
  >
    <h3>{{ account.user.friendlyName || account.user.displayName }}</h3>

    <div *ngIf="account.userStatusInfo !== 'booked'; else booked">
      <button mat-icon-button>
        <mat-icon>person_add</mat-icon>
      </button>
    </div>

    <ng-template #booked>
      <mat-icon style="color: var(--success-color)" matTooltip="Already booked for this event!">
        event_available
      </mat-icon>
    </ng-template>
  </div>

  <div *ngIf="event?.security?.canBookGuest" class="account-item" (click)="addGuest(event!.accounts[0].user, event!)">
    <h3>Invite Guest</h3>
    <div>
      <button mat-icon-button>
        <mat-icon>person_add</mat-icon>
      </button>
    </div>
  </div>
</mat-menu>
