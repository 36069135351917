<ng-container *ngIf="community">
  <greco-filter-bar
    [calendars]="true"
    [hideSeparator]="true"
    [filterOptions]="filterOptions"
    (changed)="query$.next($event[1]); bookingTable.onFilterApplied()"
  >
    <greco-calendar-picker
      calendarPicker
      style="height: 45px; margin-top: -36px; margin-right: 5px"
      [communityId]="community.id"
      [initialCalendarIds]="initialCalendars"
      (refresh)="refresh()"
    ></greco-calendar-picker>

    <button
      *ngIf="canExportBookings$ | async"
      mat-stroked-button
      color="primary"
      style="height: 32px"
      [disabled]="exporting"
      (click)="export()"
    >
      <mat-icon [class.spin]="exporting">{{exporting ? 'loop' : 'file_download'}}</mat-icon>
      <span>Export</span>
    </button>
  </greco-filter-bar>

  <greco-bookings-table
    #bookingTable
    [hideResource]="false"
    [filters]="query$.value"
    [communityId]="community.id || ''"
    [calendarIds]="initialCalendars || []"
    (rowClick)="openEvent($event)"
  ></greco-bookings-table>
</ng-container>
