import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'alt-events',
  templateUrl: './events.page.html',
  styleUrls: ['./events.page.scss'],
})
export class EventsPage {
  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(result => result.matches));

  constructor(public eventSvc: EventService, private breakpointObserver: BreakpointObserver) {}

  moment = moment;
  now = moment();

  trackById = (_: number, item: any) => item.id;

  prevDay() {
    const range = this.eventSvc.dateRange$.value;

    const nextDay = moment(range[0]).subtract(1, 'day');

    this.eventSvc.dateRange$.next([nextDay.startOf('day').toDate(), nextDay.endOf('day').toDate()]);
  }

  nextDay() {
    const range = this.eventSvc.dateRange$.value;

    const nextDay = moment(range[1]).add(1, 'day');

    this.eventSvc.dateRange$.next([nextDay.startOf('day').toDate(), nextDay.endOf('day').toDate()]);
  }
}
