import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PaginatedQueryParams, PaginationMetadata } from '@greco-fit/nest-utils';
import { SecurityService } from '@greco/ngx-security-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { PaymentDisputeService } from '../../services/payment-dispute.service';

@Component({
  selector: 'greco-payment-disputes-table',
  templateUrl: './payment-disputes-table.component.html',
  styleUrls: ['./payment-disputes-table.component.scss'],
})
export class PaymentDisputesTableComponent implements OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Output() rowClick = new EventEmitter<any>();

  constructor(private paymentDisputeSvc: PaymentDisputeService, private securitySvc: SecurityService) {}

  private _pageSizes$ = new BehaviorSubject<number[]>([10, 20, 50]);
  private _queryBuilder$ = new BehaviorSubject(new RequestQueryBuilder());

  loading = false;
  currentPagination: PaginationMetadata | null = null;
  paginatedParams$ = new BehaviorSubject<PaginatedQueryParams>({ page: 1, limit: 10 });

  readonly _accountId$ = new BehaviorSubject<string | null>(null);
  @Input() set accountId(accountId: string | null) {
    this._accountId$.next(accountId);
  }
  get accountId() {
    return this._accountId$.value;
  }

  @Input() set pageSizes(sizes: number[]) {
    this._pageSizes$.next(sizes?.length ? sizes : [10, 20, 50]);
    const paginatedParams = this.paginatedParams$.value;
    if (!this._pageSizes$.value.includes(paginatedParams.limit || 0)) {
      this.paginatedParams$.next({ ...paginatedParams, limit: this.pageSizes[0] });
    }
  }
  get pageSizes() {
    return this._pageSizes$.value;
  }

  @Input() set queryBuilder(queryBuilder: RequestQueryBuilder) {
    this._queryBuilder$.next(queryBuilder);
  }
  get queryBuilder() {
    return this._queryBuilder$.value;
  }

  disputes$ = combineLatest([this._queryBuilder$, this.paginatedParams$, this._accountId$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    debounceTime(500),
    switchMap(([queryBuilder, params, accountId]) =>
      this.paymentDisputeSvc.paginate(
        accountId || '',
        { limit: params.limit || 10, page: params.page || 1 },
        queryBuilder
      )
    ),
    tap(({ meta }) => setTimeout(() => (this.currentPagination = meta))),
    map(({ items }) => items),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  ngOnDestroy() {
    this._pageSizes$.complete();
    this._queryBuilder$.complete();
    this.paginatedParams$.complete();
  }

  refresh() {
    this.paginatedParams$.next(this.paginatedParams$.value);
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
