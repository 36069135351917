<greco-simple-dialog [data]="dialogData">
  <p style="color: var(--warn-color)">
    {{data.retryTotal < 1 ? 'This action cannot be performed!' : 'Are you sure you want to perform this action?'}}
  </p>
  <p>
    Based on your current filters, this will retry <strong>{{data.retryTotal}} of {{data.pageTotal}} purchases</strong>
    in view. Some purchases which are in failed status may not be retried if their failure reason already indicates that
    a retry will not succeed (e.g. Credit Card Expired).
  </p>

  <form *ngIf="data.userIds?.length; else noUsers" [formGroup]="formGroup" (ngSubmit)="submit()">
    <div style="margin: 16px 0; display: flex; flex-direction: column">
      <mat-checkbox formControlName="updatePaymentMethod" color="primary">Update payment method</mat-checkbox>
      <mat-hint style="margin-top: 4px">
        Selecting this option will update the payment method on all selected purchases and retry all failed purchases
        regardless of failure reason.
      </mat-hint>
    </div>

    <ng-container *ngIf="formGroup.get('updatePaymentMethod')?.value">
      <mat-form-field
        *ngFor="let userId of data.userIds; let i = index"
        appearance="standard"
        floatLabel="always"
        style="padding-top: 0px; margin-top: -24px"
      >
        <mat-label>{{users[i].displayName}}</mat-label>
        <greco-select-payment-method
          #selectPaymentMethodInput
          [formControlName]="userId"
          [required]="false"
          [allowTerminals]="false"
          [userId]="userId"
          [disabled]="!formGroup.get('updatePaymentMethod')?.value"
        ></greco-select-payment-method>

        <greco-add-payment-method-button
          color=""
          matSuffix
          [onlyIcon]="true"
          [userId]="userId"
          (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
        ></greco-add-payment-method-button>
      </mat-form-field>
    </ng-container>

    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        [disabled]="(formGroup.get('updatePaymentMethod')?.value && formGroup.invalid) || processing || data.retryTotal < 1"
        style="flex: 1"
      >
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
  <ng-template #noUsers>
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        [disabled]="processing || data.retryTotal < 1"
        style="flex: 1"
        (click)="submit()"
      >
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </ng-template>
</greco-simple-dialog>
