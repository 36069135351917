<ng-container *grecoLet="paymentMethods$ | async as paymentMethods">
  <ng-container *ngIf="selectedPaymentMethod$ | async as paymentMethod; else noPaymentMethod">
    <button mat-stroked-button (click)="bottomSheet.open(cardPicker, { panelClass: 'bottom-sheet' })">
      <div class="card-info">
        <img [src]="'assets/payment-methods/' + paymentMethod.model + '.png'" />
        <div>
          <p>
            <ng-container *ngIf="paymentMethod.model === 'bank'; else cardText">
              <strong>{{ paymentMethod.label }}</strong>
            </ng-container>
            <ng-template #cardText>
              <strong>{{ paymentMethod.model | titlecase }} ending in {{ paymentMethod.label | slice : -4 }}</strong>
            </ng-template>
          </p>
          <p>
            {{ paymentMethod.details ? 'Expiry ' + paymentMethod.details : '' }}
            {{ paymentMethod.model === 'bank' ? 'Pre-Authorized Debit' : '' }}
            {{
              paymentMethod.cardHolderName
                ? (paymentMethod.details || paymentMethod.model === 'bank' ? ' • ' : '') + paymentMethod.cardHolderName
                : ''
            }}
          </p>
        </div>
      </div>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </ng-container>
  <ng-template #noPaymentMethod>
    <button
      class="empty"
      mat-stroked-button
      (click)="addNew = false; bottomSheet.open(cardPicker, { panelClass: 'bottom-sheet' })"
    >
      <mat-icon>add_card</mat-icon>
      <div class="card-info">
        <div>
          <p><strong>Select a payment method</strong></p>
        </div>
      </div>
    </button>
  </ng-template>
  <ng-template #cardPicker>
    <ng-container *ngIf="paymentMethods?.length && !addNew; else addNewPaymentMethod">
      <div class="sheet-header">
        <h2>Select Payment Method</h2>
        <button mat-icon-button (click)="bottomSheet.dismiss()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-radio-group
        class="select-payment-method-group"
        (change)="selectedPaymentMethod$.next($event.value); bottomSheet.dismiss()"
      >
        <mat-radio-button
          *ngFor="let paymentMethod of paymentMethods"
          [value]="paymentMethod"
          [checked]="paymentMethod.id === (selectedPaymentMethod$ | async)?.id"
        >
          <div class="card-info">
            <img [src]="'assets/payment-methods/' + paymentMethod.model + '.png'" />
            <div>
              <p>
                <ng-container *ngIf="paymentMethod.model === 'bank'; else cardText">
                  <strong>{{ paymentMethod.label }}</strong>
                </ng-container>
                <ng-template #cardText>
                  <strong
                    >{{ paymentMethod.model | titlecase }} ending in {{ paymentMethod.label | slice : -4 }}</strong
                  >
                </ng-template>
              </p>
              <p>
                {{ paymentMethod.details ? 'Expiry ' + paymentMethod.details : '' }}
                {{ paymentMethod.model === 'bank' ? 'Pre-Authorized Debit' : '' }}
                {{
                  paymentMethod.cardHolderName
                    ? (paymentMethod.details || paymentMethod.model === 'bank' ? ' • ' : '') +
                      paymentMethod.cardHolderName
                    : ''
                }}
              </p>
            </div>
          </div>
        </mat-radio-button>
      </mat-radio-group>
      <button mat-stroked-button (click)="addNew = true">
        <mat-icon>add_card</mat-icon>
        Add New Payment Method
      </button>
    </ng-container>
    <ng-template #addNewPaymentMethod>
      <ng-container *ngIf="paymentMethods === null; else doneLoading">
        <div class="sheet-header">
          <h2>Select Payment Method</h2>
          <button mat-icon-button (click)="bottomSheet.dismiss()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-container>
      <ng-template #doneLoading>
        <div class="sheet-header">
          <h2>Add New Payment Method</h2>
          <button mat-icon-button (click)="bottomSheet.dismiss()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <mat-button-toggle-group
          *ngIf="!cardOnly"
          class="payment-method-types"
          [value]="selectedType"
          (change)="selectedType = $event.value"
        >
          <mat-button-toggle value="card">Card</mat-button-toggle>
          <mat-button-toggle value="bank">Bank</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="payment-method-forms">
          <ng-container *ngIf="selectedType === 'card'; else bank">
            <greco-stripe-credit-card-payment-method-form
              #cardForm
              [userId]="userId"
            ></greco-stripe-credit-card-payment-method-form>
            <div class="action-buttons">
              <button
                mat-stroked-button
                (click)="paymentMethods?.length === 0 ? bottomSheet.dismiss() : (addNew = false)"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                color="primary"
                (click)="savePaymentMethod(cardForm)"
                [disabled]="cardForm.formGroup.invalid || saving"
              >
                Confirm
                <mat-spinner *ngIf="saving" class="spinner" diameter="20"></mat-spinner>
              </button>
            </div>
          </ng-container>
          <ng-template #bank>
            <greco-fit-bank-account-payment-form #bankForm [userId]="userId"></greco-fit-bank-account-payment-form>
            <div class="action-buttons">
              <button
                mat-stroked-button
                (click)="paymentMethods?.length === 0 ? bottomSheet.dismiss() : (addNew = false)"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                color="primary"
                (click)="savePaymentMethod(bankForm)"
                [disabled]="bankForm.formGroup.invalid || saving"
              >
                Confirm
                <mat-spinner *ngIf="saving" class="spinner" diameter="20"></mat-spinner>
              </button>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-container>
