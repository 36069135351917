<greco-collapsible-section
  class="perks-section"
  [header]="{ title: 'Response Form', icon: 'info' }"
  [disabled]="disabled"
  [expanded]="expanded"
  *ngIf="_paymentDispute$ | async as paymentDispute"
>
  <ng-container header>
    <!-- Status -->
    <mat-chip>
      {{ paymentDispute.status.replace('_', ' ') | titlecase }}
    </mat-chip>

    <!-- Close -->
    <ng-container *grecoLet="canSubmitPaymentDispute$ | async as canSubmitPaymentDispute">
      <div matTooltip="You don't have Permission to close" [matTooltipDisabled]="canSubmitPaymentDispute">
        <button
          mat-stroked-button
          color="warn"
          [disabled]="canSubmitPaymentDispute ? isNotOpen || isSaving : true"
          (click)="closeDispute(paymentDispute); $event.stopImmediatePropagation()"
        >
          Close
        </button>
      </div>
    </ng-container>

    <!-- Save -->
    <ng-container *grecoLet="canUpdatePaymentDispute$ | async as canUpdatePaymentDispute">
      <div matTooltip="You don't have Permission to Save" [matTooltipDisabled]="canUpdatePaymentDispute">
        <button
          mat-stroked-button
          type="button"
          color="primary"
          [disabled]="canUpdatePaymentDispute ? !isChanging || isSaving || isNotOpen : true"
          (click)="
            onSave(paymentDispute.id);
            isSaving = true;
            loading = true;
            paymentDisputeForm.disable();
            isNotOpen = true;
            $event.stopImmediatePropagation()
          "
        >
          Save
        </button>
      </div>
    </ng-container>

    <!-- Submit -->
    <ng-container *grecoLet="canSubmitPaymentDispute$ | async as canSubmitPaymentDispute">
      <div matTooltip="You don't have Permission to Submit" [matTooltipDisabled]="canSubmitPaymentDispute">
        <button
          mat-flat-button
          type="button"
          [disabled]="canSubmitPaymentDispute ? isChanging || isNotOpen : true"
          color="primary"
          (click)="onSubmit(paymentDispute); $event.stopImmediatePropagation()"
          header
        >
          Submit
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>

  <form [formGroup]="paymentDisputeForm">
    <div class="two-col-row">
      <!-- Customer Name -->
      <mat-form-field floatLabel="always" appearance="standard">
        <mat-label>Customer Name</mat-label>
        <input type="text" matInput formControlName="customerName" />
      </mat-form-field>

      <!-- Customer E-mail -->
      <mat-form-field floatLabel="always" appearance="standard">
        <mat-label>Customer E-mail</mat-label>
        <input type="text" matInput formControlName="customerEmail" />
      </mat-form-field>
    </div>

    <!-- Billing Address -->
    <mat-form-field floatLabel="always" appearance="standard">
      <mat-label>Billing Address</mat-label>
      <ng-template #PaymentDisputeIsNotOpen>
        <input type="text" matInput [value]="paymentDispute.billingAddress?.formatted" disabled />
      </ng-template>
      <greco-address-input
        *ngIf="!isNotOpen; else PaymentDisputeIsNotOpen"
        formControlName="billingAddress"
        (change)="isChanging = true"
      ></greco-address-input>
    </mat-form-field>

    <!-- Product Description -->
    <mat-form-field floatLabel="always" appearance="standard">
      <mat-label>Product Description</mat-label>
      <textarea
        matInput
        #productDescription
        rows="1"
        [maxLength]="20000"
        formControlName="productDescription"
        placeholder="Product description"
        (keydown)="isChanging = true"
      ></textarea>
      <mat-hint align="end">{{ productDescription.value.length || 0 }}/20,000</mat-hint>
    </mat-form-field>

    <!-- Cancellation Rebuttal -->
    <mat-form-field floatLabel="always" appearance="standard">
      <mat-label>Cancellation Rebuttal</mat-label>

      <textarea
        matInput
        #cancellationRebuttal
        rows="1"
        [maxLength]="20000"
        placeholder="Cancellation rebuttal"
        formControlName="cancellationRebuttal"
        (keydown)="isChanging = true"
      ></textarea>

      <mat-icon matSuffix style="cursor: default" matTooltip="Reason why the cancellation was not approved">
        info
      </mat-icon>

      <mat-hint align="end">{{ cancellationRebuttal.value.length || 0 }}/20,000</mat-hint>
    </mat-form-field>

    <!-- Refund Refusal Explanation -->
    <mat-form-field floatLabel="always" appearance="standard">
      <mat-label>Refund Refusal Explanation</mat-label>

      <textarea
        matInput
        rows="1"
        [maxLength]="20000"
        #refundRefusalExplanation
        placeholder="Refund refusal explanation"
        formControlName="refundRefusalExplanation"
        (keydown)="isChanging = true"
      ></textarea>

      <mat-icon
        matSuffix
        style="cursor: default"
        matTooltip="Justification for why the customer is not entitled to a refund"
      >
        info
      </mat-icon>

      <mat-hint align="end">{{ refundRefusalExplanation.value.length || 0 }}/20,000</mat-hint>
    </mat-form-field>

    <!-- Service Date -->
    <mat-form-field floatLabel="always" appearance="standard">
      <mat-label>Service Date</mat-label>
      <input
        matInput
        (dateInput)="isChanging = true"
        [matDatepicker]="picker"
        placeholder="Service date"
        formControlName="serviceDate"
      />

      <mat-datepicker #picker></mat-datepicker>

      <div matSuffix style="display: flex; align-items: center">
        <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
        <mat-icon
          style="cursor: default; margin-bottom: 3px"
          matTooltip="Date on which the customer received or began receiving the purchased service"
        >
          info
        </mat-icon>
      </div>
    </mat-form-field>

    <!-- Service Documentation -->
    <mat-form-field floatLabel="always" appearance="standard" hintLabel="PDF - Max Size : 4mb">
      <mat-label>Service Documentation</mat-label>
      <greco-image-upload
        [required]="false"
        formControlName="serviceDocumentation"
        [multiple]="false"
        [disabled]="isNotOpen"
        [acceptFile]="true"
        [urls]="serviceDocumentation"
        (isTouched)="$event ? (isChanging = true) : (isChanging = false)"
      ></greco-image-upload>
    </mat-form-field>

    <!-- Customer Communications -->
    <mat-form-field floatLabel="always" appearance="standard" hintLabel="PDF - Max Size : 4mb">
      <mat-label>Customer Communication</mat-label>
      <greco-image-upload
        [required]="false"
        formControlName="customerCommunication"
        [multiple]="false"
        [disabled]="isNotOpen"
        [acceptFile]="true"
        [urls]="customerCommunication"
        (isTouched)="$event ? (isChanging = true) : (isChanging = false)"
      ></greco-image-upload>
    </mat-form-field>

    <!-- Customer Signature -->
    <mat-form-field floatLabel="always" appearance="standard" hintLabel="Image - Max Size : 4mb">
      <mat-label>Customer Signature</mat-label>
      <greco-image-upload
        [required]="false"
        formControlName="customerSignature"
        [multiple]="false"
        [disabled]="isNotOpen"
        [urls]="customerSignature"
        (isTouched)="$event ? (isChanging = true) : (isChanging = false)"
      ></greco-image-upload>
    </mat-form-field>

    <!-- Receipt -->
    <mat-form-field floatLabel="always" appearance="standard" hintLabel="PDF - Max Size : 4mb">
      <mat-label>Receipt</mat-label>
      <greco-image-upload
        [required]="false"
        formControlName="receipt"
        [multiple]="false"
        [disabled]="isNotOpen"
        [acceptFile]="true"
        [urls]="receipt"
        (isTouched)="$event ? (isChanging = true) : (isChanging = false)"
      ></greco-image-upload>
    </mat-form-field>

    <div class="two-col-row">
      <!-- Cancellation Policy -->
      <mat-form-field floatLabel="always" appearance="standard" hintLabel="PDF - Max Size : 4mb">
        <mat-label>Cancellation Policy</mat-label>
        <greco-image-upload
          [required]="false"
          formControlName="cancellationPolicy"
          [multiple]="false"
          [disabled]="isNotOpen"
          [acceptFile]="true"
          [urls]="cancellationPolicy"
          (isTouched)="$event ? (isChanging = true) : (isChanging = false)"
        ></greco-image-upload>
      </mat-form-field>

      <!-- Cancellation Policy Disclosure -->
      <mat-form-field class="textarea-feild" floatLabel="always" appearance="standard">
        <mat-label>Cancellation Policy Disclosure</mat-label>

        <textarea
          matInput
          #cancellationPolicyDisclosure
          rows="4"
          [maxLength]="20000"
          placeholder="Cancellation policy disclosure"
          formControlName="cancellationPolicyDisclosure"
          (keydown)="isChanging = true"
        ></textarea>

        <mat-icon
          matSuffix
          style="cursor: default"
          matTooltip="Explanation of how and when the customer was shown the refund policy prior to purchase"
        >
          info
        </mat-icon>

        <mat-hint align="end">{{ cancellationPolicyDisclosure.value.length || 0 }}/20,000</mat-hint>
      </mat-form-field>
    </div>

    <div class="two-col-row">
      <!-- Duplicate Charge Documentation -->
      <mat-form-field floatLabel="always" appearance="standard" hintLabel="PDF - Max Size : 4mb">
        <mat-label>Duplicate Charge Documentation</mat-label>
        <greco-image-upload
          [required]="false"
          formControlName="duplicateChargeDocumentation"
          [multiple]="false"
          [disabled]="isNotOpen"
          [acceptFile]="true"
          [urls]="duplicateChargeDocumentation"
          (isTouched)="$event ? (isChanging = true) : (isChanging = false)"
        ></greco-image-upload>
      </mat-form-field>

      <!-- Duplicate Charge Explanation -->
      <mat-form-field
        floatLabel="always"
        class="textarea-feild"
        appearance="standard"
        hintLabel="Max 20,000 characters"
      >
        <mat-label>Duplicate Charge Explanation</mat-label>

        <textarea
          matInput
          #duplicateChargeExplanation
          [maxLength]="20000"
          formControlName="duplicateChargeExplanation"
          placeholder="Duplicate charge explanation"
          rows="4"
          (keydown)="isChanging = true"
        ></textarea>

        <mat-icon
          matSuffix
          style="cursor: default"
          matTooltip="Explanation of the difference between the disputed charge and the prior charge that appears to be a duplicate"
        >
          info
        </mat-icon>

        <mat-hint align="end">{{ duplicateChargeExplanation.value.length || 0 }}/20000</mat-hint>
      </mat-form-field>
    </div>

    <div class="two-col-row">
      <!-- Refund Policy -->
      <mat-form-field floatLabel="always" appearance="standard" hintLabel="PDF - Max Size : 4mb">
        <mat-label>Refund Policy</mat-label>
        <greco-image-upload
          [required]="false"
          formControlName="refundPolicy"
          [multiple]="false"
          [disabled]="isNotOpen"
          [acceptFile]="true"
          [urls]="refundPolicy"
          (isTouched)="$event ? (isChanging = true) : (isChanging = false)"
        ></greco-image-upload>
      </mat-form-field>

      <!-- Refund Policy Disclosure -->
      <mat-form-field floatLabel="always" class="textarea-feild" appearance="standard">
        <mat-label>Refund Policy Disclosure</mat-label>

        <textarea
          matInput
          #refundPolicyDisclosure
          [maxLength]="20000"
          formControlName="refundPolicyDisclosure"
          placeholder="Refund policy disclosure"
          rows="4"
          (keydown)="isChanging = true"
        ></textarea>

        <mat-icon
          matSuffix
          style="cursor: default"
          matTooltip="Documentation demonstrating that the customer was shown the refund policy prior to purchase"
        >
          info
        </mat-icon>

        <mat-hint align="end">{{ refundPolicyDisclosure.value.length || 0 }}/20,000</mat-hint>
      </mat-form-field>
    </div>

    <div class="two-col-row">
      <!-- Uncategorized File -->
      <mat-form-field floatLabel="always" appearance="standard" hintLabel="PDF - Max Size : 4mb">
        <mat-label>Uncategorized File</mat-label>
        <greco-image-upload
          [required]="false"
          formControlName="uncategorizedFile"
          [multiple]="false"
          [disabled]="isNotOpen"
          [acceptFile]="true"
          [urls]="uncategorizedFile"
          (isTouched)="$event ? (isChanging = true) : (isChanging = false)"
        ></greco-image-upload>
      </mat-form-field>

      <!-- Uncategorized Text -->
      <mat-form-field floatLabel="always" class="textarea-feild" appearance="standard">
        <mat-label>Uncategorized Text</mat-label>

        <textarea
          matInput
          #uncategorizedText
          [maxLength]="20000"
          formControlName="uncategorizedText"
          placeholder="Uncategorized text"
          rows="4"
          (keydown)="isChanging = true"
        ></textarea>

        <mat-icon matSuffix style="cursor: default" matTooltip="Any additional evidence or statements"> info </mat-icon>

        <mat-hint align="end">{{ uncategorizedText.value.length || 0 }}/20,000</mat-hint>
      </mat-form-field>
    </div>
  </form>
</greco-collapsible-section>
