import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountTax, Tax } from '@greco/finance-tax';
import { UpdateAccountTaxDto } from '@greco/nestjs-finance-tax';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject } from 'rxjs';
import { AccountTaxService, TaxService } from '../../services';

@Component({
  selector: 'greco-update-account-tax-dialog',
  templateUrl: './update-account-tax.dialog.html',
  styleUrls: ['./update-account-tax.dialog.scss'],
})
export class UpdateAccountTaxDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { tax: AccountTax },
    private ref: MatDialogRef<UpdateAccountTaxDialog>,
    private formBuilder: FormBuilder,
    private accountTaxSvc: AccountTaxService,
    private taxSvc: TaxService,
    private snacks: MatSnackBar
  ) {
    this.tax = data?.tax || null;
  }

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '',
    suffix: '%',
    thousands: ',',
    inputMode: 0,
    min: 0,
    max: 100,
  };

  formGroup = this.formBuilder.group({
    taxNumber: [null, Validators.required],
  });
  private _tax$: BehaviorSubject<AccountTax | null> = new BehaviorSubject<AccountTax | null>(null);
  get tax() {
    return this._tax$.value;
  }
  set tax(value: AccountTax | null) {
    this._tax$.next(value);
    this.formGroup.setValue({
      taxNumber: value?.taxNumber || '',
    });
  }

  close(tax?: Tax) {
    this.ref.close(tax);
  }

  async update() {
    const value = this.formGroup.value;
    const dto: UpdateAccountTaxDto = {
      taxId: this.tax?.taxId || '',
      taxNumber: value.taxNumber,
      isDefault: this.tax?.isDefault || false,
    };
    this.ref.close(dto);
  }
}
