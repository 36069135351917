import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Calendar } from '@greco/booking-events';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface CalendarDetails {
  title?: string | null;
  group?: string | null;
  communityId?: string | null;
  id?: string | null;
  icon?: string | null;
  image?: File | null;
  private: boolean;
}

@Component({
  selector: 'greco-calendar-details-input',
  templateUrl: './calendar-details-input.component.html',
  styleUrls: ['./calendar-details-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarDetailsInputComponent),
      multi: true,
    },
  ],
})
export class CalendarDetailsInputComponent implements ControlValueAccessor, OnDestroy {
  constructor(private formBuilder: FormBuilder) {
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.onChanged?.(this.value));
  }

  private onChanged?: (value: CalendarDetails | null) => void;
  private onTouched?: () => void;

  private _onDestroy$ = new Subject<void>();

  readonly stateChanges = new Subject<void>();

  @Input() communityId!: string | null;

  _form = this.formBuilder.group({
    title: ['', [Validators.required]],
    group: [null],
    communityId: [''],
    id: [''],
    icon: [null],
    image: [null],
    private: [false],
  });

  _required = false;

  @Input() readonly = false;

  private _calendar$ = new BehaviorSubject<Calendar | null>(null);
  @Input() set calendar(calendar) {
    this._calendar$.next(calendar);
  }
  get calendar() {
    return this._calendar$.value;
  }

  @Input() initialUrls!: string[];

  @Input() get value() {
    const data: CalendarDetails = this._form.value;
    return this._form.valid
      ? ({
          title: data.title,
          group: data.group,
          communityId: data.communityId,
          id: data.id,
          icon: data.icon,
          image: data.image,
          private: data.private,
        } as CalendarDetails)
      : null;
  }

  set value(value: CalendarDetails | null) {
    this._form.patchValue({
      title: value?.title || null,
      group: value?.group || null,
      icon: value?.icon || null,
      private: value?.private || false,
      image: value?.image || null,
    });
    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    const validator = [...(this._required ? [Validators.required] : [])];
    this._form.get('title')?.setValidators(validator);
    this.stateChanges.next();
  }

  writeValue(value: CalendarDetails): void {
    this.value = value;
  }

  registerOnChange(fn: (value: CalendarDetails | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
