import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { PropertyListener } from '@greco/property-listener-util';
import { ALL_TIMEZONES, Timezone } from '@greco/timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

const TimezoneValidator: ValidatorFn = control => (ALL_TIMEZONES.includes(control.value) ? null : { timezone: true });

@Component({
  selector: 'greco-timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TimezoneSelectComponent }],
})
export class TimezoneSelectComponent
  implements AfterViewInit, OnDestroy, MatFormFieldControl<Timezone>, ControlValueAccessor
{
  constructor(
    private _elementRef: ElementRef,
    private formBuilder: FormBuilder,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (ngControl) ngControl.valueAccessor = this;
    this._formControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(timezone => {
      this.timezoneChanged.emit(timezone);
      this.stateChanges.next();
    });
  }

  private static ID = 0;

  private _touched$ = new Subject<void>();
  private _onDestroy$ = new Subject<void>();

  _formControl = this.formBuilder.control(null, [TimezoneValidator]);

  @ViewChild(MatSelect) private _select?: MatSelect;

  @PropertyListener('filter') private _filter$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() filter?: string;

  @Output() timezoneChanged = new EventEmitter<Timezone>();

  timezones$ = this._filter$.pipe(
    map(filter => {
      if (!filter) return ALL_TIMEZONES;
      const lowercaseFilter = filter.toLowerCase();
      return ALL_TIMEZONES.filter(tz => tz.toLowerCase().includes(lowercaseFilter));
    })
  );

  @Input()
  set value(value: Timezone | null) {
    this._formControl.setValue(value);
  }
  get value() {
    return this._formControl.value as Timezone | null;
  }

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-timezone-select';
  @HostBinding() readonly id = `${this.controlType}-${TimezoneSelectComponent.ID++}`;

  @Input() placeholder = '';

  get focused() {
    return this._select?.focused || false;
  }

  get empty() {
    return !this._formControl.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input() required = false;
  @Input() disabled = false;

  get errorState() {
    return this._formControl.invalid && this._formControl.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-timezone-select-container');
    if (controlElement) controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(): void {
    if (this._select) this._select.onContainerClick();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._formControl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => fn(this.value));
  }

  registerOnTouched(fn: any): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(() => fn());
  }

  @PropertyListener('required')
  @PropertyListener('disabled')
  @PropertyListener('placeholder')
  private _updateState() {
    this.stateChanges.next();

    this._formControl.setValidators([TimezoneValidator, ...(this.required ? [Validators.required] : [])]);
    this._formControl.updateValueAndValidity();

    if (this.disabled) this._formControl.disable();
    else this._formControl.enable();
  }

  ngAfterViewInit() {
    if (this._select) {
      this._select?.registerOnTouched(() => {
        this._touched$.next();
        return {};
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._touched$.complete();
    this.stateChanges.complete();
  }
}
