<ng-container *ngIf="action">
  <ng-template #purchaseOption let-click="click">
    <button *ngIf="!$any(action).purchaseId" mat-menu-item (click)="click()">
      <mat-icon>receipt_long</mat-icon>
      <span>Preview Purchase</span>
    </button>

    <ng-container *ngIf="$any(action).purchaseId">
      <a
        *ngIf="communityId"
        mat-menu-item
        [routerLink]="['/admin/sales', communityId, $any(action).purchaseId]"
        target="_blank"
      >
        <mat-icon>open_in_new</mat-icon>
        <span>View Purchase</span>
      </a>

      <a
        *ngIf="!communityId"
        mat-menu-item
        [routerLink]="['/account/purchase', $any(action).purchaseId]"
        target="_blank"
      >
        <mat-icon>open_in_new</mat-icon>
        <span>View Purchase</span>
      </a>
    </ng-container>
  </ng-template>

  <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="(hasAnyAction$ | async) !== true">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu>
    <ng-container [ngSwitch]="action.type">
      <ng-container *ngSwitchCase="'START'">
        <!-- Start Now -->
        <button mat-menu-item *ngIf="action.status === 'SCHEDULED'" (click)="startNow()">
          <mat-icon>start</mat-icon>
          <span>Start Now</span>
        </button>

        <!-- Reschedule -->
        <button mat-menu-item *ngIf="action.status === 'SCHEDULED'" (click)="rescheduleStart()">
          <mat-icon>restart_alt</mat-icon>
          <span>Reschedule</span>
        </button>

        <!-- Purchase Options -->
        <ng-container *ngTemplateOutlet="purchaseOption; context: { click: openPurchasePreview }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'RENEWAL'">
        <!-- Purchase Options -->
        <ng-container *ngTemplateOutlet="purchaseOption; context: { click: openPurchasePreview }"></ng-container>

        <!-- Schedule Cancellation -->
        <button *ngIf="action.status === 'SCHEDULED'" mat-menu-item (click)="cancelSubscription(action.timestamp)">
          <mat-icon>cancel</mat-icon>
          <span>Schedule Cancellation</span>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'UPDATE'">
        <ng-container *ngIf="action.status === 'COMPLETED' && $any(action).purchaseId">
          <ng-container *ngTemplateOutlet="purchaseOption; context: { click: openPurchasePreview }"></ng-container>
        </ng-container>

        <!-- Reactivate -->
        <button *ngIf="action.status === 'SCHEDULED'" mat-menu-item (click)="stopUpdate(action.id)">
          <mat-icon>undo</mat-icon>
          <span>Don't Update</span>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'CANCELLATION'">
        <ng-container *ngIf="action.status === 'COMPLETED' && $any(action).purchaseId">
          <ng-container *ngTemplateOutlet="purchaseOption; context: { click: openPurchasePreview }"></ng-container>
        </ng-container>

        <!-- Reactivate -->
        <button *ngIf="action.status === 'SCHEDULED'" mat-menu-item (click)="stopCancellation(action.id)">
          <mat-icon>undo</mat-icon>
          <span>Don't Cancel</span>
        </button>

        <!-- Reschedule -->
        <button mat-menu-item *ngIf="action.status === 'SCHEDULED'" (click)="cancelSubscription()">
          <mat-icon>restart_alt</mat-icon>
          <span>Reschedule</span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'COMPLETION'">
        <!-- Reactivate -->
        <button *ngIf="action.status === 'SCHEDULED'" mat-menu-item (click)="stopCancellation(action.id)">
          <mat-icon>undo</mat-icon>
          <span>Don't Cancel</span>
        </button>

        <!-- Reschedule -->
        <button mat-menu-item *ngIf="action.status === 'SCHEDULED'" (click)="cancelSubscription()">
          <mat-icon>restart_alt</mat-icon>
          <span>Reschedule</span>
        </button>
      </ng-container>
      <ng-container *ngIf="action.status === 'SCHEDULED'">
        <!-- Freeze Action -->
        <ng-container *ngSwitchCase="'FREEZE'">
          <button mat-menu-item (click)="rescheduleFreeze($any(action).freezePeriodId, 'start')">
            <mat-icon>restart_alt</mat-icon>
            <span>Reschedule</span>
          </button>
          <button mat-menu-item (click)="cancelFreeze($any(action).freezePeriodId)">
            <mat-icon>cancel</mat-icon>
            <span>Cancel Freeze</span>
          </button>
        </ng-container>
        <!-- Unfreeze Action -->
        <ng-container *ngSwitchCase="'UNFREEZE'">
          <button mat-menu-item (click)="rescheduleFreeze($any(action).freezePeriodId, 'end')">
            <mat-icon>restart_alt</mat-icon>
            <span>Reschedule</span>
          </button>
          <button mat-menu-item (click)="cancelFreeze($any(action).freezePeriodId)">
            <mat-icon>cancel</mat-icon>
            <span>Cancel Freeze</span>
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-menu>
</ng-container>
