import { DateRangeProductCondition, PRODUCT_CONDITION_TYPE_DATE_RANGE } from '@greco/sales-products';
import { DynamicFormGroupModel, DynamicInputModel, MATCH_REQUIRED, OR_OPERATOR } from '@ng-dynamic-forms/core';
import moment from 'moment';
import { ProductConditionForm } from '../dialogs';

export const PRODUCT_CONDITION_CONFIG_DATE_RANGE: ProductConditionForm = {
  type: PRODUCT_CONDITION_TYPE_DATE_RANGE,
  label: 'Date Range',
  matIcon: 'date_range',

  getConditionShortDetails({ availableFrom, availableUntil }: DateRangeProductCondition) {
    if (availableFrom && availableUntil) {
      return `Between ${moment(availableFrom).format('MMM Do')} and ${moment(availableUntil).format('MMM Do')}`;
    } else if (availableFrom) {
      return `From ${moment(availableFrom).format('MMM Do')}`;
    } else if (availableUntil) {
      return `Until ${moment(availableUntil).format('MMM Do')}`;
    } else {
      return 'Available';
    }
  },

  getConditionDetails({ availableFrom, availableUntil }: DateRangeProductCondition) {
    if (availableFrom && availableUntil) {
      return `Available between ${moment(availableFrom).format('MMM Do')} and ${moment(availableUntil).format(
        'MMM Do'
      )}`;
    } else if (availableFrom) {
      return `Available from ${moment(availableFrom).format('MMM Do')}`;
    } else if (availableUntil) {
      return `Available until ${moment(availableUntil).format('MMM Do')}`;
    } else {
      return 'Available';
    }
  },

  getFormValue({ availableFrom, availableUntil }: DateRangeProductCondition) {
    return {
      dateRange: {
        start: availableFrom ? moment(availableFrom).format('YYYY-MM-DD') : null,
        end: availableUntil ? moment(availableUntil).format('YYYY-MM-DD') : null,
      },
    };
  },

  getDtoFromFormValue({ dateRange }: { dateRange: { start?: string; end?: string } }) {
    return {
      availableFrom: dateRange.start ? moment(dateRange.start).startOf('day').toDate() : null,
      availableUntil: dateRange.end ? moment(dateRange.end).endOf('day').toDate() : null,
    };
  },

  getFormModel: () => [
    new DynamicFormGroupModel(
      {
        id: 'dateRange',
        group: [
          new DynamicInputModel({
            id: 'start',
            inputType: 'date',
            label: 'Available From',
            errorMessages: {
              required: 'A start or end date is required',
            },
            relations: [
              {
                match: MATCH_REQUIRED,
                operator: OR_OPERATOR,
                when: [
                  { id: 'end', value: null },
                  { id: 'end', value: '' },
                ],
              },
            ],
          }),
          new DynamicInputModel({
            id: 'end',
            inputType: 'date',
            label: 'Available Until',
            errorMessages: {
              required: 'A start or end date is required',
            },
            relations: [
              {
                match: MATCH_REQUIRED,
                operator: OR_OPERATOR,
                when: [
                  { id: 'start', value: null },
                  { id: 'start', value: '' },
                ],
              },
            ],
          }),
        ],
      },
      {
        element: {
          children: 'flex_form_control_child',
          control: 'flex_form_control',
        },
      }
    ),
  ],
};
