<h2>Form Configuration</h2>

<mat-form-field style="width: 100%">
  <mat-label>Reusable</mat-label>
  <input
    matInput
    readonly
    matTooltip="Checking this box allows users to reuse their previous form inputs when booking into this event."
  />
  <mat-checkbox
    matSuffix
    [checked]="reusable"
    style="margin-left: 12px"
    matTooltip="Checking this box allows users to reuse their previous form inputs when booking into this event."
    color="primary"
    (change)="reusable = $event.checked"
  ></mat-checkbox>
</mat-form-field>

<mat-form-field style="width: 100%">
  <mat-label>Required</mat-label>
  <input
    matInput
    readonly
    matTooltip="Checking this box will force users to fill-out the form before booking into the event."
  />
  <mat-checkbox
    matSuffix
    [checked]="required"
    style="margin-left: 12px"
    matTooltip="Checking this box will force users to fill-out the form before booking into the event."
    color="primary"
    (change)="required = $event.checked"
  ></mat-checkbox>
</mat-form-field>

<div style="display: flex; align-items: center; gap: 8px">
  <button type="button" mat-stroked-button style="flex: 1" (click)="close()">Cancel</button>
  <button type="button" mat-stroked-button style="flex: 1" color="primary" (click)="confirm()">Confirm</button>
</div>
