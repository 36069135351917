import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BookingOption, Event } from '@greco/booking-events';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BookingOptionService } from '../../services';

@Component({
  selector: 'greco-booking-option-details-form',
  templateUrl: './booking-option-details-form.component.html',
  styleUrls: ['./booking-option-details-form.component.scss'],
})
export class BookingOptionDetailsFormComponent {
  constructor(private formBuilder: FormBuilder, private bookingOptionSvc: BookingOptionService) {
    this.form.disable();
  }

  @Output() saved = new EventEmitter<BookingOption>(true);

  resetValue: any = {
    title: null,
    color: null,
    description: null,
    icon: null,
    tags: [],
    availableToEveryone: false,
    isCourseOption: false,
  };

  private _bookingOption$ = new BehaviorSubject<BookingOption | null>(null);

  @Input() set bookingOption(bookingOption) {
    this._bookingOption$.next(bookingOption);
    this.resetForm();
  }
  get bookingOption() {
    return this._bookingOption$.value;
  }

  events$: Observable<Event[]> = combineLatest([this._bookingOption$]).pipe(
    switchMap(async ([bookingOption]) =>
      bookingOption ? await this.bookingOptionSvc.getEventsByBookingOption(bookingOption.id) : []
    )
  );

  form = this.formBuilder.group({
    title: [null, Validators.required],
    color: [null, Validators.required],
    description: [null],
    icon: [null],
    tags: [[], [Validators.required, Validators.minLength(1)]],
    availableToEveryone: [false, Validators.required],
    isCourseOption: [false, Validators.required],
  });

  resetForm() {
    this.resetValue = {
      title: this.bookingOption?.title || null,
      color: this.bookingOption?.badge.color || null,
      tags: (this.bookingOption as any)?.tags || [],
      icon: this.bookingOption?.badge.icon || null,
      description: this.bookingOption?.description || null,
      availableToEveryone: this.bookingOption?.availableToAnyone || false,
      isCourseOption: this.bookingOption?.isCourseOption || false,
    };

    this.form.setValue(this.resetValue);
    this.form.markAsPristine();

    if (this.bookingOption) this.form.enable();
    else this.form.disable();
  }

  save = async () => {
    if (!this.bookingOption) return;

    try {
      const option = await this.bookingOptionSvc.update(this.bookingOption.id, {
        availableToAnyone: this.form.value.availableToEveryone,
        description: this.form.value.description || '',
        title: this.form.value.title,
        color: this.form.value.color,
        tags: this.form.value.tags,
        icon: this.form.value.icon,
        isCourseOption: this.form.value.isCourseOption,
      });
      this.saved.emit(option);
    } catch (err) {
      console.error(err);
    }
  };
}
