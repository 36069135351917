import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CommunityAgreement } from '@greco/community-agreements';
import { PropertyListener } from '@greco/property-listener-util';
import { SConditionAND, SFields } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommunityAgreementsService } from '../../services';

@Component({
  selector: 'greco-community-agreement-picker',
  templateUrl: './community-agreement-picker.component.html',
  styleUrls: ['./community-agreement-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CommunityAgreementPickerComponent }],
})
export class CommunityAgreementPickerComponent
  implements MatFormFieldControl<CommunityAgreement>, ControlValueAccessor, OnDestroy
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _el: ElementRef,
    // private contactSvc: ContactService,
    private agreementSvc: CommunityAgreementsService
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  private static ID = 0;

  private _onChange?: (value: CommunityAgreement | null) => void;
  private _onTouched?: () => void;

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-community-agreement-picker';
  @HostBinding() readonly id = `${this.controlType}-${CommunityAgreementPickerComponent.ID++}`;

  @ViewChild(MatInput) input?: MatInput;

  @Output() agreement = new EventEmitter();

  // @PropertyListener('withUser') private _withUser$ = new ReplaySubject<boolean>(1);
  // @Input() withUser = false;

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string>(1);
  @Input() communityId!: string;

  @Input() extraConditions?: (SFields | SConditionAND)[];

  readonly searchQuery$ = new BehaviorSubject<string>('');
  readonly agreements$ = combineLatest([this.searchQuery$, this._communityId$]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    switchMap(async ([search, communityId]) => {
      // if (!communityId) return [];
      // const page = await this.agreementSvc.paginate(
      //   new RequestQueryBuilder().search({
      //     $and: [{ $or: [{ title: { $contL: search } }] }, ...(this.extraConditions || [])],
      //   }),
      //   communityId
      // );
      // return page.items;
      return await this.agreementSvc.getMany(communityId);
    })
  );

  private _value: CommunityAgreement | null = null;
  @Input() set value(value: CommunityAgreement | null) {
    this._value = value;
    this.agreement.emit(value);
    this._onChange?.(value);
    this.stateChanges.next();
  }
  get value() {
    return this._value;
  }

  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;

  get empty() {
    return !this._value && !this.searchQuery$.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState() {
    return false;
  }

  get focused() {
    return this.input?.focused || false;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]) {
    const el = this._el.nativeElement.querySelector('.greco-community-agreement-picker-container');
    if (el) el.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.input?.onContainerClick();
  }

  writeValue(value: CommunityAgreement | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: CommunityAgreement | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  displayWith(value: CommunityAgreement | null): string {
    return value?.title || '';
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value || null;
  }

  @PropertyListener('required')
  @PropertyListener('disabled')
  @PropertyListener('placeholder')
  private _stateChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.searchQuery$.complete();
  }
}
