import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Calendar, Resource, Tag } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import type { CalendarTemplateDto } from '@greco/nestjs-booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { Pagination } from 'nestjs-typeorm-paginate';

export const CALENDAR_COOKIE_NAME = 'selectedCalendar';
const ONE_HOUR_IN_MS = 60 * 60 * 1000;

@Injectable()
export class CalendarService {
  constructor(private http: HttpClient) {}

  // @Post()
  async create(communityId: string, data: FormData) {
    return await toPromise(this.http.post<Calendar>('/api/calendar/' + communityId, data));
  }

  //@Post(template)
  async addTemplate(dto: CalendarTemplateDto) {
    return await toPromise(this.http.post<Calendar>(`/api/calendar/template`, dto));
  }

  //@Delete(template)
  async removeTemplate(dto: CalendarTemplateDto) {
    return await toPromise(this.http.delete<Calendar>(`/api/calendar/${dto.calendarId}/template/${dto.templateId}`));
  }

  // @Get(':id')
  async getOne(calendarId: string) {
    return await toPromise(this.http.get<Calendar>(`/api/calendar/${calendarId}`));
  }

  // @Get(':id/resources')
  async getCalendarTags(calendarId: string) {
    return await toPromise(this.http.get<Tag[]>(`/api/calendar/${calendarId}/tags`));
  }

  // @Get(':id/resources')
  async getCalendarResources(calendarId: string) {
    return await toPromise(this.http.get<Resource[]>(`/api/calendar/${calendarId}/resources`));
  }

  // @Get()
  async getMany(communityId: string | string[]) {
    if (!communityId || !communityId.length) return;
    const communityIds = typeof communityId === 'string' ? [communityId] : communityId;
    return await toPromise(
      this.http.get<Calendar[]>(`/api/calendar`, {
        params: {
          communityIds,
        },
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private cache = new Map<string, Calendar[]>();

  // @Get()
  async getManyUser(communityId: string | string[]) {
    if (!communityId || !communityId.length) return;
    const communityIds = typeof communityId === 'string' ? [communityId] : communityId;

    const cacheKey = communityIds.join('');
    const cachedValue = this.cache.get(cacheKey);

    return (
      cachedValue ??
      (await toPromise(
        this.http.get<Calendar[]>(`/api/calendar/for-user`, {
          params: {
            communityIds,
          },
        })
      ).then(calendars => {
        this.cache.set(cacheKey, calendars);
        return calendars;
      }))
    );
  }

  // @Get('secured')
  async getManySecured(communityId: string | string[], withoutTemplates?: boolean) {
    if (!communityId || !communityId.length) return;
    const communityIds = typeof communityId === 'string' ? [communityId] : communityId;

    return await toPromise(
      this.http.get<Calendar[]>(`/api/calendar/secured`, {
        params: {
          communityIds,
          ...(withoutTemplates ? { withoutTemplates: 'true' } : {}),
        },
      })
    );
  }

  async getManySecuredCached(communityId: string | string[], withoutTemplates?: boolean) {
    if (!communityId || !communityId.length) return;

    const communityIds = typeof communityId === 'string' ? [communityId] : communityId;
    communityIds.sort();

    const key = `CALENDARS_SECURED_${JSON.stringify(communityId)}_${withoutTemplates ?? false}`;

    const fromCache = this._getManySecured(key);
    if (fromCache !== null) return fromCache;

    const calendars = await this.getManySecured(communityIds, withoutTemplates);

    this._updateManySecuredCache(key, calendars ?? []);

    return calendars;
  }

  private _getManySecured(key: string): Calendar[] | null {
    try {
      const cache = JSON.parse(localStorage.getItem(key) || '{}');

      const expires = cache?.expires ?? 0;
      if (expires <= Date.now()) return null;

      return cache?.calendars ?? null;
    } catch {
      return null;
    }
  }

  private _updateManySecuredCache(key: string, calendars: Calendar[]) {
    try {
      const cache = { calendars, expires: Date.now() + ONE_HOUR_IN_MS };
      localStorage.setItem(key, JSON.stringify(cache));
    } catch {
      /* noop */
    }
  }

  // @Patch(':id')
  async update(communityId: string, calendarId: string, formData: FormData) {
    return await toPromise(this.http.patch<Calendar>(`/api/calendar/${communityId}/${calendarId}`, formData));
  }

  async updateRemoveImage(communityId: string, calendarId: string, dto: any) {
    return await toPromise(this.http.patch<Calendar>(`/api/calendar/${communityId}/${calendarId}/remove-image`, dto));
  }

  // @Delete(':id')
  async delete(calendar: Calendar) {
    return await toPromise(this.http.delete(`/api/calendar/${calendar.id}`));
  }

  // @Put(':id')
  async restore(calendar: Calendar) {
    return await toPromise(this.http.put(`/api/calendar/${calendar.id}`, {}));
  }

  // @Get('paginate')
  async paginate(communityId?: string, query?: RequestQueryBuilder, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<Calendar>>(`/api/calendar/paginate`, {
        params: {
          ...query?.queryObject,
          ...(communityId && { communityId }),
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get('paginateStaff')
  async paginateStaff(query: string, excludedIds?: string[]): Promise<User[]> {
    const qb = RequestQueryBuilder.create({
      search: {
        $and: [
          { $or: [{ displayName: { $contL: query } }, { email: { $contL: query } }] },
          ...(excludedIds?.length ? [{ id: { $notin: excludedIds } }] : []),
        ],
      },
    });
    const staff = await toPromise(
      this.http.get<Pagination<User>>('/api/calendar/paginateStaff', {
        params: {
          ...qb.queryObject,
          page: '1',
          limit: '10',
        },
      })
    );
    return staff.items;
  }

  sortCalendars(calendars: Calendar[]) {
    return calendars.sort((a, b) => {
      if (a.community?.canBeParent) {
        return b.community?.canBeParent ? 0 : 1;
      } else if (b.community?.canBeParent) return -1;
      else return 0;
    });
  }

  loadSelectedCalendarFromCookie(calendars: Calendar[]) {
    let selectedCalendar = null;
    const calCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith(CALENDAR_COOKIE_NAME))
      ?.split('=')[1];
    if (calCookie) {
      const prevCalendar = calendars.filter(c => c.id === calCookie);
      if (prevCalendar.length > 0) selectedCalendar = prevCalendar[0];
      else this.saveSelectedCalendarCookie();
    }
    if (!selectedCalendar) selectedCalendar = calendars[0];
    return selectedCalendar;
  }

  // Passing an empty calendarId clears the cookie
  saveSelectedCalendarCookie(calendarId?: string) {
    document.cookie = `${CALENDAR_COOKIE_NAME}=${calendarId ?? ''};`;
  }
}
