import { BaseDto } from '@greco/util-dto';
import { VideoSourceRequestDto } from '@greco/videos';
import { Expose } from 'class-transformer';
import { IsNotEmpty, IsString } from 'class-validator';

export class LinkCommunityVideoDto extends BaseDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  communityId: string;

  @Expose()
  @IsNotEmpty()
  dto: VideoSourceRequestDto;
}
