<ng-container *ngIf="!showAsBoxes; else boxView">
  <div class="container">
    <!-- need conditional styling for day recurrence from api call; set opacity to 1
  and background color to the mat primary color -->
    <div *ngFor="let day of WEEKDAYS" [class]="recurrence?.includes(day.id) === false ? 'week-day' : 'week-day-active'">
      {{ day.value }}
    </div>
  </div>
</ng-container>
<ng-template #boxView>
  <div class="box-container">
    <div
      *ngFor="let day of WEEKDAYS; let index = index"
      class="box"
      [style]="(index === 6 ? 'border-right: none;' : '') + (addedHeight > 64 ? 'height: ' + addedHeight + 'px;' : '')"
    >
      <div
        *ngIf="recurrence?.includes(day.id); else notActive"
        class="box-active"
        [style]="
          index === 0
            ? 'border-radius: 8px 0 0 8px'
            : index === 6
            ? 'border-radius: 0 8px 8px 0; margin-right: -1px'
            : ''
        "
      >
        <div class="day">
          <p>{{ day.value }}</p>
        </div>
        <div class="times" *ngFor="let rule of rules">
          <ng-container *ngIf="(rule.options.byweekday[0] + 1) % 7 === index">
            {{
              (rule.options.byhour[0] % 12 === 0 ? 12 : rule.options.byhour[0] % 12) +
                ':' +
                (rule.options.byminute[0] < 10 ? '0' + rule.options.byminute[0] : rule.options.byminute[0])
            }}
            {{ rule.options.byhour[0] >= 12 ? 'PM' : 'AM' }}
          </ng-container>
        </div>
      </div>
      <ng-template #notActive>
        <p style="margin-top: 12px">{{ day.value }}</p>
      </ng-template>
    </div>
  </div>
</ng-template>
