import { Injectable } from '@angular/core';
import { User } from '@greco/identity-users';
import { AutocompleteFilter } from '@greco/ngx-filters';
import { UserService } from '@greco/ngx-identity-users';
import { CondOperator, SFields } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'any' })
export class PurchaseSoldByFilter extends AutocompleteFilter {
  constructor(private userSvc: UserService) {
    super('PurchaseSoldByFilter', {
      description: '',
      label: 'Sold By',
      properties: ['soldById'],
      shortLabel: 'Sold By',
      onlyOne: false,
      canOpen: true,
    });
  }

  public communityId?: string;

  getImageUrl(value: User) {
    return value?.photoURL ?? 'assets/lf3/icon.png';
  }

  getAutocompleteHtml(value: User): string {
    return `${value.displayName} <small>(${value.email})</small>`;
  }

  getValueLabel(value: User): string {
    return value?.displayName;
  }

  async getValueOptions(search?: string): Promise<User[]> {
    if (!this.communityId) return [];
    return await this.userSvc.searchUsersPublic(search ?? '', undefined, true);
  }

  serializeValue(value: User): string {
    return value.id;
  }

  async deserializeValue(serializedValue: string) {
    return await this.userSvc.getUser(serializedValue);
  }

  getPropertySField(property: string, operator: CondOperator, value: User): SFields {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { [property]: { [operator]: value!.id } };
  }
}
