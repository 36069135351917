import { Component, Input } from '@angular/core';
import { Video } from '@greco/videos';

@Component({
  selector: 'greco-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent {
  @Input() video!: Video;
  @Input() title?: string;
  @Input() progress?: number;
}
