import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ProductCondition } from '@greco/sales-products';
import { ProductConditionForm, PRODUCT_CONDITION_FORMS } from '../dialogs';

@Pipe({ name: 'productConditionDetails' })
export class ProductConditionDetails implements PipeTransform {
  constructor(@Inject(PRODUCT_CONDITION_FORMS) public readonly forms: ProductConditionForm[]) {}

  transform(value: ProductCondition, type: 'short' | 'default' = 'default') {
    const form = this.forms.find(f => f.type === value.type);
    if (!form) return '';
    else if (type === 'short') return form.getConditionShortDetails(value);
    else return form.getConditionDetails(value);
  }
}
