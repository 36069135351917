<h2 style="margin: 0">Add User to Waitlist</h2>
<h3 style="margin: 0">
  {{event.title}} - {{event.startDate | date: 'mediumDate'}} {{event.startDate | date: 'shortTime'}}
</h3>

<mat-form-field appearance="standard" style="width: 100%; margin-top: 16px">
  <mat-label>User</mat-label>
  <greco-contact-picker
    [communityId]="event.community.id"
    [formControl]="contactControl"
    [withUser]="true"
  ></greco-contact-picker>
</mat-form-field>

<div style="margin-top: 16px; display: flex; align-items: center; justify-content: flex-end; gap: 8px">
  <button mat-stroked-button style="flex: 1" (click)="cancel()">Cancel</button>
  <button mat-stroked-button style="flex: 1" [disabled]="!contactControl.value?.user?.id" (click)="add()">
    Add to Waitlist
  </button>
</div>
