import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@greco/ngx-identity-auth';
import { PromotionsDialog } from '@greco/ngx-promotions';
import { ProductConditionService } from '@greco/ngx-sales-products';
import { Promotion } from '@greco/promotions';
import { Product } from '@greco/sales-products';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'greco-promotions-carousel',
  templateUrl: './promotions-carousel.component.html',
  styleUrls: ['./promotions-carousel.component.scss'],
})
export class PromotionsCarouselComponent implements OnInit, OnDestroy {
  public products!: Product[];
  private _promotion!: Promotion;
  @Input() set promotion(promo: Promotion) {
    this._promotion = promo;
    this.products = promo.productVariants.reduce((acc, cur) => {
      const products = acc;
      const currentProduct = products.find(p => p.id === cur.product?.id);
      if (currentProduct) {
        currentProduct.variants.push(cur);
      } else if (cur.product) {
        products.push(cur.product);
        cur.product.variants = [cur];
      }
      return products;
    }, [] as Product[]);
  }
  get promotion() {
    return this._promotion;
  }

  private _onDestroy$ = new Subject<void>();

  mobileView = false;

  user$ = this.userSvc.user$;

  userConditionContext$ = this.user$.pipe(
    map(u => u?.id),
    distinctUntilChanged(),
    switchMap(userId => this.conditionSvc.getConditionContext(userId)),
    shareReplay(1)
  );

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private breakpoints: BreakpointObserver,
    private userSvc: UserService,
    @Optional() private dialogRef: MatDialogRef<PromotionsDialog>,
    private conditionSvc: ProductConditionService
  ) {}

  close() {
    this.dialogRef?.close();
  }

  async ngOnInit() {
    this.breakpoints
      .observe('(max-width: 600px)')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ matches }) => {
        this.mobileView = matches;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
