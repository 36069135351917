<form [formGroup]="eventsAndFilters" *grecoLet="allEventsByCalendar$ | async as allEventsByCalendar">
  <div class="header-toolbar">
    <div style="margin-left: auto; margin-right: auto; max-width: 768px; width: 100%">
      <mat-form-field class="inline-select" floatLabel="always">
        <mat-label>Workout Type</mat-label>

        <mat-select
          placeholder="Select Workout Type"
          formControlName="eventsByCalendar"
          [disabled]="loadingCalendarTypes"
          (selectionChange)="eventsByCalendarSelectionChanged($event.value)"
        >
          <mat-select-trigger> {{ eventsAndFilters.get('eventsByCalendar')?.value?.label }} </mat-select-trigger>

          <mat-option *ngFor="let eventsByCalendar of allEventsByCalendar" [value]="eventsByCalendar">
            <mat-icon>date_range</mat-icon>
            {{ eventsByCalendar.label }}
          </mat-option>

          <mat-option *ngFor="let link of externalLinks" [value]="link.path" (click)="navigate(link.path)">
            <mat-icon> {{ link.icon }} </mat-icon>
            {{ link.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *grecoLet="eventsAndFilters.get('eventsByCalendar')?.value?.locations as locations"
        class="inline-select"
        floatLabel="always"
      >
        <mat-label>Location</mat-label>

        <mat-select
          multiple
          placeholder="Select Location"
          [value]="eventsAndFilters.get('locations')?.value"
          [disabled]="loadingCalendarTypes || !locations?.length"
          (selectionChange)="locationSelectionChanged($event.value)"
        >
          <mat-select-trigger>
            {{ (eventsAndFilters.get('locations')?.value | pluck: 'label')?.join(', ') }}
          </mat-select-trigger>

          <mat-option *ngFor="let location of locations" [value]="location">
            <mat-icon>location_on</mat-icon>
            {{ location.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        *grecoLet="eventsAndFilters.get('filters')?.value as filters"
        mat-icon-button
        class="filter-button"
        color="primary"
        [disabled]="loadingCalendarTypes || !eventsAndFilters.get('eventsByCalendar')?.value"
        (click)="showFilters = !showFilters; showFilters && !filters.length && bottomSheet.open(filterSheet, { panelClass: 'bottom-sheet' })"
      >
        <mat-icon
          *ngIf="!showFilters; else filtersShown"
          matBadgeSize="small"
          matTooltipShowDelay="300"
          matTooltip="Show applied filters"
          [matBadge]="filters?.length || null"
        >
          filter_list
        </mat-icon>

        <ng-template #filtersShown>
          <mat-icon>expand_less</mat-icon>
        </ng-template>
      </button>
    </div>

    <mat-progress-bar *ngIf="loadingCalendarTypes" mode="buffer" class="toolbar-loading"></mat-progress-bar>
  </div>

  <div class="filter-toolbar" [@heightExpansion]="showFilters ? 'expanded' : 'collapsed'">
    <div>
      <mat-chip-list *ngIf="eventsAndFilters.get('filters')?.value?.length; else noFilters">
        <mat-chip
          *ngFor="let filter of eventsAndFilters.get('filters')?.value"
          [value]="filter"
          (removed)="removeFilter(filter.value); !eventsAndFilters.get('filters')?.value?.length && (showFilters = false)"
        >
          <img *ngIf="$any(filter).imageUrl" [src]="$any(filter).imageUrl" alt="Trainer Image" class="trainer-image" />
          {{ filter.label }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <div style="margin-left: auto">
          <button
            mat-icon-button
            matTooltip="Share filtered view"
            matTooltipShowDelay="300"
            [cdkCopyToClipboard]="getFilterLink()"
            (cdkCopyToClipboardCopied)="$event && copied()"
          >
            <mat-icon>launch</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Clear all filters"
            matTooltipShowDelay="300"
            (click)="clearFilters(); showFilters = false"
          >
            <mat-icon>filter_list_off</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Edit filters"
            matTooltipShowDelay="300"
            (click)="bottomSheet.open(filterSheet, { panelClass: 'bottom-sheet'})"
          >
            <mat-icon>tune</mat-icon>
          </button>
        </div>
      </mat-chip-list>

      <ng-template #noFilters>
        <button
          mat-stroked-button
          class="add-filter-button"
          (click)="bottomSheet.open(filterSheet, { panelClass: 'bottom-sheet'})"
        >
          <mat-icon>add</mat-icon> Apply filters
        </button>
      </ng-template>
    </div>
  </div>

  <div *ngIf="isMobile$ | async" class="booking-fab">
    <button mat-raised-button color="accent" (click)="bottomSheet.open(filterSheet, { panelClass: 'bottom-sheet' })">
      <mat-icon>filter_list</mat-icon>
      Filters
      <ng-container *ngIf="eventsAndFilters.get('filters')?.value?.length as length">
        {{'(' + length + ')'}}
      </ng-container>
    </button>
  </div>

  <ng-template #filterSheet>
    <div class="bottom-sheet-content">
      <div class="sheet-header">
        <h2
          #header
          style="margin-bottom: 0"
          class="apply-filters-header"
          [matBadge]="eventsAndFilters.get('filters')?.value?.length || null"
        >
          Apply Filters
        </h2>

        <div>
          <button
            mat-icon-button
            (click)="clearFilters(); bottomSheet.dismiss()"
            [disabled]="!eventsAndFilters.get('filters')?.value?.length"
          >
            <mat-icon>filter_list_off</mat-icon>
          </button>

          <button mat-icon-button (click)="bottomSheet.dismiss()">
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
      </div>

      <h3 style="margin-bottom: 0">Event Types</h3>
      <ng-container *grecoLet="eventsAndFilters.get('locations')?.value | flattenAndCombineFilters: 'tags' as tags">
        <mat-chip-list multiple selectable *ngIf="tags?.length; else noTags">
          <mat-chip
            *ngFor="let tag of tags"
            #tagChip="matChip"
            [selected]="(eventsAndFilters.get('filters')?.value | filterBy: ['value']: tag.id: true)?.length || false"
            (selectionChange)="$event.isUserInput && filterSelectionChanged($event.selected, tag, 'tag')"
            (click)="tagChip.toggleSelected(true)"
          >
            {{ tag.label }}
          </mat-chip>
        </mat-chip-list>

        <ng-template #noTags>
          <p style="margin-bottom: 0; color: gray"><em>No tags available to select.</em></p>
        </ng-template>
      </ng-container>

      <h3 style="margin-bottom: 0; margin-top: 12px">Instructors</h3>
      <ng-container
        *grecoLet="eventsAndFilters.get('locations')?.value | flattenAndCombineFilters: 'trainers' as trainers"
      >
        <mat-chip-list multiple selectable *ngIf="trainers?.length; else noTrainers">
          <mat-chip
            *ngFor="let trainer of trainers"
            #trainerChip="matChip"
            [selected]="(eventsAndFilters.get('filters')?.value | filterBy: ['value']: trainer.id: true)?.length || false"
            (selectionChange)="$event.isUserInput && filterSelectionChanged($event.selected, trainer, 'trainer')"
            (click)="trainerChip.toggleSelected(true)"
          >
            <img
              *ngIf="$any(trainer).imageUrl"
              alt="Trainer Image"
              class="trainer-image"
              [src]="$any(trainer).imageUrl"
            />
            {{ trainer.label }}
          </mat-chip>
        </mat-chip-list>

        <ng-template #noTrainers>
          <p style="margin-bottom: 0; color: gray"><em>No instructors available to select.</em></p>
        </ng-template>
      </ng-container>
    </div>
  </ng-template>
</form>

<router-outlet></router-outlet>
