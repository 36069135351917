import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { UserService } from '@greco/ngx-identity-auth';
import type { WidgetOptions } from '@typeform/embed';

@Injectable()
export class TypeformResolver implements Resolve<{ id: string; options: Pick<WidgetOptions, 'hidden'> } | null> {
  constructor(private userService: UserService, private router: Router) {}
  async resolve(route: ActivatedRouteSnapshot): Promise<{ id: string; options: Pick<WidgetOptions, 'hidden'> } | null> {
    const formId = route.params.formId;
    const requireUser = route.queryParams.requireUser === 'true';
    const user = await toPromise(this.userService.user$);

    if (!formId || (!user && requireUser)) {
      await this.router.navigateByUrl('/');
      return null;
    }
    return {
      id: formId,
      options: {
        hidden: {
          user_id: user?.id || '',
          user_email: user?.email || user?.contactEmail || '',
          user_display_name: user?.displayName || '',
          user_friendly_name: user?.friendlyName || '',
          user_phone_number: user?.phoneNumber || '',
        },
      },
    };
  }
}
