import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { Account } from '@greco/finance-accounts';
import type { PaginatedStripePayouts } from '@greco/nestjs-stripe-payment-gateway';

@Injectable()
export class PayoutService {
  constructor(private http: HttpClient) {}

  paginatePayouts(account: Account, startAfter?: string): Promise<PaginatedStripePayouts> {
    return toPromise(
      this.http.get<PaginatedStripePayouts>(`/api/stripe/${account.id}/payouts`, {
        params: { ...(startAfter && { startAfter }) },
      })
    );
  }
}
