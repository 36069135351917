import { CondOperator } from '@nestjsx/crud-request';
import { Filter, FilterOptions } from './filter';

export abstract class ToggleFilter extends Filter {
  type: 'toggle' = 'toggle';

  constructor(typeName: string, options: Omit<FilterOptions, 'type' | 'allowedOperators' | 'onlyOne'>) {
    super(typeName, { ...options, type: 'toggle', allowedOperators: [CondOperator.EQUALS], onlyOne: true });
  }
}
