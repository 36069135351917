import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  CommunityAgreement,
  CommunityAgreementUsage,
  CreateCommunityAgreementDto,
  UpdateCommunityAgreementDto,
} from '@greco/community-agreements';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}
@Injectable({ providedIn: 'root' })
export class CommunityAgreementsService {
  constructor(private http: HttpClient) {}

  async getOne(agreementId: string) {
    return await toPromise(this.http.get<CommunityAgreement>(`/api/community_agreements/${agreementId}`));
  }

  // async getCommunityAgreement(agreementId: string) {
  //   return await toPromise(this.http.get<CommunityAgreement>(`/api/community_agreements/preview/${agreementId}`));
  // }

  async getMany(communityId: string) {
    return await toPromise(
      this.http.get<CommunityAgreement[]>(`/api/community_agreements`, { params: { communityId } })
    );
  }

  async paginate(
    communityId: string,
    includeArchive: boolean,
    pagination: IPaginationOptions,
    queryBuilder: RequestQueryBuilder
  ): Promise<PaginatedDto<CommunityAgreement>> {
    let strIncludeArchive = '';
    if (includeArchive) strIncludeArchive = 'true';
    else strIncludeArchive = 'false';

    return await toPromise(
      this.http.get<PaginatedDto<CommunityAgreement>>(`/api/community_agreements/paginate`, {
        params: {
          communityId,
          strIncludeArchive,
          ...paginationQueryParams(pagination),
          ...queryBuilder.queryObject,
        },
      })
    );
  }

  async createAgreement(dto: CreateCommunityAgreementDto) {
    return await toPromise(this.http.post<CommunityAgreement>(`/api/community_agreements`, dto));
  }

  async updateAgreement(agreementId: string, dto: UpdateCommunityAgreementDto) {
    return await toPromise(this.http.post<CommunityAgreement>(`/api/community_agreements/${agreementId}`, dto));
  }

  async archiveAgreement(agreementId: string) {
    const archiveResult = await toPromise(this.http.delete<any>(`/api/community_agreements/${agreementId}`));
    return archiveResult;
  }

  async restoreAgreement(agreement: CommunityAgreement) {
    const restoreResult = await toPromise(
      this.http.post<CommunityAgreement>(`/api/community_agreements/restore_agreement`, agreement)
    );
    return restoreResult;
  }

  async canArchiveAgreement(agreementId: string) {
    console.log(agreementId);
    const canArchive = await toPromise(
      this.http.get<CommunityAgreementUsage[]>(`/api/agreements_usage/${agreementId}`)
    );
    return canArchive;
  }
}
