import { GrecoRoute } from '@greco/ngx-routes-util';
import { EventWrapperPage } from './pages';
import { EventsPage } from './pages/events/events.page';
import { WorkoutsLayoutPage } from './pages/workouts-layout/workouts-layout.page';
import { EventUserResolver } from './resolvers';

const root: GrecoRoute = {
  path: '',
  component: WorkoutsLayoutPage,
  children: [
    {
      path: '',
      component: EventsPage,
    },
    {
      path: ':eventId',
      component: EventWrapperPage,
      resolve: { user: EventUserResolver },
    },
  ],
};

export const routes: GrecoRoute[] = [root];
