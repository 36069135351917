import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'greco-smart-img',
  templateUrl: './smart-img.component.html',
  styleUrls: ['./smart-img.component.scss'],
})
export class SmartImgComponent {
  @Input() src?: string;

  @HostBinding('class') private _borderColor?: string;
  @Input() set borderColor(value: 'primary' | 'accent' | 'warn' | 'transparent' | undefined) {
    this._borderColor = value ? value + '-border' : value;
  }
  get borderColor() {
    return this._borderColor?.replace('-border', '') as 'primary' | 'accent' | 'warn' | 'transparent' | undefined;
  }

  private _round = false;
  @HostBinding('class.round') @Input() set round(value: any) {
    this._round = coerceBooleanProperty(value);
  }
  get round() {
    return this._round;
  }

  private _roundedBorder = false;
  @HostBinding('class.roundedBorder') @Input() set roundedBorder(value: any) {
    this._roundedBorder = coerceBooleanProperty(value);
  }
  get roundedBorder() {
    return this._roundedBorder;
  }

  private _border = false;
  @HostBinding('class.border') @Input() set border(value: any) {
    this._border = coerceBooleanProperty(value);
  }
  get border() {
    return this._border;
  }

  private _borderDashed = false;
  @HostBinding('class.border-dashed') @Input() set borderDashed(value: any) {
    this._borderDashed = coerceBooleanProperty(value);
  }
  get borderDashed() {
    return this._borderDashed;
  }

  private _disabled = false;
  @HostBinding('class.disabled') @Input() set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }
  get disabled() {
    return this._disabled;
  }

  // TODO(adaoust): Branding configuration through a resolver?
  // tslint:disable-next-line: max-line-length
  // `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5v-4.58l.99.99 4-4 4 4 4-3.99L19 12.43V19zm0-9.41l-1.01-1.01-4 4.01-4-4-4 4-.99-1V5h14v4.59z'/%3E%3C/svg%3E`;
  public defaultSrc = 'assets/lf3/icon_square_pad.png';
  public showDefault = false;
}
