<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); bookingTable.onFilterApplied()"
>
  <div style="display: flex; flex-direction: row">
    <button
      mat-stroked-button
      color="primary"
      style="height: 32px; line-height: 16px; border-top-right-radius: 0; border-bottom-right-radius: 0"
      (click)="createBookingOption()"
    >
      New Option
    </button>

    <button
      mat-stroked-button
      color="primary"
      style="
        height: 32px;
        line-height: 16px;
        width: 38px;
        min-width: 38px;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      "
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>

  <mat-menu #menu>
    <button mat-menu-item color="primary" (click)="manageBookingTags()">
      <mat-icon>style</mat-icon>
      <span>Manage Booking Tags</span>
    </button>
  </mat-menu>
</greco-filter-bar>

<greco-booking-options-table
  #bookingTable
  [communityId]="community.id"
  [query]="(filters$ | async) || undefined"
></greco-booking-options-table>
