export const categories: Map<string, string> = new Map<string, string>([
  ['av', 'videocam'],
  ['image', 'photo_camera'],
  ['action', 'build'],
  ['social', 'emoji_emotions'],
  ['device', 'add_to_home_screen'],
  ['maps', 'place'],
  ['communication', 'call'],
  ['places', 'apartment'],
  ['notification', 'sms_failed'],
  ['content', 'content_paste'],
  ['alert', 'error_outline'],
  ['editor', 'mode'],
  ['navigation', 'switch_left'],
  ['hardware', 'computer'],
  ['file', 'folder'],
  ['home', 'electric_bolt'],
  ['search', 'yard'],
  ['toggle', 'check_box'],
]);
export const icons = {
  icons: [
    {
      name: '10k',
      categories: ['av'],
      tags: [
        '10000',
        '10K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '10mp',
      categories: ['image'],
      tags: [
        '10',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '11mp',

      categories: ['image'],
      tags: [
        '11',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '123',

      categories: ['action'],
      tags: ['1', '2', '3', 'digit', 'number', 'symbol'],
    },
    {
      name: '12mp',
      categories: ['image'],
      tags: [
        '12',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '13mp',
      categories: ['image'],
      tags: [
        '13',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '14mp',

      categories: ['image'],
      tags: [
        '14',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '15mp',

      categories: ['image'],
      tags: [
        '15',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '16mp',

      categories: ['image'],
      tags: [
        '16',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '17mp',

      categories: ['image'],
      tags: [
        '17',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '18_up_rating',

      categories: ['social'],
      tags: [],
    },
    {
      name: '18mp',

      categories: ['image'],
      tags: [
        '18',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '19mp',

      categories: ['image'],
      tags: [
        '19',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '1k',

      categories: ['av'],
      tags: [
        '1000',
        '1K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '1k_plus',

      categories: ['av'],
      tags: [
        '+',
        '1000',
        '1K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'plus',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '1x_mobiledata',

      categories: ['device'],
      tags: [
        '1x',
        'alphabet',
        'cellular',
        'character',
        'digit',
        'font',
        'letter',
        'mobile',
        'mobiledata',
        'network',
        'number',
        'phone',
        'signal',
        'speed',
        'symbol',
        'text',
        'type',
        'wifi',
      ],
    },
    {
      name: '20mp',

      categories: ['image'],
      tags: [
        '20',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '21mp',

      categories: ['image'],
      tags: [
        '21',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '22mp',

      categories: ['image'],
      tags: [
        '22',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '23mp',

      categories: ['image'],
      tags: [
        '23',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '24mp',

      categories: ['image'],
      tags: [
        '24',
        'camera',
        'digits',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'numbers',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '2k',

      categories: ['av'],
      tags: [
        '2000',
        '2K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '2k_plus',

      categories: ['av'],
      tags: ['+', '2k', 'alphabet', 'character', 'digit', 'font', 'letter', 'number', 'plus', 'symbol', 'text', 'type'],
    },
    {
      name: '2mp',

      categories: ['image'],
      tags: [
        '2',
        'camera',
        'digit',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'number',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '30fps',

      categories: ['device'],
      tags: [
        '30fps',
        'alphabet',
        'camera',
        'character',
        'digit',
        'font',
        'fps',
        'frames',
        'letter',
        'number',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '30fps_select',

      categories: ['image'],
      tags: [
        '30',
        'camera',
        'digits',
        'fps',
        'frame',
        'frequency',
        'image',
        'numbers',
        'per',
        'rate',
        'second',
        'seconds',
        'select',
        'video',
      ],
    },
    {
      name: '360',

      categories: ['maps'],
      tags: ['360', 'arrow', 'av', 'camera', 'direction', 'rotate', 'rotation', 'vr'],
    },
    {
      name: '3d_rotation',

      categories: ['action'],
      tags: [
        '3',
        '3d',
        'D',
        'alphabet',
        'arrow',
        'arrows',
        'av',
        'camera',
        'character',
        'digit',
        'font',
        'letter',
        'number',
        'rotation',
        'symbol',
        'text',
        'type',
        'vr',
      ],
    },
    {
      name: '3g_mobiledata',

      categories: ['device'],
      tags: [
        '3g',
        'alphabet',
        'cellular',
        'character',
        'digit',
        'font',
        'letter',
        'mobile',
        'mobiledata',
        'network',
        'number',
        'phone',
        'signal',
        'speed',
        'symbol',
        'text',
        'type',
        'wifi',
      ],
    },
    {
      name: '3k',

      categories: ['av'],
      tags: [
        '3000',
        '3K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '3k_plus',

      categories: ['av'],
      tags: [
        '+',
        '3000',
        '3K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'plus',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '3mp',

      categories: ['image'],
      tags: [
        '3',
        'camera',
        'digit',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'number',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '3p',

      categories: ['communication'],
      tags: [
        '3',
        '3p',
        'account',
        'avatar',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'face',
        'human',
        'message',
        'party',
        'people',
        'person',
        'profile',
        'speech',
        'user',
      ],
    },
    {
      name: '4g_mobiledata',

      categories: ['device'],
      tags: [
        '4g',
        'alphabet',
        'cellular',
        'character',
        'digit',
        'font',
        'letter',
        'mobile',
        'mobiledata',
        'network',
        'number',
        'phone',
        'signal',
        'speed',
        'symbol',
        'text',
        'type',
        'wifi',
      ],
    },
    {
      name: '4g_plus_mobiledata',

      categories: ['device'],
      tags: [
        '4g',
        'alphabet',
        'cellular',
        'character',
        'digit',
        'font',
        'letter',
        'mobile',
        'mobiledata',
        'network',
        'number',
        'phone',
        'plus',
        'signal',
        'speed',
        'symbol',
        'text',
        'type',
        'wifi',
      ],
    },
    {
      name: '4k',

      categories: ['av'],
      tags: [
        '4000',
        '4K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '4k_plus',

      categories: ['av'],
      tags: [
        '+',
        '4000',
        '4K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'plus',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '4mp',

      categories: ['image'],
      tags: [
        '4',
        'camera',
        'digit',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'number',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '5g',

      categories: ['av'],
      tags: [
        '5g',
        'alphabet',
        'cellular',
        'character',
        'data',
        'digit',
        'font',
        'letter',
        'mobile',
        'network',
        'number',
        'phone',
        'signal',
        'speed',
        'symbol',
        'text',
        'type',
        'wifi',
      ],
    },
    {
      name: '5k',

      categories: ['av'],
      tags: [
        '5000',
        '5K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '5k_plus',

      categories: ['av'],
      tags: [
        '+',
        '5000',
        '5K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'plus',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '5mp',

      categories: ['image'],
      tags: [
        '5',
        'camera',
        'digit',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'number',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '60fps',

      categories: ['device'],
      tags: ['60fps', 'camera', 'digit', 'fps', 'frames', 'number', 'symbol', 'video'],
    },
    {
      name: '60fps_select',

      categories: ['image'],
      tags: [
        '60',
        'camera',
        'digits',
        'fps',
        'frame',
        'frequency',
        'numbers',
        'per',
        'rate',
        'second',
        'seconds',
        'select',
        'video',
      ],
    },
    {
      name: '6_ft_apart',

      categories: ['social'],
      tags: ['6', 'apart', 'body', 'covid', 'distance', 'feet', 'ft', 'human', 'people', 'person', 'social'],
    },
    {
      name: '6k',

      categories: ['av'],
      tags: [
        '6000',
        '6K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '6k_plus',

      categories: ['av'],
      tags: [
        '+',
        '6000',
        '6K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'plus',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '6mp',

      categories: ['image'],
      tags: [
        '6',
        'camera',
        'digit',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'number',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '7k',

      categories: ['av'],
      tags: [
        '7000',
        '7K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '7k_plus',

      categories: ['av'],
      tags: [
        '+',
        '7000',
        '7K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'plus',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '7mp',

      categories: ['image'],
      tags: [
        '7',
        'camera',
        'digit',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'number',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '8k',

      categories: ['av'],
      tags: [
        '8000',
        '8K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '8k_plus',

      categories: ['av'],
      tags: [
        '+',
        '7000',
        '8K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'plus',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '8mp',

      categories: ['image'],
      tags: [
        '8',
        'camera',
        'digit',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'number',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: '9k',

      categories: ['av'],
      tags: [
        '9000',
        '9K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '9k_plus',

      categories: ['av'],
      tags: [
        '+',
        '9000',
        '9K',
        'alphabet',
        'character',
        'digit',
        'display',
        'font',
        'letter',
        'number',
        'pixel',
        'pixels',
        'plus',
        'resolution',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: '9mp',

      categories: ['image'],
      tags: [
        '9',
        'camera',
        'digit',
        'font',
        'image',
        'letters',
        'megapixel',
        'megapixels',
        'mp',
        'number',
        'pixel',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'abc',

      categories: ['action'],
      tags: ['alphabet', 'character', 'font', 'letter', 'symbol', 'text', 'type'],
    },
    {
      name: 'ac_unit',

      categories: ['places'],
      tags: ['ac', 'air', 'cold', 'conditioner', 'flake', 'snow', 'temperature', 'unit', 'weather', 'winter'],
    },
    {
      name: 'access_alarm',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'access_alarms',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'access_time',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'access_time_filled',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'accessibility',

      categories: ['action'],
      tags: ['accessibility', 'accessible', 'body', 'handicap', 'help', 'human', 'people', 'person'],
    },
    {
      name: 'accessibility_new',

      categories: ['action'],
      tags: ['accessibility', 'accessible', 'body', 'handicap', 'help', 'human', 'new', 'people', 'person'],
    },
    {
      name: 'accessible',

      categories: ['action'],
      tags: ['accessibility', 'accessible', 'body', 'handicap', 'help', 'human', 'people', 'person', 'wheelchair'],
    },
    {
      name: 'accessible_forward',

      categories: ['action'],
      tags: [
        'accessibility',
        'accessible',
        'body',
        'forward',
        'handicap',
        'help',
        'human',
        'people',
        'person',
        'wheelchair',
      ],
    },
    {
      name: 'account_balance',

      categories: ['action'],
      tags: [
        'account',
        'balance',
        'bank',
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
      ],
    },
    {
      name: 'account_balance_wallet',

      categories: ['action'],
      tags: [
        'account',
        'balance',
        'bank',
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'wallet',
      ],
    },
    {
      name: 'account_box',

      categories: ['action'],
      tags: ['account', 'avatar', 'box', 'face', 'human', 'people', 'person', 'profile', 'square', 'thumbnail', 'user'],
    },
    {
      name: 'account_circle',

      categories: ['action'],
      tags: ['account', 'avatar', 'circle', 'face', 'human', 'people', 'person', 'profile', 'thumbnail', 'user'],
    },
    {
      name: 'account_tree',

      categories: ['notification'],
      tags: [
        'account',
        'analytics',
        'chart',
        'connect',
        'data',
        'diagram',
        'flow',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'process',
        'square',
        'statistics',
        'structure',
        'tracking',
        'tree',
      ],
    },
    {
      name: 'ad_units',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'ad',
        'banner',
        'cell',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'notification',
        'notifications',
        'phone',
        'tablet',
        'top',
        'units',
      ],
    },
    {
      name: 'adb',

      categories: ['notification'],
      tags: ['adb', 'android', 'bridge', 'debug'],
    },
    {
      name: 'add',

      categories: ['content'],
      tags: ['+', 'add', 'new symbol', 'plus', 'symbol'],
    },
    {
      name: 'add_a_photo',

      categories: ['image'],
      tags: ['+', 'a photo', 'add', 'camera', 'lens', 'new', 'photography', 'picture', 'plus', 'symbol'],
    },
    {
      name: 'add_alarm',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'add_alert',

      categories: ['alert'],
      tags: [
        '+',
        'active',
        'add',
        'alarm',
        'alert',
        'bell',
        'chime',
        'new',
        'notifications',
        'notify',
        'plus',
        'reminder',
        'ring',
        'sound',
        'symbol',
      ],
    },
    {
      name: 'add_box',

      categories: ['content'],
      tags: ['add', 'box', 'new square', 'plus', 'symbol'],
    },
    {
      name: 'add_business',

      categories: ['maps'],
      tags: [
        '+',
        'add',
        'bill',
        'building',
        'business',
        'card',
        'cash',
        'coin',
        'commerce',
        'company',
        'credit',
        'currency',
        'dollars',
        'market',
        'money',
        'new',
        'online',
        'pay',
        'payment',
        'plus',
        'shop',
        'shopping',
        'store',
        'storefront',
        'symbol',
      ],
    },
    {
      name: 'add_card',

      categories: ['action'],
      tags: [
        '+',
        'add',
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'new',
        'online',
        'pay',
        'payment',
        'plus',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'add_chart',

      categories: ['editor'],
      tags: [
        '+',
        'add',
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'new',
        'plus',
        'statistics',
        'symbol',
        'tracking',
      ],
    },
    {
      name: 'add_circle',

      categories: ['content'],
      tags: ['+', 'add', 'circle', 'create', 'new', 'plus'],
    },
    {
      name: 'add_circle_outline',

      categories: ['content'],
      tags: ['+', 'add', 'circle', 'create', 'new', 'outline', 'plus'],
    },
    {
      name: 'add_comment',

      categories: ['editor'],
      tags: [
        '+',
        'add',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'feedback',
        'message',
        'new',
        'plus',
        'speech',
        'symbol',
      ],
    },
    {
      name: 'add_home',

      categories: ['action'],
      tags: [],
    },
    {
      name: 'add_home_work',

      categories: ['navigation'],
      tags: [],
    },
    {
      name: 'add_ic_call',

      categories: ['communication'],
      tags: [
        '+',
        'add',
        'call',
        'cell',
        'contact',
        'device',
        'hardware',
        'mobile',
        'new',
        'phone',
        'plus',
        'symbol',
        'telephone',
      ],
    },
    {
      name: 'add_link',

      categories: ['content'],
      tags: ['add', 'attach', 'clip', 'link', 'new', 'plus', 'symbol'],
    },
    {
      name: 'add_location',

      categories: ['maps'],
      tags: [
        '+',
        'add',
        'destination',
        'direction',
        'location',
        'maps',
        'new',
        'pin',
        'place',
        'plus',
        'stop',
        'symbol',
      ],
    },
    {
      name: 'add_location_alt',

      categories: ['maps'],
      tags: [
        '+',
        'add',
        'alt',
        'destination',
        'direction',
        'location',
        'maps',
        'new',
        'pin',
        'place',
        'plus',
        'stop',
        'symbol',
      ],
    },
    {
      name: 'add_moderator',

      categories: ['social'],
      tags: [
        '+',
        'add',
        'certified',
        'moderator',
        'new',
        'plus',
        'privacy',
        'private',
        'protect',
        'protection',
        'security',
        'shield',
        'symbol',
        'verified',
      ],
    },
    {
      name: 'add_photo_alternate',

      categories: ['image'],
      tags: [
        '+',
        'add',
        'alternate',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'new',
        'photo',
        'photography',
        'picture',
        'plus',
        'symbol',
      ],
    },
    {
      name: 'add_reaction',

      categories: ['social'],
      tags: [
        '+',
        'add',
        'emoji',
        'emotions',
        'expressions',
        'face',
        'feelings',
        'glad',
        'happiness',
        'happy',
        'insert',
        'like',
        'mood',
        'new',
        'person',
        'pleased',
        'plus',
        'smile',
        'smiling',
        'social',
        'survey',
        'symbol',
      ],
    },
    {
      name: 'add_road',

      categories: ['maps'],
      tags: [
        '+',
        'add',
        'destination',
        'direction',
        'highway',
        'maps',
        'new',
        'plus',
        'road',
        'stop',
        'street',
        'symbol',
        'traffic',
      ],
    },
    {
      name: 'add_shopping_cart',

      categories: ['action'],
      tags: [
        'add',
        'card',
        'cart',
        'cash',
        'checkout',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'money',
        'online',
        'pay',
        'payment',
        'plus',
        'shopping',
      ],
    },
    {
      name: 'add_task',

      categories: ['action'],
      tags: [
        '+',
        'add',
        'approve',
        'check',
        'circle',
        'completed',
        'increase',
        'mark',
        'ok',
        'plus',
        'select',
        'task',
        'tick',
        'yes',
      ],
    },
    {
      name: 'add_to_drive',

      categories: ['action'],
      tags: [
        'add',
        'app',
        'application',
        'backup',
        'cloud',
        'drive',
        'files',
        'folders',
        'gdrive',
        'google',
        'recovery',
        'shortcut',
        'storage',
      ],
    },
    {
      name: 'add_to_home_screen',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'add to',
        'arrow',
        'cell',
        'device',
        'hardware',
        'home',
        'iOS',
        'mobile',
        'phone',
        'screen',
        'tablet',
        'up',
      ],
    },
    {
      name: 'add_to_photos',

      categories: ['image'],
      tags: [
        'add',
        'collection',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'photos',
        'picture',
        'plus',
        'to',
      ],
    },
    {
      name: 'add_to_queue',

      categories: ['av'],
      tags: [
        '+',
        'Android',
        'OS',
        'add',
        'chrome',
        'desktop',
        'device',
        'display',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'new',
        'plus',
        'queue',
        'screen',
        'symbol',
        'to',
        'web',
        'window',
      ],
    },
    {
      name: 'addchart',

      categories: ['action'],
      tags: [
        '+',
        'addchart',
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'new',
        'plus',
        'statistics',
        'symbol',
        'tracking',
      ],
    },
    {
      name: 'adf_scanner',

      categories: ['hardware'],
      tags: ['adf', 'document', 'feeder', 'machine', 'office', 'scan', 'scanner'],
    },
    {
      name: 'adjust',

      categories: ['image'],
      tags: ['adjust', 'alter', 'center', 'circle', 'circles', 'dot', 'fix', 'image', 'move', 'target'],
    },
    {
      name: 'admin_panel_settings',

      categories: ['action'],
      tags: [
        'account',
        'admin',
        'avatar',
        'certified',
        'face',
        'human',
        'panel',
        'people',
        'person',
        'privacy',
        'private',
        'profile',
        'protect',
        'protection',
        'security',
        'settings',
        'shield',
        'user',
        'verified',
      ],
    },
    {
      name: 'ads_click',

      categories: ['action'],
      tags: ['ads', 'browser', 'click', 'clicks', 'cursor', 'internet', 'target', 'traffic', 'web'],
    },
    {
      name: 'agriculture',

      categories: ['maps'],
      tags: [
        'agriculture',
        'automobile',
        'car',
        'cars',
        'cultivation',
        'farm',
        'harvest',
        'maps',
        'tractor',
        'transport',
        'travel',
        'truck',
        'vehicle',
      ],
    },
    {
      name: 'air',

      categories: ['device'],
      tags: ['air', 'blowing', 'breeze', 'flow', 'wave', 'weather', 'wind'],
    },
    {
      name: 'airline_seat_flat',

      categories: ['notification'],
      tags: [
        'airline',
        'body',
        'business',
        'class',
        'first',
        'flat',
        'human',
        'people',
        'person',
        'rest',
        'seat',
        'sleep',
        'travel',
      ],
    },
    {
      name: 'airline_seat_flat_angled',

      categories: ['notification'],
      tags: [
        'airline',
        'angled',
        'body',
        'business',
        'class',
        'first',
        'flat',
        'human',
        'people',
        'person',
        'rest',
        'seat',
        'sleep',
        'travel',
      ],
    },
    {
      name: 'airline_seat_individual_suite',

      categories: ['notification'],
      tags: [
        'airline',
        'body',
        'business',
        'class',
        'first',
        'human',
        'individual',
        'people',
        'person',
        'rest',
        'seat',
        'sleep',
        'suite',
        'travel',
      ],
    },
    {
      name: 'airline_seat_legroom_extra',

      categories: ['notification'],
      tags: [
        'airline',
        'body',
        'extra',
        'feet',
        'human',
        'leg',
        'legroom',
        'people',
        'person',
        'seat',
        'sitting',
        'space',
        'travel',
      ],
    },
    {
      name: 'airline_seat_legroom_normal',

      categories: ['notification'],
      tags: [
        'airline',
        'body',
        'feet',
        'human',
        'leg',
        'legroom',
        'normal',
        'people',
        'person',
        'seat',
        'sitting',
        'space',
        'travel',
      ],
    },
    {
      name: 'airline_seat_legroom_reduced',

      categories: ['notification'],
      tags: [
        'airline',
        'body',
        'feet',
        'human',
        'leg',
        'legroom',
        'people',
        'person',
        'reduced',
        'seat',
        'sitting',
        'space',
        'travel',
      ],
    },
    {
      name: 'airline_seat_recline_extra',

      categories: ['notification'],
      tags: [
        'airline',
        'body',
        'extra',
        'feet',
        'human',
        'leg',
        'legroom',
        'people',
        'person',
        'seat',
        'sitting',
        'space',
        'travel',
      ],
    },
    {
      name: 'airline_seat_recline_normal',

      categories: ['notification'],
      tags: [
        'airline',
        'body',
        'extra',
        'feet',
        'human',
        'leg',
        'legroom',
        'normal',
        'people',
        'person',
        'recline',
        'seat',
        'sitting',
        'space',
        'travel',
      ],
    },
    {
      name: 'airline_stops',

      categories: ['maps'],
      tags: [
        'airline',
        'arrow',
        'destination',
        'direction',
        'layover',
        'location',
        'maps',
        'place',
        'stops',
        'transportation',
        'travel',
        'trip',
      ],
    },
    {
      name: 'airlines',

      categories: ['maps'],
      tags: ['airlines', 'airplane', 'airport', 'flight', 'plane', 'transportation', 'travel', 'trip'],
    },
    {
      name: 'airplane_ticket',

      categories: ['device'],
      tags: ['airplane', 'airport', 'boarding', 'flight', 'fly', 'maps', 'pass', 'ticket', 'transportation', 'travel'],
    },
    {
      name: 'airplanemode_active',

      categories: ['device'],
      tags: ['active', 'airplane', 'airplanemode', 'flight', 'mode', 'on', 'signal'],
    },
    {
      name: 'airplanemode_inactive',

      categories: ['device'],
      tags: [
        'airplane',
        'airplanemode',
        'airport',
        'disabled',
        'enabled',
        'flight',
        'fly',
        'inactive',
        'maps',
        'mode',
        'off',
        'offline',
        'on',
        'slash',
        'transportation',
        'travel',
      ],
    },
    {
      name: 'airplay',

      categories: ['av'],
      tags: ['airplay', 'arrow', 'connect', 'control', 'desktop', 'device', 'display', 'monitor', 'screen', 'signal'],
    },
    {
      name: 'airport_shuttle',

      categories: ['places'],
      tags: [
        'airport',
        'automobile',
        'car',
        'cars',
        'commercial',
        'delivery',
        'direction',
        'maps',
        'mini',
        'public',
        'shuttle',
        'transport',
        'transportation',
        'travel',
        'truck',
        'van',
        'vehicle',
      ],
    },
    {
      name: 'alarm',

      categories: ['action'],
      tags: ['alarm', 'alart', 'bell', 'clock', 'countdown', 'date', 'notification', 'schedule', 'time'],
    },
    {
      name: 'alarm_add',

      categories: ['action'],
      tags: [
        '+',
        'add',
        'alarm',
        'alart',
        'bell',
        'clock',
        'countdown',
        'date',
        'new',
        'notification',
        'plus',
        'schedule',
        'symbol',
        'time',
      ],
    },
    {
      name: 'alarm_off',

      categories: ['action'],
      tags: [
        'alarm',
        'alart',
        'bell',
        'clock',
        'disabled',
        'duration',
        'enabled',
        'notification',
        'off',
        'on',
        'slash',
        'time',
        'timer',
        'watch',
      ],
    },
    {
      name: 'alarm_on',

      categories: ['action'],
      tags: [
        'alarm',
        'alart',
        'bell',
        'clock',
        'disabled',
        'duration',
        'enabled',
        'notification',
        'off',
        'on',
        'slash',
        'time',
        'timer',
        'watch',
      ],
    },
    {
      name: 'album',

      categories: ['av'],
      tags: [
        'album',
        'artist',
        'audio',
        'bvb',
        'cd',
        'computer',
        'data',
        'disk',
        'file',
        'music',
        'record',
        'sound',
        'storage',
        'track',
      ],
    },
    {
      name: 'align_horizontal_center',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'center',
        'format',
        'horizontal',
        'layout',
        'lines',
        'paragraph',
        'rule',
        'rules',
        'style',
        'text',
      ],
    },
    {
      name: 'align_horizontal_left',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'format',
        'horizontal',
        'layout',
        'left',
        'lines',
        'paragraph',
        'rule',
        'rules',
        'style',
        'text',
      ],
    },
    {
      name: 'align_horizontal_right',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'format',
        'horizontal',
        'layout',
        'lines',
        'paragraph',
        'right',
        'rule',
        'rules',
        'style',
        'text',
      ],
    },
    {
      name: 'align_vertical_bottom',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'bottom',
        'format',
        'layout',
        'lines',
        'paragraph',
        'rule',
        'rules',
        'style',
        'text',
        'vertical',
      ],
    },
    {
      name: 'align_vertical_center',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'center',
        'format',
        'layout',
        'lines',
        'paragraph',
        'rule',
        'rules',
        'style',
        'text',
        'vertical',
      ],
    },
    {
      name: 'align_vertical_top',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'format',
        'layout',
        'lines',
        'paragraph',
        'rule',
        'rules',
        'style',
        'text',
        'top',
        'vertical',
      ],
    },
    {
      name: 'all_inbox',

      categories: ['action'],
      tags: ['Inbox', 'all', 'delivered', 'delivery', 'email', 'mail', 'message', 'send'],
    },
    {
      name: 'all_inclusive',

      categories: ['places'],
      tags: [
        'all',
        'endless',
        'forever',
        'inclusive',
        'infinity',
        'loop',
        'mobius',
        'neverending',
        'strip',
        'sustainability',
        'sustainable',
      ],
    },
    {
      name: 'all_out',

      categories: ['action'],
      tags: ['all', 'circle', 'out', 'shape'],
    },
    {
      name: 'alt_route',

      categories: ['maps'],
      tags: [
        'alt',
        'alternate',
        'alternative',
        'arrows',
        'direction',
        'maps',
        'navigation',
        'options',
        'other',
        'route',
        'routes',
        'split',
        'symbol',
      ],
    },
    {
      name: 'alternate_email',

      categories: ['communication'],
      tags: ['@', 'address', 'alternate', 'contact', 'email', 'tag'],
    },
    {
      name: 'analytics',

      categories: ['action'],
      tags: [
        'analytics',
        'assessment',
        'bar',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'anchor',

      categories: ['action'],
      tags: ['anchor', 'google', 'logo'],
    },
    {
      name: 'android',

      categories: ['action'],
      tags: ['android', 'character', 'logo', 'mascot', 'toy'],
    },
    {
      name: 'animation',

      categories: ['image'],
      tags: ['animation', 'circles', 'film', 'motion', 'movement', 'sequence', 'video'],
    },
    {
      name: 'announcement',

      categories: ['action'],
      tags: [
        '!',
        'alert',
        'announcement',
        'attention',
        'bubble',
        'caution',
        'chat',
        'comment',
        'communicate',
        'danger',
        'error',
        'exclamation',
        'feedback',
        'important',
        'mark',
        'message',
        'notification',
        'speech',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'aod',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'always',
        'aod',
        'device',
        'display',
        'hardware',
        'homescreen',
        'iOS',
        'mobile',
        'on',
        'phone',
        'tablet',
      ],
    },
    {
      name: 'apartment',

      categories: ['places'],
      tags: [
        'accommodation',
        'apartment',
        'architecture',
        'building',
        'city',
        'company',
        'estate',
        'flat',
        'home',
        'house',
        'office',
        'places',
        'real',
        'residence',
        'residential',
        'shelter',
        'units',
        'workplace',
      ],
    },
    {
      name: 'api',

      categories: ['action'],
      tags: ['api', 'developer', 'development', 'enterprise', 'software'],
    },
    {
      name: 'app_blocking',

      categories: ['action'],
      tags: [
        'Android',
        'OS',
        'app',
        'application',
        'block',
        'blocking',
        'cancel',
        'cell',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'stop',
        'stopped',
        'tablet',
      ],
    },
    {
      name: 'app_registration',

      categories: ['communication'],
      tags: ['app', 'apps', 'edit', 'pencil', 'register', 'registration'],
    },
    {
      name: 'app_settings_alt',

      categories: ['navigation'],
      tags: [
        'Android',
        'OS',
        'app',
        'applications',
        'cell',
        'device',
        'gear',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'setting',
        'settings',
        'tablet',
      ],
    },
    {
      name: 'app_shortcut',

      categories: ['action'],
      tags: [
        'app',
        'bookmarked',
        'favorite',
        'highlight',
        'important',
        'marked',
        'mobile',
        'save',
        'saved',
        'shortcut',
        'software',
        'special',
        'star',
      ],
    },
    {
      name: 'approval',

      categories: ['file'],
      tags: [
        'apply',
        'approval',
        'approvals',
        'approve',
        'certificate',
        'certification',
        'disapproval',
        'drive',
        'file',
        'impression',
        'ink',
        'mark',
        'postage',
        'stamp',
      ],
    },
    {
      name: 'apps',

      categories: ['navigation'],
      tags: [
        'all',
        'applications',
        'apps',
        'circles',
        'collection',
        'components',
        'dots',
        'grid',
        'interface',
        'squares',
        'ui',
        'ux',
      ],
    },
    {
      name: 'apps_outage',

      categories: ['navigation'],
      tags: [
        'all',
        'applications',
        'apps',
        'circles',
        'collection',
        'components',
        'dots',
        'grid',
        'interface',
        'outage',
        'squares',
        'ui',
        'ux',
      ],
    },
    {
      name: 'architecture',

      categories: ['social'],
      tags: ['architecture', 'art', 'compass', 'design', 'draw', 'drawing', 'engineering', 'geometric', 'tool'],
    },
    {
      name: 'archive',

      categories: ['content'],
      tags: ['archive', 'inbox', 'mail', 'store'],
    },
    {
      name: 'area_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'area',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'arrow_back',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'back',
        'components',
        'direction',
        'disable_ios',
        'interface',
        'left',
        'navigation',
        'previous',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_back_ios',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'back',
        'chevron',
        'components',
        'direction',
        'disable_ios',
        'interface',
        'ios',
        'left',
        'navigation',
        'previous',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_back_ios_new',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'back',
        'chevron',
        'components',
        'direction',
        'disable_ios',
        'interface',
        'ios',
        'left',
        'navigation',
        'new',
        'previous',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_circle_down',

      categories: ['action'],
      tags: ['arrow', 'circle', 'direction', 'down', 'navigation'],
    },
    {
      name: 'arrow_circle_left',

      categories: ['action'],
      tags: ['arrow', 'circle', 'direction', 'left', 'navigation'],
    },
    {
      name: 'arrow_circle_right',

      categories: ['action'],
      tags: ['arrow', 'circle', 'direction', 'navigation', 'right'],
    },
    {
      name: 'arrow_circle_up',

      categories: ['action'],
      tags: ['arrow', 'circle', 'direction', 'navigation', 'up'],
    },
    {
      name: 'arrow_downward',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'direction',
        'down',
        'downward',
        'interface',
        'navigation',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_drop_down',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'direction',
        'down',
        'drop',
        'interface',
        'navigation',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_drop_down_circle',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'circle',
        'components',
        'direction',
        'down',
        'drop',
        'interface',
        'navigation',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_drop_up',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'direction',
        'drop',
        'interface',
        'navigation',
        'screen',
        'site',
        'ui',
        'up',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_forward',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'arrows',
        'components',
        'direction',
        'forward',
        'interface',
        'navigation',
        'right',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_forward_ios',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'chevron',
        'components',
        'direction',
        'forward',
        'interface',
        'ios',
        'navigation',
        'next',
        'right',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_left',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'direction',
        'interface',
        'left',
        'navigation',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_outward',

      categories: ['action'],
      tags: [
        'app',
        'application',
        'arrow',
        'arrows',
        'components',
        'direction',
        'forward',
        'interface',
        'navigation',
        'right',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_right',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'direction',
        'interface',
        'navigation',
        'right',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'arrow_right_alt',

      categories: ['action'],
      tags: ['alt', 'arrow', 'arrows', 'direction', 'east', 'navigation', 'pointing', 'right'],
    },
    {
      name: 'arrow_upward',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'direction',
        'interface',
        'navigation',
        'screen',
        'site',
        'ui',
        'up',
        'upward',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'art_track',

      categories: ['av'],
      tags: [
        'album',
        'art',
        'artist',
        'audio',
        'image',
        'music',
        'photo',
        'photography',
        'picture',
        'sound',
        'track',
        'tracks',
      ],
    },
    {
      name: 'article',

      categories: ['action'],
      tags: ['article', 'doc', 'document', 'file', 'page', 'paper', 'text', 'writing'],
    },
    {
      name: 'aspect_ratio',

      categories: ['action'],
      tags: ['aspect', 'expand', 'image', 'ratio', 'resize', 'scale', 'size', 'square'],
    },
    {
      name: 'assessment',

      categories: ['action'],
      tags: [
        'analytics',
        'assessment',
        'bar',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'assignment',

      categories: ['action'],
      tags: ['assignment', 'clipboard', 'doc', 'document', 'text', 'writing'],
    },
    {
      name: 'assignment_ind',

      categories: ['action'],
      tags: [
        'account',
        'assignment',
        'clipboard',
        'doc',
        'document',
        'face',
        'ind',
        'people',
        'person',
        'profile',
        'user',
      ],
    },
    {
      name: 'assignment_late',

      categories: ['action'],
      tags: [
        '!',
        'alert',
        'assignment',
        'attention',
        'caution',
        'clipboard',
        'danger',
        'doc',
        'document',
        'error',
        'exclamation',
        'important',
        'late',
        'mark',
        'notification',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'assignment_return',

      categories: ['action'],
      tags: ['arrow', 'assignment', 'back', 'clipboard', 'doc', 'document', 'left', 'retun'],
    },
    {
      name: 'assignment_returned',

      categories: ['action'],
      tags: ['arrow', 'assignment', 'clipboard', 'doc', 'document', 'down', 'returned'],
    },
    {
      name: 'assignment_turned_in',

      categories: ['action'],
      tags: [
        'approve',
        'assignment',
        'check',
        'clipboard',
        'complete',
        'doc',
        'document',
        'done',
        'in',
        'mark',
        'ok',
        'select',
        'tick',
        'turn',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'assist_walker',

      categories: ['social'],
      tags: [
        'accessibility',
        'accessible',
        'assist',
        'body',
        'disability',
        'handicap',
        'help',
        'human',
        'injured',
        'injury',
        'mobility',
        'person',
        'walk',
        'walker',
      ],
    },
    {
      name: 'assistant',

      categories: ['image'],
      tags: [
        'assistant',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'feedback',
        'message',
        'recommendation',
        'speech',
        'star',
        'suggestion',
        'twinkle',
      ],
    },
    {
      name: 'assistant_direction',

      categories: ['navigation'],
      tags: [
        'assistant',
        'destination',
        'direction',
        'location',
        'maps',
        'navigate',
        'navigation',
        'pin',
        'place',
        'right',
        'stop',
      ],
    },
    {
      name: 'assistant_photo',

      categories: ['image'],
      tags: ['assistant', 'flag', 'photo', 'recommendation', 'smart', 'star', 'suggestion'],
    },
    {
      name: 'assured_workload',

      categories: ['action'],
      tags: [
        'assured',
        'compliance',
        'confidential',
        'federal',
        'government',
        'secure',
        'sensitive regulatory',
        'workload',
      ],
    },
    {
      name: 'atm',

      categories: ['maps'],
      tags: [
        'alphabet',
        'atm',
        'automated',
        'bill',
        'card',
        'cart',
        'cash',
        'character',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'font',
        'letter',
        'machine',
        'money',
        'online',
        'pay',
        'payment',
        'shopping',
        'symbol',
        'teller',
        'text',
        'type',
      ],
    },
    {
      name: 'attach_email',

      categories: ['file'],
      tags: [
        'attach',
        'attachment',
        'clip',
        'compose',
        'email',
        'envelop',
        'letter',
        'link',
        'mail',
        'message',
        'send',
      ],
    },
    {
      name: 'attach_file',

      categories: ['editor'],
      tags: ['add', 'attach', 'clip', 'file', 'link', 'mail', 'media'],
    },
    {
      name: 'attach_money',

      categories: ['editor'],
      tags: [
        'attach',
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'symbol',
      ],
    },
    {
      name: 'attachment',

      categories: ['file'],
      tags: ['attach', 'attachment', 'clip', 'compose', 'file', 'image', 'link'],
    },
    {
      name: 'attractions',

      categories: ['maps'],
      tags: ['amusement', 'attractions', 'entertainment', 'ferris', 'fun', 'maps', 'park', 'places', 'wheel'],
    },
    {
      name: 'attribution',

      categories: ['content'],
      tags: ['attribute', 'attribution', 'body', 'copyright', 'copywriter', 'human', 'people', 'person'],
    },
    {
      name: 'audio_file',

      categories: ['av'],
      tags: ['audio', 'doc', 'document', 'key', 'music', 'note', 'sound', 'track'],
    },
    {
      name: 'audiotrack',

      categories: ['image'],
      tags: ['audio', 'audiotrack', 'key', 'music', 'note', 'sound', 'track'],
    },
    {
      name: 'auto_awesome',

      categories: ['image'],
      tags: ['adjust', 'auto', 'awesome', 'edit', 'editing', 'enhance', 'stars'],
    },
    {
      name: 'auto_awesome_mosaic',

      categories: ['image'],
      tags: ['adjust', 'auto', 'awesome', 'collage', 'edit', 'editing', 'enhance', 'image', 'mosaic', 'photo'],
    },
    {
      name: 'auto_awesome_motion',

      categories: ['image'],
      tags: ['adjust', 'auto', 'awesome', 'collage', 'edit', 'editing', 'enhance', 'image', 'motion', 'photo', 'video'],
    },
    {
      name: 'auto_delete',

      categories: ['alert'],
      tags: ['auto', 'bin', 'can', 'clock', 'date', 'delete', 'garbage', 'remove', 'schedule', 'time', 'trash'],
    },
    {
      name: 'auto_fix_high',

      categories: ['image'],
      tags: ['adjust', 'auto', 'edit', 'editing', 'enhance', 'fix', 'high', 'pen', 'stars', 'tool'],
    },
    {
      name: 'auto_fix_normal',

      categories: ['image'],
      tags: ['auto', 'edit', 'erase', 'fix', 'magic', 'modify', 'normal', 'star', 'stars', 'wand'],
    },
    {
      name: 'auto_fix_off',

      categories: ['image'],
      tags: [
        'auto',
        'disabled',
        'edit',
        'enabled',
        'erase',
        'fix',
        'magic',
        'modify',
        'off',
        'on',
        'slash',
        'star',
        'stars',
        'wand',
      ],
    },
    {
      name: 'auto_graph',

      categories: ['editor'],
      tags: [
        'analytics',
        'auto',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'line',
        'measure',
        'metrics',
        'stars',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'auto_mode',

      categories: ['home'],
      tags: [
        'around',
        'arrow',
        'arrows',
        'auto',
        'direction',
        'inprogress',
        'load',
        'loading refresh',
        'mode',
        'navigation',
        'nest',
        'renew',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'auto_stories',

      categories: ['image'],
      tags: ['auto', 'book', 'flipping', 'pages', 'stories'],
    },
    {
      name: 'autofps_select',

      categories: ['image'],
      tags: [
        'A',
        'alphabet',
        'auto',
        'character',
        'font',
        'fps',
        'frame',
        'frequency',
        'letter',
        'per',
        'rate',
        'second',
        'seconds',
        'select',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'autorenew',

      categories: ['action'],
      tags: [
        'around',
        'arrow',
        'arrows',
        'autorenew',
        'cache',
        'cached',
        'direction',
        'inprogress',
        'load',
        'loading refresh',
        'navigation',
        'renew',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'av_timer',

      categories: ['av'],
      tags: ['av', 'clock', 'countdown', 'duration', 'minutes', 'seconds', 'time', 'timer', 'watch'],
    },
    {
      name: 'baby_changing_station',

      categories: ['places'],
      tags: [
        'babies',
        'baby',
        'bathroom',
        'body',
        'changing',
        'child',
        'children',
        'father',
        'human',
        'infant',
        'kids',
        'mother',
        'newborn',
        'people',
        'person',
        'station',
        'toddler',
        'wc',
        'young',
      ],
    },
    {
      name: 'back_hand',

      categories: ['social'],
      tags: ['back', 'fingers', 'gesture', 'hand', 'raised'],
    },
    {
      name: 'backpack',

      categories: ['places'],
      tags: ['back', 'backpack', 'bag', 'book', 'bookbag', 'knapsack', 'pack', 'storage', 'travel'],
    },
    {
      name: 'backspace',

      categories: ['content'],
      tags: ['arrow', 'back', 'backspace', 'cancel', 'clear', 'correct', 'delete', 'erase', 'remove'],
    },
    {
      name: 'backup',

      categories: ['action'],
      tags: ['arrow', 'backup', 'cloud', 'data', 'drive', 'files folders', 'storage', 'up', 'upload'],
    },
    {
      name: 'backup_table',

      categories: ['action'],
      tags: ['backup', 'drive', 'files folders', 'format', 'layout', 'stack', 'storage', 'table'],
    },
    {
      name: 'badge',

      categories: ['maps'],
      tags: [
        'account',
        'avatar',
        'badge',
        'card',
        'certified',
        'employee',
        'face',
        'human',
        'identification',
        'name',
        'people',
        'person',
        'profile',
        'security',
        'user',
        'work',
      ],
    },
    {
      name: 'bakery_dining',

      categories: ['maps'],
      tags: ['bakery', 'bread', 'breakfast', 'brunch', 'croissant', 'dining', 'food'],
    },
    {
      name: 'balance',

      categories: ['action'],
      tags: [
        'balance',
        'equal',
        'equity',
        'impartiality',
        'justice',
        'parity',
        'stability. equilibrium',
        'steadiness',
        'symmetry',
      ],
    },
    {
      name: 'balcony',

      categories: ['places'],
      tags: [
        'architecture',
        'balcony',
        'doors',
        'estate',
        'home',
        'house',
        'maps',
        'out',
        'outside',
        'place',
        'real',
        'residence',
        'residential',
        'stay',
        'terrace',
        'window',
      ],
    },
    {
      name: 'ballot',

      categories: ['content'],
      tags: ['ballot', 'bullet', 'election', 'list', 'point', 'poll', 'vote'],
    },
    {
      name: 'bar_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'batch_prediction',

      categories: ['action'],
      tags: ['batch', 'bulb', 'idea', 'light', 'prediction'],
    },
    {
      name: 'bathroom',

      categories: ['search'],
      tags: [
        'bath',
        'bathroom',
        'closet',
        'home',
        'house',
        'place',
        'plumbing',
        'room',
        'shower',
        'sprinkler',
        'wash',
        'water',
        'wc',
      ],
    },
    {
      name: 'bathtub',

      categories: ['places'],
      tags: ['bath', 'bathing', 'bathroom', 'bathtub', 'home', 'hotel', 'human', 'person', 'shower', 'travel', 'tub'],
    },
    {
      name: 'battery_0_bar',

      categories: ['device'],
      tags: ['0', 'bar', 'battery', 'cell', 'charge', 'mobile', 'power'],
    },
    {
      name: 'battery_1_bar',

      categories: ['device'],
      tags: ['1', 'bar', 'battery', 'cell', 'charge', 'mobile', 'power'],
    },
    {
      name: 'battery_2_bar',

      categories: ['device'],
      tags: ['2', 'bar', 'battery', 'cell', 'charge', 'mobile', 'power'],
    },
    {
      name: 'battery_3_bar',

      categories: ['device'],
      tags: ['3', 'bar', 'battery', 'cell', 'charge', 'mobile', 'power'],
    },
    {
      name: 'battery_4_bar',

      categories: ['device'],
      tags: ['4', 'bar', 'battery', 'cell', 'charge', 'mobile', 'power'],
    },
    {
      name: 'battery_5_bar',

      categories: ['device'],
      tags: ['5', 'bar', 'battery', 'cell', 'charge', 'mobile', 'power'],
    },
    {
      name: 'battery_6_bar',

      categories: ['device'],
      tags: ['6', 'bar', 'battery', 'cell', 'charge', 'mobile', 'power'],
    },
    {
      name: 'battery_alert',

      categories: ['device'],
      tags: [
        '!',
        'alert',
        'attention',
        'battery',
        'caution',
        'cell',
        'charge',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'mobile',
        'notification',
        'power',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'battery_charging_full',

      categories: ['device'],
      tags: ['battery', 'bolt', 'cell', 'charge', 'charging', 'full', 'lightening', 'mobile', 'power', 'thunderbolt'],
    },
    {
      name: 'battery_full',

      categories: ['device'],
      tags: ['battery', 'cell', 'charge', 'full', 'mobile', 'power'],
    },
    {
      name: 'battery_saver',

      categories: ['device'],
      tags: ['+', 'add', 'battery', 'charge', 'charging', 'new', 'plus', 'power', 'saver', 'symbol'],
    },
    {
      name: 'battery_std',

      categories: ['device'],
      tags: ['battery', 'cell', 'charge', 'mobile', 'plus', 'power', 'standard', 'std'],
    },
    {
      name: 'battery_unknown',

      categories: ['device'],
      tags: [
        '?',
        'assistance',
        'battery',
        'cell',
        'charge',
        'help',
        'info',
        'information',
        'mobile',
        'power',
        'punctuation',
        'question mark',
        'support',
        'symbol',
        'unknown',
      ],
    },
    {
      name: 'beach_access',

      categories: ['places'],
      tags: ['access', 'beach', 'places', 'summer', 'sunny', 'umbrella'],
    },
    {
      name: 'bed',

      categories: ['search'],
      tags: [
        'bed',
        'bedroom',
        'double',
        'full',
        'furniture',
        'home',
        'hotel',
        'house',
        'king',
        'night',
        'pillows',
        'queen',
        'rest',
        'room',
        'size',
        'sleep',
      ],
    },
    {
      name: 'bedroom_baby',

      categories: ['search'],
      tags: [
        'babies',
        'baby',
        'bedroom',
        'child',
        'children',
        'home',
        'horse',
        'house',
        'infant',
        'kid',
        'newborn',
        'rocking',
        'room',
        'toddler',
        'young',
      ],
    },
    {
      name: 'bedroom_child',

      categories: ['search'],
      tags: [
        'bed',
        'bedroom',
        'child',
        'children',
        'furniture',
        'home',
        'hotel',
        'house',
        'kid',
        'night',
        'pillows',
        'rest',
        'room',
        'size',
        'sleep',
        'twin',
        'young',
      ],
    },
    {
      name: 'bedroom_parent',

      categories: ['search'],
      tags: [
        'bed',
        'bedroom',
        'double',
        'full',
        'furniture',
        'home',
        'hotel',
        'house',
        'king',
        'night',
        'parent',
        'pillows',
        'queen',
        'rest',
        'room',
        'sizem master',
        'sleep',
      ],
    },
    {
      name: 'bedtime',

      categories: ['image'],
      tags: ['bedtime', 'nightime', 'sleep'],
    },
    {
      name: 'bedtime_off',

      categories: ['image'],
      tags: ['bedtime', 'nightime', 'off', 'sleep'],
    },
    {
      name: 'beenhere',

      categories: ['maps'],
      tags: [
        'approve',
        'archive',
        'beenhere',
        'bookmark',
        'check',
        'complete',
        'done',
        'favorite',
        'label',
        'library',
        'mark',
        'ok',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'select',
        'tag',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'bento',

      categories: ['places'],
      tags: ['bento', 'box', 'dinner', 'food', 'lunch', 'meal', 'restaurant', 'takeout'],
    },
    {
      name: 'bike_scooter',

      categories: ['maps'],
      tags: ['automobile', 'bike', 'car', 'cars', 'maps', 'scooter', 'transportation', 'vehicle', 'vespa'],
    },
    {
      name: 'biotech',

      categories: ['content'],
      tags: ['biotech', 'chemistry', 'laboratory', 'microscope', 'research', 'science', 'technology'],
    },
    {
      name: 'blender',

      categories: ['search'],
      tags: ['appliance', 'blender', 'cooking', 'electric', 'juicer', 'kitchen', 'machine', 'vitamix'],
    },
    {
      name: 'blind',

      categories: ['social'],
      tags: [
        'accessibility',
        'accessible',
        'assist',
        'blind',
        'body',
        'cane',
        'disability',
        'handicap',
        'help',
        'human',
        'mobility',
        'person',
        'walk',
        'walker',
      ],
    },
    {
      name: 'blinds',

      categories: ['home'],
      tags: ['blinds', 'cover', 'curtains', 'nest', 'open', 'shade', 'shutter', 'sunshade'],
    },
    {
      name: 'blinds_closed',

      categories: ['home'],
      tags: ['blinds', 'closed', 'cover', 'curtains', 'nest', 'shade', 'shutter', 'sunshade'],
    },
    {
      name: 'block',

      categories: ['content'],
      tags: ['avoid', 'block', 'cancel', 'close', 'entry', 'exit', 'no', 'prohibited', 'quit', 'remove', 'stop'],
    },
    {
      name: 'bloodtype',

      categories: ['device'],
      tags: [
        'blood',
        'bloodtype',
        'donate',
        'droplet',
        'emergency',
        'hospital',
        'medicine',
        'negative',
        'positive',
        'type',
        'water',
      ],
    },
    {
      name: 'bluetooth',

      categories: ['device'],
      tags: ['bluetooth', 'cast', 'connect', 'connection', 'device', 'paring', 'streaming', 'symbol', 'wireless'],
    },
    {
      name: 'bluetooth_audio',

      categories: ['notification'],
      tags: ['audio', 'bluetooth', 'connect', 'connection', 'device', 'music', 'signal', 'sound', 'symbol'],
    },
    {
      name: 'bluetooth_connected',

      categories: ['device'],
      tags: ['bluetooth', 'cast', 'connect', 'connection', 'device', 'paring', 'streaming', 'symbol', 'wireless'],
    },
    {
      name: 'bluetooth_disabled',

      categories: ['device'],
      tags: [
        'bluetooth',
        'cast',
        'connect',
        'connection',
        'device',
        'disabled',
        'enabled',
        'off',
        'offline',
        'on',
        'paring',
        'slash',
        'streaming',
        'symbol',
        'wireless',
      ],
    },
    {
      name: 'bluetooth_drive',

      categories: ['device'],
      tags: [
        'automobile',
        'bluetooth',
        'car',
        'cars',
        'cast',
        'connect',
        'connection',
        'device',
        'drive',
        'maps',
        'paring',
        'streaming',
        'symbol',
        'transportation',
        'travel',
        'vehicle',
        'wireless',
      ],
    },
    {
      name: 'bluetooth_searching',

      categories: ['device'],
      tags: ['bluetooth', 'connection', 'device', 'paring', 'search', 'searching', 'symbol'],
    },
    {
      name: 'blur_circular',

      categories: ['image'],
      tags: ['blur', 'circle', 'circular', 'dots', 'edit', 'editing', 'effect', 'enhance', 'filter'],
    },
    {
      name: 'blur_linear',

      categories: ['image'],
      tags: ['blur', 'dots', 'edit', 'editing', 'effect', 'enhance', 'filter', 'linear'],
    },
    {
      name: 'blur_off',

      categories: ['image'],
      tags: ['blur', 'disabled', 'dots', 'edit', 'editing', 'effect', 'enabled', 'enhance', 'off', 'on', 'slash'],
    },
    {
      name: 'blur_on',

      categories: ['image'],
      tags: [
        'blur',
        'disabled',
        'dots',
        'edit',
        'editing',
        'effect',
        'enabled',
        'enhance',
        'filter',
        'off',
        'on',
        'slash',
      ],
    },
    {
      name: 'bolt',

      categories: ['content'],
      tags: ['bolt', 'electric', 'energy', 'fast', 'flash', 'lightning', 'power', 'thunderbolt'],
    },
    {
      name: 'book',

      categories: ['action'],
      tags: [
        'book',
        'bookmark',
        'favorite',
        'label',
        'library',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'tag',
      ],
    },
    {
      name: 'book_online',

      categories: ['action'],
      tags: [
        'Android',
        'OS',
        'admission',
        'appointment',
        'book',
        'cell',
        'device',
        'event',
        'hardware',
        'iOS',
        'mobile',
        'online',
        'pass',
        'phone',
        'reservation',
        'tablet',
        'ticket',
      ],
    },
    {
      name: 'bookmark',

      categories: ['action'],
      tags: [
        'archive',
        'bookmark',
        'favorite',
        'label',
        'library',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'tag',
      ],
    },
    {
      name: 'bookmark_add',

      categories: ['action'],
      tags: ['+', 'add', 'bookmark', 'favorite', 'plus', 'remember', 'ribbon', 'save', 'symbol'],
    },
    {
      name: 'bookmark_added',

      categories: ['action'],
      tags: [
        'added',
        'approve',
        'bookmark',
        'check',
        'complete',
        'done',
        'favorite',
        'mark',
        'ok',
        'remember',
        'save',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'bookmark_border',

      categories: ['action'],
      tags: [
        'archive',
        'bookmark',
        'border',
        'favorite',
        'label',
        'library',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'tag',
      ],
    },
    {
      name: 'bookmark_remove',

      categories: ['action'],
      tags: ['bookmark', 'delete', 'favorite', 'minus', 'remember', 'remove', 'ribbon', 'save', 'subtract'],
    },
    {
      name: 'bookmarks',

      categories: ['action'],
      tags: [
        'bookmark',
        'bookmarks',
        'favorite',
        'label',
        'layers',
        'library',
        'multiple',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'stack',
        'tag',
      ],
    },
    {
      name: 'border_all',

      categories: ['editor'],
      tags: [
        'all',
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_bottom',

      categories: ['editor'],
      tags: [
        'border',
        'bottom',
        'doc',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_clear',

      categories: ['editor'],
      tags: [
        'border',
        'clear',
        'doc',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_color',

      categories: ['editor'],
      tags: [
        'all',
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'pen',
        'pencil',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_horizontal',

      categories: ['editor'],
      tags: [
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'horizontal',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_inner',

      categories: ['editor'],
      tags: [
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'inner',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_left',

      categories: ['editor'],
      tags: [
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'left',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_outer',

      categories: ['editor'],
      tags: [
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'outer',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_right',

      categories: ['editor'],
      tags: [
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'right',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_style',

      categories: ['editor'],
      tags: [
        'border',
        'color',
        'doc',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'stroke',
        'style',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_top',

      categories: ['editor'],
      tags: [
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'top',
        'type',
        'writing',
      ],
    },
    {
      name: 'border_vertical',

      categories: ['editor'],
      tags: [
        'border',
        'doc',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'stroke',
        'text',
        'type',
        'vertical',
        'writing',
      ],
    },
    {
      name: 'boy',

      categories: ['social'],
      tags: ['body', 'boy', 'gender', 'human', 'male', 'man', 'people', 'person', 'social', 'symbol'],
    },
    {
      name: 'branding_watermark',

      categories: ['av'],
      tags: [
        'branding',
        'components',
        'copyright',
        'design',
        'emblem',
        'format',
        'identity',
        'interface',
        'layout',
        'logo',
        'screen',
        'site',
        'stamp',
        'ui',
        'ux',
        'watermark',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'breakfast_dining',

      categories: ['maps'],
      tags: ['bakery', 'bread', 'breakfast', 'butter', 'dining', 'food', 'toast'],
    },
    {
      name: 'brightness_1',

      categories: ['image'],
      tags: ['1', 'brightness', 'circle', 'control', 'crescent', 'level', 'moon', 'screen'],
    },
    {
      name: 'brightness_2',

      categories: ['image'],
      tags: ['2', 'brightness', 'circle', 'control', 'crescent', 'level', 'moon', 'screen'],
    },
    {
      name: 'brightness_3',

      categories: ['image'],
      tags: ['3', 'brightness', 'circle', 'control', 'crescent', 'level', 'moon', 'screen'],
    },
    {
      name: 'brightness_4',

      categories: ['image'],
      tags: ['4', 'brightness', 'circle', 'control', 'crescent', 'level', 'moon', 'screen', 'sun'],
    },
    {
      name: 'brightness_5',

      categories: ['image'],
      tags: ['5', 'brightness', 'circle', 'control', 'crescent', 'level', 'moon', 'screen', 'sun'],
    },
    {
      name: 'brightness_6',

      categories: ['image'],
      tags: ['6', 'brightness', 'circle', 'control', 'crescent', 'level', 'moon', 'screen', 'sun'],
    },
    {
      name: 'brightness_7',

      categories: ['image'],
      tags: ['7', 'brightness', 'circle', 'control', 'crescent', 'level', 'moon', 'screen', 'sun'],
    },
    {
      name: 'brightness_auto',

      categories: ['device'],
      tags: ['A', 'auto', 'brightness', 'control', 'display', 'level', 'mobile', 'monitor', 'phone', 'screen', 'sun'],
    },
    {
      name: 'brightness_high',

      categories: ['device'],
      tags: ['auto', 'brightness', 'control', 'high', 'mobile', 'monitor', 'phone', 'sun'],
    },
    {
      name: 'brightness_low',

      categories: ['device'],
      tags: ['auto', 'brightness', 'control', 'low', 'mobile', 'monitor', 'phone', 'sun'],
    },
    {
      name: 'brightness_medium',

      categories: ['device'],
      tags: ['auto', 'brightness', 'control', 'medium', 'mobile', 'monitor', 'phone', 'sun'],
    },
    {
      name: 'broadcast_on_home',

      categories: ['home'],
      tags: [],
    },
    {
      name: 'broadcast_on_personal',

      categories: ['home'],
      tags: [],
    },
    {
      name: 'broken_image',

      categories: ['image'],
      tags: [
        'broken',
        'corrupt',
        'error',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'torn',
      ],
    },
    {
      name: 'browse_gallery',

      categories: ['action'],
      tags: ['clock', 'collection', 'gallery', 'library', 'stack', 'watch'],
    },
    {
      name: 'browser_not_supported',

      categories: ['hardware'],
      tags: [
        'browser',
        'disabled',
        'enabled',
        'internet',
        'not',
        'off',
        'on',
        'page',
        'screen',
        'site',
        'slash',
        'supported',
        'web',
        'website',
        'www',
      ],
    },
    {
      name: 'browser_updated',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'browser',
        'chrome',
        'desktop',
        'device',
        'display',
        'download',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'screen',
        'updated',
        'web',
        'window',
      ],
    },
    {
      name: 'brunch_dining',

      categories: ['maps'],
      tags: ['breakfast', 'brunch', 'champagne', 'dining', 'drink', 'food', 'lunch', 'meal'],
    },
    {
      name: 'brush',

      categories: ['image'],
      tags: ['art', 'brush', 'design', 'draw', 'edit', 'editing', 'paint', 'painting', 'tool'],
    },
    {
      name: 'bubble_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'bubble',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'bug_report',

      categories: ['action'],
      tags: ['animal', 'bug', 'fix', 'insect', 'issue', 'problem', 'report', 'testing', 'virus', 'warning'],
    },
    {
      name: 'build',

      categories: ['action'],
      tags: ['adjust', 'build', 'fix', 'home', 'nest', 'repair', 'tool', 'tools', 'wrench'],
    },
    {
      name: 'build_circle',

      categories: ['action'],
      tags: ['adjust', 'build', 'circle', 'fix', 'repair', 'tool', 'wrench'],
    },
    {
      name: 'bungalow',

      categories: ['places'],
      tags: [
        'architecture',
        'bungalow',
        'cottage',
        'estate',
        'home',
        'house',
        'maps',
        'place',
        'real',
        'residence',
        'residential',
        'stay',
        'traveling',
      ],
    },
    {
      name: 'burst_mode',

      categories: ['image'],
      tags: [
        'burst',
        'image',
        'landscape',
        'mode',
        'mountain',
        'mountains',
        'multiple',
        'photo',
        'photography',
        'picture',
      ],
    },
    {
      name: 'bus_alert',

      categories: ['maps'],
      tags: [
        '!',
        'alert',
        'attention',
        'automobile',
        'bus',
        'car',
        'cars',
        'caution',
        'danger',
        'error',
        'exclamation',
        'important',
        'maps',
        'mark',
        'notification',
        'symbol',
        'transportation',
        'vehicle',
        'warning',
      ],
    },
    {
      name: 'business',

      categories: ['communication'],
      tags: [
        'apartment',
        'architecture',
        'building',
        'business',
        'company',
        'estate',
        'home',
        'place',
        'real',
        'residence',
        'residential',
        'shelter',
      ],
    },
    {
      name: 'business_center',

      categories: ['places'],
      tags: ['bag', 'baggage', 'briefcase', 'business', 'case', 'center', 'places', 'purse', 'suitcase', 'work'],
    },
    {
      name: 'cabin',

      categories: ['places'],
      tags: [
        'architecture',
        'cabin',
        'camping',
        'cottage',
        'estate',
        'home',
        'house',
        'log',
        'maps',
        'place',
        'real',
        'residence',
        'residential',
        'stay',
        'traveling',
        'wood',
      ],
    },
    {
      name: 'cable',

      categories: ['device'],
      tags: ['cable', 'connect', 'connection', 'device', 'electronics', 'usb', 'wire'],
    },
    {
      name: 'cached',

      categories: ['action'],
      tags: ['around', 'arrows', 'cache', 'cached', 'inprogress', 'load', 'loading refresh', 'renew', 'rotate'],
    },
    {
      name: 'cake',

      categories: ['social'],
      tags: [
        'add',
        'baked',
        'birthday',
        'cake',
        'candles',
        'celebration',
        'dessert',
        'food',
        'frosting',
        'new',
        'party',
        'pastries',
        'pastry',
        'plus',
        'social',
        'sweet',
        'symbol',
      ],
    },
    {
      name: 'calculate',

      categories: ['content'],
      tags: ['+', '-', '\u003d', 'calculate', 'count', 'finance calculator', 'math'],
    },
    {
      name: 'calendar_month',

      categories: ['action'],
      tags: ['calendar', 'date', 'day', 'event', 'month', 'schedule', 'today'],
    },
    {
      name: 'calendar_today',

      categories: ['action'],
      tags: ['calendar', 'date', 'day', 'event', 'month', 'schedule', 'today'],
    },
    {
      name: 'calendar_view_day',

      categories: ['action'],
      tags: [
        'calendar',
        'date',
        'day',
        'event',
        'format',
        'grid',
        'layout',
        'month',
        'schedule',
        'today',
        'view',
        'week',
      ],
    },
    {
      name: 'calendar_view_month',

      categories: ['action'],
      tags: ['calendar', 'date', 'day', 'event', 'format', 'grid', 'layout', 'month', 'schedule', 'today', 'view'],
    },
    {
      name: 'calendar_view_week',

      categories: ['action'],
      tags: [
        'calendar',
        'date',
        'day',
        'event',
        'format',
        'grid',
        'layout',
        'month',
        'schedule',
        'today',
        'view',
        'week',
      ],
    },
    {
      name: 'call',

      categories: ['communication'],
      tags: ['call', 'cell', 'contact', 'device', 'hardware', 'mobile', 'phone', 'telephone'],
    },
    {
      name: 'call_end',

      categories: ['communication'],
      tags: ['call', 'cell', 'contact', 'device', 'end', 'hardware', 'mobile', 'phone', 'telephone'],
    },
    {
      name: 'call_made',

      categories: ['communication'],
      tags: ['arrow', 'call', 'device', 'made', 'mobile'],
    },
    {
      name: 'call_merge',

      categories: ['communication'],
      tags: ['arrow', 'call', 'device', 'merge', 'mobile'],
    },
    {
      name: 'call_missed',

      categories: ['communication'],
      tags: ['arrow', 'call', 'device', 'missed', 'mobile'],
    },
    {
      name: 'call_missed_outgoing',

      categories: ['communication'],
      tags: ['arrow', 'call', 'device', 'missed', 'mobile', 'outgoing'],
    },
    {
      name: 'call_received',

      categories: ['communication'],
      tags: ['arrow', 'call', 'device', 'mobile', 'received'],
    },
    {
      name: 'call_split',

      categories: ['communication'],
      tags: ['arrow', 'call', 'device', 'mobile', 'split'],
    },
    {
      name: 'call_to_action',

      categories: ['av'],
      tags: [
        'action',
        'alert',
        'bar',
        'call',
        'components',
        'cta',
        'design',
        'info',
        'information',
        'interface',
        'layout',
        'message',
        'notification',
        'screen',
        'site',
        'to',
        'ui',
        'ux',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'camera',

      categories: ['image'],
      tags: ['aperture', 'camera', 'lens', 'photo', 'photography', 'picture', 'shutter'],
    },
    {
      name: 'camera_alt',

      categories: ['image'],
      tags: ['alt', 'camera', 'image', 'photo', 'photography', 'picture'],
    },
    {
      name: 'camera_enhance',

      categories: ['action'],
      tags: ['camera', 'enhance', 'important', 'lens', 'photo', 'photography', 'picture', 'quality', 'special', 'star'],
    },
    {
      name: 'camera_front',

      categories: ['image'],
      tags: [
        'body',
        'camera',
        'front',
        'human',
        'lens',
        'mobile',
        'person',
        'phone',
        'photography',
        'portrait',
        'selfie',
      ],
    },
    {
      name: 'camera_indoor',

      categories: ['search'],
      tags: [
        'architecture',
        'building',
        'camera',
        'estate',
        'film',
        'filming',
        'home',
        'house',
        'image',
        'indoor',
        'inside',
        'motion',
        'nest',
        'picture',
        'place',
        'real',
        'residence',
        'residential',
        'shelter',
        'video',
        'videography',
      ],
    },
    {
      name: 'camera_outdoor',

      categories: ['search'],
      tags: [
        'architecture',
        'building',
        'camera',
        'estate',
        'film',
        'filming',
        'home',
        'house',
        'image',
        'motion',
        'nest',
        'outdoor',
        'outside',
        'picture',
        'place',
        'real',
        'residence',
        'residential',
        'shelter',
        'video',
        'videography',
      ],
    },
    {
      name: 'camera_rear',

      categories: ['image'],
      tags: [
        'camera',
        'front',
        'lens',
        'mobile',
        'phone',
        'photo',
        'photography',
        'picture',
        'portrait',
        'rear',
        'selfie',
      ],
    },
    {
      name: 'camera_roll',

      categories: ['image'],
      tags: ['camera', 'film', 'image', 'library', 'photo', 'photography', 'roll'],
    },
    {
      name: 'cameraswitch',

      categories: ['device'],
      tags: ['arrows', 'camera', 'cameraswitch', 'flip', 'rotate', 'swap', 'switch', 'view'],
    },
    {
      name: 'campaign',

      categories: ['navigation'],
      tags: ['alert', 'announcement', 'campaign', 'loud', 'megaphone', 'microphone', 'notification', 'speaker'],
    },
    {
      name: 'cancel',

      categories: ['navigation'],
      tags: ['cancel', 'circle', 'close', 'exit', 'stop', 'x'],
    },
    {
      name: 'cancel_presentation',

      categories: ['communication'],
      tags: [
        'cancel',
        'close',
        'device',
        'exit',
        'no',
        'present',
        'presentation',
        'quit',
        'remove',
        'screen',
        'slide',
        'stop',
        'website',
        'window',
        'x',
      ],
    },
    {
      name: 'cancel_schedule_send',

      categories: ['action'],
      tags: ['cancel', 'email', 'mail', 'no', 'quit', 'remove', 'schedule', 'send', 'share', 'stop', 'x'],
    },
    {
      name: 'candlestick_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'candlestick',
        'chart',
        'data',
        'diagram',
        'finance',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'car_crash',

      categories: ['maps'],
      tags: [
        'accident',
        'automobile',
        'car',
        'cars',
        'collision',
        'crash',
        'direction',
        'maps',
        'public',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'car_rental',

      categories: ['maps'],
      tags: ['automobile', 'car', 'cars', 'key', 'maps', 'rental', 'transportation', 'vehicle'],
    },
    {
      name: 'car_repair',

      categories: ['maps'],
      tags: ['automobile', 'car', 'cars', 'maps', 'repair', 'transportation', 'vehicle'],
    },
    {
      name: 'card_giftcard',

      categories: ['action'],
      tags: [
        'account',
        'balance',
        'bill',
        'card',
        'cart',
        'cash',
        'certificate',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'gift',
        'giftcard',
        'money',
        'online',
        'pay',
        'payment',
        'present',
        'shopping',
      ],
    },
    {
      name: 'card_membership',

      categories: ['action'],
      tags: [
        'bill',
        'bookmark',
        'card',
        'cash',
        'certificate',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'loyalty',
        'membership',
        'money',
        'online',
        'pay',
        'payment',
        'shopping',
        'subscription',
      ],
    },
    {
      name: 'card_travel',

      categories: ['action'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'membership',
        'miles',
        'money',
        'online',
        'pay',
        'payment',
        'travel',
        'trip',
      ],
    },
    {
      name: 'carpenter',

      categories: ['places'],
      tags: ['building', 'carpenter', 'construction', 'cutting', 'handyman', 'repair', 'saw', 'tool'],
    },
    {
      name: 'cases',

      categories: ['image'],
      tags: ['bag', 'baggage', 'briefcase', 'business', 'case', 'cases', 'purse', 'suitcase'],
    },
    {
      name: 'casino',

      categories: ['places'],
      tags: ['casino', 'dice', 'dots', 'entertainment', 'gamble', 'gambling', 'game', 'games', 'luck', 'places'],
    },
    {
      name: 'cast',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'airplay',
        'cast',
        'chrome',
        'connect',
        'desktop',
        'device',
        'display',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'screen',
        'screencast',
        'streaming',
        'television',
        'tv',
        'web',
        'window',
        'wireless',
      ],
    },
    {
      name: 'cast_connected',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'airplay',
        'cast',
        'chrome',
        'connect',
        'connected',
        'desktop',
        'device',
        'display',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'screen',
        'screencast',
        'streaming',
        'television',
        'tv',
        'web',
        'window',
        'wireless',
      ],
    },
    {
      name: 'cast_for_education',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'airplay',
        'cast',
        'chrome',
        'connect',
        'desktop',
        'device',
        'display',
        'education',
        'for',
        'hardware',
        'iOS',
        'learning',
        'lessons teaching',
        'mac',
        'monitor',
        'screen',
        'screencast',
        'streaming',
        'television',
        'tv',
        'web',
        'window',
        'wireless',
      ],
    },
    {
      name: 'castle',

      categories: ['maps'],
      tags: ['castle', 'fort', 'fortress', 'mansion', 'palace'],
    },
    {
      name: 'catching_pokemon',

      categories: ['social'],
      tags: ['catching', 'go', 'pokemon', 'pokestop', 'travel'],
    },
    {
      name: 'category',

      categories: ['maps'],
      tags: ['categories', 'category', 'circle', 'collection', 'items', 'product', 'sort', 'square', 'triangle'],
    },
    {
      name: 'celebration',

      categories: ['maps'],
      tags: ['activity', 'birthday', 'celebration', 'event', 'fun', 'party'],
    },
    {
      name: 'cell_tower',

      categories: ['communication'],
      tags: ['broadcast', 'casting', 'cell', 'network', 'signal', 'tower', 'transmitting', 'wireless'],
    },
    {
      name: 'cell_wifi',

      categories: ['communication'],
      tags: [
        'cell',
        'connection',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'center_focus_strong',

      categories: ['image'],
      tags: ['camera', 'center', 'focus', 'image', 'lens', 'photo', 'photography', 'strong', 'zoom'],
    },
    {
      name: 'center_focus_weak',

      categories: ['image'],
      tags: ['camera', 'center', 'focus', 'image', 'lens', 'photo', 'photography', 'weak', 'zoom'],
    },
    {
      name: 'chair',

      categories: ['search'],
      tags: [
        'chair',
        'comfort',
        'couch',
        'decoration',
        'furniture',
        'home',
        'house',
        'living',
        'lounging',
        'loveseat',
        'room',
        'seat',
        'seating',
        'sofa',
      ],
    },
    {
      name: 'chair_alt',

      categories: ['search'],
      tags: ['cahir', 'furniture', 'home', 'house', 'kitchen', 'lounging', 'seating', 'table'],
    },
    {
      name: 'chalet',

      categories: ['places'],
      tags: [
        'architecture',
        'chalet',
        'cottage',
        'estate',
        'home',
        'house',
        'maps',
        'place',
        'real',
        'residence',
        'residential',
        'stay',
        'traveling',
      ],
    },
    {
      name: 'change_circle',

      categories: ['content'],
      tags: ['around', 'arrows', 'change', 'circle', 'direction', 'navigation', 'rotate'],
    },
    {
      name: 'change_history',

      categories: ['action'],
      tags: ['change', 'history', 'shape', 'triangle'],
    },
    {
      name: 'charging_station',

      categories: ['places'],
      tags: [
        'Android',
        'OS',
        'battery',
        'bolt',
        'cell',
        'charging',
        'device',
        'electric',
        'hardware',
        'iOS',
        'lightning',
        'mobile',
        'phone',
        'station',
        'tablet',
        'thunderbolt',
      ],
    },
    {
      name: 'chat',

      categories: ['communication'],
      tags: ['bubble', 'chat', 'comment', 'communicate', 'feedback', 'message', 'speech'],
    },
    {
      name: 'chat_bubble',

      categories: ['communication'],
      tags: ['bubble', 'chat', 'comment', 'communicate', 'feedback', 'message', 'speech'],
    },
    {
      name: 'chat_bubble_outline',

      categories: ['communication'],
      tags: ['bubble', 'chat', 'comment', 'communicate', 'feedback', 'message', 'outline', 'speech'],
    },
    {
      name: 'check',

      categories: ['navigation'],
      tags: [
        'check',
        'confirm',
        'correct',
        'disable_ios',
        'done',
        'enter',
        'mark',
        'ok',
        'okay',
        'select',
        'tick',
        'yes',
      ],
    },
    {
      name: 'check_box',

      categories: ['toggle'],
      tags: [
        'approved',
        'box',
        'button',
        'check',
        'component',
        'control',
        'form',
        'mark',
        'ok',
        'select',
        'selected',
        'selection',
        'tick',
        'toggle',
        'ui',
        'yes',
      ],
    },
    {
      name: 'check_box_outline_blank',

      categories: ['toggle'],
      tags: [
        'blank',
        'box',
        'button',
        'check',
        'component',
        'control',
        'deselected',
        'empty',
        'form',
        'outline',
        'select',
        'selection',
        'square',
        'tick',
        'toggle',
        'ui',
      ],
    },
    {
      name: 'check_circle',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'circle',
        'complete',
        'done',
        'mark',
        'ok',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'check_circle_outline',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'circle',
        'complete',
        'done',
        'finished',
        'mark',
        'ok',
        'outline',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'checklist',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'approve',
        'check',
        'checklist',
        'complete',
        'doc',
        'done',
        'edit',
        'editing',
        'editor',
        'format',
        'list',
        'mark',
        'notes',
        'ok',
        'select',
        'sheet',
        'spreadsheet',
        'text',
        'tick',
        'type',
        'validate',
        'verified',
        'writing',
        'yes',
      ],
    },
    {
      name: 'checklist_rtl',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'approve',
        'check',
        'checklist',
        'complete',
        'doc',
        'done',
        'edit',
        'editing',
        'editor',
        'format',
        'list',
        'mark',
        'notes',
        'ok',
        'rtl',
        'select',
        'sheet',
        'spreadsheet',
        'text',
        'tick',
        'type',
        'validate',
        'verified',
        'writing',
        'yes',
      ],
    },
    {
      name: 'checkroom',

      categories: ['places'],
      tags: ['checkroom', 'closet', 'clothes', 'coat check', 'hanger'],
    },
    {
      name: 'chevron_left',

      categories: ['navigation'],
      tags: ['arrow', 'arrows', 'chevron', 'direction', 'disable_ios', 'left'],
    },
    {
      name: 'chevron_right',

      categories: ['navigation'],
      tags: ['arrow', 'arrows', 'chevron', 'direction', 'right'],
    },
    {
      name: 'child_care',

      categories: ['places'],
      tags: ['babies', 'baby', 'care', 'child', 'children', 'face', 'infant', 'kids', 'newborn', 'toddler', 'young'],
    },
    {
      name: 'child_friendly',

      categories: ['places'],
      tags: [
        'baby',
        'care',
        'carriage',
        'child',
        'children',
        'friendly',
        'infant',
        'kid',
        'newborn',
        'stroller',
        'toddler',
        'young',
      ],
    },
    {
      name: 'chrome_reader_mode',

      categories: ['action'],
      tags: ['chrome', 'mode', 'read', 'reader', 'text'],
    },
    {
      name: 'church',

      categories: ['maps'],
      tags: ['christian', 'christianity', 'religion', 'spiritual', 'worship'],
    },
    {
      name: 'circle',

      categories: ['image'],
      tags: ['circle', 'full', 'geometry', 'moon'],
    },
    {
      name: 'circle_notifications',

      categories: ['action'],
      tags: [
        'active',
        'alarm',
        'alert',
        'bell',
        'chime',
        'circle',
        'notifications',
        'notify',
        'reminder',
        'ring',
        'sound',
      ],
    },
    {
      name: 'class',

      categories: ['action'],
      tags: [
        'archive',
        'book',
        'bookmark',
        'class',
        'favorite',
        'label',
        'library',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'tag',
      ],
    },
    {
      name: 'clean_hands',

      categories: ['social'],
      tags: ['bacteria', 'clean', 'disinfect', 'germs', 'gesture', 'hand', 'hands', 'sanitize', 'sanitizer'],
    },
    {
      name: 'cleaning_services',

      categories: ['maps'],
      tags: ['clean', 'cleaning', 'dust', 'services', 'sweep'],
    },
    {
      name: 'clear',

      categories: ['content'],
      tags: ['back', 'cancel', 'clear', 'correct', 'delete', 'erase', 'exit', 'x'],
    },
    {
      name: 'clear_all',

      categories: ['communication'],
      tags: ['all', 'clear', 'doc', 'document', 'format', 'lines', 'list'],
    },
    {
      name: 'close',

      categories: ['navigation'],
      tags: ['cancel', 'close', 'exit', 'stop', 'x'],
    },
    {
      name: 'close_fullscreen',

      categories: ['action'],
      tags: ['action', 'arrow', 'arrows', 'close', 'collapse', 'direction', 'full', 'fullscreen', 'minimize', 'screen'],
    },
    {
      name: 'closed_caption',

      categories: ['av'],
      tags: [
        'accessible',
        'alphabet',
        'caption',
        'cc',
        'character',
        'closed',
        'decoder',
        'font',
        'language',
        'letter',
        'media',
        'movies',
        'subtitle',
        'subtitles',
        'symbol',
        'text',
        'tv',
        'type',
      ],
    },
    {
      name: 'closed_caption_disabled',

      categories: ['av'],
      tags: [
        'accessible',
        'alphabet',
        'caption',
        'cc',
        'character',
        'closed',
        'decoder',
        'disabled',
        'enabled',
        'font',
        'language',
        'letter',
        'media',
        'movies',
        'off',
        'on',
        'slash',
        'subtitle',
        'subtitles',
        'symbol',
        'text',
        'tv',
        'type',
      ],
    },
    {
      name: 'closed_caption_off',

      categories: ['av'],
      tags: [
        'accessible',
        'alphabet',
        'caption',
        'cc',
        'character',
        'closed',
        'decoder',
        'font',
        'language',
        'letter',
        'media',
        'movies',
        'off',
        'outline',
        'subtitle',
        'subtitles',
        'symbol',
        'text',
        'tv',
        'type',
      ],
    },
    {
      name: 'cloud',

      categories: ['file'],
      tags: ['cloud', 'connection', 'internet', 'network', 'sky', 'upload'],
    },
    {
      name: 'cloud_circle',

      categories: ['file'],
      tags: [
        'app',
        'application',
        'backup',
        'circle',
        'cloud',
        'connection',
        'drive',
        'files',
        'folders',
        'internet',
        'network',
        'sky',
        'storage',
        'upload',
      ],
    },
    {
      name: 'cloud_done',

      categories: ['file'],
      tags: [
        'app',
        'application',
        'approve',
        'backup',
        'check',
        'cloud',
        'complete',
        'connection',
        'done',
        'drive',
        'files',
        'folders',
        'internet',
        'mark',
        'network',
        'ok',
        'select',
        'sky',
        'storage',
        'tick',
        'upload',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'cloud_download',

      categories: ['file'],
      tags: [
        'app',
        'application',
        'arrow',
        'backup',
        'cloud',
        'connection',
        'down',
        'download',
        'drive',
        'files',
        'folders',
        'internet',
        'network',
        'sky',
        'storage',
        'upload',
      ],
    },
    {
      name: 'cloud_off',

      categories: ['file'],
      tags: [
        'app',
        'application',
        'backup',
        'cloud',
        'connection',
        'disabled',
        'drive',
        'enabled',
        'files',
        'folders',
        'internet',
        'network',
        'off',
        'offline',
        'on',
        'sky',
        'slash',
        'storage',
        'upload',
      ],
    },
    {
      name: 'cloud_queue',

      categories: ['file'],
      tags: ['cloud', 'connection', 'internet', 'network', 'queue', 'sky', 'upload'],
    },
    {
      name: 'cloud_sync',

      categories: ['file'],
      tags: [
        'app',
        'application',
        'around',
        'backup',
        'cloud',
        'connection',
        'drive',
        'files',
        'folders',
        'inprogress',
        'internet',
        'load',
        'loading refresh',
        'network',
        'renew',
        'rotate',
        'sky',
        'storage',
        'turn',
        'upload',
      ],
    },
    {
      name: 'cloud_upload',

      categories: ['file'],
      tags: [
        'app',
        'application',
        'arrow',
        'backup',
        'cloud',
        'connection',
        'download',
        'drive',
        'files',
        'folders',
        'internet',
        'network',
        'sky',
        'storage',
        'up',
        'upload',
      ],
    },
    {
      name: 'co2',

      categories: ['social'],
      tags: ['carbon', 'chemical', 'co2', 'dioxide', 'gas'],
    },
    {
      name: 'co_present',

      categories: ['communication'],
      tags: ['arrow', 'co-present', 'presentation', 'screen', 'share', 'site', 'slides', 'togather', 'web', 'website'],
    },
    {
      name: 'code',

      categories: ['action'],
      tags: ['brackets', 'code', 'css', 'develop', 'developer', 'engineer', 'engineering', 'html', 'platform'],
    },
    {
      name: 'code_off',

      categories: ['action'],
      tags: [
        'brackets',
        'code',
        'css',
        'develop',
        'developer',
        'disabled',
        'enabled',
        'engineer',
        'engineering',
        'html',
        'off',
        'on',
        'platform',
        'slash',
      ],
    },
    {
      name: 'coffee',

      categories: ['search'],
      tags: ['beverage', 'coffee', 'cup', 'drink', 'mug', 'plate', 'set', 'tea'],
    },
    {
      name: 'coffee_maker',

      categories: ['search'],
      tags: ['appliances', 'beverage', 'coffee', 'cup', 'drink', 'machine', 'maker', 'mug'],
    },
    {
      name: 'collections',

      categories: ['image'],
      tags: [
        'album',
        'collections',
        'gallery',
        'image',
        'landscape',
        'library',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'stack',
      ],
    },
    {
      name: 'collections_bookmark',

      categories: ['image'],
      tags: [
        'album',
        'archive',
        'bookmark',
        'collections',
        'favorite',
        'gallery',
        'label',
        'library',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'stack',
        'tag',
      ],
    },
    {
      name: 'color_lens',

      categories: ['image'],
      tags: ['art', 'color', 'lens', 'paint', 'pallet'],
    },
    {
      name: 'colorize',

      categories: ['image'],
      tags: ['color', 'colorize', 'dropper', 'extract', 'eye', 'picker', 'tool'],
    },
    {
      name: 'comment',

      categories: ['communication'],
      tags: ['bubble', 'chat', 'comment', 'communicate', 'feedback', 'message', 'outline', 'speech'],
    },
    {
      name: 'comment_bank',

      categories: ['action'],
      tags: [
        'archive',
        'bank',
        'bookmark',
        'bubble',
        'cchat',
        'comment',
        'communicate',
        'favorite',
        'label',
        'library',
        'message',
        'remember',
        'ribbon',
        'save',
        'speech',
        'tag',
      ],
    },
    {
      name: 'comments_disabled',

      categories: ['communication'],
      tags: [
        'bubble',
        'chat',
        'comment',
        'comments',
        'communicate',
        'disabled',
        'enabled',
        'feedback',
        'message',
        'off',
        'offline',
        'on',
        'slash',
        'speech',
      ],
    },
    {
      name: 'commit',

      categories: ['action'],
      tags: ['accomplish', 'bind', 'circle', 'commit', 'dedicate', 'execute', 'line', 'perform', 'pledge'],
    },
    {
      name: 'commute',

      categories: ['action'],
      tags: [
        'automobile',
        'car',
        'commute',
        'direction',
        'maps',
        'public',
        'train',
        'transportation',
        'trip',
        'vehicle',
      ],
    },
    {
      name: 'compare',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustment',
        'compare',
        'edit',
        'editing',
        'edits',
        'enhance',
        'fix',
        'image',
        'images',
        'photo',
        'photography',
        'photos',
        'scan',
        'settings',
      ],
    },
    {
      name: 'compare_arrows',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'collide', 'compare', 'direction', 'left', 'pressure', 'push', 'right', 'together'],
    },
    {
      name: 'compass_calibration',

      categories: ['maps'],
      tags: [
        'calibration',
        'compass',
        'connection',
        'internet',
        'location',
        'maps',
        'network',
        'refresh',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'compost',

      categories: ['social'],
      tags: [
        'bio',
        'compost',
        'compostable',
        'decomposable',
        'decompose',
        'eco',
        'green',
        'leaf',
        'leafs',
        'nature',
        'organic',
        'plant',
        'recycle',
        'sustainability',
        'sustainable',
      ],
    },
    {
      name: 'compress',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'collide', 'compress', 'pressure', 'push', 'together'],
    },
    {
      name: 'computer',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'computer',
        'desktop',
        'device',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'web',
        'window',
      ],
    },
    {
      name: 'confirmation_number',

      categories: ['notification'],
      tags: ['admission', 'confirmation', 'entertainment', 'event', 'number', 'ticket'],
    },
    {
      name: 'connect_without_contact',

      categories: ['social'],
      tags: ['communicating', 'connect', 'contact', 'distance', 'people', 'signal', 'social', 'socialize', 'without'],
    },
    {
      name: 'connected_tv',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'airplay',
        'chrome',
        'connect',
        'connected',
        'desktop',
        'device',
        'display',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'screen',
        'screencast',
        'streaming',
        'television',
        'tv',
        'web',
        'window',
        'wireless',
      ],
    },
    {
      name: 'connecting_airports',

      categories: ['maps'],
      tags: [
        'airplane',
        'airplanes',
        'airport',
        'airports',
        'connecting',
        'flight',
        'plane',
        'transportation',
        'travel',
        'trip',
      ],
    },
    {
      name: 'construction',

      categories: ['social'],
      tags: [
        'build',
        'carpenter',
        'construction',
        'equipment',
        'fix',
        'hammer',
        'improvement',
        'industrial',
        'industry',
        'repair',
        'tools',
        'wrench',
      ],
    },
    {
      name: 'contact_emergency',

      categories: ['communication'],
      tags: [
        'account',
        'avatar',
        'call',
        'cell',
        'contacts',
        'face',
        'human',
        'info',
        'information',
        'mobile',
        'people',
        'person',
        'phone',
        'profile',
        'user',
      ],
    },
    {
      name: 'contact_mail',

      categories: ['communication'],
      tags: [
        'account',
        'address',
        'avatar',
        'communicate',
        'contact',
        'email',
        'face',
        'human',
        'info',
        'information',
        'mail',
        'message',
        'people',
        'person',
        'profile',
        'user',
      ],
    },
    {
      name: 'contact_page',

      categories: ['action'],
      tags: [
        'account',
        'avatar',
        'contact',
        'data',
        'doc',
        'document',
        'drive',
        'face',
        'file',
        'folder',
        'folders',
        'human',
        'page',
        'people',
        'person',
        'profile',
        'sheet',
        'slide',
        'storage',
        'user',
        'writing',
      ],
    },
    {
      name: 'contact_phone',

      categories: ['communication'],
      tags: [
        'account',
        'avatar',
        'call',
        'communicate',
        'contact',
        'face',
        'human',
        'info',
        'information',
        'message',
        'mobile',
        'people',
        'person',
        'phone',
        'profile',
        'user',
      ],
    },
    {
      name: 'contact_support',

      categories: ['action'],
      tags: [
        '?',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'contact',
        'help',
        'info',
        'information',
        'mark',
        'message',
        'punctuation',
        'question',
        'question mark',
        'speech',
        'support',
        'symbol',
      ],
    },
    {
      name: 'contactless',

      categories: ['action'],
      tags: [
        'bluetooth',
        'cash',
        'connect',
        'connection',
        'connectivity',
        'contact',
        'contactless',
        'credit',
        'device',
        'finance',
        'pay',
        'payment',
        'signal',
        'transaction',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'contacts',

      categories: ['communication'],
      tags: [
        'account',
        'avatar',
        'call',
        'cell',
        'contacts',
        'face',
        'human',
        'info',
        'information',
        'mobile',
        'people',
        'person',
        'phone',
        'profile',
        'user',
      ],
    },
    {
      name: 'content_copy',

      categories: ['content'],
      tags: ['content', 'copy', 'cut', 'doc', 'document', 'duplicate', 'file', 'multiple', 'past'],
    },
    {
      name: 'content_cut',

      categories: ['content'],
      tags: ['content', 'copy', 'cut', 'doc', 'document', 'file', 'past', 'scissors', 'trim'],
    },
    {
      name: 'content_paste',

      categories: ['content'],
      tags: ['clipboard', 'content', 'copy', 'cut', 'doc', 'document', 'file', 'multiple', 'past'],
    },
    {
      name: 'content_paste_go',

      categories: ['content'],
      tags: ['clipboard', 'content', 'disabled', 'doc', 'document', 'enabled', 'file', 'go', 'on', 'paste', 'slash'],
    },
    {
      name: 'content_paste_off',

      categories: ['content'],
      tags: ['clipboard', 'content', 'disabled', 'doc', 'document', 'enabled', 'file', 'off', 'on', 'paste', 'slash'],
    },
    {
      name: 'content_paste_search',

      categories: ['content'],
      tags: ['clipboard', 'content', 'doc', 'document', 'file', 'find', 'paste', 'search', 'trace', 'track'],
    },
    {
      name: 'contrast',

      categories: ['image'],
      tags: [
        'black',
        'contrast',
        'edit',
        'editing',
        'effect',
        'filter',
        'grayscale',
        'image',
        'images',
        'photography',
        'picture',
        'pictures',
        'settings',
        'white',
      ],
    },
    {
      name: 'control_camera',

      categories: ['av'],
      tags: ['adjust', 'arrow', 'arrows', 'camera', 'center', 'control', 'direction', 'left', 'move', 'right'],
    },
    {
      name: 'control_point',

      categories: ['image'],
      tags: ['+', 'add', 'circle', 'control', 'plus', 'point'],
    },
    {
      name: 'control_point_duplicate',

      categories: ['image'],
      tags: ['+', 'add', 'circle', 'control', 'duplicate', 'multiple', 'new', 'plus', 'point', 'symbol'],
    },
    {
      name: 'cookie',

      categories: ['social'],
      tags: ['biscuit', 'cookies', 'dessert', 'wafer'],
    },
    {
      name: 'copy_all',

      categories: ['content'],
      tags: ['all', 'content', 'copy', 'cut', 'doc', 'document', 'file', 'multiple', 'page', 'paper', 'past'],
    },
    {
      name: 'copyright',

      categories: ['action'],
      tags: ['alphabet', 'c', 'character', 'copyright', 'emblem', 'font', 'legal', 'letter', 'owner', 'symbol', 'text'],
    },
    {
      name: 'coronavirus',

      categories: ['social'],
      tags: ['19', 'bacteria', 'coronavirus', 'covid', 'disease', 'germs', 'illness', 'sick', 'social'],
    },
    {
      name: 'corporate_fare',

      categories: ['places'],
      tags: [
        'architecture',
        'building',
        'business',
        'corporate',
        'estate',
        'fare',
        'organization',
        'place',
        'real',
        'residence',
        'residential',
        'shelter',
      ],
    },
    {
      name: 'cottage',

      categories: ['places'],
      tags: [
        'architecture',
        'beach',
        'cottage',
        'estate',
        'home',
        'house',
        'lake',
        'lodge',
        'maps',
        'place',
        'real',
        'residence',
        'residential',
        'stay',
        'traveling',
      ],
    },
    {
      name: 'countertops',

      categories: ['places'],
      tags: ['counter', 'countertops', 'home', 'house', 'kitchen', 'sink', 'table', 'tops'],
    },
    {
      name: 'create',

      categories: ['content'],
      tags: ['compose', 'create', 'edit', 'editing', 'input', 'new', 'pen', 'pencil', 'write', 'writing'],
    },
    {
      name: 'create_new_folder',

      categories: ['file'],
      tags: [
        '+',
        'add',
        'create',
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'new',
        'plus',
        'sheet',
        'slide',
        'storage',
        'symbol',
      ],
    },
    {
      name: 'credit_card',

      categories: ['action'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'credit_card_off',

      categories: ['action'],
      tags: [
        'card',
        'charge',
        'commerce',
        'cost',
        'credit',
        'disabled',
        'enabled',
        'finance',
        'money',
        'off',
        'online',
        'pay',
        'payment',
        'slash',
      ],
    },
    {
      name: 'credit_score',

      categories: ['device'],
      tags: [
        'approve',
        'bill',
        'card',
        'cash',
        'check',
        'coin',
        'commerce',
        'complete',
        'cost',
        'credit',
        'currency',
        'dollars',
        'done',
        'finance',
        'loan',
        'mark',
        'money',
        'ok',
        'online',
        'pay',
        'payment',
        'score',
        'select',
        'symbol',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'crib',

      categories: ['places'],
      tags: [
        'babies',
        'baby',
        'bassinet',
        'bed',
        'child',
        'children',
        'cradle',
        'crib',
        'infant',
        'kid',
        'newborn',
        'sleeping',
        'toddler',
      ],
    },
    {
      name: 'crisis_alert',

      categories: ['maps'],
      tags: [
        '!',
        'alert',
        'attention',
        'bullseye',
        'caution',
        'crisis',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'notification',
        'symbol',
        'target',
        'warning',
      ],
    },
    {
      name: 'crop',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustments',
        'area',
        'crop',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'rectangle',
        'settings',
        'size',
        'square',
      ],
    },
    {
      name: 'crop_16_9',

      categories: ['image'],
      tags: [
        '16',
        '9',
        'adjust',
        'adjustments',
        'area',
        'by',
        'crop',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'rectangle',
        'settings',
        'size',
        'square',
      ],
    },
    {
      name: 'crop_3_2',

      categories: ['image'],
      tags: [
        '2',
        '3',
        'adjust',
        'adjustments',
        'area',
        'by',
        'crop',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'rectangle',
        'settings',
        'size',
        'square',
      ],
    },
    {
      name: 'crop_5_4',

      categories: ['image'],
      tags: [
        '4',
        '5',
        'adjust',
        'adjustments',
        'area',
        'by',
        'crop',
        'edit',
        'editing settings',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'rectangle',
        'size',
        'square',
      ],
    },
    {
      name: 'crop_7_5',

      categories: ['image'],
      tags: [
        '5',
        '7',
        'adjust',
        'adjustments',
        'area',
        'by',
        'crop',
        'editing',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'rectangle',
        'settings',
        'size',
        'square',
      ],
    },
    {
      name: 'crop_din',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustments',
        'area',
        'crop',
        'din',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'rectangle',
        'settings',
        'size',
        'square',
      ],
    },
    {
      name: 'crop_free',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustments',
        'crop',
        'edit',
        'editing',
        'focus',
        'frame',
        'free',
        'image',
        'photo',
        'photos',
        'settings',
        'size',
        'zoom',
      ],
    },
    {
      name: 'crop_landscape',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustments',
        'area',
        'crop',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'landscape',
        'photo',
        'photos',
        'settings',
        'size',
      ],
    },
    {
      name: 'crop_original',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustments',
        'area',
        'crop',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'original',
        'photo',
        'photos',
        'picture',
        'settings',
        'size',
      ],
    },
    {
      name: 'crop_portrait',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustments',
        'area',
        'crop',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'portrait',
        'rectangle',
        'settings',
        'size',
        'square',
      ],
    },
    {
      name: 'crop_rotate',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustments',
        'area',
        'arrow',
        'arrows',
        'crop',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'rotate',
        'settings',
        'size',
        'turn',
      ],
    },
    {
      name: 'crop_square',

      categories: ['image'],
      tags: [
        'adjust',
        'adjustments',
        'area',
        'crop',
        'edit',
        'editing',
        'frame',
        'image',
        'images',
        'photo',
        'photos',
        'rectangle',
        'settings',
        'size',
        'square',
      ],
    },
    {
      name: 'cruelty_free',

      categories: ['social'],
      tags: [
        'animal',
        'bunny',
        'cruelty',
        'eco',
        'free',
        'nature',
        'rabbit',
        'social',
        'sustainability',
        'sustainable',
        'testing',
      ],
    },
    {
      name: 'css',

      categories: ['action'],
      tags: [
        'alphabet',
        'brackets',
        'character',
        'code',
        'css',
        'develop',
        'developer',
        'engineer',
        'engineering',
        'font',
        'html',
        'letter',
        'platform',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'currency_bitcoin',

      categories: ['image'],
      tags: [
        'bill',
        'blockchain',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'digital',
        'dollars',
        'finance',
        'franc',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'currency_exchange',

      categories: ['action'],
      tags: [
        '360',
        'around',
        'arrow',
        'arrows',
        'cash',
        'coin',
        'commerce',
        'currency',
        'direction',
        'dollars',
        'exchange',
        'inprogress',
        'money',
        'pay',
        'renew',
        'rotate',
        'sync',
        'turn',
        'universal',
      ],
    },
    {
      name: 'currency_franc',

      categories: ['image'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'franc',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'currency_lira',

      categories: ['image'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'lira',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'currency_pound',

      categories: ['image'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'pound',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'currency_ruble',

      categories: ['image'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'ruble',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'currency_rupee',

      categories: ['image'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'rupee',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'currency_yen',

      categories: ['image'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
        'yen',
      ],
    },
    {
      name: 'currency_yuan',

      categories: ['image'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
        'yuan',
      ],
    },
    {
      name: 'curtains',

      categories: ['home'],
      tags: ['blinds', 'cover', 'curtains', 'nest', 'open', 'shade', 'shutter', 'sunshade'],
    },
    {
      name: 'curtains_closed',

      categories: ['home'],
      tags: ['blinds', 'closed', 'cover', 'curtains', 'nest', 'shade', 'shutter', 'sunshade'],
    },
    {
      name: 'cyclone',

      categories: ['social'],
      tags: ['crisis', 'disaster', 'natural', 'rain', 'storm', 'weather', 'wind', 'winds'],
    },
    {
      name: 'dangerous',

      categories: ['action'],
      tags: ['broken', 'danger', 'dangerous', 'fix', 'no', 'sign', 'stop', 'update', 'warning', 'wrong', 'x'],
    },
    {
      name: 'dark_mode',

      categories: ['device'],
      tags: [
        'app',
        'application',
        'dark',
        'device',
        'interface',
        'mode',
        'moon',
        'night',
        'silent',
        'theme',
        'ui',
        'ux',
        'website',
      ],
    },
    {
      name: 'dashboard',

      categories: ['action'],
      tags: ['cards', 'dashboard', 'format', 'layout', 'rectangle', 'shapes', 'square', 'web', 'website'],
    },
    {
      name: 'dashboard_customize',

      categories: ['action'],
      tags: ['cards', 'customize', 'dashboard', 'format', 'layout', 'rectangle', 'shapes', 'square', 'web', 'website'],
    },
    {
      name: 'data_array',

      categories: ['editor'],
      tags: ['array', 'brackets', 'code', 'coder', 'data', 'parentheses'],
    },
    {
      name: 'data_exploration',

      categories: ['action'],
      tags: [
        'analytics',
        'arrow',
        'chart',
        'data',
        'diagram',
        'exploration',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'data_object',

      categories: ['editor'],
      tags: ['brackets', 'code', 'coder', 'data', 'object', 'parentheses'],
    },
    {
      name: 'data_saver_off',

      categories: ['device'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'donut',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'off',
        'on',
        'ring',
        'saver',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'data_saver_on',

      categories: ['device'],
      tags: [
        '+',
        'add',
        'analytics',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'new',
        'on',
        'plus',
        'ring',
        'saver',
        'statistics',
        'symbol',
        'tracking',
      ],
    },
    {
      name: 'data_thresholding',

      categories: ['action'],
      tags: ['data', 'hidden', 'privacy', 'thresholding', 'thresold'],
    },
    {
      name: 'data_usage',

      categories: ['device'],
      tags: [
        'analytics',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
        'usage',
      ],
    },
    {
      name: 'dataset',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'dataset_linked',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'date_range',

      categories: ['action'],
      tags: [
        'calendar',
        'date',
        'day',
        'event',
        'month',
        'range',
        'remember',
        'reminder',
        'schedule',
        'time',
        'today',
        'week',
      ],
    },
    {
      name: 'deblur',

      categories: ['image'],
      tags: [
        'adjust',
        'deblur',
        'edit',
        'editing',
        'enhance',
        'face',
        'image',
        'lines',
        'photo',
        'photography',
        'sharpen',
      ],
    },
    {
      name: 'deck',

      categories: ['social'],
      tags: [
        'chairs',
        'deck',
        'home',
        'house',
        'outdoors',
        'outside',
        'patio',
        'social',
        'terrace',
        'umbrella',
        'yard',
      ],
    },
    {
      name: 'dehaze',

      categories: ['image'],
      tags: [
        'adjust',
        'dehaze',
        'edit',
        'editing',
        'enhance',
        'haze',
        'image',
        'lines',
        'photo',
        'photography',
        'remove',
      ],
    },
    {
      name: 'delete',

      categories: ['action'],
      tags: ['bin', 'can', 'delete', 'garbage', 'remove', 'trash'],
    },
    {
      name: 'delete_forever',

      categories: ['action'],
      tags: ['bin', 'can', 'cancel', 'delete', 'exit', 'forever', 'garbage', 'remove', 'trash', 'x'],
    },
    {
      name: 'delete_outline',

      categories: ['action'],
      tags: ['bin', 'can', 'delete', 'garbage', 'outline', 'remove', 'trash'],
    },
    {
      name: 'delete_sweep',

      categories: ['content'],
      tags: ['bin', 'can', 'delete', 'garbage', 'remove', 'sweep', 'trash'],
    },
    {
      name: 'delivery_dining',

      categories: ['maps'],
      tags: [
        'delivery',
        'dining',
        'food',
        'meal',
        'restaurant',
        'scooter',
        'takeout',
        'transportation',
        'vehicle',
        'vespa',
      ],
    },
    {
      name: 'density_large',

      categories: ['action'],
      tags: ['density', 'horizontal', 'large', 'lines', 'rule', 'rules'],
    },
    {
      name: 'density_medium',

      categories: ['action'],
      tags: ['density', 'horizontal', 'lines', 'medium', 'rule', 'rules'],
    },
    {
      name: 'density_small',

      categories: ['action'],
      tags: ['density', 'horizontal', 'lines', 'rule', 'rules', 'small'],
    },
    {
      name: 'departure_board',

      categories: ['maps'],
      tags: [
        'automobile',
        'board',
        'bus',
        'car',
        'cars',
        'clock',
        'departure',
        'maps',
        'public',
        'schedule',
        'time',
        'transportation',
        'travel',
        'vehicle',
      ],
    },
    {
      name: 'description',

      categories: ['action'],
      tags: [
        'article',
        'data',
        'description',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'notes',
        'page',
        'paper',
        'sheet',
        'slide',
        'text',
        'writing',
      ],
    },
    {
      name: 'deselect',

      categories: ['content'],
      tags: ['all', 'disabled', 'enabled', 'off', 'on', 'selection', 'slash', 'square', 'tool'],
    },
    {
      name: 'design_services',

      categories: ['maps'],
      tags: [
        'compose',
        'create',
        'design',
        'draft',
        'edit',
        'editing',
        'input',
        'pen',
        'pencil',
        'ruler',
        'service',
        'write',
        'writing',
      ],
    },
    {
      name: 'desk',

      categories: ['places'],
      tags: [],
    },
    {
      name: 'desktop_access_disabled',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'access',
        'chrome',
        'desktop',
        'device',
        'disabled',
        'display',
        'enabled',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'off',
        'offline',
        'on',
        'screen',
        'slash',
        'web',
        'window',
      ],
    },
    {
      name: 'desktop_mac',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'desktop',
        'device',
        'display',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'screen',
        'web',
        'window',
      ],
    },
    {
      name: 'desktop_windows',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'desktop',
        'device',
        'display',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'screen',
        'television',
        'tv',
        'web',
        'window',
        'windows',
      ],
    },
    {
      name: 'details',

      categories: ['image'],
      tags: ['details', 'edit', 'editing', 'enhance', 'image', 'photo', 'photography', 'sharpen', 'triangle'],
    },
    {
      name: 'developer_board',

      categories: ['hardware'],
      tags: ['board', 'chip', 'computer', 'developer', 'development', 'hardware', 'microchip', 'processor'],
    },
    {
      name: 'developer_board_off',

      categories: ['hardware'],
      tags: [
        'board',
        'chip',
        'computer',
        'developer',
        'development',
        'disabled',
        'enabled',
        'hardware',
        'microchip',
        'off',
        'on',
        'processor',
        'slash',
      ],
    },
    {
      name: 'developer_mode',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'bracket',
        'cell',
        'code',
        'developer',
        'development',
        'device',
        'engineer',
        'hardware',
        'iOS',
        'mobile',
        'mode',
        'phone',
        'tablet',
      ],
    },
    {
      name: 'device_hub',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'circle',
        'computer',
        'desktop',
        'device',
        'hardware',
        'hub',
        'iOS',
        'laptop',
        'mobile',
        'monitor',
        'phone',
        'square',
        'tablet',
        'triangle',
        'watch',
        'wearable',
        'web',
      ],
    },
    {
      name: 'device_thermostat',

      categories: ['device'],
      tags: ['celsius', 'device', 'fahrenheit', 'meter', 'temp', 'temperature', 'thermometer', 'thermostat'],
    },
    {
      name: 'device_unknown',

      categories: ['hardware'],
      tags: [
        '?',
        'Android',
        'OS',
        'assistance',
        'cell',
        'device',
        'hardware',
        'help',
        'iOS',
        'info',
        'information',
        'mobile',
        'phone',
        'punctuation',
        'question mark',
        'support',
        'symbol',
        'tablet',
        'unknown',
      ],
    },
    {
      name: 'devices',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'computer',
        'desktop',
        'device',
        'hardware',
        'iOS',
        'laptop',
        'mobile',
        'monitor',
        'phone',
        'tablet',
        'watch',
        'wearable',
        'web',
      ],
    },
    {
      name: 'devices_fold',

      categories: ['device'],
      tags: ['Android', 'OS', 'cell', 'device', 'fold', 'foldable', 'hardware', 'iOS', 'mobile', 'phone', 'tablet'],
    },
    {
      name: 'devices_other',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'ar',
        'cell',
        'chrome',
        'desktop',
        'device',
        'gadget',
        'hardware',
        'iOS',
        'ipad',
        'mac',
        'mobile',
        'monitor',
        'other',
        'phone',
        'tablet',
        'vr',
        'watch',
        'wearables',
        'window',
      ],
    },
    {
      name: 'dialer_sip',

      categories: ['communication'],
      tags: [
        'alphabet',
        'call',
        'cell',
        'character',
        'contact',
        'device',
        'dialer',
        'font',
        'hardware',
        'initiation',
        'internet',
        'letter',
        'mobile',
        'over',
        'phone',
        'protocol',
        'routing',
        'session',
        'sip',
        'symbol',
        'telephone',
        'text',
        'type',
        'voice',
      ],
    },
    {
      name: 'dialpad',

      categories: ['communication'],
      tags: ['buttons', 'call', 'contact', 'device', 'dial', 'dialpad', 'dots', 'mobile', 'numbers', 'pad', 'phone'],
    },
    {
      name: 'diamond',

      categories: ['maps'],
      tags: ['diamond', 'fashion', 'gems', 'jewelry', 'logo', 'retail', 'valuable', 'valuables'],
    },
    {
      name: 'difference',

      categories: ['file'],
      tags: [
        'compare',
        'content',
        'copy',
        'cut',
        'diff',
        'difference',
        'doc',
        'document',
        'duplicate',
        'file',
        'multiple',
        'past',
      ],
    },
    {
      name: 'dining',

      categories: ['search'],
      tags: ['cafe', 'cafeteria', 'cutlery', 'diner', 'dining', 'eat', 'eating', 'fork', 'room', 'spoon'],
    },
    {
      name: 'dinner_dining',

      categories: ['maps'],
      tags: ['breakfast', 'dining', 'dinner', 'food', 'fork', 'lunch', 'meal', 'restaurant', 'spaghetti', 'utensils'],
    },
    {
      name: 'directions',

      categories: ['maps'],
      tags: ['arrow', 'directions', 'maps', 'right', 'route', 'sign', 'traffic'],
    },
    {
      name: 'directions_bike',

      categories: ['maps'],
      tags: [
        'bicycle',
        'bike',
        'direction',
        'directions',
        'human',
        'maps',
        'person',
        'public',
        'route',
        'transportation',
      ],
    },
    {
      name: 'directions_boat',

      categories: ['maps'],
      tags: [
        'automobile',
        'boat',
        'car',
        'cars',
        'direction',
        'directions',
        'ferry',
        'maps',
        'public',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_boat_filled',

      categories: ['maps'],
      tags: [
        'automobile',
        'boat',
        'car',
        'cars',
        'direction',
        'directions',
        'ferry',
        'filled',
        'maps',
        'public',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_bus',

      categories: ['maps'],
      tags: ['automobile', 'bus', 'car', 'cars', 'directions', 'maps', 'public', 'transportation', 'vehicle'],
    },
    {
      name: 'directions_bus_filled',

      categories: ['maps'],
      tags: [
        'automobile',
        'bus',
        'car',
        'cars',
        'direction',
        'directions',
        'filled',
        'maps',
        'public',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_car',

      categories: ['maps'],
      tags: ['automobile', 'car', 'cars', 'direction', 'directions', 'maps', 'public', 'transportation', 'vehicle'],
    },
    {
      name: 'directions_car_filled',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'directions',
        'filled',
        'maps',
        'public',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_off',

      categories: ['notification'],
      tags: [
        'arrow',
        'directions',
        'disabled',
        'enabled',
        'maps',
        'off',
        'on',
        'right',
        'route',
        'sign',
        'slash',
        'traffic',
      ],
    },
    {
      name: 'directions_railway',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'directions',
        'maps',
        'public',
        'railway',
        'train',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_railway_filled',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'directions',
        'filled',
        'maps',
        'public',
        'railway',
        'train',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_run',

      categories: ['maps'],
      tags: ['body', 'directions', 'human', 'jogging', 'maps', 'people', 'person', 'route', 'run', 'running', 'walk'],
    },
    {
      name: 'directions_subway',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'directions',
        'maps',
        'public',
        'rail',
        'subway',
        'train',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_subway_filled',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'directions',
        'filled',
        'maps',
        'public',
        'rail',
        'subway',
        'train',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_transit',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'directions',
        'maps',
        'public',
        'rail',
        'subway',
        'train',
        'transit',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_transit_filled',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'directions',
        'filled',
        'maps',
        'public',
        'rail',
        'subway',
        'train',
        'transit',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'directions_walk',

      categories: ['maps'],
      tags: ['body', 'direction', 'directions', 'human', 'jogging', 'maps', 'people', 'person', 'route', 'run', 'walk'],
    },
    {
      name: 'dirty_lens',

      categories: ['image'],
      tags: ['camera', 'dirty', 'lens', 'photo', 'photography', 'picture', 'splat'],
    },
    {
      name: 'disabled_by_default',

      categories: ['action'],
      tags: [
        'box',
        'by',
        'cancel',
        'close',
        'default',
        'disabled',
        'exit',
        'no',
        'quit',
        'remove',
        'square',
        'stop',
        'x',
      ],
    },
    {
      name: 'disabled_visible',

      categories: ['action'],
      tags: [
        'cancel',
        'close',
        'disabled',
        'exit',
        'eye',
        'no',
        'on',
        'quit',
        'remove',
        'reveal',
        'see',
        'show',
        'stop',
        'view',
        'visibility',
        'visible',
      ],
    },
    {
      name: 'disc_full',

      categories: ['notification'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'cd',
        'danger',
        'disc',
        'error',
        'exclamation',
        'full',
        'important',
        'mark',
        'music',
        'notification',
        'storage',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'discount',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'display_settings',

      categories: ['action'],
      tags: [
        'Android',
        'OS',
        'application',
        'change',
        'chrome',
        'desktop',
        'details',
        'device',
        'display',
        'gear',
        'hardware',
        'iOS',
        'info',
        'information',
        'mac',
        'monitor',
        'options',
        'personal',
        'screen',
        'service',
        'settings',
        'web',
        'window',
      ],
    },
    {
      name: 'diversity_1',

      categories: ['social'],
      tags: [
        'committee',
        'diverse',
        'diversity',
        'family',
        'friends',
        'group',
        'groups',
        'heart',
        'humans',
        'network',
        'people',
        'persons',
        'social',
        'team',
      ],
    },
    {
      name: 'diversity_2',

      categories: ['social'],
      tags: [
        'committee',
        'diverse',
        'diversity',
        'family',
        'friends',
        'group',
        'groups',
        'heart',
        'humans',
        'network',
        'people',
        'persons',
        'social',
        'team',
      ],
    },
    {
      name: 'diversity_3',

      categories: ['social'],
      tags: [
        'committee',
        'diverse',
        'diversity',
        'family',
        'friends',
        'group',
        'groups',
        'humans',
        'network',
        'people',
        'persons',
        'social',
        'team',
      ],
    },
    {
      name: 'dns',

      categories: ['action'],
      tags: ['address', 'bars', 'dns', 'domain', 'information', 'ip', 'list', 'lookup', 'name', 'server', 'system'],
    },
    {
      name: 'do_disturb',

      categories: ['notification'],
      tags: ['cancel', 'close', 'denied', 'deny', 'disturb', 'do', 'remove', 'silence', 'stop'],
    },
    {
      name: 'do_disturb_alt',

      categories: ['notification'],
      tags: ['cancel', 'close', 'denied', 'deny', 'disturb', 'do', 'remove', 'silence', 'stop'],
    },
    {
      name: 'do_disturb_off',

      categories: ['notification'],
      tags: [
        'cancel',
        'close',
        'denied',
        'deny',
        'disabled',
        'disturb',
        'do',
        'enabled',
        'off',
        'on',
        'remove',
        'silence',
        'slash',
        'stop',
      ],
    },
    {
      name: 'do_disturb_on',

      categories: ['notification'],
      tags: [
        'cancel',
        'close',
        'denied',
        'deny',
        'disabled',
        'disturb',
        'do',
        'enabled',
        'off',
        'on',
        'remove',
        'silence',
        'slash',
        'stop',
      ],
    },
    {
      name: 'do_not_disturb',

      categories: ['notification'],
      tags: ['cancel', 'close', 'denied', 'deny', 'disturb', 'do', 'remove', 'silence', 'stop'],
    },
    {
      name: 'do_not_disturb_alt',

      categories: ['notification'],
      tags: ['cancel', 'close', 'denied', 'deny', 'disturb', 'do', 'remove', 'silence', 'stop'],
    },
    {
      name: 'do_not_disturb_off',

      categories: ['notification'],
      tags: [
        'cancel',
        'close',
        'denied',
        'deny',
        'disabled',
        'disturb',
        'do',
        'enabled',
        'off',
        'on',
        'remove',
        'silence',
        'slash',
        'stop',
      ],
    },
    {
      name: 'do_not_disturb_on',

      categories: ['notification'],
      tags: [
        'cancel',
        'close',
        'denied',
        'deny',
        'disabled',
        'disturb',
        'do',
        'enabled',
        'off',
        'on',
        'remove',
        'silence',
        'slash',
        'stop',
      ],
    },
    {
      name: 'do_not_disturb_on_total_silence',

      categories: ['device'],
      tags: ['busy', 'disturb', 'do', 'mute', 'no', 'not', 'on total', 'quiet', 'silence'],
    },
    {
      name: 'do_not_step',

      categories: ['places'],
      tags: [
        'boot',
        'disabled',
        'do',
        'enabled',
        'feet',
        'foot',
        'not',
        'off',
        'on',
        'shoe',
        'slash',
        'sneaker',
        'step',
      ],
    },
    {
      name: 'do_not_touch',

      categories: ['places'],
      tags: ['disabled', 'do', 'enabled', 'fingers', 'gesture', 'hand', 'not', 'off', 'on', 'slash', 'touch'],
    },
    {
      name: 'dock',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'cell',
        'charging',
        'connector',
        'device',
        'dock',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'power',
        'station',
        'tablet',
      ],
    },
    {
      name: 'document_scanner',

      categories: ['communication'],
      tags: [
        'article',
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'notes',
        'page',
        'paper',
        'scan',
        'scanner',
        'sheet',
        'slide',
        'text',
        'writing',
      ],
    },
    {
      name: 'domain',

      categories: ['social'],
      tags: [
        'apartment',
        'architecture',
        'building',
        'business',
        'domain',
        'estate',
        'home',
        'place',
        'real',
        'residence',
        'residential',
        'shelter',
        'web',
        'www',
      ],
    },
    {
      name: 'domain_add',

      categories: ['social'],
      tags: [
        '+',
        'add',
        'apartment',
        'architecture',
        'building',
        'business',
        'domain',
        'estate',
        'home',
        'new',
        'place',
        'plus',
        'real',
        'residence',
        'residential',
        'shelter',
        'symbol',
        'web',
        'www',
      ],
    },
    {
      name: 'domain_disabled',

      categories: ['communication'],
      tags: [
        'apartment',
        'architecture',
        'building',
        'business',
        'company',
        'disabled',
        'domain',
        'enabled',
        'estate',
        'home',
        'internet',
        'maps',
        'off',
        'office',
        'offline',
        'on',
        'place',
        'real',
        'residence',
        'residential',
        'slash',
        'web',
        'website',
      ],
    },
    {
      name: 'domain_verification',

      categories: ['communication'],
      tags: [
        'app',
        'application desktop',
        'approve',
        'check',
        'complete',
        'design',
        'domain',
        'done',
        'interface',
        'internet',
        'layout',
        'mark',
        'ok',
        'screen',
        'select',
        'site',
        'tick',
        'ui',
        'ux',
        'validate',
        'verification',
        'verified',
        'web',
        'website',
        'window',
        'www',
        'yes',
      ],
    },
    {
      name: 'done',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'complete',
        'disable_ios',
        'done',
        'mark',
        'ok',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'done_all',

      categories: ['action'],
      tags: [
        'all',
        'approve',
        'check',
        'complete',
        'done',
        'layers',
        'mark',
        'multiple',
        'ok',
        'select',
        'stack',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'done_outline',

      categories: ['action'],
      tags: [
        'all',
        'approve',
        'check',
        'complete',
        'done',
        'mark',
        'ok',
        'outline',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'donut_large',

      categories: ['action'],
      tags: [
        'analytics',
        'chart',
        'data',
        'diagram',
        'donut',
        'graph',
        'infographic',
        'inprogress',
        'large',
        'measure',
        'metrics',
        'pie',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'donut_small',

      categories: ['action'],
      tags: [
        'analytics',
        'chart',
        'data',
        'diagram',
        'donut',
        'graph',
        'infographic',
        'inprogress',
        'measure',
        'metrics',
        'pie',
        'small',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'door_back',

      categories: ['search'],
      tags: ['back', 'closed', 'door', 'doorway', 'entrance', 'exit', 'home', 'house', 'way'],
    },
    {
      name: 'door_front',

      categories: ['search'],
      tags: ['closed', 'door', 'doorway', 'entrance', 'exit', 'front', 'home', 'house', 'way'],
    },
    {
      name: 'door_sliding',

      categories: ['search'],
      tags: [
        'auto',
        'automatic',
        'door',
        'doorway',
        'double',
        'entrance',
        'exit',
        'glass',
        'home',
        'house',
        'sliding',
        'two',
      ],
    },
    {
      name: 'doorbell',

      categories: ['search'],
      tags: ['alarm', 'bell', 'door', 'doorbell', 'home', 'house', 'ringing'],
    },
    {
      name: 'double_arrow',

      categories: ['navigation'],
      tags: ['arrow', 'arrows', 'direction', 'double', 'multiple', 'navigation', 'right'],
    },
    {
      name: 'downhill_skiing',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'body',
        'downhill',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'people',
        'person',
        'ski social',
        'skiing',
        'snow',
        'sports',
        'travel',
        'winter',
      ],
    },
    {
      name: 'download',

      categories: ['file'],
      tags: ['arrow', 'down', 'download', 'downloads', 'drive', 'install', 'upload'],
    },
    {
      name: 'download_done',

      categories: ['file'],
      tags: [
        'arrow',
        'arrows',
        'check',
        'done',
        'down',
        'download',
        'downloads',
        'drive',
        'install',
        'installed',
        'ok',
        'tick',
        'upload',
      ],
    },
    {
      name: 'download_for_offline',

      categories: ['file'],
      tags: ['arrow', 'circle', 'down', 'download', 'for offline', 'install', 'upload'],
    },
    {
      name: 'downloading',

      categories: ['file'],
      tags: [
        'arrow',
        'circle',
        'down',
        'download',
        'downloading',
        'downloads',
        'install',
        'pending',
        'progress',
        'upload',
      ],
    },
    {
      name: 'drafts',

      categories: ['content'],
      tags: ['document', 'draft', 'drafts', 'email', 'file', 'letter', 'mail', 'message', 'read'],
    },
    {
      name: 'drag_handle',

      categories: ['editor'],
      tags: [
        'app',
        'application ui',
        'components',
        'design',
        'drag',
        'handle',
        'interface',
        'layout',
        'menu',
        'move',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'drag_indicator',

      categories: ['action'],
      tags: [
        'app',
        'application',
        'circles',
        'components',
        'design',
        'dots',
        'drag',
        'drop',
        'indicator',
        'interface',
        'layout',
        'mobile',
        'monitor',
        'move',
        'phone',
        'screen',
        'shape',
        'shift',
        'site',
        'tablet',
        'ui',
        'ux',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'draw',

      categories: ['editor'],
      tags: [
        'compose',
        'create',
        'design',
        'draft',
        'draw',
        'edit',
        'editing',
        'input',
        'pen',
        'pencil',
        'write',
        'writing',
      ],
    },
    {
      name: 'drive_eta',

      categories: ['notification'],
      tags: [
        'automobile',
        'car',
        'cars',
        'destination',
        'direction',
        'drive',
        'estimate',
        'eta',
        'maps',
        'public',
        'transportation',
        'travel',
        'trip',
        'vehicle',
      ],
    },
    {
      name: 'drive_file_move',

      categories: ['file'],
      tags: [
        'arrow',
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'move',
        'right',
        'sheet',
        'slide',
        'storage',
      ],
    },
    {
      name: 'drive_file_move_rtl',

      categories: ['file'],
      tags: [
        'arrow',
        'arrows',
        'data',
        'direction',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'left',
        'move',
        'rtl',
        'sheet',
        'side',
        'slide',
        'storage',
      ],
    },
    {
      name: 'drive_file_rename_outline',

      categories: ['file'],
      tags: [
        'compose',
        'create',
        'draft',
        'drive',
        'edit',
        'editing',
        'file',
        'input',
        'pen',
        'pencil',
        'rename',
        'write',
        'writing',
      ],
    },
    {
      name: 'drive_folder_upload',

      categories: ['file'],
      tags: [
        'arrow',
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'sheet',
        'slide',
        'storage',
        'up',
        'upload',
      ],
    },
    {
      name: 'dry',

      categories: ['places'],
      tags: ['air', 'bathroom', 'dry', 'dryer', 'fingers', 'gesture', 'hand', 'wc'],
    },
    {
      name: 'dry_cleaning',

      categories: ['maps'],
      tags: ['cleaning', 'dry', 'hanger', 'hotel', 'laundry', 'places', 'service', 'towel'],
    },
    {
      name: 'duo',

      categories: ['communication'],
      tags: ['call', 'chat', 'conference', 'device', 'duo', 'video'],
    },
    {
      name: 'dvr',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'audio',
        'chrome',
        'computer',
        'desktop',
        'device',
        'display',
        'dvr',
        'electronic',
        'hardware',
        'iOS',
        'list',
        'mac',
        'monitor',
        'record',
        'recorder',
        'screen',
        'tv',
        'video',
        'web',
        'window',
      ],
    },
    {
      name: 'dynamic_feed',

      categories: ['content'],
      tags: [
        '\u0027mail_outline\u0027',
        '\u0027markunread\u0027. Keep \u0027mail\u0027 and remove others.',
        'Duplicate of \u0027email\u0027',
      ],
    },
    {
      name: 'dynamic_form',

      categories: ['action'],
      tags: [
        'bolt',
        'code',
        'dynamic',
        'electric',
        'fast',
        'form',
        'lightning',
        'lists',
        'questionnaire',
        'thunderbolt',
      ],
    },
    {
      name: 'e_mobiledata',

      categories: ['device'],
      tags: ['alphabet', 'data', 'e', 'font', 'letter', 'mobile', 'mobiledata', 'text', 'type'],
    },
    {
      name: 'earbuds',

      categories: ['hardware'],
      tags: ['accessory', 'audio', 'earbuds', 'earphone', 'headphone', 'listen', 'music', 'sound'],
    },
    {
      name: 'earbuds_battery',

      categories: ['hardware'],
      tags: [
        'accessory',
        'audio',
        'battery',
        'charging',
        'earbuds',
        'earphone',
        'headphone',
        'listen',
        'music',
        'sound',
      ],
    },
    {
      name: 'east',

      categories: ['navigation'],
      tags: ['arrow', 'directional', 'east', 'maps', 'navigation', 'right'],
    },
    {
      name: 'edgesensor_high',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'cell',
        'device',
        'edge',
        'hardware',
        'high',
        'iOS',
        'mobile',
        'move',
        'phone',
        'sensitivity',
        'sensor',
        'tablet',
        'vibrate',
      ],
    },
    {
      name: 'edgesensor_low',

      categories: ['device'],
      tags: [
        'Android',
        'cell',
        'device',
        'edge',
        'hardware',
        'iOS',
        'low',
        'mobile',
        'move',
        'phone',
        'sensitivity',
        'sensor',
        'tablet',
        'vibrate',
      ],
    },
    {
      name: 'edit',

      categories: ['image'],
      tags: ['compose', 'create', 'edit', 'editing', 'input', 'new', 'pen', 'pencil', 'write', 'writing'],
    },
    {
      name: 'edit_attributes',

      categories: ['maps'],
      tags: [
        'approve',
        'attribution',
        'check',
        'complete',
        'done',
        'edit',
        'mark',
        'ok',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'edit_calendar',

      categories: ['action'],
      tags: [
        'calendar',
        'compose',
        'create',
        'date',
        'day',
        'draft',
        'edit',
        'editing',
        'event',
        'month',
        'pen',
        'pencil',
        'schedule',
        'write',
        'writing',
      ],
    },
    {
      name: 'edit_location',

      categories: ['maps'],
      tags: ['destination', 'direction', 'edit', 'location', 'maps', 'pen', 'pencil', 'pin', 'place', 'stop'],
    },
    {
      name: 'edit_location_alt',

      categories: ['maps'],
      tags: ['alt', 'edit', 'location', 'pen', 'pencil', 'pin'],
    },
    {
      name: 'edit_note',

      categories: ['editor'],
      tags: [
        'compose',
        'create',
        'draft',
        'edit',
        'editing',
        'input',
        'lines',
        'note',
        'pen',
        'pencil',
        'text',
        'write',
        'writing',
      ],
    },
    {
      name: 'edit_notifications',

      categories: ['social'],
      tags: [
        'active',
        'alarm',
        'alert',
        'bell',
        'chime',
        'compose',
        'create',
        'draft',
        'edit',
        'editing',
        'input',
        'new',
        'notifications',
        'notify',
        'pen',
        'pencil',
        'reminder',
        'ring',
        'sound',
        'write',
        'writing',
      ],
    },
    {
      name: 'edit_off',

      categories: ['action'],
      tags: [
        'compose',
        'create',
        'disabled',
        'draft',
        'edit',
        'editing',
        'enabled',
        'input',
        'new',
        'off',
        'offline',
        'on',
        'pen',
        'pencil',
        'slash',
        'write',
        'writing',
      ],
    },
    {
      name: 'edit_road',

      categories: ['maps'],
      tags: ['destination', 'direction', 'edit', 'highway', 'maps', 'pen', 'pencil', 'road', 'street', 'traffic'],
    },
    {
      name: 'egg',

      categories: ['maps'],
      tags: ['breakfast', 'brunch', 'egg', 'food'],
    },
    {
      name: 'egg_alt',

      categories: ['maps'],
      tags: ['breakfast', 'brunch', 'egg', 'food'],
    },
    {
      name: 'eject',

      categories: ['action'],
      tags: ['disc', 'drive', 'dvd', 'eject', 'remove', 'triangle', 'usb'],
    },
    {
      name: 'elderly',

      categories: ['social'],
      tags: ['body', 'cane', 'elderly', 'human', 'old', 'people', 'person', 'senior'],
    },
    {
      name: 'elderly_woman',

      categories: ['social'],
      tags: [
        'body',
        'cane',
        'elderly',
        'female',
        'gender',
        'girl',
        'human',
        'lady',
        'old',
        'people',
        'person',
        'senior',
        'social',
        'symbol',
        'woman',
        'women',
      ],
    },
    {
      name: 'electric_bike',

      categories: ['maps'],
      tags: ['bike', 'electric', 'electricity', 'maps', 'scooter', 'transportation', 'travel', 'vespa'],
    },
    {
      name: 'electric_bolt',

      categories: ['home'],
      tags: ['bolt', 'electric', 'energy', 'fast', 'lightning', 'nest', 'thunderbolt'],
    },
    {
      name: 'electric_car',

      categories: ['maps'],
      tags: ['automobile', 'car', 'cars', 'electric', 'electricity', 'maps', 'transportation', 'travel', 'vehicle'],
    },
    {
      name: 'electric_meter',

      categories: ['home'],
      tags: [
        'bolt',
        'electric',
        'energy',
        'fast',
        'lightning',
        'measure',
        'meter',
        'nest',
        'thunderbolt',
        'usage',
        'voltage',
        'volts',
      ],
    },
    {
      name: 'electric_moped',

      categories: ['maps'],
      tags: [
        'automobile',
        'bike',
        'car',
        'cars',
        'electric',
        'maps',
        'moped',
        'scooter',
        'transportation',
        'travel',
        'vehicle',
        'vespa',
      ],
    },
    {
      name: 'electric_rickshaw',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'electric',
        'india',
        'maps',
        'rickshaw',
        'transportation',
        'truck',
        'vehicle',
      ],
    },
    {
      name: 'electric_scooter',

      categories: ['maps'],
      tags: ['bike', 'electric', 'maps', 'scooter', 'transportation', 'vehicle', 'vespa'],
    },
    {
      name: 'electrical_services',

      categories: ['maps'],
      tags: ['charge', 'cord', 'electric', 'electrical', 'plug', 'power', 'services', 'wire'],
    },
    {
      name: 'elevator',

      categories: ['places'],
      tags: ['body', 'down', 'elevator', 'human', 'people', 'person', 'up'],
    },
    {
      name: 'email',

      categories: ['communication'],
      tags: ['email', 'envelop', 'letter', 'mail', 'message', 'send'],
    },
    {
      name: 'emergency',

      categories: ['maps'],
      tags: ['asterisk', 'clinic', 'emergency', 'health', 'hospital', 'maps', 'medical', 'symbol'],
    },
    {
      name: 'emergency_recording',

      categories: ['maps'],
      tags: [
        'alert',
        'attention',
        'camera',
        'caution',
        'danger',
        'emergency',
        'film',
        'filming',
        'hardware',
        'image',
        'important',
        'motion',
        'notification',
        'picture',
        'record',
        'video',
        'videography',
        'warning',
      ],
    },
    {
      name: 'emergency_share',

      categories: ['maps'],
      tags: ['alert', 'attention', 'caution', 'danger', 'emergency', 'important', 'notification', 'share', 'warning'],
    },
    {
      name: 'emoji_emotions',

      categories: ['social'],
      tags: [
        '+',
        'add',
        'emoji',
        'emotions',
        'expressions',
        'face',
        'feelings',
        'glad',
        'happiness',
        'happy',
        'insert',
        'like',
        'mood',
        'new',
        'person',
        'pleased',
        'plus',
        'smile',
        'smiling',
        'social',
        'survey',
        'symbol',
      ],
    },
    {
      name: 'emoji_events',

      categories: ['social'],
      tags: [
        'achievement',
        'award',
        'chalice',
        'champion',
        'cup',
        'emoji',
        'events',
        'first',
        'prize',
        'reward',
        'sport',
        'trophy',
        'winner',
      ],
    },
    {
      name: 'emoji_food_beverage',

      categories: ['social'],
      tags: ['beverage', 'coffee', 'cup', 'drink', 'emoji', 'mug', 'plate', 'set', 'tea'],
    },
    {
      name: 'emoji_nature',

      categories: ['social'],
      tags: [
        'animal',
        'bee',
        'bug',
        'daisy',
        'emoji',
        'flower',
        'insect',
        'ladybug',
        'nature',
        'petals',
        'spring',
        'summer',
      ],
    },
    {
      name: 'emoji_objects',

      categories: ['social'],
      tags: ['bulb', 'creative', 'emoji', 'idea', 'light', 'objects', 'solution', 'thinking'],
    },
    {
      name: 'emoji_people',

      categories: ['social'],
      tags: ['arm', 'body', 'emoji', 'greeting', 'human', 'people', 'person', 'social', 'waving'],
    },
    {
      name: 'emoji_symbols',

      categories: ['social'],
      tags: ['ampersand', 'character', 'emoji', 'hieroglyph', 'music', 'note', 'percent', 'sign', 'symbols'],
    },
    {
      name: 'emoji_transportation',

      categories: ['social'],
      tags: [
        'architecture',
        'automobile',
        'building',
        'car',
        'cars',
        'direction',
        'emoji',
        'estate',
        'maps',
        'place',
        'public',
        'real',
        'residence',
        'residential',
        'shelter',
        'transportation',
        'travel',
        'vehicle',
      ],
    },
    {
      name: 'energy_savings_leaf',

      categories: ['home'],
      tags: ['eco', 'energy', 'leaf', 'leaves', 'nest', 'savings', 'usage'],
    },
    {
      name: 'engineering',

      categories: ['social'],
      tags: [
        'body',
        'cogs',
        'cogwheel',
        'construction',
        'engineering',
        'fixing',
        'gears',
        'hat',
        'helmet',
        'human',
        'maintenance',
        'people',
        'person',
        'setting',
        'worker',
      ],
    },
    {
      name: 'enhanced_encryption',

      categories: ['notification'],
      tags: [
        '+',
        'add',
        'encryption',
        'enhanced',
        'lock',
        'locked',
        'new',
        'password',
        'plus',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
        'symbol',
      ],
    },
    {
      name: 'equalizer',

      categories: ['av'],
      tags: [
        'adjustment',
        'analytics',
        'chart',
        'data',
        'equalizer',
        'graph',
        'measure',
        'metrics',
        'music',
        'noise',
        'sound',
        'static',
        'statistics',
        'tracking',
        'volume',
      ],
    },
    {
      name: 'error',

      categories: ['alert'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'circle',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'notification',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'error_outline',

      categories: ['alert'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'circle',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'notification',
        'outline',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'escalator',

      categories: ['places'],
      tags: ['down', 'escalator', 'staircase', 'up'],
    },
    {
      name: 'escalator_warning',

      categories: ['places'],
      tags: ['body', 'child', 'escalator', 'human', 'kid', 'parent', 'people', 'person', 'warning'],
    },
    {
      name: 'euro',

      categories: ['image'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'euro',
        'euros',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'euro_symbol',

      categories: ['action'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'euro',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'symbol',
      ],
    },
    {
      name: 'ev_station',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'charging',
        'electric',
        'electricity',
        'ev',
        'maps',
        'places',
        'station',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'event',

      categories: ['action'],
      tags: ['calendar', 'date', 'day', 'event', 'mark', 'month', 'range', 'remember', 'reminder', 'today', 'week'],
    },
    {
      name: 'event_available',

      categories: ['notification'],
      tags: [
        'approve',
        'available',
        'calendar',
        'check',
        'complete',
        'date',
        'done',
        'event',
        'mark',
        'ok',
        'schedule',
        'select',
        'tick',
        'time',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'event_busy',

      categories: ['notification'],
      tags: [
        'busy',
        'calendar',
        'cancel',
        'close',
        'date',
        'event',
        'exit',
        'no',
        'remove',
        'schedule',
        'stop',
        'time',
        'unavailable',
        'x',
      ],
    },
    {
      name: 'event_note',

      categories: ['notification'],
      tags: ['calendar', 'date', 'event', 'note', 'schedule', 'text', 'time', 'writing'],
    },
    {
      name: 'event_repeat',

      categories: ['action'],
      tags: [
        'around',
        'calendar',
        'date',
        'day',
        'event',
        'inprogress',
        'load',
        'loading refresh',
        'month',
        'renew',
        'rotate',
        'schedule',
        'turn',
      ],
    },
    {
      name: 'event_seat',

      categories: ['action'],
      tags: ['assign', 'assigned', 'chair', 'event', 'furniture', 'reservation', 'row', 'seat', 'section', 'sit'],
    },
    {
      name: 'exit_to_app',

      categories: ['action'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'design',
        'exit',
        'export',
        'interface',
        'layout',
        'leave',
        'mobile',
        'monitor',
        'move',
        'output',
        'phone',
        'screen',
        'site',
        'tablet',
        'to',
        'ui',
        'ux',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'expand',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'compress', 'enlarge', 'expand', 'grow', 'move', 'push', 'together'],
    },
    {
      name: 'expand_circle_down',

      categories: ['navigation'],
      tags: [
        'arrow',
        'arrows',
        'chevron',
        'circle',
        'collapse',
        'direction',
        'down',
        'expand',
        'expandable',
        'list',
        'more',
      ],
    },
    {
      name: 'expand_less',

      categories: ['navigation'],
      tags: ['arrow', 'arrows', 'chevron', 'collapse', 'direction', 'expand', 'expandable', 'less', 'list', 'up'],
    },
    {
      name: 'expand_more',

      categories: ['navigation'],
      tags: ['arrow', 'arrows', 'chevron', 'collapse', 'direction', 'down', 'expand', 'expandable', 'list', 'more'],
    },
    {
      name: 'explicit',

      categories: ['av'],
      tags: [
        'adult',
        'alphabet',
        'character',
        'content',
        'e',
        'explicit',
        'font',
        'language',
        'letter',
        'media',
        'movies',
        'music',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'explore',

      categories: ['action'],
      tags: [
        'compass',
        'destination',
        'direction',
        'east',
        'explore',
        'location',
        'maps',
        'needle',
        'north',
        'south',
        'travel',
        'west',
      ],
    },
    {
      name: 'explore_off',

      categories: ['action'],
      tags: [
        'compass',
        'destination',
        'direction',
        'disabled',
        'east',
        'enabled',
        'explore',
        'location',
        'maps',
        'needle',
        'north',
        'off',
        'on',
        'slash',
        'south',
        'travel',
        'west',
      ],
    },
    {
      name: 'exposure',

      categories: ['image'],
      tags: [
        'add',
        'brightness',
        'contrast',
        'edit',
        'editing',
        'effect',
        'exposure',
        'image',
        'minus',
        'photo',
        'photography',
        'picture',
        'plus',
        'settings',
        'subtract',
      ],
    },
    {
      name: 'exposure_neg_1',

      categories: ['image'],
      tags: [
        '1',
        'brightness',
        'contrast',
        'digit',
        'edit',
        'editing',
        'effect',
        'exposure',
        'image',
        'neg',
        'negative',
        'number',
        'photo',
        'photography',
        'settings',
        'symbol',
      ],
    },
    {
      name: 'exposure_neg_2',

      categories: ['image'],
      tags: [
        '2',
        'brightness',
        'contrast',
        'digit',
        'edit',
        'editing',
        'effect',
        'exposure',
        'image',
        'neg',
        'negative',
        'number',
        'photo',
        'photography',
        'settings',
        'symbol',
      ],
    },
    {
      name: 'exposure_plus_1',

      categories: ['image'],
      tags: [
        '1',
        'add',
        'brightness',
        'contrast',
        'digit',
        'edit',
        'editing',
        'effect',
        'exposure',
        'image',
        'number',
        'photo',
        'photography',
        'plus',
        'settings',
        'symbol',
      ],
    },
    {
      name: 'exposure_plus_2',

      categories: ['image'],
      tags: [
        '2',
        'add',
        'brightness',
        'contrast',
        'digit',
        'edit',
        'editing',
        'effect',
        'exposure',
        'image',
        'number',
        'photo',
        'photography',
        'plus',
        'settings',
        'symbol',
      ],
    },
    {
      name: 'exposure_zero',

      categories: ['image'],
      tags: [
        '0',
        'brightness',
        'contrast',
        'digit',
        'edit',
        'editing',
        'effect',
        'exposure',
        'image',
        'number',
        'photo',
        'photography',
        'settings',
        'symbol',
        'zero',
      ],
    },
    {
      name: 'extension',

      categories: ['action'],
      tags: ['app', 'extended', 'extension', 'game', 'jigsaw', 'plugin add', 'puzzle', 'shape'],
    },
    {
      name: 'extension_off',

      categories: ['action'],
      tags: [
        'disabled',
        'enabled',
        'extended',
        'extension',
        'jigsaw',
        'off',
        'on',
        'piece',
        'puzzle',
        'shape',
        'slash',
      ],
    },
    {
      name: 'face',

      categories: ['action'],
      tags: [
        'account',
        'emoji',
        'eyes',
        'face',
        'human',
        'lock',
        'log',
        'login',
        'logout',
        'people',
        'person',
        'profile',
        'recognition',
        'security',
        'social',
        'thumbnail',
        'unlock',
        'user',
      ],
    },
    {
      name: 'face_2',

      categories: ['social'],
      tags: [
        'account',
        'emoji',
        'eyes',
        'face',
        'human',
        'lock',
        'log',
        'login',
        'logout',
        'people',
        'person',
        'profile',
        'recognition',
        'security',
        'social',
        'thumbnail',
        'unlock',
        'user',
      ],
    },
    {
      name: 'face_3',

      categories: ['social'],
      tags: [
        'account',
        'emoji',
        'eyes',
        'face',
        'human',
        'lock',
        'log',
        'login',
        'logout',
        'people',
        'person',
        'profile',
        'recognition',
        'security',
        'social',
        'thumbnail',
        'unlock',
        'user',
      ],
    },
    {
      name: 'face_4',

      categories: ['social'],
      tags: [
        'account',
        'emoji',
        'eyes',
        'face',
        'human',
        'lock',
        'log',
        'login',
        'logout',
        'people',
        'person',
        'profile',
        'recognition',
        'security',
        'social',
        'thumbnail',
        'unlock',
        'user',
      ],
    },
    {
      name: 'face_5',

      categories: ['social'],
      tags: [
        'account',
        'emoji',
        'eyes',
        'face',
        'human',
        'lock',
        'log',
        'login',
        'logout',
        'people',
        'person',
        'profile',
        'recognition',
        'security',
        'social',
        'thumbnail',
        'unlock',
        'user',
      ],
    },
    {
      name: 'face_6',

      categories: ['social'],
      tags: [
        'account',
        'emoji',
        'eyes',
        'face',
        'human',
        'lock',
        'log',
        'login',
        'logout',
        'people',
        'person',
        'profile',
        'recognition',
        'security',
        'social',
        'thumbnail',
        'unlock',
        'user',
      ],
    },
    {
      name: 'face_retouching_natural',

      categories: ['image'],
      tags: [
        'edit',
        'editing',
        'effect',
        'emoji',
        'emotion',
        'face',
        'faces',
        'image',
        'natural',
        'photo',
        'photography',
        'retouch',
        'retouching',
        'settings',
        'star',
        'tag',
      ],
    },
    {
      name: 'face_retouching_off',

      categories: ['image'],
      tags: [
        'disabled',
        'edit',
        'editing',
        'effect',
        'emoji',
        'emotion',
        'enabled',
        'face',
        'faces',
        'image',
        'natural',
        'off',
        'on',
        'photo',
        'photography',
        'retouch',
        'retouching',
        'settings',
        'slash',
        'tag',
      ],
    },
    {
      name: 'fact_check',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'complete',
        'done',
        'fact',
        'list',
        'mark',
        'ok',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'factory',

      categories: ['maps'],
      tags: ['factory', 'industry', 'manufacturing', 'warehouse'],
    },
    {
      name: 'family_restroom',

      categories: ['places'],
      tags: ['bathroom', 'child', 'children', 'family', 'father', 'kids', 'mother', 'parents', 'restroom', 'wc'],
    },
    {
      name: 'fast_forward',

      categories: ['av'],
      tags: ['control', 'fast', 'forward', 'media', 'music', 'play', 'speed', 'time', 'tv', 'video'],
    },
    {
      name: 'fast_rewind',

      categories: ['av'],
      tags: ['back', 'control', 'fast', 'media', 'music', 'play', 'rewind', 'speed', 'time', 'tv', 'video'],
    },
    {
      name: 'fastfood',

      categories: ['maps'],
      tags: ['drink', 'fastfood', 'food', 'hamburger', 'maps', 'meal', 'places'],
    },
    {
      name: 'favorite',

      categories: ['action'],
      tags: ['appreciate', 'favorite', 'heart', 'like', 'love', 'remember', 'save', 'shape'],
    },
    {
      name: 'favorite_border',

      categories: ['action'],
      tags: ['border', 'favorite', 'heart', 'like', 'love', 'outline', 'remember', 'save', 'shape'],
    },
    {
      name: 'fax',

      categories: ['action'],
      tags: ['fax', 'machine', 'office', 'phone', 'send'],
    },
    {
      name: 'featured_play_list',

      categories: ['av'],
      tags: ['collection', 'featured', 'highlighted', 'list', 'music', 'play', 'playlist', 'recommended'],
    },
    {
      name: 'featured_video',

      categories: ['av'],
      tags: ['advertised', 'advertisement', 'featured', 'highlighted', 'recommended', 'video', 'watch'],
    },
    {
      name: 'feed',

      categories: ['search'],
      tags: [
        'article',
        'feed',
        'headline',
        'information',
        'news',
        'newspaper',
        'paper',
        'public',
        'social',
        'timeline',
      ],
    },
    {
      name: 'feedback',

      categories: ['action'],
      tags: [
        '!',
        'alert',
        'announcement',
        'attention',
        'bubble',
        'caution',
        'chat',
        'comment',
        'communicate',
        'danger',
        'error',
        'exclamation',
        'feedback',
        'important',
        'mark',
        'message',
        'notification',
        'speech',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'female',

      categories: ['social'],
      tags: ['female', 'gender', 'girl', 'lady', 'social', 'symbol', 'woman', 'women'],
    },
    {
      name: 'fence',

      categories: ['places'],
      tags: ['backyard', 'barrier', 'boundaries', 'boundary', 'fence', 'home', 'house', 'protection', 'yard'],
    },
    {
      name: 'festival',

      categories: ['maps'],
      tags: ['circus', 'event', 'festival', 'local', 'maps', 'places', 'tent', 'tour', 'travel'],
    },
    {
      name: 'fiber_dvr',

      categories: ['av'],
      tags: [
        'alphabet',
        'character',
        'digital',
        'dvr',
        'electronics',
        'fiber',
        'font',
        'letter',
        'network',
        'record',
        'recorder',
        'symbol',
        'text',
        'tv',
        'type',
        'video',
      ],
    },
    {
      name: 'fiber_manual_record',

      categories: ['av'],
      tags: ['circle', 'dot', 'fiber', 'manual', 'play', 'record', 'watch'],
    },
    {
      name: 'fiber_new',

      categories: ['av'],
      tags: ['alphabet', 'character', 'fiber', 'font', 'letter', 'network', 'new', 'symbol', 'text', 'type'],
    },
    {
      name: 'fiber_pin',

      categories: ['av'],
      tags: ['alphabet', 'character', 'fiber', 'font', 'letter', 'network', 'pin', 'symbol', 'text', 'type'],
    },
    {
      name: 'fiber_smart_record',

      categories: ['av'],
      tags: ['circle', 'dot', 'fiber', 'play', 'record', 'smart', 'watch'],
    },
    {
      name: 'file_copy',

      categories: ['content'],
      tags: ['content', 'copy', 'cut', 'doc', 'document', 'duplicate', 'file', 'multiple', 'past'],
    },
    {
      name: 'file_download',

      categories: ['file'],
      tags: ['arrow', 'arrows', 'down', 'download', 'downloads', 'drive', 'export', 'file', 'install', 'upload'],
    },
    {
      name: 'file_download_done',

      categories: ['file'],
      tags: [
        'arrow',
        'arrows',
        'check',
        'done',
        'down',
        'download',
        'downloads',
        'drive',
        'file',
        'install',
        'installed',
        'tick',
        'upload',
      ],
    },
    {
      name: 'file_download_off',

      categories: ['file'],
      tags: [
        'arrow',
        'disabled',
        'down',
        'download',
        'drive',
        'enabled',
        'export',
        'file',
        'install',
        'off',
        'on',
        'save',
        'slash',
        'upload',
      ],
    },
    {
      name: 'file_open',

      categories: ['file'],
      tags: ['arrow', 'doc', 'document', 'drive', 'file', 'left', 'open', 'page', 'paper'],
    },
    {
      name: 'file_present',

      categories: ['action'],
      tags: [
        'clip',
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'note',
        'paper',
        'present',
        'reminder',
        'sheet',
        'slide',
        'storage',
        'writing',
      ],
    },
    {
      name: 'file_upload',

      categories: ['file'],
      tags: ['arrow', 'arrows', 'download', 'drive', 'export', 'file', 'up', 'upload'],
    },
    {
      name: 'filter',

      categories: ['image'],
      tags: [
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'settings',
      ],
    },
    {
      name: 'filter_1',

      categories: ['image'],
      tags: [
        '1',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_2',

      categories: ['image'],
      tags: [
        '2',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_3',

      categories: ['image'],
      tags: [
        '3',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_4',

      categories: ['image'],
      tags: [
        '4',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_5',

      categories: ['image'],
      tags: [
        '5',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_6',

      categories: ['image'],
      tags: [
        '6',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_7',

      categories: ['image'],
      tags: [
        '7',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_8',

      categories: ['image'],
      tags: [
        '8',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_9',

      categories: ['image'],
      tags: [
        '9',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_9_plus',

      categories: ['image'],
      tags: [
        '+',
        '9',
        'digit',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'images',
        'multiple',
        'number',
        'photography',
        'picture',
        'pictures',
        'plus',
        'settings',
        'stack',
        'symbol',
      ],
    },
    {
      name: 'filter_alt',

      categories: ['action'],
      tags: ['alt', 'edit', 'filter', 'funnel', 'options', 'refine', 'sift'],
    },
    {
      name: 'filter_alt_off',

      categories: ['action'],
      tags: ['[offline]', 'alt', 'disabled', 'edit', 'filter', 'funnel', 'off', 'options', 'refine', 'sift', 'slash'],
    },
    {
      name: 'filter_b_and_w',

      categories: ['image'],
      tags: [
        'and',
        'b',
        'black',
        'contrast',
        'edit',
        'editing',
        'effect',
        'filter',
        'grayscale',
        'image',
        'images',
        'photography',
        'picture',
        'pictures',
        'settings',
        'w',
        'white',
      ],
    },
    {
      name: 'filter_center_focus',

      categories: ['image'],
      tags: ['camera', 'center', 'dot', 'edit', 'filter', 'focus', 'image', 'photo', 'photography', 'picture'],
    },
    {
      name: 'filter_drama',

      categories: ['image'],
      tags: [
        'cloud',
        'drama',
        'edit',
        'editing',
        'effect',
        'filter',
        'image',
        'photo',
        'photography',
        'picture',
        'sky camera',
      ],
    },
    {
      name: 'filter_frames',

      categories: ['image'],
      tags: [
        'boarders',
        'border',
        'camera',
        'center',
        'edit',
        'editing',
        'effect',
        'filter',
        'filters',
        'focus',
        'frame',
        'frames',
        'image',
        'options',
        'photo',
        'photography',
        'picture',
      ],
    },
    {
      name: 'filter_hdr',

      categories: ['image'],
      tags: [
        'camera',
        'edit',
        'editing',
        'effect',
        'filter',
        'hdr',
        'image',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
      ],
    },
    {
      name: 'filter_list',

      categories: ['content'],
      tags: ['filter', 'lines', 'list', 'organize', 'sort'],
    },
    {
      name: 'filter_list_off',

      categories: ['content'],
      tags: ['[offline]', 'alt', 'disabled', 'edit', 'filter', 'list', 'off', 'options', 'refine', 'sift', 'slash'],
    },
    {
      name: 'filter_none',

      categories: ['image'],
      tags: ['filter', 'multiple', 'none', 'stack'],
    },
    {
      name: 'filter_tilt_shift',

      categories: ['image'],
      tags: [
        'blur',
        'center',
        'edit',
        'editing',
        'effect',
        'filter',
        'focus',
        'image',
        'images',
        'photography',
        'picture',
        'pictures',
        'shift',
        'tilt',
      ],
    },
    {
      name: 'filter_vintage',

      categories: ['image'],
      tags: [
        'edit',
        'editing',
        'effect',
        'filter',
        'flower',
        'image',
        'images',
        'photography',
        'picture',
        'pictures',
        'vintage',
      ],
    },
    {
      name: 'find_in_page',

      categories: ['action'],
      tags: [
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'find',
        'folder',
        'folders',
        'glass',
        'in',
        'look',
        'magnify',
        'magnifying',
        'page',
        'paper',
        'search',
        'see',
        'sheet',
        'slide',
        'writing',
      ],
    },
    {
      name: 'find_replace',

      categories: ['action'],
      tags: [
        'around',
        'arrows',
        'find',
        'glass',
        'inprogress',
        'load',
        'loading refresh',
        'look',
        'magnify',
        'magnifying',
        'renew',
        'replace',
        'rotate',
        'search',
        'see',
      ],
    },
    {
      name: 'fingerprint',

      categories: ['action'],
      tags: [
        'finger',
        'fingerprint',
        'id',
        'identification',
        'identity',
        'print',
        'reader',
        'thumbprint',
        'verification',
      ],
    },
    {
      name: 'fire_extinguisher',

      categories: ['places'],
      tags: ['emergency', 'extinguisher', 'fire', 'water'],
    },
    {
      name: 'fire_hydrant_alt',

      categories: ['maps'],
      tags: [],
    },
    {
      name: 'fire_truck',

      categories: ['maps'],
      tags: [],
    },
    {
      name: 'fireplace',

      categories: ['social'],
      tags: [
        'chimney',
        'fire',
        'fireplace',
        'flame',
        'home',
        'house',
        'living',
        'pit',
        'place',
        'room',
        'warm',
        'winter',
      ],
    },
    {
      name: 'first_page',

      categories: ['navigation'],
      tags: ['arrow', 'back', 'chevron', 'first', 'left', 'page', 'rewind'],
    },
    {
      name: 'fit_screen',

      categories: ['action'],
      tags: ['enlarge', 'fit', 'format', 'layout', 'reduce', 'scale', 'screen', 'size'],
    },
    {
      name: 'fitbit',

      categories: ['social'],
      tags: ['athlete', 'athletic', 'exercise', 'fitbit', 'fitness', 'hobby'],
    },
    {
      name: 'fitness_center',

      categories: ['places'],
      tags: [
        'athlete',
        'center',
        'dumbbell',
        'exercise',
        'fitness',
        'gym',
        'hobby',
        'places',
        'sport',
        'weights',
        'workout',
      ],
    },
    {
      name: 'flag',

      categories: ['content'],
      tags: ['country', 'flag', 'goal', 'mark', 'nation', 'report', 'start'],
    },
    {
      name: 'flag_circle',

      categories: ['content'],
      tags: ['circle', 'country', 'flag', 'goal', 'mark', 'nation', 'report', 'round', 'start'],
    },
    {
      name: 'flaky',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'close',
        'complete',
        'contrast',
        'done',
        'exit',
        'flaky',
        'mark',
        'no',
        'ok',
        'options',
        'select',
        'stop',
        'tick',
        'verified',
        'x',
        'yes',
      ],
    },
    {
      name: 'flare',

      categories: ['image'],
      tags: [
        'bright',
        'edit',
        'editing',
        'effect',
        'flare',
        'image',
        'images',
        'light',
        'photography',
        'picture',
        'pictures',
        'sparkle',
        'sun',
      ],
    },
    {
      name: 'flash_auto',

      categories: ['image'],
      tags: ['a', 'auto', 'bolt', 'electric', 'fast', 'flash', 'lightning', 'thunderbolt'],
    },
    {
      name: 'flash_off',

      categories: ['image'],
      tags: [
        'bolt',
        'disabled',
        'electric',
        'enabled',
        'fast',
        'flash',
        'lightning',
        'off',
        'on',
        'slash',
        'thunderbolt',
      ],
    },
    {
      name: 'flash_on',

      categories: ['image'],
      tags: [
        'bolt',
        'disabled',
        'electric',
        'enabled',
        'fast',
        'flash',
        'lightning',
        'off',
        'on',
        'slash',
        'thunderbolt',
      ],
    },
    {
      name: 'flashlight_off',

      categories: ['device'],
      tags: ['disabled', 'enabled', 'flash', 'flashlight', 'light', 'off', 'on', 'slash'],
    },
    {
      name: 'flashlight_on',

      categories: ['device'],
      tags: ['disabled', 'enabled', 'flash', 'flashlight', 'light', 'off', 'on', 'slash'],
    },
    {
      name: 'flatware',

      categories: ['search'],
      tags: ['cafe', 'cafeteria', 'cutlery', 'diner', 'dining', 'eat', 'eating', 'fork', 'room', 'spoon'],
    },
    {
      name: 'flight',

      categories: ['maps'],
      tags: ['air', 'airplane', 'airport', 'flight', 'plane', 'transportation', 'travel', 'trip'],
    },
    {
      name: 'flight_class',

      categories: ['maps'],
      tags: [
        'airplane',
        'business',
        'class',
        'first',
        'flight',
        'plane',
        'seat',
        'transportation',
        'travel',
        'trip',
        'window',
      ],
    },
    {
      name: 'flight_land',

      categories: ['action'],
      tags: ['airport', 'arrival', 'arriving', 'flight', 'fly', 'land', 'landing', 'plane', 'transportation', 'travel'],
    },
    {
      name: 'flight_takeoff',

      categories: ['action'],
      tags: [
        'airport',
        'departed',
        'departing',
        'flight',
        'fly',
        'landing',
        'plane',
        'takeoff',
        'transportation',
        'travel',
      ],
    },
    {
      name: 'flip',

      categories: ['image'],
      tags: ['edit', 'editing', 'flip', 'image', 'orientation', 'scan scanning'],
    },
    {
      name: 'flip_camera_android',

      categories: ['image'],
      tags: [
        'android',
        'camera',
        'center',
        'edit',
        'editing',
        'flip',
        'image',
        'mobile',
        'orientation',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'flip_camera_ios',

      categories: ['image'],
      tags: [
        'android',
        'camera',
        'disable_ios',
        'edit',
        'editing',
        'flip',
        'image',
        'ios',
        'mobile',
        'orientation',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'flip_to_back',

      categories: ['action'],
      tags: ['arrange', 'arrangement', 'back', 'flip', 'format', 'front', 'layout', 'move', 'order', 'sort', 'to'],
    },
    {
      name: 'flip_to_front',

      categories: ['action'],
      tags: ['arrange', 'arrangement', 'back', 'flip', 'format', 'front', 'layout', 'move', 'order', 'sort', 'to'],
    },
    {
      name: 'flood',

      categories: ['social'],
      tags: ['crisis', 'disaster', 'natural', 'rain', 'storm', 'weather'],
    },
    {
      name: 'fluorescent',

      categories: ['device'],
      tags: ['bright', 'fluorescent', 'lamp', 'light', 'lightbulb'],
    },
    {
      name: 'flutter_dash',

      categories: ['action'],
      tags: ['bird', 'dash', 'flutter', 'mascot'],
    },
    {
      name: 'fmd_bad',

      categories: ['device'],
      tags: [
        '!',
        'alert',
        'attention',
        'bad',
        'caution',
        'danger',
        'destination',
        'direction',
        'error',
        'exclamation',
        'fmd',
        'important',
        'location',
        'maps',
        'mark',
        'notification',
        'pin',
        'place',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'fmd_good',

      categories: ['device'],
      tags: ['destination', 'direction', 'fmd', 'good', 'location', 'maps', 'pin', 'place', 'stop'],
    },
    {
      name: 'folder',

      categories: ['file'],
      tags: ['data', 'doc', 'document', 'drive', 'file', 'folder', 'folders', 'sheet', 'slide', 'storage'],
    },
    {
      name: 'folder_copy',

      categories: ['file'],
      tags: [
        'content',
        'copy',
        'cut',
        'data',
        'doc',
        'document',
        'drive',
        'duplicate',
        'file',
        'folder',
        'folders',
        'multiple',
        'paste',
        'sheet',
        'slide',
        'storage',
      ],
    },
    {
      name: 'folder_delete',

      categories: ['file'],
      tags: [
        'bin',
        'can',
        'data',
        'delete',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'garbage',
        'remove',
        'sheet',
        'slide',
        'storage',
        'trash',
      ],
    },
    {
      name: 'folder_off',

      categories: ['file'],
      tags: [
        '[online]',
        'data',
        'disabled',
        'doc',
        'document',
        'drive',
        'enabled',
        'file',
        'folder',
        'folders',
        'off',
        'on',
        'sheet',
        'slash',
        'slide',
        'storage',
      ],
    },
    {
      name: 'folder_open',

      categories: ['file'],
      tags: ['data', 'doc', 'document', 'drive', 'file', 'folder', 'folders', 'open', 'sheet', 'slide', 'storage'],
    },
    {
      name: 'folder_shared',

      categories: ['file'],
      tags: [
        'account',
        'collaboration',
        'data',
        'doc',
        'document',
        'drive',
        'face',
        'file',
        'folder',
        'human',
        'people',
        'person',
        'profile',
        'share',
        'shared',
        'sheet',
        'slide',
        'storage',
        'team',
        'user',
      ],
    },
    {
      name: 'folder_special',

      categories: ['notification'],
      tags: [
        'bookmark',
        'data',
        'doc',
        'document',
        'drive',
        'favorite',
        'file',
        'folder',
        'highlight',
        'important',
        'marked',
        'save',
        'saved',
        'shape',
        'sheet',
        'slide',
        'special',
        'star',
        'storage',
      ],
    },
    {
      name: 'folder_zip',

      categories: ['file'],
      tags: [
        'compress',
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'open',
        'sheet',
        'slide',
        'storage',
        'zip',
      ],
    },
    {
      name: 'follow_the_signs',

      categories: ['social'],
      tags: ['arrow', 'body', 'directional', 'follow', 'human', 'people', 'person', 'right', 'signs', 'social', 'the'],
    },
    {
      name: 'font_download',

      categories: ['content'],
      tags: ['A', 'alphabet', 'character', 'download', 'font', 'letter', 'square', 'symbol', 'text', 'type'],
    },
    {
      name: 'font_download_off',

      categories: ['content'],
      tags: [
        'alphabet',
        'character',
        'disabled',
        'download',
        'enabled',
        'font',
        'letter',
        'off',
        'on',
        'slash',
        'square',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'food_bank',

      categories: ['places'],
      tags: [
        'architecture',
        'bank',
        'building',
        'charity',
        'eat',
        'estate',
        'food',
        'fork',
        'house',
        'knife',
        'meal',
        'place',
        'real',
        'residence',
        'residential',
        'shelter',
        'utensils',
      ],
    },
    {
      name: 'forest',

      categories: ['maps'],
      tags: ['forest', 'jungle', 'nature', 'plantation', 'plants', 'trees', 'woodland'],
    },
    {
      name: 'fork_left',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'fork',
        'left',
        'maps',
        'navigation',
        'path',
        'route',
        'sign',
        'traffic',
      ],
    },
    {
      name: 'fork_right',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'fork',
        'maps',
        'navigation',
        'path',
        'right',
        'route',
        'sign',
        'traffic',
      ],
    },
    {
      name: 'format_align_center',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'center',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_align_justify',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'density',
        'doc',
        'edit',
        'editing',
        'editor',
        'extra',
        'format',
        'justify',
        'sheet',
        'small',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_align_left',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'left',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_align_right',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'right',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_bold',

      categories: ['editor'],
      tags: [
        'B',
        'alphabet',
        'bold',
        'character',
        'doc',
        'edit',
        'editing',
        'editor',
        'font',
        'format',
        'letter',
        'sheet',
        'spreadsheet',
        'styles',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_clear',

      categories: ['editor'],
      tags: [
        'T',
        'alphabet',
        'character',
        'clear',
        'disabled',
        'doc',
        'edit',
        'editing',
        'editor',
        'enabled',
        'font',
        'format',
        'letter',
        'off',
        'on',
        'sheet',
        'slash',
        'spreadsheet',
        'style',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_color_fill',

      categories: ['editor'],
      tags: [
        'bucket',
        'color',
        'doc',
        'edit',
        'editing',
        'editor',
        'fill',
        'format',
        'paint',
        'sheet',
        'spreadsheet',
        'style',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_color_reset',

      categories: ['editor'],
      tags: [
        'clear',
        'color',
        'disabled',
        'doc',
        'droplet',
        'edit',
        'editing',
        'editor',
        'enabled',
        'fill',
        'format',
        'off',
        'on',
        'paint',
        'reset',
        'sheet',
        'slash',
        'spreadsheet',
        'style',
        'text',
        'type',
        'water',
        'writing',
      ],
    },
    {
      name: 'format_color_text',

      categories: ['editor'],
      tags: [
        'color',
        'doc',
        'edit',
        'editing',
        'editor',
        'fill',
        'format',
        'paint',
        'sheet',
        'spreadsheet',
        'style',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_indent_decrease',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'decrease',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'indent',
        'indentation',
        'paragraph',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_indent_increase',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'increase',
        'indent',
        'indentation',
        'paragraph',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_italic',

      categories: ['editor'],
      tags: [
        'alphabet',
        'character',
        'doc',
        'edit',
        'editing',
        'editor',
        'font',
        'format',
        'italic',
        'letter',
        'sheet',
        'spreadsheet',
        'style',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_line_spacing',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'line',
        'sheet',
        'spacing',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_list_bulleted',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'bulleted',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'list',
        'notes',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_list_numbered',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'digit',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'list',
        'notes',
        'number',
        'numbered',
        'sheet',
        'spreadsheet',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_list_numbered_rtl',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'digit',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'list',
        'notes',
        'number',
        'numbered',
        'rtl',
        'sheet',
        'spreadsheet',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_overline',

      categories: ['file'],
      tags: [
        'alphabet',
        'character',
        'doc',
        'edit',
        'editing',
        'editor',
        'font',
        'format',
        'letter',
        'line',
        'overline',
        'sheet',
        'spreadsheet',
        'style',
        'symbol',
        'text',
        'type',
        'under',
        'writing',
      ],
    },
    {
      name: 'format_paint',

      categories: ['editor'],
      tags: [
        'brush',
        'color',
        'doc',
        'edit',
        'editing',
        'editor',
        'fill',
        'format',
        'paint',
        'roller',
        'sheet',
        'spreadsheet',
        'style',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_quote',

      categories: ['editor'],
      tags: [
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'quotation',
        'quote',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_shapes',

      categories: ['editor'],
      tags: [
        'alphabet',
        'character',
        'color',
        'doc',
        'edit',
        'editing',
        'editor',
        'fill',
        'font',
        'format',
        'letter',
        'paint',
        'shapes',
        'sheet',
        'spreadsheet',
        'style',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_size',

      categories: ['editor'],
      tags: [
        'alphabet',
        'character',
        'color',
        'doc',
        'edit',
        'editing',
        'editor',
        'fill',
        'font',
        'format',
        'letter',
        'paint',
        'sheet',
        'size',
        'spreadsheet',
        'style',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_strikethrough',

      categories: ['editor'],
      tags: [
        'alphabet',
        'character',
        'doc',
        'edit',
        'editing',
        'editor',
        'font',
        'format',
        'letter',
        'sheet',
        'spreadsheet',
        'strikethrough',
        'style',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_textdirection_l_to_r',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'ltr',
        'sheet',
        'spreadsheet',
        'text',
        'textdirection',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_textdirection_r_to_l',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'doc',
        'edit',
        'editing',
        'editor',
        'format',
        'rtl',
        'sheet',
        'spreadsheet',
        'text',
        'textdirection',
        'type',
        'writing',
      ],
    },
    {
      name: 'format_underlined',

      categories: ['editor'],
      tags: [
        'alphabet',
        'character',
        'doc',
        'edit',
        'editing',
        'editor',
        'font',
        'format',
        'letter',
        'line',
        'sheet',
        'spreadsheet',
        'style',
        'symbol',
        'text',
        'type',
        'under',
        'underlined',
        'writing',
      ],
    },
    {
      name: 'fort',

      categories: ['maps'],
      tags: ['castle', 'fort', 'fortress', 'mansion', 'palace'],
    },
    {
      name: 'forum',

      categories: ['communication'],
      tags: [
        'bubble',
        'chat',
        'comment',
        'communicate',
        'community',
        'conversation',
        'feedback',
        'forum',
        'hub',
        'message',
        'speech',
      ],
    },
    {
      name: 'forward',

      categories: ['content'],
      tags: ['arrow', 'forward', 'mail', 'message', 'playback', 'right', 'sent'],
    },
    {
      name: 'forward_10',

      categories: ['av'],
      tags: [
        '10',
        'arrow',
        'control',
        'controls',
        'digit',
        'fast',
        'forward',
        'music',
        'number',
        'play',
        'seconds',
        'symbol',
        'video',
      ],
    },
    {
      name: 'forward_30',

      categories: ['av'],
      tags: [
        '30',
        'arrow',
        'control',
        'controls',
        'digit',
        'fast',
        'forward',
        'music',
        'number',
        'seconds',
        'symbol',
        'video',
      ],
    },
    {
      name: 'forward_5',

      categories: ['av'],
      tags: [
        '10',
        '5',
        'arrow',
        'control',
        'controls',
        'digit',
        'fast',
        'forward',
        'music',
        'number',
        'seconds',
        'symbol',
        'video',
      ],
    },
    {
      name: 'forward_to_inbox',

      categories: ['communication'],
      tags: ['arrow', 'email', 'envelop', 'forward', 'inbox', 'letter', 'mail', 'message', 'send', 'to'],
    },
    {
      name: 'foundation',

      categories: ['places'],
      tags: [
        'architecture',
        'base',
        'basis',
        'building',
        'construction',
        'estate',
        'foundation',
        'home',
        'house',
        'real',
        'residential',
      ],
    },
    {
      name: 'free_breakfast',

      categories: ['places'],
      tags: ['beverage', 'breakfast', 'cafe', 'coffee', 'cup', 'drink', 'free', 'mug', 'tea'],
    },
    {
      name: 'free_cancellation',

      categories: ['action'],
      tags: [
        'approve',
        'calendar',
        'cancel',
        'cancellation',
        'check',
        'complete',
        'date',
        'day',
        'done',
        'event',
        'exit',
        'free',
        'mark',
        'month',
        'no',
        'ok',
        'remove',
        'schedule',
        'select',
        'stop',
        'tick',
        'validate',
        'verified',
        'x',
        'yes',
      ],
    },
    {
      name: 'front_hand',

      categories: ['social'],
      tags: ['fingers', 'front', 'gesture', 'hand', 'hello', 'palm', 'stop'],
    },
    {
      name: 'fullscreen',

      categories: ['navigation'],
      tags: [
        'adjust',
        'app',
        'application',
        'components',
        'full',
        'fullscreen',
        'interface',
        'screen',
        'site',
        'size',
        'ui',
        'ux',
        'view',
        'web',
        'website',
      ],
    },
    {
      name: 'fullscreen_exit',

      categories: ['navigation'],
      tags: [
        'adjust',
        'app',
        'application',
        'components',
        'exit',
        'full',
        'fullscreen',
        'interface',
        'screen',
        'site',
        'size',
        'ui',
        'ux',
        'view',
        'web',
        'website',
      ],
    },
    {
      name: 'functions',

      categories: ['editor'],
      tags: [
        'average',
        'calculate',
        'count',
        'custom',
        'doc',
        'edit',
        'editing',
        'editor',
        'functions',
        'math',
        'sheet',
        'spreadsheet',
        'style',
        'sum',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'g_mobiledata',

      categories: ['device'],
      tags: [
        'alphabet',
        'character',
        'data',
        'font',
        'g',
        'letter',
        'mobile',
        'network',
        'service',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'g_translate',

      categories: ['action'],
      tags: [
        'emblem',
        'g',
        'google',
        'language',
        'logo',
        'mark',
        'speaking',
        'speech',
        'translate',
        'translator',
        'words',
      ],
    },
    {
      name: 'gamepad',

      categories: ['hardware'],
      tags: ['buttons', 'console', 'controller', 'device', 'game', 'gamepad', 'gaming', 'playstation', 'video'],
    },
    {
      name: 'games',

      categories: ['av'],
      tags: [
        'adjust',
        'arrow',
        'arrows',
        'control',
        'controller',
        'direction',
        'games',
        'gaming',
        'left',
        'move',
        'right',
      ],
    },
    {
      name: 'garage',

      categories: ['search'],
      tags: [
        'automobile',
        'automotive',
        'car',
        'cars',
        'direction',
        'garage',
        'maps',
        'transportation',
        'travel',
        'vehicle',
      ],
    },
    {
      name: 'gas_meter',

      categories: ['home'],
      tags: ['droplet', 'energy', 'gas', 'measure', 'meter', 'nest', 'usage', 'water'],
    },
    {
      name: 'gavel',

      categories: ['action'],
      tags: [
        'agreement',
        'contract',
        'court',
        'document',
        'gavel',
        'government',
        'judge',
        'law',
        'mallet',
        'official',
        'police',
        'rule',
        'rules',
        'terms',
      ],
    },
    {
      name: 'generating_tokens',

      categories: ['action'],
      tags: ['access', 'api', 'coin', 'generating', 'shape', 'stars', 'tokens'],
    },
    {
      name: 'gesture',

      categories: ['content'],
      tags: ['drawing', 'finger', 'gesture', 'gestures', 'hand', 'motion'],
    },
    {
      name: 'get_app',

      categories: ['action'],
      tags: ['app', 'arrow', 'arrows', 'down', 'download', 'downloads', 'export', 'get', 'install', 'play', 'upload'],
    },
    {
      name: 'gif',

      categories: ['action'],
      tags: [
        'alphabet',
        'animated',
        'animation',
        'bitmap',
        'character',
        'font',
        'format',
        'gif',
        'graphics',
        'interchange',
        'letter',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'gif_box',

      categories: ['action'],
      tags: [
        'alphabet',
        'animated',
        'animation',
        'bitmap',
        'character',
        'font',
        'format',
        'gif',
        'graphics',
        'interchange',
        'letter',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'girl',

      categories: ['social'],
      tags: [
        'body',
        'female',
        'gender',
        'girl',
        'human',
        'lady',
        'people',
        'person',
        'social',
        'symbol',
        'woman',
        'women',
      ],
    },
    {
      name: 'gite',

      categories: ['places'],
      tags: [
        'architecture',
        'estate',
        'gite',
        'home',
        'hostel',
        'house',
        'maps',
        'place',
        'real',
        'residence',
        'residential',
        'stay',
        'traveling',
      ],
    },
    {
      name: 'golf_course',

      categories: ['places'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'club',
        'course',
        'entertainment',
        'flag',
        'golf',
        'golfer',
        'golfing',
        'hobby',
        'hole',
        'places',
        'putt',
        'sports',
      ],
    },
    {
      name: 'gpp_bad',

      categories: ['device'],
      tags: [
        'bad',
        'cancel',
        'certified',
        'close',
        'error',
        'exit',
        'gpp',
        'no',
        'privacy',
        'private',
        'protect',
        'protection',
        'remove',
        'security',
        'shield',
        'sim',
        'stop',
        'verified',
        'x',
      ],
    },
    {
      name: 'gpp_good',

      categories: ['device'],
      tags: ['certified', 'check', 'good', 'gpp', 'ok', 'pass', 'security', 'shield', 'sim', 'tick'],
    },
    {
      name: 'gpp_maybe',

      categories: ['device'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'certified',
        'danger',
        'error',
        'exclamation',
        'gpp',
        'important',
        'mark',
        'maybe',
        'notification',
        'privacy',
        'private',
        'protect',
        'protection',
        'security',
        'shield',
        'sim',
        'symbol',
        'verified',
        'warning',
      ],
    },
    {
      name: 'gps_fixed',

      categories: ['device'],
      tags: [
        'destination',
        'direction',
        'fixed',
        'gps',
        'location',
        'maps',
        'pin',
        'place',
        'pointer',
        'stop',
        'tracking',
      ],
    },
    {
      name: 'gps_not_fixed',

      categories: ['device'],
      tags: [
        'destination',
        'direction',
        'disabled',
        'enabled',
        'gps',
        'location',
        'maps',
        'not fixed',
        'off',
        'on',
        'online',
        'place',
        'pointer',
        'slash',
        'tracking',
      ],
    },
    {
      name: 'gps_off',

      categories: ['device'],
      tags: [
        'destination',
        'direction',
        'disabled',
        'enabled',
        'gps',
        'location',
        'maps',
        'not fixed',
        'off',
        'offline',
        'on',
        'place',
        'pointer',
        'slash',
        'tracking',
      ],
    },
    {
      name: 'grade',

      categories: ['action'],
      tags: [
        '\u0027favorite_news\u0027  .',
        '\u0027star_outline\u0027',
        'Duplicate of \u0027star_boarder\u0027',
        'star_border_purple500\u0027',
      ],
    },
    {
      name: 'gradient',

      categories: ['image'],
      tags: [
        'color',
        'edit',
        'editing',
        'effect',
        'filter',
        'gradient',
        'image',
        'images',
        'photography',
        'picture',
        'pictures',
      ],
    },
    {
      name: 'grading',

      categories: ['action'],
      tags: [
        '\u0027favorite\u0027_new\u0027.  \u0027 Remove this icon \u0026 keep \u0027star\u0027.',
        '\u0027star_boarder\u0027',
        '\u0027star_border_purple500\u0027',
        '\u0027star_outline\u0027',
        '\u0027star_purple500\u0027',
        '\u0027star_rate\u0027',
        'Same as \u0027star\u0027',
      ],
    },
    {
      name: 'grain',

      categories: ['image'],
      tags: [
        'dots',
        'edit',
        'editing',
        'effect',
        'filter',
        'grain',
        'image',
        'images',
        'photography',
        'picture',
        'pictures',
      ],
    },
    {
      name: 'graphic_eq',

      categories: ['device'],
      tags: ['audio', 'eq', 'equalizer', 'graphic', 'music', 'recording', 'sound', 'voice'],
    },
    {
      name: 'grass',

      categories: ['places'],
      tags: ['backyard', 'fodder', 'grass', 'ground', 'home', 'lawn', 'plant', 'turf', 'yard'],
    },
    {
      name: 'grid_3x3',

      categories: ['device'],
      tags: ['3', 'grid', 'layout', 'line', 'space'],
    },
    {
      name: 'grid_4x4',

      categories: ['device'],
      tags: ['4', 'by', 'grid', 'layout', 'lines', 'space'],
    },
    {
      name: 'grid_goldenratio',

      categories: ['device'],
      tags: ['golden', 'goldenratio', 'grid', 'layout', 'lines', 'ratio', 'space'],
    },
    {
      name: 'grid_off',

      categories: ['image'],
      tags: ['collage', 'disabled', 'enabled', 'grid', 'image', 'layout', 'off', 'on', 'slash', 'view'],
    },
    {
      name: 'grid_on',

      categories: ['image'],
      tags: ['collage', 'disabled', 'enabled', 'grid', 'image', 'layout', 'off', 'on', 'slash', 'view'],
    },
    {
      name: 'grid_view',

      categories: ['file'],
      tags: [
        'app',
        'application square',
        'blocks',
        'components',
        'dashboard',
        'design',
        'grid',
        'interface',
        'layout',
        'screen',
        'site',
        'tiles',
        'ui',
        'ux',
        'view',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'group',

      categories: ['social'],
      tags: [
        'accounts',
        'committee',
        'face',
        'family',
        'friends',
        'group',
        'humans',
        'network',
        'people',
        'persons',
        'profiles',
        'social',
        'team',
        'users',
      ],
    },
    {
      name: 'group_add',

      categories: ['social'],
      tags: [
        'accounts',
        'add',
        'committee',
        'face',
        'family',
        'friends',
        'group',
        'humans',
        'increase',
        'more',
        'network',
        'people',
        'persons',
        'plus',
        'profiles',
        'social',
        'team',
        'users',
      ],
    },
    {
      name: 'group_off',

      categories: ['social'],
      tags: [
        'body',
        'club',
        'collaboration',
        'crowd',
        'gathering',
        'group',
        'human',
        'meeting',
        'off',
        'people',
        'person',
        'social',
        'teams',
      ],
    },
    {
      name: 'group_remove',

      categories: ['social'],
      tags: [
        'accounts',
        'committee',
        'face',
        'family',
        'friends',
        'group',
        'humans',
        'network',
        'people',
        'persons',
        'profiles',
        'remove',
        'social',
        'team',
        'users',
      ],
    },
    {
      name: 'group_work',

      categories: ['action'],
      tags: ['alliance', 'collaboration', 'group', 'partnership', 'team', 'teamwork', 'together', 'work'],
    },
    {
      name: 'groups',

      categories: ['social'],
      tags: [
        'body',
        'club',
        'collaboration',
        'crowd',
        'gathering',
        'groups',
        'human',
        'meeting',
        'people',
        'person',
        'social',
        'teams',
      ],
    },
    {
      name: 'groups_2',

      categories: ['social'],
      tags: [
        'body',
        'club',
        'collaboration',
        'crowd',
        'gathering',
        'groups',
        'hair',
        'human',
        'meeting',
        'people',
        'person',
        'social',
        'teams',
      ],
    },
    {
      name: 'groups_3',

      categories: ['social'],
      tags: [
        'abstract',
        'body',
        'club',
        'collaboration',
        'crowd',
        'gathering',
        'groups',
        'human',
        'meeting',
        'people',
        'person',
        'social',
        'teams',
      ],
    },
    {
      name: 'h_mobiledata',

      categories: ['device'],
      tags: [
        'alphabet',
        'character',
        'data',
        'font',
        'h',
        'letter',
        'mobile',
        'network',
        'service',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'h_plus_mobiledata',

      categories: ['device'],
      tags: [
        '+',
        'alphabet',
        'character',
        'data',
        'font',
        'h',
        'letter',
        'mobile',
        'network',
        'plus',
        'service',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hail',

      categories: ['maps'],
      tags: ['body', 'hail', 'human', 'people', 'person', 'pick', 'public', 'stop', 'taxi', 'transportation'],
    },
    {
      name: 'handshake',

      categories: ['social'],
      tags: ['agreement', 'hand', 'hands', 'partnership', 'shake'],
    },
    {
      name: 'handyman',

      categories: ['maps'],
      tags: ['build', 'construction', 'fix', 'hammer', 'handyman', 'repair', 'screw', 'screwdriver', 'tools'],
    },
    {
      name: 'hardware',

      categories: ['maps'],
      tags: ['break', 'construction', 'hammer', 'hardware', 'nail', 'repair', 'tool'],
    },
    {
      name: 'hd',

      categories: ['av'],
      tags: [
        'alphabet',
        'character',
        'definition',
        'display',
        'font',
        'hd',
        'high',
        'letter',
        'movie',
        'movies',
        'resolution',
        'screen',
        'symbol',
        'text',
        'tv',
        'type',
      ],
    },
    {
      name: 'hdr_auto',

      categories: ['device'],
      tags: [
        'A',
        'alphabet',
        'auto',
        'camera',
        'character',
        'circle',
        'dynamic',
        'font',
        'hdr',
        'high',
        'letter',
        'photo',
        'range',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hdr_auto_select',

      categories: ['device'],
      tags: [
        '+',
        'A',
        'alphabet',
        'auto',
        'camera',
        'character',
        'circle',
        'dynamic',
        'font',
        'hdr',
        'high',
        'letter',
        'photo',
        'range',
        'select',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hdr_enhanced_select',

      categories: ['image'],
      tags: [
        'add',
        'alphabet',
        'character',
        'dynamic',
        'enhance',
        'font',
        'hdr',
        'high',
        'letter',
        'plus',
        'range',
        'select',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hdr_off',

      categories: ['image'],
      tags: [
        'alphabet',
        'character',
        'disabled',
        'dynamic',
        'enabled',
        'enhance',
        'font',
        'hdr',
        'high',
        'letter',
        'off',
        'on',
        'range',
        'select',
        'slash',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hdr_off_select',

      categories: ['device'],
      tags: [
        'alphabet',
        'camera',
        'character',
        'circle',
        'disabled',
        'dynamic',
        'enabled',
        'font',
        'hdr',
        'high',
        'letter',
        'off',
        'on',
        'photo',
        'range',
        'select',
        'slash',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hdr_on',

      categories: ['image'],
      tags: [
        'add',
        'alphabet',
        'character',
        'dynamic',
        'enhance',
        'font',
        'hdr',
        'high',
        'letter',
        'on',
        'plus',
        'range',
        'select',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hdr_on_select',

      categories: ['device'],
      tags: [
        '+',
        'alphabet',
        'camera',
        'character',
        'circle',
        'dynamic',
        'font',
        'hdr',
        'high',
        'letter',
        'on',
        'photo',
        'range',
        'select',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hdr_plus',

      categories: ['image'],
      tags: [
        '+',
        'add',
        'alphabet',
        'character',
        'circle',
        'dynamic',
        'enhance',
        'font',
        'hdr',
        'high',
        'letter',
        'plus',
        'range',
        'select',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hdr_strong',

      categories: ['image'],
      tags: ['circles', 'dots', 'dynamic', 'enhance', 'hdr', 'high', 'range', 'strong'],
    },
    {
      name: 'hdr_weak',

      categories: ['image'],
      tags: ['circles', 'dots', 'dynamic', 'enhance', 'hdr', 'high', 'range', 'weak'],
    },
    {
      name: 'headphones',

      categories: ['hardware'],
      tags: ['accessory', 'audio', 'device', 'ear', 'earphone', 'headphones', 'headset', 'listen', 'music', 'sound'],
    },
    {
      name: 'headphones_battery',

      categories: ['hardware'],
      tags: [
        'accessory',
        'audio',
        'battery',
        'charging',
        'device',
        'ear',
        'earphone',
        'headphones',
        'headset',
        'listen',
        'music',
        'sound',
      ],
    },
    {
      name: 'headset',

      categories: ['hardware'],
      tags: ['accessory', 'audio', 'device', 'ear', 'earphone', 'headphones', 'headset', 'listen', 'music', 'sound'],
    },
    {
      name: 'headset_mic',

      categories: ['hardware'],
      tags: [
        'accessory',
        'audio',
        'chat',
        'device',
        'ear',
        'earphone',
        'headphones',
        'headset',
        'listen',
        'mic',
        'music',
        'sound',
        'talk',
      ],
    },
    {
      name: 'headset_off',

      categories: ['hardware'],
      tags: [
        'accessory',
        'audio',
        'chat',
        'device',
        'disabled',
        'ear',
        'earphone',
        'enabled',
        'headphones',
        'headset',
        'listen',
        'mic',
        'music',
        'off',
        'on',
        'slash',
        'sound',
        'talk',
      ],
    },
    {
      name: 'healing',

      categories: ['image'],
      tags: ['bandage', 'edit', 'editing', 'emergency', 'fix', 'healing', 'hospital', 'image', 'medicine'],
    },
    {
      name: 'health_and_safety',

      categories: ['social'],
      tags: [
        '+',
        'add',
        'and',
        'certified',
        'cross',
        'health',
        'home',
        'nest',
        'plus',
        'privacy',
        'private',
        'protect',
        'protection',
        'safety',
        'security',
        'shield',
        'symbol',
        'verified',
      ],
    },
    {
      name: 'hearing',

      categories: ['av'],
      tags: [
        'accessibility',
        'accessible',
        'aid',
        'ear',
        'handicap',
        'hearing',
        'help',
        'impaired',
        'listen',
        'sound',
        'volume',
      ],
    },
    {
      name: 'hearing_disabled',

      categories: ['av'],
      tags: [
        'accessibility',
        'accessible',
        'aid',
        'disabled',
        'ear',
        'enabled',
        'handicap',
        'hearing',
        'help',
        'impaired',
        'listen',
        'off',
        'on',
        'slash',
        'sound',
        'volume',
      ],
    },
    {
      name: 'heart_broken',

      categories: ['social'],
      tags: ['break', 'broken', 'core', 'crush', 'health', 'heart', 'nucleus', 'split'],
    },
    {
      name: 'heat_pump',

      categories: ['home'],
      tags: ['air conditioner', 'cool', 'energy', 'furnance', 'heat', 'nest', 'pump', 'usage'],
    },
    {
      name: 'height',

      categories: ['editor'],
      tags: [
        'arrow',
        'color',
        'doc',
        'down',
        'edit',
        'editing',
        'editor',
        'fill',
        'format',
        'height',
        'paint',
        'sheet',
        'spreadsheet',
        'style',
        'text',
        'type',
        'up',
        'writing',
      ],
    },
    {
      name: 'help',

      categories: ['action'],
      tags: [
        '?',
        'assistance',
        'circle',
        'help',
        'info',
        'information',
        'punctuation',
        'question mark',
        'shape',
        'support',
        'symbol',
      ],
    },
    {
      name: 'help_center',

      categories: ['action'],
      tags: [
        '?',
        'assistance',
        'center',
        'help',
        'info',
        'information',
        'punctuation',
        'question mark',
        'support',
        'symbol',
      ],
    },
    {
      name: 'help_outline',

      categories: ['action'],
      tags: [
        '?',
        'assistance',
        'circle',
        'help',
        'info',
        'information',
        'outline',
        'punctuation',
        'question mark',
        'shape',
        'support',
        'symbol',
      ],
    },
    {
      name: 'hevc',

      categories: ['image'],
      tags: [
        'alphabet',
        'character',
        'coding',
        'efficiency',
        'font',
        'hevc',
        'high',
        'letter',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: 'hexagon',

      categories: ['editor'],
      tags: ['hexagon', 'shape', 'six sides'],
    },
    {
      name: 'hide_image',

      categories: ['image'],
      tags: [
        'disabled',
        'enabled',
        'hide',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'off',
        'on',
        'photo',
        'photography',
        'picture',
        'slash',
      ],
    },
    {
      name: 'hide_source',

      categories: ['action'],
      tags: ['circle', 'disabled', 'enabled', 'hide', 'off', 'offline', 'on', 'shape', 'slash', 'source'],
    },
    {
      name: 'high_quality',

      categories: ['av'],
      tags: [
        'alphabet',
        'character',
        'definition',
        'display',
        'font',
        'high',
        'hq',
        'letter',
        'movie',
        'movies',
        'quality',
        'resolution',
        'screen',
        'symbol',
        'text',
        'tv',
        'type',
      ],
    },
    {
      name: 'highlight',

      categories: ['editor'],
      tags: [
        'color',
        'doc',
        'edit',
        'editing',
        'editor',
        'emphasize',
        'fill',
        'flash',
        'format',
        'highlight',
        'light',
        'paint',
        'sheet',
        'spreadsheet',
        'style',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'highlight_alt',

      categories: ['action'],
      tags: [
        'alt',
        'arrow',
        'box',
        'click',
        'cursor',
        'draw',
        'focus',
        'highlight',
        'pointer',
        'select',
        'selection',
        'target',
      ],
    },
    {
      name: 'highlight_off',

      categories: ['action'],
      tags: ['cancel', 'close', 'exit', 'highlight', 'no', 'off', 'quit', 'remove', 'stop', 'x'],
    },
    {
      name: 'hiking',

      categories: ['social'],
      tags: [
        'backpacking',
        'bag',
        'climbing',
        'duffle',
        'hiking',
        'mountain',
        'social',
        'sports',
        'stick',
        'trail',
        'travel',
        'walking',
      ],
    },
    {
      name: 'history',

      categories: ['action'],
      tags: [
        'arrow',
        'back',
        'backwards',
        'clock',
        'date',
        'history',
        'refresh',
        'renew',
        'reverse',
        'rotate',
        'schedule',
        'time',
        'turn',
      ],
    },
    {
      name: 'history_edu',

      categories: ['social'],
      tags: [
        'document',
        'edu',
        'education',
        'feather',
        'history',
        'letter',
        'paper',
        'pen',
        'quill',
        'school',
        'story',
        'tools',
        'write',
        'writing',
      ],
    },
    {
      name: 'history_toggle_off',

      categories: ['action'],
      tags: ['clock', 'date', 'history', 'off', 'schedule', 'time', 'toggle'],
    },
    {
      name: 'hive',

      categories: ['social'],
      tags: ['bee', 'honey', 'honeycomb'],
    },
    {
      name: 'hls',

      categories: ['action'],
      tags: [
        'alphabet',
        'character',
        'develop',
        'developer',
        'engineer',
        'engineering',
        'font',
        'hls',
        'letter',
        'platform',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'hls_off',

      categories: ['action'],
      tags: [
        '[offline]',
        'alphabet',
        'character',
        'develop',
        'developer',
        'disabled',
        'enabled',
        'engineer',
        'engineering',
        'font',
        'hls',
        'letter',
        'off',
        'on',
        'platform',
        'slash',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'holiday_village',

      categories: ['places'],
      tags: [
        'architecture',
        'beach',
        'camping',
        'cottage',
        'estate',
        'holiday',
        'home',
        'house',
        'lake',
        'lodge',
        'maps',
        'place',
        'real',
        'residence',
        'residential',
        'stay',
        'traveling',
        'vacation',
        'village',
      ],
    },
    {
      name: 'home',

      categories: ['action'],
      tags: [
        'address',
        'app',
        'application--house',
        'architecture',
        'building',
        'components',
        'design',
        'estate',
        'home',
        'interface',
        'layout',
        'place',
        'real',
        'residence',
        'residential',
        'screen',
        'shelter',
        'site',
        'structure',
        'ui',
        'unit',
        'ux',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'home_max',

      categories: ['hardware'],
      tags: ['device', 'gadget', 'hardware', 'home', 'internet', 'iot', 'max', 'nest', 'smart', 'things'],
    },
    {
      name: 'home_mini',

      categories: ['hardware'],
      tags: ['Internet', 'device', 'gadget', 'hardware', 'home', 'iot', 'mini', 'nest', 'smart', 'things'],
    },
    {
      name: 'home_repair_service',

      categories: ['maps'],
      tags: [
        'box',
        'equipment',
        'fix',
        'home',
        'kit',
        'mechanic',
        'repair',
        'repairing',
        'service',
        'tool',
        'toolbox',
        'tools',
        'workshop',
      ],
    },
    {
      name: 'home_work',

      categories: ['navigation'],
      tags: [
        'architecture',
        'building',
        'estate',
        'home',
        'place',
        'real',
        'residence',
        'residential',
        'shelter',
        'work',
      ],
    },
    {
      name: 'horizontal_distribute',

      categories: ['editor'],
      tags: [
        'alignment',
        'distribute',
        'format',
        'horizontal',
        'layout',
        'lines',
        'paragraph',
        'rule',
        'rules',
        'style',
        'text',
      ],
    },
    {
      name: 'horizontal_rule',

      categories: ['editor'],
      tags: ['gmail', 'horizontal', 'line', 'novitas', 'rule'],
    },
    {
      name: 'horizontal_split',

      categories: ['action'],
      tags: ['bars', 'format', 'horizontal', 'layout', 'lines', 'split', 'stacked'],
    },
    {
      name: 'hot_tub',

      categories: ['places'],
      tags: [
        'bath',
        'bathing',
        'bathroom',
        'bathtub',
        'hot',
        'hotel',
        'human',
        'jacuzzi',
        'person',
        'shower',
        'spa',
        'steam',
        'travel',
        'tub',
        'water',
      ],
    },
    {
      name: 'hotel',

      categories: ['maps'],
      tags: ['body', 'hotel', 'human', 'people', 'person', 'sleep', 'stay', 'travel', 'trip'],
    },
    {
      name: 'hotel_class',

      categories: ['action'],
      tags: [
        'achievement',
        'bookmark',
        'class',
        'favorite',
        'highlight',
        'hotel',
        'important',
        'marked',
        'rank',
        'ranking',
        'rate',
        'rating',
        'reward',
        'save',
        'saved',
        'shape',
        'special',
        'star',
      ],
    },
    {
      name: 'hourglass_bottom',

      categories: ['communication'],
      tags: ['bottom', 'countdown', 'half', 'hourglass', 'loading', 'minute', 'minutes', 'time', 'wait', 'waiting'],
    },
    {
      name: 'hourglass_disabled',

      categories: ['action'],
      tags: [
        'clock',
        'countdown',
        'disabled',
        'empty',
        'enabled',
        'hourglass',
        'loading',
        'minute',
        'minutes',
        'off',
        'on',
        'slash',
        'time',
        'wait',
        'waiting',
      ],
    },
    {
      name: 'hourglass_empty',

      categories: ['action'],
      tags: ['countdown', 'empty', 'hourglass', 'loading', 'minutes', 'time', 'wait', 'waiting'],
    },
    {
      name: 'hourglass_full',

      categories: ['action'],
      tags: ['countdown', 'full', 'hourglass', 'loading', 'minutes', 'time', 'wait', 'waiting'],
    },
    {
      name: 'hourglass_top',

      categories: ['communication'],
      tags: ['countdown', 'half', 'hourglass', 'loading', 'minute', 'minutes', 'time', 'top', 'wait', 'waiting'],
    },
    {
      name: 'house',

      categories: ['places'],
      tags: [
        'architecture',
        'building',
        'estate',
        'family',
        'home',
        'homepage',
        'house',
        'place',
        'places',
        'real',
        'residence',
        'residential',
        'shelter',
      ],
    },
    {
      name: 'house_siding',

      categories: ['places'],
      tags: [
        'architecture',
        'building',
        'construction',
        'estate',
        'exterior',
        'facade',
        'home',
        'house',
        'real',
        'residential',
        'siding',
      ],
    },
    {
      name: 'houseboat',

      categories: ['places'],
      tags: [
        'architecture',
        'beach',
        'boat',
        'estate',
        'floating',
        'home',
        'house',
        'houseboat',
        'maps',
        'place',
        'real',
        'residence',
        'residential',
        'sea',
        'stay',
        'traveling',
        'vacation',
      ],
    },
    {
      name: 'how_to_reg',

      categories: ['content'],
      tags: [
        'approve',
        'ballot',
        'check',
        'complete',
        'done',
        'election',
        'how',
        'mark',
        'ok',
        'poll',
        'register',
        'registration',
        'select',
        'tick',
        'to reg',
        'validate',
        'verified',
        'vote',
        'yes',
      ],
    },
    {
      name: 'how_to_vote',

      categories: ['content'],
      tags: ['ballot', 'election', 'how', 'poll', 'to', 'vote'],
    },
    {
      name: 'html',

      categories: ['action'],
      tags: [
        'alphabet',
        'brackets',
        'character',
        'code',
        'css',
        'develop',
        'developer',
        'engineer',
        'engineering',
        'font',
        'html',
        'letter',
        'platform',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'http',

      categories: ['action'],
      tags: ['alphabet', 'character', 'font', 'http', 'letter', 'symbol', 'text', 'transfer', 'type', 'url', 'website'],
    },
    {
      name: 'https',

      categories: ['action'],
      tags: ['https', 'lock', 'locked', 'password', 'privacy', 'private', 'protection', 'safety', 'secure', 'security'],
    },
    {
      name: 'hub',

      categories: ['communication'],
      tags: ['center', 'connection', 'core', 'focal point', 'hub', 'network', 'nucleus', 'topology'],
    },
    {
      name: 'hvac',

      categories: ['maps'],
      tags: ['air', 'conditioning', 'heating', 'hvac', 'ventilation'],
    },
    {
      name: 'ice_skating',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'entertainment',
        'exercise',
        'hobby',
        'ice',
        'shoe',
        'skates',
        'skating',
        'social',
        'sports',
        'travel',
      ],
    },
    {
      name: 'icecream',

      categories: ['maps'],
      tags: ['cream', 'dessert', 'food', 'ice', 'icecream', 'snack'],
    },
    {
      name: 'image',

      categories: ['image'],
      tags: [
        'disabled',
        'enabled',
        'hide',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'off',
        'on',
        'photo',
        'photography',
        'picture',
        'slash',
      ],
    },
    {
      name: 'image_aspect_ratio',

      categories: ['image'],
      tags: ['aspect', 'image', 'photo', 'photography', 'picture', 'ratio', 'rectangle', 'square'],
    },
    {
      name: 'image_not_supported',

      categories: ['image'],
      tags: [
        'disabled',
        'enabled',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'not',
        'off',
        'on',
        'photo',
        'photography',
        'picture',
        'slash',
        'supported',
      ],
    },
    {
      name: 'image_search',

      categories: ['image'],
      tags: [
        'find',
        'glass',
        'image',
        'landscape',
        'look',
        'magnify',
        'magnifying',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'search',
        'see',
      ],
    },
    {
      name: 'imagesearch_roller',

      categories: ['notification'],
      tags: ['art', 'image', 'imagesearch', 'paint', 'roller', 'search'],
    },
    {
      name: 'import_contacts',

      categories: ['communication'],
      tags: ['address', 'book', 'contacts', 'import', 'info', 'information', 'open'],
    },
    {
      name: 'import_export',

      categories: ['communication'],
      tags: ['arrow', 'arrows', 'direction', 'down', 'explort', 'import', 'up'],
    },
    {
      name: 'important_devices',

      categories: ['action'],
      tags: [
        'Android',
        'OS',
        'desktop',
        'devices',
        'hardware',
        'iOS',
        'important',
        'mobile',
        'monitor',
        'phone',
        'star',
        'tablet',
        'web',
      ],
    },
    {
      name: 'inbox',

      categories: ['content'],
      tags: ['archive', 'email', 'inbox', 'incoming', 'mail', 'message'],
    },
    {
      name: 'incomplete_circle',

      categories: ['image'],
      tags: ['chart', 'circle', 'incomplete'],
    },
    {
      name: 'indeterminate_check_box',

      categories: ['toggle'],
      tags: [
        'app',
        'application',
        'box',
        'button',
        'check',
        'components',
        'control',
        'design',
        'form',
        'indeterminate',
        'interface',
        'screen',
        'select',
        'selected',
        'selection',
        'site',
        'square',
        'toggle',
        'ui',
        'undetermined',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'info',

      categories: ['action'],
      tags: [
        'alert',
        'announcement',
        'assistance',
        'details',
        'help',
        'i',
        'info',
        'information',
        'service',
        'support',
      ],
    },
    {
      name: 'input',

      categories: ['action'],
      tags: ['arrow', 'box', 'download', 'input', 'login', 'move', 'right'],
    },
    {
      name: 'insert_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'insert',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'insert_chart_outlined',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'insert',
        'measure',
        'metrics',
        'outlined',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'insert_comment',

      categories: ['editor'],
      tags: ['add', 'bubble', 'chat', 'comment', 'feedback', 'insert', 'message'],
    },
    {
      name: 'insert_drive_file',

      categories: ['editor'],
      tags: ['doc', 'drive', 'file', 'format', 'insert', 'sheet', 'slide'],
    },
    {
      name: 'insert_emoticon',

      categories: ['editor'],
      tags: [
        'account',
        'emoji',
        'emoticon',
        'face',
        'happy',
        'human',
        'insert',
        'people',
        'person',
        'profile',
        'sentiment',
        'smile',
        'user',
      ],
    },
    {
      name: 'insert_invitation',

      categories: ['editor'],
      tags: [
        'calendar',
        'date',
        'day',
        'event',
        'insert',
        'invitation',
        'mark',
        'month',
        'range',
        'remember',
        'reminder',
        'today',
        'week',
      ],
    },
    {
      name: 'insert_link',

      categories: ['editor'],
      tags: ['add', 'attach', 'clip', 'file', 'insert', 'link', 'mail', 'media'],
    },
    {
      name: 'insert_page_break',

      categories: ['editor'],
      tags: ['break', 'doc', 'document', 'file', 'page', 'paper'],
    },
    {
      name: 'insert_photo',

      categories: ['editor'],
      tags: ['image', 'insert', 'landscape', 'mountain', 'mountains', 'photo', 'photography', 'picture'],
    },
    {
      name: 'insights',

      categories: ['content'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'insights',
        'measure',
        'metrics',
        'stars',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'install_desktop',

      categories: ['action'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'desktop',
        'device',
        'display',
        'fix',
        'hardware',
        'iOS',
        'install',
        'mac',
        'monitor',
        'place',
        'pwa',
        'screen',
        'web',
        'window',
      ],
    },
    {
      name: 'install_mobile',

      categories: ['action'],
      tags: ['Android', 'OS', 'cell', 'device', 'hardware', 'iOS', 'install', 'mobile', 'phone', 'pwa', 'tablet'],
    },
    {
      name: 'integration_instructions',

      categories: ['action'],
      tags: [
        'brackets',
        'clipboard',
        'code',
        'css',
        'develop',
        'developer',
        'doc',
        'document',
        'engineer',
        'engineering clipboard',
        'html',
        'instructions',
        'integration',
        'platform',
      ],
    },
    {
      name: 'interests',

      categories: ['social'],
      tags: ['circle', 'heart', 'interests', 'shapes', 'social', 'square', 'triangle'],
    },
    {
      name: 'interpreter_mode',

      categories: ['av'],
      tags: ['interpreter', 'language', 'microphone', 'mode', 'person', 'speaking', 'symbol'],
    },
    {
      name: 'inventory',

      categories: ['content'],
      tags: [
        'archive',
        'box',
        'clipboard',
        'doc',
        'document',
        'file',
        'inventory',
        'organize',
        'packages',
        'product',
        'stock',
        'supply',
      ],
    },
    {
      name: 'inventory_2',

      categories: ['content'],
      tags: ['archive', 'box', 'file', 'inventory', 'organize', 'packages', 'product', 'stock', 'storage', 'supply'],
    },
    {
      name: 'invert_colors',

      categories: ['action'],
      tags: ['colors', 'drop', 'droplet', 'edit', 'editing', 'hue', 'invert', 'inverted', 'palette', 'tone', 'water'],
    },
    {
      name: 'invert_colors_off',

      categories: ['communication'],
      tags: [
        'colors',
        'disabled',
        'drop',
        'droplet',
        'enabled',
        'hue',
        'invert',
        'inverted',
        'off',
        'offline',
        'on',
        'opacity',
        'palette',
        'slash',
        'tone',
        'water',
      ],
    },
    {
      name: 'ios_share',

      categories: ['social'],
      tags: ['export', 'ios', 'send', 'share'],
    },
    {
      name: 'iron',

      categories: ['places'],
      tags: ['appliance', 'clothes', 'electric', 'iron', 'ironing', 'machine', 'object'],
    },
    {
      name: 'iso',

      categories: ['image'],
      tags: [
        'add',
        'edit',
        'editing',
        'effect',
        'image',
        'iso',
        'minus',
        'photography',
        'picture',
        'plus',
        'sensor',
        'shutter',
        'speed',
        'subtract',
      ],
    },
    {
      name: 'javascript',

      categories: ['action'],
      tags: [
        'alphabet',
        'brackets',
        'character',
        'code',
        'css',
        'develop',
        'developer',
        'engineer',
        'engineering',
        'font',
        'html',
        'javascript',
        'letter',
        'platform',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'join_full',

      categories: ['action'],
      tags: ['circle', 'combine', 'command', 'full', 'join', 'left', 'outter', 'right', 'sql'],
    },
    {
      name: 'join_inner',

      categories: ['action'],
      tags: ['circle', 'command', 'inner', 'join', 'matching', 'sql', 'values'],
    },
    {
      name: 'join_left',

      categories: ['action'],
      tags: ['circle', 'command', 'join', 'left', 'matching', 'sql', 'values'],
    },
    {
      name: 'join_right',

      categories: ['action'],
      tags: ['circle', 'command', 'join', 'matching', 'right', 'sql', 'values'],
    },
    {
      name: 'kayaking',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'body',
        'canoe',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'kayak',
        'kayaking',
        'lake',
        'paddle',
        'paddling',
        'people',
        'person',
        'rafting',
        'river',
        'row',
        'social',
        'sports',
        'summer',
        'travel',
        'water',
      ],
    },
    {
      name: 'kebab_dining',

      categories: ['maps'],
      tags: ['dining', 'dinner', 'food', 'kebab', 'meal', 'meat', 'skewer'],
    },
    {
      name: 'key',

      categories: ['communication'],
      tags: ['key', 'lock', 'password', 'unlock'],
    },
    {
      name: 'key_off',

      categories: ['communication'],
      tags: ['[offline]', 'disabled', 'enabled', 'key', 'lock', 'off', 'on', 'password', 'slash', 'unlock'],
    },
    {
      name: 'keyboard',

      categories: ['hardware'],
      tags: ['computer', 'device', 'hardware', 'input', 'keyboard', 'keypad', 'letter', 'office', 'text', 'type'],
    },
    {
      name: 'keyboard_alt',

      categories: ['hardware'],
      tags: [
        'alt',
        'computer',
        'device',
        'hardware',
        'input',
        'keyboard',
        'keypad',
        'letter',
        'office',
        'text',
        'type',
      ],
    },
    {
      name: 'keyboard_arrow_down',

      categories: ['hardware'],
      tags: ['arrow', 'arrows', 'down', 'keyboard'],
    },
    {
      name: 'keyboard_arrow_left',

      categories: ['hardware'],
      tags: ['arrow', 'arrows', 'keyboard', 'left'],
    },
    {
      name: 'keyboard_arrow_right',

      categories: ['hardware'],
      tags: ['arrow', 'arrows', 'keyboard', 'right'],
    },
    {
      name: 'keyboard_arrow_up',

      categories: ['hardware'],
      tags: ['arrow', 'arrows', 'keyboard', 'up'],
    },
    {
      name: 'keyboard_backspace',

      categories: ['hardware'],
      tags: ['arrow', 'back', 'backspace', 'keyboard', 'left'],
    },
    {
      name: 'keyboard_capslock',

      categories: ['hardware'],
      tags: ['arrow', 'capslock', 'keyboard', 'up'],
    },
    {
      name: 'keyboard_command_key',

      categories: ['hardware'],
      tags: ['button', 'command key', 'control', 'keyboard'],
    },
    {
      name: 'keyboard_control_key',

      categories: ['hardware'],
      tags: ['control key', 'keyboard'],
    },
    {
      name: 'keyboard_double_arrow_down',

      categories: ['hardware'],
      tags: ['arrow', 'arrows', 'direction', 'double', 'down', 'multiple', 'navigation'],
    },
    {
      name: 'keyboard_double_arrow_left',

      categories: ['hardware'],
      tags: ['arrow', 'arrows', 'direction', 'double', 'left', 'multiple', 'navigation'],
    },
    {
      name: 'keyboard_double_arrow_right',

      categories: ['hardware'],
      tags: ['arrow', 'arrows', 'direction', 'double', 'multiple', 'navigation', 'right'],
    },
    {
      name: 'keyboard_double_arrow_up',

      categories: ['hardware'],
      tags: ['arrow', 'arrows', 'direction', 'double', 'multiple', 'navigation', 'up'],
    },
    {
      name: 'keyboard_hide',

      categories: ['hardware'],
      tags: ['arrow', 'computer', 'device', 'down', 'hardware', 'hide', 'input', 'keyboard', 'keypad', 'text'],
    },
    {
      name: 'keyboard_option_key',

      categories: ['hardware'],
      tags: ['alt key', 'key', 'keyboard', 'modifier key', 'option'],
    },
    {
      name: 'keyboard_return',

      categories: ['hardware'],
      tags: ['arrow', 'back', 'keyboard', 'left', 'return'],
    },
    {
      name: 'keyboard_tab',

      categories: ['hardware'],
      tags: ['arrow', 'keyboard', 'next', 'right', 'tab'],
    },
    {
      name: 'keyboard_voice',

      categories: ['hardware'],
      tags: ['keyboard', 'mic', 'microphone', 'noise', 'record', 'recorder', 'speaker', 'voice'],
    },
    {
      name: 'king_bed',

      categories: ['social'],
      tags: [
        'bed',
        'bedroom',
        'double',
        'furniture',
        'home',
        'hotel',
        'house',
        'king',
        'night',
        'pillows',
        'queen',
        'rest',
        'room',
        'sleep',
      ],
    },
    {
      name: 'kitchen',

      categories: ['places'],
      tags: [
        'appliance',
        'cold',
        'food',
        'fridge',
        'home',
        'house',
        'ice',
        'kitchen',
        'places',
        'refrigerator',
        'storage',
      ],
    },
    {
      name: 'kitesurfing',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'beach',
        'body',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'kitesurfing',
        'people',
        'person',
        'social',
        'sports',
        'surf',
        'travel',
        'water',
      ],
    },
    {
      name: 'label',

      categories: ['action'],
      tags: ['favorite', 'indent', 'label', 'library', 'mail', 'remember', 'save', 'stamp', 'sticker', 'tag'],
    },
    {
      name: 'label_important',

      categories: ['action'],
      tags: [
        'favorite',
        'important',
        'indent',
        'label',
        'library',
        'mail',
        'remember',
        'save',
        'stamp',
        'sticker',
        'tag',
        'wing',
      ],
    },
    {
      name: 'label_off',

      categories: ['action'],
      tags: [
        'disabled',
        'enabled',
        'favorite',
        'indent',
        'label',
        'library',
        'mail',
        'off',
        'on',
        'remember',
        'save',
        'slash',
        'stamp',
        'sticker',
        'tag',
        'wing',
      ],
    },
    {
      name: 'lan',

      categories: ['device'],
      tags: ['computer', 'connection', 'data', 'internet', 'lan', 'network', 'service'],
    },
    {
      name: 'landscape',

      categories: ['image'],
      tags: ['image', 'landscape', 'mountain', 'mountains', 'nature', 'photo', 'photography', 'picture'],
    },
    {
      name: 'landslide',

      categories: ['social'],
      tags: ['crisis', 'disaster', 'natural', 'rain', 'storm', 'weather'],
    },
    {
      name: 'language',

      categories: ['action'],
      tags: ['globe', 'internet', 'language', 'planet', 'website', 'world', 'www'],
    },
    {
      name: 'laptop',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'computer',
        'desktop',
        'device',
        'hardware',
        'iOS',
        'laptop',
        'mac',
        'monitor',
        'web',
        'windows',
      ],
    },
    {
      name: 'laptop_chromebook',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'chromebook',
        'device',
        'display',
        'hardware',
        'iOS',
        'laptop',
        'mac chromebook',
        'monitor',
        'screen',
        'web',
        'window',
      ],
    },
    {
      name: 'laptop_mac',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'device',
        'display',
        'hardware',
        'iOS',
        'laptop',
        'mac',
        'monitor',
        'screen',
        'web',
        'window',
      ],
    },
    {
      name: 'laptop_windows',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'device',
        'display',
        'hardware',
        'iOS',
        'laptop',
        'mac',
        'monitor',
        'screen',
        'web',
        'window',
        'windows',
      ],
    },
    {
      name: 'last_page',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'chevron',
        'components',
        'end',
        'forward',
        'interface',
        'last',
        'page',
        'right',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'launch',

      categories: ['action'],
      tags: [
        'app',
        'application',
        'arrow',
        'box',
        'components',
        'interface',
        'launch',
        'new',
        'open',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'layers',

      categories: ['maps'],
      tags: [
        'arrange',
        'disabled',
        'enabled',
        'interaction',
        'layers',
        'maps',
        'off',
        'on',
        'overlay',
        'pages',
        'slash',
      ],
    },
    {
      name: 'layers_clear',

      categories: ['maps'],
      tags: [
        'arrange',
        'clear',
        'delete',
        'disabled',
        'enabled',
        'interaction',
        'layers',
        'maps',
        'off',
        'on',
        'overlay',
        'pages',
        'slash',
      ],
    },
    {
      name: 'leaderboard',

      categories: ['action'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'leaderboard',
        'measure',
        'metrics',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'leak_add',

      categories: ['image'],
      tags: ['add', 'connection', 'data', 'leak', 'link', 'network', 'service', 'signals', 'synce', 'wireless'],
    },
    {
      name: 'leak_remove',

      categories: ['image'],
      tags: [
        'connection',
        'data',
        'disabled',
        'enabled',
        'leak',
        'link',
        'network',
        'off',
        'offline',
        'on',
        'remove',
        'service',
        'signals',
        'slash',
        'synce',
        'wireless',
      ],
    },
    {
      name: 'legend_toggle',

      categories: ['navigation'],
      tags: [
        'analytics',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'legend',
        'measure',
        'metrics',
        'monitoring',
        'stackdriver',
        'statistics',
        'toggle',
        'tracking',
      ],
    },
    {
      name: 'lens',

      categories: ['image'],
      tags: ['circle', 'full', 'geometry', 'lens', 'moon'],
    },
    {
      name: 'lens_blur',

      categories: ['device'],
      tags: ['blur', 'camera', 'dim', 'dot', 'effect', 'foggy', 'fuzzy', 'image', 'lens', 'photo', 'soften'],
    },
    {
      name: 'library_add',

      categories: ['av'],
      tags: [
        '+',
        'add',
        'collection',
        'layers',
        'library',
        'multiple',
        'music',
        'new',
        'plus',
        'stacked',
        'symbol',
        'video',
      ],
    },
    {
      name: 'library_add_check',

      categories: ['av'],
      tags: [
        'add',
        'approve',
        'check',
        'collection',
        'complete',
        'done',
        'layers',
        'library',
        'mark',
        'multiple',
        'music',
        'ok',
        'select',
        'stacked',
        'tick',
        'validate',
        'verified',
        'video',
        'yes',
      ],
    },
    {
      name: 'library_books',

      categories: ['av'],
      tags: ['add', 'album', 'audio', 'book', 'books', 'collection', 'library', 'read', 'reading'],
    },
    {
      name: 'library_music',

      categories: ['av'],
      tags: ['add', 'album', 'collection', 'library', 'music', 'song', 'sounds'],
    },
    {
      name: 'light',

      categories: ['search'],
      tags: ['bulb', 'ceiling', 'hanging', 'inside', 'interior', 'lamp', 'light', 'lighting', 'pendent', 'room'],
    },
    {
      name: 'light_mode',

      categories: ['device'],
      tags: ['bright', 'brightness', 'day', 'device', 'light', 'lighting', 'mode', 'morning', 'sky', 'sun', 'sunny'],
    },
    {
      name: 'lightbulb',

      categories: ['action'],
      tags: ['alert', 'announcement', 'idea', 'info', 'information', 'light', 'lightbulb'],
    },
    {
      name: 'lightbulb_circle',

      categories: ['action'],
      tags: ['alert', 'announcement', 'idea', 'info', 'information', 'light', 'lightbulb'],
    },
    {
      name: 'line_axis',

      categories: ['editor'],
      tags: ['axis', 'dash', 'horizontal', 'line', 'stroke', 'vertical'],
    },
    {
      name: 'line_style',

      categories: ['action'],
      tags: ['dash', 'dotted', 'line', 'rule', 'spacing', 'style'],
    },
    {
      name: 'line_weight',

      categories: ['action'],
      tags: ['height', 'line', 'size', 'spacing', 'style', 'thickness', 'weight'],
    },
    {
      name: 'linear_scale',

      categories: ['editor'],
      tags: [
        'app',
        'application',
        'components',
        'design',
        'interface',
        'layout',
        'linear',
        'measure',
        'menu',
        'scale',
        'screen',
        'site',
        'slider',
        'ui',
        'ux',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'link',

      categories: ['content'],
      tags: ['chain', 'clip', 'connection', 'link', 'linked', 'links', 'multimedia', 'url'],
    },
    {
      name: 'link_off',

      categories: ['content'],
      tags: [
        'attached',
        'chain',
        'clip',
        'connection',
        'disabled',
        'enabled',
        'link',
        'linked',
        'links',
        'multimedia',
        'off',
        'on',
        'slash',
        'url',
      ],
    },
    {
      name: 'linked_camera',

      categories: ['image'],
      tags: [
        'camera',
        'connect',
        'connection',
        'lens',
        'linked',
        'network',
        'photo',
        'photography',
        'picture',
        'signal',
        'signals',
        'sync',
        'wireless',
      ],
    },
    {
      name: 'liquor',

      categories: ['maps'],
      tags: ['alcohol', 'bar', 'bottle', 'club', 'cocktail', 'drink', 'food', 'liquor', 'party', 'store', 'wine'],
    },
    {
      name: 'list',

      categories: ['action'],
      tags: ['file', 'format', 'index', 'list', 'menu', 'options'],
    },
    {
      name: 'list_alt',

      categories: ['communication'],
      tags: ['alt', 'box', 'contained', 'format', 'lines', 'list', 'order', 'reorder', 'stacked', 'title'],
    },
    {
      name: 'live_help',

      categories: ['communication'],
      tags: [
        '?',
        'assistance',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'help',
        'info',
        'information',
        'live',
        'message',
        'punctuation',
        'question mark',
        'speech',
        'support',
        'symbol',
      ],
    },
    {
      name: 'live_tv',

      categories: ['notification'],
      tags: [
        'Android',
        'OS',
        'antennas hardware',
        'chrome',
        'desktop',
        'device',
        'iOS',
        'live',
        'mac',
        'monitor',
        'movie',
        'play',
        'stream',
        'television',
        'tv',
        'web',
        'window',
      ],
    },
    {
      name: 'living',

      categories: ['search'],
      tags: [
        'chair',
        'comfort',
        'couch',
        'decoration',
        'furniture',
        'home',
        'house',
        'living',
        'lounging',
        'loveseat',
        'room',
        'seat',
        'seating',
        'sofa',
      ],
    },
    {
      name: 'local_activity',

      categories: ['maps'],
      tags: ['activity', 'event', 'local', 'star', 'things', 'ticket'],
    },
    {
      name: 'local_airport',

      categories: ['maps'],
      tags: ['air', 'airplane', 'airport', 'flight', 'plane', 'transportation', 'travel', 'trip'],
    },
    {
      name: 'local_atm',

      categories: ['maps'],
      tags: [
        'atm',
        'bill',
        'card',
        'cart',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'local',
        'money',
        'online',
        'pay',
        'payment',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'local_bar',

      categories: ['maps'],
      tags: ['alcohol', 'bar', 'bottle', 'club', 'cocktail', 'drink', 'food', 'liquor', 'local', 'wine'],
    },
    {
      name: 'local_cafe',

      categories: ['maps'],
      tags: ['bottle', 'cafe', 'coffee', 'cup', 'drink', 'food', 'restaurant', 'tea'],
    },
    {
      name: 'local_car_wash',

      categories: ['maps'],
      tags: ['automobile', 'car', 'cars', 'local', 'maps', 'transportation', 'travel', 'vehicle', 'wash'],
    },
    {
      name: 'local_convenience_store',

      categories: ['maps'],
      tags: [
        '--',
        '24',
        'bill',
        'building',
        'business',
        'card',
        'cash',
        'coin',
        'commerce',
        'company',
        'convenience',
        'credit',
        'currency',
        'dollars',
        'local',
        'maps',
        'market',
        'money',
        'new',
        'online',
        'pay',
        'payment',
        'plus',
        'shop',
        'shopping',
        'store',
        'storefront',
        'symbol',
      ],
    },
    {
      name: 'local_dining',

      categories: ['maps'],
      tags: ['dining', 'eat', 'food', 'fork', 'knife', 'local', 'meal', 'restaurant', 'spoon'],
    },
    {
      name: 'local_drink',

      categories: ['maps'],
      tags: ['cup', 'drink', 'drop', 'droplet', 'liquid', 'local', 'park', 'water'],
    },
    {
      name: 'local_fire_department',

      categories: ['maps'],
      tags: [
        '911',
        'climate',
        'department',
        'fire',
        'firefighter',
        'flame',
        'heat',
        'home',
        'hot',
        'nest',
        'thermostat',
      ],
    },
    {
      name: 'local_florist',

      categories: ['maps'],
      tags: ['florist', 'flower', 'local', 'shop'],
    },
    {
      name: 'local_gas_station',

      categories: ['maps'],
      tags: ['auto', 'car', 'gas', 'local', 'oil', 'station', 'vehicle'],
    },
    {
      name: 'local_grocery_store',

      categories: ['maps'],
      tags: ['grocery', 'market', 'shop', 'store'],
    },
    {
      name: 'local_hospital',

      categories: ['maps'],
      tags: ['911', 'aid', 'cross', 'emergency', 'first', 'hospital', 'local', 'medicine'],
    },
    {
      name: 'local_hotel',

      categories: ['maps'],
      tags: ['body', 'hotel', 'human', 'local', 'people', 'person', 'sleep', 'stay', 'travel', 'trip'],
    },
    {
      name: 'local_laundry_service',

      categories: ['maps'],
      tags: ['cleaning', 'clothing', 'dry', 'dryer', 'hotel', 'laundry', 'local', 'service', 'washer'],
    },
    {
      name: 'local_library',

      categories: ['maps'],
      tags: ['book', 'community learning', 'library', 'local', 'read'],
    },
    {
      name: 'local_mall',

      categories: ['maps'],
      tags: [
        'bag',
        'bill',
        'building',
        'business',
        'buy',
        'card',
        'cart',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'handbag',
        'local',
        'mall',
        'money',
        'online',
        'pay',
        'payment',
        'shop',
        'shopping',
        'store',
        'storefront',
      ],
    },
    {
      name: 'local_movies',

      categories: ['maps'],
      tags: [],
    },
    {
      name: 'local_offer',

      categories: ['maps'],
      tags: ['deal', 'discount', 'offer', 'price', 'shop', 'shopping', 'store', 'tag'],
    },
    {
      name: 'local_parking',

      categories: ['maps'],
      tags: [
        'alphabet',
        'auto',
        'car',
        'character',
        'font',
        'garage',
        'letter',
        'local',
        'park',
        'parking',
        'symbol',
        'text',
        'type',
        'vehicle',
      ],
    },
    {
      name: 'local_pharmacy',

      categories: ['maps'],
      tags: ['911', 'aid', 'cross', 'emergency', 'first', 'hospital', 'local', 'medicine', 'pharmacy', 'places'],
    },
    {
      name: 'local_phone',

      categories: ['maps'],
      tags: ['booth', 'call', 'communication', 'phone', 'telecommunication'],
    },
    {
      name: 'local_pizza',

      categories: ['maps'],
      tags: ['drink', 'fastfood', 'food', 'local', 'meal', 'pizza'],
    },
    {
      name: 'local_play',

      categories: ['maps'],
      tags: [],
    },
    {
      name: 'local_police',

      categories: ['maps'],
      tags: ['911', 'badge', 'law', 'local', 'officer', 'police', 'protect', 'protection', 'security', 'shield'],
    },
    {
      name: 'local_post_office',

      categories: ['maps'],
      tags: [
        'delivery',
        'email',
        'envelop',
        'letter',
        'local',
        'mail',
        'message',
        'office',
        'package',
        'parcel',
        'post',
        'postal',
        'send',
        'stamp',
      ],
    },
    {
      name: 'local_printshop',

      categories: ['maps'],
      tags: ['draft', 'fax', 'ink', 'local', 'machine', 'office', 'paper', 'print', 'printer', 'printshop', 'send'],
    },
    {
      name: 'local_see',

      categories: ['maps'],
      tags: ['camera', 'lens', 'local', 'photo', 'photography', 'picture', 'see'],
    },
    {
      name: 'local_shipping',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'delivery',
        'letter',
        'local',
        'mail',
        'maps',
        'office',
        'package',
        'parcel',
        'post',
        'postal',
        'send',
        'shipping',
        'shopping',
        'stamp',
        'transportation',
        'truck',
        'vehicle',
      ],
    },
    {
      name: 'local_taxi',

      categories: ['maps'],
      tags: [
        'automobile',
        'cab',
        'call',
        'car',
        'cars',
        'direction',
        'local',
        'lyft',
        'maps',
        'public',
        'taxi',
        'transportation',
        'uber',
        'vehicle',
        'yellow',
      ],
    },
    {
      name: 'location_city',

      categories: ['social'],
      tags: [
        'apartments',
        'architecture',
        'buildings',
        'business',
        'city',
        'estate',
        'home',
        'landscape',
        'location',
        'place',
        'real',
        'residence',
        'residential',
        'shelter',
        'town',
        'urban',
      ],
    },
    {
      name: 'location_disabled',

      categories: ['device'],
      tags: [
        'destination',
        'direction',
        'disabled',
        'enabled',
        'location',
        'maps',
        'off',
        'on',
        'pin',
        'place',
        'pointer',
        'slash',
        'stop',
        'tracking',
      ],
    },
    {
      name: 'location_off',

      categories: ['communication'],
      tags: ['destination', 'direction', 'location', 'maps', 'off', 'pin', 'place', 'room', 'stop'],
    },
    {
      name: 'location_on',

      categories: ['communication'],
      tags: ['destination', 'direction', 'location', 'maps', 'on', 'pin', 'place', 'room', 'stop'],
    },
    {
      name: 'location_searching',

      categories: ['device'],
      tags: [
        'destination',
        'direction',
        'location',
        'maps',
        'pin',
        'place',
        'pointer',
        'searching',
        'stop',
        'tracking',
      ],
    },
    {
      name: 'lock',

      categories: ['action'],
      tags: ['lock', 'locked', 'password', 'privacy', 'private', 'protection', 'safety', 'secure', 'security'],
    },
    {
      name: 'lock_clock',

      categories: ['action'],
      tags: [
        'clock',
        'date',
        'lock',
        'locked',
        'password',
        'privacy',
        'private',
        'protection',
        'safety',
        'schedule',
        'secure',
        'security',
        'time',
      ],
    },
    {
      name: 'lock_open',

      categories: ['action'],
      tags: [
        'lock',
        'open',
        'password',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
        'unlocked',
      ],
    },
    {
      name: 'lock_person',

      categories: ['action'],
      tags: [],
    },
    {
      name: 'lock_reset',

      categories: ['action'],
      tags: [
        'around',
        'inprogress',
        'load',
        'loading refresh',
        'lock',
        'locked',
        'password',
        'privacy',
        'private',
        'protection',
        'renew',
        'rotate',
        'safety',
        'secure',
        'security',
        'turn',
      ],
    },
    {
      name: 'login',

      categories: ['action'],
      tags: [
        'access',
        'app',
        'application',
        'arrow',
        'components',
        'design',
        'enter',
        'in',
        'interface',
        'left',
        'log',
        'login',
        'screen',
        'sign',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'logo_dev',

      categories: ['image'],
      tags: ['dev', 'dev.to', 'logo'],
    },
    {
      name: 'logout',

      categories: ['action'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'design',
        'exit',
        'interface',
        'leave',
        'log',
        'login',
        'logout',
        'right',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'looks',

      categories: ['image'],
      tags: ['circle', 'half', 'looks', 'rainbow'],
    },
    {
      name: 'looks_3',

      categories: ['image'],
      tags: ['3', 'digit', 'looks', 'numbers', 'square', 'symbol'],
    },
    {
      name: 'looks_4',

      categories: ['image'],
      tags: ['4', 'digit', 'looks', 'numbers', 'square', 'symbol'],
    },
    {
      name: 'looks_5',

      categories: ['image'],
      tags: ['5', 'digit', 'looks', 'numbers', 'square', 'symbol'],
    },
    {
      name: 'looks_6',

      categories: ['image'],
      tags: ['6', 'digit', 'looks', 'numbers', 'square', 'symbol'],
    },
    {
      name: 'looks_one',

      categories: ['image'],
      tags: ['1', 'digit', 'looks', 'numbers', 'square', 'symbol'],
    },
    {
      name: 'looks_two',

      categories: ['image'],
      tags: ['2', 'digit', 'looks', 'numbers', 'square', 'symbol'],
    },
    {
      name: 'loop',

      categories: ['av'],
      tags: [
        'around',
        'arrow',
        'arrows',
        'direction',
        'inprogress',
        'load',
        'loading refresh',
        'loop',
        'music',
        'navigation',
        'renew',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'loupe',

      categories: ['image'],
      tags: ['+', 'add', 'details', 'focus', 'glass', 'loupe', 'magnifying', 'new', 'plus', 'symbol'],
    },
    {
      name: 'low_priority',

      categories: ['content'],
      tags: ['arrange', 'arrow', 'backward', 'bottom', 'list', 'low', 'move', 'order', 'priority'],
    },
    {
      name: 'loyalty',

      categories: ['action'],
      tags: [
        'card',
        'credit',
        'loyalty',
        'membership',
        'miles',
        'points',
        'program',
        'subscription heart',
        'tag',
        'travel',
        'trip',
      ],
    },
    {
      name: 'lte_mobiledata',

      categories: ['device'],
      tags: [
        'alphabet',
        'character',
        'data',
        'font',
        'internet',
        'letter',
        'lte',
        'mobile',
        'network',
        'speed',
        'symbol',
        'text',
        'type',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'lte_plus_mobiledata',

      categories: ['device'],
      tags: [
        '+',
        'alphabet',
        'character',
        'data',
        'font',
        'internet',
        'letter',
        'lte',
        'mobile',
        'network',
        'plus',
        'speed',
        'symbol',
        'text',
        'type',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'luggage',

      categories: ['social'],
      tags: ['airport', 'bag', 'baggage', 'carry', 'flight', 'hotel', 'luggage', 'on', 'suitcase', 'travel', 'trip'],
    },
    {
      name: 'lunch_dining',

      categories: ['maps'],
      tags: ['breakfast', 'dining', 'dinner', 'drink', 'fastfood', 'food', 'hamburger', 'lunch', 'meal'],
    },
    {
      name: 'lyrics',

      categories: ['av'],
      tags: [
        'audio',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'feedback',
        'key',
        'lyrics',
        'message',
        'music',
        'note',
        'song',
        'sound',
        'speech',
        'track',
      ],
    },
    {
      name: 'macro_off',

      categories: ['device'],
      tags: ['[offline]', 'camera', 'disabled', 'enabled', 'flower', 'garden', 'image', 'macro', 'off', 'on', 'slash'],
    },
    {
      name: 'mail',

      categories: ['content'],
      tags: ['email', 'envelop', 'letter', 'mail', 'message', 'send'],
    },
    {
      name: 'mail_lock',

      categories: ['communication'],
      tags: [
        'email',
        'envelop',
        'letter',
        'lock',
        'locked',
        'mail',
        'message',
        'password',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
        'send',
      ],
    },
    {
      name: 'mail_outline',

      categories: ['communication'],
      tags: ['email', 'envelop', 'letter', 'mail', 'message', 'outline', 'send'],
    },
    {
      name: 'male',

      categories: ['social'],
      tags: ['boy', 'gender', 'male', 'man', 'social', 'symbol'],
    },
    {
      name: 'man',

      categories: ['social'],
      tags: ['boy', 'gender', 'male', 'man', 'social', 'symbol'],
    },
    {
      name: 'man_2',

      categories: ['social'],
      tags: ['boy', 'gender', 'male', 'man', 'social', 'symbol'],
    },
    {
      name: 'man_3',

      categories: ['social'],
      tags: ['abstract', 'boy', 'gender', 'male', 'man', 'social', 'symbol'],
    },
    {
      name: 'man_4',

      categories: ['social'],
      tags: ['abstract', 'boy', 'gender', 'male', 'man', 'social', 'symbol'],
    },
    {
      name: 'manage_accounts',

      categories: ['action'],
      tags: [
        'accounts',
        'change',
        'details service-human',
        'face',
        'gear',
        'manage',
        'options',
        'people',
        'person',
        'profile',
        'settings',
        'user',
      ],
    },
    {
      name: 'manage_history',

      categories: ['action'],
      tags: [
        'application',
        'arrow',
        'back',
        'backwards',
        'change',
        'clock',
        'date',
        'details',
        'gear',
        'history',
        'options',
        'refresh',
        'renew',
        'reverse',
        'rotate',
        'schedule',
        'settings',
        'time',
        'turn',
      ],
    },
    {
      name: 'manage_search',

      categories: ['search'],
      tags: ['glass', 'history', 'magnifying', 'manage', 'search', 'text'],
    },
    {
      name: 'map',

      categories: ['maps'],
      tags: ['destination', 'direction', 'location', 'map', 'maps', 'pin', 'place', 'route', 'stop', 'travel'],
    },
    {
      name: 'maps_home_work',

      categories: ['navigation'],
      tags: ['building', 'home', 'house', 'maps', 'office', 'work'],
    },
    {
      name: 'maps_ugc',

      categories: ['maps'],
      tags: [
        '+',
        'add',
        'bubble',
        'comment',
        'communicate',
        'feedback',
        'maps',
        'message',
        'new',
        'plus',
        'speech',
        'symbol',
        'ugc',
      ],
    },
    {
      name: 'margin',

      categories: ['editor'],
      tags: ['design', 'layout', 'margin', 'padding', 'size', 'square'],
    },
    {
      name: 'mark_as_unread',

      categories: ['action'],
      tags: ['as', 'envelop', 'letter', 'mail', 'mark', 'post', 'postal', 'read', 'receive', 'send', 'unread'],
    },
    {
      name: 'mark_chat_read',

      categories: ['communication'],
      tags: [
        'approve',
        'bubble',
        'chat',
        'check',
        'comment',
        'communicate',
        'complete',
        'done',
        'mark',
        'message',
        'ok',
        'read',
        'select',
        'sent',
        'speech',
        'tick',
        'verified',
        'yes',
      ],
    },
    {
      name: 'mark_chat_unread',

      categories: ['communication'],
      tags: [
        'bubble',
        'chat',
        'circle',
        'comment',
        'communicate',
        'mark',
        'message',
        'notification',
        'speech',
        'unread',
      ],
    },
    {
      name: 'mark_email_read',

      categories: ['communication'],
      tags: [
        'approve',
        'check',
        'complete',
        'done',
        'email',
        'envelop',
        'letter',
        'mail',
        'mark',
        'message',
        'note',
        'ok',
        'read',
        'select',
        'send',
        'sent',
        'tick',
        'yes',
      ],
    },
    {
      name: 'mark_email_unread',

      categories: ['communication'],
      tags: [
        'check',
        'circle',
        'email',
        'envelop',
        'letter',
        'mail',
        'mark',
        'message',
        'note',
        'notification',
        'send',
        'unread',
      ],
    },
    {
      name: 'mark_unread_chat_alt',

      categories: ['communication'],
      tags: [
        'bubble',
        'chat',
        'circle',
        'comment',
        'communicate',
        'mark',
        'message',
        'notification',
        'speech',
        'unread',
      ],
    },
    {
      name: 'markunread',

      categories: ['content'],
      tags: ['email', 'envelop', 'letter', 'mail', 'markunread', 'message', 'send', 'unread'],
    },
    {
      name: 'markunread_mailbox',

      categories: ['action'],
      tags: [
        'deliver',
        'envelop',
        'letter',
        'mail',
        'mailbox',
        'markunread',
        'post',
        'postal',
        'postbox',
        'receive',
        'send',
        'unread',
      ],
    },
    {
      name: 'masks',

      categories: ['social'],
      tags: [
        'air',
        'cover',
        'covid',
        'face',
        'hospital',
        'masks',
        'medical',
        'pollution',
        'protection',
        'respirator',
        'sick',
        'social',
      ],
    },
    {
      name: 'maximize',

      categories: ['action'],
      tags: [
        'app',
        'application',
        'components',
        'design',
        'interface',
        'line',
        'maximize',
        'screen',
        'shape',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'media_bluetooth_off',

      categories: ['device'],
      tags: [
        'bluetooth',
        'connect',
        'connection',
        'connectivity',
        'device',
        'disabled',
        'enabled',
        'media',
        'music',
        'note',
        'off',
        'offline',
        'on',
        'paring',
        'signal',
        'slash',
        'symbol',
        'wireless',
      ],
    },
    {
      name: 'media_bluetooth_on',

      categories: ['device'],
      tags: [
        'bluetooth',
        'connect',
        'connection',
        'connectivity',
        'device',
        'disabled',
        'enabled',
        'media',
        'music',
        'note',
        'off',
        'on',
        'online',
        'paring',
        'signal',
        'slash',
        'symbol',
        'wireless',
      ],
    },
    {
      name: 'mediation',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'direction', 'dots', 'mediation', 'right'],
    },
    {
      name: 'medical_information',

      categories: ['maps'],
      tags: ['badge', 'card', 'health', 'id', 'information', 'medical', 'services'],
    },
    {
      name: 'medical_services',

      categories: ['maps'],
      tags: ['aid', 'bag', 'briefcase', 'emergency', 'first', 'kit', 'medical', 'medicine', 'services'],
    },
    {
      name: 'medication',

      categories: ['device'],
      tags: ['doctor', 'drug', 'emergency', 'hospital', 'medication', 'medicine', 'pharmacy', 'pills', 'prescription'],
    },
    {
      name: 'medication_liquid',

      categories: ['device'],
      tags: [
        '+',
        'bottle',
        'doctor',
        'drug',
        'health',
        'hospital',
        'liquid',
        'medications',
        'medicine',
        'pharmacy',
        'spoon',
        'vessel',
      ],
    },
    {
      name: 'meeting_room',

      categories: ['places'],
      tags: [
        'building',
        'door',
        'doorway',
        'entrance',
        'home',
        'house',
        'interior',
        'meeting',
        'office',
        'open',
        'places',
        'room',
      ],
    },
    {
      name: 'memory',

      categories: ['hardware'],
      tags: ['card', 'chip', 'digital', 'memory', 'micro', 'processor', 'sd', 'storage'],
    },
    {
      name: 'menu',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'components',
        'hamburger',
        'interface',
        'line',
        'lines',
        'menu',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'menu_book',

      categories: ['maps'],
      tags: ['book', 'dining', 'food', 'meal', 'menu', 'restaurant'],
    },
    {
      name: 'menu_open',

      categories: ['navigation'],
      tags: [
        'app',
        'application',
        'arrow',
        'components',
        'hamburger',
        'interface',
        'left',
        'line',
        'lines',
        'menu',
        'open',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'merge',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'merge',
        'navigation',
        'path',
        'route',
        'sign',
        'traffic',
      ],
    },
    {
      name: 'merge_type',

      categories: ['editor'],
      tags: ['arrow', 'combine', 'direction', 'format', 'merge', 'text', 'type'],
    },
    {
      name: 'message',

      categories: ['communication'],
      tags: ['bubble', 'chat', 'comment', 'communicate', 'feedback', 'message', 'speech'],
    },
    {
      name: 'mic',

      categories: ['av'],
      tags: ['hear', 'hearing', 'mic', 'microphone', 'noise', 'record', 'sound', 'voice'],
    },
    {
      name: 'mic_external_off',

      categories: ['image'],
      tags: ['audio', 'disabled', 'enabled', 'external', 'mic', 'microphone', 'off', 'on', 'slash', 'sound', 'voice'],
    },
    {
      name: 'mic_external_on',

      categories: ['image'],
      tags: ['audio', 'disabled', 'enabled', 'external', 'mic', 'microphone', 'off', 'on', 'slash', 'sound', 'voice'],
    },
    {
      name: 'mic_none',

      categories: ['av'],
      tags: ['hear', 'hearing', 'mic', 'microphone', 'noise', 'none', 'record', 'sound', 'voice'],
    },
    {
      name: 'mic_off',

      categories: ['av'],
      tags: [
        'audio',
        'disabled',
        'enabled',
        'hear',
        'hearing',
        'mic',
        'microphone',
        'noise',
        'off',
        'on',
        'record',
        'recording',
        'slash',
        'sound',
        'voice',
      ],
    },
    {
      name: 'microwave',

      categories: ['places'],
      tags: ['appliance', 'cooking', 'electric', 'heat', 'home', 'house', 'kitchen', 'machine', 'microwave'],
    },
    {
      name: 'military_tech',

      categories: ['social'],
      tags: [
        'army',
        'award',
        'badge',
        'honor',
        'medal',
        'merit',
        'military',
        'order',
        'privilege',
        'prize',
        'rank',
        'reward',
        'ribbon',
        'soldier',
        'star',
        'status',
        'tech',
        'trophy',
        'win',
        'winner',
      ],
    },
    {
      name: 'minimize',

      categories: ['action'],
      tags: [
        'app',
        'application',
        'components',
        'design',
        'interface',
        'line',
        'minimize',
        'screen',
        'shape',
        'site',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'minor_crash',

      categories: ['maps'],
      tags: [
        'accident',
        'auto',
        'automobile',
        'car',
        'cars',
        'collision',
        'directions',
        'maps',
        'public',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'miscellaneous_services',

      categories: ['maps'],
      tags: [],
    },
    {
      name: 'missed_video_call',

      categories: ['av'],
      tags: [
        'arrow',
        'call',
        'camera',
        'film',
        'filming',
        'hardware',
        'image',
        'missed',
        'motion',
        'picture',
        'record',
        'video',
        'videography',
      ],
    },
    {
      name: 'mms',

      categories: ['notification'],
      tags: [
        'bubble',
        'chat',
        'comment',
        'communicate',
        'feedback',
        'image',
        'landscape',
        'message',
        'mms',
        'mountain',
        'mountains',
        'multimedia',
        'photo',
        'photography',
        'picture',
        'speech',
      ],
    },
    {
      name: 'mobile_friendly',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'approve',
        'cell',
        'check',
        'complete',
        'device',
        'done',
        'friendly',
        'hardware',
        'iOS',
        'mark',
        'mobile',
        'ok',
        'phone',
        'select',
        'tablet',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'mobile_off',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'cell',
        'device',
        'disabled',
        'enabled',
        'hardware',
        'iOS',
        'mobile',
        'off',
        'on',
        'phone',
        'silence',
        'slash',
        'tablet',
      ],
    },
    {
      name: 'mobile_screen_share',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'cast',
        'cell',
        'device',
        'hardware',
        'iOS',
        'mirror',
        'mobile',
        'monitor',
        'phone',
        'screen',
        'screencast',
        'share',
        'stream',
        'streaming',
        'tablet',
        'tv',
        'wireless',
      ],
    },
    {
      name: 'mobiledata_off',

      categories: ['device'],
      tags: [
        'arrow',
        'data',
        'disabled',
        'down',
        'enabled',
        'internet',
        'mobile',
        'network',
        'off',
        'on',
        'slash',
        'speed',
        'up',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'mode',

      categories: ['editor'],
      tags: ['compose', 'create', 'draft', 'draw', 'edit', 'mode', 'pen', 'pencil', 'write'],
    },
    {
      name: 'mode_comment',

      categories: ['editor'],
      tags: ['bubble', 'chat', 'comment', 'communicate', 'feedback', 'message', 'mode comment', 'speech'],
    },
    {
      name: 'mode_edit',

      categories: ['editor'],
      tags: ['compose', 'create', 'draft', 'draw', 'edit', 'mode', 'pen', 'pencil', 'write'],
    },
    {
      name: 'mode_edit_outline',

      categories: ['editor'],
      tags: ['compose', 'create', 'draft', 'draw', 'edit', 'mode', 'outline', 'pen', 'pencil', 'write'],
    },
    {
      name: 'mode_fan_off',

      categories: ['home'],
      tags: ['air conditioner', 'cool', 'disabled', 'enabled', 'fan', 'nest', 'off', 'on', 'slash'],
    },
    {
      name: 'mode_night',

      categories: ['device'],
      tags: ['dark', 'disturb', 'mode', 'moon', 'night', 'sleep', 'weather'],
    },
    {
      name: 'mode_of_travel',

      categories: ['maps'],
      tags: [
        'arrow',
        'destination',
        'direction',
        'location',
        'maps',
        'mode',
        'of',
        'pin',
        'place',
        'stop',
        'transportation',
        'travel',
        'trip',
      ],
    },
    {
      name: 'mode_standby',

      categories: ['device'],
      tags: ['disturb', 'mode', 'power', 'sleep', 'standby', 'target'],
    },
    {
      name: 'model_training',

      categories: ['action'],
      tags: [
        'arrow',
        'bulb',
        'idea',
        'inprogress',
        'light',
        'load',
        'loading',
        'model',
        'refresh',
        'renew',
        'restore',
        'reverse',
        'rotate',
        'training',
      ],
    },
    {
      name: 'monetization_on',

      categories: ['editor'],
      tags: [
        'bill',
        'card',
        'cash',
        'circle',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'monetization',
        'money',
        'on',
        'online',
        'pay',
        'payment',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'money',

      categories: ['maps'],
      tags: [
        '100',
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'digit',
        'dollars',
        'finance',
        'money',
        'number',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'money_off',

      categories: ['editor'],
      tags: [
        'bill',
        'card',
        'cart',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'disabled',
        'dollars',
        'enabled',
        'money',
        'off',
        'on',
        'online',
        'pay',
        'payment',
        'shopping',
        'slash',
        'symbol',
      ],
    },
    {
      name: 'money_off_csred',

      categories: ['editor'],
      tags: [
        'bill',
        'card',
        'cart',
        'cash',
        'coin',
        'commerce',
        'credit',
        'csred',
        'currency',
        'disabled',
        'dollars',
        'enabled',
        'money',
        'off',
        'on',
        'online',
        'pay',
        'payment',
        'shopping',
        'slash',
        'symbol',
      ],
    },
    {
      name: 'monitor',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'device',
        'display',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'screen',
        'web',
        'window',
      ],
    },
    {
      name: 'monitor_heart',

      categories: ['device'],
      tags: ['baseline', 'device', 'ecc', 'fitness', 'health', 'heart', 'medical', 'monitor', 'track'],
    },
    {
      name: 'monitor_weight',

      categories: ['device'],
      tags: ['body', 'device', 'diet', 'health', 'monitor', 'scale', 'smart', 'weight'],
    },
    {
      name: 'monochrome_photos',

      categories: ['image'],
      tags: ['black', 'camera', 'image', 'monochrome', 'photo', 'photography', 'photos', 'picture', 'white'],
    },
    {
      name: 'mood',

      categories: ['social'],
      tags: [
        'emoji',
        'emotions',
        'expressions',
        'face',
        'feelings',
        'glad',
        'happiness',
        'happy',
        'like',
        'mood',
        'person',
        'pleased',
        'smile',
        'smiling',
        'social',
        'survey',
      ],
    },
    {
      name: 'mood_bad',

      categories: ['social'],
      tags: [
        'bad',
        'disappointment',
        'dislike',
        'emoji',
        'emotions',
        'expressions',
        'face',
        'feelings',
        'mood',
        'person',
        'rating',
        'social',
        'survey',
        'unhappiness',
        'unhappy',
        'unpleased',
        'unsmile',
        'unsmiling',
      ],
    },
    {
      name: 'moped',

      categories: ['maps'],
      tags: ['automobile', 'bike', 'car', 'cars', 'maps', 'scooter', 'transportation', 'vehicle', 'vespa'],
    },
    {
      name: 'more',

      categories: ['notification'],
      tags: [
        '3',
        'archive',
        'bookmark',
        'dots',
        'etc',
        'favorite',
        'indent',
        'label',
        'more',
        'remember',
        'save',
        'stamp',
        'sticker',
        'tab',
        'tag',
        'three',
      ],
    },
    {
      name: 'more_horiz',

      categories: ['navigation'],
      tags: [
        '3',
        'app',
        'application',
        'components',
        'disable_ios',
        'dots',
        'etc',
        'horiz',
        'horizontal',
        'interface',
        'ios',
        'more',
        'screen',
        'site',
        'three',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'more_time',

      categories: ['communication'],
      tags: ['+', 'add', 'clock', 'date', 'more', 'new', 'plus', 'schedule', 'symbol', 'time'],
    },
    {
      name: 'more_vert',

      categories: ['navigation'],
      tags: [
        '3',
        'android',
        'app',
        'application',
        'components',
        'disable_ios',
        'dots',
        'etc',
        'interface',
        'more',
        'screen',
        'site',
        'three',
        'ui',
        'ux',
        'vert',
        'vertical',
        'web',
        'website',
      ],
    },
    {
      name: 'mosque',

      categories: ['maps'],
      tags: ['islam', 'islamic', 'masjid', 'muslim', 'religion', 'spiritual', 'worship'],
    },
    {
      name: 'motion_photos_auto',

      categories: ['image'],
      tags: [
        'A',
        'alphabet',
        'animation',
        'auto',
        'automatic',
        'character',
        'circle',
        'font',
        'gif',
        'letter',
        'live',
        'motion',
        'photos',
        'symbol',
        'text',
        'type',
        'video',
      ],
    },
    {
      name: 'motion_photos_off',

      categories: ['image'],
      tags: ['animation', 'circle', 'disabled', 'enabled', 'motion', 'off', 'on', 'photos', 'slash', 'video'],
    },
    {
      name: 'motion_photos_on',

      categories: ['image'],
      tags: ['animation', 'circle', 'disabled', 'enabled', 'motion', 'off', 'on', 'photos', 'play', 'slash', 'video'],
    },
    {
      name: 'motion_photos_pause',

      categories: ['image'],
      tags: ['animation', 'circle', 'motion', 'pause', 'paused', 'photos', 'video'],
    },
    {
      name: 'motion_photos_paused',

      categories: ['image'],
      tags: ['animation', 'circle', 'motion', 'pause', 'paused', 'photos', 'video'],
    },
    {
      name: 'mouse',

      categories: ['hardware'],
      tags: ['click', 'computer', 'cursor', 'device', 'hardware', 'mouse', 'wireless'],
    },
    {
      name: 'move_down',

      categories: ['editor'],
      tags: ['arrow', 'direction', 'down', 'jump', 'move', 'navigation', 'transfer'],
    },
    {
      name: 'move_to_inbox',

      categories: ['content'],
      tags: [
        'archive',
        'arrow',
        'down',
        'email',
        'envelop',
        'inbox',
        'incoming',
        'letter',
        'mail',
        'message',
        'move to',
        'send',
      ],
    },
    {
      name: 'move_up',

      categories: ['editor'],
      tags: ['arrow', 'direction', 'jump', 'move', 'navigation', 'transfer', 'up'],
    },
    {
      name: 'movie',

      categories: ['av'],
      tags: ['cinema', 'film', 'media', 'movie', 'slate', 'video'],
    },
    {
      name: 'movie_creation',

      categories: ['image'],
      tags: ['clapperboard', 'creation', 'film', 'movie', 'movies', 'slate', 'video'],
    },
    {
      name: 'movie_filter',

      categories: ['image'],
      tags: ['clapperboard', 'creation', 'film', 'filter', 'movie', 'movies', 'slate', 'stars', 'video'],
    },
    {
      name: 'moving',

      categories: ['maps'],
      tags: ['arrow', 'direction', 'moving', 'navigation', 'travel', 'up'],
    },
    {
      name: 'mp',

      categories: ['image'],
      tags: [
        'alphabet',
        'character',
        'font',
        'image',
        'letter',
        'megapixel',
        'mp',
        'photo',
        'photography',
        'pixels',
        'quality',
        'resolution',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'multiline_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'line',
        'measure',
        'metrics',
        'multiple',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'multiple_stop',

      categories: ['maps'],
      tags: ['arrows', 'directions', 'dots', 'left', 'maps', 'multiple', 'navigation', 'right', 'stop'],
    },
    {
      name: 'museum',

      categories: ['maps'],
      tags: [
        'architecture',
        'attraction',
        'building',
        'estate',
        'event',
        'exhibition',
        'explore',
        'local',
        'museum',
        'places',
        'real',
        'see',
        'shop',
        'store',
        'tour',
      ],
    },
    {
      name: 'music_note',

      categories: ['image'],
      tags: ['audio', 'audiotrack', 'key', 'music', 'note', 'sound', 'track'],
    },
    {
      name: 'music_off',

      categories: ['image'],
      tags: [
        'audio',
        'audiotrack',
        'disabled',
        'enabled',
        'key',
        'music',
        'note',
        'off',
        'on',
        'slash',
        'sound',
        'track',
      ],
    },
    {
      name: 'music_video',

      categories: ['av'],
      tags: ['band', 'music', 'recording', 'screen', 'tv', 'video', 'watch'],
    },
    {
      name: 'my_location',

      categories: ['maps'],
      tags: ['destination', 'direction', 'location', 'maps', 'navigation', 'pin', 'place', 'point', 'stop'],
    },
    {
      name: 'nat',

      categories: ['communication'],
      tags: ['communication', 'nat'],
    },
    {
      name: 'nature',

      categories: ['image'],
      tags: ['forest', 'nature', 'outdoor', 'outside', 'park', 'tree', 'wilderness'],
    },
    {
      name: 'nature_people',

      categories: ['image'],
      tags: [
        'activity',
        'body',
        'forest',
        'human',
        'nature',
        'outdoor',
        'outside',
        'park',
        'people',
        'person',
        'tree',
        'wilderness',
      ],
    },
    {
      name: 'navigate_before',

      categories: ['image'],
      tags: ['arrow', 'arrows', 'before', 'direction', 'left', 'navigate'],
    },
    {
      name: 'navigate_next',

      categories: ['image'],
      tags: ['arrow', 'arrows', 'direction', 'navigate', 'next', 'right'],
    },
    {
      name: 'navigation',

      categories: ['maps'],
      tags: ['destination', 'direction', 'location', 'maps', 'navigation', 'pin', 'place', 'point', 'stop'],
    },
    {
      name: 'near_me',

      categories: ['maps'],
      tags: [
        'destination',
        'direction',
        'location',
        'maps',
        'me',
        'navigation',
        'near',
        'pin',
        'place',
        'point',
        'stop',
      ],
    },
    {
      name: 'near_me_disabled',

      categories: ['maps'],
      tags: [
        'destination',
        'direction',
        'disabled',
        'enabled',
        'location',
        'maps',
        'me',
        'navigation',
        'near',
        'off',
        'on',
        'pin',
        'place',
        'point',
        'slash',
      ],
    },
    {
      name: 'nearby_error',

      categories: ['device'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'nearby',
        'notification',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'nearby_off',

      categories: ['device'],
      tags: ['disabled', 'enabled', 'nearby', 'off', 'on', 'slash'],
    },
    {
      name: 'nest_cam_wired_stand',

      categories: ['home'],
      tags: [
        'camera',
        'film',
        'filming',
        'hardware',
        'image',
        'motion',
        'nest',
        'picture',
        'stand',
        'video',
        'videography',
        'wired',
      ],
    },
    {
      name: 'network_cell',

      categories: ['device'],
      tags: ['cell', 'cellular', 'data', 'internet', 'mobile', 'network', 'phone', 'speed', 'wifi', 'wireless'],
    },
    {
      name: 'network_check',

      categories: ['notification'],
      tags: [
        'check',
        'connect',
        'connection',
        'internet',
        'meter',
        'network',
        'signal',
        'speed',
        'tick',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'network_locked',

      categories: ['notification'],
      tags: [
        'alert',
        'available',
        'cellular',
        'connection',
        'data',
        'error',
        'internet',
        'lock',
        'locked',
        'mobile',
        'network',
        'not',
        'privacy',
        'private',
        'protection',
        'restricted',
        'safety',
        'secure',
        'security',
        'service',
        'signal',
        'warning',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'network_ping',

      categories: ['action'],
      tags: [
        'alert',
        'available',
        'cellular',
        'connection',
        'data',
        'internet',
        'ip',
        'mobile',
        'network',
        'ping',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'network_wifi',

      categories: ['device'],
      tags: ['cell', 'cellular', 'data', 'internet', 'mobile', 'network', 'phone', 'speed', 'wifi', 'wireless'],
    },
    {
      name: 'network_wifi_1_bar',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'network_wifi_2_bar',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'network_wifi_3_bar',

      categories: ['device'],
      tags: [],
    },
    {
      name: 'new_label',

      categories: ['action'],
      tags: [
        '+',
        'add',
        'archive',
        'bookmark',
        'favorite',
        'label',
        'library',
        'new',
        'plus',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'symbol',
        'tag',
      ],
    },
    {
      name: 'new_releases',

      categories: ['av'],
      tags: [
        '!',
        'alert',
        'attention',
        'burst',
        'caution',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'new',
        'notification',
        'release',
        'releases',
        'star',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'newspaper',

      categories: ['file'],
      tags: [
        'article',
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'magazine',
        'media',
        'news',
        'newspaper',
        'notes',
        'page',
        'paper',
        'sheet',
        'slide',
        'text',
        'writing',
      ],
    },
    {
      name: 'next_plan',

      categories: ['action'],
      tags: ['arrow', 'circle', 'next', 'plan', 'right'],
    },
    {
      name: 'next_week',

      categories: ['content'],
      tags: ['arrow', 'bag', 'baggage', 'briefcase', 'business', 'case', 'next', 'suitcase', 'week'],
    },
    {
      name: 'nfc',

      categories: ['device'],
      tags: ['communication', 'data', 'field', 'mobile', 'near', 'nfc', 'wireless'],
    },
    {
      name: 'night_shelter',

      categories: ['places'],
      tags: [
        'architecture',
        'bed',
        'building',
        'estate',
        'homeless',
        'house',
        'night',
        'place',
        'real',
        'shelter',
        'sleep',
      ],
    },
    {
      name: 'nightlife',

      categories: ['maps'],
      tags: [
        'alcohol',
        'bar',
        'bottle',
        'club',
        'cocktail',
        'dance',
        'drink',
        'food',
        'glass',
        'liquor',
        'music',
        'nightlife',
        'note',
        'wine',
      ],
    },
    {
      name: 'nightlight',

      categories: ['device'],
      tags: ['dark', 'disturb', 'mode', 'moon', 'night', 'nightlight', 'sleep', 'weather'],
    },
    {
      name: 'nightlight_round',

      categories: ['action'],
      tags: ['dark', 'half', 'light', 'mode', 'moon', 'night', 'nightlight', 'round'],
    },
    {
      name: 'nights_stay',

      categories: ['social'],
      tags: [
        'cloud',
        'crescent',
        'dark',
        'mode',
        'moon',
        'nights',
        'phases',
        'silence',
        'silent',
        'sky',
        'stay',
        'time',
        'weather',
      ],
    },
    {
      name: 'no_accounts',

      categories: ['action'],
      tags: [
        'account',
        'accounts',
        'avatar',
        'disabled',
        'enabled',
        'face',
        'human',
        'no',
        'off',
        'offline',
        'on',
        'people',
        'person',
        'profile',
        'slash',
        'thumbnail',
        'unavailable',
        'unidentifiable',
        'unknown',
        'user',
      ],
    },
    {
      name: 'no_adult_content',

      categories: ['social'],
      tags: [],
    },
    {
      name: 'no_backpack',

      categories: ['places'],
      tags: ['accessory', 'backpack', 'bag', 'bookbag', 'knapsack', 'no', 'pack', 'travel'],
    },
    {
      name: 'no_cell',

      categories: ['places'],
      tags: [
        'Android',
        'OS',
        'cell',
        'device',
        'disabled',
        'enabled',
        'hardware',
        'iOS',
        'mobile',
        'no',
        'off',
        'on',
        'phone',
        'slash',
        'tablet',
      ],
    },
    {
      name: 'no_crash',

      categories: ['maps'],
      tags: [
        'accident',
        'auto',
        'automobile',
        'car',
        'cars',
        'check',
        'collision',
        'confirm',
        'correct',
        'crash',
        'direction',
        'done',
        'enter',
        'maps',
        'mark',
        'no',
        'ok',
        'okay',
        'select',
        'tick',
        'transportation',
        'vehicle',
        'yes',
      ],
    },
    {
      name: 'no_drinks',

      categories: ['places'],
      tags: ['alcohol', 'beverage', 'bottle', 'cocktail', 'drink', 'drinks', 'food', 'liquor', 'no', 'wine'],
    },
    {
      name: 'no_encryption',

      categories: ['notification'],
      tags: ['disabled', 'enabled', 'encryption', 'lock', 'no', 'off', 'on', 'password', 'safety', 'security', 'slash'],
    },
    {
      name: 'no_encryption_gmailerrorred',

      categories: ['notification'],
      tags: ['disabled', 'enabled', 'encryption', 'error', 'gmail', 'lock', 'locked', 'no', 'off', 'on', 'slash'],
    },
    {
      name: 'no_flash',

      categories: ['places'],
      tags: [
        'bolt',
        'camera',
        'disabled',
        'enabled',
        'flash',
        'image',
        'lightning',
        'no',
        'off',
        'on',
        'photo',
        'photography',
        'picture',
        'slash',
        'thunderbolt',
      ],
    },
    {
      name: 'no_food',

      categories: ['places'],
      tags: ['disabled', 'drink', 'enabled', 'fastfood', 'food', 'hamburger', 'meal', 'no', 'off', 'on', 'slash'],
    },
    {
      name: 'no_luggage',

      categories: ['social'],
      tags: [
        'bag',
        'baggage',
        'carry',
        'disabled',
        'enabled',
        'luggage',
        'no',
        'off',
        'on',
        'slash',
        'suitcase',
        'travel',
      ],
    },
    {
      name: 'no_meals',

      categories: ['maps'],
      tags: [
        'dining',
        'disabled',
        'eat',
        'enabled',
        'food',
        'fork',
        'knife',
        'meal',
        'meals',
        'no',
        'off',
        'on',
        'restaurant',
        'slash',
        'spoon',
        'utensils',
      ],
    },
    {
      name: 'no_meeting_room',

      categories: ['places'],
      tags: [
        'building',
        'disabled',
        'door',
        'doorway',
        'enabled',
        'entrance',
        'home',
        'house',
        'interior',
        'meeting',
        'no',
        'off',
        'office',
        'on',
        'open',
        'places',
        'room',
        'slash',
      ],
    },
    {
      name: 'no_photography',

      categories: ['places'],
      tags: ['camera', 'disabled', 'enabled', 'image', 'no', 'off', 'on', 'photo', 'photography', 'picture', 'slash'],
    },
    {
      name: 'no_sim',

      categories: ['communication'],
      tags: ['camera', 'card', 'device', 'eject', 'insert', 'memory', 'no', 'phone', 'sim', 'storage'],
    },
    {
      name: 'no_stroller',

      categories: ['places'],
      tags: [
        'baby',
        'care',
        'carriage',
        'child',
        'children',
        'disabled',
        'enabled',
        'infant',
        'kid',
        'newborn',
        'no',
        'off',
        'on',
        'parents',
        'slash',
        'stroller',
        'toddler',
        'young',
      ],
    },
    {
      name: 'no_transfer',

      categories: ['maps'],
      tags: [
        'automobile',
        'bus',
        'car',
        'cars',
        'direction',
        'disabled',
        'enabled',
        'maps',
        'no',
        'off',
        'on',
        'public',
        'slash',
        'transfer',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'noise_aware',

      categories: ['action'],
      tags: ['audio', 'aware', 'cancellation', 'music', 'noise', 'note', 'sound'],
    },
    {
      name: 'noise_control_off',

      categories: ['action'],
      tags: [
        'audio',
        'aware',
        'cancel',
        'cancellation',
        'control',
        'disabled',
        'enabled',
        'music',
        'noise',
        'note',
        'off',
        'offline',
        'on',
        'slash',
        'sound',
      ],
    },
    {
      name: 'nordic_walking',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'body',
        'entertainment',
        'exercise',
        'hiking',
        'hobby',
        'human',
        'nordic',
        'people',
        'person',
        'social',
        'sports',
        'travel',
        'walker',
        'walking',
      ],
    },
    {
      name: 'north',

      categories: ['navigation'],
      tags: ['arrow', 'directional', 'maps', 'navigation', 'north', 'up'],
    },
    {
      name: 'north_east',

      categories: ['navigation'],
      tags: ['arrow', 'east', 'maps', 'navigation', 'noth', 'right', 'up'],
    },
    {
      name: 'north_west',

      categories: ['navigation'],
      tags: ['arrow', 'directional', 'left', 'maps', 'navigation', 'north', 'up', 'west'],
    },
    {
      name: 'not_accessible',

      categories: ['action'],
      tags: ['accessibility', 'accessible', 'body', 'handicap', 'help', 'human', 'not', 'person', 'wheelchair'],
    },
    {
      name: 'not_interested',

      categories: ['av'],
      tags: ['cancel', 'close', 'dislike', 'exit', 'interested', 'no', 'not', 'off', 'quit', 'remove', 'stop', 'x'],
    },
    {
      name: 'not_listed_location',

      categories: ['maps'],
      tags: [
        '?',
        'assistance',
        'destination',
        'direction',
        'help',
        'info',
        'information',
        'listed',
        'location',
        'maps',
        'not',
        'pin',
        'place',
        'punctuation',
        'question mark',
        'stop',
        'support',
        'symbol',
      ],
    },
    {
      name: 'not_started',

      categories: ['action'],
      tags: ['circle', 'media', 'not', 'pause', 'play', 'started', 'video'],
    },
    {
      name: 'note',

      categories: ['av'],
      tags: ['bookmark', 'message', 'note', 'paper'],
    },
    {
      name: 'note_add',

      categories: ['action'],
      tags: [
        '+',
        '-doc',
        'add',
        'data',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'new',
        'note',
        'page',
        'paper',
        'plus',
        'sheet',
        'slide',
        'symbol',
        'writing',
      ],
    },
    {
      name: 'note_alt',

      categories: ['device'],
      tags: ['alt', 'clipboard', 'document', 'file', 'memo', 'note', 'page', 'paper', 'writing'],
    },
    {
      name: 'notes',

      categories: ['editor'],
      tags: ['comment', 'doc', 'document', 'note', 'notes', 'text', 'write', 'writing'],
    },
    {
      name: 'notification_add',

      categories: ['social'],
      tags: [
        '+',
        'active',
        'add',
        'alarm',
        'alert',
        'bell',
        'chime',
        'notification',
        'notifications',
        'notify',
        'plus',
        'reminder',
        'ring',
        'sound',
        'symbol',
      ],
    },
    {
      name: 'notification_important',

      categories: ['alert'],
      tags: [
        '!',
        'active',
        'alarm',
        'alert',
        'attention',
        'bell',
        'caution',
        'chime',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'notification',
        'notifications',
        'notify',
        'reminder',
        'ring',
        'sound',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'notifications',

      categories: ['social'],
      tags: ['active', 'alarm', 'alert', 'bell', 'chime', 'notifications', 'notify', 'reminder', 'ring', 'sound'],
    },
    {
      name: 'notifications_active',

      categories: ['social'],
      tags: [
        'active',
        'alarm',
        'alert',
        'bell',
        'chime',
        'notifications',
        'notify',
        'reminder',
        'ring',
        'ringing',
        'sound',
      ],
    },
    {
      name: 'notifications_none',

      categories: ['social'],
      tags: ['alarm', 'alert', 'bell', 'none', 'notifications', 'notify', 'reminder', 'sound'],
    },
    {
      name: 'notifications_off',

      categories: ['social'],
      tags: [
        'active',
        'alarm',
        'alert',
        'bell',
        'chime',
        'disabled',
        'enabled',
        'notifications',
        'notify',
        'off',
        'offline',
        'on',
        'reminder',
        'ring',
        'slash',
        'sound',
      ],
    },
    {
      name: 'notifications_paused',

      categories: ['social'],
      tags: [
        'active',
        'alarm',
        'alert',
        'bell',
        'chime',
        'ignore',
        'notifications',
        'notify',
        'paused',
        'quiet',
        'reminder',
        'ring --- pause',
        'sleep',
        'snooze',
        'sound',
        'z',
        'zzz',
      ],
    },
    {
      name: 'numbers',

      categories: ['editor'],
      tags: ['digit', 'number', 'numbers', 'symbol'],
    },
    {
      name: 'offline_bolt',

      categories: ['action'],
      tags: ['bolt', 'circle', 'electric', 'fast', 'lightning', 'offline', 'thunderbolt'],
    },
    {
      name: 'offline_pin',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'checkmark',
        'circle',
        'complete',
        'done',
        'mark',
        'offline',
        'ok',
        'pin',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'offline_share',

      categories: ['navigation'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'cell',
        'connect',
        'device',
        'direction',
        'hardware',
        'iOS',
        'link',
        'mobile',
        'multiple',
        'offline',
        'phone',
        'right',
        'share',
        'tablet',
      ],
    },
    {
      name: 'oil_barrel',

      categories: ['home'],
      tags: ['barrel', 'droplet', 'gas', 'gasoline', 'nest', 'oil', 'water'],
    },
    {
      name: 'on_device_training',

      categories: ['action'],
      tags: [
        'arrow',
        'bulb',
        'call',
        'cell',
        'contact',
        'device',
        'hardware',
        'idea',
        'inprogress',
        'light',
        'load',
        'loading',
        'mobile',
        'model',
        'phone',
        'refresh',
        'renew',
        'restore',
        'reverse',
        'rotate',
        'telephone',
        'training',
      ],
    },
    {
      name: 'ondemand_video',

      categories: ['notification'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'demand',
        'desktop',
        'device',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'ondemand',
        'play',
        'television',
        'tv',
        'video',
        'web',
        'window',
      ],
    },
    {
      name: 'online_prediction',

      categories: ['action'],
      tags: ['bulb', 'connection', 'idea', 'light', 'network', 'online', 'prediction', 'signal', 'wireless'],
    },
    {
      name: 'opacity',

      categories: ['action'],
      tags: ['color', 'drop', 'droplet', 'hue', 'invert', 'inverted', 'opacity', 'palette', 'tone', 'water'],
    },
    {
      name: 'open_in_browser',

      categories: ['action'],
      tags: ['arrow', 'browser', 'in', 'open', 'site', 'up', 'web', 'website', 'window'],
    },
    {
      name: 'open_in_full',

      categories: ['action'],
      tags: ['action', 'arrow', 'arrows', 'expand', 'full', 'grow', 'in', 'move', 'open'],
    },
    {
      name: 'open_in_new',

      categories: ['action'],
      tags: [
        'app',
        'application',
        'arrow',
        'box',
        'components',
        'in',
        'interface',
        'new',
        'open',
        'screen',
        'site',
        'ui',
        'ux',
        'web',
        'website',
        'window',
      ],
    },
    {
      name: 'open_in_new_off',

      categories: ['action'],
      tags: ['arrow', 'box', 'disabled', 'enabled', 'export', 'in', 'new', 'off', 'on', 'open', 'slash', 'window'],
    },
    {
      name: 'open_with',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'direction', 'expand', 'move', 'open', 'pan', 'with'],
    },
    {
      name: 'other_houses',

      categories: ['places'],
      tags: [
        'architecture',
        'cottage',
        'estate',
        'home',
        'house',
        'houses',
        'maps',
        'other',
        'place',
        'real',
        'residence',
        'residential',
        'stay',
        'traveling',
      ],
    },
    {
      name: 'outbound',

      categories: ['action'],
      tags: ['arrow', 'circle', 'directional', 'outbound', 'right', 'up'],
    },
    {
      name: 'outbox',

      categories: ['action'],
      tags: ['box', 'mail', 'outbox', 'send', 'sent'],
    },
    {
      name: 'outdoor_grill',

      categories: ['social'],
      tags: ['barbecue', 'bbq', 'charcoal', 'cooking', 'grill', 'home', 'house', 'outdoor', 'outside'],
    },
    {
      name: 'outlet',

      categories: ['action'],
      tags: ['connect', 'connecter', 'electricity', 'outlet', 'plug', 'power'],
    },
    {
      name: 'outlined_flag',

      categories: ['content'],
      tags: ['country', 'flag', 'goal', 'mark', 'nation', 'outlined', 'report', 'start'],
    },
    {
      name: 'output',

      categories: ['action'],
      tags: [],
    },
    {
      name: 'padding',

      categories: ['editor'],
      tags: ['design', 'layout', 'margin', 'padding', 'size', 'square'],
    },
    {
      name: 'pages',

      categories: ['social'],
      tags: ['article', 'gplus', 'pages', 'paper', 'post', 'star'],
    },
    {
      name: 'pageview',

      categories: ['action'],
      tags: ['doc', 'document', 'find', 'glass', 'magnifying', 'page', 'paper', 'search', 'view'],
    },
    {
      name: 'paid',

      categories: ['action'],
      tags: ['circle', 'currency', 'money', 'paid', 'payment', 'transaction'],
    },
    {
      name: 'palette',

      categories: ['image'],
      tags: ['art', 'color', 'colors', 'filters', 'paint', 'palette'],
    },
    {
      name: 'pan_tool',

      categories: ['action'],
      tags: ['fingers', 'gesture', 'hand', 'hands', 'human', 'move', 'pan', 'scan', 'stop', 'tool'],
    },
    {
      name: 'pan_tool_alt',

      categories: ['action'],
      tags: ['fingers', 'gesture', 'hand', 'hands', 'human', 'move', 'pan', 'scan', 'stop', 'tool'],
    },
    {
      name: 'panorama',

      categories: ['image'],
      tags: ['angle', 'image', 'mountain', 'mountains', 'panorama', 'photo', 'photography', 'picture', 'view', 'wide'],
    },
    {
      name: 'panorama_fish_eye',

      categories: ['image'],
      tags: ['angle', 'circle', 'eye', 'fish', 'image', 'panorama', 'photo', 'photography', 'picture', 'wide'],
    },
    {
      name: 'panorama_horizontal',

      categories: ['image'],
      tags: ['angle', 'horizontal', 'image', 'panorama', 'photo', 'photography', 'picture', 'wide'],
    },
    {
      name: 'panorama_horizontal_select',

      categories: ['image'],
      tags: ['angle', 'horizontal', 'image', 'panorama', 'photo', 'photography', 'picture', 'select', 'wide'],
    },
    {
      name: 'panorama_photosphere',

      categories: ['image'],
      tags: ['angle', 'horizontal', 'image', 'panorama', 'photo', 'photography', 'photosphere', 'picture', 'wide'],
    },
    {
      name: 'panorama_photosphere_select',

      categories: ['image'],
      tags: [
        'angle',
        'horizontal',
        'image',
        'panorama',
        'photo',
        'photography',
        'photosphere',
        'picture',
        'select',
        'wide',
      ],
    },
    {
      name: 'panorama_vertical',

      categories: ['image'],
      tags: ['angle', 'image', 'panorama', 'photo', 'photography', 'picture', 'vertical', 'wide'],
    },
    {
      name: 'panorama_vertical_select',

      categories: ['image'],
      tags: ['angle', 'image', 'panorama', 'photo', 'photography', 'picture', 'select', 'vertical', 'wide'],
    },
    {
      name: 'panorama_wide_angle',

      categories: ['image'],
      tags: ['angle', 'image', 'panorama', 'photo', 'photography', 'picture', 'wide'],
    },
    {
      name: 'panorama_wide_angle_select',

      categories: ['image'],
      tags: ['angle', 'image', 'panorama', 'photo', 'photography', 'picture', 'select', 'wide'],
    },
    {
      name: 'paragliding',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'body',
        'entertainment',
        'exercise',
        'fly',
        'gliding',
        'hobby',
        'human',
        'parachute',
        'paragliding',
        'people',
        'person',
        'sky',
        'skydiving',
        'social',
        'sports',
        'travel',
      ],
    },
    {
      name: 'park',

      categories: ['maps'],
      tags: ['attraction', 'fresh', 'local', 'nature', 'outside', 'park', 'plant', 'tree'],
    },
    {
      name: 'party_mode',

      categories: ['social'],
      tags: ['camera', 'lens', 'mode', 'party', 'photo', 'photography', 'picture'],
    },
    {
      name: 'password',

      categories: ['device'],
      tags: ['key', 'login', 'password', 'pin', 'security', 'star', 'unlock'],
    },
    {
      name: 'pattern',

      categories: ['device'],
      tags: ['key', 'login', 'password', 'pattern', 'pin', 'security', 'star', 'unlock'],
    },
    {
      name: 'pause',

      categories: ['av'],
      tags: ['control', 'controls', 'media', 'music', 'pause', 'video'],
    },
    {
      name: 'pause_circle',

      categories: ['av'],
      tags: ['circle', 'control', 'controls', 'media', 'music', 'pause', 'video'],
    },
    {
      name: 'pause_circle_filled',

      categories: ['av'],
      tags: ['circle', 'control', 'controls', 'filled', 'media', 'music', 'pause', 'video'],
    },
    {
      name: 'pause_circle_outline',

      categories: ['av'],
      tags: ['circle', 'control', 'controls', 'media', 'music', 'outline', 'pause', 'video'],
    },
    {
      name: 'pause_presentation',

      categories: ['communication'],
      tags: [
        'app',
        'application desktop',
        'device',
        'pause',
        'present',
        'presentation',
        'screen',
        'share',
        'site',
        'slides',
        'web',
        'website',
        'window',
        'www',
      ],
    },
    {
      name: 'payment',

      categories: ['action'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'payments',

      categories: ['navigation'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'layer',
        'money',
        'multiple',
        'online',
        'pay',
        'payment',
        'payments',
        'price',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'pedal_bike',

      categories: ['maps'],
      tags: [
        'automobile',
        'bicycle',
        'bike',
        'car',
        'cars',
        'direction',
        'human',
        'maps',
        'pedal',
        'public',
        'route',
        'scooter',
        'transportation',
        'vehicle',
        'vespa',
      ],
    },
    {
      name: 'pending',

      categories: ['action'],
      tags: ['circle', 'dots', 'loading', 'pending', 'progress', 'wait', 'waiting'],
    },
    {
      name: 'pending_actions',

      categories: ['action'],
      tags: ['actions', 'clipboard', 'clock', 'date', 'doc', 'document', 'pending', 'remember', 'schedule', 'time'],
    },
    {
      name: 'pentagon',

      categories: ['editor'],
      tags: ['five sides', 'pentagon', 'shape'],
    },
    {
      name: 'people',

      categories: ['social'],
      tags: [
        'accounts',
        'committee',
        'face',
        'family',
        'friends',
        'humans',
        'network',
        'people',
        'persons',
        'profiles',
        'social',
        'team',
        'users',
      ],
    },
    {
      name: 'people_alt',

      categories: ['social'],
      tags: [
        'accounts',
        'committee',
        'face',
        'family',
        'friends',
        'humans',
        'network',
        'people',
        'persons',
        'profiles',
        'social',
        'team',
        'users',
      ],
    },
    {
      name: 'people_outline',

      categories: ['social'],
      tags: [
        'accounts',
        'committee',
        'face',
        'family',
        'friends',
        'humans',
        'network',
        'outline',
        'people',
        'persons',
        'profiles',
        'social',
        'team',
        'users',
      ],
    },
    {
      name: 'percent',

      categories: ['action'],
      tags: ['math', 'number', 'percent', 'symbol'],
    },
    {
      name: 'perm_camera_mic',

      categories: ['action'],
      tags: ['camera', 'image', 'microphone', 'min', 'perm', 'photo', 'photography', 'picture', 'speaker'],
    },
    {
      name: 'perm_contact_calendar',

      categories: ['action'],
      tags: [
        'account',
        'calendar',
        'contact',
        'date',
        'face',
        'human',
        'information',
        'people',
        'perm',
        'person',
        'profile',
        'schedule',
        'time',
        'user',
      ],
    },
    {
      name: 'perm_data_setting',

      categories: ['action'],
      tags: ['data', 'gear', 'info', 'information', 'perm', 'settings'],
    },
    {
      name: 'perm_device_information',

      categories: ['action'],
      tags: [
        'Android',
        'OS',
        'alert',
        'announcement',
        'device',
        'hardware',
        'i',
        'iOS',
        'info',
        'information',
        'mobile',
        'perm',
        'phone',
        'tablet',
      ],
    },
    {
      name: 'perm_identity',

      categories: ['action'],
      tags: [
        'account',
        'avatar',
        'face',
        'human',
        'identity',
        'people',
        'perm',
        'person',
        'profile',
        'thumbnail',
        'user',
      ],
    },
    {
      name: 'perm_media',

      categories: ['action'],
      tags: [
        'collection',
        'data',
        'doc',
        'document',
        'file',
        'folder',
        'folders',
        'image',
        'landscape',
        'media',
        'mountain',
        'mountains',
        'perm',
        'photo',
        'photography',
        'picture',
        'storage',
      ],
    },
    {
      name: 'perm_phone_msg',

      categories: ['action'],
      tags: [
        'bubble',
        'call',
        'cell',
        'chat',
        'comment',
        'communicate',
        'contact',
        'device',
        'message',
        'msg',
        'perm',
        'phone',
        'recording',
        'speech',
        'telephone',
        'voice',
      ],
    },
    {
      name: 'perm_scan_wifi',

      categories: ['action'],
      tags: [
        'alert',
        'announcement',
        'connection',
        'info',
        'information',
        'internet',
        'network',
        'perm',
        'scan',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'person',

      categories: ['social'],
      tags: ['account', 'face', 'human', 'people', 'person', 'profile', 'user'],
    },
    {
      name: 'person_2',

      categories: ['social'],
      tags: ['account', 'face', 'human', 'people', 'person', 'profile', 'user'],
    },
    {
      name: 'person_3',

      categories: ['social'],
      tags: ['account', 'face', 'human', 'people', 'person', 'profile', 'user'],
    },
    {
      name: 'person_4',

      categories: ['social'],
      tags: ['account', 'face', 'human', 'people', 'person', 'profile', 'user'],
    },
    {
      name: 'person_add',

      categories: ['social'],
      tags: [
        '+',
        'account',
        'add',
        'avatar',
        'face',
        'human',
        'new',
        'people',
        'person',
        'plus',
        'profile',
        'symbol',
        'user',
      ],
    },
    {
      name: 'person_add_alt',

      categories: ['social'],
      tags: ['+', 'account', 'add', 'face', 'human', 'people', 'person', 'plus', 'profile', 'user'],
    },
    {
      name: 'person_add_alt_1',

      categories: ['social'],
      tags: [],
    },
    {
      name: 'person_add_disabled',

      categories: ['communication'],
      tags: [
        '+',
        'account',
        'add',
        'disabled',
        'enabled',
        'face',
        'human',
        'new',
        'off',
        'offline',
        'on',
        'people',
        'person',
        'plus',
        'profile',
        'slash',
        'symbol',
        'user',
      ],
    },
    {
      name: 'person_off',

      categories: ['social'],
      tags: [
        'account',
        'avatar',
        'disabled',
        'enabled',
        'face',
        'human',
        'off',
        'on',
        'people',
        'person',
        'profile',
        'slash',
        'user',
      ],
    },
    {
      name: 'person_outline',

      categories: ['social'],
      tags: ['account', 'face', 'human', 'outline', 'people', 'person', 'profile', 'user'],
    },
    {
      name: 'person_pin',

      categories: ['maps'],
      tags: [
        'account',
        'avatar',
        'destination',
        'direction',
        'face',
        'human',
        'location',
        'maps',
        'people',
        'person',
        'pin',
        'place',
        'profile',
        'stop',
        'user',
      ],
    },
    {
      name: 'person_pin_circle',

      categories: ['maps'],
      tags: [
        'account',
        'circle',
        'destination',
        'direction',
        'face',
        'human',
        'location',
        'maps',
        'people',
        'person',
        'pin',
        'place',
        'profile',
        'stop',
        'user',
      ],
    },
    {
      name: 'person_remove',

      categories: ['social'],
      tags: [
        'account',
        'avatar',
        'delete',
        'face',
        'human',
        'minus',
        'people',
        'person',
        'profile',
        'remove',
        'unfriend',
        'user',
      ],
    },
    {
      name: 'person_remove_alt_1',

      categories: ['social'],
      tags: [],
    },
    {
      name: 'person_search',

      categories: ['communication'],
      tags: [
        'account',
        'avatar',
        'face',
        'find',
        'glass',
        'human',
        'look',
        'magnify',
        'magnifying',
        'people',
        'person',
        'profile',
        'search',
        'user',
      ],
    },
    {
      name: 'personal_injury',

      categories: ['social'],
      tags: [
        'accident',
        'aid',
        'arm',
        'bandage',
        'body',
        'broke',
        'cast',
        'fracture',
        'health',
        'human',
        'injury',
        'medical',
        'patient',
        'people',
        'person',
        'personal',
        'sling',
        'social',
      ],
    },
    {
      name: 'personal_video',

      categories: ['notification'],
      tags: [
        'Android',
        'OS',
        'cam',
        'chrome',
        'desktop',
        'device',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'personal',
        'television',
        'tv',
        'video',
        'web',
        'window',
      ],
    },
    {
      name: 'pest_control',

      categories: ['maps'],
      tags: ['bug', 'control', 'exterminator', 'insects', 'pest'],
    },
    {
      name: 'pest_control_rodent',

      categories: ['maps'],
      tags: ['control', 'exterminator', 'mice', 'pest', 'rodent'],
    },
    {
      name: 'pets',

      categories: ['action'],
      tags: ['animal', 'cat', 'dog', 'hand', 'paw', 'pet'],
    },
    {
      name: 'phishing',

      categories: ['device'],
      tags: ['fish', 'fishing', 'fraud', 'hook', 'phishing', 'scam'],
    },
    {
      name: 'phone',

      categories: ['communication'],
      tags: ['call', 'cell', 'contact', 'device', 'hardware', 'mobile', 'phone', 'telephone'],
    },
    {
      name: 'phone_android',

      categories: ['hardware'],
      tags: ['OS', 'android', 'cell', 'device', 'hardware', 'iOS', 'mobile', 'phone', 'tablet'],
    },
    {
      name: 'phone_bluetooth_speaker',

      categories: ['notification'],
      tags: [
        'bluetooth',
        'call',
        'cell',
        'connect',
        'connection',
        'connectivity',
        'contact',
        'device',
        'hardware',
        'mobile',
        'phone',
        'signal',
        'speaker',
        'symbol',
        'telephone',
        'wireless',
      ],
    },
    {
      name: 'phone_callback',

      categories: ['notification'],
      tags: [
        'arrow',
        'call',
        'callback',
        'cell',
        'contact',
        'device',
        'down',
        'hardware',
        'mobile',
        'phone',
        'telephone',
      ],
    },
    {
      name: 'phone_disabled',

      categories: ['communication'],
      tags: [
        'call',
        'cell',
        'contact',
        'device',
        'disabled',
        'enabled',
        'hardware',
        'mobile',
        'off',
        'offline',
        'on',
        'phone',
        'slash',
        'telephone',
      ],
    },
    {
      name: 'phone_enabled',

      categories: ['communication'],
      tags: ['call', 'cell', 'contact', 'device', 'enabled', 'hardware', 'mobile', 'phone', 'telephone'],
    },
    {
      name: 'phone_forwarded',

      categories: ['notification'],
      tags: [
        'arrow',
        'call',
        'cell',
        'contact',
        'device',
        'direction',
        'forwarded',
        'hardware',
        'mobile',
        'phone',
        'right',
        'telephone',
      ],
    },
    {
      name: 'phone_in_talk',

      categories: ['notification'],
      tags: [
        'call',
        'cell',
        'connection',
        'contact',
        'data',
        'device',
        'hardware',
        'in',
        'mobile',
        'network',
        'phone',
        'scan',
        'service',
        'signal',
        'sound',
        'speaker',
        'talk',
        'telephone',
        'waves',
        'wireless',
      ],
    },
    {
      name: 'phone_iphone',

      categories: ['hardware'],
      tags: ['Android', 'OS', 'cell', 'device', 'hardware', 'iOS', 'iphone', 'mobile', 'phone', 'tablet'],
    },
    {
      name: 'phone_locked',

      categories: ['notification'],
      tags: [
        'call',
        'cell',
        'contact',
        'device',
        'hardware',
        'lock',
        'locked',
        'mobile',
        'password',
        'phone',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
        'telephone',
      ],
    },
    {
      name: 'phone_missed',

      categories: ['notification'],
      tags: ['arrow', 'call', 'cell', 'contact', 'device', 'hardware', 'missed', 'mobile', 'phone', 'telephone'],
    },
    {
      name: 'phone_paused',

      categories: ['notification'],
      tags: ['call', 'cell', 'contact', 'device', 'hardware', 'mobile', 'pause', 'paused', 'phone', 'telephone'],
    },
    {
      name: 'phonelink',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'computer',
        'connect',
        'desktop',
        'device',
        'hardware',
        'iOS',
        'link',
        'mac',
        'mobile',
        'phone',
        'phonelink',
        'sync',
        'tablet',
        'web',
        'windows',
      ],
    },
    {
      name: 'phonelink_erase',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'cancel',
        'cell',
        'close',
        'connection',
        'device',
        'erase',
        'exit',
        'hardware',
        'iOS',
        'mobile',
        'no',
        'phone',
        'phonelink',
        'remove',
        'stop',
        'tablet',
        'x',
      ],
    },
    {
      name: 'phonelink_lock',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'cell',
        'connection',
        'device',
        'erase',
        'hardware',
        'iOS',
        'lock',
        'locked',
        'mobile',
        'password',
        'phone',
        'phonelink',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
        'tablet',
      ],
    },
    {
      name: 'phonelink_off',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'computer',
        'connect',
        'desktop',
        'device',
        'disabled',
        'enabled',
        'hardware',
        'iOS',
        'link',
        'mac',
        'mobile',
        'off',
        'on',
        'phone',
        'phonelink',
        'slash',
        'sync',
        'tablet',
        'web',
        'windows',
      ],
    },
    {
      name: 'phonelink_ring',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'cell',
        'connection',
        'data',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'network',
        'phone',
        'phonelink',
        'ring',
        'service',
        'signal',
        'tablet',
        'wireless',
      ],
    },
    {
      name: 'phonelink_setup',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'call',
        'chat',
        'device',
        'hardware',
        'iOS',
        'info',
        'mobile',
        'phone',
        'phonelink',
        'settings',
        'setup',
        'tablet',
        'text',
      ],
    },
    {
      name: 'photo',

      categories: ['image'],
      tags: ['image', 'mountain', 'mountains', 'photo', 'photography', 'picture'],
    },
    {
      name: 'photo_album',

      categories: ['image'],
      tags: [
        'album',
        'archive',
        'bookmark',
        'image',
        'label',
        'library',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'ribbon',
        'save',
        'tag',
      ],
    },
    {
      name: 'photo_camera',

      categories: ['image'],
      tags: ['camera', 'image', 'photo', 'photography', 'picture'],
    },
    {
      name: 'photo_camera_back',

      categories: ['image'],
      tags: [
        'back',
        'camera',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'rear',
      ],
    },
    {
      name: 'photo_camera_front',

      categories: ['image'],
      tags: [
        'account',
        'camera',
        'face',
        'front',
        'human',
        'image',
        'people',
        'person',
        'photo',
        'photography',
        'picture',
        'portrait',
        'profile',
        'user',
      ],
    },
    {
      name: 'photo_filter',

      categories: ['image'],
      tags: ['filter', 'filters', 'image', 'photo', 'photography', 'picture', 'star', 'stars'],
    },
    {
      name: 'photo_library',

      categories: ['image'],
      tags: ['album', 'image', 'library', 'mountain', 'mountains', 'photo', 'photography', 'picture'],
    },
    {
      name: 'photo_size_select_actual',

      categories: ['image'],
      tags: ['actual', 'image', 'mountain', 'mountains', 'photo', 'photography', 'picture', 'select', 'size'],
    },
    {
      name: 'photo_size_select_large',

      categories: ['image'],
      tags: [
        'adjust',
        'album',
        'edit',
        'editing',
        'image',
        'large',
        'library',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'select',
        'size',
      ],
    },
    {
      name: 'photo_size_select_small',

      categories: ['image'],
      tags: [
        'adjust',
        'album',
        'edit',
        'editing',
        'image',
        'large',
        'library',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'select',
        'size',
        'small',
      ],
    },
    {
      name: 'php',

      categories: ['action'],
      tags: [
        'alphabet',
        'brackets',
        'character',
        'code',
        'css',
        'develop',
        'developer',
        'engineer',
        'engineering',
        'font',
        'html',
        'letter',
        'php',
        'platform',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'piano',

      categories: ['social'],
      tags: ['instrument', 'keyboard', 'keys', 'music', 'musical', 'piano', 'social'],
    },
    {
      name: 'piano_off',

      categories: ['social'],
      tags: [
        'disabled',
        'enabled',
        'instrument',
        'keyboard',
        'keys',
        'music',
        'musical',
        'off',
        'on',
        'piano',
        'slash',
        'social',
      ],
    },
    {
      name: 'picture_as_pdf',

      categories: ['image'],
      tags: [
        'alphabet',
        'as',
        'character',
        'document',
        'file',
        'font',
        'image',
        'letter',
        'multiple',
        'pdf',
        'photo',
        'photography',
        'picture',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'picture_in_picture',

      categories: ['action'],
      tags: ['crop', 'cropped', 'overlap', 'photo', 'picture', 'position', 'shape'],
    },
    {
      name: 'picture_in_picture_alt',

      categories: ['action'],
      tags: ['crop', 'cropped', 'overlap', 'photo', 'picture', 'position', 'shape'],
    },
    {
      name: 'pie_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'pie',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'pie_chart_outline',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'outline',
        'pie',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'pin',

      categories: ['device'],
      tags: [
        '1',
        '2',
        '3',
        'digit',
        'key',
        'login',
        'logout',
        'number',
        'password',
        'pattern',
        'pin',
        'security',
        'star',
        'symbol',
        'unlock',
      ],
    },
    {
      name: 'pin_drop',

      categories: ['maps'],
      tags: ['destination', 'direction', 'drop', 'location', 'maps', 'navigation', 'pin', 'place', 'stop'],
    },
    {
      name: 'pin_end',

      categories: ['action'],
      tags: ['action', 'arrow', 'dot', 'end', 'pin'],
    },
    {
      name: 'pin_invoke',

      categories: ['action'],
      tags: ['action', 'arrow', 'dot', 'invoke', 'pin'],
    },
    {
      name: 'pinch',

      categories: ['action'],
      tags: [
        'arrow',
        'arrows',
        'compress',
        'direction',
        'finger',
        'grasp',
        'hand',
        'navigation',
        'nip',
        'pinch',
        'squeeze',
        'tweak',
      ],
    },
    {
      name: 'pivot_table_chart',

      categories: ['navigation'],
      tags: [
        'analytics',
        'arrow',
        'arrows',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'direction',
        'drive',
        'edit',
        'editing',
        'graph',
        'grid',
        'infographic',
        'measure',
        'metrics',
        'pivot',
        'rotate',
        'sheet',
        'statistics',
        'table',
        'tracking',
      ],
    },
    {
      name: 'pix',

      categories: ['social'],
      tags: ['bill', 'brazil', 'card', 'cash', 'commerce', 'credit', 'currency', 'finance', 'money', 'payment'],
    },
    {
      name: 'place',

      categories: ['maps'],
      tags: ['destination', 'direction', 'location', 'maps', 'navigation', 'pin', 'place', 'point', 'stop'],
    },
    {
      name: 'plagiarism',

      categories: ['action'],
      tags: ['doc', 'document', 'find', 'glass', 'look', 'magnifying', 'page', 'paper', 'plagiarism', 'search', 'see'],
    },
    {
      name: 'play_arrow',

      categories: ['av'],
      tags: ['arrow', 'control', 'controls', 'media', 'music', 'play', 'video'],
    },
    {
      name: 'play_circle',

      categories: ['av'],
      tags: ['arrow', 'circle', 'control', 'controls', 'media', 'music', 'play', 'video'],
    },
    {
      name: 'play_circle_filled',

      categories: ['av'],
      tags: ['arrow', 'circle', 'control', 'controls', 'media', 'music', 'play', 'video'],
    },
    {
      name: 'play_circle_outline',

      categories: ['av'],
      tags: ['arrow', 'circle', 'control', 'controls', 'media', 'music', 'outline', 'play', 'video'],
    },
    {
      name: 'play_disabled',

      categories: ['av'],
      tags: ['control', 'controls', 'disabled', 'enabled', 'media', 'music', 'off', 'on', 'play', 'slash', 'video'],
    },
    {
      name: 'play_for_work',

      categories: ['action'],
      tags: ['arrow', 'circle', 'down', 'google', 'half', 'play', 'work'],
    },
    {
      name: 'play_lesson',

      categories: ['device'],
      tags: [
        'audio',
        'book',
        'bookmark',
        'digital',
        'ebook',
        'lesson',
        'multimedia',
        'play',
        'play lesson',
        'read',
        'reading',
        'ribbon',
      ],
    },
    {
      name: 'playlist_add',

      categories: ['av'],
      tags: ['+', 'add', 'collection', 'list', 'music', 'new', 'playlist', 'plus', 'symbol'],
    },
    {
      name: 'playlist_add_check',

      categories: ['av'],
      tags: [
        'add',
        'approve',
        'check',
        'collection',
        'complete',
        'done',
        'list',
        'mark',
        'music',
        'ok',
        'playlist',
        'select',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'playlist_add_check_circle',

      categories: ['av'],
      tags: [
        'add',
        'album',
        'artist',
        'audio',
        'cd',
        'check',
        'circle',
        'collection',
        'list',
        'mark',
        'music',
        'playlist',
        'record',
        'sound',
        'track',
      ],
    },
    {
      name: 'playlist_add_circle',

      categories: ['av'],
      tags: [
        'add',
        'album',
        'artist',
        'audio',
        'cd',
        'check',
        'circle',
        'collection',
        'list',
        'mark',
        'music',
        'playlist',
        'record',
        'sound',
        'track',
      ],
    },
    {
      name: 'playlist_play',

      categories: ['av'],
      tags: ['arrow', 'collection', 'list', 'music', 'play', 'playlist'],
    },
    {
      name: 'playlist_remove',

      categories: ['av'],
      tags: ['-', 'collection', 'list', 'minus', 'music', 'playlist', 'remove'],
    },
    {
      name: 'plumbing',

      categories: ['maps'],
      tags: ['build', 'construction', 'fix', 'handyman', 'plumbing', 'repair', 'tools', 'wrench'],
    },
    {
      name: 'plus_one',

      categories: ['social'],
      tags: ['1', 'add', 'digit', 'increase', 'number', 'one', 'plus', 'symbol'],
    },
    {
      name: 'podcasts',

      categories: ['search'],
      tags: ['broadcast', 'casting', 'network', 'podcasts', 'signal', 'transmitting', 'wireless'],
    },
    {
      name: 'point_of_sale',

      categories: ['hardware'],
      tags: [
        'checkout',
        'cost',
        'machine',
        'merchant',
        'money',
        'of',
        'pay',
        'payment',
        'point',
        'pos',
        'retail',
        'sale',
        'system',
        'transaction',
      ],
    },
    {
      name: 'policy',

      categories: ['content'],
      tags: [
        'certified',
        'find',
        'glass',
        'legal',
        'look',
        'magnify',
        'magnifying',
        'policy',
        'privacy',
        'private',
        'protect',
        'protection',
        'search',
        'security',
        'see',
        'shield',
        'verified',
      ],
    },
    {
      name: 'poll',

      categories: ['social'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'poll',
        'statistics',
        'survey',
        'tracking',
        'vote',
      ],
    },
    {
      name: 'polyline',

      categories: ['editor'],
      tags: ['compose', 'create', 'design', 'draw', 'line', 'polyline', 'vector'],
    },
    {
      name: 'polymer',

      categories: ['action'],
      tags: ['emblem', 'logo', 'mark', 'polymer'],
    },
    {
      name: 'pool',

      categories: ['places'],
      tags: [
        'athlete',
        'athletic',
        'beach',
        'body',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'ocean',
        'people',
        'person',
        'places',
        'pool',
        'sea',
        'sports',
        'swim',
        'swimming',
        'water',
      ],
    },
    {
      name: 'portable_wifi_off',

      categories: ['communication'],
      tags: [
        'connection',
        'data',
        'disabled',
        'enabled',
        'internet',
        'network',
        'off',
        'offline',
        'on',
        'portable',
        'service',
        'signal',
        'slash',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'portrait',

      categories: ['image'],
      tags: ['account', 'face', 'human', 'people', 'person', 'photo', 'picture', 'portrait', 'profile', 'user'],
    },
    {
      name: 'post_add',

      categories: ['editor'],
      tags: [
        '+',
        'add',
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'page',
        'paper',
        'plus',
        'post',
        'sheet',
        'slide',
        'text',
        'writing',
      ],
    },
    {
      name: 'power',

      categories: ['notification'],
      tags: ['charge', 'cord', 'electric', 'electrical', 'outlet', 'plug', 'power'],
    },
    {
      name: 'power_input',

      categories: ['hardware'],
      tags: ['input', 'lines', 'power', 'supply'],
    },
    {
      name: 'power_off',

      categories: ['notification'],
      tags: [
        'charge',
        'cord',
        'disabled',
        'electric',
        'electrical',
        'enabled',
        'off',
        'on',
        'outlet',
        'plug',
        'power',
        'slash',
      ],
    },
    {
      name: 'power_settings_new',

      categories: ['action'],
      tags: ['info', 'information', 'off', 'on', 'power', 'save', 'settings', 'shutdown'],
    },
    {
      name: 'precision_manufacturing',

      categories: ['social'],
      tags: [
        'arm',
        'automatic',
        'chain',
        'conveyor',
        'crane',
        'factory',
        'industry',
        'machinery',
        'manufacturing',
        'mechanical',
        'precision',
        'production',
        'repairing',
        'robot',
        'supply',
        'warehouse',
      ],
    },
    {
      name: 'pregnant_woman',

      categories: ['action'],
      tags: [
        'baby',
        'birth',
        'body',
        'female',
        'human',
        'lady',
        'maternity',
        'mom',
        'mother',
        'people',
        'person',
        'pregnant',
        'women',
      ],
    },
    {
      name: 'present_to_all',

      categories: ['communication'],
      tags: ['all', 'arrow', 'present', 'presentation', 'screen', 'share', 'site', 'slides', 'to', 'web', 'website'],
    },
    {
      name: 'preview',

      categories: ['action'],
      tags: [
        'design',
        'eye',
        'layout',
        'preview',
        'reveal',
        'screen',
        'see',
        'show',
        'site',
        'view',
        'web',
        'website',
        'window',
        'www',
      ],
    },
    {
      name: 'price_change',

      categories: ['device'],
      tags: [
        'arrows',
        'bill',
        'card',
        'cash',
        'change',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'down',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'shopping',
        'symbol',
        'up',
      ],
    },
    {
      name: 'price_check',

      categories: ['device'],
      tags: [
        'approve',
        'bill',
        'card',
        'cash',
        'check',
        'coin',
        'commerce',
        'complete',
        'cost',
        'credit',
        'currency',
        'dollars',
        'done',
        'finance',
        'mark',
        'money',
        'ok',
        'online',
        'pay',
        'payment',
        'price',
        'select',
        'shopping',
        'symbol',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'print',

      categories: ['action'],
      tags: ['draft', 'fax', 'ink', 'machine', 'office', 'paper', 'print', 'printer', 'send'],
    },
    {
      name: 'print_disabled',

      categories: ['communication'],
      tags: ['disabled', 'enabled', 'off', 'on', 'paper', 'print', 'printer', 'slash'],
    },
    {
      name: 'priority_high',

      categories: ['notification'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'danger',
        'error',
        'exclamation',
        'high',
        'important',
        'mark',
        'notification',
        'priority',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'privacy_tip',

      categories: ['action'],
      tags: [
        'alert',
        'announcement',
        'assistance',
        'certified',
        'details',
        'help',
        'i',
        'info',
        'information',
        'privacy',
        'private',
        'protect',
        'protection',
        'security',
        'service',
        'shield',
        'support',
        'tip',
        'verified',
      ],
    },
    {
      name: 'private_connectivity',

      categories: ['action'],
      tags: [
        'connectivity',
        'lock',
        'locked',
        'password',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
      ],
    },
    {
      name: 'production_quantity_limits',

      categories: ['action'],
      tags: [
        '!',
        'alert',
        'attention',
        'bill',
        'card',
        'cart',
        'cash',
        'caution',
        'coin',
        'commerce',
        'credit',
        'currency',
        'danger',
        'dollars',
        'error',
        'exclamation',
        'important',
        'limits',
        'mark',
        'money',
        'notification',
        'online',
        'pay',
        'payment',
        'production',
        'quantity',
        'shopping',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'propane',

      categories: ['home'],
      tags: ['gas', 'nest', 'propane'],
    },
    {
      name: 'propane_tank',

      categories: ['home'],
      tags: ['bbq', 'gas', 'grill', 'nest', 'propane', 'tank'],
    },
    {
      name: 'psychology',

      categories: ['social'],
      tags: [
        'behavior',
        'body',
        'brain',
        'cognitive',
        'function',
        'gear',
        'head',
        'human',
        'intellectual',
        'mental',
        'mind',
        'people',
        'person',
        'preferences',
        'psychiatric',
        'psychology',
        'science',
        'settings',
        'social',
        'therapy',
        'thinking',
        'thoughts',
      ],
    },
    {
      name: 'psychology_alt',

      categories: ['social'],
      tags: [
        '?',
        'assistance',
        'behavior',
        'body',
        'brain',
        'cognitive',
        'function',
        'gear',
        'head',
        'help',
        'human',
        'info',
        'information',
        'intellectual',
        'mental',
        'mind',
        'people',
        'person',
        'preferences',
        'psychiatric',
        'psychology',
        'punctuation',
        'question mark',
        'science',
        'settings',
        'social',
        'support',
        'symbol',
        'therapy',
        'thinking',
        'thoughts',
      ],
    },
    {
      name: 'public',

      categories: ['social'],
      tags: ['earth', 'global', 'globe', 'map', 'network', 'planet', 'public', 'social', 'space', 'web', 'world'],
    },
    {
      name: 'public_off',

      categories: ['social'],
      tags: [
        'disabled',
        'earth',
        'enabled',
        'global',
        'globe',
        'map',
        'network',
        'off',
        'on',
        'planet',
        'public',
        'slash',
        'social',
        'space',
        'web',
        'world',
      ],
    },
    {
      name: 'publish',

      categories: ['editor'],
      tags: ['arrow', 'cloud', 'file', 'import', 'publish', 'up', 'upload'],
    },
    {
      name: 'published_with_changes',

      categories: ['action'],
      tags: [
        'approve',
        'arrow',
        'arrows',
        'changes',
        'check',
        'complete',
        'done',
        'inprogress',
        'load',
        'loading',
        'mark',
        'ok',
        'published',
        'refresh',
        'renew',
        'replace',
        'rotate',
        'select',
        'tick',
        'validate',
        'verified',
        'with',
        'yes',
      ],
    },
    {
      name: 'punch_clock',

      categories: ['device'],
      tags: ['clock', 'date', 'punch', 'schedule', 'time', 'timer', 'timesheet'],
    },
    {
      name: 'push_pin',

      categories: ['content'],
      tags: ['location', 'marker', 'pin', 'place', 'push', 'remember', 'save'],
    },
    {
      name: 'qr_code',

      categories: ['communication'],
      tags: ['barcode', 'camera', 'code', 'media', 'product', 'qr', 'quick', 'response', 'smartphone', 'url', 'urls'],
    },
    {
      name: 'qr_code_2',

      categories: ['communication'],
      tags: ['barcode', 'camera', 'code', 'media', 'product', 'qr', 'quick', 'response', 'smartphone', 'url', 'urls'],
    },
    {
      name: 'qr_code_scanner',

      categories: ['communication'],
      tags: [
        'barcode',
        'camera',
        'code',
        'media',
        'product',
        'qr',
        'quick',
        'response',
        'scanner',
        'smartphone',
        'url',
        'urls',
      ],
    },
    {
      name: 'query_builder',

      categories: ['action'],
      tags: ['builder', 'clock', 'date', 'query', 'schedule', 'time'],
    },
    {
      name: 'query_stats',

      categories: ['editor'],
      tags: [
        'analytics',
        'chart',
        'data',
        'diagram',
        'find',
        'glass',
        'graph',
        'infographic',
        'line',
        'look',
        'magnify',
        'magnifying',
        'measure',
        'metrics',
        'query',
        'search',
        'see',
        'statistics',
        'stats',
        'tracking',
      ],
    },
    {
      name: 'question_answer',

      categories: ['action'],
      tags: [
        'answer',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'conversation',
        'feedback',
        'message',
        'question',
        'speech',
        'talk',
      ],
    },
    {
      name: 'question_mark',

      categories: ['action'],
      tags: ['?', 'assistance', 'help', 'info', 'information', 'punctuation', 'question mark', 'support', 'symbol'],
    },
    {
      name: 'queue',

      categories: ['av'],
      tags: [
        'add',
        'collection',
        'layers',
        'list',
        'multiple',
        'music',
        'playlist',
        'queue',
        'stack',
        'stream',
        'video',
      ],
    },
    {
      name: 'queue_music',

      categories: ['av'],
      tags: ['collection', 'list', 'music', 'playlist', 'queue'],
    },
    {
      name: 'queue_play_next',

      categories: ['av'],
      tags: [
        '+',
        'add',
        'arrow',
        'desktop',
        'device',
        'display',
        'hardware',
        'monitor',
        'new',
        'next',
        'play',
        'plus',
        'queue',
        'screen',
        'steam',
        'symbol',
        'tv',
      ],
    },
    {
      name: 'quickreply',

      categories: ['action'],
      tags: [
        'bolt',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'fast',
        'lightning',
        'message',
        'quick',
        'quickreply',
        'reply',
        'speech',
        'thunderbolt',
      ],
    },
    {
      name: 'quiz',

      categories: ['device'],
      tags: [
        '?',
        'assistance',
        'faq',
        'help',
        'info',
        'information',
        'punctuation',
        'question mark',
        'quiz',
        'support',
        'symbol',
        'test',
      ],
    },
    {
      name: 'r_mobiledata',

      categories: ['device'],
      tags: ['alphabet', 'character', 'data', 'font', 'letter', 'mobile', 'r', 'symbol', 'text', 'type'],
    },
    {
      name: 'radar',

      categories: ['device'],
      tags: ['detect', 'military', 'near', 'network', 'position', 'radar', 'scan'],
    },
    {
      name: 'radio',

      categories: ['av'],
      tags: [
        'antenna',
        'audio',
        'device',
        'frequency',
        'hardware',
        'listen',
        'media',
        'music',
        'player',
        'radio',
        'signal',
        'tune',
      ],
    },
    {
      name: 'radio_button_checked',

      categories: ['toggle'],
      tags: [
        'app',
        'application',
        'bullet',
        'button',
        'checked',
        'circle',
        'components',
        'design',
        'form',
        'interface',
        'off',
        'on',
        'point',
        'radio',
        'record',
        'screen',
        'select',
        'selected',
        'site',
        'toggle',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'radio_button_unchecked',

      categories: ['toggle'],
      tags: [
        'bullet',
        'button',
        'circle',
        'deselected',
        'form',
        'off',
        'on',
        'point',
        'radio',
        'record',
        'select',
        'toggle',
        'unchecked',
      ],
    },
    {
      name: 'railway_alert',

      categories: ['maps'],
      tags: [
        '!',
        'alert',
        'attention',
        'automobile',
        'bike',
        'car',
        'cars',
        'caution',
        'danger',
        'direction',
        'error',
        'exclamation',
        'important',
        'maps',
        'mark',
        'notification',
        'public',
        'railway',
        'scooter',
        'subway',
        'symbol',
        'train',
        'transportation',
        'vehicle',
        'vespa',
        'warning',
      ],
    },
    {
      name: 'ramen_dining',

      categories: ['maps'],
      tags: [
        'breakfast',
        'dining',
        'dinner',
        'drink',
        'fastfood',
        'food',
        'lunch',
        'meal',
        'noodles',
        'ramen',
        'restaurant',
      ],
    },
    {
      name: 'ramp_left',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'left',
        'maps',
        'navigation',
        'path',
        'ramp',
        'route',
        'sign',
        'traffic',
      ],
    },
    {
      name: 'ramp_right',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'navigation',
        'path',
        'ramp',
        'right',
        'route',
        'sign',
        'traffic',
      ],
    },
    {
      name: 'rate_review',

      categories: ['maps'],
      tags: ['comment', 'feedback', 'pen', 'pencil', 'rate', 'review', 'stars', 'write'],
    },
    {
      name: 'raw_off',

      categories: ['image'],
      tags: [
        'alphabet',
        'character',
        'disabled',
        'enabled',
        'font',
        'image',
        'letter',
        'off',
        'on',
        'original',
        'photo',
        'photography',
        'raw',
        'slash',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'raw_on',

      categories: ['image'],
      tags: [
        'alphabet',
        'character',
        'disabled',
        'enabled',
        'font',
        'image',
        'letter',
        'off',
        'on',
        'original',
        'photo',
        'photography',
        'raw',
        'slash',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'read_more',

      categories: ['communication'],
      tags: ['arrow', 'more', 'read', 'text'],
    },
    {
      name: 'real_estate_agent',

      categories: ['social'],
      tags: [
        'agent',
        'architecture',
        'broker',
        'estate',
        'hand',
        'home',
        'house',
        'loan',
        'mortgage',
        'property',
        'real',
        'residence',
        'residential',
        'sales',
        'social',
      ],
    },
    {
      name: 'receipt',

      categories: ['action'],
      tags: ['bill', 'credit', 'invoice', 'paper', 'payment', 'receipt', 'sale'],
    },
    {
      name: 'receipt_long',

      categories: ['image'],
      tags: [
        'bill',
        'check',
        'document',
        'list',
        'long',
        'paper',
        'paperwork',
        'receipt',
        'record',
        'store',
        'transaction',
      ],
    },
    {
      name: 'recent_actors',

      categories: ['av'],
      tags: [
        'account',
        'actors',
        'avatar',
        'card',
        'cards',
        'carousel',
        'face',
        'human',
        'layers',
        'list',
        'people',
        'person',
        'profile',
        'recent',
        'thumbnail',
        'user',
      ],
    },
    {
      name: 'recommend',

      categories: ['social'],
      tags: [
        'approved',
        'circle',
        'confirm',
        'favorite',
        'gesture',
        'hand',
        'like',
        'reaction',
        'recommend',
        'social',
        'support',
        'thumbs',
        'up',
        'well',
      ],
    },
    {
      name: 'record_voice_over',

      categories: ['action'],
      tags: [
        'account',
        'face',
        'human',
        'over',
        'people',
        'person',
        'profile',
        'record',
        'recording',
        'speak',
        'speaking',
        'speech',
        'transcript',
        'user',
        'voice',
      ],
    },
    {
      name: 'rectangle',

      categories: ['editor'],
      tags: ['four sides', 'parallelograms', 'polygons', 'quadrilaterals', 'recangle', 'shape'],
    },
    {
      name: 'recycling',

      categories: ['social'],
      tags: [
        'bio',
        'eco',
        'green',
        'loop',
        'recyclable',
        'recycle',
        'recycling',
        'rotate',
        'sustainability',
        'sustainable',
        'trash',
      ],
    },
    {
      name: 'redeem',

      categories: ['action'],
      tags: [
        'bill',
        'card',
        'cart',
        'cash',
        'certificate',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'gift',
        'giftcard',
        'money',
        'online',
        'pay',
        'payment',
        'present',
        'redeem',
        'shopping',
      ],
    },
    {
      name: 'redo',

      categories: ['content'],
      tags: ['arrow', 'backward', 'forward', 'next', 'redo', 'repeat', 'rotate', 'undo'],
    },
    {
      name: 'reduce_capacity',

      categories: ['social'],
      tags: ['arrow', 'body', 'capacity', 'covid', 'decrease', 'down', 'human', 'people', 'person', 'reduce', 'social'],
    },
    {
      name: 'refresh',

      categories: ['navigation'],
      tags: [
        'around',
        'arrow',
        'arrows',
        'direction',
        'inprogress',
        'load',
        'loading refresh',
        'navigation',
        'refresh',
        'renew',
        'right',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'remember_me',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'avatar',
        'device',
        'hardware',
        'human',
        'iOS',
        'identity',
        'me',
        'mobile',
        'people',
        'person',
        'phone',
        'profile',
        'remember',
        'tablet',
        'user',
      ],
    },
    {
      name: 'remove',

      categories: ['content'],
      tags: ['can', 'delete', 'minus', 'negative', 'remove', 'substract', 'trash'],
    },
    {
      name: 'remove_circle',

      categories: ['content'],
      tags: ['block', 'can', 'circle', 'delete', 'minus', 'negative', 'remove', 'substract', 'trash'],
    },
    {
      name: 'remove_circle_outline',

      categories: ['content'],
      tags: ['block', 'can', 'circle', 'delete', 'minus', 'negative', 'outline', 'remove', 'substract', 'trash'],
    },
    {
      name: 'remove_done',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'complete',
        'disabled',
        'done',
        'enabled',
        'finished',
        'mark',
        'multiple',
        'off',
        'ok',
        'on',
        'remove',
        'select',
        'slash',
        'tick',
        'yes',
      ],
    },
    {
      name: 'remove_from_queue',

      categories: ['av'],
      tags: ['desktop', 'device', 'display', 'from', 'hardware', 'monitor', 'queue', 'remove', 'screen', 'steam'],
    },
    {
      name: 'remove_moderator',

      categories: ['social'],
      tags: [
        'certified',
        'disabled',
        'enabled',
        'moderator',
        'off',
        'on',
        'privacy',
        'private',
        'protect',
        'protection',
        'remove',
        'security',
        'shield',
        'slash',
        'verified',
      ],
    },
    {
      name: 'remove_red_eye',

      categories: ['image'],
      tags: ['eye', 'iris', 'look', 'looking', 'preview', 'red', 'remove', 'see', 'sight', 'vision'],
    },
    {
      name: 'remove_road',

      categories: ['maps'],
      tags: [
        '-',
        'cancel',
        'close',
        'destination',
        'direction',
        'exit',
        'highway',
        'maps',
        'minus',
        'new',
        'no',
        'remove',
        'road',
        'stop',
        'street',
        'symbol',
        'traffic',
        'x',
      ],
    },
    {
      name: 'remove_shopping_cart',

      categories: ['action'],
      tags: [
        'card',
        'cart',
        'cash',
        'checkout',
        'coin',
        'commerce',
        'credit',
        'currency',
        'disabled',
        'dollars',
        'enabled',
        'off',
        'on',
        'online',
        'pay',
        'payment',
        'remove',
        'shopping',
        'slash',
        'tick',
      ],
    },
    {
      name: 'reorder',

      categories: ['action'],
      tags: ['format', 'lines', 'list', 'order', 'reorder', 'stacked'],
    },
    {
      name: 'repartition',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'data', 'partition', 'refresh', 'renew', 'repartition', 'restore', 'table'],
    },
    {
      name: 'repeat',

      categories: ['av'],
      tags: ['arrow', 'arrows', 'control', 'controls', 'media', 'music', 'repeat', 'video'],
    },
    {
      name: 'repeat_on',

      categories: ['av'],
      tags: ['arrow', 'arrows', 'control', 'controls', 'media', 'music', 'on', 'repeat', 'video'],
    },
    {
      name: 'repeat_one',

      categories: ['av'],
      tags: [
        '1',
        'arrow',
        'arrows',
        'control',
        'controls',
        'digit',
        'media',
        'music',
        'number',
        'one',
        'repeat',
        'symbol',
        'video',
      ],
    },
    {
      name: 'repeat_one_on',

      categories: ['av'],
      tags: [
        'arrow',
        'arrows',
        'control',
        'controls',
        'digit',
        'media',
        'music',
        'number',
        'on',
        'one',
        'repeat',
        'symbol',
        'video',
      ],
    },
    {
      name: 'replay',

      categories: ['av'],
      tags: ['arrow', 'arrows', 'control', 'controls', 'music', 'refresh', 'renew', 'repeat', 'replay', 'video'],
    },
    {
      name: 'replay_10',

      categories: ['av'],
      tags: [
        '10',
        'arrow',
        'arrows',
        'control',
        'controls',
        'digit',
        'music',
        'number',
        'refresh',
        'renew',
        'repeat',
        'replay',
        'symbol',
        'ten',
        'video',
      ],
    },
    {
      name: 'replay_30',

      categories: ['av'],
      tags: [
        '30',
        'arrow',
        'arrows',
        'control',
        'controls',
        'digit',
        'music',
        'number',
        'refresh',
        'renew',
        'repeat',
        'replay',
        'symbol',
        'thirty',
        'video',
      ],
    },
    {
      name: 'replay_5',

      categories: ['av'],
      tags: [
        '5',
        'arrow',
        'arrows',
        'control',
        'controls',
        'digit',
        'five',
        'music',
        'number',
        'refresh',
        'renew',
        'repeat',
        'replay',
        'symbol',
        'video',
      ],
    },
    {
      name: 'replay_circle_filled',

      categories: ['av'],
      tags: [
        'arrow',
        'arrows',
        'circle',
        'control',
        'controls',
        'filled',
        'music',
        'refresh',
        'renew',
        'repeat',
        'replay',
        'video',
      ],
    },
    {
      name: 'reply',

      categories: ['content'],
      tags: ['arrow', 'backward', 'left', 'mail', 'message', 'reply', 'send', 'share'],
    },
    {
      name: 'reply_all',

      categories: ['content'],
      tags: ['all', 'arrow', 'backward', 'group', 'left', 'mail', 'message', 'multiple', 'reply', 'send', 'share'],
    },
    {
      name: 'report',

      categories: ['content'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'notification',
        'octagon',
        'report',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'report_gmailerrorred',

      categories: ['content'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'danger',
        'error',
        'exclamation',
        'gmail',
        'gmailerrorred',
        'important',
        'mark',
        'notification',
        'octagon',
        'report',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'report_off',

      categories: ['content'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'danger',
        'disabled',
        'enabled',
        'error',
        'exclamation',
        'important',
        'mark',
        'notification',
        'octagon',
        'off',
        'offline',
        'on',
        'report',
        'slash',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'report_problem',

      categories: ['action'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'danger',
        'error',
        'exclamation',
        'feedback',
        'important',
        'mark',
        'notification',
        'problem',
        'report',
        'symbol',
        'triangle',
        'warning',
      ],
    },
    {
      name: 'request_page',

      categories: ['action'],
      tags: [
        'data',
        'doc',
        'document',
        'drive',
        'file',
        'folder',
        'folders',
        'page',
        'paper',
        'request',
        'sheet',
        'slide',
        'writing',
      ],
    },
    {
      name: 'request_quote',

      categories: ['file'],
      tags: [
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'quote',
        'request',
        'shopping',
        'symbol',
      ],
    },
    {
      name: 'reset_tv',

      categories: ['device'],
      tags: ['arrow', 'device', 'hardware', 'monitor', 'reset', 'television', 'tv'],
    },
    {
      name: 'restart_alt',

      categories: ['device'],
      tags: [
        'alt',
        'around',
        'arrow',
        'inprogress',
        'load',
        'loading refresh',
        'reboot',
        'renew',
        'repeat',
        'reset',
        'restart',
      ],
    },
    {
      name: 'restaurant',

      categories: ['maps'],
      tags: [
        'breakfast',
        'dining',
        'dinner',
        'eat',
        'food',
        'fork',
        'knife',
        'local',
        'lunch',
        'meal',
        'places',
        'restaurant',
        'spoon',
        'utensils',
      ],
    },
    {
      name: 'restaurant_menu',

      categories: ['maps'],
      tags: ['book', 'dining', 'eat', 'food', 'fork', 'knife', 'local', 'meal', 'menu', 'restaurant', 'spoon'],
    },
    {
      name: 'restore',

      categories: ['action'],
      tags: [
        'arrow',
        'back',
        'backwards',
        'clock',
        'date',
        'history',
        'refresh',
        'renew',
        'restore',
        'reverse',
        'rotate',
        'schedule',
        'time',
        'turn',
      ],
    },
    {
      name: 'restore_from_trash',

      categories: ['action'],
      tags: [
        'arrow',
        'back',
        'backwards',
        'clock',
        'date',
        'history',
        'refresh',
        'renew',
        'restore',
        'reverse',
        'rotate',
        'schedule',
        'time',
        'turn',
      ],
    },
    {
      name: 'restore_page',

      categories: ['action'],
      tags: ['arrow', 'data', 'doc', 'file', 'page', 'paper', 'refresh', 'restore', 'rotate', 'sheet', 'storage'],
    },
    {
      name: 'reviews',

      categories: ['device'],
      tags: [
        'bubble',
        'chat',
        'comment',
        'communicate',
        'feedback',
        'message',
        'rate',
        'rating',
        'recommendation',
        'reviews',
        'speech',
      ],
    },
    {
      name: 'rice_bowl',

      categories: ['places'],
      tags: ['bowl', 'dinner', 'food', 'lunch', 'meal', 'restaurant', 'rice'],
    },
    {
      name: 'ring_volume',

      categories: ['communication'],
      tags: [
        'call',
        'calling',
        'cell',
        'contact',
        'device',
        'hardware',
        'incoming',
        'mobile',
        'phone',
        'ring',
        'ringer',
        'sound',
        'telephone',
        'volume',
      ],
    },
    {
      name: 'rocket',

      categories: ['action'],
      tags: ['rocket', 'space', 'spaceship'],
    },
    {
      name: 'rocket_launch',

      categories: ['action'],
      tags: ['launch', 'rocket', 'space', 'spaceship', 'takeoff'],
    },
    {
      name: 'roller_shades',

      categories: ['home'],
      tags: ['blinds', 'cover', 'curtains', 'nest', 'open', 'roller', 'shade', 'shutter', 'sunshade'],
    },
    {
      name: 'roller_shades_closed',

      categories: ['home'],
      tags: ['blinds', 'closed', 'cover', 'curtains', 'nest', 'roller', 'shade', 'shutter', 'sunshade'],
    },
    {
      name: 'roller_skating',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'entertainment',
        'exercise',
        'hobby',
        'roller',
        'shoe',
        'skate',
        'skates',
        'skating',
        'social',
        'sports',
        'travel',
      ],
    },
    {
      name: 'roofing',

      categories: ['places'],
      tags: [
        'architecture',
        'building',
        'chimney',
        'construction',
        'estate',
        'home',
        'house',
        'real',
        'residence',
        'residential',
        'roof',
        'roofing',
        'service',
        'shelter',
      ],
    },
    {
      name: 'room',

      categories: ['action'],
      tags: ['destination', 'direction', 'location', 'maps', 'pin', 'place', 'room', 'stop'],
    },
    {
      name: 'room_preferences',

      categories: ['places'],
      tags: [
        'building',
        'door',
        'doorway',
        'entrance',
        'gear',
        'home',
        'house',
        'interior',
        'office',
        'open',
        'preferences',
        'room',
        'settings',
      ],
    },
    {
      name: 'room_service',

      categories: ['places'],
      tags: ['alert', 'bell', 'delivery', 'hotel', 'notify', 'room', 'service'],
    },
    {
      name: 'rotate_90_degrees_ccw',

      categories: ['image'],
      tags: [
        '90',
        'arrow',
        'arrows',
        'ccw',
        'degrees',
        'direction',
        'edit',
        'editing',
        'image',
        'photo',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'rotate_90_degrees_cw',

      categories: ['image'],
      tags: [
        '90',
        'arrow',
        'arrows',
        'ccw',
        'degrees',
        'direction',
        'edit',
        'editing',
        'image',
        'photo',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'rotate_left',

      categories: ['image'],
      tags: [
        'around',
        'arrow',
        'direction',
        'inprogress',
        'left',
        'load',
        'loading refresh',
        'renew',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'rotate_right',

      categories: ['image'],
      tags: [
        'around',
        'arrow',
        'direction',
        'inprogress',
        'load',
        'loading refresh',
        'renew',
        'right',
        'rotate',
        'turn',
      ],
    },
    {
      name: 'roundabout_left',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'left',
        'maps',
        'navigation',
        'path',
        'roundabout',
        'route',
        'sign',
        'traffic',
      ],
    },
    {
      name: 'roundabout_right',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'navigation',
        'path',
        'right',
        'roundabout',
        'route',
        'sign',
        'traffic',
      ],
    },
    {
      name: 'rounded_corner',

      categories: ['action'],
      tags: ['adjust', 'corner', 'edit', 'rounded', 'shape', 'square', 'transform'],
    },
    {
      name: 'route',

      categories: ['maps'],
      tags: ['directions', 'maps', 'path', 'route', 'sign', 'traffic'],
    },
    {
      name: 'router',

      categories: ['hardware'],
      tags: ['box', 'cable', 'connection', 'hardware', 'internet', 'network', 'router', 'signal', 'wifi'],
    },
    {
      name: 'rowing',

      categories: ['action'],
      tags: ['activity', 'boat', 'body', 'canoe', 'human', 'people', 'person', 'row', 'rowing', 'sport', 'water'],
    },
    {
      name: 'rss_feed',

      categories: ['communication'],
      tags: [
        'application',
        'blog',
        'connection',
        'data',
        'feed',
        'internet',
        'network',
        'rss',
        'service',
        'signal',
        'website',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'rsvp',

      categories: ['device'],
      tags: [
        'alphabet',
        'character',
        'font',
        'invitation',
        'invite',
        'letter',
        'plaît',
        'respond',
        'rsvp',
        'répondez',
        'sil',
        'symbol',
        'text',
        'type',
        'vous',
      ],
    },
    {
      name: 'rtt',

      categories: ['communication'],
      tags: ['call', 'real', 'rrt', 'text', 'time'],
    },
    {
      name: 'rule',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'complete',
        'done',
        'incomplete',
        'line',
        'mark',
        'missing',
        'no',
        'ok',
        'rule',
        'select',
        'tick',
        'validate',
        'verified',
        'wrong',
        'x',
        'yes',
      ],
    },
    {
      name: 'rule_folder',

      categories: ['file'],
      tags: [
        'approve',
        'cancel',
        'check',
        'close',
        'complete',
        'data',
        'doc',
        'document',
        'done',
        'drive',
        'exit',
        'file',
        'folder',
        'mark',
        'no',
        'ok',
        'remove',
        'rule',
        'select',
        'sheet',
        'slide',
        'storage',
        'tick',
        'validate',
        'verified',
        'x',
        'yes',
      ],
    },
    {
      name: 'run_circle',

      categories: ['maps'],
      tags: ['body', 'circle', 'exercise', 'human', 'people', 'person', 'run', 'running'],
    },
    {
      name: 'running_with_errors',

      categories: ['notification'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'danger',
        'duration',
        'error',
        'errors',
        'exclamation',
        'important',
        'mark',
        'notification',
        'process',
        'processing',
        'running',
        'symbol',
        'time',
        'warning',
        'with',
      ],
    },
    {
      name: 'rv_hookup',

      categories: ['places'],
      tags: [
        'arrow',
        'attach',
        'automobile',
        'automotive',
        'back',
        'car',
        'cars',
        'connect',
        'direction',
        'hookup',
        'left',
        'maps',
        'public',
        'right',
        'rv',
        'trailer',
        'transportation',
        'travel',
        'truck',
        'van',
        'vehicle',
      ],
    },
    {
      name: 'safety_check',

      categories: ['maps'],
      tags: [
        'certified',
        'check',
        'clock',
        'privacy',
        'private',
        'protect',
        'protection',
        'safety',
        'schedule',
        'security',
        'shield',
        'time',
        'verified',
      ],
    },
    {
      name: 'safety_divider',

      categories: ['social'],
      tags: ['apart', 'distance', 'divider', 'safety', 'separate', 'social', 'space'],
    },
    {
      name: 'sailing',

      categories: ['maps'],
      tags: [
        'boat',
        'entertainment',
        'fishing',
        'hobby',
        'ocean',
        'sailboat',
        'sailing',
        'sea',
        'social sports',
        'travel',
        'water',
      ],
    },
    {
      name: 'sanitizer',

      categories: ['social'],
      tags: ['bacteria', 'bottle', 'clean', 'covid', 'disinfect', 'germs', 'pump', 'sanitizer'],
    },
    {
      name: 'satellite',

      categories: ['maps'],
      tags: [
        'bluetooth',
        'connect',
        'connection',
        'connectivity',
        'data',
        'device',
        'image',
        'internet',
        'landscape',
        'location',
        'maps',
        'mountain',
        'mountains',
        'network',
        'photo',
        'photography',
        'picture',
        'satellite',
        'scan',
        'service',
        'signal',
        'symbol',
        'wireless-- wifi',
      ],
    },
    {
      name: 'satellite_alt',

      categories: ['action'],
      tags: ['alternative', 'artificial', 'communication', 'satellite', 'space', 'space station', 'television'],
    },
    {
      name: 'save',

      categories: ['content'],
      tags: ['data', 'disk', 'document', 'drive', 'file', 'floppy', 'multimedia', 'save', 'storage'],
    },
    {
      name: 'save_alt',

      categories: ['content'],
      tags: ['alt', 'arrow', 'disk', 'document', 'down', 'file', 'floppy', 'multimedia', 'save'],
    },
    {
      name: 'save_as',

      categories: ['content'],
      tags: [
        'compose',
        'create',
        'data',
        'disk',
        'document',
        'draft',
        'drive',
        'edit',
        'editing',
        'file',
        'floppy',
        'input',
        'multimedia',
        'pen',
        'pencil',
        'save',
        'storage',
        'write',
        'writing',
      ],
    },
    {
      name: 'saved_search',

      categories: ['action'],
      tags: ['find', 'glass', 'important', 'look', 'magnify', 'magnifying', 'marked', 'saved', 'search', 'see', 'star'],
    },
    {
      name: 'savings',

      categories: ['action'],
      tags: [
        'bank',
        'bill',
        'card',
        'cash',
        'coin',
        'commerce',
        'cost',
        'credit',
        'currency',
        'dollars',
        'finance',
        'money',
        'online',
        'pay',
        'payment',
        'pig',
        'piggy',
        'savings',
        'symbol',
      ],
    },
    {
      name: 'scale',

      categories: ['social'],
      tags: ['measure', 'monitor', 'scale', 'weight'],
    },
    {
      name: 'scanner',

      categories: ['hardware'],
      tags: ['copy', 'device', 'hardware', 'machine', 'scan', 'scanner'],
    },
    {
      name: 'scatter_plot',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'circles',
        'data',
        'diagram',
        'dot',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'plot',
        'scatter',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'schedule',

      categories: ['action'],
      tags: ['clock', 'date', 'schedule', 'time'],
    },
    {
      name: 'schedule_send',

      categories: ['action'],
      tags: ['calendar', 'clock', 'date', 'email', 'letter', 'mail', 'remember', 'schedule', 'send', 'share', 'time'],
    },
    {
      name: 'schema',

      categories: ['editor'],
      tags: [
        'analytics',
        'chart',
        'data',
        'diagram',
        'flow',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'schema',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'school',

      categories: ['social'],
      tags: [
        'academy',
        'achievement',
        'cap',
        'class',
        'college',
        'education',
        'graduation',
        'hat',
        'knowledge',
        'learning',
        'school',
        'university',
      ],
    },
    {
      name: 'science',

      categories: ['social'],
      tags: [
        'beaker',
        'chemical',
        'chemistry',
        'experiment',
        'flask',
        'glass',
        'laboratory',
        'research',
        'science',
        'tube',
      ],
    },
    {
      name: 'score',

      categories: ['editor'],
      tags: [
        '2k',
        'alphabet',
        'analytics',
        'bar',
        'bars',
        'character',
        'chart',
        'data',
        'diagram',
        'digit',
        'font',
        'graph',
        'infographic',
        'letter',
        'measure',
        'metrics',
        'number',
        'score',
        'statistics',
        'symbol',
        'text',
        'tracking',
        'type',
      ],
    },
    {
      name: 'scoreboard',

      categories: ['social'],
      tags: ['board', 'points', 'score', 'scoreboard', 'sports'],
    },
    {
      name: 'screen_lock_landscape',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'device',
        'hardware',
        'iOS',
        'landscape',
        'lock',
        'mobile',
        'phone',
        'rotate',
        'screen',
        'tablet',
      ],
    },
    {
      name: 'screen_lock_portrait',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'device',
        'hardware',
        'iOS',
        'lock',
        'mobile',
        'phone',
        'portrait',
        'rotate',
        'screen',
        'tablet',
      ],
    },
    {
      name: 'screen_lock_rotation',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'device',
        'hardware',
        'iOS',
        'lock',
        'mobile',
        'phone',
        'rotate',
        'rotation',
        'screen',
        'tablet',
        'turn',
      ],
    },
    {
      name: 'screen_rotation',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'rotate',
        'rotation',
        'screen',
        'tablet',
        'turn',
      ],
    },
    {
      name: 'screen_rotation_alt',

      categories: ['maps'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'rotate',
        'rotation',
        'screen',
        'tablet',
        'turn',
      ],
    },
    {
      name: 'screen_search_desktop',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'desktop',
        'device',
        'hardware',
        'iOS',
        'lock',
        'monitor',
        'rotate',
        'screen',
        'web',
      ],
    },
    {
      name: 'screen_share',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'cast',
        'chrome',
        'device',
        'display',
        'hardware',
        'iOS',
        'laptop',
        'mac',
        'mirror',
        'monitor',
        'screen',
        'share',
        'steam',
        'streaming',
        'web',
        'window',
      ],
    },
    {
      name: 'screenshot',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'cell',
        'crop',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'screen',
        'screenshot',
        'tablet',
      ],
    },
    {
      name: 'screenshot_monitor',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'desktop',
        'device',
        'display',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'screen',
        'screengrab',
        'screenshot',
        'web',
        'window',
      ],
    },
    {
      name: 'scuba_diving',

      categories: ['social'],
      tags: ['diving', 'entertainment', 'exercise', 'hobby', 'scuba', 'social', 'swim', 'swimming'],
    },
    {
      name: 'sd',

      categories: ['av'],
      tags: [
        'alphabet',
        'camera',
        'card',
        'character',
        'data',
        'device',
        'digital',
        'drive',
        'flash',
        'font',
        'image',
        'letter',
        'memory',
        'photo',
        'sd',
        'secure',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'sd_card',

      categories: ['notification'],
      tags: ['camera', 'card', 'digital', 'memory', 'photos', 'sd', 'secure', 'storage'],
    },
    {
      name: 'sd_card_alert',

      categories: ['notification'],
      tags: [
        '!',
        'alert',
        'attention',
        'camera',
        'card',
        'caution',
        'danger',
        'digital',
        'error',
        'exclamation',
        'important',
        'mark',
        'memory',
        'notification',
        'photos',
        'sd',
        'secure',
        'storage',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'sd_storage',

      categories: ['device'],
      tags: ['camera', 'card', 'data', 'digital', 'memory', 'sd', 'secure', 'storage'],
    },
    {
      name: 'search',

      categories: ['action'],
      tags: ['filter', 'find', 'glass', 'look', 'magnify', 'magnifying', 'search', 'see'],
    },
    {
      name: 'search_off',

      categories: ['action'],
      tags: [
        'cancel',
        'close',
        'disabled',
        'enabled',
        'find',
        'glass',
        'look',
        'magnify',
        'magnifying',
        'off',
        'on',
        'search',
        'see',
        'slash',
        'stop',
        'x',
      ],
    },
    {
      name: 'security',

      categories: ['hardware'],
      tags: ['certified', 'privacy', 'private', 'protect', 'protection', 'security', 'shield', 'verified'],
    },
    {
      name: 'security_update',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'device',
        'down',
        'download',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'security',
        'tablet',
        'update',
      ],
    },
    {
      name: 'security_update_good',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'checkmark',
        'device',
        'good',
        'hardware',
        'iOS',
        'mobile',
        'ok',
        'phone',
        'security',
        'tablet',
        'tick',
        'update',
      ],
    },
    {
      name: 'security_update_warning',

      categories: ['device'],
      tags: [
        '!',
        'Android',
        'OS',
        'alert',
        'attention',
        'caution',
        'danger',
        'device',
        'download',
        'error',
        'exclamation',
        'hardware',
        'iOS',
        'important',
        'mark',
        'mobile',
        'notification',
        'phone',
        'security',
        'symbol',
        'tablet',
        'update',
        'warning',
      ],
    },
    {
      name: 'segment',

      categories: ['action'],
      tags: [
        'alignment',
        'fonts',
        'format',
        'lines',
        'list',
        'paragraph',
        'part',
        'piece',
        'rule',
        'rules',
        'segment',
        'style',
        'text',
      ],
    },
    {
      name: 'select_all',

      categories: ['content'],
      tags: ['all', 'select', 'selection', 'square', 'tool'],
    },
    {
      name: 'self_improvement',

      categories: ['social'],
      tags: [
        'body',
        'calm',
        'care',
        'chi',
        'human',
        'improvement',
        'meditate',
        'meditation',
        'people',
        'person',
        'relax',
        'self',
        'sitting',
        'wellbeing',
        'yoga',
        'zen',
      ],
    },
    {
      name: 'sell',

      categories: ['device'],
      tags: [
        'bill',
        'card',
        'cart',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'money',
        'online',
        'pay',
        'payment',
        'price',
        'sell',
        'shopping',
        'tag',
      ],
    },
    {
      name: 'send',

      categories: ['content'],
      tags: ['email', 'mail', 'message', 'paper', 'plane', 'reply', 'right', 'send', 'share'],
    },
    {
      name: 'send_and_archive',

      categories: ['action'],
      tags: ['archive', 'arrow', 'down', 'download', 'email', 'letter', 'mail', 'save', 'send', 'share'],
    },
    {
      name: 'send_time_extension',

      categories: ['communication'],
      tags: ['deliver', 'dispatch', 'envelop', 'extension', 'mail', 'message', 'schedule', 'send', 'time'],
    },
    {
      name: 'send_to_mobile',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'device',
        'export',
        'forward',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'right',
        'send',
        'share',
        'tablet',
        'to',
      ],
    },
    {
      name: 'sensor_door',

      categories: ['home'],
      tags: [],
    },
    {
      name: 'sensor_occupied',

      categories: ['home'],
      tags: ['body', 'connection', 'human', 'network', 'people', 'person', 'scan', 'sensors', 'signal', 'wireless'],
    },
    {
      name: 'sensor_window',

      categories: ['home'],
      tags: [],
    },
    {
      name: 'sensors',

      categories: ['action'],
      tags: ['connection', 'network', 'scan', 'sensors', 'signal', 'wireless'],
    },
    {
      name: 'sensors_off',

      categories: ['action'],
      tags: [
        'connection',
        'disabled',
        'enabled',
        'network',
        'off',
        'on',
        'scan',
        'sensors',
        'signal',
        'slash',
        'wireless',
      ],
    },
    {
      name: 'sentiment_dissatisfied',

      categories: ['social'],
      tags: [
        'angry',
        'disappointed',
        'dislike',
        'dissatisfied',
        'emotions',
        'expressions',
        'face',
        'feelings',
        'frown',
        'mood',
        'person',
        'sad',
        'sentiment',
        'survey',
        'unhappy',
        'unsatisfied',
        'upset',
      ],
    },
    {
      name: 'sentiment_neutral',

      categories: ['social'],
      tags: [
        'emotionless',
        'emotions',
        'expressions',
        'face',
        'feelings',
        'fine',
        'indifference',
        'mood',
        'neutral',
        'okay',
        'person',
        'sentiment',
        'survey',
      ],
    },
    {
      name: 'sentiment_satisfied',

      categories: ['social'],
      tags: [
        'emotions',
        'expressions',
        'face',
        'feelings',
        'glad',
        'happiness',
        'happy',
        'like',
        'mood',
        'person',
        'pleased',
        'satisfied',
        'sentiment',
        'smile',
        'smiling',
        'survey',
      ],
    },
    {
      name: 'sentiment_satisfied_alt',

      categories: ['communication'],
      tags: [
        'account',
        'alt',
        'emoji',
        'face',
        'happy',
        'human',
        'people',
        'person',
        'profile',
        'satisfied',
        'sentiment',
        'smile',
        'user',
      ],
    },
    {
      name: 'sentiment_very_dissatisfied',

      categories: ['social'],
      tags: [
        'angry',
        'disappointed',
        'dislike',
        'dissatisfied',
        'emotions',
        'expressions',
        'face',
        'feelings',
        'mood',
        'person',
        'sad',
        'sentiment',
        'sorrow',
        'survey',
        'unhappy',
        'unsatisfied',
        'upset',
        'very',
      ],
    },
    {
      name: 'sentiment_very_satisfied',

      categories: ['social'],
      tags: [
        'emotions',
        'expressions',
        'face',
        'feelings',
        'glad',
        'happiness',
        'happy',
        'like',
        'mood',
        'person',
        'pleased',
        'satisfied',
        'sentiment',
        'smile',
        'smiling',
        'survey',
        'very',
      ],
    },
    {
      name: 'set_meal',

      categories: ['maps'],
      tags: ['chopsticks', 'dinner', 'fish', 'food', 'lunch', 'meal', 'restaurant', 'set', 'teishoku'],
    },
    {
      name: 'settings',

      categories: ['action'],
      tags: [
        'application',
        'change',
        'details',
        'gear',
        'info',
        'information',
        'options',
        'personal',
        'service',
        'settings',
      ],
    },
    {
      name: 'settings_accessibility',

      categories: ['action'],
      tags: [
        'accessibility',
        'body',
        'details',
        'human',
        'information',
        'people',
        'person',
        'personal',
        'preferences',
        'profile',
        'settings',
        'user',
      ],
    },
    {
      name: 'settings_applications',

      categories: ['action'],
      tags: [
        'application',
        'change',
        'details',
        'gear',
        'info',
        'information',
        'options',
        'personal',
        'service',
        'settings',
      ],
    },
    {
      name: 'settings_backup_restore',

      categories: ['action'],
      tags: ['arrow', 'back', 'backup', 'backwards', 'refresh', 'restore', 'reverse', 'rotate', 'settings'],
    },
    {
      name: 'settings_bluetooth',

      categories: ['action'],
      tags: ['bluetooth', 'connect', 'connection', 'connectivity', 'device', 'settings', 'signal', 'symbol'],
    },
    {
      name: 'settings_brightness',

      categories: ['action'],
      tags: ['brightness', 'dark', 'filter', 'light', 'mode', 'setting', 'settings'],
    },
    {
      name: 'settings_cell',

      categories: ['action'],
      tags: ['Android', 'OS', 'cell', 'device', 'hardware', 'iOS', 'mobile', 'phone', 'settings', 'tablet'],
    },
    {
      name: 'settings_ethernet',

      categories: ['action'],
      tags: [
        'arrows',
        'computer',
        'connect',
        'connection',
        'connectivity',
        'dots',
        'ethernet',
        'internet',
        'network',
        'settings',
        'wifi',
      ],
    },
    {
      name: 'settings_input_antenna',

      categories: ['action'],
      tags: [
        'airplay',
        'antenna',
        'arrows',
        'cast',
        'computer',
        'connect',
        'connection',
        'connectivity',
        'dots',
        'input',
        'internet',
        'network',
        'screencast',
        'settings',
        'stream',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'settings_input_component',

      categories: ['action'],
      tags: [
        'audio',
        'av',
        'cable',
        'cables',
        'component',
        'connect',
        'connection',
        'connectivity',
        'input',
        'internet',
        'plug',
        'points',
        'settings',
        'video',
        'wifi',
      ],
    },
    {
      name: 'settings_input_composite',

      categories: ['action'],
      tags: ['component', 'composite', 'connection', 'connectivity', 'input', 'plug', 'points', 'settings'],
    },
    {
      name: 'settings_input_hdmi',

      categories: ['action'],
      tags: [
        'cable',
        'connection',
        'connectivity',
        'definition',
        'hdmi',
        'high',
        'input',
        'plug',
        'plugin',
        'points',
        'settings',
        'video',
        'wire',
      ],
    },
    {
      name: 'settings_input_svideo',

      categories: ['action'],
      tags: [
        'cable',
        'connection',
        'connectivity',
        'definition',
        'input',
        'plug',
        'plugin',
        'points',
        'settings',
        'standard',
        'svideo',
        'video',
      ],
    },
    {
      name: 'settings_overscan',

      categories: ['action'],
      tags: ['arrows', 'expand', 'image', 'photo', 'picture', 'scan', 'settings'],
    },
    {
      name: 'settings_phone',

      categories: ['action'],
      tags: ['call', 'cell', 'contact', 'device', 'hardware', 'mobile', 'phone', 'settings', 'telephone'],
    },
    {
      name: 'settings_power',

      categories: ['action'],
      tags: ['info', 'information', 'off', 'on', 'power', 'save', 'settings', 'shutdown'],
    },
    {
      name: 'settings_remote',

      categories: ['action'],
      tags: ['bluetooth', 'connection', 'connectivity', 'device', 'remote', 'settings', 'signal', 'wifi', 'wireless'],
    },
    {
      name: 'settings_suggest',

      categories: ['device'],
      tags: [
        'change',
        'details',
        'gear',
        'options',
        'recommendation',
        'service',
        'settings',
        'suggest',
        'suggestion',
        'system',
      ],
    },
    {
      name: 'settings_system_daydream',

      categories: ['device'],
      tags: ['backup', 'cloud', 'daydream', 'drive', 'settings', 'storage', 'system'],
    },
    {
      name: 'settings_voice',

      categories: ['action'],
      tags: ['mic', 'microphone', 'record', 'recorder', 'settings', 'speaker', 'voice'],
    },
    {
      name: 'severe_cold',

      categories: ['social'],
      tags: ['cold', 'crisis', 'diaster', 'severe', 'snow', 'snowflake', 'weather'],
    },
    {
      name: 'shape_line',

      categories: ['editor'],
      tags: ['circle', 'draw', 'edit', 'editing', 'line', 'shape', 'square'],
    },
    {
      name: 'share',

      categories: ['social'],
      tags: [
        'android',
        'connect',
        'contect',
        'disable_ios',
        'link',
        'media',
        'multimedia',
        'multiple',
        'network',
        'options',
        'share',
        'shared',
        'sharing',
        'social',
      ],
    },
    {
      name: 'share_location',

      categories: ['device'],
      tags: ['destination', 'direction', 'gps', 'location', 'maps', 'pin', 'place', 'share', 'stop', 'tracking'],
    },
    {
      name: 'shield',

      categories: ['content'],
      tags: ['certified', 'privacy', 'private', 'protect', 'protection', 'security', 'shield', 'verified'],
    },
    {
      name: 'shield_moon',

      categories: ['home'],
      tags: [
        'certified',
        'do not disturb',
        'moon',
        'night',
        'privacy',
        'private',
        'protect',
        'protection',
        'security',
        'shield',
        'verified',
      ],
    },
    {
      name: 'shop',

      categories: ['action'],
      tags: [
        'bag',
        'bill',
        'buy',
        'card',
        'cart',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'google',
        'money',
        'online',
        'pay',
        'payment',
        'play',
        'shop',
        'shopping',
        'store',
      ],
    },
    {
      name: 'shop_2',

      categories: ['action'],
      tags: ['2', 'add', 'arrow', 'buy', 'cart', 'google', 'play', 'purchase', 'shop', 'shopping'],
    },
    {
      name: 'shop_two',

      categories: ['action'],
      tags: ['add', 'arrow', 'buy', 'cart', 'google', 'play', 'purchase', 'shop', 'shopping', 'two'],
    },
    {
      name: 'shopping_bag',

      categories: ['action'],
      tags: [
        'bag',
        'bill',
        'business',
        'buy',
        'card',
        'cart',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'money',
        'online',
        'pay',
        'payment',
        'shop',
        'shopping',
        'store',
        'storefront',
      ],
    },
    {
      name: 'shopping_basket',

      categories: ['action'],
      tags: [
        'add',
        'basket',
        'bill',
        'buy',
        'card',
        'cart',
        'cash',
        'checkout',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'money',
        'online',
        'pay',
        'payment',
        'shopping',
      ],
    },
    {
      name: 'shopping_cart',

      categories: ['action'],
      tags: [
        'add',
        'bill',
        'buy',
        'card',
        'cart',
        'cash',
        'checkout',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'money',
        'online',
        'pay',
        'payment',
        'shopping',
      ],
    },
    {
      name: 'shopping_cart_checkout',

      categories: ['action'],
      tags: [
        'arrow',
        'cart',
        'cash',
        'checkout',
        'coin',
        'commerce',
        'currency',
        'dollars',
        'money',
        'online',
        'pay',
        'payment',
        'right',
        'shopping',
      ],
    },
    {
      name: 'short_text',

      categories: ['editor'],
      tags: ['brief', 'comment', 'doc', 'document', 'note', 'short', 'text', 'write', 'writing'],
    },
    {
      name: 'shortcut',

      categories: ['device'],
      tags: ['arrow', 'direction', 'forward', 'right', 'shortcut'],
    },
    {
      name: 'show_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'line',
        'measure',
        'metrics',
        'presentation',
        'show chart',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'shower',

      categories: ['search'],
      tags: [
        'bath',
        'bathroom',
        'closet',
        'home',
        'house',
        'place',
        'plumbing',
        'room',
        'shower',
        'sprinkler',
        'wash',
        'water',
        'wc',
      ],
    },
    {
      name: 'shuffle',

      categories: ['av'],
      tags: ['arrow', 'arrows', 'control', 'controls', 'music', 'random', 'shuffle', 'video'],
    },
    {
      name: 'shuffle_on',

      categories: ['av'],
      tags: ['arrow', 'arrows', 'control', 'controls', 'music', 'on', 'random', 'shuffle', 'video'],
    },
    {
      name: 'shutter_speed',

      categories: ['image'],
      tags: [
        'aperture',
        'camera',
        'duration',
        'image',
        'lens',
        'photo',
        'photography',
        'photos',
        'picture',
        'setting',
        'shutter',
        'speed',
        'stop',
        'time',
        'timer',
        'watch',
      ],
    },
    {
      name: 'sick',

      categories: ['social'],
      tags: [
        'covid',
        'discomfort',
        'emotions',
        'expressions',
        'face',
        'feelings',
        'fever',
        'flu',
        'ill',
        'mood',
        'pain',
        'person',
        'sick',
        'survey',
        'upset',
      ],
    },
    {
      name: 'sign_language',

      categories: ['social'],
      tags: ['communication', 'deaf', 'fingers', 'gesture', 'hand', 'language', 'sign'],
    },
    {
      name: 'signal_cellular_0_bar',

      categories: ['device'],
      tags: [
        '0',
        'bar',
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'signal',
        'speed',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_cellular_4_bar',

      categories: ['device'],
      tags: [
        '4',
        'bar',
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'signal',
        'speed',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_cellular_alt',

      categories: ['device'],
      tags: [
        'alt',
        'analytics',
        'bar',
        'cell',
        'cellular',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'internet',
        'measure',
        'metrics',
        'mobile',
        'network',
        'phone',
        'signal',
        'statistics',
        'tracking',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_cellular_alt_1_bar',

      categories: ['device'],
      tags: [
        '1',
        'bar',
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'signal',
        'speed',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_cellular_alt_2_bar',

      categories: ['device'],
      tags: [
        '2',
        'bar',
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'signal',
        'speed',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_cellular_connected_no_internet_0_bar',

      categories: ['device'],
      tags: [
        '!',
        '0',
        'alert',
        'attention',
        'bar',
        'caution',
        'cell',
        'cellular',
        'connected',
        'danger',
        'data',
        'error',
        'exclamation',
        'important',
        'internet',
        'mark',
        'mobile',
        'network',
        'no',
        'notification',
        'phone',
        'signal',
        'symbol',
        'warning',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_cellular_connected_no_internet_4_bar',

      categories: ['device'],
      tags: [
        '!',
        '4',
        'alert',
        'attention',
        'bar',
        'caution',
        'cell',
        'cellular',
        'connected',
        'danger',
        'data',
        'error',
        'exclamation',
        'important',
        'internet',
        'mark',
        'mobile',
        'network',
        'no',
        'notification',
        'phone',
        'signal',
        'symbol',
        'warning',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_cellular_no_sim',

      categories: ['device'],
      tags: [
        'camera',
        'card',
        'cellular',
        'chip',
        'device',
        'disabled',
        'enabled',
        'memory',
        'no',
        'off',
        'offline',
        'on',
        'phone',
        'signal',
        'sim',
        'slash',
        'storage',
      ],
    },
    {
      name: 'signal_cellular_nodata',

      categories: ['device'],
      tags: [
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'no',
        'nodata',
        'offline',
        'phone',
        'quit',
        'signal',
        'wifi',
        'wireless',
        'x',
      ],
    },
    {
      name: 'signal_cellular_null',

      categories: ['device'],
      tags: [
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'null',
        'phone',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_cellular_off',

      categories: ['device'],
      tags: [
        'cell',
        'cellular',
        'data',
        'disabled',
        'enabled',
        'internet',
        'mobile',
        'network',
        'off',
        'offline',
        'on',
        'phone',
        'signal',
        'slash',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_wifi_0_bar',

      categories: ['device'],
      tags: [
        '0',
        'bar',
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_wifi_4_bar',

      categories: ['device'],
      tags: [
        '4',
        'bar',
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_wifi_4_bar_lock',

      categories: ['device'],
      tags: [
        '4',
        'bar',
        'cell',
        'cellular',
        'data',
        'internet',
        'lock',
        'locked',
        'mobile',
        'network',
        'password',
        'phone',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_wifi_bad',

      categories: ['device'],
      tags: [
        'bad',
        'bar',
        'cancel',
        'cell',
        'cellular',
        'close',
        'data',
        'exit',
        'internet',
        'mobile',
        'network',
        'no',
        'phone',
        'quit',
        'remove',
        'signal',
        'stop',
        'wifi',
        'wireless',
        'x',
      ],
    },
    {
      name: 'signal_wifi_connected_no_internet_4',

      categories: ['device'],
      tags: [
        '4',
        'cell',
        'cellular',
        'connected',
        'data',
        'internet',
        'mobile',
        'network',
        'no',
        'offline',
        'phone',
        'signal',
        'wifi',
        'wireless',
        'x',
      ],
    },
    {
      name: 'signal_wifi_off',

      categories: ['device'],
      tags: [
        'cell',
        'cellular',
        'data',
        'disabled',
        'enabled',
        'internet',
        'mobile',
        'network',
        'off',
        'on',
        'phone',
        'signal',
        'slash',
        'speed',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_wifi_statusbar_4_bar',

      categories: ['device'],
      tags: [
        '4',
        'bar',
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'signal',
        'speed',
        'statusbar',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_wifi_statusbar_connected_no_internet_4',

      categories: ['device'],
      tags: [
        '!',
        '4',
        'alert',
        'attention',
        'caution',
        'cell',
        'cellular',
        'connected',
        'danger',
        'data',
        'error',
        'exclamation',
        'important',
        'internet',
        'mark',
        'mobile',
        'network',
        'no',
        'notification',
        'phone',
        'signal',
        'speed',
        'statusbar',
        'symbol',
        'warning',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signal_wifi_statusbar_null',

      categories: ['device'],
      tags: [
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'null',
        'phone',
        'signal',
        'speed',
        'statusbar',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'signpost',

      categories: ['maps'],
      tags: ['arrow', 'direction', 'left', 'maps', 'right', 'signal', 'signs', 'street', 'traffic'],
    },
    {
      name: 'sim_card',

      categories: ['hardware'],
      tags: ['camera', 'card', 'chip', 'device', 'memory', 'phone', 'sim', 'storage'],
    },
    {
      name: 'sim_card_alert',

      categories: ['notification'],
      tags: [
        '!',
        'alert',
        'attention',
        'camera',
        'card',
        'caution',
        'danger',
        'digital',
        'error',
        'exclamation',
        'important',
        'mark',
        'memory',
        'notification',
        'photos',
        'sd',
        'secure',
        'storage',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'sim_card_download',

      categories: ['device'],
      tags: ['arrow', 'camera', 'card', 'chip', 'device', 'down', 'download', 'memory', 'phone', 'sim', 'storage'],
    },
    {
      name: 'single_bed',

      categories: ['social'],
      tags: [
        'bed',
        'bedroom',
        'double',
        'furniture',
        'home',
        'hotel',
        'house',
        'king',
        'night',
        'pillows',
        'queen',
        'rest',
        'room',
        'single',
        'sleep',
        'twin',
      ],
    },
    {
      name: 'sip',

      categories: ['communication'],
      tags: [
        'alphabet',
        'call',
        'character',
        'dialer',
        'font',
        'initiation',
        'internet',
        'letter',
        'over',
        'phone',
        'protocol',
        'routing',
        'session',
        'sip',
        'symbol',
        'text',
        'type',
        'voice',
      ],
    },
    {
      name: 'skateboarding',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'body',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'people',
        'person',
        'skate',
        'skateboarder',
        'skateboarding',
        'social',
        'sports',
      ],
    },
    {
      name: 'skip_next',

      categories: ['av'],
      tags: ['arrow', 'control', 'controls', 'music', 'next', 'play', 'previous', 'skip', 'video'],
    },
    {
      name: 'skip_previous',

      categories: ['av'],
      tags: ['arrow', 'control', 'controls', 'music', 'next', 'play', 'previous', 'skip', 'video'],
    },
    {
      name: 'sledding',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'body',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'people',
        'person',
        'sled',
        'sledding',
        'sledge',
        'snow',
        'social',
        'sports',
        'travel',
        'winter',
      ],
    },
    {
      name: 'slideshow',

      categories: ['image'],
      tags: ['movie', 'photos', 'play', 'slideshow', 'square', 'video', 'view'],
    },
    {
      name: 'slow_motion_video',

      categories: ['av'],
      tags: ['arrow', 'control', 'controls', 'motion', 'music', 'play', 'slow', 'speed', 'video'],
    },
    {
      name: 'smart_button',

      categories: ['action'],
      tags: [
        'action',
        'auto',
        'button',
        'components',
        'composer',
        'function',
        'interface',
        'site',
        'smart',
        'special',
        'stars',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'smart_display',

      categories: ['hardware'],
      tags: [
        'airplay',
        'cast',
        'chrome',
        'connect',
        'device',
        'display',
        'play',
        'screen',
        'screencast',
        'smart',
        'stream',
        'television',
        'tv',
        'video',
        'wireless',
      ],
    },
    {
      name: 'smart_screen',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'airplay',
        'cast',
        'cell',
        'connect',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'screen',
        'screencast',
        'smart',
        'stream',
        'tablet',
        'video',
      ],
    },
    {
      name: 'smart_toy',

      categories: ['hardware'],
      tags: ['games', 'robot', 'smart', 'toy'],
    },
    {
      name: 'smartphone',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'call',
        'cell',
        'chat',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'smartphone',
        'tablet',
        'text',
      ],
    },
    {
      name: 'smoke_free',

      categories: ['places'],
      tags: [
        'cigarette',
        'disabled',
        'enabled',
        'free',
        'never',
        'no',
        'off',
        'on',
        'places',
        'prohibited',
        'slash',
        'smoke',
        'smoking',
        'tobacco',
        'warning',
        'zone',
      ],
    },
    {
      name: 'smoking_rooms',

      categories: ['places'],
      tags: ['allowed', 'cigarette', 'places', 'rooms', 'smoke', 'smoking', 'tobacco', 'zone'],
    },
    {
      name: 'sms',

      categories: ['notification'],
      tags: [
        '3',
        'bubble',
        'chat',
        'communication',
        'conversation',
        'dots',
        'message',
        'more',
        'service',
        'sms',
        'speech',
        'three',
      ],
    },
    {
      name: 'sms_failed',

      categories: ['notification'],
      tags: [
        '!',
        'alert',
        'attention',
        'bubbles',
        'caution',
        'chat',
        'communication',
        'conversation',
        'danger',
        'error',
        'exclamation',
        'failed',
        'important',
        'mark',
        'message',
        'notification',
        'service',
        'sms',
        'speech',
        'symbol',
        'warning',
      ],
    },
    {
      name: 'snippet_folder',

      categories: ['file'],
      tags: ['data', 'doc', 'document', 'drive', 'file', 'folder', 'sheet', 'slide', 'snippet', 'storage'],
    },
    {
      name: 'snooze',

      categories: ['av'],
      tags: ['alarm', 'bell', 'clock', 'duration', 'notification', 'snooze', 'time', 'timer', 'watch', 'z'],
    },
    {
      name: 'snowboarding',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'body',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'people',
        'person',
        'snow',
        'snowboarding',
        'social',
        'sports',
        'travel',
        'winter',
      ],
    },
    {
      name: 'snowmobile',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'direction',
        'skimobile',
        'snow',
        'snowmobile',
        'social',
        'sports',
        'transportation',
        'travel',
        'vehicle',
        'winter',
      ],
    },
    {
      name: 'snowshoeing',

      categories: ['social'],
      tags: [
        'body',
        'human',
        'people',
        'person',
        'snow',
        'snowshoe',
        'snowshoeing',
        'sports',
        'travel',
        'walking',
        'winter',
      ],
    },
    {
      name: 'soap',

      categories: ['places'],
      tags: ['bathroom', 'clean', 'fingers', 'gesture', 'hand', 'soap', 'wash', 'wc'],
    },
    {
      name: 'social_distance',

      categories: ['social'],
      tags: ['6', 'apart', 'body', 'distance', 'ft', 'human', 'people', 'person', 'social', 'space'],
    },
    {
      name: 'solar_power',

      categories: ['home'],
      tags: ['eco', 'energy', 'heat', 'nest', 'power', 'solar', 'sun', 'sunny'],
    },
    {
      name: 'sort',

      categories: ['content'],
      tags: ['filter', 'find', 'lines', 'list', 'organize', 'sort'],
    },
    {
      name: 'sort_by_alpha',

      categories: ['av'],
      tags: [
        'alphabet',
        'alphabetize',
        'az',
        'by alpha',
        'character',
        'font',
        'letter',
        'list',
        'order',
        'organize',
        'sort',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'sos',

      categories: ['maps'],
      tags: ['font', 'help', 'letters', 'save', 'sos', 'text', 'type'],
    },
    {
      name: 'soup_kitchen',

      categories: ['maps'],
      tags: ['breakfast', 'brunch', 'dining', 'food', 'kitchen', 'lunch', 'meal', 'soup'],
    },
    {
      name: 'source',

      categories: ['action'],
      tags: [
        'code',
        'composer',
        'content',
        'creation',
        'data',
        'doc',
        'document',
        'file',
        'folder',
        'mode',
        'source',
        'storage',
        'view',
      ],
    },
    {
      name: 'south',

      categories: ['navigation'],
      tags: ['arrow', 'directional', 'down', 'maps', 'navigation', 'south'],
    },
    {
      name: 'south_america',

      categories: ['social'],
      tags: ['continent', 'landscape', 'place', 'region', 'south america'],
    },
    {
      name: 'south_east',

      categories: ['navigation'],
      tags: ['arrow', 'directional', 'down', 'east', 'maps', 'navigation', 'right', 'south'],
    },
    {
      name: 'south_west',

      categories: ['navigation'],
      tags: ['arrow', 'directional', 'down', 'left', 'maps', 'navigation', 'south', 'west'],
    },
    {
      name: 'spa',

      categories: ['places'],
      tags: [
        'aromatherapy',
        'flower',
        'healthcare',
        'leaf',
        'massage',
        'meditation',
        'nature',
        'petals',
        'places',
        'relax',
        'spa',
        'wellbeing',
        'wellness',
      ],
    },
    {
      name: 'space_bar',

      categories: ['editor'],
      tags: ['bar', 'keyboard', 'line', 'space'],
    },
    {
      name: 'space_dashboard',

      categories: ['action'],
      tags: [
        'cards',
        'dashboard',
        'format',
        'grid',
        'layout',
        'rectangle',
        'shapes',
        'space',
        'squares',
        'web',
        'website',
      ],
    },
    {
      name: 'spatial_audio',

      categories: ['action'],
      tags: ['audio', 'music', 'note', 'sound', 'spatial'],
    },
    {
      name: 'spatial_audio_off',

      categories: ['action'],
      tags: ['[offline]', 'audio', 'disabled', 'enabled', 'music', 'note', 'off', 'on', 'slash', 'sound', 'spatial'],
    },
    {
      name: 'spatial_tracking',

      categories: ['action'],
      tags: [
        '[offline]',
        'audio',
        'disabled',
        'enabled',
        'music',
        'note',
        'off',
        'on',
        'slash',
        'sound',
        'spatial',
        'tracking',
      ],
    },
    {
      name: 'speaker',

      categories: ['hardware'],
      tags: ['box', 'electronic', 'loud', 'music', 'sound', 'speaker', 'stereo', 'system', 'video'],
    },
    {
      name: 'speaker_group',

      categories: ['hardware'],
      tags: [
        'box',
        'electronic',
        'group',
        'loud',
        'multiple',
        'music',
        'sound',
        'speaker',
        'stereo',
        'system',
        'video',
      ],
    },
    {
      name: 'speaker_notes',

      categories: ['action'],
      tags: [
        'bubble',
        'chat',
        'comment',
        'communicate',
        'format',
        'list',
        'message',
        'notes',
        'speaker',
        'speech',
        'text',
      ],
    },
    {
      name: 'speaker_notes_off',

      categories: ['action'],
      tags: [
        'bubble',
        'chat',
        'comment',
        'communicate',
        'disabled',
        'enabled',
        'format',
        'list',
        'message',
        'notes',
        'off',
        'on',
        'slash',
        'speaker',
        'speech',
        'text',
      ],
    },
    {
      name: 'speaker_phone',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'cell',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'sound',
        'speaker',
        'tablet',
        'volume',
      ],
    },
    {
      name: 'speed',

      categories: ['av'],
      tags: [
        'arrow',
        'control',
        'controls',
        'fast',
        'gauge',
        'meter',
        'motion',
        'music',
        'slow',
        'speed',
        'speedometer',
        'velocity',
        'video',
      ],
    },
    {
      name: 'spellcheck',

      categories: ['action'],
      tags: [
        'a',
        'alphabet',
        'approve',
        'character',
        'check',
        'font',
        'letter',
        'mark',
        'ok',
        'processor',
        'select',
        'spell',
        'spellcheck',
        'symbol',
        'text',
        'tick',
        'type',
        'word',
        'write',
        'yes',
      ],
    },
    {
      name: 'splitscreen',

      categories: ['device'],
      tags: ['grid', 'layout', 'multitasking', 'screen', 'split', 'splitscreen', 'two'],
    },
    {
      name: 'spoke',

      categories: ['communication'],
      tags: ['connection', 'network', 'radius', 'spoke'],
    },
    {
      name: 'sports',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'blowing',
        'coach',
        'entertainment',
        'exercise',
        'game',
        'hobby',
        'instrument',
        'referee',
        'social',
        'sound',
        'sports',
        'warning',
        'whistle',
      ],
    },
    {
      name: 'sports_bar',

      categories: ['places'],
      tags: ['alcohol', 'bar', 'beer', 'drink', 'liquor', 'pint', 'places', 'pub', 'sports'],
    },
    {
      name: 'sports_baseball',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'baseball',
        'entertainment',
        'exercise',
        'game',
        'hobby',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_basketball',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'basketball',
        'entertainment',
        'exercise',
        'game',
        'hobby',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_cricket',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'bat',
        'cricket',
        'entertainment',
        'exercise',
        'game',
        'hobby',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_esports',

      categories: ['social'],
      tags: [
        'controller',
        'entertainment',
        'esports',
        'game',
        'gamepad',
        'gaming',
        'hobby',
        'online',
        'social',
        'sports',
        'video',
      ],
    },
    {
      name: 'sports_football',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'entertainment',
        'exercise',
        'football',
        'game',
        'hobby',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_golf',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'club',
        'entertainment',
        'exercise',
        'game',
        'golf',
        'golfer',
        'golfing',
        'hobby',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_gymnastics',

      categories: ['social'],
      tags: ['athlete', 'athletic', 'entertainment', 'exercise', 'gymnastics', 'hobby', 'social', 'sports'],
    },
    {
      name: 'sports_handball',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'body',
        'entertainment',
        'exercise',
        'game',
        'handball',
        'hobby',
        'human',
        'people',
        'person',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_hockey',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'entertainment',
        'exercise',
        'game',
        'hobby',
        'hockey',
        'social',
        'sports',
        'sticks',
      ],
    },
    {
      name: 'sports_kabaddi',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'body',
        'combat',
        'entertainment',
        'exercise',
        'fighting',
        'game',
        'hobby',
        'human',
        'kabaddi',
        'people',
        'person',
        'social',
        'sports',
        'wrestle',
        'wrestling',
      ],
    },
    {
      name: 'sports_martial_arts',

      categories: ['social'],
      tags: [
        'arts',
        'athlete',
        'athletic',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'karate',
        'martial',
        'people',
        'person',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_mma',

      categories: ['social'],
      tags: [
        'arts',
        'athlete',
        'athletic',
        'boxing',
        'combat',
        'entertainment',
        'exercise',
        'fighting',
        'game',
        'glove',
        'hobby',
        'martial',
        'mixed',
        'mma',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_motorsports',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'automobile',
        'bike',
        'drive',
        'driving',
        'entertainment',
        'helmet',
        'hobby',
        'motorcycle',
        'motorsports',
        'protect',
        'social',
        'sports',
        'vehicle',
      ],
    },
    {
      name: 'sports_rugby',

      categories: ['social'],
      tags: ['athlete', 'athletic', 'ball', 'entertainment', 'exercise', 'game', 'hobby', 'rugby', 'social', 'sports'],
    },
    {
      name: 'sports_score',

      categories: ['device'],
      tags: ['destination', 'flag', 'goal', 'score', 'sports'],
    },
    {
      name: 'sports_soccer',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'entertainment',
        'exercise',
        'football',
        'game',
        'hobby',
        'soccer',
        'social',
        'sports',
      ],
    },
    {
      name: 'sports_tennis',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'bat',
        'entertainment',
        'exercise',
        'game',
        'hobby',
        'racket',
        'social',
        'sports',
        'tennis',
      ],
    },
    {
      name: 'sports_volleyball',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'ball',
        'entertainment',
        'exercise',
        'game',
        'hobby',
        'social',
        'sports',
        'volleyball',
      ],
    },
    {
      name: 'square',

      categories: ['editor'],
      tags: ['draw', 'four', 'shape quadrangle', 'sides', 'square'],
    },
    {
      name: 'square_foot',

      categories: ['content'],
      tags: [
        'construction',
        'feet',
        'foot',
        'inches',
        'length',
        'measurement',
        'ruler',
        'school',
        'set',
        'square',
        'tools',
      ],
    },
    {
      name: 'ssid_chart',

      categories: ['device'],
      tags: ['chart', 'graph', 'lines', 'network', 'ssid', 'wifi'],
    },
    {
      name: 'stacked_bar_chart',

      categories: ['content'],
      tags: [
        'analytics',
        'bar',
        'chart-chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'stacked',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'stacked_line_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'line',
        'measure',
        'metrics',
        'stacked',
        'statistics',
        'tracking',
      ],
    },
    {
      name: 'stadium',

      categories: ['maps'],
      tags: ['activity', 'amphitheater', 'arena', 'coliseum', 'event', 'local', 'stadium', 'star', 'things', 'ticket'],
    },
    {
      name: 'stairs',

      categories: ['places'],
      tags: ['down', 'staircase', 'stairs', 'up'],
    },
    {
      name: 'star',

      categories: ['toggle'],
      tags: ['best', 'bookmark', 'favorite', 'highlight', 'ranking', 'rate', 'rating', 'save', 'star', 'toggle'],
    },
    {
      name: 'star_border',

      categories: ['toggle'],
      tags: [
        'best',
        'bookmark',
        'border',
        'favorite',
        'highlight',
        'outline',
        'ranking',
        'rate',
        'rating',
        'save',
        'star',
        'toggle',
      ],
    },
    {
      name: 'star_border_purple500',

      categories: ['toggle'],
      tags: [
        '500',
        'best',
        'bookmark',
        'border',
        'favorite',
        'highlight',
        'outline',
        'purple',
        'ranking',
        'rate',
        'rating',
        'save',
        'star',
        'toggle',
      ],
    },
    {
      name: 'star_half',

      categories: ['toggle'],
      tags: [
        'achievement',
        'bookmark',
        'favorite',
        'half',
        'highlight',
        'important',
        'marked',
        'ranking',
        'rate',
        'rating rank',
        'reward',
        'save',
        'saved',
        'shape',
        'special',
        'star',
        'toggle',
      ],
    },
    {
      name: 'star_outline',

      categories: ['toggle'],
      tags: ['bookmark', 'favorite', 'half', 'highlight', 'ranking', 'rate', 'rating', 'save', 'star', 'toggle'],
    },
    {
      name: 'star_purple500',

      categories: ['toggle'],
      tags: [
        '500',
        'best',
        'bookmark',
        'favorite',
        'highlight',
        'purple',
        'ranking',
        'rate',
        'rating',
        'save',
        'star',
        'toggle',
      ],
    },
    {
      name: 'star_rate',

      categories: ['action'],
      tags: [
        'achievement',
        'bookmark',
        'favorite',
        'highlight',
        'important',
        'marked',
        'ranking',
        'rate',
        'rating rank',
        'reward',
        'save',
        'saved',
        'shape',
        'special',
        'star',
      ],
    },
    {
      name: 'stars',

      categories: ['action'],
      tags: [
        'achievement',
        'bookmark',
        'circle',
        'favorite',
        'highlight',
        'important',
        'marked',
        'ranking',
        'rate',
        'rating rank',
        'reward',
        'save',
        'saved',
        'shape',
        'special',
        'star',
      ],
    },
    {
      name: 'start',

      categories: ['hardware'],
      tags: ['arrow', 'keyboard', 'next', 'right', 'start'],
    },
    {
      name: 'stay_current_landscape',

      categories: ['communication'],
      tags: ['Android', 'OS', 'current', 'device', 'hardware', 'iOS', 'landscape', 'mobile', 'phone', 'stay', 'tablet'],
    },
    {
      name: 'stay_current_portrait',

      categories: ['communication'],
      tags: ['Android', 'OS', 'current', 'device', 'hardware', 'iOS', 'mobile', 'phone', 'portrait', 'stay', 'tablet'],
    },
    {
      name: 'stay_primary_landscape',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'current',
        'device',
        'hardware',
        'iOS',
        'landscape',
        'mobile',
        'phone',
        'primary',
        'stay',
        'tablet',
      ],
    },
    {
      name: 'stay_primary_portrait',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'current',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'portrait',
        'primary',
        'stay',
        'tablet',
      ],
    },
    {
      name: 'sticky_note_2',

      categories: ['action'],
      tags: ['2', 'bookmark', 'mark', 'message', 'note', 'paper', 'sticky', 'text', 'writing'],
    },
    {
      name: 'stop',

      categories: ['av'],
      tags: ['control', 'controls', 'music', 'pause', 'play', 'square', 'stop', 'video'],
    },
    {
      name: 'stop_circle',

      categories: ['av'],
      tags: ['circle', 'control', 'controls', 'music', 'pause', 'play', 'square', 'stop', 'video'],
    },
    {
      name: 'stop_screen_share',

      categories: ['communication'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'cast',
        'chrome',
        'device',
        'disabled',
        'display',
        'enabled',
        'hardware',
        'iOS',
        'laptop',
        'mac',
        'mirror',
        'monitor',
        'off',
        'offline',
        'on',
        'screen',
        'share',
        'slash',
        'steam',
        'stop',
        'streaming',
        'web',
        'window',
      ],
    },
    {
      name: 'storage',

      categories: ['device'],
      tags: ['computer', 'data', 'drive', 'memory', 'storage'],
    },
    {
      name: 'store',

      categories: ['action'],
      tags: [
        'bill',
        'building',
        'business',
        'card',
        'cash',
        'coin',
        'commerce',
        'company',
        'credit',
        'currency',
        'dollars',
        'market',
        'money',
        'online',
        'pay',
        'payment',
        'shop',
        'shopping',
        'store',
        'storefront',
      ],
    },
    {
      name: 'store_mall_directory',

      categories: ['maps'],
      tags: ['directory', 'mall', 'store'],
    },
    {
      name: 'storefront',

      categories: ['places'],
      tags: [
        'business',
        'buy',
        'cafe',
        'commerce',
        'front',
        'market',
        'places',
        'restaurant',
        'retail',
        'sell',
        'shop',
        'shopping',
        'store',
        'storefront',
      ],
    },
    {
      name: 'storm',

      categories: ['device'],
      tags: ['forecast', 'hurricane', 'storm', 'temperature', 'twister', 'weather', 'wind'],
    },
    {
      name: 'straight',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'navigation',
        'path',
        'route',
        'sign',
        'straight',
        'traffic',
        'up',
      ],
    },
    {
      name: 'straighten',

      categories: ['image'],
      tags: ['length', 'measure', 'measurement', 'ruler', 'size', 'straighten'],
    },
    {
      name: 'stream',

      categories: ['content'],
      tags: ['cast', 'connected', 'feed', 'live', 'network', 'signal', 'stream', 'wireless'],
    },
    {
      name: 'streetview',

      categories: ['maps'],
      tags: ['maps', 'street', 'streetview', 'view'],
    },
    {
      name: 'strikethrough_s',

      categories: ['editor'],
      tags: [
        'alphabet',
        'character',
        'cross',
        'doc',
        'edit',
        'editing',
        'editor',
        'font',
        'letter',
        'out',
        's',
        'sheet',
        'spreadsheet',
        'strikethrough',
        'styles',
        'symbol',
        'text',
        'type',
        'writing',
      ],
    },
    {
      name: 'stroller',

      categories: ['places'],
      tags: [
        'baby',
        'care',
        'carriage',
        'child',
        'children',
        'infant',
        'kid',
        'newborn',
        'stroller',
        'toddler',
        'young',
      ],
    },
    {
      name: 'style',

      categories: ['image'],
      tags: ['booklet', 'cards', 'filters', 'options', 'style', 'tags'],
    },
    {
      name: 'subdirectory_arrow_left',

      categories: ['navigation'],
      tags: ['arrow', 'directory', 'down', 'left', 'navigation', 'sub', 'subdirectory'],
    },
    {
      name: 'subdirectory_arrow_right',

      categories: ['navigation'],
      tags: ['arrow', 'directory', 'down', 'navigation', 'right', 'sub', 'subdirectory'],
    },
    {
      name: 'subject',

      categories: ['action'],
      tags: ['alignment', 'doc', 'document', 'email', 'full', 'justify', 'list', 'note', 'subject', 'text', 'writing'],
    },
    {
      name: 'subscript',

      categories: ['editor'],
      tags: [
        '2',
        'doc',
        'edit',
        'editing',
        'editor',
        'gmail',
        'novitas',
        'sheet',
        'spreadsheet',
        'style',
        'subscript',
        'symbol',
        'text',
        'writing',
        'x',
      ],
    },
    {
      name: 'subscriptions',

      categories: ['av'],
      tags: ['enroll', 'list', 'media', 'order', 'play', 'signup', 'subscribe', 'subscriptions'],
    },
    {
      name: 'subtitles',

      categories: ['av'],
      tags: [
        'accessible',
        'caption',
        'cc',
        'character',
        'closed',
        'decoder',
        'language',
        'media',
        'movies',
        'subtitle',
        'subtitles',
        'tv',
      ],
    },
    {
      name: 'subtitles_off',

      categories: ['action'],
      tags: [
        'accessibility',
        'accessible',
        'caption',
        'cc',
        'closed',
        'disabled',
        'enabled',
        'language',
        'off',
        'on',
        'slash',
        'subtitle',
        'subtitles',
        'translate',
        'video',
      ],
    },
    {
      name: 'subway',

      categories: ['maps'],
      tags: [
        'automobile',
        'bike',
        'car',
        'cars',
        'maps',
        'rail',
        'scooter',
        'subway',
        'train',
        'transportation',
        'travel',
        'tunnel',
        'underground',
        'vehicle',
        'vespa',
      ],
    },
    {
      name: 'summarize',

      categories: ['device'],
      tags: ['doc', 'document', 'list', 'menu', 'note', 'report', 'summary'],
    },
    {
      name: 'superscript',

      categories: ['editor'],
      tags: [
        '2',
        'doc',
        'edit',
        'editing',
        'editor',
        'gmail',
        'novitas',
        'sheet',
        'spreadsheet',
        'style',
        'superscript',
        'symbol',
        'text',
        'writing',
        'x',
      ],
    },
    {
      name: 'supervised_user_circle',

      categories: ['action'],
      tags: [
        'account',
        'avatar',
        'circle',
        'control',
        'face',
        'human',
        'parental',
        'parents',
        'people',
        'person',
        'profile',
        'supervised',
        'supervisor',
        'user',
      ],
    },
    {
      name: 'supervisor_account',

      categories: ['action'],
      tags: [
        'account',
        'avatar',
        'control',
        'face',
        'human',
        'parental',
        'parental control',
        'parents',
        'people',
        'person',
        'profile',
        'supervised',
        'supervisor',
        'user',
      ],
    },
    {
      name: 'support',

      categories: ['action'],
      tags: ['assist', 'buoy', 'help', 'life', 'lifebuoy', 'rescue', 'safe', 'safety', 'support'],
    },
    {
      name: 'support_agent',

      categories: ['notification'],
      tags: ['agent', 'care', 'customer', 'face', 'headphone', 'person', 'representative', 'service', 'support'],
    },
    {
      name: 'surfing',

      categories: ['social'],
      tags: [
        'athlete',
        'athletic',
        'beach',
        'body',
        'entertainment',
        'exercise',
        'hobby',
        'human',
        'people',
        'person',
        'sea',
        'social sports',
        'sports',
        'summer',
        'surfing',
        'water',
      ],
    },
    {
      name: 'surround_sound',

      categories: ['av'],
      tags: ['circle', 'signal', 'sound', 'speaker', 'surround', 'system', 'volumn', 'wireless'],
    },
    {
      name: 'swap_calls',

      categories: ['communication'],
      tags: ['arrow', 'arrows', 'calls', 'device', 'direction', 'mobile', 'share', 'swap'],
    },
    {
      name: 'swap_horiz',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'back', 'forward', 'horizontal', 'swap'],
    },
    {
      name: 'swap_horizontal_circle',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'back', 'circle', 'forward', 'horizontal', 'swap'],
    },
    {
      name: 'swap_vert',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'direction', 'down', 'navigation', 'swap', 'up', 'vert', 'vertical'],
    },
    {
      name: 'swap_vertical_circle',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'circle', 'down', 'swap', 'up', 'vertical'],
    },
    {
      name: 'swipe',

      categories: ['action'],
      tags: ['arrow', 'arrows', 'fingers', 'gesture', 'hand', 'hands', 'swipe', 'touch'],
    },
    {
      name: 'swipe_down',

      categories: ['action'],
      tags: [
        'arrows',
        'direction',
        'disable',
        'down',
        'enable',
        'finger',
        'hands',
        'hit',
        'navigation',
        'strike',
        'swing',
        'swpie',
        'take',
      ],
    },
    {
      name: 'swipe_down_alt',

      categories: ['action'],
      tags: [
        'alt',
        'arrows',
        'direction',
        'disable',
        'down',
        'enable',
        'finger',
        'hands',
        'hit',
        'navigation',
        'strike',
        'swing',
        'swpie',
        'take',
      ],
    },
    {
      name: 'swipe_left',

      categories: ['action'],
      tags: [
        'arrow',
        'arrows',
        'finger',
        'hand',
        'hit',
        'left',
        'navigation',
        'reject',
        'strike',
        'swing',
        'swipe',
        'take',
      ],
    },
    {
      name: 'swipe_left_alt',

      categories: ['action'],
      tags: [
        'alt',
        'arrow',
        'arrows',
        'finger',
        'hand',
        'hit',
        'left',
        'navigation',
        'reject',
        'strike',
        'swing',
        'swipe',
        'take',
      ],
    },
    {
      name: 'swipe_right',

      categories: ['action'],
      tags: [
        'accept',
        'arrows',
        'direction',
        'finger',
        'hands',
        'hit',
        'navigation',
        'right',
        'strike',
        'swing',
        'swpie',
        'take',
      ],
    },
    {
      name: 'swipe_right_alt',

      categories: ['action'],
      tags: [
        'accept',
        'alt',
        'arrows',
        'direction',
        'finger',
        'hands',
        'hit',
        'navigation',
        'right',
        'strike',
        'swing',
        'swpie',
        'take',
      ],
    },
    {
      name: 'swipe_up',

      categories: ['action'],
      tags: [
        'arrows',
        'direction',
        'disable',
        'enable',
        'finger',
        'hands',
        'hit',
        'navigation',
        'strike',
        'swing',
        'swpie',
        'take',
        'up',
      ],
    },
    {
      name: 'swipe_up_alt',

      categories: ['action'],
      tags: [
        'alt',
        'arrows',
        'direction',
        'disable',
        'enable',
        'finger',
        'hands',
        'hit',
        'navigation',
        'strike',
        'swing',
        'swpie',
        'take',
        'up',
      ],
    },
    {
      name: 'swipe_vertical',

      categories: ['action'],
      tags: [
        'arrows',
        'direction',
        'finger',
        'hands',
        'hit',
        'navigation',
        'strike',
        'swing',
        'swpie',
        'take',
        'verticle',
      ],
    },
    {
      name: 'switch_access_shortcut',

      categories: ['action'],
      tags: [
        'access',
        'arrow',
        'arrows',
        'direction',
        'navigation',
        'new',
        'north',
        'shortcut',
        'star',
        'switch',
        'symbol',
        'up',
      ],
    },
    {
      name: 'switch_access_shortcut_add',

      categories: ['action'],
      tags: [
        '+',
        'access',
        'add',
        'arrow',
        'arrows',
        'direction',
        'navigation',
        'new',
        'north',
        'plus',
        'shortcut',
        'star',
        'switch',
        'symbol',
        'up',
      ],
    },
    {
      name: 'switch_account',

      categories: ['social'],
      tags: [
        'account',
        'choices',
        'face',
        'human',
        'multiple',
        'options',
        'people',
        'person',
        'profile',
        'social',
        'switch',
        'user',
      ],
    },
    {
      name: 'switch_camera',

      categories: ['image'],
      tags: ['arrow', 'arrows', 'camera', 'photo', 'photography', 'picture', 'switch'],
    },
    {
      name: 'switch_left',

      categories: ['navigation'],
      tags: ['arrows', 'directional', 'left', 'navigation', 'switch', 'toggle'],
    },
    {
      name: 'switch_right',

      categories: ['navigation'],
      tags: ['arrows', 'directional', 'navigation', 'right', 'switch', 'toggle'],
    },
    {
      name: 'switch_video',

      categories: ['image'],
      tags: ['arrow', 'arrows', 'camera', 'photography', 'switch', 'video', 'videos'],
    },
    {
      name: 'synagogue',

      categories: ['maps'],
      tags: ['jew', 'jewish', 'religion', 'shul', 'spiritual', 'temple', 'worship'],
    },
    {
      name: 'sync',

      categories: ['notification'],
      tags: [
        '360',
        'around',
        'arrow',
        'arrows',
        'direction',
        'inprogress',
        'load',
        'loading refresh',
        'renew',
        'rotate',
        'sync',
        'turn',
      ],
    },
    {
      name: 'sync_alt',

      categories: ['action'],
      tags: ['alt', 'arrow', 'arrows', 'horizontal', 'internet', 'sync', 'technology', 'up', 'update', 'wifi'],
    },
    {
      name: 'sync_disabled',

      categories: ['notification'],
      tags: [
        '360',
        'around',
        'arrow',
        'arrows',
        'direction',
        'disabled',
        'enabled',
        'inprogress',
        'load',
        'loading refresh',
        'off',
        'on',
        'renew',
        'rotate',
        'slash',
        'sync',
        'turn',
      ],
    },
    {
      name: 'sync_lock',

      categories: ['notification'],
      tags: [
        'around',
        'arrow',
        'arrows',
        'lock',
        'locked',
        'password',
        'privacy',
        'private',
        'protection',
        'renew',
        'rotate',
        'safety',
        'secure',
        'security',
        'sync',
        'turn',
      ],
    },
    {
      name: 'sync_problem',

      categories: ['notification'],
      tags: [
        '!',
        '360',
        'alert',
        'around',
        'arrow',
        'arrows',
        'attention',
        'caution',
        'danger',
        'direction',
        'error',
        'exclamation',
        'important',
        'inprogress',
        'load',
        'loading refresh',
        'mark',
        'notification',
        'problem',
        'renew',
        'rotate',
        'symbol',
        'sync',
        'turn',
        'warning',
      ],
    },
    {
      name: 'system_security_update',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'cell',
        'device',
        'down',
        'hardware',
        'iOS',
        'mobile',
        'phone',
        'security',
        'system',
        'tablet',
        'update',
      ],
    },
    {
      name: 'system_security_update_good',

      categories: ['device'],
      tags: [
        'Android',
        'OS',
        'approve',
        'cell',
        'check',
        'complete',
        'device',
        'done',
        'good',
        'hardware',
        'iOS',
        'mark',
        'mobile',
        'ok',
        'phone',
        'security',
        'select',
        'system',
        'tablet',
        'tick',
        'update',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'system_security_update_warning',

      categories: ['device'],
      tags: [
        '!',
        'Android',
        'OS',
        'alert',
        'attention',
        'caution',
        'cell',
        'danger',
        'device',
        'error',
        'exclamation',
        'hardware',
        'iOS',
        'important',
        'mark',
        'mobile',
        'notification',
        'phone',
        'security',
        'symbol',
        'system',
        'tablet',
        'update',
        'warning',
      ],
    },
    {
      name: 'system_update',

      categories: ['notification'],
      tags: [
        'Android',
        'OS',
        'arrow',
        'arrows',
        'cell',
        'device',
        'direction',
        'down',
        'download',
        'hardware',
        'iOS',
        'install',
        'mobile',
        'phone',
        'system',
        'tablet',
        'update',
      ],
    },
    {
      name: 'system_update_alt',

      categories: ['action'],
      tags: ['arrow', 'down', 'download', 'export', 'system', 'update'],
    },
    {
      name: 'tab',

      categories: ['action'],
      tags: [
        'browser',
        'computer',
        'document',
        'documents',
        'folder',
        'internet',
        'tab',
        'tabs',
        'web',
        'website',
        'window',
        'windows',
      ],
    },
    {
      name: 'tab_unselected',

      categories: ['action'],
      tags: [
        'browser',
        'computer',
        'document',
        'documents',
        'folder',
        'internet',
        'tab',
        'tabs',
        'unselected',
        'web',
        'website',
        'window',
        'windows',
      ],
    },
    {
      name: 'table_bar',

      categories: ['search'],
      tags: ['bar', 'cafe', 'round', 'table'],
    },
    {
      name: 'table_chart',

      categories: ['editor'],
      tags: [
        'analytics',
        'bar',
        'bars',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic grid',
        'measure',
        'metrics',
        'statistics',
        'table',
        'tracking',
      ],
    },
    {
      name: 'table_restaurant',

      categories: ['search'],
      tags: ['bar', 'dining', 'table'],
    },
    {
      name: 'table_rows',

      categories: ['editor'],
      tags: ['grid', 'layout', 'lines', 'rows', 'stacked', 'table'],
    },
    {
      name: 'table_view',

      categories: ['action'],
      tags: ['format', 'grid', 'group', 'layout', 'multiple', 'table', 'view'],
    },
    {
      name: 'tablet',

      categories: ['hardware'],
      tags: ['Android', 'OS', 'device', 'hardware', 'iOS', 'ipad', 'mobile', 'tablet', 'web'],
    },
    {
      name: 'tablet_android',

      categories: ['hardware'],
      tags: ['OS', 'android', 'device', 'hardware', 'iOS', 'ipad', 'mobile', 'tablet', 'web'],
    },
    {
      name: 'tablet_mac',

      categories: ['hardware'],
      tags: ['Android', 'OS', 'device', 'hardware', 'iOS', 'ipad', 'mobile', 'tablet mac', 'web'],
    },
    {
      name: 'tag',

      categories: ['content'],
      tags: ['hash', 'hashtag', 'key', 'media', 'number', 'pound', 'social', 'tag', 'trend'],
    },
    {
      name: 'tag_faces',

      categories: ['image'],
      tags: ['emoji', 'emotion', 'faces', 'happy', 'satisfied', 'smile', 'tag'],
    },
    {
      name: 'takeout_dining',

      categories: ['maps'],
      tags: ['box', 'container', 'delivery', 'dining', 'food', 'meal', 'restaurant', 'takeout'],
    },
    {
      name: 'tap_and_play',

      categories: ['notification'],
      tags: [
        'Android',
        'OS wifi',
        'cell',
        'connection',
        'device',
        'hardware',
        'iOS',
        'internet',
        'mobile',
        'network',
        'phone',
        'play',
        'signal',
        'tablet',
        'tap',
        'to',
        'wireless',
      ],
    },
    {
      name: 'tapas',

      categories: ['places'],
      tags: ['appetizer', 'brunch', 'dinner', 'food', 'lunch', 'restaurant', 'snack', 'tapas'],
    },
    {
      name: 'task',

      categories: ['device'],
      tags: [
        'approve',
        'check',
        'complete',
        'data',
        'doc',
        'document',
        'done',
        'drive',
        'file',
        'folder',
        'folders',
        'mark',
        'ok',
        'page',
        'paper',
        'select',
        'sheet',
        'slide',
        'task',
        'tick',
        'validate',
        'verified',
        'writing',
        'yes',
      ],
    },
    {
      name: 'task_alt',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'circle',
        'complete',
        'done',
        'mark',
        'ok',
        'select',
        'task',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'taxi_alert',

      categories: ['maps'],
      tags: [
        '!',
        'alert',
        'attention',
        'automobile',
        'cab',
        'car',
        'cars',
        'caution',
        'danger',
        'direction',
        'error',
        'exclamation',
        'important',
        'lyft',
        'maps',
        'mark',
        'notification',
        'public',
        'symbol',
        'taxi',
        'transportation',
        'uber',
        'vehicle',
        'warning',
        'yellow',
      ],
    },
    {
      name: 'temple_buddhist',

      categories: ['maps'],
      tags: ['buddha', 'buddhism', 'buddhist', 'monastery', 'religion', 'spiritual', 'temple', 'worship'],
    },
    {
      name: 'temple_hindu',

      categories: ['maps'],
      tags: ['hindu', 'hinduism', 'hindus', 'mandir', 'religion', 'spiritual', 'temple', 'worship'],
    },
    {
      name: 'terminal',

      categories: ['action'],
      tags: ['application', 'code', 'emulator', 'program', 'software', 'terminal'],
    },
    {
      name: 'terrain',

      categories: ['maps'],
      tags: ['geography', 'landscape', 'mountain', 'terrain'],
    },
    {
      name: 'text_decrease',

      categories: ['editor'],
      tags: [
        '-',
        'alphabet',
        'character',
        'decrease',
        'font',
        'letter',
        'minus',
        'remove',
        'resize',
        'subtract',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'text_fields',

      categories: ['editor'],
      tags: [
        'T',
        'add',
        'alphabet',
        'character',
        'field',
        'fields',
        'font',
        'input',
        'letter',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'text_format',

      categories: ['content'],
      tags: ['alphabet', 'character', 'font', 'format', 'letter', 'square A', 'style', 'symbol', 'text', 'type'],
    },
    {
      name: 'text_increase',

      categories: ['editor'],
      tags: [
        '+',
        'add',
        'alphabet',
        'character',
        'font',
        'increase',
        'letter',
        'new',
        'plus',
        'resize',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'text_rotate_up',

      categories: ['action'],
      tags: [
        'A',
        'alphabet',
        'arrow',
        'character',
        'field',
        'font',
        'letter',
        'move',
        'rotate',
        'symbol',
        'text',
        'type',
        'up',
      ],
    },
    {
      name: 'text_rotate_vertical',

      categories: ['action'],
      tags: [
        'A',
        'alphabet',
        'arrow',
        'character',
        'down',
        'field',
        'font',
        'letter',
        'move',
        'rotate',
        'symbol',
        'text',
        'type',
        'vertical',
      ],
    },
    {
      name: 'text_rotation_angledown',

      categories: ['action'],
      tags: [
        'A',
        'alphabet',
        'angledown',
        'arrow',
        'character',
        'field',
        'font',
        'letter',
        'move',
        'rotate',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'text_rotation_angleup',

      categories: ['action'],
      tags: [
        'A',
        'alphabet',
        'angleup',
        'arrow',
        'character',
        'field',
        'font',
        'letter',
        'move',
        'rotate',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'text_rotation_down',

      categories: ['action'],
      tags: [
        'A',
        'alphabet',
        'arrow',
        'character',
        'dow',
        'field',
        'font',
        'letter',
        'move',
        'rotate',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'text_rotation_none',

      categories: ['action'],
      tags: [
        'A',
        'alphabet',
        'arrow',
        'character',
        'field',
        'font',
        'letter',
        'move',
        'none',
        'rotate',
        'symbol',
        'text',
        'type',
      ],
    },
    {
      name: 'text_snippet',

      categories: ['file'],
      tags: ['data', 'doc', 'document', 'file', 'note', 'notes', 'snippet', 'storage', 'text', 'writing'],
    },
    {
      name: 'textsms',

      categories: ['communication'],
      tags: ['bubble', 'chat', 'comment', 'communicate', 'dots', 'feedback', 'message', 'speech', 'textsms'],
    },
    {
      name: 'texture',

      categories: ['image'],
      tags: ['diagonal', 'lines', 'pattern', 'stripes', 'texture'],
    },
    {
      name: 'theater_comedy',

      categories: ['maps'],
      tags: [
        'broadway',
        'comedy',
        'event',
        'movie',
        'musical',
        'places',
        'show',
        'standup',
        'theater',
        'tour',
        'watch',
      ],
    },
    {
      name: 'theaters',

      categories: ['action'],
      tags: ['film', 'movie', 'movies', 'show', 'showtimes', 'theater', 'theaters', 'watch'],
    },
    {
      name: 'thermostat',

      categories: ['device'],
      tags: ['forecast', 'temperature', 'thermostat', 'weather'],
    },
    {
      name: 'thermostat_auto',

      categories: ['image'],
      tags: ['A', 'auto', 'celsius', 'fahrenheit', 'meter', 'temp', 'temperature', 'thermometer', 'thermostat'],
    },
    {
      name: 'thumb_down',

      categories: ['action'],
      tags: [
        'ate',
        'dislike',
        'down',
        'favorite',
        'fingers',
        'gesture',
        'hand',
        'hands',
        'like',
        'rank',
        'ranking',
        'rating',
        'thumb',
      ],
    },
    {
      name: 'thumb_down_alt',

      categories: ['social'],
      tags: [
        'bad',
        'decline',
        'disapprove',
        'dislike',
        'down',
        'feedback',
        'hate',
        'negative',
        'no',
        'reject',
        'social',
        'thumb',
        'veto',
        'vote',
      ],
    },
    {
      name: 'thumb_down_off_alt',

      categories: ['action'],
      tags: [
        '[offline]',
        'disabled',
        'dislike',
        'down',
        'enabled',
        'favorite',
        'filled',
        'fingers',
        'gesture',
        'hand',
        'hands',
        'like',
        'off',
        'on',
        'rank',
        'ranking',
        'rate',
        'rating',
        'slash',
        'thumb',
      ],
    },
    {
      name: 'thumb_up',

      categories: ['action'],
      tags: [
        'favorite',
        'fingers',
        'gesture',
        'hand',
        'hands',
        'like',
        'rank',
        'ranking',
        'rate',
        'rating',
        'thumb',
        'up',
      ],
    },
    {
      name: 'thumb_up_alt',

      categories: ['social'],
      tags: [
        'agreed',
        'approved',
        'confirm',
        'correct',
        'favorite',
        'feedback',
        'good',
        'happy',
        'like',
        'okay',
        'positive',
        'satisfaction',
        'social',
        'thumb',
        'up',
        'vote',
        'yes',
      ],
    },
    {
      name: 'thumb_up_off_alt',

      categories: ['action'],
      tags: [
        '[offline]',
        'alt',
        'disabled',
        'enabled',
        'favorite',
        'fingers',
        'gesture',
        'hand',
        'hands',
        'like',
        'off',
        'on',
        'rank',
        'ranking',
        'rate',
        'rating',
        'slash',
        'thumb',
        'up',
      ],
    },
    {
      name: 'thumbs_up_down',

      categories: ['action'],
      tags: ['dislike', 'down', 'favorite', 'fingers', 'gesture', 'hands', 'like', 'rate', 'rating', 'thumbs', 'up'],
    },
    {
      name: 'thunderstorm',

      categories: ['social'],
      tags: ['cloud', 'lightning', 'rain', 'storm', 'thunder', 'thunderstorm', 'weather'],
    },
    {
      name: 'time_to_leave',

      categories: ['notification'],
      tags: [
        'automobile',
        'car',
        'cars',
        'destination',
        'direction',
        'drive',
        'estimate',
        'eta',
        'maps',
        'public',
        'transportation',
        'travel',
        'trip',
        'vehicle',
      ],
    },
    {
      name: 'timelapse',

      categories: ['image'],
      tags: ['duration', 'motion', 'photo', 'time', 'timelapse', 'timer', 'video'],
    },
    {
      name: 'timeline',

      categories: ['action'],
      tags: ['data', 'history', 'line', 'movement', 'point', 'points', 'timeline', 'tracking', 'trending', 'zigzag'],
    },
    {
      name: 'timer',

      categories: ['image'],
      tags: [
        'alarm',
        'alart',
        'bell',
        'clock',
        'disabled',
        'duration',
        'enabled',
        'notification',
        'off',
        'on',
        'slash',
        'stop',
        'time',
        'timer',
        'watch',
      ],
    },
    {
      name: 'timer_10',

      categories: ['image'],
      tags: ['10', 'digits', 'duration', 'number', 'numbers', 'seconds', 'time', 'timer'],
    },
    {
      name: 'timer_10_select',

      categories: ['device'],
      tags: [
        '10',
        'alphabet',
        'camera',
        'character',
        'digit',
        'font',
        'letter',
        'number',
        'seconds',
        'select',
        'symbol',
        'text',
        'timer',
        'type',
      ],
    },
    {
      name: 'timer_3',

      categories: ['image'],
      tags: ['3', 'digits', 'duration', 'number', 'numbers', 'seconds', 'time', 'timer'],
    },
    {
      name: 'timer_3_select',

      categories: ['device'],
      tags: [
        '3',
        'alphabet',
        'camera',
        'character',
        'digit',
        'font',
        'letter',
        'number',
        'seconds',
        'select',
        'symbol',
        'text',
        'timer',
        'type',
      ],
    },
    {
      name: 'timer_off',

      categories: ['image'],
      tags: [
        'alarm',
        'alart',
        'bell',
        'clock',
        'disabled',
        'duration',
        'enabled',
        'notification',
        'off',
        'on',
        'slash',
        'stop',
        'time',
        'timer',
        'watch',
      ],
    },
    {
      name: 'tips_and_updates',

      categories: ['action'],
      tags: [
        'alert',
        'and',
        'announcement',
        'electricity',
        'idea',
        'info',
        'information',
        'light',
        'lightbulb',
        'stars',
        'tips',
        'updates',
      ],
    },
    {
      name: 'tire_repair',

      categories: ['maps'],
      tags: ['auto', 'automobile', 'car', 'cars', 'gauge', 'mechanic', 'pressure', 'repair', 'tire', 'vehicle'],
    },
    {
      name: 'title',

      categories: ['editor'],
      tags: ['T', 'alphabet', 'character', 'font', 'header', 'letter', 'subject', 'symbol', 'text', 'title', 'type'],
    },
    {
      name: 'toc',

      categories: ['action'],
      tags: ['content', 'format', 'lines', 'list', 'order', 'reorder', 'stacked', 'table', 'title', 'titles', 'toc'],
    },
    {
      name: 'today',

      categories: ['action'],
      tags: ['calendar', 'date', 'day', 'event', 'mark', 'month', 'remember', 'reminder', 'schedule', 'time', 'today'],
    },
    {
      name: 'toggle_off',

      categories: ['toggle'],
      tags: [
        'active',
        'app',
        'application',
        'components',
        'configuration',
        'control',
        'design',
        'disable',
        'inable',
        'inactive',
        'interface',
        'off',
        'on',
        'selection',
        'settings',
        'site',
        'slider',
        'switch',
        'toggle',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'toggle_on',

      categories: ['toggle'],
      tags: [
        'active',
        'app',
        'application',
        'components',
        'configuration',
        'control',
        'design',
        'disable',
        'inable',
        'inactive',
        'interface',
        'off',
        'on',
        'selection',
        'settings',
        'site',
        'slider',
        'switch',
        'toggle',
        'ui',
        'ux',
        'web',
        'website',
      ],
    },
    {
      name: 'token',

      categories: ['action'],
      tags: ['badge', 'hexagon', 'mark', 'shield', 'sign', 'symbol'],
    },
    {
      name: 'toll',

      categories: ['action'],
      tags: [
        'bill',
        'booth',
        'car',
        'card',
        'cash',
        'coin',
        'commerce',
        'credit',
        'currency',
        'dollars',
        'highway',
        'money',
        'online',
        'pay',
        'payment',
        'ticket',
        'toll',
      ],
    },
    {
      name: 'tonality',

      categories: ['image'],
      tags: ['circle', 'edit', 'editing', 'filter', 'image', 'photography', 'picture', 'tonality'],
    },
    {
      name: 'topic',

      categories: ['file'],
      tags: ['data', 'doc', 'document', 'drive', 'file', 'folder', 'sheet', 'slide', 'storage', 'topic'],
    },
    {
      name: 'tornado',

      categories: ['social'],
      tags: ['crisis', 'disaster', 'natural', 'rain', 'storm', 'tornado', 'weather', 'wind'],
    },
    {
      name: 'touch_app',

      categories: ['action'],
      tags: ['app', 'command', 'fingers', 'gesture', 'hand', 'press', 'tap', 'touch'],
    },
    {
      name: 'tour',

      categories: ['action'],
      tags: ['destination', 'flag', 'places', 'tour', 'travel', 'visit'],
    },
    {
      name: 'toys',

      categories: ['hardware'],
      tags: ['car', 'games', 'kids', 'toy', 'toys', 'windmill'],
    },
    {
      name: 'track_changes',

      categories: ['action'],
      tags: ['bullseye', 'changes', 'circle', 'evolve', 'lines', 'movement', 'rotate', 'shift', 'target', 'track'],
    },
    {
      name: 'traffic',

      categories: ['maps'],
      tags: ['direction', 'light', 'maps', 'signal', 'street', 'traffic'],
    },
    {
      name: 'train',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'maps',
        'public',
        'rail',
        'subway',
        'train',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'tram',

      categories: ['maps'],
      tags: [
        'automobile',
        'car',
        'cars',
        'direction',
        'maps',
        'public',
        'rail',
        'subway',
        'train',
        'tram',
        'transportation',
        'vehicle',
      ],
    },
    {
      name: 'transcribe',

      categories: ['action'],
      tags: [],
    },
    {
      name: 'transfer_within_a_station',

      categories: ['maps'],
      tags: [
        'a',
        'arrow',
        'arrows',
        'body',
        'direction',
        'human',
        'left',
        'maps',
        'people',
        'person',
        'public',
        'right',
        'route',
        'station',
        'stop',
        'transfer',
        'transportation',
        'vehicle',
        'walk',
        'within',
      ],
    },
    {
      name: 'transform',

      categories: ['image'],
      tags: ['adjust', 'crop', 'edit', 'editing', 'image', 'photo', 'picture', 'transform'],
    },
    {
      name: 'transgender',

      categories: ['social'],
      tags: ['female', 'gender', 'lgbt', 'male', 'neutral', 'social', 'symbol', 'transgender'],
    },
    {
      name: 'transit_enterexit',

      categories: ['maps'],
      tags: ['arrow', 'direction', 'enterexit', 'maps', 'navigation', 'route', 'transit', 'transportation'],
    },
    {
      name: 'translate',

      categories: ['action'],
      tags: ['language', 'speaking', 'speech', 'translate', 'translator', 'words'],
    },
    {
      name: 'travel_explore',

      categories: ['social'],
      tags: [
        'earth',
        'explore',
        'find',
        'glass',
        'global',
        'globe',
        'look',
        'magnify',
        'magnifying',
        'map',
        'network',
        'planet',
        'search',
        'see',
        'social',
        'space',
        'travel',
        'web',
        'world',
      ],
    },
    {
      name: 'trending_down',

      categories: ['action'],
      tags: [
        'analytics',
        'arrow',
        'data',
        'diagram',
        'down',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'movement',
        'rate',
        'rating',
        'statistics',
        'tracking',
        'trending',
      ],
    },
    {
      name: 'trending_flat',

      categories: ['action'],
      tags: ['arrow', 'change', 'data', 'flat', 'metric', 'movement', 'rate', 'right', 'track', 'tracking', 'trending'],
    },
    {
      name: 'trending_up',

      categories: ['action'],
      tags: [
        'analytics',
        'arrow',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'movement',
        'rate',
        'rating',
        'statistics',
        'tracking',
        'trending',
        'up',
      ],
    },
    {
      name: 'trip_origin',

      categories: ['maps'],
      tags: ['circle', 'departure', 'origin', 'trip'],
    },
    {
      name: 'troubleshoot',

      categories: ['action'],
      tags: [
        'analytics',
        'chart',
        'data',
        'diagram',
        'find',
        'glass',
        'graph',
        'infographic',
        'line',
        'look',
        'magnify',
        'magnifying',
        'measure',
        'metrics',
        'search',
        'see',
        'statistics',
        'tracking',
        'troubleshoot',
      ],
    },
    {
      name: 'try',

      categories: ['action'],
      tags: [
        'bookmark',
        'bubble',
        'chat',
        'comment',
        'communicate',
        'favorite',
        'feedback',
        'highlight',
        'important',
        'marked',
        'message',
        'save',
        'saved',
        'shape',
        'special',
        'speech',
        'star',
        'try',
      ],
    },
    {
      name: 'tsunami',

      categories: ['social'],
      tags: ['crisis', 'disaster', 'flood', 'rain', 'storm', 'tsunami', 'weather'],
    },
    {
      name: 'tty',

      categories: ['places'],
      tags: [
        'call',
        'cell',
        'contact',
        'deaf',
        'device',
        'hardware',
        'impaired',
        'mobile',
        'phone',
        'speech',
        'talk',
        'telephone',
        'text',
        'tty',
      ],
    },
    {
      name: 'tune',

      categories: ['image'],
      tags: [
        'adjust',
        'audio',
        'controls',
        'custom',
        'customize',
        'edit',
        'editing',
        'filter',
        'filters',
        'instant',
        'mix',
        'music',
        'options',
        'setting',
        'settings',
        'slider',
        'sliders',
        'switches',
        'tune',
      ],
    },
    {
      name: 'tungsten',

      categories: ['device'],
      tags: ['electricity', 'indoor', 'lamp', 'light', 'lightbulb', 'setting', 'tungsten'],
    },
    {
      name: 'turn_left',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'left',
        'maps',
        'navigation',
        'path',
        'route',
        'sign',
        'traffic',
        'turn',
      ],
    },
    {
      name: 'turn_right',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'navigation',
        'path',
        'right',
        'route',
        'sign',
        'traffic',
        'turn',
      ],
    },
    {
      name: 'turn_sharp_left',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'left',
        'maps',
        'navigation',
        'path',
        'route',
        'sharp',
        'sign',
        'traffic',
        'turn',
      ],
    },
    {
      name: 'turn_sharp_right',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'navigation',
        'path',
        'right',
        'route',
        'sharp',
        'sign',
        'traffic',
        'turn',
      ],
    },
    {
      name: 'turn_slight_left',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'navigation',
        'path',
        'right',
        'route',
        'sign',
        'slight',
        'traffic',
        'turn',
      ],
    },
    {
      name: 'turn_slight_right',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'navigation',
        'path',
        'right',
        'route',
        'sharp',
        'sign',
        'slight',
        'traffic',
        'turn',
      ],
    },
    {
      name: 'turned_in',

      categories: ['action'],
      tags: [
        'archive',
        'bookmark',
        'favorite',
        'in',
        'label',
        'library',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'tag',
        'turned',
      ],
    },
    {
      name: 'turned_in_not',

      categories: ['action'],
      tags: [
        'archive',
        'bookmark',
        'favorite',
        'in',
        'label',
        'library',
        'not',
        'read',
        'reading',
        'remember',
        'ribbon',
        'save',
        'tag',
        'turned',
      ],
    },
    {
      name: 'tv',

      categories: ['hardware'],
      tags: ['device', 'display', 'monitor', 'screen', 'screencast', 'stream', 'television', 'tv', 'video', 'wireless'],
    },
    {
      name: 'tv_off',

      categories: ['notification'],
      tags: [
        'Android',
        'OS',
        'chrome',
        'desktop',
        'device',
        'disabled',
        'enabled',
        'hardware',
        'iOS',
        'mac',
        'monitor',
        'off',
        'on',
        'slash',
        'television',
        'tv',
        'web',
        'window',
      ],
    },
    {
      name: 'two_wheeler',

      categories: ['maps'],
      tags: [
        'automobile',
        'bike',
        'car',
        'cars',
        'direction',
        'maps',
        'motorcycle',
        'public',
        'scooter',
        'sport',
        'transportation',
        'travel',
        'two wheeler',
        'vehicle',
      ],
    },
    {
      name: 'type_specimen',

      categories: ['editor'],
      tags: [],
    },
    {
      name: 'u_turn_left',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'left',
        'maps',
        'navigation',
        'path',
        'route',
        'sign',
        'traffic',
        'u-turn',
      ],
    },
    {
      name: 'u_turn_right',

      categories: ['maps'],
      tags: [
        'arrow',
        'arrows',
        'direction',
        'directions',
        'maps',
        'navigation',
        'path',
        'right',
        'route',
        'sign',
        'traffic',
        'u-turn',
      ],
    },
    {
      name: 'umbrella',

      categories: ['places'],
      tags: ['beach', 'protection', 'rain', 'sun', 'sunny', 'umbrella'],
    },
    {
      name: 'unarchive',

      categories: ['content'],
      tags: ['archive', 'arrow', 'inbox', 'mail', 'store', 'unarchive', 'undo', 'up'],
    },
    {
      name: 'undo',

      categories: ['content'],
      tags: ['arrow', 'backward', 'mail', 'previous', 'redo', 'repeat', 'rotate', 'undo'],
    },
    {
      name: 'unfold_less',

      categories: ['navigation'],
      tags: [
        'arrow',
        'arrows',
        'chevron',
        'collapse',
        'direction',
        'expand',
        'expandable',
        'inward',
        'less',
        'list',
        'navigation',
        'unfold',
        'up',
      ],
    },
    {
      name: 'unfold_less_double',

      categories: ['action'],
      tags: [
        'arrow',
        'arrows',
        'chevron',
        'collapse',
        'direction',
        'double',
        'expand',
        'expandable',
        'inward',
        'less',
        'list',
        'navigation',
        'unfold',
        'up',
      ],
    },
    {
      name: 'unfold_more',

      categories: ['navigation'],
      tags: [
        'arrow',
        'arrows',
        'chevron',
        'collapse',
        'direction',
        'down',
        'expand',
        'expandable',
        'list',
        'more',
        'navigation',
        'unfold',
      ],
    },
    {
      name: 'unfold_more_double',

      categories: ['action'],
      tags: [
        'arrow',
        'arrows',
        'chevron',
        'collapse',
        'direction',
        'double',
        'down',
        'expand',
        'expandable',
        'list',
        'more',
        'navigation',
        'unfold',
      ],
    },
    {
      name: 'unpublished',

      categories: ['action'],
      tags: [
        'approve',
        'check',
        'circle',
        'complete',
        'disabled',
        'done',
        'enabled',
        'mark',
        'off',
        'ok',
        'on',
        'select',
        'slash',
        'tick',
        'unpublished',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'unsubscribe',

      categories: ['communication'],
      tags: [
        'cancel',
        'close',
        'email',
        'envelop',
        'letter',
        'mail',
        'message',
        'newsletter',
        'off',
        'remove',
        'send',
        'subscribe',
        'unsubscribe',
      ],
    },
    {
      name: 'upcoming',

      categories: ['content'],
      tags: ['alarm', 'calendar', 'mail', 'message', 'notification', 'upcoming'],
    },
    {
      name: 'update',

      categories: ['action'],
      tags: [
        'arrow',
        'back',
        'backwards',
        'clock',
        'forward',
        'history',
        'load',
        'refresh',
        'reverse',
        'schedule',
        'time',
        'update',
      ],
    },
    {
      name: 'update_disabled',

      categories: ['action'],
      tags: [
        'arrow',
        'back',
        'backwards',
        'clock',
        'date',
        'disabled',
        'enabled',
        'forward',
        'history',
        'load',
        'off',
        'on',
        'refresh',
        'reverse',
        'rotate',
        'schedule',
        'slash',
        'time',
        'update',
      ],
    },
    {
      name: 'upgrade',

      categories: ['action'],
      tags: ['arrow', 'export', 'instal', 'line', 'replace', 'up', 'update', 'upgrade'],
    },
    {
      name: 'upload',

      categories: ['file'],
      tags: ['arrow', 'arrows', 'download', 'drive', 'up', 'upload'],
    },
    {
      name: 'upload_file',

      categories: ['file'],
      tags: [
        'arrow',
        'data',
        'doc',
        'document',
        'download',
        'drive',
        'file',
        'folder',
        'folders',
        'page',
        'paper',
        'sheet',
        'slide',
        'up',
        'upload',
        'writing',
      ],
    },
    {
      name: 'usb',

      categories: ['device'],
      tags: ['cable', 'connection', 'device', 'usb', 'wire'],
    },
    {
      name: 'usb_off',

      categories: ['device'],
      tags: ['cable', 'connection', 'device', 'off', 'usb', 'wire'],
    },
    {
      name: 'vaccines',

      categories: ['social'],
      tags: [
        'aid',
        'covid',
        'doctor',
        'drug',
        'emergency',
        'hospital',
        'immunity',
        'injection',
        'medical',
        'medication',
        'medicine',
        'needle',
        'pharmacy',
        'sick',
        'syringe',
        'vaccination',
        'vaccines',
        'vial',
      ],
    },
    {
      name: 'vape_free',

      categories: ['places'],
      tags: [
        'disabled',
        'e-cigarette',
        'enabled',
        'free',
        'never',
        'no',
        'off',
        'on',
        'places',
        'prohibited',
        'slash',
        'smoke',
        'smoking',
        'tobacco',
        'vape',
        'vaping',
        'vapor',
        'warning',
        'zone',
      ],
    },
    {
      name: 'vaping_rooms',

      categories: ['places'],
      tags: [
        'allowed',
        'e-cigarette',
        'never',
        'no',
        'places',
        'prohibited',
        'smoke',
        'smoking',
        'tobacco',
        'vape',
        'vaping',
        'vapor',
        'warning',
        'zone',
      ],
    },
    {
      name: 'verified',

      categories: ['action'],
      tags: [
        'approve',
        'badge',
        'burst',
        'check',
        'complete',
        'done',
        'mark',
        'ok',
        'select',
        'star',
        'tick',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'verified_user',

      categories: ['action'],
      tags: [
        'approve',
        'certified',
        'check',
        'complete',
        'done',
        'mark',
        'ok',
        'privacy',
        'private',
        'protect',
        'protection',
        'security',
        'select',
        'shield',
        'tick',
        'user',
        'validate',
        'verified',
        'yes',
      ],
    },
    {
      name: 'vertical_align_bottom',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'arrow',
        'bottom',
        'doc',
        'down',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'vertical',
        'writing',
      ],
    },
    {
      name: 'vertical_align_center',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'arrow',
        'center',
        'doc',
        'down',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'up',
        'vertical',
        'writing',
      ],
    },
    {
      name: 'vertical_align_top',

      categories: ['editor'],
      tags: [
        'align',
        'alignment',
        'arrow',
        'doc',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'text',
        'top',
        'type',
        'up',
        'vertical',
        'writing',
      ],
    },
    {
      name: 'vertical_distribute',

      categories: ['editor'],
      tags: [
        'alignment',
        'distribute',
        'format',
        'layout',
        'lines',
        'paragraph',
        'rule',
        'rules',
        'style',
        'text',
        'vertical',
      ],
    },
    {
      name: 'vertical_shades',

      categories: ['home'],
      tags: ['blinds', 'cover', 'curtains', 'nest', 'open', 'shade', 'shutter', 'sunshade', 'vertical'],
    },
    {
      name: 'vertical_shades_closed',

      categories: ['home'],
      tags: ['blinds', 'closed', 'cover', 'curtains', 'nest', 'roller', 'shade', 'shutter', 'sunshade'],
    },
    {
      name: 'vertical_split',

      categories: ['action'],
      tags: ['design', 'format', 'grid', 'layout', 'paragraph', 'split', 'text', 'vertical', 'website', 'writing'],
    },
    {
      name: 'vibration',

      categories: ['notification'],
      tags: [
        'Android',
        'OS',
        'alert',
        'cell',
        'device',
        'hardware',
        'iOS',
        'mobile',
        'mode',
        'motion',
        'notification',
        'phone',
        'silence',
        'silent',
        'tablet',
        'vibrate',
        'vibration',
      ],
    },
    {
      name: 'video_call',

      categories: ['av'],
      tags: [
        '+',
        'add',
        'call',
        'camera',
        'chat',
        'conference',
        'film',
        'filming',
        'hardware',
        'image',
        'motion',
        'new',
        'picture',
        'plus',
        'symbol',
        'video',
        'videography',
      ],
    },
    {
      name: 'video_camera_back',

      categories: ['image'],
      tags: [
        'back',
        'camera',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'photo',
        'photography',
        'picture',
        'rear',
        'video',
      ],
    },
    {
      name: 'video_camera_front',

      categories: ['image'],
      tags: [
        'account',
        'camera',
        'face',
        'front',
        'human',
        'image',
        'people',
        'person',
        'photo',
        'photography',
        'picture',
        'profile',
        'user',
        'video',
      ],
    },
    {
      name: 'video_chat',

      categories: ['notification'],
      tags: [
        'bubble',
        'cam',
        'camera',
        'chat',
        'comment',
        'communicate',
        'facetime',
        'feedback',
        'message',
        'speech',
        'video',
        'voice',
      ],
    },
    {
      name: 'video_file',

      categories: ['av'],
      tags: [
        'camera',
        'doc',
        'document',
        'film',
        'filming',
        'hardware',
        'image',
        'motion',
        'picture',
        'video',
        'videography',
      ],
    },
    {
      name: 'video_label',

      categories: ['av'],
      tags: ['label', 'screen', 'video', 'window'],
    },
    {
      name: 'video_library',

      categories: ['av'],
      tags: ['arrow', 'collection', 'library', 'play', 'video'],
    },
    {
      name: 'video_settings',

      categories: ['av'],
      tags: [
        'change',
        'details',
        'gear',
        'info',
        'information',
        'options',
        'play',
        'screen',
        'service',
        'setting',
        'settings',
        'video',
        'window',
      ],
    },
    {
      name: 'video_stable',

      categories: ['image'],
      tags: ['film', 'filming', 'recording', 'setting', 'stability', 'stable', 'taping', 'video'],
    },
    {
      name: 'videocam',

      categories: ['av'],
      tags: [
        'cam',
        'camera',
        'conference',
        'film',
        'filming',
        'hardware',
        'image',
        'motion',
        'picture',
        'video',
        'videography',
      ],
    },
    {
      name: 'videocam_off',

      categories: ['av'],
      tags: [
        'cam',
        'camera',
        'conference',
        'disabled',
        'enabled',
        'film',
        'filming',
        'hardware',
        'image',
        'motion',
        'off',
        'offline',
        'on',
        'picture',
        'slash',
        'video',
        'videography',
      ],
    },
    {
      name: 'videogame_asset',

      categories: ['hardware'],
      tags: ['asset', 'console', 'controller', 'device', 'game', 'gamepad', 'gaming', 'playstation', 'video'],
    },
    {
      name: 'videogame_asset_off',

      categories: ['hardware'],
      tags: [
        'asset',
        'console',
        'controller',
        'device',
        'disabled',
        'enabled',
        'game',
        'gamepad',
        'gaming',
        'off',
        'on',
        'playstation',
        'slash',
        'video',
        'videogame',
      ],
    },
    {
      name: 'view_agenda',

      categories: ['action'],
      tags: ['agenda', 'cards', 'design', 'format', 'grid', 'layout', 'stacked', 'view', 'website'],
    },
    {
      name: 'view_array',

      categories: ['action'],
      tags: ['array', 'design', 'format', 'grid', 'layout', 'view', 'website'],
    },
    {
      name: 'view_carousel',

      categories: ['action'],
      tags: ['cards', 'carousel', 'design', 'format', 'grid', 'layout', 'view', 'website'],
    },
    {
      name: 'view_column',

      categories: ['action'],
      tags: ['column', 'design', 'format', 'grid', 'layout', 'vertical', 'view', 'website'],
    },
    {
      name: 'view_comfy',

      categories: ['image'],
      tags: ['comfy', 'grid', 'layout', 'pattern', 'squares', 'view'],
    },
    {
      name: 'view_comfy_alt',

      categories: ['action'],
      tags: ['alt', 'comfy', 'cozy', 'design', 'format', 'layout', 'view', 'web'],
    },
    {
      name: 'view_compact',

      categories: ['image'],
      tags: ['compact', 'grid', 'layout', 'pattern', 'squares', 'view'],
    },
    {
      name: 'view_compact_alt',

      categories: ['action'],
      tags: ['alt', 'compact', 'design', 'format', 'layout dense', 'view', 'web'],
    },
    {
      name: 'view_cozy',

      categories: ['action'],
      tags: ['comfy', 'cozy', 'design', 'format', 'layout', 'view', 'web'],
    },
    {
      name: 'view_day',

      categories: ['action'],
      tags: ['cards', 'carousel', 'day', 'design', 'format', 'grid', 'layout', 'view', 'website'],
    },
    {
      name: 'view_headline',

      categories: ['action'],
      tags: ['design', 'format', 'grid', 'headline', 'layout', 'paragraph', 'text', 'view', 'website'],
    },
    {
      name: 'view_in_ar',

      categories: ['action'],
      tags: ['3d', 'ar', 'augmented', 'cube', 'daydream', 'headset', 'in', 'reality', 'square', 'view', 'vr'],
    },
    {
      name: 'view_kanban',

      categories: ['action'],
      tags: ['grid', 'kanban', 'layout', 'pattern', 'squares', 'view'],
    },
    {
      name: 'view_list',

      categories: ['action'],
      tags: ['design', 'format', 'grid', 'layout', 'lines', 'list', 'stacked', 'view', 'website'],
    },
    {
      name: 'view_module',

      categories: ['action'],
      tags: ['design', 'format', 'grid', 'layout', 'module', 'square', 'squares', 'stacked', 'view', 'website'],
    },
    {
      name: 'view_quilt',

      categories: ['action'],
      tags: ['design', 'format', 'grid', 'layout', 'quilt', 'square', 'squares', 'stacked', 'view', 'website'],
    },
    {
      name: 'view_sidebar',

      categories: ['action'],
      tags: ['design', 'format', 'grid', 'layout', 'sidebar', 'view', 'web'],
    },
    {
      name: 'view_stream',

      categories: ['action'],
      tags: ['design', 'format', 'grid', 'layout', 'lines', 'list', 'stacked', 'stream', 'view', 'website'],
    },
    {
      name: 'view_timeline',

      categories: ['action'],
      tags: ['grid', 'layout', 'pattern', 'squares', 'timeline', 'view'],
    },
    {
      name: 'view_week',

      categories: ['action'],
      tags: ['bars', 'columns', 'design', 'format', 'grid', 'layout', 'view', 'website', 'week'],
    },
    {
      name: 'vignette',

      categories: ['image'],
      tags: ['border', 'edit', 'editing', 'filter', 'gradient', 'image', 'photo', 'photography', 'setting', 'vignette'],
    },
    {
      name: 'villa',

      categories: ['places'],
      tags: [
        'architecture',
        'beach',
        'estate',
        'home',
        'house',
        'maps',
        'place',
        'real',
        'residence',
        'residential',
        'traveling',
        'vacation stay',
        'villa',
      ],
    },
    {
      name: 'visibility',

      categories: ['action'],
      tags: ['eye', 'on', 'reveal', 'see', 'show', 'view', 'visability'],
    },
    {
      name: 'visibility_off',

      categories: ['action'],
      tags: ['disabled', 'enabled', 'eye', 'off', 'on', 'reveal', 'see', 'show', 'slash', 'view', 'visability'],
    },
    {
      name: 'voice_chat',

      categories: ['notification'],
      tags: [
        'bubble',
        'cam',
        'camera',
        'chat',
        'comment',
        'communicate',
        'facetime',
        'feedback',
        'message',
        'speech',
        'video',
        'voice',
      ],
    },
    {
      name: 'voice_over_off',

      categories: ['action'],
      tags: [
        'account',
        'disabled',
        'enabled',
        'face',
        'human',
        'off',
        'on',
        'over',
        'people',
        'person',
        'profile',
        'recording',
        'slash',
        'speak',
        'speaking',
        'speech',
        'transcript',
        'user',
        'voice',
      ],
    },
    {
      name: 'voicemail',

      categories: ['communication'],
      tags: ['call', 'device', 'message', 'missed', 'mobile', 'phone', 'recording', 'voice', 'voicemail'],
    },
    {
      name: 'volcano',

      categories: ['social'],
      tags: ['crisis', 'disaster', 'eruption', 'lava', 'magma', 'natural', 'volcano'],
    },
    {
      name: 'volume_down',

      categories: ['av'],
      tags: ['audio', 'control', 'down', 'music', 'sound', 'speaker', 'tv', 'volume'],
    },
    {
      name: 'volume_mute',

      categories: ['av'],
      tags: ['audio', 'control', 'music', 'mute', 'sound', 'speaker', 'tv', 'volume'],
    },
    {
      name: 'volume_off',

      categories: ['av'],
      tags: [
        'audio',
        'control',
        'disabled',
        'enabled',
        'low',
        'music',
        'off',
        'on',
        'slash',
        'sound',
        'speaker',
        'tv',
        'volume',
      ],
    },
    {
      name: 'volume_up',

      categories: ['av'],
      tags: ['audio', 'control', 'music', 'sound', 'speaker', 'tv', 'up', 'volume'],
    },
    {
      name: 'volunteer_activism',

      categories: ['maps'],
      tags: [
        'activism',
        'donation',
        'fingers',
        'gesture',
        'giving',
        'hand',
        'hands',
        'heart',
        'love',
        'sharing',
        'volunteer',
      ],
    },
    {
      name: 'vpn_key',

      categories: ['communication'],
      tags: ['code', 'key', 'lock', 'network', 'passcode', 'password', 'unlock', 'vpn'],
    },
    {
      name: 'vpn_key_off',

      categories: ['communication'],
      tags: [
        '[offline]',
        'code',
        'disabled',
        'enabled',
        'key',
        'lock',
        'network',
        'off',
        'on',
        'passcode',
        'password',
        'slash',
        'unlock',
        'vpn',
      ],
    },
    {
      name: 'vpn_lock',

      categories: ['notification'],
      tags: [
        'earth',
        'globe',
        'lock',
        'locked',
        'network',
        'password',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
        'virtual',
        'vpn',
        'world',
      ],
    },
    {
      name: 'vrpano',

      categories: ['image'],
      tags: [
        'angle',
        'image',
        'landscape',
        'mountain',
        'mountains',
        'panorama',
        'photo',
        'photography',
        'picture',
        'view',
        'vrpano',
        'wide',
      ],
    },
    {
      name: 'wallet',

      categories: ['social'],
      tags: [],
    },
    {
      name: 'wallpaper',

      categories: ['device'],
      tags: ['background', 'image', 'landscape', 'photo', 'photography', 'picture', 'wallpaper'],
    },
    {
      name: 'warehouse',

      categories: ['maps'],
      tags: ['garage', 'industry', 'manufacturing', 'storage', 'warehouse'],
    },
    {
      name: 'warning',

      categories: ['alert'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'notification',
        'symbol',
        'triangle',
        'warning',
      ],
    },
    {
      name: 'warning_amber',

      categories: ['alert'],
      tags: [
        '!',
        'alert',
        'amber',
        'attention',
        'caution',
        'danger',
        'error',
        'exclamation',
        'important',
        'mark',
        'notification',
        'symbol',
        'triangle',
        'warning',
      ],
    },
    {
      name: 'wash',

      categories: ['places'],
      tags: ['bathroom', 'clean', 'fingers', 'gesture', 'hand', 'wash', 'wc'],
    },
    {
      name: 'watch',

      categories: ['hardware'],
      tags: ['Android', 'OS', 'ar', 'clock', 'gadget', 'iOS', 'time', 'vr', 'watch', 'wearables', 'web', 'wristwatch'],
    },
    {
      name: 'watch_later',

      categories: ['action'],
      tags: ['clock', 'date', 'later', 'schedule', 'time', 'watch'],
    },
    {
      name: 'watch_off',

      categories: ['hardware'],
      tags: [
        'Android',
        'OS',
        'ar',
        'clock',
        'close',
        'gadget',
        'iOS',
        'off',
        'shut',
        'time',
        'vr',
        'watch',
        'wearables',
        'web',
        'wristwatch',
      ],
    },
    {
      name: 'water',

      categories: ['device'],
      tags: ['aqua', 'beach', 'lake', 'ocean', 'river', 'water', 'waves', 'weather'],
    },
    {
      name: 'water_damage',

      categories: ['places'],
      tags: [
        'architecture',
        'building',
        'damage',
        'drop',
        'droplet',
        'estate',
        'house',
        'leak',
        'plumbing',
        'real',
        'residence',
        'residential',
        'shelter',
        'water',
      ],
    },
    {
      name: 'water_drop',

      categories: ['social'],
      tags: ['drink', 'drop', 'droplet', 'eco', 'liquid', 'nature', 'ocean', 'rain', 'social', 'water'],
    },
    {
      name: 'waterfall_chart',

      categories: ['navigation'],
      tags: [
        'analytics',
        'bar',
        'chart',
        'data',
        'diagram',
        'graph',
        'infographic',
        'measure',
        'metrics',
        'statistics',
        'tracking',
        'waterfall',
      ],
    },
    {
      name: 'waves',

      categories: ['content'],
      tags: ['beach', 'lake', 'ocean', 'pool', 'river', 'sea', 'swim', 'water', 'wave', 'waves'],
    },
    {
      name: 'waving_hand',

      categories: ['social'],
      tags: ['bye', 'fingers', 'gesture', 'goodbye', 'greetings', 'hand', 'hello', 'palm', 'wave', 'waving'],
    },
    {
      name: 'wb_auto',

      categories: ['image'],
      tags: [
        'A',
        'W',
        'alphabet',
        'auto',
        'automatic',
        'balance',
        'character',
        'edit',
        'editing',
        'font',
        'image',
        'letter',
        'photo',
        'photography',
        'symbol',
        'text',
        'type',
        'white',
        'wp',
      ],
    },
    {
      name: 'wb_cloudy',

      categories: ['image'],
      tags: ['balance', 'cloud', 'cloudy', 'edit', 'editing', 'white', 'wp'],
    },
    {
      name: 'wb_incandescent',

      categories: ['image'],
      tags: [
        'balance',
        'bright',
        'edit',
        'editing',
        'incandescent',
        'light',
        'lighting',
        'setting',
        'settings',
        'white',
        'wp',
      ],
    },
    {
      name: 'wb_iridescent',

      categories: ['image'],
      tags: [
        'balance',
        'bright',
        'edit',
        'editing',
        'iridescent',
        'light',
        'lighting',
        'setting',
        'settings',
        'white',
        'wp',
      ],
    },
    {
      name: 'wb_shade',

      categories: ['image'],
      tags: ['balance', 'house', 'light', 'lighting', 'shade', 'wb', 'white'],
    },
    {
      name: 'wb_sunny',

      categories: ['image'],
      tags: ['balance', 'bright', 'light', 'lighting', 'sun', 'sunny', 'wb', 'white'],
    },
    {
      name: 'wb_twilight',

      categories: ['image'],
      tags: ['balance', 'light', 'lighting', 'noon', 'sun', 'sunset', 'twilight', 'wb', 'white'],
    },
    {
      name: 'wc',

      categories: ['notification'],
      tags: ['bathroom', 'closet', 'female', 'male', 'man', 'restroom', 'room', 'wash', 'water', 'wc', 'women'],
    },
    {
      name: 'web',

      categories: ['av'],
      tags: ['browser', 'internet', 'page', 'screen', 'site', 'web', 'website', 'www'],
    },
    {
      name: 'web_asset',

      categories: ['av'],
      tags: [
        '-website',
        'app',
        'application desktop',
        'asset',
        'browser',
        'design',
        'download',
        'image',
        'interface',
        'internet',
        'layout',
        'screen',
        'site',
        'ui',
        'ux',
        'video',
        'web',
        'website',
        'window',
        'www',
      ],
    },
    {
      name: 'web_asset_off',

      categories: ['av'],
      tags: [
        'asset',
        'browser',
        'disabled',
        'enabled',
        'internet',
        'off',
        'on',
        'page',
        'screen',
        'slash',
        'web',
        'webpage',
        'website',
        'windows',
        'www',
      ],
    },
    {
      name: 'web_stories',

      categories: ['content'],
      tags: ['google', 'images', 'logo', 'stories', 'web'],
    },
    {
      name: 'webhook',

      categories: ['action'],
      tags: ['api', 'developer', 'development', 'enterprise', 'software', 'webhook'],
    },
    {
      name: 'weekend',

      categories: ['content'],
      tags: ['chair', 'couch', 'furniture', 'home', 'living', 'lounge', 'relax', 'room', 'weekend'],
    },
    {
      name: 'west',

      categories: ['navigation'],
      tags: ['arrow', 'directional', 'left', 'maps', 'navigation', 'west'],
    },
    {
      name: 'whatsapp',

      categories: ['social'],
      tags: ['calls', 'chat', 'messaging', 'messenger', 'platform', 'video', 'voice', 'whatsapp'],
    },
    {
      name: 'whatshot',

      categories: ['social'],
      tags: ['arrow', 'circle', 'direction', 'fire', 'frames', 'hot', 'round', 'whatshot'],
    },
    {
      name: 'wheelchair_pickup',

      categories: ['places'],
      tags: ['accessibility', 'accessible', 'body', 'handicap', 'help', 'human', 'person', 'pickup', 'wheelchair'],
    },
    {
      name: 'where_to_vote',

      categories: ['content'],
      tags: [
        'approve',
        'ballot',
        'check',
        'complete',
        'destination',
        'direction',
        'done',
        'location',
        'maps',
        'mark',
        'ok',
        'pin',
        'place',
        'poll',
        'select',
        'stop',
        'tick',
        'to',
        'validate election',
        'verified',
        'vote',
        'where',
        'yes',
      ],
    },
    {
      name: 'widgets',

      categories: ['device'],
      tags: ['app', 'box', 'menu', 'setting', 'squares', 'ui', 'widgets'],
    },
    {
      name: 'width_full',

      categories: ['action'],
      tags: [],
    },
    {
      name: 'width_normal',

      categories: ['action'],
      tags: [],
    },
    {
      name: 'width_wide',

      categories: ['action'],
      tags: [],
    },
    {
      name: 'wifi',

      categories: ['notification'],
      tags: ['connection', 'data', 'internet', 'network', 'scan', 'service', 'signal', 'wifi', 'wireless'],
    },
    {
      name: 'wifi_1_bar',

      categories: ['device'],
      tags: [
        '1',
        'bar',
        'cell',
        'cellular',
        'connection',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'scan',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_2_bar',

      categories: ['device'],
      tags: [
        '2',
        'bar',
        'cell',
        'cellular',
        'connection',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'scan',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_calling',

      categories: ['communication'],
      tags: [
        'call',
        'calling',
        'cell',
        'connect',
        'connection',
        'connectivity',
        'contact',
        'device',
        'hardware',
        'mobile',
        'phone',
        'signal',
        'telephone',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_calling_3',

      categories: ['device'],
      tags: [
        '3',
        'calling',
        'cell',
        'cellular',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'speed',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_channel',

      categories: ['device'],
      tags: [
        '(scan)',
        '[cellular',
        'channel',
        'connection',
        'data',
        'internet',
        'mobile]',
        'network',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_find',

      categories: ['device'],
      tags: [
        '(scan)',
        '[cellular',
        'connection',
        'data',
        'detect',
        'discover',
        'find',
        'internet',
        'look',
        'magnifying glass',
        'mobile]',
        'network',
        'notice',
        'search',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_lock',

      categories: ['device'],
      tags: [
        'cellular',
        'connection',
        'data',
        'internet',
        'lock',
        'locked',
        'mobile',
        'network',
        'password',
        'privacy',
        'private',
        'protection',
        'safety',
        'secure',
        'security',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_off',

      categories: ['notification'],
      tags: [
        'connection',
        'data',
        'disabled',
        'enabled',
        'internet',
        'network',
        'off',
        'offline',
        'on',
        'scan',
        'service',
        'signal',
        'slash',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_password',

      categories: ['device'],
      tags: [
        '(scan)',
        '[cellular',
        'connection',
        'data',
        'internet',
        'lock',
        'mobile]',
        'network',
        'password',
        'secure',
        'service',
        'signal',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_protected_setup',

      categories: ['action'],
      tags: ['around', 'arrow', 'arrows', 'protected', 'rotate', 'setup', 'wifi'],
    },
    {
      name: 'wifi_tethering',

      categories: ['device'],
      tags: [
        'cell',
        'cellular',
        'connection',
        'data',
        'internet',
        'mobile',
        'network',
        'phone',
        'scan',
        'service',
        'signal',
        'speed',
        'tethering',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_tethering_error',

      categories: ['device'],
      tags: [
        '!',
        'alert',
        'attention',
        'caution',
        'cell',
        'cellular',
        'connection',
        'danger',
        'data',
        'error',
        'exclamation',
        'important',
        'internet',
        'mark',
        'mobile',
        'network',
        'notification',
        'phone',
        'rounded',
        'scan',
        'service',
        'signal',
        'speed',
        'symbol',
        'tethering',
        'warning',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wifi_tethering_off',

      categories: ['device'],
      tags: [
        'cell',
        'cellular',
        'connection',
        'data',
        'disabled',
        'enabled',
        'internet',
        'mobile',
        'network',
        'off',
        'offline',
        'on',
        'phone',
        'scan',
        'service',
        'signal',
        'slash',
        'speed',
        'tethering',
        'wifi',
        'wireless',
      ],
    },
    {
      name: 'wind_power',

      categories: ['home'],
      tags: ['eco', 'energy', 'nest', 'power', 'wind', 'windy'],
    },
    {
      name: 'window',

      categories: ['search'],
      tags: ['close', 'glass', 'grid', 'home', 'house', 'interior', 'layout', 'outside', 'window'],
    },
    {
      name: 'wine_bar',

      categories: ['maps'],
      tags: ['alcohol', 'bar', 'cocktail', 'cup', 'drink', 'glass', 'liquor', 'wine'],
    },
    {
      name: 'woman',

      categories: ['social'],
      tags: ['female', 'gender', 'girl', 'lady', 'social', 'symbol', 'woman', 'women'],
    },
    {
      name: 'woman_2',

      categories: ['social'],
      tags: ['female', 'gender', 'girl', 'lady', 'social', 'symbol', 'woman', 'women'],
    },
    {
      name: 'work',

      categories: ['action'],
      tags: ['bag', 'baggage', 'briefcase', 'business', 'case', 'job', 'suitcase', 'work'],
    },
    {
      name: 'work_history',

      categories: ['action'],
      tags: [
        'arrow',
        'back',
        'backwards',
        'bag',
        'baggage',
        'briefcase',
        'business',
        'case',
        'clock',
        'date',
        'history',
        'job',
        'refresh',
        'renew',
        'reverse',
        'rotate',
        'schedule',
        'suitcase',
        'time',
        'turn',
        'work',
      ],
    },
    {
      name: 'work_off',

      categories: ['action'],
      tags: [
        'bag',
        'baggage',
        'briefcase',
        'business',
        'case',
        'disabled',
        'enabled',
        'job',
        'off',
        'on',
        'slash',
        'suitcase',
        'work',
      ],
    },
    {
      name: 'work_outline',

      categories: ['action'],
      tags: ['bag', 'baggage', 'briefcase', 'business', 'case', 'job', 'suitcase', 'work'],
    },
    {
      name: 'workspace_premium',

      categories: ['social'],
      tags: [
        'certification',
        'degree',
        'ecommerce',
        'guarantee',
        'medal',
        'permit',
        'premium',
        'ribbon',
        'verification',
        'workspace',
      ],
    },
    {
      name: 'workspaces',

      categories: ['file'],
      tags: ['circles', 'collaboration', 'dot', 'filled', 'group', 'outline', 'space', 'team', 'work', 'workspaces'],
    },
    {
      name: 'wrap_text',

      categories: ['editor'],
      tags: [
        'arrow writing',
        'doc',
        'edit',
        'editing',
        'editor',
        'sheet',
        'spreadsheet',
        'text',
        'type',
        'wrap',
        'write',
        'writing',
      ],
    },
    {
      name: 'wrong_location',

      categories: ['maps'],
      tags: [
        'cancel',
        'close',
        'destination',
        'direction',
        'exit',
        'location',
        'maps',
        'no',
        'pin',
        'place',
        'quit',
        'remove',
        'stop',
        'wrong',
        'x',
      ],
    },
    {
      name: 'wysiwyg',

      categories: ['action'],
      tags: [
        'composer',
        'mode',
        'screen',
        'site',
        'software',
        'system',
        'text',
        'view',
        'visibility',
        'web',
        'website',
        'window',
        'wysiwyg',
      ],
    },
    {
      name: 'yard',

      categories: ['search'],
      tags: ['backyard', 'flower', 'garden', 'home', 'house', 'nature', 'pettle', 'plants', 'yard'],
    },
    {
      name: 'youtube_searched_for',

      categories: ['action'],
      tags: [
        'arrow',
        'back',
        'backwards',
        'find',
        'glass',
        'history',
        'inprogress',
        'load',
        'loading',
        'look',
        'magnify',
        'magnifying',
        'refresh',
        'renew',
        'restore',
        'reverse',
        'rotate',
        'search',
        'see',
        'yt',
      ],
    },
    {
      name: 'zoom_in',

      categories: ['action'],
      tags: [
        'big',
        'bigger',
        'find',
        'glass',
        'grow',
        'in',
        'look',
        'magnify',
        'magnifying',
        'plus',
        'scale',
        'search',
        'see',
        'size',
        'zoom',
      ],
    },
    {
      name: 'zoom_in_map',

      categories: ['maps'],
      tags: ['arrow', 'arrows', 'destination', 'in', 'location', 'maps', 'move', 'place', 'stop', 'zoom'],
    },
    {
      name: 'zoom_out',

      categories: ['action'],
      tags: [
        'find',
        'glass',
        'look',
        'magnify',
        'magnifying',
        'minus',
        'negative',
        'out',
        'scale',
        'search',
        'see',
        'size',
        'small',
        'smaller',
        'zoom',
      ],
    },
    {
      name: 'zoom_out_map',

      categories: ['maps'],
      tags: ['arrow', 'arrows', 'destination', 'location', 'maps', 'move', 'out', 'place', 'stop', 'zoom'],
    },
  ],
};
