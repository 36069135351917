<mat-form-field [appearance]="appearance">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-icon *ngIf="this.value" [svgIcon]="this.value?.alpha2Code?.toLowerCase()" matSuffix></mat-icon>
  <input
    #countryInput="matInput"
    (blur)="onBlur(countryInput)"
    (input)="inputChanged($event?.target?.value)"
    [class]="class"
    [matAutocomplete]="this.countryAutocomplete"
    [placeholder]="this.placeHolder"
    [readonly]="this.readonly"
    [value]="this.value?.name"
    [required]="this.required"
    [disabled]="this.disabled || this.loadingDB"
    aria-label="country"
    matInput
    type="text"
    autocomplete="shipping country-name"
  />
  <mat-progress-bar *ngIf="this.loadingDB || this.loading" mode="buffer"></mat-progress-bar>
  <mat-autocomplete
    #countryAutocomplete="matAutocomplete"
    (opened)="autocompleteScroll()"
    (optionSelected)="onOptionsSelected($event)"
  >
    <mat-option *ngFor="let country of filteredOptions" [value]="country?.name">
      <mat-icon [svgIcon]="country?.alpha2Code?.toLowerCase()"></mat-icon>
      <span>{{ [country?.name, country?.alpha2Code] | join : ' - ' }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="showRequiredHint" style="color: var(--danger-color, red)">*Required</mat-hint>
</mat-form-field>
