import { Component, Input } from '@angular/core';
import { EventTemplate } from '@greco/booking-events';

@Component({
  selector: 'greco-event-template-page',
  templateUrl: './event-template.page.html',
  styleUrls: ['./event-template.page.scss'],
})
export class EventTemplatePage {
  @Input() eventTemplate!: EventTemplate;
}
