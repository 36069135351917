import { Component, Injectable, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { Promotion } from '@greco/promotions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CreatePromotionDialog } from '../../dialogs/create-promotion/create-promotion.dialog';
import { PromotionsService } from '../../services';

@Injectable({ providedIn: 'any' })
export class PromotionSearchFilter extends BuildSearchFilter('PromotionSearchFilter', {
  properties: ['title', 'subtitle'],
  propertyLabels: ['Title', 'Tag Line'],
}) {}

@Component({
  selector: 'greco-promotions-page',
  templateUrl: './promotions.page.html',
  styleUrls: ['./promotions.page.scss'],
})
export class PromotionsPage implements OnDestroy {
  constructor(
    private promotionSvc: PromotionsService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  private _communityId$ = new BehaviorSubject<string | null>(null);

  @Input() get communityId() {
    return this._communityId$.value;
  }

  set communityId(communityId: string | null) {
    this._communityId$.next(communityId);
  }

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });
  perks$ = combineLatest([this.filters$, this._communityId$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    filter(([_, c]) => !!c),
    switchMap(
      async ([filters, communityId, pagination]) =>
        await this.promotionSvc.paginatedCommunityPromotions(filters, communityId as any, pagination)
    ),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  filterOptions = [PromotionSearchFilter];
  loading = false;
  pagination: IPaginationMeta | null = null;

  // TODO(adaoust): Change to promotion security resource
  public canCreatePromotion$ = of(true);
  // public canCreatePromotion$ = this.securityService.hasAccess(
  //   PurchaseResource.key,
  //   PurchaseResourceAction.PURCHASE_AS_USER,
  //   {},
  //   true
  // );

  ngOnDestroy() {
    this.page$.complete();
    this.filters$.complete();
    this._communityId$.complete();
  }

  async createPromotion() {
    const dialog = this.dialog.open(CreatePromotionDialog, {
      data: { communityId: this.communityId },
      width: '750px',
      maxWidth: '90%',
    });
    const promotion = await toPromise(dialog.afterClosed());
    if (promotion?.id) await this.router.navigate([promotion.id], { relativeTo: this.route });
  }

  async openPromo(promo: Promotion) {
    await this.router.navigate([promo.id], { relativeTo: this.route });
  }
}
