import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SubscriptionResource, SubscriptionResourceAction } from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription-com-table',
  templateUrl: './subscription-com-table.component.html',
  styleUrls: ['./subscription-com-table.component.scss'],
})
export class SubscriptionComTableComponent implements OnDestroy {
  constructor(private subscriptionSvc: SubscriptionsService, private securitySvc: SecurityService) {}

  @Output() rowClick = new EventEmitter<any>();

  @Output() subscriptionsInformation = new EventEmitter<any>();
  @PropertyListener('variantIds') _variantIds$ = new BehaviorSubject<string[]>(['']);
  @Input() variantIds!: string[];

  @Input() communityId?: string;
  @PropertyListener('accountId')
  private _accountId$ = new BehaviorSubject<string | null>(null);
  @Input() accountId?: string;

  @PropertyListener('queryBuilder')
  private _queryBuilder$ = new BehaviorSubject(new RequestQueryBuilder());
  @Input() queryBuilder?: RequestQueryBuilder;

  @PropertyListener('actionQueryBuilder')
  private _actionQueryBuilder$ = new BehaviorSubject(new RequestQueryBuilder());
  @Input() actionQueryBuilder?: RequestQueryBuilder;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  canCancelSubscriptions$ = this._accountId$.pipe(
    switchMap(async accountId =>
      accountId
        ? await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.CANCEL, { accountId })
        : false
    ),
    shareReplay(1)
  );

  canUpdateSubscriptions$ = this._accountId$.pipe(
    switchMap(async accountId =>
      accountId
        ? await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.UPDATE, { accountId })
        : false
    ),
    shareReplay(1)
  );

  loading = true;
  currentPagination?: IPaginationMeta;
  paginatedParams$ = new BehaviorSubject<PaginatedQueryParams>({
    page: 1,
    limit: 10,
  });

  subscriptions$ = combineLatest([
    this._queryBuilder$,
    this._actionQueryBuilder$,
    this.paginatedParams$,
    this._accountId$,
    this._variantIds$,
  ]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    debounceTime(500),
    switchMap(async ([queryBuilder, actionQueryBuilder, params, accountId, variantIds]) =>
      accountId
        ? await this.subscriptionSvc.paginateSubscriptions(accountId, queryBuilder, actionQueryBuilder, params, {
            ...(variantIds && { variantIds }),
          })
        : null
    ),
    tap(data => setTimeout(() => (this.currentPagination = data?.meta))),
    tap(data => this.subscriptionsInformation.emit(data?.meta)),
    map(data => data?.items || []),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  refresh() {
    this.paginatedParams$.next(this.paginatedParams$.value);
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.paginatedParams$.complete();
    this._queryBuilder$.complete();
  }
}
