import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { EventResourceAssignment } from '@greco/booking-events';
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
  selector: 'greco-busy-resources',
  templateUrl: './busy-resources.dialog.html',
  styleUrls: ['./busy-resources.dialog.scss'],
})
export class BusyResourcesDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      resourceAssignments: EventResourceAssignment[];
      canDoubleBook$: Observable<boolean>;
      title: string;
      content: string;
    },
    private dialogRef: MatDialogRef<BusyResourcesDialog>
  ) {}

  canDoubleBook$ = new BehaviorSubject<boolean>(false);

  dialogData: DialogData = {
    title: this.data.title ? this.data.title : 'Some Resources Are Busy',
    content: this.data.content ? this.data.content : 'The following resources are booked in other events at this time',
  };

  cancel() {
    this.dialogRef.close({ continue: false });
  }

  continue() {
    this.dialogRef.close({ continue: true });
  }

  async ngOnInit() {
    this.data.canDoubleBook$.subscribe(value => this.canDoubleBook$.next(value));
  }
}
