<ng-container *grecoLet="canRead$ | async as canRead">
  <ng-container *grecoLet="canArchive$ | async as canArchive">
    <div class="agreements-table-top-bar">
      <greco-filter-bar
        [hideSeparator]="true"
        [filterOptions]="filterOptions$"
        (changed)="filters$.next($event[1]); onFilterApplied()"
        class="agreements-filter-bar"
      >
        <mat-slide-toggle
          [disabled]="!canRead"
          header
          [color]="color"
          matTooltip="Show/Hide Archived Agreements"
          (change)="toggleArchived()"
          class="agreement-toggle-button"
        >
          Toggle Archived
        </mat-slide-toggle>
        <ng-content select="[filterbar]"></ng-content>
      </greco-filter-bar>
    </div>

    <greco-table
      *ngIf="canRead"
      [data]="(agreements$ | async) || []"
      [highlight]="true"
      [loading]="loading"
      (rowClick)="openAgreement($event)"
      #agreementsTable
    >
      <mat-icon *grecoTableCol="''; fitContent: true; let agreement" [matTooltip]="agreement.id">
        edit_document
      </mat-icon>

      <p *grecoTableCol="'Name'; let agreement" matTooltip="Agreement Title">
        <strong>{{ agreement.title }}</strong>
      </p>

      <p *grecoTableCol="'Type'; let agreement" class="type" matTooltip="Signing Method">
        <span [ngSwitch]="agreement.agreementType">
          <mat-chip *ngSwitchCase="'CHECKBOX'">
            <mat-icon>check_box_outline_blank</mat-icon> <span>Checkbox</span>
          </mat-chip>
          <mat-chip *ngSwitchCase="'AUTO_CHECKBOX'">
            <mat-icon>check_box</mat-icon> <span>Auto Checkbox</span>
          </mat-chip>
          <mat-chip *ngSwitchCase="'DIGITAL_SIGNATURE'"> <mat-icon>edit</mat-icon> <span>Signature</span> </mat-chip>
        </span>
      </p>

      <p *grecoTableCol="'Created Date'; let agreement; sorting: 'created'" matTooltip="Date Agreement was Created">
        {{ agreement.created | date : 'longDate' }}
      </p>

      <mat-chip-list *grecoTableCol="'Status'; let agreement; sorting: 'status'; fitContent: true">
        <div [ngSwitch]="agreement.archivedDate">
          <mat-chip *ngSwitchDefault [style.background-color]="'var(--warn-color)'" [style.color]="'white'"
            >Archived</mat-chip
          >
          <mat-chip *ngSwitchCase="null" [style.background-color]="''" [style.color]="''">Active</mat-chip>
        </div>
      </mat-chip-list>

      <ng-container>
        <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let agreement">
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <a mat-menu-item [routerLink]="agreement.id" *ngIf="canRead">
              <mat-icon>open_in_new</mat-icon>
              <span>View Details</span>
            </a>

            <button
              mat-menu-item
              [disabled]="!canArchive"
              (click)="archiveAgreement(agreement)"
              *ngIf="!agreement.archivedDate; else restore"
            >
              <mat-icon>archive</mat-icon>
              <span>Archive</span>
            </button>
            <ng-template #restore>
              <button mat-menu-item [disabled]="!canArchive$" (click)="restoreAgreement(agreement)">
                <mat-icon>restore</mat-icon>
                <span>Restore</span>
              </button>
            </ng-template>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>
  </ng-container>
</ng-container>

<mat-paginator
  *ngIf="paginationMeta?.totalItems"
  showFirstLastButtons
  [length]="paginationMeta!.totalItems || 0"
  [pageSize]="paginationMeta!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
