<div class="selected">
  <img
    [src]="
      selectedVariant?.image?.imageURL ||
      selectedImage?.imageURL ||
      product.images[0]?.imageURL ||
      'assets/lf3/icon_square_pad.png'
    "
  />
</div>

<div class="image-swiper" *ngIf="product.images.length > 1">
  <swiper
    #SwiperComponent
    [slidesPerView]="'auto'"
    [spaceBetween]="8"
    [navigation]="true"
    [slidesOffsetBefore]="32"
    [slidesOffsetAfter]="32"
  >
    <ng-template swiperSlide *ngFor="let image of product.images; index as index">
      <ng-container
        *ngIf="
          selectedVariant
            ? image.imageURL === selectedVariant?.image?.imageURL
            : image.imageURL === selectedImage?.imageURL;
          else notSelected
        "
      >
        <div class="image-box" style="border: 1px solid var(--primary-color)">
          <img [src]="image.imageURL" (click)="selectImage(image)" />
        </div>
      </ng-container>
      <ng-template #notSelected>
        <div class="image-box">
          <img [src]="image.imageURL" (click)="selectImage(image)" />
        </div>
      </ng-template>
    </ng-template>
  </swiper>
</div>
