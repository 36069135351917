<div class="list-item date">
  <h3 [id]="id" style="margin: 0">{{ dayHeader }}</h3>
</div>

<ng-container *ngFor="let item of events; let index = index; trackBy: trackById">
  <greco-user-event-list-item
    *ngIf="!pagination || (index >= displayRange[0] && index < displayRange[1])"
    class="list-item event"
    [event]="item.event"
    [userWaitlist]="userWaitlist"
    [userBookings]="userBookings"
    [bookingOption]="item.bookingOption"
    [bookings]="$any(item.event).bookings || 0"
    [hasLinkedAccounts]="linkedAccounts.length ? true : false"
  ></greco-user-event-list-item>
</ng-container>

<mat-paginator
  *ngIf="pagination && events.length > 5"
  hidePageSize
  showFirstLastButtons
  [pageSize]="5"
  [pageIndex]="pageIndex"
  [length]="events.length"
  (page)="changePage($event.pageIndex)"
></mat-paginator>
