<greco-box>
  <div>
    <h3><mat-icon *ngIf="community.tags?.includes('In-Studio')">place</mat-icon>{{ community.name }}</h3>
    <ng-container *ngIf="community.address">
      <p>
        {{ community.address?.formatted }}
      </p>
      <object
        class="map"
        type="text/html"
        *ngIf="community.address?.placeId; else noAddress"
        [data]="
          'https://www.google.com/maps/embed/v1/place?q=place_id:' +
            community.address?.placeId +
            '&key=' +
            googleMapsKey | safe : 'resourceUrl'
        "
      ></object>
      <ng-template #noAddress>
        <img class="map" src="assets/greco/placeholder.png" />
      </ng-template>
    </ng-container>
  </div>
  <button *ngIf="callToActionText || selectedCallToActionText" mat-stroked-button color="primary" [disabled]="selected">
    {{ selected ? selectedCallToActionText : callToActionText || selectedCallToActionText }}
  </button>
</greco-box>
