import * as moment from 'moment';
import { BookingOption, CalendarEvent, EventSeries } from '../models';

function formatMinutes(minutes: number) {
  const hours = minutes / 60;
  const days = hours / 24;

  if (days % 1 === 0) return days + ' Day' + (!days || days > 1 ? 's' : '');

  if (hours % 1 === 0) return hours + ' Hour' + (!hours || hours > 1 ? 's' : '');

  return minutes + ' Minute' + (!minutes || minutes > 1 ? 's' : '');
}

export function getCancellationPolicyInnerHtml(bookingOption: BookingOption): string {
  let html = '';

  if (bookingOption.cancellationWindow) {
    const formatted = formatMinutes(bookingOption.cancellationWindow).toLowerCase();
    html += `<p>Please note that due to the limited availability of spots in each event we require a minimum of ${formatted} notice prior to the event start time to cancel any booking. If you do not attend the event or fail to cancel within ${formatted}, you will forfeit your perks and/or purchase${
      bookingOption.cancellationPrice
        ? ` and you will be charged a $${(bookingOption.cancellationPrice / 100).toFixed(2)}+tx cancellation fee`
        : ''
    }.</p>`;
  }

  html += `<p>If for some reason you are unable to attend, please cancel your booking so that another member can take your place.</p>`;

  return html;
}

export function calculateBoosterRequirements(
  event: CalendarEvent | EventSeries,
  bookingOption: BookingOption,
  date?: Date
) {
  const now = date ? moment(date) : moment();
  const cutoff = moment(event.startDate).subtract(bookingOption.bookingWindow, 'minutes');
  return now.isBefore(cutoff) ? Math.ceil(cutoff.diff(now, 'days', true)) : 0;
}

export function bookingOptionCancellationPolicyLabel(bookingOption: BookingOption): string {
  if (!bookingOption.cancellation) return 'Anytime, no penalty';

  let label = '';

  if (bookingOption.cancellationWindow) {
    label += formatMinutes(bookingOption.cancellationWindow) + ' Before Event';
  }

  if (bookingOption.cancellationPrice) {
    if (label) label += ' or ';
    label += '$' + (bookingOption.cancellationPrice / 100).toFixed(2) + ' Fee';
  }

  if (!bookingOption.refund) {
    label += label ? ' (No Refund)' : 'No Refund';
  }

  return label;
}

export function isAvailableNow(
  event: CalendarEvent | EventSeries,
  bookingOption: BookingOption,
  now: Date = new Date()
): boolean {
  const mNow = moment(now);

  const bookingWindowReached = mNow.isSameOrAfter(
    moment(event.startDate).subtract(bookingOption.bookingWindow, 'minutes')
  );
  if (bookingWindowReached) return true;
  if (bookingOption.maxBoost === -1) return false;
  if (!bookingOption.maxBoost) return true;

  const boostDays = calculateBoosterRequirements(event, bookingOption, now);
  return bookingOption.maxBoost >= boostDays;
}
