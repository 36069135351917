import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { User } from '@greco/identity-users';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../../services';

@Component({
  selector: 'greco-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: UserPickerComponent }],
})
export class UserPickerComponent implements MatFormFieldControl<User>, ControlValueAccessor, OnDestroy {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef,
    private userSvc: UserService
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'user-picker';

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  stateChanges = new Subject<void>();

  @Input() onlyStaff = false;
  @Input() communityId?: string | null;

  @Input() showPhoto = false;

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `${UserPickerComponent._controlType}-${UserPickerComponent._id++}`;
  readonly controlType = UserPickerComponent._controlType;

  searchTerm$ = new BehaviorSubject<string>('');
  // FIXME: This request is breaking due to a permissions bug with the security service for non-Super Admins
  users$ = this.searchTerm$.pipe(
    tap(() => {
      if (this._value) this._value = null;
    }),
    debounceTime(250),
    map(searchTerm => searchTerm || ''),
    distinctUntilChanged(),
    switchMap(searchTerm =>
      this.userSvc.searchUsersPublic(searchTerm, undefined, this.onlyStaff, this.communityId ?? undefined)
    ),
    catchError(err => {
      console.error(err);
      return of([]);
    })
  );

  private _value: User | null = null;
  @Input() set value(value: User | null) {
    this._value = value;
    if (this._input) {
      this._input.value = value?.displayName || value?.email || '';
      this.searchTerm$.next(this._input.value);
    }

    this._onChange?.(value);
    this.stateChanges.next();
  }
  get value() {
    return this._value;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return false;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  ngOnDestroy() {
    this.stateChanges.complete();
    this.searchTerm$.complete();
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.user-picker-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: User | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: User | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  displayWith(value: User | null): string {
    return value?.displayName || value?.email || '';
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value || null;
  }
}
