<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  [saleCategories]="true"
  (changed)="filters$.next($event[1]); onFilterApplied()"
>
  <greco-sale-category-picker
    saleCategoryPicker
    #saleCategoryPicker
    [accountId]="account.id"
    (loaded)="selectedCategories$.next($event)"
    (categoriesChanged)="selectedCategories$.next($event)"
    (showUncategorizedChanged)="showUncategorized$.next($event)"
  ></greco-sale-category-picker>

  <button
    *ngIf="listIncludesInactiveProducts && productsToActivate.length"
    mat-flat-button
    color="primary"
    (click)="activateBulk()"
  >
    <mat-icon>check_circle</mat-icon>
    <span>Activate {{ productsToActivate.length }} Product{{ productsToActivate.length > 1 ? 's' : '' }}</span>
  </button>

  <button mat-stroked-button [matMenuTriggerFor]="actionsMenu" color="primary" style="height: 32px">
    Quick Actions
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #actionsMenu>
    <button mat-menu-item (click)="createProduct()">
      <mat-icon>add</mat-icon>
      <span>New Product</span>
    </button>

    <mat-divider></mat-divider>

    <button *ngIf="communityId" mat-menu-item (click)="exportInventories(communityId)">
      <mat-icon>file_download</mat-icon>
      <span>Export Inventories</span>
    </button>
  </mat-menu>
</greco-filter-bar>

<greco-table [data]="(products$ | async) || []" [highlight]="true" [loading]="loading" (rowClick)="openProduct($event)">
  <div
    *grecoTableCol="''; fitContent: true; let product"
    [matTooltip]="product.status === active ? 'This product is already active, no bulk actions to perform' : ''"
  >
    <mat-checkbox
      color="primary"
      [checked]="productInList(product)"
      [disabled]="product.status === active"
      (click)="$event.stopImmediatePropagation()"
      (change)="toggleActivateProduct(product)"
    >
    </mat-checkbox>
  </div>

  <mat-icon *grecoTableCol="''; fitContent: true; let product" [matTooltip]="product.id">shopping_cart</mat-icon>

  <p *grecoTableCol="'Title'; let product" [matTooltip]="product.description">{{product.title}}</p>
  <p *grecoTableCol="'Sales Category'; let product" [matTooltip]="product.saleCategoryId">
    {{ product.saleCategory?.label || '-' }}
  </p>

  <!-- <p *grecoTableCol="'Description'; let product" [matTooltip]="product.description">{{product.description}}</p> -->

  <p *grecoTableCol="'Variants'; let product">{{product | variants}}</p>

  <!-- TODO -->
  <!-- <p *grecoTableCol="'Perks'"></p> -->

  <!-- TODO -->
  <!-- <p *grecoTableCol="'Fees & Referrals'"></p> -->

  <mat-chip-list *grecoTableCol="'Status'; let product">
    <mat-chip> {{ product.status | titlecase }} </mat-chip>
  </mat-chip-list>

  <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let product">
    <button
      mat-icon-button
      [cdkCopyToClipboard]="getLink(product)"
      [disabled]="this.product.status !== 'ACTIVE'"
      (click)="$event.stopImmediatePropagation();"
      (cdkCopyToClipboardCopied)="$event && copied()"
    >
      <mat-icon>link</mat-icon>
    </button>

    <button [matMenuTriggerFor]="menu" mat-icon-button (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <!-- Archive (only if active) -->
      <button *ngIf="product.status === 'ACTIVE'" mat-menu-item color="primary" (click)="archiveProduct(product)">
        <mat-icon>archive</mat-icon>
        Archive
      </button>

      <!-- Activate -->
      <button *ngIf="product.status !== 'ACTIVE'" mat-menu-item color="primary" (click)="activateProduct(product)">
        <mat-icon>check_circle</mat-icon>
        {{ product.status === 'DRAFT' ? 'Activate' : 'Re-activate' }}
      </button>

      <!-- View Details -->
      <button mat-menu-item color="primary" (click)="openProduct(product)">
        <mat-icon>open_in_new</mat-icon>
        View Details
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [pageSizeOptions]="[10, 20, 50]"
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
