import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { shareReplay } from 'rxjs/operators';
import { GrecoStudio } from '../models';

@Injectable()
export class GrecoStudioService {
  constructor(private ngFire: AngularFirestore) {}
  studios$ = this.ngFire.collection<GrecoStudio>('studios').valueChanges({ idField: 'id' }).pipe(shareReplay(1));
}
