<greco-simple-dialog [data]="dialogData">
  <ng-container *ngIf="data.event">
    <greco-box>
      <!-- Event Details -->
      <greco-preview-booking-event-details [event]="data.event"></greco-preview-booking-event-details>

      <!-- Booking Option Picker -->
      <greco-preview-booking-booking-option-picker
        [user]="data.user"
        [event]="data.event"
        [booking]="data.booking"
        [onlyAvailablePerks]="false"
        (selected)="bookingOption$.next($event)"
      ></greco-preview-booking-booking-option-picker>

      <ng-container *grecoLet="preview$ | async as preview">
        <!-- Purchase Preview (+ add payment method) -->
        <ng-container *ngIf="preview && preview.purchase">
          <greco-collapsible-section
            *ngIf="preview.purchase"
            id="payment-section"
            [header]="{ title: 'Payment', icon: 'receipt_long' }"
          >
            <greco-purchase-preview
              [purchase]="preview.purchase.purchase"
              [showHeader]="false"
              [showTotals]="true"
            ></greco-purchase-preview>

            <div
              *ngIf="data.user && (preview.purchase.purchase.total > preview.purchase.purchase.balanceUsed)"
              class="payment-method-container"
            >
              <mat-form-field appearance="standard" class="payment-method-picker-form-field">
                <mat-label *ngIf="!paymentMethodControl.value"><i>Add a Payment Method</i></mat-label>
                <greco-select-payment-method
                  #selectPaymentMethodInput
                  [formControl]="paymentMethodControl"
                  [userId]="data.user.id"
                  [required]="true"
                  [allowBankPayments]="false"
                ></greco-select-payment-method>
                <greco-add-payment-method-button
                  matSuffix
                  [onlyIcon]="true"
                  [userId]="data.user.id"
                  [hiddenForms]="['Bank Account']"
                  (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
                ></greco-add-payment-method-button>
              </mat-form-field>
            </div>
          </greco-collapsible-section>
        </ng-container>

        <ng-container *ngIf="preview">
          <div id="confirm">
            <div id="warning" *ngIf="preview?.errors?.length || preview?.dto?.completed === false">
              <mat-icon>warning</mat-icon>
              <span *ngIf="preview?.errors?.length" [innerHTML]="$any(preview).errors[0] | safe: 'html'"></span>
              <span *ngIf="!preview?.errors?.length">
                The selected booking option isn't available and the booking will remain pending until checked-in.
              </span>
            </div>

            <div id="button" style="display: flex; float: right; margin-top: 30px">
              <button
                mat-flat-button
                color="primary"
                [disabled]="confirming"
                [matTooltip]="preview?.dto?.completed === false ? 'Confirm pending booking' : 'Confirm booking'"
                (click)="confirmBooking(preview,data.booking.id)"
              >
                <span>Confirm Booking</span>
                <mat-icon *ngIf="confirming || loading" style="margin-left: 4px" class="spin">loop</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </greco-box>
  </ng-container>
</greco-simple-dialog>
