<div [formGroup]="_form" class="greco-icon-picker-container">
  <mat-chip-list #chipsList>
    <mat-icon *ngIf="value">{{ value }}</mat-icon>
    <input
      #input
      matInput
      type="text"
      [value]="_inputValue"
      [disabled]="disabled"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      [matChipInputFor]="chipsList"
      (input)="_form.get('search')?.setValue(input.value)"
      (keyup)="removeIcon()"
    />
  </mat-chip-list>

  <mat-autocomplete
    style="width: 100%"
    #auto="matAutocomplete"
    (optionSelected)="optionSelected($event); input.value = value || ''; _form.get('search')?.setValue(value)"
  >
    <mat-tab-group>
      <mat-tab label="All" *ngIf="_form.get('search')?.value">
        <ng-template mat-tab-label>
          <mat-icon>search</mat-icon>
        </ng-template>
        <div class="wrapper-simple">
          <mat-option *ngFor="let icon of (icons$ | async) || []" [value]="icon" style="width: fit-content">
            <mat-icon
              style="
                width: fit-content;
                border: 2px solid var(--border-color, lightgrey);
                border-radius: 6px;
                padding: 4px;
              "
              [matTooltip]="icon"
              >{{ icon }}</mat-icon
            >
          </mat-option>
        </div>
      </mat-tab>
      <mat-tab
        [label]="category.category"
        *ngFor="let category of (iconsSorted$ | async) || []"
        (click)="$event.stopImmediatePropagation()"
        style="justify-content: center"
      >
        <ng-template mat-tab-label>
          <mat-icon [matTooltip]="category.category">{{ categories.get(category.category) }}</mat-icon>
        </ng-template>
        <div class="wrapper-simple">
          <mat-option *ngFor="let icon of category.icons || []" [value]="icon" style="width: fit-content">
            <mat-icon
              style="
                width: fit-content;
                border: 2px solid var(--border-color, lightgrey);
                border-radius: 6px;
                padding: 4px;
              "
              [matTooltip]="icon"
              >{{ icon }}</mat-icon
            >
          </mat-option>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-autocomplete>
</div>
