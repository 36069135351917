import { CurrencyPipe } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ProductVariant, ProductVariantRecurrence, RecurrencePeriod } from '@greco/sales-products';
import { SubscriptionRecurrence } from '@greco/sales-subscriptions';
import { VariantPriceBillingPipe } from './variant-price-billing.pipe';
import { VariantPriceCostPipe } from './variant-price-cost.pipe';

@Pipe({ name: 'variantPrice' })
export class VariantPriceLabel implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD'
  ) {}

  private _cost = new VariantPriceCostPipe(this._locale, this._defaultCurrencyCode);
  private _currency = new CurrencyPipe(this._locale, this._defaultCurrencyCode);
  private _billing = new VariantPriceBillingPipe();
  transform(variant?: ProductVariant): string {
    if (!variant) return '';
    else
      return `${this._currency.transform(variant.price / 100)}${
        variant.recurrence?.frequency ? ` ${this._billing.transform(variant, false)}` : ''
      }`;
  }

  formatFrequencyString(recurrence: SubscriptionRecurrence | ProductVariantRecurrence) {
    if (!recurrence) return '';
    return `${recurrence.frequency === 1 ? '' : recurrence.frequency + ' '}${this.getPeriodLabel(recurrence.period)}${
      recurrence.frequency > 1 ? 's' : ''
    }`;
  }

  private getPeriodLabel(period: RecurrencePeriod) {
    switch (period) {
      case RecurrencePeriod.Daily:
        return 'day';
      case RecurrencePeriod.Monthly:
        return 'month';
      case RecurrencePeriod.Weekly:
        return 'week';
      case RecurrencePeriod.Yearly:
        return 'year';
    }
  }
}
