import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar } from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CalendarService } from '../../services';

@Component({
  selector: 'greco-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss'],
})
export class CalendarPickerComponent implements OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private calendarSvc: CalendarService,
    private breakpointObs: BreakpointObserver
  ) {}

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string | string[]>('');
  @Input() communityId?: string | string[] | null;

  @Input() initialCalendarIds?: string[] | null;

  @Input() calendarIdToAdd?: string | null;

  @Input() selectAll = false;

  @Input() showCommunity?: boolean = false;

  @Input() placeholder = 'Select Calendar(s)';

  @Output() refresh: EventEmitter<null> = new EventEmitter();

  initialSelectedCalendarIds: string[] = [];
  allSelected = false;
  calendarCount = 0;

  mobileBreakpoint$ = this.breakpointObs.observe('(max-width: 600px)');

  private _onDestroy$ = new Subject<void>();

  private _value$ = new BehaviorSubject<string | null>(null);
  @Input() get value() {
    return this._value$.value;
  }
  set value(value) {
    this._value$.next(value);
  }

  readonly queriedCalendarIds$ = this.route.queryParams.pipe(
    distinctUntilChanged((prev, next) => prev.calendarIds === next.calendarIds),
    map(params => params.calendarIds as string),
    map(calendarIds => calendarIds?.split(',') ?? []),
    shareReplay(1)
  );

  calendars$ = combineLatest([this._communityId$.pipe(distinctUntilChanged())]).pipe(
    switchMap(async ([communityId]) => await this.calendarSvc.getManySecuredCached(communityId, true)),
    tap(calendars => (this.calendarCount = calendars?.length || 0)),
    tap(calendars => {
      if (!calendars?.length) return;

      const selectedCalendars: Calendar[] = [];

      if (this.selectAll) {
        this.initialSelectedCalendarIds = calendars.map(calendar => calendar.id) ?? [];
        this.onChanged(calendars.map(calendar => calendar.id) ?? []);
      } else {
        if (this.calendarIdToAdd) {
          calendars?.forEach(calendar => {
            if (
              calendar.id === this.calendarIdToAdd &&
              !selectedCalendars.map(selected => selected.id).includes(calendar.id)
            ) {
              selectedCalendars.push(calendar);
            }
          });
        }

        const storedIds: string[] = JSON.parse(
          localStorage.getItem(calendars[0].communityId + '_events-selected-calendar-ids') || '{}'
        );

        calendars?.forEach(calendar => {
          if (!selectedCalendars.map(selected => selected.id).includes(calendar.id)) {
            if (this.initialCalendarIds?.includes(calendar.id)) selectedCalendars.push(calendar);
            else if (storedIds?.length && storedIds?.includes(calendar.id)) selectedCalendars.push(calendar);
          }
        });

        // Have at least the first calendar selected by default
        if (!selectedCalendars.length && calendars?.length) {
          selectedCalendars.push(calendars[0]);
        }

        this.initialSelectedCalendarIds = selectedCalendars.map(calendar => calendar.id) ?? [];
        this.onChanged(this.initialSelectedCalendarIds);
      }
    }),
    shareReplay(1)
  );

  selectedCalendars$ = combineLatest([
    this.calendars$,
    this.queriedCalendarIds$.pipe(distinctUntilChanged((prev, next) => prev?.join() === next?.join())),
  ]).pipe(
    debounceTime(100),
    map(([calendars, queriedCalendarIds]) => {
      const selectedCalendars: Calendar[] = [];

      calendars?.forEach(calendar => {
        if (queriedCalendarIds.includes(calendar.id)) selectedCalendars.push(calendar);
        else if (this.initialSelectedCalendarIds.includes(calendar.id)) selectedCalendars.push(calendar);
      });

      if (calendars?.length) {
        localStorage.setItem(
          calendars[0].communityId + '_events-selected-calendar-ids',
          JSON.stringify(selectedCalendars.map(cal => cal.id))
        );
      }

      this.initialSelectedCalendarIds = [];
      return selectedCalendars;
    })
  );

  selectedCalendarIds$ = this.selectedCalendars$.pipe(map(calendars => calendars.map(calendar => calendar.id)));

  toggleAllSelection(select: MatSelect) {
    if (this.allSelected) {
      select.options.forEach((item: MatOption) => item.select());
    } else {
      select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  async onChanged(calendarIds: string[]) {
    this.allSelected = calendarIds.length === this.calendarCount;

    await new Promise(res => setTimeout(res, 50));

    this.router.navigate([], {
      replaceUrl: true,
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        ...(calendarIds?.length ? { calendarIds: calendarIds.join(',') } : { calendarIds: null }),
      },
    });

    await new Promise(res => setTimeout(res, 50));
    this.refresh.emit();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
