import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'greco-nav-title',
  templateUrl: './nav-title.component.html',
  styleUrls: ['./nav-title.component.scss']
})
export class NavTitleComponent {
  @Input() text: string;
  @Input() showBackBtn = true;
  @Input() backBtnRoute: string[] = null;

  constructor(private location: Location) {}

  back() {
    this.location.back();
  }
}
