import { BaseDto } from '@greco/util-dto';
import { Expose } from 'class-transformer';
import { IsArray, IsDefined, IsEnum, IsNotEmpty, IsString } from 'class-validator';
import { AccountLinkPrivilege } from '../models';
export class UpdateAccountLinkDto extends BaseDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  linkId!: string;

  @Expose()
  @IsDefined()
  @IsArray()
  @IsEnum(AccountLinkPrivilege, { each: true })
  privileges!: AccountLinkPrivilege[];
}
