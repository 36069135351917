<greco-collapsible-section
  #section
  id="stripe-fees-container"
  [expanded]="true"
  [header]="{ title: 'Stripe Fees', icon: 'add_card', small: false }"
>
  <ng-container header>
    <div
      class="split-button-container"
      *ngIf="section.expanded && (saving || loading)"
      (click)="$event.stopImmediatePropagation()"
    >
      <button disabled color="primary" mat-stroked-button class="left-button">Save Changes</button>
      <button disabled color="primary" mat-stroked-button class="right-button">
        <mat-icon class="spin">loop</mat-icon>
      </button>
    </div>

    <div
      class="split-button-container"
      *ngIf="section.expanded && !saving && !loading && !(form.value._array | isSame : initialValue)"
    >
      <button
        color="primary"
        mat-stroked-button
        class="left-button"
        (click)="_save(); $event.stopImmediatePropagation()"
      >
        Save Changes
      </button>

      <button
        color="primary"
        mat-stroked-button
        class="right-button"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopImmediatePropagation()"
      >
        <mat-icon>expand_more</mat-icon>
      </button>
    </div>

    <mat-menu #menu>
      <button mat-menu-item (click)="reset()">
        <mat-icon>undo</mat-icon>
        <span>Reset Changes</span>
      </button>
    </mat-menu>

    <button
      mat-stroked-button
      color="primary"
      *ngIf="section.expanded && !saving && !loading && (form.value._array | isSame : initialValue)"
      (click)="_runProcessingFeesReconciliation(); $event.stopImmediatePropagation()"
    >
      Run Reconciliation
    </button>
  </ng-container>

  <ng-container [formGroup]="form">
    <greco-table [data]="configsTableData" [loading]="loading" formArrayName="_array">
      <ng-container *grecoTableCol="'Event'; let data">
        <ng-container *grecoLet="_errorState(data.formControl) as errorState">
          <p
            style="user-select: none"
            [matTooltip]="errorState ? 'Missing value for percentage AND/OR amount' : data.description"
          >
            <span
              *ngIf="!(data.formControl.value | isSame : data.initialValue)"
              [class.error]="errorState"
              class="changes-marker"
            >
              •
            </span>
            <span>{{ data.title }}</span>
          </p>
          <div *ngIf="errorState" class="error-border"></div>
        </ng-container>
      </ng-container>

      <ng-container *grecoTableCol="'Fee Type'; let data; let index = index">
        <ng-container *grecoLet="_errorState(data.formControl) as errorState">
          <mat-form-field [formGroupName]="index">
            <mat-select formControlName="feeType">
              <mat-option value="none">Use Stripe Fee</mat-option>
              <mat-option value="add-to">Add to Stripe Fee</mat-option>
              <mat-option value="overwrite">Overwrite Stripe Fee</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="errorState" class="error-border"></div>
        </ng-container>
      </ng-container>

      <ng-container *grecoTableCol="'Percentage of Charge'; let data; let index = index">
        <ng-container *grecoLet="_errorState(data.formControl) as errorState">
          <mat-form-field *ngIf="data.formControl.value.feeType !== 'none'; else none" [formGroupName]="index">
            <mat-icon matPrefix (click)="input.focus()">create</mat-icon>
            <input
              #input
              matInput
              suffix="%"
              type="text"
              mask="percent"
              autocomplete="off"
              [validation]="true"
              formControlName="percentage"
              [readonly]="saving || loading"
              [readOnly]="saving || loading"
              [dropSpecialCharacters]="false"
            />
          </mat-form-field>
          <div *ngIf="errorState" class="error-border"></div>
        </ng-container>
      </ng-container>

      <ng-container *grecoTableCol="'Flat Amount'; let data; let index = index">
        <ng-container *grecoLet="_errorState(data.formControl) as errorState">
          <mat-form-field *ngIf="data.formControl.value.feeType !== 'none'; else none" [formGroupName]="index">
            <mat-icon matPrefix (click)="input.focus()">create</mat-icon>
            <input
              #input
              matInput
              currencyMask
              autocomplete="off"
              formControlName="amount"
              [options]="currencyMaskConfig"
              [readonly]="saving || loading"
              [readOnly]="saving || loading"
            />
          </mat-form-field>
          <div *ngIf="errorState" class="error-border"></div>
        </ng-container>
      </ng-container>

      <ng-container
        *grecoTableCol="'Minimum Stripe Fee'; let data; let index = index; fitContent: true; alignCenter: true"
      >
        <ng-container *grecoLet="_errorState(data.formControl) as errorState">
          <ng-container [ngSwitch]="data.formControl.value.feeType" [formGroupName]="index">
            <mat-checkbox *ngSwitchCase="'overwrite'" formControlName="stripeMinimum"></mat-checkbox>
            <mat-checkbox *ngSwitchCase="'add-to'" disabled [checked]="true"></mat-checkbox>
            <input *ngSwitchCase="'none'" matInput disabled value="-" style="text-align: center" />
          </ng-container>
          <div *ngIf="errorState" class="error-border"></div>
        </ng-container>
      </ng-container>

      <ng-container *grecoTableCol="''; let data; fitContent: true; stickyEnd: true">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu>
          <button
            mat-menu-item
            [disabled]="data.formControl.value | isSame : data.initialValue"
            (click)="data.formControl.reset(data.initialValue)"
          >
            <mat-icon>undo</mat-icon>
            <span>Reset Changes</span>
          </button>
        </mat-menu>
      </ng-container>
    </greco-table>
  </ng-container>
</greco-collapsible-section>

<ng-template #none>
  <mat-form-field>
    <input matInput disabled value="-" />
  </mat-form-field>
</ng-template>
