import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionItem } from '@greco/sales-subscriptions';

@Pipe({ name: 'filterCoupon' })
export class FilterCouponPipe implements PipeTransform {
  transform(items: SubscriptionItem[], showCoupons?: boolean) {
    if (!showCoupons) return items.filter(item => item.type !== 'CouponSubscriptionItemEntity');
    else return items.filter(item => item.type === 'CouponSubscriptionItemEntity');
  }
}
