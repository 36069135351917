<greco-table [data]="(bookingOptions$ | async) || []" [highlight]="true" (rowClick)="openOption($event)">
  <greco-perk-badge
    *grecoTableCol="''; let bookingOption; fitContent: true"
    [small]="true"
    [matTooltip]="bookingOption.id"
    [text]="bookingOption.badge.text"
    [icon]="bookingOption.badge.icon"
    [color]="bookingOption.badge.color"
    [shape]="bookingOption.badge.shape"
  ></greco-perk-badge>

  <p *grecoTableCol="'Booking Option'; let bookingOption" [matTooltip]="bookingOption.title">
    {{ bookingOption.title }}
  </p>

  <p
    *grecoTableCol="'Booking Window'; let bookingOption"
    [matTooltip]="bookingOption.bookingWindow ? (bookingOption.bookingWindow | grecoMinutes) : ''"
  >
    {{ bookingOption.bookingWindow | grecoMinutes }}
  </p>

  <p *grecoTableCol="'Max Boost'; let bookingOption">
    {{
      bookingOption.maxBoost === -1
        ? '-'
        : bookingOption.maxBoost
        ? bookingOption.maxBoost + ' Boost (' + bookingOption.maxBoost * 24 + 'hr)'
        : 'Unlimited'
    }}
  </p>

  <p *grecoTableCol="'Cancellation Policy'; let bookingOption">{{ bookingOption | cancellationPolicyLabel }}</p>

  <mat-chip-list *grecoTableCol="'Tags'; let bookingOption">
    <mat-chip *ngFor="let tag of bookingOption.tags">
      {{ tag.label }}
    </mat-chip>
  </mat-chip-list>

  <p *grecoTableCol="'Per Event Cost'; let bookingOption; alignRight: true">
    {{ bookingOption.price ? (bookingOption.price / 100 | currency) : '-' }}
  </p>

  <mat-icon *grecoTableCol="'Available to Everyone'; let bookingOption; alignCenter: true">
    {{ bookingOption.availableToAnyone ? 'check_circle' : '' }}
  </mat-icon>

  <ng-container *grecoTableCol="''; let bookingOption; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <a mat-menu-item [routerLink]="bookingOption.id">
        <mat-icon>create</mat-icon>
        <span>Edit Configuration</span>
      </a>

      <button mat-menu-item *ngIf="!bookingOption.used" (click)="removeBookingOption(bookingOption)">
        <mat-icon>delete</mat-icon>
        <span>Remove</span>
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
