import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'grecoDuration' })
export class DurationPipe implements PipeTransform {
  transform(duration?: number) {
    if (!duration) return '-';

    const hours = Math.floor(duration / 3600); // get hours
    const minutes = Math.floor((duration - hours * 3600) / 60); // get minutes
    const seconds = Math.floor(duration - hours * 3600 - minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    const hoursString = hours < 10 ? '0' + hours : hours;
    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    const secondsString = seconds < 10 ? '0' + seconds : seconds;
    return hours && hours > 0
      ? hoursString + ':' + minutesString + ':' + secondsString
      : minutesString + ':' + secondsString;

    // const hours = (duration || 0) / 60;
    // const days = hours / 24;

    // if (!days.toString().includes('.')) return `${days} Day${days > 1 ? 's' : ''}`;
    // if (!hours.toString().includes('.')) return `${hours} Hour${hours > 1 ? 's' : ''}`;
    // return `${duration} Minute${duration > 1 ? 's' : ''}`;
  }
}
