import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountLink } from '@greco/account-linking';
import { Community } from '@greco/identity-communities';
import { PropertyListener } from '@greco/property-listener-util';
import { ProductCollection, ProductConditionContext } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ShopService } from '../../services';

@Component({
  selector: 'greco-collection-slides',
  templateUrl: './collection-slides.component.html',
  styleUrls: ['./collection-slides.component.scss'],
})
export class CollectionSlidesComponent implements OnInit, OnDestroy {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private breakpoints: BreakpointObserver,
    private shopSvc: ShopService,
    private router: Router
  ) {}

  @Input() userId?: string;
  @Input() userConditionContext?: ProductConditionContext;
  @Input() linkedAccounts?: AccountLink[];

  @PropertyListener('collection') private _collection$ = new ReplaySubject<ProductCollection>(1);
  @Input() collection!: ProductCollection;

  @PropertyListener('communities') private _communities$ = new ReplaySubject<Community[]>(1);
  @Input() communities!: Community[];

  products$ = combineLatest([this._collection$, this._communities$]).pipe(
    switchMap(async ([collection, communities]) =>
      collection && communities?.length
        ? await this.shopSvc.paginateAllProducts(
            new RequestQueryBuilder().search({
              $and: [{ 'collections.id': collection.id }, { 'community.id': { $in: communities.map(com => com.id) } }],
            }),
            { limit: 10 }
          )
        : null
    ),
    map(data => data?.items || [])
  );

  mobileView = false;

  private _onDestroy$ = new Subject<void>();

  async viewMore() {
    await this.router.navigate(['shop', 'products'], { queryParams: { collection: this.collection.id } });
  }

  async ngOnInit() {
    this.breakpoints
      .observe('(max-width: 600px)')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ matches }) => {
        this.mobileView = matches;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._collection$.complete();
  }
}
