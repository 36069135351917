import {
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

export interface Tab {
  label: string;
  disabled?: boolean;
  iconStart?: string;
  iconEnd?: string;
  template?: TemplateRef<any>;
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'button[mat-flat-button]' })
export class TabButtonDirective {}

@Component({
  selector: 'greco-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() tabs: Tab[] = [];
  @Output() tabChanged: EventEmitter<MatTabChangeEvent> = new EventEmitter<MatTabChangeEvent>();

  @ViewChild(MatTabGroup) private tabGroup?: MatTabGroup;
  get selectedTabIndex() {
    return this.tabGroup?.selectedIndex || 0;
  }

  get selectedTab() {
    return this.tabs?.[this.selectedTabIndex];
  }

  @ContentChildren(TabButtonDirective) private buttons?: QueryList<TabButtonDirective>;
  @HostBinding('class.has-buttons') get hasButtons() {
    return !!this.buttons?.length;
  }
}
