import { Component, Injectable, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { Perk } from '@greco/sales-perks';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { PerkBadgeEditDialog } from '../../dialogs/perk-badge-edit/perk-badge-edit.dialog';
import { PerkService } from '../../services';

@Injectable({ providedIn: 'any' })
export class PerkSearchFilter extends BuildSearchFilter('PerkSearchFilter', {
  properties: ['title', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Component({
  selector: 'greco-perks-page',
  templateUrl: './perks.page.html',
  styleUrls: ['./perks.page.scss'],
})
export class PerksPage implements OnDestroy {
  constructor(private perksSvc: PerkService, private snackbar: MatSnackBar, private dialog: MatDialog) {}

  private _communityId$ = new BehaviorSubject<string | null>(null);

  @Input() get communityId() {
    return this._communityId$.value;
  }

  set communityId(communityId: string | null) {
    this._communityId$.next(communityId);
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });
  perks$ = combineLatest([this.filters$, this._communityId$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(
      async ([filters, communityId, pagination]) =>
        await this.perksSvc.paginate(filters, communityId || undefined, pagination)
    ),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  filterOptions = [PerkSearchFilter];
  loading = false;
  pagination: IPaginationMeta | null = null;

  async toggleSubAvailable(perk: Perk) {
    try {
      await this.perksSvc.toggleAvailability(perk.id);
      this.page$.next({ limit: this.page$.value.limit, page: this.page$.value.page });
      this.snackbar.open('Sub-community availability has been toggled!', 'Ok', {
        duration: 2500,
        panelClass: 'mat-primary',
      });
    } catch (err) {
      this.snackbar.open('Error toggling sub-community availability.', 'Ok', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
      console.error(err);
    }
  }

  async updatePerkBadge(perk: Perk) {
    const dialogRef = this.dialog.open(PerkBadgeEditDialog, {
      data: {
        perk,
      },
      width: '750px',
      maxWidth: '90%',
    });
    await toPromise(dialogRef.afterClosed());
    this.page$.next(this.page$.value);
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.page$.complete();
    this.filters$.complete();
    this._communityId$.complete();
  }
}
