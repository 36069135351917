import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '@greco/identity-users';
import moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { PerkService } from '../../services';

@Component({
  selector: 'greco-grant-user-perk-dialog',
  templateUrl: './grant-user-perk.dialog.html',
  styleUrls: ['./grant-user-perk.dialog.scss'],
})
export class GrantUserPerkDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { user: User; communityId: string },
    private dialogRef: MatDialogRef<GrantUserPerkDialog>,
    private formBuilder: FormBuilder,
    private perkSvc: PerkService,
    private snacks: MatSnackBar
  ) {}

  submitting = false;

  form = this.formBuilder.group({
    contact: [null, Validators.required],
    perk: [null, Validators.required],
    transferable: [false, Validators.required],
    quantity: [null, [Validators.required, Validators.min(1)]],
    unlimited: [false, Validators.required],
    expiry: [null, Validators.min(0)],
    expiryNotification: [false],
    expiryNotificationDays: [2, Validators.min(1)],
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  expiresOn$ = this.form.get('expiry')!.valueChanges.pipe(
    startWith(null),
    map(expiry => (typeof expiry === 'number' && expiry >= 0 ? moment().add(expiry, 'days').format('ll') : null))
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  expiresNotification$ = this.form.get('expiryNotificationDays')!.valueChanges.pipe(
    startWith(null),
    map(expiry =>
      typeof expiry === 'number' && expiry >= 0
        ? moment()
            .add((this.form.value.expiry || 0) - expiry, 'days')
            .format('ll')
        : null
    )
  );

  close() {
    this.dialogRef.close();
  }

  async submit() {
    this.submitting = true;

    try {
      const result = await this.perkSvc.grantPerk({
        transferable: this.form.value.transferable,
        consumable: !this.form.value.unlimited,
        expiryDelay: this.form.value.expiry,
        expiryNotificationDelay: this.form.value.expiryNotification ? this.form.value.expiryNotificationDays : null,
        quantity: this.form.value.quantity,
        perkId: this.form.value.perk.id,
        userId: this.form.value.contact.user.id,
      });

      this.snacks.open('Perks granted!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close(result);
    } catch (err) {
      console.error(err);
    }

    this.submitting = false;
  }
}
