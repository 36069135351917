import { SecurityResource } from '@greco/security';

export const EVENT_TEMPLATE_SECURITY_RESOURCE = 'booking_event_template';

export enum EventTemplateSecurityResourceAction {
  ACCESS_TAB = 'ACCESS_TAB',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export const EventTemplateSecurityResource: SecurityResource = {
  key: EVENT_TEMPLATE_SECURITY_RESOURCE,
  module: 'booking',

  title: 'Event Template',
  description: 'Manage event templates',

  context: class {
    eventTemplateId?: string;
    communityId?: string;
    resources?: string[];
  },

  actions: [
    {
      key: EventTemplateSecurityResourceAction.ACCESS_TAB,
      title: 'Allow access to event template tab',
      description: 'Manage event templates',
    },
    {
      key: EventTemplateSecurityResourceAction.CREATE,
      title: 'Create event templates',
      description: 'Ability to create event templates',
    },
    {
      key: EventTemplateSecurityResourceAction.UPDATE,
      title: 'Update event templates',
      description: 'Ability to update event templates',
    },
    {
      key: EventTemplateSecurityResourceAction.DELETE,
      title: 'Delete event templates',
      description: 'Ability to delete event templates',
    },
  ],
};
