<!-- Add substitution support -->
<!-- <ion-grid *ngIf="showSubstitutions" class="ion-margin-bottom">
  <ion-row>
    <ion-col class="ion-align-items-center" style="display: flex;">
      <h3 class="tooltip-right" data-tooltip="Use the {&#8203;{token}} notation &#10;to apply tokens in the editor.">
        Substitutions <ion-icon name="information-circle-outline"></ion-icon>
      </h3>
    </ion-col>
    <ion-col size="auto">
      <greco-button type="clear" size="small" (btnClick)="addSub()">
        <ion-icon name="add-circle-outline" slot="icon-only"></ion-icon>
      </greco-button>
    </ion-col>
  </ion-row>
  <ion-row *ngFor="let token of data?.tokens" class="ion-align-items-center">
    <ion-col>
      <ion-item color="light">
        <ion-input type="text" placeholder="token" [value]="token.key" (ionChange)="token.key = $event.detail.value; emitChange()"></ion-input>
      </ion-item>
    </ion-col>
    <ion-col>
      <ion-item color="light">
        <ion-input type="text" placeholder="substitution" [value]="token.value" (ionChange)="token.value = $event.detail.value; emitChange()"></ion-input>
      </ion-item>
    </ion-col>
    <ion-col size="auto">
      <greco-button type="clear" size="small" (btnClick)="removeSub(token)">
        <ion-icon name="remove-circle-outline" slot="icon-only"></ion-icon>
      </greco-button>
    </ion-col>
  </ion-row>
</ion-grid> -->
<ckeditor
  [disabled]="customDisabled"
  [editor]="editor"
  [config]="config"
  [ngModel]="data?.value"
  (change)="editorChanged($event)"
></ckeditor>
