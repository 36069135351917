import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleResolver implements Resolve<string> {
  constructor(private titleService: Title) {}

  resolve(_: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const getParents = (c: ActivatedRouteSnapshot) => {
      const data = [];
      let child = c;
      let gotTitle = false;
      do {
        if (child?.data?.title) {
          gotTitle = true;
          data.push(child.data.title);
        }
        child = child?.firstChild?.firstChild;
      } while (child && !gotTitle);
      return data;
    };
    const title = getParents(stateSnapshot.root).join(' | ');
    return title ? this.titleService.setTitle(title) : null;
  }
}
