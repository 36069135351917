import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { SmartImgModule } from '@greco/ui-smart-img';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import { DateRangePickerComponent, FilterBarComponent } from './components';
import { FilterDialog } from './dialogs';
import { FilterAutocompleteHtmlPipe, FilterValueLabelPipe, FilterValueOptionsPipe } from './pipes';
import { CondOperatorPipe } from './pipes/cond-operator.pipe';
import { FilterImageUrlPipe } from './pipes/filter-image-url.pipe';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // Material
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,

    // Greco
    SmartImgModule,
    SimpleDialogModule,

    // Other
    NgPipesModule,
    SafePipeModule,
  ],
  declarations: [
    FilterDialog,
    CondOperatorPipe,
    FilterImageUrlPipe,
    FilterBarComponent,
    FilterValueLabelPipe,
    FilterValueOptionsPipe,
    DateRangePickerComponent,
    FilterAutocompleteHtmlPipe,
  ],
  exports: [FilterBarComponent],
})
export class FiltersModule {}
