<greco-box>
  <div class="box-header">
    <div class="box-header-content">
      <div>
        <h2>
          {{ docContent?.title || "Document Unavailable '" + doc + "'" }}
        </h2>
        <h3 *ngIf="docContent">Last Modified › {{ docContent.updated.toDateString() }}</h3>
      </div>
      <ng-content select="[header-end]"></ng-content>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div class="box-content" *ngIf="docContent" [innerHtml]="docContent.text | safe : 'html'"></div>
</greco-box>
