import { unique } from '../array';

export function generateTextIndex(text: string, withNormalized = true): string[] {
  return unique([text.toLowerCase(), ...(withNormalized ? [normalize(text).toLowerCase()] : [])]).reduce(
    (acc, name) => [...acc, ...generateIndex(name).filter(v => !acc.includes(v))],
    []
  );
}

function generateIndex(text: string): string[] {
  return text.split(' ').reduce(
    (acc, _, index, array) => [
      ...acc,
      ...array
        .slice(index)
        .join(' ')
        .split('')
        .reduce((a, c) => [...a, ...(a.length ? [a[a.length - 1] + c] : [c])], [])
    ],
    []
  );
}

export function normalize(text: string): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
