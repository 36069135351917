import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import type { CreateVariantTransferDto } from '@greco/nestjs-sales-products';
import { VariantTransfer } from '@greco/sales-products';

@Injectable()
export class VariantTransferService {
  constructor(private http: HttpClient) {}

  // @Get()
  async paginate(productId: string, variantId: string, pagination?: PaginatedQueryParams) {
    return await toPromise(
      this.http.get<PaginatedDto<VariantTransfer>>(`/api/products/${productId}/variants/${variantId}/transfers`, {
        params: {
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get(':transferId')
  async getOne(productId: string, variantId: string, transferId: string) {
    return await toPromise(
      this.http.get<VariantTransfer>(`/api/products/${productId}/variants/${variantId}/transfers/${transferId}`)
    );
  }

  // @Post()
  async create(productId: string, variantId: string, dto: Omit<CreateVariantTransferDto, 'variantId'>) {
    return await toPromise(
      this.http.post<VariantTransfer>(`/api/products/${productId}/variants/${variantId}/transfers`, {
        ...dto,
        variantId,
      })
    );
  }

  // @Delete(':transferId')
  async remove(productId: string, variantId: string, transferId: string) {
    return await toPromise(
      this.http.delete<VariantTransfer>(`/api/products/${productId}/variants/${variantId}/transfers/${transferId}`)
    );
  }
}
