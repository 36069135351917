import { IsBoolean, IsString } from 'class-validator';
import { Product } from '../../product';
import {
  ProductCondition,
  ProductConditionContext,
  ProductConditionDto,
  ProductConditionEvaluation,
  ProductConditionsRegistry,
} from '../user-availability-addon';

export const PRODUCT_CONDITION_TYPE_ACTIVE_SUBSCRIPTION = 'active-subscription';

export interface SubscriptionProductCondition extends ProductCondition {
  subjectId: string;
  subject: Product;
  hasSubscription: boolean;
}

export class SubscriptionProductConditionDto extends ProductConditionDto {
  @IsString()
  subjectId: string;
  @IsBoolean()
  hasSubscription: boolean;
}

function evaluateSubscriptionCondition(
  condition: SubscriptionProductCondition,
  ctx: ProductConditionContext
): ProductConditionEvaluation {
  const subscriptions: number =
    ctx.productSubscriptions && ctx.productSubscriptions[condition.subjectId]
      ? ctx.productSubscriptions[condition.subjectId]
      : 0;
  if (!condition.hasSubscription) {
    if (subscriptions > 0)
      return { result: false, error: `You cannot be subscribed to "${condition.subject?.title}".` };
    else return { result: true };
  } else if (subscriptions <= 0)
    return { result: false, error: `You have to be subscribed to "${condition.subject?.title}".` };
  else return { result: true };
}

ProductConditionsRegistry.registerCondition(
  PRODUCT_CONDITION_TYPE_ACTIVE_SUBSCRIPTION,
  SubscriptionProductConditionDto,
  (condition: SubscriptionProductCondition, ctx: ProductConditionContext) =>
    evaluateSubscriptionCondition(condition, ctx)
);
