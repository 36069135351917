<div>
  <div style="display: flex; align-items: center; justify-content: space-between">
    <h2 style="margin-bottom: 2px; margin-right: 32px">{{data?.title || 'Confirm Adding Attendee'}}</h2>
    <button type="button" mat-icon-button (click)="dismiss('cancel')">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div style="border-radius: 4px; margin: 16px 0; padding: 8px 16px; display: flex; align-items: center">
    <mat-icon style="color: var(--primary-color)">info</mat-icon>
    <p style="margin-bottom: 0; margin-left: 8px">
      You are adding: <strong>{{data?.userName}}</strong> into the event: <strong>{{data?.eventTitle}}</strong> .
    </p>
  </div>

  <div style="margin-top: 16px; display: flex; align-items: center; justify-content: flex-end">
    <button mat-button (click)="dismiss('cancel')">Cancel</button>
    <button mat-flat-button color="primary" style="margin-left: 8px" (click)="dismiss('confirm')">Confirm</button>
  </div>
</div>
