import { BaseDto } from '@greco/util-dto';
import { Type } from 'class-transformer';
import { IsBoolean, IsDate, IsOptional, IsString } from 'class-validator';

export class UpdateVideoCollectionDto extends BaseDto {
  @IsString()
  @IsOptional()
  icon?: string;

  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  availableOn?: Date | null;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  availableUntil?: Date | null;

  @IsBoolean()
  @IsOptional()
  featured?: boolean;
}
