<div class="greco-perk-picker-container">
  <input
    matInput
    type="text"
    [disabled]="disabled"
    [required]="required"
    [matAutocomplete]="auto"
    class="greco-perk-picker-input"
    [placeholder]="placeholder"
    (input)="searchQuery$.next($any($event.target).value)"
    (blur)="touched()"
  />

  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="optionSelected($event)"
  >
    <mat-option *ngFor="let perk of perks$ | async" [value]="perk">
      <!-- TODO: Perk Badge -->
      {{ perk.title }}
    </mat-option>
  </mat-autocomplete>
</div>
