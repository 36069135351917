import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-update-subscribed-by-dialog',
  templateUrl: './update-subscribed-by.dialog.html',
  styleUrls: ['./update-subscribed-by.dialog.scss'],
})
export class UpdateSubscribedByDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { subscriptionId: string; userId: string },
    private dialogRef: MatDialogRef<UpdateSubscribedByDialog>,
    private snacks: MatSnackBar,
    private subscriptionSvc: SubscriptionsService,
    private fb: FormBuilder,
    private securitySvc: SecurityService,
    private userSvc: UserService
  ) {
    this.subscriptionId = data.subscriptionId;
    this.userId = data.userId;
  }

  subscriptionId!: string;
  userId!: string;
  processing = false;

  dialogData: DialogData = {
    title: 'Assume Control of your subscription',
    subtitle: 'Which payment method would you like to be charged, starting next billing cycle?',
    hideDefaultButton: true,
    showCloseButton: false,
  };
  _paymentMethodControl = new FormControl(null, Validators.required);

  async submitUpdate() {
    try {
      if (this._paymentMethodControl.value) {
        this.processing = true;
        const subscription = await this.subscriptionSvc.updateSubscribedBy(
          this.subscriptionId,
          this.userId,
          this._paymentMethodControl.value.id
        );
        this.snacks.open('Subscription updated successfully', 'Ok', { duration: 6000, panelClass: 'mat-primary' });
        this.processing = false;
        this.dialogRef.close(subscription);
      } else {
        console.log(this._paymentMethodControl.value);
        this.processing = false;
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      this.processing = false;
      this.snacks.open('Subscription failed to update', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
    }
  }
}
