<button
  *ngIf="!onlyIcon"
  mat-flat-button
  [disabled]="disabled"
  [color]="color"
  type="button"
  [matMenuTriggerFor]="menu"
>
  <span>Add Assignment</span>
  <mat-icon *ngIf="!hideIcon">arrow_drop_down</mat-icon>
</button>

<button *ngIf="onlyIcon" mat-icon-button [color]="color" type="button" [matMenuTriggerFor]="menu">
  <mat-icon>arrow_drop_down</mat-icon>
</button>

<mat-menu #menu>
  <button *ngFor="let form of forms" mat-menu-item (click)="$event.stopImmediatePropagation(); open(form)">
    <mat-icon *ngIf="form.matIcon">{{ form.matIcon }}</mat-icon>
    <span>{{ form.label }}</span>
  </button>
</mat-menu>
