import { Component, Injectable, Input, OnDestroy, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayUtils, toPromise } from '@greco-fit/util';
import {
  Calendar,
  CalendarEvent,
  EventTemplate,
  Resource,
  ResourceAssignmentStatus,
  ResourceTag,
} from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter, BuildTextFilter, Filter, FilterBarComponent } from '@greco/ngx-filters';
import { CommunityService } from '@greco/ngx-identity-communities';
import { CondOperator, RequestQueryBuilder, SFieldOperator, SFields } from '@nestjsx/crud-request';
import { CalendarView } from 'angular-calendar';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { EventsCalendarComponent } from '../../components';
import { CalendarService, EventService, EventTemplateService, ResourcesService } from '../../services';
import {
  ResourceEventIncludePrivateFilter,
  ResourceEventRequestSubstitutionFilter,
  ResourceEventTagsSelectFilter,
} from './filters';

@Injectable({ providedIn: 'any' })
export class ResourceEventEventTitleFilter extends BuildTextFilter('ResourceEventEventTitleFilter', {
  label: 'Title',
  shortLabel: 'Title',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW],
  properties: ['title'],
}) {}

@Injectable({ providedIn: 'any' })
export class ResourceEventEventSearchFilter extends BuildSearchFilter('ResourceEventEventSearchFilter', {
  properties: ['title', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Injectable({ providedIn: 'any' })
export class ResourceEventEventDescriptionFilter extends BuildTextFilter('ResourceEventEventDescriptionFilter', {
  label: 'Description',
  shortLabel: 'Description',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW],
  properties: ['description'],
}) {}

@Component({
  selector: 'greco-resource-events-page',
  templateUrl: './resource-events.page.html',
  styleUrls: ['./resource-events.page.scss'],
})
export class ResourceEventsPage implements OnDestroy {
  dateClicked: Date | null = null;
  contextMenuPosition = { x: '0px', y: '0px' };

  constructor(
    private resourceSvc: ResourcesService,
    private router: Router,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private eventSvc: EventService,
    private calendarSvc: CalendarService,
    private communitySvc: CommunityService,
    private eventTemplateSvc: EventTemplateService,
    private tagFilter: ResourceEventTagsSelectFilter
  ) {}

  readonly STARTING_VIEW = CalendarView.Week;
  private _resources$ = new BehaviorSubject<Resource[]>([]);
  @Input() get resources() {
    return this._resources$.value;
  }
  set resources(resources) {
    this._resources$.next(resources);
  }

  filterOptions: Type<Filter>[] = [
    ResourceEventEventSearchFilter,
    ResourceEventEventTitleFilter,
    ResourceEventEventDescriptionFilter,
    ResourceEventRequestSubstitutionFilter,
    ResourceEventTagsSelectFilter,
    ResourceEventIncludePrivateFilter,
  ];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(RequestQueryBuilder.create());

  initialCalendars: string[] = [];
  calendarIdToAdd: string | null = null;

  calendarIds: string[] = [];

  communityIds$ = this._resources$.pipe(
    map(resources => resources.map(res => res.community.id)),
    tap(communityIds => (this.tagFilter.communityIds = communityIds))
  );

  communities$ = this.communityIds$.pipe(
    switchMap(async communityIds => {
      return (await this.communitySvc.getAllCommunities())
        .filter(community => communityIds.includes(community.id))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    })
  );

  calendarsAndCommunities$ = this.communities$.pipe(
    switchMap(async communities => {
      const calendars = await this.calendarSvc.getManySecuredCached(communities.map(c => c.id));

      const filtered = (calendars ?? []).filter(calendar => {
        if (calendar.eventTemplates?.length) {
          calendar.eventTemplates = this.filterByTags(calendar.communityId, calendar.eventTemplates);
          calendar.eventTemplates = calendar.eventTemplates.filter(et => et.private !== false); // Exclude public templates
        }

        if (calendar.eventTemplates?.length) return true;
        else return false;
      });

      const grouped = ArrayUtils.groupBy(filtered, calendar => calendar.communityId);

      return Object.entries(grouped).reduce((acc, [communityId, calendars]) => {
        const community = communities.find(c => c.id === communityId);
        if (community) acc.push({ community, calendars });
        return acc;
      }, [] as { community: Community; calendars: Calendar[] }[]);
    })
  );

  readonly resourceIds$ = this._resources$.pipe(map(resources => resources.map(res => res.id)));

  readonly dates$ = new BehaviorSubject<[Date, Date] | null>(null);

  private _refresh$ = new BehaviorSubject(null);
  readonly events$ = combineLatest([this.resourceIds$, this.dates$, this.filters$, this._refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    switchMap(async ([resources, dates, _queryBuilder, _refresh]) => {
      if (!dates || !resources.length) return [];

      const calendarIds = await this.calendarIdfromRoute();
      const searchObj = JSON.parse(_queryBuilder.queryObject.s || '{}');

      const tagConditions: SFields[] = (searchObj.$and || []).filter((cond: SFields) => !!cond['tags.id']);
      const tags = tagConditions.reduce(
        (acc, cond) => [
          ...acc,
          ...((cond['tags.id'] as SFieldOperator).$in as string[]).filter(id => !acc.includes(id)),
        ],
        [] as string[]
      );

      let resourceTagIds: string[] = [];
      if (resources.length) {
        for (const resource of resources) {
          const resourceInfo = await this.resourceSvc.getResource(resource);
          if (resourceInfo.resourceTags.length) {
            resourceTagIds = resourceTagIds.concat(resourceInfo.resourceTags.map(resourceTag => resourceTag.id));
          }
        }
      }

      const searchCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['$or']);
      const titleCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['title']);
      const descriptionCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['description']);
      const substitutionCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['requestingSubstitution']);
      const privateCondition = (searchObj.$and || []).find((cond: SFields) => !!cond['private']);

      return await this.eventSvc.getEventsByDate({
        description: descriptionCondition?.description?.$contL,
        search: searchCondition?.$or?.[0]?.title?.$contL,
        title: titleCondition?.title?.$contL,
        resourceTagIds: resourceTagIds,
        showCourseInstances: true,
        includeOpenEvents: !!substitutionCondition?.requestingSubstitution?.$eq,
        calendars: calendarIds,
        includePrivate: !!privateCondition?.private?.$eq,
        includeCourse: true,
        startDate: dates[0],
        endDate: dates[1],
        resources,
        tags,
      });
    }),
    tap(() => setTimeout(() => (this.loading = false)))
  );

  loading = true;

  readonly GROUP_BY: EventsCalendarComponent['dayViewGroupBy'] = evt => [
    { label: evt.community.name, metadata: evt.community.id },
  ];

  positionTrigger(event: MouseEvent) {
    this.contextMenuPosition.x = event.pageX + 'px';
    this.contextMenuPosition.y = event.pageY + 'px';
  }

  async eventClicked(evt: CalendarEvent) {
    await this.router.navigate([evt.id], { relativeTo: this.route });
  }

  async calendarIdfromRoute() {
    const query = await toPromise(this.route.queryParamMap);
    const calendarIds = query.get('calendarIds')?.split(',') ?? [];
    this.initialCalendars = calendarIds;
    return calendarIds;
  }

  async createEventFromTemplate(communityId: string, templateId: string, calendarId: string, resourceTag: ResourceTag) {
    const eventTemplate = await this.eventTemplateSvc.getOne(templateId);
    if (communityId !== eventTemplate?.communityId) return;

    const resource = this.resources?.find(resource => resource.community.id === communityId);

    const _addAttendee = false;
    const _event: CalendarEvent | null = null;
    const _eventIds: string[] = [];
    const _capacity = 0;

    this.router.navigate(['new'], {
      relativeTo: this.route,
      state: {
        data: {
          startDate: this.dateClicked,
          resource,
          calendarId,
          resourceTag,
          communityId,
          eventTemplate,
        },
      },
    });

    // await toPromise(
    //   this.dialog
    //     .open(CreateEventFromTemplateDialog, {
    //       data: {
    //         resource,
    //         calendarId,
    //         resourceTag,
    //         communityId,
    //         eventTemplate,
    //       },
    //       width: '750px',
    //       maxWidth: '90%',
    //     })
    //     .afterClosed()
    // ).then(res => {
    //   if (res?.event) {
    //     event = res.event as CalendarEvent;

    //     setTimeout(() => (this.calendarIdToAdd = res.event.calendarId));
    //   }
    //   if (res?.eventIds?.length) eventIds = res.eventIds;
    //   res?.action === 'create_and_add_attendee' ? (addAttendee = true) : (addAttendee = false);
    // });
    // try {
    //   if (event && addAttendee) {
    //     capacity = (event as CalendarEvent).maxCapacity;
    //     this.matDialog
    //       .open(AddAttendeeDialog, { data: { event, extraEventIds: eventIds }, width: '750px', maxWidth: '90%' })
    //       .afterClosed()
    //       .subscribe(res => {
    //         if (res.action !== 'cancel' && event) this.keepOpeningAttendeeDialog(event, capacity, 1, eventIds);
    //       });
    //   }
    // } catch (err) {
    //   console.log('Event creation cancelled:', err);
    // }

    this.calendarIdToAdd = calendarId;
    this.refresh();
  }

  // async keepOpeningAttendeeDialog(event: CalendarEvent, capacity: number, attendees: number, extraEventIds?: string[]) {
  //   if (attendees < capacity) {
  //     this.matDialog
  //       .open(AddAttendeeDialog, { data: { event, extraEventIds }, width: '750px', maxWidth: '90%' })
  //       .afterClosed()
  //       .subscribe(res => {
  //         if (res.action !== 'cancel') this.keepOpeningAttendeeDialog(event, capacity, attendees++);
  //       });
  //   } else {
  //     this.snacks.open('Event capacity reached!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
  //   }
  //   this.refresh();
  // }

  filterByTags(communityId: string, templates: EventTemplate[]) {
    const resource = this.resources?.find(resource => resource.community.id === communityId);

    return templates.filter(template => {
      const tagsForSubstitution = template.resourceAssignments?.reduce((acc, assignment) => {
        if (
          assignment.resourceTagId &&
          !acc.includes(assignment.resourceTagId) &&
          (assignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION ||
            assignment.resourceId === resource?.id)
        ) {
          acc.push(assignment.resourceTagId);
        }
        return acc;
      }, [] as string[]);

      return resource?.resourceTags?.map(t => t.id).some(tagId => tagsForSubstitution?.includes(tagId));
    });
  }

  sharedTags(communityId: string, template: EventTemplate) {
    const resource = this.resources?.find(resource => resource.community.id === communityId);
    const tagsForSubstitution = template.resourceAssignments?.reduce((acc, assignment) => {
      const resourceTag = template.resourceTags?.find(resourceTag => assignment.resourceTagId === resourceTag.id);
      if (
        resourceTag &&
        !acc.map(t => t.id).includes(assignment.resourceTagId || '') &&
        (assignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION ||
          assignment.resourceId === resource?.id)
      ) {
        acc.push(resourceTag);
      }
      return acc;
    }, [] as ResourceTag[]);
    return tagsForSubstitution?.filter(tag => resource?.resourceTags.map(t => t.id).includes(tag.id)) || [];
  }

  resourceHasTag(communityId: string, resourceTag: ResourceTag) {
    const resource = this.resources?.find(resource => resource.community.id === communityId);
    return resource?.resourceTags.filter(tag => tag.id === resourceTag.id)?.length || 0 > 0;
  }

  ngOnDestroy() {
    this.dates$.complete();
    this._refresh$.complete();
    this._resources$.complete();
  }

  refresh() {
    this._refresh$.next(null);
  }

  async setInitialFilters(filterBar: FilterBarComponent) {
    if (filterBar.values.length === 0) {
      filterBar.optionSelected(new ResourceEventIncludePrivateFilter(), true);
    }
  }
}
