import { SecurityResource } from '@greco/security';

export const EVENT_SERIES_SECURITY_RESOURCE = 'booking_event_series';

export enum EventSeriesSecurityResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  CREATE_CUSTOM = 'CREATE_CUSTOM',
  UPDATE = 'UPDATE',
  UPDATE_CUSTOM = 'UPDATE_CUSTOM',
}

export const EventSeriesSecurityResource: SecurityResource = {
  key: EVENT_SERIES_SECURITY_RESOURCE,
  module: 'booking',

  title: 'Event Series',
  description: 'Manage event series',

  context: class {
    seriesId?: string;
    communityId?: string;
  },

  actions: [
    {
      key: EventSeriesSecurityResourceAction.READ,
      title: 'Read Event Series',
      description: 'Ability to see the event series tab',
    },
    {
      key: EventSeriesSecurityResourceAction.CREATE,
      title: 'Create event series',
      description: 'Ability to create an event series',
    },
    {
      key: EventSeriesSecurityResourceAction.CREATE_CUSTOM,
      title: 'Create custom event series',
      description: 'Ability to create a custom event series',
    },
    {
      key: EventSeriesSecurityResourceAction.UPDATE,
      title: 'Update event series',
      description: 'Ability to update an event series',
    },
    {
      key: EventSeriesSecurityResourceAction.UPDATE_CUSTOM,
      title: 'Custom Update event series',
      description: 'Ability to update an event series with locked event template',
    },
  ],
};
