<div [formGroup]="_form" class="greco-child-account-picker-container">
  <div *ngIf="value" style="width: 24px; height: 24px; margin-right: 8px">
    <greco-user-avatar [showOnlineStatus]="false" [user]="value"></greco-user-avatar>
  </div>
  <input
    #input
    matInput
    type="text"
    [value]="_inputValue?.displayName"
    [disabled]="disabled"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    (input)="_form.get('search')?.setValue(input.value)"
    (keyup)="remove()"
  />

  <mat-autocomplete
    style="width: 100%"
    #auto="matAutocomplete"
    (optionSelected)="
      optionSelected($event);
      input.value = value?.contactEmail || '';
      _form.get('search')?.setValue(value?.contactEmail)
    "
  >
    <mat-option *ngFor="let account of search$ | async" [value]="account">
      <div style="display: flex; align-items: center">
        <div style="width: 24px; height: 24px; margin-right: 8px">
          <greco-user-avatar [showOnlineStatus]="false" [user]="account"></greco-user-avatar>
        </div>

        <div>
          <ng-container>
            {{ account.displayName }} <small>({{ account.contactEmail }})</small>
          </ng-container>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
