<ng-container *grecoLet="bookingOption$ | async as bookingOption;">
  <ng-container *grecoLet="_overridePaymentMethod$ | async">
    <!-- NEW -->
    <greco-preview-booking-booking-option-picker
      *grecoLet="canUsePerks$ | async as canUsePerks"
      [readonly]="readonly"
      [user]="user"
      [event]="event"
      [bookedBy]="bookedBy"
      [onlyAvailablePerks]="!allowPendingBookings"
      (selected)="bookingOption$.next($event)"
      (useBookedByPerks)="useBookedByPerks$.next($event)"
      [disableUserPerks]="!canUsePerks"
    ></greco-preview-booking-booking-option-picker>
    <greco-cancellation-policy-requirement
      *ngIf="event && bookingOption && bookingOption?.cancellation"
      [bookingOption]="bookingOption"
      [event]="event"
    ></greco-cancellation-policy-requirement>

    <ng-container *ngIf="user && !readonly">
      <ng-container *ngIf="currentUserId$ | async as currentUserId">
        <!-- Confirm email address -->
        <greco-email-verification-requirement
          *ngIf="user.id !== currentUserId && !user.emailVerified && user.email"
        ></greco-email-verification-requirement>
        <!-- Confirm profile completion -->
        <greco-profile-completion-requirement
          *ngIf="!user.address?.line1"
          [userId]="user.id"
        ></greco-profile-completion-requirement>
      </ng-container>

      <!-- Payment Method -->
      <ng-container *grecoLet="hasPaymentMethod$ | async as hasPaymentMethod">
        <greco-payment-method-requirement
          *ngIf="hasPaymentMethod === false"
          [user]="user"
          [allowBankPayments]="false"
          (refresh)="paymentMethodControl.setValue(null)"
        ></greco-payment-method-requirement>
      </ng-container>
      <!-- Form Requirements -->
      <greco-typeform-requirement
        *ngFor="let req of typeformRequirements$ | async"
        [requirement]="req"
        [user]="user"
        (filled)="requirementFilledIn(req, $event.responseId)"
      ></greco-typeform-requirement>
    </ng-container>

    <ng-container *grecoLet="spot$ | async as spot">
      <ng-container *grecoLet="(room$ | async) as room">
        <ng-container *grecoLet="(canManageSpots$ | async) as canManageSpots">
          <greco-item *ngIf="room && (event.enableUserSpotBooking || canManageSpots)" [lines]="false">
            <mat-icon item-start>person_pin</mat-icon>
            <div>
              <p style="margin-bottom: 0">
                <strong>Spot Selection</strong>
              </p>
              <p style="margin-bottom: 0">
                <small *ngIf="spot$ | async as spot; else noSpotPicked"
                  >You picked <strong>{{spot.name || spot.spotNumber}}</strong>
                </small>
                <ng-template #noSpotPicked>
                  <small style="color: var(--warn-color)">Please pick your spot in the room.</small>
                </ng-template>
              </p>
            </div>
            <div item-end style="display: flex; align-items: center; justify-content: center">
              <button
                mat-button
                color="accent"
                style="height: 32px; margin-left: auto; padding: 0 12px"
                (click)="bottomSheet.open(spotContent, { panelClass: 'bottom-sheet' })"
              >
                <span>{{readonly ? 'View' : 'Pick Spot'}}</span>
              </button>
              <ng-template #spotContent>
                <div style="display: flex; gap: 12px; justify-content: space-between; align-items: center">
                  <h2 #header style="margin-bottom: 0">Pick Your Spot</h2>
                  <button mat-icon-button (click)="bottomSheet.dismiss()">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <greco-room-spot-picker
                  [readonly]="readonly"
                  [room]="room"
                  [event]="event"
                  [disableSpotIds]="disableSpotIds"
                  (selectedSpot)="spot$.next($event ? $event : null); spotSelected.emit($event ? $event.id : null)"
                ></greco-room-spot-picker>
                <button
                  class="scroll-to-top-button"
                  style="z-index: 11"
                  mat-mini-fab
                  (click)="header.scrollIntoView({ behavior: 'smooth' })"
                >
                  <mat-icon>vertical_align_top</mat-icon>
                </button>
              </ng-template>
            </div>
          </greco-item>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *grecoLet="(seriesAgrUsage$ | async) as usages">
      <div [formGroup]="agreementsForm">
        <ng-container *ngFor="let usage of usages; let index = index">
          <greco-item *grecoLet="canLeaveUnsigned$ | async as canLeaveUnsigned" class="agreement-usage" [lines]="false">
            <mat-icon item-start>assignment</mat-icon>
            <div style="width: 100%">
              <p
                style="
                  margin-bottom: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-weight: bold;
                "
              >
                {{ usage.agreement?.title }}
              </p>
              <p
                style="
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: smaller;
                "
                *ngIf="!readonly; else readonlyAgreements"
              >
                <mat-checkbox
                  *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                  [checked]="agreementsForm.get(usage.id)?.value"
                  [required]="true"
                  color="primary"
                  (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
                  [formControlName]="usage.id"
                  >I have read and agree to the terms of the agreement</mat-checkbox
                >

                <mat-checkbox
                  *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                  [checked]="agreementsForm.get(usage.id)?.value"
                  [required]="true"
                  color="primary"
                  [formControlName]="usage.id"
                  (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
                  >I have read and agree to the terms of the agreement</mat-checkbox
                >

                <ng-container *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'">
                  <span *ngIf="!agreementsForm.get(usage.id)?.value" style="color: var(--warn-color)"
                    >* Signature Required</span
                  >
                  <span *ngIf="agreementsForm.get(usage.id)?.value">Digitally Signed</span>
                </ng-container>
              </p>

              <ng-template #readonlyAgreements>
                <p
                  style="
                    margin-bottom: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: smaller;
                  "
                >
                  You have agreed to the terms of this agreement.
                </p>
              </ng-template>
            </div>

            <div item-end style="display: flex; align-items: center; justify-content: center">
              <button
                mat-button
                color="accent"
                style="height: 32px; margin-left: auto; padding: 0 12px"
                (click)="bottomSheet.open(agreementContent, { panelClass: 'bottom-sheet' })"
              >
                <span
                  >{{ usage.agreement?.agreementType == 'DIGITAL_SIGNATURE' && !agreementsForm.get(usage.id)?.value &&
                  !readonly ? 'Sign' : 'Details'}}</span
                >
              </button>

              <ng-template #agreementContent>
                <div style="display: flex; gap: 12px; justify-content: space-between; align-items: center">
                  <h2 #header style="margin-bottom: 0">Sign {{ usage.agreement?.title }}</h2>
                  <button mat-icon-button (click)="bottomSheet.dismiss()">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

                <div style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
                  <span [innerHtml]="(usage.agreement?.text || '') | safe:'html'"></span>
                </div>

                <ng-container *ngIf="!readonly">
                  <mat-checkbox
                    *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                    [checked]="agreementsForm.get(usage.id)?.value"
                    [required]="true"
                    color="primary"
                    [formControlName]="usage.id"
                    (click)="setCheck(usage.id, usage.agreementId);  $event.preventDefault()"
                    >I have read and agree to the terms of the agreement</mat-checkbox
                  >

                  <mat-checkbox
                    *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                    [checked]="agreementsForm.get(usage.id)?.value"
                    [required]="true"
                    color="primary"
                    [formControlName]="usage.id"
                    (click)="setCheck(usage.id, usage.agreementId); $event.preventDefault()"
                    >I have read and agree to the terms of the agreement</mat-checkbox
                  >
                  <greco-signature
                    *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'"
                    [user]="bookedBy || user"
                    [forPurchase]="true"
                    [formControlName]="usage.id"
                    ngDefaultControl
                    (signatureChange)="setSignature($event, usage.id, usage.agreementId)"
                  >
                  </greco-signature>
                </ng-container>

                <button
                  class="scroll-to-top-button"
                  mat-mini-fab
                  (click)="header.scrollIntoView({ behavior: 'smooth' })"
                >
                  <mat-icon>vertical_align_top</mat-icon>
                </button>
              </ng-template>
            </div>
          </greco-item>

          <greco-alert
            *ngIf="hideSignLater && user?.id !== bookedBy?.id"
            [config]="{
              title: 'Signature Required',
              type: $any('WARN'),
              description: (user?.displayName || 'This member') + ' will have to sign this agreement before booking into other events.'
            }"
          ></greco-alert>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *grecoLet="(preview$ | async) as preview">
      <!-- Purchase Preview (+ add payment method) -->

      <ng-container *ngIf="preview && preview.purchase">
        <greco-collapsible-section
          *ngIf="preview.purchase && !forMultiple"
          id="payment-section"
          [header]="{ title: 'Payment', icon: 'receipt_long' }"
        >
          <greco-purchase-preview
            [purchase]="preview.purchase.purchase"
            [showHeader]="false"
            [showTotals]="true"
          ></greco-purchase-preview>

          <div
            *ngIf="user && !forMultiple && (preview.purchase.purchase.total > preview.purchase.purchase.balanceUsed)"
            class="payment-method-container"
          >
            <mat-form-field appearance="standard" class="payment-method-picker-form-field">
              <mat-label *ngIf="!paymentMethodControl.value"><i>Add a Payment Method</i></mat-label>
              <greco-select-payment-method
                #selectPaymentMethodInput
                [formControl]="paymentMethodControl"
                [userId]="user.id"
                [required]="true"
                [allowBankPayments]="false"
              ></greco-select-payment-method>
              <greco-add-payment-method-button
                matSuffix
                [onlyIcon]="true"
                [userId]="user.id"
                [hiddenForms]="['Bank Account']"
                (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
              ></greco-add-payment-method-button>
            </mat-form-field>
          </div>
        </greco-collapsible-section>
      </ng-container>
    </ng-container>

    <div
      id="error"
      *ngIf="!preview||preview?.errors?.length || hasMultiBookingOptionError || (preview?.dto?.completed === false && allowPendingBookings)"
    >
      <mat-icon *ngIf="preview?.errors?.length">warning</mat-icon>
      <span *ngIf="preview?.errors?.length" [innerHTML]="$any(preview).errors[0] | safe: 'html'"></span>
    </div>

    <div id="warning" *ngIf="preview?.warnings?.length">
      <mat-icon>warning</mat-icon>
      <span *ngIf="preview?.warnings?.length" [innerHTML]="$any(preview).warnings[0] | safe: 'html'"> </span>
    </div>

    <div id="confirm" *grecoLet="hasMultiBookingOptionError$ | async as hasMultiBookingOptionError">
      <div id="button" *ngIf="!forMultiple" [matTooltip]="!user?.address?.line1 ? 'Profile Completion Required!' : ''">
        <button
          mat-flat-button
          color="primary"
          [disabled]="!preview || !!preview.errors.length || !agreementsForm.valid || confirming || loading || !user?.address?.line1"
          [matTooltip]="preview?.dto?.completed === false ? 'Confirm pending booking' : 'Confirm booking'"
          (click)="$event.stopImmediatePropagation(); confirmBooking(preview)"
        >
          <span>Confirm Booking</span>
          <mat-icon *ngIf="confirming" style="margin-left: 4px" class="spin">loop</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
