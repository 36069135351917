<mat-card style="width: 300px" class="testimonial-card">
  <img
    style="background-size: cover; object-fit: cover"
    class="card-img"
    mat-card-image
    src="{{ image }}"
    alt="card image"
  />

  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-subtitle>{{ tags }}</mat-card-subtitle>
</mat-card>
