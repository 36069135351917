import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { UtilModule } from '@greco-fit/util/ngx';
import { SafePipeModule } from 'safe-pipe';
import { NavTitleModule } from '../../components';
import { TabsBreadcrumbLayoutComponent } from './tabs-breadcrumb.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AngularFirestoreModule,
    NavTitleModule,
    MatTabsModule,
    UtilModule,
    MatCardModule,
    MatIconModule,
    SafePipeModule
  ],
  declarations: [TabsBreadcrumbLayoutComponent],
  exports: [TabsBreadcrumbLayoutComponent]
})
export class TabsBreadcrumbLayoutModule {}
