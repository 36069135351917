import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditorComponent } from './components/editor/editor.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CKEditorModule],
  exports: [EditorComponent],
  declarations: [EditorComponent],
})
export class EditorModule {}
