import { SecurityResource } from '@greco/security';

export enum AccountChargeResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  RETRY = 'RETRY',
  REFUND = 'REFUND',
  VOID = 'VOID',
  SET_DEFAULT = 'SET_DEFAULT',
  CONFIGURE_SCHEDULE = 'CONFIGURE_SCHEDULE',
}

export const AccountChargeResource: SecurityResource = {
  key: 'finance_account_charges',
  module: 'Finance',

  title: 'Account Charges',
  description: 'Manage account charges',

  context: class {
    userId?: string;
  },

  actions: [
    { key: AccountChargeResourceAction.READ, title: 'Read', description: 'Ability to view account charges' },
    { key: AccountChargeResourceAction.CREATE, title: 'Create', description: 'Ability to create a new account charge' },
    { key: AccountChargeResourceAction.VOID, title: 'Void', description: 'Ability to void an account charge' },
    { key: AccountChargeResourceAction.RETRY, title: 'Retry', description: 'Ability to retry an account charge' },
    { key: AccountChargeResourceAction.REFUND, title: 'Refund', description: 'Ability to refund an account charge' },
    {
      key: AccountChargeResourceAction.SET_DEFAULT,
      title: 'Set Default',
      description: "Ability to specify an account's default payment method",
    },
    {
      key: AccountChargeResourceAction.CONFIGURE_SCHEDULE,
      title: 'Configure Schedule',
      description: 'Ability to configure the reconciliation schedule',
    },
  ],
};
