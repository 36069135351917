import { from, isObservable, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type Unresolved<T> = null | string | T | Promise<string | T> | Observable<string | T>;

export function resolve<T>(resolver: (id?: string) => Observable<T>, unresolved?: Unresolved<T>): Observable<T> {
  return unresolved
    ? isObservable(unresolved)
      ? unresolved.pipe(switchMap(obj => (typeof obj === 'string' ? resolver(obj) : of(obj))))
      : typeof unresolved === 'string'
      ? resolver(unresolved)
      : 'then' in unresolved && typeof unresolved.then === 'function'
      ? from(unresolved).pipe(switchMap(obj => (typeof obj === 'string' ? resolver(obj) : of(obj))))
      : of(unresolved as T)
    : resolver();
}
