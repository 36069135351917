import { Pipe, PipeTransform } from '@angular/core';
import { SearchUtils } from '@greco-fit/util';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(value: string, query: string) {
    const normalizedQuery = SearchUtils.normalize(query?.toLowerCase() || '');
    const index = SearchUtils.normalize('' + value?.toLowerCase()).indexOf(normalizedQuery);
    if (index !== -1) return value.substr(0, index) + value.substr(index, normalizedQuery.length).bold() + value.substr(index + normalizedQuery.length);
    else return value;
  }
}
