import type { Timestamp } from '@greco-fit/firebase';

export const CONTACT_STATUS_CHANGES_COLLECTION = 'modules/identity/contact-status-changes';

export const CONTACT_STATUS = ['invited', 'active', 'inactive'] as const;
export type ContactStatus = (typeof CONTACT_STATUS)[number];

export interface ContactStatusChange {
  id: string;
  timestamp: Timestamp;

  contact: {
    id: string;
    email: string;
    displayName: string;

    community: {
      id: string;
      name: string;
    };
  };

  from: null | ContactStatus;
  to: ContactStatus;
}
