import { Injectable } from '@angular/core';
import { BuildDateFilter } from '@greco/ngx-filters';

@Injectable({ providedIn: 'any' })
export class PaymentDisputeCreatedDateFilter extends BuildDateFilter('PaymentDisputeCreatedDateFilter', {
  label: 'Payment Dispute Created Date',
  shortLabel: 'Created On',
  description: '',
  properties: ['created'],
}) {}
