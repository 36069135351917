<div [formGroup]="_form" class="greco-sale-category-input-container">
  <mat-chip-list #chipsList>
    <!-- <p *ngIf="value" style="vertical-align: bottom; padding: 0; margin: 0">
      {{ value.label }}
    </p> -->

    <!-- <button mat-icon-button (click)="removeCategory()" *ngIf="!disabled && value && value.communityId === communityId">
      <mat-icon>cancel</mat-icon>
    </button> -->
    <mat-icon *ngIf="value">category</mat-icon>
    <input
      #input
      matInput
      type="text"
      [value]="_inputValue"
      [disabled]="disabled"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      [matChipInputFor]="chipsList"
      (input)="_form.get('search')?.setValue(input.value)"
      (keyup)="removeCategory()"
    />
    <!-- (blur)="touched()" -->

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="
        optionSelected($event); input.value = value?.label || ''; _form.get('search')?.setValue(value?.label)
      "
    >
      <div *ngIf="!value">
        <mat-option *ngFor="let category of _categories$ | async" [value]="category">
          {{ category.label }}
        </mat-option>
      </div>
    </mat-autocomplete>
  </mat-chip-list>
</div>
