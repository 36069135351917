import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-users';

@Injectable()
export class EventUserResolver implements Resolve<User | null> {
  constructor(private userSvc: UserService) {}

  async resolve(_route: ActivatedRouteSnapshot): Promise<User | null> {
    return await this.userSvc.getSelf();
  }
}
