import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { RoomResource, RoomResourceSpot } from '@greco/booking-events';
import { Subject } from 'rxjs';

@Component({
  selector: 'greco-room-spots',
  templateUrl: './room-spots.component.html',
  styleUrls: ['./room-spots.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RoomSpotsComponent }],
})
export class RoomSpotsComponent implements ControlValueAccessor, OnDestroy {
  constructor(private _elementRef: ElementRef, @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'greco-room-spots';

  private _onTouched?: () => void;
  private _onChange?: (value: RoomResourceSpot[] | null) => void;
  private _onDestroy$ = new Subject<void>();
  readonly stateChanges = new Subject<void>();

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `${RoomSpotsComponent._controlType}-${RoomSpotsComponent._id++}`;
  readonly controlType = RoomSpotsComponent._controlType;

  @Input() room: RoomResource | null = null;
  @Input() canUpdate = false;
  @Input() fromCreate = false;

  _required = true;
  _readonly = false;

  private _value: RoomResourceSpot[] | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: RoomResourceSpot[] | null) {
    this._value = value;

    if (value && value.length) {
      this.selected = value;
      this.selected = this.selected.sort((a, b) => (a.spotNumber > b.spotNumber ? 1 : -1));
      this.selectedIds = this.selected.map(spot => spot.name);
    }
    if (!this.selected?.length) this.addToSelected('', '');

    this._onChange?.(value);
    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.focused || !!this._input?.value || (this.value?.length && !this._input?.value) || false;
  }

  get errorState() {
    return (
      !!this.ngControl?.touched &&
      (!!Object.keys(this.ngControl?.errors || {}).length || (this.required && !this.value)) &&
      !!this.selected.some(spot => !spot.name)
    );
  }

  selected: RoomResourceSpot[] = [];
  selectedIds: string[] = [];

  addToSelected(name: string, description: string) {
    const spotNumber = this.selected.length + 1;
    this.selected.push({
      spotNumber,
      id: '',
      name: name || 'Spot ' + spotNumber,
      description,
      roomId: this.room?.id || '',
    } as RoomResourceSpot);
    this.selectedIds.push(name);
    this.renumberSpots();
    this.value = [...this.selected];
  }

  removeFromSelected(index: number) {
    this.selected.splice(index, 1);
    this.selectedIds.splice(index, 1);
    this.renumberSpots();
    this.value = [...this.selected];
  }

  editName(name: string, index: number) {
    this.selected[index].name = name;
    this.value = [...this.selected];
  }

  editDescription(description: string, index: number) {
    this.selected[index].description = description;
    this.value = [...this.selected];
  }

  renumberSpots() {
    let number = 1;
    this.selected.forEach(spot => {
      spot.spotNumber = number;
      number++;
    });
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.room-spots-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: RoomResourceSpot[]): void {
    this.value = value;
  }

  registerOnChange(fn: (value: RoomResourceSpot[] | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
