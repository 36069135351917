import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { toPromise } from '@greco-fit/util';
import { PaymentMethod } from '@greco/finance-payments';
import {
  CreatePaymentMethodDialog,
  CreatePaymentMethodDialogData,
} from '../../dialogs/create-payment-method/create-payment-method.dialog';
import { PAYMENT_METHOD_FORMS, PaymentMethodForm } from './payment-method-forms.token';

@Component({
  selector: 'greco-add-payment-method-button',
  templateUrl: './add-payment-method-button.component.html',
  styleUrls: ['./add-payment-method-button.component.scss'],
})
export class AddPaymentMethodComponent {
  constructor(@Inject(PAYMENT_METHOD_FORMS) public readonly forms: PaymentMethodForm[], private matDialog: MatDialog) {}

  @Input() hideIcon = false;
  @Input() onlyIcon = false;
  @Input() color?: string = 'primary';

  @Input() hiddenForms: string[] = [];

  @Input() userId?: string;
  @Input() accountId?: string;
  @Output() saved = new EventEmitter<undefined | PaymentMethod>(true);

  @ViewChild(MatMenuTrigger) private _menu?: MatMenuTrigger;

  async open({ form, gateway }: PaymentMethodForm) {
    if (this._menu?.menuOpen) this._menu.closeMenu();

    const dialog = this.matDialog.open(CreatePaymentMethodDialog, {
      width: '512px',
      maxWidth: 'calc(80vw)',
      data: {
        form,
        gatewayId: gateway,
        userId: this.userId,
        accountId: this.accountId,
      } as CreatePaymentMethodDialogData,
    });

    const result = await toPromise(dialog.afterClosed());
    this.saved.emit(result || null);
  }

  openMenu() {
    this._menu?.openMenu();
  }
}
