import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VimeoService } from './services';
@NgModule({
  imports: [CommonModule],
  providers: [VimeoService],
  declarations: [],
  exports: [],
})
export class VimeoModule {
  static forRoot() {
    return {
      ngModule: VimeoModule,
      providers: [],
    };
  }
}
