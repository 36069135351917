<div class="course-filters">
  <div class="filter-row">
    <!-- Search -->
    <greco-courses-search (searchEvent)="searchQuery = $event"></greco-courses-search>
    <!-- Community selector  -->
    <mat-form-field appearance="outline">
      <mat-label>Communities</mat-label>
      <greco-user-community-picker
        #picker
        [user]="user"
        [required]="true"
        [formControl]="communitiesControl"
        [autoSelectUserCommunities]="!user"
      ></greco-user-community-picker>
    </mat-form-field>
  </div>

  <div style="margin: 0 24px">
    <ng-container *grecoLet="((userCalendars$ | async) || []) as calendars">
      <ng-container *ngIf="(calendars?.length || 0) > 0">
        <h3 style="margin-bottom: 12px; margin-top: 8px">Schedules</h3>

        <ng-container *grecoLet="isMobile$ | async as isMobile">
          <mat-form-field *ngIf="isMobile" class="calendar-form-field" appearance="outline">
            <mat-select [value]="selectedCalendar?.id || ''">
              <mat-option
                *ngFor="let calendar of calendars"
                class="calendar-option"
                [value]="calendar.id"
                (click)="selectCalendar(calendar)"
              >
                <div style="display: flex; align-items: center">
                  <mat-icon>{{calendar.icon || 'calendar_month'}}</mat-icon>
                  <div style="flex: 1; display: flex; flex-direction: column; line-height: 1.2">
                    <span>{{calendar.title}}</span>
                    <span style="font-size: 80%">{{calendar.community?.name}}</span>
                  </div>
                  <mat-icon class="chevron-icon">chevron_right</mat-icon>
                </div>
              </mat-option>

              <mat-select-trigger *ngIf="selectedCalendar" class="calendar-select-trigger">
                <div style="display: flex; align-items: center; gap: 16px">
                  <mat-icon>{{selectedCalendar.icon || 'calendar_month'}}</mat-icon>
                  <div style="flex: 1; display: flex; flex-direction: column; line-height: 1.2">
                    <span>{{selectedCalendar.title}}</span>
                    <span style="font-size: 80%">{{selectedCalendar.community?.name}}</span>
                  </div>
                </div>
              </mat-select-trigger>
            </mat-select>
          </mat-form-field>

          <div *ngIf="!isMobile" class="grid-container">
            <div *ngFor="let calendar of calendars">
              <button
                mat-stroked-button
                class="calendar-button"
                [class.selected-calendar]="calendar.id === selectedCalendar?.id"
                [color]="calendar.id === selectedCalendar?.id ? 'primary' : undefined"
                (click)="selectCalendar(calendar)"
              >
                <div class="calendar-button-content">
                  <mat-icon>{{calendar.icon || 'calendar_month'}}</mat-icon>
                  <span class="calendar-title">{{calendar.title}}</span>
                  <span class="calendar-location">
                    <mat-icon>place</mat-icon>
                    {{calendar.community?.name}}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!-- Grid -->
  <div class="courses-grid">
    <greco-courses-grid
      *ngIf="_selectedCommunities$ | async as selectedCommunities"
      [user]="user"
      [searchQuery]="searchQuery"
      [communityIds]="selectedCommunities"
      [selectedCalendar]="selectedCalendar"
    ></greco-courses-grid>
  </div>
</div>
