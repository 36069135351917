import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommunityAgreement } from '@greco/community-agreements';
import { CommunityAgreementsService } from '../../services';

@Component({
  selector: 'greco-agreement-page',
  templateUrl: './agreement.page.html',
  styleUrls: ['./agreement.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AgreementPage implements OnChanges {
  constructor(private agreementSvc: CommunityAgreementsService) {}

  @Output() updated = new EventEmitter<CommunityAgreement>(true);

  @Input() communityId!: string;
  @Input() agreement!: CommunityAgreement;

  canUpdateDetails = false;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.agreement?.currentValue !== changes.agreement?.previousValue) {
      await Promise.all([this.loadCanUpdateDetails()]);
    }
  }

  private async loadCanUpdateDetails() {
    return true;
    // this.canUpdateDetails = await this.agreementSvc.hasAccess(
    //   this.communityId,
    //   CommunitySecurityRoleResource.key,
    //   CommunitySecurityRoleActions.UPDATE_DETAILS
    // );
  }
}
