import { Pipe, PipeTransform } from '@angular/core';
import { VariantTransfer, VariantTransferTrigger } from '@greco/sales-products';

@Pipe({ name: 'grecoVariantTransferTrigger' })
export class VariantTransferTriggerPipe implements PipeTransform {
  transform(value: VariantTransfer): string {
    return (value.triggerEvents || [])
      .map(trigger => {
        switch (trigger) {
          case VariantTransferTrigger.Initial:
            return 'On Initial Purchase';
          case VariantTransferTrigger.Renewal:
            return 'On Subscription Renewal';
          default:
            return '';
        }
      })
      .join(', ');
  }
}
