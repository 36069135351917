import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypeformService } from '../../services';

@Component({
  selector: 'greco-typeform-connect-form-dialog',
  templateUrl: './connect-form.dialog.html',
  styleUrls: ['./connect-form.dialog.scss'],
})
export class ConnectFormDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private accountId: string,
    private ref: MatDialogRef<ConnectFormDialog>,
    private typeformSvc: TypeformService,
    private snacks: MatSnackBar
  ) {}

  forms: { id: string; title: string }[] = [];

  selectedForm?: string;

  connecting = false;

  async ngOnInit() {
    this.forms = await this.typeformSvc.getForms(this.accountId);
  }

  close() {
    this.ref.close();
  }

  async connect() {
    if (!this.selectedForm) return;
    this.connecting = true;

    try {
      const result = await this.typeformSvc.connectForm(this.accountId, this.selectedForm);
      this.snacks.open('Form connected!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.ref.close(result);
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 300, panelClass: 'mat-warn' });
    }

    this.connecting = false;
  }
}
