<div class="stats-container" *grecoLet="stats$ | async as stats">
  <greco-box>
    <h3>Perks in circulation</h3>
    <p>{{stats ? (stats.perksInCirculation) : '-'}}</p>
    <p style="font-size: 75%">{{stats?((stats.totalReusable/stats.perksInCirculation)|percent): '-'}} reusable</p>
  </greco-box>

  <greco-box>
    <h3>Number of users with perks</h3>
    <p>{{stats ? ((stats.numberOfUserswPerks )) : '-'}} Users</p>
    <p style="font-size: 75%">{{stats? (stats.averagePerksPerUser|number: '1.0-0') : '-'}} perks per user average</p>
  </greco-box>
</div>

<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]);onFilterApplied()"
>
  <div
    *ngIf="canManageUserPerks$ | async"
    style="display: flex; align-items: center; justify-content: flex-end; height: 32px"
  >
    <mat-menu #purchaseMenu>
      <button mat-menu-item color="primary" *grecoLet="userperks$ | async as userperks" (click)="grantPerk()">
        <mat-icon>add</mat-icon>
        <span>Grant Perk</span>
      </button>
      <!-- <button mat-menu-item color="primary">
        <mat-icon>delete</mat-icon>
        <span>Remove Perks</span>
      </button> -->
    </mat-menu>

    <button mat-stroked-button [matMenuTriggerFor]="purchaseMenu" color="primary" style="height: 32px">
      Quick Actions
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </div>
</greco-filter-bar>

<greco-table [data]="(userperks$ | async) || []" [highlight]="false" [loading]="loading">
  <div class="badge-col" *grecoTableCol="''; let userperk; fitContent: true" (click)="updatePerkBadge(userperk)">
    <greco-perk-badge
      [small]="true"
      [text]="userperk.perk.badge.text"
      [color]="userperk.perk.badge.color"
      [shape]="userperk.perk.badge.shape"
      [icon]="userperk.perk.badge.icon"
    ></greco-perk-badge>
    <mat-icon class="edit-icon">create</mat-icon>
  </div>

  <ng-container *grecoTableCol="'User'; let userperk">
    <a
      *ngIf="userperk.user"
      [matTooltip]=" userperk.user?.contactEmail"
      [routerLink]="['/admin/community', communityId, userperk.user?.id]"
      (click)="$event.stopImmediatePropagation()"
    >
      {{userperk.user?.displayName || 'All Users'}}
    </a>
    <p *ngIf="!userperk.user">All Users</p>
  </ng-container>

  <p *grecoTableCol="'Perk title '; let userperk" [matTooltip]=" userperk.perk.id">{{userperk.perk.title}}</p>

  <mat-icon *grecoTableCol="'Reusable'; let userperk; alignCenter: true">
    {{ userperk.consumable ? '' : 'check_circle' }}
  </mat-icon>

  <p *grecoTableCol="'Granted Date'; let userperk" [matTooltip]="(userperk.created|date:'full')||''">
    {{ userperk.created | date }}
  </p>

  <p *grecoTableCol="'Expiry Date'; let userperk" [matTooltip]="(userperk.expiryDate|date:'full')||''">
    {{ userperk.expiryDate | date }}
  </p>

  <p *grecoTableCol="'Granted By'; let userperk" [matTooltip]="userperk.grantedBy">
    <ng-container *ngIf="userperk.grantedBy | test: '^subitm_'; else fromPurchase">Subscription</ng-container>

    <ng-template #fromPurchase>
      <ng-container *ngIf="userperk.grantedBy | test: '^puritm_'; else fromStaff">One-Time Purchase</ng-container>
    </ng-template>

    <ng-template #fromStaff>
      {{ userperk?.grantedByUser?.displayName || (userperk.grantedBy ? 'Staff' : '') }}
    </ng-template>
  </p>

  <ng-container *ngIf="canManageUserPerks$ | async">
    <ng-container *grecoTableCol="''; let userperk; fitContent: true; stickyEnd: true">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <button mat-menu-item [disabled]="userperk.perk.community?.id !== communityId" (click)="_removePerks(userperk)">
          <mat-icon>delete</mat-icon>
          <span>Remove Perk</span>
        </button>
      </mat-menu>
    </ng-container>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [pageSizeOptions]="[10, 20, 50]"
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
