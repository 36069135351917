<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <!-- Title -->
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput required formControlName="title" />
    </mat-form-field>

    <!-- Amount -->
    <mat-form-field>
      <mat-label>Amount</mat-label>
      <input matInput required currencyMask formControlName="amount" [options]="currencyMaskConfig" />
    </mat-form-field>

    <div id="amount-container">
      <mat-form-field id="send-receive">
        <mat-select required formControlName="sendReceive">
          <mat-option value="send">Send To</mat-option>
          <mat-option value="receive">Receive From</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Account -->
      <mat-form-field id="amount">
        <mat-label>Account</mat-label>
        <greco-account-search-component
          [required]="true"
          formControlName="account"
          [excluded]="[product.community.financeAccount]"
        ></greco-account-search-component>
      </mat-form-field>
    </div>

    <!-- Trigger -->
    <mat-form-field>
      <mat-label>Trigger</mat-label>
      <mat-select multiple required formControlName="trigger">
        <mat-option value="INITIAL">On Initial Purchase</mat-option>
        <mat-option *ngIf="!!variant.recurrence?.frequency" value="RENEWAL">On Subscription Renewal</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="variant.recurrence?.frequency" style="margin-bottom: 16px">
      <mat-label>Reverse & Proration Behaviour</mat-label>
      <mat-select required formControlName="prorateTrigger">
        <mat-option value="NEVER">Never</mat-option>
        <mat-option value="ALWAYS">Always</mat-option>
        <mat-option value="CUSTOMER_REFUNDED">When Customer Refunded</mat-option>
      </mat-select>
      <mat-hint>
        Reverse (prorated) this fee when the subscription is cancelled/updated in the middle of a period?
      </mat-hint>
    </mat-form-field>

    <div *ngIf="form.valid" class="preview">
      {{form.value.sendReceive === 'send' ? 'Transfer ' : 'Receive '}}<strong>{{form.value.amount | currency}}</strong>
      {{form.value.sendReceive === 'send' ? ' to ' : ' from '}} <strong>{{form.value.account.name}}</strong> when
      <ng-container
        *ngTemplateOutlet="form.value.trigger.length === 2 ? both : form.value.trigger[0] === 'INITIAL' ? initial : renewal"
      ></ng-container>
      <ng-template #both>
        a user initially purchases <strong>{{product.title}}</strong> or their subscription renews
      </ng-template>
      <ng-template #initial>a user initially purchases <strong>{{product.title}}</strong></ng-template>
      <ng-template #renewal>a user's subscription to <strong>{{product.title}}</strong> renews</ng-template>
    </div>

    <mat-checkbox labelPosition="before" formControlName="applyRoyalties" color="primary">
      Apply Royalties:
    </mat-checkbox>

    <div class="buttons">
      <button type="button" mat-stroked-button [disabled]="submitting" (click)="cancel()">Cancel</button>
      <button type="submit" mat-stroked-button color="primary" [disabled]="form.invalid || submitting">
        <mat-icon *ngIf="submitting" class="spin">loop</mat-icon>
        <span>Confirm</span>
      </button>
    </div>
  </form>
</greco-simple-dialog>
