import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { LetModule } from '@greco/ngx-let-util';
import { ScheduleInputComponentModule } from '@greco/ngx-schedule';
import { SecurityUtilsModule } from '@greco/ngx-security-util';
import { AddressInputModule } from '@greco/ui-address-input';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { TableModule } from '@greco/ui-table';
import { NgxMaskModule } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import { AccountPaymentMethodsComponent, AccountSearchComponentModule } from './components';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { AccountReconciliationConfigComponent } from './components/account-reconciliation-config/account-reconciliation-config.component';
import { AccountRoyaltiesComponent } from './components/account-royalties/account-royalties.component';
import { AddRoyaltiesDialog, CreateAccountDialog } from './dialogs';
import { AccountNameFilter, AccountPage, AccountsPage } from './pages';
import { AccountServiceModule } from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    TableModule,
    MatIconModule,
    MatButtonModule,
    SafePipeModule,
    MatMenuModule,
    MatTooltipModule,
    MatTabsModule,
    LetModule,
    AccountServiceModule,
    NgPipesModule,
    MatPaginatorModule,
    MatChipsModule,
    MatDialogModule,
    MatRadioModule,
    SimpleDialogModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatFormFieldModule,

    // Greco
    LetModule,
    TableModule,
    FiltersModule,
    PaymentsModule,
    SafePipeModule,
    SimpleDialogModule,
    AddressInputModule,
    SecurityUtilsModule,
    AccountServiceModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,
    CollapsibleSectionModule,
    ScheduleInputComponentModule,
    AccountSearchComponentModule,

    // Other
    NgxMaskModule,
    NgPipesModule,
  ],
  declarations: [
    AccountsPage,
    AccountPage,
    CreateAccountDialog,
    AccountDetailsComponent,
    AccountRoyaltiesComponent,
    AddRoyaltiesDialog,
    AccountPaymentMethodsComponent,
    AccountReconciliationConfigComponent,
  ],
  exports: [AccountsPage, AccountPage],
  providers: [AccountNameFilter],
})
export class AccountsModule {}
