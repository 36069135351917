import { BreakpointObserver } from '@angular/cdk/layout';
import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { AccountLink, AccountLinkStatus } from '@greco/account-linking';
import {
  BookingOption,
  CourseRegistration,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventSeries,
  UserBookingOptionStats,
  bookingOptionCancellationPolicyLabel,
  calculateBoosterRequirements,
  getCancellationPolicyInnerHtml,
  isAvailableNow,
} from '@greco/booking-events';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { CourseConfirmationDto, CoursePreview } from '@greco/nestjs-booking-events';
import { PaymentMethodDialogs, UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { SignatureService } from '@greco/ngx-identity-users';
import { PerkService } from '@greco/ngx-sales-perks';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { AccountLinkingService } from '@greco/web-account-linking';
import * as moment from 'moment';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { UserAddGuestDialog } from '../../../dialogs';
import { MinutesPipe } from '../../../pipes';
import { BookingOptionService, BookingService, CourseService, EventService } from '../../../services';

interface UserBookingPreview {
  user: User;
  preview?: CoursePreview;
  signatures: { agreementId: string; signature: string }[];
  requirementValues$: BehaviorSubject<{ [id: string]: string }>;
  bookingOptionError?: boolean;
}
@Component({
  selector: 'greco-user-course-page',
  templateUrl: './user-course.page.html',
  styleUrls: ['./user-course.page.scss'],
})
export class UserCoursePage implements OnInit, OnDestroy {
  constructor(
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private perkSvc: PerkService,
    private matDialog: MatDialog,
    private eventSvc: EventService,
    private formBuilder: FormBuilder,
    private courseSvc: CourseService,
    private bookingSvc: BookingService,
    private securitySrv: SecurityService,
    private linkSvc: AccountLinkingService,
    private signatureSvc: SignatureService,
    private breakpoints: BreakpointObserver,
    private bookingOptionSvc: BookingOptionService,
    private paymentMethodSvc: UserPaymentMethodService,
    private paymentMethodDialogs: PaymentMethodDialogs
  ) {}
  @PropertyListener('user') private _user$ = new ReplaySubject<User>(1);
  @Input() user!: User;

  @PropertyListener('event') private _event$ = new ReplaySubject<EventSeries>(1);
  @Input() event!: EventSeries;

  @PropertyListener('previews') previews$ = new BehaviorSubject<UserBookingPreview[]>([]);
  previews: UserBookingPreview[] = [];

  @PropertyListener('selectedBookingOption') _selectedBookingOption$ = new ReplaySubject<BookingOption>(1);
  selectedBookingOption?: BookingOption;

  @PropertyListener('selectedPaymentMethod') selectedPaymentMethod$ = new BehaviorSubject<PaymentMethod | null>(null);
  selectedPaymentMethod: PaymentMethod | null = null;

  @Input() forceMobile = false;
  @Input() footerInPage = false;
  @Input() preventRedirect = false;
  @Input() allowPendingBookings = false;

  @Output() refreshed = new EventEmitter<void>();
  @Output() booked = new EventEmitter<CourseRegistration>();

  private readonly _onDestroy$ = new Subject<void>();
  readonly bookingOption$ = new ReplaySubject<BookingOption>(1);

  paginationMeta?: IPaginationMeta;
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });

  refresh$ = new BehaviorSubject(null);

  now = 0;

  confirming = false;

  coursePreview!: CoursePreview;

  signature: string | null = null;

  clashes: { [key: string]: boolean } = {};

  currentUserId$ = this.userSvc.getUserId();

  _stats!: { [id: string]: UserBookingOptionStats };

  paymentMethodControl = this.formBuilder.control([null]);

  private _firstRun = true;
  private startedWithPaymentMethod?: boolean;
  private startedWithEmailVerified?: boolean;

  isMobile$ = this.breakpoints.observe('(max-width: 1000px)').pipe(
    map(({ matches }) => matches),
    shareReplay(1)
  );

  courseBookings$ = this._event$.pipe(
    filter(event => event.availableAsCourse === true),
    switchMap(async event => {
      if (!event) return [];
      return await this.courseSvc.getAllRegistrationsByCourseId(event.id);
    })
  );

  parent$ = this._user$.pipe(
    switchMap(async user => {
      if (!user) return undefined;
      if (!user.email) {
        const links = (await this.linkSvc.getGivenLinksByAccount(user.id))?.filter(
          link => link.status === AccountLinkStatus.ACTIVE
        );
        if (links?.length) return links[0]?.accessor;
      }
      return user;
    })
  );
  private readonly emailVerified$ = this.parent$.pipe(map(user => user?.emailVerified || false));

  linkedAccounts$ = combineLatest([this.parent$, this.previews$, this.courseBookings$]).pipe(
    tap(async () => await new Promise(res => setTimeout(res, 500))),
    switchMap(async ([user, previews, courseBookings]) => {
      const links: AccountLink[] = [
        { account: user, status: AccountLinkStatus.ACTIVE } as AccountLink,
        ...(user
          ? (await this.linkSvc.getPrivilegeLinksForAccessor(user.id))?.filter(
              link => link.status === AccountLinkStatus.ACTIVE
            )
          : []),
      ];

      const linksAndStatus: {
        link: AccountLink;
        selected: boolean;
        booked: boolean;
      }[] = [];
      links.forEach(link => {
        linksAndStatus.push({
          link,
          selected: previews.some(preview => preview.user.id === link.account?.id),
          booked: courseBookings.some(cBooking => cBooking?.user?.id === link.account?.id),
        });
      });

      return linksAndStatus;
    })
  );

  readonly canBookComplimentary$ = combineLatest([this.parent$, this._event$]).pipe(
    switchMap(async ([user, event]) => {
      if (!user || !event) return false;
      return await this.securitySrv.hasAccess(
        EventBookingSecurityResource.key,
        EventBookingSecurityResourceAction.COMPLIMENTARY,
        { userId: user.id, communityId: event.community.id }
      );
    })
  );

  readonly hasPaymentMethod$ = combineLatest([this.parent$, this.refreshed.pipe(startWith(null))]).pipe(
    switchMap(async ([user]) => {
      const paymentMethod = user ? await this.paymentMethodSvc.getDefault(user.id, true) : null;
      if (paymentMethod && paymentMethod.model !== 'bank') return paymentMethod;
      else return null;
    }),
    distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
    tap(pm => this.paymentMethodControl.setValue(pm || null)),
    map(pm => !!pm),
    shareReplay(1)
  );

  readonly requiredBoosts$ = combineLatest([this._event$, this._selectedBookingOption$]).pipe(
    map(([event, bookingOption]) => {
      if (!event || !bookingOption) return -1;
      if (bookingOption.id === 'prk_complimentarybooking') return 0;
      return calculateBoosterRequirements(event, bookingOption);
    }),
    shareReplay(1)
  );

  readonly requirements$ = combineLatest([
    this._event$,
    this._selectedBookingOption$,
    this.requiredBoosts$,
    this.hasPaymentMethod$,
    this.emailVerified$,
    this.userSvc.getUserId(),
  ]).pipe(
    tap(([_event, _bookingOption, _requiredBoosts, hasPaymentMethod, emailVerified]) => {
      if (this._firstRun) {
        this._firstRun = false;
        this.startedWithPaymentMethod = !!hasPaymentMethod;
        this.startedWithEmailVerified = !!emailVerified;
      }
    }),
    map(([event, bookingOption, requiredBoosts, hasPaymentMethod, emailVerified, currentUserId]) => {
      return [
        // Cancellation Policy
        ...(bookingOption
          ? [
              {
                icon: 'confirmation_number',
                title: 'Your Booking Option',
                subtitle:
                  bookingOption.title +
                  (bookingOption.bookingWindow ? `(${new MinutesPipe().transform(bookingOption.bookingWindow)})` : '') +
                  (bookingOption.price
                    ? ' | ' + new CurrencyPipe('en-CA').transform(bookingOption.price / 100) + ' Booking Fee'
                    : '') +
                  (requiredBoosts
                    ? ' | ' + requiredBoosts + ' Booking Window Booster' + (requiredBoosts === 1 ? '' : 's')
                    : ''),
                buttonLabel: 'Review',
                completed: true,
                buttonAction: async () =>
                  toPromise(
                    this.matDialog
                      .open(SimpleDialog, {
                        maxWidth: 700,
                        data: {
                          showCloseButton: false,
                          title: 'Your Booking Option',
                          subtitle:
                            bookingOption.title +
                            ' (' +
                            new MinutesPipe().transform(bookingOption.bookingWindow) +
                            ')' +
                            (bookingOption.price
                              ? ' | ' + new CurrencyPipe('en-CA').transform(bookingOption.price / 100) + ' Booking Fee'
                              : '') +
                            (requiredBoosts
                              ? ' | ' + requiredBoosts + ' Booking Window Booster' + (requiredBoosts === 1 ? '' : 's')
                              : ''),
                          content: bookingOption.description,
                        } as DialogData,
                      })
                      .afterClosed()
                  ),
              },
            ]
          : []),

        // Cancellation Policy
        ...(bookingOption?.cancellation
          ? [
              {
                icon: 'info',
                title: 'Cancellation Policy',
                subtitle: bookingOptionCancellationPolicyLabel(bookingOption),
                buttonLabel: 'Review',
                completed: true,
                buttonAction: async () =>
                  toPromise(
                    this.matDialog
                      .open(SimpleDialog, {
                        maxWidth: 700,
                        data: {
                          showCloseButton: false,
                          title: 'Cancellation Policy',
                          subtitle: `${event.title} - ${moment(event.startDate).format('MMM Do, hh:mm A')}`,
                          content: getCancellationPolicyInnerHtml(bookingOption),
                        } as DialogData,
                      })
                      .afterClosed()
                  ),
              },
            ]
          : []),

        // Email Verification
        ...(!this.startedWithEmailVerified && this.user.id === currentUserId
          ? [
              {
                icon: 'email',
                title: 'Email Address',
                subtitle: 'Please verify your email address prior to booking.',
                buttonLabel: emailVerified ? null : 'Verify',
                completed: emailVerified,
                buttonAction: async () => {
                  try {
                    // await this.userSvc.sendEmailVerification();
                    this.snacks.open(`Email verification sent to ${this.user.contactEmail}`, 'Ok', {
                      panelClass: 'mat-primary',
                      duration: 2500,
                    });
                  } catch (err) {
                    console.error(err);
                    this.snacks.open('Something went wrong. Please try again later.', 'Ok', { duration: 3000 });
                  }
                },
              },
            ]
          : []),

        // Payment Method
        ...(!this.startedWithPaymentMethod &&
        bookingOption?.id !== 'prk_complimentarybooking' &&
        (bookingOption.price || bookingOption.cancellationPrice || requiredBoosts)
          ? [
              {
                icon: 'payment',
                title: 'Add a Payment Method',
                subtitle: 'Your card will never be charged without your authorization.',
                buttonLabel: hasPaymentMethod ? null : 'Add',
                completed: hasPaymentMethod,
                buttonAction: async () => {
                  await this.paymentMethodDialogs.addCreditCard(this.user.id);
                  this.user = { ...this.user };
                },
              },
            ]
          : []),
      ];
    }),
    shareReplay(1)
  );

  readonly bookingOptions$ = combineLatest([this._user$, this._event$, this.canBookComplimentary$]).pipe(
    switchMap(async ([user, event, canBookComplimentary]) => {
      const complimentary = canBookComplimentary ? await this.bookingOptionSvc.getComplimentaryOption() : null;
      const result = [
        event,
        //TODO dynamic assignment of value for is course option
        user && event ? await this.eventSvc.getBookingOptionsBySeries(event.id) : [],
      ] as [EventSeries, (BookingOption & { userPerks?: any })[]];
      if (complimentary) result[1].push({ ...complimentary, userPerks: { hasUnlimited: true } });
      return result;
    }),
    map(([event, bookingOptions]) => {
      const options = bookingOptions
        .filter(opt => isAvailableNow(event, opt))
        .sort((a, b) => {
          // Complimentary at the end
          if (a.id === 'prk_complimentarybooking') return 1;
          if (b.id === 'prk_complimentarybooking') return -1;

          // Free before cost
          if (!!a.price !== !!b.price) return a.price ? 1 : -1;

          // Less costly
          if (a.price && b.price) return a.price - b.price;

          // Unlimited before consumable
          if (a?.userPerks?.hasUnlimited !== b?.userPerks?.hasUnlimited) {
            return a?.userPerks?.hasUnlimited ? -1 : 1;
          }

          return a.title.localeCompare(b.title);
        });

      return options.some(opt => !opt.price && opt.id !== 'prk_complimentarybooking')
        ? options.filter(opt => !opt.price)
        : options;
    }),
    tap(async opts => {
      const bookingOptionIds = opts.map(opt => opt.id);
      const stats = await this.bookingOptionSvc.getUserBookingOptionStats(this.user.id, bookingOptionIds);
      this._stats = stats.reduce((acc, stat) => ({ ...acc, [stat.bookingOptionId]: stat }), {});
    }),
    shareReplay(1),
    tap(options => (this.selectedBookingOption = options[0])) // this is the default.
  );

  readonly availableIn$ = combineLatest([this._event$, this._selectedBookingOption$, this.requiredBoosts$]).pipe(
    map(([event, bookingOption, requiredBoosts]) => {
      if (!event || !bookingOption || requiredBoosts === -1) return null;
      if (!bookingOption.maxBoost || requiredBoosts <= bookingOption.maxBoost) return 'Now';
      return moment().to(
        moment(event.startDate)
          .subtract(bookingOption.bookingWindow, 'minutes')
          .subtract(bookingOption.maxBoost === -1 ? 0 : bookingOption.maxBoost, 'days')
      );
    })
  );

  readonly canBook$ = combineLatest([
    this._event$,
    this._selectedBookingOption$,
    this.requiredBoosts$,
    this.requirements$,
  ]).pipe(
    map(([event, bookingOption, requiredBoosts, requirements]) => {
      if (!event || !bookingOption || requiredBoosts === -1) return false;
      if (requiredBoosts && bookingOption.maxBoost === -1) return false;
      if (bookingOption.maxBoost !== -1 && requiredBoosts > (bookingOption.maxBoost || Infinity)) return false;
      if (requiredBoosts && bookingOption.maxBoost === -1) return false;
      return requirements.every(req => req.completed);
    })
  );

  isValid$ = this.previews$.pipe(
    map(previews => {
      for (const preview of previews) {
        if (!preview.preview) return false;
        if (preview.preview.errors.length) return false;
      }
      return true;
    })
  );

  remainingCapacity$ = combineLatest([this._event$, this.previews$]).pipe(
    switchMap(async ([event]) => {
      if (!event) return 0;
      const maxCapacity = event.maxCapacity;
      const courseBookings = await this.courseSvc.getAllRegistrationsByCourseId(event.id);
      const bookingCount = courseBookings?.length || 0;
      return maxCapacity - bookingCount;
    })
  );

  userBookingOptions$ = this.parent$.pipe(
    switchMap(async user => {
      return user ? await this.perkSvc.availablePerks(user.id) : [];
    })
  );

  hasBookingOptionError$ = this.previews$.pipe(
    map(previews => {
      const indexes: number[] = [];
      for (let i = 0; i < previews.length; i++) {
        const preview = previews[i];
        if (preview.bookingOptionError) {
          indexes.push(i);
        }
      }
      if (indexes.length) return { error: true, indexes };
      return { error: false, indexes };
    })
  );

  checkForMultiBookingOptionError(indexes: number[], index: number) {
    return indexes.some(i => i === index);
  }

  async confirmRegistration() {
    if (this.confirming) return;

    this.confirming = true;
    const dtos: CourseConfirmationDto[] = [];
    for (const preview of this.previews) {
      if (!preview.preview) continue;

      if (preview.preview.warnings?.length) {
        const dialog = this.matDialog.open(SimpleDialog, {
          data: {
            title: 'Warnings:',
            content: preview.preview.warnings
              .map(warn => `<p>${warn} for <b>${preview.user.displayName}</b> </p>`)
              .join(''),
            buttons: [
              { label: 'No, I want to make some changes first', role: 'no' },
              { label: 'Yes, Confirm the booking', role: 'yes' },
            ],
          } as DialogData,
        });
        const result = await toPromise(dialog.afterClosed());

        if (!result || result === 'no') {
          this.confirming = false;
          return;
        }
      }

      const requirements = Object.entries(preview.requirementValues$.value || {}).reduce(
        (acc, [key, value]) => [...acc, { requirementId: key, value }],
        [] as { requirementId: string; value: string }[]
      );

      await this.signAgreement();

      const dto = {
        booking: { ...preview.preview.dto, requirements },
        purchaseHash: preview.preview.purchase?.hash,
        bookingHash: preview.preview.hash,
      };
      dtos.push(dto);
    }

    try {
      if (dtos.length) {
        const courseRegistration = await this.courseSvc.confirmMultiple(dtos);

        for (const registration of courseRegistration) {
          this.booked.emit(registration);
        }
      } else {
        this.snacks.open(
          this.preventRedirect
            ? 'Oops, failed to confirm this booking! ' // + (courseRegistration.purchases?.[0]?.failureReason || '')
            : 'Oops, failed to confirm your booking! ', // + (courseRegistration.purchases?.[0]?.failureReason || ''),
          'Ok',
          { duration: 10000, panelClass: 'mat-warn' }
        );
      }
      this.snacks.open(this.preventRedirect ? 'Attendee added!' : 'Course added to your schedule!', 'Ok', {
        duration: 5000,
        panelClass: 'mat-primary',
      });
    } catch (err: any) {
      const errMessage: string = err.error.message;
      console.error(err);
      this.snacks.open('Oops, failed to confirm your booking! ' + errMessage, 'Ok', {
        duration: 10000,
        panelClass: 'mat-warn',
      });
    }
    this.confirming = false;
  }

  async signAgreement() {
    const user = await toPromise(this.parent$);
    if (user && this.signature) {
      let signature: any = null;

      try {
        signature = await this.signatureSvc.getOne(user?.id);
      } catch (err) {
        console.log('No signature found for user, creating default now');
      }

      if (signature) {
        if (this.signature !== signature.signature) await this.signatureSvc.update(user?.id, this.signature);
      } else
        await this.signatureSvc.create({
          userId: user.id,
          signature: this.signature,
        });
    }
  }

  async selected(selectedUser: User) {
    const userInPreviews = this.previews.some(preview => preview.user.id === selectedUser.id);
    if (userInPreviews) {
      this.snacks.open('This user has already been added to bookings.', '', {
        duration: 6000,
        panelClass: 'mat-warn',
      });
    }

    if (!this.clashes[selectedUser.id]) {
      this.previews = [
        ...this.previews,
        {
          user: selectedUser,
          signatures: [],
          requirementValues$: new BehaviorSubject<{ [id: string]: string }>({}),
        },
      ];
    }
  }

  async addGuest() {
    const user = await toPromise(this.parent$);
    const dialog = this.matDialog.open(UserAddGuestDialog, {
      data: { user: user, event: this.event },
    });
    const response: User | undefined | null = await toPromise(dialog.afterClosed());
    if (response) this.selected(response);
  }

  removeUser(removedUser: User) {
    this.previews = [...this.previews.filter(preview => preview.user.id !== removedUser.id)];
  }

  async previewChanged(index: number, preview: CoursePreview | null) {
    if (preview !== null && this.previews[index].user.id === preview.dto.userId) {
      let bookingOptionError = undefined;

      if (preview.dto.useBookedByPerks) {
        const userPerks = await toPromise(this.userBookingOptions$);

        const perkId = preview.dto.bookingOptionId;

        const usedCount = this.previews.filter(
          p => p?.preview?.dto.bookingOptionId === perkId && p?.preview?.dto.useBookedByPerks === true
        ).length;

        const availableCount = userPerks.filter(up => up.perk?.id === perkId).length;

        let reusable = false;
        for (const userperk of userPerks.filter(userPerk => userPerk.id === perkId)) {
          if (!userperk.consumable) {
            reusable = true;
            break;
          }
        }

        if (!reusable && usedCount + 1 > availableCount) {
          bookingOptionError = true;
        }
      }

      this.previews[index].preview = preview;
      this.previews[index].bookingOptionError = bookingOptionError;

      if (this.clashes[this.previews[index].user.id]) this.removeUser(this.previews[index].user);
      else this.previews = [...this.previews];
    } else throw new Error(`Preview not available for user at index: ${index}`);
  }

  signaturesChanged(index: number, signatures: { agreementId: string; signature: string }[]) {
    this.previews[index].signatures = signatures;
    this.previews = [...this.previews];
  }

  requirementsChanged(index: number, requirementValues$: BehaviorSubject<{ [id: string]: string }>) {
    this.previews[index].requirementValues$ = requirementValues$;
    this.previews = [...this.previews];
  }

  selectedPaymentMethodChanged(paymentMethod: PaymentMethod | null) {
    this.selectedPaymentMethod = paymentMethod;
  }

  async ngOnInit() {
    this.now = Date.now();
    const courseBookings = await toPromise(this.courseBookings$);
    const linkedAccounts = await toPromise(this.linkedAccounts$);

    if (!linkedAccounts?.length || !courseBookings.some(booking => booking?.user?.id === this.user.id)) {
      this.previews = [
        {
          user: this.user,
          signatures: [],
          requirementValues$: new BehaviorSubject<{ [id: string]: string }>({}),
        },
      ];
    }

    this.clashes[this.user.id] = await this.courseSvc.checkForBookingClash(this.event.id, this.user.id);
    await Promise.all(
      linkedAccounts.map(async accountLink => {
        this.clashes[accountLink.link.accountId] = await this.courseSvc.checkForBookingClash(
          this.event.id,
          accountLink.link.accountId
        );
      })
    );
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this._user$.complete();
    this._event$.complete();
    this._selectedBookingOption$.complete();
  }
}
