<div
  class="course-card"
  (click)="goToDetails()"
  style="cursor: pointer; padding-bottom: 15px"
  *grecoLet="canRegister$ | async as canRegister"
>
  <div *ngIf="course" class="content">
    <div class="image-container">
      <img [src]="course.imageURL || 'assets/lf3/icon_square_pad.png'" />
    </div>
    <div class="course-info">
      <div>
        <div class="info-header">
          <h4 class="title" [matTooltip]="course.title">{{ course.title }}</h4>
        </div>
        <p class="date-range" style="line-height: 8px" *ngIf="course.startDate">
          {{ course.startDate | date }} - {{ course.endDate | date }}
        </p>
        <greco-course-week [course]="course"> </greco-course-week>
      </div>

      <div class="button-container" *grecoLet="course.maxCapacity - registrations as spotsLeft">
        <div style="display: flex; gap: 0.7rem">
          <!-- Already Bookd Button -->
          <a *ngIf="!canRegister" mat-stroked-button color="primary" [routerLink]="['/']">
            Already Booked <mat-icon>launch</mat-icon>
          </a>
          <!-- Book Another Button -->
          <a
            *ngIf="!canRegister && registrations < course.maxCapacity; else closed"
            mat-raised-button
            color="primary"
            [routerLink]="[course.id]"
            (click)="$event.stopImmediatePropagation()"
          >
            <div class="register" [style.margin]="(spotsLeft || 0) > 0 ? '4px 0' : ''">
              Book Another
              <small>
                Only <strong>{{ spotsLeft }}</strong> spot{{ spotsLeft === 1 ? '' : 's' }} left!
              </small>
            </div>
          </a>
          <!-- Book another else Button -->
          <ng-template #closed style="pointer-events: none; z-index: -1">
            <a
              [routerLink]="[course.id]"
              *ngIf="registrations >= course.maxCapacity"
              [disableRipple]="true"
              mat-stroked-button
              [matTooltip]="'Registration Closed'"
              color="primary"
            >
              View Details
            </a>
          </ng-template>
        </div>
        <button
          *ngIf="canRegister && registrations < course.maxCapacity"
          mat-flat-button
          color="primary"
          class="button"
        >
          <div [routerLink]="[course.id]" class="register" [style.margin]="(spotsLeft || 0) > 0 ? '4px 0' : ''">
            Register Now
            <small *ngIf="course.maxCapacity > registrations">
              Only <strong>{{ spotsLeft }}</strong> spot{{ spotsLeft === 1 ? '' : 's' }} left!
            </small>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- canRegister checks if the user itself has registerd into the event -->
