import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { AccountTax, CommunityTaxSecurityActions, CommunityTaxSecurityResource, Tax } from '@greco/finance-tax';
import { Community } from '@greco/identity-communities';
import { AccountService } from '@greco/ngx-finance-accounts';
import {
  AccountTaxService,
  SelectTaxDialog,
  TaxService,
  UpdateAccountTaxDialog,
  UpdateTaxDialog,
} from '@greco/ngx-finance-tax';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { CommunityService } from '../../services/community/community.service';
@Component({
  selector: 'greco-community-finance-settings-page',
  templateUrl: './community-finance-settings.page.html',
  styleUrls: ['./community-finance-settings.page.scss'],
})
export class CommunityFinanceSettingsPage implements OnDestroy {
  constructor(
    private communitySvc: CommunityService,
    private userSvc: UserService,
    private accountSvc: AccountService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService,
    private accTaxSvc: AccountTaxService,
    private taxSvc: TaxService
  ) {}

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string>(1);
  @Input() communityId!: string;

  loading = false;
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });

  isSuperAdmin$ = this.userSvc.user$.pipe(switchMap(async user => (user ? user.isSuperAdmin : false)));

  canRead$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CommunityTaxSecurityResource.key, CommunityTaxSecurityActions.READ, {
            communityId,
          })
        : false;
    })
  );

  canCreate$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CommunityTaxSecurityResource.key, CommunityTaxSecurityActions.ADD, {
            communityId: communityId,
          })
        : false;
    })
  );

  canUpdate$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CommunityTaxSecurityResource.key, CommunityTaxSecurityActions.UPDATE, {
            communityId: communityId,
          })
        : false;
    })
  );

  canDelete$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(CommunityTaxSecurityResource.key, CommunityTaxSecurityActions.DELETE, {
            communityId: communityId,
          })
        : false;
    })
  );

  communityTaxList$ = combineLatest([this._communityId$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([communityId, pagination]) => {
      const community: Community | null = communityId ? await this.communitySvc.getCommunity(communityId) : null;
      if (!community) return [];
      return (await this.accTaxSvc.paginate(new RequestQueryBuilder(), community.financeAccountId, pagination)).items;
    }),
    tap(() => (this.loading = false))
  );

  platformTaxes$ = this.communityTaxList$.pipe(
    switchMap(async accountTaxes => {
      const platformTaxes = await this.taxSvc.getTaxes();
      const taxes: Tax[] = [];
      platformTaxes.forEach(tax => {
        if (!accountTaxes.some(accTax => accTax.taxId === tax.id)) taxes.push(tax);
      });
      return taxes;
    })
  );

  async addTax(tax: Tax) {
    const community = await this.communitySvc.getCommunity(this.communityId);
    const dialog = this.matDialog.open(UpdateAccountTaxDialog);
    const result = await toPromise(dialog.afterClosed());
    if (result) {
      await this.accTaxSvc.addTax({
        taxId: tax.id,
        financeAccountId: community.financeAccountId,
        isDefault: false,
        taxNumber: result.taxNumber,
      });
      this.page$.next({ limit: this.page$.value.limit, page: this.page$.value.page });
    }
  }

  async createTax() {
    try {
      if (this.communityId) {
        const community = await this.communitySvc.getCommunity(this.communityId);
        const dialog = this.matDialog.open(SelectTaxDialog, {
          data: { accountId: community.financeAccountId },
          width: '400px',
          maxWidth: '80vw',
        });

        const result: Tax = await toPromise(dialog.afterClosed());

        if (result) {
          this.page$.next({ limit: this.page$.value.limit, page: this.page$.value.page });
          this.snacks.open('Tax added to community!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
        }
      }
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
    }
  }

  async updateTax(tax: Tax) {
    const dialog = this.matDialog.open(UpdateTaxDialog, {
      data: { tax },
    });
    const result = await toPromise(dialog.afterClosed());
    if (result) this.page$.next({ limit: this.page$.value.limit, page: this.page$.value.page });
  }

  async updateAccountTaxNumber(accountTax: AccountTax, refresh = true) {
    const dialog = this.matDialog.open(UpdateAccountTaxDialog, { data: { tax: accountTax } });

    const result = await toPromise(dialog.afterClosed());

    if (result) {
      if (refresh) {
        await this.accTaxSvc.updateTax(accountTax.financeAccountId, result);
        this.page$.next({ limit: this.page$.value.limit, page: this.page$.value.page });
      } else return result;
    }
  }

  async removeCommunityTax(taxId: string) {
    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Remove selected tax?',
          buttons: [
            { label: 'Cancel', role: 'cancel' },
            { label: 'Confirm', role: 'ok' },
          ],
        },
      });
      const result = await toPromise(dialog.afterClosed());

      if (this.communityId && result == 'ok') {
        const community = await this.communitySvc.getCommunity(this.communityId);
        await this.accTaxSvc.archiveTax(taxId, community.financeAccountId);
        this.page$.next({ limit: this.page$.value.limit, page: this.page$.value.page });
        this.snacks.open('Tax removed from community!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3000, panelClass: 'mat-warn' });
    }
  }

  async switchTaxType(accountTax: AccountTax, isDefault: boolean) {
    try {
      const community = await this.communitySvc.getCommunity(this.communityId);
      let taxNumber = accountTax.taxNumber;
      if (!taxNumber) {
        taxNumber = (await this.updateAccountTaxNumber(accountTax, false))?.taxNumber;
        if (!taxNumber) {
          this.snacks.open('Cannot update! A Tax Number is required!', 'Ok', {
            duration: 5000,
            panelClass: 'mat-warn',
          });
          this.page$.next({ limit: this.page$.value.limit, page: this.page$.value.page });
          return;
        }
      }
      const tax = await this.accTaxSvc.updateTax(community.financeAccountId, {
        isDefault,
        taxId: accountTax.taxId,
        taxNumber,
      });
      this.page$.next({ limit: this.page$.value.limit, page: this.page$.value.page });
      this.snacks.open(
        tax.isDefault
          ? 'Tax has been set as a community default'
          : "Tax has been removed from the community's defaults",
        'Ok',
        {
          duration: 5000,
          panelClass: 'mat-primary',
        }
      );
    } catch (err) {
      this.snacks.open('Error setting Tax`s default state.', 'Ok', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
      console.error(err);
    }
  }

  ngOnDestroy() {
    this._communityId$.complete();
  }
}
