import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Booking, BookingStatus, Calendar, CalendarEvent, EventStatus } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import moment from 'moment';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { delay, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { BookingService } from './booking.service';
import { CalendarService } from './calendar.service';
import { EventService } from './event.service';

@Injectable()
export class UserEventsService {
  selectedEvent$ = new ReplaySubject<(CalendarEvent & { userBooking?: Booking }) | null>(1);
  selectedDay$ = new BehaviorSubject<Date>(moment(new Date()).startOf('day').toDate());
  selectedCalendars$ = new ReplaySubject<Calendar[]>(1);
  selectedCommunities$ = new ReplaySubject<Community[]>(1);
  communities$ = new ReplaySubject<Community[]>(1);
  filters$ = new ReplaySubject<{
    tags: string[];
    resources: string[];
    timesOfDay?: string[];
  }>(1);
  user$ = this.userSvc.user$.pipe(tap(() => (this.userLoading = false)));
  userLoading = true;
  loading = true;
  firstLoad = true;
  private events = new Map<string, (CalendarEvent & { userBooking?: Booking })[]>();

  // Also update the filter below.
  timesOfDay = [
    {
      id: 'morning',
      label: 'Morning',
      icon: 'wb_twilight',
      color: '#FDA225',
      start: 6,
      end: 9,
    },
    {
      id: 'late-morning',
      label: 'Late Morning',
      icon: 'wb_twilight',
      color: '#FEC425',
      start: 9,
      end: 12,
    },
    {
      id: 'afternoon',
      label: 'Afternoon',
      icon: 'wb_sunny',
      color: '#FEC425',
      start: 12,
      end: 15,
    },
    {
      id: 'late-afternoon',
      label: 'Late Afternoon',
      icon: 'wb_sunny',
      color: '#FDA225',
      start: 15,
      end: 18,
    },
    {
      id: 'evening',
      label: 'Evening',
      icon: 'wb_twilight',
      color: '#FDA225',
      start: 18,
      end: 21,
    },
    {
      id: 'late-evening',
      label: 'Late Evening',
      icon: 'nights_stay',
      color: '#397CD9',
      start: 21,
      end: 24,
    },
    {
      id: 'night',
      label: 'Night',
      icon: 'bedtime',
      color: '#397CD9',
      start: 0,
      end: 3,
    },
    {
      id: 'late-night',
      label: 'Late Night',
      icon: 'bedtime',
      color: '#397CD9',
      start: 3,
      end: 6,
    },
  ];

  weekDays$ = this.selectedDay$.pipe(
    map(day => {
      return new Array(7).fill(null).map((_, i) => moment(day).startOf('week').add(i, 'days').startOf('day').toDate());
    }),
    shareReplay(1)
  );
  calendarsValue$ = new BehaviorSubject<Calendar[]>([]);
  calendars$ = combineLatest([
    this.userSvc.user$.pipe(
      tap(async user => {
        const communities = user
          ? (await this.contactService.getUserContacts(user?.id)).map(contact => contact.community)
          : [];
        this.communities$.next(communities);
        // if (communities.length) this.selectedCommunities$.next(communities);
      })
    ),
    // this.selectedCommunities$.pipe(startWith([])),
    this.communities$,
  ]).pipe(
    delay(3000),
    switchMap(async ([_user, /*_selectedCommunities,*/ communities]) => {
      const cals = await this.calendarSvc.getManyUser(communities.map(c => c.id));
      return cals;
    }),
    map(calendars => calendars?.filter(c => !c.private)),
    tap(calendars => {
      this.calendarsValue$.next(calendars || []);
    }),
    tap(calendars => {
      if (calendars && calendars.length > 0) {
        const inputCalendars = new URLSearchParams(this.route.snapshot.queryParams).getAll('calendar');
        this.selectedCalendars$.next(calendars.filter(c => inputCalendars.includes(c.id)) || calendars);
      }
    }),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  params$ = combineLatest([
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => new URLSearchParams(this.router.routerState.snapshot.url.split('?')[1] || '')),
      startWith(
        new URLSearchParams(
          Object.entries(this.route.snapshot.queryParams).reduce((acc, [k, v]) => {
            if (Array.isArray(v)) {
              v.forEach((v1: any) => {
                acc += k + '=' + v1 + '&';
              });
            } else if (v.includes(',')) {
              v.split(',').forEach((v1: any) => {
                acc += k + '=' + v1 + '&';
              });
              return acc;
            } else acc += k + '=' + v + '&';
            return acc;
          }, '')
        )
      )
    ),
    this.calendarsValue$.pipe(filter(cals => cals.length > 0)),
  ]).pipe(
    tap(([params, calendars]) => {
      const calendarIds = params.getAll('calendar');
      if (calendarIds.length) {
        const cals = calendars?.filter(c => calendarIds.includes(c.id)) || [];
        if (cals.length) this.selectedCalendars$.next(cals);
      }
      const tags = params.getAll('tag');
      const resources = params.getAll('resource');
      const timesOfDay = params.getAll('time');
      this.filters$.next({
        tags: tags.length ? tags : [],
        resources: resources.length ? resources : [],
        timesOfDay: timesOfDay.length ? timesOfDay : [],
      });
    }),
    shareReplay(1)
  );

  tags$ = this.selectedCalendars$.pipe(
    switchMap(async calendars => {
      const calendarTags = await Promise.all(
        calendars.map(async calendar => await this.calendarSvc.getCalendarTags(calendar.id))
      );
      return calendarTags.reduce((acc, tags) => {
        tags.forEach(tag => {
          if (!acc.some(t => t.id === tag.id)) acc.push(tag);
        });
        return acc;
      }, []);
    }),
    map(tags => tags.sort((a, b) => a.label.localeCompare(b.label))),
    shareReplay(1)
  );

  trainers$ = this.selectedCalendars$.pipe(
    switchMap(async calendars => {
      const calendarResources = await Promise.all(
        calendars.map(async calendar => await this.calendarSvc.getCalendarResources(calendar.id))
      );
      return calendarResources.reduce((acc, resources) => {
        resources.forEach(resource => {
          if (!acc.some(r => r.id === resource.id)) acc.push(resource);
        });
        return acc;
      }, []);
    }),
    map(resources => resources.filter(r => r.type === 'PERSON')),
    shareReplay(1)
  );

  $events = combineLatest([
    this.userSvc.user$.pipe(tap(() => this.events.clear())),
    this.selectedCommunities$.pipe(startWith([])), // .pipe(tap(() => this.events.clear())),
    this.selectedCalendars$,
    this.selectedDay$,
    this.params$, // Needed to subscribe to params. Otherwise, the params$ observable won't emit
    this.filters$,
  ]).pipe(
    tap(() => (this.loadingEvents = true)),
    switchMap(async ([user, selectedCommunities, selectedCalendars, selectedDay, _, filters]) => {
      // Load events for the selected day and calendar
      return this.loadEvents(user, selectedCommunities, selectedCalendars, selectedDay, _, filters, true);
    }),
    map(([_user, selectedCommunities, selectedCalendars, selectedDay, filters]) =>
      (
        this.events.get(
          selectedDay.getTime() + '_' + selectedCalendars.map(c => c.id).join('_') // +
          // '_' +
          // filters?.tags.sort().join(',') +
          // '_' +
          // filters?.resources.sort().join(',')
        ) || []
      ).filter(e => {
        let include = true;
        if (selectedCommunities.length) {
          include = selectedCommunities.some(c => c.id === e.community.id);
        }
        if (include && filters.tags?.length) {
          include = filters.tags.some(t => e.tags.some(et => et.id === t));
          console.log({ include, tags: filters.tags, eventTags: e.tags });
        }
        if (include && filters.resources?.length) {
          include = filters.resources.some(r => e.resourceAssignments.some(er => er.id === r));
        }
        if (include && filters.timesOfDay?.length) {
          const hour = moment(e.startDate).hour();
          if (filters.timesOfDay.includes('morning') && hour > 6 && hour < 9) return true;
          if (filters.timesOfDay.includes('late-morning') && hour >= 9 && hour < 12) return true;
          else if (filters.timesOfDay.includes('afternoon') && hour >= 12 && hour < 15) return true;
          else if (filters.timesOfDay.includes('late-afternoon') && hour >= 15 && hour < 18) return true;
          else if (filters.timesOfDay.includes('evening') && hour >= 18 && hour < 21) return true;
          else if (filters.timesOfDay.includes('late-evening') && hour >= 21 && hour < 24) return true;
          else if (filters.timesOfDay.includes('night') && hour >= 0 && hour < 3) return true;
          else if (filters.timesOfDay.includes('late-night') && hour >= 3 && hour < 6) return true;
          else include = false;
        }
        return include;
      })
    ),
    tap(events => {
      // Auto-Check the next day if there are no events for the selected day (up to 7 days in the future)
      if (
        this.firstLoad &&
        !events.length &&
        moment(this.selectedDay$.value).isBefore(moment().startOf('day').add(7, 'days'))
      ) {
        this.selectedDay$.next(moment(this.selectedDay$.value).add(1, 'day').toDate());
      } else if (
        this.firstLoad &&
        !events.length &&
        !moment(this.selectedDay$.value).isBefore(moment().startOf('day').add(7, 'days'))
      ) {
        this.selectedDay$.next(moment().startOf('day').toDate());
        this.firstLoad = false;
      } else {
        this.firstLoad = false;
      }
    }),
    map(events => {
      return events.map(e => ({
        ...e,
        resourceAssignments: e.resourceAssignments
          .filter(ra => ra.resource?.type === 'ROOM' || ra.resource?.type === 'PERSON')
          .sort((a, b) => {
            // Put types rooms first, then people
            if (a.resource?.type === 'ROOM' && b.resource?.type === 'PERSON') return -1;
            if (a.resource?.type === 'PERSON' && b.resource?.type === 'ROOM') return 1;
            return 0;
          }),
      }));
    }),
    tap(() => (this.loadingEvents = false)),
    shareReplay(1)
  );

  loadingEvents = false;

  constructor(
    private userSvc: UserService,
    private contactService: ContactService,
    private bookingsSvc: BookingService,
    private calendarSvc: CalendarService,
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async loadEvents(
    user: User | null,
    selectedCommunities: Community[],
    selectedCalendars: Calendar[],
    selectedDay: Date,
    _: [URLSearchParams, Calendar[]],
    filters: { tags: string[]; resources: string[]; timesOfDay?: string[] },
    loadNextDays = false
  ) {
    // Also load next two days, so it's cached when navigated to.
    // Only load next days if loading from the selected day
    if (loadNextDays)
      setTimeout(async () => {
        await Promise.all([
          this.loadEvents(
            user,
            selectedCommunities,
            selectedCalendars,
            moment(selectedDay).add(1, 'day').toDate(),
            _,
            filters
          ),
          this.loadEvents(
            user,
            selectedCommunities,
            selectedCalendars,
            moment(selectedDay).add(2, 'day').toDate(),
            _,
            filters
          ),
        ]);
      });
    if (
      !this.events.has(
        selectedDay.getTime() + '_' + selectedCalendars.map(c => c.id).join('_') // +
        // '_' +
        // filters?.tags.sort().join(',') +
        // '_' +
        // filters?.resources.sort().join(',')
      )
    ) {
      const [bookings, events] = await Promise.all([
        Promise.all(
          selectedCalendars.map(async selectedCalendar => {
            return (
              await this.bookingsSvc.getByDate({
                userId: user?.id,
                communityId: selectedCalendar.communityId,
                startDate: moment(selectedDay).startOf('day').toDate(),
                endDate: moment(selectedDay).endOf('day').toDate(),
              })
            ).filter(
              b =>
                b.status === BookingStatus.CONFIRMED ||
                b.status === BookingStatus.PENDING ||
                b.status === BookingStatus.CHECKED_IN
            );
          })
        ),
        await this.eventSvc.getEventsByDate({
          communities: [], // Always has a selected calendar
          calendars: selectedCalendars.map(c => c.id) || [],
          statuses: [EventStatus.ACTIVE],
          // resources: filters.resources, // Client-side filtering
          // tags: filters.tags, // Client-side filtering
          includePrivate: false,
          includeCourse: true,
          showOnlyCourseInstances: true,
          startDate: moment(selectedDay).startOf('day').toDate(),
          endDate: moment(selectedDay).endOf('day').toDate(),
        }),
      ]);
      const flatBookings = bookings.reduce((acc, bookings) => {
        bookings.forEach(booking => acc.push(booking));
        return acc;
      }, []);
      this.events.set(
        selectedDay.getTime() + '_' + selectedCalendars.map(c => c.id).join('_'), // +
        // '_' +
        // filters?.tags.sort().join(',') +
        // '_' +
        // filters?.resources.sort().join(',')
        events
          .filter(e => {
            return !e.private && (e.maxCapacity > 1 || (e as any)?.bookings === 0) && e.startDate >= new Date();
          })
          .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
          .map(e => ({
            ...e,
            userBooking: flatBookings.find(b => b.event.id === e.id),
          }))
      );
    }
    return [user, selectedCommunities, selectedCalendars, selectedDay, filters] as [
      User | null,
      Community[],
      Calendar[],
      Date,
      { tags: string[]; resources: string[]; timesOfDay?: string[] }
    ];
  }

  clearFilters() {
    return this.router.navigate([], {
      queryParams: {
        tag: [],
        resource: [],
        time: [],
        calendar: this.route.snapshot.queryParams.calendar,
      },
    });
  }

  filterTagToggle(tag: string) {
    const params = this.route.snapshot.queryParams;
    let tags = params.tag ?? [];
    if (!Array.isArray(tags)) tags = [tags];
    const index = tags.indexOf(tag);
    this.router.navigate([], {
      queryParams: {
        ...params,
        tag: index === -1 ? [...tags, tag] : tags.filter((t: string) => t !== tag),
      },
    });
  }

  filterResourceToggle(resource: string) {
    const params = this.route.snapshot.queryParams;
    let resources = params.resource ?? [];
    if (!Array.isArray(resources)) resources = [resources];
    const index = resources.indexOf(resource);
    this.router.navigate([], {
      queryParams: {
        ...params,
        resource: index === -1 ? [...resources, resource] : resources.filter((t: string) => t !== resource),
      },
    });
  }

  filterTimeOfDayToggle(timeOfDay: string) {
    const params = this.route.snapshot.queryParams;
    let timesOfDay = params.time ?? [];
    if (!Array.isArray(timesOfDay)) timesOfDay = [timesOfDay];
    const index = timesOfDay.indexOf(timeOfDay);
    this.router.navigate([], {
      queryParams: {
        ...params,
        time: index === -1 ? [...timesOfDay, timeOfDay] : timesOfDay.filter((t: string) => t !== timeOfDay),
      },
    });
  }
}
