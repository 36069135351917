<div class="split-div">
  <div #primary class="mat-button mat-primary" style="display: none"></div>

  <div class="room-image-container">
    <div class="room-image" [style.cursor]="cursorStyle" [style.display]="room?.imageURL ? '' : 'none'">
      <ng-container *grecoLet="drawSpots$ | async"></ng-container>

      <canvas
        #canvas
        style="max-height: 50vh"
        (click)="!readonly ? canvasClick($event) : null"
        (mousemove)="!readonly ? onCanvas($event) : null"
        (mouseleave)="!readonly ? noSpotHovered() : null"
      >
      </canvas>
    </div>

    <p *ngIf="canvas.height === 0">Error loading room image. Please try again</p>
  </div>

  <mat-radio-group *grecoLet="spots$ | async as spots" style="flex: 1">
    <greco-table
      *ngIf="spots && !readonly"
      [data]="spots"
      [hideEmptyPlaceholder]="true"
      (mouseleave)="hoverSpot()"
      (rowClick)="selectSpot($event)"
    >
      <ng-container *grecoTableCol="''; fitContent: true; let spot; let index = index">
        <span
          [style.color]="spot.spotId === selectedSpotId ? 'var(--primary-color)' : spot.userId ? 'lightgray' : ''"
          (mouseenter)="hoverSpot(spot.spotId)"
        >
          {{ spot.spotNumber > 0 ? spot.spotNumber : '?' }}
        </span>
      </ng-container>

      <ng-container *grecoTableCol="'Name'; fitContent: true; let spot; let index = index">
        <div style="max-width: 20vw" (mouseenter)="hoverSpot(spot.spotId)">
          <span
            [innerHtml]="spot.spotName || 'Spot ' + spot.spotNumber"
            [style.color]="spot.spotId === selectedSpotId ? 'var(--primary-color)' : spot.userId ? 'lightgray' : ''"
          ></span>
        </div>
      </ng-container>

      <ng-container *grecoTableCol="'Description'; let spot; let index = index">
        <div (mouseenter)="hoverSpot(spot.spotId)">
          <span
            type="text"
            readonly="true"
            [innerHtml]="spot.spotDescription || spot.spotName || 'Spot ' + spot.spotNumber"
            [style.color]="spot.spotId === selectedSpotId ? 'var(--primary-color)' : spot.userId ? 'lightgray' : ''"
          ></span>
        </div>
      </ng-container>

      <ng-container *grecoTableCol="'Select Spot'; fitContent: true; stickyEnd: true; let spot; let index = index">
        <mat-radio-button
          color="primary"
          [value]="spot.spotId"
          [checked]="spot.spotId === selectedSpotId"
          [disabled]="spot.userId && spot.spotId !== selectedSpotId"
          (mouseenter)="hoverSpot(spot.spotId)"
          (click)="selectSpot(spot); $event.preventDefault()"
        >
        </mat-radio-button>
      </ng-container>
    </greco-table>
  </mat-radio-group>
</div>
