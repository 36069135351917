<greco-simple-dialog
  [data]="{
    title: 'Sign User Agreement',
    subtitle: 'Please review the agreement and sign',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      { label: 'Sign', role: 'create', disabled: !formGroup.value.checkbox || !signature, color: 'primary', resultFn: getResult }
    ]
  }"
>
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <h2>{{ agreement.signedAgreementTitle }}</h2>

    <div style="padding: 0 8px">
      <div [innerHtml]="agreement.signedAgreementText || '' | safe:'html'"></div>
    </div>

    <div style="display: flex; justify-content: center">
      <div style="width: 98%; border-bottom: 1px solid darkgray"></div>
    </div>

    <mat-checkbox
      *ngIf="agreement.agreement?.agreementType !== 'DIGITAL_SIGNATURE'"
      [checked]="checked"
      [required]="true"
      style="padding: 8px"
      color="primary"
      formControlName="checkbox"
    >
      I have read and agree to the terms of the agreement
    </mat-checkbox>

    <greco-signature
      *ngIf="agreement.agreement?.agreementType === 'DIGITAL_SIGNATURE'"
      [forPurchase]="true"
      [user]="agreement.user"
      (signatureChange)="setSignature($event)"
    >
    </greco-signature>
  </form>
</greco-simple-dialog>
