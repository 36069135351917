import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { DataExport } from '@greco/data-exports';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, interval, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { DataExportService } from '../../services';

export interface InitiateExportDialogData {
  processorId: string;
  initiateExport: () => Promise<DataExport>;
}

@Component({
  templateUrl: './initiate-export.dialog.html',
  styleUrls: ['./initiate-export.dialog.scss'],
})
export class InitiateExportDialog {
  private refresh$ = new BehaviorSubject<null>(null);

  readonly exports$ = this.refresh$.pipe(
    switchMap(() =>
      this.dataExportSvc.getDataExports(new RequestQueryBuilder().search({ processor: this.data.processorId }), {
        page: 1,
        limit: 3,
      })
    ),
    map(data => data?.items || [])
  );

  private _selectedExport$ = new BehaviorSubject<DataExport | null>(null);
  selectedExport$ = this._selectedExport$.pipe(
    switchMap(dataExport =>
      dataExport
        ? interval(10000).pipe(
            switchMap(() => this.dataExportSvc.getDataExport(dataExport.id)),
            startWith(dataExport)
          )
        : of(null)
    )
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InitiateExportDialogData,
    private dialogRef: MatDialogRef<InitiateExportDialog>,
    private dataExportSvc: DataExportService
  ) {
    //console.log("InitiateExportDialog::ctor() called")
    toPromise(this.exports$).then(dataExports => {
      console.log({ dataExports });
      if (!dataExports?.length) this.startExport();
    });
  }

  openDataExport(dataExport: DataExport) {
    //console.log("InitiateExportDialog::openDataExport() called")
    this._selectedExport$.next(dataExport);
  }

  closeDataExport() {
    //console.log("InitiateExportDialog::closeDataExport() called")
    this.refresh();
    this._selectedExport$.next(null);
  }

  sendByEmail(dataExport: DataExport) {
    //console.log("InitiateExportDialog::sendByEmail() called")
    return this.dataExportSvc.setSendEmalTrue(dataExport.id);
  }

  async startExport() {
    //console.log("InitiateExportDialog::startExport() called")
    const xprt = await this.data.initiateExport();
    this.openDataExport(xprt);
  }

  refresh() {
    //console.log("InitiateExportDialog::refresh() called")
    this.refresh$.next(null);
  }

  close() {
    //console.log("InitiateExportDialog::close() called")
    this.dialogRef.close();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static open(dialogSvc: MatDialog, data: InitiateExportDialogData) {
    //console.log("InitiateExportDialog::open() called")
    dialogSvc.open(InitiateExportDialog, { data, autoFocus: false });
  }
}
