import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Account } from '@greco/finance-accounts';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { AccountService } from '../../services';

@Component({
  selector: 'greco-account-search-component',
  templateUrl: './account-search.component.html',
  styleUrls: ['./account-search.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: AccountSearchComponent }],
})
export class AccountSearchComponent implements MatFormFieldControl<Account>, ControlValueAccessor, OnInit, OnDestroy {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private formBuilder: FormBuilder,
    private accountSvc: AccountService
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
    this.formControl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(value => (value?.id ? value : null)),
        distinctUntilChanged()
      )
      .subscribe(value => this._onChange?.(value));
  }

  private static _id = 0;

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  private _onDestroy = new Subject<void>();

  stateChanges = new Subject<void>();

  formControl = this.formBuilder.control({ value: null, disabled: false });

  private _allAccounts: Account[] = [];
  accounts: Account[] = [];

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `account-search-${AccountSearchComponent._id++}`;
  readonly controlType = 'account-search';

  private _excluded: Account[] = [];
  @Input() set excluded(excluded: Account[]) {
    this._excluded = excluded;
    this.search(this.formControl.value?.id ? '' : this.formControl.value);
  }
  get excluded() {
    return this._excluded;
  }

  @Input() set value(value: Account | null) {
    this.formControl.setValue(value?.id ? value : null);
    this.stateChanges.next();
  }
  get value() {
    return this.formControl.value?.id ? this.formControl.value : null;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.formControl.setValidators(this._required ? [Validators.required] : []);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this._disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.formControl.invalid && this.formControl.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  async ngOnInit() {
    this._allAccounts = await this.accountSvc.getAllAccounts();
    this.search('');
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stateChanges.complete();
  }

  setDescribedByIds() {
    return;
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: Account | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: Account | null) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  displayWith(value: Account | null) {
    return value?.name || '';
  }

  search(value: string) {
    const qry = typeof value === 'string' ? value : '';
    const excludedIds = this.excluded?.map(acct => acct.id) || [];
    this.accounts = this._allAccounts.filter(
      acct => !excludedIds.includes(acct.id) && acct.name.toLowerCase().indexOf(qry.toLowerCase()) >= 0
    );
  }
}
