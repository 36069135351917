import { BaseDto } from '@greco/util-dto';
import { IsOptional, IsString } from 'class-validator';

export class UpdateReportDto extends BaseDto {
  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsString()
  url?: string;
}
