import { BaseDto } from '@greco/util-dto';
import { AgreementStatus } from '../models';

export class CreateUserCommunityAgreementDto extends BaseDto {
  type: string;
  userId: string;
  agreementId: string;
  signedAgreementTitle: string;
  signedAgreementText: string;
  signature?: string | null;
  status: AgreementStatus;
}

export class UserAgreementDto extends BaseDto {
  agreementId: string;
  signed: boolean;
}
