import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { EventWithUserDetails } from '@greco/booking-events2';
import { PropertyListener } from '@greco/property-listener-util';
import { AlertConfig, AlertType } from '@greco/ui-alert';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'alt-event-failed-payment-alert',
  templateUrl: './event-failed-payment-alert.component.html',
  styleUrls: ['./event-failed-payment-alert.component.scss'],
})
export class EventFailedPaymentAlertComponent {
  constructor(private breakpointObserver: BreakpointObserver, private eventSvc: EventService) {}

  @PropertyListener('event') event$ = new BehaviorSubject<EventWithUserDetails | null>(null);
  @Input() event!: EventWithUserDetails;

  isMobile$ = this.breakpointObserver.observe('(max-width: 399px)').pipe(map(result => result.matches));

  alerts$ = this.event$.pipe(
    map(event => {
      if (!event) return [];

      const alerts: AlertConfig[] = [];

      const account = event.accounts[0];
      if (!account) return [];

      if (account.failedPayments.length) {
        const sNeeded = account.failedPayments.length > 1 ? 's' : '';
        alerts.push({
          title: 'Failed Payment',
          type: AlertType.DANGER,
          description:
            'You have ' +
            account.failedPayments.length +
            ' failed payment' +
            sNeeded +
            ' that will need to be addressed before making new purchases',
        });
      }

      return alerts;
    })
  );
}
