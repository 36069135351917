import type { SecurityResource } from '@greco/security';

export enum ProductResourceAction {
  MANAGE = 'MANAGE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export const ProductResource: SecurityResource = {
  key: 'sales_product_product',
  module: 'Sales',

  title: 'Product',
  description: '',

  context: class {
    id?: string;
  },

  actions: [
    {
      key: ProductResourceAction.MANAGE,
      title: 'Manage Products',
      description: 'Ability to view and edit products for a community.',
    },
  ],
};
