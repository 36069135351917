import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Invoice } from '@greco/finance-invoices';
import { InvoiceService } from '../../services';

@Component({
  selector: 'greco-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss'],
})
export class InvoicesTableComponent implements OnChanges {
  constructor(private invoiceSvc: InvoiceService) {}

  @Input() userId!: string;

  invoices: Invoice[] = [];

  loading = true;
  reachedLastPage = false;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.userId.previousValue !== changes.userId.currentValue) {
      await this.loadPage();
    }
  }

  async loadPage(startAfter?: string) {
    this.loading = true;

    if (this.userId) {
      const invoices = await this.invoiceSvc.paginateUserInvoices(this.userId, startAfter);

      if (startAfter) this.invoices = [...this.invoices, ...invoices];
      else this.invoices = invoices;

      this.reachedLastPage = invoices.length < 10;
    } else this.invoices = [];

    this.loading = false;
  }
}
