<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <!-- Title -->
    <mat-form-field>
      <mat-label>Title</mat-label>
      <div style="display: flex; flex-direction: row">
        <input matInput required formControlName="title" style="width: 100%" />
        <mat-checkbox color="primary" formControlName="private" [required]="false">Private</mat-checkbox>
      </div>
    </mat-form-field>

    <mat-form-field style="margin-bottom: 16px">
      <mat-label> Group </mat-label>
      <input matInput formControlName="group" style="width: 100%" />
      <mat-hint>Logical grouping of Calendars to help members book.</mat-hint>
    </mat-form-field>

    <mat-form-field floatLabel="always">
      <mat-label>Image</mat-label>
      <greco-image-upload [required]="true" formControlName="image" [multiple]="false"></greco-image-upload>
    </mat-form-field>
    <!-- Icon -->
    <mat-form-field appearance="standard" floatLabel="always">
      <mat-label>Icon</mat-label>
      <greco-icon-picker formControlName="icon" [required]="true"></greco-icon-picker>
    </mat-form-field>
  </form>
</greco-simple-dialog>
