import { Directive, Input } from '@angular/core';
import { BookingOption, UserBookingOptionStats } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { BookingOptionService } from '../../services';

export interface PreBookingOption {
  optionId: string;
  option: BookingOption;
  canBookPending: boolean;
  requiredPendingBookings: number;
  stats?: UserBookingOptionStats;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[grecoPreBooking]',
  exportAs: 'grecoPreBooking',
})
export class PreBookingDirective {
  constructor(private bookingOptionSvc: BookingOptionService) {}

  @Input() instancesToBook?: number | null;
  @PropertyListener('instancesToBook') private instancesToBook$ = new ReplaySubject<number>(1);

  @Input() canBookPending?: boolean | null;
  @PropertyListener('canBookPending') private canBookPending$ = new ReplaySubject<boolean>(1);

  @Input() canBookPendingWithSub?: boolean | null;
  @PropertyListener('canBookPendingWithSub') private canBookPendingWithSub$ = new ReplaySubject<boolean>(1);

  @Input() user?: User | null;
  @PropertyListener('user') private user$ = new ReplaySubject<User>(1);

  @Input() bookingOptions?: BookingOption[] | null;
  @PropertyListener('bookingOptions') private bookingOptions$ = new ReplaySubject<BookingOption[]>(1);

  readonly data$: Observable<PreBookingOption[]> = combineLatest([
    this.instancesToBook$,
    this.canBookPending$,
    this.canBookPendingWithSub$,
    this.user$,
    this.bookingOptions$,
  ]).pipe(
    debounceTime(300),
    switchMap(async ([instancesToBook, canBookPending, canBookPendingWithSub, user, bookingOptions]) => {
      // console.log({ instancesToBook, bookingOptions });
      if (!user || !bookingOptions) return [];

      const userBookingOptions = await this.getUserBookingOptions(user, bookingOptions);

      const stats = userBookingOptions.reduce(
        (a, s) => ({ ...a, [s.bookingOptionId]: s }),
        {} as { [id: string]: UserBookingOptionStats }
      );

      const options = this.sortOptions(
        bookingOptions.filter(opt => {
          const userBookingOption = userBookingOptions.find(o => o.bookingOptionId === opt.id);
          const requiredPendingBookings = !userBookingOption?.reusable
            ? Math.max(0, instancesToBook - (userBookingOption?.consumable || 0))
            : 0;

          return requiredPendingBookings ? opt.allowPendingBookings : true;
        }),
        stats
      );

      return options.map(option => {
        const userBookingOption = userBookingOptions.find(o => o.bookingOptionId === option.id);
        return <PreBookingOption>{
          option,
          optionId: option.id,
          stats: stats[option.id],
          canBookPending: canBookPending || (canBookPendingWithSub && userBookingOption?.subscriptions.length),
          requiredPendingBookings: !userBookingOption?.reusable
            ? Math.max(0, instancesToBook - (userBookingOption?.consumable || 0))
            : 0,
        };
      });
    })
  );

  sortOptions(bookingOptions: BookingOption[], stats: { [id: string]: UserBookingOptionStats }) {
    return bookingOptions.sort((a, b) => {
      const aPrice = a.price || 0;
      const bPrice = b.price || 0;

      const aAvailable = !!stats?.[a.id]?.reusable || !!stats?.[a.id]?.consumable;
      const bAvailable = !!stats?.[b.id]?.reusable || !!stats?.[b.id]?.consumable;

      if (aAvailable !== bAvailable) {
        return aAvailable ? -1 : 1;
      }

      if (aAvailable && bAvailable) {
        const aReusable = !!stats?.[a.id]?.reusable;
        const bReusable = !!stats?.[b.id]?.reusable;

        if (!!aPrice !== !!bPrice) {
          return aPrice < bPrice ? -1 : 1;
        }

        if (aReusable !== bReusable) {
          return aReusable ? -1 : 1;
        }

        if (aPrice !== bPrice) {
          return aPrice < bPrice ? -1 : 1;
        }
      }

      const aSubs = !!stats?.[a.id]?.subscriptions?.length;
      const bSubs = !!stats?.[b.id]?.subscriptions?.length;

      if (aSubs !== bSubs) {
        return aSubs ? -1 : 1;
      }

      if (aPrice !== bPrice) {
        return aPrice < bPrice ? -1 : 1;
      }

      const aUsed = !!stats?.[a.id]?.used;
      const bUsed = !!stats?.[b.id]?.used;

      if (aUsed !== bUsed) {
        return aUsed ? -1 : 1;
      }

      return a.title.localeCompare(b.title);
    });
  }

  private getUserBookingOptions(user: User, bookingOptions: BookingOption[]) {
    return this.bookingOptionSvc.getUserBookingOptionStats(
      user.id,
      bookingOptions.map(opt => opt.id)
    );
  }
}
