import {
  ProductConditionOperator,
  PRODUCT_CONDITION_TYPE_PURCHASE_CATEGORY_COUNT,
  PurchaseCategoryProductCondition,
} from '@greco/sales-products';
import { SaleCategory } from '@greco/sales-purchases';
import { DynamicInputModel, DynamicSelectModel } from '@ng-dynamic-forms/core';
import { ProductConditionForm } from '../dialogs';
import { DynamicCategoryPickerModel } from '../forms';

export const PRODUCT_CONDITION_CONFIG_PURCHASE_CATEGORY_COUNT: ProductConditionForm = {
  type: PRODUCT_CONDITION_TYPE_PURCHASE_CATEGORY_COUNT,
  label: 'Purchase Count (Sale Category)',
  matIcon: 'receipt_long',

  getConditionDetails({ saleCategory, operator, limit }: PurchaseCategoryProductCondition) {
    return `Available if user has purchase count of "${saleCategory?.label}" ${operator} ${limit}`;
  },

  getConditionShortDetails({ saleCategory, operator, limit }: PurchaseCategoryProductCondition) {
    return `${saleCategory?.label} ${operator} ${limit}`;
  },

  getDtoFromFormValue({
    saleCategory,
    operator,
    limit,
  }: {
    saleCategory: SaleCategory;
    operator: ProductConditionOperator;
    limit: number;
  }) {
    return {
      saleCategoryId: saleCategory.id,
      saleCategory,
      operator,
      limit,
    };
  },
  getFormModel(communityId: string) {
    return [
      new DynamicCategoryPickerModel({
        id: 'saleCategory',
        communityId: communityId,
        label: 'Purchase Category',
        required: true,
        errorMessages: {
          required: 'Select a valid category',
        },
        validators: {
          required: null,
        },
      }),

      new DynamicSelectModel({
        id: 'operator',
        label: 'Operator',
        required: true,
        options: [
          { label: 'equals', value: ProductConditionOperator.EQUALS },
          { label: 'greater than', value: ProductConditionOperator.GREATER_THAN },
          { label: 'greater than or equal to', value: ProductConditionOperator.GREATER_THAN_EQUALS },
          { label: 'less than', value: ProductConditionOperator.LESS_THAN },
          { label: 'less than or equal to', value: ProductConditionOperator.LESS_THAN_EQUALS },
        ],
        errorMessages: {
          required: 'Select a valid operator',
        },
        validators: {
          required: null,
        },
      }),

      new DynamicInputModel({
        id: 'limit',
        inputType: 'number',
        label: 'Limit',
        min: 0,
        required: true,
        errorMessages: {
          required: 'A limit >= 0 is required',
          min: 'A limit >= 0 is required',
        },
        validators: {
          required: null,
          min: 0,
        },
      }),
    ];
  },
};
