import { IsEnum, IsInt, IsString } from 'class-validator';
import { Product } from '../../product';
import {
  ProductCondition,
  ProductConditionContext,
  ProductConditionDto,
  ProductConditionEvaluation,
  ProductConditionOperator,
  ProductConditionsRegistry,
} from '../user-availability-addon';

export const PRODUCT_CONDITION_TYPE_PURCHASE_COUNT = 'purchase-count';

export interface PurchaseProductCondition extends ProductCondition {
  subjectId: string;
  subject: Product;
  limit: number;
  operator: ProductConditionOperator;
}

export class PurchaseProductConditionDto extends ProductConditionDto {
  @IsString()
  subjectId: string;

  @IsInt()
  limit: number;

  @IsEnum(ProductConditionOperator)
  operator: ProductConditionOperator;
}

ProductConditionsRegistry.registerCondition(
  PRODUCT_CONDITION_TYPE_PURCHASE_COUNT,
  PurchaseProductConditionDto,
  (condition: PurchaseProductCondition, ctx: ProductConditionContext) => evaluatePurchaseCondition(condition, ctx)
);

function evaluatePurchaseCondition(
  condition: PurchaseProductCondition,
  ctx: ProductConditionContext
): ProductConditionEvaluation {
  const purchases: number =
    ctx.productPurchases && ctx.productPurchases[condition.subjectId] ? ctx.productPurchases[condition.subjectId] : 0;
  const limit = condition.limit;
  let result = false;
  let message = '';
  let error = '';
  const difference = limit - purchases;
  const title = condition.subject?.title || condition.subjectId;
  switch (condition.operator) {
    case ProductConditionOperator.EQUALS:
      if (purchases === limit) {
        result = true;
        message = `After this purchase, you would be passed the purchase limit of ${limit} for ${title}.`;
      } else {
        result = false;
        error = `You need to have ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
    case ProductConditionOperator.GREATER_THAN:
      if (purchases > limit) {
        result = true;
      } else {
        result = false;
        error = `You need to have more than ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
    case ProductConditionOperator.GREATER_THAN_EQUALS:
      if (purchases >= limit) {
        result = true;
      } else {
        result = false;
        error = `You need to have at least ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
    case ProductConditionOperator.LESS_THAN:
      if (purchases < limit) {
        result = true;
        if (difference <= 0 || purchases === limit - 1)
          message = `After this purchase, you will be passed the purchase limit of ${limit} for ${title}.`;
        else
          message = `After this purchase, you will have ${difference} of ${limit} purchase${
            limit === 1 ? '' : 's'
          } left for ${title}.`;
      } else {
        result = false;
        error = `You need to have less than ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
    case ProductConditionOperator.LESS_THAN_EQUALS:
      if (purchases <= limit) {
        result = true;
        if (difference <= 0)
          message = `After this purchase, you will be passed the purchase limit of ${limit} for ${title}.`;
        else message = `After this purchase, you will have ${difference} of ${limit} purchases left for ${title}.`;
      } else {
        result = false;
        error = `You cannot of more than ${limit} purchase${
          limit === 1 ? '' : 's'
        } of ${title}. You have ${purchases} recorded purchase${purchases === 1 ? '' : 's'}.`;
      }
      break;
  }
  return { result, message, error };
}
