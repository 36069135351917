import { Pipe, PipeTransform } from '@angular/core';
import { Booking } from '@greco/booking-events';

@Pipe({ name: 'inUndoWindow' })
export class InUndoWindow implements PipeTransform {
  transform(booking: Booking) {
    const dayInMilliseconds = 86400 * 1000;

    const now = new Date();
    const dayAfterEvent = new Date(new Date(booking.event?.startDate).getTime() + dayInMilliseconds);

    return now < dayAfterEvent;
  }
}
