import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventAccount, EventWithUserDetails } from '@greco/booking-events2';
import { User } from '@greco/identity-users';
import moment from 'moment';
import { EventService } from '../../services';

@Component({
  selector: 'alt-waitlist-card',
  templateUrl: './waitlist-card.component.html',
  styleUrls: ['./waitlist-card.component.scss'],
})
export class WaitlistCardComponent {
  constructor(private eventSvc: EventService) {}

  moment = moment;
  now = new Date();

  @Input() user!: User;
  @Input() event!: EventWithUserDetails;
  @Input() eventAccounts: EventAccount[] = [];

  @Output() booked = new EventEmitter();

  confirming = false;

  async leaveWaitlist() {
    this.confirming = true;
    try {
      await this.eventSvc.removeFromWaitlist(this.event.location.id, this.event.id, this.user.id);
    } catch (err) {
      console.error(err);
    }

    this.confirming = false;
  }

  bookNow() {
    if (
      this.event.capacityRemaining > 0 &&
      !this.eventSvc.bookings$.value.some(booking => booking.userId === this.user.id)
    ) {
      const userAccount = this.eventAccounts.find(account => account.user.id === this.user.id);
      if (!userAccount) return;

      const accountWithBookingOptions =
        userAccount.canUsePerks && userAccount.bookingOptions.length ? userAccount : this.event.accounts[0];

      let bookingOptions = accountWithBookingOptions.bookingOptions;

      if (accountWithBookingOptions.user.id !== userAccount.user.id) {
        bookingOptions = bookingOptions.filter(option => option.transferable || option.transferableReusable);
      }

      this.eventSvc.addBooking({
        userId: this.user.id,
        eventId: this.event?.id || '',
        bookingOptionId: bookingOptions[0]?.id || '',
        bookingOptionUserId: bookingOptions[0]?.userId || '',
      });
    }
  }
}
