<ng-container *ngIf="groups.length; else noEvents">
  <ng-container *grecoLet="linkedAccounts$ | async as linkedAccounts">
    <ng-container *ngFor="let group of groups">
      <greco-user-event-list-group
        [id]="group.id"
        [events]="group.events"
        [dayHeader]="group.day"
        [userBookings]="userBookings"
        [userWaitlist]="userWaitlist"
        [pagination]="eventsCount > 35"
        [linkedAccounts]="linkedAccounts || []"
      ></greco-user-event-list-group>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noEvents>
  <div class="no-events">
    <h2>Oops! Looks like there are no events here at those times.</h2>
    <p>Try a different week or change some filters!</p>
    <p *ngIf="!user">
      You should also
      <a [routerLink]="" (click)="signIn()">sign in</a> to view events that have special booking requirements!
    </p>
  </div>
</ng-template>
