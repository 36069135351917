<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tag Line</mat-label>
      <input matInput type="text" formControlName="subtitle" />
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <mat-label>Promotion Date</mat-label>
      <mat-date-range-input class="greco-date-range-picker-container" [rangePicker]="picker">
        <input matStartDate placeholder="Start date" formControlName="startDate" />
        <input matEndDate placeholder="End date" formControlName="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</greco-simple-dialog>
