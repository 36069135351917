<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <div style="display: flex; flex-direction: column; align-items: flex; gap: 8px; margin-top: 6px">
      <!-- Cancellation Type  -->
      <mat-radio-group formControlName="dateRadioButton" aria-label="Start Subscription: ">
        <mat-radio-button style="margin-left: 16px" color="primary" value="now">Now</mat-radio-button>
        <mat-radio-button style="margin-left: 16px" color="primary" value="endOfPeriod">
          At the end of current period
        </mat-radio-button>
        <mat-radio-button style="margin: 10px" color="primary" value="future"> Custom Date </mat-radio-button>
      </mat-radio-group>
      <!-- Cancellation Proration -->
      <!-- <mat-checkbox
        style="margin-left: auto; align-self: center"
        color="primary"
        *ngIf="canProrate"
        formControlName="prorateCancellation"
        labelPosition="before"
        ><small>Prorate unused to balance</small>
      </mat-checkbox> -->
      <mat-radio-group *ngIf="canProrate" formControlName="prorateRadioButton" aria-label="Proration: ">
        <mat-radio-button style="margin-left: 16px" color="primary" value="none">No proration</mat-radio-button>
        <mat-radio-button style="margin-left: 16px" color="primary" value="balance">
          Prorate unused to balance
        </mat-radio-button>
        <mat-radio-button style="margin: 10px" color="primary" value="card">
          Prorate unused to payment method
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Cancellation Date  -->
    <div>
      <mat-form-field *ngIf="formGroup.value.dateRadioButton == 'future'" style="margin-top: 6px" appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input
          matInput
          [min]="minimalDate"
          [max]="maximalDate"
          [matDatepicker]="picker"
          (click)="picker.open()"
          formControlName="dateSelection"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- Cancellation Details -->
    <div>
      <mat-form-field style="margin-top: 6px" appearance="outline" class="custom">
        <mat-label>Reason</mat-label>
        <mat-select required formControlName="cancellationDetails">
          <mat-option *ngFor="let reason of cancellationReasons" [value]="reason">{{ reason }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Confirmation -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        type="button"
        color="primary"
        style="flex: 1"
        mat-stroked-button
        [disabled]="processing"
        (click)="submitCancellation()"
      >
        <span>Confirm</span>
        <mat-progress-spinner
          diameter="18"
          mode="indeterminate"
          *ngIf="processing"
          style="filter: grayscale(1) opacity(0.5)"
        ></mat-progress-spinner>
      </button>
    </div>
  </form>
</greco-simple-dialog>
