import { Calendar, EventTemplate, ResourceTag, WaitlistItem, ZoomResource } from '@greco/booking-events';
import { CommunityAgreementUsage } from '@greco/community-agreements';
import { Community } from '@greco/identity-communities';
import { Timezone } from '@greco/timezone';
import { BookingRequirement } from './booking-requirement';
import { Resource } from './resource';
import { Tag } from './tag';

export enum EventStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum ResourceAssignmentStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  REQUESTING_SUBSTITUTION = 'REQUESTING_SUBSTITUTION',
}

export enum EventType {
  EVENT = 'EVENT',
  EVENT_SERIES = 'EVENT_SERIES',
  EVENT_SERIES_INSTANCE = 'EVENT_SERIES_INSTANCE',
}

export interface EventDetails {
  timezone: Timezone;

  deleted: Date;

  created: Date;
  modified: Date;

  tags: Tag[];
  community: Community;

  calendar?: Calendar;

  calendarId?: string;

  private: boolean;

  color: string;
  title: string;
  imageUrl?: string;
  description?: string;

  /** Minutes */
  duration: number;
  /** Minutes */
  checkInWindow: number;

  maxCapacity: number;
  waitlist: WaitlistItem[];

  resourceTags: ResourceTag[];
  resourceAssignments: EventResourceAssignment[];
  autoAssign: boolean;
  enableUserSpotBooking: boolean;

  requirements: BookingRequirement[];

  availableAsCourse?: boolean;
  availableAsInstances?: boolean;

  type?: EventType;

  eventTemplateId?: string;

  eventTemplate?: EventTemplate;
}

export interface Event extends EventDetails {
  id: string;

  status: EventStatus;

  endDate: Date;
  startDate: Date;

  zoomMeetingId?: string;
  zoomEvent?: ZoomEvent;
}

export interface EventOverview {
  id: string;

  title: string;
  imageUrl?: string;

  endDate: Date;
  startDate: Date;
  duration: number;

  timezone: Timezone;

  calendar?: string;

  maxCapacity: number;
  bookings: number;

  trainers: EventResourceAssignment[];
  rooms: EventResourceAssignment[];
}

export interface ResourceAssignment {
  id: string;

  resourceId?: string;
  resource?: Resource;

  created?: Date;

  resourceTagId?: string;
  resourceTag?: ResourceTag;

  resourceStatus: ResourceAssignmentStatus;
}

export interface EventResourceAssignment extends ResourceAssignment {
  eventId: string;
}

export interface EventTemplateResourceAssignment extends ResourceAssignment {
  eventTemplateId: string;
}

export interface EventSeries extends EventDetails {
  id: string;
  archived: boolean;

  endDate?: Date;
  startDate: Date;

  recurrence: string[];

  imageURL: string;

  status?: EventStatus;

  zoomMeetingId?: string;
  zoomEvent?: ZoomEvent;
}

export interface EventSeriesInstance extends Event {
  series: EventSeries;
  instanceDate: Date;
}

export type CalendarEvent = Event | EventSeriesInstance;

export interface ZoomEvent {
  id: string;

  event: CalendarEvent;

  resource: ZoomResource;

  status: EventStatus;

  meetingID?: string;

  hostUrl?: string;

  joinUrl?: string;

  created: Date;

  modified: Date;
}

export interface EventAgreementUsage extends CommunityAgreementUsage {
  eventId: string;
  event?: CalendarEvent;
}

export interface SeriesAgreementUsage extends CommunityAgreementUsage {
  seriesId: string;
  series?: EventSeries;
}
