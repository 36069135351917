import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VideoUnlock } from '@greco/videos';
import moment from 'moment';
import { DashboardVideosService } from '../../services/dashboard-videos.service';

@Component({
  selector: 'greco-dashboard-videos',
  templateUrl: './dashboard-videos.component.html',
  styleUrls: ['./dashboard-videos.component.scss'],
})
export class DashboardVideosComponent {
  constructor(private router: Router, public dashboardVideosSvc: DashboardVideosService) {}

  moment = moment;

  async openVideo(videoUnlock: VideoUnlock) {
    if ((videoUnlock as any).eventVideoId) {
      await this.router.navigate(['/workouts', 'go', (videoUnlock as any).eventVideoId]);
    } else if ((videoUnlock as any).collectionVideoId) {
      await this.router.navigate([
        '/workouts/collections',
        `${(videoUnlock as any).collectionVideo.collectionId}`,
        (videoUnlock as any).collectionVideoId,
      ]);
    }
  }
}
