<form class="greco-minutes-input-container" [formGroup]="_internalForm">
  <input
    class="greco-minutes-input"
    (focus)="onFocusIn($event)"
    (blur)="onFocusOut($event)"
    [placeholder]="placeholder"
    formControlName="count"
    [required]="required"
    type="number"
    matInput
    min="0"
  />

  <mat-select
    class="greco-minutes-select"
    formControlName="period"
    (focus)="onFocusIn($event)"
    (blur)="onFocusOut($event)"
  >
    <mat-option value="minutes">Minute(s)</mat-option>
    <mat-option value="hours">Hour(s)</mat-option>
    <mat-option value="days">Day(s)</mat-option>
  </mat-select>
</form>
