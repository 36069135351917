<div style="margin-bottom: 16px">
  <h2 style="margin-bottom: 8px">Bank Account</h2>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="outline">
      <mat-label>Account Holder Name</mat-label>
      <input required matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Contact Email</mat-label>
      <input required matInput formControlName="email" />
    </mat-form-field>
  </form>
</div>
