import { Component, InjectionToken, Input, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({ selector: 'greco-abstract-payment-method-form', template: '' })
export abstract class PaymentMethodFormComponent {
  @Input() userId!: string;
  @Input() accountId!: string;

  abstract formGroup: FormGroup;

  abstract save(): Promise<{
    label: string;
    details?: string;
    model: string;
    externalId: string;
    externalData?: any;
    cardHolderName?: string;
  }>;
}

export interface PaymentMethodForm {
  gateway: string;

  label: string;
  matIcon: string;

  form: Type<PaymentMethodFormComponent>;
}

export const PAYMENT_METHOD_FORMS = new InjectionToken<PaymentMethodForm[]>('PAYMENT_METHOD_FORMS');
