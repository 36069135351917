<greco-simple-dialog
  [data]="{
  title: 'Create Coupon',
  subtitle: 'Provide the coupon details you wish to create.',
  showCloseButton: false,
  hideDefaultButton: true
}"
>
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input placeholder="Write a descriptive coupon title." matInput type="text" required formControlName="title" />
    </mat-form-field>

    <mat-form-field class="hidden">
      <mat-label>Short Title</mat-label>
      <input placeholder="Write a meaningful coupon title." matInput type="text" formControlName="shortTitle" />
    </mat-form-field>

    <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
      <mat-label>Icon</mat-label>
      <greco-icon-picker formControlName="icon" [required]="true"></greco-icon-picker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <textarea
        matInput
        [matTextareaAutosize]="true"
        formControlName="description"
        placeholder="Write a descriptive coupon details."
      ></textarea>
    </mat-form-field>

    <mat-form-field style="margin: 4px">
      <mat-label>Discount Type</mat-label>
      <mat-select
        required
        style="margin: 2px"
        required
        formControlName="discountType"
        (valueChange)="discountValue.value=''"
        [(value)]="discountUnit"
      >
        <mat-option *ngFor="let type of discountTypeArray" [value]="type.replace(' ','_').toUpperCase()"
          >{{ type.replace('_', ' ') | titlecase }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Discount {{ discountUnit.replace('_', ' ') | titlecase }}</mat-label>
      <div></div>
      <input
        placeholder="Specify a discount amount"
        matInput
        required
        formControlName="discountAmount"
        currencyMask
        [options]="discountUnit=='FLAT_AMOUNT' ? flatAmountMaskConfig : percentageMaskConfig"
        #discountValue
      />
    </mat-form-field>

    <div style="display: flex; flex-direction: column; gap: 8px 24px; flex-wrap: wrap; margin-top: 15px">
      <mat-checkbox formControlName="allProducts" color="primary">Available To All Products</mat-checkbox>
    </div>
  </form>
</greco-simple-dialog>

<div class="buttonsDiv">
  <button class="customButton" type="button" mat-stroked-button (click)="close()">Cancel</button>
  <button
    class="customButton"
    type="button"
    mat-stroked-button
    color="primary"
    [disabled]="formGroup.invalid || submitting"
    (click)="getResult()"
  >
    <span *ngIf="!submitting">Create</span>
    <mat-icon *ngIf="submitting" class="spin">loop</mat-icon>
  </button>
</div>
