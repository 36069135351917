import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { CommunityAgreementUsage } from '@greco/community-agreements';

@Injectable({ providedIn: 'root' })
export class CommunityAgreementsUsageService {
  constructor(private http: HttpClient) {}

  async getManyByProdAddonId(prodAddonId: string) {
    return await toPromise(
      this.http.get<CommunityAgreementUsage[]>(`/api/agreements_usage/usage`, { params: { prodAddonId } })
    );
  }
}
