import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Community, CommunityResource, CommunityResourceAction } from '@greco/identity-communities';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { SecurityService } from '@greco/ngx-security-util';
import { CreateCommunityDialog } from '../../dialogs';

@Component({
  selector: 'greco-community-page',
  templateUrl: './community.page.html',
  styleUrls: ['./community.page.scss'],
})
export class CommunityPage implements OnChanges {
  @Input() googleMapsKey = 'AIzaSyBxVmVtXTm4_SjzgaB7KYrruNE7FlR8jeI';
  @Input() community!: Community;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private security: CommunitySecurityService,
    private securitySvc: SecurityService,
    private clipboard: ClipboardService
  ) {}

  canUpdateCommunityInfo = false;

  canCreateSub$ = this.securitySvc.hasAccess(CommunityResource.key, CommunityResourceAction.CREATE, {}, true);

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.community.currentValue?.id !== changes.community.previousValue?.id) {
      this.canUpdateCommunityInfo = this.community
        ? await this.security.hasAccess(this.community.id, CommunityResource.key, 'UPDATE').catch(() => false)
        : false;
    }
  }

  async createCommunity() {
    const dialog = this.dialog.open(CreateCommunityDialog, {
      data: { parentId: this.community.id },
      width: '750px',
      maxWidth: '90%',
    });
    const subCommunity = await toPromise(dialog.afterClosed());
    if (subCommunity?.id) await this.router.navigate([subCommunity.id], { relativeTo: this.route });
  }

  copyCommunityLink() {
    this.clipboard.copy(
      'https://lf3.fit/' + encodeURIComponent(this.community.invitationId || this.community.id),
      'Link'
    );
  }
}
