import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { PersonResource, Resource, ResourceTag, ResourceType } from '@greco/booking-events';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { Subject } from 'rxjs';
import { ResourcesService } from '../../services';
import { ResourceTagService } from '../../services/resource-tag.service';
@Component({
  selector: 'greco-specify-substitutions',
  templateUrl: './specify-substitutions.dialog.html',
  styleUrls: ['./specify-substitutions.dialog.scss'],
})
export class SpecifySubstitutionsDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      communityId: string;
      resourceTags: ResourceTag[];
      resources: Resource[];
      title: string;
      content: string;
    },
    private formBuilder: FormBuilder,
    private resourceSvc: ResourcesService,
    private resourceTagSvc: ResourceTagService
  ) {}

  private _onDestroy$ = new Subject<void>();

  form = this.formBuilder.group({
    resources: [[]],
    specification: [],
  });

  tags: ResourceTag[] = [];
  resources: PersonResource[] = [];
  tagsAndResources: { tag: ResourceTag; resources: PersonResource[] }[] = [];

  searchQuery = '';
  specifySubstitutions = false;

  emails: string[] = [];
  selectedResources: PersonResource[] = [];

  dialogData: DialogData = {
    title: this.data.title ? this.data.title : 'A Resource is Requesting Substitution',
    content: this.data.content
      ? this.data.content
      : 'Updating this status will email other resources about the change, who would you like to notify?',
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Confirm',
        role: 'confirm',
        resultFn: async () => {
          switch (this.form.value.specification) {
            case 'specify':
              return this.emails;
            case 'none':
              return ['no emails'];
            default:
              return [];
          }
        },
      },
    ],
  };

  filterTagsAndResouces() {
    this.resources.forEach(resource => {
      if (resource.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
        resource.resourceTags.forEach(tag => {
          const index = this.tagsAndResources.findIndex(grouping => grouping.tag.id === tag.id);
          if (index > -1) this.tagsAndResources[index].resources.push(resource);
          else this.tagsAndResources.push({ tag, resources: [resource] });
        });
      }
    });
  }

  select(resource: PersonResource) {
    this.emails.includes(resource?.user?.contactEmail || '') ? this.removeEmail(resource) : this.addEmail(resource);
  }

  addEmail(resource: PersonResource) {
    this.selectedResources.push(resource);
    this.emails.push(resource?.user?.contactEmail || '');
  }

  removeEmail(resource: PersonResource) {
    const email = resource?.user?.contactEmail || '';
    const index = this.emails.findIndex(item => item === email);
    this.emails.splice(index, 1);
    this.selectedResources.splice(index, 1);
  }

  async ngOnInit() {
    if (!this.data.communityId) return;
    this.tags = this.data.resourceTags
      ? this.data.resourceTags
      : await this.resourceTagSvc.getResourceTags(this.data.communityId);
    this.tags = this.tags.sort((a, b) => a.label.localeCompare(b.label));

    const query = RequestQueryBuilder.create();
    const resourceData = await this.resourceSvc.paginateResources(query, this.data.communityId, { limit: 500 });
    this.resources = resourceData.items.filter(
      resource =>
        resource.type === ResourceType.PERSON &&
        !resource.disabled &&
        !this.data.resources?.map(resource => resource.id).includes(resource.id)
    ) as PersonResource[];

    this.filterTagsAndResouces();
  }
}
