import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { AppConfig, APP_CONFIG } from '@greco/ngx-app-config';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunityService } from '@greco/ngx-identity-communities';
import { CustomerSupportService } from '@greco/ngx-platform-customer-support';
import { TypeformService } from '@greco/ngx-typeform';
import type { WidgetOptions } from '@typeform/embed';

@Injectable()
export class WelcomeFormResolver implements Resolve<{ id: string; options: Pick<WidgetOptions, 'hidden'> } | null> {
  constructor(
    private customerSupportService: CustomerSupportService,
    private communityService: CommunityService,
    private userService: UserService,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: AppConfig,
    private typService: TypeformService
  ) {}
  async resolve(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Promise<{ id: string; options: Pick<WidgetOptions, 'hidden'> } | null> {
    const formId = (await this.customerSupportService.getCustomerSupportConfig(this.appConfig.name))
      ?.onboardingTypeform;
    const communityId = route.params.communityId;
    const community = communityId ? await this.communityService.getCommunity(communityId) : null;
    const user = await toPromise(this.userService.user$);
    if (formId && user?.id) {
      const alreadySubmitted = await this.typService.getMostRecentResponse(user.id, formId);
      if (alreadySubmitted) {
        this.router.navigate(['']);
        return null;
      }
    }

    /* TODO(adaoust): Replace with config about whether community leverages the onboarding form. */
    if (!formId || !community || !user || !community.browsable) {
      await this.router.navigateByUrl('/');
      return null;
    }
    return {
      id: formId,
      options: {
        hidden: {
          community_name: community.name,
          community_email: community.email,
          community_phone_number: community.phoneNumber,
          community_offers_online: community.tags?.includes('online').toString() || 'false',
          community_offers_in_studio: community.tags?.includes('in-studio').toString() || 'false',
          user_email: user.contactEmail,
          user_display_name: user.displayName,
          user_friendly_name: user.friendlyName || '',
          user_phone_number: user.phoneNumber || '',
          user_id: user?.id || '',
        },
      },
    };
  }
}
