<greco-table [data]="selectedProductAgreements || []">
  <ng-container *grecoLet="agreementUsages$ | async"></ng-container>
  <mat-icon *grecoTableCol="''; let condition; fitContent: true" [matTooltip]="condition.id || ''">event_note</mat-icon>

  <p *grecoTableCol="'Title'; let agreement">{{ agreement.title }}</p>

  <button
    *grecoTableCol="'Preview'; let agreement"
    mat-icon-button
    type="button"
    color="primary"
    style="margin-right: 8px"
    (click)="previewAgreement(agreement)"
  >
    <mat-icon>visibility</mat-icon>
  </button>

  <button
    *grecoTableCol="''; let agreement"
    mat-icon-button
    type="button"
    color="warn"
    style="margin-right: 8px"
    (click)="deleteAgreementUsage(agreement)"
  >
    <mat-icon>delete</mat-icon>
  </button>
</greco-table>

<ng-container *grecoLet="agreements$ | async as agreements">
  <div style="height: 32px; padding: 8px">
    <ng-container *ngIf="agreements?.length; else noAgreements">
      <button mat-button style="width: 100%; height: 100%" type="button" [matMenuTriggerFor]="addAgreements">
        <mat-icon
          style="--icon-size: 20px; height: var(--icon-size); width: var(--icon-size); font-size: var(--icon-size)"
        >
          add_circle_outline
        </mat-icon>
        <span>Add Agreement</span>
      </button>
    </ng-container>
    <ng-template #noAgreements>
      <button mat-button style="width: 100%; height: 100%" type="button" [disabled]="true">
        <mat-icon
          style="--icon-size: 20px; height: var(--icon-size); width: var(--icon-size); font-size: var(--icon-size)"
        >
          add_circle_outline
        </mat-icon>
        <span>
          <i>{{ selectedProductAgreements ? 'No More Agreements Found' : 'No Agreements Configured' }}</i>
        </span>
      </button>
    </ng-template>
  </div>

  <mat-menu #addAgreements>
    <button mat-menu-item *ngFor="let agreement of agreements" (click)="addAgreement(agreement)">
      <mat-icon>event_note</mat-icon>
      <span>{{ agreement.title }}</span>
    </button>
  </mat-menu>
</ng-container>
