import { Address } from '@greco/ui-address-input';
import { BaseDto } from '@greco/util-dto';
import { IsOptional, MaxLength } from 'class-validator';

export class UpdatePaymentDisputeDto extends BaseDto {
  /**Misc Required**/
  accountId!: string;
  accessActivityLog?: string;

  /**Customer Info**/
  customerName?: string;
  customerEmailAddress?: string;
  billingAddress?: Address | null;

  /**Product Description**/
  @IsOptional()
  @MaxLength(20000, {
    message: 'Product Description should be under 20,000 charachters',
  })
  productDescription?: string;

  /**Cancellation Rebuttal**/
  @IsOptional()
  @MaxLength(20000, {
    message: 'Cancellation Rebuttal should be under 20,000 charachters',
  })
  cancellationRebuttal?: string;

  /**Refund Refusal Explanation**/
  @IsOptional()
  @MaxLength(20000, {
    message: 'Refund Refusal Explanation should be under 20,000 charachters',
  })
  refundRefusalExplanation?: string;

  /**Customer Purchase IP**/
  @IsOptional()
  @MaxLength(39, {
    message: 'Customer Purchase IP Address should be under 39 charachters',
  })
  customerPurchaseIP?: string;

  /**Service Information**/
  serviceDate?: string;
  serviceDocumentationURL?: string;
  serviceDocumentationStripeFileId?: string;

  /**Customer Communication**/
  customerCommunicationURL?: string;
  customerCommunicationStripeFileId?: string;

  /**Customer Signature**/
  customerSignatureURL?: string;
  customerSignatureStripeFileId?: string;

  /**Receipt**/
  receiptURL?: string;
  receiptStripeFileId?: string;

  /**Cancellation Policy**/
  @IsOptional()
  @MaxLength(20000, {
    message: 'Cancellation Policy Disclosure should be under 20,000 charachters',
  })
  cancellationPolicyDisclosure?: string;
  cancellationPolicyURL?: string;
  cancellationPolicyStripeFileId?: string;

  /**Duplicate Charge**/
  @IsOptional()
  @MaxLength(20000, {
    message: 'Duplicate Charge Explanation should be under 20,000 charachters',
  })
  duplicateChargeExplanation?: string;
  duplicateChargeId?: string;
  duplicateChargeDocumentationURL?: string;
  duplicateChargeDocumentationStripeFileId?: string;

  /**Refund Policy**/
  @IsOptional()
  @MaxLength(20000, {
    message: 'Refund Policy Disclosure should be under 20,000 charachters',
  })
  refundPolicyDisclosure?: string;
  refundPolicyURL?: string;
  refundPolicyStripeFileId?: string;

  /**Uncategorized**/
  @IsOptional()
  @MaxLength(20000, {
    message: 'Uncategorized Text should be under 20,000 charachters',
  })
  uncategorizedText?: string;
  uncategorizedFileURL?: string;
  uncategorizedStripeFileId?: string;
}
