import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import type { Community } from '@greco/identity-communities';
import {
  CommunityRolePermissionDto,
  CommunitySecurityAssignment,
  CommunitySecurityRole,
} from '@greco/identity-community-staff';
import type { SecurityResource } from '@greco/security';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';
import { map } from 'rxjs/operators';
import { CommunitySecurityServiceModule } from './community-security.module';

const HOUR_IN_MS = 60 * 60 * 1000;

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}

@Injectable({ providedIn: CommunitySecurityServiceModule })
export class CommunitySecurityService {
  constructor(private http: HttpClient) {}

  getAllowedPermissions(): Promise<SecurityResource[]> {
    return toPromise(this.http.get<SecurityResource[]>('/api/community-security/allowedPermissions'));
  }

  async communitiesWithAccess(resource: string, action: string): Promise<Community[]> {
    const key = `COMMUNITIES_WITH_ACCESS_${resource}_${action}`;

    const fromCache = this._cachedCommunitiesWithAccess(key);
    if (fromCache !== null) return fromCache;

    const communities = await toPromise(
      this.http.get<Community[]>('/api/community-security/with-access', { params: { resource, action } })
    );

    this._cacheCommunitiesWithAccess(key, communities);
    return communities;
  }

  private _cachedCommunitiesWithAccess(key: string): Community[] | null {
    try {
      const cache = JSON.parse(localStorage.getItem(key) || '{}');

      const expires = cache?.expires ?? 0;
      if (expires <= Date.now()) return null;

      return cache?.communities ?? null;
    } catch {
      return null;
    }
  }

  private _cacheCommunitiesWithAccess(key: string, communities: Community[]) {
    try {
      const cache = { communities, expires: Date.now() + HOUR_IN_MS };
      localStorage.setItem(key, JSON.stringify(cache));
    } catch {
      /* noop */
    }
  }

  async hasAccess(community: string, resource: string, action: string): Promise<boolean> {
    const key = `COMMUNITY_HAS_ACCESS_${community}_${resource}_${action}`;

    const fromCache = this._cachedHasAccess(key);
    if (fromCache !== null) return fromCache;

    const hasAccess = await toPromise(
      this.http.get<boolean>(`/api/community-security/${community}/has-access`, { params: { resource, action } })
    );

    this._cacheHasAccess(key, hasAccess);
    return hasAccess;
  }

  private _cachedHasAccess(key: string): boolean | null {
    try {
      const cache = JSON.parse(localStorage.getItem(key) || '{}');

      const expires = cache?.expires ?? 0;
      if (expires <= Date.now()) return null;

      return cache?.hasAccess ?? null;
    } catch {
      return null;
    }
  }

  private _cacheHasAccess(key: string, hasAccess: boolean) {
    try {
      const cache = { hasAccess, expires: Date.now() + HOUR_IN_MS };
      localStorage.setItem(key, JSON.stringify(cache));
    } catch {
      /* noop */
    }
  }

  // @Get(':communityId/roles')
  async paginateCommunityRoles(
    communityId: string,
    options: IPaginationOptions,
    queryBuilder?: RequestQueryBuilder
  ): Promise<PaginatedDto<CommunitySecurityRole>> {
    return await toPromise(
      this.http.get<any>(`/api/community-security/${communityId}/roles`, {
        params: {
          ...queryBuilder?.queryObject,
          ...paginationQueryParams(options),
        },
      })
    );
  }

  countCommunityRoles(communityId: string): Promise<{ count: number }> {
    return toPromise(this.http.get<{ count: number }>(`/api/community-security/${communityId}/roles-count`));
  }

  createCommunityRole(
    community: string,
    data: Pick<CommunitySecurityRole, 'allowInChildren' | 'description' | 'title'>
  ): Promise<CommunitySecurityRole> {
    return toPromise(this.http.post<CommunitySecurityRole>(`/api/community-security/${community}/roles`, data));
  }

  getCommunityRole(community: string, role: string): Promise<CommunitySecurityRole> {
    return toPromise(this.http.get<CommunitySecurityRole>(`/api/community-security/${community}/roles/${role}`));
  }

  updateCommunityRole(
    community: string,
    role: string,
    data: Pick<CommunitySecurityRole, 'allowInChildren' | 'description' | 'title'>
  ): Promise<CommunitySecurityRole> {
    return toPromise(this.http.put<CommunitySecurityRole>(`/api/community-security/${community}/roles/${role}`, data));
  }

  deleteCommunityRole(community: string, role: string): Promise<CommunitySecurityRole> {
    return toPromise(this.http.delete<CommunitySecurityRole>(`/api/community-security/${community}/roles/${role}`));
  }

  updateCommunityRolePermissions(
    community: string,
    role: string,
    permissions: CommunityRolePermissionDto[]
  ): Promise<CommunitySecurityRole> {
    return toPromise(
      this.http.put<CommunitySecurityRole>(`/api/community-security/${community}/roles/${role}/permissions`, {
        permissions,
      })
    );
  }

  getCommunityRoleAssignments(
    community: string,
    role: string,
    options?: IPaginationOptions
  ): Promise<PaginatedDto<CommunitySecurityAssignment>> {
    return toPromise(
      this.http.get<PaginatedDto<CommunitySecurityAssignment>>(
        `/api/community-security/${community}/roles/${role}/assignments`,
        { params: paginationQueryParams(options) }
      )
    );
  }

  createCommunityRoleAssignment(community: string, role: string, userId: string): Promise<CommunitySecurityAssignment> {
    return toPromise(
      this.http.post<CommunitySecurityAssignment>(`/api/community-security/${community}/roles/${role}/assignments`, {
        userId,
      })
    );
  }

  getCommunityRoleAssignment(
    community: string,
    role: string,
    assignment: string
  ): Promise<CommunitySecurityAssignment> {
    return toPromise(
      this.http.get<CommunitySecurityAssignment>(
        `/api/community-security/${community}/roles/${role}/assignments/${assignment}`
      )
    );
  }

  deleteCommunityRoleAssignment(community: string, role: string, assignment: string): Promise<void> {
    return toPromise(
      this.http.delete<any>(`/api/community-security/${community}/roles/${role}/assignments/${assignment}`).pipe(
        map(() => {
          return;
        })
      )
    );
  }
}
