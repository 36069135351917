import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccountLink } from '@greco/account-linking';
import { ProductConditionService } from '@greco/ngx-sales-products';
import { PropertyListener } from '@greco/property-listener-util';
import {
  Product,
  ProductConditionContext,
  ProductStatus,
  ProductVariant,
  UserAvailabilityAddon,
} from '@greco/sales-products';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'greco-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  inf = Infinity;

  constructor(
    private router: Router,
    private cartService: CartService,
    private conditionSvc: ProductConditionService
  ) {}

  @PropertyListener('userConditionContext') private _userConditionContext$ =
    new BehaviorSubject<ProductConditionContext | null>(null);
  @Input() userConditionContext?: ProductConditionContext;
  private _product$ = new BehaviorSubject<Product | null>(null);
  @Input() product!: Product;
  @Output() productBuyClicked = new EventEmitter<void>();

  @PropertyListener('selectedVariant') private _variant$ = new ReplaySubject<ProductVariant | null>(1);
  selectedVariant?: ProductVariant;

  @PropertyListener('userId') private _userId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() userId?: string;

  @Input() linkedAccounts?: AccountLink[];

  selectedImageUrl = '';

  private availabilityAddon$ = this._product$.pipe(
    distinctUntilChanged((prev, next) => prev?.id === next?.id),
    map(product => (product as any)?.availabilityAddon as UserAvailabilityAddon),
    shareReplay(1)
  );

  sortedVariants$ = this._product$.pipe(
    map(product => product?.variants?.sort((a, b) => b.priority - a.priority).filter(variant => !variant.isHidden))
  );

  conditionCanBuy$ = combineLatest([this._userConditionContext$, this.availabilityAddon$]).pipe(
    map(([context, extension]) => {
      if (!context) return { result: false, errors: ['No user retrieved'], messages: [] };
      if (!extension) return { result: true, errors: [], messages: [] };

      return this.conditionSvc.evaluateAll(extension, context);
    }),
    shareReplay(1)
  );

  ngOnInit() {
    if (this.selectedVariant && this.selectedVariant?.image)
      this.selectedImageUrl = this.selectedVariant.image.imageURL;
    else this.selectedImageUrl = this.product?.images[0]?.imageURL || 'assets/lf3/icon_square_pad.png';
  }

  changeImage(variant: ProductVariant | undefined) {
    if (variant && variant.image) this.selectedImageUrl = variant.image.imageURL;
  }

  @PropertyListener('product')
  private _setSelectedVariant() {
    this._product$.next(this.product);
    this.selectedVariant =
      this.product?.variants.filter(v => v.status === ProductStatus.Active && !v.isHidden)?.[0] || null;
  }

  async addToCart(variant: ProductVariant) {
    this.cartService.add(variant);
  }

  async buyNow(variant: ProductVariant) {
    await this.router.navigate(['shop/checkout'], { queryParams: { items: variant.id, user: this.userId || null } });
    this.productBuyClicked.emit();
  }

  async goToProductPage(variant?: ProductVariant) {
    await this.router.navigate(['shop/products/' + this.product.id], {
      queryParams: { variantId: variant?.id },
      queryParamsHandling: 'merge',
    });
  }
}
