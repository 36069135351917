import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { LetModule } from '@greco/ngx-let-util';
import { SafePipeModule } from 'safe-pipe';
import { BreadcrumbService } from './breadcrumbs.service';
import { TabRoutesComponent } from './tab-routes.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    SafePipeModule,
    LetModule,
  ],
  declarations: [TabRoutesComponent],
  providers: [BreadcrumbService],
  exports: [TabRoutesComponent],
})
export class TabRoutesModule {}
