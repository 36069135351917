import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { PaymentDispute, PaymentDisputeStats, UpdatePaymentDisputeDto } from '@greco/finance-payments';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}
@Injectable({
  providedIn: 'root',
})
export class PaymentDisputeService {
  constructor(private http: HttpClient) {}

  // Post('payment-disputes/document/:paymentDisputeId')
  async submitPaymentDispute(paymentDisputeId: string, data: UpdatePaymentDisputeDto) {
    return await toPromise(this.http.post<PaymentDispute>(`/api/payment-disputes/document/${paymentDisputeId}`, data));
  }

  //  @Post(':paymentDisputeId/doc')
  async uploadPaymentDisputeDoc(paymentDisputeId: string, data: FormData) {
    const result = await toPromise(
      this.http.post<PaymentDispute>(`/api/payment-disputes/${paymentDisputeId}/doc`, data)
    );
    return result;
  }

  // @Post(':paymentDisputeId')
  async submitPaymentDisputeToStripe(paymentDisputeId: string) {
    return await toPromise(this.http.post<PaymentDispute>(`/api/stripe/${paymentDisputeId}`, {}));
  }
  async savePaymentDispute(paymentDisputeId: string, dto: UpdatePaymentDisputeDto) {
    return await toPromise(this.http.post<PaymentDispute>(`/api/payment-disputes/${paymentDisputeId}`, dto));
  }

  // @Post('status/:paymentDisputeId')
  async closeStripePaymentDispute(paymentDisputeId: string, accountId: string) {
    return await toPromise(this.http.post<PaymentDispute>(`/api/stripe/status/${paymentDisputeId}`, { accountId }));
  }

  // @Get(':paymentDisputeId')
  async getPaymentDispute(paymentDisputeId: string) {
    return await toPromise(this.http.get<PaymentDispute>(`/api/payment-disputes/${paymentDisputeId}`));
  }

  async getPaymentDisputesByUserId(userId: string) {
    return await toPromise(this.http.get<PaymentDispute[]>(`/api/payment-disputes/user-payment-diputes/${userId}`));
  }

  // @Get('paginate')
  async paginate(
    accountId: string,
    pagination: IPaginationOptions,
    queryBuilder: RequestQueryBuilder
  ): Promise<PaginatedDto<PaymentDispute>> {
    return await toPromise(
      this.http.get<PaginatedDto<PaymentDispute>>(`/api/payment-disputes/paginate`, {
        params: {
          accountId,
          ...paginationQueryParams(pagination),
          ...queryBuilder.queryObject,
        },
      })
    );
  }

  // @Get('stats')
  async getStats(accountId: string) {
    return await toPromise(this.http.get<PaymentDisputeStats>(`/api/payment-disputes/stats/${accountId}`));
  }
}
