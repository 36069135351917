import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { BookingStatus, CalendarEvent, EventSeries, RoomResource, RoomResourceSpot } from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BookingService } from '../../services';

@Component({
  selector: 'greco-room-spot-picker',
  templateUrl: './room-spot-picker.component.html',
  styleUrls: ['./room-spot-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RoomSpotPickerComponent }],
})
export class RoomSpotPickerComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  constructor(
    private _elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    private bookingSvc: BookingService,
    private breakpoints: BreakpointObserver
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'greco-room-spot-picker';

  private _onTouched?: () => void;
  private _onChange?: (value: RoomResourceSpot | null) => void;
  private _onDestroy$ = new Subject<void>();
  readonly stateChanges = new Subject<void>();

  @ViewChild(MatInput) private _input?: MatInput;
  @ViewChild('primary') primary: ElementRef | null = null;
  primaryColor: string | null = null;

  @ViewChild('canvas', { static: true }) private canvas?: ElementRef<HTMLCanvasElement>;
  canvasContext: CanvasRenderingContext2D | null = null;
  image: HTMLImageElement | null = null;
  refreshCanvas$ = new BehaviorSubject<null>(null);

  selectedSpotId = '';
  hoveredSpotId = '';
  cursorStyle = '';
  spotImages: { [key: string]: string } = {};

  mobile = false;

  @HostBinding() readonly id = `${RoomSpotPickerComponent._controlType}-${RoomSpotPickerComponent._id++}`;
  readonly controlType = RoomSpotPickerComponent._controlType;

  _room: RoomResource | null = null;
  spots: RoomResourceSpot[] = [];
  @Input() get room() {
    return this._room;
  }
  set room(room) {
    this._room = room;
    if (room && room.spots?.length)
      this.spots = [...room.spots, { spotNumber: 0, id: 'general', name: 'General' } as RoomResourceSpot];
    else this.spots = [];
  }

  @Input() event!: CalendarEvent | EventSeries;
  @Input() readonly = false;
  @PropertyListener('event') private event$ = new BehaviorSubject(this.event);

  @Input() disableSpotIds: string[] = [];
  @PropertyListener('disableSpotIds') private disableSpotIds$ = new BehaviorSubject(this.disableSpotIds);

  @Output() selectedSpot = new EventEmitter<RoomResourceSpot | null>();

  _required = true;
  _readonly = false;

  isMobile$ = this.breakpoints.observe('(max-width: 700px)').pipe(
    map(({ matches }) => matches),
    tap(matches => (this.mobile = matches)),
    shareReplay(1)
  );

  bookedSpotIds$ = this.event$.pipe(
    switchMap(
      async event =>
        await this.bookingSvc.paginate(
          new RequestQueryBuilder().search({
            'event.id': event.id,
            status: { $in: [BookingStatus.CONFIRMED, BookingStatus.CHECKED_IN, BookingStatus.PENDING] },
          }),
          event.community.id,
          { limit: 100 }
        )
    ),
    map(result => result?.items),
    map(bookings => {
      if (!bookings) return null;

      const bookedSpots: string[] = [];
      bookings.forEach(booking => {
        if (booking.spotId) {
          bookedSpots.push(booking.spotId);
          this.spotImages[booking.spotId] = booking.user?.photoURL || 'assets/lf3/icon_square_pad.png';
        }
      });

      return bookedSpots;
    }),
    switchMap(bookedSpots => {
      return this.disableSpotIds$.pipe(
        map(disabledSpots => {
          const takenSpots = [...disabledSpots, ...(bookedSpots || [])].filter(x => x !== 'general');
          return takenSpots;
        })
      );
    })
  );

  scale = 1;

  drawSpots$ = combineLatest([this.bookedSpotIds$, this.refreshCanvas$]).pipe(
    map(([bookedSpotIds, _refresh]) => {
      if (this.image && this.canvasContext) this.drawImageScaled(this.image, this.canvasContext);
      else return;

      this.scale = this.canvasContext.canvas.width / this.canvasContext.canvas.clientWidth;

      this.room?.spots?.forEach(spot => {
        if (this.canvasContext && spot.x && spot.y && spot.width && spot.height) {
          if (bookedSpotIds?.includes(spot.id)) {
            const midx = (spot.x * 2 + spot.width) / 2;
            const midy = (spot.y * 2 + spot.height) / 2;
            if (!this.mobile) this.drawCircularImage(this.spotImages[spot.id], midx, midy);
          } else {
            if (this.selectedSpotId === spot.id) {
              this.canvasContext.fillStyle = 'rgba(105, 207, 142, .7)';
            } else if (this.hoveredSpotId === spot.id) {
              this.canvasContext.fillStyle = 'rgba(103, 146, 161, .8)';
            } else {
              this.canvasContext.fillStyle = 'rgba(103, 146, 161, .5)';
            }
            this.canvasContext?.fillRect(spot.x, spot.y, spot.width, spot.height);
          }
        }
      });
    })
  );

  private _value: RoomResourceSpot | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: RoomResourceSpot | null) {
    this._value = value;

    this._onChange?.(value);
    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.focused || !!this._input?.value || !this._input?.value || false;
  }

  get errorState() {
    return (
      !!this.ngControl?.touched &&
      (!!Object.keys(this.ngControl?.errors || {}).length || (this.required && !this.value))
    );
  }

  selectSpot(spot: RoomResourceSpot) {
    this.selectedSpotId = spot.id;
    this.refreshCanvas$.next(null);
    this.selectedSpot.emit(spot);
  }

  hoverSpot(spot?: RoomResourceSpot) {
    if (spot) this.hoveredSpotId = spot.id;
    else this.hoveredSpotId = '';
    this.refreshCanvas$.next(null);
  }

  canvasClick(event: MouseEvent) {
    this.room?.spots?.forEach(spot => {
      if (spot.x && spot.y && spot.width && spot.height) {
        if (spot.x <= event.offsetX * this.scale && spot.x + spot.width >= event.offsetX * this.scale) {
          if (spot.y <= event.offsetY * this.scale && spot.y + spot.height >= event.offsetY * this.scale) {
            this.selectSpot(spot);
            return;
          }
        }
      }
    });
  }

  onCanvas(event: MouseEvent) {
    for (const spot of this.room?.spots || []) {
      if (spot.x && spot.y && spot.width && spot.height) {
        if (spot.x <= event.offsetX * this.scale && spot.x + spot.width >= event.offsetX * this.scale) {
          if (spot.y <= event.offsetY * this.scale && spot.y + spot.height >= event.offsetY * this.scale) {
            this.hoverSpot(spot);
            if (!Object.keys(this.spotImages).includes(spot.id)) this.cursorStyle = 'pointer';
            return;
          }
        }
      }
    }
    this.hoveredSpotId = '';
    this.cursorStyle = '';
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.room-spots-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: RoomResourceSpot): void {
    this.value = value;
  }

  registerOnChange(fn: (value: RoomResourceSpot | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  drawImageScaled(image: HTMLImageElement, canvasContext: CanvasRenderingContext2D) {
    const canvas = canvasContext.canvas;
    canvas.height = image.height;
    canvas.width = image.width;

    const wRatio = canvas.width / image.width;
    const scaledHeight = image.height * wRatio;

    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    canvasContext.drawImage(image, 0, 0, canvas.width, scaledHeight);
  }

  drawCircularImage(imageURL: string, x: number, y: number) {
    const context = this.canvasContext;

    if (context) {
      const r1 = 42; // radius of the coloured circle on the outside
      const r2 = 34; // radius of the white circle inside
      const r3 = 30; // radius of the image drawn on top of those

      context.save();
      context.beginPath();
      context.arc(x, y, 20, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      context.fillStyle = this?.primaryColor || 'rgba(104, 90, 143, 1)';
      this.canvasContext?.fillRect(x - r1 / 2, y - r1 / 2, r1, r1);

      context.beginPath();
      context.arc(x, y, r2 / 2, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      context.fillStyle = 'rgba(255, 255, 255, 1)';
      this.canvasContext?.fillRect(x - r2 / 2, y - r2 / 2, r2, r2);

      context.beginPath();
      context.arc(x, y, r3 / 2, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      const image = new Image();
      image.src = imageURL;

      const imageWidthHeightRatio = image.width / image.height;
      let newWidth = r3;
      let newHeight = newWidth / imageWidthHeightRatio;

      if (newHeight < r3) {
        newHeight = r3;
        newWidth = newHeight * imageWidthHeightRatio;
      }

      let xoffset = 0;
      if (newWidth !== r3) xoffset = (newWidth - r3) / 2;

      context.drawImage(image, x - xoffset - r3 / 2, y - r3 / 2, newWidth, newHeight);
      context.restore();
    }
  }

  ngOnInit() {
    if (this.canvas && this.room?.imageURL) {
      this.canvasContext = this.canvas.nativeElement.getContext('2d');
      if (this.canvasContext) {
        this.image = new Image();
        this.image.src = this.room.imageURL;
        this.drawImageScaled(this.image, this.canvasContext);
      }
    }
    if (this.room?.spots) {
      const generalSpot = {
        spotNumber: 0,
        id: 'general',
        name: 'General',
        roomId: this.room.id,
        description: 'Let staff assign you a spot',
      } as RoomResourceSpot;
      this.room.spots = [...this.room.spots, generalSpot];
      this.room.spots = this.room.spots.sort((a, b) => (a.spotNumber > b.spotNumber ? 1 : -1));
    }
  }

  ngAfterViewInit() {
    this.primaryColor = getComputedStyle(this.primary?.nativeElement).color;
    this.refreshCanvas$.next(null);
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
