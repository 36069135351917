import { Component, InjectionToken, Input, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from '@greco/sales-subscriptions';

@Component({ template: '' })
export abstract class UpdateSubscriptionHandlerFormComponent {
  @Input() subscription?: Subscription;
  mode?: 'user' | 'staff';

  abstract form: FormGroup;

  abstract getData(): Promise<any>;
}

export interface UpdateSubscriptionHandlerForm {
  subscriptionType: string;

  stepLabel: string;

  formComponent: Type<UpdateSubscriptionHandlerFormComponent>;
}

export const SUBSCRIPTION_HANDLER_FORMS = new InjectionToken<UpdateSubscriptionHandlerForm[]>(
  'SUBSCRIPTION_HANDLER_FORMS'
);
