import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ShopProductCollectionDto } from '@greco/nestjs-sales-products';
import { ProductCollection } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { CollectionService } from '../../services';

@Component({
  selector: 'greco-collections-input',
  templateUrl: './collections-input.component.html',
  styleUrls: ['./collections-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CollectionsInputComponent }],
})
export class CollectionsInputComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<ShopProductCollectionDto[]>
{
  static ID = 0;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef,
    private formBuilder: FormBuilder,
    collectionSvc: CollectionService
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;

    this._internalForm.valueChanges
      .pipe(takeUntil(this._onDestroy$), distinctUntilKeyChanged('value'))
      .subscribe(() => this.stateChanges.next());

    this.collections$ = this._internalForm.valueChanges.pipe(
      startWith(this._internalForm.value),
      debounceTime(250),
      switchMap(async ({ searchQuery, value }) => {
        const activeIds = ((value || []) as ShopProductCollectionDto[])
          .filter(col => !!col.id)
          .map(col => col.id as string);
        const request = new RequestQueryBuilder().search({
          $and: [
            ...(activeIds.length ? [{ id: { $notin: activeIds } }] : []),
            ...(searchQuery ? [{ label: { $contL: searchQuery } }] : []),
          ],
        });
        return await collectionSvc.paginate(request, { limit: 10 }, false);
      }),
      map(data => data.items)
    );
  }

  private _onDestroy$ = new Subject<void>();

  readonly separatorKeyCodes = [COMMA];

  readonly _internalForm = this.formBuilder.group({
    searchQuery: [''],
    value: [null],
  });

  readonly stateChanges = new Subject<void>();
  readonly touched$ = new BehaviorSubject<boolean>(false);

  @HostBinding() readonly id = `greco-collections-input-${CollectionsInputComponent.ID++}`;
  readonly controlType = 'greco-collections-input';

  @Input() get value() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._internalForm.get('value')!.value as ShopProductCollectionDto[];
  }
  set value(value) {
    this._internalForm.setValue({ ...this._internalForm.value, value });
  }

  private _placeholder = '';
  @Input() get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  private _focused = false;
  get focused() {
    return this._focused;
  }
  set focused(focused) {
    if (focused && !this._focused) {
      this._focused = focused;
      this.stateChanges.next();
    } else if (!focused) {
      this._focused = focused;
      this.touched$.next(true);
      this.stateChanges.next();
    }
  }

  get empty() {
    const { searchQuery, value } = this._internalForm.value;
    return !searchQuery && !value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _required = false;
  @Input() get required() {
    return this._required;
  }
  set required(required) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input() get disabled() {
    return this._disabled;
  }
  set disabled(disabled) {
    this._disabled = coerceBooleanProperty(disabled);
    this._disabled ? this._internalForm.disable() : this._internalForm.enable();
    this.stateChanges.next();
  }

  get errorState() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._internalForm.get('value')!.invalid && this.touched$.value;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';

  collections$: Observable<ProductCollection[]>;

  add(event: MatChipInputEvent) {
    const label = (event.value || '').trim();
    if (label && !this.value?.find(col => col.label.toLowerCase() === label.toLowerCase())) {
      this._internalForm.setValue({
        searchQuery: '',
        value: [...(this._internalForm.value.value || []), { label }],
      });
    }
  }

  optionSelection(event: MatAutocompleteSelectedEvent) {
    this._internalForm.setValue({
      searchQuery: '',
      value: [...(this._internalForm.value.value || []), event.option.value],
    });
  }

  remove(collection: ShopProductCollectionDto) {
    const json = JSON.stringify(collection);
    this._internalForm.setValue({
      ...this._internalForm.value,
      value: (this._internalForm.value.value || []).filter(
        (col: ShopProductCollectionDto) => JSON.stringify(col) !== json
      ),
    });
  }

  ngOnDestroy() {
    this.touched$.complete();
    this.stateChanges.complete();

    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  // ControlValueAccessor
  writeValue(value: ShopProductCollectionDto[]): void {
    this._internalForm.setValue({ ...this._internalForm.value, value });
  }

  registerOnChange(fn: (value: ShopProductCollectionDto[]) => void) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._internalForm
      .get('value')!
      .valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe(value => fn(value));
  }

  registerOnTouched(fn: () => void): void {
    this.touched$.pipe(takeUntil(this._onDestroy$)).subscribe(() => fn());
  }

  // MatFormFieldControl
  setDescribedByIds(ids: string[]) {
    this._elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }
}
