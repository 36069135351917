import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from '@greco/sales-subscriptions';

@Component({
  selector: 'greco-subscription-schedule',
  templateUrl: './subscription-schedule.dialog.html',
  styleUrls: ['./subscription-schedule.dialog.scss'],
})
export class SubscriptionScheduleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) readonly data: { subscription: Subscription; communityId: string }) {}
}
