import { Account } from '@greco/finance-accounts';
import { ProductVariant } from './product-variant';

export enum VariantTransferTrigger {
  Initial = 'INITIAL',
  Renewal = 'RENEWAL',
  Update = 'UPDATE',
}

export enum VariantTransferProrateTrigger {
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS',
  CUSTOMER_REFUNDED = 'CUSTOMER_REFUNDED',
}

export interface VariantTransfer {
  id: string;
  variant: ProductVariant;

  title: string;

  amount: number;
  triggerEvents: VariantTransferTrigger[];

  sourceAccount: Account;
  destinationAccount: Account;

  applyRoyalties: boolean;

  prorateTrigger: VariantTransferProrateTrigger;
}
