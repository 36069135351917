<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput required formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Email Address</mat-label>
      <input matInput required formControlName="email" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input matInput required type="tel" mask="(000) 000-0000" formControlName="phone" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Address</mat-label>
      <greco-address-input required [strict]="true" formControlName="address"></greco-address-input>
    </mat-form-field>
  </form>
</greco-simple-dialog>
