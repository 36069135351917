import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { CommunityAgreement, CommunityAgreementUsage } from '@greco/community-agreements';
import { Community } from '@greco/identity-communities';
import { AgreementUsageByEventDto } from '@greco/nestjs-booking-events';
import { CommunityAgreementsService } from '@greco/ngx-community-agreements';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'greco-link-agreement',
  templateUrl: './link-agreement.dialog.html',
  styleUrls: ['./link-agreement.dialog.scss'],
})
export class LinkAgreementDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      community: Community;
      series: CommunityAgreementUsage[];
      linkedIds: string[];
      checked: boolean;
      eventId: string;
    },
    public dialogRef: MatDialogRef<LinkAgreementDialog>,
    private agreementsSvc: CommunityAgreementsService,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private securitySvc: CommunitySecurityService
  ) {}

  dialogData: DialogData = {
    title: 'Select Agreements to Link',
    showCloseButton: true,
  };

  selected$ = new BehaviorSubject<AgreementUsageByEventDto[] | null>(null);

  confirmed(items: CommunityAgreement[]) {
    this.dialogRef.close(items);
  }
}
