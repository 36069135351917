<greco-filter-bar
  [calendars]="true"
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()"
>
  <greco-calendar-picker
    calendarPicker
    style="height: 45px; margin-top: -36px; margin-right: 5px"
    [communityId]="community?.id"
    [initialCalendarIds]="initialCalendars"
    (refresh)="refresh()"
  ></greco-calendar-picker>

  <div style="display: flex; align-items: center; justify-content: flex-end; height: 32px">
    <mat-menu #seriesMenu>
      <p style="margin: 8px 16px">
        <small><b>Calendars</b></small>
      </p>

      <ng-container *ngFor=" let calendar of calendarsList$ | async">
        <div *ngIf="calendar.eventTemplates?.length">
          <button mat-menu-item [matMenuTriggerFor]="templateMenu">
            <mat-icon>{{calendar.icon || 'calendar_today'}}</mat-icon><span>{{calendar.title}}</span>
          </button>
        </div>

        <mat-menu #templateMenu>
          <p style="margin: 8px 16px">
            <small><b>Templates</b></small>
          </p>

          <ng-container *ngFor=" let template of calendar.eventTemplates;">
            <button mat-menu-item (click)="createSeriesFromTemplate(template.id, calendar.id)">
              <span>{{template.title}}</span>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>

      <button *ngIf="canCreateCustomSeries$ | async" mat-menu-item color="primary" (click)="createSeries()">
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <span>Custom Series</span>
          <mat-icon style="margin: 12px 0px 0px 16px">add</mat-icon>
        </div>
      </button>
    </mat-menu>
    <div style="display: flex; flex-direction: row" *ngIf="canCreateSeries$ | async">
      <ng-container *grecoLet="canCreateCustomSeries$ | async as createCustom">
        <button
          *ngIf="createCustom"
          mat-stroked-button
          style="height: 32px; border-top-right-radius: 0px; border-bottom-right-radius: 0px"
          color="primary"
          (click)="createSeries()"
        >
          New Series
        </button>
        <button
          mat-stroked-button
          style="height: 32px"
          [style.width]="createCustom ? '38px' : ''"
          [style.min-width]="createCustom ? '38px' : ''"
          [style.border-left]="createCustom ? 'none' : ''"
          [style.border-top-left-radius]="createCustom ? '0px' : ''"
          [style.border-bottom-left-radius]="createCustom ? '0px' : ''"
          color="primary"
          [matMenuTriggerFor]="seriesMenu"
        >
          {{ createCustom ? '' : 'New Series'}}
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
</greco-filter-bar>

<greco-table [data]="(events$ | async) || []" [highlight]="true" [loading]="loading" (rowClick)="openSeries($event)">
  <mat-icon *grecoTableCol="''; let event; fitContent: true;" [matTooltip]="event.id">event</mat-icon>

  <p *grecoTableCol="'Title'; let event">{{event.title}}</p>

  <p *grecoTableCol="'Calendar'; let event">{{event.calendar.title}}</p>

  <p *grecoTableCol="'Start Date'; let event">{{event.startDate | date}}</p>

  <ng-container *grecoTableCol="'End Date'; let event">
    <p *ngIf="event.endDate">{{event.endDate | date}}</p>
    <p *ngIf="!event.endDate">
      <small><em>No end date</em></small>
    </p>
  </ng-container>
  <ng-container *grecoTableCol="'Allow users to book spots'; let event; alignCenter: true">
    <p *ngIf="event.enableUserSpotBooking">Yes</p>
    <p *ngIf="!event.enableUserSpotBooking">-</p>
  </ng-container>

  <mat-chip-list *grecoTableCol="'Tags'; let event">
    <mat-chip *ngFor="let tag of event.tags"> {{ tag.label }} </mat-chip>
  </mat-chip-list>

  <mat-chip-list *grecoTableCol="'Resources'; let event">
    <mat-chip *ngFor="let assignment of event.resourceAssignments" style="padding-left: 6px">
      <div style="width: 24px; height: 24px">
        <greco-smart-img
          *ngIf="assignment.resource?.photoURL || assignment.resource?.type === 'PERSON' else noPhoto"
          [src]="assignment.resource?.photoURL || 'assets/lf3/icon_square_pad.png'"
          [round]="true"
        >
        </greco-smart-img>
      </div>
      <ng-template #noPhoto>
        <mat-icon *ngIf="assignment.resource; else noResource" style="margin-left: 2px">
          {{ { PERSON: 'account_circle', ROOM: 'living', OTHER: 'texture', ZOOM: 'videocam' }[assignment.resource?.type
          + ''] }}
        </mat-icon>
        <ng-template #noResource>
          <mat-icon>pending_actions</mat-icon>
        </ng-template>
      </ng-template>

      <!-- Name -->
      <span style="margin-left: 6px">{{ assignment?.resource?.name || 'Resource Needed' }}</span>
    </mat-chip>
  </mat-chip-list>

  <mat-chip-list *grecoTableCol="'Schedule'; let event;">
    <mat-chip *ngFor="let day of event.recurrence | recurrenceSplit; let event"> {{day | weekdayChip}} </mat-chip>
  </mat-chip-list>

  <mat-chip-list *grecoTableCol="'Status'; let event">
    <mat-chip> {{event.endDate | status}} </mat-chip>
  </mat-chip-list>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
