import { Component, ElementRef, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { toPromise } from '@greco-fit/util';
import type { FormEntity } from '@greco/nestjs-typeform';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { FormPickerDialog } from '../../dialogs';
import { TypeformService } from '../../services';

// export interface FormEntity {
//   id: string;
// }

@Component({
  selector: 'greco-typeform-form-picker',
  templateUrl: './form-picker.component.html',
  styleUrls: ['./form-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: TypeformFormPickerComponent }],
})
export class TypeformFormPickerComponent
  implements
    MatFormFieldControl<(FormEntity & { reusable: boolean; required: boolean })[] | null>,
    ControlValueAccessor,
    OnDestroy
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private typeformSvc: TypeformService,
    private _elementRef: ElementRef,
    private matDialog: MatDialog
  ) {
    if (ngControl) ngControl.valueAccessor = this;
  }

  private static ID = 0;

  private _onChange?: (value: (FormEntity & { reusable: boolean; required: boolean })[] | null) => void;
  private _onTouched?: () => void;

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-typeform-form-picker';
  readonly id = `${TypeformFormPickerComponent.ID++}-${this.controlType}`;

  _control = new FormControl();

  @Input() communityId!: string | null;

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string | null>();
  private _account$ = this._communityId$.pipe(switchMap(communityId => this.typeformSvc.getAccount(communityId)));
  private allForms$ = this._account$.pipe(
    switchMap(async account => (account ? await this.typeformSvc.getConnectedForms(account.id) : [])),
    shareReplay(1)
  );

  private search$ = new BehaviorSubject<string>('');
  readonly _forms$ = combineLatest([
    this.allForms$,
    this.search$,
    this._control.valueChanges.pipe(startWith(this._control.value)) as Observable<
      (FormEntity & { reusable: boolean; required: boolean })[] | null
    >,
  ]).pipe(
    map(([forms, search, currentValue]) =>
      forms.filter(
        form =>
          form.formTitle.toLowerCase().includes((search || '').toLowerCase()) &&
          !(currentValue || []).some(f => f.id === form.id)
      )
    )
  );

  @Input() get value() {
    return this._control.value as (FormEntity & { reusable: boolean; required: boolean })[] | null;
  }
  set value(value: (FormEntity & { reusable: boolean; required: boolean })[] | null) {
    this._control.setValue(value);
    this.search$.next('');

    this.stateChanges.next();
    this._onChange?.(value);
  }

  @Input() placeholder = '';

  private _required = false;
  @Input() get required() {
    return this._required;
  }
  set required(value) {
    this._required = value;

    if (value) this._control.setValidators([Validators.required]);
    else this._control.setValidators([]);
    this._control.updateValueAndValidity();

    this.stateChanges.next();
  }

  @Input() get disabled() {
    return this._control.disabled;
  }
  set disabled(value) {
    if (value) this._control.disable();
    else this._control.enable();
    this.stateChanges.next();
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';

  @ViewChild(MatInput) matInput?: MatInput;

  get empty() {
    return !this._control.value?.length && !this.matInput?.value;
  }

  get focused() {
    return this.matInput?.focused || false;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState() {
    return this._control.invalid;
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-typeform-form-picker-container');
    if (controlElement) controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(): void {
    this.matInput?.onContainerClick();
    this._onTouched?.();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.addForm(event.option.value);
  }

  async addForm(form: FormEntity) {
    if (!this.value?.some(f => f.id === form.id)) {
      const dialog = this.matDialog.open(FormPickerDialog, {
        data: { required: true, reusable: true },
        width: '350px',
      });
      const result = await toPromise(dialog.afterClosed());
      if (result) this.value = [...(this.value || []), { ...form, ...result }];
    }
  }

  async updateform(form: FormEntity & { reusable: boolean; required: boolean }) {
    if (this.disabled) return;
    const dialog = this.matDialog.open(FormPickerDialog, {
      data: { required: form.required, reusable: form.reusable },
      width: '350px',
    });

    const result = await toPromise(dialog.afterClosed());
    if (result) this.value = [...(this.value || []).map(f => (f.id === form.id ? { ...f, ...result } : f))];
  }

  removeForm(form: FormEntity) {
    this.value = this.value ? this.value.filter(f => f.id !== form.id) : null;
  }

  search(value: string) {
    this.search$.next(value);
  }

  ngOnDestroy() {
    this._communityId$.complete();
    this.stateChanges.complete();
    this.search$.complete();
  }

  @PropertyListener('placeholder')
  private _stateChanges() {
    this.stateChanges.next();
  }
}
