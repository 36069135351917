import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AccountLink } from '@greco/account-linking';
import { User } from '@greco/identity-users';
import { ProductVariant } from '@greco/sales-products';

@Component({
  selector: 'greco-linked-account-buy-button',
  templateUrl: './linked-account-buy-button.component.html',
  styleUrls: ['./linked-account-buy-button.component.scss'],
})
export class LinkedAccountBuyButtonComponent {
  constructor(private router: Router) {}
  @Input() userId?: string;
  @Input() linkedAccounts?: AccountLink[];
  @Input() selectedVariant!: ProductVariant;

  @Input() conditionCanBuy?: boolean;

  async selected(purchaseFor: User) {
    await this.router.navigate(['shop/checkout'], {
      queryParams: { items: this.selectedVariant.id, user: this.userId || null, purchaseFor: purchaseFor.id },
    });
  }
}
