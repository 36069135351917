import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ɵisPromise
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { BehaviorSubject, from, isObservable, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { AutocompleteSelectContentDirective } from './autocomplete-select.directive';
import { AutocompleteSelectValue, Search } from './autocomplete-select.model';

@Component({
  selector: 'greco-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: AutocompleteSelectComponent }]
})
export class AutocompleteSelectComponent<T>
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<AutocompleteSelectValue<T>> {
  private static ID = 0;

  @ContentChild(AutocompleteSelectContentDirective, { static: true }) content: AutocompleteSelectContentDirective<T>;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<T>();

  @Input() search: Search<T>;

  @Input() set value(value: AutocompleteSelectValue<T>) {
    this._updateValue(value);
  }
  get value() {
    return this._value;
  }

  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @HostBinding() id = `autocomplete-select-${AutocompleteSelectComponent.ID++}`;

  @HostBinding('class.floating') get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    return !this.searchQuery$.value && !this.value;
  }

  get errorState() {
    if (this.ngControl) return !this.ngControl.pristine && Object.keys(this.ngControl.errors || {}).length > 0;
    return this.required && this.empty;
  }

  focused = false;

  readonly controlType = 'autocomplete-select';

  readonly searchQuery$ = new BehaviorSubject<string>(null);
  readonly options$ = this.searchQuery$.pipe(
    debounceTime(300),
    switchMap(query => {
      const result = this.search?.(query) || of([]);

      if (isObservable(result)) return result;
      else if (ɵisPromise(result)) return from(result);
      else return of(result);
    })
  );

  readonly stateChanges = new Subject<void>();

  private _value: AutocompleteSelectValue<T> = null;
  private _disabled = false;
  private _required = false;
  private _placeholder: string;

  private _onChange: (value: AutocompleteSelectValue<T> | null) => any;
  private _onTouched: () => any;

  private readonly _onDestroy = new Subject<void>();

  constructor(focusMonitor: FocusMonitor, @Optional() @Self() public ngControl: NgControl, private ref: ElementRef) {
    if (this.ngControl !== null) this.ngControl.valueAccessor = this;

    focusMonitor
      .monitor(ref.nativeElement, true)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(origin => {
        this.focused = !!origin;
        this.stateChanges.next();
      });
  }

  setDescribedByIds(ids: string[]) {
    const element = this.ref.nativeElement.querySelector('input');
    element?.setAttribute('aria-describedby', ids.join(' '));
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stateChanges.complete();
    this.searchQuery$.complete();
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.ref.nativeElement.querySelector('input')?.focus();
    }
  }

  writeValue(value: AutocompleteSelectValue<T> | null) {
    this._value = value;
    this.searchQuery$.next(this._value?.label || null);
  }

  registerOnChange(fn: (value: AutocompleteSelectValue<T> | null) => any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _updateValue(value: AutocompleteSelectValue<T> | null) {
    this.writeValue(value);
    this.stateChanges.next();

    this._onChange?.(this._value);
    this.change.emit(this._value?.value);

    this._onTouched?.();

    const element = this.ref.nativeElement.querySelector('input') as HTMLInputElement;
    if (element) element.value = this._value?.label || '';
  }
}
