import { Component, Input } from '@angular/core';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-video-watch-progress-bar',
  templateUrl: './video-watch-progress-bar.component.html',
  styleUrls: ['./video-watch-progress-bar.component.scss'],
})
export class VideoWatchProgressBarComponent {
  @PropertyListener('progress') private _progress$ = new BehaviorSubject<number>(0);
  @Input() progress = 0;
  @PropertyListener('duration') private _duration$ = new BehaviorSubject<number>(0);
  @Input() duration = 0;

  value$ = combineLatest([this._progress$, this._duration$]).pipe(
    switchMap(async ([progress, duration]) => {
      return progress === 0 ? 0 : (progress / duration) * 100;
    })
  );
}
