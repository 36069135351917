import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CouponAssignmentFormComponent } from '@greco/feature-coupons';
import { CreateSaleCategoryCouponAssignmentDto } from '@greco/nestjs-sales-products';
import { CommunityService } from '@greco/ngx-identity-communities';
import { SaleCategoryService } from '@greco/ngx-sales-purchases';
import { PropertyListener } from '@greco/property-listener-util';
import { SaleCategory } from '@greco/sales-purchases';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-fit-sale-category-coupon-assignment-from',
  templateUrl: './sale-category-coupon-assignment-from.component.html',
  styleUrls: ['./sale-category-coupon-assignment-from.component.scss'],
})
export class SaleCategoryCouponAssignmentFormComponent extends CouponAssignmentFormComponent {
  constructor(
    private formBuilder: FormBuilder,
    private salesCategorySvc: SaleCategoryService,
    private communitySvc: CommunityService
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      saleCategory: [null, Validators.required],
    });
  }

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  communityId: string = this.communityId;

  formGroup: FormGroup;
  assignments: any;
  selectedAssignments: any;
  createAssignmentFromSelected: any;
  couponAssignmentSvc: any;
  dialogRef: any;
  selectedSaleCategory?: SaleCategory;
  SaleCategoryList: CreateSaleCategoryCouponAssignmentDto[] = [];

  salesCategories$ = this._communityId$.pipe(
    switchMap(async communityId => {
      const community = await this.communitySvc.getCommunity(communityId);
      return await this.salesCategorySvc.getMany(community.financeAccountId);
    })
  );

  save() {
    if (this.formGroup.valid && this.selectedSaleCategory) {
      this.SaleCategoryList.push({
        saleCategoryId: this.selectedSaleCategory.id,
        couponId: this.couponId,
        type: this.type,
      });
    }
    return { data: this.SaleCategoryList, module: 'sales' };
  }
}
