<greco-item [lines]="false">
  <mat-icon item-start>info</mat-icon>

  <div style="width: 100%">
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">
      <strong>Cancellation Policy</strong>
    </p>
    <p
      [matTooltip]="(subtitle$ | async) || ''"
      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
    >
      <small>{{ subtitle$ | async }}</small>
    </p>
  </div>

  <div item-end>
    <button
      mat-button
      color="accent"
      style="height: 32px; margin-left: auto; padding: 0 12px"
      (click)="openDetailsDialog()"
    >
      Details
    </button>
  </div>
</greco-item>
