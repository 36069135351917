<div *ngIf="stats$ | async as stats" class="stats-container">
  <greco-stat-box
    title="Lifetime value"
    description="Total amount collected via subscriptions"
    [content]="(stats.value?.lifetimeValue || 0) / 100 | currency"
    [delta]="stats.delta?.lifetimeValue"
  ></greco-stat-box>

  <greco-stat-box
    title="Active subscriptions"
    description="Current count of active subscriptions"
    [content]="stats.value?.activeSubs || '-'"
    [delta]="stats.delta?.activeSubs"
  ></greco-stat-box>

  <greco-stat-box
    title="MRR"
    description="Normalized monthly recurring revenue"
    [content]="(stats.value?.MRR || 0) / 100 | currency"
    [delta]="stats.delta?.MRR"
  ></greco-stat-box>

  <greco-stat-box
    title="New subscribers"
    description="Number of new paid subscribers today"
    [content]="stats.value?.newSubs || '-'"
    [delta]="stats.delta?.newSubs"
  ></greco-stat-box>

  <greco-stat-box
    title="Subscriber churn rate"
    description="Cancellation rate in the last 30 days"
    [content]="(stats.value?.subChurnedRate || 0 | number : '1.0-0') + '%'"
    [delta]="stats.delta?.subChurnedRate"
    [deltaPositiveGood]="false"
  ></greco-stat-box>

  <greco-stat-box
    title="Churned revenue"
    description="Total MRR lost in the last day"
    [content]="(stats.value?.churnedRevenue || 0) / 100 | currency"
    [delta]="stats.delta?.churnedRevenue"
    [deltaPositiveGood]="false"
  ></greco-stat-box>
</div>
