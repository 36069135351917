import {
  ProductCondition,
  ProductConditionDto,
  ProductConditionEvaluation,
  ProductConditionsRegistry,
} from '../user-availability-addon';

export const PRODUCT_CONDITION_TYPE_UNAVAILABLE = 'unavailable';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnavailableProductCondition extends ProductCondition {}

export class UnavailableProductConditionDto extends ProductConditionDto {}

function evaluateUnavailableCondition(): ProductConditionEvaluation {
  const result = false;
  const error = 'This product is currently unavailable.';
  return { result, error };
}

ProductConditionsRegistry.registerCondition<UnavailableProductCondition>(
  PRODUCT_CONDITION_TYPE_UNAVAILABLE,
  UnavailableProductConditionDto,
  () => evaluateUnavailableCondition()
);
