import { Schedule, ScheduleFrequency } from '../models';
import { Frequency, RRule } from 'rrule';
import * as moment from 'moment-timezone';

export function getRRuleFromSchedule(schedule: Schedule | null): RRule | null {
  if (!schedule) return null;
  try {
    return new RRule({
      byhour: [0],
      byminute: [0],
      bysecond: [0],
      ...(schedule.frequency === 'once' ? { count: 1 } : RRule.fromString(schedule.rrule || '').origOptions),
      until: schedule.until ? transformDate(schedule.until?.toDate()) : null,
      dtstart: transformDate(schedule.date.toDate()),
      freq: getFrequency(schedule.frequency)
    });
  } catch {
    return null;
  }
}

export function getScheduleNextTime(schedule: Schedule | null, now: Date): Date {
  if (!schedule) return null;
  const date = getRRuleFromSchedule(schedule)?.after(transformDate(now), true);
  return date ? moment(moment.utc(date).format('YYYY-MM-DDTHH:mm:ss')).toDate() : null;
}

export function getScheduledTimesBetween(schedule: Schedule, after: Date, before: Date): Date[] {
  if (!schedule) return [];

  const rrule = getRRuleFromSchedule(schedule);
  if (!rrule) return [];

  const beforeTime = before.getTime();
  const dates = rrule.between(transformDate(after), transformDate(before), true).filter(date => date.getTime() !== beforeTime);

  return dates.map(date => moment(moment.utc(date).format('YYYY-MM-DDTHH:mm:ss')).toDate());
}

export function getScheduleText(schedule: Schedule | null): string {
  if (schedule?.frequency === 'once') return `Once on ${schedule.date.toDate().toDateString()}`;
  const text = schedule ? firstLetterUppercase(getRRuleFromSchedule(schedule)?.toText()?.replace(' (~ approximate)', '') || '') : null;
  return text ? text + ` starting ${schedule.date.toDate().toDateString()}` : null;
}

function firstLetterUppercase(text: string): string {
  if (!text?.length || typeof text !== 'string') return text;
  const [first, ...rest] = text.trimLeft();
  return [first.toUpperCase(), ...rest].join('');
}

function transformDate(date: Date): Date {
  const format = (d: moment.Moment) => d.format('YYYY-MM-DDTHH:mm:ss');
  return moment.utc(format(moment(date))).toDate();
}

function getFrequency(frequency: ScheduleFrequency): Frequency {
  switch (frequency) {
    case 'weekly':
      return Frequency.WEEKLY;
    case 'monthly':
      return Frequency.MONTHLY;
    case 'yearly':
      return Frequency.YEARLY;
    default:
      return Frequency.DAILY;
  }
}
