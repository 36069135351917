<greco-simple-dialog
  [data]="{
  title: 'Create New Agreement',
  subtitle: 'Provide the details for the new agreement',
  showCloseButton: false,
  buttons: [
    { label: 'Cancel', role: 'cancel' },
    { label: 'Create', role: 'create', resultFn: getResult }
  ]
}"
>
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input
        placeholder="Write a descriptive agreement title..."
        matInput
        type="text"
        required
        formControlName="title"
      />
    </mat-form-field>
    <!-- Insert CKEditor here -->

    <greco-editor
      [class.dirty]="formGroup.dirty"
      [data]="{ value: formGroup.get('text')?.value || '' }"
      (valueChanged)="valueChanged($event.value)"
      aria-placeholder="Write meaningful agreement description..."
      formControlName="text"
      required
      style="border: 0.5px solid lightgray; margin: 5px 0px 15px 0px"
    >
    </greco-editor>

    <mat-form-field style="margin: 4px">
      <mat-label>Agreement Type</mat-label>
      <mat-select #select required style="margin: 2px" formControlName="agreementType" required>
        <mat-select-trigger class="type-value">
          <span [ngSwitch]="select.value">
            <div *ngSwitchCase="'CHECKBOX'">
              <mat-icon>check_box_outline_blank</mat-icon>
              <span>Checkbox</span>
            </div>
            <div *ngSwitchCase="'AUTO_CHECKBOX'">
              <mat-icon>check_box</mat-icon>
              <span>Auto Checkbox</span>
            </div>
            <div *ngSwitchCase="'DIGITAL_SIGNATURE'">
              <mat-icon>edit</mat-icon>
              <span>Signature</span>
            </div>
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let type of agreementTypeArray" [value]="type">
          <span [ngSwitch]="type">
            <span *ngSwitchCase="'CHECKBOX'"> <mat-icon>check_box_outline_blank</mat-icon> <span>Checkbox</span></span>
            <span *ngSwitchCase="'AUTO_CHECKBOX'"> <mat-icon>check_box</mat-icon> <span>Auto Checkbox</span></span>
            <span *ngSwitchCase="'DIGITAL_SIGNATURE'"> <mat-icon>edit</mat-icon> <span>Signature</span></span>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div></div>
  </form>
</greco-simple-dialog>
