<greco-collapsible-section
  *ngIf="subscription"
  id="subscription-schedule-section"
  [header]="{ title: 'Schedule', icon: 'schedule' }"
  [disabled]="disabled"
>
  <ng-container
    header
    *ngIf="canCancel && (subscription.status === 'ACTIVE' || subscription.status === 'FROZEN') && !canMakeChange"
  >
    <button mat-icon-button [matMenuTriggerFor]="optionsMenu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #optionsMenu>
      <button mat-menu-item (click)="cancelSubscription()">
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
      <button mat-menu-item (click)="updateSubscription()" *ngIf="subscription.status !== 'FROZEN'">
        <mat-icon>update</mat-icon>
        <span>Update</span>
      </button>
      <button mat-menu-item (click)="freezeSubscription()" *ngIf="subscription.status === 'ACTIVE'">
        <mat-icon>pause_circle_outline</mat-icon>
        <span>Freeze</span>
      </button>
      <ng-container *grecoLet="currentFreezes$ | async as currentFreezes">
        <button
          mat-menu-item
          (click)="unfreezeSubscription(currentFreezes || [])"
          *ngIf="subscription.status === 'FROZEN'"
        >
          <mat-icon>play_circle_outline</mat-icon>
          <span>Unfreeze</span>
        </button>
      </ng-container>

      <button *ngIf="canManuallyProcess$ | async" mat-menu-item (click)="forceProcessSubscription()">
        <mat-icon>construction</mat-icon>
        <span>Force process</span>
      </button>
    </mat-menu>
  </ng-container>

  <greco-table [data]="(actions$ | async) || []" [loading]="_loading">
    <mat-icon *grecoTableCol="''; let action; fitContent: true" [matTooltip]="action.id" [class]="action.status">
      {{
        $any({
          START: 'start',
          RENEWAL: 'loop',
          UPDATE: 'update',
          CANCELLATION: 'cancel',
          FREEZE: 'pause_circle_outline',
          UNFREEZE: 'play_circle_outline',
          COMPLETION: 'check_circle_outline'
        })[action.type]
      }}
    </mat-icon>

    <p
      *grecoTableCol="'Action'; let action"
      [class]="action.status"
      style="padding-right: 4px"
      [matTooltip]="(action.timestamp | date : 'medium') || ''"
    >
      <span *ngIf="action.status === 'SCHEDULED' || action.status === 'FROZEN'">
        <span *ngIf="action.status === 'FROZEN'">(Frozen) </span>
        Scheduled
        {{
          $any({
            START: 'to start',
            RENEWAL: 'for renewal',
            UPDATE: 'for update',
            CANCELLATION: 'final cycle ending',
            FREEZE: 'to freeze',
            UNFREEZE: 'to unfreeze',
            COMPLETION: 'final cycle ending'
          })[action.type]
        }}
        on {{ action.timestamp | date : 'medium' }}
      </span>
      <span *ngIf="action.status === 'COMPLETED'">
        {{
          $any({
            START: 'Started',
            RENEWAL: 'Renewed',
            UPDATE: 'Updated',
            CANCELLATION: 'Final cycle ended',
            FREEZE: 'Frozen',
            UNFREEZE: 'Unfrozen',
            COMPLETION: 'Final cycle ended'
          })[action.type]
        }}
        on {{ action.timestamp | date : 'medium' }}
      </span>
      <span *ngIf="action.type === 'CANCELLATION' && action.proration">&nbsp;(prorated)</span>
    </p>

    <p *grecoTableCol="'Items'; let action" style="padding-right: 4px">
      <span>
        {{ action.itemsDisplayTitle || '' }}
      </span>
    </p>

    <ng-container *ngIf="canViewDetails$ | async">
      <p *grecoTableCol="'Created By'; let action" style="padding-right: 4px">
        <span>
          {{ action.createdBy?.displayName || '' }}
        </span>
      </p>

      <p *grecoTableCol="'Details'; let action" style="padding-right: 4px">
        <span>
          {{ action.lockedForProcessing ? 'Processing...' : '' }}
        </span>
        <span>
          {{ action.details ? action.details : '' }}
        </span>
      </p>

      <p *grecoTableCol="'Prorate'; let action" style="padding-right: 4px">
        <!-- <span *ngIf="action.prorate"> Yes </span> -->
        <mat-checkbox [disabled]="true" color="primary" [checked]="action.prorate"></mat-checkbox>
        <!-- <mat-checkbox [checked]="false" *ngIf="!action.prorate"></mat-checkbox> -->
        <!-- <span *ngIf="!action.prorate"> No </span> -->
      </p>
    </ng-container>

    <ng-container *ngIf="!canMakeChange">
      <ng-container *grecoTableCol="''; let action; fitContent: true; stickyEnd: true">
        <greco-subscription-action-options-menu
          [action]="action"
          [communityId]="communityId"
          [subscription]="subscription"
          (scheduleChanged)="scheduledChanged.emit()"
        ></greco-subscription-action-options-menu>
      </ng-container>
    </ng-container>
  </greco-table>

  <mat-paginator
    *ngIf="_paginationMeta?.totalItems"
    showFirstLastButtons
    style="border-top: 1px solid lightgrey"
    [pageSizeOptions]="[5, 10]"
    [length]="_paginationMeta!.totalItems || 0"
    [pageSize]="_paginationMeta!.itemsPerPage || 5"
    (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</greco-collapsible-section>
