<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()"
>
  <div style="display: flex; align-items: center; justify-content: flex-end; height: 32px">
    <button
      mat-stroked-button
      *ngIf="canCreateResource$ | async"
      color="primary"
      style="height: 32px; line-height: 16px"
      (click)="createRoomResource()"
    >
      <span>New Room</span>
    </button>
  </div>

  <mat-menu #button_menu>
    <input #fileInput type="file" color="primary" style="display: none" />
    <ng-container>
      <a mat-menu-item (click)="manageTags()" color="primary">
        <mat-icon>sort</mat-icon>
        <span>Manage Tags</span>
      </a>
    </ng-container>
  </mat-menu>
</greco-filter-bar>

<greco-table
  [data]="(rooms$ | async) || []"
  [highlight]="true"
  [loading]="loading"
  (rowClick)="openRoomResource($event)"
>
  <div *grecoTableCol="''; let room; fitContent: true" [matTooltip]="room.id">
    <mat-icon style="margin-top: 4px"> {{room.icon || 'chair'}} </mat-icon>
  </div>

  <p *grecoTableCol="'Room'; let room">{{room.name}}</p>

  <a *grecoTableCol="'Calendar'; let room" (click)="viewInCalendar(room)"> View in Calendar </a>

  <mat-chip *grecoTableCol="'Spots'; let room"> {{room.spotCount || 1}} </mat-chip>

  <mat-chip *grecoTableCol="'Status'; let room"> {{ room.disabled ? "Disabled" : "Enabled" }} </mat-chip>

  <ng-container *grecoTableCol="''; let room; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button mat-menu-item (click)="openRoomResource(room);">
        <mat-icon>open_in_new</mat-icon>
        <span>View Details</span>
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
