import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { SafePipeModule } from 'safe-pipe';
import { NavTitleModule } from '../../components/nav-title/nav-title.module';
import { TabsLayoutSelectComponent } from './components/select/select.component';
import { TabsLayoutComponent } from './tabs-layout.component';

@NgModule({
  declarations: [TabsLayoutComponent, TabsLayoutSelectComponent],
  exports: [TabsLayoutComponent, TabsLayoutSelectComponent],
  imports: [CommonModule, RouterModule, NavTitleModule, MatTabsModule, MatFormFieldModule, MatSelectModule, SafePipeModule]
})
export class TabsLayoutModule {}
