import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialog } from './confirm.dialog';

@NgModule({
  declarations: [ConfirmDialog],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
  exports: [ConfirmDialog],
  entryComponents: [ConfirmDialog],
})
export class ConfirmDialogModule {}
