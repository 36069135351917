<greco-collapsible-section
  [header]="{
    title: 'Stripe Payment Gateway',
    icon: 'account_balance',
    badge: stripeAccountIncomplete ? '!' : '',
    badgeColor: 'warn'
  }"
  (changed)="expanded.emit($event)"
>
  <!-- Gateway Status -->
  <mat-checkbox
    header
    disabled
    color="primary"
    labelPosition="before"
    style="margin-right: 16px"
    [checked]="accountGateway?.enabled || false"
    (click)="$event.stopImmediatePropagation()"
  >
    Enabled
  </mat-checkbox>

  <!-- Stripe Account ID -->
  <mat-form-field *ngIf="stripeAccountId">
    <mat-label>Stripe Account</mat-label>
    <input matInput readonly [value]="stripeAccountId" />
    <button disabled matSuffix mat-button style="height: 32px; line-height: 16px; overflow: hidden">
      <mat-icon [color]="stripeAccountIncomplete ? 'warn' : 'primary'">
        {{ stripeAccountIncomplete ? 'error_outline' : 'check_circle_outline' }}
      </mat-icon>
    </button>
  </mat-form-field>

  <!-- Gateway Fee -->
  <mat-form-field *ngIf="stripeAccountId && !hideGatewayFee" id="stripe-gateway-fee-form-field">
    <mat-label>Stripe Gateway Fee (Percentage)</mat-label>
    <input
      min="0"
      matInput
      max="100"
      step=".01"
      type="number"
      [value]="stripeGatewayFee"
      [readonly]="!accountGateway || savingStripeGatewayFee"
      (input)="inputValueUpdated($any($event.target).value)"
    />
    <button
      matSuffix
      mat-button
      color="primary"
      *ngIf="stripeGatewayFeeChanged"
      [disabled]="savingStripeGatewayFee"
      [class.mat-button]="savingStripeGatewayFee"
      [class.mat-stroked-button]="!savingStripeGatewayFee"
      style="height: 32px; line-height: 16px; overflow: hidden"
      (click)="$event.stopImmediatePropagation(); updateGatewayFee()"
    >
      <span *ngIf="!savingStripeGatewayFee">Save</span>
      <mat-progress-spinner
        diameter="18"
        mode="indeterminate"
        *ngIf="savingStripeGatewayFee"
        style="filter: grayscale(1) opacity(0.5)"
      ></mat-progress-spinner>
    </button>
  </mat-form-field>

  <!-- Stripe Connection Button -->
  <a
    *ngIf="stripeAccountIncomplete || !stripeAccountId"
    [href]="stripeConnectUrl"
    mat-stroked-button
    target="_blank"
    color="primary"
  >
    <mat-icon>link</mat-icon>
    <span style="margin-left: 8px">
      {{ stripeAccountIncomplete ? 'More Info Required!' : 'Connect Stripe Account' }}
    </span>
  </a>
</greco-collapsible-section>
