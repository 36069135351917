import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import {
  EventResourceSecurityResource,
  EventResourceSecurityResourceAction,
  RoomResource,
  RoomResourceSpot,
} from '@greco/booking-events';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigureRoomSpotsDialog } from '../../../dialogs';
import { ResourcesService } from '../../../services';

@Component({
  selector: 'greco-room-resource-page',
  templateUrl: './room-resource.page.html',
  styleUrls: ['./room-resource.page.scss'],
})
export class RoomResourcePage implements OnInit {
  constructor(
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private resourceSvc: ResourcesService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('room') private room$ = new BehaviorSubject<RoomResource | null>(null);
  @Input() room!: RoomResource;

  canUpdateRoom$ = this.room$.pipe(
    switchMap(async resource => {
      return resource
        ? await this.securitySvc.hasAccess(
            EventResourceSecurityResource.key,
            EventResourceSecurityResourceAction.UPDATE_ROOM,
            { communityId: resource.community.id }
          )
        : false;
    })
  );

  saving = false;
  canUpdate = true;
  roomImageURL: string[] = [];

  form = this.formBuilder.group({
    name: ['', Validators.required],
    description: [''],
    icon: [''],
    roomImage: [null],
    spots: [[], Validators.minLength(1)],
  });

  initialValue: any;

  onSave = async () => {
    this.saving = true;

    try {
      if (
        this.initialValue.name !== this.form.value.name ||
        this.initialValue.description !== this.form.value.description ||
        this.initialValue.icon !== this.form.value.icon ||
        this.initialValue.roomImage !== this.form.value.roomImage ||
        this.initialValue.spots !== this.form.value.spots
      ) {
        if ((this.form.value.spots as RoomResourceSpot[]).filter(spot => !spot.name)?.length) {
          this.snacks.open('Make sure all spots have names before saving!', 'Ok', {
            panelClass: 'mat-warn',
            duration: 3000,
          });
        } else {
          this.room = await this.resourceSvc.updateRoomResource(this.room.id, {
            name: this.form.value.name,
            description: this.form.value.description,
            icon: this.form.value.icon,
            spotCount: this.form.value.spots.length,
            spots: this.form.value.spots,
          });

          const roomImage = this.form.value.roomImage;
          if (roomImage?.length) {
            if (!this.room.imageURL || this.room.imageURL !== roomImage) {
              const formData = new FormData();
              formData.append('file', roomImage[0]);
              this.room.imageURL = await this.resourceSvc.uploadRoomImage(this.room.id, formData);
            }
          } else {
            await this.resourceSvc.removeRoomImage(this.room.id);
            this.room.imageURL = '';
          }

          this.initialValue = {
            name: this.room.name,
            description: this.room.description,
            icon: this.room.icon,
            roomImage: this.room.imageURL,
            spots: this.room.spots,
          };

          if (this.room.imageURL) this.roomImageURL = [this.room.imageURL];

          this.form.reset(this.initialValue);
          this.form.markAsPristine();

          this.snacks.open(`Updated ${this.form.value.name}!`, 'Ok!', { panelClass: 'mat-primary', duration: 3000 });
        }
      }
    } catch (err) {
      console.log(err);
    }

    this.saving = false;
  };

  async configureSpots() {
    if (this.initialValue.spots !== this.form.value.spots) {
      this.room = await this.resourceSvc.updateRoomResource(this.room.id, {
        spotCount: this.form.value.spots.length,
        spots: this.form.value.spots,
      });
    }

    const configs = await toPromise(
      this.dialog
        .open(ConfigureRoomSpotsDialog, {
          data: { imageURL: this.room.imageURL, spots: this.room.spots },
          maxWidth: '90%',
        })
        .afterClosed()
    );

    if (configs && configs !== 'cancel') {
      Object.keys(configs).forEach(spotId => {
        const spot = this.room.spots?.find(spot => spot.id === spotId);

        if (spot) {
          spot.x = configs[spotId].x;
          spot.y = configs[spotId].y;
          spot.width = configs[spotId].width;
          spot.height = configs[spotId].height;
        }
      });

      this.room = await this.resourceSvc.updateRoomResource(this.room.id, {
        spots: this.room.spots,
      });

      this.form.patchValue({ spots: this.room.spots });
      this.initialValue = { ...this.initialValue, spots: this.room.spots };

      this.snacks.open('Spot configuration updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    }
  }

  async setDisable() {
    const isDisabled = this.room.disabled;
    try {
      this.room = await this.resourceSvc.updateRoomResource(this.room.id, {
        disabled: !isDisabled,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async ngOnInit() {
    this.room = await this.resourceSvc.getRoomResource(this.room.id);
    this.initialValue = {
      name: this.room.name,
      description: this.room.description,
      icon: this.room.icon,
      roomImage: this.room.imageURL,
      spots: this.room.spots,
    };
    if (this.room.imageURL) this.roomImageURL = [this.room.imageURL];
    this.room.spots = this.room.spots?.sort((a, b) => (a.spotNumber > b.spotNumber ? 1 : -1));

    this.form.reset(this.initialValue);
  }
}
