<div class="header-container" matRipple [matRippleTrigger]="avatar" [matRippleRadius]="300">
  <div class="user-info">
    <div #avatar class="clickable" routerLink="account">
      <greco-user-avatar [user]="user$ | async" size="large" [showOnlineStatus]="false"></greco-user-avatar>
    </div>
    <div>
      <h1 style="margin-bottom: 0">Welcome back{{ name ? ' ' + name : '' }}!</h1>
      <p *ngIf="now$ | async as now">{{ now | amDateFormat : 'ddd, MMMM Do LTS' }}</p>
    </div>
  </div>
  <div class="header-content">
    <ng-content></ng-content>
  </div>
</div>
<!-- <mat-divider></mat-divider> -->
