<greco-simple-dialog #dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <greco-tiles-input
      [communityId]="data.communityId || ''"
      [required]="true"
      [initialUrls]="initialUrls"
      formControlName="tileDetails"
    ></greco-tiles-input>
  </form>
</greco-simple-dialog>
