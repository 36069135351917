import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PaymentDisputeStatus } from '@greco/finance-payments';
import { SelectFilter } from '@greco/ngx-filters';

@Injectable({ providedIn: 'any' })
export class PaymentDisputeStatusFilter extends SelectFilter {
  private titleCase = new TitleCasePipe();

  constructor() {
    super('PaymentDisputeStatusFilter', {
      label: 'Payment Dispute Status',
      shortLabel: 'Dispute Status',
      description: 'Payment Dispute Status',
      properties: ['status'],
    });
  }

  getValueLabel(value: PaymentDisputeStatus | PaymentDisputeStatus[]): string {
    return Array.isArray(value)
      ? value.map(type => this.titleCase.transform(type)).join(', ')
      : this.titleCase.transform(value);
  }
  getValueOptions(search?: string): PaymentDisputeStatus[] {
    const types = Object.values(PaymentDisputeStatus);
    return search
      ? types.filter(type => this.titleCase.transform(type).toLowerCase().startsWith(search.toLowerCase()))
      : types;
  }

  serializeValue(value: PaymentDisputeStatus | PaymentDisputeStatus[]): string {
    return Array.isArray(value) ? value.map(type => type).join(',') : value;
  }

  deserializeValue(serializedValue: string): PaymentDisputeStatus[] {
    const keys = Object.keys(PaymentDisputeStatus);
    return serializedValue.split(',').filter(type => keys.includes(type)) as PaymentDisputeStatus[];
  }
}
