import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import SwiperCore, { Navigation } from 'swiper/core';
import { CommunityService } from '../../services';

SwiperCore.use([Navigation]);

@Component({
  selector: 'greco-user-browse-communities',
  templateUrl: './user-browse-communities.component.html',
  styleUrls: ['./user-browse-communities.component.scss'],
})
export class UserBrowseCommunitiesComponent implements OnInit {
  @Input() googleMapsKey = 'AIzaSyBxVmVtXTm4_SjzgaB7KYrruNE7FlR8jeI';
  @Input() callToActionText = 'Join';
  @Input() selectedCallToActionText = 'Selected';
  @Input() selectedCommunity?: string;
  @Output() communityClick = new EventEmitter<Community>();

  communities: Community[] = [];
  filteredCommunities: Community[] = [];
  tags = [
    { label: 'In-Studio Classes', id: 'in-studio' },
    { label: 'Online Classes', id: 'online' },
    { label: 'Personal Training', id: 'pt' },
  ];
  selectedTags: string[] = [];

  mobileQuery?: MediaQueryList;
  private _mobileQueryListener?: () => void;

  locating = false;
  location?: GeolocationPosition;
  userHasSubscriptions = false;

  constructor(
    private media: MediaMatcher,
    private userSvc: UserService,
    private contactService: ContactService,
    private communitiesSvc: CommunityService,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    await this.refresh();
  }

  private async getAllCommunities() {
    const communities: Community[] = [];
    let paginationMeta: IPaginationMeta | undefined;

    do {
      const page = (paginationMeta?.currentPage || 0) + 1;
      const { items, meta } = await this.communitiesSvc.paginateCommunities({ page, limit: 100 });

      paginationMeta = meta;
      communities.push(...items);
    } while (paginationMeta && communities.length < paginationMeta.totalItems);

    return communities;
  }

  toggleSelect(tag: string) {
    const index = this.selectedTags.indexOf(tag);
    if (index >= 0) this.selectedTags.splice(index);
    else this.selectedTags.push(tag);
    this.updateCommunities();
  }

  updateCommunities() {
    this.filteredCommunities = this.communities.filter(c => {
      return c.browsable && (!this.selectedTags.length || this.selectedTags.every(t => c.tags?.includes(t)));
    });
    this.sortLocations();
  }

  locate() {
    this.locating = true;
    if (navigator.geolocation && !this.location) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          this.location = position;
          this.locating = false;
          this.sortLocations();
        },
        () => {
          this.snackBar.open('Oops. Unable to retrieve location! Check browser permissions..', 'Ok', {
            duration: 5000,
            panelClass: 'mat-warn',
          });
          this.locating = false;
        }
      );
    } else this.locating = false;
  }

  sortLocations() {
    if (this.location) {
      this.filteredCommunities = this.filteredCommunities.sort((a, b) => {
        if (!a.address) return 1;
        if (!b.address) return -1;

        const distanceA = this.location
          ? this.calculateDistance(
              this.location.coords.latitude,
              this.location.coords.longitude,
              a.address?.latitude,
              a.address?.longitude
            )
          : Infinity;
        const distanceB = this.location
          ? this.calculateDistance(
              this.location.coords.latitude,
              this.location.coords.longitude,
              b.address?.latitude,
              b.address?.longitude
            )
          : Infinity;

        return distanceA - distanceB;
      });
    }
  }

  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: 'K' | 'N' = 'K') {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    // const radlon1 = (Math.PI * lon1) / 180;
    // const radlon2 = (Math.PI * lon2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }

  communityClicked(community: Community) {
    this.communityClick.emit(community);
  }

  async refresh() {
    await toPromise(this.userSvc.authUser$); // Force user refresh to avoid permission errors when signing-out

    // TODO(adaoust): This should probably be paginated
    const userContacts = await this.contactService.getUserContacts();
    this.userHasSubscriptions = userContacts.length > 1;
    this.communities = (await this.getAllCommunities()).filter(
      c => c.browsable && !userContacts.some(ctn => ctn.community.id === c.id)
    );
    this.updateCommunities();

    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery?.addListener(this._mobileQueryListener.bind(this));
  }
}
