export enum ScheduleFrequency {
  ONCE = 'ONCE',
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

export interface Schedule {
  /** [Required] Date when the schedule takes effect */
  date: Date;
  /** [Optional] Date until which the schedule will run */
  until: Date | null;

  /** [Required] */
  frequency: ScheduleFrequency;

  /** [Optional] RRule string. This property is used to "extend" the functionality with properties that are not available in this interface. */
  rrule: string | null;
}
