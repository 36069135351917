<greco-item>
  <mat-icon item-start>confirmation_number</mat-icon>

  <div style="width: 100%">
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">Your Booking Option</p>
    <p
      [matTooltip]="(title$ | async) || ''"
      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
    >
      <small>{{ title$ | async }}</small>
    </p>
  </div>

  <div item-end>
    <button mat-flat-button color="primary" style="width: 100%" (click)="openReviewDialog()">Details</button>
  </div>
</greco-item>
