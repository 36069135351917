import { Pipe, PipeTransform } from '@angular/core';
import type { TypeformResponseEntity } from '@greco/nestjs-typeform';
import { FormAnswer } from '@greco/typeform';

@Pipe({ name: 'typeformResponseTooltip' })
export class TypeformResponseTooltip implements PipeTransform {
  transform(value: TypeformResponseEntity) {
    return value.response.answers.map(answer => {
      const field = value.response.definition.fields.find(field => field.id === answer.field.id);
      return field?.title + ': ' + this.getAnswerLabel(answer);
    });
  }

  getAnswerLabel(answer: FormAnswer) {
    switch (answer.type) {
      case 'boolean':
        return answer.boolean ? 'Yes' : 'No';
      case 'choice':
        return answer.choice.other || answer.choice.label;
      case 'choices':
        return answer.choices.other || answer.choices.labels?.join(', ');
      case 'date':
        return answer.date;
      case 'email':
        return answer.email;
      case 'file_url':
        return answer.file_url;
      case 'number':
        return answer.number;
      case 'payment':
        return answer.payment.amount;
      case 'text':
        return answer.text;
      case 'url':
        return answer.url;
    }
  }
}
