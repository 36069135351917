<form [formGroup]="form">
  <!-- Label -->
  <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
    <mat-label>Label</mat-label>
    <input matInput required formControlName="label" placeholder="Tag Label" />
  </mat-form-field>
</form>
<div style="display: flex; flex-direction: row; justify-content: space-evenly">
  <button mat-flat-button color="primary" (click)="save()" [innerHtml]="'Add Tag'"></button>
  <button matSuffix mat-flat-button (click)="close()" [innerHtml]="'Cancel'"></button>
</div>
