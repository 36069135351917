import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountTax } from '@greco/finance-tax';
import { CreateAccountTaxDto, TaxDto } from '@greco/nestjs-finance-tax';
import { AccountTaxService } from '../../services';

@Component({
  selector: 'greco-select-tax-dialog',
  templateUrl: './select-tax.dialog.html',
  styleUrls: ['./select-tax.dialog.scss'],
})
export class SelectTaxDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { accountId: string },
    private ref: MatDialogRef<SelectTaxDialog>,
    private formBuilder: FormBuilder,
    private taxSvc: AccountTaxService,
    private snacks: MatSnackBar
  ) {
    this.accountId = data.accountId;
  }

  accountId!: string;
  formGroup = this.formBuilder.group({
    customTax: [null],
  });

  close(tax?: AccountTax) {
    this.ref.close(tax);
  }

  async addTax(value: {
    financeAccountId: string;
    isDefault: boolean;
    taxNumber: string;
    title: string;
    abbreviation: string;
    description: string;
    percentage: number;
  }) {
    const taxDto: TaxDto = {
      title: value.title,
      abbreviation: value.abbreviation,
      description: value.description,
      percentage: value.percentage,
    };
    const dto: CreateAccountTaxDto = {
      financeAccountId: this.accountId,
      isDefault: value.isDefault,
      taxNumber: value.taxNumber,
      taxDto,
    };
    const tax = await this.taxSvc.createTax(dto);
    if (tax)
      this.snacks.open(`Tax "${tax.tax?.title}" created and assigned to community!`, 'Ok', {
        duration: 5000,
        panelClass: 'mat-primary',
      });
    this.close(tax);
  }
}
