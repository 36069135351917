import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EventWithUserDetails } from '@greco/booking-events2';
import { PaymentMethod } from '@greco/finance-payments';
import { PropertyListener } from '@greco/property-listener-util';
import { AlertConfig, AlertType } from '@greco/ui-alert';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'alt-event-payment-method-alert',
  templateUrl: './event-payment-method-alert.component.html',
  styleUrls: ['./event-payment-method-alert.component.scss'],
})
export class EventPaymentMethodAlertComponent {
  constructor(public eventSvc: EventService, public bottomSheet: MatBottomSheet) {}

  @PropertyListener('event') event$ = new BehaviorSubject<EventWithUserDetails | null>(null);
  @Input() event!: EventWithUserDetails;

  paymentMethodWarnings$ = combineLatest([
    this.event$,
    this.eventSvc.paymentMethods$,
    this.eventSvc.paymentMethodToUse$,
    this.eventSvc.paymentMethodError$,
  ]).pipe(
    map(([event, paymentMethods, paymentMethodToUse, paymentMethodError]) => {
      const expiredAlerts: AlertConfig[] = [];
      const expiringAlerts: AlertConfig[] = [];
      const missingPaymentMethod = paymentMethodError;

      if (!event) return { expiredAlerts, expiringAlerts, missingPaymentMethod };

      const now = new Date();

      paymentMethods.forEach(paymentMethod => {
        if (!paymentMethod.details || paymentMethod.id !== paymentMethodToUse?.id) return;

        const monthYear = paymentMethod.details.split('/');
        const expiresOn = new Date(monthYear[0] + '/01/' + monthYear[1]).getTime();

        if (expiresOn < now.getTime()) {
          expiredAlerts.push({
            title: paymentMethod.label + ' - Expired',
            type: AlertType.DANGER,
            description:
              'This payment method is expired and can not be used to create purchases. Please update your payment information before continuing.',
          });
        } else if (expiresOn < event.startTime.getTime()) {
          expiredAlerts.push({
            title: paymentMethod.label + ' - Expired',
            type: AlertType.DANGER,
            description:
              'This payment method will expire before the event start. Please update your payment methods before continuing.',
          });
        } else if (expiresOn <= new Date(now.setMonth(now.getMonth() + 3)).getTime()) {
          expiringAlerts.push({
            title: paymentMethod.label + ' - Expiring Soon',
            type: AlertType.WARN,
            description:
              'This payment method is expiring within the next three months. Please consider updating your payment information soon.',
          });
        }
      });

      return { expiredAlerts, expiringAlerts, missingPaymentMethod };
    })
  );

  expiredAlerts$ = this.paymentMethodWarnings$.pipe(map(alerts => alerts.expiredAlerts));
  expiringAlerts$ = this.paymentMethodWarnings$.pipe(map(alerts => alerts.expiringAlerts));
  missingPaymentMethod$ = this.paymentMethodWarnings$.pipe(map(alerts => alerts.missingPaymentMethod));

  updatePaymentMethodToUse(paymentMethod?: PaymentMethod) {
    if (!paymentMethod) return;

    this.eventSvc.paymentMethodToUse$.next(paymentMethod);
    this.bottomSheet.dismiss();
  }

  updatePaymentMethods(paymentMethods: PaymentMethod[] | null, paymentMethod?: PaymentMethod) {
    if (!paymentMethod) return;

    this.eventSvc.paymentMethods$.next([...(paymentMethods ?? []), paymentMethod]);
  }
}
