<ng-container *grecoLet="canViewBookings$ | async as canViewBookings">
  <ng-container *grecoLet="hasAttendees$ | async as hasAttendees">
    <!-- Attendees -->
    <ng-container *ngIf="canViewBookings">
      <greco-collapsible-section
        #attendeesSection
        *ngIf="attendeeFilters$ | async as attendeeFilters"
        [header]="{ title:'Attendees', icon: 'people' }"
        [style.display]="hasAttendees ? 'block' : 'none'"
        id="attendees-section"
      >
        <ng-container header>
          <mat-chip *ngIf="attendeesTable.metadata" style="margin-right: auto">
            {{attendeesTable.metadata.totalItems}} / {{event.maxCapacity}}
          </mat-chip>
          <ng-container *ngIf="attendeesSection.expanded">
            <p style="margin-bottom: 0">Sort By:</p>
            <mat-button-toggle-group
              class="button-toggle-group"
              [(ngModel)]="bookingsSortAlphabetically"
              aria-label="Font Style"
            >
              <mat-button-toggle [value]="true" (click)="$event.stopImmediatePropagation();">Name</mat-button-toggle>
              <mat-button-toggle [value]="false" (click)="$event.stopImmediatePropagation();">
                Spot #
              </mat-button-toggle>
            </mat-button-toggle-group>
          </ng-container>
          <button
            *ngIf="attendeesTable.metadata && hasRoom() && (canManageSpots$ | async) && !(event.availableAsCourse && !event.availableAsInstances) && !eventCancelled"
            mat-stroked-button
            style="height: 32px; line-height: 16"
            (click)="$event.stopImmediatePropagation(); autoAssignSpots()"
            color="primary"
          >
            <span>Auto-Assign Spots</span>
          </button>
          <button
            *ngIf="(attendeesTable.metadata?.totalItems || 0) < event.maxCapacity && (canCreateBooking$ | async) && !(event.availableAsCourse && !event.availableAsInstances) && !eventCancelled"
            mat-stroked-button
            style="height: 32px; line-height: 16; margin-left: 16px"
            (click)="$event.stopImmediatePropagation(); addAttendee()"
            [disabled]="!event.tags.length"
            [matTooltip]="!event.tags.length ? 'Event must have at least one booking tag to add an attendee.' : ''"
            color="primary"
          >
            <span>Add Attendee</span>
          </button>
        </ng-container>

        <greco-bookings-table
          #attendeesTable
          [event]="event"
          [showSpotNumber]="true"
          [communityId]="event.community.id || ''"
          [onlyStaffCalendars]="true"
          [filters]="attendeeFilters"
          [hideEvent]="true"
          [hideDate]="true"
          [sortAlphabetically]="bookingsSortAlphabetically"
        ></greco-bookings-table>
      </greco-collapsible-section>
    </ng-container>

    <!-- Details -->
    <ng-container *grecoLet="canUpdateEvent$ | async as canUpdateEvent">
      <greco-event-details
        [event]="event"
        [lockResources]="lockResources"
        [readonly]="!canUpdateEvent || event.status == 'CANCELLED'"
        (eventCancelled)="eventCancelled = $event"
        (seriesUpdated)="updateSeriesListener()"
      >
        <ng-container header *grecoLet="sharedTags$ | async as sharedTags">
          <ng-container *ngIf="sharedTags && sharedTags.length === 1; else multipleTags">
            <button
              *ngIf="lockResources && selectedResource && !selectedAssignment || selectedAssignment?.resourceStatus === pending"
              header
              mat-stroked-button
              color="primary"
              style="height: 32px; line-height: 16; margin-left: 16px"
              [disabled]="selectedAssignment?.resourceStatus !== pending ? false : true"
              (click)="openSubstitutionDialog(sharedTags[0]); $event.stopImmediatePropagation()"
            >
              {{ selectedAssignment?.resourceStatus !== pending ? 'Substitute' : 'Pending Substitution'}}
            </button>
          </ng-container>
          <ng-template #multipleTags>
            <button
              *ngIf="lockResources && selectedResource && !selectedAssignment || selectedAssignment?.resourceStatus === pending"
              header
              mat-stroked-button
              color="primary"
              style="height: 32px; line-height: 16; margin-left: 16px"
              [disabled]="selectedAssignment?.resourceStatus !== pending ? false : true"
              [matMenuTriggerFor]="tagMenu"
              (click)="$event.stopImmediatePropagation()"
            >
              {{ selectedAssignment?.resourceStatus !== pending ? 'Substitute' : 'Pending Substitution'}}
            </button>
          </ng-template>
        </ng-container>

        <mat-menu #tagMenu>
          <ng-container>
            <button
              mat-menu-item
              header
              color="primary"
              *ngFor="let tag of sharedTags"
              (click)="openSubstitutionDialog(tag)"
            >
              <mat-icon>label</mat-icon>
              <span style="margin-right: 24px">{{ tag?.label }}</span>
            </button>
          </ng-container>
        </mat-menu>

        <button
          *ngIf="lockResources && selectedAssignment && !event.private && selectedAssignment?.resourceStatus !== pending"
          header
          mat-stroked-button
          color="primary"
          style="height: 32px; line-height: 16; margin-left: 16px"
          (click)="requestSubstitution(); $event.stopImmediatePropagation()"
          [disabled]="selectedAssignment.resourceStatus === requestingSubstitution"
        >
          {{ selectedAssignment.resourceStatus === requestingSubstitution ? 'Substitution Requested' : ('Request ' +
          'Substitution') }}
        </button>

        <button
          *ngIf="(canViewContacts$ | async) && (!hasAttendees && (canCreateBooking$ | async) || hasAttendees && (canViewBookings$ | async) === false) && !(event.availableAsCourse && !event.availableAsInstances)"
          header
          mat-stroked-button
          style="height: 32px; line-height: 16"
          (click)="$event.stopImmediatePropagation(); addAttendee()"
          [disabled]="event.status == 'CANCELLED' || eventCancelled || !event.tags.length"
          [matTooltip]="!event.tags.length ? 'Event must have at least one booking tag to add an attendee.' : ''"
        >
          <span>Add Attendee</span>
        </button>
      </greco-event-details>
    </ng-container>

    <!-- Waitlist -->
    <ng-container *ngIf="canViewBookings && !event.private">
      <greco-collapsible-section id="waitlist-section" [header]="{ title: 'Waitlist', icon: 'pending_actions' }">
        <greco-waitlist-table [event]="event"></greco-waitlist-table>
      </greco-collapsible-section>
    </ng-container>

    <!-- Agreements -->

    <greco-event-community-agreement-usage
      *ngIf="event"
      [event]="event"
      [communityId]="event.community.id"
    ></greco-event-community-agreement-usage>

    <!-- Cancelled Bookings -->
    <ng-container *ngIf="canViewBookings">
      <greco-collapsible-section
        *ngIf="cancelledBookingsFilters$ | async as cancelledBookingsFilters"
        [header]="{ title:'Cancelled Bookings', icon: 'event_busy' }"
        [style.display]="cancelledBookingsTable.metadata?.totalItems ? 'block' : 'none'"
        id="attendees-section"
      >
        <greco-bookings-table
          #cancelledBookingsTable
          [communityId]="event.community.id || ''"
          [onlyStaffCalendars]="true"
          [filters]="cancelledBookingsFilters"
          [hideEvent]="true"
          [hideDate]="true"
        ></greco-bookings-table>
      </greco-collapsible-section>
    </ng-container>
  </ng-container>
</ng-container>
