import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { Video, VideoSourceRequestDto, VideoWatchTime } from '@greco/videos';
import { UserVideoWatchTimeService } from '../../services';

@Component({
  selector: 'greco-video-player-wrapper',
  templateUrl: './video-player-wrapper.component.html',
  styleUrls: ['./video-player-wrapper.component.scss'],
})
export class VideoPlayerWrapperComponent implements OnInit {
  constructor(private watchTimeSvc: UserVideoWatchTimeService, private matDialog: MatDialog) {}
  @Input() video!: Video | VideoSourceRequestDto;
  loading = true;
  watchTime = 0;
  async ngOnInit(): Promise<Promise<void>> {
    if ((this.video as Video)?.id) {
      const watchTime = await this.watchTimeSvc.getUserVideoWatchTime((this.video as Video).id);
      if (
        watchTime &&
        this.video.duration &&
        watchTime.watchDuration < this.video.duration &&
        watchTime.watchDuration > 5
      ) {
        await this.watchHistoryDialog(watchTime);
      } else {
        this.loading = false;
      }
    }
  }

  async watchHistoryDialog(watchTime: VideoWatchTime) {
    this.loading = true;
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Continue Watching',
        content: 'Looks like you started watching this before. Would you like to pick up where you left off?',
        buttons: [
          { label: 'No, play from the beginning', role: 'no' },
          { label: 'Yes, continue watching', role: 'yes' },
        ],
      } as DialogData,
      width: '700px',
      maxWidth: '90%',
    });
    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      this.watchTime = watchTime.watchDuration;
      (document.getElementById('vimeo-container') as any)?.contentDocument.location.reload(true);
    } else this.watchTime = 0;

    this.loading = false;
  }
}
