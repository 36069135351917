<ng-container *grecoLet="canRead$ | async as canRead">
  <ng-container *grecoLet="canCreate$ | async as canCreate">
    <ng-container *grecoLet="canUpdate$ | async as canUpdate">
      <ng-container *grecoLet="canDelete$ | async as canDelete">
        <ng-container *ngIf="communityId && canRead">
          <greco-collapsible-section
            (click)="$event.stopImmediatePropagation()"
            id="communityTaxes"
            [header]="{ title: 'Community Taxes', icon: 'attach_money'}"
          >
            <button
              *ngIf="canCreate"
              mat-flat-button
              header
              color="primary"
              style="height: 32px; line-height: 16px"
              [mat-menu-trigger-for]="taxesMenu"
              (click)="$event.stopImmediatePropagation();"
            >
              <mat-icon>add</mat-icon>
              Add Tax
            </button>
            <mat-menu #taxesMenu>
              <ng-container *grecoLet="platformTaxes$ | async as taxes">
                <p mat-menu-item *ngIf="!taxes?.length; else taxItems">All taxes added!</p>
                <ng-template #taxItems>
                  <button mat-menu-item *ngFor="let tax of taxes" (click)="addTax(tax)">
                    {{tax.title}} ({{tax.percentage}}%)
                  </button>
                </ng-template>
              </ng-container>
            </mat-menu>
            <button
              *ngIf="isSuperAdmin$ | async as isSuperAdmin"
              mat-flat-button
              header
              color="primary"
              style="height: 32px; line-height: 16px"
              (click)="$event.stopImmediatePropagation(); createTax()"
            >
              <mat-icon>add</mat-icon>
              Create Tax
            </button>

            <greco-table [data]="(communityTaxList$ | async) || []" *grecoLet="isSuperAdmin$ | async as isSuperAdmin">
              <p *grecoTableCol="''; let tax; fitContent: true;" [matTooltip]="tax.tax.id"><mat-icon>sell</mat-icon></p>
              <p *grecoTableCol="'Name'; let tax;">{{ tax.tax.title }}</p>
              <p *grecoTableCol="'Abbreviation'; let tax;">{{ tax.tax.abbreviation }}</p>
              <p *grecoTableCol="'Description'; let tax">{{ tax.tax.description }}</p>
              <p *grecoTableCol="'Percentage'; let tax">{{ tax.tax.percentage }} %</p>
              <p *grecoTableCol="'Tax Number'; let tax">{{ tax.taxNumber }}</p>

              <p *grecoTableCol="'Default'; fitContent: true; let tax" style="display: flex">
                <mat-checkbox
                  [disabled]="!canUpdate"
                  matTooltip="Is this tax applied to community purchases by default?"
                  [checked]="tax.isDefault"
                  color="primary"
                  (click)="switchTaxType(tax, !tax.isDefault)"
                ></mat-checkbox>
              </p>

              <ng-container *grecoTableCol="''; let tax; stickyEnd:true; fitContent:true">
                <button mat-icon-button *ngIf="isSuperAdmin || canDelete || canUpdate" [mat-menu-trigger-for]="options">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #options>
                  <button *ngIf="isSuperAdmin" mat-menu-item (click)="updateTax(tax.tax)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit Platform Tax</span>
                  </button>
                  <button *ngIf="canUpdate" mat-menu-item (click)="updateAccountTaxNumber(tax)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit Tax Number</span>
                  </button>
                  <button *ngIf="canDelete" mat-menu-item (click)="removeCommunityTax(tax.taxId)">
                    <mat-icon>delete</mat-icon>
                    <span>Remove Account Tax</span>
                  </button>
                </mat-menu>
              </ng-container>
            </greco-table>
          </greco-collapsible-section>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
