<!-- Main container with content on the left -->
<div class="main-container">
  <div class="main-content">
    <ng-content></ng-content>
  </div>
</div>

<!-- Fixed container overlay with content on the right -->
<div class="fixed-container">
  <div class="fixed-content">
    <ng-content select="[fixed]"></ng-content>
  </div>
</div>
