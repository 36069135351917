import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@greco/identity-users';
import { UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { PropertyListener } from '@greco/property-listener-util';
import { ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'alt-payment-method-requirement',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodRequirementComponent {
  constructor(private paymentMethodSvc: UserPaymentMethodService) {}

  @Output() refresh = new EventEmitter();
  @Input() allowBankPayments = true;

  @PropertyListener('user') private _user$ = new ReplaySubject<User>();
  @Input() user!: User;

  hasPaymentMethods$ = this._user$.pipe(
    switchMap(async user => {
      const [defaultPaymentMethod, paymentMethods] = await Promise.all([
        this.paymentMethodSvc.getDefault(user.id, true),
        this.paymentMethodSvc.getAll(user.id),
      ]);

      let paymentMethod = paymentMethods.find(pm => pm.id === defaultPaymentMethod?.id) || null;
      if (paymentMethod?.model === 'bank' && !this.allowBankPayments) {
        paymentMethod = null;
        paymentMethod = paymentMethods.filter(pm => pm.model !== 'bank')[0];
      }
      return paymentMethod;
    }),
    map(pm => !!pm)
  );

  _refresh() {
    this.user = { ...this.user };
    this.refresh.emit();
  }
}
