import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'greco-tabs-layout-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class TabsLayoutSelectComponent {
  @Output() changed = new EventEmitter<string>();

  @Input() label: string;

  @Input() options: SelectOption[];

  @Input() set value(option: string) {
    this._value = option;
    this.changed.emit(option);
  }
  get value() {
    return this._value;
  }

  private _value: string;
}

export interface SelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}
