import { BaseDto } from '@greco/util-dto';
import { AgreementType } from '../models';

export class CreateCommunityAgreementDto extends BaseDto {
  communityId: string;
  title: string;
  text: string;
  agreementType: AgreementType;
  created: Date;
}
