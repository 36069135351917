<ng-container *grecoLet="terminalsWithStatus$ | async as terminals">
  <ng-container *grecoLet="readersToConnect$ | async as readersToConnect">
    <div
      style="
        border-bottom: 1px solid lightgrey;
        margin: 0 -24px;
        padding: 0 24px 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 48px;
      "
    >
      <h2 style="margin: 0">Manage Terminals</h2>

      <div style="display: flex; gap: 16px">
        <ng-container *ngIf="terminals?.length">
          <button *ngIf="readersToConnect?.length" mat-stroked-button [matMenuTriggerFor]="readersMenu">
            <mat-icon>wifi</mat-icon>
            Connect Terminal
          </button>
        </ng-container>

        <button mat-icon-button (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div style="margin: 0 -24px 24px -24px">
      <greco-table *ngIf="terminals && terminals.length" [data]="terminals">
        <mat-icon *grecoTableCol="''; fitContent: true; let terminal" [matTooltip]="terminal.id">contactless</mat-icon>
        <p *grecoTableCol="'Label'; let terminal">{{ terminal.label }}</p>
        <mat-icon
          *grecoTableCol="'Status'; fitContent: true; alignCenter:true; let terminal"
          [matTooltip]="terminal.status | titlecase"
          [style.opacity]="terminal.status === 'online' ? '1' : '0.5'"
        >
          {{terminal.status === 'online' ? 'wifi' : 'wifi_off'}}
        </mat-icon>
        <p *grecoTableCol="'Serial #'; let terminal">{{ terminal.externalData?.serial_number || '-' }}</p>
        <p *grecoTableCol="'Device Type'; let terminal">{{ terminal.externalData?.device_type || '-' }}</p>
        <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let terminal">
          <button
            mat-icon-button
            [disabled]="terminal.externalId === 'SIMULATOR'"
            (click)="updateReaderLabel(terminal.id)"
          >
            <mat-icon>create</mat-icon>
          </button>
        </ng-container>
      </greco-table>

      <div
        *ngIf="!terminals?.length"
        style="
          padding: 0 24px;
          max-width: 80vw;
          width: 300px;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <greco-empty-placeholder></greco-empty-placeholder>

        <button *ngIf="readersToConnect?.length" mat-stroked-button [matMenuTriggerFor]="readersMenu">
          <mat-icon>wifi</mat-icon>
          Connect Terminal
        </button>
      </div>
    </div>

    <mat-menu #readersMenu>
      <button *ngFor="let reader of readersToConnect" mat-menu-item (click)="connectReader(reader)">
        <div>
          <p style="font-size: 16px; line-height: 16px; margin-bottom: 2px">{{ reader.label }}</p>
          <p style="font-size: 12px; line-height: 12px; color: grey; margin-bottom: 0">
            Serial: {{ reader.serial_number }}
          </p>
        </div>
      </button>
    </mat-menu>

    <button *ngIf="terminals?.length" mat-stroked-button style="display: block; margin-left: auto" (click)="close()">
      Close
    </button>
  </ng-container>
</ng-container>
