<greco-box>
  <div class="title-container">
    <div class="title">
      <h3>{{ title }}</h3>
    </div>
    <div
      class="delta"
      [class.green]="deltaPositiveGood ? delta > 0 : delta < 0"
      [class.yellow]="deltaPositiveGood ? delta < 0 : delta > 0"
    >
      <span>{{ delta > 0 ? '+ ' : delta < 0 ? '- ' : '' }}{{ (delta ? absDelta * 100 : 0) | number : '1.1-2' }}%</span>
    </div>
  </div>
  <p class="content">{{ content }}</p>
  <p class="description">{{ description }}</p>
</greco-box>
