import { Pipe, PipeTransform } from '@angular/core';
import { CondOperator } from '@nestjsx/crud-request';

@Pipe({ name: 'condOperator' })
export class CondOperatorPipe implements PipeTransform {
  transform(value: CondOperator): string {
    const label = getOperatorLabel(value);
    const details = getOperatorDetails(value);
    return `
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <span>${label}</span>
        ${details ? `<small>${details}</small>` : ''}
      </div>
    `;
  }
}

function getOperatorLabel(operator: CondOperator): string {
  switch (operator) {
    case CondOperator.EQUALS:
    case CondOperator.EQUALS_LOW:
      return 'Equals';

    case CondOperator.NOT_EQUALS:
    case CondOperator.NOT_EQUALS_LOW:
      return 'Not Equals';

    case CondOperator.CONTAINS:
    case CondOperator.CONTAINS_LOW:
      return 'Contains';

    case CondOperator.GREATER_THAN:
      return 'Greater Than';

    case CondOperator.GREATER_THAN_EQUALS:
      return 'Greater Than or Equal';

    case CondOperator.LOWER_THAN:
      return 'Less Than';

    case CondOperator.LOWER_THAN_EQUALS:
      return 'Less Than or Equal';

    case CondOperator.BETWEEN:
      return 'Between';

    default:
      return operator;
  }
}

function getOperatorDetails(operator: CondOperator): string {
  switch (operator) {
    case CondOperator.IN_LOW:
    case CondOperator.ENDS_LOW:
    case CondOperator.NOT_IN_LOW:
    case CondOperator.EQUALS_LOW:
    case CondOperator.STARTS_LOW:
    case CondOperator.CONTAINS_LOW:
    case CondOperator.EXCLUDES_LOW:
    case CondOperator.NOT_EQUALS_LOW:
      return 'Case Insensitive';

    default:
      return '';
  }
}
