<mat-select [formControl]="formControl" [required]="required" [compareWith]="compareWith">
  <mat-select-trigger>
    <div *ngIf="value" style="display: flex; align-items: center; justify-content: flex-start; gap: 16px">
      <img style="width: 36px" [src]="'assets/payment-methods/' + value.model + '.png'" />
      <span>{{ value.model | titlecase }}</span>
      <span style="flex: 1">{{ value.label }}</span>
    </div>
  </mat-select-trigger>

  <mat-option *ngFor="let option of options$ | async" [value]="option">
    <div style="display: flex; align-items: center; justify-content: flex-start; gap: 16px">
      <img style="width: 36px" [src]="'assets/payment-methods/' + option.model + '.png'" />
      <span>{{ option.model | titlecase }}</span>
      <span style="flex: 1">{{ option.label }}</span>
      <mat-chip-list *ngIf="option.id === accountDefaultPaymentMethod">
        <mat-chip>Default</mat-chip>
      </mat-chip-list>
    </div>
  </mat-option>
</mat-select>
