import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlEncode',
})
export class UrlEncodePipe implements PipeTransform {
  transform(value: string, component?: boolean): string {
    return component ? encodeURIComponent(value) : encodeURI(value);
  }
}
