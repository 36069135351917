import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ZOOM_CLIENT_ID } from './services/zoom.service';

@NgModule({
  imports: [CommonModule],
})
export class ZoomModule {
  static forRoot(clientId: string) {
    return {
      ngModule: ZoomModule,
      providers: [{ provide: ZOOM_CLIENT_ID, useValue: clientId }],
    };
  }
}
