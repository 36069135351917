import { SecurityResource } from '@greco/security';

export enum CustomChargeResourceAction {
  CREATE = 'CREATE',
}

export const CustomChargeResource: SecurityResource = {
  key: 'finance_purchasing_custom_charge',
  module: 'Finance',

  title: 'Custom Charge',
  description: 'Manage custom charges',

  context: class {
    accountId?: string;
  },

  actions: [
    { key: CustomChargeResourceAction.CREATE, title: 'Create', description: 'Ability to create a new custom charge' }, //
  ],
};
