<!-- Header -->
<div cdkOverlayOrigin #trigger="cdkOverlayOrigin" style="margin-bottom: -16px">
  <!-- <div class="main-header">
    <div *ngIf="userSvc.user$ | async as user">
      <h3>Welcome back <strong>{{user.friendlyName || user.displayName}}</strong>!</h3>
      <p *ngIf="now$ | async as now" style="margin-bottom: 0">{{ now | amDateFormat : 'ddd, MMM Do LTS' }}</p>
    </div>
    <button
      *ngIf="(isMobile$ | async) === false"
      mat-raised-button
      color="accent"
      routerLink="/workouts"
      style="border-radius: 18px; color: white"
    >
      New Booking
    </button>
  </div> -->
</div>
<!-- <mat-divider></mat-divider> -->

<greco-dashboard-frozen-subscriptions></greco-dashboard-frozen-subscriptions>

<!-- Upcoming Bookings -->
<greco-dashboard-bookings [trigger]="trigger"></greco-dashboard-bookings>

<!-- Unsigned Agreements -->
<greco-dashboard-unsigned-agreements></greco-dashboard-unsigned-agreements>

<!-- Favorites -->
<greco-dashboard-favorites></greco-dashboard-favorites>

<!-- Tiles -->
<greco-dashboard-tiles></greco-dashboard-tiles>

<!-- OnDemand Videos -->
<greco-dashboard-videos></greco-dashboard-videos>

<!-- Booking FAB -->
<div *ngIf="isMobile$ | async" class="booking-fab">
  <button mat-raised-button rounded="true" color="accent" routerLink="/workouts" style="color: white">
    New Booking
  </button>
</div>
