import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';
import { PlatformLayoutComponent } from './platform.layout';
import { RouteTransitionPipe } from './router.animations';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatRippleModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatTooltipModule,
    NgPipesModule,
    MatBadgeModule,
    MatProgressBarModule
  ],
  exports: [PlatformLayoutComponent],
  declarations: [PlatformLayoutComponent, RouteTransitionPipe]
})
export class PlatformLayoutModule {}
