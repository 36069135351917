<div class="greco-contact-picker-container" *grecoLet="agreements$ | async as agreements">
  <!-- <div *ngIf="value && value.user" style="width: 24px; height: 24px; margin-right: 8px">
    <greco-user-avatar [showOnlineStatus]="false" [user]="value.user"></greco-user-avatar>
  </div> -->

  <input
    matInput
    type="text"
    [disabled]="disabled"
    [required]="required"
    [matAutocomplete]="auto"
    class="greco-contact-picker-input"
    [placeholder]="placeholder"
    (input)="searchQuery$.next($any($event.target).value)"
    (blur)="touched()"
  />

  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="optionSelected($event)"
  >
    <ng-container *ngIf="agreements?.length; else noAgreements">
      <mat-option *ngFor="let agreement of agreements" [value]="agreement">
        <div style="display: flex; align-items: center">
          <div>
            <ng-container *ngIf="agreement.title">
              {{ agreement.title }}
            </ng-container>
          </div>
        </div>
      </mat-option>
    </ng-container>
    <ng-template #noAgreements>
      <mat-option [disabled]="!agreements?.length"><i>No agreements found</i></mat-option>
    </ng-template>
  </mat-autocomplete>
</div>
