import { Component, Input } from '@angular/core';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase } from '@greco/sales-purchases';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'greco-purchase-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
})
export class PurchaseStatusChipComponent {
  @Input() isAdminPage!: boolean;

  @Input() purchase!: Purchase;
  @PropertyListener('purchase') private purchase$ = new ReplaySubject<Purchase>(1);

  status$ = this.purchase$.pipe(
    map(purchase => {
      if (this.isAdminPage) {
        return purchase.status.split('_').join(' ');
      } else if (purchase.previousStatus) {
        return purchase.previousStatus.split('_').join(' ');
      } else {
        return purchase.status.split('_').join(' ');
      }
    })
  );
}
