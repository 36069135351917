<greco-simple-dialog [data]="dialogData">
  <form style="min-height: 32px" [formGroup]="form">
    <mat-radio-group color="primary" formControlName="specification">
      <mat-radio-button style="margin-right: 12px" value="tag" [checked]="true">Everyone in Tag</mat-radio-button>
      <mat-radio-button style="margin-right: 12px" value="none">No Notifications</mat-radio-button>
      <mat-radio-button style="margin-right: 12px" value="specify">Specify Resources</mat-radio-button>
    </mat-radio-group>

    <mat-form-field *ngIf="form.value.specification === 'specify'" appearance="standard">
      <mat-label>Resources to notify</mat-label>
      <mat-chip-list #chipList style="white-space: nowrap; flex: 1" [disabled]="form.value.noNotificaiton">
        <mat-chip *ngFor="let resource of selectedResources">
          <div style="width: 32px; height: 32px">
            <greco-smart-img
              style="margin-left: -6px"
              [src]="resource?.photoURL || 'assets/lf3/icon_square_pad.png'"
              [round]="true"
            ></greco-smart-img>
          </div>
          {{ resource.name }}
          <mat-icon (click)="select(resource)">cancel</mat-icon>
        </mat-chip>

        <input
          #input
          matInput
          [disabled]="form.value.noNotificaiton"
          [readonly]="form.value.noNotificaiton"
          [matChipInputFor]="chipList"
          [matMenuTriggerFor]="tagMenu"
          formControlName="resources"
        />
      </mat-chip-list>
      <mat-menu #tagMenu (close)="searchQuery = ''; input.value = ''">
        <ng-container *ngIf="tagsAndResources?.length; else noResources">
          <button *ngFor="let grouping of tagsAndResources" mat-menu-item [mat-menu-trigger-for]="resourceMenu">
            <mat-icon style="margin-right: 12px">label</mat-icon>
            <span [innerHTML]="grouping.tag.label | safe: 'html'"></span>

            <mat-menu #resourceMenu>
              <button
                *ngFor="let resource of grouping.resources"
                mat-menu-item
                (click)="select(resource); $event.stopImmediatePropagation()"
              >
                <div style="display: flex">
                  <mat-checkbox
                    style="margin-right: 8px; margin-top: -1px"
                    color="primary"
                    [checked]="emails.includes(resource.user?.contactEmail || '')"
                    [disableRipple]="true"
                    (click)="$event.preventDefault()"
                  ></mat-checkbox>
                  <div style="width: 24px; height: 24px; margin-right: 7px">
                    <greco-smart-img
                      style="margin-top: 6px"
                      [src]="resource?.photoURL || 'assets/lf3/icon_square_pad.png'"
                      [round]="true"
                    ></greco-smart-img>
                  </div>
                  <span style="margin-left: 4px" [innerHTML]="resource.name | safe: 'html'"></span>
                </div>
              </button>
            </mat-menu>
          </button>
        </ng-container>
        <ng-template #noResources>
          <button mat-menu-item disabled="true">
            <i>No resources found</i>
          </button>
        </ng-template>
      </mat-menu>
    </mat-form-field>
  </form>
</greco-simple-dialog>
