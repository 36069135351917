import { Component, Input, OnInit } from '@angular/core';
import { Video, VideoSourceRequestDto } from '@greco/videos';

@Component({
  selector: 'greco-watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.scss'],
})
export class WatchVideoComponent implements OnInit {
  @Input() video!: Video | VideoSourceRequestDto;
  @Input() embedUrl!: string;
  @Input() displayTitle = true;
  @Input() titleOverride?: string;
  playerLink: string;
  constructor() {
    this.playerLink = this.video
      ? this.video?.embedUrl + '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
      : this.embedUrl + '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
  }
  ngOnInit(): void {
    this.playerLink = this.video
      ? this.video?.embedUrl + '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
      : this.embedUrl + '&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
  }
}
