import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';

@Injectable({
  providedIn: 'root',
})
export class VimeoService {
  constructor(private http: HttpClient) {}

  async upload(file: File, uploadUrl: string) {
    const size = file.size;
    //patch the binary data of the entire video file to the upload url
    const buffer = await file.arrayBuffer();
    await this._patch(buffer, uploadUrl, '0', size);
  }

  private async _patch(file: ArrayBuffer, uploadUrl: string, uploadOffset: string, size: number): Promise<any> {
    const header = new HttpHeaders()
      .set('Tus-Resumable', '1.0.0')
      .set('Upload-Offset', uploadOffset)
      .set('content-type', 'application/offset+octet-stream')
      .set('accept', 'application/vnd.vimeo.*+json;version=3.4');
    const response = await toPromise(this.http.patch<any>(uploadUrl, file, { headers: header, observe: 'response' }));
    const uploadOffsetResponse = response.headers.get('upload-offset');
    if (uploadOffsetResponse) {
      if (parseInt(uploadOffsetResponse) < size) {
        return await this._patch(file, uploadUrl, uploadOffsetResponse, size);
      }
    }
    return response;
  }
}
