import { MatSpinner } from '@angular/material/progress-spinner';
import { HelpTypeformResolver } from '@greco/ngx-platform-customer-support';
import { WelcomeFormResolver } from '@greco/ngx-platform-dashboard';
import { GrecoRoute } from '@greco/ngx-routes-util';
import { TypeformAuthorizationResolver, TypeformEmbedPage, TypeformResolver } from '@greco/ngx-typeform';
import { EmbedComponent } from '@greco/ui-embed';
import { AgreementTempPage } from './pages/agreement-temp/agreement-temp.page';
import { ScheduleWrapperPage } from './pages/schedule-wrapper/schedule-wrapper.page';

/**
 * !!!IMPORTANT!!! Remembed to update paths in: libs/greco/platform/util-reserved-paths/src/lib/reserved-paths.ts
 */
const root: GrecoRoute = {
  path: '',
  data: { title: '$APP' },
  children: [
    {
      path: 'typeform/registration',
      resolve: { typeform: TypeformAuthorizationResolver },
      component: MatSpinner,
    },

    {
      path: 'account',
      data: { title: 'My Account' },
      loadChildren: () => import('@greco/ngx-route-user-profile').then(r => r.UserProfileRoutingModule),
    },

    {
      path: 'workouts',
      data: { title: 'Workouts & Classes' },
      loadChildren: () => import('@greco/ngx-route-user-booking').then(r => r.UserBookingRoutingModule),
    },

    {
      path: 'workouts-new',
      data: { title: 'Workouts & Classes' },
      loadChildren: () => import('@greco/ngx-route-booking').then(r => r.RouteBookingModule),
    },

    {
      path: 'schedule/:communityId',
      data: { hideNavigation: true, hideEndContent: true, hideBackButton: true },
      component: ScheduleWrapperPage,
    },

    {
      path: 'membership-agreement',
      data: { title: 'Membership Agreement', hideNavigation: true },
      component: AgreementTempPage,
    },
    {
      path: 'music',
      data: {
        title: 'Workout Beats',
        link: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/687818006&color=%23685a8f&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true',
      },
      component: EmbedComponent,
    },

    {
      path: 'shop',
      data: { title: 'Shop & Perks' },
      loadChildren: () => import('@greco/ngx-route-shop').then(r => r.ShopRoutingModule),
    },
    // {
    //   path: 'cart',
    //   data: { title: 'Cart', hideNavigation: true },
    //   component: CartPage,
    // },
    // {
    //   path: 'checkout',
    //   data: { title: 'Checkout', hideNavigation: true },
    //   resolve: {
    //     items: CartItemsResolver,
    //   },
    //   component: CheckoutPage,
    // },
    {
      path: 'form/:formId',
      resolve: {
        typeform: TypeformResolver,
      },
      data: {
        hideNavigation: true,
      },
      component: TypeformEmbedPage,
    },

    {
      path: 'help',
      data: { hideNavigation: true },
      resolve: {
        typeform: HelpTypeformResolver,
      },
      component: TypeformEmbedPage,
    },

    {
      path: 'admin',
      data: { title: 'Administration' },
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'community',
        },
        {
          path: 'lf3-shop',
          data: {
            title: 'Staff Shop',
            link: 'https://lf3.ac-page.com/lf3-shop',
          },
          component: EmbedComponent,
        },
        {
          path: 'community',
          loadChildren: () => import('@greco/ngx-route-communities-admin').then(r => r.CommunitiesAdminRoutingModule),
        },
        {
          path: 'scheduling',
          loadChildren: () => import('@greco/ngx-route-booking-admin').then(r => r.BookingAdminRoutingModule),
        },
        {
          path: 'resource',
          loadChildren: () => import('@greco/ngx-route-resource-admin').then(r => r.ResourceAdminRoutingModule),
        },
        {
          path: 'accounting',
          loadChildren: () =>
            import('@greco/ngx-route-financial-management').then(r => r.FinancialManagementRoutingModule),
        },
        {
          path: 'sales',
          loadChildren: () => import('@greco/ngx-route-product-management').then(r => r.ProductManagementRoutingModule),
        },
        {
          path: 'accounts',
          loadChildren: () => import('@greco/ngx-route-account-management').then(r => r.AccountManagementRoutingModule),
        },
        {
          path: 'videos',
          loadChildren: () => import('@greco/ngx-route-videos-admin').then(r => r.VideosAdminRoutingModule),
        },
        {
          path: 'platform',
          loadChildren: () => import('@greco/ngx-route-platform-admin').then(r => r.PlatformAdminRoutingModule),
        },
        {
          path: 'reports',
          loadChildren: () => import('@greco/ngx-route-reports').then(r => r.ReportsAdminRoutingModule),
        },
      ],
    },

    {
      path: '',
      data: { title: 'Home' },
      loadChildren: () => import('@greco/ngx-route-dashboard').then(r => r.DashboardRoutingModule),
    },
    {
      path: 'welcome/:communityId',
      resolve: {
        typeform: WelcomeFormResolver,
      },
      data: {
        hideNavigation: true,
      },
      component: TypeformEmbedPage,
    },
  ],
};

export const routes: GrecoRoute[] = [root];
