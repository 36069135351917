import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { EventVideo } from '@greco/event-videos';
import { User } from '@greco/identity-users';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';
import { UserVideoTableComponent } from '../user-video-table';

@Injectable({ providedIn: 'any' })
export class VideoUnlockSearchFilter extends BuildSearchFilter('VideoUnlockSearchFilter', {
  properties: ['video.title'],
  propertyLabels: ['Title'],
}) {}

@Component({
  selector: 'greco-unlocked-gallery',
  templateUrl: './unlocked-gallery.component.html',
  styleUrls: ['./unlocked-gallery.component.scss'],
})
export class UnlockedGalleryComponent {
  @ViewChild(UserVideoTableComponent) table?: UserVideoTableComponent;
  videos: EventVideo[] = [];

  filterOptions = [VideoUnlockSearchFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  @Input() user?: User;
  @Input() expanded = true;

  private refresh() {
    this.table?.refresh();
  }
}
