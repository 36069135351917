import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GrecoDateComparePipe } from './date-compare.pipe';
import { GrecoJoinPipe } from './join.pipe';
import { GrecoMapPipe } from './map.pipe';
import { GreocRemoveUnderscoresPipe } from './remove-underscore.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [GrecoDateComparePipe, GrecoJoinPipe, GrecoMapPipe, GreocRemoveUnderscoresPipe],
  exports: [GrecoDateComparePipe, GrecoJoinPipe, GrecoMapPipe, GreocRemoveUnderscoresPipe],
})
export class JoinPipeModule {}
