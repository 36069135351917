import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QueryEventsByDateDto } from '@greco/nestjs-booking-events';
import { InitiateExportDialog } from '@greco/ngx-data-exports';
import { userDefaultTimezone } from '@greco/timezone';
import * as moment from 'moment';
import { EventService } from '../../services';

export interface DateRangePickerDialogData {
  processorId: string;
  communityId: string;
  dto: QueryEventsByDateDto;
}

@Component({
  styleUrls: ['date-range-picker.dialog.scss'],
  templateUrl: 'date-range-picker.dialog.html',
})
export class DateRangePickerDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DateRangePickerDialogData,
    public dialogRef: MatDialogRef<DateRangePickerDialog>,
    public eventsSvc: EventService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private dialog: MatDialog
  ) {}

  exporting = false;
  communityId = null;
  tooFarInFuture = false;
  twoWeeks = new Date().setDate(new Date().getDate() + 2 * 7);

  formGroup = this.formBuilder.group(
    {
      start: [{ date: new Date(), timezone: userDefaultTimezone() }, [Validators.required]],
      end: [{ date: this.twoWeeks, timezone: userDefaultTimezone() }, [Validators.required]],
    },
    {
      validator: this.validateDate(),
    }
  );

  static open(dialogSvc: MatDialog, data: DateRangePickerDialogData) {
    dialogSvc.open(DateRangePickerDialog, { data, autoFocus: false });
  }

  export() {
    try {
      this.data.dto.startDate = new Date(this.formGroup.value.start.date);
      this.data.dto.endDate = new Date(this.formGroup.value.end.date);
      this.close();

      InitiateExportDialog.open(this.dialog, {
        processorId: 'EventsDataExportProcessor',
        initiateExport: () => {
          return this.eventsSvc.export(this.data.dto);
        },
      });
    } catch (err) {
      this.snacks.open('There was an issue initiating your export!', 'Ok', {
        duration: 2500,
        panelClass: 'mat-primary',
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  validateDate() {
    return (formGroup: FormGroup) => {
      const startDate = new Date(formGroup.value.start.date).getTime();
      const endDate = new Date(formGroup.value.end.date).getTime();

      if (endDate <= startDate || moment(endDate).toDate() > moment(startDate).endOf('day').add(6, 'M').toDate()) {
        formGroup.controls['end'].setErrors({ invalid: true });
        this.tooFarInFuture = true;
      } else {
        formGroup.controls['end'].setErrors(null);
        this.tooFarInFuture = false;
      }
    };
  }
}
