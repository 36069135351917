import { SecurityResource } from '@greco/security';

export const DEFAULT_SALE_CATEGORY_SECURITY_RESOURCE = 'finance_purchasing_sale_category_default';

export enum DefaultSaleCategoryResourceAction {
  SET_DEFAULT = 'SET_DEFAULT',
}

export const DefaultSaleCategoryResource: SecurityResource = {
  key: DEFAULT_SALE_CATEGORY_SECURITY_RESOURCE,
  module: 'Finance',

  title: 'Default Sale Categories',
  description: 'Set default sale categories',

  context: class {
    accountId?: string;
  },

  actions: [
    {
      key: DefaultSaleCategoryResourceAction.SET_DEFAULT,
      title: 'Set Defaults',
      description: 'Ability to set default sales categories for purchase types',
    },
  ],
};
