<greco-table
  [loading]="loading"
  [highlight]="true"
  [data]="(categories$ | async) || []"
  (rowClick)="rowClick.next($event)"
>
  <!-- Icon -->
  <mat-icon *grecoTableCol="''; let category; fitContent: true" [matTooltip]="category.id">category</mat-icon>

  <!-- Title -->
  <p *grecoTableCol="'Sale Category'; let category" [matTooltip]="category.label">
    {{ category.label }}
  </p>

  <!-- CREATED -->
  <p *grecoTableCol="'Created'; let category" [matTooltip]="(category.created | date : 'full') || ''">
    {{ category.created | date }}
  </p>

  <!-- GENERAL LEDGER CODE -->
  <p *grecoTableCol="'General Ledger Code'; let category" matTooltip="General Ledger Code">
    {{ category.generalLedgerCode }}
  </p>

  <!-- Stats -->
  <p *grecoTableCol="'Products'; let category" matTooltip="Number of products assigned to category">
    {{ category.productCount || '-' }}
  </p>

  <!--TODO: OTHER COUNTS
  <ng-container *grecoTableCol="'One Time Product Variants'; let category">
    <p style="overflow: hidden">
      {{ category.oneTimeProductCount || '-' }}
    </p>
  </ng-container>

  <ng-container *grecoTableCol="'Recurring Product Variants'; let category">
    <p style="overflow: hidden">
      {{ category.recurringProducts?.length || '-' }}
    </p>
  </ng-container>
  <ng-container *grecoTableCol="'One Time Purchases'; let category">
    <p style="overflow: hidden">
      {{ category.oneTimePurchases?.length || '-' }}
    </p>
  </ng-container>
  <ng-container *grecoTableCol="'Recurring Purchases'; let category">
    <p style="overflow: hidden">
      {{ category.recurringPurchases?.length || '-' }}
    </p>
  </ng-container>-->

  <!-- Status-->
  <mat-chip
    *grecoTableCol="'Status'; let category"
    [matTooltip]="category.archivedOn ? 'Archived on ' + (category.archivedOn | date) : ''"
  >
    {{ category.archivedOn ? 'Archived' : 'Active' }}
  </mat-chip>

  <!-- Staff-->
  <mat-chip-list *grecoTableCol="'Staff'; let category">
    <mat-chip *ngFor="let staff of category.staff" style="padding-left: 6px">
      <div style="width: 24px; height: 24px">
        <greco-smart-img [src]="staff?.photoURL || 'assets/lf3/icon_square_pad.png'" [round]="true"> </greco-smart-img>
      </div>
      <span style="margin-left: 6px">{{ staff?.friendlyName || staff?.displayName || staff?.email }}</span>
    </mat-chip>
  </mat-chip-list>

  <!-- Options -->
  <ng-container *ngIf="canUpdate$ | async">
    <ng-container *grecoTableCol="''; let category; fitContent: true; stickyEnd: true">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <!-- View Info -->
        <button mat-menu-item (click)="rowClick.emit(category)">
          <mat-icon>info</mat-icon>
          <span>View Info</span>
        </button>

        <!-- Update -->
        <button mat-menu-item (click)="update(category)" *ngIf="!category.archivedOn">
          <mat-icon>edit</mat-icon>
          <span>Update Label</span>
        </button>

        <!-- Activate/Archive -->
        <ng-container>
          <button mat-menu-item (click)="category.archivedOn ? unarchive(category) : archive(category)">
            <mat-icon>{{ category.archivedOn ? 'unarchive' : 'archive' }}</mat-icon>
            <span>{{ category.archivedOn ? 'Unarchive' : 'Archive' }}</span>
          </button></ng-container
        >
      </mat-menu>
    </ng-container>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="currentPagination?.totalItems"
  showFirstLastButtons
  [length]="currentPagination!.totalItems || 0"
  [pageSize]="currentPagination!.itemsPerPage || pageSizes[0]"
  [pageSizeOptions]="pageSizes"
  (page)="paginatedParams$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
