<ng-container *ngIf="!acceptFile; else fileUpload">
  <div style="width: 100%; display: flex; flex-direction: row; flex-wrap: wrap">
    <!-- List of images -->
    <div *ngFor="let imageUrl of urls" class="image-block">
      <div *ngIf="!disabled" class="image-hover">
        <mat-icon style="color: lightgray; margin-top: -40px; margin-left: 8px" (click)="removeImage(imageUrl)">
          close
        </mat-icon>
      </div>
      <img [src]="imageUrl | safe : 'resourceUrl'" />
    </div>

    <!-- Add Image Button -->
    <ng-container *ngIf="multiple ? true : urls.length > 0 ? false : true">
      <div *ngIf="disabled; else notDisabled" class="image-block" style="pointer-events: none">
        <mat-icon style="color: darkgray; margin-top: 8px; margin-left: -40px" color="primary"> add </mat-icon>
      </div>
      <ng-template #notDisabled>
        <div class="image-block">
          <mat-icon style="margin-top: 8px; margin-left: -40px" color="primary" (click)="fileInput.click()">
            add
          </mat-icon>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <input
    #fileInput
    [readonly]="false"
    style="display: none"
    type="file"
    accept="image/*"
    [multiple]="multiple"
    (change)="addImage($any($event.target).files); fileInput.value = ''"
  />
</ng-container>

<ng-template #fileUpload>
  <div class="file-box">
    <div *ngFor="let filrURL of urls" [class]="'file-list'">
      <div *ngIf="!disabled" class="close-button">
        <mat-icon class="file-icon" color="primary" (click)="removeFile(filrURL)"> close </mat-icon>
      </div>
      <div class="view-button">
        <a (click)="viewFile(filrURL)">
          <mat-icon class="file-icon" color="primary"> search </mat-icon>
        </a>
      </div>
      <mat-icon class="document" color="primary">picture_as_pdf</mat-icon>
    </div>

    <ng-container *ngIf="multiple ? true : urls.length > 0 ? false : true">
      <mat-icon *ngIf="disabled; else notDisabled" class="add-document-disabled" color="primary"> add </mat-icon>
      <ng-template #notDisabled>
        <mat-icon class="add-document" color="primary" (click)="fileInput.click()"> add </mat-icon>
      </ng-template>
    </ng-container>
  </div>

  <input
    #fileInput
    [readonly]="false"
    style="display: none"
    type="file"
    accept=".pdf"
    [multiple]="false"
    (change)="addFile($any($event.target).files); fileInput.value = ''"
  />
</ng-template>
