<div class="greco-user-community-picker-container">
  <mat-select multiple [formControl]="_control" [compareWith]="compareCommunities">
    <ng-container *ngIf="userCommunities$ | async as userCommunities">
      <mat-optgroup *ngIf="userCommunities.length" label="My Communities">
        <mat-option
          *ngFor="let community of userCommunities"
          [disabled]="disabled || (required && _control.value.length === 1 && _control.value[0]?.id === community.id)"
          [value]="community"
        >
          {{ community.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <!-- <ng-container *ngIf="otherCommunities$ | async as otherCommunities">
      <mat-optgroup *ngIf="otherCommunities.length" label="Other Communities">
        <mat-option
          *ngFor="let community of otherCommunities"
          [disabled]="disabled || (required && _control.value.length === 1 && _control.value[0]?.id === community.id)"
          [value]="community"
        >
          {{ community.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container> -->
  </mat-select>
</div>
