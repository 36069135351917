import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountChargeResource, AccountChargeResourceAction } from '@greco/account-charges';
import { Account } from '@greco/finance-accounts';
import { ReconciliationConfig } from '@greco/finance-payments';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AccountService } from '../../services';

@Component({
  selector: 'greco-account-reconciliation-config',
  templateUrl: './account-reconciliation-config.component.html',
  styleUrls: ['./account-reconciliation-config.component.scss'],
})
export class AccountReconciliationConfigComponent {
  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private accountSvc: AccountService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('account') account$ = new BehaviorSubject<Account | null>(null);
  @Input() account!: Account;

  refresh$ = new BehaviorSubject(null);
  loading = false;

  showTopups = true;

  resetValue: any = { paymentMethod: null };

  hasAccess$ = this.account$.pipe(
    switchMap(async account => {
      return account
        ? await this.securitySvc.hasAccess(AccountChargeResource.key, AccountChargeResourceAction.CONFIGURE_SCHEDULE, {
            accountId: account.id,
          })
        : false;
    })
  );

  config$ = combineLatest([this.account$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([account]) => {
      const reconConfig = account ? await this.accountSvc.getReconciliationConfig(account.id) : null;

      if (reconConfig) {
        const payoutSchedule = {
          date: reconConfig.schedulePayoutDate,
          until: reconConfig.schedulePayoutUntil,
          frequency: reconConfig.schedulePayoutFrequency,
          rrule: reconConfig.schedulePayoutRrule,
        };

        const topupSchedule = {
          date: reconConfig.scheduleTopupDate,
          until: reconConfig.scheduleTopupUntil,
          frequency: reconConfig.scheduleTopupFrequency,
          rrule: reconConfig.scheduleTopupRrule,
        };

        this.form.setValue({
          paymentMethod: reconConfig.paymentMethod,
          scheduleTopup: topupSchedule,
          schedulePayout: payoutSchedule,
        });

        this.reset({
          paymentMethod: reconConfig.paymentMethod,
          scheduleTopup: topupSchedule,
          schedulePayout: payoutSchedule,
        });
      } else {
        this.reset({ paymentMethod: null, scheduleTopup: null, schedulePayout: null });
      }

      return reconConfig;
    }),
    tap(() => (this.loading = false))
  );

  form = this.formBuilder.group({
    paymentMethod: [null],
    scheduleTopup: [null],
    schedulePayout: [null],
  });

  async save(currentConfig?: ReconciliationConfig) {
    if (this.account.id) {
      if (this.form.value.scheduleTopup && !this.form.value.paymentMethod?.id) {
        this.snackBar.open('Make sure to add a payment method before saving!', 'Ok', {
          duration: 10000,
          panelClass: 'mat-warn',
        });
        return;
      }

      const topupDetails = this.form.value?.scheduleTopup;
      const payoutDetails = this.form.value?.schedulePayout;

      if (!currentConfig && !topupDetails?.date && !payoutDetails?.date) {
        this.snackBar.open('Make sure to configure the schedule for topups or payouts before saving!', 'Ok', {
          duration: 10000,
          panelClass: 'mat-warn',
        });
        return;
      }

      const dto = {
        paymentMethodId: this.form.value.paymentMethod?.id || null,

        scheduleTopupDate: topupDetails?.date || null,
        scheduleTopupUntil: topupDetails?.until || null,
        scheduleTopupFrequency: topupDetails?.frequency || null,
        scheduleTopupRrule: topupDetails?.rrule || null,

        schedulePayoutDate: payoutDetails?.date || null,
        schedulePayoutUntil: payoutDetails?.until || null,
        schedulePayoutFrequency: payoutDetails?.frequency || null,
        schedulePayoutRrule: payoutDetails?.rrule || null,
      };

      if (currentConfig) {
        if (
          currentConfig.paymentMethodId === dto.paymentMethodId &&
          currentConfig.scheduleTopupDate === dto.scheduleTopupDate &&
          currentConfig.scheduleTopupUntil === dto.scheduleTopupUntil &&
          currentConfig.scheduleTopupFrequency === dto.scheduleTopupFrequency &&
          currentConfig.scheduleTopupRrule === dto.scheduleTopupRrule &&
          currentConfig.schedulePayoutDate === dto.schedulePayoutDate &&
          currentConfig.schedulePayoutUntil === dto.schedulePayoutUntil &&
          currentConfig.schedulePayoutFrequency === dto.schedulePayoutFrequency &&
          currentConfig.schedulePayoutRrule == dto.schedulePayoutRrule
        ) {
          this.snackBar.open('No changes have been made!', 'Ok', { duration: 10000, panelClass: 'mat-warn' });
          return;
        }
      }

      this.loading = true;

      try {
        await this.accountSvc.updateReconciliationConfig(this.account.id, dto);
        this.snackBar.open('Changes Saved!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
        this.refresh();
      } catch (err: any) {
        this.snackBar.open(err?.error?.message, 'Ok', { duration: 10000, panelClass: 'mat-warn' });
      }

      this.loading = false;
    }
  }

  async changeStatus(currentConfig: ReconciliationConfig) {
    const response = await this.accountSvc.updateReconciliationConfig(this.account.id, {
      ...currentConfig,
      enabled: !currentConfig.enabled,
    });
    if (response) {
      this.snackBar.open('Status updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.refresh();
    } else {
      this.snackBar.open('Oops! Something Went Wrong', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }
  }

  paymentMethodcahnged(event: any) {
    if (!event) this.showTopups = false;
    else this.showTopups = true;
  }

  refresh() {
    this.refresh$.next(null);
  }

  private reset(resetValue: any) {
    this.resetValue = resetValue;
    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }
}
