import { SecurityResource } from '@greco/security';

export const COUPON_SECURITY_RESOURCE = 'coupon';

export enum CouponSecurityAction {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  ARCHIVE = 'ARCHIVE',
  GRANT = 'GRANT',
}

export const CouponSecurityResource: SecurityResource = {
  key: COUPON_SECURITY_RESOURCE,
  module: 'Sales',
  title: 'Coupon',
  description: 'Manage coupon options',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CouponSecurityAction.READ,
      title: 'View coupons',
      description: 'Ability to view coupons',
    },
    {
      key: CouponSecurityAction.CREATE,
      title: 'Create coupons',
      description: 'Ability to create a new coupon',
    },
    {
      key: CouponSecurityAction.UPDATE,
      title: 'Update coupons',
      description: 'Ability to update a coupon',
    },
    {
      key: CouponSecurityAction.ARCHIVE,
      title: 'Archive coupons',
      description: 'Ability to archive a coupon',
    },
    {
      key: CouponSecurityAction.GRANT,
      title: 'Grant coupons',
      description: 'Abily to grant a coupon ',
    },
  ],
};
