import { SecurityResource } from '@greco/security';

export const COMMUNITY_VIDEO_UNLOCK_SECURITY_RESOURCE = 'community_video_unlock';

export enum CommunityVideoUnlockSecurityResourceAction {
  READ = 'READ',
  MANAGE = 'MANAGE',
}

export const CommunityVideoUnlockSecurityResource: SecurityResource = {
  key: COMMUNITY_VIDEO_UNLOCK_SECURITY_RESOURCE,
  module: 'videos',

  title: 'Video Unlock Controls',
  description: 'Manage user video unlocks for a community',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CommunityVideoUnlockSecurityResourceAction.READ,
      title: 'Read unlocks for videos',
      description: 'Ability to read user unlocks for community videos',
    },
    {
      key: CommunityVideoUnlockSecurityResourceAction.MANAGE,
      title: 'Grant or Update unlocked videos',
      description: 'Ability to grant or update user video unlocks',
    },
  ],
};
