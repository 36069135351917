<greco-table
  [loading]="loading"
  [highlight]="true"
  [data]="(disputes$ | async) || []"
  (rowClick)="rowClick.next($event)"
>
  <!-- Reason -->
  <ng-container *grecoTableCol="'Reason'; let paymentDispute">
    <p style="overflow: hidden">
      {{ paymentDispute.reason | titlecase }}
    </p>
  </ng-container>

  <!-- Created -->
  <ng-container *grecoTableCol="'Created'; let paymentDispute">
    <p>
      {{ paymentDispute.created | date }}
    </p>
  </ng-container>

  <!-- Due By -->
  <ng-container *grecoTableCol="'Due By'; let paymentDispute">
    <p>
      {{ paymentDispute.evidanceSubmissionDueBy | date : 'medium' }}
    </p>
  </ng-container>

  <!-- Customer -->
  <ng-container *grecoTableCol="'Customer'; let paymentDispute">
    <a (click)="$event.stopImmediatePropagation()">
      {{ paymentDispute.user.displayName }}
    </a>
  </ng-container>

  <!-- Amount -->
  <ng-container *grecoTableCol="'Amount'; let paymentDispute; alignRight: true">
    <p>{{ paymentDispute.payment.amount / 100 | currency }}</p>
  </ng-container>

  <!-- Status -->
  <ng-container *grecoTableCol="'Status'; let paymentDispute; alignRight: true">
    <p>
      <mat-chip *ngIf="paymentDispute.status">
        {{ paymentDispute.status.replace('_', ' ') | titlecase }}
      </mat-chip>
    </p>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="currentPagination?.totalItems"
  showFirstLastButtons
  [length]="currentPagination!.totalItems || 0"
  [pageSize]="currentPagination!.itemsPerPage || pageSizes[0]"
  [pageSizeOptions]="pageSizes"
  (page)="paginatedParams$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
