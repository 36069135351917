<!-- Invoice -->
<greco-collapsible-section
  [disabled]="true"
  [header]="{ title: 'Invoice', icon: 'inventory_2' }"
  style="display: block; margin-bottom: 12px"
>
  <mat-chip-list header style="margin-right: 8px">
    <!-- Status -->
    <mat-chip [color]="invoice.data.status === 'paid' ? 'primary' : 'warn'" [selected]="invoice.data.status !== 'paid'">
      <ng-container *ngIf="invoice.data.status === 'paid' && invoice.data.total < 0; else other">
        Balance Credited
      </ng-container>
      <ng-template #other>
        {{invoice.data.status | titlecase}}{{invoice.data.status === 'open' && invoice.data.charge ? (' | ' +
        (invoice.data.charge.status | titlecase)) : ''}}
      </ng-template>
    </mat-chip>

    <!-- Refunded -->
    <mat-chip *ngIf="invoice.data.charge?.refunded">
      <span style="margin-right: 8px">Refunded</span>
      <mat-icon style="font-size: 16px; width: auto; height: auto">undo</mat-icon>
    </mat-chip>

    <!-- Partial Refund -->
    <mat-chip
      *ngIf="(invoice.data.charge?.amount_refunded || 0) > 0 && (invoice.data.charge?.amount_refunded || 0) < (invoice.data.charge?.amount || 0)"
    >
      <span style="margin-right: 8px">Partial Refund</span>
      <mat-icon style="font-size: 16px; width: auto; height: auto">undo</mat-icon>
    </mat-chip>

    <!-- Subscription -->
    <mat-chip *ngIf="invoice.data.subscription">
      <span style="margin-right: 8px">
        {{ invoice?.data?.billing_reason?.replace('subscription_', '') || '' | titlecase }}
      </span>
      <mat-icon style="font-size: 16px; width: auto; height: auto">sync</mat-icon>
    </mat-chip>

    <!-- Covid -->
    <mat-chip *ngIf="covid">
      <span style="margin-right: 8px">Covid</span>
      <mat-icon style="font-size: 16px; width: auto; height: auto">favorite_border</mat-icon>
    </mat-chip>

    <!-- Notes -->
    <mat-chip *ngIf="invoice.notes?.length">
      <span style="margin-right: 8px">Notes</span>
      <mat-icon style="font-size: 16px; width: auto; height: auto">description</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <ng-container header *ngIf="invoice.data.hosted_invoice_url || invoice.data.charge?.receipt_url">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <a *ngIf="invoice.data.hosted_invoice_url" mat-menu-item target="_blank" [href]="invoice.data.hosted_invoice_url">
        <mat-icon>open_in_new</mat-icon>
        <span>Invoice</span>
      </a>

      <a
        *ngIf="invoice.data.charge?.receipt_url"
        mat-menu-item
        target="_blank"
        [href]="invoice.data.charge?.receipt_url"
      >
        <mat-icon>open_in_new</mat-icon>
        <span>Receipt</span>
      </a>
    </mat-menu>
  </ng-container>

  <div class="grid">
    <ng-container *ngFor="let line of invoice.data.lines.data">
      <h3>{{line.description}}</h3>
      <p>{{((line.amount || 0) * (line.quantity || 1)) / 100 | currency}}</p>
    </ng-container>

    <mat-divider style="grid-column: 1 / span 2"></mat-divider>

    <ng-container *ngIf="discount">
      <h3>Discount</h3>
      <p>{{ discount / 100 | currency }}</p>
    </ng-container>

    <ng-container *ngIf="invoice.data.tax">
      <h3 style="text-align: right">Taxes</h3>
      <p>{{ invoice.data.tax / 100 | currency }}</p>
    </ng-container>

    <ng-container>
      <h3 style="text-align: right">Subtotal</h3>
      <p>{{ invoice.data.total / 100 | currency }}</p>
    </ng-container>

    <ng-container *ngIf="balanceApplied">
      <h3 style="text-align: right">Balance Applied</h3>
      <p>{{ -balanceApplied / 100 | currency }}</p>
    </ng-container>

    <ng-container *ngIf="invoice.data?.charge?.amount">
      <h3 style="text-align: right">Charge Amount</h3>
      <p>{{ invoice.data.charge.amount / 100 | currency }}</p>
    </ng-container>
  </div>
</greco-collapsible-section>

<!-- Refunds -->
<greco-collapsible-section
  [expanded]="expanded === 'refunds'"
  (changed)="expanded = ($event ? 'refunds' : null)"
  [header]="{ title: 'Refunds', icon: 'undo' }"
  style="display: block; margin-bottom: 12px"
  *ngIf="invoice.data.charge?.refunds?.data?.length"
>
  <div class="grid">
    <ng-container *ngFor="let refund of invoice.data.charge?.refunds?.data">
      <h3>{{refund.created * 1000 | date}} {{refund.created * 1000 | date: 'shortTime'}}</h3>
      <p>{{(refund.amount || 0) / 100 | currency}}</p>
    </ng-container>

    <mat-divider style="grid-column: 1 / span 2"></mat-divider>

    <ng-container>
      <h3 style="text-align: right">Total</h3>
      <p>{{ (invoice.data.charge?.amount_refunded || 0) / 100 | currency }}</p>
    </ng-container>
  </div>
</greco-collapsible-section>

<!-- Fees -->
<greco-collapsible-section
  [expanded]="expanded === 'fees'"
  (changed)="expanded = ($event ? 'fees' : null)"
  [header]="{ title: 'Fees', icon: 'request_quote' }"
  style="display: block; margin-bottom: 12px"
>
  <div class="grid">
    <ng-container *ngFor="let fee of fees; let last = last; let first = first">
      <div>
        <h3 style="cursor: pointer" (click)="fee.info && openPaymentProcessingFeeDialog()">
          <span>{{fee.title}}</span>
          <mat-icon *ngIf="fee.info" style="font-size: 16px; margin-left: 4px">info</mat-icon>
        </h3>
        <span style="font-size: 80%">{{fee.subtitle}}</span>
      </div>

      <p>{{fee.amount / 100 | currency}}</p>
    </ng-container>

    <mat-divider style="grid-column: 1 / span 2"></mat-divider>

    <ng-container>
      <h3 style="text-align: right">Total Fees</h3>
      <p>{{ (totalFees || 0) / 100 | currency }}</p>
    </ng-container>

    <ng-container>
      <h3 style="text-align: right">Studio Net</h3>
      <p>{{ (totalAfterFees || 0) / 100 | currency }}</p>
    </ng-container>
  </div>
</greco-collapsible-section>

<!-- Customer -->
<greco-collapsible-section
  [expanded]="expanded === 'customer'"
  (changed)="expanded = ($event ? 'customer' : null)"
  [header]="{ title: 'Customer', icon: 'account_circle', badge: ''}"
  style="display: block; margin-bottom: 12px"
>
  <div class="grid">
    <h3>Name</h3>
    <p>{{invoice.displayName}}</p>

    <h3>Email Address</h3>
    <p>{{invoice.email}}</p>
  </div>
</greco-collapsible-section>

<!-- Notes -->
<greco-collapsible-section
  [expanded]="expanded === 'notes'"
  (changed)="expanded = ($event ? 'notes' : null)"
  [header]="{ title: 'Notes', icon: 'description' }"
  style="display: block; margin-bottom: 12px"
  *ngIf="invoice.notes?.length"
>
  <div class="grid">
    <ng-container *ngFor="let note of invoice.notes; let last = last">
      <h3 [innerHTML]="note.content | safe: 'html'"></h3>
      <p>
        {{ $any(note.timestamp)._seconds * 1000 | date }} {{ $any(note.timestamp)._seconds * 1000 | date: 'shortTime' }}
      </p>

      <mat-divider *ngIf="!last" style="grid-column: 1 / span 2"></mat-divider>
    </ng-container>
  </div>
</greco-collapsible-section>

<!-- <pre>
  {{invoice | json}}
</pre> -->
