import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

function OneOfValidator(...options: any[]): ValidatorFn {
  return (control: AbstractControl) => {
    1;
    return (options || []).includes(control.value) ? null : { oneOf: true };
  };
}

@Component({
  selector: 'greco-minutes-input',
  templateUrl: './minutes-input.component.html',
  styleUrls: ['./minutes-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MinutesInputComponent }],
})
export class MinutesInputComponent implements OnDestroy, MatFormFieldControl<number>, ControlValueAccessor {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  static ID = 0;

  @ViewChild(MatInput) private input?: MatInput;

  private _onTouched?: () => void;

  _internalForm = this.formBuilder.group({
    count: [null, [Validators.min(0)]],
    period: ['minutes', [Validators.required, OneOfValidator('minutes', 'hours', 'days')]],
  });

  readonly stateChanges = new Subject<void>();
  private _onDestroy$ = new Subject<void>();

  readonly controlType = 'greco-cancellation-window-input';
  readonly id = `${this.controlType}-${MinutesInputComponent.ID++}`;

  @Input() get value() {
    const { count, period } = this._internalForm.value;
    if (count === null) return null;
    const countValue = Number(count || 0);

    switch (period) {
      case 'minutes':
        return countValue;
      case 'hours':
        return countValue * 60;
      case 'days':
        return countValue * 60 * 24;
      default:
        return null;
    }
  }
  set value(value) {
    const actualValue = typeof value === 'number' ? value : value ? Number(value) : null;
    this._internalForm.setValue({ count: actualValue, period: 'minutes' }, { emitEvent: false });

    if (actualValue) {
      const hours = actualValue / 60;
      const days = hours / 24;

      if (!days.toString().includes('.')) {
        this._internalForm.setValue({ count: days, period: 'days' }, { emitEvent: false });
      } else if (!hours.toString().includes('.')) {
        this._internalForm.setValue({ count: hours, period: 'hours' }, { emitEvent: false });
      }
    }

    this.stateChanges.next();
  }

  touched = false;
  focused = false;

  get empty() {
    return !this._internalForm.value.count?.toString?.();
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder = '';
  @Input() get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  private _required = false;
  @Input() get required() {
    return this._required;
  }
  set required(required) {
    this._required = coerceBooleanProperty(required);

    this._internalForm
      .get('count')
      ?.setValidators([...(this._required ? [Validators.required] : []), Validators.min(0)]);

    this.stateChanges.next();
  }

  private _disabled = false;
  @Input() get disabled() {
    return this._disabled;
  }
  set disabled(disabled) {
    this._disabled = coerceBooleanProperty(disabled);

    if (this._disabled) this._internalForm.disable();
    else this._internalForm.enable();

    this.stateChanges.next();
  }

  get errorState() {
    return this.touched && this._internalForm.invalid;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy!: string;

  setDescribedByIds(ids: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-minutes-input-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    if (!this.disabled) this.input?.onContainerClick();
  }

  onFocusIn(_: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this._onTouched?.();
      this.stateChanges.next();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._internalForm.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => fn(this.value));
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnDestroy() {
    this.stateChanges.complete();

    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
