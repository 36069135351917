import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { SelectFilter } from '@greco/ngx-filters';
import { PurchaseStatus } from '@greco/sales-purchases';

@Injectable({ providedIn: 'any' })
export class PurchaseSalesStatusFilter extends SelectFilter {
  private titleCase = new TitleCasePipe();

  constructor() {
    super('PurchaseSalesStatusFilter', {
      label: 'Purchase Sale Status',
      shortLabel: 'Sale Status',
      description: 'Purchase Sale Status',
      properties: ['status'],
    });
  }

  getValueLabel(value: PurchaseStatus | PurchaseStatus[]): string {
    return Array.isArray(value)
      ? value.map(type => this.titleCase.transform(type)).join(', ')
      : this.titleCase.transform(value);
  }
  getValueOptions(search?: string): PurchaseStatus[] {
    const types = Object.values(PurchaseStatus);
    return search
      ? types.filter(type => this.titleCase.transform(type).toLowerCase().startsWith(search.toLowerCase()))
      : types;
  }

  serializeValue(value: PurchaseStatus | PurchaseStatus[]): string {
    return Array.isArray(value) ? value.map(type => type).join(',') : value;
  }

  deserializeValue(serializedValue: string): PurchaseStatus[] {
    const keys = Object.keys(PurchaseStatus);
    return serializedValue.split(',').filter(type => keys.includes(type)) as PurchaseStatus[];
  }
}
