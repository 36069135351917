import { Component, Input } from '@angular/core';
import { AccountLink } from '@greco/account-linking';
import { Booking, BookingOption, CalendarEvent } from '@greco/booking-events';

@Component({
  selector: 'greco-user-event-list-group',
  templateUrl: './event-list-group.component.html',
  styleUrls: ['./event-list-group.component.scss'],
})
export class EventListGroupComponent {
  @Input() pagination = true;

  @Input() id?: string;

  @Input() dayHeader?: string;

  @Input() userWaitlist?: string[];
  @Input() userBookings: Booking[] = [];

  @Input() linkedAccounts: AccountLink[] = [];

  @Input() events: { event: CalendarEvent; bookingOption: BookingOption }[] = [];

  pageIndex = 0;
  displayRange = this.calculateRange();

  trackById(_: number, row: { event: CalendarEvent; bookingOption: BookingOption }) {
    return row.event.id;
  }

  changePage(index: number) {
    this.pageIndex = index;
    this.displayRange = this.calculateRange();
  }

  private calculateRange() {
    return [this.pageIndex * 5, this.pageIndex * 5 + 5];
  }
}
