<div class="card-bg">
  <ng-content select="[before]"></ng-content>
</div>

<table class="greco-table" mat-table [dataSource]="data" [trackBy]="trackByField">
  <ng-container *ngFor="let column of columns" matColumnDef="{{ column?.id }}" [stickyEnd]="column?.stickyEnd">
    <th
      mat-header-cell
      *matHeaderCellDef
      [style.text-align]="column?.align ? column?.align : null"
      [style.background-color]="column?.name ? null : 'transparent'"
    >
      {{ column?.name }}
    </th>

    <td
      [ngSwitch]="column?.type"
      mat-cell
      *matCellDef="let element"
      [class.fit-content]="column?.fitContent"
      [style.text-align]="column?.align ? column?.align : null"
    >
      <ng-container *ngSwitchCase="'custom'">
        <!-- TODO(adaoust): Refactor the table to always use outlets? -->
        <ng-container
          *ngTemplateOutlet="
            getTemplate(column.id);
            context: { $implicit: element, value: element | itemFn: column.value | async }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'avatar'">
        <greco-smart-img
          [round]="true"
          [border]="true"
          style="width: 28px; height: 28px; margin: 0 4px"
          [src]="element | itemFn: column.value | async"
          [matTooltip]="column?.tooltip ? ((element | itemFn: column.tooltip | async) || '' | stripTags) : null"
        ></greco-smart-img>
      </ng-container>

      <ng-container *ngSwitchCase="'icon-button'">
        <button
          [matTooltip]="column?.tooltip ? ((element | itemFn: column.tooltip | async) || '' | stripTags) : null"
          matTooltipClass="multiline-tooltip"
          mat-icon-button
          (click)="buttonClick(element, column, $event, menuTrigger)"
        >
          <mat-icon>{{ (element | itemFn: column.value | async) || element[column.id] }}</mat-icon>
        </button>
        <button
          style="width: 0; height: 0; pointer-events: none; opacity: 0; padding: 0; margin: 0"
          [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger"
        ></button>
        <mat-menu #menu="matMenu">
          <ng-template matMenuContent let-options="options">
            <button
              mat-menu-item
              *ngFor="let option of options"
              [disabled]="option.disabled || false"
              (click)="option.action()"
            >
              <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
              <span>{{ option.text }}</span>
            </button>
          </ng-template>
        </mat-menu>
      </ng-container>

      <mat-chip-list *ngSwitchCase="'chip'" tabindex="-1" [selectable]="false" style="pointer-events: none">
        <mat-chip
          *ngIf="(element | itemFn: column.value | async) || element[column.id]"
          matTooltipClass="multiline-tooltip"
          [matTooltip]="column?.tooltip ? ((element | itemFn: column.tooltip | async) || '' | stripTags) : null"
          color="accent"
        >
          {{ (element | itemFn: column.value | async) || element[column.id] }}
        </mat-chip>
      </mat-chip-list>

      <mat-chip-list *ngSwitchCase="'chip-list'" tabindex="-1" [selectable]="false" style="pointer-events: none">
        <mat-chip
          matTooltipClass="multiline-tooltip"
          [matTooltip]="column?.tooltip ? ((element | itemFn: column.tooltip | async) || '' | stripTags) : null"
          color="accent"
          *ngFor="let value of element | itemFn: $any(column).listValue | async"
        >
          {{ value || element[column.id] }}
        </mat-chip>
      </mat-chip-list>

      <mat-icon
        *ngSwitchCase="'icon'"
        matTooltipClass="multiline-tooltip"
        [matTooltip]="column?.tooltip ? ((element | itemFn: column.tooltip | async) || '' | stripTags) : null"
        style="margin-left: 10px; margin-right: 10px; width: 20px; height: 20px; font-size: 20px"
      >
        {{ (element | itemFn: column.value | async) || element[column.id] }}
      </mat-icon>

      <div
        *ngSwitchCase="'value'"
        matTooltipClass="multiline-tooltip"
        [matTooltip]="column?.tooltip ? ((element | itemFn: column.tooltip | async) || '' | stripTags) : null"
        [innerHtml]="(element | itemFn: column.value | async) || element[column.id] | safe: 'html'"
      ></div>

      <ng-container *ngSwitchCase="'link'">
        <a
          *ngIf="element | itemFn: $any(column)?.active | async; else inactiveLink"
          matTooltipClass="multiline-tooltip"
          [matTooltip]="column?.tooltip ? ((element | itemFn: column.tooltip | async) || '' | stripTags) : null"
          [innerHtml]="(element | itemFn: column.value | async) || element[column.id] | safe: 'html'"
          [href]="element | itemFn: $any(column).href | async | safe: 'url'"
          [target]="$any(column)?.target || '_blank'"
          (click)="$event.stopImmediatePropagation()"
        ></a>
        <ng-template #inactiveLink>
          <div
            matTooltipClass="multiline-tooltip"
            [matTooltip]="column?.tooltip ? ((element | itemFn: column.tooltip | async) || '' | stripTags) : null"
            [innerHtml]="element | itemFn: column.value | async | safe: 'html'"
          ></div>
        </ng-template>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row mat-header-row *matHeaderRowDef="columns | pluck: 'id'"></tr>
  <tr mat-row *matRowDef="let element; columns: columns | pluck: 'id'" (click)="rowClick.emit(element)"></tr>
</table>
<ng-content select="[after]"></ng-content>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div *ngIf="showFooter" class="p1 flex items-center justify-between" style="background-color: #f5f5f5">
  <button mat-button *ngIf="false">
    <mat-icon class="mr1">arrow_back_ios</mat-icon>
    <span class="mr1">Back</span>
  </button>
  <p class="mat-caption" style="margin-left: 10px; margin-bottom: 0">
    {{
      totalItems
        ? (data?.length ? '1 - ' + data.length + ' of ' : '') + totalItems + ' Results'
        : data?.length
        ? 'Results 1 - ' + data.length
        : 'There are no results for the given query.'
    }}
  </p>
  <button mat-button *ngIf="showAllLoaded" (click)="loadMore.next()" [disabled]="reachedEnd">
    <span class="ml1">{{ reachedEnd ? 'All Items Loaded' : 'Load More' }}</span>
    <mat-icon class="ml1" *ngIf="!reachedEnd">arrow_forward_ios</mat-icon>
  </button>
  <ng-content select="[footer-end]"></ng-content>
</div>
<ng-content select="[bottom]"></ng-content>
