import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '@greco/identity-users';
import type { SignaturePad } from 'angular2-signaturepad';
import { SignatureService } from '../../services';

@Component({
  selector: 'greco-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit, AfterViewInit {
  constructor(private signatureSvc: SignatureService) {}

  @Input() user!: User | undefined;
  @Input() forPurchase!: boolean;
  @Input() reuseLast = false;

  @Output() signatureChange = new EventEmitter<string>();

  @ViewChild('widthDiv') widthDiv!: ElementRef;
  @ViewChild('sigPad') signaturePad!: SignaturePad;

  signaturePadOptions: any;
  saving = false;
  existingSig = false;
  savedSig = '';
  sameSig = false;
  justSaved = true;
  cleared = true;

  async ngOnInit() {
    if (this.user) {
      this.savedSig = (await this.signatureSvc.getOne(this.user.id)).signature;
      if (this.savedSig) this.existingSig = true;
      if (!this.forPurchase || this.reuseLast) this.reuseSignature();
    } else if (this.forPurchase) this.emitSignature('');
  }

  ngAfterViewInit() {
    const width = this.widthDiv.nativeElement.offsetWidth;
    this.signaturePadOptions = { minWidth: 1, canvasWidth: width, canvasHeight: 150 };
    if (this.forPurchase) this.emitSignature('');
  }

  drawComplete() {
    if (this.user) {
      const signature = this.signaturePad.toDataURL();
      if (signature === this.savedSig) this.sameSig = true;
      else this.sameSig = false;
      this.cleared = false;
      if (this.forPurchase) this.emitSignature(signature);
    }
  }

  clearSignature() {
    this.signaturePad?.clear();
    this.sameSig = false;
    this.cleared = true;
    if (this.forPurchase) this.emitSignature('');
  }

  async saveSignature() {
    this.saving = true;
    try {
      if (this.user) {
        this.savedSig = this.signaturePad.toDataURL();
        if (this.existingSig) await this.signatureSvc.update(this.user.id, this.savedSig);
        else await this.signatureSvc.create({ userId: this.user.id, signature: this.savedSig });
        this.existingSig = true;
        this.sameSig = true;
        this.justSaved = true;
      }
    } catch (err) {
      console.log(err);
    }
    this.saving = false;
  }

  getCanvasContext() {
    const sigPad = (this.signaturePad as any).elementRef.nativeElement as Element;
    const sigPadCanvas = sigPad?.querySelectorAll('canvas');
    const canvas = sigPadCanvas ? sigPadCanvas[0] : null;
    const context = canvas?.getContext('2d');
    return context || null;
  }

  async reuseSignature() {
    if (this.user) {
      this.clearSignature();
      const context = this.getCanvasContext();

      const image = new Image();
      image.src = this.savedSig;
      context?.drawImage(image, 0, 0);
      this.sameSig = true;

      if (this.justSaved && context) {
        this.savedSig = (await this.signatureSvc.getOne(this.user.id)).signature;
        image.src = this.savedSig;
        context.drawImage(image, 0, 0);
        this.justSaved = false;
      }
    }

    this.cleared = false;
    if (this.forPurchase) this.emitSignature(this.savedSig);
  }

  emitSignature(signature: string) {
    this.signatureChange.emit(signature);
  }

  drawSignature() {
    this.clearSignature();
    const canvas = this.getCanvasContext();

    if (canvas && this.user) {
      canvas.font = '42px Brush Script MT';
      canvas?.fillText(this.user.displayName || this.user.friendlyName || '', 35, 115);
      this.cleared = false;
      this.drawComplete();
    }
  }
}
