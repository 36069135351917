<ng-container *grecoLet="frozenSubscriptions$ | async as frozenSubscriptions">
  <greco-alert
    *ngIf="frozenSubscriptions?.length"
    [config]="{
      title: 'Your membership is currently frozen',
      icon: 'warning',
      description:
        'You may not be able to access all our services while your membership is frozen. To unfreeze, please contact our team.',
      type: $any('WARN')
    }"
  >
  </greco-alert>
</ng-container>
