import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommunityAgreement } from '@greco/community-agreements';
import { ViewCommunityAgreementDialog } from '@greco/ngx-booking-events';
import { CommunityAgreementsService, CommunityAgreementsUsageService } from '@greco/ngx-community-agreements';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ProductAgreementAddonsService } from '../../../../services';

@Component({
  selector: 'greco-agreement-configuration-form',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: AgreementFormComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: AgreementFormComponent, multi: true },
  ],
})
export class AgreementFormComponent implements ControlValueAccessor, Validator {
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private agreementSvc: CommunityAgreementsService,
    private agreementUsageSvc: CommunityAgreementsUsageService,
    private productAgreementSVC: ProductAgreementAddonsService
  ) {}

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string>();
  @Input() communityId = '';

  @PropertyListener('addonId') private _addonId$ = new ReplaySubject<string>();
  @Input() addonId? = '';

  @Output() usage = new EventEmitter();
  @Output() deleteUsage = new EventEmitter();

  refresh$ = new BehaviorSubject(null);

  @PropertyListener('selectedProductAgreements') selectedAgreements$ = new BehaviorSubject<CommunityAgreement[]>([]);
  selectedProductAgreements: CommunityAgreement[] = [];
  agreementIds: string[] = [];

  agreementUsages$ = this._addonId$.pipe(
    switchMap(async addonId => {
      if (addonId) {
        return await this.productAgreementSVC.getManyByProdAddonId(addonId);
      }
      return;
    }),
    map(usage => usage?.map(use => use.agreement) || []),
    tap(agr => {
      agr?.forEach(agreement => {
        agreement ? (this.selectedProductAgreements = [...this.selectedProductAgreements, agreement]) : {};
      });
      this.usage.emit(agr);
    })
  );

  agreements$ = combineLatest([this._communityId$, this.selectedAgreements$]).pipe(
    switchMap(async ([communityId, usages]) => {
      const result = await this.agreementSvc.getMany(communityId);
      const options: CommunityAgreement[] = [];
      result.forEach(agr => {
        if (!usages.some(a => a.id === agr.id)) {
          options.push(agr);
        }
      });
      return options;
    })
  );

  addAgreement(agreement: CommunityAgreement) {
    this.selectedProductAgreements = [...this.selectedProductAgreements, agreement];
    this.usage.emit(this.selectedProductAgreements);
  }

  deleteAgreementUsage(agreement: CommunityAgreement) {
    this.selectedProductAgreements = this.selectedProductAgreements.filter(agr => agr != agreement);
    this.usage.emit(this.selectedProductAgreements);
  }

  async previewAgreement(agreement: CommunityAgreement) {
    this.dialog.open(ViewCommunityAgreementDialog, {
      data: { commAgreement: { commAgreement: agreement } },
      width: '750px',
      maxWidth: '90%',
    });
  }

  valueChanged(_text: string) {
    //
  }

  writeValue(_obj: any): void {
    //
  }
  registerOnChange(_fn: any): void {
    //
  }

  registerOnTouched(_fn: any): void {
    //
  }

  validate(_: AbstractControl): ValidationErrors | null {
    //
    return null;
  }
}
