import { Filter, FilterOptions } from './filter';

export class TextFilter extends Filter {
  type: 'text' = 'text';

  constructor(typeName: string, options: Omit<FilterOptions, 'type'>) {
    super(typeName, { ...options, type: 'text' });
  }

  getValueOptions(search?: string): string[] {
    return search ? [search] : [];
  }

  getValueLabel(value: string): string {
    return value;
  }

  serializeValue(value: string): string {
    return value;
  }

  deserializeValue(serializedValue: string): string {
    return serializedValue;
  }
}

export function BuildTextFilter(typeName: string, options: Omit<FilterOptions, 'type'>) {
  return class extends TextFilter {
    constructor() {
      super(typeName, options);
    }
  };
}
