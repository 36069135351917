<ng-container *grecoLet="view === 'account' ? ['your', 'you'] : ['their', 'them'] as person">
  <mat-chip-list>
    <mat-chip
      *ngIf="link.canUsePerks"
      matTooltip="Ability to use {{ person![0] }} perks while making purchases/bookings"
      >Use {{ person![0] }} perks</mat-chip
    >
    <mat-chip matTooltip="Ability to book {{ person![1] }} into events">Book</mat-chip>
    <mat-chip matTooltip="Ability to purchase for {{ person![1] }}">Purchase</mat-chip>

    <!-- Last Item -->
    <mat-chip
      style="background-color: var(--primary-color)"
      *ngIf="(view === 'account' || link.status === 'PENDING') && canUpdate && link.account?.email"
    >
      <button mat-icon-button (click)="add()">
        <mat-icon style="color: white; font-size: 24px; width: 24px; height: 24px; margin-bottom: 0">edit</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-list>
</ng-container>
