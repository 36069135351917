import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Calendar, CalendarSecurityResource, CalendarSecurityResourceAction } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { CalendarService } from '../../services';

@Component({
  selector: 'greco-calendar-details',
  templateUrl: './calendar-details.component.html',
  styleUrls: ['./calendar-details.component.scss'],
})
export class CalendarDetailsComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredStaff: Observable<User[]>;

  staffCtrl = new FormControl();
  managerCtrl = new FormControl([]);

  staffAssignments: User[] = [];
  managerEmails: string[] = [];

  @ViewChild('staffInput') staffInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('managerInput') managerInput: ElementRef<HTMLInputElement> | undefined;

  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private calendarSvc: CalendarService,
    private comSecSvc: CommunitySecurityService
  ) {
    this.filteredStaff = this.staffCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(300),
      switchMap(async (qry: string | null) => {
        if (!qry) return [];

        const excludedUsers = this.staffAssignments.map(staff => staff.id);
        return await this.calendarSvc.paginateStaff(qry, excludedUsers);
      })
    );
  }

  @Input() community!: Community;

  private _calendar$ = new BehaviorSubject<Calendar | null>(null);
  @Input() set calendar(calendar) {
    this._calendar$.next(calendar);
    setTimeout(() => {
      this.resetDetails();
    });
  }
  get calendar() {
    return this._calendar$.value;
  }

  saving = false;
  validEmail = true;

  canUpdate$ = this._calendar$.pipe(
    switchMap(async calendar =>
      calendar
        ? await this.comSecSvc.hasAccess(
            calendar.communityId,
            CalendarSecurityResource.key,
            CalendarSecurityResourceAction.UPDATE
          )
        : false
    )
  );

  resetValue: any = {
    calendarDetails: {
      title: '',
      group: null,
      icon: null,
      private: false,
      image: null,
    },
    staffAssignments: [],
    managerEmails: [],
  };

  detailsForm = this.formBuilder.group({
    calendarDetails: [null, [Validators.required]],
    staffAssignments: [[]],
    managerEmails: [[]],
  });

  saveDetails = async () => {
    this.saving = true;

    try {
      const detailsData = this.detailsForm.value.calendarDetails;

      const calendarId = this.calendar?.id;
      if (!calendarId) return;

      // TODO UPDATE CALENDAR API w/ Image File
      if (detailsData.image?.[0]) {
        const formData = new FormData();
        formData.append('file', detailsData.image[0]);
        if (detailsData.title) formData.append('title', detailsData.title);
        if (detailsData.group !== null) formData.append('group', detailsData.group);
        formData.append('staff', (this.staffAssignments.map(staff => staff.id) || []).join(','));
        formData.append('managers', (this.managerEmails || []).join(','));
        formData.append('private', detailsData.private || false);
        if (detailsData.icon) formData.append('icon', detailsData.icon);
        formData.append('updateImage', '' + !detailsData.image?.[0].name.startsWith('https://storage.googleapis.com/'));

        this.calendar = await this.calendarSvc.update(this.community.id, calendarId, formData);
      } else {
        this.calendar = await this.calendarSvc.updateRemoveImage(this.community.id, calendarId, {
          staff: (this.staffAssignments.map(staff => staff.id) || []).join(','),
          managers: (this.managerEmails || []).join(','),
          private: detailsData.private || false,
          title: detailsData.title,
          group: detailsData.group,
          icon: detailsData.icon,
        });
      }

      this.snacks.open('Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }

    this.saving = false;
  };

  resetDetails() {
    this.resetValue = {
      calendarDetails: {
        title: this.calendar?.title || null,
        group: this.calendar?.group || null,
        icon: this.calendar?.icon,
        private: this.calendar?.private || false,
        image: this.calendar?.imageUrl ? [new File([], this.calendar?.imageUrl?.split('images/').pop() || '')] : null,
      },
      staffAssignments: this.calendar?.staff || [],
      managerAssignments: this.calendar?.managers || [],
    };

    this.staffAssignments = this.resetValue.staffAssignments;
    this.managerEmails = this.resetValue.managerAssignments;
    this.detailsForm.reset(this.resetValue);
    this.detailsForm.markAsPristine();

    if (this.calendar) this.detailsForm.enable();
    else this.detailsForm.disable();
  }

  removeStaff(user: User): void {
    const index = this.staffAssignments.indexOf(user);
    if (index >= 0) {
      this.staffAssignments.splice(index, 1);
      this.staffAssignments = [...this.staffAssignments];
      this.detailsForm.setValue({
        ...this.detailsForm.value,
        staffAssignments: this.staffAssignments,
      });
    }
  }

  selectedStaff(event: MatAutocompleteSelectedEvent): void {
    this.staffAssignments = [...this.staffAssignments, event.option.value];
    this.detailsForm.setValue({
      ...this.detailsForm.value,
      staffAssignments: this.staffAssignments,
    });
    if (this.staffInput) this.staffInput.nativeElement.value = '';
    this.staffCtrl.setValue(null);
  }

  selectedManager(email: string) {
    this.validEmail = this.emailValidator(email);
    if (!email || !this.validEmail) return;

    this.managerEmails = [...this.managerEmails, this.managerCtrl.value];
    this.detailsForm.setValue({
      ...this.detailsForm.value,
      managerEmails: this.managerEmails,
    });

    if (this.managerInput) this.managerInput.nativeElement.value = '';
    this.managerCtrl.setValue(null);
    this.snacks.open('Email added!', 'Ok', { duration: 1500, panelClass: 'mat-primary' });
  }

  removeManager(email: string): void {
    const index = this.managerEmails.indexOf(email);
    this.managerEmails.splice(index, 1);
    this.managerEmails = [...this.managerEmails];
    this.detailsForm.setValue({
      ...this.detailsForm.value,
      managerEmails: this.managerEmails,
    });

    this.snacks.open('Email removed!', 'Ok', { duration: 1500, panelClass: 'mat-primary' });
  }

  emailValidator(email: string): boolean {
    if (email) {
      const matches = email.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      );

      return !!matches;
    } else return false;
  }
}
