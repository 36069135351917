import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';
import { ResourceAvailabilityStatus } from '@greco/booking-events';

@Component({
  selector: 'greco-availability-status',
  templateUrl: './availability-status.component.html',
  styleUrls: ['./availability-status.component.scss'],
})
export class AvailabilityStatusComponent {
  available = ResourceAvailabilityStatus.AVAILABLE;
  tentative = ResourceAvailabilityStatus.TENTATIVE;
  busy = ResourceAvailabilityStatus.BUSY;

  @Input() availability!: ResourceAvailabilityStatus;

  private _large = false;
  @HostBinding('class.large') @Input() set large(value: any) {
    this._large = coerceBooleanProperty(value);
  }
  get large() {
    return this._large;
  }
}
