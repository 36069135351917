<mat-chip-list *ngIf="status$ | async as status">
  <mat-chip
    [color]="status === 'FAILED' ? 'warn' : 'primary'"
    [selected]="status === 'FAILED' || status === 'REFUNDED'"
    [matTooltip]="
      status === 'FAILED'
        ? purchase.failureReason || ''
        : status === 'REFUNDED'
        ? purchase.payment?.modified
          ? (purchase.payment?.modified | date : 'full') || ''
          : ''
        : status === 'VOIDED'
        ? ((purchase.voidedDate | date : 'full') || '') +
            (purchase.voidedReason ? ' (' + purchase.voidedReason + ')' : '') || ''
        : ''
    "
  >
    <ng-container *ngIf="status.startsWith('PROCESSING'); else normalStatus">
      <mat-icon class="processing" *ngIf="!(purchase.payment?.status === 'DELAYED_PROCESSING')"> loop </mat-icon>
      <span>{{ purchase.payment?.status === 'DELAYED_PROCESSING' ? 'Waiting for Bank Transfer' : 'Processing' }}</span>
    </ng-container>

    <ng-template #normalStatus>
      <ng-container [ngSwitch]="status">
        <mat-icon *ngSwitchCase="'FAILED'">error</mat-icon>
        <mat-icon *ngSwitchCase="'VOIDED'">block</mat-icon>
        <mat-icon *ngSwitchCase="'REFUNDED'">undo</mat-icon>
        <mat-icon *ngSwitchCase="'PAID'">check</mat-icon>
      </ng-container>

      <span>{{ status | titlecase }}</span>
    </ng-template>
  </mat-chip>
</mat-chip-list>
