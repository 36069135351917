import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccountLinkStatus } from '@greco/account-linking';
import { UserService } from '@greco/ngx-identity-auth';
import { AddonsService, ProductConditionService } from '@greco/ngx-sales-products';
import { SubscriptionsService } from '@greco/ngx-sales-subscriptions';
import { PropertyListener } from '@greco/property-listener-util';
import { AddonType, Product, ProductVariant, UserAvailabilityAddon } from '@greco/sales-products';
import { AccountLinkingService } from '@greco/web-account-linking';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'greco-product-purchase-buttons',
  templateUrl: './product-purchase-buttons.component.html',
  styleUrls: ['./product-purchase-buttons.component.scss'],
})
export class ProductPurchaseButtonsComponent {
  signedOutMockContext = {
    productPurchases: { id: 1 },
    categoryPurchases: { id: 4 },

    productSubscriptions: { id: 5 },
    categorySubscriptions: { id: 9 },
  };
  constructor(
    private router: Router,
    private userSvc: UserService,
    private cartService: CartService,
    private subscriptionSvc: SubscriptionsService,
    private addonSvc: AddonsService,
    private conditionSvc: ProductConditionService,
    private linkSvc: AccountLinkingService
  ) {}

  isSignedIn$ = this.userSvc.isSignedIn$();

  @PropertyListener('product') private _product$ = new BehaviorSubject<Product | null>(null);
  @Input() product!: Product;
  @Input() selectedVariant!: ProductVariant;

  @PropertyListener('selectedVariant') private _variant$ = new ReplaySubject<ProductVariant | null>(1);

  @PropertyListener('userId') private _userId$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() userId?: string;
  @Input() disabled?: boolean = true;

  linkedAccounts$ = this._userId$.pipe(
    switchMap(async userId => {
      return userId
        ? (await this.linkSvc.getPrivilegeLinksForAccessor(userId))?.filter(
            link => link.status === AccountLinkStatus.ACTIVE
          )
        : [];
    })
  );

  @Output() productBuyClicked = new EventEmitter<void>();

  userConditionContext$ = this.userSvc.getUserId().pipe(
    distinctUntilChanged(),
    switchMap(userId => this.conditionSvc.getConditionContext(userId)),
    shareReplay(1)
  );

  availablityExtension$ = this._product$.pipe(
    map(product => product?.id),
    distinctUntilChanged(),

    switchMap(async id => (id ? await this.addonSvc.getOneByType(id, AddonType.UserAvailability) : null)),
    map(extension => (extension?.enabled ? (extension as UserAvailabilityAddon) : null)),
    shareReplay(1)
  );

  conditionCanBuy$ = combineLatest([this.userConditionContext$, this.availablityExtension$, this.isSignedIn$]).pipe(
    map(([context, extension, isSignedIn]) => {
      if (!isSignedIn) context = this.signedOutMockContext;
      if (!context) return { result: false, errors: ['No user retrieved'], messages: [] };
      if (!extension) return { result: true, errors: [], messages: [] };

      return this.conditionSvc.evaluateAll(extension, context);
    })
  );

  async addToCart(variant: ProductVariant) {
    this.cartService.add(variant);
  }

  async buyNow(variant: ProductVariant) {
    await this.router.navigate(['shop/checkout'], {
      queryParams: {
        items: variant.id,
        user: this.userId || null,
        productName: this.product.title,
        productId: this.product.id,
      },
    });
    this.productBuyClicked.emit();
  }
}
