<h2>Update Card Expiry</h2>

<form [formGroup]="form">
  <div style="display: flex; gap: 8px">
    <mat-form-field appearance="standard" style="width: 120px">
      <mat-label>Month</mat-label>
      <input matInput type="number" max="12" min="1" formControlName="month" />
    </mat-form-field>

    <mat-form-field appearance="standard" style="width: 120px">
      <mat-label>Year</mat-label>
      <input matInput type="number" max="99" min="0" formControlName="year" />
    </mat-form-field>
  </div>
</form>

<div style="display: flex; align-items: center">
  <button mat-stroked-button style="flex: 1; margin-right: 16px" [disabled]="saving" (click)="close()">
    <span>Cancel</span>
  </button>

  <button
    mat-flat-button
    color="primary"
    style="flex: 1"
    [disabled]="!form.dirty || !form.valid || saving"
    (click)="save()"
  >
    <span>Confirm</span>
    <mat-icon *ngIf="saving" class="processing">loop</mat-icon>
  </button>
</div>
