<ng-container *grecoLet="dashboardTilesSvc.tileGroups$ | async as tileGroups">
  <div class="tiles-section-header" [class.no-favorites-tiles]="(dashboardFavoritesSvc.entries$ | async)?.length === 0">
    <div class="header-text">
      <h2>Your Community</h2>
      <!-- <p>What's happening & more!</p> -->
    </div>

    <a mat-button class="location-picker" (click)="select.open()">
      <mat-icon color="accent">location_on</mat-icon>

      {{ (dashboardBookingsSvc.community$ | async)?.name }}

      <mat-select
        #select
        [value]="dashboardBookingsSvc.community$ | async"
        (selectionChange)="dashboardBookingsSvc.community$.next($event.value)"
      >
        <mat-option *ngFor="let community of dashboardBookingsSvc.communities$ | async" [value]="community">
          {{ community.name }}
        </mat-option>
      </mat-select>
    </a>
  </div>

  <div *ngIf="tileGroups?.length; else noTiles" class="tile-groups">
    <div *ngFor="let group of tileGroups" class="tile-group">
      <div class="tile-group-header">
        <h3 style="color: dimgrey; margin-bottom: 0; font-size: 17px; display: flex; gap: 4px">
          <mat-icon *ngIf="group.icon"> {{ group.icon }} </mat-icon> {{ group.name }}
        </h3>
      </div>

      <div class="tiles">
        <a class="tile" *ngFor="let tile of group.tiles" [href]="tile.url">
          <img [src]="tile.imageUrl ? tile.imageUrl : 'assets/greco/placeholder.png'" />
          <p>
            <strong> {{ tile.label }} </strong>
          </p>
          <!-- <button
            class="favourite-button"
            [class.favorite]="favorites?.has($any(tile.id))"
            mat-icon-button
            (click)="$event.preventDefault(); (favorites?.has($any(tile.id))
        ? dashboardFavoritesSvc.unfavorite($any(tile.id))
        : dashboardFavoritesSvc.addFavorite($any(tile.id), {
          title: $any(tile?.label),
          imageUrl: $any(tile.imageUrl),
          url: $any(tile.url)
        }));"
          >
            <mat-icon>{{ favorites?.has($any(tile.id)) ? 'favorite' : 'favorite'}}</mat-icon>
          </button> -->
        </a>
      </div>
    </div>
  </div>

  <ng-template #noTiles>
    <div class="tile-groups">
      <p style="color: dimgray; text-align: center; font-style: italic; margin-top: 8px">
        Check back here later for awesome events and more!
      </p>
    </div>
  </ng-template>
</ng-container>
