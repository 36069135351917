<ng-container *ngIf="canRead$ | async">
  <greco-collapsible-section
    #section
    *grecoLet="assignments$ | async as assignments"
    [header]="{ title: 'Assignments', icon: 'category' }"
    [disabled]="coupon.allProducts"
    [expanded]="!coupon.allProducts"
  >
    <div *ngIf="canCreate$ | async" style="display: flex; flex-direction: row" header>
      <greco-add-assignment-button
        *ngIf="!coupon.allProducts"
        [communityId]="coupon.community?.id || null"
        [couponId]="coupon.id"
        (click)="$event.stopImmediatePropagation()"
        (refresh)="refresh$.next(null)"
      ></greco-add-assignment-button>

      <div *ngIf="coupon.allProducts" style="display: flex; align-items: center; padding: 0 8px">
        This coupon is assigned to all products
      </div>
    </div>

    <greco-filter-bar
      *ngIf="assignments?.length"
      [hideSeparator]="true"
      [filterOptions]="filterOptions"
      style="position: sticky; top: 56px; z-index: 10; background-color: var(--background-color, white)"
      (changed)="filters$.next($event[1]); onFilterApplied()"
    >
    </greco-filter-bar>

    <greco-table [data]="assignments || []" [loading]="loading" [highlight]="true">
      <mat-icon *grecoTableCol="''; fitContent: true; let assignment" [matTooltip]="assignment.id">
        <span *ngIf="assignment.type == 'product'">storefront</span>
        <span *ngIf="assignment.type == 'saleCategory'">category</span>
      </mat-icon>

      <p *grecoTableCol="'Title'; let assignment">
        <span *ngIf="assignment.type == 'product'" [matTooltip]="assignment.product.id">
          {{ assignment.product.title | titlecase }}
        </span>
        <span *ngIf="assignment.type == 'saleCategory'" [matTooltip]="assignment.saleCategory.id">
          {{ assignment.saleCategory.label | titlecase }}
        </span>
      </p>

      <p *grecoTableCol="'Type'; let assignment">
        <span *ngIf="assignment.type == 'product'"> {{ assignment.type | titlecase }} </span>
        <span *ngIf="assignment.type == 'saleCategory'">Sales Category</span>
      </p>

      <p *grecoTableCol="'Assigned'; let assignment">{{ assignment.created | date }}</p>

      <ng-container *grecoTableCol="''; let assignment; fitContent: true; stickyEnd: true">
        <ng-container *grecoLet="canUpdate$ | async as canUpdate">
          <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!canUpdate">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <button
              *grecoLet="canUpdate"
              mat-menu-item
              (click)="
                _removeAssignment(
                  assignment.id,
                  assignment.type === 'saleCategory'
                    ? assignment.saleCategory.label
                    : assignment.type === 'product'
                    ? assignment.product.title
                    : ''
                );
                $event.stopImmediatePropagation()
              "
            >
              <mat-icon>delete</mat-icon>
              <span>Remove Assignment</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-container>
    </greco-table>

    <mat-paginator
      *ngIf="paginationMeta?.totalItems"
      showFirstLastButtons
      [length]="paginationMeta!.totalItems || 0"
      [pageSize]="paginationMeta!.itemsPerPage || 10"
      [pageSizeOptions]="[10, 20, 50]"
      (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
    ></mat-paginator>
  </greco-collapsible-section>
</ng-container>
