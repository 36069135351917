import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import type { FundUserBalanceDto, PurchasePreview, UserBalanceDto } from '@greco/nestjs-sales-purchases';
import { UserService } from '@greco/ngx-identity-auth';
import { UserBalance, UserBalanceTransaction } from '@greco/sales-purchases';

@Injectable()
export class UserBalanceService {
  constructor(private userService: UserService, private http: HttpClient) {}

  async getUserBalance(userId?: string) {
    userId = userId || (await toPromise(this.userService.getUserId()));
    return toPromise(this.http.get<UserBalance[]>('/api/user-balance/' + userId));
  }

  // @Post()
  async createBalanceTransaction(dto: UserBalanceDto): Promise<UserBalanceTransaction> {
    return await toPromise(this.http.post<UserBalanceTransaction>(`/api/user-balance`, dto));
  }

  async getUserBalanceHistory(accountId: string, userId?: string, pagination?: Partial<PaginatedQueryParams>) {
    userId = userId || (await toPromise(this.userService.getUserId()));

    return toPromise(
      this.http.get<PaginatedDto<UserBalanceTransaction>>(
        '/api/user-balance/' + userId + '/' + accountId + '/history',
        {
          params: {
            page: (pagination?.page || 1).toString(),
            limit: (pagination?.limit || 10).toString(),
          },
        }
      )
    );
  }

  async previewFundUserBalance(dto: FundUserBalanceDto) {
    return await toPromise(this.http.post<PurchasePreview>(`/api/user-balance/fund`, dto));
  }
}
