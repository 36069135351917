<greco-filter-bar [hideSeparator]="true" [filterOptions]="filterOptions" (changed)="filters$.next($event[1])">
  <button
    *ngIf="canCreateTemplate$ | async"
    mat-stroked-button
    color="primary"
    style="height: 32px; line-height: 16px"
    (click)="createEventTemplates()"
  >
    <mat-icon>add</mat-icon>
    <span>New Template</span>
  </button>
</greco-filter-bar>

<greco-table
  [data]="(eventTemplates$ | async) || []"
  [highlight]="true"
  [loading]="loading"
  (rowClick)="openEventTemplates($event)"
>
  <mat-icon *grecoTableCol="''; let eventTemplate; fitContent: true;" [matTooltip]="eventTemplate.id"
    >edit_calendar</mat-icon
  >

  <p *grecoTableCol="'Title'; let eventTemplate">{{eventTemplate.title}}</p>

  <mat-chip-list *grecoTableCol="'Tags'; let eventTemplate">
    <mat-chip *ngFor="let tag of eventTemplate.tags"> {{ tag.label }} </mat-chip>
  </mat-chip-list>

  <mat-chip-list *grecoTableCol="'Resources'; let eventTemplate">
    <mat-chip *ngFor="let resource of eventTemplate.resourceAssignments | pluck: 'resource'" style="padding-left: 6px">
      <ng-container *ngIf="resource; else noResource;">
        <!-- Photo URL -->

        <div *ngIf="resource?.photoURL || resource?.type === 'PERSON'; else noPhoto" style="width: 24px; height: 24px">
          <greco-smart-img [src]="resource?.photoURL || 'assets/lf3/icon_square_pad.png'" [round]="true">
          </greco-smart-img>
        </div>

        <ng-template #noPhoto>
          <mat-icon>
            {{ { PERSON: 'account_circle', ROOM: 'chair', OTHER: 'texture', ZOOM: 'videocam' }[resource?.type + ''] }}
          </mat-icon>
        </ng-template>

        <!-- Name -->
        <span style="margin-left: 6px">{{ resource.name }}</span>
      </ng-container>
      <ng-template #noResource>
        <p style="margin-left: 6px; margin-top: 12px">Resource Needed</p>
        <mat-icon style="margin-left: 6px">pending_actions</mat-icon>
      </ng-template>
    </mat-chip>
  </mat-chip-list>

  <!-- Options -->
  <ng-container *ngIf="canDeleteTemplate$ | async">
    <ng-container *grecoTableCol="''; let eventTemplate; fitContent: true; stickyEnd: true">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <a *ngIf="eventTemplate" mat-menu-item (click)="deleteEventTemplate(eventTemplate)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </a>
      </mat-menu>
    </ng-container>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
