import { Component, FactoryProvider, Inject, InjectionToken, Input, OnDestroy, Optional, Type } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Route, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { GrecoRouteModifier } from '../../routing';
import { TabsBreadcrumbService } from './tabs-breadcrumb.service';

export const TAB_DEPENDENCIES = new InjectionToken<any>('TAB_DEPENDENCIES');

@Component({
  selector: 'greco-tabs-breadcrumb-layout',
  templateUrl: './tabs-breadcrumb.component.html',
  styleUrls: ['./tabs-breadcrumb.component.scss'],
  providers: [TabsBreadcrumbService]
})
export class TabsBreadcrumbLayoutComponent implements OnDestroy {
  @Input() pageTitle: string;
  @Input() tabs: Tab[] = [];

  tabs$ = new BehaviorSubject<Tab[]>([]);
  crumbs$ = new BehaviorSubject<Tab[]>([]);
  crumbsActive$ = this.crumbs$.pipe(map(crumbs => crumbs?.length > 1));

  private _onDestroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breadCrumbService: TabsBreadcrumbService,
    @Optional() @Inject(TAB_DEPENDENCIES) private deps: any
  ) {
    if (this.route.routeConfig.data?.title) {
      this.pageTitle = this.route.routeConfig.data.title;
    }

    combineLatest([
      this.router.events.pipe(
        takeUntil(this._onDestroy$),
        filter(event => event instanceof NavigationEnd)
      ),
      this.breadCrumbService.updated
    ]).subscribe(async () => {
      const [tabs, crumbs] = await Promise.all([
        this.createTabs(this.route, this.route),
        this.createCrumbs(this.route, this.route)
      ]);
      this.tabs$.next(tabs);
      this.crumbs$.next(crumbs);
    });
  }

  static getBasePath(route: ActivatedRoute) {
    return (
      '/' + route.snapshot.pathFromRoot.reduce((acc, path) => [...acc, ...path.url.map(url => url.path)], []).join('/')
    );
  }

  static rootModifier = (title: string): GrecoRouteModifier => route => ({ ...route, data: { ...route.data, title } });
  static childModifier = (
    tab:
      | TabRouterData
      | ((
          tabRoot: ActivatedRouteSnapshot,
          route: Route | ActivatedRouteSnapshot,
          ...params: any[]
        ) => Promise<TabRouterData>)
  ): GrecoRouteModifier => route => ({
    ...route,
    data: { ...route.data, tab },
    children: route.children?.map(child => ({
      ...child,
      data: { ...child.data, ...(child.path === '' ? { tab: { label: null } } : {}) }
    }))
  });

  static provideDeps = (...deps: Type<any>[]): FactoryProvider => {
    return { provide: TAB_DEPENDENCIES, deps, useFactory: (...params: any[]) => params };
  };

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async createTabs(root: ActivatedRoute, route: ActivatedRoute) {
    const tabs: Tab[] = [];
    const basePath = TabsBreadcrumbLayoutComponent.getBasePath(route);

    for (const child of route.routeConfig.children) {
      const data = await this.data(root.snapshot, child);
      if ((data?.label || data?.iconStart || data?.iconEnd) && ('active' in data ? data.active : true)) {
        tabs.push({ ...data, routerLink: [basePath, ...(child.path === '' ? [] : [child.path])] });
      }
    }

    return tabs;
  }

  private async createCrumbs(root: ActivatedRoute, route: ActivatedRoute, crumbs: Tab[] = []) {
    for (const child of route.children) {
      let data = await this.data(root.snapshot, child.snapshot);
      data = this.breadCrumbService.getData(TabsBreadcrumbLayoutComponent.getBasePath(child), data);
      if (data?.label) {
        return this.createCrumbs(root, child, [
          ...crumbs,
          { ...data, routerLink: [TabsBreadcrumbLayoutComponent.getBasePath(child)] }
        ]);
      }
    }

    return crumbs;
  }

  private async data(root: ActivatedRouteSnapshot, route: Route | ActivatedRouteSnapshot) {
    return typeof route.data?.tab === 'function' ? await route.data.tab(root, route, this.deps) : route.data?.tab;
  }
}

export interface Tab {
  label: string;
  active?: boolean;
  routerLink: string[];
  iconStart?: string;
  iconEnd?: string;
}

export interface TabRouterData {
  label: string;
  active?: boolean;
  iconStart?: string;
  iconEnd?: string;
}
