<greco-stats-grid [referenceId]="product.id"></greco-stats-grid>

<!-- Product Details -->
<greco-collapsible-section #section [header]="{ title: 'Product Details', icon: 'info' }" [expanded]="false">
  <form [formGroup]="formGroup">
    <!-- Title -->
    <mat-form-field style="width: 100%">
      <mat-label>Title</mat-label>
      <input matInput type="text" required formControlName="title" [disabled]="true" [readonly]="true" />
    </mat-form-field>

    <!--Sale Category-->
    <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
      <mat-label>Sales Category</mat-label>
      <greco-sale-category-input
        [accountId]="product.community.financeAccountId"
        formControlName="saleCategory"
        [required]="true"
        [disabled]="true"
      ></greco-sale-category-input>
    </mat-form-field>

    <!-- Images -->
    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
      <mat-label>Images</mat-label>
      <greco-image-upload
        [multiple]="true"
        [urls]="initialUrls"
        (click)="formGroup.markAsDirty()"
        formControlName="images"
        [disabled]="true"
      ></greco-image-upload>
    </mat-form-field>

    <!-- Description -->
    <mat-form-field style="width: 100%">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        type="text"
        formControlName="description"
        rows="6"
        [disabled]="true"
        [readonly]="true"
      ></textarea>
    </mat-form-field>

    <!-- Taxes -->
    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
      <mat-label>Taxes</mat-label>
      <greco-account-taxes-picker
        formControlName="taxes"
        [accountId]="product.community.financeAccountId"
        [disabled]="true"
      >
        <mat-checkbox
          #ignoreTaxes
          formControlName="ignoreTaxes"
          [checked]="product.ignoreTaxes"
          color="primary"
          [disabled]="true"
          >No Taxes</mat-checkbox
        >
      </greco-account-taxes-picker>
    </mat-form-field>
  </form>
</greco-collapsible-section>

<!-- Addon Details-->
<ng-container *grecoLet="canManageInventory$ | async as canManageInventory">
  <greco-collapsible-section
    #section
    [header]="{ title: 'Inventory Details', icon: 'info' }"
    [expanded]="true"
    *ngIf="inventoryAddon$ | async as addon"
  >
    <ng-container header>
      <mat-chip
        *ngIf="canManageInventory; else simpleStatus"
        style="display: flex; gap: 4px; align-items: center"
        [matTooltip]="addon.enabled ? 'Disable Addon' : 'Enable Addon'"
        (click)="$event.stopImmediatePropagation(); (!processing ? (updateAddonStatus(addon.id, !addon.enabled)) : {})"
      >
        <mat-checkbox *ngIf="!processing" [checked]="addon.enabled" [disabled]="processing"></mat-checkbox>
        {{addon.enabled ? 'Enabled' :'Disabled'}}
      </mat-chip>
      <ng-template #simpleStatus>
        <mat-chip> {{addon.enabled ? 'Enabled' :'Disabled'}} </mat-chip>
      </ng-template>
    </ng-container>

    <greco-form-save-buttons
      *ngIf="section.expanded && !processing"
      header
      [form]="inventoryFormGroup"
      [saveAction]="save"
      [resetValue]="inventoryResetValue"
      (formReset)="resetInventoryForm(inventoryResetValue) "
    ></greco-form-save-buttons>
    <div style="display: flex; flex-direction: column; gap: 16px">
      <form [formGroup]="inventoryFormGroup">
        <mat-form-field appearance="standard" floatLabel="always">
          <mat-label>Out of Stock Message</mat-label>
          <input
            matInput
            type="text"
            formControlName="outOfStockMessage"
            placeholder="Out of stock"
            [disabled]="!canManageInventory || processing"
            [readonly]="!canManageInventory || processing"
          />
        </mat-form-field>

        <mat-checkbox formControlName="displayStockNumber" [disabled]="!canManageInventory || processing"
          >Display inventory amount in shop</mat-checkbox
        >
      </form>

      <div style="display: flex; flex-direction: column; gap: 16px; position: relative">
        <ng-container *ngIf="addon?.id; else notConfigured">
          <ng-container *grecoLet="variantInventories$ | async as inventories">
            <greco-table *ngIf="inventories; else loading" [data]="inventories || [] | inventory">
              <p *grecoTableCol="'Variant'; let inventory">{{ inventory.variant | variantTitle }}</p>
              <!-- <p *grecoTableCol="'Lifetime Stock'; let inventory">{{ inventory.totalStock }}</p>
          <p *grecoTableCol="'Lifetime Sold'; let inventory">{{ inventory.quantitySold || 0 }}</p> -->
              <p *grecoTableCol="'Available';  fitContent: true; let inventory">
                {{ inventory.availableInventoryCount || 0 }}
              </p>
              <ng-container *ngIf="canManageInventory">
                <ng-container *ngIf="restocking">
                  <p *grecoTableCol="'New Total'; let inventory; fitContent: true; alignRight: true">
                    {{ (inventory.availableInventoryCount || 0) +
                    (inventoryFormGroup.get('inventories')?.value.get(inventory.variantId)?.value || 0) }}
                  </p>
                </ng-container>

                <span *grecoTableCol="''; let inventory; stickyEnd: true; fitContent: true" style="width: 48px">
                  <ng-container
                    *grecoLet="inventoryFormGroup.get('inventories')?.value.get(inventory.variantId) as restockControl"
                  >
                    <button
                      *ngIf="!restockControl"
                      mat-icon-button
                      type="button"
                      color="primary"
                      [disabled]="processing"
                      style="
                        border-radius: 100%;
                        background-color: var(--primary-color);
                        --icon-size: 28px;
                        height: var(--icon-size);
                        width: var(--icon-size);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                      (click)="addRestock(inventory.variantId)"
                    >
                      <span style="color: white; font-size: 12px">+/-</span>
                    </button>
                    <div
                      *ngIf="restockControl"
                      style="display: flex; flex-direction: row; justify-content: space-between; gap: 4px"
                    >
                      <input
                        matInput
                        style="width: 48px; text-align: center"
                        type="number"
                        [formControl]="restockControl"
                        [min]="(inventory.availableInventoryCount || 0) * -1"
                        [disabled]="processing"
                        [readonly]="processing"
                      />
                      <button
                        *ngIf="!processing"
                        mat-icon-button
                        type="button"
                        color="warn"
                        (click)="removeRestock(inventory.variantId)"
                      >
                        <mat-icon
                          style="
                            color: var(--warn-color);
                            --icon-size: 28px;
                            height: var(--icon-size);
                            width: var(--icon-size);
                            font-size: var(--icon-size);
                          "
                          >cancel</mat-icon
                        >
                      </button>
                    </div>
                  </ng-container>
                </span>
              </ng-container>
            </greco-table>
            <ng-template #loading>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </ng-template>
          </ng-container>

          <ng-container *grecoLet="productVariants$ | async as productVariants">
            <ng-container *ngIf="productVariants?.length">
              <button
                mat-button
                style="width: 100%; height: 100%; margin-top: 16px"
                type="button"
                [matMenuTriggerFor]="variantPicker"
                [disabled]="processing"
              >
                <mat-icon
                  style="
                    --icon-size: 20px;
                    height: var(--icon-size);
                    width: var(--icon-size);
                    font-size: var(--icon-size);
                  "
                >
                  add_circle_outline
                </mat-icon>
                <span>Add Variant</span>
              </button>
              <mat-menu #variantPicker>
                <button mat-menu-item *ngFor="let variant of productVariants" (click)="addVariant(variant, addon.id)">
                  <mat-icon>style</mat-icon>
                  <span>{{ variant | variantTitle }}</span>
                </button>
              </mat-menu>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #notConfigured>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          <mat-hint>Auto generating the variant inventories. This may take a few minutes.</mat-hint>
        </ng-template>
        <div style="width: 100%; background: white; position: sticky; bottom: 0; z-index: 20">
          <greco-alert
            *ngIf="inventoryFormGroup.dirty"
            [config]="{
          type: $any('WARN'),
          title: 'Save your changes! Please click the save button above to confirm any changes.'
        }"
          ></greco-alert>
        </div>
      </div>
    </div>
  </greco-collapsible-section>
</ng-container>
