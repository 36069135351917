import { Injectable } from '@angular/core';
import { Tag } from '@greco/booking-events';
import { MultiAutocompleteFilter } from '@greco/ngx-filters';
import { CondOperator, RequestQueryBuilder, SFields } from '@nestjsx/crud-request';
import { TagService } from '../../../services';

@Injectable({ providedIn: 'any' })
export class BookingTagFilter extends MultiAutocompleteFilter {
  constructor(private tagSvc: TagService) {
    super('BookingTagFilter', {
      properties: ['bookingOption.tags.id'],
      label: 'Booking Tag',
      shortLabel: 'Tag',
      description: '',
      canOpen: true,
    });
  }

  public communityId?: string;

  getImageUrl(_value: Tag) {
    return null;
  }

  getAutocompleteHtml(value: Tag): string {
    return value.label;
  }

  getValueLabel(value: Tag | Tag[]): string {
    if ((value as Tag[])?.length) {
      return (value as Tag[])?.map(tag => tag.label).join(', ');
    } else return (value as Tag)?.label;
  }

  async getValueOptions(search?: string): Promise<Tag[]> {
    if (!this.communityId) return [];

    const options = await this.tagSvc.paginate(
      new RequestQueryBuilder().search({
        $and: [{ label: { $contL: search } }],
      }),
      this.communityId,
      { limit: 10 }
    );

    return options.items;
  }

  serializeValue(value: Tag[]): string {
    return value.map(tag => tag.id).join(',');
  }

  async deserializeValue(serializedValue: string) {
    const ids = serializedValue.split(',');
    const tags: Tag[] = [];

    await Promise.all(
      ids.map(async id => {
        const tag = await this.tagSvc.getOne(id);
        if (tag) tags.push(tag);
      })
    );

    return tags?.length ? tags : null;
  }

  getPropertySField(property: string, operator: CondOperator, value: Tag[]): SFields {
    return value?.length ? { [property]: { [operator]: value.map(tag => tag?.id) } } : {};
  }
}
