import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { AppConfig, APP_CONFIG } from '@greco/ngx-app-config';
import { UserService } from '@greco/ngx-identity-auth';
import type { WidgetOptions } from '@typeform/embed';
import { CustomerSupportService } from '../services/customer-support.service';

@Injectable({
  providedIn: 'root',
})
export class HelpTypeformResolver implements Resolve<{ id: string; options: Pick<WidgetOptions, 'hidden'> } | null> {
  constructor(
    private customerSupportService: CustomerSupportService,
    private userService: UserService,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: AppConfig
  ) {}
  async resolve(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Promise<{ id: string; chat?: boolean; options: Pick<WidgetOptions, 'hidden' | 'chat'> } | null> {
    console.log({ data: route.data });
    const formId = (await this.customerSupportService.getCustomerSupportConfig(this.appConfig.name))?.helpTypeform;
    const user = await toPromise(this.userService.user$);
    if (!formId) {
      await this.router.navigateByUrl('/');
      return null;
    }
    return {
      id: formId,
      options: {
        chat: true,
        hidden: {
          user_email: user?.contactEmail || '',
          user_display_name: user?.displayName || '',
          user_friendly_name: user?.friendlyName || '',
          user_phone_number: user?.phoneNumber || '',
        },
      },
    };
  }
}
