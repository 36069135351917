import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event } from '@greco/booking-events2';
import { UserEventsFavoritesService } from '@greco/ngx-booking-events';
import { UserService } from '@greco/ngx-identity-users';
import { map } from 'rxjs/operators';
import { EventService } from '../../services';

@Component({
  selector: 'alt-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnInit {
  constructor(
    private router: Router,
    public favoriteSvc: UserEventsFavoritesService,
    private breakpointObserver: BreakpointObserver,
    private eventSvc: EventService,
    private userSvc: UserService
  ) {}

  @HostBinding('class.loading') loading = true;

  @Input() event?: Event;

  isSmallMobile$ = this.breakpointObserver.observe('(max-width: 399px)').pipe(map(result => result.matches));
  isFavorite$ = this.favoriteSvc.favorites$.pipe(map(favorites => favorites.has(this.event?.title || '')));

  removeFavorite() {
    if (!this.event) return;
    this.favoriteSvc.unfavorite(this.event.title);
  }

  addFavorite() {
    if (!this.event) return;
    this.favoriteSvc.addFavorite(this.event.title, {
      title: this.event.title,
      imageUrl: this.event.imageUrl || this.event.calendar?.imageUrl || 'assets/greco/placeholder.png',
      tags: this.event.tags.map(tag => tag.label + ':' + tag.id),
    });
  }

  @HostListener('click') onClick() {
    this.router.navigate(['workouts-new', this.event?.id]);
  }

  ngOnInit() {
    this.loading = !this.event;
  }

  async joinWaitlist() {
    if (!this.event) return;
    const user = await this.userSvc.getSelf();
    if (!user) return;

    this.eventSvc.joinWaitlistMultiple(this.event?.id, [user.id]);

    this.router.navigate(['workouts-new', this.event?.id]);
  }
}
