import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { ResourceTagService } from '../../services/resource-tag.service';
@Component({
  selector: 'greco-create-tag',
  templateUrl: './create-tag.dialog.html',
  styleUrls: ['./create-tag.dialog.scss'],
})
export class CreateTagDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private tagSvc: ResourceTagService
  ) {}

  private _onDestroy$ = new Subject<void>();

  form = this.formBuilder.group({
    label: ['', Validators.required],
  });

  dialogData: DialogData = {
    title: 'Create Tag',
    subtitle: 'Provide details for the new tag',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        disabled: this.form.errors?.length,
        resultFn: async () => {
          try {
            const tag = await this.tagSvc.createResourceTag({
              label: this.form.value.label,
              communityId: this.data.communityId,
            });

            this.snacks.open('New tag created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
            return tag;
          } catch (err) {
            console.error(err);
            return null;
          }
        },
      },
    ],
  };

  ngOnInit() {
    this.form.valueChanges.pipe(startWith(this.form.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.form.invalid; // || !valid
          return btn;
        }),
      };
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
