import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'greco-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss'],
})
export class EmbedComponent {
  @Input() link?: string;
  @Input() title?: string;

  constructor(private route: ActivatedRoute) {
    this.link = this.route.snapshot.data.link;
    this.title = this.route.snapshot.data.embedTitle;
  }
}
