<greco-simple-dialog [data]="dialogData">
  <ng-container *grecoLet="updatePreview$ | async as preview">
    <form [formGroup]="formGroup">
      <mat-horizontal-stepper labelPosition="bottom" #stepper>
        <mat-step *ngIf="handlerForm">
          <ng-template matStepLabel>{{handlerForm.stepLabel}}</ng-template>
          <div #formContainer></div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Timing</ng-template>
          <div style="display: flex; align-items: flex; gap: 8px; margin-top: 6px">
            <!-- Update Type  -->
            <mat-radio-group formControlName="dateRadioButton" aria-label="Start Subscription: ">
              <mat-radio-button style="margin-left: 16px" color="primary" value="now">Now</mat-radio-button>
              <mat-radio-button style="margin-left: 16px" color="primary" value="endOfPeriod">
                At the end of current period
              </mat-radio-button>
              <mat-radio-button *ngIf="data.mode !== 'user'" style="margin: 10px" color="primary" value="future">
                Custom Date
              </mat-radio-button>
            </mat-radio-group>
            <!-- Update Proration -->
            <mat-checkbox
              style="margin-left: auto; align-self: center"
              color="primary"
              *ngIf="canProrate && data.mode !== 'user'"
              formControlName="prorateUpdate"
              labelPosition="before"
              ><small>Prorate unused to balance</small>
            </mat-checkbox>
          </div>

          <!-- Update Date  -->
          <div>
            <mat-form-field
              *ngIf="formGroup.value.dateRadioButton == 'future'"
              style="margin-top: 6px"
              appearance="fill"
            >
              <mat-label>Choose a date</mat-label>
              <input
                matInput
                [min]="minimalDate"
                [max]="maximalDate"
                [matDatepicker]="picker"
                (click)="picker.open()"
                formControlName="dateSelection"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- Update Details -->
          <div [style.display]="data.mode !== 'user' ? 'block' : 'none'">
            <mat-form-field style="margin-top: 6px" appearance="outline" class="custom">
              <mat-label>Reason</mat-label>
              <mat-select required formControlName="updateDetails">
                <mat-option *ngFor="let reason of updateReasons" [value]="reason">{{ reason }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button mat-stroked-button matStepperNext style="margin-left: auto; margin-top: 16px">
            Continue
            <mat-icon>chevron_right</mat-icon>
          </button>
        </mat-step>

        <!-- Agreements -->
        <mat-step *ngIf="agreements$ | async as agreements" [formGroup]="agreementsForm">
          <ng-template matStepLabel>Agreement{{agreements.length === 1 ? '' : 's'}}</ng-template>
          <div style="display: flex; flex-direction: column; gap: 12px">
            <ng-container *ngFor="let usage of agreements$ | async">
              <greco-collapsible-section
                #agreementEx
                [header]="{ title: usage.agreement?.title || ''}"
                [expanded]="false"
                (click)="$event.stopImmediatePropagation()"
              >
                <div style="padding: 0 8px">
                  <span [innerHtml]="usage.agreement?.text || '' | safe:'html'"></span>
                </div>
                <div style="display: flex; justify-content: center">
                  <div style="width: 98%; border-bottom: 1px solid darkgray"></div>
                </div>

                <div *ngIf="!agreementEx.expanded" subheader style="display: flex; flex-direction: column">
                  <mat-checkbox
                    *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                    [checked]="agreementsForm.get(usage.id)?.value"
                    [required]="true"
                    style="padding-bottom: 8px"
                    color="primary"
                    (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
                    formControlName="{{usage.id}}"
                    ><span style="white-space: normal"
                      >I have read and agree to the terms of the agreement</span
                    ></mat-checkbox
                  >

                  <mat-checkbox
                    *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                    [checked]="agreementsForm.get(usage.id)?.value"
                    [required]="true"
                    style="padding-bottom: 8px"
                    color="primary"
                    formControlName="{{usage.id}}"
                    (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
                    ><span style="white-space: normal"
                      >I have read and agree to the terms of the agreement</span
                    ></mat-checkbox
                  >

                  <ng-container *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'">
                    <div
                      *ngIf="!canLeaveUnsigned && !agreementsForm.get(usage.id)?.value"
                      style="color: var(--warn-color); margin-bottom: 16px"
                    >
                      * Signature Required
                    </div>
                    <i *ngIf="agreementsForm.get(usage.id)?.value">Signed</i>
                  </ng-container>
                </div>

                <mat-checkbox
                  *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
                  [checked]="agreementsForm.get(usage.id)?.value"
                  [required]="true"
                  style="padding: 8px"
                  color="primary"
                  formControlName="{{usage.id}}"
                  (click)="setCheck(usage.id, usage.agreementId);  $event.preventDefault()"
                  ><span style="white-space: normal"
                    >I have read and agree to the terms of the agreement</span
                  ></mat-checkbox
                >

                <mat-checkbox
                  *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
                  [checked]="agreementsForm.get(usage.id)?.value"
                  [required]="true"
                  style="padding: 8px"
                  color="primary"
                  formControlName="{{usage.id}}"
                  (click)="setCheck(usage.id, usage.agreementId); $event.preventDefault()"
                  ><span style="white-space: normal"
                    >I have read and agree to the terms of the agreement</span
                  ></mat-checkbox
                >
                <greco-signature
                  *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'"
                  [user]="data.subscription.user"
                  [forPurchase]="true"
                  formControlName="{{usage.id}}"
                  ngDefaultControl
                  (signatureChange)="setSignature($event, usage.id, usage.agreementId)"
                >
                </greco-signature>
              </greco-collapsible-section>

              <greco-alert
                *ngIf="canLeaveUnsigned"
                [config]="{
                title: 'Signature Required',
                type: $any('WARN'),
                description:
                  (data.subscription.user?.displayName || 'This member') + ' will have to sign this agreement before purchasing other items.'
              }"
              ></greco-alert>
            </ng-container>
          </div>

          <button
            mat-stroked-button
            matStepperNext
            style="margin-left: auto; margin-top: 16px"
            [disabled]="!agreementsForm.valid"
          >
            Continue
            <mat-icon>chevron_right</mat-icon>
          </button>
        </mat-step>

        <mat-step *ngIf="preview">
          <ng-template matStepLabel>Preview</ng-template>
          <div *ngIf="preview?.errors?.length">
            <li *ngFor="let error of preview.errors" style="color: var(--warn-color)">{{error}}</li>
          </div>
          <div *ngIf="preview?.warnings?.length">
            <li *ngFor="let warning of preview.warnings" style="color: #df8436">{{warning}}</li>
          </div>

          <greco-purchase-preview
            [communityId]="communityId!"
            [showTotals]="true"
            [subscription]="$any(preview.subscription)"
            [purchase]="$any(preview.purchase)"
          ></greco-purchase-preview>
        </mat-step>
      </mat-horizontal-stepper>
    </form>

    <button
      mat-flat-button
      [disabled]="!!(!agreementsForm.valid || !formGroup.valid || !handlerFormComponent?.instance?.form?.valid || preview?.errors?.length || ((stepper.steps.length - 1) !== stepper.selectedIndex) || processing)"
      type="button"
      (click)="submitUpdate()"
      color="primary"
      style="margin-left: auto; margin-top: 16px; position: sticky; bottom: 0; z-index: 999"
      [style.display]="(stepper.steps.length - 1) === stepper.selectedIndex ? 'block' : 'none'"
    >
      <span>Confirm Change</span>
      <mat-progress-spinner
        diameter="18"
        mode="indeterminate"
        *ngIf="processing"
        style="filter: grayscale(1) opacity(0.5)"
      ></mat-progress-spinner>
      <mat-icon>check</mat-icon>
    </button>
  </ng-container>
</greco-simple-dialog>
