<div class="greco-contact-picker-container">
  <div *ngIf="value && value.user" style="width: 24px; height: 24px; margin-right: 8px">
    <greco-user-avatar [showOnlineStatus]="false" [user]="value.user"></greco-user-avatar>
  </div>

  <input
    #nativeInput
    #autoTrigger="matAutocompleteTrigger"
    matInput
    type="text"
    [disabled]="disabled"
    [required]="required"
    [matAutocomplete]="auto"
    class="greco-contact-picker-input"
    [placeholder]="placeholder"
    (click)="nativeInput.select()"
    (input)="searchQuery$.next($any($event.target).value)"
    (blur)="touched()"
    [value]="value?.displayName"
  />

  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="optionSelected($event)"
  >
    <mat-option *ngFor="let contact of contacts$ | async" [value]="contact">
      <div style="display: flex; align-items: center">
        <div *ngIf="contact.user" style="width: 24px; height: 24px; margin-right: 8px">
          <greco-user-avatar *ngIf="contact.user" [showOnlineStatus]="false" [user]="contact.user"></greco-user-avatar>
        </div>

        <div>
          <ng-container *ngIf="contact.displayName; else noDisplayName">
            {{ contact.displayName }} <small>({{ contact.email }})</small>
            <small *ngIf="contact.memberNumber">({{ contact.memberNumber }})</small>
          </ng-container>
          <ng-template #noDisplayName>{{ contact.email }}</ng-template>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
