<ng-container *grecoLet="hasAccess$ | async as hasAccess">
  <greco-collapsible-section
    *grecoLet="config$ | async as config"
    #section
    [header]="{ title: 'Automatic Reconciliation', icon: 'settings' }"
    [expanded]="false"
    [disabled]="!hasAccess"
  >
    <mat-chip header *ngIf="config">{{ config.enabled ? 'Active' : 'Disabled' }}</mat-chip>

    <button
      *ngIf="section.expanded && !loading && hasAccess"
      mat-flat-button
      color="primary"
      header
      style="height: 32px"
      (click)="$event.stopImmediatePropagation(); save(config || undefined)"
    >
      Save
    </button>

    <button
      *ngIf="config"
      header
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="$event.stopImmediatePropagation()"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button *ngIf="config" mat-menu-item (click)="changeStatus(config)">
        <mat-icon>{{ config.enabled ? 'archive' : 'unarchive' }}</mat-icon>
        {{ config.enabled ? 'Disable' : 'Enable' }}
      </button>
    </mat-menu>

    <form [formGroup]="form" style="width: 100%">
      <mat-tab-group dynamicHeight>
        <mat-tab label="Schedule Payouts">
          <greco-schedule-input [required]="true" formControlName="schedulePayout"></greco-schedule-input>
        </mat-tab>

        <mat-tab [disabled]="!showTopups">
          <ng-template mat-tab-label>{{ showTopups ? 'Schedule Topups' : '' }}</ng-template>
          <greco-schedule-input [required]="true" formControlName="scheduleTopup"></greco-schedule-input>

          <mat-form-field style="width: 100%" floatLabel="always" appearance="standard">
            <mat-label>Payment Method</mat-label>
            <greco-select-account-payment-method
              [accountId]="account.id"
              (valueChange)="paymentMethodcahnged($event)"
              formControlName="paymentMethod"
            >
            </greco-select-account-payment-method>
          </mat-form-field>
        </mat-tab>
      </mat-tab-group>
    </form>
  </greco-collapsible-section>
</ng-container>
