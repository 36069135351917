import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ClipboardService {
  constructor(private snackbar: MatSnackBar) {}

  copy(text: string, textType = 'Text') {
    try {
      const el = document.createElement('textarea');
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.snackbar.open(textType + ' copied to clipboard', 'Ok', {
        duration: 2500,
        panelClass: 'mat-primary',
      });
    } catch (error) {
      console.error(error);
      this.snackbar.open('Failed to copy ' + textType.toLowerCase() + ' to clipboard.', 'Ok', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
    }
  }
}
