<mat-form-field appearance="outline" style="margin-bottom: 16px">
  <mat-label>Self-Serve Update Candidates</mat-label>
  <greco-product-variant-picker
    [value]="products.value"
    [formControl]="products"
    [communityId]="communityId"
    [onlyProducts]="true"
    [multiselect]="true"
  ></greco-product-variant-picker>
  <mat-hint>
    Select products that will be presented as update options to members with active subscriptions of the current
    product. You may select the current product if you want to allow changing between variants of the current product.
    Note that the selected update candidates must be active and have at least one active variant with recurrence to be
    shown as an option for update.
  </mat-hint>
</mat-form-field>
