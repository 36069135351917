import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PaymentMethod } from '@greco/finance-payments';
import { PaymentMethodFormComponent, UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'greco-payment-method-picker',
  templateUrl: './payment-method-picker.component.html',
  styleUrls: ['./payment-method-picker.component.scss'],
})
export class PaymentMethodPickerComponent {
  @PropertyListener('userId') userId$ = new BehaviorSubject<string | null>(null);
  @Input() userId!: string;
  @Input() cardOnly = false;

  refresh$ = new BehaviorSubject<void>(undefined);
  paymentMethods$: Observable<PaymentMethod[]> = combineLatest([this.userId$, this.refresh$]).pipe(
    map(([id]) => id),
    filter(id => !!id),
    switchMap(id => (id ? this.paymentMethodSvc.getAll(id) : of([]))),
    map(methods => (this.cardOnly ? methods.filter(method => method.model !== 'bank') : methods)),
    tap(methods => {
      if (this.selectedPaymentMethod$.value === null && methods.length > 0) {
        this.selectedPaymentMethod$.next(methods.find(m => m.isDefault) || methods[0]);
      }
    })
  );

  selectedPaymentMethod$ = new BehaviorSubject<PaymentMethod | null>(null);

  addNew = false;
  selectedType: 'card' | 'bank' = 'card';
  saving = false;

  constructor(
    private paymentMethodSvc: UserPaymentMethodService,
    public bottomSheet: MatBottomSheet,
    private userPaymentMethods: UserPaymentMethodService
  ) {}

  async savePaymentMethod(form: PaymentMethodFormComponent) {
    this.saving = true;
    const paymentMethodInfo = await form.save();
    const paymentMethod = await this.userPaymentMethods.create({
      gatewayId: 'stripe',
      userId: this.userId,
      ...paymentMethodInfo,
    });
    this.selectedPaymentMethod$.next(paymentMethod);
    this.refresh$.next();
    this.bottomSheet.dismiss();
    this.addNew = false;
    this.saving = false;
  }
}
