import { User } from '@greco/identity-users';
import { SubscriptionRecurrence } from '.';
import { SubscriptionItem } from './subscription-item';

export enum SubscriptionActionStatus {
  SCHEDULED = 'SCHEDULED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FROZEN = 'FROZEN',
}

export enum SubscriptionActionType {
  START = 'START',
  RENEWAL = 'RENEWAL',
  UPDATE = 'UPDATE',
  CANCELLATION = 'CANCELLATION',
  FREEZE = 'FREEZE',
  UNFREEZE = 'UNFREEZE',
  COMPLETION = 'COMPLETION',
}

export interface SubscriptionAction {
  id: string;
  lockedForProcessing: boolean;
  timestamp: Date;
  type: SubscriptionActionType;
  status: SubscriptionActionStatus;
  subscriptionId: string;
  createdById: string;
  createdBy?: User;
  details?: string;
}

export interface SubscriptionStartAction extends SubscriptionAction {
  type: SubscriptionActionType.START;
  purchaseId?: string;
}

export interface SubscriptionRenewalAction extends SubscriptionAction {
  type: SubscriptionActionType.RENEWAL;
  purchaseId?: string;
}

export interface SubscriptionUpdateAction extends SubscriptionAction {
  type: SubscriptionActionType.UPDATE;
  items?: SubscriptionItem[];
  proration: boolean;
  recurrence: SubscriptionRecurrence;
  purchaseId?: string;
}

export interface SubscriptionCancellationAction extends SubscriptionAction {
  type: SubscriptionActionType.CANCELLATION;
  proration?: boolean;
  refundToCard?: boolean;
}

export interface SubscriptionFreezeAction extends SubscriptionAction {
  type: SubscriptionActionType.FREEZE;
  freezePeriodId: string;
}

export interface SubscriptionUnfreezeAction extends SubscriptionAction {
  type: SubscriptionActionType.UNFREEZE;
  freezePeriodId: string;
}

export interface SubscriptionCompletionAction extends SubscriptionAction {
  type: SubscriptionActionType.COMPLETION;
}
