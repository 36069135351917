import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { BookingOption } from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MinutesPipe } from '../../../../../pipes';

@Component({
  selector: 'greco-booking-option-requirement',
  templateUrl: './booking-option.component.html',
  styleUrls: ['./booking-option.component.scss'],
})
export class BookingOptionRequirementComponent {
  constructor(private matDialog: MatDialog) {}

  @PropertyListener('bookinOption') private _bookingOption$ = new ReplaySubject<BookingOption>();
  @Input() bookinOption!: BookingOption;

  @PropertyListener('requiredBoosts') private _requiredBoosts$ = new ReplaySubject<number>();
  @Input() requiredBoosts!: number;

  readonly title$ = combineLatest([this._bookingOption$, this._requiredBoosts$]).pipe(
    map(([option, requiredBoosts]) => {
      let title = option.title;

      if (option.bookingWindow) {
        title += ` (${new MinutesPipe().transform(option.bookingWindow)})`;
      }

      if (option.price) {
        title += ` | ${new CurrencyPipe('en-CA').transform(option.price / 100)} Booking Fee`;
      }

      if (requiredBoosts) {
        title += ` | ${requiredBoosts} Booking Window Booster${requiredBoosts === 1 ? '' : 's'}`;
      }

      return title;
    })
  );

  async openReviewDialog() {
    const title = await toPromise(this.title$);
    this.matDialog.open(SimpleDialog, {
      maxWidth: 700,
      data: {
        subtitle: title,
        showCloseButton: false,
        title: 'Your Booking Option',
        content: this.bookinOption.description,
      } as DialogData,
    });
  }
}
