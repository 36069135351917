<greco-simple-dialog [data]="dialogData">
  <p>
    Please specify the balance amount to reverse for this refund. This will undo the refund to balance for the amount
    you specify.
  </p>
  <p>
    Amount Reversable:
    <strong>{{ maxUndo | currency }}</strong>
  </p>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-form-field appearance="standard" *ngIf="(canUndoRefundToBalance$ | async)">
      <mat-label>Reverse Balance Refund Amount</mat-label>
      <input
        matInput
        type="text"
        formControlName="balanceUndoneAmount"
        currencyMask
        [options]="currencyMaskConfig"
        [readonly]="processing"
      />
      <mat-error
        *ngIf="formGroup.get('balanceUndoneAmount')?.errors && formGroup.get('balanceUndoneAmount')?.hasError('invalid')"
      >
        Amount exceeded the maximum undo limit of <strong> {{ maxUndo | currency }} </strong></mat-error
      >
    </mat-form-field>

    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        mat-stroked-button
        type="submit"
        color="primary"
        [disabled]="formGroup.invalid || processing"
        style="flex: 1"
      >
        Confirm
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
