import { Perk, UserPerkFee } from '@greco/sales-perks';
import { ProductStatus } from './product';
import { ProductVariant } from './product-variant';

export enum VariantPerkGranted {
  Initial = 'INITIAL',
  Renewal = 'RENEWAL',
  Update = 'UPDATE',
}

export enum VariantPerkExpiryEvent {
  CANCELLATION = 'CANCELLATION',
  RENEWAL = 'RENEWAL',
  Update = 'UPDATE',
}

export interface VariantPerk {
  id: string;

  perk: Perk;
  variant: ProductVariant;

  quantity: number;
  fee: UserPerkFee;

  granted: VariantPerkGranted[];

  /** Number of days */
  expiryDelay: number | null;
  expiryNotificationDelay: number | null;

  expiryEvent: VariantPerkExpiryEvent[];

  status: ProductStatus;

  transferable: boolean;
  reusable: boolean;
  freezable: boolean;
}
