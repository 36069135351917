<swiper [navigation]="true">
  <ng-template swiperSlide *ngFor="let booking of bookings$ | async">
    <greco-box style="margin: 0 32px; padding: 0; overflow: hidden; cursor: pointer" (click)="openBooking(booking)">
      <div style="display: flex; flex-direction: column">
        <div style="display: flex; gap: 16px; color: white; background: black; padding: 12px 16px">
          <div
            style="position: relative; width: 44px; height: 44px; flex-shrink: 0"
            *ngIf="booking.event.imageUrl || booking.event.calendar?.imageUrl || true"
          >
            <img
              style="
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: 4px 4px 0 var(--primary-color);
              "
              [src]="
                booking.event.imageUrl ||
                booking.event.calendar?.imageUrl ||
                'https://alteaactive.com/toronto/wp-content/uploads/sites/36/2023/04/class-carousel-cycle.jpg'
              "
            />
          </div>
          <div style="flex: 1; overflow: hidden">
            <h3 style="margin-bottom: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              {{ booking.event.title }}
            </h3>
            <p style="margin-bottom: 0">{{ booking.event.startDate | date : 'MMM d, y, h:mm a' }}</p>
          </div>
          <div style="display: flex">
            <mat-icon style="width: 20px; height: 20px; font-size: 20px">open_in_new</mat-icon>
          </div>
        </div>
        <div style="padding: 12px 16px; display: flex; gap: 8px">
          <p
            style="display: flex; gap: 4px; align-items: center; margin-bottom: 0; font-weight: bold"
            *ngIf="booking.event.calendar"
          >
            <mat-icon>location_on</mat-icon> {{ booking.event.calendar.title }}
          </p>
          |
          <p style="display: flex; gap: 4px; align-items: center; margin-bottom: 0; font-weight: bold">
            {{ booking.status | titlecase }}
          </p>
        </div>
        <div style="padding: 12px 16px; display: flex; gap: 8px">
          <p
            style="display: flex; gap: 4px; align-items: center; margin-bottom: 0; font-weight: bold"
            *ngIf="booking.event.calendar"
          >
            <mat-icon>meeting_room</mat-icon> {{ booking.event.resourceAssignments | json }}
          </p>
        </div>
      </div>
    </greco-box>
  </ng-template>
</swiper>
<button style="margin: 12px 16px; width: calc(100% - 32px)" mat-flat-button routerLink="/workouts" color="primary">
  <mat-icon>add</mat-icon>New Booking
</button>
<div style="padding: 8px 16px 32px 16px">
  <h2 style="margin-bottom: 0">Past</h2>
  <p>Bookin 2</p>
</div>
