import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { toPromise } from '@greco-fit/util';
import { AccountService } from '@greco/ngx-finance-accounts';
import { PaymentMethodFormComponent } from '@greco/ngx-finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import type {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElements,
} from '@stripe/stripe-js';
import { ReplaySubject } from 'rxjs';
import { StripeJsService } from '../../services';

@Component({
  selector: 'greco-stripe-credit-card-payment-method-form',
  templateUrl: './credit-card-payment-method-form.component.html',
  styleUrls: ['./credit-card-payment-method-form.component.scss'],
})
export class CreditCardPaymentMethodFormComponent
  extends PaymentMethodFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    private formBuilder: FormBuilder,
    private stripejs: StripeJsService,
    private userSVC: UserService,
    private accountSvc: AccountService
  ) {
    super();
  }
  readonly formGroup = this.formBuilder.group({
    cardHolderName: ['', [Validators.required]],
    cardNumber: [false, [Validators.required, Validators.requiredTrue]],
    cardExpiry: [false, [Validators.required, Validators.requiredTrue]],
    cardCvc: [false, [Validators.required, Validators.requiredTrue]],
  });

  public cardElemId: string = Math.random().toString(36).slice(-8);

  private cardNumberElement: undefined | StripeCardNumberElement;
  private cardExpiryElement: undefined | StripeCardExpiryElement;
  private cardCvcElement: undefined | StripeCardCvcElement;

  private _initialized = new ReplaySubject<void>(1);

  elements?: StripeElements;

  async ngOnInit() {
    if (this.userId) {
      const user = await toPromise(this.userSVC.getUser(this.userId));
      if (user) {
        this.formGroup.get('cardHolderName')?.setValue(user.displayName);
      }
    } else if (this.accountId) {
      const account = await this.accountSvc.getAccount(this.accountId);
      if (account) {
        this.formGroup.get('cardHolderName')?.setValue(account.name);
      }
    }

    this.elements = await this.stripejs.elements();
    if (this.elements) {
      const options = {
        showIcon: true,
        style: {
          base: {
            color: '#202222',
            // fontWeight: 500,
            fontFamily: 'PT Mono, monospace',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
              color: '#acadaf',
            },
            ':-webkit-autofill': {
              color: '#e39f48',
            },
          },
          invalid: {
            color: '#f65149',

            '::placeholder': {
              color: '#ffc8c5',
            },
          },
        },
      };
      this.cardNumberElement = this.elements.create('cardNumber', options);
      this.cardExpiryElement = this.elements.create('cardExpiry', options);
      this.cardCvcElement = this.elements.create('cardCvc', options);

      this.cardNumberElement?.on('change', event => {
        this.formGroup.patchValue({ cardNumber: event.complete });
      });
      this.cardExpiryElement?.on('change', event => {
        this.formGroup.patchValue({ cardExpiry: event.complete });
      });
      this.cardCvcElement?.on('change', event => {
        this.formGroup.patchValue({ cardCvc: event.complete });
      });
    }

    this._initialized.next();
  }

  async ngAfterViewInit() {
    await toPromise(this._initialized);
    this.cardNumberElement?.mount?.('#cardNumber-element-' + this.cardElemId);
    this.cardExpiryElement?.mount?.('#cardExpiry-element-' + this.cardElemId);
    this.cardCvcElement?.mount?.('#cardCvc-element-' + this.cardElemId);
  }

  ngOnDestroy() {
    this._initialized.complete();
  }

  async save(): Promise<{
    label: string;
    model: string;
    externalId: string;
    externalData?: any;
    cardHolderName: string;
  }> {
    if (!this.cardNumberElement) throw new Error('Unable to initialize StripeJS instance');
    let customer = null;
    if (this.userId) {
      customer = { id: this.userId, type: 'user' };
    } else if (this.accountId) {
      customer = { id: this.accountId, type: 'account' };
    }
    if (!customer) throw new Error('Stripe Customer details not provided');
    if (!this.elements) throw new Error('Unable to initialize StripeJS instance');
    return await this.stripejs.createCreditCardPaymentMethod(
      customer.id,
      customer.type,
      this.cardNumberElement,
      this.formGroup.value.cardHolderName
    );
  }
}
