<div style="display: flex; align-items: stretch; justify-content: center; gap: 8px; flex-wrap: wrap">
  <ng-container *ngFor="let perk of groupedPerks$ | async">
    <div style="min-width: 256px; flex: 1" (click)="emitPerk(perk)">
      <greco-box
        style="min-width: 256px; flex: 1"
        [attr.id]="perk.id"
        [class]="isSelected?.id === perk.id ? 'isSelected' : 'default'"
      >
        <div style="display: flex; align-items: center; justify-content: flex-start; gap: 12px">
          <greco-perk-badge
            [matTooltip]="perk.id"
            style="user-select: none"
            [shape]="perk.badge?.badgeShape || 'circle'"
            [icon]="perk.badge?.badgeIcon"
            [text]="perk.badge?.badgeText"
            [color]="perk.badge?.badgeColor || ''"
            [small]="true"
          ></greco-perk-badge>

          <h3
            [matTooltip]="perk.title || ''"
            style="margin: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
          >
            {{ perk.title }}
          </h3>

          <h2
            [matTooltip]="(perk.availableNow || 0) + ' Available Now + ' + (perk.reusable || 0) + ' Reusable'"
            style="display: flex; align-items: center; margin: 0; margin-left: auto"
          >
            <span *ngIf="perk.availableNow">{{ perk.availableNow }}</span>
            <span *ngIf="perk.reusable" style="margin-left: 8px; display: flex; align-items: center">
              <mat-icon style="line-height: 18px; font-size: 18px; width: 18px; height: 18px">replay</mat-icon>
              {{ perk.reusable }}
            </span>
          </h2>
        </div>

        <div *ngIf="perk.subscriptions?.length" style="padding: 0 2px">
          <div
            *ngFor="let sub of perk.subscriptions"
            style="margin-top: 12px; display: flex; align-items: center; justify-content: space-between"
          >
            <div>+{{ sub.quantity }} Every {{ sub.frequency | grecoFrequency : $any(sub.period) }}</div>
            <div
              style="
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 16px;
                margin-bottom: -2px;
              "
            >
              <mat-icon style="color: lightgrey; font-size: 16px; width: 16px; height: 16px">trending_flat</mat-icon>
            </div>
            <div>{{ sub.renewal | date : 'mediumDate' }}</div>
          </div>
        </div>
      </greco-box>
    </div>
  </ng-container>
</div>
