import { Component, Input } from '@angular/core';

@Component({
  selector: 'greco-stat-box',
  templateUrl: './stat-box.component.html',
  styleUrls: ['./stat-box.component.scss'],
})
export class StatBoxComponent {
  @Input() title?: string | null;
  @Input() description?: string | null;
  @Input() content?: number | string | null;

  absDelta = 0;
  private _delta = 0;
  @Input() set delta(value: number | null | undefined) {
    this._delta = value || 0;
    this.absDelta = Math.abs(this._delta);
  }
  get delta(): number {
    return this._delta;
  }

  @Input() deltaPositiveGood = true;
}
