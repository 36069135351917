import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { ReferralService } from '../services/referral.service';

@Injectable()
export class ReferralGuard implements CanActivate {
  constructor(
    private router: Router,
    private referralService: ReferralService,
    private userService: UserService,
    private contactService: ContactService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot) {
    const user = await toPromise(this.userService.user$);
    if (user) {
      const referralInfo = await this.referralService.processReferral(route.params.link);
      if (referralInfo?.type === 'Community') {
        const community = referralInfo.community;
        const result = await this.contactService.subscribeUserToCommunity(community.id, user.id);
        return result &&
          community.browsable /* TODO(adaoust): Replace with config about whether community leverages the onboarding form. */
          ? this.router.createUrlTree(['welcome', community.id])
          : this.router.parseUrl('');
      } else {
        this.referralService.setReferral(route.params.link);
        return this.router.parseUrl('');
      }
    } else {
      this.referralService.setReferral(route.params.link);
      return this.router.parseUrl('');
    }
  }
}
