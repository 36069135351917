import { Pipe, PipeTransform } from '@angular/core';
import { ProductVariant, ProductVariantRecurrence, RecurrencePeriod } from '@greco/sales-products';
import { SubscriptionRecurrence } from '@greco/sales-subscriptions';

@Pipe({
  name: 'variantPriceRate',
})
export class VariantPriceBillingPipe implements PipeTransform {
  transform(variant: ProductVariant, capitalB: boolean = true): string {
    if (variant.recurrence?.frequency) {
      return variant.recurrence?.cycles
        ? `${capitalB ? 'B' : 'b'}illed ${variant.recurrence.cycles} time${variant.recurrence.cycles > 1 ? 's' : ''}`
        : `${capitalB ? 'B' : 'b'}illed every ${this.formatFrequencyString(variant.recurrence)}`;
    } else return '';
  }

  formatFrequencyString(recurrence: SubscriptionRecurrence | ProductVariantRecurrence) {
    if (!recurrence) return '';
    return `${recurrence.frequency === 1 ? '' : recurrence.frequency + ' '}${this.getPeriodLabel(recurrence.period)}${
      recurrence.frequency > 1 ? 's' : ''
    }`;
  }

  private getPeriodLabel(period: RecurrencePeriod) {
    switch (period) {
      case RecurrencePeriod.Daily:
        return 'day';
      case RecurrencePeriod.Monthly:
        return 'month';
      case RecurrencePeriod.Weekly:
        return 'week';
      case RecurrencePeriod.Yearly:
        return 'year';
    }
  }
}
