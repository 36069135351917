import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Community } from '@greco/identity-communities';
import { PropertyListener } from '@greco/property-listener-util';
import { TileGroup } from '@greco/tiles';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TilesService } from '../../services/tiles.service';

@Component({
  selector: 'greco-tiles-grid',
  templateUrl: './tiles-grid.component.html',
  styleUrls: ['./tiles-grid.component.scss'],
})
export class TilesGridComponent {
  constructor(private tileSvc: TilesService, private router: Router, private route: ActivatedRoute) {}

  private _communities$ = new BehaviorSubject<Community[]>([]);

  @Input() set communities(communities: Community[]) {
    this._communities$.next(communities);
  }

  @PropertyListener('tileGroup') _tileGroup$ = new BehaviorSubject<TileGroup | null>(null);

  @Input()
  tileGroup?: TileGroup;
  refresh$ = new BehaviorSubject(null);
  @Output() refresh = new EventEmitter();
  tiles$ = combineLatest([this._communities$, this._tileGroup$, this.refresh$]).pipe(
    switchMap(async ([communities, tileGroup]) => {
      if (communities.length == 0 || tileGroup == null) {
        return null;
      }
      if (tileGroup.id == '_favorites') {
        return this.tileSvc.getFavorites();
      }
      return this.tileSvc.paginate(
        new RequestQueryBuilder().search({
          communityId: {
            $in: communities.map(com => com.id),
          },
          status: true,
          tileGroupId: tileGroup.id,
        }),
        undefined,
        { limit: 100 }
      );
    }),
    map(data => {
      if (data?.items.length == 0) {
        this.router.navigate([''], { relativeTo: this.route });
        return null;
      } else {
        return data?.items || [];
      }
    })
  );
  refreshed(_id: string) {
    this.refresh$.next(null);
    this.refresh.emit(_id);
  }
}
