import { Component, Input, OnDestroy } from '@angular/core';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  RecurrencePeriod,
  Subscription,
  SubscriptionResource,
  SubscriptionResourceAction,
} from '@greco/sales-subscriptions';
import { CollapsibleSectionController, INITIAL_COLLAPSIBLE_SECTION_EXPANDED } from '@greco/ui-collapsible-section';
import { of, ReplaySubject } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-subscription',
  templateUrl: './subscription.page.html',
  styleUrls: ['./subscription.page.scss'],
  viewProviders: [CollapsibleSectionController, { provide: INITIAL_COLLAPSIBLE_SECTION_EXPANDED, useValue: 1 }],
})
export class SubscriptionPage implements OnDestroy {
  constructor(private securitySvc: SecurityService, private subscriptionSvc: SubscriptionsService) {}

  readonly _PERIODS = {
    [RecurrencePeriod.Daily]: 'day',
    [RecurrencePeriod.Monthly]: 'month',
    [RecurrencePeriod.Weekly]: 'week',
    [RecurrencePeriod.Yearly]: 'year',
  };

  @Input() communityId?: string;

  @Input() hideLifetimeValue = false;

  @Input() subscription?: Subscription;
  @PropertyListener('subscription') private subscription$ = new ReplaySubject<Subscription>(1);

  ltv$ = this.subscription$.pipe(
    switchMap(async subscription => (subscription ? await this.subscriptionSvc.getSubscriptionLtv(subscription.id) : 0))
  );

  canCancel$ = this.subscription$.pipe(
    switchMap(async subscription =>
      subscription
        ? await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.CANCEL, {
            accountId: subscription.accountId,
          })
        : false
    ),
    catchError(() => of(false)),
    shareReplay(1)
  );

  async refresh() {
    if (this.subscription) this.subscription = await this.subscriptionSvc.getSubscription(this.subscription.id);
  }

  ngOnDestroy() {
    this.subscription$.complete();
  }
}
