import { Injectable } from '@angular/core';
import { QueryParamsHandling } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export interface Crumb {
  icon?: string;
  label: string;
  routerLink: string;
  queryParams?: string;
  queryParamsHandling?: QueryParamsHandling;
}

@Injectable()
export class BreadcrumbService {
  private _crumbs$ = new BehaviorSubject<Crumb[]>([]);

  set(crumbs: Crumb[]) {
    this._crumbs$.next(crumbs);
  }

  get() {
    return this._crumbs$.value;
  }

  listen() {
    return this._crumbs$.pipe(shareReplay(1));
  }
}
