<div *ngIf="!multiselect" style="display: flex; flex-direction: row">
  <input
    matInput
    type="text"
    (blur)="touched()"
    autocomplete="off"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="placeholder"
    (keydown)="value = null"
    class="product-variant-picker-input"
    [matMenuTriggerFor]="salesCategories"
    (input)="searchQuery$.next($any($event).target.value)"
  />

  <button class="trigger" #trigger="matMenuTrigger" [matMenuTriggerFor]="allSalesCategories"></button>
  <button type="button" mat-icon-button style="margin: -12px" [disabled]="disabled" (click)="trigger.openMenu()">
    <mat-icon>manage_search</mat-icon>
  </button>
</div>

<div *ngIf="multiselect" style="display: flex; flex-direction: row">
  <mat-chip-list #chipList style="white-space: nowrap" style="flex: 1">
    <ng-container *ngIf="!selectionsView">
      <!-- Chip list for vairants -->
      <mat-chip *ngFor="let variant of selectedVariants" style="margin-right: 4px">
        {{ variant.product?.title }} -
        {{
          variant.title
            ? variant.title + ' (' + (variant.variantOpts | pluck : 'value')?.join(', ') + ')'
            : (variant.variantOpts | pluck : 'value')?.join(', ')
        }}
        <mat-icon
          style="line-height: 18px; font-size: 18px; margin: 7px -10px 0px 6px; color: gray"
          (click)="removeFromSelectedVariants(variant)"
        >
          cancel
        </mat-icon>
      </mat-chip>

      <!-- Chip list for products -->
      <mat-chip *ngFor="let product of selectedProducts" style="margin-right: 4px">
        {{ product.title }}
        <mat-icon
          style="line-height: 18px; font-size: 18px; margin: 7px -10px 0px 6px; color: gray"
          (click)="removeFromSelectedProducts(product)"
        >
          cancel
        </mat-icon>
      </mat-chip>
    </ng-container>

    <input
      matInput
      type="text"
      (blur)="touched()"
      autocomplete="off"
      [disabled]="disabled"
      [required]="required"
      [placeholder]="placeholder"
      [matChipInputFor]="chipList"
      class="product-variant-picker-input"
      [matMenuTriggerFor]="salesCategories"
      (input)="searchQuery$.next($any($event).target.value)"
      [value]="selectionsView && selectedVariantIds.length ? selectedVariantIds.length + ' Variants Selected' : null"
    />
  </mat-chip-list>

  <div style="display: flex; flex-direction: column; justify-content: flex-end">
    <button class="trigger" #trigger="matMenuTrigger" [matMenuTriggerFor]="allSalesCategories"></button>
    <button type="button" mat-icon-button style="margin: -12px" [disabled]="disabled" (click)="trigger.openMenu()">
      <mat-icon>manage_search</mat-icon>
    </button>
  </div>
</div>

<!-- Shows sales categories with filter from text input -->
<mat-menu #salesCategories (close)="stateChanges.next()">
  <ng-container *grecoLet="justProducts$ | async as justProducts">
    <!-- Show sales categories if no text has been entered to search for -->
    <ng-container *ngIf="!justProducts">
      <div style="display: flex">
        <p style="font-weight: bold; font-size: small; margin: 8px 16px" [class.clearallbtn]="selectionsView">
          Sales Categories
        </p>
        <ng-container *ngIf="selectionsView">
          <button
            class="btn-clear-custom"
            [disabled]="!selectedVariants.length"
            mat-menu-item
            (click)="clearSelections(); $event.stopImmediatePropagation()"
          >
            Clear All
          </button>
        </ng-container>
      </div>
      <ng-container *ngFor="let category of productsAndCategory$ | async">
        <button mat-menu-item [mat-menu-trigger-for]="products">
          <span [innerHTML]="category.name | safe : 'html'"></span>
        </button>

        <mat-menu #products>
          <p style="font-weight: bold; font-size: small; margin: 8px 16px">Products</p>

          <ng-container *ngFor="let product of category.products">
            <button
              *ngIf="!onlyProducts"
              mat-menu-item
              [mat-menu-trigger-for]="variants"
              (click)="toggleAllVariants(product)"
            >
              <mat-checkbox
                *ngIf="multiselect"
                style="margin-right: 4px"
                color="primary"
                [checked]="selectedProductIds.includes(product.id) || allVariantsInProduct(product)"
                [indeterminate]="productIndeterminate(product)"
                [disableRipple]="true"
                (click)="$event.preventDefault()"
              ></mat-checkbox>
              <span *ngIf="product.status !== 'ACTIVE'">[{{ product.status | titlecase }}] </span>{{ product.title }}
            </button>
            <button
              *ngIf="onlyProducts"
              mat-menu-item
              (click)="select(product, null); multiselect ? $event.stopImmediatePropagation() : null"
            >
              <mat-checkbox
                *ngIf="multiselect"
                style="margin-right: 4px"
                color="primary"
                [checked]="selectedProductIds.includes(product.id)"
                (click)="$event.preventDefault()"
              ></mat-checkbox>
              <span *ngIf="product.status !== 'ACTIVE'">[{{ product.status | titlecase }}] </span>{{ product.title }}
            </button>

            <mat-menu #variants>
              <ng-container *ngFor="let variant of product.variants">
                <button
                  mat-menu-item
                  *ngIf="variant.status == activeStatus || showAllVariants"
                  (click)="
                    select(product, variant);
                    emitVariant(variant);
                    multiselect ? $event.stopImmediatePropagation() : null
                  "
                >
                  <mat-checkbox
                    *ngIf="multiselect"
                    style="margin-right: 4px"
                    color="primary"
                    [checked]="selectedVariantIds.includes(variant.id)"
                    (click)="$event.preventDefault()"
                  ></mat-checkbox>
                  <span>
                    <strong>
                      <span *ngIf="variant.status !== 'ACTIVE'">[{{ variant.status | titlecase }}] </span
                      >{{ variant | variantPrice }}
                    </strong>
                    -
                    {{
                      variant.title
                        ? variant.title + ' (' + (variant.variantOpts | pluck : 'value')?.join(', ') + ')'
                        : (variant.variantOpts | pluck : 'value')?.join(', ')
                    }}
                  </span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>

    <!-- Hide sales categories if text to be searched has been entered -->
    <ng-container *ngIf="justProducts">
      <p style="font-weight: bold; font-size: small; margin: 8px 16px">Products</p>

      <ng-container *grecoLet="productsAndCategory$ | async as productsAndCategory">
        <button
          *ngIf="!productsAndCategory || productsAndCategory.length === 0"
          mat-menu-item
          style="pointer-events: none"
        >
          No products found
        </button>

        <ng-container *ngFor="let category of productsAndCategory">
          <ng-container *ngFor="let product of category.products">
            <button
              *ngIf="!onlyProducts"
              mat-menu-item
              [mat-menu-trigger-for]="variants"
              (click)="toggleAllVariants(product)"
            >
              <mat-checkbox
                *ngIf="multiselect"
                style="margin-right: 4px"
                color="primary"
                [checked]="selectedProductIds.includes(product.id) || allVariantsInProduct(product)"
                [indeterminate]="productIndeterminate(product)"
                [disableRipple]="true"
                (click)="$event.preventDefault()"
              ></mat-checkbox>
              <span *ngIf="product.status !== 'ACTIVE'">[{{ product.status | titlecase }}] </span>{{ product.title }}
            </button>
            <button
              *ngIf="onlyProducts"
              mat-menu-item
              (click)="select(product, null); multiselect ? $event.stopImmediatePropagation() : null"
            >
              <mat-checkbox
                *ngIf="multiselect"
                style="margin-right: 4px"
                color="primary"
                [checked]="selectedProductIds.includes(product.id)"
                (click)="$event.preventDefault()"
              ></mat-checkbox>
              <span *ngIf="product.status !== 'ACTIVE'">[{{ product.status | titlecase }}] </span>{{ product.title }}
            </button>

            <mat-menu #variants>
              <ng-container *ngFor="let variant of product.variants">
                <button
                  mat-menu-item
                  *ngIf="variant.status == activeStatus || showAllVariants"
                  (click)="
                    select(product, variant);
                    emitVariant(variant);
                    multiselect ? $event.stopImmediatePropagation() : null
                  "
                >
                  <mat-checkbox
                    *ngIf="multiselect"
                    style="margin-right: 4px"
                    color="primary"
                    [checked]="selectedVariantIds.includes(variant.id)"
                    (click)="$event.preventDefault()"
                  ></mat-checkbox>
                  <strong>
                    <span *ngIf="variant.status !== 'ACTIVE'">[{{ variant.status | titlecase }}] </span
                    >{{ variant | variantPrice }}
                  </strong>
                  -
                  {{
                    variant.title
                      ? variant.title + ' (' + (variant.variantOpts | pluck : 'value')?.join(', ') + ')'
                      : (variant.variantOpts | pluck : 'value')?.join(', ')
                  }}
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-menu>

<!-- Constant list of all sales categories and products -->
<mat-menu #allSalesCategories (close)="stateChanges.next()">
  <div style="display: flex">
    <p style="font-weight: bold; font-size: small; margin: 8px 16px" [class.clearallbtn]="selectionsView">
      Sales Categories
    </p>
    <ng-container *ngIf="selectionsView">
      <button
        class="btn-clear-custom"
        [disabled]="!selectedVariants.length"
        mat-menu-item
        (click)="clearSelections(); $event.stopImmediatePropagation()"
      >
        Clear All
      </button>
    </ng-container>
  </div>
  <ng-container *ngFor="let category of allProducts$ | async">
    <button mat-menu-item [mat-menu-trigger-for]="products">
      <span [innerHTML]="category.name | safe : 'html'"></span>
    </button>

    <mat-menu #products>
      <p style="font-weight: bold; font-size: small; margin: 8px 16px">Products</p>

      <ng-container *ngFor="let product of category.products">
        <button
          *ngIf="!onlyProducts"
          mat-menu-item
          [mat-menu-trigger-for]="variants"
          (click)="toggleAllVariants(product)"
        >
          <mat-checkbox
            *ngIf="multiselect"
            style="margin-right: 4px"
            color="primary"
            [checked]="selectedProductIds.includes(product.id) || allVariantsInProduct(product)"
            [indeterminate]="productIndeterminate(product)"
            [disableRipple]="true"
            (click)="$event.preventDefault()"
          ></mat-checkbox>
          <span *ngIf="product.status !== 'ACTIVE'">[{{ product.status | titlecase }}] </span>{{ product.title }}
        </button>
        <button
          *ngIf="onlyProducts"
          mat-menu-item
          (click)="select(product, null); multiselect ? $event.stopImmediatePropagation() : null"
        >
          <mat-checkbox
            *ngIf="multiselect"
            style="margin-right: 4px"
            color="primary"
            [checked]="selectedProductIds.includes(product.id)"
            (click)="select(product, null); $event.preventDefault()"
          ></mat-checkbox>
          <span *ngIf="product.status !== 'ACTIVE'">[{{ product.status | titlecase }}] </span>{{ product.title }}
        </button>

        <mat-menu #variants>
          <ng-container *ngFor="let variant of product.variants">
            <button
              mat-menu-item
              *ngIf="variant.status == activeStatus || showAllVariants"
              (click)="
                select(product, variant); emitVariant(variant); multiselect ? $event.stopImmediatePropagation() : null
              "
            >
              <mat-checkbox
                *ngIf="multiselect"
                style="margin-right: 4px"
                color="primary"
                [checked]="selectedVariantIds.includes(variant.id)"
                (click)="$event.preventDefault()"
              ></mat-checkbox>
              <strong>
                <span *ngIf="variant.status !== 'ACTIVE'">[{{ variant.status | titlecase }}] </span
                >{{ variant | variantPrice }}
              </strong>
              -
              {{
                variant.title
                  ? variant.title + ' (' + (variant.variantOpts | pluck : 'value')?.join(', ') + ')'
                  : (variant.variantOpts | pluck : 'value')?.join(', ')
              }}
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </mat-menu>
  </ng-container>
</mat-menu>
