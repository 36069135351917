import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { AccountLink } from '@greco/account-linking';
import { UpdateAccountLinkDialog } from '../../dialogs';

@Component({
  selector: 'greco-account-link-permissions',
  templateUrl: './account-link-permissions.component.html',
  styleUrls: ['./account-link-permissions.component.scss'],
})
export class AccountLinkPermissionsComponent {
  constructor(private matDialog: MatDialog) {}
  @Input() link!: AccountLink;
  @Input() view: 'accessor' | 'account' = 'accessor';
  @Input() canUpdate = false;
  @Output() refresh = new EventEmitter();

  async add() {
    const dialog = this.matDialog.open(UpdateAccountLinkDialog, {
      data: { link: this.link, view: this.view },
      width: '400px',
      maxWidth: '90%',
    });
    await toPromise(dialog.afterClosed());
    this.refresh.emit();
  }
}
