/* eslint-disable @angular-eslint/no-input-rename */
import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { BookingOption, calculateBoosterRequirements, CalendarEvent } from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { heightExpansion } from '@greco/ui-animations';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MinutesPipe } from '../../../../pipes';

@Component({
  selector: 'greco-booking-preview-booking-option-picker',
  templateUrl: './booking-option-picker.component.html',
  styleUrls: ['./booking-option-picker.component.scss'],
  animations: [heightExpansion],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BookingPreviewBookingOptionPickerComponent,
      multi: true,
    },
  ],
})
export class BookingPreviewBookingOptionPickerComponent implements ControlValueAccessor {
  @Input() complimentaryOption?: BookingOption | null;

  @Input('showBoosters') _showBoosters = false;
  @PropertyListener('_showBoosters') private showBoosters$ = new BehaviorSubject(this._showBoosters);

  @Input('bookingOptions') _bookingOptions: BookingOption[] = [];
  @PropertyListener('_bookingOptions') private bookingOptions$ = new BehaviorSubject(this._bookingOptions);

  @Input('event') _event?: CalendarEvent = undefined;
  @PropertyListener('_event') private event$ = new BehaviorSubject(this._event);

  private value$ = new BehaviorSubject<BookingOption | null>(null);

  @Input() get value() {
    return this.value$.value;
  }
  set value(value: BookingOption | null) {
    this.value$.next(value);
    this._onChange?.(this.value$.value);
  }

  _onChange?: (value: BookingOption | null) => void;
  _onTouched?: () => void;

  private options$: Observable<Option[]> = combineLatest([this.bookingOptions$, this.event$, this.showBoosters$]).pipe(
    map(([options, event, showBoosters]) => {
      if (!event) return [];
      return options.map(option => {
        return {
          ...option,
          requiredBoosters: showBoosters
            ? this.requiredBoosters(option, event) //
            : 0,
        };
      });
    })
  );

  private selectedOption$: Observable<Option | null> = combineLatest([
    this.value$,
    this.event$,
    this.showBoosters$,
  ]).pipe(
    map(([value, event, showBoosters]) => {
      if (!value || !event) return null;
      return {
        ...value,
        requiredBoosters: showBoosters
          ? this.requiredBoosters(value, event) //
          : 0,
      };
    })
  );

  data$ = combineLatest([this.options$, this.selectedOption$]).pipe(
    map(([options, selectedOption]) => ({ options, selectedOption }))
  );

  expanded = false;

  private _complimentary = false;
  get complimentary() {
    return this._complimentary;
  }

  constructor(private matDialog: MatDialog) {}

  async toggleComplimentary() {
    if (!this.complimentaryOption) {
      this._complimentary = false;
    } else {
      this._complimentary = !this._complimentary;
    }

    if (this._complimentary) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectOption(this.complimentaryOption!);
    } else {
      this.selectOption(this._bookingOptions[0] ?? null);
    }
  }

  selectOption(option: BookingOption) {
    this.writeValue(option);
    this.expanded = false;
  }

  openInfoDialog(option: Option) {
    let title = option.title;

    if (option.bookingWindow) {
      title += ` (${new MinutesPipe().transform(option.bookingWindow)})`;
    }

    if (option.price) {
      title += ` | ${new CurrencyPipe('en-CA').transform(option.price / 100)} Booking Fee`;
    }

    if (option.requiredBoosters) {
      title += ` | ${option.requiredBoosters} Booking Window Booster${option.requiredBoosters === 1 ? '' : 's'}`;
    }

    this.matDialog.open(SimpleDialog, {
      maxWidth: 700,
      data: {
        subtitle: title,
        showCloseButton: false,
        title: 'Your Booking Option',
        content: option.description,
      } as DialogData,
    });
  }

  writeValue(value: BookingOption | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: BookingOption | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  private requiredBoosters(option: BookingOption, event: CalendarEvent): number {
    if (option.id === 'prk_complimentarybooking') return 0;
    return calculateBoosterRequirements(event, option);
  }
}

interface Option extends BookingOption {
  requiredBoosters: number;
}
