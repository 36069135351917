<ng-container *grecoLet="requiredBoosts$ | async as requiredBoosts">
  <span style="margin: 0 4px 0 0">{{ bookingOption.title }}</span>
  <ng-container *ngIf="bookingOption.price || requiredBoosts">
    <span style="margin: 0 4px">|</span>
    <ng-container *ngIf="requiredBoosts">
      <span style="margin: 0 4px">{{ requiredBoosts }} Booster{{ requiredBoosts === 1 ? '' : 's' }}</span>
      <span style="margin: 0 4px" *ngIf="bookingOption.price">+</span>
    </ng-container>
    <span style="margin: 0 4px" *ngIf="bookingOption.price">
      {{ bookingOption.price / 100 | currency }}
    </span>
  </ng-container>
</ng-container>
