import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipeModule } from 'safe-pipe';
import { SmartImgComponent } from './smart-img.component';

@NgModule({
  declarations: [SmartImgComponent],
  exports: [SmartImgComponent],
  imports: [CommonModule, SafePipeModule]
})
export class SmartImgModule {}
