import * as moment from 'moment';
import { Frequency, RRule } from 'rrule';
import { Schedule, ScheduleFrequency } from './models';

export function getRRuleFromSchedule(schedule: Schedule | null): RRule | null {
  if (!schedule) return null;
  try {
    return new RRule({
      byhour: [0],
      byminute: [0],
      bysecond: [0],
      ...(schedule.frequency === ScheduleFrequency.ONCE
        ? { count: 1 }
        : RRule.fromString(schedule.rrule || '').origOptions),
      until: schedule.until ? schedule.until : null,
      dtstart: transformDate(schedule.date),
      freq: getFrequency(schedule.frequency),
    });
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function getFrequency(frequency: ScheduleFrequency): Frequency {
  switch (frequency) {
    case ScheduleFrequency.WEEKLY:
      return Frequency.WEEKLY;
    case ScheduleFrequency.MONTHLY:
      return Frequency.MONTHLY;
    case ScheduleFrequency.YEARLY:
      return Frequency.YEARLY;
    default:
      return Frequency.DAILY;
  }
}

export function getScheduleText(schedule: Schedule | null): string | null {
  if (schedule?.frequency === ScheduleFrequency.ONCE) {
    return `Once on ${schedule.date.toDateString()}`;
  }

  const text = schedule
    ? getRRuleFromSchedule(schedule)?.toText()?.replace(' (~ approximate)', '')?.replace('at 0 ', '') || ''
    : null;
  return text ? text + ` starting ${schedule?.date.toDateString()}` : null;
}

export function getScheduleNextDate(schedule: Schedule, after?: Date, incl?: boolean): Date | null {
  const rrule = getRRuleFromSchedule(schedule);
  if (rrule === null) throw new Error('Invalid schedule configuration');

  const nextDate = rrule.after(transformDate(after || new Date()), incl || false);
  return nextDate ? moment(moment.utc(nextDate).format('YYYY-MM-DDTHH:mm:ss')).toDate() : null;
}

function transformDate(date: Date): Date {
  const format = (d: moment.Moment) => d.format('YYYY-MM-DDTHH:mm:ss');
  return moment.utc(format(moment(date))).toDate();
}
