import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import type {
  AccountFeeConfigurationDto,
  StripeAccountFeeConfigurationEntity,
} from '@greco/nestjs-stripe-payment-gateway';

@Injectable()
export class StripeFeesService {
  constructor(private http: HttpClient) {}

  // @Post()
  runProcessingFeesReconciliation() {
    return toPromise(this.http.post(`/api/stripe-fees/reconciliation`, null));
  }

  // @Get(':accountId')
  getAccountFeeConfigs(accountId: string) {
    return toPromise(this.http.get<StripeAccountFeeConfigurationEntity[]>(`/api/stripe-fees/${accountId}`));
  }

  // @Post(':accountId')
  updateAccountFees(accountId: string, dtos: AccountFeeConfigurationDto[]) {
    return toPromise(this.http.post<StripeAccountFeeConfigurationEntity[]>(`/api/stripe-fees/${accountId}`, dtos));
  }
}
