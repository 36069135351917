import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'trim' })
export class TrimCouponPipe implements PipeTransform {
  transform(items: string[]) {
    const trimmed: string[] = [];

    items.forEach(item => {
      const r = item.split(')');
      trimmed.push(r[1]);
    });

    return trimmed;
  }
}
