import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import type { SubscriptionCancellationDto } from '@greco/nestjs-sales-subscriptions';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { Subscription, SubscriptionResource, SubscriptionResourceAction } from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import moment from 'moment';
import { SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-cancel-subscription-dialog',
  templateUrl: './cancel-subscription.dialog.html',
  styleUrls: ['./cancel-subscription.dialog.scss'],
})
export class CancelSubscriptionDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly subscription: Subscription,
    private dialogRef: MatDialogRef<CancelSubscriptionDialog>,
    private snacks: MatSnackBar,
    private subscriptionSvc: SubscriptionsService,
    private fb: FormBuilder,
    private securitySvc: SecurityService,
    private userSvc: UserService
  ) {}

  readonly cancellationReasons = [
    'Admin A/R cleanup',
    'Admin Error',
    'Altea Team',
    'Banned from Club',
    'Climbing Club',
    'Deceased',
    'Early Buy Out',
    'Financial',
    'Joined Another Club',
    'Medical',
    'Misconduct',
    'Move/Relocation',
    'No Time',
    'Non-usage',
    'Rate Increase',
    'Registered Letter',
    'Rescind',
    'Student joining school gym',
    'Too Far',
    'Towels',
    'Travel',
    'Unhappy with Experience/Club',
    'Class Schedule',
    'Facilities',
  ];

  addOnProductCancellationReasons = [
    'Add On Cancellation',
    'Add On Service Quality',
    'Add On Product no longer offered',
    'Add On Price Change',
    'Add On Cancelled Other',
  ];

  membershipCancellationReasons = ['Home Club Transfer', 'Membership Transfer'];

  activeKidsClubReasons = [
    'AKC Cancellation',
    'AKC Cancelled Rescind',
    'AKC Cancelled Relocation',
    'AKC Cancelled Relocation',
    'AKC Cancelled Service Quality',
    'AKC Cancelled End of Commitment Period',
    'AKC Cancelled Other',
  ];

  processing = false;

  dialogData: DialogData = {
    title: 'Cancel Subscription',
    subtitle: 'When should the subscription be cancelled?',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  formGroup = this.fb.group({
    dateSelection: [moment().add(1, 'days').toDate(), Validators.required],
    dateRadioButton: ['now'],
    prorateRadioButton: ['none'],
    cancellationDetails: [null, [Validators.required]],
  });

  canProrate = false;

  minimalDate = moment().add(1, 'days').toDate();
  maximalDate = moment().add(5, 'years').toDate();

  queryBuilder!: RequestQueryBuilder;

  async ngOnInit() {
    this.canProrate = await this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.PRORATE, {
      userId: this.subscription.userId,
      accountId: this.subscription.accountId,
      createdById: this.subscription.createdBy?.id,
    });

    this.queryBuilder = new RequestQueryBuilder()
      .setFilter({
        field: 'subscriptionId',
        operator: 'eq',
        value: this.subscription.id,
      })
      .sortBy({ field: 'created', order: 'DESC' });
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submitCancellation() {
    try {
      this.processing = true;

      const currentUserId = await toPromise(this.userSvc.getUserId());

      const cancellationDto: SubscriptionCancellationDto = {
        proration: false,
        refundToCard: false,
        endOfPeriod: false,
        createdById: currentUserId,
        details: this.formGroup.value.cancellationDetails,
      };

      if (this.canProrate) {
        cancellationDto.proration = this.formGroup.value.prorateRadioButton !== 'none';
        cancellationDto.refundToCard = this.formGroup.value.prorateRadioButton === 'card';
      }

      if (this.formGroup.value.dateRadioButton === 'endOfPeriod') {
        cancellationDto.endOfPeriod = true;
      }

      if (this.formGroup.value.dateRadioButton === 'future') {
        const formattedCancellationDate: Date = this.formGroup.value.dateSelection;
        formattedCancellationDate.setHours(3, 0, 0, 0);
        cancellationDto.cancellationDate = formattedCancellationDate;
      }

      await this.subscriptionSvc.cancelSubscription(this.subscription.id, cancellationDto);

      this.snacks.open('Subscription cancelled successfully', 'Ok', { duration: 6000, panelClass: 'mat-primary' });
      this.dialogRef.close();
    } catch (error) {
      this.snacks.open('Subscription could not be cancelled', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
    }

    this.processing = false;
  }
}
