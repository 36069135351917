<greco-filter-bar [hideSeparator]="true" [filterOptions]="filterOptions" (changed)="query$.next($event[1])">
  <button
    *ngIf="canCreateBooking$ | async"
    mat-stroked-button
    color="primary"
    style="height: 32px; line-height: 16px"
    (click)="addToWaitlist()"
    [disabled]="event.status == 'CANCELLED'"
  >
    <mat-icon style="margin-left: -4px">add</mat-icon>
    <span>Add</span>
  </button>
</greco-filter-bar>

<ng-container *grecoLet="canRemoveUserFromEvent$ | async as canRemoveUserFromEvent">
  <greco-table [data]="(waitlist$ | async) || []" [loading]="loading">
    <mat-icon *grecoTableCol="''; let item; fitContent: true">people</mat-icon>
    <p *grecoTableCol="'User'; let item">{{ item.user?.displayName }}</p>
    <a *grecoTableCol="'Email Address'; let item" [href]="'mailto:' + (item.contactEmail || item.user?.email)">{{
      item.contactEmail || item.user?.email
    }}</a>
    <p *grecoTableCol="'Actions'; let item">
      <button
        [disabled]="!canRemoveUserFromEvent"
        (click)="openDialog(item.event?.communityId, item.user?.id, item.event?.id)"
        mat-icon-button
      >
        <mat-icon>delete_outline</mat-icon>
      </button>
    </p>
  </greco-table>
</ng-container>

<mat-paginator
  *ngIf="metadata?.totalItems"
  showFirstLastButtons
  [pageSizeOptions]="[10, 20, 50]"
  [length]="metadata!.totalItems || 0"
  [pageSize]="metadata!.itemsPerPage || 10"
  (page)="pagination$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
