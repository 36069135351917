<h4 style="margin-bottom: 0">
  {{
    mode === 'refer' ? 'Were you referred by a friend?' : 'Was this subscription transferred over from another user?'
  }}
</h4>

<ng-container *grecoLet="users$ | async as users">
  <mat-form-field class="picker" appearance="standard" floatLabel="always" style="width: 100%; margin-bottom: 8px">
    <mat-label>Email Address</mat-label>
    <input
      #userMenuTrigger="matMenuTrigger"
      #input
      matInput
      type="text"
      placeholder="Enter their email"
      [matMenuTriggerFor]="userMenu"
      [value]="selectedUser?.displayName"
      (input)="
        searchQuery$.next(input.value);
        users?.length === 1 ? userMenuTrigger.openMenu() : null;
        !input.value ? clearUser : emailSelected(input.value)
      "
    />
  </mat-form-field>

  <mat-menu #userMenu xPosition="after">
    <ng-container *ngIf="users?.length === 1; else noUser">
      <button *ngFor="let user of users" mat-menu-item (click)="selectUser($any(user))">
        <div style="display: flex; gap: 12px; align-items: center">
          <div style="width: 24px; height: 24px">
            <greco-smart-img round="true" [src]="user.photoURL || ''"></greco-smart-img>
          </div>

          <span [innerHTML]="user.displayName | safe : 'html'"></span>
          <mat-icon style="margin-left: auto; margin-right: 0">chevron_right</mat-icon>
        </div>
      </button>
    </ng-container>

    <ng-template #noUser>
      <div style="padding: 12px 16px; color: grey; max-width: 250px; text-align: center">
        <p style="margin-bottom: 0">
          No user found with that email. Make sure to type their full email.
          <ng-container *ngIf="mode === 'refer'">
            <br />
            <br />
            You can just type their email address here, and we'll keep track of your referral.
          </ng-container>
        </p>
      </div>
    </ng-template>
  </mat-menu>
</ng-container>
