<greco-collapsible-section #section [header]="{ title: 'Contact Information', icon: 'group' }">
  <greco-form-save-buttons
    *ngIf="section.expanded && !readonly"
    header
    [form]="form"
    [saveAction]="save"
    [resetValue]="resetValue"
    (formReset)="form.markAsPristine()"
  ></greco-form-save-buttons>

  <form [formGroup]="form">
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Community Member Number</mat-label>
      <input matInput type="text" placeholder="123456789" formControlName="memberNumber" [readonly]="readonly" />
      <button mat-icon-button (click)="openQR()" matSuffix>
        <mat-icon>qr_code_2</mat-icon>
      </button>
    </mat-form-field>
  </form>
</greco-collapsible-section>
