<greco-simple-dialog [data]="dialogData">
  <!-- <h3>{{this.saleCategory ? 'Update Sale Category' : 'Create Sale Category'}}</h3> -->
  <form [formGroup]="form" (ngSubmit)="submit()">
    <!-- Label -->
    <mat-form-field appearance="standard">
      <mat-label>Label</mat-label>
      <input matInput type="text" required formControlName="label" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>General Ledger Code</mat-label>
      <input matInput type="text" formControlName="generalLedgerCode" />
    </mat-form-field>

    <!-- Buttons -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button mat-stroked-button type="submit" color="primary" [disabled]="form.invalid || processing" style="flex: 1">
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="spin">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
