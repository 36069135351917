<alt-event-day-picker></alt-event-day-picker>

<ng-container *grecoLet="eventSvc.events$ | async as events">
  <ng-container *ngIf="events && ((eventSvc.eventsLoading$ | async) !== true); else loading">
    <ng-container *ngIf="events.length; else noEvents">
      <alt-event-item *ngFor="let event of events; trackBy: trackById" [event]="event"> </alt-event-item>
    </ng-container>

    <div class="buttons">
      <button mat-button [disabled]="moment(eventSvc.dateRange$.value[0]).isBefore(now)" (click)="prevDay()">
        <mat-icon>chevron_left</mat-icon>
        Previous Day
        <span *ngIf="isMobile$ | async" style="flex: 1"></span>
      </button>

      <button mat-button (click)="nextDay()">
        <span *ngIf="isMobile$ | async" style="flex: 1"></span>
        Next Day
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <ng-template #noEvents>
      <div class="events-empty-state">
        <svg width="480" height="280" viewBox="0 0 480 280" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask
            id="mask0_4945_372723"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="-200"
            width="480"
            height="480"
          >
            <rect width="480" height="480" transform="translate(0 -200)" fill="url(#paint0_radial_4945_372723)" />
          </mask>
          <g mask="url(#mask0_4945_372723)">
            <circle cx="240" cy="40" r="47.5" stroke="#EAECF0" />
            <circle cx="240" cy="40" r="79.5" stroke="#EAECF0" />
            <circle cx="240" cy="40" r="111.5" stroke="#EAECF0" />
            <circle cx="240" cy="40" r="143.5" stroke="#EAECF0" />
            <circle cx="240" cy="40" r="143.5" stroke="#EAECF0" />
            <circle cx="240" cy="40" r="175.5" stroke="#EAECF0" />
            <circle cx="240" cy="40" r="207.5" stroke="#EAECF0" />
            <circle cx="240" cy="40" r="239.5" stroke="#EAECF0" />
          </g>
          <defs>
            <radialGradient
              id="paint0_radial_4945_372723"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(240 240) rotate(90) scale(240 240)"
            >
              <stop />
              <stop offset="1" stop-opacity="0" />
            </radialGradient>
          </defs>
        </svg>

        <h2>No events found!</h2>
        <p style="margin-bottom: 0; color: grey">Your current selection did not match any events.</p>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <alt-event-item *ngFor="let i of [null, null, null, null, null]"></alt-event-item>
  </ng-template>
</ng-container>
