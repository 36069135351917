import { Component, Input, OnInit } from '@angular/core';
import { LegalDocument } from '@greco/platform-legal';
import { LegalService } from '../../services/legal.service';

@Component({
  selector: 'greco-legal-preview',
  templateUrl: './legal-preview.component.html',
  styleUrls: ['./legal-preview.component.scss'],
})
export class LegalPreviewComponent implements OnInit {
  @Input() doc!: 'terms' | 'privacy';

  public docContent: LegalDocument | null = null;

  constructor(public legal: LegalService) {}

  async ngOnInit() {
    this.docContent = this.doc === 'terms' ? await this.legal.getTermsOfService() : await this.legal.getPrivacyPolicy();
  }
}
