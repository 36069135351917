<ng-container *ngIf="form.dirty">
  <button
    mat-icon-button
    (click)="reset(); $event.stopPropagation()"
    matTooltip="Reset to original values"
    [disabled]="loading"
  >
    <mat-icon>restart_alt</mat-icon>
  </button>
  <button
    [disabled]="!form.valid || loading"
    mat-flat-button
    color="primary"
    style="height: 32px; line-height: 32px"
    (click)="submit(); $event.stopPropagation()"
  >
    <mat-icon *ngIf="loading; else notLoading || processing" class="spin">loop</mat-icon>
    <ng-template #notLoading>Save</ng-template>
  </button>
</ng-container>
