import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { SubscriptionAction, SubscriptionActionType } from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class SubscriptionActionsService {
  constructor(private http: HttpClient) {}

  // @Get()
  paginateActions(subscriptionId: string, query: RequestQueryBuilder, pagination?: PaginatedQueryParams) {
    return toPromise(
      this.http.get<PaginatedDto<SubscriptionAction>>(this.path(), {
        params: {
          subscriptionId,
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get('all')
  getAllActions(subscriptionId: string) {
    return toPromise(this.http.get<SubscriptionAction[]>(this.path('all'), { params: { subscriptionId } }));
  }

  // @Get('next')
  getNextAction(subscriptionId: string, type?: SubscriptionActionType) {
    return toPromise(
      this.http.get<SubscriptionAction>(this.path('next'), {
        params: {
          subscriptionId,
          ...(type && { type }),
        },
      })
    );
  }

  // @Get(':actionId')
  getAction(actionId: string) {
    return toPromise(this.http.get<SubscriptionAction>(this.path(actionId)));
  }

  private path(suffix?: string) {
    return '/api/subscription-actions' + (suffix && !suffix.startsWith('/') ? '/' + suffix : suffix || '');
  }
}
