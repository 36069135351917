<div *grecoLet="canUpdateSeries$ | async as canUpdateSeries" style="display: flex; flex-direction: column">
  <greco-collapsible-section
    #section
    *grecoLet="canUpdateEvent$ | async as canUpdateEvent"
    [expanded]="true"
    [header]="{ title: 'Event Details', icon: 'info' }"
  >
    <ng-container header>
      <ng-content select="[header]"></ng-content>
      <mat-chip style="background-color: var(--warn-color); color: white" *ngIf="event?.status == 'CANCELLED'">
        This event is cancelled
      </mat-chip>
    </ng-container>

    <button
      *ngIf="event?.zoomMeetingId || event?.zoomEvent?.meetingID"
      header
      mat-stroked-button
      color="primary"
      style="height: 32px; line-height: 16"
      (click)="$event.stopImmediatePropagation(); host()"
    >
      <mat-icon>videocam</mat-icon>
      <span>Host</span>
    </button>

    <!-- also write condition with && for permissions -->
    <button
      *ngIf="seriesId && !forSeries && canUpdateSeries"
      header
      mat-stroked-button
      color="primary"
      style="height: 32px; line-height: 16px"
      (click)="$event.stopImmediatePropagation(); editSeries()"
    >
      <mat-icon>edit</mat-icon>
      Edit Series
    </button>

    <button
      *ngIf="!hideCancelButton && (canCancel$ | async)"
      header
      mat-stroked-button
      color="warn"
      style="height: 32px; line-height: 16px"
      [disabled]="cancelling"
      (click)="$event.stopImmediatePropagation(); cancel()"
    >
      <span>Cancel</span>
      <mat-icon *ngIf="cancelling" class="spin">loop</mat-icon>
    </button>

    <greco-form-save-buttons
      header
      *ngIf="section.expanded && !readonly && (canUpdateEvent || (forSeries && canUpdateSeries))"
      [form]="detailsForm"
      [saveAction]="saveDetails"
      [resetValue]="{ details: resetDetailsValue }"
    ></greco-form-save-buttons>

    <form [formGroup]="detailsForm">
      <greco-event-details-input
        [required]="true"
        [eventId]="event?.id"
        [forSeries]="forSeries"
        [lockResources]="lockResources"
        [eventTemplate]="eventTemplate"
        [initialImage]="event?.imageUrl"
        [calendarId]="event?.calendar?.id || ''"
        [communityId]="event?.community?.id || ''"
        [readonly]="readonly || event?.status === 'CANCELLED'"
        [availableAsCourse]="event?.availableAsCourse || false"
        [isCompleted]="(event?.startDate?.getTime() || 0) < now"
        [customAccess]="(canUpdateCustomEvent$ | async) || (canUpdateCustomSeries$ | async) || false"
        [disabled]="!canUpdateEvent || event?.status === 'CANCELLED'"
        (resourceAvailability)="availability = $event"
        formControlName="details"
      ></greco-event-details-input>
    </form>
  </greco-collapsible-section>

  <!-- Series Schedule -->
  <ng-container *ngIf="forSeries">
    <greco-collapsible-section
      #schedule
      style="margin-top: 16px"
      [expanded]="false"
      [header]="{ title: 'Schedule', icon: 'schedule' }"
    >
      <greco-form-save-buttons
        header
        *ngIf="schedule.expanded && canUpdateSeries"
        [form]="scheduleForm"
        [saveAction]="saveSchedule"
        [resetValue]="{ series: resetScheduleValue }"
      ></greco-form-save-buttons>

      <form [formGroup]="scheduleForm">
        <greco-series-schedule-input
          #series
          [required]="false"
          [readonly]="!canUpdateSeries"
          [disabled]="!canUpdateSeries"
          [communityId]="event?.community?.id || ''"
          (scheduleChanged)="refreshAvailability($event)"
          formControlName="schedule"
        ></greco-series-schedule-input>
      </form>
    </greco-collapsible-section>
  </ng-container>
</div>
