import { Component, Input } from '@angular/core';
import { UserService } from '@greco/ngx-identity-auth';
import { interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'greco-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent {
  constructor(private userSvc: UserService) {}

  @Input() name = '';

  now$ = interval(1000).pipe(
    startWith(new Date()),
    map(() => new Date())
  );

  user$ = this.userSvc.user$;
}
