import { Component, HostBinding, Input } from '@angular/core';
import { User } from '@greco/identity-users';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'greco-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent {
  @HostBinding('class') @Input() size: 'small' | 'large' | 'default' = 'default';

  @Input() showOnlineStatus = true;

  @Input() set user(user: Pick<User, 'photoURL'> | null) {
    this._user$.next(user);
  }
  get user() {
    return this._user$.value;
  }

  @Input() set uid(uid: string | null) {
    this._uid$.next(uid);
  }
  get uid() {
    return this._uid$.value;
  }

  @Input() set defaultToCurrentUser(value: boolean) {
    this._defaultTo$.next(value);
  }
  get defaultToCurrentUser() {
    return this._defaultTo$.value;
  }

  private _uid$ = new BehaviorSubject<string | null>(null);
  private _user$ = new BehaviorSubject<Pick<User, 'photoURL'> | null>(null);
  private _defaultTo$ = new BehaviorSubject<boolean>(true);

  // user$: Observable<Pick<User, 'photoURL'> | null> = combineLatest([this._user$, this._uid$, this._defaultTo$]).pipe(
  //   switchMap(([user, uid, defaultTo]) => {
  //     return user ? of(user) : uid ? this.userSvc.getUser(uid) : defaultTo ? this.userSvc.user$ : of(null);
  //   })
  // );

  // constructor(private userSvc: UserService) {}
}
