<greco-collapsible-section [disabled]="true" [header]="{ title: 'Community Details', icon: 'info' }">
  <greco-form-save-buttons
    header
    [form]="formGroup"
    [resetValue]="resetValue"
    [saveAction]="save"
  ></greco-form-save-buttons>
  <!-- @Input() saveAction: (form: FormGroup) => void | Promise<void> = console.log;
    @Output() formReset = new EventEmitter<any>(); -->

  <form [formGroup]="formGroup">
    <mat-form-field appearance="standard">
      <mat-label>Name</mat-label>
      <input matInput required formControlName="name" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Email Address</mat-label>
      <input matInput required formControlName="email" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Phone Number</mat-label>
      <input matInput required type="tel" mask="(000) 000-0000" formControlName="phoneNumber" />
    </mat-form-field>

    <!-- <mat-form-field>
      <mat-label>Welcome Content</mat-label>
      <input matInput required formControlName="welcome" />
    </mat-form-field> -->

    <mat-form-field appearance="standard">
      <mat-label>Address</mat-label>
      <greco-address-input
        [zipRequired]="false"
        [strict]="true"
        required
        formControlName="address"
      ></greco-address-input>
    </mat-form-field>
  </form>
</greco-collapsible-section>

<greco-collapsible-section
  class="welcome-content"
  [expanded]="false"
  [header]="{ title: 'User Onboarding', icon: 'sticky_note_2' }"
>
  <greco-form-save-buttons
    header
    [form]="welcomeFormGroup"
    [resetValue]="welcomeResetValue"
    [saveAction]="welcomeSave"
  ></greco-form-save-buttons>

  <form [formGroup]="welcomeFormGroup" style="padding: 12px 16px">
    <mat-form-field appearance="standard">
      <mat-label>Invitation ID</mat-label>
      <p matPrefix><small>https://lf3.fit/</small>&nbsp;</p>
      <input matInput required formControlName="invitationId" />
      <button
        [disabled]="welcomeFormGroup.get('invitationId')?.dirty"
        mat-icon-button
        matSuffix
        (click)="copyInvitationLink()"
      >
        <mat-icon>link</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>External Registration Link</mat-label>
      <input matInput formControlName="externalRegistrationLink" />
      <button
        [disabled]="!this.community?.externalRegistrationLink"
        mat-icon-button
        matSuffix
        (click)="copyRegistrationLink()"
      >
        <mat-icon>link</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>External Registration Label (CTA)</mat-label>
      <input matInput formControlName="externalRegistrationLabel" />
    </mat-form-field>
    <div style="display: flex; gap: 8px 24px; flex-wrap: wrap">
      <mat-checkbox formControlName="browsable" color="primary">Browsable by Users</mat-checkbox>
      <mat-checkbox formControlName="inStudio" color="primary">Offers In-Studio Classes</mat-checkbox>
      <mat-checkbox formControlName="online" color="primary">Offers Online Classes</mat-checkbox>
      <mat-checkbox formControlName="pt" color="primary">Offers Private Training</mat-checkbox>
    </div>
  </form>
  <p style="padding: 4px 16px; font-size: 12px; margin-bottom: 0; color: rgba(14, 15, 15, 0.54)">Welcome Content</p>
  <greco-editor
    [class.dirty]="welcomeFormGroup.dirty"
    [data]="{
    value: welcomeFormGroup.get('welcomeContent')?.value || ''
  }"
    (valueChanged)="welcomeChanged($event.value)"
  ></greco-editor>
</greco-collapsible-section>

<greco-collapsible-section
  class="welcome-content"
  [expanded]="false"
  [header]="{ title: 'Payment settings', icon: 'receipt_long' }"
>
  <greco-form-save-buttons
    header
    [form]="paymentSettingsForm"
    [resetValue]="paymentSettingsResetValue"
    [saveAction]="paymentSettingsSave"
  ></greco-form-save-buttons>
  <form [formGroup]="paymentSettingsForm" style="padding: 12px 16px">
    <p>
      When a user has failed purchases, it will wait x days before their account is frozen (cannot make new purchases),
      where x is the input below.
    </p>
    <mat-form-field appearance="standard">
      <mat-label>Grace period</mat-label>
      <input matInput formControlName="gracePeriod" />
    </mat-form-field>
  </form>
</greco-collapsible-section>

<greco-account-page
  style="padding: 0"
  *ngIf="community?.financeAccount"
  [account]="community.financeAccount"
  [showGatewayFee]="false"
  [showDetails]="false"
></greco-account-page>
