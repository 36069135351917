import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import {
  Booking,
  BookingStatus,
  CalendarEvent,
  EventResourceAssignment,
  getCancellationPolicyInnerHtml,
  Resource,
  ResourceType,
  RoomResource,
} from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { heightExpansion } from '@greco/ui-animations';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { CalendarEvent as NgCalendarEvent, CalendarView } from 'angular-calendar';
import moment from 'moment';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { BookingInfoDialog } from '../../../dialogs';
import { BookingOptionService, BookingService } from '../../../services';

@Component({
  selector: 'greco-user-bookings-calendar',
  templateUrl: './user-bookings-calendar.component.html',
  styleUrls: ['./user-bookings-calendar.component.scss'],
  animations: [heightExpansion],
})
export class UserBookingsCalendarComponent {
  moment = moment;
  now = new Date();
  mobileBreakpoint$ = this.breakpointObs.observe('(max-width: 600px)');
  boosterConfig$ = this.bookingConfig.getBoosterConfig();

  constructor(
    private bookingConfig: BookingOptionService,
    private breakpointObs: BreakpointObserver,
    private bookingSvc: BookingService,
    private snackbar: MatSnackBar,
    private matDialog: MatDialog
  ) {}

  @PropertyListener('user') private _user$ = new ReplaySubject<User>(1);
  @Input() user!: User;

  @PropertyListener('showChildEvents') showChildEvents$ = new ReplaySubject<boolean>();
  showChildEvents = false;

  cancelling: { [id: string]: boolean } = {};
  loading = true;
  readonly MONTH_VIEW = CalendarView.Month;

  readonly dateRange$ = new ReplaySubject<[Date, Date]>(1);
  bookings: Booking[] = [];

  readonly bookings$: Observable<Booking[]> = combineLatest([this._user$, this.dateRange$]).pipe(
    distinctUntilChanged((p, n) => p[0]?.id === n[0]?.id && JSON.stringify(p[1] ?? []) === JSON.stringify(n[1] ?? [])),
    tap(() => (this.loading = true)),

    switchMap(async ([user, dateRange]) => {
      if (!user || !dateRange.length) return [];
      return await this.bookingSvc.getByDate({
        statuses: [BookingStatus.CONFIRMED, BookingStatus.PENDING, BookingStatus.CHECKED_IN],
        includeLinkedAccountBookings: true,
        startDate: dateRange[0],
        endDate: dateRange[1],
        userId: user.id,
      });
    }),

    tap(value => (this.bookings = value)),
    tap(() => (this.loading = false))
  );

  readonly calendarEvents$: Observable<NgCalendarEvent[]> = combineLatest([this.bookings$, this.showChildEvents$]).pipe(
    map(([bookings, showChildEvents]) => {
      const b = showChildEvents ? bookings : bookings.filter(booking => booking.user.id === this.user.id);
      return b.map(booking => this._transformBooking(booking));
    })
  );

  matchBooking(id: string) {
    return this.bookings
      .filter(bkg => bkg.id === id)
      .map(bkg => {
        return { createdBy: bkg?.createdBy, user: bkg.user };
      })[0];
  }

  private _transformBooking(booking: Booking): NgCalendarEvent {
    return {
      start: booking.event.startDate,
      end: booking.event.endDate,
      title: booking.event.title,
      id: booking.id,
      color: { primary: booking.event.color || 'var(--color-primary)', secondary: 'transparent' },
      meta: booking,
    };
  }

  async cancelBooking(booking: Booking) {
    this.cancelling[booking.id] = true;

    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Cancel Booking',
          subtitle: `${booking.event.title} - ${moment(booking.event.startDate).format('ll hh:mm A')}`,
          content: `
            ${getCancellationPolicyInnerHtml(booking.bookingOption)}
            <p>Are you sure you wish to cancel your booking?<p>
          `,
          buttons: [
            { label: 'No, keep my booking', role: 'no' },
            { label: 'Yes, cancel my booking', role: 'yes' },
          ],
          showCloseButton: false,
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        await this.bookingSvc.cancel(booking.id);
        this.snackbar.open('Your booking has been cancelled.', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } catch (err: any) {
      let msg = 'Error cancelling your booking!.';
      if (err.error.error === 'Conflict' && err.error.message.includes("with status 'CANCELLED'")) {
        msg += 'Already Cancelled';
      }
      this.snackbar.open(msg, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    } finally {
      this.dateRange$.next(await toPromise(this.dateRange$));
    }

    this.cancelling[booking.id] = false;
  }

  async openBookingDetails(booking: Booking) {
    await toPromise(
      this.matDialog
        .open(BookingInfoDialog, {
          data: {
            booking,
          },
        })
        .afterClosed()
    );
    this.dateRange$.next(await toPromise(this.dateRange$));
  }

  async join(booking: Booking) {
    if (booking.status === BookingStatus.CONFIRMED) {
      this.bookingSvc
        .checkIn(booking.id)
        .then(b => {
          booking = b;
        })
        .catch(err => console.error(err));
      // FIXME: Prevent users from joining if check-in fails.
    }

    const joinUrl = booking.event.zoomMeetingId
      ? `https://zoom.us/j/${booking.event.zoomMeetingId}`
      : booking.event.zoomEvent?.joinUrl;

    if (joinUrl) window.open(joinUrl, '_blank');
  }

  getPersonResources(assignments: EventResourceAssignment[]) {
    return assignments.reduce((acc, assignment) => {
      if (assignment.resource?.type === ResourceType.PERSON) acc.push(assignment.resource);
      return acc;
    }, [] as Resource[]);
  }

  getRoom(event: CalendarEvent) {
    const assignment = event?.resourceAssignments?.find(assignment => assignment?.resource?.type === ResourceType.ROOM);
    return (assignment?.resource as RoomResource) || false;
  }

  getSpot(room: RoomResource, spotId: string) {
    return room.spots?.find(spot => spot.id === spotId) || null;
  }

  getSpotName(room: RoomResource, spotId: string) {
    const spot = this.getSpot(room, spotId);
    if (spot) return ' - ' + spot.name;
    else return '';
  }
}
