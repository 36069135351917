import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'greco-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss'],
})
export class AlertCardComponent {
  @Input() icon?: string;
  @Input() title!: string;
  @Input() subtitle?: string;
  @Input() color: 'error' | 'warn' | 'primary' | 'secondary' | 'tertiary' = 'warn';
  @Input() small = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter();
  @Input() clickable = false;

  clicked(event: any) {
    if (this.clickable) this.click.emit(event);
  }
}
