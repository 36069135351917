import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import type { CreateCommunityDto, UpdateCommunityDto } from '@greco/nestjs-identity-communities';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommunityServiceModule } from './community.module';

@Injectable({ providedIn: CommunityServiceModule })
export class CommunityService {
  constructor(private http: HttpClient) {}

  static mapInvitationId = map((c: Community) =>
    c
      ? {
          ...c,
          invitationId: c.invitationId ? decodeURIComponent(c.invitationId) : c.invitationId,
        }
      : c
  );

  private communityIds: { [accountId: string]: ReplaySubject<string | undefined> } = {};

  async getCommunity(communityId: string): Promise<Community> {
    return toPromise(
      this.http.get<Community>(`/api/communities/${communityId}`).pipe(CommunityService.mapInvitationId)
    );
  }

  async getCommunityByAccountId(accountId: string): Promise<Community> {
    return toPromise(this.http.get<Community>(`/api/communities/account/${accountId}`));
  }

  getCommunityIdByAccountId(accountId: string) {
    if (!this.communityIds[accountId]) {
      this.communityIds[accountId] = new ReplaySubject(1);
      this.getCommunityByAccountId(accountId).then(com => this.communityIds[accountId].next(com?.id));
    }

    return this.communityIds[accountId].asObservable();
  }

  async paginateCommunities(
    options: Pick<PaginatedQueryParams, 'limit' | 'page'>,
    parentId?: string
  ): Promise<PaginatedDto<Community>> {
    return toPromise(
      this.http
        .get<PaginatedDto<Community>>('/api/communities', {
          params: {
            ...(parentId ? { parentId } : {}),
            ...(options?.page ? { page: options.page.toString() } : {}),
            ...(options?.limit ? { limit: options.limit.toString() } : {}),
          },
        })
        .pipe(
          map(data => {
            return data
              ? {
                  ...data,
                  items: data.items.map(c => ({
                    ...c,
                    invitationId: c.invitationId ? decodeURIComponent(c.invitationId) : c.invitationId,
                  })),
                }
              : data;
          })
        )
    );
  }

  async createCommunity(createCommunityDto: CreateCommunityDto): Promise<Community> {
    return toPromise(
      this.http.post<Community>('/api/communities', createCommunityDto).pipe(CommunityService.mapInvitationId)
    );
  }

  async updateCommunity(communityId: string, updateCommunityDto: UpdateCommunityDto): Promise<Community> {
    return toPromise(
      this.http
        .put<Community>(`/api/communities/${communityId}`, updateCommunityDto)
        .pipe(CommunityService.mapInvitationId)
    );
  }

  async getAllCommunities(): Promise<Community[]> {
    const communities: Community[] = [];

    let page = await this.paginateCommunities({ limit: 100, page: 1 });
    while (page?.items?.length) {
      communities.push(...page.items);
      page = await this.paginateCommunities({ limit: 100, page: page.meta.currentPage + 1 });
    }

    return communities;
  }
}
