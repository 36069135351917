<form [formGroup]="form">
  <div style="display: flex;">
    <input id="main-input" matInput autocomplete="off" type="search" [placeholder]="placeholder || 'Date'" [matDatepicker]="startDate" formControlName="start" />
    <mat-datepicker #startDate></mat-datepicker>

    <div style="height: 18px; display: flex; align-items: center; margin-top: calc(-0.84375em / 3);">
      <mat-chip-list>
        <mat-chip style="margin: 0; margin-right: 8px; cursor: pointer; user-select: none;" (click)="frequencySelect.toggle()">
          <span *grecoLet="form.get('frequency') as frequency" style="margin-left: 4px;">{{ frequency.value | titlecase }}</span>
          <mat-icon style="margin-right: -4px;">arrow_drop_down</mat-icon>
          <mat-select #frequencySelect required formControlName="frequency" style="position: absolute; width: 0; height: 0; opacity: 0; pointer-events: none;">
            <mat-option value="once">Once</mat-option>
            <mat-option value="daily">Daily</mat-option>
            <mat-option value="weekly">Weekly</mat-option>
            <mat-option value="monthly">Monthly</mat-option>
            <mat-option value="yearly">Yearly</mat-option>
          </mat-select>
        </mat-chip>
      </mat-chip-list>

      <mat-datepicker-toggle [for]="startDate"></mat-datepicker-toggle>
    </div>
  </div>

  <div *ngIf="expanded$ | async as expanded">
    <div [class.my2]="expanded">
      <mat-divider [class.focused]="expanded && focused" [class.primary-border]="expanded && focused"></mat-divider>
    </div>
    <div class="sub-form">
      <mat-form-field class="schedule-input">
        <mat-label>Until</mat-label>
        <input matInput placeholder="Until" autocomplete="off" type="search" [matDatepicker]="untilDate" formControlName="until" />
        <mat-datepicker #untilDate></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="untilDate"></mat-datepicker-toggle>
        <mat-error>Invalid date</mat-error>
      </mat-form-field>

      <mat-form-field class="schedule-input">
        <mat-label>Advanced Options</mat-label>
        <input matInput autocomplete="off" type="search" placeholder="Advanced Options" formControlName="rrule" />
        <!-- <a
          matSuffix
          mat-icon-button
          type="button"
          style="display: flex; align-items: center; justify-content: center;"
          href="https://jakubroztocil.github.io/rrule/"
          target="_blank"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>open_in_new</mat-icon>
        </a> -->
        <mat-error>Invalid options</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
