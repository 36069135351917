import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { ZoomAccountInfo } from '@greco/zoom';
import { ResourceTag } from './resource-tag';

export enum ResourceType {
  PERSON = 'PERSON',
  ROOM = 'ROOM',
  OTHER = 'OTHER',
  ZOOM = 'ZOOM',
}

export enum ResourceAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  TENTATIVE = 'TENTATIVE',
  BUSY = 'BUSY',
}

export interface BaseResource {
  id: string;
  groupId: string;

  type: ResourceType;

  community: Community;

  name: string;
  disabled: boolean;
  description?: string;
  photoURL?: string;
  icon?: string;

  resourceTags: ResourceTag[];

  hideFromFilters?: boolean;
}

export type Resource = PersonResource | RoomResource | OtherResource | ZoomResource;

export interface PersonResource extends BaseResource {
  type: ResourceType.PERSON;
  user?: User;
}

export interface RoomResource extends BaseResource {
  type: ResourceType.ROOM;

  spots?: RoomResourceSpot[];
  spotCount?: number;

  imageURL?: string;
}

export interface RoomResourceSpot {
  id: string;
  roomId: string;

  created: Date;
  modified: Date;

  spotNumber: number;

  name: string;
  description?: string;

  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

export interface OtherResource extends BaseResource {
  type: ResourceType.OTHER;
}

export interface ZoomResource extends BaseResource {
  type: ResourceType.ZOOM;
  zoomAccount?: ZoomAccountInfo;
}
