import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase } from '@greco/sales-purchases';
import {
  Subscription,
  SubscriptionActionType,
  SubscriptionResource,
  SubscriptionResourceAction,
} from '@greco/sales-subscriptions';
import { ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  CancelSubscriptionDialog,
  ChangePaymentMethodDialog,
  SetSubscriptionReferredByDialog,
  SetSubscriptionSoldByDialog,
  SetSubscriptionTransferredFromDialog,
  UpdateMinimumCommitmentDialog,
  UpdateSubscriptionDialog,
} from '../../dialogs';
import { SubscriptionActionsService } from '../../services';

@Component({
  selector: 'greco-subscription-options',
  templateUrl: './subscription-options.component.html',
  styleUrls: ['./subscription-options.component.scss'],
})
export class SubscriptionOptionsComponent {
  constructor(
    private actionSvc: SubscriptionActionsService,
    private securitySvc: SecurityService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar
  ) {}

  @Input() canCancelSubscriptions?: boolean;
  @Input() canUpdateSubscriptions?: boolean;

  @Input() hidePaymentMethodOption = false;
  @Input() hideViewDetailsOption = false;

  @Input() subscription!: Subscription;
  @Input() communityId!: string;

  @Input() purchase: Purchase | null = null;

  @Output() paymentMethodUpdated = new EventEmitter<Subscription>();
  @Output() subscriptionCancelled = new EventEmitter<Subscription>();
  @Output() subscriptionUpdated = new EventEmitter<Subscription>();
  @Output() refresh = new EventEmitter();

  cancelling = false;
  updating = false;
  updatingPaymentMethod = false;

  @PropertyListener('subscription') private _subscription$ = new ReplaySubject<Subscription>(1);
  isScheduledToCancel$ = this._subscription$.pipe(
    switchMap(sub => this.actionSvc.getNextAction(sub.id, SubscriptionActionType.CANCELLATION)),
    map(action => !!action)
  );

  allowBankPayments$ = this._subscription$.pipe(
    switchMap(async sub => {
      if (sub) {
        return sub.paymentTypes?.some(type => type === 'bank') || false;
      } else return false;
    })
  );

  canManageSoldBy$ = this.securitySvc.hasAccess(
    SubscriptionResource.key,
    SubscriptionResourceAction.MANAGE_SOLD_BY,
    {},
    true
  );

  canManageReferredBy$ = this.securitySvc.hasAccess(
    SubscriptionResource.key,
    SubscriptionResourceAction.MANAGE_REFERRED_BY,
    {},
    true
  );

  canManageTransferredFrom$ = this.securitySvc.hasAccess(
    SubscriptionResource.key,
    SubscriptionResourceAction.MANAGE_TRANSFERRED_FROM,
    {},
    true
  );

  canManageMinimumCommitment$ = this.securitySvc.hasAccess(
    SubscriptionResource.key,
    SubscriptionResourceAction.MANAGE_MINIMUM_COMMITMENT,
    {},
    true
  );

  async cancel() {
    this.cancelling = true;
    await toPromise(this.matDialog.open(CancelSubscriptionDialog, { data: this.subscription }).afterClosed());
    this.subscriptionCancelled.emit(this.subscription);
    this.cancelling = false;
  }

  async update() {
    this.updating = true;
    await toPromise(
      this.matDialog
        .open(UpdateSubscriptionDialog, { data: { mode: 'staff', subscription: this.subscription } })
        .afterClosed()
    );
    this.subscriptionUpdated.emit(this.subscription);
    this.updating = false;
  }

  async changePaymentMethodDialog(subscription: Subscription) {
    try {
      await toPromise(
        this.matDialog
          .open(ChangePaymentMethodDialog, {
            data: { subscription, user: subscription.user },
            width: '400px',
            maxWidth: '90%',
          })
          .afterClosed()
      );
      this.paymentMethodUpdated.emit(this.subscription);
    } catch (e) {
      this.snacks.open('Oops something went wrong. Please try again later.', 'Ok', {
        duration: 5000,
        panelClass: 'mat-warn',
      });
    }
  }

  async setSoldBy(subscription: Subscription) {
    const result = await toPromise(
      this.matDialog.open(SetSubscriptionSoldByDialog, { data: subscription }).afterClosed()
    );
    if (result) this.refresh.emit();
  }

  async setReferredBy(subscription: Subscription) {
    const result = await toPromise(
      this.matDialog.open(SetSubscriptionReferredByDialog, { data: subscription }).afterClosed()
    );
    if (result) this.refresh.emit();
  }

  async setTransferredFrom(subscription: Subscription) {
    const result = await toPromise(
      this.matDialog.open(SetSubscriptionTransferredFromDialog, { data: subscription }).afterClosed()
    );
    if (result) this.refresh.emit();
  }

  async updateMinimumCommitmentDialog(subscription: Subscription) {
    const result = await toPromise(
      this.matDialog.open(UpdateMinimumCommitmentDialog, { data: { subscription }, maxWidth: '90%' }).afterClosed()
    );
    if (result) this.refresh.emit();
  }
}
