<greco-legal-preview doc="terms">
  <a header-end mat-icon-button routerLink="terms">
    <mat-icon>create</mat-icon>
  </a>
</greco-legal-preview>
<greco-legal-preview doc="privacy">
  <a header-end mat-icon-button routerLink="privacy">
    <mat-icon>create</mat-icon>
  </a>
</greco-legal-preview>
