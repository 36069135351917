import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  AgreementType,
  CommunityAgreement,
  CommunityAgreementSecurityActions,
  CommunityAgreementSecurityResource,
} from '@greco/community-agreements';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
// import { CommunityAgreementService } from '@greco/nest-community-agreements';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommunityAgreementsService } from '../../services';
// import {} from '@greco/'

@Component({
  selector: 'greco-community-agreement-details',
  templateUrl: './community-agreement-details.component.html',
  styleUrls: ['./community-agreement-details.component.scss'],
})
// , ControlValueAccessor
export class CommunityAgreementDetailsComponent implements OnInit {
  agreementTypeArray = [AgreementType.CHECKBOX, AgreementType.AUTO_CHECKBOX, AgreementType.DIGITAL_SIGNATURE];
  initialValue: any;
  saving = false;
  selectedValue = '';

  constructor(
    private formBuilder: FormBuilder,
    private agreementSvc: CommunityAgreementsService,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private securitySvc: SecurityService
  ) {}

  @Output() updated = new EventEmitter<CommunityAgreement>(true);
  @Input() communityId!: string;

  @PropertyListener('agreement') agreement$ = new BehaviorSubject<CommunityAgreement | null>(null);
  @Input() agreement!: CommunityAgreement;

  readonly canUpdate$ = this.agreement$.pipe(
    switchMap(async agreement => {
      return agreement
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.UPDATE,
            { communityId: agreement.communityId }
          )
        : false;
    })
  );

  readonly canArchive$ = this.agreement$.pipe(
    switchMap(async agreement => {
      return agreement
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.ARCHIVE,
            { communityId: agreement.communityId }
          )
        : false;
    })
  );

  readonly canRead$ = this.agreement$.pipe(
    switchMap(async agreement => {
      return agreement
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.READ,
            { communityId: agreement.communityId }
          )
        : false;
    })
  );

  formGroup = this.formBuilder.group({
    title: ['', Validators.required],
    text: ['', Validators.required],
    agreementType: ['', Validators.required],
    archivedDate: [''],
  });

  onSave = async () => {
    this.saving = true;

    try {
      this.agreement = await this.agreementSvc.updateAgreement(this.agreement.id, {
        ...this.formGroup.value,
      });

      this.snacks.open(this.agreement.title + ' updated!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      this.updated.emit(this.agreement);
      this.initialValue = this.formGroup.value;
      this.formGroup.markAsPristine();
    } catch (err) {
      console.error(err);
      // TODO: Report error to user
    }

    this.saving = false;
  };

  async ngOnInit() {
    this.initialValue = {
      title: this.agreement.title,
      text: this.agreement.text,
      agreementType: this.agreement.agreementType,
      archivedDate: this.agreement.archivedDate,
    };
    this.formGroup.reset(this.initialValue);
    if (this.agreement.archivedDate != null) {
      this.formGroup.disable();
    }
  }

  valueChanged(text: string) {
    if (this.formGroup.get('text')?.value !== text) {
      this.formGroup.get('text')?.setValue(text);
      this.formGroup.markAsDirty();
    }
  }

  selectorValueChange(type: string) {
    if (this.formGroup.get('agreementType')?.value !== type) {
      this.formGroup.get('agreementType')?.setValue(type);
      this.formGroup.markAsDirty();
      this.selectedValue = type;
    }
  }

  async archiveAgreement() {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to archive the agreement - <strong>${this.agreement.title}</strong>.</p><p><small><b>Are you sure you want to continue?</b></small></p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      const archiveResult = await this.agreementSvc.archiveAgreement(this.agreement.id);
      if (!archiveResult['success']) {
        await this.snacks.open(archiveResult['response'], 'Ok', { duration: 5000 });

        const removeUsage = await toPromise(
          this.dialog
            .open(SimpleDialog, {
              data: {
                title: 'Delete Agreement Usage',
                content: `<p><strong>${this.agreement.title}</strong> has following usage.</p><p>Please verify & delete individual agreement usage in order to archive this agreement.</p><br><p><b>This action is NOT reversible!</b></p>`,
                buttons: [
                  { label: 'Cancel', role: 'cancel' },
                  { label: 'Archive', role: 'archive' },
                ],
              },
            })
            .afterClosed()
        );
        if (removeUsage === 'archive') {
          const archiveAgreement = await this.agreementSvc.archiveAgreement(this.agreement.id);
          if (archiveAgreement) {
            this.snacks.open(this.agreement.title + ' archived successfully!', 'Ok', { duration: 3000 });
            await this.router.navigate(['..'], { relativeTo: this.route });
          } else this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3000 });
        }
      } else {
        this.snacks.open(this.agreement.title + ' archived successfully!', 'Ok', { duration: 3000 });
        await this.router.navigate(['..'], { relativeTo: this.route });
      }
    }
  }
}
