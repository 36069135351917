import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { BrowseCommunitiesDialog, CommunityService } from '@greco/ngx-identity-communities';
import { ContactService } from '@greco/ngx-identity-contacts';
import { LegalService } from '@greco/ngx-platform-legal';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { tap } from 'rxjs/operators';
import { ReferralService } from '../../services/referral.service';

@Component({
  selector: 'greco-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  loading = true;
  user$ = this.userService.user$.pipe(tap(() => (this.loading = false)));
  community: Community | null = null;

  hasTerms = false;
  hasPrivacy = false;

  fromReferral = true;

  signInSuccess = async (_: any, __: Router) => {
    await this.subscribeUser(this.community?.id || 'com_greco', await toPromise(this.user$));
  };

  beforeSignIn = () => true;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    public legalService: LegalService,
    private contactSvc: ContactService,
    private communitySvc: CommunityService,
    private referralService: ReferralService,
    private crumbs: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.hasTerms = !!(await this.legalService.getTermsOfService());
    this.hasPrivacy = !!(await this.legalService.getPrivacyPolicy());
    setTimeout(() => this.crumbs.set([]));

    const referral = this.referralService.getReferral();
    if (referral) {
      const referralInfo = await this.referralService.processReferral(referral);
      if (referralInfo?.type === 'Community') {
        this.community = referralInfo.community;
        this.fromReferral = true;
        const user = await toPromise(this.user$);
        await this.subscribeUser(this.community.id, user);
      }
    } else this.fromReferral = false;

    if (!this.community) {
      this.community = await this.communitySvc.getCommunity('com_greco');
    }
  }

  async subscribeUser(communityId: string | null, user: User | null) {
    if (user?.id && communityId) {
      let result = await this.contactSvc.subscribeUserToCommunity(communityId, user.id);
      const subscriptions = await this.contactSvc.getUserContacts(user.id);

      const createdLast5Minutes = Date.now() - user.created.getTime() < 5 * 60 * 1000;
      if (!subscriptions || (subscriptions.length === 1 && createdLast5Minutes)) {
        if (communityId === 'com_greco') {
          const newCommunityId = await toPromise(this.dialog.open(BrowseCommunitiesDialog).afterClosed());
          if (newCommunityId && newCommunityId !== communityId) {
            result = await this.contactSvc.subscribeUserToCommunity(newCommunityId, user.id);
          }
        }

        if (result && typeof result !== 'boolean') {
          await this.router.navigate(['welcome', result.community.id]);
        }
      }

      this.referralService.resetReferral();
    }
  }

  communitySelected(community: Community) {
    this.community = community;
  }
}
