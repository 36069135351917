import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { PaymentDispute } from '@greco/finance-payments';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'greco-payment-dispute-page',
  templateUrl: './payment-dispute.page.html',
  styleUrls: ['./payment-dispute.page.scss'],
})
export class PaymentDisputePage {
  @ViewChild('subscriptionInfo') subscriptionInfo!: TemplateRef<any>;

  @Input() expanded = false;

  readonly _paymentDispute$ = new BehaviorSubject<PaymentDispute | null>(null);
  @Input() set paymentDispute(paymentDispute: PaymentDispute | null) {
    this._paymentDispute$.next(paymentDispute);
  }
  get paymentDispute() {
    return this._paymentDispute$.value;
  }

  readonly _accountId$ = new BehaviorSubject<string | null>(null);
  @Input() set accountId(accountId: string | null) {
    this._accountId$.next(accountId);
  }
  get accountId() {
    return this._accountId$.value;
  }
}
