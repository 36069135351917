import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@greco/ngx-identity-auth';
import { AddonsService, InventoryService, ProductAgreementAddonsService } from '@greco/ngx-sales-products';
import { PropertyListener } from '@greco/property-listener-util';
import {
  AddonType,
  InventoryProductAddon,
  Product,
  ProductAgreementUsage,
  ProductStatus,
  ProductVariant,
} from '@greco/sales-products';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ShopService } from '../../services/shop.service';

@Component({
  selector: 'greco-product',
  templateUrl: './product.page.html',
  styleUrls: ['./product.page.scss'],
})
export class ProductPage implements OnInit {
  constructor(
    private shopSvc: ShopService,
    private addonSvc: AddonsService,
    private usageSvc: ProductAgreementAddonsService,
    private userSvc: UserService,
    private inventorySvc: InventoryService
  ) {}
  inventoryLoading = true;

  @Input() product: Product | null = null;
  @PropertyListener('product') private product$ = new BehaviorSubject<Product | null>(null);

  user$ = this.userSvc.user$;

  @Input() selectedVariantId?: string;
  @PropertyListener('selectedVariantId') private selectedVariantId$ = new BehaviorSubject<string | undefined>(
    undefined
  );

  selectedVariant$: Observable<ProductVariant | null> = combineLatest([this.product$, this.selectedVariantId$]).pipe(
    map(([product, variantId]) =>
      product
        ? product.variants.find(v => v.id === variantId) ||
          product.variants.filter(v => !v.isHidden && v.status === ProductStatus.Active)[0]
        : null
    ),
    map(v => (v ? { ...v, variantPerks: v?.variantPerks.filter(p => p.status === ProductStatus.Active) } : null)),
    shareReplay(1)
  );

  inventoryAddon$ = this.product$.pipe(
    switchMap(async product => {
      if (!product) return null;
      return (await this.addonSvc.getOneByType(product.id, AddonType.Inventory)) as InventoryProductAddon;
    })
  );

  variantInventory$ = combineLatest([this.selectedVariant$, this.inventoryAddon$]).pipe(
    tap(() => (this.inventoryLoading = true)),
    switchMap(async ([variant, addon]) => {
      if (!variant || !addon) {
        return null;
      }
      this.inventoryLoading = false;

      if (!addon.enabled) return null;

      return await this.inventorySvc.getVariantInventory(variant.id);
    })
  );

  agreements: ProductAgreementUsage[] = [];
  canBuy = true;

  async ngOnInit() {
    if (this.product) {
      const addon = await this.addonSvc.getOneByType(this.product.id, AddonType.Agreement);
      if (addon) {
        this.agreements = await this.usageSvc.getManyByProdAddonId(addon.id);
      }
    }
  }
}
