import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { PropertyListener } from '@greco/property-listener-util';
import { Purchase } from '@greco/sales-purchases';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  RefundPurchaseDialog,
  RetryFailedPurchaseDialog,
  SetPurchaseReferredByDialog,
  SetPurchaseSoldByDialog,
  VoidPurchaseDialog,
} from '../../dialogs';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-purchase-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class PurchaseOptionsComponent implements OnDestroy {
  constructor(private dialog: MatDialog, private snacks: MatSnackBar, private purchaseSvc: PurchaseService) {}

  @PropertyListener('isSuper') isSuper$ = new BehaviorSubject(false);
  @Input() isSuper = false;

  @PropertyListener('canVoid') canVoid$ = new BehaviorSubject(false);
  @Input() canVoid = false;

  @PropertyListener('canRetry') canRetry$ = new BehaviorSubject(false);
  @Input() canRetry = false;

  @PropertyListener('canManageSoldBy') canManageSoldBy$ = new BehaviorSubject(false);
  @Input() canManageSoldBy = false;

  @PropertyListener('canManageReferredBy') canManageReferredBy$ = new BehaviorSubject(false);
  @Input() canManageReferredBy = false;

  @PropertyListener('canRefundToBalance') canRefundToBalance$ = new BehaviorSubject(false);
  @Input() canRefundToBalance = false;

  @PropertyListener('canRefundToPaymentMethod') canRefundToPaymentMethod$ = new BehaviorSubject(false);
  @Input() canRefundToPaymentMethod = false;

  @PropertyListener('purchase') private purchase$ = new BehaviorSubject<Purchase | null>(null);
  @Input() purchase!: Purchase;

  @Input() showOpenOption = false;
  @Input() disabled = false;

  @Output() refresh = new EventEmitter();

  canRefund$ = combineLatest([this.purchase$, this.canRefundToBalance$, this.canRefundToPaymentMethod$]).pipe(
    map(([purchase, canRefundToBalance, canRefundToPaymentMethod]) => {
      if (!canRefundToBalance && !canRefundToPaymentMethod) return false;
      if (!canRefundToBalance && !purchase?.payment) return false;
      return true;
    })
  );

  ngOnDestroy(): void {
    this.isSuper$.complete();
    this.canVoid$.complete();
    this.canRetry$.complete();
    this.purchase$.complete();
    this.canManageSoldBy$.complete();
    this.canRefundToBalance$.complete();
    this.canRefundToPaymentMethod$.complete();
  }

  async refundPurchase(purchase: Purchase) {
    await toPromise(this.dialog.open(RefundPurchaseDialog, { data: purchase }).afterClosed());
    this.refresh.emit();
  }

  async sendAdhocEmailReceipt(purchase: Purchase) {
    try {
      await this.purchaseSvc.sendPurchaseReceipt(purchase.id);

      this.snacks.open('Email with purchase receipt sent!', 'Ok', {
        panelClass: 'mat-primary',
        duration: 3000,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async sendPurchaseFailedEmail(purchase: Purchase) {
    try {
      await this.purchaseSvc.sendPurchaseFailedEmail(purchase.id);

      this.snacks.open('Purchase Failed Email sent!', 'Ok', {
        panelClass: 'mat-primary',
        duration: 3000,
      });
    } catch (err) {
      console.error(err);
    }
  }

  async voidPurchase(purchase: Purchase) {
    const closed = await toPromise(this.dialog.open(VoidPurchaseDialog, { data: purchase }).afterClosed());
    if (closed.submit) this.refresh.emit();
  }

  async retryFailedPurchase(purchase: Purchase) {
    await toPromise(this.dialog.open(RetryFailedPurchaseDialog, { data: purchase }).afterClosed());
    this.refresh.emit();
  }

  async setSoldBy(purchase: Purchase) {
    const result = await toPromise(this.dialog.open(SetPurchaseSoldByDialog, { data: purchase }).afterClosed());
    if (result) this.refresh.emit();
  }

  async setReferredBy(purchase: Purchase) {
    const result = await toPromise(this.dialog.open(SetPurchaseReferredByDialog, { data: purchase }).afterClosed());
    if (result) this.refresh.emit();
  }
}
