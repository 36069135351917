<div class="greco-typeform-form-picker-container">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let form of value"
      selectable
      [removable]="!disabled"
      (removed)="removeForm(form)"
      (click)="updateform(form)"
      style="cursor: pointer"
      [disabled]="disabled"
    >
      {{ form.formTitle }}
      <mat-icon *ngIf="!disabled" matChipRemove>cancel</mat-icon>
    </mat-chip>

    <input
      #input
      matInput
      type="text"
      [disabled]="disabled"
      [required]="required"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      [matChipInputFor]="chipList"
      (input)="search(input.value)"
      (blur)="touched()"
    />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event); input.value = ''">
      <mat-option *ngFor="let form of _forms$ | async" [value]="form">
        {{ form.formTitle }}
      </mat-option>
    </mat-autocomplete>
  </mat-chip-list>
</div>
