<greco-simple-dialog [data]="dialogData" *ngIf="community$ | async as community">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <!-- Title -->
    <mat-form-field style="width: 100%">
      <mat-label>Title</mat-label>
      <input matInput type="text" required formControlName="title" />
    </mat-form-field>

    <!--Sale Category-->
    <mat-form-field floatLabel="always" appearance="standard" style="width: 100%">
      <mat-label>Sales Category</mat-label>
      <greco-sale-category-input
        [accountId]="accountId"
        formControlName="saleCategory"
        [required]="true"
      ></greco-sale-category-input>
    </mat-form-field>

    <!-- Description -->
    <mat-form-field style="width: 100%">
      <mat-label>Description</mat-label>
      <input matInput type="text" formControlName="description" />
    </mat-form-field>

    <!-- Taxes -->
    <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
      <mat-label>Taxes</mat-label>
      <greco-account-taxes-picker
        formControlName="taxes"
        [accountId]="community.financeAccountId"
        [disabled]="ignoreTaxes.checked"
      >
        <mat-checkbox #ignoreTaxes formControlName="ignoreTaxes" color="primary">No Taxes</mat-checkbox>
      </greco-account-taxes-picker>
    </mat-form-field>

    <!-- Buttons -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button mat-stroked-button type="submit" color="primary" [disabled]="form.invalid || processing" style="flex: 1">
        <span>Confirm</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
