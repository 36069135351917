<ng-content select="[agreementButton]"></ng-content>
<greco-table
  id="test"
  [data]="(agreements$ | async) || []"
  [highlight]="true"
  [loading]="loading"
  (rowClick)="canView($event)"
>
  <p *grecoTableCol="'Title'; let agreement">
    {{ agreement.signedAgreementTitle ? agreement.signedAgreementTitle : 'Unknown Agreement' }}
  </p>

  <p *grecoTableCol="'Created'; let agreement">{{ agreement.created | date : 'EEE' }} {{ agreement.created | date }}</p>

  <p *grecoTableCol="'Signed'; let agreement">{{ agreement.status | titlecase }}</p>

  <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let agreement">
    <div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
      <mat-icon
        #menuTrigger="matMenuTrigger"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="$event.stopImmediatePropagation()"
      >
        more_vert
      </mat-icon>
    </div>

    <mat-menu #menu>
      <ng-container *ngIf="agreement.status === 'SIGNED'; else notSigned">
        <button mat-menu-item (click)="downloadPDF(agreement)">
          <mat-icon>get_app</mat-icon>
          <span>Download</span>
        </button>

        <button mat-menu-item (click)="previewPDF(agreement)">
          <mat-icon>open_in_browser</mat-icon>
          <span>Preview</span>
        </button>
      </ng-container>

      <ng-template #notSigned>
        <button mat-menu-item (click)="signAgreement(agreement)">
          <mat-icon>edit</mat-icon>
          <span>Sign Agreement</span>
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="currentPagination?.totalItems"
  showFirstLastButtons
  [length]="currentPagination!.totalItems || 0"
  [pageSize]="currentPagination!.itemsPerPage || pageSizes[0]"
  [pageSizeOptions]="pageSizes"
  (page)="paginatedParams$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
