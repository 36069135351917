import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { Calendar, CalendarSecurityResource, CalendarSecurityResourceAction } from '@greco/booking-events';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CalendarService } from '../../services/calendar.service';

@Component({
  selector: 'greco-calendars-table',
  templateUrl: './calendars-table.component.html',
  styleUrls: ['./calendars-table.component.scss'],
})
export class CalendarsTableComponent implements OnDestroy {
  constructor(
    private snacks: MatSnackBar,
    private calendarSvc: CalendarService,
    private securitySvc: CommunitySecurityService
  ) {}

  @PropertyListener('event') private _event$ = new ReplaySubject<Calendar>(1);
  @Input() event!: Calendar;

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId?: string;

  @PropertyListener('filters') private _filters$ = new BehaviorSubject<RequestQueryBuilder | undefined>(undefined);
  @Input() filters?: RequestQueryBuilder;

  @Output() rowClick = new EventEmitter<Calendar>();

  refresh$ = new BehaviorSubject(null);

  loading = true;
  metadata?: IPaginationMeta;

  readonly canDelete$ = this._communityId$.pipe(
    switchMap(async communityId =>
      communityId
        ? await this.securitySvc.hasAccess(
            communityId,
            CalendarSecurityResource.key,
            CalendarSecurityResourceAction.DELETE
          )
        : false
    )
  );

  readonly pagination$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});
  readonly calendars$ = combineLatest([this._communityId$, this._filters$, this.pagination$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([communityId, filters, pagination]) => {
      //removing deleted from parsed.search and setting the value to parsed.includeDeleted
      if (filters) {
        const parsedFilters = JSON.parse(filters?.queryObject.s || '{}');
        if (parsedFilters.$and) {
          const deletedFilter = parsedFilters.$and.findIndex((item: { deleted: any }) => !!item.deleted);
          if (deletedFilter != -1 && parsedFilters.$and[deletedFilter].deleted.$eq === true)
            filters?.setIncludeDeleted(1);
          if (deletedFilter != -1) parsedFilters.$and.splice(deletedFilter);
          filters.queryObject.s = JSON.stringify(parsedFilters);
        }
      }

      return communityId
        ? await this.calendarSvc.paginate(communityId, filters || new RequestQueryBuilder(), pagination)
        : null;
    }),
    tap(data => (this.metadata = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  ngOnDestroy() {
    this._communityId$.complete();
    this._filters$.complete();
    this.pagination$.complete();
  }

  refresh() {
    this.refresh$.next(null);
  }

  async archiveCalendar(calendar: Calendar) {
    await this.calendarSvc.delete(calendar).then((res: any) => {
      if (res?.name === 'Error') {
        this.snacks.open(res?.message, 'Ok!', { duration: 5000, panelClass: 'snack-error' });
      } else this.snacks.open('Calendar Archived', 'Ok!', { duration: 5000, panelClass: 'mat-primary' });
    });
    this.refresh();
  }

  async restoreCalendar(calendar: Calendar) {
    await this.calendarSvc.restore(calendar).then((res: any) => {
      if (res?.name === 'Error') {
        this.snacks.open(res?.message, 'Ok!', { duration: 5000, panelClass: 'snack-error' });
      } else this.snacks.open('Calendar Restored', 'Ok!', { duration: 5000, panelClass: 'mat-primary' });
    });
    this.refresh();
  }
}
