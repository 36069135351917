import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SaleCategory, SaleCategoryResource, SaleCategoryResourceAction } from '@greco/sales-purchases';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { SaleCategoryService } from '../../services';

type NString = string | null | undefined;
@Component({
  selector: 'greco-sale-category-input',
  templateUrl: './sale-category-input.component.html',
  styleUrls: ['./sale-category-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SaleCategoryInputComponent }],
})
export class SaleCategoryInputComponent implements MatFormFieldControl<SaleCategory>, ControlValueAccessor, OnDestroy {
  private static ID = 0;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private categorySvc: SaleCategoryService,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef,
    private securitySvc: SecurityService,
    private snacks: MatSnackBar
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  private onDestroy$ = new Subject<void>();
  @Input() accountId?: string;
  @PropertyListener('accountId') private accountId$ = new BehaviorSubject<NString>(null);

  _form = this.formBuilder.group({
    search: [null],
    category: [null],
  });

  get currentSearchValue() {
    return (this._form.get('search')?.value as string) || null;
  }

  readonly _categories$ = combineLatest([
    this.categoryControl.valueChanges.pipe(startWith(null)),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._form.get('search')!.valueChanges.pipe(startWith(null)),
    this.accountId$,
  ]).pipe(
    switchMap(async ([current, search, accountId]) => {
      let categories = await this.categorySvc.getManySecured(accountId || undefined, search);
      if (current) categories = categories.filter(category => current.id !== category.id);
      return categories;
    })
  );

  @ViewChild(MatInput) private matInput?: MatInput;

  private get categoryControl() {
    return this._form.get('category') as AbstractControl;
  }

  readonly stateChanges = new Subject<void>();

  readonly controlType = 'greco-sale-category-input';
  @HostBinding() readonly id = `${SaleCategoryInputComponent.ID++}-${this.controlType}`;

  _inputValue = '';

  set value(value: SaleCategory | null) {
    const currentValue = this.categoryControl.value;
    if (currentValue?.id !== value?.id) {
      this.categoryControl.setValue(value);
      if (value) this._inputValue = value.label;
      this.stateChanges.next();
    }
  }
  get value(): SaleCategory | null {
    return this.categoryControl.value;
  }

  get focused() {
    return !!this.matInput?.focused;
  }

  get empty() {
    const control = this._form.get('category');
    return !!control?.value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    this.categoryControl.setValidators(this._required ? [Validators.required, Validators.minLength(1)] : []);
    this._form.updateValueAndValidity();

    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;

    this.categoryControl;

    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return this.categoryControl.invalid && this._form.touched;
  }

  readonly canCreate$ = this.accountId$.pipe(
    switchMap(async accountId => {
      return accountId
        ? await this.securitySvc.hasAccess(SaleCategoryResource.key, SaleCategoryResourceAction.CREATE, { accountId })
        : null;
    })
  );

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-sale-category-input-container');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.matInput?.onContainerClick();
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.categoryControl.valueChanges.pipe(startWith(null), takeUntil(this.onDestroy$)).subscribe(() => fn(this.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_fn: any) {}

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
  }

  removeCategory() {
    this.value = null;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.stateChanges.complete();
  }
}
