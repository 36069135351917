import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Booking, BookingStatus } from '@greco/booking-events';
import { EventWithUserDetails, SpotDetails } from '@greco/booking-events2';
import { User } from '@greco/identity-users';
import { BookingService } from '@greco/ngx-booking-events';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { EventService } from '../../services';

@Component({
  selector: 'alt-attendee-card',
  templateUrl: './attendee-card.component.html',
  styleUrls: ['./attendee-card.component.scss'],
})
export class AttendeeCardComponent {
  constructor(
    private eventSvc: EventService,
    public bottomSheet: MatBottomSheet,
    private bookingSvc: BookingService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @Input() event!: EventWithUserDetails;
  @Input() booking!: Booking;
  @Input() isStaffView = true;

  moment = moment;
  now = new Date();

  selectedSpot: SpotDetails | null = null;
  initialSpotId = this.booking?.spotId;

  confirming = false;

  isMobile$ = this.breakpointObserver.observe('(max-width: 600px)').pipe(map(bps => bps.matches));

  async cancel(freeOfCharge: boolean) {
    this.confirming = true;
    try {
      await this.eventSvc.cancelBooking(this.booking.id, freeOfCharge);
    } catch (err) {
      console.error(err);
    }
    this.confirming = false;
  }

  async join(booking: Booking) {
    if (booking.status === BookingStatus.CONFIRMED) {
      this.bookingSvc
        .checkIn(booking.id)
        .then(b => {
          booking = b;
        })
        .catch(err => console.error(err));
      // FIXME: Prevent users from joining if check-in fails.
    }

    const joinUrl = booking.event.zoomMeetingId
      ? `https://zoom.us/j/${booking.event.zoomMeetingId}`
      : booking.event.zoomEvent?.joinUrl;

    if (joinUrl) window.open(joinUrl, '_blank');
  }

  updateSelectedSpot(spot: SpotDetails) {
    this.selectedSpot = spot;
  }

  async updateSpot(booking: Booking) {
    if (!this.selectedSpot) return;

    await this.bookingSvc.updateSpot(booking.id, this.selectedSpot.spotId);

    this.updateAttendees(booking, this.selectedSpot.spotId == 'general' ? '' : this.selectedSpot.spotId);
    this.updateBookedSpots(booking.user, this.selectedSpot);

    this.selectedSpot = null;
  }

  updateAttendees(booking: Booking, spotId: string) {
    const bookings = this.eventSvc.attendees$.value;
    const userBooking = bookings.find(eventBooking => eventBooking.user.id === booking.user.id);
    if (!userBooking) return;

    const spot = this.event.requirements.spotBooking?.room.spots?.find(spot => spot.id === spotId);
    userBooking.spot = spot;
    userBooking.spotId = spotId;

    const bookingIndex = bookings.findIndex(eventBooking => eventBooking.user.id === booking.user.id);
    bookings[bookingIndex] = userBooking;

    this.eventSvc.attendees$.next(bookings);
  }

  updateBookedSpots(user: User, spot: SpotDetails) {
    const bookedSpots = this.eventSvc.bookedSpots$.value;
    const userSpotIndex = bookedSpots.findIndex(bookedSpot => bookedSpot.userId === user.id);

    if (spot.spotId === 'general') {
      this.eventSvc.bookedSpots$.next(bookedSpots.filter(bookedSpot => bookedSpot.userId !== user.id));
      return;
    }

    if (userSpotIndex !== -1) {
      bookedSpots[userSpotIndex].spotId = spot.spotId;
      bookedSpots[userSpotIndex].spotName = spot.spotName;
      bookedSpots[userSpotIndex].spotNumber = spot.spotNumber;
      bookedSpots[userSpotIndex].spotDescription = spot.spotDescription;

      this.eventSvc.bookedSpots$.next(bookedSpots);
    } else {
      const bookedSpot: SpotDetails = {
        ...spot,
        userId: user.id,
        photoUrl: user.photoURL || '',
      };

      this.eventSvc.bookedSpots$.next([...bookedSpots, bookedSpot]);
    }
  }
}
