<greco-simple-dialog [data]="dialogData">
  <greco-perk-badge
    before-header-content
    style="margin-right: 16px"
    [text]="formGroup.value.label || perk.badge.text"
    [color]="formGroup.value.color || perk.badge.color"
    [shape]="perk.badge.shape"
    [icon]="formGroup.value.icon || perk.badge.icon"
  ></greco-perk-badge>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="standard">
      <mat-label>Badge Color</mat-label><input matInput type="color" formControlName="color" style="width: 100%" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Badge Text</mat-label><input matInput formControlName="label" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Badge Icon</mat-label><input matInput formControlName="icon" />
      <mat-hint>
        <a href="https://fonts.google.com/icons" target="_blank">
          Material Icons <mat-icon style="width: 10px; height: 10px; font-size: 10px">launch</mat-icon>
        </a>
      </mat-hint>
    </mat-form-field>
  </form>
</greco-simple-dialog>
