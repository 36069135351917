<form [formGroup]="_form">
  <div class="fields-section">
    <h3>Quick Access Details</h3>
    <mat-form-field [appearance]="readonly ? 'fill' : 'standard'">
      <mat-label>Label</mat-label>
      <input
        placeholder="Enter a label for the tile"
        matInput
        type="text"
        required
        formControlName="label"
        [readonly]="readonly"
      />
    </mat-form-field>
    <mat-form-field [appearance]="readonly ? 'fill' : 'standard'">
      <mat-label>Priority</mat-label>
      <input placeholder="Priority" matInput type="number" required formControlName="priority" [readonly]="readonly" />
    </mat-form-field>

    <mat-form-field [appearance]="readonly ? 'fill' : 'standard'">
      <mat-label>URL</mat-label>
      <input placeholder="Enter the page URL" matInput type="text" formControlName="url" [readonly]="readonly" />
    </mat-form-field>

    <mat-form-field [appearance]="readonly ? 'fill' : 'standard'">
      <mat-label>GroupName</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        formControlName="group"
        [matAutocomplete]="auto"
        [readonly]="readonly"
      />
      <mat-icon
        *ngIf="showUpdateGroup"
        class="editGroup"
        matSuffix
        (click)="manageTileGroups(); $event.stopImmediatePropagation()"
        >mode_edit</mat-icon
      >
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let group of groupsOptions | async" [value]="group.name">
          {{ group.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-hint class="hint" *ngIf="isMatch">{{ 'This is an existing group' }}</mat-hint>
    </mat-form-field>

    <mat-form-field floatLabel="always" [appearance]="readonly ? 'fill' : 'standard'" style="width: 100%">
      <mat-label>Image</mat-label>
      <greco-image-upload
        [required]="true"
        formControlName="image"
        [urls]="initialUrls"
        [multiple]="false"
        [disabled]="readonly"
      ></greco-image-upload>
    </mat-form-field>
  </div>
</form>
