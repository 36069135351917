import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventSeries, EventSeriesInstance } from '@greco/booking-events';

@Component({
  selector: 'greco-review-instances-dialog',
  templateUrl: './review-instances.dialog.html',
  styleUrls: ['./review-instances.dialog.scss'],
})
export class ReviewInstancesDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { instances: EventSeriesInstance[]; series: EventSeries },
    private dialogRef: MatDialogRef<ReviewInstancesDialog>
  ) {
    this.cancellations = data.instances.reduce((acc, evt) => ({ ...acc, [evt.id]: true }), {});
  }

  readonly cancellations: { [key: string]: boolean };

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(
      this.data.instances.map(instance => ({
        cancel: this.cancellations[instance.id],
        instanceId: instance.id,
      }))
    );
  }
}
