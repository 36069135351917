<div class="flex">
  <div class="flex flex-auto">
    <input
      #addressInput
      matInput
      type="search"
      placeholder="123 Street"
      [value]="value?.formatted"
      ngx-google-places-autocomplete
      [options]="{ componentRestrictions: { country: 'CA' } }"
      (change)="value = null"
      (onAddressChange)="value = mapAddress($event)"
      (focus)="focused = true"
      (blur)="focused = false; addressInput.value = value?.formatted ? value.formatted : null"
    />
  </div>
  <button mat-icon-button type="button" (click)="expanded = !expanded" style="margin-top: -15px; margin-bottom: -7px">
    <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
  </button>
</div>
<div [@bodyExpansion]="expanded ? 'expanded' : 'collapsed'">
  <div [class.my2]="expanded">
    <mat-divider [class.focused]="expanded && focused" [class.primary-border]="expanded && focused"></mat-divider>
  </div>
  <form class="sub-form" [formGroup]="form">
    <mat-form-field>
      <mat-label>Address Line 1</mat-label>
      <input #street matInput formControlName="line1" required autocomplete="shipping address-line1" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Address Line 2</mat-label>
      <input #street2 matInput formControlName="line2" autocomplete="shipping address-line2" />
    </mat-form-field>
    <div class="flex" style="gap: 8px">
      <mat-form-field class="flex-auto">
        <mat-label>City</mat-label>
        <input matInput formControlName="city" required autocomplete="shipping address-level2" />
      </mat-form-field>
      <mat-form-field class="flex-auto">
        <mat-label>State/Province</mat-label>
        <input matInput formControlName="state" required autocomplete="shipping address-level1" />
      </mat-form-field>
    </div>
    <div class="flex" style="gap: 8px">
      <mat-form-field style="flex: 1">
        <mat-label>Zip/Postal Code</mat-label>
        <input matInput formControlName="postalCode" required autocomplete="shipping postal-code" />
      </mat-form-field>
      <greco-country-select
        [value]="form.get('country').value"
        (onCountrySelected)="form.get('country').setValue($event)"
        style="flex: 1"
        required
      ></greco-country-select>
    </div>
  </form>
</div>
