import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'alt-profile-completion-requirement',
  templateUrl: './profile-completion.component.html',
  styleUrls: ['./profile-completion.component.scss'],
})
export class ProfileCompletionRequirementComponent {
  constructor(private userSvc: UserService, private eventSvc: EventService, public bottomSheet: MatBottomSheet) {}

  @Output() bottomSheetOpened = new EventEmitter<TemplateRef<any>>();

  @PropertyListener('user') private _user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;

  @Input() required = true;

  @Output() refreshed = new EventEmitter<void>();

  refresh$ = new BehaviorSubject<void>(undefined);

  user$ = combineLatest([this._user$, this.refresh$]).pipe(
    map(([user]) => user),
    switchMap(user => (user && !user.address?.line1 ? this.userSvc.getUser(user.id) : user ? of(user) : of(null))),
    tap(user => {
      if (!user) return;

      const index = this.eventSvc.profilesCompleted$.value.findIndex(value => value.userId == user?.id);
      if (index == -1) {
        this.eventSvc.profilesCompleted$.value.push({ userId: user.id, completed: !!user?.address?.line1 });
      } else {
        this.eventSvc.profilesCompleted$.value[index].completed = !!user?.address?.line1;
      }

      this.eventSvc.profilesCompleted$.next(this.eventSvc.profilesCompleted$.value);
    }),
    shareReplay(1)
  );

  refresh() {
    this.refresh$.next();
    this.refreshed.emit();
  }

  saved() {
    this.bottomSheet.dismiss();
    this.refresh();
  }

  emitBottomSheetOpened(bottomSheetRef: TemplateRef<any>) {
    this.bottomSheetOpened.emit(bottomSheetRef);
  }
}
