import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { LegalDocument, UpdateLegalDto } from '@greco/platform-legal';
import { SimpleDialog } from '@greco/ui-dialog-simple';

@Injectable({
  providedIn: 'root',
})
export class LegalService {
  constructor(private http: HttpClient, private dialog: MatDialog, private snackbar: MatSnackBar) {}

  async openTerms() {
    const terms = await this.getTermsOfService();
    if (terms)
      this.dialog.open(SimpleDialog, {
        data: {
          title: terms.title,
          subtitle: 'Last Modified › ' + terms.updated.toDateString(),
          content: terms.text,
        },
      });
    else
      this.snackbar.open('Unable to load Terms of Service!', 'Ok', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
  }

  async openPrivacy() {
    const privacy = await this.getPrivacyPolicy();
    if (privacy)
      this.dialog.open(SimpleDialog, {
        data: {
          title: privacy.title,
          subtitle: 'Last Modified › ' + privacy.updated.toDateString(),
          content: privacy.text,
        },
      });
    else
      this.snackbar.open('Unable to load Privacy Policy!', 'Ok', {
        duration: 2500,
        panelClass: 'mat-warn',
      });
  }

  getTermsOfService() {
    return toPromise(this.http.get<LegalDocument>('/api/legal/terms'));
  }

  getPrivacyPolicy() {
    return toPromise(this.http.get<LegalDocument>('/api/legal/privacy'));
  }

  updateTermsOfService(text: string) {
    return toPromise(
      this.http.post<UpdateLegalDto>('/api/legal/terms', {
        text,
      })
    );
  }

  updatePrivacyPolicy(text: string) {
    return toPromise(
      this.http.post<UpdateLegalDto>('/api/legal/privacy', {
        text,
      })
    );
  }
}
