<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>Promotions!</mat-label>
    <mat-select multiple formControlName="promos">
      <mat-option
        *ngFor="let promo of promotions || []"
        [value]="promo"
        [disabled]="form.value.promos.length === 1 && form.value.promos.includes(promo)"
        >{{promo.title}}</mat-option
      >
    </mat-select>
  </mat-form-field>
</form>
<ng-container *ngFor="let promo of form.value.promos || []">
  <greco-promotions-carousel [promotion]="promo"></greco-promotions-carousel>
</ng-container>
