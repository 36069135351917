<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form">
    <mat-form-field style="width: 100%" floatLabel="always">
      <mat-label>Find a Variant</mat-label>
      <greco-product-variant-picker
        placeholder="Start typing to search for variants..."
        [communityId]="data?.promotion?.community?.id || null"
        [required]="true"
        [multiselect]="true"
        formControlName="variants"
      ></greco-product-variant-picker>
    </mat-form-field>
  </form>
</greco-simple-dialog>
