import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { PurchaseService } from '@greco/ngx-sales-purchases';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-user-alerts',
  templateUrl: './user-alerts.component.html',
  styleUrls: ['./user-alerts.component.scss'],
})
export class UserAlertsComponent {
  constructor(
    private purchaseSvc: PurchaseService,
    private paymentMethodSvc: UserPaymentMethodService,
    private breakpoints: BreakpointObserver
  ) {}

  _isMobile$ = this.breakpoints.observe('(max-width: 1000px)').pipe(
    map(({ matches }) => matches),
    shareReplay(1)
  );

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;
  failedPurchases$ = combineLatest([this.user$]).pipe(
    switchMap(async ([user]) => {
      const userId: string | undefined = user?.id;
      return await this.purchaseSvc.paginate(
        new RequestQueryBuilder().search({
          status: 'FAILED',
        }),
        { limit: 3 },
        {
          userId,
        }
      );
    })
  );

  paymentMethods$ = this.user$.pipe(
    switchMap(async user => {
      if (!user) return [];

      const paymentMethodIdsInUse = await this.paymentMethodSvc.getUsedPaymentMethods(user.id);
      const defaultPaymentMethod = await this.paymentMethodSvc.getDefault(user.id, true);

      const paymentMethods: PaymentMethod[] = [];
      for (const methodId of paymentMethodIdsInUse) {
        const payMethod = await this.paymentMethodSvc.getById(methodId);
        paymentMethods.push(payMethod);
      }

      const defaultExist = paymentMethods.some(method => method.id === defaultPaymentMethod?.id);
      if (!defaultExist && defaultPaymentMethod) paymentMethods.push(defaultPaymentMethod);

      const expiringPaymentMethods: { paymentMethod: PaymentMethod; expiresOn: Date; expired: boolean }[] = [];
      paymentMethods.forEach(paymentMethod => {
        if (paymentMethod.details) {
          const now = new Date();
          const monthYear = paymentMethod.details.split('/');

          const expiresOn = new Date(monthYear[0] + '/01/' + monthYear[1]);
          const expired = expiresOn.getTime() < now.getTime();
          const cutoff = new Date(now.setMonth(now.getMonth() + 3));

          if (expiresOn.getTime() <= cutoff.getTime()) {
            expiringPaymentMethods.push({ paymentMethod, expiresOn, expired });
          }
        }
      });

      return expiringPaymentMethods.sort((a, b) => Number(b.expired) - Number(a.expired));
    })
  );
}
