import { Component, ComponentFactoryResolver, Inject, OnInit, Type, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentMethodFormComponent } from '../../components/add-payment-method-button/payment-method-forms.token';
import { AccountPaymentMethodService } from '../../services/account-payment-methods.service';
import { UserPaymentMethodService } from '../../services/user-payment-methods.service';
import { CreatePaymentMethodFormContainerDirective } from './create-payment-method.directive';

export interface CreatePaymentMethodDialogData {
  userId?: string;
  accountId?: string;
  gatewayId: string;
  form: Type<PaymentMethodFormComponent>;
}

@Component({
  selector: 'greco-create-payment-method-dialog',
  templateUrl: './create-payment-method.dialog.html',
  styleUrls: ['./create-payment-method.dialog.scss'],
})
export class CreatePaymentMethodDialog implements OnInit {
  constructor(
    private snacks: MatSnackBar,
    private factoryResolver: ComponentFactoryResolver,
    private userPaymentMethods: UserPaymentMethodService,
    private dialogRef: MatDialogRef<CreatePaymentMethodDialog>,
    private accountPaymentMethods: AccountPaymentMethodService,
    @Inject(MAT_DIALOG_DATA) private data: CreatePaymentMethodDialogData
  ) {}

  @ViewChild(CreatePaymentMethodFormContainerDirective, { static: true })
  private container: undefined | CreatePaymentMethodFormContainerDirective;

  private component: undefined | PaymentMethodFormComponent;

  saving = false;

  get isValid() {
    return this.component?.formGroup?.valid;
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async save() {
    this.saving = true;

    try {
      if (this.component) {
        const data = await this.component.save();
        let paymentMethod = null;
        if (this.data.userId) {
          paymentMethod = await this.userPaymentMethods.create({
            gatewayId: this.data.gatewayId,
            userId: this.data.userId,
            ...data,
          });
        } else if (this.data.accountId) {
          paymentMethod = await this.accountPaymentMethods.create({
            gatewayId: this.data.gatewayId,
            accountId: this.data.accountId,
            ...data,
          });
        }

        this.close(paymentMethod);
      } else this.close();
    } catch (err: any) {
      console.error(err);
      this.snacks.open('Error adding payment method! ' + (err?.message ? err.message : err), 'Ok', {
        duration: 6000,
        panelClass: 'mat-warn',
      });
      this.close();
    }
  }

  ngOnInit() {
    if (this.data?.form && this.container) {
      const factory = this.factoryResolver.resolveComponentFactory(this.data.form);
      const componentRef = this.container.viewRef.createComponent<PaymentMethodFormComponent>(factory);
      this.component = componentRef.instance;

      if (this.data.userId) this.component.userId = this.data.userId;
      else if (this.data.accountId) this.component.accountId = this.data.accountId;
      else this.close();
    }
  }
}
