import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Coupon, CouponSecurityAction, CouponSecurityResource } from '@greco/coupon';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CouponAssignmentService } from '../../services';

@Injectable({ providedIn: 'any' })
export class CouponAssignmentSearchFilter extends BuildSearchFilter('CouponAssignmentSearchFilter', {
  properties: ['title', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Component({
  selector: 'greco-coupon-assignment-table',
  templateUrl: './coupon-assignment-table.component.html',
  styleUrls: ['./coupon-assignment-table.component.scss'],
})
export class CouponAssignmentTableComponent {
  constructor(
    private couponAssignmentSvc: CouponAssignmentService,
    private route: ActivatedRoute,
    private snacks: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('coupon') private _coupon$ = new ReplaySubject<Coupon>(1);
  @Input() coupon!: Coupon;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  filterOptions = [CouponAssignmentSearchFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  paginationMeta?: null | IPaginationMeta = null;
  refresh$ = new BehaviorSubject(null);
  loading = true;

  assignments$ = combineLatest([this._coupon$, this.pagination$, this.filters$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([coupon, pagination, filters]) =>
      coupon?.community?.id && coupon.id
        ? await this.couponAssignmentSvc.paginate(coupon.id, pagination, filters)
        : null
    ),
    tap(data => (this.paginationMeta = data?.meta || null)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  readonly canRead$ = this._coupon$.pipe(
    switchMap(async coupon => {
      return coupon?.community?.id
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.READ, {
            communityId: coupon.community.id,
          })
        : false;
    })
  );

  readonly canCreate$ = this._coupon$.pipe(
    switchMap(async coupon => {
      return coupon?.community?.id
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.CREATE, {
            communityId: coupon.community.id,
          })
        : false;
    })
  );

  readonly canUpdate$ = this._coupon$.pipe(
    switchMap(async coupon => {
      return coupon?.community?.id
        ? await this.securitySvc.hasAccess(CouponSecurityResource.key, CouponSecurityAction.UPDATE, {
            communityId: coupon.community.id,
          })
        : false;
    })
  );

  async _removeAssignment(assignmentId: string, assignmentTitle: string) {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to remove the coupon assignment to - <strong>${assignmentTitle}</strong>.</p><p><small><b>Are you sure you want to continue?</b></small></p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      const archiveResult = await this.couponAssignmentSvc.archive(assignmentId);
      if (archiveResult) {
        this.snacks.open('Assignment removed successfully!', 'Ok', { duration: 3500 });
        this.refresh$.next(null);
      } else {
        this.snacks.open('Something went wrong. Please try again!', 'Ok', { duration: 3500 });
      }
    }
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }
}
