import { Component, Input } from '@angular/core';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { UserPerkDetails } from '../user-perks-grid/user-perks-grid.component';

@Component({
  selector: 'greco-user-perk-box',
  templateUrl: './user-perk-box.component.html',
  styleUrls: ['./user-perk-box.component.scss'],
})
export class UserPerkBoxComponent {
  @PropertyListener('userPerk') userPerks$ = new BehaviorSubject<UserPerkDetails[] | null>(null);
  @Input() userPerks!: UserPerkDetails[];

  @Input() type?: string;

  expanded = false;

  toggleExpand() {
    this.expanded = !this.expanded;
  }
}
