import { CondOperator, RequestQueryBuilder, SCondition, SFields } from '@nestjsx/crud-request';

export type FilterType = 'text' | 'select' | 'date' | 'toggle' | 'autocomplete' | 'multiautocomplete';

export interface FilterOptions {
  type: FilterType;
  canOpen?: boolean;

  label: string;
  shortLabel: string;

  description: string;
  overwriteDescriptionOnSearch?: boolean;

  properties: string[];
  allowedOperators: CondOperator[];

  onlyOne?: boolean;

  hideCond?: boolean;
}

export abstract class Filter {
  type: FilterType;
  canOpen: boolean;

  label: string;
  shortLabel: string;

  description: string;
  overwriteDescriptionOnSearch: boolean;

  properties: string[];
  allowedOperators: CondOperator[];

  onlyOne = false;

  hideCond = false;

  constructor(public readonly typeName: string, options: FilterOptions) {
    this.type = options.type;
    this.canOpen = options.canOpen ?? true;

    this.label = options.label;
    this.shortLabel = options.shortLabel;

    this.description = options.description;
    this.allowedOperators = options.allowedOperators;

    this.properties = options.properties;
    this.overwriteDescriptionOnSearch = options.overwriteDescriptionOnSearch ?? true;

    this.onlyOne = options.onlyOne || false;

    this.hideCond = options.hideCond || false;
  }

  abstract getValueLabel(value: any): string;
  abstract getValueOptions(search?: string): any[] | Promise<any[]>;

  abstract serializeValue(value: any): string;
  abstract deserializeValue(serializedValue: string): any | Promise<any>;

  formatAsQueryParam(operator: CondOperator, value: any): string {
    return `[${this.typeName}]||[${operator}]||[${this.serializeValue(value)}]`;
  }

  getQueryBuilderSearchOptions(operator: CondOperator, value: any): SCondition {
    // This uses the 'getPropertySField' of the filter, not the method below this one
    return this.properties.length === 1
      ? this.getPropertySField(this.properties[0], operator, value)
      : { $or: this.properties.map(property => this.getPropertySField(property, operator, value)) };
  }

  getPropertySField(property: string, operator: CondOperator, value: any): SFields {
    return { [property]: { [operator]: value } };
  }

  getQueryBuilder(operator: CondOperator, value: any): RequestQueryBuilder {
    return RequestQueryBuilder.create({
      search: {
        $and: [
          {
            $or: this.properties.map(property => ({
              [property]: {
                [operator]: value,
              },
            })),
          },
        ],
      },
    });
  }
}
