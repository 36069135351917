import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { EventTemplateResourceAssignment, ResourceTag, RoomResource, Tag } from '@greco/booking-events';
import type { FormEntity } from '@greco/nestjs-typeform';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface EventTemplateDetails {
  color?: string | null;
  title?: string | null;
  titleLocked: boolean;
  imageUrl?: string | null;
  imageUrlLocked: boolean;
  colorLocked: boolean;
  description?: string | null;
  descriptionLocked: boolean;
  tags?: Tag[] | null;
  tagsLocked: boolean;
  resourceTags?: ResourceTag[] | null;
  resourceAssignments?: EventTemplateResourceAssignment[] | null;
  autoAssign: boolean;
  resourcesLocked: boolean;
  enableUserSpotBooking: boolean;
  enableUserSpotBookingLocked: boolean;
  duration?: number | null;
  durationLocked: boolean;
  checkInWindow?: number | null;
  checkInWindowLocked: boolean;
  maxCapacity?: number | null;
  maxCapacityLocked: boolean;
  private?: boolean;
  privateLocked: boolean;
  typeform?: (FormEntity & { reusable: boolean; required: boolean })[];
  typeformLocked: boolean;
  roomAssignment?: EventTemplateResourceAssignment | null;
  zoomAssignment?: EventTemplateResourceAssignment | null;
  zoomMeetingId?: string | null;
}

@Component({
  selector: 'greco-event-templates-input',
  templateUrl: './event-templates-input.component.html',
  styleUrls: ['./event-templates-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventTemplatesInputComponent),
      multi: true,
    },
  ],
})
export class EventTemplatesInputComponent implements ControlValueAccessor, OnDestroy {
  constructor(private formBuilder: FormBuilder) {
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.onChanged?.(this.value));
  }

  private onChanged?: (value: EventTemplateDetails | null) => void;
  private onTouched?: () => void;

  private _onDestroy$ = new Subject<void>();

  readonly stateChanges = new Subject<void>();

  _form = this.formBuilder.group({
    color: ['#005574', []],
    title: ['', [Validators.required]],
    titleLocked: [false],
    imageUrl: [null],
    imageUrlLocked: [false],
    colorLocked: [false],
    description: ['', []],
    descriptionLocked: [false],
    tags: [[], []],
    tagsLocked: [false],
    resourceAssignments: [[], []],
    resourcesLocked: [false],
    autoAssign: [false],
    enableUserSpotBooking: [false],
    enableUserSpotBookingLocked: [false],
    duration: [45, []],
    durationLocked: [false],
    checkInWindow: [null, []],
    checkInWindowLocked: [false],
    maxCapacity: [[Validators.min(0), Validators.pattern(/\d+/)]],
    maxCapacityLocked: [false],
    private: [false],
    privateLocked: [false],
    typeform: [[]],
    typeformLocked: [false],
    roomAssignment: [null],
    zoomAssignment: [null],
    zoomMeetingId: [''],
  });

  _required = false;

  @Input() readonly = false;
  @Input() communityId!: string | null;
  @Input() initialImage?: string | null;

  @Input() get value() {
    const data: EventTemplateDetails = this._form.value;

    const resourceTags = data.resourceAssignments?.reduce((acc, assignment) => {
      if (assignment.resourceTag && assignment.resourceTagId) {
        if (!acc.map(resourceTag => resourceTag.id).includes(assignment?.resourceTagId)) {
          acc.push(assignment.resourceTag);
        }
      }
      return acc;
    }, [] as ResourceTag[]);

    return this._form.valid
      ? ({
          color: data.color || '#005574',
          title: data.title,
          titleLocked: data.titleLocked || false,
          imageUrl: data.imageUrl,
          imageUrlLocked: data.imageUrlLocked || false,
          colorLocked: data.colorLocked || false,
          description: data.description,
          descriptionLocked: data.descriptionLocked || false,
          tags: data.tags,
          tagsLocked: data.tagsLocked || false,
          resourceTags,
          resourceAssignments: data.resourceAssignments,
          autoAssign: data.autoAssign,
          resourcesLocked: data.resourcesLocked || false,
          enableUserSpotBooking: data.enableUserSpotBooking || false,
          enableUserSpotBookingLocked: data.enableUserSpotBookingLocked || false,
          duration: data.duration,
          durationLocked: data.durationLocked || false,
          checkInWindow: data.checkInWindow,
          checkInWindowLocked: data.checkInWindowLocked || false,
          maxCapacity: data.maxCapacity,
          maxCapacityLocked: data.maxCapacityLocked || false,
          typeform: data.typeform,
          typeformLocked: data.typeformLocked || false,
          private: data.private || false,
          privateLocked: data.privateLocked || false,
          roomAssignment: data.roomAssignment,
          zoomAssignment: data.zoomAssignment,
          zoomMeetingId: data.zoomMeetingId || '',
        } as EventTemplateDetails)
      : null;
  }

  set value(value: EventTemplateDetails | null) {
    this._form.patchValue({
      color: value?.color || '#005574',
      title: value?.title || null,
      titleLocked: value?.titleLocked,
      imageUrl: value?.imageUrl || null,
      imageUrlLocked: value?.imageUrlLocked,
      colorLocked: value?.colorLocked,
      description: value?.description || null,
      descriptionLocked: value?.descriptionLocked,
      tags: value?.tags || [],
      tagsLocked: value?.tagsLocked,
      resourceAssignments: value?.resourceAssignments || [],
      autoAssign: value?.autoAssign || false,
      resourcesLocked: value?.resourcesLocked,
      enableUserSpotBooking: value?.enableUserSpotBooking,
      enableUserSpotBookingLocked: value?.enableUserSpotBookingLocked,
      duration: value?.duration || null,
      durationLocked: value?.durationLocked,
      checkInWindow: value?.checkInWindow ?? null,
      checkInWindowLocked: value?.checkInWindowLocked,
      maxCapacity: value?.maxCapacity || null,
      maxCapacityLocked: value?.maxCapacityLocked,
      typeform: value?.typeform || [],
      typeformLocked: value?.typeformLocked,
      private: value?.private || false,
      privateLocked: value?.privateLocked,
      roomAssignment: value?.roomAssignment,
      zoomAssignment: value?.zoomAssignment,
      zoomMeetingId: value?.zoomMeetingId,
    });

    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    const validator = [...(this._required ? [Validators.required] : [])];
    this._form.get('title')?.setValidators(validator);

    this.stateChanges.next();
  }

  updateCapacity(room: RoomResource) {
    if (!this._form.value.maxCapacity && room?.spotCount) this._form.patchValue({ maxCapacity: room.spotCount });
  }

  writeValue(value: EventTemplateDetails): void {
    this.value = value;
  }

  registerOnChange(fn: (value: EventTemplateDetails | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
