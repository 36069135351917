import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SafePipeModule } from 'safe-pipe';
import { EmptyPlaceholderComponent } from './components/empty-placeholder/empty-placeholder.component';
import { GrecoItemComponent, GrecoItemStartDirective } from './components/item/item.component';
import { MemoPipe, TableColumnDirective, TableComponent } from './components/table/table.component';

@NgModule({
  imports: [
    CommonModule,
    MatDividerModule,
    MatTableModule,
    SafePipeModule,
    MatProgressBarModule,
    MatSortModule,
    DragDropModule,
  ],
  exports: [
    TableComponent,
    TableColumnDirective,
    GrecoItemComponent,
    GrecoItemStartDirective,
    EmptyPlaceholderComponent,
  ],
  declarations: [
    MemoPipe,
    TableComponent,
    TableColumnDirective,
    EmptyPlaceholderComponent,
    GrecoItemComponent,
    GrecoItemStartDirective,
  ],
})
export class TableModule {}
