<!-- Toolbar Component -->
<mat-toolbar matRipple [matRippleTrigger]="homeLink" [matRippleRadius]="100">
  <button
    *ngIf="links?.length && !hideNavigation"
    class="toolbar-app-button"
    [class.with-links-overlay]="!mobileQuery.matches && !drawer.opened"
    mat-icon-button
    (click)="drawer.toggle()"
  >
    <mat-icon>apps</mat-icon>
  </button>
  <a
    id="homelink"
    #homeLink
    routerLink="/"
    routerLinkActive="disable-clicks"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <mat-icon *ngIf="hideNavigation && !hideBackButton" class="title-back-icon">chevron_left</mat-icon>
    <ng-container *ngIf="logo; else showTitle;">
      <img class="toolbar-logo" [src]="logo" />
    </ng-container>
    <ng-template #showTitle>
      <h2 class="toolbar-title">{{layoutTitle || 'Platform'}}</h2>
    </ng-template>
  </a>
  <ng-container *ngIf="subtitle && !mobileQuery.matches">
    <h2 class="toolbar-subtitle" @fadeIn>{{subtitle}}</h2>
  </ng-container>
  <div class="toolbar-content">
    <ng-content select="[slot='toolbar-content']"></ng-content>
  </div>
  <div *ngIf="!hideEndContent" class="toolbar-end">
    <ng-content select="[slot='toolbar-end']"></ng-content>
  </div>
</mat-toolbar>

<!-- Drawer & Content Component -->
<mat-drawer-container class="container" autosize>
  <mat-drawer
    #drawer
    class="sidenav"
    [class.mobile]="mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
  >
    <ng-content select="[slot='drawer-top']"></ng-content>
    <div class="drawer-content">
      <button class="close-drawer-button" [class.bottom]="!showDrawerTitle" mat-icon-button (click)="drawer.close()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <ng-content select="[slot='drawer-start']"></ng-content>
      <h4 class="link-header" @fadeIn *ngIf="showDrawerTitle">{{layoutTitle}}</h4>
      <greco-nav-link
        @fadeIn
        *ngIf="showDefaultHomeLink"
        color="primary"
        link="/"
        [exact]="true"
        label="Dashboard"
        icon="space_dashboard"
        (mouseup)="mobileQuery.matches && drawer.close()"
        [style.margin-top]="showDrawerTitle ? '16px' : 0"
      ></greco-nav-link>
      <ng-container *ngFor="let linkGroup of links | groupBy: 'group' | keyvalue">
        <h4 class="link-header" [class.not-first]="!showDefaultHomeLink" @fadeIn>{{linkGroup.key}}</h4>
        <greco-nav-link
          @fadeIn
          *ngFor="let link of $any(linkGroup.value)"
          [color]="link.color"
          [link]="link.url"
          [label]="link.label"
          [tooltip]="link.description"
          [tooltipDelay]="500"
          [icon]="link.icon"
          [disabled]="link.disabled"
          [disabledAction]="link.disabledAction"
          (mouseup)="mobileQuery.matches && drawer.close()"
        ></greco-nav-link>
      </ng-container>
      <ng-content select="[slot='drawer-end']"></ng-content>
    </div>
    <ng-content select="[slot='drawer-bottom']"></ng-content>
  </mat-drawer>
  <div class="links-overlay" *ngIf="!mobileQuery.matches && !drawer.opened && !hideNavigation">
    <ng-content select="[slot='overlay-start']"></ng-content>
    <greco-nav-link
      @fadeIn
      *ngIf="showDefaultHomeLink"
      color="primary"
      link="/"
      [exact]="true"
      tooltip="Dashboard"
      icon="space_dashboard"
      [full]="false"
    ></greco-nav-link>
    <greco-nav-link
      @fadeIn
      *ngFor="let link of links"
      [tooltip]="link.label || ''"
      matTooltipPosition="right"
      [link]="link.url || ''"
      [icon]="link.icon  || 'link'"
      [color]="link.color"
      [disabled]="link.disabled"
      [disabledAction]="link.disabledAction"
      [full]="false"
    >
    </greco-nav-link>
    <ng-content select="[slot='overlay-end']"></ng-content>
  </div>

  <mat-progress-bar [class.hide]="!showProgressBar" mode="indeterminate"></mat-progress-bar>

  <main
    class="main-outlet"
    [class.with-links-overlay]="!mobileQuery.matches && !drawer.opened && !hideNavigation"
    [@routerTransition]="routerOutlet && routerOutlet.activatedRouteData"
  >
    <router-outlet #routerOutlet="outlet"></router-outlet>
  </main>
</mat-drawer-container>
