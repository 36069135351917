import { Component, Input } from '@angular/core';
import { Booking } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { BookingService } from '@greco/ngx-booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent {
  @PropertyListener('user') user$ = new ReplaySubject<User>(1);
  @Input() user?: User;

  bookings$ = this.user$.pipe(
    switchMap(user => {
      return this.bookingSvc.getByUser(user.id);
    }),
    map(events => {
      return events.sort((b1, b2) => (b1.event.startDate < b2.event.startDate ? -1 : 1));
    })
  );

  constructor(private bookingSvc: BookingService) {}

  openBooking(booking: Booking) {
    console.log({ booking });
  }
}
