import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { ContactService } from '@greco/ngx-identity-contacts';
import { BehaviorSubject, combineLatest, from, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CommunityService } from '../../services';

@Component({
  selector: 'greco-user-community-picker',
  templateUrl: './user-community-picker.component.html',
  styleUrls: ['./user-community-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: UserCommunityPickerComponent }],
})
export class UserCommunityPickerComponent
  implements OnDestroy, OnInit, AfterViewInit, MatFormFieldControl<Community[]>, ControlValueAccessor
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private communitySvc: CommunityService,
    private contactSvc: ContactService,
    private formBuilder: FormBuilder,
    private _elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private userSvc: UserService
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;

    this._control.valueChanges
      .pipe(
        takeUntil(this._onDestroy$),
        tap(() => this.stateChanges.next()),
        filter(() => this.syncWithUrl),
        distinctUntilChanged((prev, curr) => (prev || []).join() === (curr || []).join()),
        tap(communities => this.valueChanged.next(communities)),
        map((communities: Community[]) => communities.map(com => com.id))
      )
      .subscribe(communities =>
        setTimeout(() => this.router.navigate([], { queryParamsHandling: 'merge', queryParams: { communities } }))
      );
  }

  private static ID = 0;

  private _onDestroy$ = new Subject<void>();

  readonly stateChanges = new Subject<void>();

  readonly touched = new Subject<void>();
  readonly valueChanged = new Subject<Community[]>();

  @ViewChild(MatSelect) private matSelect?: MatSelect;

  @Input() syncWithUrl = true;
  @Input() autoSelectUserCommunities = true;

  private _user$ = new BehaviorSubject<User | null>(null);
  @Input() get user() {
    return this._user$.value;
  }
  set user(user) {
    this._user$.next(user);
    if (!user) this._control.disable();
    else this._control.enable();
  }

  readonly userCommunities$ = this.userSvc.user$.pipe(
    switchMap(async user => (user ? await this.contactSvc.getUserContacts(user.id) : [])),
    map(contacts => contacts.map(cnt => cnt.community).sort((a, b) => a.name.localeCompare(b.name))),
    map(communities =>
      communities.reduce((acc, community) => {
        if (!acc.map(com => com.id).includes(community.id)) acc.push(community);
        return acc;
      }, [] as Community[])
    ),
    shareReplay(1)
  );

  readonly otherCommunities$ = combineLatest([from(this.communitySvc.getAllCommunities()), this.userCommunities$]).pipe(
    map(([communities, userCommunities]) =>
      communities.filter(com => com.browsable && !userCommunities.some(c => c.id === com.id))
    ),
    map(communities => communities.sort((a, b) => a.name.localeCompare(b.name))),
    shareReplay(1)
  );

  /** @hidden */
  readonly _control = this.formBuilder.control([], []);

  @Input() get value() {
    return this._control.value;
  }
  set value(value: Community[]) {
    this._control.setValue(value);
    this.stateChanges.next();
  }

  readonly controlType = 'greco-user-community-picker';
  @HostBinding() readonly id = `${this.controlType}-${UserCommunityPickerComponent.ID++}`;

  placeholder = '';

  get focused() {
    return this.matSelect?.focused || false;
  }

  get empty() {
    return !this._control.value || !this._control.value.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _required = false;
  @Input() get required() {
    return this._required;
  }
  set required(required) {
    this._required = coerceBooleanProperty(required);

    this._control.setValidators(required ? [Validators.required] : []);
    this._control.updateValueAndValidity();

    this.stateChanges.next();
  }

  private _disabled = false;
  @Input() get disabled() {
    return this._disabled;
  }
  set disabled(disabled) {
    this._disabled = disabled;
    this.stateChanges.next();
  }

  get errorState() {
    return this._control.invalid && this._control.touched;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';

  /** @hidden */
  readonly compareCommunities = (a: Community, b: Community) => a?.id === b?.id;

  async ngOnInit() {
    if (this.syncWithUrl) {
      const query = await toPromise(this.route.queryParamMap);
      const communityIds = query.getAll('communities');
      if (communityIds.length) {
        const communities = await Promise.all(communityIds.map(comId => this.communitySvc.getCommunity(comId)));
        this.value = communities;
      }
    }

    if (!this._control.value.length && this.autoSelectUserCommunities) {
      const user = await toPromise(this.userSvc.user$);
      let communities = user
        ? (await this.contactSvc.getUserContacts(user.id))
            .map(cnt => cnt.community)
            .sort((a, b) => a.name.localeCompare(b.name))
        : (await this.communitySvc.getAllCommunities()).filter(community => community.canBeParent === true);

      communities = communities.reduce((acc, community) => {
        if (!acc.map(com => com.id).includes(community.id)) acc.push(community);
        return acc;
      }, [] as Community[]);

      this.value = communities;
    }
  }

  ngAfterViewInit() {
    if (this.matSelect) {
      this.matSelect.registerOnTouched(() => {
        this.touched.next();
        return {};
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();

    this.stateChanges.complete();
    this.valueChanged.complete();
    this.touched.complete();
    this._user$.complete();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.greco-user-community-picker-container');
    if (controlElement) controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.matSelect?.onContainerClick();
  }

  writeValue(value: Community[]) {
    this.value = value;
  }

  registerOnChange(fn: (value: Community[]) => void) {
    this.valueChanged.pipe(takeUntil(this._onDestroy$)).subscribe(value => fn?.(value));
  }

  registerOnTouched(fn: () => any) {
    this.touched.pipe(takeUntil(this._onDestroy$)).subscribe(() => fn?.());
  }

  openSelect() {
    this.matSelect?.open();
  }
}
