import { Pipe, PipeTransform } from '@angular/core';
import { Perk } from '@greco/sales-perks';

@Pipe({
  name: 'perkSubCommunities',
})
export class PerkSubCommunitiesPipe implements PipeTransform {
  transform(perk: Perk): string {
    if (!perk.availableInSubCommunities) return 'No';
    if (perk.availableInSubCommunities && !perk.requiresApproval) return 'Yes';
    return 'Yes (With Approval)';
  }
}
