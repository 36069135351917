import { BaseDto } from '@greco/util-dto';
import { Expose } from 'class-transformer';
import { IsDate, IsNotEmpty, IsString } from 'class-validator';

export class VideoSourceRequestDto extends BaseDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  externalId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  title: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  embedUrl: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  thumbnailUrl: string;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  uploadDate: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  source: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  duration: number;
}
