import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ArrayUtils, toPromise } from '@greco-fit/util';
import { Booking, BookingOption, CalendarEvent } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { SignInComponent } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { AccountLinkingService } from '@greco/web-account-linking';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-user-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class UserEventListComponent implements AfterViewInit {
  constructor(private matDialog: MatDialog, private linkSvc: AccountLinkingService) {}

  @PropertyListener('user') private _user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User | null;
  @Input() userWaitlist?: string[];
  @Input() userBookings: Booking[] = [];

  readonly linkedAccounts$ = this._user$.pipe(
    switchMap(async user => (user ? await this.linkSvc.getPrivilegeLinksForAccessor(user.id) : []))
  );

  eventsCount = 0;
  groups: { id: string; day: string; events: { event: CalendarEvent; bookingOption: BookingOption }[] }[] = [];

  async ngAfterViewInit() {
    if (!this.user) await this.signIn();
  }

  @Input() set events(events: { event: CalendarEvent; bookingOption: BookingOption }[]) {
    this.eventsCount = events.length;

    const grouped = ArrayUtils.groupBy(events, event => moment(event.event.startDate).format('YYYY-MM-DD'));
    this.groups = Object.entries(grouped).reduce(
      (acc, [key, value]) => [
        ...acc,
        { id: key.replace(/-/g, ''), day: moment(key).format('dddd, MMMM Do'), events: value },
      ],
      [] as { id: string; day: string; events: { event: CalendarEvent; bookingOption: BookingOption }[] }[]
    );
  }

  async signIn() {
    const _dialog = this.matDialog.open(SignInComponent, { data: {}, width: '100%', maxWidth: '400px' });
    await toPromise(_dialog.afterClosed());
  }
}
