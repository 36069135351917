<ng-container *ngIf="isMobile$ | async; else webVersion">
  <div *grecoLet="dashboardFavoritesSvc.favorites$ | async as favorites" class="upcoming-bookings">
    <div
      class="header"
      (click)="
        calendarPickerOpen = !calendarPickerOpen;
        calendarPickerOpen && dashboardBookingsSvc.activeDate$.next([calendar.activeDate, calendar])
      "
    >
      <div>
        <h2>
          Welcome back
          <ng-container *ngIf="userSvc.user$ | async as user">
            <strong> {{ user.friendlyName || user.displayName }} </strong>
          </ng-container>
          !
        </h2>

        <ng-container *ngIf="dashboardBookingsSvc.selectedDate$ | async as selectedDate; else noDate">
          <p style="margin-top: -2px">{{ selectedDate | date : 'longDate' }}</p>
        </ng-container>

        <ng-template #noDate>
          <ng-container *ngIf="(dashboardBookingsSvc.bookings$ | async)?.length; else noBookingsHeader">
            <p style="margin-top: -2px">Your upcoming bookings</p>
          </ng-container>

          <ng-template #noBookingsHeader>
            <p style="margin-top: -2px" *ngIf="isMobile$ | async">Click to view past bookings</p>
          </ng-template>
        </ng-template>
      </div>

      <div class="calendar-picker">
        <ng-container *ngIf="dashboardBookingsSvc.waitlistEvents$ | async as waitListEvents">
          <ng-container *grecoLet="dashboardBookingsSvc.selectedEvent$ | async as selectedEvent">
            <mat-chip-list *ngIf="waitListEvents.length">
              <mat-chip (click)="bottomSheet.open(waitlistSheetContent, { panelClass: 'bottom-sheet' })">
                View Waitlists
              </mat-chip>
            </mat-chip-list>

            <ng-template #waitlistSheetContent>
              <div style="display: flex; gap: 12px; justify-content: space-between; align-items: center">
                <h2 #header style="margin-bottom: 0">My Waitlist</h2>

                <div>
                  <button mat-icon-button (click)="bottomSheet.dismiss()">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>

              <div class="classes">
                <ng-container *ngFor="let event of waitListEvents">
                  <div
                    class="event-card"
                    (click)="dashboardBookingsSvc.selectedEvent$.next(event); overlay.open = true"
                  >
                    <div class="card-image">
                      <ng-container *grecoLet="event.maxCapacity - $any(event).bookings as spotsLeft">
                        <p *ngIf="event.community?.id !== 'com_greco'" class="spots-left">
                          <ng-container *ngIf="spotsLeft; else noSpots">
                            {{ spotsLeft }} Spot{{ spotsLeft === 1 ? '' : 's' }} Left
                          </ng-container>

                          <ng-template #noSpots> Full </ng-template>
                        </p>
                      </ng-container>

                      <img
                        class="main-image"
                        [src]="event.imageUrl || event.calendar?.imageUrl || 'assets/greco/placeholder.png'"
                      />
                    </div>

                    <div class="card-content">
                      <p class="time">
                        {{ event.startDate | date : 'MMM d' }} {{ event.startDate | date : 'h:mm' }} -
                        {{ event.endDate | date : 'shortTime' }}
                        <ng-container *ngIf="event.community?.name">
                          <mat-icon
                            style="
                              vertical-align: middle;
                              margin-bottom: 4px;
                              margin-left: 4px;
                              width: 14px;
                              height: 14px;
                              line-height: 14px;
                              font-size: 14px;
                              color: var(--primary-color);
                            "
                          >
                            location_on
                          </mat-icon>
                          {{ event.community.name }}
                        </ng-container>
                      </p>

                      <h2>
                        <strong> {{ event.title }} </strong>
                      </h2>

                      <p>
                        <!-- {{event.calendar?.title}} -->
                        <!-- Use for lightgrey -> <span></span> -->
                        <ng-container *ngIf="event.resourceAssignments?.length">
                          <!-- - -->
                          <ng-container *ngFor="let resourceAssignment of event.resourceAssignments; let last = last">
                            {{ resourceAssignment.resource?.name }}{{ last ? '' : ',' }}
                          </ng-container>
                        </ng-container>
                      </p>

                      <div class="action-buttons">
                        <button mat-stroked-button>
                          {{ event.maxCapacity - $any(event).bookings <= 0 ? 'Waitlisted' : 'Book Now' }}
                          <mat-icon color="accent">alarm</mat-icon>
                        </button>

                        <button
                          mat-stroked-button
                          (click)="$event.preventDefault(); $event.stopImmediatePropagation(); leaveWaitlist(event)"
                        >
                          Leave List <mat-icon color="accent">playlist_remove</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>

            <ng-template
              #overlay="cdkConnectedOverlay"
              cdkConnectedOverlay
              cdkConnectedOverlayPanelClass="fullscreen-overlay"
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="overlay.open && !!selectedEvent"
            >
              <div class="overlay-toolbar" (click)="overlay.open = false">
                <button mat-icon-button>
                  <mat-icon>arrow_back_ios</mat-icon>
                </button>

                <h2>Dashboard</h2>
              </div>

              <greco-multi-booking-preview
                *ngIf="selectedEvent && dashboardBookingsSvc.user$ | async"
                [booking]="selectedEvent?.userBooking"
                [user]="$any(dashboardBookingsSvc.user$ | async)"
                [event]="$any(selectedEvent)"
                (booked)="refresh()"
              ></greco-multi-booking-preview>
            </ng-template>
          </ng-container>
        </ng-container>

        <mat-spinner *ngIf="dashboardBookingsSvc.loadingBookings" color="accent" [diameter]="24"></mat-spinner>

        <button mat-icon-button color="accent" [disabled]="dashboardBookingsSvc.loadingBookings">
          <mat-icon> {{ calendarPickerOpen ? 'expand_less' : 'calendar_month' }} </mat-icon>
        </button>
      </div>
    </div>

    <div class="collapsible-calendar" [@heightExpansion]="calendarPickerOpen ? 'expanded' : 'collapsed'">
      <mat-calendar
        #calendar
        [dateClass]="highlightDatesWithBooking"
        [selected]="dashboardBookingsSvc.selectedDate$ | async"
        (selectedChange)="
          $event && ((calendarPickerOpen = false) || true) && dashboardBookingsSvc.selectedDate$.next($event)
        "
        (click)="
          calendar.currentView === 'month' && dashboardBookingsSvc.activeDate$.next([calendar.activeDate, calendar])
        "
      ></mat-calendar>
    </div>

    <ng-container *grecoLet="dashboardBookingsSvc.userBookings$ | async as userBookings">
      <ng-container *grecoLet="dashboardBookingsSvc.linkedBookings$ | async as linkedBookings">
        <!-- Bookings -->
        <div *ngIf="userBookings?.length || linkedBookings?.length; else noBookings" class="bookings">
          <!-- Event Overlay -->
          <ng-container *grecoLet="selectedBooking$ | async as booking">
            <ng-template
              #overlay="cdkConnectedOverlay"
              cdkConnectedOverlay
              cdkConnectedOverlayPanelClass="fullscreen-overlay"
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="overlay.open && !!booking"
            >
              <div class="overlay-toolbar" (click)="overlay.open = false">
                <button mat-icon-button>
                  <mat-icon>arrow_back_ios</mat-icon>
                </button>

                <h2>Dashboard</h2>
              </div>

              <greco-multi-booking-preview
                *ngIf="booking && dashboardBookingsSvc.user$ | async"
                [booking]="$any(booking)"
                [user]="$any(dashboardBookingsSvc.user$ | async)"
                [event]="$any(booking).event"
              ></greco-multi-booking-preview>
            </ng-template>

            <!-- Booking Cards -->
            <p *ngIf="linkedBookings?.length && userBookings?.length" style="margin-bottom: -4px">
              <strong>Your Bookings</strong>
            </p>

            <greco-dashboard-booking-card
              *ngFor="let booking of userBookings"
              [booking]="booking"
              [favorites]="favorites"
              (selectedBookingChanged)="navigateTo($event.event.id)"
            >
            </greco-dashboard-booking-card>

            <!-- Linked bookings -->
            <div *ngFor="let user of linkedBookings" class="linked-booking">
              <p style="margin: 4px 0px 8px">
                <strong>Bookings For {{ user.bookings[0].user.displayName }}</strong>
              </p>

              <greco-dashboard-booking-card
                *ngFor="let booking of user.bookings"
                [booking]="booking"
                [favorites]="favorites"
                (selectedBookingChanged)="navigateTo($event.event.id)"
              >
              </greco-dashboard-booking-card>
            </div>
          </ng-container>
        </div>

        <ng-template #noBookings>
          <div class="no-booking">
            <div>
              <h2>
                <strong>
                  <ng-container *ngIf="(dashboardBookingsSvc.selectedDate$ | async) === null; else noBookingWFilter">
                    No upcoming bookings
                  </ng-container>

                  <ng-template #noBookingWFilter> No bookings on that day </ng-template>
                </strong>
              </h2>

              <p>Book a class to get started!</p>
            </div>

            <div class="action-buttons">
              <button
                *ngIf="dashboardBookingsSvc.selectedDate$ | async"
                mat-stroked-button
                color="accent"
                (click)="dashboardBookingsSvc.selectedDate$.next(null)"
              >
                Clear Filter
              </button>

              <button mat-raised-button color="accent" routerLink="/workouts">Book a Class</button>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-template #loading>
      <div class="loading-card"></div>
    </ng-template>
  </div>
</ng-container>

<ng-template #webVersion>
  <div *grecoLet="dashboardBookingsSvc.bookings$ | async" class="upcoming-bookings">
    <div class="header" style="margin-bottom: 8px">
      <div>
        <h2>
          Welcome back
          <ng-container *ngIf="userSvc.user$ | async as user">
            <strong> {{ user.friendlyName || user.displayName }} </strong>
          </ng-container>
          !
        </h2>
        <p style="margin-top: -2px">Here are your upcoming bookings</p>
      </div>

      <button mat-raised-button color="accent" routerLink="/workouts" style="border-radius: 18px; color: white">
        New Booking
      </button>
    </div>
  </div>

  <greco-user-bookings-calendar *ngIf="userSvc.user$ | async as user" [user]="user"></greco-user-bookings-calendar>
</ng-template>
