import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'greco-browse-communities-dialog',
  templateUrl: './browse-communities-dialog.dialog.html',
  styleUrls: ['./browse-communities-dialog.dialog.scss'],
})
export class BrowseCommunitiesDialog {
  constructor(private dialogRef: MatDialogRef<BrowseCommunitiesDialog>) {}

  selectedCommunityId = 'com_greco';

  confirm() {
    this.dialogRef.close(this.selectedCommunityId);
  }
}
