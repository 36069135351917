<ng-container *ngIf="availableOptions[0]?.length">
  <mat-divider style="margin-bottom: 12px"></mat-divider>

  <!-- Regualar Options -->
  <form [formGroup]="optionsForm">
    <ng-container formArrayName="options">
      <h4>Options</h4>
      <mat-form-field appearance="standard" *ngFor="let control of getSelectedOptions().controls; index as i">
        <mat-label>{{ optionNames[i] }}</mat-label>
        <mat-select [formControlName]="i">
          <mat-option *ngFor="let selectedOption of availableOptions[i]; index as j" [value]="selectedOption">
            <ng-container *ngIf="enabledOptions[i][j]; else disabled">{{ selectedOption }}</ng-container>
            <ng-template #disabled>
              <span style="color: darkgray">{{ selectedOption }}</span>
            </ng-template>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </form>

  <!-- If there are more than one version of the same option combination -->
  <ng-container *ngIf="(validVariants?.length || 0) > 1">
    <h4 style="margin-top: 0">Select product variant</h4>
    <mat-form-field appearance="standard" style="margin-top: 0">
      <mat-label>Variant</mat-label>
      <mat-select #select [value]="validVariants[0]" (selectionChange)="this.emitVariant($event.value)">
        <mat-option *ngFor="let variant of validVariants" [value]="variant">
          {{ variant.title ? variant.title : (variant.variantOpts | pluck : 'value')?.join(', ') }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</ng-container>

<!-- Do check for product with options next -->
