<!-- Only show the community picker if the user has not already selected a community, TODO(adaoust): Remove condition once a user can join multiple communities -->
<ng-container *ngIf="!userHasSubscriptions && communities?.length">
  <div class="communities-header">
    <h2>Discover Communities</h2>
    <div class="buttons-bar">
      <button
        *ngFor="let tag of tags"
        mat-stroked-button
        color="primary"
        [class.active]="selectedTags.includes(tag.id)"
        (click)="toggleSelect(tag.id)"
      >
        {{ tag.label }}
      </button>
      <button
        *ngIf="!location; else locationBtn"
        class="location-button"
        mat-button
        (click)="locate()"
        [disabled]="locating"
      >
        <mat-icon [class.spin]="locating">{{ locating ? 'sync' : 'my_location' }}</mat-icon>
        {{ mobileQuery?.matches ? '' : 'Locations' }} Near Me
      </button>
      <ng-template #locationBtn>
        <button class="location-button" mat-button disabled>
          <mat-icon>location_on</mat-icon>
          <span>Near Me</span>
        </button>
      </ng-template>
    </div>
  </div>
  <div class="communities-swiper">
    <swiper
      [slidesPerView]="'auto'"
      [spaceBetween]="16"
      [navigation]="true"
      [slidesOffsetBefore]="mobileQuery?.matches ? 16 : 32"
      [slidesOffsetAfter]="mobileQuery?.matches ? 16 : 32"
    >
      <ng-template swiperSlide *ngFor="let community of filteredCommunities">
        <greco-community-card
          (click)="selectedCommunity !== community.id && communityClicked(community)"
          [community]="community"
          [selected]="selectedCommunity === community.id"
          [callToActionText]="callToActionText"
          [selectedCallToActionText]="selectedCallToActionText"
        ></greco-community-card>
      </ng-template>
    </swiper>
  </div>
</ng-container>
