import { Injectable } from '@angular/core';
import { BuildDateFilter } from '@greco/ngx-filters';
import { SubscriptionActionType } from '@greco/sales-subscriptions';
import { CondOperator, SFields } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'any' })
export class SubscriptionUpdateDateFilter extends BuildDateFilter('SubscriptionUpdateDateFilter', {
  label: 'Update Date',
  shortLabel: 'Update',
  description: '',
  properties: ['SubscriptionActionEntity.timestamp'],
}) {
  getPropertySField(property: string, operator: CondOperator, value: Date | Date[]): SFields {
    return {
      ['$and' as any]: [
        { 'SubscriptionActionEntity.type': SubscriptionActionType.UPDATE },
        super.getPropertySField(property, operator, value),
      ],
    };
  }
}
