<ng-container *grecoLet="canUpdate$ | async as canUpdate">
  <ng-container *grecoLet="canRevoke$ | async as canRevoke">
    <mat-tab-group style="width: 100%; height: 100%" [selectedIndex]="activeTab === 'outbox' ? 1 : 0">
      <!-- Incoming Tab -->
      <mat-tab label="Incoming">
        <ng-template mat-tab-label> <mat-icon style="margin-right: 8px">move_to_inbox</mat-icon>Incoming</ng-template>
        <ng-container *ngIf="grantedLinks?.length; else empty" style="width: 100%">
          <greco-table [data]="grantedLinks || []" [loading]="loading" [highlight]="true">
            <greco-user-avatar
              *grecoTableCol="''; fitContent: true; let link"
              [user]="link.accessor"
              size="small"
              [showOnlineStatus]="false"
            ></greco-user-avatar>

            <div *grecoTableCol="'Account'; let link">
              <p style="margin-bottom: 0">{{ link.accessor.displayName }}</p>
              <small>{{ link.accessor.contactEmail || link.accessor.email }}</small>
            </div>

            <greco-account-link-permissions
              *grecoTableCol="'Access Requested'; let link"
              [link]="link"
              view="account"
              [canUpdate]="canUpdate || false"
              (refresh)="refresh.emit()"
            >
            </greco-account-link-permissions>

            <ng-container *grecoTableCol="''; fitContent: true; let link"> </ng-container>

            <ng-container *ngIf="canRevoke">
              <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let link">
                <div *ngIf="link.status === 'PENDING'">
                  <button mat-flat-button [matMenuTriggerFor]="respondMenu" color="primary" style="min-width: 136px">
                    Respond
                  </button>

                  <mat-menu #respondMenu>
                    <button mat-menu-item (click)="accept(link)" style="color: var(--primary-color)">
                      <mat-icon style="color: var(--primary-color)">thumb_up</mat-icon>
                      Accept
                    </button>

                    <button mat-menu-item (click)="revokeAccess(link)" style="color: var(--warn-color)">
                      <mat-icon style="color: var(--warn-color)">thumb_down</mat-icon>
                      Decline
                    </button>
                  </mat-menu>
                </div>
                <button *ngIf="link.status === 'ACTIVE'" mat-flat-button color="warn" (click)="revokeAccess(link)">
                  Revoke Access
                </button>
              </ng-container>
            </ng-container>
          </greco-table>
        </ng-container>
      </mat-tab>

      <!-- Outgoing Tab -->
      <mat-tab label="Outgoing">
        <ng-template mat-tab-label> <mat-icon style="margin-right: 8px">outbox</mat-icon>Outgoing</ng-template>

        <ng-container *ngIf="ownedLinks?.length; else empty" style="width: 100%">
          <greco-table [data]="ownedLinks || []" [loading]="loading" [highlight]="true">
            <greco-user-avatar
              *grecoTableCol="''; fitContent: true; let link"
              [user]="link.account"
              size="small"
              [showOnlineStatus]="false"
            ></greco-user-avatar>

            <div *grecoTableCol="'Account'; let link">
              <p style="margin-bottom: 0">{{ link.account.displayName }}</p>
              <small>{{ link.account.contactEmail || link.account.email }}</small>
            </div>

            <greco-account-link-permissions
              *grecoTableCol="'Access Requested'; let link"
              [link]="link"
              view="accessor"
              [canUpdate]="canUpdate || false"
              (refresh)="refresh.emit()"
            >
            </greco-account-link-permissions>

            <ng-container *grecoTableCol="''; fitContent: true; let link"> </ng-container>

            <ng-container *ngIf="canRevoke">
              <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let link">
                <button mat-flat-button color="warn" (click)="revokeAccess(link)" style="min-width: 136px">
                  Cancel
                </button>
              </ng-container>
            </ng-container>
          </greco-table>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>

<ng-template #empty>
  <greco-table style="width: 100%"></greco-table>
</ng-template>
