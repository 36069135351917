import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Resource } from '@greco/booking-events';
import { Timezone, userDefaultTimezone } from '@greco/timezone';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface SeriesSchedule {
  timezone?: Timezone;

  startDate?: Date | null;
  endDate?: Date | null;
  monday?: Date[] | Moment[];
  tuesday?: Date[] | Moment[];
  wednesday?: Date[] | Moment[];
  thursday?: Date[] | Moment[];
  friday?: Date[] | Moment[];
  saturday?: Date[] | Moment[];
  sunday?: Date[] | Moment[];
  resources?: Record<string, Resource[]>;
  availableAsCourse?: boolean;
  availableAsInstances?: boolean;
  courseImage?: File[];
}

@Component({
  selector: 'greco-series-schedule-input',
  templateUrl: './series-schedule-input.component.html',
  styleUrls: ['./series-schedule-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SeriesScheduleInputComponent),
      multi: true,
    },
  ],
})
export class SeriesScheduleInputComponent implements ControlValueAccessor, OnDestroy {
  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private snackBar: MatSnackBar) {
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.onChanged?.(this.value));
  }

  private onChanged?: (value: SeriesSchedule | null) => void;
  private onTouched?: () => void;

  private _onDestroy$ = new Subject<void>();

  readonly stateChanges = new Subject<void>();

  @Output() scheduleChanged = new EventEmitter<SeriesSchedule>();

  maxStartDate: Date | null = null;
  minEndDate: Date | null = null;

  dayRequired = '';
  weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  _form = this.formBuilder.group({
    timezone: [userDefaultTimezone(), [Validators.required]],
    startDate: [null, [Validators.required]],
    endDate: [null, []],
    monday: [[], []],
    tuesday: [[], []],
    wednesday: [[], []],
    thursday: [[], []],
    friday: [[], []],
    saturday: [[], []],
    sunday: [[], []],
    resources: [{}],
    availableAsCourse: [null],
    availableAsInstances: [null],
    courseImage: [null],
  });

  _required = false;
  courseImageURL: string[] = [];
  isNew = true;

  @Input() readonly = false;
  @Input() communityId!: string | null;

  @Input() get value() {
    this.scheduleChanged.emit(this._form.value);
    const data: SeriesSchedule = this._form.value;

    if (data.startDate && data.endDate && data.startDate > data.endDate) return null;

    return this._form.valid && this.validateRecurrence()
      ? ({
          timezone: data.timezone,
          startDate: data.startDate,
          endDate: data.endDate,
          monday: data.monday,
          tuesday: data.tuesday,
          wednesday: data.wednesday,
          thursday: data.thursday,
          friday: data.friday,
          saturday: data.saturday,
          sunday: data.sunday,
          resources: data.resources,
          availableAsCourse: data.availableAsCourse,
          availableAsInstances: data.availableAsInstances,
          courseImage: data.courseImage,
        } as SeriesSchedule)
      : null;
  }

  set value(value: SeriesSchedule | null) {
    this.isNew = !value?.startDate;

    this._form.patchValue({
      timezone: value?.timezone || userDefaultTimezone(),
      startDate: value?.startDate || null,
      endDate: value?.endDate || null,
      monday: value?.monday || null,
      tuesday: value?.tuesday || null,
      wednesday: value?.wednesday || null,
      thursday: value?.thursday || null,
      friday: value?.friday || null,
      saturday: value?.saturday || null,
      sunday: value?.sunday || null,
      resources: value?.resources || {},
      availableAsCourse: value?.availableAsCourse,
      availableAsInstances: value?.availableAsInstances,
      courseImage: value?.courseImage || null,
    } as SeriesSchedule);

    if (value?.courseImage?.length) this.courseImageURL = [value?.courseImage[0].name];

    this.stateChanges.next();
    this._form.markAsPristine();
  }

  @Input() get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    const validator = [...(this._required ? [Validators.required] : [])];
    this._form.get('startDate')?.setValidators(validator);

    this.stateChanges.next();
  }

  validateRecurrence() {
    const data: SeriesSchedule = this._form.value;
    const date =
      data.monday?.length ||
      data.tuesday?.length ||
      data.wednesday?.length ||
      data.thursday?.length ||
      data.friday?.length ||
      data.saturday?.length ||
      data.sunday?.length;
    return date;
  }

  datesUpdated(startDate: any | null, endDate?: any) {
    const updatedStartDate = startDate?.value as Date;
    const updatedEndDate = endDate?.value as Date;

    if (updatedStartDate) this.minEndDate = moment(updatedStartDate).add(1, 'days').toDate();
    if (updatedEndDate) this.maxStartDate = moment(updatedEndDate).subtract(1, 'days').toDate();

    const formStartDate = this._form.value.startDate;
    const formEndDate = this._form.value.endDate;

    if (updatedEndDate && formStartDate > formEndDate) {
      this._form.get('startDate')?.setValue(null);
      this.snackBar.open('Series start date can`t be after end date!', 'Ok', { duration: 5000 });
    }

    if (updatedStartDate) {
      this.dayRequired = this.weekDays[formStartDate.getDay()];
      for (const day of this.weekDays) {
        if (day !== this.dayRequired) this._form.get(day)?.clearValidators();
        if (day === this.dayRequired) this._form.get(day)?.setValidators([Validators.required]);
        this._form.updateValueAndValidity();
      }
    }
  }

  removeTime(day: string, index: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const previous: string[] = this._form.get(day)!.value || [];
    previous.splice(index, 1);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._form.get(day)!.setValue(previous);
  }

  clearCourseValues() {
    if (this._form.value.availableAsCourse) {
      this._form.patchValue({ availableAsInstances: false, courseImage: null });
    } else {
      if (this.courseImageURL.length) {
        this._form.patchValue({ courseImage: [new File([], this.courseImageURL[0] || '')] });
      }
    }
  }

  writeValue(value: SeriesSchedule): void {
    this.value = value;
  }

  registerOnChange(fn: (value: SeriesSchedule | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
