import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CouponAssignmentFormComponent } from '@greco/feature-coupons';
import { CreateProductCouponAssignmentDto } from '@greco/nestjs-sales-products';
import { Product } from '@greco/sales-products';

@Component({
  selector: 'greco-fit-product-coupon-assignment-from',
  templateUrl: './product-coupon-assignment-from.component.html',
  styleUrls: ['./product-coupon-assignment-from.component.scss'],
})
export class ProductCouponAssignmentFormComponent extends CouponAssignmentFormComponent {
  constructor(private formBuilder: FormBuilder) {
    super();
    this.formGroup = this.formBuilder.group({
      variant: [null, Validators.required],
    });
  }

  formGroup: FormGroup;
  dialogRef: any;
  submitting = false;
  selectedProductsList: CreateProductCouponAssignmentDto[] = [];

  save() {
    if (this.formGroup.valid) {
      const products = this.formGroup.get('variant')?.value as Product[];

      products.map(product => {
        this.selectedProductsList.push({
          productId: product.id,
          couponId: this.couponId,
          type: this.type,
        });
      });
    }
    return { data: this.selectedProductsList, module: 'sales' };
  }
}
