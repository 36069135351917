import { Pipe, PipeTransform } from '@angular/core';

function normalize(text: string): string {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(value: string, search: string): string {
    if (!value || !search) return value;

    const normalizedQuery = normalize(search);
    const index = normalize(value).indexOf(normalizedQuery);

    if (index !== -1) {
      return (
        value.substr(0, index) +
        value.substr(index, normalizedQuery.length).bold() +
        value.substr(index + normalizedQuery.length)
      );
    } else return value;
  }
}
