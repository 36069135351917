/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter, BuildTextFilter } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  Tile,
  TileSecurityResourceAction,
  TilesGroupSecurityResource,
  TilesGroupSecurityResourceAction,
  TilesSecurityResource,
} from '@greco/tiles';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CreateTileComponent } from '../../dialogs/create-tile/create-tile.dialog';
import { ManageTileGroupDialogComponent } from '../../dialogs/manage-tile-groups/manage-tile-group.dialog';
import { TilesService } from '../../services/tiles.service';

@Injectable({ providedIn: 'root' })
export class RoleTitleFilter extends BuildTextFilter('RoleTitleFilter', {
  label: 'Label',
  shortLabel: 'Label',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['label'],
}) {}

@Injectable({ providedIn: 'root' })
export class RoleSearchFilter extends BuildSearchFilter('RoleSearchFilter', {
  properties: ['label'],
  propertyLabels: ['Label'],
}) {}

@Component({
  selector: 'greco-tiles-table',
  templateUrl: './tiles-table.component.html',
  styleUrls: ['./tiles-table.component.scss'],
})
export class TilesTableComponent {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private tilesSvc: TilesService,
    private comSvc: CommunitySecurityService,
    private snacks: MatSnackBar
  ) {}

  metadata?: IPaginationMeta;
  loading = true;
  private _community$ = new BehaviorSubject<Community | undefined>(undefined);
  readonly pagination$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({});

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @PropertyListener('filters') _filters$ = new BehaviorSubject<RequestQueryBuilder | undefined>(undefined);
  @Input() filters?: RequestQueryBuilder;
  @Input() allowNoTileId = false;

  @Input() get community() {
    return this._community$.value;
  }
  set community(community) {
    this._community$.next(community);
  }

  filterOptions = [RoleSearchFilter, RoleTitleFilter];

  private _refresh$ = new BehaviorSubject<null>(null);

  tiles$ = combineLatest([this._community$, this._refresh$, this._filters$, this.pagination$]).pipe(
    tap(() => (this.loading = true)),

    switchMap(async ([community, _, filters, pagination]) => {
      return this.community?.id || this.allowNoTileId
        ? await this.tilesSvc.paginate(
            (filters || new RequestQueryBuilder()).sortBy({ field: 'label', order: 'ASC' }),
            community?.id,
            pagination
          )
        : null;
    }),
    tap(data => (this.metadata = data?.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  openTileDetails(tile: any) {
    this.router.navigate([tile.id], { relativeTo: this.route });
  }

  createTile = async () => {
    const communityId = this.community?.id;
    if (!communityId) return;
    const _dialog = this.dialog.open(CreateTileComponent, { data: { communityId }, width: '750px', maxWidth: '90%' });
    await toPromise(_dialog.afterClosed()).then(() => this._community$.next(this.community));
  };

  async checkEnableDisbale(tileId: any, tileStatus: boolean) {
    let communityId = '';
    if (this.community) {
      communityId = this.community.id;
    }
    await this.tilesSvc.updateTile(tileId, { status: tileStatus, communityId });
    this.pagination$.next(this.pagination$.value);
  }

  updateTile = async () => {
    console.log('update');
  };

  canCreateTile$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSvc.hasAccess(community.id, TilesSecurityResource.key, TileSecurityResourceAction.CREATE)
        : false
    )
  );
  canUpdateTile$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSvc.hasAccess(community.id, TilesSecurityResource.key, TileSecurityResourceAction.UPDATE)
        : false
    )
  );

  canUpdateTileGroup$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSvc.hasAccess(
            community.id,
            TilesGroupSecurityResource.key,
            TilesGroupSecurityResourceAction.UPDATE
          )
        : false
    )
  );

  canDeleteTile$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSvc.hasAccess(community.id, TilesSecurityResource.key, TileSecurityResourceAction.DELETE)
        : false
    )
  );

  async manageTileGroups() {
    const communityId = this.community?.id;
    if (!communityId) return;
    const _dialog = this.dialog.open(ManageTileGroupDialogComponent, {
      data: { communityId },
      width: '750px',
      maxWidth: '90%',
    });
    await toPromise(_dialog.afterClosed().pipe(tap(() => this._refresh$.next(null))));
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  async deleteTile(tile: Tile) {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `<p>You are about to delete the <strong>${tile.label}</strong> tile.</p><p>This will remove the tile from the system.</p><br><p>This action is NOT reversible!</p>`,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      try {
        await this.tilesSvc.delete(tile.id);
        this.snacks.open(`Deleted ${tile.label} tile!`, 'Ok', { duration: 3000 });
        this.pagination$.next(this.pagination$.value);
      } catch (error) {
        this.snacks.open('Something went wrong. Please try again!', 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
      }
    }
  }
}
