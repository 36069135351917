<div class="community-item" *ngFor="let community of communities; let last = last">
  <a style="color: unset; text-decoration: none" class="row" [routerLink]="community.routerLink">
    <div style="padding-right: 32px; display: flex; align-items: center">
      <mat-icon>groups</mat-icon>
    </div>

    <div>
      <p style="margin: 0">
        <strong>{{ community.name }}</strong>
      </p>
    </div>

    <span>{{ community.contacts ? community.contacts + ' Contacts' : '' }}</span>

    <div class="arrow">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </a>

  <mat-divider *ngIf="!last"></mat-divider>
</div>
