import { Component, Input } from '@angular/core';

@Component({
  selector: 'greco-coupons-page',
  templateUrl: './coupons.page.html',
  styleUrls: ['./coupons.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CouponsPage {
  @Input() communityId?: string;
}
