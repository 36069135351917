<greco-simple-dialog [data]="dialogData">
  <mat-form-field>
    <mat-chip-list #chipList style="white-space: nowrap">
      <ng-container *ngFor="let tag of tags">
        <mat-chip
          *ngIf="tagsToRemove.includes(tag.id); else notDelete"
          style="background-color: var(--warn-lighter-color); color: white"
          [matMenuTriggerFor]="tagMenu"
        >
          {{tag.label}}
          <mat-icon>more_vert</mat-icon>
        </mat-chip>
        <ng-template #notDelete>
          <mat-chip [matMenuTriggerFor]="tagMenu">
            {{tag.label}}
            <mat-icon>more_vert</mat-icon>
          </mat-chip>
        </ng-template>

        <mat-menu #tagMenu xPosition="before">
          <a *ngIf="!tagsToRemove.includes(tag.id)" mat-menu-item (click)="updateTag(tag)" color="primary">
            <mat-icon>edit</mat-icon>
            <span>Update Tag</span>
          </a>
          <a
            *ngIf="!tagsToRemove.includes(tag.id); else notDeleteButton"
            mat-menu-item
            (click)="removeTag(tag.id, tag.label)"
            color="primary"
          >
            <mat-icon>delete_forever</mat-icon>
            <span>Remove Tag</span>
          </a>
          <ng-template #notDeleteButton>
            <a mat-menu-item (click)="dontRemoveTag(tag.id)" color="primary">
              <mat-icon>unarchive</mat-icon>
              <span>Restore Tag</span>
            </a>
          </ng-template>
        </mat-menu>
      </ng-container>
      <ng-container *ngIf="!tags.length">
        <em style="margin-left: 8px" placeholder="No tags found"></em>
      </ng-container>
      <input
        #input
        matInput
        type="text"
        [matChipInputFor]="chipList"
        (keydown.enter)="newTag(input.value); input.value = ''"
        (keydown)="isTyping = true"
      />
      <button
        *ngIf="isTyping && input.value"
        (click)="newTag(input.value); input.value = ''; isTyping = false"
        mat-icon-button
      >
        <mat-icon color="primary" style="font-size: 24px">new_label</mat-icon>
      </button>
    </mat-chip-list>
    <mat-hint *ngIf="tagsToRemove.length" style="color: var(--warn-color)">
      <i>
        Warning: Removing a tag deletes any event resource assignments with the tag and also removes it from any
        corresponding resources
      </i>
    </mat-hint>
  </mat-form-field>
</greco-simple-dialog>
