import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'isPast' })
export class IsInThePastPipe implements PipeTransform {
  transform(value: Date, granularity?: moment.unitOfTime.StartOf) {
    if (!value) return false;

    const date = moment(value);
    if (!date.isValid()) return false;

    return date.isBefore(moment(), granularity);
  }
}
