import { SecurityResource } from '@greco/security';

export enum TransactionResourceAction {
  READ = 'READ',
  REVERSE = 'REVERSE',
  VIEW_FINANCIAL_REPORTS = 'VIEW_FINANCIAL_REPORTS',
}

export const TransactionResource: SecurityResource = {
  key: 'finance_transaction',
  module: 'Finance',

  title: 'Transaction',
  description: 'Manage community transactions',

  context: class {
    accountId?: string;
    type?: string;
  },

  actions: [
    { key: TransactionResourceAction.READ, title: 'Read', description: 'Ability to read/view financial transactions' },
    {
      key: TransactionResourceAction.REVERSE,
      title: 'Reverse',
      description: 'Ability to manually reverse a financial transaction',
    },
    {
      key: TransactionResourceAction.VIEW_FINANCIAL_REPORTS,
      title: 'View Financial Reports',
      description: 'Ability to view financial reports for the account',
    },
  ],
};
