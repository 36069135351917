import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { DataExport } from '@greco/data-exports';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class DataExportService {
  constructor(private http: HttpClient) {}

  getDataExports(query: RequestQueryBuilder, pagination: PaginatedQueryParams) {
    return toPromise(
      this.http.get<PaginatedDto<DataExport>>('/api/data-exports', {
        params: {
          ...query.queryObject,
          page: (pagination.page || 1).toString(),
          limit: (pagination.limit || 10).toString(),
        },
      })
    );
  }

  setSendEmalTrue(dataExportId: string) {
    return toPromise(this.http.post<DataExport>(`/api/data-exports/${dataExportId}/email`, {}));
  }

  getDataExport(dataExportId: string): Promise<DataExport> {
    return toPromise(this.http.get<DataExport>(`/api/data-exports/${dataExportId}`));
  }
}
