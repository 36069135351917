import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Product, ProductCollection } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class CollectionService {
  constructor(private http: HttpClient) {}

  // @Get()
  async paginate(request: RequestQueryBuilder, pagination?: Partial<PaginatedQueryParams>, onlyWithProducts?: boolean) {
    return await toPromise(
      this.http.get<PaginatedDto<ProductCollection>>('/api/collections', {
        params: {
          ...request.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          ...(onlyWithProducts !== undefined ? { onlyWithProducts: onlyWithProducts.toString() } : {}),
        },
      })
    );
  }

  // @Get(':collectionId')
  async getOne(collectionId: string) {
    return await toPromise(this.http.get<ProductCollection>(`/api/collections/${collectionId}`));
  }

  // @Get(':collectionId/products')
  async paginateProducts(collectionId: string, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<Product>>(`/api/collections/${collectionId}/products`, {
        params: {
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
}
