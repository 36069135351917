<ng-container *grecoLet="data$ | async as data">
  <ng-container *ngIf="!isAddAttendee">
    <div
      class="event-image has-image"
      [class.has-resources]="(event.resourceAssignments | filterBy : ['resource.type'] : 'PERSON').length"
    >
      <img [src]="event.imageUrl || event.calendar?.imageUrl || 'assets/greco/placeholder.png'" />
    </div>
    <div
      class="trainer-info"
      *ngIf="((event?.resourceAssignments | filterBy : ['resource.type'] : 'PERSON') || [])[0]?.resource as resource"
    >
      <img *ngIf="resource.photoURL" [src]="resource.photoURL" />
      <div>
        <h2>
          <strong>{{ resource.name }}</strong>
        </h2>
        <p *ngIf="resource.description">{{ resource.description }}</p>
      </div>
    </div>
    <div class="event-info">
      <p>
        {{ event.startDate | date : 'MMM dd hh:mm' }} - {{ event.endDate | date : 'hh:mm aa' }}
        <ng-container *ngIf="event.community?.name">
          <mat-icon
            style="
              vertical-align: middle;
              margin-bottom: 4px;
              margin-left: 4px;
              width: 14px;
              height: 14px;
              line-height: 14px;
              font-size: 14px;
              color: var(--primary-color);
            "
          >
            location_on
          </mat-icon>
          {{ event.community.name }}
        </ng-container>
      </p>
      <div class="title-row">
        <div class="title-text">
          <h2>
            <strong>{{ event.title }}</strong>
          </h2>
          <p>{{ event.calendar?.title }}</p>
        </div>
        <p style="color: dimgray" *ngIf="data?.remainingCapacity; else noSpots">
          {{ data?.remainingCapacity || 0 }} Spot{{ (data?.remainingCapacity || 0) === 1 ? '' : 's' }}
          Left
        </p>
        <ng-template #noSpots>
          <p style="color: var(--warn-color); font-weight: bold; font-size: 22px; margin-right: 8px">Full</p>
        </ng-template>
      </div>
      <div
        *ngIf="
          event.description || (event.tags | without : (event.tags | filterBy : ['hideFromFilters'] : true))?.length
        "
        (click)="openDescription = !openDescription"
      >
        <p *ngIf="event.description" class="description" [class.open]="openDescription">
          {{ event.description }}
        </p>
        <p *ngIf="openDescription || !event.description" style="color: dimgrey; margin-top: 4px">
          <ng-container *ngFor="let tag of event.tags | without : (event.tags | filterBy : ['hideFromFilters'] : true)">
            #{{ tag.label + ' ' }}
          </ng-container>
        </p>
        <p
          *ngIf="event.description"
          style="margin-top: 4px; display: inline-flex; gap: 4px; color: var(--accent-color)"
        >
          Read {{ openDescription ? 'Less' : 'More' }}
          <mat-icon>{{ openDescription ? 'expand_less' : 'expand_more' }}</mat-icon>
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="booking">
    <div class="booking-card">
      <div class="card-header">
        <div>
          <p style="margin-bottom: 0">
            <strong>Booking for {{ booking.user.displayName }}</strong>
          </p>
        </div>
      </div>
      <div class="card-content">
        <greco-item [lines]="false">
          <mat-icon item-start>confirmation_number</mat-icon>
          <div style="width: 100%">
            <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">
              <strong>{{ booking.bookingOption.title }}</strong>
            </p>

            <p
              *ngIf="booking.bookingOption.description"
              style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
            >
              <small>{{ booking.bookingOption.description }}</small>
            </p>
          </div>
        </greco-item>
        <greco-cancellation-policy-requirement
          *ngIf="event && booking.bookingOption && booking.bookingOption?.cancellation"
          [bookingOption]="booking.bookingOption"
          [event]="event"
        ></greco-cancellation-policy-requirement>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="previews?.length">
    <div class="booking-card" *ngFor="let preview of previews; let index = index">
      <div class="card-header">
        <div
          [style.cursor]="'pointer'"
          (click)="$event.stopImmediatePropagation()"
          [matMenuTriggerFor]="menu0"
          #menuTrigger="matMenuTrigger"
        >
          <p style="margin-bottom: 0">
            Booking for <strong>{{ preview.user.displayName }} </strong>
            <mat-icon *ngIf="previews.length <= (data?.remainingCapacity || 0)">arrow_drop_down</mat-icon>
          </p>
          <div class="warning">
            <small>
              <div
                *ngIf="previews[index].preview?.errors?.length"
                [innerHTML]="$any(previews[index].preview).errors.join(', ') | safe : 'html'"
                style="color: var(--warn-color)"
              ></div
            ></small>
          </div>
        </div>

        <mat-menu #menu0>
          <ng-container *ngFor="let data of data?.linkedAccounts">
            <button
              *ngIf="data.link.status === 'ACTIVE'"
              mat-menu-item
              [disabled]="data.selected || data.booked"
              (click)="selected(data.link.account!, previews[index].user)"
            >
              <p *ngIf="data.link.account?.id !== user.id; else yourself">
                {{ data.link.account?.displayName }}<span *ngIf="data.booked"> (Already Booked)</span>
              </p>
              <ng-template #yourself>
                <p>Yourself</p>
              </ng-template>
            </button>
          </ng-container>
        </mat-menu>

        <div class="header-end">
          <mat-icon
            *ngIf="previews[index].preview?.errors?.length || !user?.address?.line1; else warning"
            [matTooltip]="!user.address?.line1 ? 'Profile Completion Required!' : ''"
            color="warn"
          >
            error_outline
          </mat-icon>

          <ng-template #warning>
            <mat-icon *ngIf="previews[index].preview?.warnings?.length; else completed" [style.color]="'#df8436'">
              error_outline
            </mat-icon>
          </ng-template>

          <ng-template #completed>
            <mat-icon
              style="color: var(--success-color)"
              *ngIf="
                previews[index].preview &&
                !previews[index].preview!.errors.length &&
                !bookingOptionError &&
                !sameSpotError &&
                user?.address?.line1
              "
            >
              check_circle
            </mat-icon>
          </ng-template>

          <div
            [matTooltip]="
              previews.length <= 1
                ? 'You must have at least one other booking in order to discard this'
                : 'Discard this booking'
            "
          >
            <button *ngIf="previews.length > 1" mat-icon-button (click)="remove(preview.user)">
              <mat-icon>delete</mat-icon>
              <!-- <mat-menu #discardMenu>
                <button
                  mat-menu-item
                  (click)="$event.stopImmediatePropagation(); "
                  [matTooltip]="
                    previews.length <= 1
                      ? 'You must have at least one other booking in order to discard this'
                      : 'Discard this booking'
                  "
                  [disabled]="previews.length <= 1"
                  color="warn"
                >
                  <mat-icon>delete</mat-icon>Discard
                </button>
              </mat-menu> -->
            </button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isAddAttendee">
        <div
          *ngFor="let error of previews[index].preview?.errors"
          class="card-header"
          style="border-radius: 0; gap: 8px; justify-content: flex-start"
        >
          <mat-icon style="margin-right: 4px" color="warn">error_outline</mat-icon>{{ error }}
        </div>

        <div
          *ngFor="let warning of previews[index].preview?.warnings"
          class="card-header"
          style="border-radius: 0; gap: 8px; justify-content: flex-start"
        >
          <mat-icon style="margin-right: 4px" [style.color]="'#df8436'">error_outline</mat-icon
          >{{ warning.replace('WARNING!', '').trim() }}
        </div>
      </ng-container>

      <div class="card-content">
        <greco-preview-booking-2-page
          [user]="preview.user"
          [readonly]="previews.length > (data?.remainingCapacity || 0)"
          [bookedBy]="data?.bookedBy || user"
          [event]="event"
          [forMultiple]="true"
          [preventRedirect]="true"
          [overridePaymentMethod]="selectedPaymentMethod"
          [allowPendingBookings]="allowPendingBookings"
          [disableSpotIds]="getDisabledSpots(preview.user.id)"
          (bookingOptionChanged)="bookingOptionChanged($event)"
          [extraEventIds]="extraEventIds"
          (spotSelected)="spotSelect($event, preview.user.id)"
          (previewLoading)="changeLoading($event)"
          (previewUpdated)="$event ? previewChanged(index, $event) : {}"
          (signaturesUpdated)="$event ? signaturesChanged(index, $event) : {}"
          (requirementsUpdated)="$event ? requirementsChanged(index, $event) : {}"
        ></greco-preview-booking-2-page>
      </div>
    </div>

    <greco-multi-booking-payment-preview
      *ngIf="previews.length <= (data?.remainingCapacity || 0)"
      [hasCancellationFee]="
        (previews
          | filterBy : ['preview.booking.bookingOption.cancellation'] : true
          | pluck : 'preview.booking.bookingOption.cancellationPrice'
          | sum) > 0
      "
      [user]="data?.bookedBy || user"
      [previews]="previews"
      [event]="event"
      (selectedPaymentMethod)="selectedPaymentMethodChanged($event)"
    ></greco-multi-booking-payment-preview>
  </ng-container>

  <div class="action-buttons" *grecoLet="isValid$ | async as isValid">
    <button
      *ngIf="(!booking || previews.length) && previews.length <= (data?.remainingCapacity || 0); else waitlist"
      mat-flat-button
      color="primary"
      style="min-width: 162px"
      [disabled]="loading || !isValid || _confirming || !previews.length || (!isAddAttendee && !user.address?.line1)"
      (click)="$event.stopImmediatePropagation(); confirm()"
    >
      <span>{{ previews.length > 1 ? 'Confirm Booking x' + previews.length : 'Confirm Booking' }}</span>
      <mat-icon *ngIf="_confirming" style="margin-left: 2px" class="spin">loop</mat-icon>
    </button>

    <button
      *ngIf="booking && !previews.length && booking.status === 'CONFIRMED' && booking.event.startDate > now"
      mat-flat-button
      color="warn"
      style="min-width: 162px"
      (click)="cancelBooking()"
    >
      Cancel Booking
    </button>
    <button
      mat-stroked-button
      style="background-color: white"
      matTooltip="Select a linked user"
      *ngIf="
        (!booking || (booking && booking.event.startDate > now)) && previews.length <= (data?.remainingCapacity || 0)
      "
      (click)="$event.stopImmediatePropagation()"
      [matMenuTriggerFor]="menu"
      #menuTrigger="matMenuTrigger"
    >
      <mat-icon>person_add</mat-icon>
    </button>
    <ng-template #waitlist>
      <button
        mat-flat-button
        color="primary"
        style="min-width: 162px"
        *ngIf="previews.length > (data?.remainingCapacity || 0)"
        (click)="joinWaitlist()"
      >
        <span>{{ (event.waitlist | pluck : 'email')?.includes(user.email) ? 'Leave Waitlist' : 'Join Waitlist' }}</span>
        <mat-icon *ngIf="_confirming" style="margin-left: 2px" class="spin">loop</mat-icon>
      </button>
    </ng-template>
  </div>
  <a
    (click)="joinWaitlist()"
    style="width: 100%; display: block; text-align: center; margin-top: -24px; text-decoration: underline"
    *ngIf="
      (!booking || previews.length) &&
      previews.length <= (data?.remainingCapacity || 0) &&
      (event.waitlist | pluck : 'email')?.includes(user.email)
    "
  >
    <span>Leave Waitlist</span>
    <mat-icon *ngIf="_confirming" style="margin-left: 2px" class="spin">loop</mat-icon>
  </a>
  <mat-menu #menu>
    <ng-container *ngFor="let data of data?.linkedAccounts">
      <button
        *ngIf="data.link.status === 'ACTIVE'"
        mat-menu-item
        [disabled]="data.selected || data.booked"
        (click)="selected(data.link.account!)"
      >
        <p *ngIf="data.link.account?.id !== user.id; else yourself">
          {{ data.link.account?.displayName }}<span *ngIf="data.booked"> (Already Booked)</span>
        </p>
        <ng-template #yourself>
          <p>Yourself</p>
        </ng-template>
      </button>
    </ng-container>
    <button
      style="margin-left: 12px; width: calc(100% - 24px)"
      mat-flat-button
      class="buttons"
      color="primary"
      (click)="addGuest()"
    >
      Invite Guest
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </mat-menu>
</ng-container>
