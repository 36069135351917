import { User } from '@greco/identity-users';
import { Address } from '@greco/ui-address-input';

enum PaymentDisputeStatus {
  OPEN = 'OPEN',
  ACCEPTED = 'ACCEPTED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  WON = 'WON',
  LOST = 'LOST',
}

export interface PaymentDispute {
  id: string;
  externalId: string;
  paymentId: string;
  accountId: string;
  userId: string;
  user?: User;
  billingAddress?: Address | null;
  cancellationPolicyURL?: string;
  cancellationPolicyStripeFileId?: string;
  cancellationPolicyDisclosure?: string;
  cancellationRebuttal?: string;
  customerCommunicationURL?: string;
  customerPurchaseIP?: string;
  customerName: string;
  customerCommunicationStripeFileId?: string;
  customerSignatureURL?: string;
  customerSignatureStripeFileId?: string;
  duplicateChargeDocumentationURL?: string;
  duplicateChargeDocumentationStripeFileId?: string;
  duplicateChargeExplanation?: string;
  duplicateChargeId?: string;
  productDescription?: string;
  receiptURL?: string;
  receiptStripeFileId?: string;
  refundPolicyURL?: string;
  refundPolicyStripeFileId?: string;
  refundPolicyDisclosure?: string;
  refundRefusalExplanation?: string;
  serviceDate?: string;
  serviceDocumentationURL?: string;
  serviceDocumentationStripeFileId?: string;
  uncategorizedFileURL?: string;
  uncategorizedStripeFileId?: string;
  uncategorizedText?: string;
  evidanceSubmissionDueBy: Date;
  reason: string;
  status: PaymentDisputeStatus;
}
