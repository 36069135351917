import { Component, Input } from '@angular/core';
import {
  CalendarEvent,
  EventResourceAssignment,
  EventSeries,
  PersonResource,
  ResourceType,
  RoomResource,
} from '@greco/booking-events';

@Component({
  selector: 'greco-preview-booking-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class PreviewBookingEventDetailsComponent {
  photoUrls = ['assets/lf3/icon_square_pad.png'];

  private _event?: CalendarEvent | EventSeries | null;
  @Input() get event() {
    return this._event;
  }
  set event(event) {
    this._event = event;

    const trainers = event?.resourceAssignments?.reduce((acc, assignment) => {
      if (assignment?.resource?.type === ResourceType.PERSON) acc.push(assignment?.resource);
      return acc;
    }, [] as PersonResource[]);
    this.photoUrls = trainers?.length
      ? (trainers.map(trainer => trainer.photoURL).filter(url => !!url) as string[])
      : ['assets/lf3/icon_square_pad.png'];
  }

  getPersonAssignments(assignments: EventResourceAssignment[]) {
    return assignments?.filter(assignment => assignment?.resource?.type === ResourceType.PERSON) || [];
  }

  getRoomAssignment(assignments: EventResourceAssignment[]) {
    return assignments?.find(assignment => assignment?.resource?.type === ResourceType.ROOM)?.resource as RoomResource;
  }
}
