import { Component, Input } from '@angular/core';
import { Community } from '@greco/identity-communities';
import { PropertyListener } from '@greco/property-listener-util';
import { VideoTag } from '@greco/videos';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-video-tag',
  templateUrl: './video-tag.component.html',
  styleUrls: ['./video-tag.component.scss'],
})
export class VideoTagComponent {
  @PropertyListener('tag') private _tag$ = new BehaviorSubject<VideoTag | null>(null);
  @Input() tag!: VideoTag;
  @Input() admin = false;
  @Input() icon!: string;

  @PropertyListener('community') private _community$ = new BehaviorSubject<Community | undefined>(undefined);
  @Input() community?: Community | undefined;

  readonly hide$ = combineLatest([this._tag$, this._community$]).pipe(
    switchMap(async ([tag, community]) => {
      if (community && tag) {
        const tagCommunity = (tag as any).communityId;
        if (tagCommunity) {
          if (tagCommunity === community.id) return false;
          else return true;
        } else return false;
      } else return false;
    })
  );
}
