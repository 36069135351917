/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */
import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  QueryList,
  TemplateRef,
  ViewChild,
  ɵisPromise
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { isObservable, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ButtonColumn, TableColumn } from './table.models';

@Directive({
  selector: '[grecoTableColTemplate]'
})
export class TableColumnTemplate {
  @Input('grecoTableColTemplate') id: string;
  constructor(public templateRef: TemplateRef<TableColumnTemplate>) {}
}

@Component({
  selector: 'greco-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit, AfterViewInit {
  @ContentChildren(TableColumnTemplate) colTemplates: QueryList<TableColumnTemplate>;
  @Output()
  rowClick = new EventEmitter<T>();
  @Output() loadMore = new EventEmitter<void>();
  @HostBinding('class.hover-highlight') @Input() hoverHighlight = false;
  @HostBinding('class.elevated') @Input() elevated = true;
  @Input() showFooter = true;
  @Input() showAllLoaded = true;

  @Input() data: T[];
  @Input() totalItems: number;

  @Input() trackBy = 'id';
  @Input() reachedEnd = true;
  @Input() loading = false;
  @Input() columns: TableColumn<T>[];

  private dataSource: MatTableDataSource<T>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  getTemplate(id: string) {
    return this.colTemplates.find(t => t.id === id)?.templateRef;
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<T>(this.data);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  trackByField(item: T) {
    return item[this.trackBy];
  }

  buttonClick(item: T, column: ButtonColumn<T>, event: Event, menu: MatMenuTrigger) {
    column?.action?.(item, event);
    column?.menuOptions?.(item)?.then(options => {
      menu.menuData = { options };
      if (options?.length) menu.openMenu();
    });
    event.stopImmediatePropagation();
  }
}

@Pipe({ name: 'itemFn' })
export class ItemFunctionPipe<T> implements PipeTransform {
  transform(item: T, fn: (item: T, ...args: any[]) => string | Promise<string> | Observable<string>, ...args: any[]) {
    let result = fn?.(item, ...args);
    if (result === '') result = ' ';

    if (ɵisPromise(result)) return result;
    if (isObservable(result)) return result.pipe(startWith(' '));
    return Promise.resolve(result);
  }
}
