import { Pipe, PipeTransform } from '@angular/core';
import { EventLocation, EventTag, EventTrainer } from '@greco/booking-events2';

export const flattenAndCombineFilters =
  (type: 'tags' | 'trainers') =>
  (locations: EventLocation[]): (EventTag | EventTrainer)[] => {
    const filters: (EventTag | EventTrainer)[] = [];
    locations.forEach(location => {
      location[type].forEach(f => {
        const existingTag = filters.find(t => t.label === f.label);
        if (existingTag) {
          existingTag.id += `-${f.id}`;
        } else {
          filters.push({ id: f.id, label: f.label, imageUrl: (f as any).imageUrl });
        }
      });
    });
    return filters;
  };

@Pipe({
  name: 'flattenAndCombineFilters',
})
export class FlattenAndCombineFiltersPipe implements PipeTransform {
  transform(locations: EventLocation[], type: 'tags' | 'trainers'): (EventTag | EventTrainer)[] {
    return flattenAndCombineFilters(type)(locations);
  }
}
