<form [formGroup]="formGroup">
  <mat-form-field appearance="standard" style="width: 29em !important; margin-bottom: 25px">
    <mat-label>Product</mat-label>
    <greco-product-variant-picker
      placeholder="Start typing to search for products..."
      [communityId]="communityId"
      [required]="true"
      formControlName="variant"
      [multiselect]="true"
      [onlyProducts]="true"
    ></greco-product-variant-picker>
  </mat-form-field>
</form>
