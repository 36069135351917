<div class="complimentary" *ngIf="complimentaryOption">
  <mat-checkbox color="primary" [checked]="complimentary" (change)="toggleComplimentary()">
    Complimentary
  </mat-checkbox>
</div>

<div class="container" *ngIf="data$ | async as data">
  <ng-template #optionTemplate let-option let-showInfo="showInfo">
    <div class="perk-info">
      <div class="badge">
        <greco-perk-badge style="user-select: none" [badge]="option.badge" [small]="true"></greco-perk-badge>
      </div>

      <div>
        <h3 [matTooltip]="option.title">{{ option.title }}</h3>

        <div style="display: grid; grid-template-columns: auto 1fr">
          <p
            *grecoLet="option.bookingWindow | grecoMinutes as bookingWindow"
            [matTooltip]="(bookingWindow | test : '-') ? '' : bookingWindow + ' Booking Window'"
          >
            <span>{{ option.price / 100 | currency }}</span>
            <span>{{ option.requiredBoosters ? ' + ' + option.requiredBoosters + ' Boosters' : '' }}</span>
          </p>

          <a class="show-info" *ngIf="showInfo && !complimentary" (click)="openInfoDialog(option)">
            <span>Details</span>
            <mat-icon>chevron_right</mat-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="available">
      <h5>Available</h5>
      <p *ngIf="!complimentary">TODO</p>
      <p *ngIf="complimentary">-</p>
    </div>

    <div class="subscriptions">
      <h5>Subscriptions</h5>
      <p *ngIf="!complimentary">TODO</p>
      <p *ngIf="complimentary">-</p>
    </div>

    <div class="used">
      <h5>Used</h5>
      <p *ngIf="!complimentary">TODO</p>
      <p *ngIf="complimentary">-</p>
    </div>
  </ng-template>

  <div
    class="grid"
    [class.complimentary-selected]="complimentary"
    [class.border]="expanded"
    *ngIf="data.selectedOption"
  >
    <ng-container
      [ngTemplateOutlet]="optionTemplate"
      [ngTemplateOutletContext]="{ $implicit: data.selectedOption, showInfo: true }"
    ></ng-container>

    <div class="btn">
      <button mat-icon-button *ngIf="data.options.length > 1 && !complimentary" (click)="expanded = !expanded">
        <mat-icon>swap_vert</mat-icon>
      </button>
    </div>
  </div>

  <ng-container *ngIf="data.options.length > 1">
    <div [@heightExpansion]="expanded ? 'expanded' : 'collapsed'">
      <ng-container *ngFor="let option of data.options">
        <div *ngIf="option.id !== data.selectedOption?.id" class="grid hover" (click)="selectOption(option)">
          <ng-container
            [ngTemplateOutlet]="optionTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-container>

          <div class="btn">
            <button mat-icon-button>
              <mat-icon>arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
