import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BookingOption } from '@greco/booking-events';
import { BookingOptionService } from '../../services';

@Component({
  selector: 'greco-booking-option-page',
  templateUrl: './booking-option.page.html',
  styleUrls: ['./booking-option.page.scss'],
})
export class BookingOptionPage {
  constructor(private bookingOptionSvc: BookingOptionService, private snacks: MatSnackBar) {}

  @Input() bookingOption!: BookingOption;

  async saved() {
    this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    await this.refresh();
  }

  async refresh() {
    this.bookingOption = await this.bookingOptionSvc.getOne(this.bookingOption.id);
  }
}
