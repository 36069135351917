import { SecurityResource } from '@greco/security';

export enum AccountResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  CONFIGURE = 'CONFIGURE',
}

export const AccountResource: SecurityResource = {
  key: 'finance_account',
  module: 'Finance',

  title: 'Financial Account',
  description: 'Manage financial accounts',

  context: class {
    accountId?: string;
  },

  actions: [
    {
      key: AccountResourceAction.READ,
      title: 'Read',
      description: 'Ability to read/view financial account information',
    },
    { key: AccountResourceAction.CREATE, title: 'Create', description: 'Ability to create a new financial account' },
    {
      key: AccountResourceAction.CONFIGURE,
      title: 'Configure',
      description: "Ability to configure/update a financial account's information",
    },
  ],
};
