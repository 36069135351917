<div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary" value="40"></mat-progress-bar>
</div>
<greco-simple-dialog
  [data]=" {
  title: dialogData.title,
  subtitle: dialogData.subtitle,
  showCloseButton: dialogData.showCloseButton,
  buttons: data.series? [cancelButton] : !userControl.value?.user ? [cancelButton] : [cancelButton, confirmButton]
}"
>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>User</mat-label>
      <greco-contact-picker
        [communityId]="data.event.community.id || data.series.community.id || ''"
        [formControl]="userControl"
        [withUser]="true"
        [required]="true"
        [disabled]="!!userControl.value?.user && !!data.series"
      ></greco-contact-picker>
    </mat-form-field>
  </div>

  <ng-container *ngIf="userControl.value?.user">
    <ng-container *ngIf="data?.series; else notCourse">
      <greco-user-course-page
        [user]="userControl.value.user"
        [forceMobile]="true"
        [footerInPage]="true"
        [event]="data.series"
        (booked)="booked($event)"
        [preventRedirect]="true"
        [allowPendingBookings]="true"
      ></greco-user-course-page>
    </ng-container>

    <ng-template #notCourse>
      <!-- <greco-multi-booking-preview
        #bookingPreview
        [forceMobile]="true"
        [event]="data.event"
        [footerInPage]="true"
        [preventRedirect]="true"
        [allowPendingBookings]="true"
        [user]="userControl.value.user"
        [isAddAttendee]="true"
        [extraEventIds]="data.extraEventIds || []"
        (multiPreviewLoading)="changeLoading($event)"
        (booked)="booked($event)"
      ></greco-multi-booking-preview> -->
      <!-- <div
        *ngIf="!(bookingPreview.preview$ | async)?.booking?.bookingOption"
      ></greco-preview-booking-2-page>
      <div
        *ngIf="(bookingPreview.hasBookingOptions$ | async) === (false || null)"
        style="display: flex; align-items: center; padding: 0 8px; margin-top: 16px"
      >
        <mat-icon color="warn" style="margin-right: 8px">error_outline</mat-icon>
        <i>No applicable booking options for user {{userControl.value.displayName}} ({{userControl.value.email}})</i>
      </div> -->
    </ng-template>
  </ng-container>
</greco-simple-dialog>
