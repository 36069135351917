import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { TerminalResource, TerminalResourceAction } from '@greco/finance-payments';
import { SecurityService } from '@greco/ngx-security-util';
import { Purchase, PurchaseResource, PurchaseResourceAction, PurchaseStatus } from '@greco/sales-purchases';
import { CurrencyMaskConfig } from 'ngx-currency';
import { RETURN_TO_INVENTORY_DIALOG } from '../../return-to-inventory.token';
import { PurchaseService } from '../../services';

function validateBalanceRefundAmount(maxRefund: number) {
  return (formGroup: FormGroup) => {
    const balanceRefundAmount = formGroup.controls['balanceRefundAmount'];
    const paymentRefundAmount = formGroup.controls['paymentRefundAmount'];

    if (maxRefund < 0) {
      if (balanceRefundAmount.value > (maxRefund - paymentRefundAmount.value) * -1) {
        balanceRefundAmount.setErrors({ invalid: true });
      } else balanceRefundAmount.setErrors(null);
    } else if (balanceRefundAmount.value > maxRefund - paymentRefundAmount.value) {
      balanceRefundAmount.setErrors({ invalid: true });
    } else balanceRefundAmount.setErrors(null);
  };
}

@Component({
  selector: 'greco-refund-purchase-dialog',
  templateUrl: './refund.dialog.html',
  styleUrls: ['./refund.dialog.scss'],
})
export class RefundPurchaseDialog {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private purchaseSvc: PurchaseService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<RefundPurchaseDialog>,
    @Inject(MAT_DIALOG_DATA) public readonly purchase: Purchase,
    @Inject(RETURN_TO_INVENTORY_DIALOG) public readonly returnInventoryDialog: any
  ) {}

  readonly terminalControl = new FormControl(null);

  readonly dialogData: DialogData = {
    title: 'Refund Purchase',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  readonly maxRefund: number =
    (this.purchase.balanceUsed -
      (this.purchase.balanceRefundedAmount || 0) +
      (this.purchase.payment?.amount || 0) -
      (this.purchase.payment?.refundedAmount || 0)) /
    100;

  readonly maxRefundPositive = this.maxRefund > 0;

  readonly maxPaymentRefund: number =
    ((this.purchase.payment?.amount || 0) - (this.purchase.payment?.refundedAmount || 0)) / 100;

  formGroup = this.formBuilder.group(
    {
      balanceRefundAmount: [{ value: 0, disabled: false }],
      paymentRefundAmount: [
        { value: 0, disabled: !this.purchase.payment?.amount },
        [Validators.min(0), Validators.max(this.getMinValue([this.maxRefund, this.maxPaymentRefund]))],
      ],
      refundReason: ['', [Validators.required]],
      returnToInventory: [false],
    },
    { validator: validateBalanceRefundAmount(this.maxRefund) }
  );

  refundReasons = [
    'Admin Error',
    'Banned Member',
    'Double Charge',
    'Guest Pass Refund for New Member Sign-Up',
    'Incorrect Product Purchased',
    'Incorrect Booking',
    'Incorrect payment method',
    'Member Experience',
    'Service Quality',
    'Club Service Changed',
    'Waive Late Fees',
    "Membership Hold wasn't applied",
    'Realign Billing Period',
    'Product Change',
    'Price Change',
    'Product Revoked',
    'Promotional Discount',
    'Tax Exemption',
    'Other',
  ];

  membershipCancellationReasons = [
    'Membership Cancellation',
    'Membership Cancelled Rescind',
    'Membership Cancelled Relocation',
    'Membership Cancelled Medical',
    'Member Cancelled Service Quality',
    'Member Cancelled End of Commitment Period',
    'Member Cancelled Other',
  ];

  membershipTransferReasons = ['Membership Transfer', 'Home Club Transfer'];

  personalTrainingReasons = [
    'PT Cancellation',
    'PT Cancelled Rescind',
    'PT Cancelled Medical',
    'PT Cancelled Relocation',
    'PT Package Change',
    'PT Cancelled Service Quality',
    'PT Cancelled Other',
  ];

  activeKidsClubReasons = [
    'AKC Cancellation',
    'AKC Cancelled Rescind',
    'AKC Cancelled Relocation',
    'AKC Cancelled Medical',
    'AKC Cancelled Service Quality',
    'AKC Cancelled Other',
  ];

  waiveLateFeeReasons = [
    'Waive (AKC)',
    'Waive (Booking Error)',
    'Waive (Facilities/Equipment)',
    'Waive (Medical)',
    'Waive (Member Experience)',
    'Waive (Missed Check-In)',
    'Waive (Other)',
  ];

  processing = false;

  canRefundToBalance$ = this.securitySvc.hasAccess(
    PurchaseResource.key,
    PurchaseResourceAction.REFUND_TO_BALANCE,
    {},
    true
  );

  canRefundToPayment$ = Promise.all([
    this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.REFUND_TO_PAYMENT_METHOD, {}, true),
    this.securitySvc.hasAccess(TerminalResource.key, TerminalResourceAction.USE_TERMINALS, {}, true),
  ]).then(
    ([canRefund, canUseTerminals]) =>
      canRefund && (this.purchase.payment?.gatewayMetadata === 'interac_refund' ? canUseTerminals : true)
  );

  isInteracPayment = this.purchase.payment?.gatewayMetadata?.payment_method_type === 'interac_present';

  close(result?: any) {
    this.dialogRef.close(result);
  }

  getMinValue(values: number[]) {
    return Math.min(...values);
  }

  async submit() {
    this.processing = true;

    try {
      if (!this.formGroup.value.balanceRefundAmount) this.formGroup.value.balanceRefundAmount = 0;
      if (!this.formGroup.value.paymentRefundAmount) this.formGroup.value.paymentRefundAmount = 0;
      if (
        !(
          this.formGroup.value.balanceRefundAmount + this.formGroup.value.paymentRefundAmount <=
          (this.maxRefundPositive ? this.maxRefund : this.maxRefund * -1)
        )
      ) {
        // TODO(adaoust): Add additional validation.
        this.snacks.open('Refund Limit Exceeded.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        this.close(null);
        return;
      }

      const returnToInventory = this.formGroup.value.returnToInventory;

      const paymentRefundAmount = Math.round(this.formGroup.value.paymentRefundAmount * 100);

      const terminalId = this.terminalControl.value?.externalId;
      if (paymentRefundAmount && this.isInteracPayment && !terminalId) throw new Error();

      const refundId =
        paymentRefundAmount && this.isInteracPayment
          ? await this.purchaseSvc.handleInteracRefund(
              this.purchase,
              terminalId,
              paymentRefundAmount,
              this.dialogRef.afterClosed()
            )
          : null;

      const purchase = await this.purchaseSvc.refund(
        this.purchase.id,
        Math.round(this.formGroup.value.balanceRefundAmount * 100) * (this.maxRefundPositive ? 1 : -1),
        paymentRefundAmount,
        this.formGroup.value.refundReason,
        refundId ?? undefined
      );

      if (purchase.status !== PurchaseStatus.REFUNDED && purchase.status !== PurchaseStatus.PARTIALLY_REFUNDED) {
        console.error('Purchase could not be refunded');
        this.snacks.open('Purchase could not be refunded', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      } else {
        if (returnToInventory) {
          const inventoryDialog = this.matDialog.open(this.returnInventoryDialog, {
            data: {
              purchaseId: purchase.id,
              variants: purchase.items
                .filter(item => (item as any).variantId)
                .map(item => {
                  return {
                    variantId: (item as any).variantId,
                    quantity: item.quantity,
                    title: item.displayName,
                    variant: (item as any).variant,
                  };
                }),
            },
          });
          await toPromise(inventoryDialog.afterClosed());
        }
        this.snacks.open('Refund successful!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;

    this.close({
      submit: true,
      balanceRefundAmount: this.formGroup.value.balanceRefundAmount,
      paymentRefundAmount: this.formGroup.value.paymentRefundAmount,
    });
  }
}
