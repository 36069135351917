import { SecurityResource } from '@greco/security';

export const CALENDLY_SECURITY_RESOURCE = 'calendly';

export enum CalendlySecurityResourceAction {
  READ = 'READ',
  MANAGE = 'MANAGE',
}

export const CalendlySecurityResource: SecurityResource = {
  key: CALENDLY_SECURITY_RESOURCE,
  module: 'booking',
  title: 'Calendly',
  description: 'Manage Calendly events',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CalendlySecurityResourceAction.READ,
      title: 'View Calendly tab',
      description: 'Ability to view the Calendly tab',
    },
    {
      key: CalendlySecurityResourceAction.MANAGE,
      title: 'Manage Calendly',
      description: 'Ability to manage Calendly account information',
    },
  ],
};
