import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentDispute } from '@greco/finance-payments';
import { FilterBarComponent } from '@greco/ngx-filters';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PaymentDisputeService } from '../../services';
import { PaymentDisputeCreatedDateFilter } from './filters/createdDate';
import { PaymentDisputeDueDateFilter } from './filters/dueDate';
import { PaymentDisputeStatusFilter } from './filters/status';

@Component({
  selector: 'greco-payment-disputes-page',
  templateUrl: './payment-disputes.page.html',
  styleUrls: ['./payment-disputes.page.scss'],
})
export class PaymentDisputesPage implements OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private paymentDisputeSvc: PaymentDisputeService
  ) {}

  @ViewChild(FilterBarComponent) private filterBar?: FilterBarComponent;

  readonly _accountId$ = new BehaviorSubject<string | null>(null);
  @Input() set accountId(accountId: string | null) {
    this._accountId$.next(accountId);
  }
  get accountId() {
    return this._accountId$.value;
  }

  refresh$ = new BehaviorSubject<null>(null);

  filterOptions = [PaymentDisputeCreatedDateFilter, PaymentDisputeDueDateFilter, PaymentDisputeStatusFilter];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  stats$ = this._accountId$.pipe(
    switchMap(async accountId => (accountId ? await this.paymentDisputeSvc.getStats(accountId) : null))
  );

  async openPaymentDispute(paymentDispute: PaymentDispute) {
    await this.router.navigate(['..', 'payment-disputes', paymentDispute.id], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.filters$.complete();
  }
}
