import { SecurityResource } from '@greco/security';

export const EVENT_AGREEMENT_USAGE_SECURITY_RESOURCE = 'event_agreements_usage';

export enum EventAgreementUsageSecurityActions {
  READ = 'READ',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export const EventAgreementUsageSecurityResource: SecurityResource = {
  key: EVENT_AGREEMENT_USAGE_SECURITY_RESOURCE,
  module: 'events',
  title: 'Event Usage Agreements ',
  description: 'Manage event usage agreement options',

  context: class {
    eventId?: string;
  },

  actions: [
    {
      key: EventAgreementUsageSecurityActions.READ,
      title: 'Read usage agreements in a community',
      description: 'Ability to read usage agreements in a community',
    },
    {
      key: EventAgreementUsageSecurityActions.CREATE,
      title: 'Create usage agreements to a community',
      description: 'Ability to create a new usage agreement in a community',
    },
    {
      key: EventAgreementUsageSecurityActions.DELETE,
      title: 'Delete usage agreements from a community',
      description: 'Ability to delete a usage agreement in a community',
    },
  ],
};
