import { Injectable } from '@angular/core';
import { AutocompleteFilter } from '@greco/ngx-filters';
import { Perk } from '@greco/sales-perks';
import { CondOperator, RequestQueryBuilder, SFields } from '@nestjsx/crud-request';
import { PerkService } from '../../../services';

@Injectable({ providedIn: 'any' })
export class UserPerkPerkFilter extends AutocompleteFilter {
  constructor(private perkSvc: PerkService) {
    super('UserPerkPerkFilter', {
      description: '',
      label: 'Perk',
      properties: ['perk.id'],
      shortLabel: 'Perk',
      canOpen: true,
      onlyOne: true,
    });
  }

  public communityId?: string;

  getImageUrl() {
    return null;
  }

  getAutocompleteHtml(value: Perk): string {
    return value.title;
  }

  getValueLabel(value: Perk): string {
    return value?.title || '';
  }

  async getValueOptions(search?: string): Promise<any[]> {
    if (!this.communityId) return [];

    const perks = await this.perkSvc.paginate(
      new RequestQueryBuilder().search({ title: { $contL: search } }),
      this.communityId,
      { limit: 5 }
    );

    return perks.items;
  }

  serializeValue(value: Perk): string {
    return value.id;
  }

  deserializeValue(serializedValue: string) {
    return this.perkSvc
      .paginate(new RequestQueryBuilder().search({ id: serializedValue }), this.communityId, { limit: 1 })
      .then(({ items }) => items[0]);
  }

  getPropertySField(property: string, operator: CondOperator, value: Perk): SFields {
    return { [property]: { [operator]: value.id } };
  }
}
