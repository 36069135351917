import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatFormFieldControl } from '@angular/material/form-field';
import { RoomResource } from '@greco/booking-events';
import { Event, SpotDetails } from '@greco/booking-events2';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { EventService } from '../../services';

@Component({
  selector: 'alt-room-spot-picker',
  templateUrl: './room-spot-picker.component.html',
  styleUrls: ['./room-spot-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RoomSpotPickerComponent }],
})
export class RoomSpotPickerComponent {
  constructor(
    public bottomSheet: MatBottomSheet,
    private eventSvc: EventService,
    private breakpoints: BreakpointObserver
  ) {}

  @ViewChild('canvas', { static: true }) private canvas?: ElementRef<HTMLCanvasElement>;
  @ViewChild('primary') primary: any | null = null;

  @PropertyListener('event') event$ = new BehaviorSubject<Event | null>(null);
  @Input() event?: Event;

  @Input() selectedSpotId?: string | null = null;

  @Input() readonly = false;

  @Output() spotSelected = new EventEmitter<SpotDetails>();

  room?: RoomResource | null = null;
  spots?: SpotDetails[] | null = null;
  bookedSpots?: SpotDetails[] | null = null;

  cursorStyle = '';
  hoveredSpotId = '';

  scale = 1;
  mobile = false;
  primaryColor: string | null = null;

  image: HTMLImageElement | null = null;
  canvasContext: CanvasRenderingContext2D | null = null;

  refreshCanvas$ = new BehaviorSubject<null>(null);

  isMobile$ = this.breakpoints.observe('(max-width: 700px)').pipe(
    map(({ matches }) => matches),
    tap(matches => (this.mobile = matches)),
    shareReplay(1)
  );

  room$ = this.event$.pipe(
    map(event => event?.requirements.spotBooking?.room),
    tap(room => (this.room = room))
  );

  bookedSpots$ = combineLatest([this.room$, this.eventSvc.bookedSpots$]).pipe(
    map(([room, bookedSpots]) =>
      room?.spots?.reduce((acc, spot) => {
        const bookedSpot = bookedSpots.find(booked => booked.spotId === spot.id);
        if (bookedSpot) {
          acc.push({
            spotId: bookedSpot.spotId,
            spotName: bookedSpot.spotName,
            spotNumber: bookedSpot.spotNumber,
            spotDescription: bookedSpot.spotDescription,
            userId: bookedSpot.userId,
            photoUrl: bookedSpot.photoUrl,
          } as SpotDetails);
          return acc;
        }

        return acc;
      }, [] as SpotDetails[])
    ),
    tap(bookedSpots => (this.bookedSpots = bookedSpots))
  );

  spots$ = combineLatest([this.room$, this.bookedSpots$]).pipe(
    map(([room, bookedSpots]) => {
      if (!room || !bookedSpots) return null;

      let allSpots: SpotDetails[] =
        room.spots?.map(spot => {
          const bookedSpot = bookedSpots?.find(booked => booked.spotId === spot.id);
          if (bookedSpot) return bookedSpot;

          return {
            spotId: spot.id,
            spotName: spot.name,
            spotNumber: spot.spotNumber,
            spotDescription: spot.description,
          };
        }) || [];

      const generalSpot: SpotDetails = {
        spotId: 'general',
        spotName: 'General',
        spotNumber: 0,
        spotDescription: 'Let staff assign a spot for you',
      };

      allSpots = [...allSpots, generalSpot];
      allSpots = allSpots.sort((a, b) => a.spotNumber - b.spotNumber);

      return allSpots;
    }),
    tap(spots => (this.spots = spots))
  );

  drawSpots$ = combineLatest([this.room$, this.spots$, this.refreshCanvas$]).pipe(
    tap(async ([room, spots]) => {
      if (!room || !spots) return;

      if (!this.image || !this.canvasContext) {
        this.getCanvasDetails();
        return;
      }

      this.drawImageScaled(this.image, this.canvasContext);
      this.scale = this.canvasContext.canvas.width / this.canvasContext.canvas.clientWidth;

      room.spots?.forEach(async spot => {
        const spotBooked = this.bookedSpots?.find(bookedSpot => bookedSpot.userId && bookedSpot.spotId === spot.id);

        if (this.canvasContext && spot.x && spot.y && spot.width && spot.height) {
          if (spotBooked) {
            const midx = (spot.x * 2 + spot.width) / 2;
            const midy = (spot.y * 2 + spot.height) / 2;
            if (!this.mobile) {
              this.drawCircularImage(spotBooked.photoUrl || 'assets/lf3/icon_square_pad.png', midx, midy);
            }
          } else {
            if (this.selectedSpotId === spot.id) {
              this.canvasContext.fillStyle = 'rgba(105, 207, 142, .7)';
            } else if (this.hoveredSpotId === spot.id) {
              this.canvasContext.fillStyle = 'rgba(103, 146, 161, .8)';
            } else {
              this.canvasContext.fillStyle = 'rgba(103, 146, 161, .5)';
            }
            this.canvasContext?.fillRect(spot.x, spot.y, spot.width, spot.height);
          }
        }
      });
    })
  );

  selectSpot(spot: SpotDetails) {
    if (this.bookedSpots?.map(bookedSpot => bookedSpot.spotId)?.includes(spot.spotId)) return;

    this.selectedSpotId = spot.spotId;
    this.refreshCanvas$.next(null);
    this.spotSelected.emit(spot);
  }

  hoverSpot(spotId?: string) {
    if (spotId && spotId !== this.hoveredSpotId) {
      this.hoveredSpotId = spotId;
      this.refreshCanvas$.next(null);
    }
  }

  noSpotHovered() {
    if (this.hoveredSpotId !== '') {
      this.hoveredSpotId = '';
      this.cursorStyle = '';
      this.refreshCanvas$.next(null);
    }
  }

  canvasClick(event: MouseEvent) {
    this.room?.spots?.forEach(spot => {
      if (spot.x && spot.y && spot.width && spot.height) {
        if (spot.x <= event.offsetX * this.scale && spot.x + spot.width >= event.offsetX * this.scale) {
          if (spot.y <= event.offsetY * this.scale && spot.y + spot.height >= event.offsetY * this.scale) {
            this.selectSpot({
              spotId: spot.id,
              spotName: spot.name,
              spotNumber: spot.spotNumber,
              spotDescription: spot.description,
            });
            return;
          }
        }
      }
    });
  }

  onCanvas(event: MouseEvent) {
    let onSpot = false;
    for (const spot of this.room?.spots || []) {
      if (!onSpot && spot.x && spot.y && spot.width && spot.height) {
        if (spot.x <= event.offsetX * this.scale && spot.x + spot.width >= event.offsetX * this.scale) {
          if (spot.y <= event.offsetY * this.scale && spot.y + spot.height >= event.offsetY * this.scale) {
            this.hoverSpot(spot.id);
            onSpot = true;

            const spotBooked = this.spots?.find(bookedSpot => bookedSpot.userId && bookedSpot.spotId === spot.id);
            if (!spotBooked) this.cursorStyle = 'pointer';
            return;
          }
        }
      }
    }

    if (!onSpot) this.noSpotHovered();
  }

  drawImageScaled(image: HTMLImageElement, canvasContext: CanvasRenderingContext2D) {
    const canvas = canvasContext.canvas;
    canvas.height = image.height;
    canvas.width = image.width;

    const wRatio = canvas.width / image.width;
    const scaledHeight = image.height * wRatio;

    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    canvasContext.drawImage(image, 0, 0, canvas.width, scaledHeight);
  }

  drawCircularImage(imageURL: string, x: number, y: number) {
    const context = this.canvasContext;

    if (context) {
      const r1 = 42; // radius of the coloured circle on the outside
      const r2 = 34; // radius of the white circle inside
      const r3 = 30; // radius of the image drawn on top of those

      context.save();
      context.beginPath();
      context.arc(x, y, 20, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      context.fillStyle = this?.primaryColor || 'rgba(104, 90, 143, 1)';
      this.canvasContext?.fillRect(x - r1 / 2, y - r1 / 2, r1, r1);

      context.beginPath();
      context.arc(x, y, r2 / 2, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      context.fillStyle = 'rgba(255, 255, 255, 1)';
      this.canvasContext?.fillRect(x - r2 / 2, y - r2 / 2, r2, r2);

      context.beginPath();
      context.arc(x, y, r3 / 2, 0, Math.PI * 2, true);
      context.closePath();
      context.clip();

      const image = new Image();
      image.src = imageURL;

      const imageWidthHeightRatio = image.width / image.height;
      let newWidth = r3;
      let newHeight = newWidth / imageWidthHeightRatio;

      if (newHeight < r3) {
        newHeight = r3;
        newWidth = newHeight * imageWidthHeightRatio;
      }

      let xoffset = 0;
      if (newWidth !== r3) xoffset = (newWidth - r3) / 2;

      context.drawImage(image, x - xoffset - r3 / 2, y - r3 / 2, newWidth, newHeight);
      context.restore();
    }
  }

  async getCanvasDetails() {
    if (this.canvas && this.room?.imageURL) {
      this.canvasContext = this.canvas.nativeElement.getContext('2d');

      if (this.canvasContext) {
        this.image = new Image();
        this.image.src = this.room.imageURL;

        const imageRef = this.image;
        await new Promise(res => (imageRef.onload = res));

        this.drawImageScaled(this.image, this.canvasContext);

        if (this.primary) this.primaryColor = getComputedStyle(this.primary?.nativeElement).color;
        this.refreshCanvas$.next(null);
      }
    }
  }
}
