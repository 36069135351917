<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <!-- Title -->
    <mat-form-field style="margin: 13px 0px 0px -1px" appearance="standard">
      <mat-label>Title</mat-label>
      <input matInput type="text" [required]="true" [readonly]="readonly" formControlName="title" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Event Start</mat-label>
      <greco-datetime-timezone-input
        #startDatetimePicker
        [disabled]="readonly || isCompleted"
        [required]="true"
        formControlName="eventStart"
      ></greco-datetime-timezone-input>
    </mat-form-field>

    <!-- Duration -->
    <mat-form-field class="flex-1" appearance="standard">
      <mat-label>Duration</mat-label>
      <greco-minutes-input
        [required]="required"
        [disabled]="readonly"
        placeholder="Duration"
        formControlName="duration"
      ></greco-minutes-input>
    </mat-form-field>
  </form>
</greco-simple-dialog>
