<!-- Account -->
<div style="display: flex; gap: 4px">
  <mat-icon style="width: 20px; height: 20px; font-size: 20px">place</mat-icon>
  <h4 style="margin-bottom: 8px">
    Sold by <strong>{{ product.community.name }}</strong>
  </h4>
</div>

<!-- Price -->
<div style="display: flex; flex-direction: row; align-items: center">
  <h2>
    <span>{{ selectedVariant.price / 100 | currency }}</span>
    <ng-container *ngIf="selectedVariant.recurrence && selectedVariant.recurrence.frequency">
      <span>&nbsp;Every {{ selectedVariant.recurrence | grecoRecurrence }}</span>
      <small
        >&nbsp;&nbsp;
        <div
          *ngIf="
            selectedVariant.recurrence.frequency && selectedVariant.recurrence.cycles;
            then fixedDisplay;
            else indefDisplay
          "
        ></div>
        <ng-template #fixedDisplay>
          <small
            >( Billed {{ selectedVariant.recurrence.cycles }} time{{
              (selectedVariant.recurrence.cycles || 0) > 1 ? 's' : ''
            }}
            <ng-container *ngIf="selectedVariant.enrolmentFee">
              + {{ selectedVariant.enrolmentFee / 100 | currency }}
              {{ selectedVariant.enrolmentFeeName || 'Initiation Fee' }} </ng-container
            >)</small
          >
        </ng-template>
        <ng-template #indefDisplay>
          <small
            >( Only {{ selectedVariant | variantPriceCost }} {{ selectedVariant | variantPriceRate : false }}
            <ng-container *ngIf="selectedVariant.enrolmentFee">
              + {{ selectedVariant.enrolmentFee / 100 | currency }}
              {{ selectedVariant.enrolmentFeeName || 'Initiation Fee' }} </ng-container
            >)</small
          >
        </ng-template>
      </small>
    </ng-container>
  </h2>
</div>

<!-- Description -->
<mat-divider *ngIf="product.description" style="margin-bottom: 12px"></mat-divider>
<p *ngIf="product.description" style="white-space: pre-wrap" [innerHtml]="product.description | safe : 'html'"></p>
<ng-container *grecoLet="autoUpgradeVariant | async as variant">
  <small *ngIf="selectedVariant.recurrence!.cycles" style="color: var(--warn-color)">
    <strong>
      Total subscription length:
      {{ selectedVariant.recurrence!.cycles * selectedVariant.recurrence!.frequency }}
      {{
        selectedVariant.recurrence?.period === 'DAILY'
          ? 'day'
          : selectedVariant.recurrence?.period === 'WEEKLY'
          ? 'week'
          : selectedVariant.recurrence?.period === 'MONTHLY'
          ? 'month'
          : selectedVariant.recurrence?.period === 'YEARLY'
          ? 'year'
          : 'unknown'
      }}{{ selectedVariant.recurrence!.cycles * selectedVariant.recurrence!.frequency == 1 ? '' : 's' }}
    </strong>

    <em *ngIf="selectedVariant.recurrence!.cycles > 1">
      (1 initial payment + {{ selectedVariant.recurrence!.cycles - 1 }} renewal{{
        selectedVariant.recurrence!.cycles - 1 > 1 ? 's every' : ' after'
      }}
      {{ selectedVariant.recurrence?.frequency }}
      {{
        selectedVariant.recurrence?.period === 'DAILY'
          ? 'day'
          : selectedVariant.recurrence?.period === 'WEEKLY'
          ? 'week'
          : selectedVariant.recurrence?.period === 'MONTHLY'
          ? 'month'
          : selectedVariant.recurrence?.period === 'YEARLY'
          ? 'year'
          : 'unknown'
      }}{{ selectedVariant.recurrence?.frequency == 1 ? '' : 's' }})
    </em>
  </small>

  <p *ngIf="selectedVariant.minimumCommitment?.length" style="color: var(--warn-color)">
    <small>
      <strong>
        Minimum Commitment:
        {{ selectedVariant.minimumCommitment?.length }}
        {{
          selectedVariant.minimumCommitment?.period === 'DAILY'
            ? 'day'
            : selectedVariant.minimumCommitment?.period === 'WEEKLY'
            ? 'week'
            : selectedVariant.minimumCommitment?.period === 'MONTHLY'
            ? 'month'
            : selectedVariant.minimumCommitment?.period === 'YEARLY'
            ? 'year'
            : 'unknown'
        }}{{ selectedVariant.minimumCommitment!.length == 1 ? '' : 's' }}
      </strong>
    </small>
  </p>

  <p *ngIf="variant && selectedVariant.autoUpgradeVariant">
    <br />
    Product will auto-upgrade to {{ variant.variantOpts[0].value || undefined }} ({{ variant.price / 100 | currency }} /
    {{ variant.recurrence || undefined | grecoRecurrence }}) at the end of subscription
  </p>
</ng-container>
<!-- Perks -->
<!-- <mat-divider *ngIf="selectedVariant?.variantPerks?.length" style="margin-bottom: 6px"></mat-divider> -->
<p *ngIf="selectedVariant?.variantPerks?.length">
  <i>Purchasing this item will give you the following perks:</i>
</p>
<div class="perks" *ngIf="selectedVariant">
  <div class="perk" *ngFor="let varPerk of (selectedVariant || product.variants[0])?.variantPerks">
    <greco-perk-badge
      style="flex: 1"
      [color]="varPerk.perk.badge.color"
      [shape]="$any(varPerk.perk.badge.shape)"
      [text]="varPerk.perk.badge.text"
      [quantity]="varPerk.quantity"
      [icon]="varPerk.perk.badge.icon"
      [small]="true"
      [matTooltip]="
        varPerk.perk.title +
        (varPerk.perk.description ? '\n - ' + varPerk.perk.description : '') +
        (varPerk.reusable ? '\n - This perk is reusable, it does not get consumed when used!' : '')
      "
    ></greco-perk-badge>
  </div>
</div>
