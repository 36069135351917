<greco-item *ngIf="user$ | async as user" [lines]="false">
  <mat-icon item-start>contact_emergency</mat-icon>

  <div style="width: 100%">
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">
      <strong>{{
        !user.address?.line1
          ? required
            ? 'Profile Completion Required'
            : 'Profile Completion May Be Required'
          : 'Profile Completed'
      }}</strong>
    </p>

    <p
      *ngIf="!user.address?.line1; else profileComplete"
      matTooltip="Please complete your profile prior to booking."
      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
    >
      <small *ngIf="required" style="color: var(--warn-color)">* Please complete your profile prior to booking.</small>
      <small *ngIf="!required" style="color: orange"
        >Your guest may be required to complete their profile prior to admittance.</small
      >
    </p>

    <ng-template #profileComplete>
      <p
        matTooltip="Please complete your profile prior to booking."
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
      >
        <small>Thank you for completing your profile.</small>
      </p>
    </ng-template>
  </div>

  <div item-end style="display: flex; align-items: center; justify-content: center">
    <button
      *ngIf="!user.address?.line1"
      mat-button
      color="accent"
      style="height: 32px; width: 100%; margin-left: auto; padding: 0 12px"
      (click)="emitBottomSheetOpened(profileCompletionSheet)"
    >
      <span>Complete</span>
    </button>
    <ng-template #profileCompletionSheet>
      <div style="display: flex; gap: 12px; justify-content: space-between; align-items: center">
        <h2 #header style="margin-bottom: 0">Complete Your Profile</h2>
        <button mat-icon-button (click)="bottomSheet.dismiss()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <greco-user-profile-details
        style="display: block; margin-top: 8px"
        [user]="user"
        [alwaysOpen]="true"
        [preventReload]="true"
        (saved)="saved()"
      ></greco-user-profile-details>
      <button class="scroll-to-top-button" mat-mini-fab (click)="header.scrollIntoView({ behavior: 'smooth' })">
        <mat-icon>vertical_align_top</mat-icon>
      </button>
    </ng-template>

    <mat-icon *ngIf="!!user.address?.line1" style="color: var(--success-color)">check_circle</mat-icon>
  </div>
</greco-item>
