import { SecurityResource } from '@greco/security';

export const EVENT_WAITLIST_SECURITY_RESOURCE = 'event-waitlist';
export enum EventWaitListSecurityResourceAction {
  DELETE = 'DELETE',
}

export const EventWaitlistSecurityResource: SecurityResource = {
  key: EVENT_WAITLIST_SECURITY_RESOURCE,
  module: 'booking',

  title: 'Event Waitlist',
  description: 'Manage event waitlists',

  context: class {
    id?: string;
    communityId?: string;
  },

  actions: [
    {
      key: EventWaitListSecurityResourceAction.DELETE,
      title: 'Delete user from waitlist',
      description: 'Abiliity to delete user from waitlist.',
    },
  ],
};
