<div *ngIf="!multiselect" style="display: flex; flex-direction: row">
  <input
    matInput
    type="text"
    autocomplete="off"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="placeholder"
    class="community-calendar-picker-input"
    (input)="searchQuery$.next($any($event).target.value)"
    (blur)="touched()"
    [matMenuTriggerFor]="communities"
  />

  <button class="trigger" #trigger="matMenuTrigger" [matMenuTriggerFor]="communities"></button>
  <button mat-icon-button style="margin: -12px" (click)="trigger.openMenu()" *ngIf="showSearchButton">
    <mat-icon>manage_search</mat-icon>
  </button>
</div>

<div *ngIf="multiselect" style="display: flex; flex-direction: row">
  <mat-chip-list #chipList style="white-space: nowrap; flex: 1">
    <!-- Chip list for calendars -->
    <mat-chip *ngFor="let calendar of selectedCalendars">
      {{ calendar.title }}
      <mat-icon
        style="font-size: 18px; margin: 7px -10px 0px 6px; color: gray"
        (click)="removeFromSelectedCalendars(calendar)"
      >
        cancel
      </mat-icon>
    </mat-chip>
    <input
      matInput
      type="text"
      autocomplete="off"
      [disabled]="disabled"
      [required]="required"
      [placeholder]="placeholder"
      class="community-calendar-picker-input"
      (input)="searchQuery$.next($any($event).target.value)"
      (blur)="touched()"
      [matMenuTriggerFor]="communities"
      [matChipInputFor]="chipList"
    />
  </mat-chip-list>

  <div style="display: flex; flex-direction: column; justify-content: flex-end">
    <button class="trigger" #trigger="matMenuTrigger" [matMenuTriggerFor]="communities"></button>
    <button mat-icon-button style="margin: -12px" (click)="trigger.openMenu()" *ngIf="showSearchButton">
      <mat-icon>manage_search</mat-icon>
    </button>
  </div>
</div>

<!-- Shows communities with filter from text input -->
<mat-menu #communities (close)="stateChanges.next()">
  <ng-container *grecoLet="justCalendars$ | async as justCalendars">
    <!-- Show community if no text has been entered to search for -->
    <!-- <ng-container *ngIf="!justCalendars"> -->
    <ng-container *grecoLet="filteredCalendars$ | async as calendars">
      <button *ngIf="!calendars || calendars.length === 0" mat-menu-item style="pointer-events: none">
        No calendars found
      </button>

      <p style="font-weight: bold; font-size: small; margin: 8px 16px" *ngIf="calendars && calendars.length">
        Communities
      </p>

      <ng-container *ngFor="let communityAndCalendars of calendars">
        <button mat-menu-item [mat-menu-trigger-for]="calendars" (click)="selectAllCalendars()">
          <span [innerHTML]="communityAndCalendars.name | safe : 'html'"></span>
        </button>

        <mat-menu #calendars>
          <p style="font-weight: bold; font-size: small; margin: 8px 16px">Calendars</p>
          <ng-container *ngFor="let calendar of communityAndCalendars.calendars">
            <button mat-menu-item (click)="select(calendar); multiselect ? $event.stopImmediatePropagation() : null">
              <mat-checkbox
                *ngIf="multiselect"
                style="margin-right: 4px"
                color="primary"
                [checked]="selectedCalendarIds.includes(calendar.id)"
                (click)="$event.preventDefault()"
              ></mat-checkbox>
              <mat-icon> {{ calendar.icon || 'calendar_today' }}</mat-icon>
              {{ calendar.title }}
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-menu>
