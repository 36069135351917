import {
  Product,
  PRODUCT_CONDITION_TYPE_ACTIVE_SUBSCRIPTION,
  SubscriptionProductCondition,
} from '@greco/sales-products';
import { DynamicSelectModel } from '@ng-dynamic-forms/core';
import { ProductConditionForm } from '../dialogs';
import { DynamicProductVariantPickerModel } from '../forms';

export const PRODUCT_CONDITION_CONFIG_ACTIVE_SUBSCRIPTION: ProductConditionForm = {
  label: 'Active Subscription (Product)',
  type: PRODUCT_CONDITION_TYPE_ACTIVE_SUBSCRIPTION,
  matIcon: 'autorenew',
  getConditionDetails({ subject, hasSubscription }: SubscriptionProductCondition) {
    return `Available if user ${hasSubscription ? 'has' : 'does not have'} active subscription of "${subject.title}"`;
  },
  getConditionShortDetails({ subject, hasSubscription }: SubscriptionProductCondition) {
    return `${hasSubscription ? 'Has' : 'No'} Active Subscription: ${subject.title}`;
  },
  getDtoFromFormValue({ subject, hasSubscription }: { subject: Product; hasSubscription: boolean }) {
    return {
      subjectId: subject.id,
      subject,
      hasSubscription,
    };
  },
  getFormModel(communityId: string) {
    return [
      new DynamicSelectModel({
        id: 'hasSubscription',
        label: 'Validation',
        required: true,
        options: [
          { label: 'Does Not Have Subscription', value: false },
          { label: 'Have Subscription', value: true },
        ],
        errorMessages: {
          required: 'Select a valid operator',
        },
        validators: {
          required: null,
        },
      }),
      new DynamicProductVariantPickerModel({
        id: 'subject',
        communityId: communityId,
        label: 'Subscription Product',
        required: true,
        onlyProducts: true,
        onlyRecurring: true,
        errorMessages: {
          required: 'Select a valid product',
        },
        validators: {
          required: null,
        },
        //options: [],
      }),
    ];
  },
};
