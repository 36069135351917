import {
  Component,
  EventEmitter,
  Inject,
  Injectable,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { heightExpansion } from '@greco/ui-animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const INITIAL_COLLAPSIBLE_SECTION_EXPANDED = new InjectionToken<number>('INITIAL_COLLAPSIBLE_SECTION_EXPANDED');

@Injectable()
export class CollapsibleSectionController implements OnDestroy {
  constructor(@Inject(INITIAL_COLLAPSIBLE_SECTION_EXPANDED) @Optional() initialExpandedSectionIndex?: number) {
    this._initialExpandedSectionIndex = initialExpandedSectionIndex || 0;
  }

  private _initialExpandedSectionIndex: number;

  private _sections: Map<string, CollapsibleSectionComponent> = new Map();
  private _onDestroy$ = new Subject<void>();

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  register(section: CollapsibleSectionComponent) {
    const id = '' + Math.random();
    section.expand(this._sections.size === this._initialExpandedSectionIndex);

    this._sections.set(id, section);
    section.changed.pipe(takeUntil(this._onDestroy$)).subscribe(expanded => {
      if (expanded) this._sections.forEach((sec, key) => key !== id && sec.expand(false));
    });
  }
}

@Component({
  selector: 'greco-collapsible-section',
  templateUrl: './collapsible-section.component.html',
  styleUrls: ['./collapsible-section.component.scss'],
  animations: [heightExpansion],
})
export class CollapsibleSectionComponent implements OnInit {
  constructor(@Optional() private controller?: CollapsibleSectionController) {}

  @Output() changed = new EventEmitter<boolean>();

  @Input() header?: {
    title: string;
    icon?: string;
    iconColor?: string;
    badge?: string;
    badgeColor?: string;
    date?: Date;
    small?: boolean;
    itemized?: boolean; //works in tandem with the small boolean shrink the icon and title stylings to be more in line with other "item" components, mainly the booking preview items
  };
  @Input() noBorder = false;
  @Input() innerSection = false;
  @Input() expanded = true;
  @Input() disabled = false;

  ngOnInit() {
    if (this.controller) this.controller.register(this);
  }

  expand(expanded = !this.expanded) {
    if (!this.disabled) {
      this.expanded = expanded;
      this.changed.emit(this.expanded);
    }
  }
}
