import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@greco/sales-products';

@Pipe({ name: 'variants' })
export class VariantsPipe implements PipeTransform {
  transform(product: Product): string {
    const activeProductVariant = product.variants.filter(vrt => vrt.status === 'ACTIVE');
    const prices = activeProductVariant.map(vrt => vrt.price);
    const price = { min: (Math.min(...prices) / 100).toFixed(2), max: (Math.max(...prices) / 100).toFixed(2) };
    const numVariants = activeProductVariant.length;

    if (price.min === price.max) return `${numVariants} variant${numVariants === 1 ? '' : 's'} at $${price.min}`;
    return numVariants ? `${numVariants} variants between $${price.min} and $${price.max}` : '-';
  }
}
