import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Promotion } from '@greco/promotions';
import { Observable, of } from 'rxjs';
import { PromotionsService } from '../../services';

@Injectable()
export class PromotionResolver implements Resolve<Promotion | null> {
  constructor(private promotionSvc: PromotionsService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<Promotion | null> | Promise<Promotion | null> {
    return route.params.promoId ? this.promotionSvc.getPromotion(route.params.promoId) : of(null);
  }
}
