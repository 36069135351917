<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div style="display: flex; align-items: flex-end">
      <!-- Variant Perk -->
      <mat-form-field>
        <mat-label>Perk</mat-label>
        <input matInput readonly value="{{data.variantPerk.perk.title}}" />
      </mat-form-field>
      <!-- Transferrable -->
      <mat-form-field style="flex: 0">
        <input matInput style="display: none" />
        <mat-checkbox
          matSuffix
          style="margin-left: 12px"
          formControlName="transferable"
          labelPosition="before"
          matTooltip="Checking this box, makes the perk transferrable from the purchasing user to another."
          >Transferable</mat-checkbox
        >
      </mat-form-field>
    </div>

    <!-- Quantity -->
    <mat-form-field>
      <mat-label>Quantity</mat-label>
      <input matInput required type="number" min="1" step="1" formControlName="quantity" />
      <mat-checkbox
        matSuffix
        style="margin-left: 12px"
        formControlName="reusable"
        labelPosition="before"
        matTooltip="Checking this box, makes it so the perks granted can be used an unlimited amount of times. The perks do not get consumed on use."
        >Unlimited Uses</mat-checkbox
      >
    </mat-form-field>

    <!-- Granted -->
    <mat-form-field>
      <mat-label>Granted</mat-label>
      <mat-select multiple formControlName="granted">
        <mat-option value="INITIAL">On Initial Purchase</mat-option>
        <mat-option value="RENEWAL">On Subscription Renewal</mat-option>
      </mat-select>
    </mat-form-field>

    <h3>Expiry Events (First to Occur)</h3>

    <!-- Expiry Delay -->
    <mat-form-field>
      <mat-label>Expiry Delay (Number of Days After Granted)</mat-label>
      <input matInput type="number" min="1" step="1" formControlName="expiryDelay" />
    </mat-form-field>

    <!-- Expiry Event -->
    <mat-form-field>
      <mat-label>Expiry Event (For Recurring Prices)</mat-label>
      <mat-select multiple formControlName="expiryEvent">
        <mat-option value="CANCELLATION">On Subscription Expiry</mat-option>
        <mat-option value="RENEWAL">On Subscription Renewal</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Freezable -->
    <mat-form-field>
      <mat-label>Freezable (For Recurring Prices)</mat-label>
      <mat-select required formControlName="freezable">
        <mat-option [value]="true">Unusable During Freeze</mat-option>
        <mat-option [value]="false">Usable During Freeze</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Buttons -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button mat-icon-button type="button" *ngIf="form.dirty && form.valid" (click)="reset()">
        <mat-icon>restart_alt</mat-icon>
      </button>

      <button mat-stroked-button type="submit" color="primary" [disabled]="form.invalid || !form.dirty" style="flex: 1">
        <span>Save</span>
        <mat-icon *ngIf="processing" style="margin-left: 8px" class="rotate">loop</mat-icon>
      </button>
    </div>
  </form>
</greco-simple-dialog>
