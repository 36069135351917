import { CurrencyPipe } from '@angular/common';
import { Component, DEFAULT_CURRENCY_CODE, Inject, Input, LOCALE_ID } from '@angular/core';
import { PropertyListener } from '@greco/property-listener-util';
import { Subscription } from '@greco/sales-subscriptions';
import { combineLatest, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'greco-fit-subscription-preview',
  templateUrl: './subscription-preview.component.html',
  styleUrls: ['./subscription-preview.component.scss'],
})
export class SubscriptionPreviewComponent {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD'
  ) {}

  @PropertyListener('subscription') private _subscription$ = new ReplaySubject<Subscription>(1);
  @Input() subscription!: Subscription;
  @Input() showSubInfo = true;
  @Input() communityId!: string;
  @PropertyListener('showTotals') private _showTotals$ = new ReplaySubject<boolean>(1);
  @Input() showTotals = false;

  private _currency = new CurrencyPipe(this._locale, this._defaultCurrencyCode);

  readonly items$ = combineLatest([this._subscription$, this._showTotals$]).pipe(
    filter(([subscription]) => !!subscription),
    map(([subscription, showTotals]) => [
      ...(subscription?.items || []).map(item => ({
        amount: this._currency.transform((item.quantity * item.price) / 100),
        unitPrice: this._currency.transform(item.price / 100),
        quantity: item.quantity,
        description: {
          displayName: item.displayName,
          description: item.description,
          photoURL: item.photoURL,
        },
      })),
      ...(showTotals
        ? [
            {
              description: null,
              quantity: null,
              unitPrice: '',
              amount: this._currency.transform(subscription.subtotal / 100),
              amountClass: 'subtotal',
            },
            {
              description: null,
              quantity: null,
              unitPrice: '',
              amount: subscription.tax ? this._currency.transform(subscription.tax / 100) : '-',
              amountClass: 'taxes',
            },
            {
              description: null,
              quantity: null,
              unitPrice: '',
              unitPriceClass: 'strong',
              amount: this._currency.transform(subscription.tax / 100),
              amountClass: 'total strong',
            },
          ]
        : []),
    ])
  );
}
