import { Tax } from '@greco/finance-tax';
import { User } from '@greco/identity-users';
import { AccountEntity } from '@greco/nestjs-finance-accounts';
import { PaymentMethodEntity } from '@greco/nestjs-finance-payments';
import { UserEntity } from '@greco/nestjs-identity-users-util';
import { SubscriptionItem } from './subscription-item';
import { SubscriptionRecurrence } from './subscription-recurrence';

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  VOIDED = 'VOIDED',
  FROZEN = 'FROZEN',
  COMPLETED = 'COMPLETED',
}

export interface Subscription {
  id: string;
  lockedForProcessing: boolean;

  created: Date;
  modified: Date;

  startDate?: Date | null;
  endDate?: Date | null;

  currentPeriod: number;
  periodStart?: Date | null;
  periodEnd?: Date | null;
  minimumCommitmentDate?: Date | null;

  recurrence: SubscriptionRecurrence;

  accountId: string;
  account?: AccountEntity;

  status: SubscriptionStatus;

  userId: string;
  user?: UserEntity;

  subscribedById: string;
  subscribedBy?: User;

  createdById: string | null;
  createdBy?: User | null;

  soldById: string | null;
  soldBy?: User | null;

  referredById?: string | null;
  referredByEmail?: string | null;
  referredBy?: User | null;

  transferredFromId?: string | null;
  transferredFrom?: User | null;

  taxes?: Tax[];
  items?: SubscriptionItem[];

  paymentMethodId?: string;
  paymentMethod?: PaymentMethodEntity;

  tax: number;
  subtotal: number;
  total: number;

  failureDate: Date | null;
  failureReason: string | null;

  type: string;

  paymentTypes?: string[];

  prorateStart?: Date;
}
