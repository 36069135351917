import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ResourceTag, ResourceType } from '@greco/booking-events';
import { UserService } from '@greco/ngx-identity-users';
import { ResourcesService } from '../../services';

@Component({
  selector: 'greco-person-resource-input',
  templateUrl: './person-resource-input.component.html',
  styleUrls: ['./person-resource-input.component.scss'],
})
export class PersonResourceInputComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private resourceSvc: ResourcesService,
    private formBuilder: FormBuilder,
    private userSvc: UserService,
    private snacks: MatSnackBar,
    private router: Router
  ) {}
  formGroup = this.formBuilder.group({
    //user: [null, Validators.required],
    contact: [null, Validators.required],
    name: ['', Validators.required],
    description: ['', [Validators.required]],
    resourceTags: [[]],
  });

  async create() {
    try {
      const resource = await this.resourceSvc.createPersonResource({
        description: this.formGroup.value.description,
        userId: this.formGroup.value.contact.user.id,
        communityId: this.data.communityId,
        name: this.formGroup.value.name,
        type: ResourceType.PERSON,
        resourceTagIds: (this.formGroup.value.resourceTags as ResourceTag[]).map(resourceTag => resourceTag.id),
      });

      this.snacks.open(
        'New ' + (resource.type === ResourceType.PERSON ? 'trainer' : 'zoom resource') + ' created!',
        'Ok',
        { duration: 3000, panelClass: 'mat-primary' }
      );

      this.router.navigate(['admin/scheduling', this.data.communityId, 'resources', resource.id]);
      return resource;
    } catch (err: any) {
      console.log(err);
      this.snacks.open(err?.error?.message, 'Ok', { duration: 10000, panelClass: 'mat-warn' });
      return null;
    }
  }
}
