import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from '@greco/ngx-clipboard-util';
import { AccountsModule } from '@greco/ngx-finance-accounts';
import { GrecoFinanceFeatureTaxModule } from '@greco/ngx-finance-tax';
import { CommunitySecurityServiceModule } from '@greco/ngx-identity-community-staff-util';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { LetModule } from '@greco/ngx-let-util';
import { UrlEncodePipeModule } from '@greco/ngx-url-encode-pipe-util';
import { AddressInputModule } from '@greco/ui-address-input';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { EditorModule } from '@greco/ui-editor';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { TableModule } from '@greco/ui-table';
import { NgxMaskModule } from 'ngx-mask';
import { SafePipeModule } from 'safe-pipe';
import { SwiperModule } from 'swiper/angular';
import { CommunitiesTableComponent, SubscribedCommunitiesComponent, UserCommunityPickerComponent } from './components';
import { CommunityCardComponent } from './components/community-card/community-card.component';
import { UserBrowseCommunitiesComponent } from './components/user-browse-communities/user-browse-communities.component';
import { BrowseCommunitiesDialog, CreateCommunityDialog } from './dialogs';
import { JoinCommunityDialog } from './dialogs/join-community/join-community.dialog';
import { CommunityFinanceSettingsPage, CommunityPage, SubCommunityPage } from './pages';
import { CommunityServiceModule } from './services';

export * from './dialogs/join-community/join-community.dialog';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    // Material
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatTooltipModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatMenuModule,

    // Greco
    BoxModule,
    AccountsModule,
    GrecoFinanceFeatureTaxModule,
    ContactsModule,
    ClipboardModule,
    SimpleDialogModule,
    AddressInputModule,
    FormSaveButtonsModule,
    CommunityServiceModule,
    CollapsibleSectionModule,
    CommunitySecurityServiceModule,
    TableModule,
    LetModule,

    // External
    SwiperModule,
    EditorModule,
    SafePipeModule,
    UrlEncodePipeModule,
    NgxMaskModule.forChild(),
  ],
  declarations: [
    // Components
    CommunityCardComponent,
    CommunitiesTableComponent,
    UserCommunityPickerComponent,
    UserBrowseCommunitiesComponent,
    SubscribedCommunitiesComponent,

    // Dialogs
    JoinCommunityDialog,
    CreateCommunityDialog,
    BrowseCommunitiesDialog,

    // Pages
    CommunityPage,
    SubCommunityPage,
    CommunityFinanceSettingsPage,
  ],
  exports: [
    // Components
    CommunityCardComponent,
    CommunitiesTableComponent,
    UserCommunityPickerComponent,
    UserBrowseCommunitiesComponent,
    SubscribedCommunitiesComponent,

    // Dialogs
    JoinCommunityDialog,
    CreateCommunityDialog,
    BrowseCommunitiesDialog,

    // Pages
    CommunityPage,
    SubCommunityPage,
    CommunityFinanceSettingsPage,
  ],
  providers: [],
})
export class CommunitiesModule {}
