import { Injectable } from '@angular/core';
import { UserService } from '@greco/ngx-identity-auth';
import { VideoUnlockService } from '@greco/ngx-videos';
import { shareReplay, startWith, switchMap } from 'rxjs/operators';

@Injectable()
export class DashboardVideosService {
  videoUnlocks$ = this.userSvc.authUser$.pipe(
    switchMap(() => this.videoUnlockSvc.getUserUnlockedVideosLimit(3)),
    shareReplay(1),
    startWith(null)
  );

  constructor(private userSvc: UserService, private videoUnlockSvc: VideoUnlockService) {}
}
