import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CurrencyMaskConfig } from 'ngx-currency';

@Component({
  selector: 'greco-add-community-custom-tax',
  templateUrl: './add-community-custom-tax.component.html',
  styleUrls: ['./add-community-custom-tax.component.scss'],
})
export class AddCommunityCustomTaxComponent {
  constructor(private formBuilder: FormBuilder) {}
  touched = false;
  disabled = false;

  private _accountId!: string;
  @Input() get accountId() {
    return this._accountId;
  }
  set accountId(accountId: string) {
    this._accountId = accountId;
    this._form.setValue({ financeAccountId: accountId });
  }
  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '',
    suffix: '%',
    thousands: ',',
    inputMode: 0,
    min: 0,
    max: 100,
  };

  _form = this.formBuilder.group({
    financeAccountId: [''],
    title: ['', Validators.required],
    abbreviation: ['', Validators.required],
    description: [''],
    taxNumber: ['', Validators.required],
    percentage: ['', Validators.required],
    isDefault: [false],
  });
}
