import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BookingOptionDetails, EventAccount, EventWithUserDetails } from '@greco/booking-events2';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { AlertConfig, AlertType } from '@greco/ui-alert';
import moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { EventService } from '../../../services';

const bookingOptionQuantityErrorTitle = 'Insufficient Quantity';

@Component({
  selector: 'alt-booking-option-picker2',
  templateUrl: './booking-option-picker.component.html',
  styleUrls: ['./booking-option-picker.component.scss'],
})
export class BookingOptionPicker2Component {
  constructor(
    private eventSvc: EventService,
    public bottomSheet: MatBottomSheet,
    private breakpoints: BreakpointObserver
  ) {}

  @ViewChild('bottomSheetContent', { static: false }) bottomSheetContent!: TemplateRef<void>;

  @PropertyListener('user') private user$ = new BehaviorSubject<User | null>(null);
  @Input() user?: User;

  @PropertyListener('event') private event$ = new BehaviorSubject<EventWithUserDetails | null>(null);
  @Input() event?: EventWithUserDetails;

  @PropertyListener('eventAccounts') private eventAccounts$ = new BehaviorSubject<EventAccount[]>([]);
  @Input() eventAccounts: EventAccount[] = [];

  @PropertyListener('bookingOption') bookingOption$ = new BehaviorSubject<BookingOptionDetails | null>(null);
  @Input() bookingOption?: BookingOptionDetails;

  @PropertyListener('isStaffView') isStaffView$ = new BehaviorSubject<boolean>(true);
  @Input() isStaffView = true;

  @PropertyListener('boostersActivated') boostersActivated$ = new BehaviorSubject<boolean>(false);
  @Input() boostersActivated = false;

  expanded = false;
  mobile = false;
  areBoostersRequired = false;

  isMobile$ = this.breakpoints.observe('(max-width: 600px)').pipe(
    map(({ matches }) => matches),
    tap(matches => (this.mobile = matches)),
    shareReplay(1)
  );

  availableBookingOptions$ = combineLatest([
    this.user$,
    this.event$,
    this.eventAccounts$,
    this.bookingOption$,
    this.isStaffView$,
  ]).pipe(
    map(([user, event, eventAccounts, bookingOption, isStaffView]) => {
      if (!event) return null;

      const userAccount = eventAccounts.find(account => account.user.id === user?.id);

      let parentOptions: BookingOptionDetails[] = [];
      let userOptions: BookingOptionDetails[] = userAccount?.canUsePerks ? userAccount?.bookingOptions || [] : [];
      let pendingOptions = isStaffView && userAccount?.canUsePerks ? userAccount?.pendingBookingOptions || [] : [];

      const parent = eventAccounts[0];
      if (parent?.user.id !== user?.id) {
        parentOptions = parent.bookingOptions.filter(option => option.transferable || option.transferableReusable);
        pendingOptions = isStaffView ? [...pendingOptions, ...parent.pendingBookingOptions] : [];
      }

      if (bookingOption) {
        bookingOption.userId === user?.id
          ? (userOptions = userOptions.filter(
              option => !(option.id === bookingOption.id && option.userId === bookingOption.userId)
            ))
          : (parentOptions = parentOptions.filter(
              option => !(option.id === bookingOption.id && option.userId === bookingOption.userId)
            ));
      }

      return {
        complimentaryOption: event.complimentaryBookingOption,
        userOptions,
        parentOptions,
        pendingOptions,
        count: userOptions.length + parentOptions.length + pendingOptions.length,
      };
    })
  );

  alertConfig$ = combineLatest([
    this.event$,
    this.user$,
    this.bookingOption$,
    this.boostersActivated$,
    this.eventSvc.bookingOptionErrors$,
    this.eventSvc.bookingsBoosterInfo$,
  ]).pipe(
    map(([event, user, bookingOption, boostersActivated, bookingOptionErrors, boosterInfos]) => {
      if (!event || !user || !bookingOption) return null;

      let alert: AlertConfig | null = null;

      this.areBoostersRequired = false;

      const now = moment();
      const cutoff = moment(event.startTime).subtract(bookingOption.bookingWindow, 'minutes');
      const requiredBoosts = now.isBefore(cutoff) ? Math.ceil(cutoff.diff(now, 'days', true)) : 0;

      const bookingStart = moment(event.startTime).subtract(bookingOption.bookingWindow, 'minutes');
      const bookingStartWithBoost = moment(bookingStart).subtract(
        bookingOption.maxBoost === -1 ? 0 : bookingOption.maxBoost,
        'days'
      );

      if (bookingOption.id === 'prk_complimentarybooking') {
        alert = null;
      } else if (bookingOption.isPending) {
        alert = {
          title: 'This Booking will be Pending',
          type: AlertType.WARN,
          description:
            'This perk is currently unavailable to the user. Continuing with this option will result in the booking being pending.',
        };
      } else if (bookingStartWithBoost.isAfter(now) && bookingOption.id) {
        alert = {
          title: 'Outside of Booking Window',
          type: AlertType.DANGER,
          description:
            'Booking for this event is not available yet. For the ' +
            bookingOption.title +
            ' perk, booking will be available from ' +
            cutoff.format('dddd, MMMM Do, @h:mm a') +
            '.',
        };
      } else if (bookingOptionErrors.conflicts.includes(user.id) && bookingOption.id) {
        alert = {
          title: bookingOptionQuantityErrorTitle,
          type: AlertType.DANGER,
          description: 'This perk has been selected for more bookings than are available. Please review your bookings.',
        };
      } else if (bookingOptionErrors.transferableConflicts.includes(user.id) && bookingOption.id) {
        alert = {
          title: bookingOptionQuantityErrorTitle,
          type: AlertType.DANGER,
          description:
            'This transferable perk has been selected for more bookings than are available. Please review your bookings.',
        };
      } else if (bookingStart.isAfter(now) && bookingOption.id) {
        const boosterInfo = boosterInfos.find(boosterInfo => boosterInfo.userId === user.id);
        const boostersToConsume = boosterInfo?.boostersToConsume || 0;
        const boostersToPurchase = boosterInfo?.boostersToPurchase || 0;
        const ownedOrTransferable = boosterInfo?.isOwned ? ' Owned' : ' Transferable';
        const isReusable = boosterInfo?.isReusable ?? false;

        this.areBoostersRequired = boosterInfo?.boostersRequired ?? false;

        if (boosterInfo?.boostersRequired && boostersActivated) {
          alert = {
            title:
              requiredBoosts +
              ' Booster' +
              (requiredBoosts > 1 ? 's' : '') +
              ' (' +
              (!isReusable && boostersToConsume ? boostersToConsume + ownedOrTransferable : '') +
              (isReusable ? 'Reusable' : '') +
              (!isReusable && boostersToConsume && boostersToPurchase ? ' + ' : '') +
              (boostersToPurchase ? boostersToPurchase + ' to Purchase' : '') +
              ') Being Used',
            type: AlertType.INFO,
          };
        } else {
          alert = null;
        }
      }

      return alert;
    }),
    tap(alert => {
      if (!this.user || alert?.type !== AlertType.DANGER || alert.title === bookingOptionQuantityErrorTitle) {
        return;
      }

      const eventAlerts = this.eventSvc.alerts$.value;
      const userAlerts = eventAlerts.find(alert => alert.userId === this.user?.id);

      if (!userAlerts && alert) {
        this.eventSvc.alerts$.next([
          ...eventAlerts,
          {
            userId: this.user.id,
            outsideBookingWindow: true,
          },
        ]);
      } else if (userAlerts && alert) {
        const otherUserAlerts = eventAlerts.filter(alert => alert.userId !== this.user?.id);
        this.eventSvc.alerts$.next([
          ...otherUserAlerts,
          {
            ...userAlerts,
            outsideBookingWindow: true,
          },
        ]);
      } else if (!userAlerts && !alert) {
        this.eventSvc.alerts$.next([
          ...eventAlerts,
          {
            userId: this.user.id,
            outsideBookingWindow: false,
          },
        ]);
      } else if (userAlerts && !alert) {
        const otherUserAlerts = eventAlerts.filter(alert => alert.userId !== this.user?.id);
        this.eventSvc.alerts$.next([
          ...otherUserAlerts,
          {
            ...userAlerts,
            outsideBookingWindow: false,
          },
        ]);
      }
    })
  );

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  open() {
    this.bottomSheet.open(this.bottomSheetContent, { panelClass: 'bottom-sheet' });
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }

  selectOption(option: BookingOptionDetails) {
    if (!this.user || !this.event) return;

    const bookings = this.eventSvc.bookings$.value;
    const userBooking = bookings.find(booking => booking.userId === this.user?.id);
    if (!userBooking) return;

    userBooking.bookingOptionId = option.id;
    userBooking.bookingOptionUserId = option.userId;

    const bookingIndex = bookings.findIndex(booking => booking.userId === this.user?.id);

    bookings[bookingIndex] = userBooking;
    this.eventSvc.bookings$.next(bookings);

    this.bottomSheet.dismiss();
  }

  applyBoosters() {
    if (!this.user || !this.event) return;

    const bookings = this.eventSvc.bookings$.value;
    const userBooking = bookings.find(booking => booking.userId === this.user?.id);
    if (!userBooking) return;

    userBooking.boostersActivated = true;

    const bookingIndex = bookings.findIndex(booking => booking.userId === this.user?.id);

    bookings[bookingIndex] = userBooking;
    this.eventSvc.bookings$.next(bookings);
  }
}
