import { Component, Input } from '@angular/core';
import { PropertyListener } from '@greco/property-listener-util';
import { Subscription } from '@greco/sales-subscriptions';
import { ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubscriptionActionsService, SubscriptionsService } from '../../services';

@Component({
  selector: 'greco-fit-subscription-history',
  templateUrl: './subscription-history.component.html',
  styleUrls: ['./subscription-history.component.scss'],
})
export class SubscriptionHistoryComponent {
  constructor(private subscriptionSvc: SubscriptionsService, private actionSvc: SubscriptionActionsService) {}

  @Input() subscription?: Subscription;
  @PropertyListener('subscription') private _subscription$ = new ReplaySubject<Subscription>(1);

  subAction$ = this._subscription$.pipe(
    switchMap(async sub => {
      if (!sub) return [];
      return await this.actionSvc.getAllActions(sub.id);
    })
  );
}
