import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import type { Account, AccountPaymentGateway } from '@greco/finance-accounts';
import type { StripePaymentGatewayEntity } from '@greco/nestjs-stripe-payment-gateway';

export const CLIENT_ID_TOKEN = new InjectionToken<string>('CLIENT_ID_TOKEN');

@Injectable()
export class StripeConnectService {
  constructor(@Inject(CLIENT_ID_TOKEN) private clientId: string, private http: HttpClient) {}

  // @Post(':accountId/fee')
  async setAccountFeePercentage(accountId: string, feePercentage: number) {
    return await toPromise(
      this.http.post<StripePaymentGatewayEntity>(`/api/stripe/${accountId}/fee`, { feePercentage })
    );
  }

  getCompleteAccountLink(account: Account, redirectUrl: string) {
    return toPromise(
      this.http.get<{ url: string }>(`/api/stripe/${account.id}/complete-link`, { params: { redirectUrl } })
    );
  }

  needsMoreInfo(account: Account) {
    return toPromise(this.http.get<boolean>(`/api/stripe/${account.id}/needs-more-info`));
  }

  getAuthorizationLink(account: Account, redirectUri: string): string {
    return `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=${this.clientId}&redirect_uri=${redirectUri}&state=${account.id}`;
  }

  /**
   * @param authorizationCode An authorization code obtained by going through the OAuth flow
   * @param accountId Should be the account id set in the state query param when going through the OAuth flow
   */
  async completeAccountAuthorization(authorizationCode: string, accountId: string): Promise<AccountPaymentGateway> {
    return toPromise(
      this.http.post<AccountPaymentGateway>('/api/stripe/authorize-account', { authorizationCode, accountId })
    );
  }
}
