import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CountryInputModule } from '@greco/ui-country-input';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AddressInputComponent } from './components/address-input/address-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    GooglePlaceModule,
    MatInputModule,
    MatButtonModule,
    CountryInputModule.forRoot('en'),
  ],
  exports: [AddressInputComponent],
  declarations: [AddressInputComponent],
})
export class AddressInputModule {}
