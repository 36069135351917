import { SecurityResource } from '@greco/security';

export enum TerminalResourceAction {
  USE_TERMINALS = 'USE_TERMINALS',
  MANAGE_TERMINALS = 'MANAGE_TERMINALS',
}

export const TerminalResource: SecurityResource = {
  key: 'finance_terminals',
  module: 'Finance',

  title: 'Terminals',
  description: 'Manage Terminals (readers)',

  context: class {
    accountId?: string;
    communityId?: string;
  },

  actions: [
    {
      key: TerminalResourceAction.USE_TERMINALS,
      title: 'Use terminals',
      description: 'Ability to use terminals (readers) as payment methods for one-time purchases',
    },
    {
      key: TerminalResourceAction.MANAGE_TERMINALS,
      title: 'Manage terminals',
      description: 'Ability to manage and rename terminals (readers)',
    },
  ],
};
