import { Timestamp } from '@greco-fit/firebase';
import * as moment from 'moment';
import { ArrayProperty, FormattableProperty, ObjectProperty, ReferenceProperty } from './definition';

type ObjectPropertyInput = Partial<Omit<ObjectProperty, 'type' | 'key'>> & Pick<ReferenceProperty, 'key'>;
type FormattablePropertyInput = Partial<Omit<FormattableProperty, 'type' | 'key' | 'formats' | 'properties'>> & Pick<FormattableProperty, 'key' | 'formats'>;
type ArrayPropertyInput = Partial<Omit<ArrayProperty, 'type' | 'key' | 'arrayType'>> & Pick<ArrayProperty, 'key' | 'arrayType'>;
type ReferencePropertyInput = Partial<Omit<ReferenceProperty, 'type' | 'key' | 'expand'>> & Pick<ReferenceProperty, 'key' | 'get'>;

// tslint:disable-next-line: variable-name
export const Properties = {
  Array: (input: ArrayPropertyInput): ArrayProperty => ({
    ...input,
    type: 'array',
    name: input.name || input.key
  }),

  Boolean: (input: Omit<ObjectPropertyInput, 'properties'>): ObjectProperty => ({
    ...input,
    type: 'boolean',
    name: input.name || input.key
  }),

  Currency: (input: Omit<FormattablePropertyInput, 'formats'>, amount = Math.random() * 1000000): FormattableProperty => {
    const compact = (value: number) =>
      new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD', currencyDisplay: 'symbol', notation: 'compact' } as any).format(value / 100);
    const fixed = (value: number) => new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD', currencyDisplay: 'symbol' }).format(value / 100);
    const rounded = (value: number) =>
      new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD', currencyDisplay: 'symbol' }).format(Math.round(value / 100)).replace(/\.\d+$/, '');
    return {
      ...input,
      type: 'formattable',
      name: input.name || input.key,
      formats: [
        { type: 'string', key: 'default', name: '' + amount, formatter: value => value },
        { type: 'string', key: 'compact', name: compact(amount), formatter: compact },
        { type: 'string', key: 'rounded', name: rounded(amount), formatter: rounded },
        { type: 'string', key: 'fixed', name: fixed(amount), formatter: fixed }
      ]
    };
  },

  Email: (input?: Partial<Omit<ObjectPropertyInput, 'properties'>>): ObjectProperty => ({
    ...input,
    type: 'string',
    key: input?.key || 'email',
    name: input?.name || 'Email Address'
  }),

  Id: (input?: Partial<Omit<ObjectPropertyInput, 'properties'>>): ObjectProperty => ({
    ...input,
    type: 'id',
    key: input?.key || 'id',
    name: input?.name || 'Id'
  }),

  Number: (input: Omit<ObjectPropertyInput, 'properties'>): ObjectProperty => ({
    ...input,
    type: 'number',
    name: input.name || input.key
  }),

  Object: (input: ObjectPropertyInput): ObjectProperty => ({
    ...input,
    type: 'object',
    name: input.name || input.name
  }),

  Reference: (input: Omit<ReferencePropertyInput, 'properties'>): ReferenceProperty => ({
    ...input,
    type: 'reference',
    name: input?.name || input.key
  }),

  String: (input: Omit<ObjectPropertyInput, 'properties'>): ObjectProperty => ({
    ...input,
    type: 'string',
    name: input?.name || input.key
  }),

  Timestamp: (input: Omit<FormattablePropertyInput, 'formats'>, date = moment()): FormattableProperty => ({
    ...input,
    type: 'formattable',
    name: input?.name || input.key,
    formats: ['LT', 'LTS', 'L', 'l', 'LL', 'll', 'LLL', 'lll', 'LLLL', 'llll'].map(key => ({
      key,
      type: 'string',
      name: date.format(key),
      formatter: (timestamp: Timestamp) => moment(timestamp.toDate()).format(key)
    }))
  })
};
