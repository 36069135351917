/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { AccountLink, AccountLinkStatus } from '@greco/account-linking';
import { Booking, CalendarEvent, getCancellationPolicyInnerHtml } from '@greco/booking-events';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { BookingConfirmationDto, BookingPreview } from '@greco/nestjs-booking-events';
import { SignatureService } from '@greco/ngx-identity-users';
import { PerkService } from '@greco/ngx-sales-perks';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { AccountLinkingService } from '@greco/web-account-linking';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ConfirmAddAttendeeDialog, UserAddGuestDialog } from '../../../dialogs';
import { BookingService } from '../../../services';
import { PreviewBooking2Page } from '../preview-booking-2/preview-booking-2.page';

interface UserBookingPreview {
  user: User;
  preview?: BookingPreview;
  signatures: { agreementId: string; signature: string }[];
  requirementValues$: BehaviorSubject<{ [id: string]: string }>;
  bookingOptionError?: boolean;
}
interface Data {
  bookedBy: User | undefined;
  linkedAccounts: {
    link: AccountLink;
    selected: boolean;
    booked: boolean;
  }[];
  isMobile: boolean;
  hasBookingOptionError: {
    error: boolean;
    indexes: number[];
  };
  hasSpotError: {
    error: boolean;
    indexes: number[];
  };
  remainingCapacity: number;
}

@Component({
  selector: 'greco-multi-booking-preview',
  templateUrl: './multi-booking-preview.component.html',
  styleUrls: ['./multi-booking-preview.component.scss'],
})
export class MultiBookingPreviewComponent implements OnInit {
  now = new Date();
  openDescription = false;

  constructor(
    private linkSvc: AccountLinkingService,
    private perkSvc: PerkService,
    private breakpoints: BreakpointObserver,
    private matDialog: MatDialog,
    private snacks: MatSnackBar,
    private bookingSvc: BookingService,
    private signatureSvc: SignatureService,
    private router: Router
  ) {}

  @PropertyListener('previews') previews$ = new BehaviorSubject<UserBookingPreview[]>([]);
  previews: UserBookingPreview[] = [];

  _isMobile$ = this.breakpoints.observe('(max-width: 1000px)').pipe(
    map(({ matches }) => matches),
    shareReplay(1)
  );

  private spots = new Map<string, string | null>();
  userBookingOptionMap: { userId: string; optionId: string }[] = [];

  @Output() multiPreviewLoading = new EventEmitter<boolean>(false);

  sameSpotError = false;
  _confirming = false;
  loading = false;
  bookingOptionError = false;

  @ViewChildren(PreviewBooking2Page) private previewPages?: QueryList<PreviewBooking2Page>;

  @Input() forceMobile = false;
  @Input() booking?: Booking;

  @Input() isAddAttendee?: boolean = false;

  @Output() booked = new EventEmitter<Booking | Booking[]>(true);
  @Input() showEventTitleBar = true;

  @Input() extraEventIds: string[] = [];

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;

  parent$ = this.user$.pipe(
    switchMap(async user => {
      if (!user) return undefined;
      if (!user.email) {
        const links = (await this.linkSvc.getGivenLinksByAccount(user.id))?.filter(
          link => link.status === AccountLinkStatus.ACTIVE
        );
        if (links?.length) return links[0]?.accessor;
      }
      return user;
    })
  );

  @PropertyListener('event') event$ = new BehaviorSubject<CalendarEvent | null>(null);
  @Input() event!: CalendarEvent;

  @Input() footerInPage = false;

  @Input() allowPendingBookings = false;
  @Input() preventRedirect = false;

  @PropertyListener('selectedPaymentMethod') selectedPaymentMethod$ = new BehaviorSubject<PaymentMethod | null>(null);
  selectedPaymentMethod: PaymentMethod | null = null;

  userBookingOptions$ = combineLatest([this.parent$]).pipe(
    switchMap(async ([user]) => {
      return user ? await this.perkSvc.availablePerks(user.id) : [];
    })
  );

  eventBookings$ = this.event$.pipe(
    switchMap(async event => {
      if (!event) return [];
      return await this.bookingSvc.getByEvent(event.id);
    })
  );

  linkedAccounts$ = combineLatest([this.parent$, this.previews$, this.eventBookings$]).pipe(
    tap(async () => await new Promise(res => setTimeout(res, 500))),
    switchMap(async ([user, previews, eventBookings]) => {
      const links: AccountLink[] = [
        { account: user, status: AccountLinkStatus.ACTIVE } as AccountLink,
        ...(user
          ? (await this.linkSvc.getPrivilegeLinksForAccessor(user.id))?.filter(
              link => link.status === AccountLinkStatus.ACTIVE
            )
          : []),
      ];
      const linksAndStatus: { link: AccountLink; selected: boolean; booked: boolean }[] = [];
      links.forEach(link => {
        linksAndStatus.push({
          link,
          selected: previews.some(preview => preview.user.id === link.account?.id),
          booked: eventBookings.some(eBooking => eBooking.user.id === link.account?.id),
        });
      });
      return linksAndStatus;
    })
  );
  isValid$ = this.previews$.pipe(
    map(previews => {
      for (const preview of previews) {
        if (!preview.preview) return false;
        if (preview.preview.errors.length) return false;
      }
      return true;
    })
  );

  remainingCapacity$ = combineLatest([this.event$, this.previews$]).pipe(
    switchMap(async ([event]) => {
      if (!event) return 0;
      const maxCapacity = event.maxCapacity;
      const eventBookings = await this.bookingSvc.getByEvent(event.id);
      const bookingCount = eventBookings?.length || 0;
      return maxCapacity - bookingCount;
    })
  );

  hasBookingOptionError$ = this.previews$.pipe(
    map(previews => {
      const indexes: number[] = [];
      for (let i = 0; i < previews.length; i++) {
        const preview = previews[i];
        if (preview.bookingOptionError) {
          indexes.push(i);
        }
      }
      if (indexes.length) return { error: true, indexes };
      return { error: false, indexes };
    })
  );

  hasSpotError$ = combineLatest([this.event$, this.previews$]).pipe(
    map(([event, previews]) => {
      const indexes: number[] = [];
      const spotIds: { id: string; index: number }[] = [];

      if (!event) return { error: false, indexes };
      if (!previews || !previews.length) return { error: false, indexes };

      for (let i = 0; i < previews.length; i++) {
        const preview = previews[i];
        const spotId = preview.preview?.dto.spotId;
        if (spotId) {
          spotIds.push({ id: spotId, index: i });
        }
      }

      for (const spotId of spotIds) {
        const id = spotId.id;
        const index = spotId.index;
        const conflictedIndexes = spotIds
          .filter(item => item.id !== 'general' && item.id === id && item.index !== index)
          .map(item => item.index);

        if (conflictedIndexes.length) {
          return { error: true, indexes: conflictedIndexes };
        }
      }

      return { error: false, indexes };
    })
  );

  data$: Observable<Data> = combineLatest([
    this.parent$,
    this.linkedAccounts$,
    this._isMobile$,
    this.hasBookingOptionError$,
    this.hasSpotError$,
    this.remainingCapacity$,
  ]).pipe(
    map(([bookedBy, linkedAccounts, isMobile, hasBookingOptionError, hasSpotError, remainingCapacity]) => {
      return {
        bookedBy,
        linkedAccounts,
        isMobile,
        hasBookingOptionError,
        hasSpotError,
        remainingCapacity,
      };
    })
  );

  async selected(selectedUser: User, removeUser?: User) {
    const userInPreviews = this.previews.some(preview => preview.user.id === selectedUser.id);

    if (userInPreviews) {
      this.snacks.open('This user has already been added to bookings.', '', {
        duration: 6000,
        panelClass: 'mat-warn',
      });
    }
    const eventClash = await this.hasClashError(selectedUser);

    if (!eventClash) {
      this.previews = [
        ...this.previews.filter(p => (removeUser ? p.user.id !== removeUser.id : true)),
        { user: selectedUser, signatures: [], requirementValues$: new BehaviorSubject<{ [id: string]: string }>({}) },
      ];
    }
    if (removeUser) this.remove(removeUser);
  }
  spotSelect(id: string | null, userId: string) {
    this.spots.set(userId, id);
  }

  async cancelBooking() {
    if (this.booking) {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          showCloseButton: false,
          title: 'Cancel Booking',
          subtitle: 'Are you sure you want to cancel this booking?',
          content: `
              <p><strong>The following cancellation policy applies</strong>:</p>
              ${getCancellationPolicyInnerHtml(this.booking.bookingOption)}
            `,
          buttons: [
            { label: 'No, Keep the Booking', role: 'no' },
            { label: 'Yes, Cancel the Booking', role: 'yes' },
          ],
        },
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        await this.bookingSvc.cancel(this.booking.id, false);
        this.snacks.open('Booking Cancelled', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
        location.reload();
      }
    }
  }

  getDisabledSpots(userId: string) {
    const userSpotId = this.spots.get(userId);
    const values = [...this.spots.values()];
    return values.filter(spotId => spotId !== null && spotId !== userSpotId) as string[];
  }

  async bookingOptionChanged(bookingOptionId: string | undefined) {
    //map users to bookingOptions
    //if user doesn't exist add user + bookingOptionId; else update the new bookingOptionId
    if (this.previewPages) {
      for (const preview of this.previewPages) {
        if (!this.userBookingOptionMap.some(item => item.userId == preview.user!.id)) {
          this.userBookingOptionMap.push({ userId: preview.user!.id, optionId: preview.bookingOptionId });
        } else {
          const index = this.userBookingOptionMap.findIndex(x => x.userId === preview.user!.id);
          this.userBookingOptionMap[index].optionId = preview.bookingOptionId;
        }
      }
    }

    const optionsCount: { optionId: string; usedCount: number; availableCount: number; reusable: boolean | null }[] =
      [];

    //count all the options
    for (const optionMap of this.userBookingOptionMap) {
      if (!optionsCount.some(o => o.optionId === optionMap.optionId))
        optionsCount.push({ optionId: optionMap.optionId, usedCount: 1, availableCount: 0, reusable: null });
      else if (optionsCount.some(o => o.optionId === optionMap.optionId)) {
        const index = optionsCount.findIndex(x => x.optionId === optionMap.optionId);
        optionsCount[index].usedCount = optionsCount[index].usedCount + 1;
      }
    }

    const perkIds = optionsCount.map(item => item.optionId);

    if (bookingOptionId) {
      //for each mapif they are consumable or reusable
      for (const perkId of perkIds) {
        const userPerks = await toPromise(this.userBookingOptions$);

        let availableCount = 0;
        let reusable = null;

        const index = optionsCount.findIndex(x => x.optionId === perkId);

        for (const userperk of userPerks.filter(userPerk => userPerk.id === perkId)) {
          if (userperk.consumable) {
            availableCount += Number(userperk.count);
            reusable = false;
          }
          if (userperk.consumable === 0) {
            reusable = true;
          }
        }
        optionsCount[index].reusable = reusable;
        optionsCount[index].availableCount = availableCount;

        //get all used > available, only if they are (reusable:false === consumable)
        const moreUsedThanAvaialble = optionsCount.filter(x => x.usedCount > x.availableCount && x.reusable === false);

        //set error if more are used than available
        if (moreUsedThanAvaialble && moreUsedThanAvaialble.length > 0) {
          if (this.previewPages) {
            for (const preview of this.previewPages) {
              if (preview?.bookingOptionId === perkId) {
                preview.hasMultiBookingOptionError$.next(true);
              } else if (preview?.bookingOptionId !== perkId) {
                preview.hasMultiBookingOptionError$.next(false);
              }
            }
          }
        } else {
          if (this.previewPages)
            for (const preview of this.previewPages) {
              {
                preview.hasMultiBookingOptionError$.next(false);
              }
            }
        }
      }
    }
  }

  async hasClashError(user: User) {
    const userBookedEvents = (await this.bookingSvc.getByUser(user.id))
      .filter(booking => booking.user.id === user.id)
      .map(bkg => bkg.event);
    let clashError = false;

    for (let i = 0; i < userBookedEvents.length; i++) {
      const currentEventStartDate = new Date(this.event.startDate).getTime();
      const bookedEventStartDate = new Date(userBookedEvents[i].startDate).getTime();
      const bookedEventEndDate = new Date(userBookedEvents[i].endDate).getTime();

      if (bookedEventStartDate <= currentEventStartDate && currentEventStartDate <= bookedEventEndDate) {
        clashError = true;
        this.snacks.open(
          `Events Clash! ${user.displayName} is already attending another event at the same time.`,
          'Ok',
          {
            duration: 10000,
            panelClass: 'mat-warn',
          }
        );
      }
    }
    return clashError;
  }

  remove(removedUser: User) {
    this.previews = [...this.previews.filter(preview => preview.user.id !== removedUser.id)];
    const index = this.userBookingOptionMap.findIndex(x => x.userId === removedUser.id);
    this.userBookingOptionMap.splice(index, 1);
  }

  async addGuest() {
    const user = await toPromise(this.parent$);
    const dialog = this.matDialog.open(UserAddGuestDialog, {
      data: { user, event: this.event },
    });
    const response: User | undefined | null = await toPromise(dialog.afterClosed());
    if (response) {
      this.selected(response);
    }
  }

  async previewChanged(index: number, preview: BookingPreview) {
    if (this.previews[index].user.id === preview.dto.userId) {
      this.previews[index].preview = preview;
      const clashError = await this.hasClashError(this.previews[index].user);
      if (clashError) {
        this.remove(this.previews[index].user);
      }
      if (!clashError) this.previews = [...this.previews];
    } else throw new Error(`Preview not for user at index: ${index}`);
  }

  async changeLoading(loading: boolean) {
    this.loading = loading;
    this.multiPreviewLoading.emit(loading);
  }

  signaturesChanged(index: number, signatures: { agreementId: string; signature: string }[]) {
    this.previews[index].signatures = signatures;
    this.previews = [...this.previews];
  }

  requirementsChanged(index: number, requirementValues$: BehaviorSubject<{ [id: string]: string }>) {
    this.previews[index].requirementValues$ = requirementValues$;
    this.previews = [...this.previews];
  }

  selectedPaymentMethodChanged(paymentMethod: PaymentMethod | null) {
    this.selectedPaymentMethod = paymentMethod;
  }

  async leaveWaitlist() {
    const event = this.event;
    if (!event) return;

    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        title: 'Leave Waitlist',
        showCloseButton: false,
        subtitle: `${event.title} - ${moment(event.startDate).format('ll hh:mm A')}`,
        content: 'If you leave the waitlist you will no longer be notified as soon as a spot opens up.',
        buttons: [
          { label: 'No, stay in the waitlist', role: 'no' },
          { label: 'Yes, leave the waitlist', role: 'yes' },
        ],
      } as DialogData,
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.bookingSvc.removeFromWaitlist(event.community.id, { userId: this.user.id, eventId: event.id });

      if (!this.preventRedirect) await this.router.navigate(['/']);
      this.snacks.open('Removed from waitlist!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      location.reload();
    }
  }

  async joinWaitlist() {
    const event = this.event;
    if (!event) return;

    if (this.user?.email && event.waitlist && event.waitlist.map(wi => wi.email).includes(this.user.email)) {
      this.leaveWaitlist();
      return;
    }

    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        title: 'Join Waitlist',
        showCloseButton: false,
        subtitle: `${event.title} - ${moment(event.startDate).format('ll hh:mm A')}`,
        content:
          'The event is currently fully booked or does not have room for all selected users. Join the waitlist to be notified as soon as a spot opens up.',
        buttons: [
          { label: "No, I don't want to join the waitlist", role: 'no' },
          { label: 'Yes, add me to the waitlist', role: 'yes' },
        ],
      } as DialogData,
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      try {
        await this.bookingSvc.joinWaitlistMultiple(
          event.id,
          this.previews.map(preview => preview.user.id)
        );

        if (!this.preventRedirect) await this.router.navigate(['/']);
        this.snacks.open('Added to waitlist!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
        location.reload();
      } catch (e: any) {
        if (e.error.message.includes('Unable to join waitlist, user already booked into event')) {
          this.snacks.open('User already booked into the event.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
          await this.router.navigate(['/']);
        } else {
          this.snacks.open('Something went wrong! Please try again later', 'Ok', {
            duration: 2500,
            panelClass: 'mat-warn',
          });
        }
      }
    }
  }

  async confirm() {
    if (this._confirming) return;

    // let accountComplete = true;
    // this.previews.forEach(async preview => {
    //   const checkUser = preview.user;

    //   if (
    //     checkUser.id === this.user.id &&
    //     (!checkUser.displayName || !checkUser.birthday || !checkUser.phoneNumber || !checkUser.emergencyPhoneNumber)
    //   ) {
    //     accountComplete = false;
    //   }
    // });

    // let shouldConfirm = true;
    // if (!accountComplete) shouldConfirm = await this.completeUserProfile(this.user, this.user.displayName);

    // if (shouldConfirm) {
    this._confirming = true;
    this._confirm();
    // }
  }

  async _confirm() {
    const dtos: BookingConfirmationDto[] = [];
    for (const requestedBooking of this.previews) {
      if (!requestedBooking.preview) continue;

      if (requestedBooking.preview?.warnings?.length) {
        const dialog = this.matDialog.open(SimpleDialog, {
          data: {
            title: `Warnings For ${requestedBooking.user.displayName}:`,
            content: requestedBooking.preview.warnings.map(warn => `<p>${warn}</p>`).join(''),
            buttons: [
              { label: 'No, I want to make some changes first', role: 'no' },
              { label: 'Yes, Confirm the Booking', role: 'yes' },
            ],
          } as DialogData,
        });
        const result = await toPromise(dialog.afterClosed());

        if (!result || result === 'no') {
          this._confirming = false;
          return;
        }
      }

      const requirements = Object.entries(requestedBooking.requirementValues$.value || {}).reduce(
        (acc, [key, value]) => [...acc, { requirementId: key, value }],
        [] as { requirementId: string; value: string }[]
      );

      await Promise.all(
        requestedBooking.signatures.map(async linkedSignature => {
          try {
            await this.signatureSvc.getOne(requestedBooking.preview!.booking.bookedById);
            await this.signatureSvc.update(requestedBooking.preview!.booking.bookedById, linkedSignature.signature);
          } catch (err) {
            console.log('No signature found, creating one. Error:', err);
            await this.signatureSvc.create({
              userId: requestedBooking.preview!.booking.bookedById,
              signature: linkedSignature.signature,
            });
          }
        })
      );

      const dto = {
        booking: { ...requestedBooking.preview.dto, requirements },
        purchaseHash: requestedBooking.preview.purchase?.hash,
        bookingHash: requestedBooking.preview.hash,
      };
      dtos.push(dto);
    }

    try {
      if (dtos.length) {
        let bookings = null;
        let dialog = null;

        if (this.isAddAttendee) {
          dialog = await this.matDialog
            .open(ConfirmAddAttendeeDialog, {
              data: {
                title: 'Confirm Adding Attendee',
                userName: this.previews.map(x => ' ' + x.preview?.booking?.user?.displayName),
                eventTitle: this.event?.title,
              },
              width: '500px',
              maxWidth: '90%',
            })
            .afterClosed()
            .toPromise();

          if (dialog === 'confirm') {
            bookings = await this.bookingSvc.confirmMultiple(dtos);
          } else {
            this._confirming = false;
            return;
          }
        }

        if (!this.isAddAttendee) {
          bookings = await this.bookingSvc.confirmMultiple(dtos);
        }

        if (bookings?.length) {
          if (!this.preventRedirect) await this.router.navigate(['/']);
          else {
            this.previews = [...this.previews];
          }
        }

        this.booked.emit(bookings || undefined);

        this.snacks.open(
          bookings!.length === 1
            ? 'Your booking has been confirmed!'
            : bookings!.length + ' bookings have been confirmed!',
          'Ok!',
          {
            duration: 5000,
            panelClass: 'mat-primary',
          }
        );
      }
    } catch (err: any) {
      let msg = 'message' in err ? err.error.message : '' + err;
      if (msg.includes('User already booked') && msg.includes('Event already full')) {
        msg =
          'Duplicate entry! User already booked in and further registration is closed as the Event is already full.';
      } else if (msg.includes('User already booked')) {
        msg = 'Duplicate entry! User already booked in.';
      } else if (msg.includes('Event already full')) {
        msg = 'This Event is Full Now, No more bookings accepted!';
      } else {
        msg = 'Something went wrong! Please try again later.';
      }
      this.snacks.open(`Oops, failed to confirm ${this.preventRedirect ? 'this' : 'your'} booking! ${msg}`, 'Ok', {
        duration: 10000,
        panelClass: 'mat-warn',
      });

      if (msg.includes('Event already full')) {
        await this.joinWaitlist();
      }
    }

    this._confirming = false;
  }

  // async completeUserProfile(user: User, name: string) {
  //   if (!user) return false;

  //   const dialog = this.matDialog.open(AccountCompletionDialog, { data: { user, name } });
  //   const response = await toPromise(dialog.afterClosed());

  //   if (!response || response === 'cancel') {
  //     this._confirming = false;
  //     return false;
  //   } else {
  //     this.snacks.open('Updated profile credentials!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
  //     return true;
  //   }
  // }

  async ngOnInit() {
    const eventBookings = await toPromise(this.eventBookings$);
    const linkedAccounts = await toPromise(this.linkedAccounts$);
    if (!linkedAccounts?.length || !eventBookings.some(booking => booking.user.id === this.user.id)) {
      this.previews = [
        { user: this.user, signatures: [], requirementValues$: new BehaviorSubject<{ [id: string]: string }>({}) },
      ];
    }
  }
}
