import { Component, Input, OnInit } from '@angular/core';
import { EventSeries } from '@greco/booking-events';
import { RRule } from 'rrule';

@Component({
  selector: 'greco-course-week',
  templateUrl: './course-week.component.html',
  styleUrls: ['./course-week.component.scss'],
})
export class CourseWeekComponent implements OnInit {
  @Input() course?: EventSeries; //or pass recurrence data here
  @Input() showAsBoxes = false;

  recurrence?: any;
  rules: RRule[] = [];
  daysOfWeek: string[] = [];
  addedHeight = 0;

  WEEKDAYS = [
    { id: 'BYDAY=SU', value: 'S', name: 'Sunday' },
    { id: 'BYDAY=MO', value: 'M', name: 'Monday' },
    { id: 'BYDAY=TU', value: 'T', name: 'Tuesday' },
    { id: 'BYDAY=WE', value: 'W', name: 'Wednesday' },
    { id: 'BYDAY=TH', value: 'T', name: 'Thursday' },
    { id: 'BYDAY=FR', value: 'F', name: 'Friday' },
    { id: 'BYDAY=SA', value: 'S', name: 'Sunday' },
  ];

  ngOnInit(): void {
    this.recurrence = this.course?.recurrence.toString();
    this.course?.recurrence.forEach((item: string) => {
      const rule = RRule.fromString(item);
      this.rules?.push(rule);
    });

    // Check if box sizes need to be updated
    if (this.showAsBoxes) {
      const rulesPerDay = [0, 0, 0, 0, 0, 0, 0];
      this.rules?.forEach(rule => (rulesPerDay[rule.options.byweekday[0]] += 1));
      const mostRules = Math.max(...rulesPerDay);
      if (mostRules > 2) this.addedHeight = 64 + mostRules * 7;
    }
  }
}
