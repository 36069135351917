import { BaseDto } from '@greco/util-dto';
import { Expose } from 'class-transformer';
import { IsOptional, IsString } from 'class-validator';

export class UpdateVideoTagDto extends BaseDto {
  @Expose()
  @IsString()
  @IsOptional()
  label?: string;

  @Expose()
  @IsString()
  @IsOptional()
  icon?: string;
}
