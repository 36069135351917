<greco-simple-dialog [data]="simpleDialog">
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-form-field appearance="standard">
      <mat-label>Extension</mat-label>
      <input matInput readonly [value]="formGroup.value.addonType | titlecase" />
    </mat-form-field>

    <ng-container [ngSwitch]="formGroup.value.addonType">
      <greco-shop-configuration-form
        *ngSwitchCase="'SHOP'"
        formControlName="config"
        [productId]="productId"
      ></greco-shop-configuration-form>

      <greco-agreement-configuration-form
        [communityId]="communityId"
        [addonId]="addonId"
        *ngSwitchCase="'AGREEMENT'"
        formControlName="config"
        (usage)="addUsage($event)"
      ></greco-agreement-configuration-form>

      <greco-user-availability-configuration-form
        *ngSwitchCase="'USER AVAILABILITY'"
        formControlName="config"
        [communityId]="communityId"
      ></greco-user-availability-configuration-form>

      <greco-upgrade-configuration-form
        [communityId]="communityId"
        *ngSwitchCase="'SELF-SERVE UPDATE'"
        formControlName="config"
      ></greco-upgrade-configuration-form>

      <greco-inventory-configuration-form
        *ngSwitchCase="'INVENTORY'"
        formControlName="config"
        [productId]="productId"
        [addonId]="addon?.id"
        (created)="addonCreated($event)"
      ></greco-inventory-configuration-form>
    </ng-container>

    <div class="buttons">
      <button type="button" mat-stroked-button [disabled]="submitting" (click)="cancel()">Cancel</button>

      <button type="submit" color="primary" mat-stroked-button [disabled]="(valid$ | async) === false || submitting">
        <mat-icon *ngIf="submitting" class="spin">loop</mat-icon>
        <span>Confirm</span>
      </button>
    </div>
  </form>
</greco-simple-dialog>
