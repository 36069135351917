import { Account } from '@greco/finance-accounts';
import { Transaction } from '@greco/finance-transactions';
import { PaymentMethod } from './payment-method';

export enum PaymentStatus {
  PENDING = 'PENDING',
  VOIDED = 'VOIDED',
  PAID = 'PAID',

  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',

  PENDING_PAYMENT = 'PENDING_PRE_AUTHORIZATION',
  PRE_AUTHORIZED = 'PRE_AUTHORIZED',

  FAILED = 'FAILED',
  RETRYING = 'RETRYING',
  DELAYED_PROCESSING = 'DELAYED_PROCESSING',

  DISPUTED = 'DISPUTED',
  DISPUTE_LOST = 'DISPUTE_LOST',
}

export interface PaymentRetry {
  payment: Payment;
  date: Date;
}

export interface Payment {
  id: string;
  account: Account;
  gateway: string;
  gatewayMetadata: null | any;

  transaction: Transaction;

  externalId: null | string;
  externalData: null | any;
  externalFee: number | null;

  status: PaymentStatus;
  failureReason: string | null;

  nextRetryDate: null | Date;
  retries: PaymentRetry[];

  created: Date;
  modified: Date;

  amount: number;
  refundedAmount?: number;
  lastRefundedAmount?: number;

  fundsAvailableDate: null | Date;

  paymentDate: null | Date;
  shouldPreAuthorize: boolean;
  paymentMethod: PaymentMethod;

  description: null | string;
  previousStatus?: PaymentStatus;
}
