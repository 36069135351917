import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Calendar } from '@greco/booking-events';
import { SelectFilter } from '@greco/ngx-filters';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { CalendarService } from '../../../services';

@Injectable({
  providedIn: 'any',
})
export class CalendarFilter extends SelectFilter {
  public titleCase = new TitleCasePipe();
  constructor(public calendarSvc: CalendarService) {
    super('CalendarFilter', {
      label: 'Calendar',
      properties: ['calendar.id'],
      shortLabel: 'Calendar',
      description: '',
    });
    this.allowedOperators = [CondOperator.EQUALS];
  }

  public communityId?: string;

  // getImageUrl(value: Calendar | Calendar[]) {
  //   return (Array.isArray(value) ? value[0].photoURL : value.photoURL) ?? 'assets/lf3/icon.png';
  // }

  getValueLabel(value: Calendar | Calendar[]): string {
    return Array.isArray(value)
      ? value.map(calendar => this.titleCase.transform(calendar.title)).join(', ')
      : this.titleCase.transform(value.title);
  }

  async getValueOptions(search?: string): Promise<Calendar[]> {
    if (!this.communityId) return [];
    const calendars = await this.calendarSvc.paginate(
      this.communityId,
      new RequestQueryBuilder().search({
        name: { $contL: search },
      }),
      {
        page: 1,
        limit: search ? 2 : 100,
      }
    );

    return calendars.items;
  }

  serializeValue(value: Calendar | Calendar[]): string {
    return Array.isArray(value) ? value.map(calendar => calendar.id).join(',') : value.id;
  }

  async deserializeValue(serializedValue: string): Promise<Calendar[]> {
    const ids = serializedValue.split(',');
    if (!ids.length) return [];
    return await Promise.all(
      ids.map(id => {
        return this.calendarSvc.getOne(id);
        // return this.resourceService.getResource(id);
      })
    );
  }

  compareWith(value1: Calendar, value2: Calendar) {
    return value1?.id === value2?.id;
  }
}
