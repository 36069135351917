<div *ngIf="community" class="stats-container">
  <greco-box>
    <h3>Last 7 Days</h3>
    <p>{{ stats ? (stats.last7Days + ' Contacts') : '-' }}</p>
    <p style="font-size: 75%">Created/joined in the last 7 days</p>
  </greco-box>

  <greco-box>
    <h3>Last 30 Days</h3>
    <p>{{ stats ? (stats.last30Days + ' Contacts') : '-' }}</p>
    <p style="font-size: 75%">Created/joined in the last 30 days</p>
  </greco-box>

  <greco-box>
    <h3>Last 90 Days</h3>
    <p>{{ stats ? (stats.last90Days + ' Contacts') : '-' }}</p>
    <p style="font-size: 75%">Created/joined in the last 90 days</p>
  </greco-box>
</div>

<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()"
>
  <div style="display: flex; align-items: center; justify-content: flex-end; height: 32px">
    <ng-container *ngIf="community?.id">
      <button
        *ngIf="canCreateContact$ | async; else exportContactsT"
        (click)="createContact()"
        mat-stroked-button
        color="primary"
        style="height: 100%; border-top-right-radius: 0; border-bottom-right-radius: 0"
      >
        New Contact
      </button>

      <ng-template #exportContactsT>
        <button
          *ngIf="canExportContacts$ | async"
          (click)="exportContacts(community.id)"
          mat-stroked-button
          color="primary"
          style="height: 100%"
        >
          Export Contacts
        </button>
      </ng-template>
    </ng-container>

    <button
      *ngIf="canExportContacts$ | async"
      [matMenuTriggerFor]="create_menu"
      mat-stroked-button
      color="primary"
      style="
        border-left: none;
        min-width: 0;
        height: 100%;
        padding: 0 8px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      "
    >
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>

  <mat-menu #create_menu>
    <a *ngIf="canExportContacts$ | async" mat-menu-item (click)="exportContacts(community.id)" color="primary">
      <mat-icon>cloud_download</mat-icon>
      Export Contacts
    </a>
  </mat-menu>
</greco-filter-bar>

<greco-table
  #contactsTable
  [highlight]="true"
  [loading]="loading"
  [data]="(contacts$ | async) || []"
  [activeSort]="(sort$ | async) || undefined"
  (sortChange)="sort$.next($event)"
  (rowClick)="openContact($event)"
>
  <!-- Icon/Photo -->
  <ng-container *grecoTableCol="''; fitContent:true; let contact" [matTooltip]="contact.id">
    <div style="width: 26px; height: 26px" [style.filter]="contact.user ? '' : 'grayscale(1) opacity(0.7)'">
      <greco-smart-img
        [borderColor]="'primary'"
        [src]="contact.user?.photoURL"
        [border]="true"
        [round]="true"
      ></greco-smart-img>
    </div>
  </ng-container>

  <!-- Name -->
  <p *grecoTableCol="'Name'; let contact; sorting: 'displayName'" [matTooltip]="contact.email">
    <strong>{{contact.displayName}}</strong>
    <small *ngIf="contact.parents?.length && !contact.user.email" style="font-style: italic">
      ({{(contact.parents | pluck:'accessor.displayName')?.join(', ')}})
    </small>
  </p>

  <!-- Email Address -->
  <p *grecoTableCol="'Email Address'; let contact; sorting: 'email'" [matTooltip]="contact.email">{{contact.email}}</p>

  <!-- Community -->
  <ng-container *ngIf="!community">
    <p *grecoTableCol="'Community'; let contact;">{{contact.community.name}}</p>
  </ng-container>

  <!-- Phone Number -->
  <p
    *grecoTableCol="'Phone Number'; let contact; sorting: 'phoneNumber'"
    [matTooltip]="contact.phoneNumber ? (contact.phoneNumber | mask: '(000) 000-0000') : ''"
  >
    {{ contact.phoneNumber ? (contact.phoneNumber | mask: '(000) 000-0000') : '-' }}
  </p>

  <!-- Created Date-->
  <p *grecoTableCol="'Created Date'; let contact; sorting: 'created'">{{ contact.created | date: 'longDate'}}</p>

  <!-- Last Visit (Date + info on hover) -->
  <!-- <p *grecoTableCol="'Last Visit'; let contact"></p> -->

  <!-- Last Purchase (Date + info on hover) -->
  <!-- <p *grecoTableCol="'Last Purchase'; let contact"></p> -->

  <!-- Perks -->
  <!-- <p *grecoTableCol="'Perks'; let contact"></p> -->

  <!-- State (Contact, Subscriber, Member) -->
  <p *grecoTableCol="'Subscribed'; let contact; sorting: 'subscribedDate'">{{contact.subscribedDate ? 'Yes' : 'No'}}</p>

  <!-- Options -->
  <ng-container *grecoTableCol="''; let contact; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <a
        *ngIf="!contact.user && (canInviteContact$ | async)"
        mat-menu-item
        [disabled]="invited || inviteLoading"
        (click)="inviteUser(contact); $event.stopImmediatePropagation()"
      >
        <mat-icon *ngIf="!inviteLoading">{{invited ? "mark_email_read" : "forward_to_inbox"}}</mat-icon>
        <mat-icon class="processing" *ngIf="inviteLoading">loop</mat-icon>
        {{ inviteLoading ? "Inviting..." : invited ? "Invited" : "Invite" }}
      </a>

      <a mat-menu-item [routerLink]="['/admin/community', contact.community.id, contact.id]">
        <mat-icon>open_in_new</mat-icon>
        Details
      </a>
      <span *ngIf="(user$ | async) as user">
        <a
          mat-menu-item
          *ngIf="!contact.subscribedDate && contact?.user?.id && (canSubscribeMember$ | async)"
          (click)="subscribeUser(contact.user.id)"
        >
          <mat-icon>group_add</mat-icon>
          Subscribe
        </a>
      </span>

      <!-- <a
        *ngIf="contact.user && (canShopAs$ | async)"
        mat-menu-item
        [routerLink]="['/shop']"
        [queryParams]="{ user: contact.user.id }"
      >
        <mat-icon>shopping_cart</mat-icon>
        Shop as {{contact.displayName}}
      </a> -->

      <a *ngIf="!contact.user && (canDeleteContact$ | async)" mat-menu-item (click)="deleteContact(contact)">
        <mat-icon>delete</mat-icon>
        Delete
      </a>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
