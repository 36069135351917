import { Pipe, PipeTransform } from '@angular/core';
import { ProductVariantRecurrence } from '@greco/sales-products';
import { RecurrencePeriod, SubscriptionRecurrence } from '@greco/sales-subscriptions';

@Pipe({ name: 'grecoRecurrence' })
export class RecurrenceLabelPipe implements PipeTransform {
  transform(recurrence?: SubscriptionRecurrence | ProductVariantRecurrence) {
    if (!recurrence) return '';
    return `${recurrence.frequency === 1 ? '' : recurrence.frequency} ${this.getPeriodLabel(recurrence.period)}${
      recurrence.frequency > 1 ? 's' : ''
    }`;
  }

  private getPeriodLabel(period: RecurrencePeriod) {
    switch (period) {
      case RecurrencePeriod.Daily:
        return 'Day';
      case RecurrencePeriod.Monthly:
        return 'Month';
      case RecurrencePeriod.Weekly:
        return 'Week';
      case RecurrencePeriod.Yearly:
        return 'Year';
    }
  }
}
