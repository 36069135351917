import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CLIENT_ID_TOKEN, StripeConnectService } from '../services';

@Injectable()
export class StripeConnectRedirectResolver implements Resolve<void> {
  constructor(
    private connectSvc: StripeConnectService,
    private snackbar: MatSnackBar,
    @Inject(CLIENT_ID_TOKEN) private clientId: string
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    const { code, state } = route.queryParams;
    if (!code || !state) return;

    try {
      await this.connectSvc.completeAccountAuthorization(code, state);
      this.snackbar.open('Account connection completed!', 'Ok', { duration: 2000 });
    } catch (err) {
      console.error('StripeConnectRedirectResolver', err);
    }
  }
}
