<div class="content">
  <!--  <div class="left-column">-->
  <!--    <div class="hero-section">-->
  <!--      <h1 class="hero-title" *ngIf="signInTitle" [class.with-subtitle]="subtitle">{{ signInTitle }}</h1>-->
  <!--      <h2 class="hero-subtitle" *ngIf="subtitle">{{ subtitle }}</h2>-->
  <!--      <div class="hero-desc" *ngIf="description" [innerHtml]="description || '' | safe: 'html'"></div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash; <div class="testimonials">-->
  <!--      <greco-testimonial-carousel></greco-testimonial-carousel>-->
  <!--    </div> &ndash;&gt;-->
  <!--  </div>-->

  <div class="right-col">
    <ng-content></ng-content>
  </div>
</div>
