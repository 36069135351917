<greco-filter-bar [hideSeparator]="true" [filterOptions]="filterOptions" (changed)="filters$.next($event[1])">
  <button class="filter-bar-button" *ngIf="canCreatePromotion$ | async" mat-stroked-button (click)="createPromotion()">
    <mat-icon>add</mat-icon>Create Promotion
  </button>
</greco-filter-bar>

<greco-table [data]="(perks$ | async) || []" [highlight]="true" [loading]="loading" (rowClick)="openPromo($event)">
  <mat-icon *grecoTableCol="''; let promo; fitContent: true;" [matTooltip]="promo.id">local_offer</mat-icon>
  <p *grecoTableCol="'Promotion'; let promo">{{promo.title}}</p>
  <p *grecoTableCol="'Tag Line'; let promo">{{promo.subtitle}}</p>
  <p *grecoTableCol="'Date'; let promo">{{ promo.startDate | date }} - {{ promo.endDate | date }}</p>

  <!-- <ng-container *grecoTableCol="''; let perk; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu> -->
  <!-- <button mat-menu-item>
        <mat-icon>toggle_on</mat-icon>
        <span>Toggle Sub-Community Availability</span>
      </button> -->
  <!-- <button mat-menu-item>
        <mat-icon>open_in_new</mat-icon>
        <span>View Details</span>
      </button> -->
  <!-- </mat-menu>
  </ng-container> -->
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [pageSizeOptions]="[10, 20, 50]"
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
