import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { toPromise } from '@greco-fit/util';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { PerkResource, PerkResourceAction, UserPerk } from '@greco/sales-perks';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { GrantUserPerkDialog, RemoveSinglePerkDialog } from '../../dialogs';
import { PerkBadgeEditDialog } from '../../dialogs/perk-badge-edit/perk-badge-edit.dialog';
import { PerkService } from '../../services';
import {
  UserPerkExpiryDateFilter,
  UserPerkGrantedByFilter,
  UserPerkGrantedDateFilter,
  UserPerkPerkFilter,
  UserPerkSearchFilter,
  UserPerkUserFilter,
} from './filters';

@Component({
  selector: 'greco-admin-user-perks-page',
  templateUrl: './user-perks.page.html',
  styleUrls: ['./user-perks.page.scss'],
})
export class UserPerksPage implements OnDestroy {
  matDialog: any;
  userId: any;

  constructor(
    private dialog: MatDialog,
    public userSvc: UserService,
    private perksSvc: PerkService,
    private securitySvc: SecurityService,
    private userFilter: UserPerkUserFilter,
    private perkFilter: UserPerkPerkFilter
  ) {}

  private _communityId$ = new BehaviorSubject<string | null>(null);

  @Input() get communityId() {
    return this._communityId$.value;
  }

  set communityId(communityId: string | null) {
    this.userFilter.communityId = communityId || undefined;
    this.perkFilter.communityId = communityId || undefined;
    this._communityId$.next(communityId);
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });

  userperks$ = combineLatest([this.filters$, this._communityId$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(
      async ([filters, communityId, pagination]) =>
        await this.perksSvc.paginateUserPerks(filters, communityId || undefined, pagination)
    ),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  stats$ = this._communityId$.pipe(
    switchMap(async communityId => (communityId ? await this.perksSvc.getUserPerksStats(communityId) : null))
  );

  filterOptions = [
    UserPerkSearchFilter,
    UserPerkExpiryDateFilter,
    UserPerkGrantedDateFilter,
    UserPerkUserFilter,
    UserPerkPerkFilter,
    UserPerkGrantedByFilter,
  ];

  loading = false;
  pagination: IPaginationMeta | null = null;

  currentUser$ = this._communityId$.pipe(
    filter(c => !!c),
    switchMap(async () => {
      return await this.userSvc.getSelf();
    })
  );
  canManageUserPerks$ = combineLatest([this._communityId$, this.currentUser$]).pipe(
    distinctUntilChanged(),
    switchMap(async ([id, user]) => {
      return id && user
        ? await this.securitySvc.hasAccess(PerkResource.key, PerkResourceAction.GRANT, {
            communityId: id,
            userId: user?.id,
          })
        : false;
    }),
    shareReplay(1)
  );

  refresh() {
    this._communityId$.next(this._communityId$.value);
  }

  async _removePerks(perk: UserPerk) {
    const dialog = this.dialog.open(RemoveSinglePerkDialog, {
      data: {
        reusable: !perk.consumable,
        userName: perk.user?.displayName,
        userId: perk.user?.id,
        expiry: perk.expiryDate,
        perk: perk.perk,
      },
      width: '750px',
      maxWidth: '90%',
    });
    const result = await toPromise(dialog.afterClosed());
    if (result) this.refresh();
  }

  async grantPerk() {
    const [communityId] = await Promise.all([toPromise(this._communityId$)]);

    const dialog = this.dialog.open(GrantUserPerkDialog, {
      data: { communityId },
      width: '750px',
      maxWidth: '90%',
    });

    const result = await toPromise(dialog.afterClosed());
    if (result) this.refresh?.();
  }

  async updatePerkBadge(userperk: UserPerk) {
    const dialogRef = this.dialog.open(PerkBadgeEditDialog, {
      data: {
        perk: userperk.perk,
      },
      width: '750px',
      maxWidth: '90%',
    });
    await toPromise(dialogRef.afterClosed());
    this.page$.next(this.page$.value);
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.page$.complete();
    this.filters$.complete();
    this._communityId$.complete();
  }
}
