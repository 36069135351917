<greco-simple-dialog [data]="dialogData">
  <form [formGroup]="formGroup">
    <greco-event-details-input
      #details
      [required]="true"
      [date]="data.date"
      [communityId]="data.communityId"
      [forSeries]="data.forSeries || false"
      [lockResources]="!!data.resource && !data.allowChangeResources"
      (resourceAvailability)="availability = $event"
      formControlName="details"
    ></greco-event-details-input>

    <greco-series-schedule-input
      #schedule
      *ngIf="data.forSeries"
      [required]="true"
      [communityId]="data.communityId"
      (scheduleChanged)="refreshAvailability($event)"
      formControlName="schedule"
    ></greco-series-schedule-input>
  </form>

  <div style="margin-top: 12px; display: flex; justify-content: flex-end; gap: 8px">
    <button mat-button [disabled]="createCalled || createAttendeeCalled" (click)="cancel()">Cancel</button>

    <button
      *ngIf="(canCreateBookings$ | async) && !data.forSeries"
      mat-button
      [disabled]="formGroup.invalid || createCalled || createAttendeeCalled"
      (click)="createAndAddAttendees()"
    >
      <mat-icon *ngIf="createAttendeeCalled" class="spin">loop</mat-icon> Create & Add Attendees
    </button>

    <button mat-button [disabled]="formGroup.invalid || createCalled || createAttendeeCalled" (click)="create()">
      <mat-icon *ngIf="createCalled" class="spin">loop</mat-icon> Create
    </button>
  </div>
</greco-simple-dialog>
