import { Pipe, PipeTransform } from '@angular/core';
import { Booking, BookingStatus } from '@greco/booking-events';

@Pipe({ name: 'bookingIcon' })
export class BookingIconPipe implements PipeTransform {
  transform(value?: Booking): string {
    switch (value?.status) {
      case BookingStatus.NO_SHOW:
      case BookingStatus.CANCELLED:
      case BookingStatus.LATE_CANCELLED:
        return 'event_busy';

      case BookingStatus.CHECKED_IN:
        return 'event_available';

      default:
        return 'today';
    }
  }
}
