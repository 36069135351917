<greco-alert
  *ngIf="missingPaymentMethod$ | async"
  style="display: block; margin-bottom: 8px"
  [config]="{
    title: 'Payment Method Required',
    type: $any('DANGER'),
    description: 'This payment method will never be charged without your authorization.'
  }"
>
  <ng-container slot="buttons" *ngIf="event">
    <greco-add-payment-method-button
      *grecoLet="eventSvc.paymentMethods$ | async as paymentMethods"
      [userId]="event.accounts[0].user.id || ''"
      [hiddenForms]="['Bank Account']"
      (saved)="updatePaymentMethods(paymentMethods, $event); updatePaymentMethodToUse($event)"
    ></greco-add-payment-method-button>
  </ng-container>
</greco-alert>

<greco-alert *ngFor="let alert of expiredAlerts$ | async" style="display: block; margin-bottom: 8px" [config]="alert">
  <button
    mat-flat-button
    color="warn"
    (click)="bottomSheet.open(updatePaymentMethodSheet, { panelClass: 'bottom-sheet' })"
  >
    Update Payment
  </button>
</greco-alert>

<greco-alert
  *ngFor="let alert of expiringAlerts$ | async"
  style="display: block; margin-bottom: 8px"
  [config]="alert"
></greco-alert>

<ng-template #updatePaymentMethodSheet>
  <div *grecoLet="eventSvc.paymentMethodToUse$ | async as paymentMethodToUse" class="bottom-sheet-content">
    <div class="sheet-header">
      <h2 #header>Update Payment Method</h2>
      <div>
        <button mat-icon-button (click)="bottomSheet.dismiss()">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
    </div>

    <h3>Select Payment Method</h3>
    <div *ngFor="let paymentMethod of eventSvc.paymentMethods$ | async" class="cards-container">
      <div class="card-info">
        <div class="card">
          <img [src]="'assets/payment-methods/' + paymentMethod.model + '.png'" />

          <div class="card-text">
            <p>
              <ng-container *ngIf="paymentMethod.model === 'bank'; else cardText">
                <strong>{{ paymentMethod.label }}</strong>
              </ng-container>

              <ng-template #cardText>
                <strong>{{ paymentMethod.model | titlecase }} ending in {{ paymentMethod.label | slice : -4 }}</strong>
              </ng-template>
            </p>

            <p>
              {{ paymentMethod.details ? 'Expiry ' + paymentMethod.details : '' }}
              {{ paymentMethod.model === 'bank' ? 'Pre-Authorized Debit' : '' }}
              {{
                paymentMethod.cardHolderName
                  ? (paymentMethod.details || paymentMethod.model === 'bank' ? ' • ' : '') +
                    paymentMethod.cardHolderName
                  : ''
              }}
            </p>
          </div>
        </div>

        <button
          mat-flat-button
          [disabled]="paymentMethod.id === paymentMethodToUse?.id"
          (click)="updatePaymentMethodToUse(paymentMethod)"
        >
          Select
        </button>
      </div>
    </div>

    <div style="display: flex; justify-content: center">
      <ng-container *ngIf="event">
        <greco-add-payment-method-button
          *grecoLet="eventSvc.paymentMethods$ | async as paymentMethods"
          [userId]="event.accounts[0].user.id || ''"
          [hiddenForms]="['Bank Account']"
          (saved)="updatePaymentMethods(paymentMethods, $event)"
        ></greco-add-payment-method-button>
      </ng-container>
    </div>
  </div>
</ng-template>
