import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { CommunityAgreementSecurityActions, CommunityAgreementSecurityResource } from '@greco/community-agreements';
import { Community } from '@greco/identity-communities';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CreateAgreementDialog, CreateUserAgreementDialog } from '../../dialogs';

@Component({
  selector: 'greco-agreements-page',
  templateUrl: './agreements.page.html',
  styleUrls: ['./agreements.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AgreementsPage {
  private static plainText = '<i>Start typing here...<i>';
  constructor(
    private securitySvc: SecurityService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  @PropertyListener('community') community$ = new BehaviorSubject<Community | null>(null);
  @Input() community!: Community;

  canCreate$ = this.community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.CREATE,
            { communityId: community.id }
          )
        : false;
    })
  );

  canExecute$ = this.community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            CommunityAgreementSecurityResource.key,
            CommunityAgreementSecurityActions.EXECUTE,
            { communityId: community.id }
          )
        : false;
    })
  );

  async createAgreement() {
    const createAgr = await toPromise(
      this.dialog
        .open(CreateAgreementDialog, {
          data: { communityId: this.community.id },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
    );
    if (createAgr?.id) {
      this.snackBar.open('Agreement created sucessfully!', 'Ok', { duration: 3000 });
      this.router.navigate([createAgr.id], { relativeTo: this.route });
    }
  }

  async createUserAgreement() {
    const createUserAgr = await toPromise(
      this.dialog
        .open(CreateUserAgreementDialog, {
          data: { communityId: this.community.id },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
    );
    if (createUserAgr?.id) {
      this.snackBar.open('User agreement created sucessfully!', 'Ok', { duration: 3000, panelClass: 'mat-primary' });
    }
  }
}
