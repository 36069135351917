import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CartItemsResolver implements Resolve<any[]> {
  async resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<any[]> {
    return [];
  }
}
