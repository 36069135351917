import { TitleCasePipe } from '@angular/common';
import { Component, Injectable, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { Tag } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { BuildSearchFilter, SelectFilter } from '@greco/ngx-filters';
import { PropertyListener } from '@greco/property-listener-util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';
import { BookingOptionsTableComponent } from '../../components';
import { CreateOptionDialog, ManageBookingTagsDialog } from '../../dialogs';
import { TagService } from '../../services';

@Injectable({ providedIn: 'any' })
export class BookingOptionSearchFilter extends BuildSearchFilter('BookingOptionSearchFilter', {
  properties: ['title', 'shortTitle', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Injectable({ providedIn: 'any' })
export class BookingOptionTagsFilter extends SelectFilter {
  constructor(private tagSvc: TagService) {
    super('BookingOptionTagsFilter', {
      label: 'Tag',
      properties: ['tags.id'],
      shortLabel: 'Tag',
      description: '',
    });
    this.allowedOperators = [CondOperator.EQUALS];
  }

  private titleCase = new TitleCasePipe();
  public communityId?: string;

  getValueLabel(value: Tag | Tag[]): string {
    return Array.isArray(value)
      ? value.map(tag => this.titleCase.transform(tag.label)).join(', ')
      : this.titleCase.transform(value.label);
  }

  async getValueOptions(search?: string): Promise<Tag[]> {
    if (!this.communityId) return [];
    const tags = await this.tagSvc.paginate(
      new RequestQueryBuilder().search({ label: { $contL: search } }),
      this.communityId,
      { limit: search ? 2 : 100 }
    );

    return tags.items;
  }

  serializeValue(value: Tag | Tag[]): string {
    return Array.isArray(value) ? value.map(tag => tag.id).join(',') : value.id;
  }

  async deserializeValue(serializedValue: string): Promise<Tag[]> {
    const ids = serializedValue.split(',');
    if (!ids.length) return [];
    return await Promise.all(ids.map(id => this.tagSvc.getOne(id)));
  }

  compareWith(value1: Tag, value2: Tag) {
    return value1?.id === value2?.id;
  }
}

@Component({
  selector: 'greco-bookability-page',
  templateUrl: './bookability.page.html',
  styleUrls: ['./bookability.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BookabilityPage {
  constructor(private dialog: MatDialog, private tagFilter: BookingOptionTagsFilter) {}

  @ViewChild(BookingOptionsTableComponent) table?: BookingOptionsTableComponent;

  @Input() community!: Community;

  filterOptions = [BookingOptionSearchFilter, BookingOptionTagsFilter];

  //   filterOptions: Type<Filter>[] = [ContactSearchFilter, ContactEmailFilter, ContactDisplayNameFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  @PropertyListener('community')
  setFilter() {
    this.tagFilter.communityId = this.community?.id;
  }

  async createBookingOption() {
    await toPromise(
      this.dialog
        .open(CreateOptionDialog, {
          data: {
            communityId: this.community.id,
            accountId: this.community.financeAccountId,
          },
        })
        .afterClosed()
    );
    this.refresh();
  }

  manageBookingTags() {
    this.dialog.open(ManageBookingTagsDialog, { data: { communityId: this.community.id } });
  }

  private refresh() {
    this.table?.refresh();
  }
}
