<div style="margin-bottom: 16px; display: flex; flex-direction: column; align-items: center">
  <mat-icon style="margin-bottom: 8px; font-size: 32px; width: 32px; height: 32px">contactless</mat-icon>
  <div style="font-weight: bold">Unable to complete payment on terminal</div>
  <div>{{ data.error.message }}</div>
</div>

<div style="display: grid; grid-template-columns: 1fr 1fr; gap: 8px">
  <button mat-stroked-button [disabled]="processing" (click)="clearTerminal()">
    <mat-icon *ngIf="processing === 'clear'" class="processing">loop</mat-icon>
    Clear terminal & try again
  </button>

  <button mat-stroked-button [disabled]="processing" (click)="close()">Cancel</button>
</div>
