import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { SaleCategory } from '@greco/sales-purchases';
import { SaleCategoryService } from '../../services';

@Component({
  templateUrl: './sale-category.dialog.html',
  styleUrls: ['./sale-category.dialog.scss'],
})
export class SaleCategoryDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) data: { accountId: string; saleCategory: SaleCategory },
    private dialogRef: MatDialogRef<SaleCategoryDialog>,
    private categorySvc: SaleCategoryService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {
    this.accountId = data.accountId;
    if (!this.accountId) this.close();
    if (data.saleCategory) {
      this.saleCategory = data.saleCategory;
      this.form.reset({ label: data.saleCategory.label, generalLedgerCode: data.saleCategory.generalLedgerCode });
      this.dialogData.title = 'Update Sale Category';
    } else {
      this.saleCategory = null;
    }
  }
  accountId: string;
  saleCategory: SaleCategory | null = null;
  dialogData: DialogData = {
    title: 'Create New Sale Category',
    //subtitle: 'Charge a custom amount to any user',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  form = this.formBuilder.group({
    label: ['', Validators.required],
    generalLedgerCode: [''],
  });

  processing = false;

  close(category?: SaleCategory | null) {
    this.dialogRef.close(category);
  }

  async submit() {
    this.processing = true;
    let category = this.saleCategory;
    try {
      if (!category) {
        category = await this.categorySvc.createSaleCategory({
          accountId: this.accountId,
          label: this.form.value.label,
          generalLedgerCode: this.form.value.generalLedgerCode || undefined,
        });
        this.snacks.open('Sale Category Created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      } else {
        category = await this.categorySvc.updateSaleCategory(category.id, {
          label: this.form.value.label,
          generalLedgerCode: this.form.value.generalLedgerCode || undefined,
        });
        this.snacks.open('Sale Category Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }

    // await new Promise(res => setTimeout(res, 3000));

    this.processing = false;
    this.close(category);
  }
}
