import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { UserService } from '@greco/ngx-identity-auth';
import { PropertyListener } from '@greco/property-listener-util';
import { Perk } from '@greco/sales-perks';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PerkService } from '../../services';

@Component({
  selector: 'greco-perk-picker',
  templateUrl: './perk-picker.component.html',
  styleUrls: ['./perk-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: PerkPickerComponent }],
})
export class PerkPickerComponent implements MatFormFieldControl<Perk>, ControlValueAccessor, OnDestroy {
  constructor(
    private _elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    private perkSvc: PerkService,
    private userSvc: UserService
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  private static _id = 1;
  private static _controlType = 'greco-perk-picker';

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  readonly stateChanges = new Subject<void>();

  @HostBinding() readonly id = `${PerkPickerComponent._controlType}-${PerkPickerComponent._id++}`;
  readonly controlType = PerkPickerComponent._controlType;

  @ViewChild(MatInput) private _input?: MatInput;

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string | null>(null);
  @Input() communityId?: string;

  readonly searchQuery$ = new BehaviorSubject<string>('');

  readonly perks$ = combineLatest([this._communityId$, this.searchQuery$, this.userSvc.user$]).pipe(
    switchMap(async ([communityId, searchQuery, user]) => {
      if (!communityId) return null;
      return await this.perkSvc.paginate(
        new RequestQueryBuilder().search({
          title: { $contL: searchQuery },
          ...(user?.isSuperAdmin ? {} : { 'community.id': communityId }),
        }),
        communityId
      );
    }),
    map(data => data?.items || [])
  );

  private _value: Perk | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: Perk | null) {
    this._value = value;
    this._onChange?.(value);
    this.stateChanges.next();
  }

  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input ? this._input.empty : true;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  errorState = false;
  autofilled = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]) {
    const el = this._elementRef.nativeElement.querySelector('.greco-perk-picker-container');
    if (el) el.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_event: MouseEvent): void {
    this._input?.onContainerClick?.();
  }

  writeValue(value: Perk | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  displayWith(value: Perk | null): string {
    return value?.title || '';
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value || null;
  }

  @PropertyListener('required')
  @PropertyListener('disabled')
  @PropertyListener('placeholder')
  private _stateChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.searchQuery$.complete();
    this._communityId$.complete();
  }
}
