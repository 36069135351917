import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TabButtonDirective, TabsComponent } from './components/tabs/tabs.component';

@NgModule({
  imports: [CommonModule, MatTabsModule, MatIconModule, MatButtonModule],
  declarations: [TabsComponent, TabButtonDirective],
  exports: [TabsComponent, TabButtonDirective],
})
export class TabsModule {}
