export enum AddonType {
  Shop = 'SHOP',
  Agreement = 'AGREEMENT',
  UserAvailability = 'USER AVAILABILITY',
  Upgrade = 'SELF-SERVE UPDATE',
  Inventory = 'INVENTORY',
}

export interface ProductAddon {
  id: string;

  created: Date;
  modified: Date;

  type: AddonType;

  enabled: boolean;

  productId: string;
}
