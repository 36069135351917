<div class="drop-down-button-container">
  <button [class.left]="showMenuButton" mat-flat-button color="primary" [disabled]="disabled" (click)="btnClick.emit($event)">
    <ng-content></ng-content>
  </button>
  <button class="right" mat-flat-button [matMenuTriggerFor]="menu" color="primary" [disabled]="disabled" *ngIf="showMenuButton">
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #menu>
    <ng-content select="[mat-menu-item]"></ng-content>
  </mat-menu>
</div>
