import { UpdateVideoTagDto } from '@greco/videos';
import { IsBoolean, IsOptional } from 'class-validator';

export class UpdateCommunityVideoTagDto extends UpdateVideoTagDto {
  @IsBoolean()
  @IsOptional()
  hidden?: boolean;

  @IsBoolean()
  @IsOptional()
  locked?: boolean;
}
