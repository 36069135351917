<div class="calendar-options-row">
  <mat-button-toggle-group [value]="view" (valueChange)="view = $event; scrollToCurrentView()" *ngIf="showViewToggle">
    <mat-button-toggle *ngIf="!hideMonthView" value="month">Month</mat-button-toggle>
    <mat-button-toggle *ngIf="!hideWeekView" value="week">Week</mat-button-toggle>
    <mat-button-toggle *ngIf="!hideDayView" value="day">Day</mat-button-toggle>
  </mat-button-toggle-group>

  <h3 style="margin: 0">{{ viewDate | calendarDate : view + 'ViewTitle' }}</h3>

  <div style="display: flex; align-items: center">
    <mat-slide-toggle
      *ngIf="showChildEventsToggle"
      color="primary"
      labelPosition="before"
      style="margin-right: 8px"
      [(ngModel)]="showChildEvents"
    >
      Show guest bookings
    </mat-slide-toggle>

    <mat-datepicker #picker startView="month"></mat-datepicker>

    <input
      [matDatepicker]="picker"
      (dateChange)="changeCustomDateView($event)"
      style="visibility: hidden; pointer-events: none; display: none"
    />

    <button color="primary" mat-stroked-button style="margin-right: 8px; min-width: 0" (click)="picker.open()">
      <mat-icon>event</mat-icon>
    </button>

    <button color="primary" mat-stroked-button style="margin-right: 8px" mwlCalendarToday [(viewDate)]="viewDate">
      Today
    </button>

    <button
      color="primary"
      mat-stroked-button
      mwlCalendarPreviousView
      [(viewDate)]="viewDate"
      [view]="view"
      style="padding: 0 8px; min-width: 0; border-bottom-right-radius: 0; border-top-right-radius: 0"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>

    <button
      color="primary"
      mat-stroked-button
      mwlCalendarNextView
      [(viewDate)]="viewDate"
      [view]="view"
      style="padding: 0 8px; min-width: 0; border-bottom-left-radius: 0; border-top-left-radius: 0; border-left: none"
    >
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>

<!-- List of Collapses Events for a given Day -->
<ng-template #openDayTemplate let-events="events" let-isOpen="isOpen" let-eventClicked="eventClicked">
  <div [@heightExpansion]="isOpen ? 'expanded' : 'collapsed'">
    <greco-table
      [data]="events || []"
      [highlight]="true"
      [hideEmptyPlaceholder]="true"
      (rowClick)="eventClicked.emit({ event: $event })"
    >
      <mat-icon *grecoTableCol="''; let event; fitContent: true" [matTooltip]="event.meta.id">event</mat-icon>

      <p *grecoTableCol="'Event'; let event">{{ event.title }}</p>

      <p *grecoTableCol="'Time'; let event">
        {{ event.start | date : 'shortTime' }} - {{ event.end | date : 'shortTime' }}
      </p>
    </greco-table>
  </div>
</ng-template>

<!-- Event Template in Week View-->
<ng-template #weekEventTemplate let-weekEvent="weekEvent" let-eventClicked="eventClicked">
  <div (click)="eventClicked.emit({ event: weekEvent })">
    {{ weekEvent.title }}
  </div>
</ng-template>

<ng-container [ngSwitch]="view">
  <ng-container *ngSwitchCase="'month'">
    <mwl-calendar-month-view
      *ngIf="!hideMonthView"
      [events]="events"
      [viewDate]="viewDate"
      [activeDayIsOpen]="activeDayIsOpen"
      (eventClicked)="eventClicked.emit($event.event)"
      (dayClicked)="dayClicked($event.day)"
      [cellTemplate]="$any(dayCellTemplate)"
      [openDayEventsTemplate]="eventListTemplate || openDayTemplate"
    ></mwl-calendar-month-view>
  </ng-container>

  <ng-container *ngSwitchCase="'week'" style="top: 400px">
    <mwl-calendar-week-view
      *ngIf="!hideWeekView"
      [events]="events"
      [hourSegments]="4"
      [viewDate]="viewDate"
      [dayEndHour]="dayEndHour"
      [dayStartHour]="dayStartHour"
      [eventTemplate]="$any(eventCardTemplate)"
      [refresh]="refreshCalendarEmitter"
      (eventClicked)="eventClicked.emit($event.event)"
      (eventTimesChanged)="eventTimesChanged.emit($event)"
      (hourSegmentClicked)="dateClicked.emit([$event.date, undefined])"
    ></mwl-calendar-week-view>
  </ng-container>

  <ng-container *ngSwitchCase="'day'">
    <ng-container *ngIf="!hideDayView">
      <mwl-calendar-day-view
        *ngIf="!dayViewGroupBy"
        [events]="events"
        [hourSegments]="4"
        [viewDate]="viewDate"
        [dayEndHour]="dayEndHour"
        [dayStartHour]="dayStartHour"
        [eventTemplate]="$any(eventCardTemplate)"
        (eventClicked)="eventClicked.emit($event.event)"
        [refresh]="refreshCalendarEmitter"
        (eventTimesChanged)="eventTimesChanged.emit($event)"
        (hourSegmentClicked)="dateClicked.emit([$event.date, undefined])"
      ></mwl-calendar-day-view>

      <greco-grouped-day-view
        *ngIf="dayViewGroupBy"
        [events]="events"
        [hourSegments]="4"
        [viewDate]="viewDate"
        [dayEndHour]="dayEndHour"
        [sortGroups]="sortGroups"
        [groupBy]="dayViewGroupBy"
        [dayStartHour]="dayStartHour"
        [eventTemplate]="$any(eventCardTemplate)"
        (eventClicked)="eventClicked.emit($event.event)"
        [refresh]="refreshCalendarEmitter"
        (eventTimesChanged)="eventTimesChanged.emit($event)"
        (hourSegmentClicked)="dateClicked.emit([$event.date, $event.group?.metadata])"
      ></greco-grouped-day-view>
    </ng-container>
  </ng-container>
</ng-container>
