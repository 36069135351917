<greco-simple-dialog [data]="simpleDialogData">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field *ngIf="!data.filter.hideCond && data.filter.type !== 'toggle'">
      <mat-label>Operator</mat-label>
      <mat-select required formControlName="operator">
        <mat-select-trigger>
          <div style="padding-right: 16px" [innerHTML]="form.value.operator| condOperator | safe: 'html'"></div>
        </mat-select-trigger>

        <mat-option *ngFor="let operator of data.filter.allowedOperators" [value]="operator">
          <div [innerHTML]="operator | condOperator | safe: 'html'"></div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container [ngSwitch]="data.filter.type">
      <!-- Text Filter -->
      <mat-form-field *ngSwitchCase="'text'">
        <mat-label>Value</mat-label>
        <input required matInput formControlName="value" />
      </mat-form-field>

      <!-- Select Filter -->
      <mat-form-field *ngSwitchCase="'select'">
        <mat-label>Value</mat-label>
        <mat-select multiple formControlName="value" [compareWith]="$any(data.filter).compareWith">
          <mat-option *ngFor="let option of data.filter | filterValueOptions: '' | async" [value]="option">
            <div class="picker" style="display: flex; align-items: center">
              <div
                *ngIf="$any(data.filter) | filterImageUrl: option as imageUrl"
                style="width: 24px; height: 24px; margin-right: 8px"
              >
                <greco-smart-img
                  round
                  border
                  borderColor="primary"
                  style="width: 24px; height: 24px"
                  [src]="imageUrl"
                ></greco-smart-img>
              </div>

              <div style="overflow: hidden; text-overflow: ellipsis">{{ data.filter | filterValueLabel: option }}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Toggle Filter -->
      <mat-slide-toggle *ngSwitchCase="'toggle'" formControlName="value" color="primary">
        {{ data.filter.label }}
      </mat-slide-toggle>

      <!-- Date Filter -->
      <ng-container *ngSwitchCase="'date'">
        <mat-form-field *ngIf="form.value.operator !== '$between'">
          <mat-label>Value</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="value" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <greco-date-range-picker
          *ngIf="form.value.operator === '$between'"
          [required]="true"
          formControlName="value"
        ></greco-date-range-picker>
      </ng-container>

      <!-- Autocomplete Filter -->
      <mat-form-field *ngSwitchCase="'autocomplete'">
        <mat-label>Value</mat-label>
        <input #autocompleteInput matInput required formControlName="value" [matAutocomplete]="autocomplete" />

        <mat-autocomplete #autocomplete [displayWith]="data.filter.getValueLabel">
          <mat-option
            *ngFor="let option of data.filter | filterValueOptions: autocompleteInput.value | async"
            [value]="option"
          >
            <div class="picker" style="display: flex; align-items: center">
              <div
                *ngIf="$any(data.filter) | filterImageUrl: option as imageUrl"
                style="width: 24px; height: 24px; margin-right: 8px"
              >
                <greco-smart-img
                  round
                  border
                  borderColor="primary"
                  style="width: 24px; height: 24px"
                  [src]="imageUrl"
                ></greco-smart-img>
              </div>

              <div
                style="overflow: hidden; text-overflow: ellipsis"
                [innerHTML]="$any(data.filter) | filterAutocompleteHtml: option | safe: 'html'"
              ></div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Multi Autocomplete Filter -->
      <mat-form-field *ngSwitchCase="'multiautocomplete'" style="width: 350px" [floatLabel]="'always'">
        <mat-label>Value</mat-label>

        <mat-chip-list>
          <mat-chip
            *ngFor="let option of form.value.value"
            style="max-height: 40px; height: auto"
            (removed)="selectOption(option)"
          >
            <div class="picker" style="display: flex; align-items: center">
              <div
                *ngIf="$any(data.filter) | filterImageUrl: option as imageUrl"
                style="width: 24px; height: 24px; margin-right: 8px"
              >
                <greco-smart-img
                  round
                  border
                  borderColor="primary"
                  style="width: 24px; height: 24px"
                  [src]="imageUrl"
                ></greco-smart-img>
              </div>

              <div
                style="overflow: hidden; text-overflow: ellipsis"
                [innerHTML]="$any(data.filter) | filterAutocompleteHtml: option | safe: 'html'"
              ></div>
            </div>

            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>

        <input #multiAutocompleteInput matInput required [matAutocomplete]="autocomplete" />

        <mat-autocomplete #autocomplete [displayWith]="data.filter.getValueLabel">
          <mat-option
            *ngFor="let option of data.filter | filterValueOptions: multiAutocompleteInput.value | async"
            [value]="option"
            (click)="selectOption(option); multiAutocompleteInput.value = ''"
          >
            <div class="picker" style="display: flex; align-items: center">
              <div
                *ngIf="$any(data.filter) | filterImageUrl: option as imageUrl"
                style="width: 24px; height: 24px; margin-right: 8px"
              >
                <greco-smart-img
                  round
                  border
                  borderColor="primary"
                  style="width: 24px; height: 24px"
                  [src]="imageUrl"
                ></greco-smart-img>
              </div>

              <div
                style="overflow: hidden; text-overflow: ellipsis"
                [innerHTML]="$any(data.filter) | filterAutocompleteHtml: option | safe: 'html'"
              ></div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <div style="margin-top: 16px; display: flex; align-items: center; gap: 8px">
      <button style="flex: 1" mat-stroked-button type="button" (click)="cancel()">Cancel</button>
      <button style="flex: 1" mat-stroked-button type="submit" [disabled]="form.invalid">Confirm</button>
    </div>
  </form>
</greco-simple-dialog>
