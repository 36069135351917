import { Product, ProductVariant } from '@greco/sales-products';
import {
  DynamicFormControlLayout,
  DynamicFormValueControlModel,
  DynamicFormValueControlModelConfig,
  serializable,
} from '@ng-dynamic-forms/core';

export const DYNAMIC_FORM_CONTROL_TYPE_PRODUCT_VARIANT_PICKER = 'product-variant-picker';

export interface DynamicProductVariantPickerModelConfig
  extends DynamicFormValueControlModelConfig<ProductVariant | ProductVariant[] | Product | Product[]> {
  communityId: string;
  onlyProducts?: boolean;
  multiselect?: boolean;
  showAllVariants?: boolean;
  onlyRecurring?: boolean;
  productIdsToExclude?: string[];
  variantIdsToExclude?: string[];
}

export class DynamicProductVariantPickerModel extends DynamicFormValueControlModel<
  ProductVariant | ProductVariant[] | Product | Product[]
> {
  @serializable() readonly type = DYNAMIC_FORM_CONTROL_TYPE_PRODUCT_VARIANT_PICKER;

  @serializable() communityId!: string;
  @serializable() placeholder!: string;
  @serializable() onlyProducts?: boolean;
  @serializable() multiselect?: boolean;
  @serializable() showAllVariants?: boolean;
  @serializable() onlyRecurring?: boolean;
  @serializable() productIdsToExclude?: string[];
  @serializable() variantIdsToExclude?: string[];

  constructor(config: DynamicProductVariantPickerModelConfig, layout?: DynamicFormControlLayout) {
    super(config, layout);
    this.communityId = config.communityId;
    this.onlyProducts = config.onlyProducts || false;
    this.multiselect = config.multiselect || false;
    this.showAllVariants = config.showAllVariants || false;
    this.onlyRecurring = config.onlyRecurring || false;
    this.productIdsToExclude = config.productIdsToExclude || [];
    this.variantIdsToExclude = config.variantIdsToExclude || [];
  }

  getAdditional(key: string, defaultValue?: any) {
    if (key === 'isFormFieldControl') return true;
    else return super.getAdditional(key, defaultValue);
  }
}
