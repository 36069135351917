import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PropertyListener } from '@greco/property-listener-util';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { UserBalanceService } from '../../services';

@Component({
  selector: 'greco-balance-history-section',
  templateUrl: './balance-history-section.component.html',
  styleUrls: ['./balance-history-section.component.scss'],
})
export class BalanceHistorySectionComponent implements OnDestroy {
  constructor(private balanceSvc: UserBalanceService) {}

  @Input() userId?: string;
  @PropertyListener('userId') private userId$ = new BehaviorSubject<string | undefined>(undefined);

  accountSelect = new FormControl(null);
  private storedAccount = '';
  pageIndex = 1;

  _loading = true;

  private selectedAccountId$ = this.accountSelect.valueChanges.pipe(
    tap(accountId => this.selectedAccountIdChange.emit(accountId)),
    startWith(this.accountSelect.value)
  );
  @Output()
  selectedAccountIdChange = new EventEmitter<string>();

  _meta?: IPaginationMeta;
  _pagination$ = new BehaviorSubject(1);

  _balanceHistory$ = combineLatest([this.userId$, this.selectedAccountId$, this._pagination$]).pipe(
    tap(() => (this._loading = true)),

    switchMap(async ([userId, accountId, pagination]) => {
      if (accountId != this.storedAccount) {
        this.pageIndex = 0;
        pagination = this.pageIndex;
        this.storedAccount = accountId;
      } else this.pageIndex = pagination - 1;

      return userId && accountId
        ? await this.balanceSvc.getUserBalanceHistory(accountId, userId, { limit: 5, page: pagination })
        : null;
    }),
    tap(data => (this._meta = data?.meta)),
    map(data => data?.items || []),

    tap(() => (this._loading = false))
  );

  _userBalances$ = this.userId$.pipe(
    switchMap(async userId => (userId ? await this.balanceSvc.getUserBalance(userId) : [])),
    tap(balances => this.accountSelect.setValue(balances[0]?.accountId || null)),
    shareReplay(1)
  );

  _currentAccountBalance$ = this.selectedAccountId$.pipe(
    switchMap(accountId =>
      this._userBalances$.pipe(
        map(balances => balances.find(balance => accountId === balance.accountId)),
        map(balance => balance?.amount || 0)
      )
    )
  );

  refresh() {
    this.userId$.next(this.userId);
  }

  ngOnDestroy(): void {
    this.userId$.complete();
    this._pagination$.complete();
  }
}
