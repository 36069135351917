import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Perk, UserPerk } from '@greco/sales-perks';
import moment from 'moment';
import { PerkService } from '../../services';

@Component({
  selector: 'greco-remove-single-perk-dialog',
  templateUrl: './remove-single-perk.dialog.html',
  styleUrls: ['./remove-single-perk.dialog.scss'],
})
export class RemoveSinglePerkDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { userId: string; userName: string; perk: Perk; expiry?: string; reusable?: boolean },
    private dialogRef: MatDialogRef<RemoveSinglePerkDialog>,
    private perkSvc: PerkService
  ) {}

  submitting = false;

  userPerks: UserPerk[] = [];

  async ngOnInit() {
    const userPerks = (await this.perkSvc.getUserPerks(this.data.userId)).filter(up => {
      if (up.perk.id !== this.data.perk.id) return false;
      if (typeof this.data.reusable === 'boolean' && up.consumable !== !this.data.reusable) return false;
      if (
        this.data.expiry &&
        typeof this.data.expiry === 'string' &&
        this.data.expiry !== moment(up.expiryDate).fromNow()
      ) {
        return false;
      }
      return true;
    });

    if (!userPerks.length) return this.dialogRef.close();

    this.userPerks = userPerks.sort((a, b) => {
      if (!a.expiryDate !== !b.expiryDate) return a.expiryDate ? -1 : 1;
      return (a.expiryDate?.getTime() || 0) - (b.expiryDate?.getTime() || 0);
    });
  }

  close() {
    this.dialogRef.close();
  }

  async submit() {
    this.submitting = true;

    try {
      const numberOfperksToRemove = 1;
      const perksToRemove = this.userPerks.slice(0, numberOfperksToRemove).map(userPerk => {
        return userPerk.id;
      });

      await this.perkSvc.removePerks(this.data.perk.id, this.data.userId, perksToRemove);

      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
    }

    this.submitting = false;
  }
}
