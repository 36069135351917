<ng-container *grecoLet="canRead$ | async as canRead">
  <div *ngIf="canRead">
    <ng-container *grecoLet="canUpdate$ | async as canUpdate">
      <ng-container *grecoLet="canArchive$ | async as canArchive">
        <greco-collapsible-section
          [expanded]="true"
          [disabled]="true"
          [header]="{ title: 'Details', icon: 'info' }"
          class="role-details"
        >
          <greco-form-save-buttons
            header
            [form]="formGroup"
            [saveAction]="onSave"
            [resetValue]="initialValue"
          ></greco-form-save-buttons>

          <div header style="margin-left: 16px" *ngIf="canRead">
            <button
              mat-icon-button
              style="width: 32px; height: 32px; line-height: 32px"
              [matMenuTriggerFor]="menu"
              [disabled]="agreement.archivedDate != null"
            >
              <mat-icon style="font-size: 22px">more_vert</mat-icon>
            </button>

            <mat-menu #menu>
              <button mat-menu-item (click)="archiveAgreement()" [disabled]="!canArchive">
                <mat-icon>archive</mat-icon>
                <span>Archive Agreement</span>
              </button>
            </mat-menu>
          </div>

          <form [formGroup]="formGroup">
            <mat-form-field class="no-hint">
              <mat-label>Title</mat-label>
              <input
                matInput
                required
                type="text"
                [readonly]="saving || !canUpdate"
                formControlName="title"
                placeholder="Write a descriptive agreement title."
              />
            </mat-form-field>

            <greco-editor
              [class.dirty]="formGroup.dirty"
              [data]="{ value: formGroup.get('text')?.value || '' }"
              (valueChanged)="valueChanged($event.value)"
              aria-placeholder="Write meaningful agreement description..."
              required
              style="border: 0.5px solid lightgray; margin: 5px 0px 25px 0px"
              [customDisabled]="agreement.archivedDate != null || !canUpdate"
              formControlName="text"
            >
            </greco-editor>
            <!-- </div> -->

            <mat-form-field>
              <mat-label>Agreement Type</mat-label>
              <mat-select
                #select
                required
                style="margin: 2px"
                [value]="selectedValue"
                formControlName="agreementType"
                (selectionChange)="selectorValueChange($event.value)"
                [disabled]="!canUpdate"
              >
                <mat-select-trigger>
                  <span [ngSwitch]="select.value">
                    <div *ngSwitchCase="'CHECKBOX'">
                      <mat-icon>check_box_outline_blank</mat-icon> <span>Checkbox</span>
                    </div>
                    <div *ngSwitchCase="'AUTO_CHECKBOX'"><mat-icon>check_box</mat-icon> <span>Auto Checkbox</span></div>
                    <div *ngSwitchCase="'DIGITAL_SIGNATURE'"><mat-icon>edit</mat-icon> <span>Signature</span></div>
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let type of agreementTypeArray" [value]="type" [disabled]="!canUpdate">
                  <span [ngSwitch]="type">
                    <span *ngSwitchCase="'CHECKBOX'">
                      <mat-icon>check_box_outline_blank</mat-icon> <span>Checkbox</span>
                    </span>
                    <span *ngSwitchCase="'AUTO_CHECKBOX'">
                      <mat-icon>check_box</mat-icon> <span>Auto Checkbox</span>
                    </span>
                    <span *ngSwitchCase="'DIGITAL_SIGNATURE'"> <mat-icon>edit</mat-icon> <span>Signature</span> </span>
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="no-hint" *ngIf="agreement.archivedDate != null">
              <mat-label>Archived Date</mat-label>
              <input
                matInput
                required
                type="text"
                [readonly]="saving || !canUpdate"
                formControlName="archivedDate"
                placeholder="Displays the date this agreement archived."
                [readonly]="true"
              />
            </mat-form-field>
          </form>
        </greco-collapsible-section>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
