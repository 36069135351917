import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import {
  AccountLink,
  CreateChildAccountDto,
  RequestAccountLinkDto,
  UpdateAccountLinkDto,
} from '@greco/account-linking';
import { User } from '@greco/identity-users';
import { CreateUserDto } from '@greco/nestjs-identity-users-util';

@Injectable({ providedIn: 'root' })
export class AccountLinkingService {
  baseUrl = '/api/account-linking';
  constructor(private http: HttpClient) {}

  // @Get(':id')
  async getOne(id: string) {
    return await toPromise(this.http.get(`${this.baseUrl}/${id}`));
  }

  // @Get('accessor/:accessorId')
  async getPrivilegeLinksForAccessor(accessorId: string) {
    return await toPromise(this.http.get<AccountLink[]>(`${this.baseUrl}/accessor/${accessorId}`));
  }

  // @Get('account/:accountId')
  async getGivenLinksByAccount(accountId: string) {
    return await toPromise(this.http.get<AccountLink[]>(`${this.baseUrl}/account/${accountId}`));
  }

  // @Get('child')
  async findChildAccounts(email: string) {
    return await toPromise(this.http.get<User[]>(`${this.baseUrl}/child`, { params: { email } }));
  }

  // @Get('child/:accessorId')
  async getChildAccounts(accessorId: string) {
    return await toPromise(this.http.get<User[]>(`${this.baseUrl}/child/${accessorId}`));
  }

  // @Get('link')
  async getLink(accessorId: string, accountId: string) {
    return await toPromise(this.http.get<AccountLink>(`${this.baseUrl}/link`, { params: { accessorId, accountId } }));
  }
  // @Post('guest/:userId')
  async addGuest(userId: string, dto: CreateUserDto) {
    return await toPromise(this.http.post<AccountLink>(`${this.baseUrl}/guest/${userId}`, dto));
  }
  // @Post('request')
  async requestAccess(dto: RequestAccountLinkDto) {
    return await toPromise(this.http.post<AccountLink>(`${this.baseUrl}/request`, dto));
  }
  // @Post('create')
  async createLink(dto: RequestAccountLinkDto) {
    return await toPromise(this.http.post<AccountLink>(`${this.baseUrl}/create`, dto));
  }
  // @Post('create/:userId')
  async createChildAccount(userId: string, dto: CreateChildAccountDto) {
    return await toPromise(this.http.post(`${this.baseUrl}/create/${userId}`, dto));
  }
  // @Post('create/:userId/link')
  async linkChildAccount(userId: string, childId: string) {
    return await toPromise(this.http.post(`${this.baseUrl}/create/${userId}/link`, { childId }));
  }

  // @Patch('update')
  async updateLink(dto: UpdateAccountLinkDto) {
    return await toPromise(this.http.patch(`${this.baseUrl}/update`, dto));
  }

  // @Delete('revoke/:linkId')
  async revokeAccess(linkId: string) {
    return await toPromise(this.http.delete(`${this.baseUrl}/revoke/${linkId}`));
  }

  // @Patch('accept/:linkId')
  async accept(linkId: string) {
    return await toPromise(this.http.patch(`${this.baseUrl}/accept/${linkId}`, {}));
  }
}
