import { Injectable } from '@angular/core';
import { ToggleFilter } from '@greco/ngx-filters';

@Injectable({ providedIn: 'any' })
export class ShowProcessingFilter extends ToggleFilter {
  constructor() {
    super('ShowProcessingFilter', {
      label: 'Show still in process',
      description: 'Show in process',
      shortLabel: 'Processing',
      properties: ['lockedForProcessing'],
    });
  }

  getValueLabel(value: any): string {
    return value ? 'Show processing' : 'Show all';
  }

  getValueOptions(search?: string): any[] | Promise<any[]> {
    if (search === 'processing') {
      return [true];
    } else if (search?.toLowerCase() === 'all') {
      return [false];
    } else {
      return [];
    }
  }

  serializeValue(value: any): string {
    return value;
  }

  deserializeValue(serializedValue: string) {
    return new Boolean(serializedValue);
  }
}
