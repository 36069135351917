import { BaseDto } from '@greco/util-dto';
import { Expose, Type } from 'class-transformer';
import { IsDate, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class UnlockVideoDto extends BaseDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  userId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  videoId: string;

  @Expose()
  @IsDate()
  @IsOptional()
  @Type(() => Date)
  expiresOn?: Date;
}
