import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Account } from '@greco/finance-accounts';
import type {
  AccountFeeConfigurationDto,
  StripeAccountFeeConfigurationEntity,
} from '@greco/nestjs-stripe-payment-gateway';
import { PropertyListener } from '@greco/property-listener-util';
import { CurrencyMaskConfig } from 'ngx-currency';
import { StripeFeesService } from '../../services';

enum StripeFeeEventType {
  charge_captured = 'charge_captured',
  charge_authorized = 'charge_authorized',
  charge_failed = 'charge_failed',
  refund = 'refund',
  interac_refund = 'interac_refund',
  setup_intent = 'setup_intent',
  authorization_expiry = 'authorization_expiry',
  setup_intent_failed = 'setup_intent_failed',
}

const eventTypeDescriptors: Record<StripeFeeEventType, { title: string; description: string }> = {
  [StripeFeeEventType.charge_captured]: { title: 'Charge Captured', description: '' },
  [StripeFeeEventType.charge_authorized]: { title: 'Charge Authorized', description: '' },
  [StripeFeeEventType.charge_failed]: { title: 'Charge Failed', description: '' },
  [StripeFeeEventType.refund]: { title: 'Refund', description: '' },
  [StripeFeeEventType.interac_refund]: { title: 'Interac Refund', description: '' },
  [StripeFeeEventType.setup_intent]: { title: 'Setup Intent', description: '' },
  [StripeFeeEventType.authorization_expiry]: { title: 'Authorization Expiry', description: '' },
  [StripeFeeEventType.setup_intent_failed]: { title: 'Setup Intent Failed', description: '' },
};

interface FeeConfigTableData {
  eventType: StripeFeeEventType;

  title: string;
  description: string;

  formControl: AbstractControl;
  initialValue: any;
}

@Component({
  selector: 'greco-stripe-account-fee-configuration',
  templateUrl: './fee-configuration.component.html',
  styleUrls: ['./fee-configuration.component.scss'],
})
export class StripeAccountFeeConfigurationComponent {
  @Input() account?: Account;

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  readonly form = this.formBuilder.group({ _array: this.formBuilder.array([]) });
  private get arrayControl() {
    return this.form.get('_array') as FormArray;
  }

  configsTableData: FeeConfigTableData[] = [];
  initialValue: any[] = [];

  loading = true;
  saving = false;

  constructor(private feeSvc: StripeFeesService, private formBuilder: FormBuilder, private snacks: MatSnackBar) {}

  @PropertyListener('account')
  async refresh() {
    const configs = this.account ? await this.feeSvc.getAccountFeeConfigs(this.account.id) : null;
    this._loadData(configs);
  }

  reset() {
    this.form.reset({ _array: this.configsTableData.map(data => data.initialValue) });
  }

  async _save() {
    this.saving = true;

    try {
      const accountId = this.account?.id;
      if (!accountId) return;

      if (this.arrayControl.invalid) {
        return this.arrayControl.controls.forEach(control => {
          if (control.invalid) control.get('percentage')?.markAsTouched();
        });
      }

      const dtos: AccountFeeConfigurationDto[] = this.configsTableData.reduce((acc, data) => {
        const value = data.formControl.value;

        if (value.feeType !== 'none') {
          acc.push({
            type: value.feeType,
            eventType: data.eventType,
            minStripe: value.stripeMinimum,
            percentage: Number(value.percentage || 0),
            flatFee: Math.round(Number(value.amount || 0) * 100),
          });
        }

        return acc;
      }, [] as AccountFeeConfigurationDto[]);

      const configs = await this.feeSvc.updateAccountFees(accountId, dtos);
      this._loadData(configs);

      this.snacks.open('Changes saved!', 'Ok', { duration: 6000, panelClass: 'mat-primary' });
    } catch (err) {
      console.error(err);
      this.snacks.open('Something went wront, please try again', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
    } finally {
      this.saving = false;
    }
  }

  async _runProcessingFeesReconciliation() {
    await this.feeSvc.runProcessingFeesReconciliation();
  }

  _errorState(control: AbstractControl): boolean {
    return (
      !!control.errors?._validator &&
      (!!control.get('percentage')?.touched ||
        !!control.get('amount')?.touched ||
        !!control.get('stripeMinimum')?.touched)
    );
  }

  private _loadData(configs: null | StripeAccountFeeConfigurationEntity[]) {
    this.loading = true;

    this.configsTableData = [];
    this.initialValue = [];

    this.arrayControl.clear();
    this.form.reset({ _array: [] });

    if (configs) {
      const eventTypes = Object.values(StripeFeeEventType);
      for (let i = 0; i < eventTypes.length; i++) {
        const config = configs.find(c => c.eventType === eventTypes[i]);
        const formControl = this.getFormControl(config);

        const data: FeeConfigTableData = {
          ...eventTypeDescriptors[eventTypes[i]],
          initialValue: { ...formControl.value },
          eventType: eventTypes[i],
          formControl,
        };

        this.configsTableData = [...this.configsTableData, data];
        this.initialValue = [...this.initialValue, data.initialValue];

        this.arrayControl.insert(i, data.formControl);
      }
    }

    this.loading = false;
  }

  private getFormControl(config?: StripeAccountFeeConfigurationEntity) {
    return this.formBuilder.group(
      {
        feeType: [config?.type ?? 'none', Validators.required],
        percentage: [config?.percentage ?? null, [Validators.min(0), Validators.max(100)]],
        amount: [typeof config?.flatFee === 'number' ? config.flatFee / 100 : null, [Validators.min(0)]],
        stripeMinimum: [config?.minStripe ?? false, Validators.required],
      },
      {
        validators: _validator(),
      }
    );
  }
}

function _validator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const feeType = control.get('feeType')?.value ?? 'none';
    const percentage = control.get('percentage')?.value ?? null;
    const amount = control.get('amount')?.value ?? null;

    if (feeType === 'none' || feeType === 'overwrite' || percentage || amount) return null;
    return { _validator: { value: control.value } };
  };
}
