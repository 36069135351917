import { CreateVideoTagDto } from '@greco/videos';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class CreateCommunityVideoTagDto extends CreateVideoTagDto {
  @IsString()
  @IsNotEmpty()
  communityId: string;

  @IsBoolean()
  hidden: boolean;

  @IsBoolean()
  locked: boolean;

  @IsString()
  @IsOptional()
  id?: string;
}
