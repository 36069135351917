<greco-item [lines]="false">
  <mat-icon item-start>payment</mat-icon>

  <div style="width: 100%">
    <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0">
      <strong>Add a Payment Method</strong>
    </p>

    <p
      matTooltip="Your payment method will never be charged without your authorization."
      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 0"
    >
      <small>Your payment method will never be charged without your authorization.</small>
    </p>
  </div>

  <div
    item-end
    style="display: flex; align-items: center; justify-content: center"
    *grecoLet="hasPaymentMethods$ | async as has"
  >
    <greco-add-payment-method-button
      *ngIf="has === false"
      [userId]="user.id"
      [hideIcon]="true"
      [hiddenForms]="['Bank Account']"
      style="flex: 1"
      (saved)="_refresh()"
    ></greco-add-payment-method-button>

    <mat-icon *ngIf="has" style="color: var(--success-color)">check_circle</mat-icon>
  </div>
</greco-item>
