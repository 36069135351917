<div style="margin: 16px" class="testimonial-carousel">
  <swiper
    class="testimonialContainer"
    [spaceBetween]="10"
    [slidesPerView]="2"
    [centeredSlides]="true"
    [freeMode]="true"
    [loop]="true"
    [autoplay]="{
      delay: 2500,
      disableOnInteraction: false
    }"
    [pagination]="false"
    [navigation]="false"
  >
    <!-- use testimonial card component here -->
    <ng-template *ngFor="let testimonial of testimonials" swiperSlide>
      <greco-testimonial-card
        [title]="testimonial.title"
        [tags]="testimonial.tags"
        [image]="testimonial.image"
      ></greco-testimonial-card>
    </ng-template>
  </swiper>
</div>
