import { Injectable } from '@angular/core';
import { BuildDateFilter } from '@greco/ngx-filters';

@Injectable({ providedIn: 'any' })
export class CategoryArchivedDateFilter extends BuildDateFilter('CategoryArchivedDateFilter', {
  label: 'Archived Date',
  shortLabel: 'Archived',
  description: '',
  properties: ['archivedOn'],
}) {}
