import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-users';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { PurchaseService } from '../../services';

@Component({
  selector: 'greco-bulk-retry-purchase-dialog',
  templateUrl: './bulk-retry.dialog.html',
  styleUrls: ['./bulk-retry.dialog.scss'],
})
export class BulkRetryPurchaseDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      retryTotal: number;
      pageTotal: number;
      queryBuilder: RequestQueryBuilder;
      userIds?: string[];
      filters?: {
        accountId?: string;
        userId?: string;
        saleCategoryIds?: string[];

        variantIds?: string[];
      };
      showUncategorized?: boolean;
    },
    private dialogRef: MatDialogRef<BulkRetryPurchaseDialog>,
    private purchaseSvc: PurchaseService,
    private userSvc: UserService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar
  ) {}

  readonly dialogData: DialogData = {
    title: 'Bulk Retry Failed Purchases',
    hideDefaultButton: true,
    showCloseButton: false,
  };

  formGroup = this.formBuilder.group({
    updatePaymentMethod: [false],
  });

  processing = false;

  users: User[] = [];

  async ngOnInit() {
    this.users = [];
    if (!this.data.userIds?.length) {
      return;
    }

    for (const userId of this.data.userIds) {
      this.formGroup.addControl(userId, this.formBuilder.control(null, [Validators.required]));
      const user = await this.userSvc.getUser(userId);
      this.users.push(user);
    }
  }

  close(result?: any) {
    this.dialogRef.close(result);
  }

  async submit() {
    this.processing = true;
    let paymentMethods: Record<string, string> | undefined = undefined;
    if (this.data.userIds?.length && this.formGroup.value.updatePaymentMethod) {
      paymentMethods = {};

      for (const userId of this.data.userIds) {
        paymentMethods[userId] = this.formGroup.get(userId)?.value?.id;
      }
    }

    try {
      const response = await this.purchaseSvc.bulkRetry(
        this.data.queryBuilder,
        this.data.filters,
        this.data.showUncategorized,
        paymentMethods
      );

      if (response?.errors.length) {
        this.snacks.open(`${response.errors.length} purchases encountered errors while retrying.`, 'Ok', {
          duration: 5000,
          panelClass: 'mat-warn',
        });
      } else {
        this.snacks.open(`Bulk retry executed, email notification pending.`, 'Ok', {
          duration: 3000,
          panelClass: 'mat-primary',
        });
      }
    } catch (err) {
      console.error(err);
    }

    this.processing = false;
    this.close({
      submit: true,
    });
  }
}
