import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { SignInComponent } from '@greco/ngx-identity-auth';

@Component({
  selector: 'greco-locked-videos-page',
  templateUrl: './locked-videos.page.html',
  styleUrls: ['./locked-videos.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LockedVideosPage {
  constructor(private matDialog: MatDialog) {}

  @Input() user?: User | null;

  /*
    TODO:
    1. Query user event video unlock options
    2. Query event videos based on date range + unlock options
  */

  async signIn() {
    const _dialog = this.matDialog.open(SignInComponent, { data: {}, width: '100%', maxWidth: '400px' });
    await toPromise(_dialog.afterClosed());
  }
}
