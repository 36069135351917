import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'greeting' })
export class GreetingPipe implements PipeTransform {
  transform(time: Date | string): string {
    const m = moment(time);
    if (m.get('hour') <= 11) return 'Good morning';
    else if (m.get('hour') >= 12 && m.get('hour') <= 16) return 'Good afternoon';
    else return 'Good evening';
  }
}
