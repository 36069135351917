<ng-container *grecoLet="canViewBookings$ | async as canViewBookings">
  <ng-container *grecoLet="hasAttendees$ | async as hasAttendees">
    <!-- Attendees -->
    <ng-container *ngIf="canViewBookings">
      <greco-collapsible-section
        *ngIf="attendeeFilters$ | async as attendeeFilters"
        [header]="{ title:'Attendees', icon: 'people' }"
        [style.display]="hasAttendees ? 'block' : 'none'"
        id="attendees-section"
      >
        <ng-container header>
          <mat-chip *ngIf="attendeesTable.metadata">
            {{ attendeesTable.metadata.totalItems }} / {{ series.maxCapacity }}
          </mat-chip>
          <button
            *ngIf="(attendeesTable.metadata?.totalItems || 0) < series.maxCapacity"
            mat-stroked-button
            color="primary"
            style="height: 32px; line-height: 16; margin-left: 16px"
            (click)="$event.stopImmediatePropagation(); addAttendee()"
          >
            <span>Add Attendee</span>
          </button>
        </ng-container>

        <greco-course-bookings-table
          #attendeesTable
          [event]="series"
          [showSpotNumber]="true"
          [communityId]="series.community?.id || ''"
          [filters]="attendeeFilters"
          [hideEvent]="true"
          [hideDate]="true"
        ></greco-course-bookings-table>
      </greco-collapsible-section>
    </ng-container>

    <!-- Details -->
    <ng-container *grecoLet="canUpdateSeries$ | async as canUpdateSeries">
      <greco-event-details
        [event]="series"
        [forSeries]="true"
        [readonly]="!canUpdateSeries"
        (eventUpdated)="refreshed.emit()"
      >
        <button
          *ngIf="!hasAttendees && (canCreateBooking$ | async) && series.availableAsCourse"
          header
          mat-stroked-button
          style="height: 32px; line-height: 16"
          (click)="$event.stopImmediatePropagation(); addAttendee()"
        >
          <span>Add Attendee</span>
        </button>
      </greco-event-details>
    </ng-container>

    <!-- Agreements -->
    <greco-event-community-agreement-usage
      *ngIf="series"
      [event]="series"
      [communityId]="series.community.id"
    ></greco-event-community-agreement-usage>
  </ng-container>
</ng-container>
