import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  DynamicFormControlComponent,
  DynamicFormControlCustomEvent,
  DynamicFormControlLayout,
  DynamicFormLayoutService,
  DynamicFormValidationService,
} from '@ng-dynamic-forms/core';
import { DynamicProductVariantPickerModel } from '../../forms';

@Component({
  selector: 'greco-dynamic-form-product-variant-picker',
  templateUrl: './dynamic-form-product-variant-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormControlProductVariantPickerComponent extends DynamicFormControlComponent {
  @Input() group!: FormGroup;
  @Input() layout!: DynamicFormControlLayout;
  @Input() model!: DynamicProductVariantPickerModel;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<any> = new EventEmitter();

  @ViewChild(DynamicFormControlComponent)
  myCustomFormControlComponent!: DynamicFormControlProductVariantPickerComponent;

  constructor(
    protected layoutService: DynamicFormLayoutService,
    protected validationService: DynamicFormValidationService
  ) {
    super(layoutService, validationService);
  }
}
