<form [formGroup]="form">
  <div style="display: flex; align-items: flex-end">
    <!-- Price -->
    <mat-form-field>
      <mat-label>Price</mat-label>
      <input
        matInput
        type="text"
        required
        currencyMask
        [options]="currencyMaskConfig"
        formControlName="price"
        [readonly]="isActive"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Payment Methods</mat-label>
      <mat-select [multiple]="true" [value]="['card']" formControlName="paymentTypes" required>
        <mat-option value="card" [disabled]="true" [matTooltip]="'Type is required at this time'">Card</mat-option>
        <mat-option value="bank" [matTooltip]="'This payment method requires 3+ business days to process'"
          >Bank</mat-option
        >
      </mat-select>
    </mat-form-field>

    <!-- Recurrence -->
    <mat-form-field>
      <mat-label>Recurrence</mat-label>
      <mat-select required formControlName="recurrence" [disabled]="isActive">
        <mat-option value="once">One-Time Purchase</mat-option>
        <mat-option value="fixedRecurrence">Fixed Term Recurring Purchase</mat-option>
        <mat-option value="indefiniteRecurrence">Indefinite Term Recurring Purchase</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field appearance="standard" floatLabel="always" style="width: 100%">
    <mat-label>Taxes</mat-label>
    <greco-account-taxes-picker formControlName="taxes" [accountId]="accountId" [disabled]="ignoreTaxes.checked">
      <mat-checkbox #ignoreTaxes formControlName="ignoreTaxes" [checked]="value?.ignoreTaxes || false" color="primary"
        >No Taxes</mat-checkbox
      >
    </greco-account-taxes-picker>
  </mat-form-field>

  <div style="display: flex; align-items: flex-end">
    <ng-container
      *ngIf="form.value.recurrence === 'indefiniteRecurrence' || form.value.recurrence === 'fixedRecurrence'"
    >
      <!-- Frequency -->
      <mat-form-field>
        <mat-label>Frequency</mat-label>
        <input matInput type="number" required formControlName="frequency" [readonly]="isActive" />
      </mat-form-field>

      <!-- Period -->
      <mat-form-field>
        <mat-label>Period</mat-label>
        <mat-select required formControlName="period" [disabled]="isActive">
          <mat-option value="DAILY">Day(s)</mat-option>
          <mat-option value="WEEKLY">Week(s)</mat-option>
          <mat-option value="MONTHLY">Month(s)</mat-option>
          <mat-option value="YEARLY">Year(s)</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- cycles -->
      <mat-form-field *ngIf="form.value.recurrence === 'fixedRecurrence'">
        <mat-label>Number of Payments</mat-label>
        <input matInput type="number" required formControlName="cycles" [readonly]="isActive" />
      </mat-form-field>
    </ng-container>
  </div>

  <div>
    <ng-container *ngIf="form.value.recurrence === 'fixedRecurrence'">
      <mat-checkbox
        style="margin-left: auto; align-self: center; margin-bottom: 15px"
        color="primary"
        formControlName="autoUpgradeEnabled"
        labelPosition="before"
        ><small>Enable Auto-Upgrade</small>
      </mat-checkbox>

      <mat-form-field *ngIf="form?.value?.autoUpgradeEnabled">
        <greco-product-variant-picker
          placeholder="Auto Upgrade Product"
          [variantIdsToExclude]="variantId ? [variantId] : []"
          [communityId]="communityId"
          [onlyRecurring]="true"
          [required]="true"
          [value]="form.value?.autoUpgradeVariant"
          formControlName="autoUpgradeVariant"
        ></greco-product-variant-picker>
      </mat-form-field>
    </ng-container>
  </div>
  <!-- Initiation fee -->
  <div>
    <ng-container
      *ngIf="form.value.recurrence === 'indefiniteRecurrence' || form.value.recurrence === 'fixedRecurrence'"
    >
      <mat-checkbox
        style="margin-left: auto; align-self: center; margin-bottom: 15px"
        color="primary"
        formControlName="enrolmentFeeEnabled"
        labelPosition="before"
        ><small>Enable Initiation Fee</small>
      </mat-checkbox>
      <ng-container *ngIf="form.value.enrolmentFeeEnabled">
        <div style="display: flex; align-items: flex-end">
          <mat-form-field>
            <mat-label>Title</mat-label>
            <input
              matInput
              type="text"
              required
              formControlName="enrolmentFeeName"
              placeholder="Initiation Fee"
              [readonly]="isActive"
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Fee</mat-label>
            <input
              matInput
              type="text"
              required
              currencyMask
              [options]="currencyMaskConfig"
              formControlName="enrolmentFee"
              [readonly]="isActive"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Description</mat-label>
            <input
              matInput
              type="text"
              required
              formControlName="enrolmentFeeDescription"
              placeholder="Initiation fee, applicable only first time when purchasing this item"
              [readonly]="isActive"
            />
          </mat-form-field>
        </div>
        <mat-form-field *ngIf="accountId$ | async" floatLabel="always" appearance="standard" style="width: 100%">
          <mat-label>Sales Category</mat-label>
          <greco-sale-category-input
            [accountId]="(accountId$ | async) || undefined"
            formControlName="enrolmentFeeSaleCategory"
            [required]="false"
          ></greco-sale-category-input>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </div>
  <!-- <div
    style="display: flex; align-items: flex-end; margin-top: 15px"
    *ngIf="form.value.recurrence === 'fixedRecurrence'"
  >
    <small *ngIf="form.value.cycles && form.value.period === 'DAILY'" style="color: var(--warn-color)">
      Renews every {{ form.value.frequency }} day{{ form.value.frequency == 1 ? '' : 's' }} for
      {{ form.value.cycles * form.value.frequency }} day{{ form.value.cycles * form.value.frequency == 1 ? '' : 's' }}
    </small>
    <small *ngIf="form.value.cycles && form.value.period === 'WEEKLY'" style="color: var(--warn-color)">
      Renews every {{ form.value.frequency }} week{{ form.value.frequency == 1 ? '' : 's' }} for
      {{ form.value.cycles * form.value.frequency }} week{{ form.value.cycles * form.value.frequency == 1 ? '' : 's' }}
    </small>
    <small *ngIf="form.value.cycles && form.value.period === 'MONTHLY'" style="color: var(--warn-color)">
      Renews every {{ form.value.frequency }} month{{ form.value.frequency == 1 ? '' : 's' }} for
      {{ form.value.cycles * form.value.frequency }} month{{ form.value.cycles * form.value.frequency == 1 ? '' : 's' }}
    </small>
    <small *ngIf="form.value.cycles && form.value.period === 'YEARLY'" style="color: var(--warn-color)">
      Renews every {{ form.value.frequency }} year{{ form.value.frequency == 1 ? '' : 's' }} for
      {{ form.value.cycles * form.value.frequency }} year{{ form.value.cycles * form.value.frequency == 1 ? '' : 's' }} -->
  <div style="display: flex; align-items: flex-end" *ngIf="form.value.recurrence === 'fixedRecurrence'">
    <small *ngIf="form.value.cycles" style="color: var(--warn-color)">
      <strong
        >Total subscription length:
        {{ form.value.cycles * form.value.frequency }}
        {{
          form.value.period === 'DAILY'
            ? 'day'
            : form.value.period === 'WEEKLY'
            ? 'week'
            : form.value.period === 'MONTHLY'
            ? 'month'
            : form.value.period === 'YEARLY'
            ? 'year'
            : 'unknown'
        }}{{ form.value.cycles * form.value.frequency == 1 ? '' : 's' }}</strong
      >
      <em *ngIf="form.value.cycles > 1">
        (1 initial payment + {{ form.value.cycles - 1 }} renewal{{ form.value.cycles - 1 > 1 ? 's every' : ' after' }}
        {{ form.value.frequency }}
        {{
          form.value.period === 'DAILY'
            ? 'day'
            : form.value.period === 'WEEKLY'
            ? 'week'
            : form.value.period === 'MONTHLY'
            ? 'month'
            : form.value.period === 'YEARLY'
            ? 'year'
            : 'unknown'
        }}{{ form.value.frequency == 1 ? '' : 's' }})
      </em>
    </small>
  </div>
</form>
