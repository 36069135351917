<svg viewBox="0 0 900 600" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill="transparent" d="M0 0h900v600H0z" />
  <path
    d="M344.18 443.621h225.261c17.772 0 32.18-14.408 32.18-32.18V186.18c0-17.772-14.408-32.18-32.18-32.18H408.54c-17.772 0-32.18 14.408-32.18 32.18v225.261c0 17.772-14.407 32.18-32.18 32.18v0c-17.772 0-32.18-14.408-32.18-32.18V234.45c0-17.772 14.408-32.18 32.18-32.18h32.18m56.316 16.09h112.63m-112.63 64.361h112.63m-112.63 64.361h112.63m74.541 96.539h124.927c9.856 0 17.847-7.991 17.847-17.847V300.847c0-9.857-7.991-17.847-17.847-17.847H655.54c-9.856 0-17.847 7.99-17.847 17.847v124.927c0 9.856-7.99 17.847-17.846 17.847v0c-9.857 0-17.847-7.991-17.847-17.847v-98.157c0-9.857 7.99-17.847 17.847-17.847h17.846m31.233 8.924h62.463m-62.463 35.693h62.463m-62.463 35.694h62.463m-562.542 53.54h124.927c9.856 0 17.847-7.991 17.847-17.847V300.847c0-9.857-7.991-17.847-17.847-17.847H204.54c-9.856 0-17.847 7.99-17.847 17.847v124.927c0 9.856-7.99 17.847-17.846 17.847v0c-9.857 0-17.847-7.991-17.847-17.847v-98.157c0-9.857 7.99-17.847 17.847-17.847h17.846m31.233 8.924h62.463m-62.463 35.693h62.463m-62.463 35.694h62.463"
    stroke="#E1E4E5"
    stroke-width="4"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M617.435 481.617c10.096 2.392 20.774-6.17 20.774-6.17s-5.698-12.435-15.797-14.82c-10.096-2.391-20.771 6.163-20.771 6.163s5.698 12.435 15.794 14.827z"
    fill="url(#a)"
  />
  <path
    d="M687.933 196.9c-21.005 14.469-53.096 4.355-53.096 4.355s1.987-33.568 23.007-48.023c21.005-14.468 53.082-4.368 53.082-4.368s-1.987 33.568-22.993 48.036z"
    fill="url(#b)"
  />
  <path
    d="M231.985 182.943c12.547 12.77 36.01 10.078 36.01 10.078s3.105-23.398-9.454-36.16c-12.547-12.77-35.998-10.086-35.998-10.086s-3.105 23.398 9.442 36.168z"
    fill="url(#c)"
  />
  <circle r="10.78" transform="matrix(1 0 0 -1 107.78 411.78)" fill="var(--primary-color, #e1e4e5)" />
  <ellipse rx="11.936" ry="11.339" transform="matrix(1 0 0 -1 543.064 472.61)" fill="var(--primary-color, #e1e4e5)" />
  <circle cx="186.452" cy="253.452" r="15.452" fill="var(--primary-color, #e1e4e5)" />
  <ellipse cx="419.995" cy="472.505" rx="10.743" ry="10.146" fill="#E1E4E5" />
  <circle cx="794.376" cy="445.622" r="8.624" fill="#E1E4E5" />
  <circle cx="348.351" cy="493.336" r="5.151" fill="#E1E4E5" />
  <circle cx="391.618" cy="126.688" r="7.358" fill="#E1E4E5" />
  <circle cx="694.788" cy="484.105" r="7.19" transform="rotate(105 694.788 484.105)" fill="#E1E4E5" />
  <circle cx="713.73" cy="245.951" r="9.565" fill="#E1E4E5" />
  <ellipse cx="119.256" cy="340.687" rx="7.358" ry="5.886" fill="#E1E4E5" />
  <circle cx="231.581" cy="487.581" r="11.581" transform="rotate(90 231.581 487.581)" fill="#E1E4E5" />
  <path
    d="M287.556 233h-.109c-.646 9.145-7.447 9.286-7.447 9.286s7.5.147 7.5 10.714c0-10.567 7.5-10.714 7.5-10.714s-6.799-.141-7.444-9.286zm314.476-132h-.188C600.731 117.431 589 117.684 589 117.684s12.936.263 12.936 19.248c0-18.985 12.935-19.248 12.935-19.248s-11.726-.253-12.839-16.684zm183.597 233.111h-.188c-1.113 16.431-12.844 16.684-12.844 16.684s12.936.263 12.936 19.248c0-18.985 12.935-19.248 12.935-19.248s-11.726-.253-12.839-16.684zM343.032 157h-.188C341.731 173.431 330 173.684 330 173.684s12.936.263 12.936 19.248c0-18.985 12.935-19.248 12.935-19.248s-11.726-.253-12.839-16.684zm288.705 86h-.14c-.832 12.277-9.597 12.466-9.597 12.466s9.665.196 9.665 14.382c0-14.186 9.665-14.382 9.665-14.382s-8.761-.189-9.593-12.466z"
    fill="#E1E4E5"
  />
  <defs>
    <linearGradient id="a" x1="655.453" y1="487.564" x2="567.075" y2="445.811" gradientUnits="userSpaceOnUse">
      <stop stop-color="#fff" />
      <stop offset="1" stop-color="#EEE" />
    </linearGradient>
    <linearGradient id="b" x1="606.109" y1="244.373" x2="771.458" y2="70.016" gradientUnits="userSpaceOnUse">
      <stop stop-color="#fff" />
      <stop offset="1" stop-color="#EEE" />
    </linearGradient>
    <linearGradient id="c" x1="282.046" y1="226.564" x2="191.338" y2="84.371" gradientUnits="userSpaceOnUse">
      <stop stop-color="#fff" />
      <stop offset="1" stop-color="#EEE" />
    </linearGradient>
  </defs>
</svg>
<p *ngIf="text" [innerHtml]="text | safe : 'html'"></p>
