<div id="tabs-layout-container">
  <!--  Page Title -->
  <greco-nav-title id="page-title" [text]="text" [showBackBtn]="false"></greco-nav-title>

  <!-- Tabs -->
  <div id="tab-bar" *ngIf="tabs?.length">
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        *ngFor="let tab of tabs"
        [routerLink]="tab.routerLink"
        [replaceUrl]="replaceUrl$ | async"
        routerLinkActive
        #link="routerLinkActive"
        [active]="link.isActive"
        [disabled]="tab.disabled || false"
      >
        <span [innerHTML]="tab.label | safe: 'html'"></span>
      </a>
    </nav>
  </div>

  <ng-content></ng-content>
</div>

<router-outlet></router-outlet>
