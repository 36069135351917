<greco-collapsible-section
  #section
  [header]="{ title: label, icon: 'info', iconColor: danger ? 'warn' : undefined }"
  [disabled]="alwaysOpen"
  [expanded]="alwaysOpen"
>
  <ng-content header select="[header]"></ng-content>

  <greco-form-save-buttons
    *ngIf="section.expanded && !readonly"
    header
    [form]="form"
    [saveAction]="save"
    [resetValue]="resetValue"
    (formReset)="form.markAsPristine()"
  ></greco-form-save-buttons>

  <ng-container>
    <div
      *ngIf="missingFields$ | async as missingFields"
      style="color: var(--danger-color, red); font-size: 85%; margin-bottom: 16px"
    >
      Missing information: {{ missingFields }}
    </div>
  </ng-container>

  <form [formGroup]="form">
    <!-- Profile Picture -->
    <mat-form-field id="picture-form-field" floatLabel="always" appearance="standard" style="width: 100%">
      <greco-user-avatar matPrefix size="small" [showOnlineStatus]="false" [user]="form.value"></greco-user-avatar>

      <mat-label style="padding-left: 24px">Profile Picture</mat-label>
      <input
        [readonly]="readonly"
        style="padding-left: 16px"
        matInput
        readonly
        placeholder="Upload new photo"
        [value]="profilePictureFile?.name || ''"
      />

      <input
        #fileInput
        [readonly]="readonly"
        style="display: none"
        type="file"
        accept="image/*"
        (change)="setProfilePicture($any($event.target).files[0])"
      />

      <div matSuffix style="display: flex">
        <button mat-button color="primary" (click)="fileInput.click()" [disabled]="readonly">Select File</button>
        <button mat-button color="primary" (click)="takePhoto()" [disabled]="readonly">Use Webcam</button>
      </div>
    </mat-form-field>

    <!-- Email -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label
        >Email Address
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span></mat-label
      >
      <input
        [readonly]="readonly || !user?.email"
        matInput
        type="email"
        formControlName="email"
        placeholder="What's your email address?"
      />
      <mat-hint *ngIf="!user?.email">This email is managed in the parent account</mat-hint>
    </mat-form-field>

    <!-- Name -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label
        >Full Name
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span></mat-label
      >
      <input [readonly]="readonly" matInput formControlName="name" placeholder="What's your full name?" />
    </mat-form-field>

    <!-- Friendly Name -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>Nickname</mat-label>
      <input [readonly]="readonly" matInput formControlName="friendlyName" placeholder="What should we call you?" />
    </mat-form-field>

    <!-- Phone Number -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label
        >Phone Number
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span></mat-label
      >
      <input
        [readonly]="readonly"
        matInput
        type="tel"
        mask="(000) 000-0000"
        formControlName="phoneNumber"
        placeholder="At what number can we reach you?"
      />
    </mat-form-field>

    <!-- Address -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label
        >Address
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span></mat-label
      >
      <greco-address-input [disabled]="readonly" formControlName="address"></greco-address-input>
    </mat-form-field>

    <!-- Birthday -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label
        >Birthday
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span></mat-label
      >
      <input
        matInput
        [max]="nowDate"
        [readonly]="readonly"
        [disabled]="readonly"
        [matDatepicker]="birthday"
        formControlName="birthday"
        placeholder="When's your birthday?"
        (click)="birthday.open()"
      />
      <mat-datepicker-toggle [disabled]="readonly" matSuffix [for]="birthday"></mat-datepicker-toggle>
      <mat-datepicker [disabled]="readonly" startView="multi-year" #birthday></mat-datepicker>
    </mat-form-field>

    <!-- Gender -->
    <div style="margin-bottom: -1.34375em">
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label
          >Gender
          <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span></mat-label
        >
        <mat-select #select formControlName="gender">
          <mat-option value="Male">Male</mat-option>
          <mat-option value="Female">Female</mat-option>
          <mat-option value="Non-binary">Non-binary</mat-option>
          <mat-option value="Transgender">Transgender</mat-option>
          <mat-option value="Intersex">Intersex</mat-option>
          <mat-option value="Prefer not to say">Prefer not to say</mat-option>
          <mat-option value="Other">Other</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="standard"
        class="other-field"
        [floatLabel]="'always'"
        [style.overflow]="select.value === 'Other' ? 'auto' : 'hidden'"
        [@heightExpansion]="select.value === 'Other' ? 'expanded' : 'collapsed'"
      >
        <mat-label style="margin-left: 12px">Other</mat-label>
        <input matInput placeholder="Please Specify..." formControlName="genderOther" />
      </mat-form-field>
    </div>

    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>
        Emergency Contact Name
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
      </mat-label>
      <input
        [readonly]="readonly"
        matInput
        formControlName="emergencyContactName"
        placeholder="Who should we call in case of an emergency?"
      />
    </mat-form-field>

    <!-- Emergency Phone Number -->
    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label
        >Emergency Contact Phone Number
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span></mat-label
      >
      <input
        [readonly]="readonly"
        matInput
        type="tel"
        mask="(000) 000-0000"
        formControlName="emergencyPhoneNumber"
        placeholder="At what number can we reach you in case of an emergency?"
      />
    </mat-form-field>

    <!-- <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>
        Emergency Contact Email
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
      </mat-label>
      <input
        [readonly]="readonly"
        matInput
        formControlName="emergencyContactEmail"
        placeholder="What is your emergency contacts email?"
      />
    </mat-form-field> -->

    <mat-form-field appearance="standard" style="width: 100%">
      <mat-label>
        Emergency Contact Relationship
        <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker"> *</span>
      </mat-label>
      <input
        [readonly]="readonly"
        matInput
        formControlName="emergencyContactRelationship"
        placeholder="What is your relationship to the emergency contact?"
      />
    </mat-form-field>
  </form>
</greco-collapsible-section>
