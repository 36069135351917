import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { LetModule } from '@greco/ngx-let-util';
import { TextFitModule } from '@greco/ngx-textfit-util';
import { BoxModule } from '@greco/ui-box';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { TableModule } from '@greco/ui-table';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  PerkBadgeComponent,
  PerkPickerComponent,
  PerksPreviewComponent,
  UserPerkBoxComponent,
  UserPerksGridComponent,
  UserPerksTableComponent,
} from './components';
import {
  GrantPerkDialog,
  GrantUserPerkDialog,
  PerkBadgeEditDialog,
  RemovePerkDialog,
  RemoveSinglePerkDialog,
} from './dialogs';
import { PerksPage, UserPerksPage } from './pages';
import { FrequencyPipe, PerkSubCommunitiesPipe, RecurrenceLabelPipe } from './pipes';
import { PerkService } from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    // Material
    MatBadgeModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,

    // Greco
    BoxModule,
    FiltersModule,
    TableModule,
    TextFitModule,
    SimpleDialogModule,
    LetModule,
    ContactsModule,

    // Other
    SafePipeModule,
    NgPipesModule,
  ],
  providers: [PerkService],
  declarations: [
    // Pages
    PerksPage,
    UserPerksPage,

    // Components
    PerkBadgeComponent,
    PerksPreviewComponent,
    UserPerksTableComponent,
    UserPerksGridComponent,
    UserPerkBoxComponent,
    PerkPickerComponent,

    // Dialogs
    GrantPerkDialog,
    RemovePerkDialog,
    GrantUserPerkDialog,
    PerkBadgeEditDialog,
    RemoveSinglePerkDialog,

    // Pipes
    FrequencyPipe,
    RecurrenceLabelPipe,
    PerkSubCommunitiesPipe,
  ],
  exports: [
    // Pages
    PerksPage,
    UserPerksPage,

    // Components
    PerkBadgeComponent,
    PerksPreviewComponent,
    UserPerksGridComponent,
    UserPerksTableComponent,
    PerkPickerComponent,

    // Dialogs
    GrantPerkDialog,
    GrantUserPerkDialog,

    // Pipes
    FrequencyPipe,
  ],
})
export class PerksModule {}
