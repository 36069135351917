<greco-simple-dialog [data]="simpleDialogData">
  <mat-form-field style="width: 100%; margin-top: 16px">
    <mat-label>Sold By</mat-label>

    <greco-user-picker
      [formControl]="soldByFormControl"
      [showPhoto]="true"
      [onlyStaff]="true"
      [communityId]="accountId"
      placeholder="Select who sold this purchase"
    ></greco-user-picker>

    <button matSuffix mat-icon-button *ngIf="soldByFormControl.value" (click)="soldByFormControl.setValue(null)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div style="margin-top: 16px; display: flex; align-items: center; gap: 8px">
    <button style="flex: 1" mat-stroked-button type="button" (click)="cancel()">Cancel</button>
    <button style="flex: 1" mat-flat-button color="primary" type="button" (click)="submit()">Confirm</button>
  </div>
</greco-simple-dialog>
