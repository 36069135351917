<ng-container *grecoLet="selectedPaymentMethod$ | async as selectedPaymentMethod">
  <div class="header">
    <div class="user-photo">
      <greco-smart-img
        class="userImageToExport"
        [src]="user.photoURL || 'assets/lf3/icon_square_pad.png'"
        [border]="true"
        [round]="true"
      ></greco-smart-img>
    </div>
    <div style="display: flex; flex-direction: row">
      <div class="billed-to">
        <h3>Billed To</h3>
        <p>{{ user.displayName }}</p>
        <p style="font-style: italic">{{ user.contactEmail }}</p>
      </div>
    </div>
  </div>
  <div class="content" *ngIf="!!(previews$ | async | pluck : 'preview.purchase.purchase.total' | sum)">
    <greco-purchase-preview-table [items]="(items$ | async) || []" [showUsername]="true"></greco-purchase-preview-table>
  </div>
  <div
    *ngIf="!!(previews$ | async | pluck : 'preview.purchase.purchase.total' | sum) || hasCancellationFee"
    [class.in-page]="true"
  >
    <!-- <mat-divider></mat-divider> -->
    <div>
      <div
        class="payment-method-container"
        [class.border-top]="!!(previews$ | async | pluck : 'preview.purchase.purchase.total' | sum)"
      >
        <!-- *ngIf="user  && !forMultiple&& (preview.purchase.purchase.total > preview.purchase.purchase.balanceUsed)"s -->
        <p *ngIf="hasCancellationFee" style="margin-bottom: 0; width: 100%; padding-top: 8px">
          <small>
            Used to cover any cancellation fees for
            {{ previews.length > 1 ? 'these bookings' : 'this booking' }}
          </small>
        </p>
        <!-- <div style="width: 100%"></div> -->
        <mat-form-field appearance="standard" class="payment-method-picker-form-field">
          <mat-label *ngIf="!paymentMethodControl.value"><i>Add a Payment Method</i></mat-label>
          <greco-select-payment-method
            #selectPaymentMethodInput
            [formControl]="paymentMethodControl"
            [userId]="user.id"
            [required]="true"
            [allowBankPayments]="false"
          ></greco-select-payment-method>
          <greco-add-payment-method-button
            matSuffix
            [onlyIcon]="true"
            [userId]="user.id"
            [hiddenForms]="['Bank Account']"
            (saved)="selectPaymentMethodInput.refreshPaymentMethods()"
          ></greco-add-payment-method-button>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
