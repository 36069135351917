<ng-container *grecoLet="canUpdateTile$ | async as canUpdateTile">
  <!-- Tile Details -->

  <greco-collapsible-section #details [header]="{ title: 'Details', icon: 'info' }">
    <greco-form-save-buttons
      header
      *ngIf="details.expanded && canUpdateTile"
      [form]="detailsForm"
      [saveAction]="saveDetails"
      [resetValue]="{ tileDetails: resetDetailsValue }"
      (formReset)="resetForm()"
    ></greco-form-save-buttons>
    <!-- {{ detailsForm.value | json }} -->
    <form [formGroup]="detailsForm">
      <greco-tiles-input
        [communityId]="tile?.communityId || ''"
        [required]="true"
        [readonly]="!canUpdateTile"
        [initialUrls]="initialUrls"
        formControlName="tileDetails"
        [showUpdateGroup]="true"
      ></greco-tiles-input>
    </form>
  </greco-collapsible-section>
</ng-container>
