import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AccountService } from '../../services';

@Component({
  selector: 'greco-create-account-dialog',
  templateUrl: './create-account.dialog.html',
  styleUrls: ['./create-account.dialog.scss'],
})
export class CreateAccountDialog implements OnDestroy, OnInit {
  constructor(private formBuilder: FormBuilder, private accountSvc: AccountService, private snacks: MatSnackBar) {}

  dialogData: DialogData = {
    showCloseButton: false,
    title: 'Create Account',
    subtitle: 'Provide the details for the new account',
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      { label: 'Confirm', role: 'submit', disabled: true, resultFn: this.createAccount.bind(this) },
    ],
  };

  formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.required],
    address: [null, Validators.required],
  });

  private onDestroy$ = new Subject<void>();

  ngOnInit() {
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this.onDestroy$)).subscribe(() => {
      this.toggleConfirmationButton(this.formGroup.valid);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private toggleConfirmationButton(enabled: boolean) {
    this.dialogData = {
      ...this.dialogData,
      buttons: [
        { label: 'Cancel', role: 'cancel' },
        { label: 'Confirm', role: 'submit', disabled: !enabled, resultFn: this.createAccount.bind(this) },
      ],
    };
  }

  private async createAccount() {
    this.toggleConfirmationButton(false);

    try {
      const account = await this.accountSvc.createAccount({
        name: this.formGroup.value.name,
        email: this.formGroup.value.email,
        phone: this.formGroup.value.phone,
        address: this.formGroup.value.address,
        royalties: [],
      });

      this.snacks.open('Account created!', 'Ok');
      return account;
    } catch (err) {
      console.error(err);
    }

    return null;
  }
}
