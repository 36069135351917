<ng-template #template>
  <div class="info-card-wrapper" matRipple [matRippleDisabled]="!clickable" [class.info-card-clickable]="clickable">
    <ng-content select="greco-info-card-image"></ng-content>

    <div class="info-card-content">
      <h4 class="info-card-title">{{ title }}</h4>
      <div style="overflow: hidden">
        <div class="info-card-subtitle" [innerHTML]="subtitle | safe: 'html'"></div>
        <div class="info-card-description">
          <span>{{ description }}</span>
          <ng-content select="greco-info-card-select"></ng-content>
        </div>
      </div>
    </div>

    <div class="info-card-icon">
      <ng-content select="mat-icon"></ng-content>
    </div>
  </div>
</ng-template>

<a
  *ngIf="routerLink && !select; else template"
  class="info-card-link"
  [routerLink]="routerLink"
  [queryParams]="queryParams"
>
  <ng-container *ngTemplateOutlet="template"></ng-container>
</a>
