<div class="tabs-container" [class.mobile]="(mobileBreakpoint$ | async)?.matches">
  <div *ngIf="pageTitle || forceShowTitle" class="title-container">
    <ng-content select="[header]"></ng-content>
    <h1>{{ pageTitle }}</h1>
  </div>

  <div class="tabs">
    <!-- ActionsStart :: ?? && Mobile Nav Menu -->
    <div class="actions-start">
      <ng-content [select]="[actionsStart]"></ng-content>
      <ng-container *ngIf="(mobileBreakpoint$ | async)?.matches && tabs.length > 1">
        <button class="menu-button" mat-icon-button [matMenuTriggerFor]="linkMenu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #linkMenu="matMenu">
          <ng-container *ngFor="let tab of tabs">
            <a
              mat-menu-item
              [routerLink]="tab.routerLink ? ((basePath$ | async) || []).concat(tab.routerLink) : (basePath$ | async)"
              queryParamsHandling="preserve"
              routerLinkActive="active-tab"
              [routerLinkActiveOptions]="{ exact: true }"
              #mrla="routerLinkActive"
              [style.display]="mrla.isActive ? 'none' : ''"
            >
              <mat-icon class="tab-icon" *ngIf="tab.iconStart">{{ tab.iconStart }}</mat-icon>
              <span [innerHtml]="tab.label | safe : 'html'" style="margin: 0 8px"></span>
              <mat-icon class="tab-icon" *ngIf="tab.iconEnd">{{ tab.iconEnd }}</mat-icon>
            </a>
          </ng-container>
        </mat-menu>
      </ng-container>
    </div>

    <!-- CRUMBS :: CRUMB NAVIGATION -->
    <nav mat-tab-nav-bar *grecoLet="crumbs$ | async as crumbs">
      <ng-container *ngIf="crumbs?.length">
        <ng-container *ngFor="let crumb of crumbs; let last = last">
          <a
            mat-tab-link
            #rla="routerLinkActive"
            [active]="rla.isActive"
            [disabled]="rla.isActive"
            routerLinkActive="active-tab"
            [queryParamsHandling]="
              crumb.queryParamsHandling !== null && crumb.queryParamsHandling !== undefined
                ? crumb.queryParamsHandling
                : 'preserve'
            "
            [routerLink]="[crumb.routerLink]"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <mat-icon class="tab-icon" *ngIf="crumb.icon">{{ crumb.icon }}</mat-icon>
            <span [innerHtml]="crumb.label | safe : 'html'" style="margin: 0 8px"></span>
          </a>

          <div *ngIf="!last" style="display: flex; align-items: center">
            <span style="color: rgba(14, 15, 15, 0.87); opacity: 0.6; margin-left: -8px">/</span>
          </div>
        </ng-container>
      </ng-container>

      <!-- NO CRUMBS :: BASIC NAVIGATION -->
      <ng-container *ngIf="!crumbs?.length">
        <ng-container
          *ngFor="let tab of tabs | slice : 0 : (tabs.length <= moreBreakpoint + 1 ? tabs.length : moreBreakpoint)"
        >
          <a
            mat-tab-link
            [routerLink]="tab.routerLink ? ((basePath$ | async) || []).concat(tab.routerLink) : (basePath$ | async)"
            queryParamsHandling="preserve"
            routerLinkActive="active-tab"
            [routerLinkActiveOptions]="{ exact: true }"
            #rla="routerLinkActive"
            [active]="rla.isActive"
            [style.display]="(mobileBreakpoint$ | async)?.matches && !rla.isActive ? 'none' : ''"
          >
            <mat-icon class="tab-icon" *ngIf="tab.iconStart">{{ tab.iconStart }}</mat-icon>
            <span [innerHtml]="tab.label | safe : 'html'" style="margin: 0 8px"></span>
            <mat-icon class="tab-icon" *ngIf="tab.iconEnd">{{ tab.iconEnd }}</mat-icon>
          </a>
        </ng-container>
        <ng-container *ngIf="tabs.length > moreBreakpoint + 1 && !(mobileBreakpoint$ | async)?.matches">
          <ng-container *ngFor="let tab of tabs | slice : moreBreakpoint">
            <a
              mat-tab-link
              [routerLink]="tab.routerLink ? ((basePath$ | async) || []).concat(tab.routerLink) : (basePath$ | async)"
              queryParamsHandling="preserve"
              routerLinkActive="active-tab"
              [routerLinkActiveOptions]="{ exact: true }"
              #rla="routerLinkActive"
              [active]="rla.isActive"
              [style.display]="(mobileBreakpoint$ | async)?.matches || rla.isActive ? '' : 'none'"
            >
              <mat-icon class="tab-icon" *ngIf="tab.iconStart">{{ tab.iconStart }}</mat-icon>
              <span [innerHtml]="tab.label | safe : 'html'" style="margin: 0 8px"></span>
              <mat-icon class="tab-icon" *ngIf="tab.iconEnd">{{ tab.iconEnd }}</mat-icon>
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="tabs.length > moreBreakpoint + 1 && !(mobileBreakpoint$ | async)?.matches">
          <button mat-button mat-tab-link [matMenuTriggerFor]="menu">More<mat-icon>expand_more</mat-icon></button>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let tab of tabs | slice : moreBreakpoint">
              <button
                #rla="routerLinkActive"
                routerLinkActive="active-tab"
                mat-menu-item
                [style]="rla.isActive ? 'display: none' : ''"
                [routerLink]="tab.routerLink ? ((basePath$ | async) || []).concat(tab.routerLink) : (basePath$ | async)"
              >
                <mat-icon class="tab-icon" *ngIf="tab.iconStart">{{ tab.iconStart }}</mat-icon>
                <span [innerHtml]="tab.label | safe : 'html'" style="margin: 0 8px"></span>
                <mat-icon class="tab-icon" *ngIf="tab.iconEnd">{{ tab.iconEnd }}</mat-icon>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </ng-container>
    </nav>

    <div class="actions">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="content" style="height: 100%; flex-grow: 1; display: flex; flex-direction: column; position: relative">
    <router-outlet #outlet></router-outlet>
  </div>
</div>
