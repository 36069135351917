<ng-container *ngIf="user$ | async as user">
  <h2 class="section-title">Your Perks</h2>

  <ng-container *grecoLet="perks$ | async as perks">
    <greco-user-perks-grid
      *ngIf="perks?.length; else noPerks"
      [perks]="perks"
      [userId]="user?.id"
    ></greco-user-perks-grid>

    <ng-template #noPerks>
      <p>You currently have no perks! Perks can be used to book in to events and can be purchased in the shop</p>
    </ng-template>
  </ng-container>
</ng-container>
