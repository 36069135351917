<div id="iframe-container">
  <greco-video-player *ngIf="!loading" [video]="video" [watchTime]="watchTime"></greco-video-player>
  <iframe
    *ngIf="loading"
    [src]="video.embedUrl | safe : 'resourceUrl'"
    frameborder="0"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  ></iframe>
</div>
