import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { ResourceTag } from '@greco/booking-events';
import { CreateResourceTagDto, UpdateResourceTagDto } from '@greco/nestjs-booking-events';

@Injectable()
export class ResourceTagService {
  constructor(private http: HttpClient) {}

  // @Get(':resourceId')
  async getResourceTag(resourceTagId: string) {
    return await toPromise(this.http.get<ResourceTag>(`/api/resource_tags/${resourceTagId}`));
  }

  // @Get(':communityId')
  async getResourceTags(communityId: string) {
    return await toPromise(this.http.get<ResourceTag[]>(`/api/resource_tags/community/${communityId}`));
  }

  // @Post()
  async createResourceTag(dto: CreateResourceTagDto) {
    return await toPromise(this.http.post<ResourceTag>(`/api/resource_tags`, dto));
  }

  // @Put(':resourceId')
  async updateResourceTag(dto: UpdateResourceTagDto) {
    return await toPromise(this.http.put<ResourceTag>(`/api/resource_tags/${dto.id}`, dto));
  }

  // @Delete(':resourceId')
  async removeResourceTag(resourceTagId: string) {
    return await toPromise(this.http.delete<ResourceTag>(`/api/resource_tags/${resourceTagId}`));
  }
}
