<greco-simple-dialog [data]="dialogData">
  <greco-table id="test" [data]="data.links" [highlight]="true" [loading]="loading">
    <p *grecoTableCol="'Label'; let link">{{ link.label }}</p>
    <ng-container *grecoTableCol="'Link'; let link">
      <a [routerLink]="link.url" target="_blank">{{ origin }}{{ link.url }}</a>
    </ng-container>

    <ng-container *grecoTableCol="''; fitContent: true; stickyEnd: true; let link">
      <button mat-icon-button (click)="copylink(link.url)">
        <mat-icon [matTooltip]="'Copy link to clipboard'" style="font-size: large">content_copy</mat-icon>
      </button>
    </ng-container>
  </greco-table>
</greco-simple-dialog>
