import { Component, Input, OnDestroy } from '@angular/core';
import { BookingOption, calculateBoosterRequirements, CalendarEvent, EventSeries } from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'greco-booking-option-item',
  templateUrl: './booking-option-item.component.html',
})
export class BookingOptionItemComponent implements OnDestroy {
  @Input() event!: CalendarEvent | EventSeries;
  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>(1);

  @Input() bookingOption!: BookingOption;
  @PropertyListener('bookingOption') private _bookingOption$ = new ReplaySubject<BookingOption>(1);

  readonly requiredBoosts$ = combineLatest([this._event$, this._bookingOption$]).pipe(
    map(([event, bookingOption]) => {
      if (!event || !bookingOption) return -1;
      if (bookingOption.id === 'prk_complimentarybooking') return 0;
      return calculateBoosterRequirements(event, bookingOption);
    }),
    shareReplay(1)
  );

  ngOnDestroy() {
    this._event$.complete();
    this._bookingOption$.complete();
  }
}
