import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GreetingPipe } from './greeting.pipe';

export * from './greeting.module';

@NgModule({
  imports: [CommonModule],
  declarations: [GreetingPipe],
  exports: [GreetingPipe]
})
export class GreetingModule {}
