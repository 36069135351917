<ng-container>
  <div class="dialog-container">
    <div class="header">
      <h2 class="title">Please Choose a Date Range</h2>
    </div>
    <div class="content">
      <form [formGroup]="formGroup">
        <mat-form-field appearance="standard">
          <mat-label>Start</mat-label>
          <greco-datetime-timezone-input
            #startDatetimePicker
            [required]="true"
            formControlName="start"
          ></greco-datetime-timezone-input>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>End</mat-label>
          <greco-datetime-timezone-input
            #endDatetimePicker
            [required]="true"
            formControlName="end"
          ></greco-datetime-timezone-input>
          <mat-hint *ngIf="tooFarInFuture" style="color: var(--warn-color); display: flex">
            <mat-icon style="margin: 1px 2px">info</mat-icon>
            <i>Exports only supports events up to six months past the start date!</i>
          </mat-hint>
        </mat-form-field>
      </form>
    </div>
    <div class="footer buttons">
      <button mat-stroked-button (click)="close()">Close</button>
      <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="export()">Start Export</button>
    </div>
  </div>
</ng-container>
