import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TileGroup } from '@greco/tiles';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ManageTileGroupDialogComponent } from '../../dialogs/manage-tile-groups/manage-tile-group.dialog';
import { TilesService } from '../../services/tiles.service';

export interface TileDetails {
  label: string;
  image: File[] | null;
  url?: string;
  group?: string;
  priority: number;
}

@Component({
  selector: 'greco-tiles-input',
  templateUrl: './tiles-input.component.html',
  styleUrls: ['./tiles-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TilesInputComponent),
      multi: true,
    },
  ],
})
export class TilesInputComponent implements ControlValueAccessor, OnDestroy, OnInit {
  constructor(private formBuilder: FormBuilder, private tileSvc: TilesService, private dialog: MatDialog) {
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.onChanged?.(this.value));
  }

  @Input() initialUrls!: string[];
  private onChanged?: (value: TileDetails | null) => void;
  private onTouched?: () => void;

  private _onDestroy$ = new Subject<void>();
  // tilegroup
  groups: TileGroup[] = [];
  groupsOptions?: Observable<TileGroup[]>;

  // *********
  readonly stateChanges = new Subject<void>();

  _form = this.formBuilder.group({
    label: [''],
    image: [null],
    url: [''],
    group: [''],
    priority: [0],
  });

  _required = false;

  @Input() readonly = false;
  @Input() communityId!: string;
  @Input() get value() {
    const data: TileDetails = this._form.value;
    return this._form.valid
      ? ({
          label: data.label,
          image: data.image,
          url: data.url,
          group: data.group,
          priority: data.priority,
        } as TileDetails)
      : null;
  }

  set value(value: TileDetails | null) {
    this._form.patchValue({
      label: value?.label || '',
      image: value?.image || null,
      url: value?.url || '',
      group: value?.group || '',
      priority: value?.priority || 0,
    });

    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    const validator = [...(this._required ? [Validators.required] : [])];
    this._form.get('label')?.setValidators(validator);
    this._form.get('image')?.setValidators(validator);
    this.stateChanges.next();
  }
  isMatch?: TileGroup;
  @Input() showUpdateGroup = false;
  writeValue(value: TileDetails): void {
    this.value = value;
  }

  registerOnChange(fn: (value: TileDetails | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  //
  async manageTileGroups() {
    const communityId = this.communityId;
    if (!communityId) return;
    const _dialog = this.dialog.open(ManageTileGroupDialogComponent, {
      data: { communityId },
      width: '750px',
      maxWidth: '90%',
    });
  }

  // tilegroup
  async ngOnInit() {
    this.groups = await this.tileSvc.getGroupsByCommunity(this.communityId, true);

    this.groupsOptions = this._form.get('group')?.valueChanges.pipe(
      startWith(''),
      tap(val => {
        this.isMatch = this.groups.find(group => {
          return group.name.toLowerCase() === val.toLowerCase();
        });
      }),
      map(val => {
        {
          return this.groups.filter(group => group.name.toLowerCase().includes(val.toLowerCase()));
        }
      })
    );

    //
  }
  // *******
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
