<greco-simple-dialog [data]="dialogData">
  <greco-box>
    <form [formGroup]="completionForm">
      <div style="display: flex; flex-direction: column">
        <!-- Name -->
        <mat-form-field *ngIf="!data.user.displayName" appearance="standard" type="text">
          <input matInput required formControlName="displayName" placeholder="John Doe" type="text" />
          <mat-label>Full Name</mat-label>
        </mat-form-field>

        <!-- Phone Number -->
        <mat-form-field *ngIf="!this.data.user.phoneNumber" appearance="standard">
          <mat-label>Phone Number</mat-label>
          <input
            matInput
            required
            type="tel"
            mask="(000) 000-0000"
            formControlName="phoneNumber"
            placeholder="At what number can we reach you?"
          />
        </mat-form-field>

        <!-- Birthday -->
        <mat-form-field *ngIf="!data.user.birthday" appearance="standard">
          <input
            matInput
            required
            [max]="nowDate"
            [matDatepicker]="birthday"
            placeholder="When's your birthday?"
            (dateInput)="addBirthday($event)"
            (click)="birthday.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="birthday"></mat-datepicker-toggle>
          <mat-datepicker startView="multi-year" #birthday></mat-datepicker>
          <mat-label>Birthday</mat-label>
        </mat-form-field>

        <!-- Emergency Phone Number -->
        <mat-form-field *ngIf="!this.data.user.emergencyPhoneNumber" appearance="standard">
          <mat-label>Emergency Phone Number</mat-label>
          <input
            matInput
            required
            type="tel"
            mask="(000) 000-0000"
            formControlName="emergencyPhoneNumber"
            placeholder="At what number can we reach you in case of an emergency?"
          />
        </mat-form-field>
      </div>
    </form>
  </greco-box>
</greco-simple-dialog>
