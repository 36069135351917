import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScheduleTextPipe } from './schedule-text.pipe';

export * from './schedule-text.pipe';
@NgModule({
  imports: [CommonModule],
  declarations: [ScheduleTextPipe],
  exports: [ScheduleTextPipe]
})
export class ScheduleTextModule {}
