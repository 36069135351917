<div class="title-container">
  <h3 class="title">{{ weekLabel$ | async }}</h3>
  <div class="title-container">
    <button mat-stroked-button style="margin-right: 8px" (click)="today()">Today</button>
    <button mat-stroked-button class="left-button" (click)="previousWeek()">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <button mat-stroked-button class="right-button" (click)="nextWeek()">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>

<div class="buttons" *grecoLet="formats$ | async as formats">
  <ng-container *ngFor="let day of week">
    <button
      mat-stroked-button
      class="day-button"
      [class.today]="day | isToday"
      [disabled]="canSearchThePast ? false : (day | isPast : 'day')"
      [color]="(day | isToday) ? 'primary' : 'basic'"
      (click)="dayClicked.emit(day)"
    >
      <div class="day-button-content">
        <span>{{ day | date : formats?.weekDay || 'EEE' }}</span>
        <span>{{ day | date : formats?.date || 'MMM dd' }}</span>
      </div>
    </button>
  </ng-container>
</div>
