<div id="video_wrapper" *ngIf="video || embedUrl" style="justify-content: center">
  <h1 *ngIf="displayTitle">{{ titleOverride || video.title }}</h1>
  <greco-video-player-wrapper *ngIf="video" [video]="video"></greco-video-player-wrapper>
  <!-- <div style="padding: 56.25% 0 0 0; position: relative">
    <iframe
      [src]="embedUrl | safe: 'resourceUrl'"
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: black; border-radius: 4px"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    ></iframe>
  </div>-->

  <!--<div style="padding: 56.25% 0 0 0; position: relative">
    <iframe
      src="{{playerLink | safe: 'resourceUrl}}'"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      title="{{ video.title }}"
    ></iframe>
  </div>
  <script src="https://player.vimeo.com/api/player.js"></script>
</div> -->
</div>
