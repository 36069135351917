import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ArrayUtils } from '@greco-fit/util';
import { Calendar } from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { CalendarService } from '../../services';

// NOTE: This component is never used
@Component({
  selector: 'greco-community-calendar-picker',
  templateUrl: './community-calendar-picker.component.html',
  styleUrls: ['./community-calendar-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CommunityCalendarPickerComponent }],
})
export class CommunityCalendarPickerComponent
  implements MatFormFieldControl<Calendar | Calendar[] | null>, ControlValueAccessor, OnDestroy
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private calendarSvc: CalendarService,
    private _elementRef: ElementRef
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'greco-community-calendar-picker';

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  stateChanges = new Subject<void>();

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `${
    CommunityCalendarPickerComponent._controlType
  }-${CommunityCalendarPickerComponent._id++}`;
  readonly controlType = CommunityCalendarPickerComponent._controlType;

  @Input() multiselect = false;
  @Input() showSearchButton = true;

  @Output() selectedVariantChange = new EventEmitter<Calendar>();

  @PropertyListener('communityIds') private _communityIds$ = new BehaviorSubject<string[]>([]);
  @Input() communityIds: string[] = [];
  searchQuery$ = new BehaviorSubject<string>('');

  // allCalendars$ = this._communityIds$.pipe(
  //   switchMap(async communityIds => {
  //     if (!communityIds || !communityIds.length) return null;
  //     const filter = RequestQueryBuilder.create({
  //       search: {
  //         $and: [
  //           {
  //             ...(communityIds.length && { communityId: { $in: communityIds } }),
  //           },
  //         ],
  //       },
  //     });
  //     return await this.calendarSvc.paginate(undefined, filter, { limit: 100 });
  //   }),
  //   map(paginatedCalendars => paginatedCalendars?.items),
  //   map(calendars => {
  //     const noCommunity = '<i>No Community</i>';
  //     const grouped = ArrayUtils.groupBy(calendars || [], calendars => calendars.community?.name || noCommunity);
  //     return Object.entries(grouped)
  //       .map(([name, calendars]) => ({ name, calendars }))
  //       .sort((a, b) => {
  //         if (a.name === noCommunity) return 1;
  //         if (b.name === noCommunity) return -1;
  //         return a.name.localeCompare(b.name);
  //       });
  //   }),
  //   shareReplay(1)
  // );

  filteredCalendars$ = combineLatest([this._communityIds$, this.searchQuery$]).pipe(
    switchMap(async ([communityIds, searchQuery]) => {
      if (!communityIds) return null;
      const filter = RequestQueryBuilder.create({
        search: {
          $and: [
            {
              ...(communityIds.length && { communityId: { $in: communityIds } }),
            },
            {
              $or: ['title'].map(property => ({
                [property]: {
                  [CondOperator.CONTAINS_LOW]: searchQuery,
                },
              })),
            },
          ],
        },
      });
      return await this.calendarSvc.paginate(undefined, filter, { limit: 10 });
    }),
    map(result => result?.items),
    map(calendars => {
      const noCommunity = '<i>No Community</i>';
      const grouped = ArrayUtils.groupBy(calendars || [], calendars => calendars.community?.name || noCommunity);
      return Object.entries(grouped)
        .map(([name, calendars]) => ({ name, calendars }))
        .sort((a, b) => {
          if (a.name === noCommunity) return 1;
          if (b.name === noCommunity) return -1;
          return a.name.localeCompare(b.name);
        });
    }),
    shareReplay(1)
  );

  private _value: Calendar | Calendar[] | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: Calendar | Calendar[] | null) {
    this._value = value;
    if (this._input) {
      if (!(value instanceof Array)) {
        this._input.value = value?.title || '';
        this.searchQuery$.next(value ? '' : this._input.value);
      } else {
        this._input.value = ' ';
      }
    }

    this._onChange?.(value);
    this.stateChanges.next();
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return (
      !!this.ngControl?.touched &&
      (!!Object.keys(this.ngControl?.errors || {}).length || (this.required && !this.value))
    );
  }
  selectedCalendars: Calendar[] = [];
  selectedCalendarIds: string[] = [];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;
  justCalendars$ = this.searchQuery$.pipe(map(value => !!value));
  select(calendar: Calendar) {
    if (!this.multiselect) {
      this.value = calendar;
      if (this._input) {
        if (calendar) this._input.value = calendar.title;
        else this._input.value = '';
        this.stateChanges.next();
      }
    } else {
      this.selectedCalendarIds.includes(calendar.id)
        ? this.removeFromSelectedCalendars(calendar)
        : this.addToSelectedCalendars(calendar);
    }
  }

  async selectAllCalendars() {
    const result = await this.calendarSvc.getManySecuredCached(this.communityIds, true);
    for (const calendar of result ?? []) {
      this.addToSelectedCalendars(calendar);
    }
  }

  addToSelectedCalendars(calendar: Calendar) {
    if (!this.selectedCalendarIds.includes(calendar.id)) {
      this.selectedCalendars = [...this.selectedCalendars, calendar];
      this.selectedCalendarIds = [...this.selectedCalendarIds, calendar.id];
    }

    this.value = this.selectedCalendars;
  }

  removeFromSelectedCalendars(calendar: Calendar) {
    const index = this.selectedCalendarIds.findIndex(p => calendar.id === p);
    this.selectedCalendars.splice(index, 1);
    this.selectedCalendarIds.splice(index, 1);
    if (!this.selectedCalendars.length) {
      this.value = null;
    } else this.value = this.selectedCalendars;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.community-calendar-picker-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: Calendar | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: Calendar | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  emitCalendar(calendar: Calendar) {
    this.selectedVariantChange.emit(calendar);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.searchQuery$.complete();
    this._communityIds$.complete();
  }
}
