import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from '@greco/ngx-clipboard-util';
import { DataExportsModule } from '@greco/ngx-data-exports';
import { FiltersModule } from '@greco/ngx-filters';
import { InvoicesModule } from '@greco/ngx-finance-invoices';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { AuthModule } from '@greco/ngx-identity-auth';
import { UsersModule } from '@greco/ngx-identity-users';
import { LetModule } from '@greco/ngx-let-util';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { SmartImgModule } from '@greco/ui-smart-img';
import { TableModule } from '@greco/ui-table';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { NgxMaskModule } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { ContactPickerComponent, MembershipNumberInputComponent } from './components';
import { CreateContactDialog, CreateMemberNumberDialog } from './dialogs';
import { ContactDisplayNameFilter, ContactEmailFilter, ContactPage, ContactSearchFilter, ContactsPage } from './pages';
import { ContactService, ContactServiceModule } from './services';
@NgModule({
  imports: [
    CommonModule,
    TableModule,
    FiltersModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDialogModule,
    SimpleDialogModule,
    RouterModule,
    ContactServiceModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    BoxModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    LetModule,
    SmartImgModule,
    CollapsibleSectionModule,
    FormSaveButtonsModule,
    NgxMaskModule.forChild(),
    UsersModule,
    ClipboardModule,
    PaymentsModule,
    InvoicesModule,
    AuthModule,
    DataExportsModule,
    UserAvatarModule,
    MatCheckboxModule,
    NgPipesModule,
    MatChipsModule,
  ],
  providers: [ContactDisplayNameFilter, ContactSearchFilter, ContactEmailFilter, ContactService],
  declarations: [
    ContactsPage,
    ContactPage,
    CreateContactDialog,
    ContactPickerComponent,
    MembershipNumberInputComponent,
    CreateMemberNumberDialog,
  ],
  exports: [ContactsPage, ContactPage, ContactPickerComponent, MembershipNumberInputComponent],
})
export class ContactsModule {}
