import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LetModule } from '@greco/ngx-let-util';
import { SafePipeModule } from 'safe-pipe';
import { InitiateExportDialog } from './dialogs';
import { DataExportService } from './services';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    LetModule,
    MatButtonModule,
    MatIconModule,
    SafePipeModule,
    MatProgressSpinnerModule,
  ],
  declarations: [InitiateExportDialog],
  exports: [InitiateExportDialog],
  providers: [DataExportService],
})
export class DataExportsModule {}
