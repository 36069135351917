import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Account } from '@greco/finance-accounts';
import { CollapsibleSectionController, INITIAL_COLLAPSIBLE_SECTION_EXPANDED } from '@greco/ui-collapsible-section';
import { CurrencyMaskConfig } from 'ngx-currency';

export enum StripeFeeEventType {
  charge_authorized = 'charge_authorized',
  charge_captured = 'charge_captured',
  charge_failed = 'charge_failed',
  authorization_expiry = 'authorization_expiry',
  refund = 'refund',
  interac_refund = 'interac_refund',
  setup_intent = 'setup_intent',
  setup_intent_failed = 'setup_intent_failed',
}

@Component({
  selector: 'greco-account-page',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: INITIAL_COLLAPSIBLE_SECTION_EXPANDED, useValue: 2 }, CollapsibleSectionController],
})
export class AccountPage {
  @Input() account!: Account;
  @Input() showDetails = true;
  @Input() showGatewayFee = true;

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  eventTypes = [
    {
      eventType: 'Basic Processing Fee',
      description: 'Description goes here',
      formControl: this.feeFormControl(),
      initialValue: { feeType: 'None', percentage: null, amount: null, stripeMinimum: false },
    },
    ...Object.values(StripeFeeEventType).map(eventType => ({
      eventType: eventType.replace(/_/g, ' '),
      description: 'Description goes here',
      formControl: this.feeFormControl(),
      initialValue: { feeType: 'None', percentage: null, amount: null, stripeMinimum: false },
    })),
  ];

  _initialValue = this.eventTypes.map(a => a.initialValue);

  form = this.formBuilder.group({
    array: this.formBuilder.array(this.eventTypes.map(({ formControl }) => formControl)),
  });

  saving = false;

  constructor(private formBuilder: FormBuilder) {}

  private feeFormControl() {
    return this.formBuilder.group({
      feeType: ['None', Validators.required],
      percentage: [null, [Validators.min(0), Validators.max(100)]],
      amount: [null, [Validators.min(0)]],
      stripeMinimum: [false, Validators.required],
    });
  }

  reset() {
    this.form.reset({ array: this._initialValue });
  }
}
