<div class="item-container" [class.empty-start]="!hasStartContent">
  <div class="start">
    <ng-content select="[item-start]"></ng-content>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>

  <div class="end">
    <ng-content select="[item-end]"></ng-content>
  </div>
</div>
