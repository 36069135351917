import { Component, Input } from '@angular/core';
import { BookingService } from '../../services/booking.service';

@Component({
  selector: 'greco-add-booking-to-calendar-button',
  templateUrl: './add-booking-to-calendar-button.component.html',
  styleUrls: ['./add-booking-to-calendar-button.component.scss'],
})
export class AddBookingToCalendarButtonComponent {
  constructor(private bookingSvc: BookingService) {}

  @Input() bookingId!: string;

  async addToCalendar() {
    const ics = await this.bookingSvc.getIcs(this.bookingId);
    const blob = new Blob([ics.ics], { type: 'text/calendar;charset=utf-8' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = ics.filename;
    a.dataset.downloadurl = ['text/calendar', a.download, a.href].join(':');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
    }, 1500);
  }
}
