<div class="stats-container" *ngIf="stats$ | async as stat">
  <greco-box>
    <h3>Open Disputes</h3>
    <p>{{stat.open}}</p>
  </greco-box>

  <greco-box>
    <h3>Under Review</h3>
    <p>{{stat.underReview}}</p>
  </greco-box>

  <greco-box>
    <h3>Won</h3>
    <p>{{stat.won}}</p>
  </greco-box>

  <greco-box>
    <h3>Lost</h3>
    <p>{{stat.lost}}</p>
  </greco-box>
</div>

<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  style="position: sticky; top: 56px; z-index: 10; background-color: var(--background-color, white)"
  (changed)="paymentDispute.queryBuilder = $event[1]; filters$.next($event[1]);paymentDispute.onFilterApplied()"
>
</greco-filter-bar>
<greco-payment-disputes-table
  #paymentDispute
  [accountId]="_accountId$ | async"
  (rowClick)="openPaymentDispute($event)"
></greco-payment-disputes-table>
