<button *ngIf="!onlyIcon" mat-flat-button [color]="color" type="button" [matMenuTriggerFor]="menu">
  <mat-icon *ngIf="!hideIcon">add</mat-icon>
  <span>Add</span>
</button>

<button *ngIf="onlyIcon" mat-icon-button [color]="color" type="button" [matMenuTriggerFor]="menu">
  <mat-icon>add</mat-icon>
</button>

<mat-menu #menu>
  <ng-container *ngFor="let form of forms">
    <button
      *ngIf="!hiddenForms.includes(form.label)"
      mat-menu-item
      style="height: 48px"
      (click)="open(form); $event.stopImmediatePropagation()"
    >
      <mat-icon *ngIf="form.matIcon">{{ form.matIcon }}</mat-icon>
      <span>{{ form.label }}</span>
    </button>
  </ng-container>
</mat-menu>
