import { animate, query, style, transition, trigger } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { PlatformService } from './platform.service';

export interface PlatformLink {
  group: string | null;
  icon: string | null;
  label: string | null;
  description: string | null;
  color: 'primary' | 'accent' | 'warn' | 'null';
  url: string | null;
}

export interface PlatformLayoutConfig {
  logo: string | null;
  title: string | null;
  subtitle: string | null;
  links: PlatformLink[];
}

@Component({
  selector: 'greco-platform-layout',
  templateUrl: './platform.layout.html',
  styleUrls: ['./platform.layout.scss'],
  animations: [
    trigger('routerTransition', [
      transition('* <=> *', [
        query(
          'main.main-outlet > :enter, main.main-outlet > :leave',
          [
            style({
              position: 'absolute',
              left: 0,
              width: '100%',
              opacity: 0,
            }),
          ],
          { optional: true }
        ),
        query('main.main-outlet > :enter', [animate('600ms ease', style({ opacity: 1 }))], { optional: true }),
      ]),
    ]),
  ],
})
export class PlatformLayoutComponent {
  public config: PlatformLayoutConfig;
  public mobileQuery: MediaQueryList;

  @ViewChild(MatDrawer) drawer: MatDrawer;

  private _mobileQueryListener: () => void;

  showProgressBar = false;

  constructor(
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    private platformSvc: PlatformService,
    private router: Router
  ) {
    this.config = this.platformSvc.config;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationStart) this.showProgressBar = true;
      else if (evt instanceof NavigationEnd) this.showProgressBar = false;
    });
  }

  test(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }
}
