import { TitleCasePipe } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { ResourceType } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-users';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PersonResourceInputComponent } from '../../components/person-resource-input/person-resource-input.component';
import { ZoomResourceInputComponent } from '../../components/zoom-resource-input/zoom-resource-input.component';
@Component({
  selector: 'greco-create-resource',
  templateUrl: './create-resource.dialog.html',
  styleUrls: ['./create-resource.dialog.scss'],
})
export class CreateResourceDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string; type: ResourceType },
    private userSvc: UserService,
    private formBuilder: FormBuilder
  ) {}

  @ViewChild(PersonResourceInputComponent) person?: PersonResourceInputComponent;
  @ViewChild(ZoomResourceInputComponent) zoom?: ZoomResourceInputComponent;

  dialogData: DialogData = {
    title: `Create New ${this.data.type === ResourceType.PERSON ? 'Trainer' : 'Zoom Resource'}`,
    subtitle: `Provide the details for the new ${this.data.type === ResourceType.PERSON ? 'trainer' : 'zoom resource'}`,
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          switch (this.formGroup.value.type) {
            case new TitleCasePipe().transform(ResourceType.PERSON):
              await this.person?.create();
              break;
            case new TitleCasePipe().transform(ResourceType.ZOOM):
              await this.zoom?.create();
              break;
          }
        },
      },
    ],
  };

  searchQuery$ = new BehaviorSubject<string | null>(null);
  excludedIds$ = new BehaviorSubject<string[] | null>(null);

  users$: Observable<User[]> = combineLatest([this.searchQuery$ /* , this.excludedIds$ */]).pipe(
    debounceTime(300),
    switchMap(async ([searchQuery /* , excludedIds */]) =>
      this.userSvc.searchUsers(searchQuery as string /* , excludedIds || undefined */)
    )
  );

  formGroup = this.formBuilder.group({
    type: [new TitleCasePipe().transform(this.data.type), Validators.required],
  });

  onChange(value?: string) {
    this.searchQuery$.next(value || '');
  }
}
