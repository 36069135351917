import { SecurityResource } from '@greco/security';

export const COLLECTION_VIDEOS_SECURITY_RESOURCE = 'collection_videos';

export enum CollectionVideoSecurityResourceAction {
  READ = 'READ',
  UPLOAD = 'UPLOAD',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  IGNORE_PAYMENT_FREEZE = 'IGNORE_PAYMENT_FREEZE',
}

export const CollectionVideoSecurityResource: SecurityResource = {
  key: COLLECTION_VIDEOS_SECURITY_RESOURCE,
  module: 'videos',

  title: 'Video Collection Videos',
  description: 'Manage video collection videos',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: CollectionVideoSecurityResourceAction.READ,
      title: 'Read videos in collections',
      description: 'Ability to see videos in a collection',
    },
    {
      key: CollectionVideoSecurityResourceAction.UPLOAD,
      title: 'Upload videos to collections',
      description: 'Ability to upload videos to a collection',
    },
    {
      key: CollectionVideoSecurityResourceAction.UPDATE,
      title: 'Update videos in collections',
      description: 'Ability to update videos in a collection',
    },
    {
      key: CollectionVideoSecurityResourceAction.REMOVE,
      title: 'Remove videos from collections',
      description: 'Ability to remove videos from a collection',
    },
    {
      key: CollectionVideoSecurityResourceAction.IGNORE_PAYMENT_FREEZE,
      title: 'Ignore payment freeze',
      description: 'Ability to unlock a video and ignore the payment freeze',
    },
  ],
};
