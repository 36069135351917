import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CenteredContainerLayout } from './centered-container.layout';

export * from './centered-container.layout';

@NgModule({
  declarations: [CenteredContainerLayout],
  imports: [CommonModule],
  exports: [CenteredContainerLayout]
})
export class CenteredContainerModule {}
