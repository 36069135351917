import { CurrencyPipe } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ProductVariant, RecurrencePeriod } from '@greco/sales-products';

@Pipe({ name: 'variantPeriodPrice' })
export class VariantPricePeriod implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode: string = 'USD'
  ) {}

  private _currency = new CurrencyPipe(this._locale, this._defaultCurrencyCode);

  transform(variant?: ProductVariant): string {
    if (!variant) return '';

    if (variant.recurrence?.frequency) {
      const frequency = variant.recurrence.frequency;
      const price = variant.price / frequency / 100;

      switch (variant.recurrence.period) {
        case RecurrencePeriod.Daily:
          return this._currency.transform(price || 0) + ' / day';

        case RecurrencePeriod.Weekly:
          return this._currency.transform(price || 0) + ' / week';

        case RecurrencePeriod.Monthly:
          return this._currency.transform(price || 0) + ' / month';

        case RecurrencePeriod.Yearly:
          return this._currency.transform(price || 0) + ' / year';
      }
    }

    return this._currency.transform(variant.price / 100 || 0) + '';
  }
}
