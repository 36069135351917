import { BaseDto } from '@greco/util-dto';
import { IsInt, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class CreateVideoSubCollectionDto extends BaseDto {
  @IsInt()
  sortIndex: number;

  @IsString()
  @IsOptional()
  icon?: string;

  @IsString()
  @IsNotEmpty()
  title: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsString()
  @IsNotEmpty()
  collectionId: string;
}
