import type { Stripe } from 'stripe';

export interface IGrecoPrice {
  id: string;
  hidden?: boolean;
  transferPercentage?: number;
  data: Stripe.Price & {
    product: string;
  };
}

export class GrecoPrice {
  public readonly ID: string;
  public readonly weeklyPrice: number;
  public readonly dailyPrice: null | number;
  public readonly intervalPrice: null | number;
  public readonly billingInterval: string;
  public readonly intervalSeconds?: number;
  public readonly ignoreCovid: boolean;

  constructor(public readonly price: IGrecoPrice) {
    this.ID = this.price.id;
    this.weeklyPrice = GrecoPrice.getWeeklyPrice(price);
    this.dailyPrice = GrecoPrice.getDailyPrice(price);
    this.intervalPrice = price.data.unit_amount;
    this.billingInterval = GrecoPrice.getBillingInterval(price);
    this.intervalSeconds = GrecoPrice.getIntervalSeconds(price);

    this.ignoreCovid = [
      'prod_HgoQraeJnWijTP',
      'prod_HjtzP0j1YHuvFB',
      'prod_HgEF4xisETnk6v',
      'prod_HgEE38jzbIl9M4',
    ].includes(this.price.data.product);
  }

  // tslint:disable-next-line: member-ordering
  static sortPrices(priceA: GrecoPrice, priceB: GrecoPrice) {
    return (
      Math.round((priceB.dailyPrice || 0) - (priceA.dailyPrice || 0)) ||
      (priceB.price.data.unit_amount || 0) - (priceA.price.data.unit_amount || 0)
    );
  }

  static getWeeklyPrice(price: IGrecoPrice) {
    return (GrecoPrice.getDailyPrice(price) || 0) * 7;
  }

  static getDailyPrice(price: IGrecoPrice) {
    if (price?.data?.type === 'one_time') return price?.data?.unit_amount;
    switch (price?.data?.recurring?.interval) {
      case 'day':
        return (price.data.unit_amount || 0) / price.data.recurring.interval_count;
      case 'week':
        return (price.data.unit_amount || 0) / (7 * price.data.recurring.interval_count);
      case 'month':
        return (price.data.unit_amount || 0) / ((365.25 / 12) * price.data.recurring.interval_count);
      case 'year':
        return (price.data.unit_amount || 0) / (365.25 * price.data.recurring.interval_count);
      default:
        return 0;
    }
  }

  static getBillingInterval(price: IGrecoPrice) {
    switch (price?.data?.recurring?.interval) {
      case 'day':
        return price.data.recurring.interval_count > 1 ? `Every ${price.data.recurring.interval_count} Days` : 'Daily';
      case 'week':
        return price.data.recurring.interval_count > 1
          ? `Every ${price.data.recurring.interval_count} Weeks`
          : 'Weekly';
      case 'month':
        if (price.data.recurring.interval_count === 3) return 'Quarterly';
        if (price.data.recurring.interval_count === 6) return 'Bi-Yearly';
        return price.data.recurring.interval_count > 1
          ? `Every ${price.data.recurring.interval_count} Months`
          : 'Monthly';
      case 'year':
        return price.data.recurring.interval_count > 1
          ? `Every ${price.data.recurring.interval_count} Years`
          : 'Yearly';
      default:
        return '';
    }
  }

  static getIntervalSeconds(price: IGrecoPrice) {
    switch (price?.data?.recurring?.interval) {
      case 'day':
        return price.data.recurring.interval_count * 24 * 60 * 60;
      case 'week':
        return price.data.recurring.interval_count * 7 * 24 * 60 * 60;
      case 'month':
        return price.data.recurring.interval_count * (365.25 / 12) * 24 * 60 * 60;
      case 'year':
        return price.data.recurring.interval_count * 265.25 * 24 * 60 * 60;
    }
  }
}

export function getPlanDailyPrice(plan?: Stripe.Plan) {
  if (!plan) return null;
  switch (plan.interval) {
    case 'day':
      return (plan.amount || 0) / plan.interval_count;
    case 'week':
      return (plan.amount || 0) / (7 * plan.interval_count);
    case 'month':
      return (plan.amount || 0) / ((365.25 / 12) * plan.interval_count);
    case 'year':
      return (plan.amount || 0) / (365.25 * plan.interval_count);
  }
}
