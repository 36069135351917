import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { VimeoService } from '@greco/ngx-vimeo';
import { CreateVideoAndInitiateUploadDto, Video, VideoSourceRequestDto, VideoUnlock } from '@greco/videos';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({ providedIn: 'root' })
export class VideosService {
  constructor(private vimeoSvc: VimeoService, private http: HttpClient) {}
  async upload(file: File, dto: CreateVideoAndInitiateUploadDto) {
    dto.fileSize = file.size;
    const response = await toPromise(this.http.post<any>(`/api/videos/upload`, dto));
    await this.clientSideUpload(file, dto.source, response.video, response.uploadUrl);
    return response.video;
  }

  async clientSideUpload(file: File, source: string, video: Video, uploadUrl: string) {
    if (!video || !uploadUrl) throw new Error('no video or upload url provided');
    switch (source) {
      case 'vimeo':
        return await this.vimeoSvc.upload(file, uploadUrl);
      default:
        console.log('not a valid source selection');
        break;
    }
    return video;
  }

  async get(videoId: string): Promise<Video> {
    return await toPromise(this.http.get<Video>(`/api/videos/${videoId}`));
  }

  async searchSourceVideos(
    source: string,
    pagination: Partial<PaginatedQueryParams>,
    title?: string
  ): Promise<PaginatedDto<VideoSourceRequestDto>> {
    return await toPromise(
      this.http.get<PaginatedDto<VideoSourceRequestDto>>(`/api/videos/all/${source}`, {
        params: {
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
          title: title || '',
        },
      })
    );
  }

  hasActiveVideoUnlock(videoId: string) {
    return toPromise(this.http.get<VideoUnlock>(`/api/video-unlocks/has-active-unlock`, { params: { videoId } }));
  }

  async paginate(query: RequestQueryBuilder, pagination?: Partial<PaginatedQueryParams>): Promise<PaginatedDto<Video>> {
    return await toPromise(
      this.http.get<PaginatedDto<Video>>(`/api/videos`, {
        params: {
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }
  getVideos() {
    return toPromise(this.http.get<Video[]>(`/api/videos/all`));
  }
}
