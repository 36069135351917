import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Resource } from '@greco/booking-events';
import { SelectFilter } from '@greco/ngx-filters';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { ResourcesService } from '../../../services';

@Injectable({
  providedIn: 'any',
})
export class ResourceFilter extends SelectFilter {
  public titleCase = new TitleCasePipe();
  constructor(public resourceService: ResourcesService) {
    super('ResourceFilter', {
      label: 'Resource',
      properties: ['resources.id'],
      shortLabel: 'Resource',
      description: '',
    });
    this.allowedOperators = [CondOperator.EQUALS];
  }

  public communityId?: string;

  getImageUrl(value: Resource | Resource[]) {
    return (Array.isArray(value) ? value[0].photoURL : value.photoURL) ?? 'assets/lf3/icon.png';
  }

  getValueLabel(value: Resource | Resource[]): string {
    return Array.isArray(value)
      ? value.map(resource => this.titleCase.transform(resource.name)).join(', ')
      : this.titleCase.transform(value.name);
  }

  async getValueOptions(search?: string): Promise<Resource[]> {
    if (!this.communityId) return [];
    const resources = await this.resourceService.paginateResources(
      new RequestQueryBuilder().search({
        name: { $contL: search },
      }),
      this.communityId,
      {
        page: 1,
        limit: search ? 2 : 100,
      }
    );

    return resources.items;
  }

  serializeValue(value: Resource | Resource[]): string {
    return Array.isArray(value) ? value.map(resource => resource.id).join(',') : value.id;
  }

  async deserializeValue(serializedValue: string): Promise<Resource[]> {
    const ids = serializedValue.split(',');
    if (!ids.length) return [];
    return await Promise.all(
      ids.map(id => {
        return this.resourceService.getResource(id);
      })
    );
  }

  compareWith(value1: Resource, value2: Resource) {
    return value1?.id === value2?.id;
  }
}
