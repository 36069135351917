<h2>{{ promotion.title }}</h2>
<p>
  {{ promotion.subtitle }}{{ promotion.subtitle ? ' | ' : ''
  }}<em>{{ promotion.startDate | date }} - {{ promotion.endDate | date }}</em>
</p>
<div class="products-swiper">
  <swiper
    [slidesPerView]="'auto'"
    [spaceBetween]="16"
    [navigation]="true"
    [slidesOffsetBefore]="mobileView ? 16 : 32"
    [slidesOffsetAfter]="mobileView ? 16 : 32"
  >
    <ng-template swiperSlide *ngFor="let product of products">
      <greco-product-card
        *grecoLet="user$ | async as user"
        [userId]="user?.id"
        [userConditionContext]="(userConditionContext$ | async) || undefined"
        [product]="product"
        (productBuyClicked)="close()"
      ></greco-product-card>
    </ng-template>
  </swiper>
</div>
