<greco-filter-bar
  *grecoLet="canCreateResource$ | async as canCreateResource"
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()"
>
  <div
    *grecoLet="canManageTags$ | async as canManageTags"
    style="display: flex; align-items: center; justify-content: flex-end; height: 32px"
  >
    <button
      *ngIf="canCreateResource"
      mat-stroked-button
      color="primary"
      [style.border-top-right-radius]="canManageTags ? '0px' : ''"
      [style.border-bottom-right-radius]="canManageTags ? '0px' : ''"
      style="height: 32px; line-height: 16px"
      (click)="createResource()"
    >
      <span>New Trainer</span>
    </button>

    <button
      *ngIf="canManageTags"
      mat-stroked-button
      color="primary"
      [matMenuTriggerFor]="button_menu"
      [style.border-left]="canCreateResource ? 'none' : ''"
      [style.border-top-left-radius]="canCreateResource ? '0px' : ''"
      [style.border-bottom-left-radius]="canCreateResource ? '0px' : ''"
      style="min-width: 0; height: 100%; padding: 0 8px"
    >
      <mat-icon *ngIf="canCreateResource" style="margin-left: -4px">expand_more</mat-icon>
      <span *ngIf="!canCreateResource" style="margin-right: 8px">New Tag</span>
    </button>
  </div>
  <mat-menu #button_menu>
    <a mat-menu-item (click)="manageTags()" color="primary">
      <mat-icon>sort</mat-icon>
      <span>Manage Tags</span>
    </a>
  </mat-menu>
</greco-filter-bar>

<greco-table
  [data]="(resources$ | async) || []"
  [highlight]="true"
  [loading]="loading"
  (rowClick)="openResource($event)"
>
  <div *grecoTableCol="''; let resource; fitContent: true" [matTooltip]="resource.id">
    <div *ngIf="resource?.photoURL; else noPhoto" style="width: 24px; height: 24px">
      <greco-smart-img [src]="resource?.photoURL || 'assets/lf3/icon_square_pad.png'" [round]="true"> </greco-smart-img>
    </div>
    <ng-template #noPhoto>
      <mat-icon> account_circle </mat-icon>
    </ng-template>
  </div>

  <p *grecoTableCol="'Display Name'; let resource">{{resource.name}}</p>

  <a *grecoTableCol="'Calendar'; let resource" (click)="viewInCalendar(resource)"> View in Calendar </a>

  <mat-chip *grecoTableCol="'Status'; let resource"> {{ resource.disabled ? "Disabled" : "Enabled" }} </mat-chip>

  <!-- Resource Tags -->
  <mat-chip-list *grecoTableCol="'Tags'; let resource" style="flex: 0 0 10%">
    <mat-chip *ngFor="let tag of resource.resourceTags">{{tag.label}}</mat-chip>
  </mat-chip-list>

  <ng-container *grecoTableCol="''; let resource; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button mat-menu-item (click)="openResource(resource);">
        <mat-icon>open_in_new</mat-icon>
        <span>View Details</span>
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
