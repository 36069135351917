<div id="iframe-container" *ngIf="video">
  <iframe
    id="video-player"
    [src]="video.embedUrl + '#t=' + (watchTime || 0) | safe : 'resourceUrl'"
    frameborder="0"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  ></iframe>
</div>
<greco-video-watch-progress-bar
  *ngIf="video"
  class="video-progress"
  [duration]="video.duration || 0"
  [progress]="watchTime"
></greco-video-watch-progress-bar>
