import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { UserCommunityAgreement } from '@greco/community-agreements';
import { SignAgreementDialog, UserCommunityAgreementsService } from '@greco/ngx-community-agreements';
import { UserService } from '@greco/ngx-identity-auth';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'greco-dashboard-unsigned-agreements',
  templateUrl: './dashboard-unsigned-agreements.component.html',
  styleUrls: ['./dashboard-unsigned-agreements.component.scss'],
})
export class DashboardUnsignedAgreementsComponent {
  constructor(
    public userSvc: UserService,
    private dialog: MatDialog,
    private agreementSvc: UserCommunityAgreementsService
  ) {}

  private _refresh$ = new BehaviorSubject<void>(undefined);

  unsignedAgreements$ = combineLatest([this.userSvc.user$, this._refresh$]).pipe(
    switchMap(async ([user]) => (user ? await this.agreementSvc.getUnsignedUserAgrmts(user.id) : []))
  );

  async signAgreement(agreement: UserCommunityAgreement) {
    const result = await toPromise(this.dialog.open(SignAgreementDialog, { data: agreement }).afterClosed());
    if (result) this._refresh$.next();
  }
}
