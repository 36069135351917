import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { User } from '@greco/identity-users';
import { FundUserBalanceDialog, FundUserBalanceDialogData } from '../../dialogs';

@Component({
  selector: 'greco-fund-user-balance-button',
  templateUrl: './fund-user-balance-button.component.html',
  styleUrls: ['./fund-user-balance-button.component.scss'],
})
export class FundUserBalanceButtonComponent {
  constructor(private matDialog: MatDialog) {}

  @Output() fundedBalance = new EventEmitter();
  @Input() user?: User;
  @Input() accountId?: string;

  async openDialog() {
    const dialog = this.matDialog.open(FundUserBalanceDialog, {
      data: { user: this.user, accountId: this.accountId } as FundUserBalanceDialogData,
    });
    const result = await toPromise(dialog.afterClosed());

    if (result) this.fundedBalance.emit();
  }
}
