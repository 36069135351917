import { UpdateVideoDto } from '@greco/videos';
import { Type } from 'class-transformer';
import { IsDate, IsInt, IsOptional, IsString, Min } from 'class-validator';

export class UpdateCollectionVideoDto extends UpdateVideoDto {
  @IsString()
  @IsOptional()
  descriptionOverride?: string | null;

  @IsString()
  @IsOptional()
  titleOverride?: string | null;

  @Min(0)
  @IsInt()
  @IsOptional()
  sortIndex?: number;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  availableOn?: Date | null;

  @IsDate()
  @IsOptional()
  @Type(() => Date)
  availableUntil?: Date | null;
}
