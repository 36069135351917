<form>
  <div class="main-fields">
    <!-- <mat-form-field appearance="outline">
      <mat-label>Seller</mat-label>
      <input type="text" placeholder="Select a Seller" aria-label="Select a seller" matInput />
    </mat-form-field> -->
    <mat-form-field appearance="outline">
      <mat-icon matPrefix style="margin: 0 12px 0 4px">search</mat-icon>
      <mat-label>Search</mat-label>
      <input
        matInput
        type="text"
        [value]="searchValue || ''"
        aria-label="Search Products"
        placeholder="Search Products"
        (keyup.enter)="searchValue && search()"
        (input)="searchValue = $any($event.target).value"
      />

      <button matSuffix *ngIf="searchValue" mat-icon-button (click)="searchValue = null; search()">
        <mat-icon>clear</mat-icon>
      </button>

      <mat-hint (click)="pickCommunities()">
        Showing products from: {{ communities | pluck : 'name' | grecoJoin : ', ' }}
        <div style="position: relative">
          <greco-user-community-picker
            #communityPicker
            [formControl]="communitiesControl"
            style="position: absolute; opacity: 0; pointer-events: none"
            [required]="true"
            [user]="user$ | async"
            [autoSelectUserCommunities]="(user$ | async) ? false : true"
          ></greco-user-community-picker>
        </div>
      </mat-hint>
    </mat-form-field>

    <button type="button" mat-flat-button color="primary" [disabled]="!searchValue" (click)="search()">Search</button>
  </div>

  <greco-collections-grid
    *ngIf="showCollections"
    [collections]="collections || []"
    (collectionClicked)="openCollection($event)"
  ></greco-collections-grid>
</form>
