import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipeModule } from 'safe-pipe';
import { MultiAvatarComponent, SmartImgComponent } from './components';

@NgModule({
  imports: [CommonModule, SafePipeModule],
  declarations: [SmartImgComponent, MultiAvatarComponent],
  exports: [SmartImgComponent, MultiAvatarComponent],
})
export class SmartImgModule {}
