<greco-simple-dialog [data]="dialogData">
  <p>
    Are you sure you want to
    <b>{{this.positiveAmount ? 'add' : 'withdraw'}} {{this.absAmount | currency}}</b> {{this.positiveAmount ? 'to' :
    'from'}} {{this.data.userId ? 'your' : 'the'}} account in <b>{{this.data.communityId}}?</b>
  </p>
  <!-- Buttons -->
  <div style="display: flex; align-items: center; gap: 8px">
    <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
      <span>Cancel</span>
    </button>

    <button mat-flat-button type="submit" (click)="confirm()" color="primary" style="flex: 1">
      <span>Confirm</span>
    </button>
  </div>
</greco-simple-dialog>
