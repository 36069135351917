import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SuperTitleModule } from '../super-title/super-title.module';
import { NavTitleComponent } from './nav-title.component';

@NgModule({
  declarations: [NavTitleComponent],
  exports: [NavTitleComponent],
  imports: [CommonModule, RouterModule, SuperTitleModule, MatIconModule, MatButtonModule]
})
export class NavTitleModule {}
