import { Pipe, PipeTransform } from '@angular/core';
import { EventAccount } from '@greco/booking-events2';

@Pipe({
  name: 'getEventAccount',
})
export class GetEventAccountPipe implements PipeTransform {
  transform(userId: string, accounts: EventAccount[]): EventAccount | undefined {
    return accounts.find(a => a.user.id === userId);
  }
}
