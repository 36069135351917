<greco-alert
  *ngIf="displayStyle === 'banner'; else card"
  [config]="isUser ? userAlert : staffAlert"
  style="display: block"
></greco-alert>

<ng-template #card>
  <ng-container *grecoLet="isUser ? userAlert : staffAlert as config">
    <greco-alert-card [title]="config!.title" [subtitle]="config!.description" [icon]="config!.icon" color="warn">
      <ng-content select="[action]" action></ng-content>
    </greco-alert-card>
  </ng-container>
</ng-template>
