import { SecurityResource } from '@greco/security';

export const EVENT_SECURITY_RESOURCE = 'booking_event';

export enum EventSecurityResourceAction {
  READ_PRIVATE = 'READ_PRIVATE',
  CREATE = 'CREATE',
  CREATE_CUSTOM = 'CREATE_CUSTOM',
  UPDATE = 'UPDATE',
  UPDATE_CUSTOM = 'UPDATE_CUSTOM',
  EXPORT = 'EXPORT',
  CANCEL = 'CANCEL',
}

export const EventSecurityResource: SecurityResource = {
  key: EVENT_SECURITY_RESOURCE,
  module: 'booking',
  title: 'Event',
  description: 'Manage community events',

  context: class {
    eventId?: string;
    communityId?: string;
    resources?: string[];
  },

  actions: [
    {
      key: EventSecurityResourceAction.READ_PRIVATE,
      title: 'See private events',
      description: 'Ability to view private events',
    },
    {
      key: EventSecurityResourceAction.CREATE,
      title: 'Create events',
      description: 'Ability to create a new event from a template',
    },
    {
      key: EventSecurityResourceAction.CREATE_CUSTOM,
      title: 'Create custom events',
      description: 'Ability to create custom events',
    },
    { key: EventSecurityResourceAction.UPDATE, title: 'Update events', description: 'Ability to update an event' },
    {
      key: EventSecurityResourceAction.UPDATE_CUSTOM,
      title: 'Update custom events',
      description: 'Ability to update events created from templates',
    },
    {
      key: EventSecurityResourceAction.EXPORT,
      title: 'Export Events',
      description: 'Ability to export a CSV file containing a list of Events',
    },
    { key: EventSecurityResourceAction.CANCEL, title: 'Cancel events', description: 'Ability to cancel events' },
  ],
};
