import type { SecurityResource } from '@greco/security';

export const ProductOptionResource: SecurityResource = {
  key: 'sales_product',
  module: 'Sales',

  title: 'Product Option',
  description: '',

  context: class {
    productOptionId?: string;
    productId?: string;
  },

  actions: [
    { key: 'READ', title: 'Read product options', description: '' },
    { key: 'CREATE', title: 'Create product options', description: '' },
    { key: 'UPDATE', title: 'Update product options', description: '' },
    { key: 'REMOVE', title: 'Remove product options', description: '' },
  ],
};
