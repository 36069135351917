import { Component, Input } from '@angular/core';
import { BookingStatus, Calendar, EventSeries } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CalendarService, CourseService, EventService } from '../../services';

@Component({
  selector: 'greco-courses-grid',
  templateUrl: './courses-grid.component.html',
  styleUrls: ['./courses-grid.component.scss'],
})
export class CoursesGridComponent {
  constructor(private eventSvc: EventService, private calendarSvc: CalendarService, private courseSvc: CourseService) {}

  @PropertyListener('searchQuery') searchQuery$ = new BehaviorSubject<string>('');
  @Input() searchQuery = '';

  @PropertyListener('user') _user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User | null;

  @PropertyListener('selectedCalendar') _selectedCalendar$ = new BehaviorSubject<Calendar | null>(null);
  @Input() selectedCalendar!: Calendar | null;

  private _communities$ = new BehaviorSubject<Community[]>([]);
  @Input() set communityIds(communityIds: Community[]) {
    this._communities$.next(communityIds);
  }

  communityIds$ = this._communities$.pipe(map(items => items.map(item => item.id)));

  //TODO: Add dates to paginate and pass the community ID
  courses$ = combineLatest([this.communityIds$, this.searchQuery$, this._user$, this._selectedCalendar$]).pipe(
    switchMap(async ([communityIds, searchQuery, user, selectedCalendar]) => {
      const dateNow = new Date().toISOString();
      const result: EventSeries[] = [];
      await Promise.all(
        communityIds.map(async communityId => {
          const calendarIds = [selectedCalendar?.id as string] || [];
          const query = RequestQueryBuilder.create({
            search: {
              title: { $cont: searchQuery },
              availableAsCourse: { $eq: true },
              communityId: { $in: communityIds },
              startDate: { $gt: dateNow },
            },
          });

          const data = await this.eventSvc.paginateSeries(query, communityId, calendarIds, {
            limit: 100,
          });

          if (data.items) result.push(...data.items);
        })
      );
      return {
        courses: result.sort((a, b) => {
          const aTime = a.startDate.getTime();
          const bTime = b.startDate.getTime();
          if (aTime === bTime) return a.title.localeCompare(b.title);
          return aTime - bTime;
        }),
        user,
      };
    }),
    switchMap(async data => {
      const availableToRegister: { course: EventSeries; registrations: number; alreadyBooked: boolean }[] = [];
      const fullyBooked: { course: EventSeries; registrations: number; alreadyBooked: boolean }[] = [];
      const myBookings: { course: EventSeries; registrations: number; alreadyBooked: boolean }[] = [];

      await Promise.all(
        data.courses.map(async course => {
          const registrations = await this.courseSvc.getRegistrationByCourse(
            new RequestQueryBuilder().search({
              courseId: course.id,
              status: { $in: [BookingStatus.CHECKED_IN, BookingStatus.CONFIRMED] },
            }),
            course.id,
            { limit: 100 }
          );
          const alreadyBooked = registrations.items?.some(reg => reg.user?.id === data.user?.id) || false;

          if (alreadyBooked) {
            myBookings.push({ course, registrations: registrations.items?.length || 0, alreadyBooked });
          } else if (registrations.items?.length >= course.maxCapacity) {
            fullyBooked.push({ course, registrations: registrations.items?.length || 0, alreadyBooked });
          } else {
            availableToRegister.push({ course, registrations: registrations.items?.length || 0, alreadyBooked });
          }
        })
      );

      return { fullyBooked, availableToRegister, myBookings };
    })
  );

  addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
}
