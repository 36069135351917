import { User } from '@greco/identity-users';
import { ProductAddon } from '../product-addon';
import { ProductVariant } from '../product-variant';

export interface InventoryProductAddon extends ProductAddon {
  inventories: ProductVariantInventory[];

  outOfStockMessage: string | null;

  displayStockNumber: boolean;
}

export interface ProductVariantInventory {
  id: string;

  addonId: string;
  addon?: InventoryProductAddon;

  variantId: string;
  variant?: ProductVariant;

  stock: ProductVariantInventoryRestock[];

  totalStock: number;
  quantitySold: number;
  availableInventoryCount: number;

  enabled: boolean;

  created: Date;
}

export enum InventoryStockType {
  RESTOCK = 'RESTOCK',
  RETURN = 'RETURN',
}

export interface ProductVariantInventoryRestock {
  id: string;

  inventoryId: string;
  inventory?: ProductVariantInventory;

  stockedOn: Date;

  stockedById: string | null;
  stockedBy?: User | null;

  quantity: number;

  type: InventoryStockType;

  correlationId: string | null;
}
