import { Component, Input } from '@angular/core';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Navigation, Pagination]);

export interface Testimonial {
  title: string;
  tags: string;
  image: string;
}

@Component({
  selector: 'greco-testimonial-carousel',
  templateUrl: './testimonial-carousel.component.html',
  styleUrls: ['./testimonial-carousel.component.scss'],
})
export class TestimonialCarouselComponent {
  @Input() testimonials: Testimonial[] = [
    {
      title: 'LF3 1',
      tags: '#getFit #letsGetIt',
      image: 'https://i.pinimg.com/originals/e5/97/5a/e5975a2f0775537f4901f1234436a98c.jpg',
    },
    {
      title: 'LF3 2',
      tags: '#getFit #letsGetIt',
      image: 'https://pbs.twimg.com/media/CwhpeP7UoAAXvJW.jpg',
    },
    {
      title: 'LF3 3',
      tags: '#getFit #letsGetIt',
      image: 'https://i.pinimg.com/originals/e5/97/5a/e5975a2f0775537f4901f1234436a98c.jpg',
    },
    {
      title: 'LF3 4',
      tags: '#getFit #letsGetIt',
      image: 'https://pbs.twimg.com/media/CwhpeP7UoAAXvJW.jpg',
    },
  ];
}
