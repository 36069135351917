import { Country } from '../country';

export const COUNTRIES_DB_FR: Country[] = [
  {
    name: 'Afghanistan',
    alpha2Code: 'AF',
    alpha3Code: 'AFG',
    numericCode: '004',
  },
  {
    name: 'Albanie',
    alpha2Code: 'AL',
    alpha3Code: 'ALB',
    numericCode: '008',
  },
  {
    name: 'Algérie',
    alpha2Code: 'DZ',
    alpha3Code: 'DZA',
    numericCode: '012',
  },
  {
    name: 'Samoa américaines',
    alpha2Code: 'AS',
    alpha3Code: 'ASM',
    numericCode: '016',
  },
  {
    name: 'Andorre',
    alpha2Code: 'AD',
    alpha3Code: 'ET',
    numericCode: '020',
  },
  {
    name: 'Angola',
    alpha2Code: 'AO',
    alpha3Code: 'AGO',
    numericCode: '024',
  },
  {
    name: 'Antigua-et-Barbuda',
    alpha2Code: 'AG',
    alpha3Code: 'ATG',
    numericCode: '028',
  },
  {
    name: 'Argentine',
    alpha2Code: 'AR',
    alpha3Code: 'ARG',
    numericCode: '032',
  },
  {
    name: 'Arménie',
    alpha2Code: 'AM',
    alpha3Code: 'ARM',
    numericCode: '051',
  },
  {
    name: 'Australie',
    alpha2Code: 'AU',
    alpha3Code: 'AUS',
    numericCode: '036',
  },
  {
    name: 'Autriche',
    alpha2Code: 'AT',
    alpha3Code: 'AUT',
    numericCode: '040',
  },
  {
    name: 'Azerbaïdjan',
    alpha2Code: 'AZ',
    alpha3Code: 'AZE',
    numericCode: '031',
  },
  {
    name: 'Bahamas',
    alpha2Code: 'BS',
    alpha3Code: 'BHS',
    numericCode: '044',
  },
  {
    name: 'Bahreïn',
    alpha2Code: 'BH',
    alpha3Code: 'BHR',
    numericCode: '048',
  },
  {
    name: 'Bangladesh',
    alpha2Code: 'BD',
    alpha3Code: 'BGD',
    numericCode: '050',
  },
  {
    name: 'Barbados',
    alpha2Code: 'BB',
    alpha3Code: 'BRB',
    numericCode: '052',
  },
  {
    name: 'Belarus',
    alpha2Code: 'PAR',
    alpha3Code: 'BLR',
    numericCode: '112',
  },
  {
    name: 'Belgique',
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    numericCode: '056',
  },
  {
    name: 'Belize',
    alpha2Code: 'BZ',
    alpha3Code: 'BLZ',
    numericCode: '084',
  },
  {
    name: 'Bénin',
    alpha2Code: 'BJ',
    alpha3Code: 'BEN',
    numericCode: '204',
  },
  {
    name: 'Bermuda',
    alpha2Code: 'BM',
    alpha3Code: 'BMU',
    numericCode: '060',
  },
  {
    name: 'Bhoutan',
    alpha2Code: 'BT',
    alpha3Code: 'BTN',
    numericCode: '064',
  },
  {
    name: 'Bolivie',
    alpha2Code: 'BO',
    alpha3Code: 'BOL',
    numericCode: '068',
  },
  {
    name: 'Bonaire, Saint-Eustache et Saba',
    alpha2Code: 'BQ',
    alpha3Code: 'BES',
    numericCode: '535',
  },
  {
    name: 'Bosnie-Herzégovine',
    alpha2Code: 'BA',
    alpha3Code: 'BIH',
    numericCode: '070',
  },
  {
    name: 'Botswana',
    alpha2Code: 'BW',
    alpha3Code: 'BWA',
    numericCode: '072',
  },
  {
    name: 'Île Bouvet',
    alpha2Code: 'BV',
    alpha3Code: 'BVT',
    numericCode: '074',
  },
  {
    name: 'Brésil',
    alpha2Code: 'BR',
    alpha3Code: 'BRA',
    numericCode: '076',
  },
  {
    name: 'Brunei',
    alpha2Code: 'BN',
    alpha3Code: 'BRN',
    numericCode: '096',
  },
  {
    name: 'Bulgarie',
    alpha2Code: 'BG',
    alpha3Code: 'BGR',
    numericCode: '100',
  },
  {
    name: 'Burkina Faso',
    alpha2Code: 'BF',
    alpha3Code: 'BFA',
    numericCode: '854',
  },
  {
    name: 'Burundi',
    alpha2Code: 'BI',
    alpha3Code: 'BDI',
    numericCode: '108',
  },
  {
    name: 'Cabo Verde',
    alpha2Code: 'CV',
    alpha3Code: 'CPV',
    numericCode: '132',
  },
  {
    name: 'Cambodge',
    alpha2Code: 'KH',
    alpha3Code: 'KHM',
    numericCode: '116',
  },
  {
    name: 'Cameroun',
    alpha2Code: 'CM',
    alpha3Code: 'CMR',
    numericCode: '120',
  },
  {
    name: 'Canada',
    alpha2Code: 'CA',
    alpha3Code: 'CAN',
    numericCode: '124',
  },
  {
    name: 'République centrafricaine',
    alpha2Code: 'CF',
    alpha3Code: 'CAF',
    numericCode: '140',
  },
  {
    name: 'Chad',
    alpha2Code: 'TD',
    alpha3Code: 'TCD',
    numericCode: '148',
  },
  {
    name: 'Chili',
    alpha2Code: 'CL',
    alpha3Code: 'CHL',
    numericCode: '152',
  },
  {
    name: 'Chine',
    alpha2Code: 'CN',
    alpha3Code: 'CHN',
    numericCode: '156',
  },
  {
    name: 'Colombie',
    alpha2Code: 'CO',
    alpha3Code: 'COL',
    numericCode: '170',
  },
  {
    name: 'Congo (République démocratique du)',
    alpha2Code: 'CD',
    alpha3Code: 'COD',
    numericCode: '180',
  },
  {
    name: 'Congo (République du)',
    alpha2Code: 'CG',
    alpha3Code: 'COG',
    numericCode: '178',
  },
  {
    name: 'Costa Rica',
    alpha2Code: 'CR',
    alpha3Code: 'CRI',
    numericCode: '188',
  },
  {
    name: 'Croatie',
    alpha2Code: 'HR',
    alpha3Code: 'HRV',
    numericCode: '191',
  },
  {
    name: 'Cuba',
    alpha2Code: 'CU',
    alpha3Code: 'CUB',
    numericCode: '192',
  },
  {
    name: 'Chypre',
    alpha2Code: 'CY',
    alpha3Code: 'CYP',
    numericCode: '196',
  },
  {
    name: 'Czechia',
    alpha2Code: 'CZ',
    alpha3Code: 'CZE',
    numericCode: '203',
  },
  {
    name: "Côte d ' Ivoire ",
    alpha2Code: 'CI',
    alpha3Code: 'CIV',
    numericCode: '384',
  },
  {
    name: 'Danemark',
    alpha2Code: 'DK',
    alpha3Code: 'DNK',
    numericCode: '208',
  },
  {
    name: 'Djibouti',
    alpha2Code: 'DJ',
    alpha3Code: 'DJI',
    numericCode: '262',
  },
  {
    name: 'Dominique',
    alpha2Code: 'DM',
    alpha3Code: 'DMA',
    numericCode: '212',
  },
  {
    name: 'République dominicaine',
    alpha2Code: 'DO',
    alpha3Code: 'DOM',
    numericCode: '214',
  },
  {
    name: 'Equateur',
    alpha2Code: 'EC',
    alpha3Code: 'ECU',
    numericCode: '218',
  },
  {
    name: 'Egypte',
    alpha2Code: 'EG',
    alpha3Code: 'EGY',
    numericCode: '818',
  },
  {
    name: 'El Salvador',
    alpha2Code: 'SV',
    alpha3Code: 'SLV',
    numericCode: '222',
  },
  {
    name: 'Guinée équatoriale',
    alpha2Code: 'GQ',
    alpha3Code: 'GNQ',
    numericCode: '226',
  },
  {
    name: 'Erythrée',
    alpha2Code: 'ER',
    alpha3Code: 'ERI',
    numericCode: '232',
  },
  {
    name: 'Estonie',
    alpha2Code: 'EE',
    alpha3Code: 'EST',
    numericCode: '233',
  },
  {
    name: 'Eswatini',
    alpha2Code: 'SZ',
    alpha3Code: 'SWZ',
    numericCode: '748',
  },
  {
    name: 'Ethiopie',
    alpha2Code: 'ET',
    alpha3Code: 'ETH',
    numericCode: '231',
  },
  {
    name: 'Fidji',
    alpha2Code: 'FJ',
    alpha3Code: 'FJI',
    numericCode: '242',
  },
  {
    name: 'Finlande',
    alpha2Code: 'FI',
    alpha3Code: 'FIN',
    numericCode: '246',
  },
  {
    name: 'France',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    numericCode: '250',
  },
  {
    name: 'Gabon',
    alpha2Code: 'GA',
    alpha3Code: 'GAB',
    numericCode: '266',
  },
  {
    name: 'Gambie',
    alpha2Code: 'GM',
    alpha3Code: 'GMB',
    numericCode: '270',
  },
  {
    name: 'Géorgie',
    alpha2Code: 'GE',
    alpha3Code: 'GEO',
    numericCode: '268',
  },
  {
    name: 'Allemagne',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    numericCode: '276',
  },
  {
    name: 'Ghana',
    alpha2Code: 'GH',
    alpha3Code: 'GHA',
    numericCode: '288',
  },
  {
    name: 'Grèce',
    alpha2Code: 'GR',
    alpha3Code: 'GRC',
    numericCode: '300',
  },
  {
    name: 'Groenland',
    alpha2Code: 'GL',
    alpha3Code: 'GRL',
    numericCode: '304',
  },
  {
    name: 'Guadeloupe',
    alpha2Code: 'GP',
    alpha3Code: 'GLP',
    numericCode: '312',
  },
  {
    name: 'Grenada',
    alpha2Code: 'GD',
    alpha3Code: 'GRD',
    numericCode: '308',
  },
  {
    name: 'Guatemala',
    alpha2Code: 'GT',
    alpha3Code: 'GTM',
    numericCode: '320',
  },
  {
    name: 'Guinée',
    alpha2Code: 'GN',
    alpha3Code: 'GIN',
    numericCode: '324',
  },
  {
    name: 'Guinée-Bissau',
    alpha2Code: 'GW',
    alpha3Code: 'GNB',
    numericCode: '624',
  },
  {
    name: 'Guyana',
    alpha2Code: 'GY',
    alpha3Code: 'GUY',
    numericCode: '328',
  },
  {
    name: 'Haïti',
    alpha2Code: 'HT',
    alpha3Code: 'HTI',
    numericCode: '332',
  },
  {
    name: 'Honduras',
    alpha2Code: 'HN',
    alpha3Code: 'HND',
    numericCode: '340',
  },
  {
    name: 'Hong Kong',
    alpha2Code: 'HK',
    alpha3Code: 'HKG',
    numericCode: '344',
  },
  {
    name: 'Hongrie',
    alpha2Code: 'HU',
    alpha3Code: 'HUN',
    numericCode: '348',
  },
  {
    name: 'Islande',
    alpha2Code: 'EST',
    alpha3Code: 'ISL',
    numericCode: '352',
  },
  {
    name: 'Inde',
    alpha2Code: 'IN',
    alpha3Code: 'IND',
    numericCode: '356',
  },
  {
    name: 'Indonésie',
    alpha2Code: 'ID',
    alpha3Code: 'IDN',
    numericCode: '360',
  },
  {
    name: 'Iran',
    alpha2Code: 'IR',
    alpha3Code: 'IRN',
    numericCode: '364',
  },
  {
    name: 'Iraq',
    alpha2Code: 'IQ',
    alpha3Code: 'IRQ',
    numericCode: '368',
  },
  {
    name: 'Irlande',
    alpha2Code: 'IE',
    alpha3Code: 'IRL',
    numericCode: '372',
  },
  {
    name: 'Israël',
    alpha2Code: 'IL',
    alpha3Code: 'ISR',
    numericCode: '376',
  },
  {
    name: 'Italie',
    alpha2Code: 'IT',
    alpha3Code: 'ITA',
    numericCode: '380',
  },
  {
    name: 'Jamaica',
    alpha2Code: 'JM',
    alpha3Code: 'JAM',
    numericCode: '388',
  },
  {
    name: 'Japon',
    alpha2Code: 'JP',
    alpha3Code: 'JPN',
    numericCode: '392',
  },
  {
    name: 'Jersey',
    alpha2Code: 'JE',
    alpha3Code: 'JEY',
    numericCode: '832',
  },
  {
    name: 'Jordan',
    alpha2Code: 'JO',
    alpha3Code: 'JOR',
    numericCode: '400',
  },
  {
    name: 'Kazakhstan',
    alpha2Code: 'KZ',
    alpha3Code: 'KAZ',
    numericCode: '398',
  },
  {
    name: 'Kenya',
    alpha2Code: 'KE',
    alpha3Code: 'KEN',
    numericCode: '404',
  },
  {
    name: 'Kiribati',
    alpha2Code: 'KI',
    alpha3Code: 'KIR',
    numericCode: '296',
  },
  {
    name: 'Corée (République populaire démocratique de)',
    alpha2Code: 'KP',
    alpha3Code: 'PRK',
    numericCode: '408',
  },
  {
    name: 'Corée (République de)  t',
    alpha2Code: 'KR',
    alpha3Code: 'KOR',
    numericCode: '410',
  },
  {
    name: 'Koweït',
    alpha2Code: 'KW',
    alpha3Code: 'KWT',
    numericCode: '414',
  },
  {
    name: 'Kirghizistan',
    alpha2Code: 'KG',
    alpha3Code: 'KGZ',
    numericCode: '417',
  },
  {
    name: 'Laos',
    alpha2Code: 'LA',
    alpha3Code: 'LAO',
    numericCode: '418',
  },
  {
    name: 'Lettonie',
    alpha2Code: 'LV',
    alpha3Code: 'LVA',
    numericCode: '428',
  },
  {
    name: 'Liban',
    alpha2Code: 'LB',
    alpha3Code: 'LBN',
    numericCode: '422',
  },
  {
    name: 'Lesotho',
    alpha2Code: 'LS',
    alpha3Code: 'LSO',
    numericCode: '426',
  },
  {
    name: 'Liberia',
    alpha2Code: 'LR',
    alpha3Code: 'LBR',
    numericCode: '430',
  },
  {
    name: 'Libye',
    alpha2Code: 'LY',
    alpha3Code: 'LBY',
    numericCode: '434',
  },
  {
    name: 'Liechtenstein',
    alpha2Code: 'LI',
    alpha3Code: 'LIE',
    numericCode: '438',
  },
  {
    name: 'Lituanie',
    alpha2Code: 'LT',
    alpha3Code: 'LTU',
    numericCode: '440',
  },
  {
    name: 'Luxembourg',
    alpha2Code: 'LU',
    alpha3Code: 'LUX',
    numericCode: '442',
  },
  {
    name: 'Macao',
    alpha2Code: 'MO',
    alpha3Code: 'MAC',
    numericCode: '446',
  },
  {
    name: 'Madagascar',
    alpha2Code: 'MG',
    alpha3Code: 'MDG',
    numericCode: '450',
  },
  {
    name: 'Malawi',
    alpha2Code: 'MW',
    alpha3Code: 'MWI',
    numericCode: '454',
  },
  {
    name: 'Malaisie',
    alpha2Code: 'MON',
    alpha3Code: 'MYS',
    numericCode: '458',
  },
  {
    name: 'Maldives',
    alpha2Code: 'MV',
    alpha3Code: 'MDV',
    numericCode: '462',
  },
  {
    name: 'Mali',
    alpha2Code: 'ML',
    alpha3Code: 'MLI',
    numericCode: '466',
  },
  {
    name: 'Malte',
    alpha2Code: 'MT',
    alpha3Code: 'MLT',
    numericCode: '470',
  },
  {
    name: 'Mauritania',
    alpha2Code: 'MR',
    alpha3Code: 'MRT',
    numericCode: '478',
  },
  {
    name: 'Mauritius',
    alpha2Code: 'MU',
    alpha3Code: 'MUS',
    numericCode: '480',
  },
  {
    name: 'Mexique',
    alpha2Code: 'MX',
    alpha3Code: 'MEX',
    numericCode: '484',
  },
  {
    name: 'Moldova',
    alpha2Code: 'MD',
    alpha3Code: 'MDA',
    numericCode: '498',
  },
  {
    name: 'Monaco',
    alpha2Code: 'MC',
    alpha3Code: 'MCO',
    numericCode: '492',
  },
  {
    name: 'Mongolie',
    alpha2Code: 'MN',
    alpha3Code: 'MNG',
    numericCode: '496',
  },
  {
    name: 'Monténégro',
    alpha2Code: 'ME',
    alpha3Code: 'MNE',
    numericCode: '499',
  },
  {
    name: 'Maroc',
    alpha2Code: 'MA',
    alpha3Code: 'MAR',
    numericCode: '504',
  },
  {
    name: 'Mozambique',
    alpha2Code: 'MZ',
    alpha3Code: 'MOZ',
    numericCode: '508',
  },
  {
    name: 'Myanmar',
    alpha2Code: 'MM',
    alpha3Code: 'MMR',
    numericCode: '104',
  },
  {
    name: 'Namibie',
    alpha2Code: 'NA',
    alpha3Code: 'NAM',
    numericCode: '516',
  },
  {
    name: 'Nauru',
    alpha2Code: 'NR',
    alpha3Code: 'NRU',
    numericCode: '520',
  },
  {
    name: 'Népal',
    alpha2Code: 'NP',
    alpha3Code: 'NPL',
    numericCode: '524',
  },
  {
    name: 'Pays-Bas',
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    numericCode: '528',
  },
  {
    name: 'Nouvelle Calédonie',
    alpha2Code: 'NC',
    alpha3Code: 'NCL',
    numericCode: '540',
  },
  {
    name: 'Nouvelle-Zélande',
    alpha2Code: 'NZ',
    alpha3Code: 'NZL',
    numericCode: '554',
  },
  {
    name: 'Nicaragua',
    alpha2Code: 'NI',
    alpha3Code: 'NIC',
    numericCode: '558',
  },
  {
    name: 'Niger',
    alpha2Code: 'NE',
    alpha3Code: 'NER',
    numericCode: '562',
  },
  {
    name: 'Nigeria',
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    numericCode: '566',
  },
  {
    name: 'Norvège',
    alpha2Code: 'NON',
    alpha3Code: 'NOR',
    numericCode: '578',
  },
  {
    name: 'Oman',
    alpha2Code: 'OM',
    alpha3Code: 'OMN',
    numericCode: '512',
  },
  {
    name: 'Pakistan',
    alpha2Code: 'PK',
    alpha3Code: 'PAK',
    numericCode: '586',
  },
  {
    name: 'Palau',
    alpha2Code: 'PW',
    alpha3Code: 'PLW',
    numericCode: '585',
  },
  {
    name: 'Palestine',
    alpha2Code: 'PS',
    alpha3Code: 'PSE',
    numericCode: '275',
  },
  {
    name: 'Panama',
    alpha2Code: 'PA',
    alpha3Code: 'PAN',
    numericCode: '591',
  },
  {
    name: 'Papouasie-Nouvelle-Guinée',
    alpha2Code: 'PG',
    alpha3Code: 'PNG',
    numericCode: '598',
  },
  {
    name: 'Paraguay',
    alpha2Code: 'PY',
    alpha3Code: 'PRY',
    numericCode: '600',
  },
  {
    name: 'Pérou',
    alpha2Code: 'PE',
    alpha3Code: 'PER',
    numericCode: '604',
  },
  {
    name: 'Philippines',
    alpha2Code: 'PH',
    alpha3Code: 'PHL',
    numericCode: '608',
  },
  {
    name: 'Pologne',
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    numericCode: '616',
  },
  {
    name: 'Portugal',
    alpha2Code: 'PT',
    alpha3Code: 'PRT',
    numericCode: '620',
  },
  {
    name: 'Puerto Rico',
    alpha2Code: 'PR',
    alpha3Code: 'PRI',
    numericCode: '630',
  },
  {
    name: 'Qatar',
    alpha2Code: 'QA',
    alpha3Code: 'QAT',
    numericCode: '634',
  },
  {
    name: 'République de Macédoine du Nord',
    alpha2Code: 'MK',
    alpha3Code: 'MKD',
    numericCode: '807',
  },
  {
    name: 'Roumanie',
    alpha2Code: 'RO',
    alpha3Code: 'ROU',
    numericCode: '642',
  },
  {
    name: 'Fédération de Russie (la)',
    alpha2Code: 'RU',
    alpha3Code: 'RUS',
    numericCode: '643',
  },
  {
    name: 'Rwanda',
    alpha2Code: 'RW',
    alpha3Code: 'RWA',
    numericCode: '646',
  },
  {
    name: 'Saint Kitts et Nevis',
    alpha2Code: 'KN',
    alpha3Code: 'KNA',
    numericCode: '659',
  },
  {
    name: 'Sainte Lucie',
    alpha2Code: 'LC',
    alpha3Code: 'LCA',
    numericCode: '662',
  },
  {
    name: 'Saint-Vincent-et-les Grenadines',
    alpha2Code: 'VC',
    alpha3Code: 'VCT',
    numericCode: '670',
  },
  {
    name: 'Samoa',
    alpha2Code: 'WS',
    alpha3Code: 'WSM',
    numericCode: '882',
  },
  {
    name: 'Saint-Marin',
    alpha2Code: 'SM',
    alpha3Code: 'SMR',
    numericCode: '674',
  },
  {
    name: 'Sao Tomé-et-Principe',
    alpha2Code: 'ST',
    alpha3Code: 'STP',
    numericCode: '678',
  },
  {
    name: 'Arabie saoudite',
    alpha2Code: 'SA',
    alpha3Code: 'SAU',
    numericCode: '682',
  },
  {
    name: 'Sénégal',
    alpha2Code: 'SN',
    alpha3Code: 'SEN',
    numericCode: '686',
  },
  {
    name: 'Serbie',
    alpha2Code: 'RS',
    alpha3Code: 'SRB',
    numericCode: '688',
  },
  {
    name: 'Seychelles',
    alpha2Code: 'SC',
    alpha3Code: 'SYC',
    numericCode: '690',
  },
  {
    name: 'Sierra Leone',
    alpha2Code: 'SL',
    alpha3Code: 'SLE',
    numericCode: '694',
  },
  {
    name: 'Singapour',
    alpha2Code: 'SG',
    alpha3Code: 'SGP',
    numericCode: '702',
  },
  {
    name: 'Slovaquie',
    alpha2Code: 'SK',
    alpha3Code: 'SVK',
    numericCode: '703',
  },
  {
    name: 'Slovénie',
    alpha2Code: 'SI',
    alpha3Code: 'SVN',
    numericCode: '705',
  },
  {
    name: 'Îles Salomon',
    alpha2Code: 'SB',
    alpha3Code: 'SLB',
    numericCode: '090',
  },
  {
    name: 'Somalie',
    alpha2Code: 'SO',
    alpha3Code: 'SOM',
    numericCode: '706',
  },
  {
    name: 'Afrique du Sud',
    alpha2Code: 'ZA',
    alpha3Code: 'ZAF',
    numericCode: '710',
  },
  {
    name: 'Soudan du Sud',
    alpha2Code: 'SS',
    alpha3Code: 'SSD',
    numericCode: '728',
  },
  {
    name: 'Espagne',
    alpha2Code: 'ES',
    alpha3Code: 'ESP',
    numericCode: '724',
  },
  {
    name: 'Sri Lanka',
    alpha2Code: 'LK',
    alpha3Code: 'LKA',
    numericCode: '144',
  },
  {
    name: 'Soudan',
    alpha2Code: 'SD',
    alpha3Code: 'SDN',
    numericCode: '729',
  },
  {
    name: 'Suriname',
    alpha2Code: 'SR',
    alpha3Code: 'SUR',
    numericCode: '740',
  },
  {
    name: 'Suède',
    alpha2Code: 'SE',
    alpha3Code: 'SWE',
    numericCode: '752',
  },
  {
    name: 'Suisse',
    alpha2Code: 'CH',
    alpha3Code: 'CHE',
    numericCode: '756',
  },
  {
    name: 'République arabe syrienne',
    alpha2Code: 'SY',
    alpha3Code: 'SYR',
    numericCode: '760',
  },
  {
    name: 'Taiwan',
    alpha2Code: 'TW',
    alpha3Code: 'TWN',
    numericCode: '158',
  },
  {
    name: 'Tadjikistan',
    alpha2Code: 'TJ',
    alpha3Code: 'TJK',
    numericCode: '762',
  },
  {
    name: 'Tanzanie',
    alpha2Code: 'TZ',
    alpha3Code: 'TZA',
    numericCode: '834',
  },
  {
    name: 'Thaïlande',
    alpha2Code: 'TH',
    alpha3Code: 'THA',
    numericCode: '764',
  },
  {
    name: 'Timor-Leste',
    alpha2Code: 'TL',
    alpha3Code: 'TLS',
    numericCode: '626',
  },
  {
    name: 'Togo',
    alpha2Code: 'TG',
    alpha3Code: 'TGO',
    numericCode: '768',
  },
  {
    name: 'Tonga',
    alpha2Code: 'TO',
    alpha3Code: 'TON',
    numericCode: '776',
  },
  {
    name: 'Trinité-et-Tobago',
    alpha2Code: 'TT',
    alpha3Code: 'TTO',
    numericCode: '780',
  },
  {
    name: 'Tunisie',
    alpha2Code: 'TN',
    alpha3Code: 'TUN',
    numericCode: '788',
  },
  {
    name: 'Turquie',
    alpha2Code: 'TR',
    alpha3Code: 'TUR',
    numericCode: '792',
  },
  {
    name: 'Turkménistan',
    alpha2Code: 'TM',
    alpha3Code: 'TKM',
    numericCode: '795',
  },
  {
    name: 'Tuvalu',
    alpha2Code: 'TV',
    alpha3Code: 'TUV',
    numericCode: '798',
  },
  {
    name: 'Ouganda',
    alpha2Code: 'UG',
    alpha3Code: 'UGA',
    numericCode: '800',
  },
  {
    name: 'Ukraine',
    alpha2Code: 'UA',
    alpha3Code: 'UKR',
    numericCode: '804',
  },
  {
    name: 'Emirats Arabes Unis',
    alpha2Code: 'AE',
    alpha3Code: 'ARE',
    numericCode: '784',
  },
  {
    name: 'Royaume-Uni',
    alpha2Code: 'GB',
    alpha3Code: 'GBR',
    numericCode: '826',
  },
  {
    name: "États-Unis d'Amérique",
    alpha2Code: 'US',
    alpha3Code: 'USA',
    numericCode: '840',
  },
  {
    name: 'Uruguay',
    alpha2Code: 'UY',
    alpha3Code: 'URY',
    numericCode: '858',
  },
  {
    name: 'Ouzbékistan',
    alpha2Code: 'UZ',
    alpha3Code: 'UZB',
    numericCode: '860',
  },
  {
    name: 'Vanuatu',
    alpha2Code: 'VU',
    alpha3Code: 'VUT',
    numericCode: '548',
  },
  {
    name: 'Venezuela',
    alpha2Code: 'VE',
    alpha3Code: 'VEN',
    numericCode: '862',
  },
  {
    name: 'Vietnam',
    alpha2Code: 'VN',
    alpha3Code: 'VNM',
    numericCode: '704',
  },
  {
    name: 'Yémen',
    alpha2Code: 'OUI',
    alpha3Code: 'YEM',
    numericCode: '887',
  },
  {
    name: 'Zambie',
    alpha2Code: 'ZM',
    alpha3Code: 'ZMB',
    numericCode: '894',
  },
  {
    name: 'Zimbabwe',
    alpha2Code: 'ZW',
    alpha3Code: 'ZWE',
    numericCode: '716',
  },
];
