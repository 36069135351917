<ng-container *grecoLet="canUpdate$ | async as canUpdate">
  <greco-collapsible-section #details [header]="{ title: 'Details', icon: 'info' }" [disabled]="true">
    <greco-form-save-buttons
      header
      *ngIf="details.expanded && canUpdate"
      [form]="detailsForm"
      [saveAction]="saveDetails"
      [resetValue]="{ eventTemplateDetails: resetDetailsValue }"
    ></greco-form-save-buttons>

    <form [formGroup]="detailsForm">
      <greco-event-templates-input
        [communityId]="eventTemplate?.communityId || ''"
        [readonly]="!canUpdate"
        [required]="false"
        [initialImage]="eventTemplate?.imageUrl"
        formControlName="eventTemplateDetails"
      ></greco-event-templates-input>
    </form>
  </greco-collapsible-section>
</ng-container>
