<input
  matInput
  type="text"
  [required]="required"
  [matAutocomplete]="auto"
  [placeholder]="placeholder"
  [formControl]="formControl"
  (input)="search($any($event.target).value)"
  (blur)="touched()"
/>

<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayWith">
  <mat-option *ngFor="let account of accounts" [value]="account">
    {{ account.name }}
  </mat-option>
</mat-autocomplete>
