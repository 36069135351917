import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import type { AddonDto } from '@greco/nestjs-sales-products';
import { AddonType, ProductAddon } from '@greco/sales-products';

@Injectable()
export class AddonsService {
  constructor(private http: HttpClient) {}

  // @Get()
  async getOneByType(productId: string, type: AddonType) {
    return await toPromise(this.http.get<ProductAddon>(`/api/products/${productId}/addons/${type}`));
  }

  // @Get()
  async paginate(productId: string, pagination?: PaginatedQueryParams) {
    return await toPromise(
      this.http.get<PaginatedDto<ProductAddon>>(`/api/products/${productId}/addons`, {
        params: {
          page: (pagination?.page || 1)?.toString(),
          limit: (pagination?.limit || 10)?.toString(),
        },
      })
    );
  }

  // @Post()
  async addAddon(productId: string, data: AddonDto) {
    return await toPromise(this.http.post<ProductAddon>(`/api/products/${productId}/addons`, data));
  }

  // @Post(':addonId')
  async updateAddonConfiguration(productId: string, addonId: string, body: AddonDto) {
    return await toPromise(this.http.post<ProductAddon>(`/api/products/${productId}/addons/${addonId}`, body));
  }

  // @Post(':addonId/enabled')
  async updateAddonStatus(productId: string, addonId: string, enabled: boolean) {
    return await toPromise(
      this.http.post<ProductAddon>(`/api/products/${productId}/addons/${addonId}/status`, { enabled })
    );
  }

  // @Delete(':addonId')
  async removeAddonConfiguration(productId: string, addonId: string) {
    return await toPromise(this.http.delete<any>(`/api/products/${productId}/addons/${addonId}`));
  }
}
