import { CouponAssignment } from '@greco/coupon';
import { Tax } from '@greco/finance-tax';
import { Community } from '@greco/identity-communities';
import { SaleCategory } from '@greco/sales-purchases';
import { ProductImage } from './product-image';
import { ProductOption } from './product-option';
import { ProductVariant } from './product-variant';

export enum ProductStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export interface Product {
  id: string;
  community: Community;

  status: ProductStatus;

  title: string;
  description?: string;
  saleCategoryId?: string;
  saleCategory?: SaleCategory;

  images: ProductImage[];
  variants: ProductVariant[];
  options: ProductOption[];
  taxes: Tax[];
  ignoreTaxes: boolean;
}
export interface ProductCouponAssignment extends CouponAssignment {
  productId: string;
  variant?: ProductVariant;
}

export interface SaleCategoryCouponAssignment extends CouponAssignment {
  saleCategoryId: string;
  saleCategory?: SaleCategory;
}
