<form [formGroup]="form">
  <ng-template matStepLabel>Product</ng-template>
  <div *ngIf="mode === 'staff'; else userMode">
    <mat-form-field appearance="standard">
      <mat-label>Product Variant</mat-label>
      <!-- *grecoLet="currentVariantId$ | async as variantId"
      [variantIdsToExclude]="variantId ? [variantId] : []" -->
      <greco-product-variant-picker
        [onlyRecurring]="true"
        placeholder="Start typing to search for products..."
        [communityId]="communityId"
        [required]="true"
        formControlName="variant"
      ></greco-product-variant-picker>
    </mat-form-field>
  </div>
  <ng-template #userMode>
    <ng-container
      *grecoLet="
        ($any(subscription)?.items[0].variant?.product.addons | filterBy : ['type'] : 'SELF-SERVE UPDATE')[0]
          ?.upgradeCandidates | filterBy : ['status'] : 'ACTIVE' as products
      "
    >
      <greco-lazyformcontrol
        [formControl]="$any(form.get('variant'))"
        [value]="
          ($any((products | filterBy : ['id'] : radioGroup.value)[0]).variants
            | filterBy
              : ['id']
              : radioGroupElem
                  .querySelector('#' + (radioGroup.value || products[0]?.id) + ' mat-select')
                  ?.getAttribute('ng-reflect-value'))[0]
        "
      ></greco-lazyformcontrol>
      <mat-radio-group
        #radioGroupElem
        #radioGroup="matRadioGroup"
        [style.display]="products.length ? 'block' : 'none'"
        [value]="products[0]?.id"
      >
        <div
          style="
            display: flex;
            gap: 8px;
            flex-direction: column;
            margin-bottom: 16px;
            max-height: 340px;
            overflow-y: scroll;
          "
        >
          <greco-box [id]="product.id" style="display: flex; gap: 12px" *ngFor="let product of products">
            <div style="flex: 1">
              <mat-radio-button [value]="product.id">
                <h4 style="margin-bottom: 0; margin-left: 8px">
                  {{ product.title }}
                  <a [routerLink]="'./shop/products/' + product.id" target="_blank">
                    <mat-icon style="width: 16px; height: 16px; font-size: 16px; color: gray">open_in_new</mat-icon>
                  </a>
                </h4>
              </mat-radio-button>
              <ng-container *grecoLet="product.variants | filterBy : ['status'] : 'ACTIVE' as variants">
                <mat-form-field
                  style="width: calc(100% - 36px); margin-left: 36px; margin-top: 12px; margin-bottom: -1.25em"
                >
                  <mat-label>Option</mat-label>
                  <mat-select #select="matSelect" [value]="select.value || variants[0].id">
                    <mat-option *ngFor="let variant of variants" [value]="variant.id">
                      <strong>{{ variant | variantPrice }}</strong>
                      {{ ' - ' }}
                      {{
                        variant.title
                          ? variant.title + ' (' + (variant.variantOpts | pluck : 'value')?.join(', ') + ')'
                          : (variant.variantOpts | pluck : 'value')?.join(', ')
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </div>
            <img
              *ngIf="product.images?.length"
              [src]="product.images[0].imageURL"
              style="display: block; object-fit: cover; height: 82px; width: 82px; border-radius: 8px"
            />
          </greco-box>
        </div>
      </mat-radio-group>
    </ng-container>
  </ng-template>
  <button mat-stroked-button matStepperNext style="margin-top: 16px; margin-left: auto">
    Continue
    <mat-icon>chevron_right</mat-icon>
  </button>
</form>
