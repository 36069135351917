<greco-table [data]="invoices" [loading]="loading" [highlight]="true">
  <!-- Invoice ID & Number-->
  <ng-container *grecoTableCol="''; let invoice; fitContent: true">
    <div
      [matTooltip]="invoice.id + (invoice.data.number ? ' (' + invoice.data.number + ')' : '')"
      style="display: flex; align-items: center; justify-content: center"
    >
      <img
        style="width: 36px; min-width: 36px"
        *ngIf="invoice.data.charge?.payment_method_details?.card?.brand; else noCard"
        [src]="'assets/payment-methods/' + invoice.data.charge.payment_method_details.card.brand + '.png'"
      />

      <ng-template #noCard>
        <mat-icon>payment</mat-icon>
      </ng-template>
    </div>
  </ng-container>

  <!-- Created -->
  <p *grecoTableCol="'Created'; let invoice">
    {{ invoice.data.created | grecoTimestamp | date }} {{ invoice.data.created | grecoTimestamp | date : 'shortTime' }}
  </p>

  <!-- Subtotal -->
  <p *grecoTableCol="'Subtotal'; let invoice; alignRight: true">
    {{ (invoice.data.subtotal || 0) / 100 | currency }}
  </p>

  <!-- Taxes -->
  <p *grecoTableCol="'Taxes'; let invoice; alignRight: true">
    {{ (invoice.data.tax || 0) / 100 | currency }}
  </p>

  <!-- Discount -->
  <p *grecoTableCol="'Discount'; let invoice; alignRight: true">
    <ng-template #noDiscount>-</ng-template>
    <ng-container *ngIf="invoice.data.discount?.coupon as coupon; else noDiscount">
      <ng-container *ngIf="coupon.amount_off">
        {{ $any([invoice.data.subtotal, coupon.amount_off] | min) / 100 | currency }}
      </ng-container>
      <ng-container *ngIf="coupon.percent_off">
        {{ (coupon.percent_off * invoice.data.subtotal) / 10000 | currency }}
      </ng-container>
    </ng-container>
  </p>

  <!-- Total -->
  <p *grecoTableCol="'Total'; let invoice; alignRight: true">
    <strong>{{ (invoice.data.total || 0) / 100 | currency }}</strong>
  </p>

  <!-- Balance Used -->
  <p *grecoTableCol="'Balance Used'; let invoice; alignRight: true">
    {{ (invoice.data.ending_balance - invoice.data.starting_balance) / 100 | currency }}
  </p>

  <!-- Charge Amount -->
  <p *grecoTableCol="'Charge Amount'; let invoice; alignRight: true">
    <ng-container
      *grecoLet="
        invoice.data?.charge
          ? invoice.data?.charge.amount >= 50
            ? invoice.data?.charge.amount
            : 0
          : invoice.data?.metadata?.payment_intent
          ? invoice.data?.amount_remaining || 0
          : 0 as amount
      "
    >
      {{ amount ? (amount / 100 | currency) : '-' }}
    </ng-container>
  </p>

  <!-- Refunded -->
  <p *grecoTableCol="'Refunded'; let invoice; alignRight: true">
    {{ invoice.data.charge?.amount_refunded ? (invoice.data.charge?.amount_refunded / 100 | currency) : '-' }}
  </p>

  <!-- Items -->
  <p *grecoTableCol="'Items'; let invoice">
    {{ invoice.data.lines.data | pluck : 'description' | grecoJoin : ' | ' }}
  </p>

  <!-- Studio -->
  <p *grecoTableCol="'Studio'; let invoice">
    {{ invoice.studio }}
  </p>

  <!-- Billing Reason -->
  <p *grecoTableCol="'Billing Reason'; let invoice">
    {{ invoice.data.billing_reason || '' | titlecase }}
  </p>

  <!-- Status -->
  <mat-chip-list *grecoTableCol="'Status'; let invoice">
    <mat-chip>
      {{ invoice.data.status | titlecase }}
      <ng-container *ngIf="invoice.data.status === 'open' && invoice.data.charge">
        &nbsp;&nbsp;|&nbsp;{{ invoice.data.charge.status | titlecase }}
      </ng-container>
    </mat-chip>
  </mat-chip-list>

  <!-- Options (urls) -->
  <ng-container *grecoTableCol="''; let invoice; fitContent: true; stickyEnd: true">
    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <a mat-menu-item target="_blank" [href]="invoice.data.invoice_pdf" [disabled]="!invoice.data.invoice_pdf">
        <mat-icon>open_in_new</mat-icon>
        <span>PDF Receipt</span>
      </a>
      <a
        mat-menu-item
        target="_blank"
        [href]="invoice.data.hosted_invoice_url"
        [disabled]="!invoice.data.hosted_invoice_url"
      >
        <mat-icon>open_in_new</mat-icon>
        <span>Online Receipt</span>
      </a>
    </mat-menu>
  </ng-container>
</greco-table>

<div style="padding: 8px">
  <button
    color="primary"
    mat-stroked-button
    [disabled]="loading"
    *ngIf="invoices.length && !reachedLastPage"
    (click)="loadPage(invoices[invoices.length - 1].id)"
    style="margin-left: auto; height: 32px; line-height: 16px"
  >
    <span>Load more</span>
  </button>
</div>
