import { Component, Input } from '@angular/core';
import { Community } from '@greco/identity-communities';

@Component({
  selector: 'greco-tiles-page',
  templateUrl: './tiles.page.html',
  styleUrls: ['./tiles.page.scss'],
})
export class TilesPage {
  @Input() community?: Community;
}
