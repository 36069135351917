<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()"
>
</greco-filter-bar>

<greco-table [data]="(perks$ | async) || []" [highlight]="false" [loading]="loading">
  <div class="badge-col" *grecoTableCol="''; let perk; fitContent: true" (click)="updatePerkBadge(perk)">
    <greco-perk-badge
      [small]="true"
      [text]="perk.badge.text"
      [color]="perk.badge.color"
      [shape]="perk.badge.shape"
      [icon]="perk.badge.icon"
    ></greco-perk-badge>

    <mat-icon class="edit-icon">create</mat-icon>
  </div>

  <p *grecoTableCol="'Perk'; let perk">{{ perk.title }}</p>

  <p *grecoTableCol="'Module'; let perk">{{ perk.module?.replaceAll('_', ' ') | titlecase }}</p>

  <p *grecoTableCol="'Type'; let perk">{{ perk.type?.replaceAll('_', ' ') | titlecase }}</p>

  <p *grecoTableCol="'Available in Sub-Communities'; let perk">
    {{ perk | perkSubCommunities }}
    <small>
      <em><a style="color: var(--color-primary); cursor: pointer" (click)="toggleSubAvailable(perk)">(Toggle)</a></em>
    </small>
  </p>

  <ng-container
    *grecoTableCol="'Pending Reviews'; let perk;"
    matTooltip="This is the number unapproved variant perks corresponding to this perk."
  >
    <p *ngIf="!perk.pendingReviews.count">-</p>

    <!-- TODO(adaoust): Pending reviews link, need to know the community review is for -> [routerLink]="['/admin/sales',perk.community.id,'products',perk.pendingReviews.productId,'variants',perk.pendingReviews.variantId]" -->
    <a *ngIf="perk.pendingReviews.count" style="color: var(--primary-color); cursor: pointer">
      {{ perk.pendingReviews.count + ' Product Variants' }}
    </a>
  </ng-container>

  <p *grecoTableCol="'Users w/ Perk'; let perk">{{ perk.userCount ? perk.userCount : '-' }}</p>

  <!-- <ng-container *grecoTableCol="''; let perk; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu> -->
  <!-- <button mat-menu-item>
        <mat-icon>toggle_on</mat-icon>
        <span>Toggle Sub-Community Availability</span>
      </button> -->
  <!-- <button mat-menu-item>
        <mat-icon>open_in_new</mat-icon>
        <span>View Details</span>
      </button> -->
  <!-- </mat-menu>
  </ng-container> -->
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [pageSizeOptions]="[10, 20, 50]"
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
