<div [formGroup]="_form" class="greco-account-taxes-picker-container">
  <mat-chip-list #taxes aria-label="Tax Selection" [required]="false">
    <mat-chip *ngFor="let tax of value" [selectable]="true" [removable]="!disabled" (removed)="remove(tax)">
      {{ $any(tax).title || tax }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #input
      matInput
      type="text"
      [disabled]="disabled"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      [matChipInputFor]="taxes"
      (focus)="openAutocomplete()"
      (input)="_form.get('search')?.setValue(input.value)"
      [matChipInputSeparatorKeyCodes]="[]"
    />
    <ng-content matSuffix></ng-content>
  </mat-chip-list>

  <mat-autocomplete
    style="width: max-content"
    panelWidth="max-content"
    #auto="matAutocomplete"
    (optionSelected)="optionSelected($event); input.value = ''; _form.get('search')?.setValue('')"
  >
    <mat-option #matOption *ngFor="let tax of taxes$ | async" [value]="tax">
      {{ tax?.title }}
      <span
        ><small>{{ ' (' + tax?.abbreviation + ')' }}</small></span
      >
    </mat-option>
  </mat-autocomplete>
</div>
