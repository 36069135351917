import { IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class TilesGroupDto {
  constructor(dto?: TilesGroupDto) {
    if (dto) Object.assign(this, dto);
  }

  @IsString()
  @IsOptional()
  id?: string;

  @IsString()
  @IsNotEmpty()
  communityId!: string;

  @IsString()
  @IsNotEmpty()
  name!: string;
}
