import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgreementStatus, UserCommunityAgreement } from '@greco/community-agreements';
import { User } from '@greco/identity-users';
import { SignatureService } from '@greco/ngx-identity-users';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { UserCommunityAgreementsService } from '../../services';

@Component({
  selector: 'greco-sign-agreement',
  templateUrl: './sign-agreement.dialog.html',
  styleUrls: ['./sign-agreement.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SignAgreementDialog implements OnInit, OnDestroy {
  constructor(
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private signatureService: SignatureService,
    private agreementService: UserCommunityAgreementsService,
    @Inject(MAT_DIALOG_DATA) public agreement: UserCommunityAgreement
  ) {}

  @ViewChild(SimpleDialog) private container!: SimpleDialog;

  private _onDestroy$ = new Subject<void>();

  checked = false;
  signature?: string;

  user?: User;

  formGroup = this.formBuilder.group({
    checkbox: [false, Validators.requiredTrue],
  });

  setSignature(event: string) {
    this.signature = event;
    if (this.signature != '') {
      this.formGroup.patchValue({ checkbox: true });
    }
  }

  getResult = async () => {
    if (this.user && this.signature) {
      let signature: any = null;

      try {
        signature = await this.signatureService.getOne(this.user?.id);
      } catch (err) {
        console.log(err);
      }

      if (signature) {
        if (this.signature !== signature.signature) await this.signatureService.update(this.user?.id, this.signature);
      } else await this.signatureService.create({ userId: this.user.id, signature: this.signature });
    }

    try {
      const agreement = await this.agreementService.signUserAgrmt(this.agreement.id, this.signature);

      if (agreement.status === AgreementStatus.SIGNED) {
        this.snacks.open(agreement.signedAgreementTitle + ' has been signed!', 'Ok', {
          duration: 3000,
          panelClass: 'mat-primary',
        });
      }
      return agreement;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  ngOnInit() {
    this.checked = false;
    this.formGroup.valueChanges
      .pipe(
        startWith(this.formGroup.value),
        takeUntil(this._onDestroy$),
        map(() => this.container?.data?.buttons?.find(btn => btn.role === 'create'))
      )
      .subscribe(button => button && ((button as any).disabled = this.formGroup.invalid));
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
