import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataConnector } from '@greco-fit/util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NgxFirestoreConnector implements DataConnector<'observable'> {
  constructor(private ngFire: AngularFirestore) {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  getDoc<T extends object>(path: string): Observable<T> {
    const doc = this.ngFire.doc<T>(path);
    return doc.valueChanges().pipe(map(data => (data ? ({ ...data, id: doc.ref.id } as T) : null)));
  }
}
