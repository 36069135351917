<greco-simple-dialog [data]="dialogData">
  <greco-purchases-table
    [userId]="subscription.userId"
    [accountId]="subscription.accountId"
    [isAdminPage]="false"
    [pageSizes]="[3]"
    [queryBuilder]="queryBuilder"
  ></greco-purchases-table>

  <form [formGroup]="formGroup">
    <div style="display: flex; flex-direction: column; align-items: flex; gap: 8px; margin-top: 6px">
      <!-- Cancellation Type  -->
      <mat-radio-group formControlName="dateRadioButton" aria-label="Start Subscription: ">
        <mat-radio-button style="margin-left: 16px" color="primary" value="now">Now</mat-radio-button>
        <mat-radio-button style="margin-left: 16px" color="primary" value="endOfPeriod">
          At the end of current period
        </mat-radio-button>
        <mat-radio-button style="margin: 10px" color="primary" value="future"> Custom Date </mat-radio-button>
      </mat-radio-group>

      <!-- Cancellation Proration -->
      <div
        [matTooltip]="canProrate ? '' : 'You do not have permission to prorate cancellations'"
        matTooltipPosition="above"
        style="display: flex"
      >
        <!-- <mat-checkbox
          style="margin-left: auto; align-self: center"
          color="primary"
          [disabled]="!canProrate"
          formControlName="prorateCancellation"
          labelPosition="before"
          ><small>Prorate unused to balance</small>
        </mat-checkbox> -->

        <mat-radio-group *ngIf="canProrate" formControlName="prorateRadioButton" aria-label="Proration: ">
          <mat-radio-button style="margin-left: 16px" color="primary" value="none">No proration</mat-radio-button>
          <mat-radio-button style="margin-left: 16px" color="primary" value="balance">
            Prorate unused to balance
          </mat-radio-button>
          <mat-radio-button style="margin: 10px" color="primary" value="card">
            Prorate unused to payment method
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <!-- Cancellation Date  -->
    <div>
      <mat-form-field *ngIf="formGroup.value.dateRadioButton == 'future'" style="margin-top: 6px" appearance="outline">
        <mat-label>Choose a date</mat-label>
        <input
          matInput
          [min]="minimalDate"
          [max]="maximalDate"
          [matDatepicker]="picker"
          (click)="picker.open()"
          formControlName="dateSelection"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- Cancellation Details -->
    <div>
      <mat-form-field style="margin-top: 6px" appearance="outline" class="custom">
        <mat-label>Reason</mat-label>
        <mat-select required formControlName="cancellationDetails" [matMenuTriggerFor]="cancellationReasonsMenu">
          <mat-menu #cancellationReasonsMenu>
            <mat-option *ngFor="let reason of cancellationReasons" style="max-height: 40px" [value]="reason">
              {{ reason }}
            </mat-option>

            <button mat-menu-item style="font-weight: bold" [matMenuTriggerFor]="addOnProductCancellation">
              Add On Product Cancellation

              <mat-menu #addOnProductCancellation>
                <mat-option
                  *ngFor="let addOnProductCancellationReason of addOnProductCancellationReasons"
                  [value]="addOnProductCancellationReason"
                >
                  {{ addOnProductCancellationReason }}
                </mat-option>
              </mat-menu>
            </button>

            <button mat-menu-item style="font-weight: bold" [matMenuTriggerFor]="membershipCancellation">
              Membership Cancellation

              <mat-menu #membershipCancellation>
                <mat-option
                  *ngFor="let membershipCancellationReason of membershipCancellationReasons"
                  [value]="membershipCancellationReason"
                >
                  {{ membershipCancellationReason }}
                </mat-option>
              </mat-menu>
            </button>

            <button mat-menu-item style="font-weight: bold" [matMenuTriggerFor]="activeKidsClub">
              Active Kids Club

              <mat-menu #activeKidsClub>
                <mat-option *ngFor="let activeKidsClubReason of activeKidsClubReasons" [value]="activeKidsClubReason">
                  {{ activeKidsClubReason }}
                </mat-option>
              </mat-menu>
            </button>
          </mat-menu>
        </mat-select>
      </mat-form-field>

      <mat-error
        style="font-size: 80%; margin: -12px 0 12px 0; max-width: 620px; color: var(--warning-color)"
        *ngIf="formGroup.get('prorateCancellation')?.value"
      >
        <strong>Warning: </strong>When prorating a cancellation, the unused portion of the current period will be
        reimbursed to the member's account balance. This will always take place even if past payments have failed.
        Ensure you are following cancellation policies.
      </mat-error>
    </div>

    <!-- Confirmation -->
    <div style="display: flex; align-items: center; gap: 8px">
      <button mat-stroked-button type="button" (click)="close()" style="flex: 1">
        <span>Cancel</span>
      </button>

      <button
        type="button"
        color="primary"
        style="flex: 1"
        mat-stroked-button
        [disabled]="processing || formGroup.invalid"
        (click)="submitCancellation()"
      >
        <span>Confirm</span>
        <mat-progress-spinner
          diameter="18"
          mode="indeterminate"
          *ngIf="processing"
          style="filter: grayscale(1) opacity(0.5)"
        ></mat-progress-spinner>
      </button>
    </div>
  </form>
</greco-simple-dialog>
