<form [formGroup]="formGroup">
  <mat-form-field appearance="standard">
    <mat-label>Collections</mat-label>
    <greco-collections-input [required]="true" formControlName="collections"></greco-collections-input>
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>Shop Order Priority</mat-label>
    <input matInput type="number" [required]="true" formControlName="priority" />
    <mat-hint>Higher priority products will be shown first in the shop.</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>Tags</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let tag of formGroup.value.tags" removable (removed)="removeTag(tag)">
        {{ tag }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <input
        matInput
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="addTag($event)"
      />
    </mat-chip-list>
  </mat-form-field>

  <mat-form-field floatLabel="always" *grecoLet="variants$ | async as allVariants">
    <mat-label>Restricted Variants (hidden to members)</mat-label>
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let restrictedVariant of formGroup.value.restrictedVariants; let i = index"
        color="accent"
        [selectable]="true"
        [removable]="true"
        [matTooltip]="restrictedVariant.email"
        (removed)="removeVariant(formGroup.value.restrictedVariants[i])"
      >
        {{ restrictedVariant.id | getVariant : allVariants || [] | variantTitle }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <input
        #restrictedVariantsInput
        placeholder="Search for a variant"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="($event)"
        (input)="search(restrictedVariantsInput.value)"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="addVariant($event.option.value); restrictedVariantsInput.value = ''"
    >
      <ng-container>
        <mat-option *ngFor="let variant of variantSearchResults$ | async" [value]="variant">
          {{ variant | variantTitle }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</form>
