import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import {
  EventResourceAssignment,
  EventTemplate,
  ResourceAvailabilityStatus,
  ResourceTag,
  RoomResource,
  Tag,
} from '@greco/booking-events';
import type { FormEntity } from '@greco/nestjs-typeform';
import { PropertyListener } from '@greco/property-listener-util';
import { Timezone, userDefaultTimezone } from '@greco/timezone';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CalendarService } from '../../services';
import { EventResourceAssignmentComponent } from '../event-resource-assignment/event-resource-assignment.component';

export interface EventDetails {
  startDate?: Date | null;
  timezone?: Timezone;

  color?: string | null;
  title?: string | null;
  imageUrl?: string | null;
  description?: string | null;
  tags?: Tag[] | null;
  resourceTags?: ResourceTag[] | null;
  resourceAssignments?: EventResourceAssignment[] | null;
  autoAssign?: boolean | null;
  enableUserSpotBooking: boolean;
  duration?: number | null;
  checkInWindow?: number | null;
  private?: boolean | null;
  maxCapacity?: number | null;
  typeform?: (FormEntity & { reusable: boolean; required: boolean })[];
  roomAssignment?: EventResourceAssignment | null;
  zoomAssignment?: EventResourceAssignment | null;
  zoomMeetingId?: string | null;
  calendarId: string;
}

@Component({
  selector: 'greco-event-details-input',
  templateUrl: './event-details-input.component.html',
  styleUrls: ['./event-details-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EventDetailsInputComponent), multi: true }],
})
export class EventDetailsInputComponent implements ControlValueAccessor, OnDestroy {
  constructor(private formBuilder: FormBuilder, private calendarSvc: CalendarService) {
    this._form.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(() => this.onChanged?.(this.value));
  }
  readonly stateChanges = new Subject<void>();

  private onTouched?: () => void;
  private onChanged?: (value: EventDetails | null) => void;
  private _onDestroy$ = new Subject<void>();
  private _refresh$ = new BehaviorSubject(null);

  @Input() isCompleted = false;
  @Input() eventId?: string = '';
  @Input() date?: Date;
  @Input() readonly = false;
  @Input() lockResources = false;
  @Input() addAttendees = false;
  @Input() customAccess = false;
  @Input() calendarId!: string | null;
  @Input() forSeries = false;
  @Input() initialImage?: string;

  availability: { [key: string]: ResourceAvailabilityStatus } = {};
  @Output() resourceAvailability = new EventEmitter<{ [key: string]: ResourceAvailabilityStatus }>();

  _form = this.formBuilder.group({
    eventStart: [
      { date: null, timezone: userDefaultTimezone() },
      [this.forSeries ? Validators.required : Validators.nullValidator],
    ],

    color: ['#005574', [Validators.required]],
    title: ['', [Validators.required]],
    imageUrl: [''],
    calendarId: ['', [Validators.required]],
    description: ['', []],
    tags: [[], [Validators.required, Validators.minLength(1)]],
    resourceAssignments: [[], []],
    autoAssign: [false],
    enableUserSpotBooking: [false],
    duration: [null, [Validators.required]],
    checkInWindow: [null, [Validators.required]],
    private: [true],
    maxCapacity: [10, [Validators.required, Validators.min(0)]],
    zoomMeetingId: [null],
    typeform: [[]],
    roomAssignment: [null],
    zoomAssignment: [null],
  });

  saving = false;
  _required = true;
  _readonly = false;
  tagsLocked = false;
  durationLocked = false;
  maxCapacityLocked = false;
  titleLocked = false;
  imageUrlLocked = false;
  resourcesLocked = false;
  typeformLocked = false;
  descriptionLocked = false;
  privateLocked = false;
  checkInWindowLocked = false;
  colorLocked = false;
  requirementsLocked = false;
  enableUserSpotBookingLocked = false;

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId!: string | null;

  @ViewChild('resourceAssignments') private resourceAssignments?: ElementRef<EventResourceAssignmentComponent>;

  _availableAsCourse = false;
  get availableAsCourse() {
    return this._availableAsCourse;
  }
  @Input() set availableAsCourse(availableAsCourse: boolean) {
    this._availableAsCourse = availableAsCourse;
  }

  @Input() get value() {
    this.resourceAvailability.emit(this.availability);

    const data: EventDetails = this._form.value;
    const resourceTags = data.resourceAssignments?.reduce((acc, assignment) => {
      if (assignment.resourceTag && assignment.resourceTagId) {
        if (!acc.map(resourceTag => resourceTag.id).includes(assignment?.resourceTagId)) {
          acc.push(assignment.resourceTag);
        }
      }
      return acc;
    }, [] as ResourceTag[]);

    return this._form.valid
      ? ({
          startDate: (data as any).eventStart.date,
          timezone: (data as any).eventStart.timezone,

          color: data.color || '#005574',
          title: data.title,
          imageUrl: data.imageUrl,
          calendarId: data.calendarId,
          description: data.description,
          tags: data.tags,
          resourceTags,
          resourceAssignments: data.resourceAssignments,
          autoAssign: data.autoAssign,
          enableUserSpotBooking: data.enableUserSpotBooking,
          duration: data.duration,
          checkInWindow: data.checkInWindow,
          private: data.private,
          maxCapacity: data.maxCapacity,
          typeform: data.typeform || [],
          roomAssignment: data.roomAssignment,
          zoomAssignment: data.zoomAssignment,
          zoomMeetingId: data.zoomMeetingId,
        } as EventDetails)
      : null;
  }

  set value(value: EventDetails | null) {
    this._form.patchValue({
      eventStart: {
        date: value?.startDate || this.date || null,
        timezone: value?.timezone || userDefaultTimezone(),
      },

      color: value?.color || '#005574',
      title: value?.title || null,
      imageUrl: value?.imageUrl || null,
      description: value?.description || null,
      tags: value?.tags || [],
      resourceAssignments: value?.resourceAssignments ? JSON.parse(JSON.stringify(value.resourceAssignments)) : [],
      autoAssign: value?.autoAssign || null,
      enableUserSpotBooking: value?.enableUserSpotBooking || false,
      duration: value?.duration || null,
      checkInWindow: value?.checkInWindow ?? null,
      private: value ? value.private : true,
      maxCapacity: value?.maxCapacity,
      typeform: value?.typeform || [],
      roomAssignment: value?.roomAssignment || null,
      zoomAssignment: value?.zoomAssignment || null,
      zoomMeetingId: value?.zoomMeetingId || null,
      calendarId: value?.calendarId || null,
    });

    this.stateChanges.next();
  }

  private _eventTemplate$ = new BehaviorSubject<EventTemplate | null>(null);
  @Input() get eventTemplate() {
    return this._eventTemplate$.value as EventTemplate;
  }

  set eventTemplate(eventTemplate) {
    this._eventTemplate$.next(eventTemplate);

    setTimeout(() => {
      this.setEventTemplate();
    });
  }

  calendars$ = combineLatest([this._communityId$]).pipe(
    switchMap(([communityId]) => this.calendarSvc.getManySecuredCached(communityId, true))
  );

  @Input() get required() {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);

    const validator = [...(this._required ? [Validators.required] : [])];
    this._form.get('title')?.setValidators(validator);
    this._form.get('tags')?.setValidators(validator);
    this._form.get('startDate')?.setValidators(validator);
    this._form.get('duration')?.setValidators(validator);
    this._form.get('checkInWindow')?.setValidators(validator);

    this.stateChanges.next();
  }

  updateCapacity(room: RoomResource) {
    if (!this._form.value.maxCapacity && room?.spotCount) this._form.patchValue({ maxCapacity: room.spotCount });
  }

  refresh() {
    this._refresh$.next(null);
  }

  refreshAvailability(recurrence: string[], startDate?: Date) {
    if (this.forSeries) (this.resourceAssignments as any)?.refreshAvailability(recurrence, startDate);
  }

  setEventTemplate() {
    if (this.eventTemplate) {
      this.tagsLocked = this.eventTemplate.tagsLocked;
      this.durationLocked = this.eventTemplate.durationLocked;
      this.maxCapacityLocked = this.eventTemplate.maxCapacityLocked;
      this.titleLocked = this.eventTemplate.titleLocked;
      this.imageUrlLocked = this.eventTemplate.imageUrlLocked;
      this.resourcesLocked = this.eventTemplate.resourcesLocked;
      this.descriptionLocked = this.eventTemplate.descriptionLocked;
      this.privateLocked = this.eventTemplate.privateLocked;
      this.checkInWindowLocked = this.eventTemplate.checkInWindowLocked;
      this.typeformLocked = this.eventTemplate.requirementsLocked;
      this.colorLocked = this.eventTemplate.colorLocked;
      this.setColorDisable(this.colorLocked);
      this.enableUserSpotBookingLocked = this.eventTemplate.enableUserSpotBookingLocked;
    }
  }

  setColorDisable(colorLocked: boolean) {
    if (colorLocked && !this.customAccess) this._form.controls['color'].disable();
    this._form.markAsPristine();
  }

  writeValue(value: EventDetails): void {
    this.value = value;
  }

  registerOnChange(fn: (value: EventDetails | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
