import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { AccountLink } from '@greco/account-linking';
import { EventAccount, EventWithUserDetails } from '@greco/booking-events2';
import { User } from '@greco/identity-users';
import { UserAddGuestDialog } from '@greco/web-account-linking';
import moment from 'moment';
import { EventService } from '../../services';

@Component({
  selector: 'alt-add-booking-card',
  templateUrl: './placeholder-booking-card.component.html',
  styleUrls: ['./placeholder-booking-card.component.scss'],
})
export class AddBookingCardComponent {
  constructor(private eventSvc: EventService, private dialog: MatDialog) {}

  moment = moment;
  now = new Date();

  @Input() event!: EventWithUserDetails;
  @Input() eventAccounts: EventAccount[] = [];
  @Input() disabled = false;

  addBooking(accountToAdd: EventAccount) {
    const accountWithBookingOptions =
      accountToAdd.canUsePerks && accountToAdd.bookingOptions.length ? accountToAdd : this.event.accounts[0];

    let bookingOptions = accountWithBookingOptions.bookingOptions;

    if (accountWithBookingOptions.user.id !== accountToAdd.user.id) {
      bookingOptions = bookingOptions.filter(option => option.transferable || option.transferableReusable);
    }

    this.eventSvc.addBooking({
      userId: accountToAdd.user.id,
      eventId: this.event?.id || '',
      bookingOptionId: bookingOptions[0]?.id || '',
      bookingOptionUserId: bookingOptions[0]?.userId || '',
    });
  }

  async addGuest(user: User, event: EventWithUserDetails) {
    const dialog = this.dialog.open(UserAddGuestDialog, {
      data: {
        user,
        communityId: event.location.id,
      },
    });

    const response: AccountLink = await toPromise(dialog.afterClosed());
    if (response) {
      this.eventSvc.accounts$.next([
        ...this.eventSvc.accounts$.value,
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          user: response.account!,
          userStatusInfo: 'available',
          bookingOptions: [],
          conflictingBookings: [],
          canUsePerks: false,
          booking: undefined,
          boosters: [],
          failedPayments: [],
          pendingBookingOptions: [],
        },
      ]);

      this.eventSvc.addBooking({
        userId: response.accountId,
        eventId: event.id,
        bookingOptionId:
          event.accounts[0].bookingOptions.filter(option => option.transferable || option.transferableReusable)[0]
            ?.id || '',
        bookingOptionUserId: user.id,
      });
    }
  }
}
