<a
  href="../workouts-new"
  style="display: flex; justify-content: center; text-decoration: none"
  [style.margin-top]="(isMobile$ | async) === false ? '-64px' : ''"
>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 32px;
      width: fit-content;
      margin: 16px;
      padding: 0 12px;
      border-radius: 8px;
      color: rgb(var(--info-color-rgb));
      background-color: rgb(var(--info-color-rgb), 0.2);
      font-weight: medium;
      overflow: hidden;
    "
    [style.width]="(isMobile$ | async) === false ? '' : '100%'"
    [style.margin-top]="(isMobile$ | async) === false ? '16px' : '0'"
  >
    <mat-icon style="color: rgb(var(--info-color-rgb)); width: 20px; height: 20px; line-height: 20px; font-size: 20px">
      info
    </mat-icon>
    <span style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
      Check out our new <span style="text-decoration: underline"> booking experience </span>!
    </span>
  </div>
</a>

<div class="classes-and-filters" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <a
    mat-button
    class="location-picker"
    (click)="select.open()"
    *ngIf="(userEventsSvc.user$ | async) && !userEventsSvc.firstLoad"
  >
    <mat-icon color="accent">location_on</mat-icon>
    <ng-container *grecoLet="userEventsSvc.selectedCommunities$ | async as selectedCommunities">
      {{(selectedCommunities?.length ? selectedCommunities?.length + ' Selected' : 'All Locations')}}
    </ng-container>
    <mat-select
      #select
      multiple="true"
      [value]="userEventsSvc.selectedCommunities$ | async"
      (selectionChange)="userEventsSvc.selectedCommunities$.next($event.value)"
    >
      <mat-option *ngFor="let community of userEventsSvc.communities$ | async" [value]="community">
        {{community.name}}
      </mat-option>
    </mat-select>
  </a>
  <ng-container *grecoLet="userEventsSvc.calendars$ | async as calendars">
    <ng-container *ngIf="calendars?.length; else noCalendar">
      <!-- <p style="margin-left: 16px">
        [SANDBOX ONLY] Calendar: {{(userEventsSvc.selectedCalendars$ | async | pluck: 'title')?.join(', ')}}
      </p> -->
      <div class="calendar-picker" *ngIf="(userEventsSvc.tags$ | async) || [] as tags">
        <mat-chip-list [value]="(userEventsSvc.filters$ | async)?.tags" [multiple]="true">
          <mat-chip
            color="accent"
            *ngFor="let tag of tags"
            [selectable]="true"
            [value]="tag.id"
            (click)="userEventsSvc.filterTagToggle(tag.id)"
          >
            {{tag.label}}
          </mat-chip>
          <button
            *ngIf="(isMobile$ | async) === false"
            mat-icon-button
            color="accent"
            (click)="bottomSheet.open(filtersSheet, { panelClass: 'bottom-sheet' })"
            [matBadge]="
              ((userEventsSvc.filters$ | async)?.resources?.length || 0) + 
              ((userEventsSvc.filters$ | async)?.timesOfDay?.length || 0)"
            [matBadgeHidden]="!(((userEventsSvc.filters$ | async)?.resources?.length || 0) + 
              ((userEventsSvc.filters$ | async)?.timesOfDay?.length || 0))"
            matBadgePosition="after"
            matBadgeSize="small"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
        </mat-chip-list>

        <!-- <mat-chip-list [value]="userEventsSvc.selectedCalendars$ | async" [multiple]="true">
          <mat-chip
            color="accent"
            *ngFor="let calendar of calendars"
            [selectable]="true"
            [value]="calendar"
            (click)="userEventsSvc.selectedCalendars$.next([calendar])"
          >
            {{calendar.title}}
          </mat-chip>
          <button
            *ngIf="(isMobile$ | async) === false"
            mat-icon-button
            color="accent"
            (click)="bottomSheet.open(filtersSheet, { panelClass: 'bottom-sheet' })"
          >
            <mat-icon>filter_alt</mat-icon>
          </button>
        </mat-chip-list> -->
      </div>
      <div class="day-picker" *grecoLet="userEventsSvc.selectedDay$ | async as selectedDay">
        <div class="header">
          <h2>
            {{ selectedDay | date: 'MMMM YYYY' }}
            <button
              style="margin-left: 8px"
              mat-icon-button
              (click)="userEventsSvc.selectedDay$.next(moment(selectedDay).subtract(7, 'days').toDate())"
            >
              <mat-icon>navigate_before</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="userEventsSvc.selectedDay$.next(moment(selectedDay).add(7, 'days').toDate())"
            >
              <mat-icon>navigate_next</mat-icon>
            </button>
          </h2>
          <a mat-button color="accent" class="today-link" (click)="userEventsSvc.selectedDay$.next(today)">Today</a>
        </div>
        <div class="days">
          <div
            class="day"
            *ngFor="let day of userEventsSvc.weekDays$ | async"
            [class.disabled]="day.getTime() < today.getTime()"
            [class.selected]="selectedDay?.getTime() === day.getTime()"
            [class.today]="today.getTime() === day.getTime()"
            (click)="userEventsSvc.selectedDay$.next(day)"
          >
            <p>{{day | date: 'EEE'}}</p>
            <p>{{day | date: 'dd'}}</p>
          </div>
        </div>
      </div>
      <ng-container *ngIf="userEventsSvc.$events | async as events; else loadingEvents">
        <ng-container *ngIf="!userEventsSvc.loadingEvents; else loadingEvents">
          <ng-container *ngIf="events.length; else noEvents">
            <div *grecoLet="favoriteSvc.favorites$ | async as favorites" class="classes">
              <ng-container *grecoLet="userEventsSvc.selectedEvent$ | async as selectedEvent">
                <ng-template
                  #overlay="cdkConnectedOverlay"
                  cdkConnectedOverlay
                  cdkConnectedOverlayPanelClass="fullscreen-overlay"
                  [cdkConnectedOverlayOrigin]="trigger"
                  [cdkConnectedOverlayOpen]="overlay.open && !!selectedEvent"
                >
                  <div class="overlay-toolbar" (click)="overlay.open = false">
                    <button mat-icon-button>
                      <mat-icon>arrow_back_ios</mat-icon>
                    </button>
                    <h2>Book a Class</h2>
                  </div>
                  <a
                    style="display: flex; justify-content: center; text-decoration: none; margin: 0 0 0 auto"
                    [href]="'../workouts-new/' + $any(selectedEvent).id"
                    (click)="$event.stopImmediatePropagation()"
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 12px;
                        height: 32px;
                        width: fit-content;
                        margin: 16px;
                        padding: 0 12px;
                        border-radius: 8px;
                        color: rgb(var(--info-color-rgb));
                        background-color: rgb(var(--info-color-rgb), 0.2);
                        font-weight: medium;
                      "
                    >
                      <mat-icon style="color: rgb(var(--info-color-rgb))">info</mat-icon>
                      <span>
                        Check out our new
                        <span style="text-decoration: underline">booking experience</span>!
                      </span>
                    </div>
                  </a>

                  <greco-multi-booking-preview
                    *ngIf="selectedEvent && userEventsSvc.user$ | async"
                    [booking]="selectedEvent?.userBooking"
                    [user]="$any(userEventsSvc.user$ | async)"
                    [event]="$any(selectedEvent)"
                    [preventRedirect]="true"
                    (booked)="overlay.open = false"
                  ></greco-multi-booking-preview>
                </ng-template>
                <div
                  class="event-card"
                  *ngFor="let event of events"
                  (click)="userEventsSvc.selectedEvent$.next(event); overlay.open = true"
                >
                  <div class="card-image">
                    <button
                      class="favourite-button"
                      [class.favorite]="favorites?.has(event.title)"
                      mat-icon-button
                      (click)="$event.stopImmediatePropagation(); checkFavorite(favorites, event)"
                    >
                      <mat-icon>{{ favorites?.has($any(event.title)) ? 'favorite' : 'favorite'}}</mat-icon>
                    </button>
                    <ng-container *grecoLet="event.maxCapacity - $any(event).bookings as spotsLeft">
                      <p class="spots-left" *ngIf="event.community?.id !== 'com_greco'">
                        <ng-container *ngIf="spotsLeft; else noSpots">
                          {{spotsLeft}} Spot{{spotsLeft === 1 ? '' : 's'}} Left</ng-container
                        >
                        <ng-template #noSpots> Full </ng-template>
                      </p>
                    </ng-container>
                    <img
                      class="main-image"
                      [src]="event.imageUrl || event.calendar?.imageUrl || 'assets/greco/placeholder.png'"
                    />
                  </div>
                  <div class="card-content">
                    <p class="time">
                      {{event.startDate | date: 'MMM d'}} {{event.startDate | date: 'h:mm'}} - {{event.endDate | date:
                      'shortTime'}}
                      <ng-container *ngIf="event.community?.name">
                        <mat-icon
                          style="
                            vertical-align: middle;
                            margin-bottom: 4px;
                            margin-left: 4px;
                            width: 14px;
                            height: 14px;
                            line-height: 14px;
                            font-size: 14px;
                            color: var(--primary-color);
                          "
                        >
                          location_on
                        </mat-icon>
                        {{event.community.name}}
                      </ng-container>
                    </p>
                    <h2><strong>{{event.title}}</strong></h2>
                    <p>
                      <!-- {{event.calendar?.title}} -->
                      <!-- Use for lightgrey -> <span></span> -->
                      <ng-container *ngIf="event.resourceAssignments?.length">
                        <!-- - -->
                        <ng-container *ngFor="let resourceAssignment of event.resourceAssignments; let last = last">
                          {{resourceAssignment.resource?.name}}{{last ? '' : ','}}
                        </ng-container>
                      </ng-container>
                    </p>
                    <div class="action-buttons">
                      <ng-container *ngIf="!event.userBooking; else isBooked">
                        <button
                          mat-flat-button
                          color="primary"
                          *ngIf="!(event.waitlist | pluck:
                        'email')?.includes((userEventsSvc.user$ | async)?.email); else onWaitlist"
                        >
                          {{ event.maxCapacity - $any(event).bookings <= 0 ? 'Join Waitlist' : 'Book Now' }}
                        </button>
                        <ng-template #onWaitlist>
                          <button mat-stroked-button>
                            {{ event.maxCapacity - $any(event).bookings <= 0 ? 'Waitlisted' : 'Book Now' }}
                            <mat-icon color="accent">alarm</mat-icon>
                          </button>
                        </ng-template>
                      </ng-container>
                      <ng-template #isBooked>
                        <button mat-stroked-button>Booked <mat-icon color="accent">check_circle</mat-icon></button>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noEvents>
            <p
              style="padding: 16px"
              *ngIf="$any(userEventsSvc.selectedDay$ | async)?.getTime() < today.getTime(); else futureDate"
            >
              You cannot book events in the past!
            </p>
            <ng-template #futureDate>
              <p style="padding: 16px">
                There are no events here on that day.
                <a
                  *ngIf="(userEventsSvc.filters$ | async)?.tags?.length || (userEventsSvc.filters$ | async)?.resources?.length"
                  (click)="userEventsSvc.clearFilters()"
                  style="color: var(--accent-color); text-decoration: underline; cursor: pointer"
                  >Clear Filters</a
                >
              </p>
            </ng-template>
          </ng-template>
          <button
            *grecoLet="userEventsSvc.selectedDay$ | async as selectedDay"
            mat-stroked-button
            style="width: calc(100% - 32px); margin-left: 16px; height: 32px"
            (click)="userEventsSvc.selectedDay$.next(moment(selectedDay).add(1, 'days').toDate())"
          >
            Next Day <mat-icon>arrow_forward_ios</mat-icon>
          </button>
        </ng-container>
      </ng-container>
      <ng-template #loadingEvents>
        <div class="loading-events">
          <div class="loading-card"></div>
          <div class="loading-card"></div>
          <div class="loading-card"></div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #noCalendar>
      <p style="margin: 12px 16px" *ngIf="(userEventsSvc.user$ | async) || userEventsSvc.userLoading; else noUser">
        <ng-container *ngIf="userEventsSvc.loading; else noCalendars">
          <div class="loading-events" style="padding: 0 !important">
            <div class="loading-card"></div>
            <div class="loading-card"></div>
            <div class="loading-card"></div>
          </div>
        </ng-container>
        <ng-template #noCalendars>This community doesn't have any classes at this time!</ng-template>
      </p>
      <ng-template #noUser>
        <p style="margin: 12px 16px">Please sign-in in order to book classes!</p>
        <button style="margin-left: 16px" mat-raised-button routerLink="/" color="primary">
          <mat-icon>login</mat-icon>Sign-In
        </button>
      </ng-template>
    </ng-template>
  </ng-container>
</div>

<!-- Booking FAB -->
<div class="booking-fab" *ngIf="isMobile$ | async">
  <button mat-raised-button color="accent" (click)="bottomSheet.open(filtersSheet, { panelClass: 'bottom-sheet' })">
    <mat-icon>filter_alt</mat-icon>
    Filters
    <ng-container
      *ngIf="((userEventsSvc.filters$ | async)?.resources?.length || 0) + ((userEventsSvc.filters$ | async)?.timesOfDay?.length || 0) as length"
    >
      {{'(' + length + ')'}}
    </ng-container>
  </button>
</div>
<ng-template #filtersSheet>
  <div style="display: flex; gap: 12px; justify-content: space-between; align-items: center">
    <h2 #header style="margin-bottom: 0">Apply Filters</h2>
    <div>
      <button
        [disabled]="
          !(userEventsSvc.filters$ | async)?.tags?.length &&
          !(userEventsSvc.filters$ | async)?.resources?.length &&
          !(userEventsSvc.filters$ | async)?.timesOfDay?.length"
        mat-icon-button
        (click)="userEventsSvc.clearFilters()"
      >
        <mat-icon>filter_alt_off</mat-icon>
      </button>
      <button mat-icon-button (click)="bottomSheet.dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <!-- <div class="filters-sheet-content">
    <h3 style="margin-bottom: 0">Time of Day</h3>
    <div class="times-container">
      <div class="times-row">
        <div
          class="time-filter"
          *ngFor="let timeOfDay of userEventsSvc.timesOfDay  | initial: 4"
          [class.selected]="(userEventsSvc.filters$ | async)?.timesOfDay?.includes(timeOfDay.id)"
          (click)="userEventsSvc.filterTimeOfDayToggle(timeOfDay.id)"
        >
          <mat-icon [style.color]="timeOfDay.color">{{timeOfDay.icon}}</mat-icon>
          <p><strong>{{timeOfDay.start}}-{{timeOfDay.end}}</strong></p>
          <p>{{timeOfDay.label}}</p>
        </div>
      </div>
      <div class="times-row">
        <div
          class="time-filter"
          *ngFor="let timeOfDay of userEventsSvc.timesOfDay | tail: 4"
          [class.selected]="(userEventsSvc.filters$ | async)?.timesOfDay?.includes(timeOfDay.id)"
          (click)="userEventsSvc.filterTimeOfDayToggle(timeOfDay.id)"
        >
          <mat-icon [style.color]="timeOfDay.color">{{timeOfDay.icon}}</mat-icon>
          <p><strong>{{timeOfDay.start}}-{{timeOfDay.end}}</strong></p>
          <p>{{timeOfDay.label}}</p>
        </div>
      </div>
    </div>
  </div> -->
  <div class="filters-sheet-content">
    <h3 style="margin-bottom: 12px">Instructors</h3>
    <ng-container *grecoLet="(userEventsSvc.trainers$ | async) || [] as trainers">
      <ng-container *ngIf="trainers?.length; else noTrainer">
        <div class="trainer-filter">
          <div
            class="trainer"
            *ngFor="let trainer of trainers"
            [class.selected]="(userEventsSvc.filters$ | async)?.resources?.includes(trainer.id)"
            (click)="userEventsSvc.filterResourceToggle(trainer.id)"
          >
            <greco-multi-avatar
              [images]="trainer.photoURL ? [trainer.photoURL] : []"
              [size]="96"
              color="accent"
            ></greco-multi-avatar>
            <p><strong>{{ trainer.name}}</strong></p>
          </div>
        </div>
      </ng-container>
      <ng-template #noTrainer>
        <p>There are no instructors by which to filter here.</p>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
