import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toPromise } from '@greco-fit/util';
import { CustomerSupport, UpdateCustomerSupportDto } from '@greco/platform-customer-support';

@Injectable({
  providedIn: 'root',
})
export class CustomerSupportService {
  constructor(private http: HttpClient) {}

  getCustomerSupportConfig(appName: string) {
    return toPromise(this.http.get<CustomerSupport>(`/api/customer-support/${appName}`));
  }

  updateCustomerSupportConfig(config: UpdateCustomerSupportDto) {
    return toPromise(this.http.post<UpdateCustomerSupportDto>('/api/customer-support', config));
  }
}
