<div class="grid">
  <greco-product-card
    *ngFor="let product of products$ | async"
    [product]="product"
    [userId]="userId"
    [userConditionContext]="userConditionContext"
    [linkedAccounts]="linkedAccounts"
  ></greco-product-card>
</div>

<ng-container *ngIf="pagination">
  <mat-paginator
    *ngIf="pagination.totalItems"
    showFirstLastButtons
    [length]="pagination.totalItems"
    [pageSize]="pagination.itemsPerPage"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  >
  </mat-paginator>
</ng-container>
