<form [formGroup]="formGroup">
  <greco-event-from-template-input
    [required]="true"
    [forSeries]="data.forSeries"
    [resource]="data.resource"
    [initialStartDate]="data.startDate"
    [communityId]="data.communityId"
    [resourceTag]="data.resourceTag"
    [lockResources]="!!data.resource"
    [customAccess]="canCreateCustomEvents"
    [initialImage]="data.eventTemplate.imageUrl"
    [privateTag]="data.eventTemplate.private"
    [tagsLocked]="data.eventTemplate.tagsLocked"
    [titleLocked]="data.eventTemplate.titleLocked"
    [colorLocked]="data.eventTemplate.colorLocked"
    [privateLocked]="data.eventTemplate.privateLocked"
    [durationLocked]="data.eventTemplate.durationLocked"
    [resourcesLocked]="data.eventTemplate.resourcesLocked"
    [typeformLocked]="data.eventTemplate.requirementsLocked"
    [descriptionLocked]="data.eventTemplate.descriptionLocked"
    [maxCapacityLocked]="data.eventTemplate.maxCapacityLocked"
    [checkInWindowLocked]="data.eventTemplate.checkInWindowLocked"
    [enableUserSpotBookingLocked]="data.eventTemplate.enableUserSpotBookingLocked || false"
    (resourceAvailability)="availability = $event"
    formControlName="details"
  ></greco-event-from-template-input>

  <greco-collapsible-section
    *ngIf="data.forSeries"
    style="margin-top: 16px"
    [expanded]="false"
    [header]="{ title: 'Schedule Options', small: true }"
  >
    <greco-series-schedule-input
      [required]="true"
      [communityId]="data.communityId"
      (scheduleChanged)="refreshAvailability($event)"
      formControlName="schedule"
    >
    </greco-series-schedule-input>
  </greco-collapsible-section>
</form>

<div style="margin-top: 12px; display: flex; justify-content: flex-end; gap: 8px">
  <button mat-button [disabled]="createCalled || createAttendeeCalled" (click)="cancel()">Discard</button>

  <button mat-button [disabled]="formGroup.invalid || createCalled || createAttendeeCalled" (click)="create()">
    <mat-icon *ngIf="createCalled" class="spin">loop</mat-icon> Create Event
  </button>
</div>
