import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { ResourceType, RoomResourceSpot } from '@greco/booking-events';
import { ResourcesService } from '../../services';

@Component({
  templateUrl: './create-room-resource.dialog.html',
  styleUrls: ['./create-room-resource.dialog.scss'],
})
export class CreateRoomResourceDialog {
  readonly _dialogData: DialogData = {
    subtitle: 'Create a new room resource',
    hideDefaultButton: true,
    title: 'New Room',
  };

  readonly _form = this.formBuilder.group({
    name: [null, Validators.required],
    description: [''],
    icon: [''],
    roomImage: [null],
    spots: [[], Validators.required],
  });

  submitting = false;

  constructor(
    private router: Router,
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private resourceSvc: ResourcesService,
    @Inject(MAT_DIALOG_DATA) private communityId: string,
    private dialogRef: MatDialogRef<CreateRoomResourceDialog>
  ) {}

  async submit() {
    this.submitting = true;

    try {
      const room = await this.resourceSvc.createRoomResource({
        type: ResourceType.ROOM,
        name: this._form.value.name,
        description: this._form.value.description,
        icon: this._form.value.icon,
        spots: this._form.value.spots,
        spotCount: this._form.value.spots.length,
        communityId: this.communityId,
      });

      const roomImage = this._form.value.roomImage;
      if (roomImage?.length) {
        const formData = new FormData();
        formData.append('file', roomImage[0]);
        await this.resourceSvc.uploadRoomImage(room.id, formData);
      }

      this.close();
      this.snacks
        .open(`Added '${this._form.value.name}' with ${this._form.value.spots.length} spots`, 'View details', {
          panelClass: 'mat-primary',
          duration: 6000,
        })
        .onAction()
        .subscribe(() => this.router.navigate(['admin/scheduling', this.communityId, 'room-resources', room.id]));
    } catch (err) {
      console.error(err);
    }

    this.submitting = false;
  }

  allSpotsHaveNames() {
    return !!(this._form.value.spots as RoomResourceSpot[]).filter(spot => !spot.name)?.length;
  }

  close() {
    this.dialogRef.close();
  }
}
