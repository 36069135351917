<h1>{{data.series.title}}</h1>
<p>Events that do not fit the new schedule</p>
<p>Should they be cancelled or kept as exceptions?</p>

<greco-table [data]="data.instances">
  <mat-icon *grecoTableCol="''; let instance; fitContent: true;" [matTooltip]="instance.id">event</mat-icon>

  <p *grecoTableCol="'Date'; let instance;">
    {{instance.startDate | date: 'mediumDate'}} {{instance.startDate | date: 'shortTime'}}
  </p>

  <p *grecoTableCol="'Title'; let instance">{{instance.title}}</p>

  <p *grecoTableCol="'Bookings'; let instance">{{instance.bookings}} / {{instance.maxCapacity}}</p>

  <mat-chip-list *grecoTableCol="'Resource Tags'; let instance">
    <mat-chip *ngFor="let resoruceTag of instance.resourceTags">{{resoruceTag.label}}</mat-chip>
  </mat-chip-list>

  <mat-chip-list *grecoTableCol="'Resource Assignments'; let instance">
    <mat-chip *ngFor="let assignment of instance.resourceAssignments">{{assignment.resource?.name}}</mat-chip>
  </mat-chip-list>

  <mat-chip-list *grecoTableCol="'Tags'; let instance">
    <mat-chip *ngFor="let tag of instance.tags">{{tag.label}}</mat-chip>
  </mat-chip-list>

  <mat-chip *grecoTableCol="'Status'; let instance">{{instance.status | titlecase}}</mat-chip>

  <div *grecoTableCol="'Cancel Event Occurrence'; let instance; fitContent: true; stickyEnd: true">
    <mat-checkbox
      [checked]="cancellations[instance.id]"
      (change)="cancellations[instance.id] = $event.checked"
      color="primary"
    >
      <div style="margin-left: 8px">
        <div style="line-height: 20px">Cancel</div>
        <div style="line-height: 18px"><small>(Refund Bookings)</small></div>
      </div>
    </mat-checkbox>
  </div>
</greco-table>

<div style="display: flex; align-items: center; justify-content: space-between; gap: 8px; margin-top: 16px">
  <button mat-stroked-button style="flex: 1" (click)="cancel()">Cancel</button>
  <button mat-stroked-button color="primary" style="flex: 1" (click)="confirm()">Confirm</button>
</div>
