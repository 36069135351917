import { CreateVideoAndInitiateUploadDto } from '@greco/videos';
import { Expose } from 'class-transformer';
import { IsNotEmpty, IsString } from 'class-validator';

export class CreateCommunityVideoAndInitiateUploadDto extends CreateVideoAndInitiateUploadDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  communityId: string;
}
