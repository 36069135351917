import { SecurityResource } from '@greco/security';

export const VIDEO_COLLECTION_SECURITY_RESOURCE = 'video_collection';

export enum VideoCollectionSecurityResourceAction {
  READ = 'READ',
  CREATE = 'CREATE',
  MANAGE = 'MANAGE',
}

export const VideoCollectionSecurityResource: SecurityResource = {
  key: VIDEO_COLLECTION_SECURITY_RESOURCE,
  module: 'videos',

  title: 'Video Collections',
  description: 'Manage video collections',

  context: class {
    communityId?: string;
  },

  actions: [
    {
      key: VideoCollectionSecurityResourceAction.MANAGE,
      title: 'Manage Video Collections',
      description: 'Ability to manage (update and archive) video collections',
    },
    {
      key: VideoCollectionSecurityResourceAction.CREATE,
      title: 'Create Video Collections',
      description: 'Ability to create video collections',
    },
    {
      key: VideoCollectionSecurityResourceAction.READ,
      title: 'Read Video Collections',
      description: 'Ability to read video collections',
    },
  ],
};
