<greco-collapsible-section [header]="{ title: 'Promotion Details', icon: 'local_offer' }">
  <greco-form-save-buttons header [form]="form" [resetValue]="resetValue" [saveAction]="save"></greco-form-save-buttons>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tag Line</mat-label>
      <input matInput type="text" formControlName="subtitle" />
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <mat-label>Promotion Date</mat-label>
      <mat-date-range-input class="greco-date-range-picker-container" [rangePicker]="picker">
        <input matStartDate placeholder="Start date" formControlName="startDate" />
        <input matEndDate placeholder="End date" formControlName="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</greco-collapsible-section>
<greco-collapsible-section [header]="{ title: 'Products', icon: 'shopping_cart' }">
  <button header mat-stroked-button style="height: 32px" (click)="$event.stopImmediatePropagation(); addProduct()">
    <mat-icon>add</mat-icon>Add Product
  </button>
  <greco-table [data]="products" style="margin-left: -16px; margin-right: -16px">
    <mat-icon *grecoTableCol="''; let product; fitContent: true" [matTooltip]="product.id">shopping_cart</mat-icon>
    <p *grecoTableCol="'Product'; let product">{{product.title}}</p>
    <mat-chip-list *grecoTableCol="'Variants'; let product">
      <mat-chip *ngFor="let variant of product.variants" (click)="removeVar(variant)">
        <strong style="margin-right: 4px">{{ variant | variantPrice }}</strong> - {{variant.title ||
        (variant.variantOpts | pluck: 'value')?.join(', ') }}
        <mat-icon style="margin-left: 8px; width: 18px; height: 18px; font-size: 18px">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <button
      *grecoTableCol="''; let product; stickyEnd: true; fitContent:true;"
      mat-icon-button
      (click)="remove(product)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </greco-table>
</greco-collapsible-section>
