<ng-container *grecoLet="canUpdate$ | async as canUpdate">
  <!-- Event Template Details -->
  <greco-collapsible-section #details [header]="{ title: 'Event Template Details', icon: 'info' }">
    <button
      header
      *ngIf="details.expanded && canUpdate"
      mat-flat-button
      type="button"
      [color]="color"
      [matMenuTriggerFor]="templates"
      (click)="$event.stopImmediatePropagation()"
    >
      <mat-icon>add</mat-icon>
      <span>ADD</span>
    </button>

    <mat-menu #templates xPosition="before">
      <ng-container *grecoLet="filteredTemplates$ | async as filteredTemplates">
        <ng-container *ngIf="filteredTemplates?.length; else noTemplates">
          <ng-container *ngFor="let template of filteredTemplates">
            <button mat-menu-item (click)="addTemplate(template.id)">
              <span>{{ template.title }}</span>
            </button>
          </ng-container>
        </ng-container>
        <ng-template #noTemplates>
          <button mat-menu-item>
            <i>No more templates found</i>
          </button>
        </ng-template>
      </ng-container>
    </mat-menu>

    <ng-container *ngIf="canReadCalendar$ | async">
      <greco-table *grecoLet="templates$ | async" [data]="filteredByCalendar" style="margin: -20px">
        <p *grecoTableCol="''; let template" [matTooltip]="template.id" style="margin: -10px">
          <mat-icon>view_timeline</mat-icon>
        </p>

        <p *grecoTableCol="'Template'; let template" style="font-size: 13px">
          {{ template.title }}
        </p>

        <p *grecoTableCol="'Color'; let template; alignCenter: true">
          <mat-icon *ngIf="template.colorLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Tags'; let template; alignCenter: true">
          <mat-icon *ngIf="template.tagsLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Title'; let template; alignCenter: true">
          <mat-icon *ngIf="template.titleLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Image'; let template; alignCenter: true">
          <mat-icon *ngIf="template.imageUrlLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Description'; let template; alignCenter: true">
          <mat-icon *ngIf="template.descriptionLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Duration'; let template; alignCenter: true">
          <mat-icon *ngIf="template.durationLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Booking Cut-Off'; let template; alignCenter: true">
          <mat-icon *ngIf="template.checkInWindowLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Max Capacity'; let template; alignCenter: true">
          <mat-icon *ngIf="template.maxCapacityLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Private'; let template; alignCenter: true">
          <mat-icon *ngIf="template.privateLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Requirements'; let template; alignCenter: true">
          <mat-icon *ngIf="template.requirementsLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <p *grecoTableCol="'Resources'; let template; alignCenter: true">
          <mat-icon *ngIf="template.resourcesLocked; else unlocked" color="primary">lock</mat-icon>
        </p>
        <p *grecoTableCol="'Allow users to book spots'; let template; alignCenter: true">
          <mat-icon *ngIf="template.enableUserSpotBookingLocked; else unlocked" color="primary">lock</mat-icon>
        </p>

        <ng-template #unlocked>
          <mat-icon>lock_open</mat-icon>
        </ng-template>

        <ng-container *grecoTableCol="''; let template; fitContent: true; stickyEnd: true">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu>
            <button mat-menu-item (click)="navigateToTemplate(template.id)">
              <mat-icon>info</mat-icon>
              <span>View Details</span>
            </button>
            <button mat-menu-item (click)="removeTemplate(template.id)">
              <mat-icon>delete_forever</mat-icon>
              <span>Remove</span>
            </button>
          </mat-menu>
        </ng-container>
      </greco-table>
    </ng-container>
  </greco-collapsible-section>
</ng-container>
