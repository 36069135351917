import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Product, ProductImage, ProductVariant } from '@greco/sales-products';
import { Subject } from 'rxjs';

@Component({
  selector: 'greco-variant-image-picker',
  templateUrl: './greco-variant-image-picker.component.html',
  styleUrls: ['./greco-variant-image-picker.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: VariantImagePickerComponent }],
})
export class VariantImagePickerComponent implements MatFormFieldControl<ProductImage>, ControlValueAccessor, OnDestroy {
  constructor(@Optional() @Self() public ngControl: NgControl, private _elementRef: ElementRef) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'greco-product-variant-picker';

  private _onChange?: (value: any) => void;
  private _onTouched?: () => void;

  stateChanges = new Subject<void>();

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `${VariantImagePickerComponent._controlType}-${VariantImagePickerComponent._id++}`;
  readonly controlType = VariantImagePickerComponent._controlType;

  private _value: ProductImage | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: ProductImage | null) {
    this._value = value;
    this._onChange?.(value);
    this.stateChanges.next();
  }

  private _product: Product | null = null;
  @Input() get product() {
    return this._product;
  }
  set product(product: Product | null) {
    this._product = product;
  }

  private _placeholder = '';
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder || '';
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.shouldLabelFloat || false;
  }

  private _required = false;
  @Input() set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  get required() {
    return this._required;
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get errorState() {
    return (
      !!this.ngControl?.touched &&
      (!!Object.keys(this.ngControl?.errors || {}).length || (this.required && !this.value))
    );
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy?: string;

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.product-variant-picker-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: ProductImage | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: ProductVariant | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
