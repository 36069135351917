import { BaseDto } from '@greco/util-dto';
import { Expose, Type } from 'class-transformer';
import { IsDate, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class UnlockCommunityVideoDto extends BaseDto {
  @Expose()
  @IsDate()
  @IsOptional()
  @Type(() => Date)
  unlockDate: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  userId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  unlockOptionId: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  communityVideoId: string;

  @Expose()
  @IsString()
  @IsOptional()
  paymentMethodId?: string;
}
