import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import {
  PaymentMethodForm,
  PAYMENT_METHOD_FORMS,
} from '../components/add-payment-method-button/payment-method-forms.token';
import {
  CreatePaymentMethodDialog,
  CreatePaymentMethodDialogData,
} from '../dialogs/create-payment-method/create-payment-method.dialog';

@Injectable()
export class PaymentMethodDialogs {
  constructor(private matDialog: MatDialog, @Inject(PAYMENT_METHOD_FORMS) private forms: PaymentMethodForm[]) {}

  async addCreditCard(userId: string) {
    const form = this.forms.find(f => f.label === 'Credit Card');
    if (!form) return null;

    const dialog = this.matDialog.open(CreatePaymentMethodDialog, {
      width: '512px',
      maxWidth: 'calc(80vw)',
      data: { form: form.form, gatewayId: form.gateway, userId } as CreatePaymentMethodDialogData,
    });

    return await toPromise(dialog.afterClosed());
  }
}
