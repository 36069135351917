<div id="dialog-header" class="mb2">
  <ng-content select="[before-header]"></ng-content>
  <div class="flex items-center">
    <div *ngIf="data?.showCloseButton" class="mr2">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div>
      <h2 mat-dialog-title [innerHtml]="data?.title | safe: 'html'"></h2>
      <h3 *ngIf="data?.subtitle" [innerHtml]="data?.subtitle | safe: 'html'">{{data?.subtitle}}</h3>
    </div>
  </div>
  <ng-content select="[after-header]"></ng-content>
</div>
<mat-dialog-content class="mat-typography">
  <ng-content select="[before-content]"></ng-content>
  <p *ngIf="data?.content" [innerHtml]="data?.content | safe: 'html'"></p>
  <ng-content></ng-content>
  <ng-content select="[after-content]"></ng-content>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!data?.hideDefaultButton" align="end">
  <button
    *ngFor="let button of data.buttons"
    mat-button
    [color]="button.color || 'basic'"
    [disabled]="button.disabled"
    [class]="button.class"
    (click)="close(button.role)"
  >
    {{button.label}}
  </button>
</mat-dialog-actions>
