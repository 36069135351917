<ng-container *grecoLet="videos$ | async as data">
  <greco-filter-bar [hideSeparator]="true" [filterOptions]="filterOptions" (changed)="filters$.next($event[0])">
    <div style="display: flex; flex-direction: row; gap: 16px">
      <mat-chip *ngIf="checked.length"
        >{{ checked.length }} selected
        <button class="clear" mat-icon-button (click)="clear()" matTooltip="Click to clear selected videos">
          <mat-icon style="width: 8px">clear</mat-icon>
        </button>
      </mat-chip>
      <button mat-stroked-button *ngIf="checked.length" color="primary" (click)="save()">
        <mat-icon>add</mat-icon>Select
      </button>
    </div>
    <mat-checkbox
      id="select-all"
      color="primary"
      matInput
      (change)="checkAll($event, data || [])"
      matTooltip="Select All"
      style="padding-left: 16px"
      [checked]="selectAll"
      >{{ selectAll ? '' : 'Select All' }}</mat-checkbox
    >
  </greco-filter-bar>

  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

  <greco-table [highlight]="true" [data]="data || []">
    <!--checkbox-->
    <ng-container *grecoTableCol="''; let item; fitContent: true; sticky: true">
      <mat-checkbox
        color="primary"
        id="{{ item.video.externalId }}"
        matInput
        [checked]="item.checked"
        (change)="itemChecked(item, $event.checked); verifySelectAllCheck(data || [])"
      ></mat-checkbox>
    </ng-container>
    <!-- Thumbnail -->
    <div *grecoTableCol="''; fitContent: true; let item" [matTooltip]="item.video.title">
      <img
        *ngIf="item.video.thumbnailUrl"
        [src]="item.video.thumbnailUrl | safe : 'resourceUrl'"
        alt="Thumbnail not found"
        style="
          width: 56px;
          aspect-ratio: 16 / 9;
          object-fit: cover;
          border: 0px solid #685a8f;
          border-radius: 2px;
          display: block;
        "
      />
      <mat-icon *ngIf="!item.video.thumbnailUrl"> source </mat-icon>
    </div>
    <!-- video title -->
    <ng-container>
      <p *grecoTableCol="'Title'; let item" [matTooltip]="item.video.title">
        {{ item.video.title }}
      </p>
    </ng-container>
    <!-- Date -->
    <ng-container>
      <p *grecoTableCol="'Upload Date'; let item" [matTooltip]="'Upload Date'">
        {{ item.video.uploadDate | date }}
      </p>
    </ng-container>

    <!-- Duration -->
    <ng-container>
      <p *grecoTableCol="'Duration'; let item" [matTooltip]="'Length of Video'">
        {{ item.video.duration | grecoDuration }}
      </p>
    </ng-container>

    <!--source-->
    <ng-container>
      <p *grecoTableCol="'Source'; let item" [matTooltip]="'Where the video is uploaded'">
        {{ item.video.source | titlecase }}
      </p>
    </ng-container>
    <!--Watch-->
    <ng-container>
      <button
        *grecoTableCol="''; let item"
        [matTooltip]="'Watch ' + item.video.title"
        mat-stroked-button
        style="height: 32px; line-height: 16px; margin-left: auto"
        (click)="$event.stopImmediatePropagation(); watch(item.video)"
      >
        Watch
      </button>
    </ng-container>
  </greco-table>
  <mat-paginator
    *ngIf="pagination?.totalItems"
    showFirstLastButtons
    [length]="pagination!.totalItems || 0"
    [pageSize]="pagination!.itemsPerPage || 10"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
  ></mat-paginator>
</ng-container>
