import { BaseDto } from '@greco/util-dto';
import { IsOptional, IsString } from 'class-validator';

export class CreateReportDto extends BaseDto {
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsString()
  url: string;

  @IsString()
  communityId: string;
}
