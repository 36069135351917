import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { Booking, BookingStatus, getCancellationPolicyInnerHtml } from '@greco/booking-events';
import { BookingService, Favorite, UserEventsFavoritesService } from '@greco/ngx-booking-events';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment from 'moment';

@Component({
  selector: 'greco-dashboard-booking-card',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss'],
})
export class DashboardBookingCardComponent {
  constructor(
    private matDialog: MatDialog,
    private snackbar: MatSnackBar,
    private bookingSvc: BookingService,
    public dashboardFavoritesSvc: UserEventsFavoritesService
  ) {}

  @Input() booking?: Booking;

  @Input() favorites?: Map<string, Favorite> | null;

  @Output() selectedBookingChanged = new EventEmitter<Booking>();

  moment = moment;

  now = moment();

  cancelling = false;

  addFavorite(event: any) {
    this.dashboardFavoritesSvc.addFavorite(event.title, {
      title: event.title,
      imageUrl: event.imageUrl || event.calendar?.imageUrl || 'assets/greco/placeholder.png',
      url: '/workouts?calendar=' + event.calendar?.id + '&' + event.tags?.map((tag: any) => 'tag=' + tag.id).join('&'),
    });
  }

  async join(booking: Booking) {
    if (booking.status === BookingStatus.CONFIRMED) {
      this.bookingSvc
        .checkIn(booking.id)
        .then(b => {
          booking = b;
        })
        .catch(err => console.error(err));
      // FIXME: Prevent users from joining if check-in fails.
    }

    const joinUrl = booking.event.zoomMeetingId
      ? `https://zoom.us/j/${booking.event.zoomMeetingId}`
      : booking.event.zoomEvent?.joinUrl;

    if (joinUrl) window.open(joinUrl, '_blank');
  }

  async cancelBooking(booking: Booking) {
    this.cancelling = true;

    try {
      const dialog = this.matDialog.open(SimpleDialog, {
        data: {
          title: 'Cancel Booking',
          subtitle: `${booking.event.title} - ${moment(booking.event.startDate).format('ll hh:mm A')}`,
          content: `
            ${getCancellationPolicyInnerHtml(booking.bookingOption)}
            <p>Are you sure you wish to cancel your booking?<p>
          `,
          buttons: [
            { label: 'No, keep my booking', role: 'no' },
            { label: 'Yes, cancel my booking', role: 'yes', color: 'warn' },
          ],
          showCloseButton: false,
        } as DialogData,
      });

      if ((await toPromise(dialog.afterClosed())) === 'yes') {
        await this.bookingSvc.cancel(booking.id);
        this.snackbar.open('Your booking has been cancelled.', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }
    } catch (err: any) {
      let msg = 'Error cancelling your booking!.';
      if (err.error.error === 'Conflict' && err.error.message.includes("with status 'CANCELLED'")) {
        msg += 'Already Cancelled';
      }
      this.snackbar.open(msg, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    }

    this.cancelling = false;
  }
}
