import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'isSameDay' })
export class IsSameDayPipe implements PipeTransform {
  transform(value: Date, other?: Date | null) {
    if (!value || !other) return false;
    return moment(value).isSame(other, 'day');
  }
}
