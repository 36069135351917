import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from '@greco/identity-contacts';
import { QRCodesDialog } from '@greco/ngx-identity-auth';
import { ContactService } from '../../services';
//  import type { ContactService } from '@greco/nestjs-identity-contacts';

@Component({
  selector: 'greco-membership-number-input',
  templateUrl: './membership-number-input.component.html',
  styleUrls: ['./membership-number-input.component.scss'],
})
export class MembershipNumberInputComponent implements OnChanges {
  @Input() contact!: Contact;
  @Input() readonly = false;
  @Output() saved = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder,
    private contactSvc: ContactService,
    private matDialog: MatDialog,
    private snacks: MatSnackBar
  ) {}

  resetValue = { memberNumber: '' };
  form = this.formBuilder.group({
    memberNumber: [''],
  });

  save = async () => {
    if (this.readonly) return;

    try {
      this.contact = await this.contactSvc.editContactMemberNumber(this.contact.id, {
        memberNumber: this.form.value.memberNumber,
      });
      this.reset();
      this.saved.emit();
      this.snacks.open('Member number updated!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
    } catch (err: any) {
      if (err instanceof HttpErrorResponse) {
        console.error((err as HttpErrorResponse).error.message);
        this.snacks.open((err as HttpErrorResponse).error.message, 'Ok', { duration: 6000, panelClass: 'mat-warn' });
      } else {
        console.error(err);
        this.snacks.open('Unable to update member number!', 'Ok', { duration: 6000, panelClass: 'mat-warn' });
      }
    }
  };

  openQR() {
    this.matDialog.open(QRCodesDialog, {
      data: {
        contact: this.contact,
      },
    });
  }

  private reset() {
    this.resetValue = { memberNumber: this.contact.memberNumber || '' };
    this.form.reset(this.resetValue);
    this.form.markAsPristine();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contact?.previousValue !== changes.contact?.currentValue) this.reset();
  }
}
