import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import { SmartImgModule } from '../smart-img/smart-img.module';
import { ItemFunctionPipe, TableColumnTemplate, TableComponent } from './table.component';

@NgModule({
  declarations: [TableComponent, ItemFunctionPipe, TableColumnTemplate],
  exports: [TableComponent, TableColumnTemplate],
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    NgPipesModule,
    MatButtonModule,
    MatIconModule,
    SafePipeModule,
    MatChipsModule,
    MatTooltipModule,
    MatMenuModule,
    SmartImgModule
  ]
})
export class TableModule {}
