import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import type { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { Tax } from '@greco/finance-tax';
import { UpdateProductDto } from '@greco/nestjs-sales-products';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import {
  AddonType,
  Product,
  ProductOption,
  ProductStatus,
  ProductVariant,
  ProductVariantVisibility,
  VariantResource,
  VariantResourceAction,
} from '@greco/sales-products';
import { CollapsibleSectionController } from '@greco/ui-collapsible-section';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AddInventoryDialog, CreateVariantDialog } from '../../dialogs';
import { AddonsService, InventoryService, ProductsService, VariantsService } from '../../services';

@Injectable({ providedIn: 'any' })
export class ProductVariantSearchFilter extends BuildSearchFilter('ProductVariantSearchFilter', {
  properties: ['title', 'variantOpts.value'],
  propertyLabels: ['Title'],
}) {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-product-page',
  templateUrl: './product.page.html',
  styleUrls: ['./product.page.scss'],
  viewProviders: [CollapsibleSectionController],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ProductPage implements OnInit, OnChanges {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private addonSvc: AddonsService,
    private inventorySvc: InventoryService,
    private variantSvc: VariantsService,
    private productSvc: ProductsService,
    private clipboardSvc: ClipboardService,
    private productsService: ProductsService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('product') private _product$ = new ReplaySubject<Product>(1);
  @Input() product!: Product;

  @Output() refresh = new EventEmitter();

  @PropertyListener('optionLabels') optionLabels$ = new BehaviorSubject<string[]>([]);
  optionLabels!: string[]; // Option labels are unique; used to display mat-chips in the UI

  loadingVariants = true;
  variantsMetadata?: IPaginationMeta;
  variantFilterOptions = [ProductVariantSearchFilter];

  active = ProductStatus.Active;
  variantsToActivate: ProductVariant[] = [];
  listIncludesInactiveVariants = false;

  productOptions!: ProductOption[];
  resetOptions!: string[];
  updating = false;
  updatingToCreate = false;
  updatingToUpdate = false;
  prevLabel!: string;
  processing = false;

  initialImages: File[] = [];
  initialUrls: string[] = [];

  selectable = true;
  removable = true;
  addOnBlur = true;

  options: { toCreate: string[]; toUpdate: { from: string; into: string }[]; toRemove: string[] } = {
    toCreate: [],
    toUpdate: [],
    toRemove: [],
  };

  resetValue: any = { title: '', description: '', images: null, saleCategory: null, taxes: [], ignoreTaxes: false };

  formGroup = this.formBuilder.group({
    title: ['', Validators.required],
    description: [''],
    images: [null],
    saleCategory: [null, Validators.required],
    taxes: [null],
    ignoreTaxes: [false],
  });

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  includeHiddenVariants$ = new BehaviorSubject<boolean>(false);
  includeArchivedVariants$ = new BehaviorSubject<boolean>(false);
  variantPagination$ = new BehaviorSubject<PaginatedQueryParams>({});
  variantFilters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  canManageInventory$ = this._product$.pipe(
    switchMap(async product => {
      return product?.community?.id
        ? await this.securitySvc.hasAccess(VariantResource.key, VariantResourceAction.MANAGE_INVENTORY, {
            communityId: product.community.id,
          })
        : false;
    })
  );

  variants$ = combineLatest([
    this._product$,
    this.includeHiddenVariants$,
    this.includeArchivedVariants$,
    this.variantFilters$,
    this.variantPagination$,
  ]).pipe(
    tap(() => (this.loadingVariants = true)),
    switchMap(async ([product, includeRestricted, includeArchived, filters, pagination]) => {
      const obj = filters.queryObject && filters.queryObject.s ? filters.queryObject.s : '{"$and":[]}';
      const query = new RequestQueryBuilder().search({
        $and: [
          ...(includeArchived ? [] : [{ status: { $ne: ProductStatus.Archived } }]),
          ...(includeRestricted
            ? []
            : [{ visibility: { $in: [ProductVariantVisibility.VISIBLE, ProductVariantVisibility.HIDDEN] } }]),
          ...JSON.parse(obj)['$and'],
        ],
      });
      return product ? await this.variantSvc.paginateVariants({ productId: this.product.id, pagination }, query) : null;
    }),
    tap(data => (this.variantsMetadata = data?.meta)),
    map(data => data?.items || []),
    tap(data =>
      data?.filter(variant => variant.status !== ProductStatus.Active)?.length
        ? (this.listIncludesInactiveVariants = true)
        : (this.listIncludesInactiveVariants = false)
    ),
    tap(() => (this.loadingVariants = false))
  );

  inventoryAddon$ = this._product$.pipe(
    switchMap(async product => {
      if (!product) return null;
      return await this.addonSvc.getOneByType(product.id, AddonType.Inventory);
    })
  );

  variantInventories$ = this.inventoryAddon$.pipe(
    switchMap(async addon => (addon?.enabled ? await this.inventorySvc.getVariantInventories(addon.id) : []))
  );

  async loadPage(page: number, limit: number) {
    this.processing = true;

    this.productOptions = (await this.productsService.paginateProductOptions(page, limit, this.product.id)).items.sort(
      (a, b) => a.label.localeCompare(b.label)
    );
    this.optionLabels = this.productOptions.map(option => option.label);
    this.resetOptions = this.productOptions.map(option => option.label);
    this.processing = false;
  }

  save = async () => {
    this.processing = true;

    try {
      const data: UpdateProductDto = {};

      const formImageNames: string[] = [];
      this.formGroup.value.images?.forEach((image: File) => formImageNames.push(image.name));
      const productImageNames: string[] = [];
      this.product?.images.forEach(image => productImageNames.push(image.imageURL.split('images/').pop() || ''));

      let changedImages = false;
      if (formImageNames.length !== productImageNames.length) changedImages = true;
      if (!changedImages) {
        for (let i = 0; i < formImageNames.length; i++) {
          if (formImageNames[i] !== productImageNames[i]) changedImages = true;
          break;
        }
      }

      if (this.formGroup.value.title !== this.product?.title) data.title = this.formGroup.value.title;
      if (this.formGroup.value.description !== this.product?.description)
        data.description = this.formGroup.value.description;
      if (this.formGroup.value.saleCategory?.id !== this.product?.saleCategoryId)
        data.saleCategoryId = this.formGroup.value.saleCategory?.id || null;
      if (this.formGroup.value.taxes !== this.product?.taxes)
        data.taxes = this.formGroup.value.taxes?.map((tax: Tax) => tax.id) || [];
      if (this.formGroup.value.ignoreTaxes !== this.product?.ignoreTaxes)
        data.ignoreTaxes = this.formGroup.value.ignoreTaxes;

      if (!Object.keys(data).length && !changedImages) throw new Error("You haven't made any changes");

      if (changedImages) {
        const formData = new FormData();
        let uploadImages = false;

        // only upload new photos
        this.formGroup.value.images?.forEach((image: File) => {
          if (!productImageNames.includes(image.name)) {
            uploadImages = true;
            formData.append('files', image);
          }
        });

        // remove photos
        const imagesToRemove: string[] = [];
        this.product?.images.forEach(image => {
          if (!formImageNames.includes(image.imageURL.split('images/').pop() || '')) {
            imagesToRemove.push(image.id);
          }
        });

        if (uploadImages) this.product = await this.productsService.updateProductImages(this.product.id, formData);
        if (imagesToRemove.length)
          this.product.images = await this.productsService.removeProductImages(this.product.id, imagesToRemove);
      }
      if (Object.keys(data).length) this.product = await this.productsService.updateProduct(this.product.id, data);
      this.reset();

      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }

    this.processing = false;
  };

  reset() {
    this.resetImages();
    this.resetValue = {
      title: this.product.title,
      images: this.initialImages,
      description: this.product.description,
      saleCategory: this.product.saleCategory,
      taxes: this.product.taxes || [],
      ignoreTaxes: this.product.ignoreTaxes || false,
    };
    this.formGroup.reset(this.resetValue);
    this.formGroup.markAsPristine();
  }

  resetImages() {
    this.initialImages = [];
    this.initialUrls = [];
    if (this.product?.images && this.product?.images.length) {
      for (const image of this.product.images) {
        const file = new File([], image.imageURL.split('images/').pop() || '');
        this.initialImages.push(file);
        this.initialUrls.push(image.imageURL);
      }
    }
  }

  addOption(event: MatChipInputEvent) {
    const label = (event.value || '').trim();
    if (label && !this.optionLabels.includes(label)) {
      if (this.updating) {
        // If trying to update option that was being created
        if (this.updatingToCreate) this.options.toCreate.push(label);
        // If trying to update option (that was being created/updated or already existed) to a **new** label
        else if (this.prevLabel !== label) this.options.toUpdate.push({ from: this.prevLabel, into: label });
        // If trying to update option (that was being updated) to the option's original value
        else if (this.updatingToUpdate) {
          const index = this.options.toUpdate.findIndex(elem => elem.from === this.prevLabel);
          this.options.toUpdate.splice(index, 1);
        }
      } else {
        const index = this.options.toRemove.indexOf(label);
        if (index >= 0) this.options.toRemove.splice(index, 1);
        else this.options.toCreate.push(label);
      }

      this.optionLabels.push(label);
    }

    this.updating = false;
    this.updatingToCreate = false;
    this.updatingToUpdate = false;
    this.prevLabel = '';

    event.input.value = '';
  }

  updateOption(label: string) {
    if (this.updating) return;
    this.updating = true;
    this.prevLabel = label;

    let index = this.options.toCreate.indexOf(label);
    if (index >= 0) {
      this.updatingToCreate = true;
      this.options.toCreate.splice(index, 1);
    }

    index = this.options.toUpdate.findIndex(elem => elem.into === label);
    if (index >= 0) {
      this.updatingToUpdate = true;
      this.prevLabel = this.options.toUpdate[index].from;
      this.options.toUpdate.splice(index);
    }

    this.optionLabels.splice(this.optionLabels.indexOf(label), 1);

    (<HTMLInputElement>document.getElementById('input')).value = label;
  }

  removeOption(label: string) {
    if (this.updating) return;
    if (this.optionLabels.length === 1) {
      const err = 'Cannot remove the last product option';
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      throw new Error(err);
    }

    const index = this.optionLabels.indexOf(label);
    if (index >= 0) {
      this.optionLabels.splice(index, 1);

      const indexCreate = this.options.toCreate.indexOf(label);
      if (indexCreate >= 0) this.options.toCreate.splice(indexCreate, 1);

      const indexUpdate = this.options.toUpdate.findIndex(elem => elem.into === label);
      if (indexUpdate >= 0) {
        this.options.toRemove.push(this.options.toUpdate[indexUpdate].from);
        this.options.toUpdate.splice(indexUpdate, 1);
      }

      if (indexCreate < 0 && indexUpdate < 0) this.options.toRemove.push(label);
    }
  }

  async saveOptions() {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `
              <p>
                You are about to create ${this.options.toCreate.length} option(s), update
                ${this.options.toUpdate.length} option(s), and remove ${this.options.toRemove.length} option(s).
              </p>

              <p><strong>Are you sure you want to continue?</strong></p>
            `,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      try {
        for (const label of this.options.toCreate) {
          await this.productsService.createProductOption(this.product.id, { label });
        }
        if (this.options.toCreate.length) await this.loadPage(1, 10);

        for (const obj of this.options.toUpdate) {
          const option = this.productOptions.find(option => option.label === obj.from);
          await this.productsService.updateProductOption(option?.id || '', this.product.id, { label: obj.into });
        }
        if (this.options.toUpdate.length) await this.loadPage(1, 10);

        for (const label of this.options.toRemove) {
          const option = this.productOptions.find(option => option.label === label);
          await this.productsService.removeProductOption(option?.id || '', this.product.id);
        }
        if (this.options.toRemove.length) await this.loadPage(1, 10);

        this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err) {
        this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        console.error(err);
      } finally {
        this.options = {
          toCreate: [],
          toUpdate: [],
          toRemove: [],
        };
      }
    }
  }

  resetProductOptions() {
    this.optionLabels = this.resetOptions.map(elem => elem);
    this.options = {
      toCreate: [],
      toUpdate: [],
      toRemove: [],
    };
  }

  async activateVariant(variant: ProductVariant) {
    try {
      await this.variantSvc.activateVariant({
        productId: this.product.id,
        variantId: variant.id,
      });

      this.snacks.open('Variant activated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.variantPagination$.next(this.variantPagination$.value);
      this.refresh.emit();
    } catch (err) {
      console.error(err);
    }
  }

  toggleActivateVariant(variantToToggle: ProductVariant) {
    if (this.variantsToActivate.map(variant => variant.id).includes(variantToToggle.id)) {
      this.variantsToActivate = this.variantsToActivate.filter(variant => variant.id != variantToToggle.id);
    } else this.variantsToActivate.push(variantToToggle);
  }

  variantInList(variant: ProductVariant) {
    return this.variantsToActivate.map(p => p.id).includes(variant.id);
  }

  async activateBulk() {
    const activatedVariants: string[] = [];
    const variantsNotActivated: ProductVariant[] = [];

    await Promise.all(
      this.variantsToActivate.map(async variant => {
        try {
          const activatedVariant = await this.variantSvc.activateVariant({
            productId: variant.productId,
            variantId: variant.id,
          });
          if (activatedVariant.status == ProductStatus.Active) activatedVariants.push(activatedVariant.id);
        } catch (err) {
          variantsNotActivated.push(variant);
          this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
          console.error(err);
        }
      })
    );

    if (activatedVariants.length) this.variantPagination$.next(this.variantPagination$.value);
    if (activatedVariants.length === this.variantsToActivate.length) {
      this.snacks.open('Activated all variants!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } else {
      if (activatedVariants.length) {
        this.snacks.open('Activated some variants!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      }

      this.snacks.open('Unable to activate variants: ' + variantsNotActivated.map(p => p.title).join(', '), 'Ok', {
        duration: 10000,
        panelClass: 'mat-warn',
      });
    }

    this.variantsToActivate = [];
  }

  async toggleVariantHidden(variant: ProductVariant) {
    const visibility =
      variant.visibility === ProductVariantVisibility.VISIBLE
        ? ProductVariantVisibility.HIDDEN
        : variant.visibility === ProductVariantVisibility.HIDDEN
        ? ProductVariantVisibility.RESTRICTED
        : ProductVariantVisibility.VISIBLE;
    try {
      await this.variantSvc.updateVariant({
        productId: variant.productId,
        variantId: variant.id,
        data: { visibility },
      });
      this.snacks.open('Variant visibility updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.variantPagination$.next(this.variantPagination$.value);
    } catch (err) {
      console.error(err);
    }
  }

  async archiveVariant(variant: ProductVariant) {
    try {
      await this.variantSvc.archiveVariant({
        productId: this.product.id,
        variantId: variant.id,
      });
      this.snacks.open('Variant archived!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.variantPagination$.next(this.variantPagination$.value);
    } catch (err) {
      console.error(err);
    }
  }

  async openVariant(variant: ProductVariant) {
    await this.router.navigate([`variants/${variant.id}`], { relativeTo: this.route });
  }

  async createVariant() {
    if (!this.productOptions.length) {
      const msg = 'Cannot create variant without any product options';
      this.snacks.open(msg, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      throw new Error('Cannot create variant without any product options');
    }

    const dialog = await toPromise(
      this.dialog
        .open(CreateVariantDialog, { data: { options: this.productOptions, product: this.product } })
        .afterClosed()
    );
    if (dialog) {
      this.variantPagination$.next(this.variantPagination$.value);
    }
  }

  async duplicateVariant(variant: ProductVariant) {
    try {
      await this.variantSvc.duplicateVariant(this.product.id, variant.id);
      this.snacks.open('New variant created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.variantPagination$.next(this.variantPagination$.value);
    } catch (err) {
      console.error(err);
    }
  }

  copyCheckoutUrlToClipboard(variant: ProductVariant) {
    this.clipboardSvc.copy(
      `${window.location.origin}/shop/checkout?items=${variant.id}&productId=${this.product.id}`,
      'Checkout URL'
    );
  }

  async activateProduct() {
    try {
      const oneVrtActive = this.product.variants.some(vrt => vrt.status === ProductStatus.Active);
      if (!oneVrtActive) throw new Error('Cannot activate product, there must be at least one active variant');

      this.product = await this.productSvc.activateProduct(this.product.id);
      this.snacks.open('Activated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }
  }

  async archiveProduct() {
    try {
      this.product = await this.productSvc.archiveProduct(this.product.id);
      this.snacks.open('Archived!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err) {
      this.snacks.open('' + err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }
  }

  resetForm() {
    this.resetImages();
    this.formGroup.markAsPristine();
  }

  async ngOnInit() {
    this.product = await this.productsService.getOneProduct(this.product.id);

    await this.loadPage(1, 5);

    this.resetImages();

    this.formGroup.patchValue({
      title: this.product.title,
      images: this.initialImages,
      description: this.product.description,
      saleCategory: this.product.saleCategory,
      taxes: this.product.taxes || [],
      ignoreTaxes: this.product.ignoreTaxes,
    });

    this.resetValue = {
      title: this.product.title,
      images: this.initialImages,
      description: this.product.description,
      saleCategory: this.product.saleCategory,
      taxes: this.product.taxes || [],
      ignoreTaxes: this.product.ignoreTaxes,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.previousValue !== changes.user?.currentValue) this.reset();
  }

  async openInventory(addonId: string, variantId: string) {
    const dialog = await toPromise(
      this.dialog.open(AddInventoryDialog, { data: { addonId, variantId } }).afterClosed()
    );
    if (dialog) {
      this.variantPagination$.next(this.variantPagination$.value);
    }
  }
}
