<h2 style="margin: 0">Connect New Form</h2>

<mat-form-field appearance="standard" style="width: 100%">
  <mat-label>Form</mat-label>
  <mat-select required (valueChange)="selectedForm = $event">
    <mat-option *ngFor="let form of forms" [value]="form.id"> {{form.title}} </mat-option>
  </mat-select>
</mat-form-field>

<div style="display: flex; gap: 8px">
  <button style="flex: 1" type="button" mat-stroked-button (click)="close()">Cancel</button>
  <button
    style="flex: 1"
    type="button"
    mat-stroked-button
    color="primary"
    [disabled]="!selectedForm || connecting"
    (click)="connect()"
  >
    <mat-icon *ngIf="connecting" class="spin">loop</mat-icon>
    <span>Confirm</span>
  </button>
</div>
