import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { ProductAddonEntity } from '@greco/nestjs-sales-products';
import { AddonType, Product, ProductCollection } from '@greco/sales-products';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable()
export class ShopService {
  constructor(private http: HttpClient) {}

  // @Get('products')
  async paginateAllProducts(query: RequestQueryBuilder, pagination?: Partial<PaginatedQueryParams>) {
    return await toPromise(
      this.http.get<PaginatedDto<Product>>('/api/shop/products', {
        params: {
          ...query.queryObject,
          page: (pagination?.page || 1).toString(),
          limit: (pagination?.limit || 10).toString(),
        },
      })
    );
  }

  // @Get('collections')
  async getAllCollections(communityIds: string[]) {
    return await toPromise(
      this.http.get<ProductCollection[]>('/api/shop/collections', {
        params: { communityIds },
      })
    );
  }

  async getProductAddOnConfig(productId: string, addOnType: AddonType): Promise<ProductAddonEntity> {
    return await toPromise(this.http.get<ProductAddonEntity>(`/api/products/${productId}/addons/${addOnType}`));
  }
}
