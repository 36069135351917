import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogData } from '@greco-fit/scaffolding';
import { Booking, CalendarEvent, CourseRegistration, EventSeries } from '@greco/booking-events';
import { Contact } from '@greco/identity-contacts';
import { EventService } from '../../services';

@Component({
  selector: 'greco-add-attendee-dialog',
  templateUrl: './add-attendee.dialog.html',
  styleUrls: ['./add-attendee.dialog.scss'],
})
export class AddAttendeeDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: { event: CalendarEvent; series: EventSeries; contact?: Contact; extraEventIds?: string[] },
    private dialogRef: MatDialogRef<AddAttendeeDialog>,
    private snacks: MatSnackBar,
    private eventSvc: EventService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (data.contact) {
      this.userControl.setValue(data.contact);
    }
  }

  loading = false;

  cancelButton = {
    label: 'Cancel',
    role: 'cancel',
    resultFn: async () => this.cancel(),
  };

  confirmButton = {
    label: 'Proceed to Booking',
    color: 'primary',
    role: 'submit',
    resultFn: async () => this.confirm(),
  };

  dialogData: DialogData = {
    title: 'Add Attendee',
    subtitle: `<b>${this.data?.event?.title || this.data?.series?.title}</b>`,
    showCloseButton: false,
    buttons: [this.cancelButton],
  };

  public readonly userControl = new FormControl(null, Validators.required);

  async changeLoading(loading: boolean) {
    this.loading = loading;
  }

  cancel() {
    try {
      return { event: null, action: 'cancel' };
    } catch (err) {
      console.error(err);
      this.snacks.open('' + err, 'Ok', { panelClass: 'mat-warn' });
      return null;
    }
  }

  confirm() {
    this.router.navigate(
      [`/admin/scheduling/${this.data.event.community.id}/${this.data.event.id}/${this.userControl.value.user.id}`],
      { relativeTo: this.route }
    );
  }

  booked(booking: Booking | Booking[] | CourseRegistration) {
    this.dialogRef.close(booking);
  }

  async ngOnInit() {
    if (this.data.event && !this.data.series) this.data.event = await this.eventSvc.getOneEvent(this.data.event.id);
  }
}
