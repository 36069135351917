import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { EventTemplateResourceAssignment, ResourceAssignment } from '@greco/booking-events';
import { EventTemplateDetails } from '@greco/ngx-booking-events';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { EventTemplateService } from '../../services';

@Component({
  selector: 'greco-create-event-template-dialog',
  templateUrl: './create-event-template.dialog.html',
  styleUrls: ['./create-event-template.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateEventTemplateDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private formBuilder: FormBuilder,
    private eventTemplateSvc: EventTemplateService,
    private snacks: MatSnackBar
  ) {}

  private _onDestroy$ = new Subject<void>();

  formGroup = this.formBuilder.group({
    eventTemplateDetails: [null],
  });

  dialogData: DialogData = {
    title: 'Create New Event Template',
    subtitle: 'Provide the details for the new event template',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      {
        label: 'Create',
        role: 'create',
        resultFn: async () => {
          try {
            const eventTemplateDetails = this.formGroup.value.eventTemplateDetails;

            let resourceAssignments: EventTemplateResourceAssignment[] = [];
            if (eventTemplateDetails.resourceAssignments) {
              resourceAssignments = eventTemplateDetails.resourceAssignments;
              resourceAssignments.forEach(assignment => {
                delete assignment.resourceTag;
              });
            }

            if (eventTemplateDetails.zoomAssignment) resourceAssignments.push(eventTemplateDetails.zoomAssignment);
            if (eventTemplateDetails.roomAssignment) resourceAssignments.push(eventTemplateDetails.roomAssignment);

            const resourceTags = (eventTemplateDetails.resourceAssignments as ResourceAssignment[])?.reduce(
              (acc, assignment) => {
                if (assignment.resourceTag && assignment.resourceTagId) {
                  if (!acc.includes(assignment.resourceTagId)) {
                    acc.push(assignment.resourceTagId);
                  }
                }
                return acc;
              },
              [] as string[]
            );

            const eventTemplate = await this.eventTemplateSvc.createEventTemplate({
              resourceTags,
              resourceAssignments,
              autoAssign: eventTemplateDetails.autoAssign,
              resourcesLocked: eventTemplateDetails.resourcesLocked,
              enableUserSpotBooking: eventTemplateDetails.enableUserSpotBooking,
              enableUserSpotBookingLocked: eventTemplateDetails.enableUserSpotBookingLocked,
              checkInWindow: eventTemplateDetails.checkInWindow,
              checkInWindowLocked: eventTemplateDetails.checkInWindowLocked,
              description: eventTemplateDetails.description,
              descriptionLocked: eventTemplateDetails.descriptionLocked,
              maxCapacity: eventTemplateDetails.maxCapacity,
              maxCapacityLocked: eventTemplateDetails.maxCapacityLocked,
              communityId: this.data.communityId,
              duration: eventTemplateDetails.duration,
              durationLocked: eventTemplateDetails.durationLocked,
              title: eventTemplateDetails.title,
              titleLocked: eventTemplateDetails.titleLocked,
              imageUrl: eventTemplateDetails.imageUrl,
              imageUrlLocked: eventTemplateDetails.imageUrlLocked,
              colorLocked: eventTemplateDetails.colorLocked,
              color: eventTemplateDetails.color,
              tags: eventTemplateDetails.tags,
              tagsLocked: eventTemplateDetails.tagsLocked,
              private: eventTemplateDetails.private,
              privateLocked: eventTemplateDetails.privateLocked,
              typeform: ((eventTemplateDetails.typeform as EventTemplateDetails['typeform']) || []).map(
                ({ id, reusable, required }) => ({
                  formId: id,
                  reusable,
                  required,
                })
              ),
              typeformLocked: eventTemplateDetails.typeformLocked,
              zoomMeetingId: eventTemplateDetails.zoomMeetingId,
            });

            this.snacks.open('New Event Template created!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
            return eventTemplate;
          } catch (err) {
            console.error(err);
            if (!(err instanceof HttpErrorResponse)) {
              this.snacks.open(`Error: ${err.message ? err.message : err}`, 'Ok', {
                duration: 2500,
                panelClass: 'mat-warn',
              });
            }
            return null;
          }
        },
      },
    ],
  };

  async ngOnInit() {
    this.formGroup.valueChanges.pipe(startWith(this.formGroup.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.dialogData = {
        ...this.dialogData,
        buttons: this.dialogData.buttons?.map(btn => {
          if (btn.role === 'create') btn.disabled = this.formGroup.invalid;
          return btn;
        }),
      };
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
