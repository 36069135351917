import { Component, Inject, OnInit } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@greco/ngx-app-config';
import { CustomerSupportService } from '../../services/customer-support.service';

@Component({
  selector: 'greco-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
})
export class HelpButtonComponent implements OnInit {
  hasLink = false;
  constructor(
    private customerSupportService: CustomerSupportService,
    @Inject(APP_CONFIG) public appConfig: AppConfig
  ) {}

  async ngOnInit() {
    this.hasLink = !!(await this.customerSupportService.getCustomerSupportConfig(this.appConfig.name))?.helpTypeform;
  }
}
