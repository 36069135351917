import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalendarEvent } from '@greco/booking-events';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-users';
import { AccountLinkingService } from '@greco/web-account-linking';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './user-add-guest.dialog.html',
  styleUrls: ['./user-add-guest.dialog.scss'],
})
export class UserAddGuestDialog implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User; event: CalendarEvent },
    private ref: MatDialogRef<UserAddGuestDialog>,
    private formBuilder: FormBuilder,
    private userSvc: UserService,
    private linkSvc: AccountLinkingService,
    private snacks: MatSnackBar
  ) {
    this.user = data.user;
    this.event = data.event;
  }
  private _onDestroy$ = new Subject<void>();
  user!: User;
  event!: CalendarEvent;
  isValid = true;
  _confirming = false;

  form = this.formBuilder.group({
    displayName: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
  });

  isValid$ = this.form.valueChanges.pipe();

  async ngOnInit() {
    this.form.valueChanges.pipe(startWith(this.form.value), takeUntil(this._onDestroy$)).subscribe(() => {
      this.isValid = !this.form.invalid;
    });
  }
  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  cancel() {
    this.ref.close();
  }
  async confirm() {
    this._confirming = true;
    const displayName = this.form.value.displayName;
    const email = this.form.value.email;
    if (!displayName || !email) {
      this.snacks.open('Missing email or display name', '', {
        duration: 6000,
        panelClass: 'mat-warn',
      });
      this._confirming = false;
      return;
    }
    if (email === this.user.email) {
      this.snacks.open('Cannot create a guest account with the same email as you!', '', {
        duration: 6000,
        panelClass: 'mat-warn',
      });
      this._confirming = false;
      this.ref.close();
      return;
    }
    const user = await this.userSvc.findOneByEmail(email);
    if (user) {
      //check for existing link.
      const links = await this.linkSvc.getPrivilegeLinksForAccessor(this.user.id);
      const link = links.find(link => link.account?.email === email);
      if (link) {
        //link exists, return it.
        this.ref.close(link.account);
        return;
      } else {
        await this.linkSvc.requestAccess({ accessorId: this.user.id, accountId: user.id, privileges: [] });
        this.ref.close();
        this.snacks.open('User Already Exists! An account link request has been sent on your behalf', '', {
          duration: 6000,
          panelClass: 'mat-warn',
        });
      }
    } else {
      try {
        const link = await this.linkSvc.addGuest(this.user.id, {
          displayName,
          email,
          communityId: this.event.community.id,
        });

        this.ref.close(link.account);
      } catch (e) {
        this.snacks.open('Something Went wrong! Make sure email address is properly formatted.', '', {
          duration: 6000,
          panelClass: 'mat-warn',
        });
      }
      this._confirming = false;
    }
  }
}
