<ng-container *ngIf="community"
  ><greco-simple-dialog [data]="dialogData">
    <div *ngIf="community.welcomeContent" [innerHtml]="community.welcomeContent | safe: 'html'"></div>
    <ng-container *ngIf="community.address">
      <h3 style="margin-bottom: 0">Community Address</h3>
      <p><small>{{community.address?.formatted}}</small></p>
      <object
        style="width: 100%; border-radius: 8px"
        type="text/html"
        [data]="'https://www.google.com/maps/embed/v1/place?q=place_id:' + community.address?.placeId + '&key=' + googleMapsKey | safe: 'resourceUrl'"
      ></object>
    </ng-container>
  </greco-simple-dialog>
</ng-container>

<!-- title: string;
subtitle?: string;
content?: string;
showCloseButton?: boolean;
hideDefaultButton?: boolean;
buttons?: {
  label: string;
  role: string;
  class?: string;
  color?: string;
  disabled?: boolean;
  resultFn?: () => Promise<any> | any;
}[]; -->
