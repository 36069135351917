import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PaginatedDto } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { CreateCouponDto, UpdateCouponDto } from '@greco/api-coupons';
import { Coupon } from '@greco/coupon';
import { GrantPerkDto, UserPerkEntity } from '@greco/nestjs-sales-perks';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationOptions as IIPaginationOptions } from 'nestjs-typeorm-paginate';

type IPaginationOptions = Omit<IIPaginationOptions, 'route'>;

function paginationQueryParams(options?: IPaginationOptions): { [param: string]: string } {
  return {
    ...(options?.limit ? { limit: options.limit.toString() } : {}),
    ...(options?.page ? { page: options.page.toString() } : {}),
  };
}

@Injectable({ providedIn: 'root' })
export class CouponService {
  constructor(private http: HttpClient) {}

  async paginate(
    communityId: string,
    pagination: IPaginationOptions,
    queryBuilder: RequestQueryBuilder
  ): Promise<PaginatedDto<Coupon>> {
    return await toPromise(
      this.http.get<PaginatedDto<Coupon>>('/api/coupons/paginate', {
        params: {
          communityId,
          ...paginationQueryParams(pagination),
          ...queryBuilder.queryObject,
        },
      })
    );
  }

  // async getCouponStats(couponId: string){
  //   return await toPromise(this.http.get<Coupon>(`/api/coupons/${couponId}`));
  // }

  async getOne(couponId: string): Promise<Coupon> {
    return await toPromise(this.http.get<Coupon>(`/api/coupons/${couponId}`));
  }

  async getManyExcludingArchives(communityId: string) {
    return await toPromise(this.http.get<Coupon[]>(`/api/coupons`, { params: { communityId } }));
  }

  async create(dto: CreateCouponDto) {
    return await toPromise(this.http.post<Coupon>(`/api/coupons`, dto));
  }

  async update(couponId: string, data: UpdateCouponDto) {
    return await toPromise(this.http.patch<Coupon>(`/api/coupons/${couponId}`, data));
  }

  // @Post('grant')
  async grantCoupon(dto: GrantPerkDto) {
    return await toPromise(this.http.post<UserPerkEntity>('/api/coupons/grant', dto));
  }

  async archive(couponId: string) {
    return await toPromise(this.http.put<Coupon>(`/api/coupons/archive`, { couponId }));
  }

  async restore(couponId: string) {
    return await toPromise(this.http.put<Coupon>(`/api/coupons/restore`, { couponId }));
  }
}
