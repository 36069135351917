import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import {
  BookingOption,
  BookingRequirement,
  BookingStatus,
  calculateBoosterRequirements,
  EventSeries,
  PersonResource,
  Resource,
  ResourceType,
  TypeformBookingRequirement,
  UserBookingOptionStats,
} from '@greco/booking-events';
import {
  AgreementType,
  CommunityAgreementSecurityActions,
  CommunityAgreementSecurityResource,
  UserAgreementDto,
} from '@greco/community-agreements';
import { PaymentMethod } from '@greco/finance-payments';
import { User } from '@greco/identity-users';
import { CoursePreview } from '@greco/nestjs-booking-events';
import { UserCommunityAgreementsService } from '@greco/ngx-community-agreements';
import { UserPaymentMethodService } from '@greco/ngx-finance-payments';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { AccountLinkingService } from '@greco/web-account-linking';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { BookingService, CourseService } from '../../../../services';
import { EventCommunityAgreementsUsageService } from '../../../../services/event-community-agreements-usage.service';
import { PreviewBookingBookingOptionPickerComponent } from '../../preview-booking-2/booking-option-picker/booking-option-picker.component';

@Component({
  selector: 'greco-preview-course',
  templateUrl: './preview-course.component.html',
  styleUrls: ['./preview-course.component.scss'],
})
export class PreviewCourseComponent implements OnDestroy {
  constructor(
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private formGroup: FormBuilder,
    private courseSvc: CourseService,
    private formBuilder: FormBuilder,
    private bookingSvc: BookingService,
    private linkSvc: AccountLinkingService,
    private securitySvc: CommunitySecurityService,
    private paymentMethodSvc: UserPaymentMethodService,
    private usrAgreementSvc: UserCommunityAgreementsService,
    private agreementUsageSvc: EventCommunityAgreementsUsageService
  ) {}

  @ViewChild(PreviewBookingBookingOptionPickerComponent)
  private bookingOptionPicker?: PreviewBookingBookingOptionPickerComponent;

  @Input() _stats!: {
    [id: string]: UserBookingOptionStats;
  };
  @PropertyListener('event') private _event$ = new ReplaySubject<EventSeries>(1);
  @Input() event!: EventSeries;

  @PropertyListener('selectedBookingOption') _selectedBookingOption$ = new ReplaySubject<BookingOption>(1);
  @Input() selectedBookingOption?: BookingOption;

  @Input() allowPendingBookings!: boolean;

  @Input() user!: User;
  @PropertyListener('user') private user$ = new BehaviorSubject(this.user);

  @Input() bookedBy?: User = undefined;
  @PropertyListener('bookedBy') bookedBy$ = new BehaviorSubject(this.bookedBy);

  @PropertyListener('overridePaymentMethod') overridePaymentMethod$ = new BehaviorSubject<PaymentMethod | null>(null);
  @Input() overridePaymentMethod: PaymentMethod | null = null;

  @Output() previewUpdated = new EventEmitter<CoursePreview | null>();
  @Output() requirementsUpdated = new EventEmitter<BehaviorSubject<{ [id: string]: string }>>();
  @Output() signaturesUpdated = new EventEmitter<{ agreementId: string; signature: string }[]>();

  @Output() refreshed = new EventEmitter<void>();

  loading = true;
  hideSignLater = false;

  readonly useBookedByPerks$ = new ReplaySubject<boolean>(1);

  requirementValues$ = new BehaviorSubject<{ [id: string]: string }>({});

  refresh$ = new BehaviorSubject(null);
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  reuseLast: boolean[] = [];
  loaded = false;
  agreementsForm = this.formBuilder.group({});
  paginationMeta?: IPaginationMeta;
  controlsConfigDetails: { [key: string]: any } = {};
  agreementData$ = new BehaviorSubject<(UserAgreementDto & { unsigned?: boolean })[]>([]);
  currentUserId$ = this.userSvc.getUserId();

  paymentMethodControl = this.formBuilder.control([null]);
  signatures: { agreementId: string; signature: string }[] = [];

  _overridePaymentMethod$ = this.overridePaymentMethod$.pipe(
    tap(paymentMethod => {
      this.paymentMethodControl.setValue(paymentMethod);
    })
  );

  readonly hasPaymentMethod$ = combineLatest([
    this.bookedBy$,
    this.paymentMethodControl.valueChanges.pipe(startWith(null)),
  ]).pipe(
    switchMap(async ([user, selectedPaymentMethod]) => {
      if (selectedPaymentMethod) return true;

      const paymentMethod = user ? await this.paymentMethodSvc.getDefault(user.id, true) : null;
      return !!paymentMethod && paymentMethod.model !== 'bank';
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly trainers$ = this._event$.pipe(
    map(
      event =>
        (event?.resourceAssignments
          ?.map(assignment => assignment?.resource)
          .filter(r => r?.type === ResourceType.PERSON) || []) as PersonResource[]
    ),
    shareReplay(1)
  );

  readonly photoUrls$ = this.trainers$.pipe(
    map(trainers => {
      const photoUrls = trainers.map(trainer => trainer.photoURL as string).filter(url => !!url);
      return photoUrls?.length ? photoUrls : ['assets/lf3/icon_square_pad.png'];
    })
  );

  eventResources$ = this._event$.pipe(
    map(event => {
      return event.resourceAssignments.reduce((acc, assignment) => {
        if (assignment?.resource?.type == ResourceType.PERSON) acc.push(assignment.resource);
        return acc;
      }, [] as Resource[]);
    })
  );

  readonly requiredBoosts$ = combineLatest([this._event$, this._selectedBookingOption$]).pipe(
    map(([event, bookingOption]) => {
      if (!event || !bookingOption) return -1;
      if (bookingOption.id === 'prk_complimentarybooking') return 0;
      return calculateBoosterRequirements(event, bookingOption);
    }),
    shareReplay(1)
  );

  typeformRequirements$ = this._event$.pipe(
    map(event => event.requirements.filter(req => req.type === 'typeform') as TypeformBookingRequirement[])
  );

  readonly booking$ = combineLatest([this._event$, this.user$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([event, user]) =>
      event && user
        ? await this.bookingSvc.paginate(
            new RequestQueryBuilder().search({
              'user.id': user.id,
              'event.id': event.id,
              status: { $in: [BookingStatus.CONFIRMED, BookingStatus.CHECKED_IN, BookingStatus.PENDING] },
            }),
            event.community.id,
            { limit: 1 }
          )
        : null
    ),
    map(data => data?.items?.[0] || null)
  );

  readonly seriesAgrUsage$ = combineLatest([this.filters$, this.pagination$, this._event$, this.refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = false))),
    debounceTime(500),
    switchMap(async ([filters, options, event]) => {
      const agreements = await this.agreementUsageSvc.paginateEventAgreementUsages(event.id, options, filters);
      return agreements;
    }),
    tap(agreements => {
      if (this.loaded == false) {
        agreements.items.forEach(agreement => {
          this.controlsConfigDetails[`${agreement.id}-unsigned`] = [false];

          switch (agreement.agreement?.agreementType) {
            case AgreementType.AUTO_CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [true, Validators.requiredTrue];
              this.agreementData$.value.push({ agreementId: agreement.agreementId, signed: true });
              break;
            }
            case AgreementType.CHECKBOX: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData$.value.push({ agreementId: agreement.agreementId, signed: false });
              break;
            }
            case AgreementType.DIGITAL_SIGNATURE: {
              this.controlsConfigDetails[`${agreement.id}`] = [false, Validators.requiredTrue];
              this.agreementData$.value.push({ agreementId: agreement.agreementId, signed: false });
              break;
            }
          }
        });
      }
      this.loaded = true;

      this.agreementsForm = this.formGroup.group({ ...this.controlsConfigDetails });
    }),
    tap(({ meta }) => setTimeout(() => (this.paginationMeta = meta))),
    map(({ items }) => items),

    tap(() => setTimeout(() => (this.loading = false)))
  );

  readonly canLeaveUnsigned$ = combineLatest([this.bookedBy$, this.user$, this._event$, this.seriesAgrUsage$]).pipe(
    switchMap(async ([bookedBy, bookedFor, event, agreementUsages]) => {
      const controls = this.agreementsForm.controls;
      if (bookedFor?.id !== bookedBy?.id) {
        Object.keys(controls).forEach(control => {
          const agreementId = agreementUsages.find(usage => usage.id === control)?.agreementId;
          if (agreementId) this.setAgreementRequired(control, agreementId, false);
        });

        this.hideSignLater = true;
      } else {
        Object.keys(controls).forEach(control => {
          const agreementId = agreementUsages.find(usage => usage.id === control)?.agreementId;
          if (agreementId) this.setAgreementRequired(control, agreementId, true);
        });
      }

      if (!bookedFor || !bookedBy || !event) return false;
      if (bookedBy.id === bookedFor.id) return false;

      const link = await this.linkSvc.getLink(bookedBy.id, bookedFor.id);
      if (bookedBy.id !== bookedFor.id && link) return true;

      return await this.securitySvc.hasAccess(
        event.community.id,
        CommunityAgreementSecurityResource.key,
        CommunityAgreementSecurityActions.LEAVE_UNSIGNED
      );
    })
  );

  private selectedPaymentMethod$ = this.paymentMethodControl.valueChanges.pipe(
    startWith<PaymentMethod | null, PaymentMethod | null>(null),
    switchMap(pm => {
      if (pm) return of(pm);
      return this.bookedBy$.pipe(
        switchMap(user => (user ? this.paymentMethodSvc.getDefault(user.id, true) : of(null))),
        map(pm => pm ?? null)
      );
    }),
    distinctUntilChanged((prev, next) => prev?.id === next?.id)
  );

  readonly preview$: Observable<CoursePreview | null> = combineLatest([
    this.booking$,
    this.user$,
    this.bookedBy$,
    this._event$,
    this._selectedBookingOption$,
    this.requirementValues$,
    this.selectedPaymentMethod$,
    this.agreementData$ as Observable<any>,
    this.seriesAgrUsage$,
  ]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([booking, user, bookedBy, event, bookingOption, reqs, pm, agreements, _sr]) => {
      if (!user || !event || !bookingOption) return null;

      const requirements = Object.entries(reqs || {}).reduce(
        (acc, [key, value]) => [...(acc as any), { requirementId: key, value }],
        [] as { requirementId: string; value: string }[]
      );
      const useBookedByPerks = this.bookingOptionPicker?.bookedByPerksAreSelected || false;
      const bookingOptionStats = useBookedByPerks
        ? this.bookingOptionPicker?._bookedByStats?.[bookingOption.id]
        : this.bookingOptionPicker?._stats?.[bookingOption.id];
      const bookingOptionAvailable = !!(bookingOptionStats?.consumable || bookingOptionStats?.reusable);
      const isComplimentary = bookingOption.id === this.bookingOptionPicker?.complimentaryBookingOption?.id;

      return !booking && user && event && bookingOption
        ? await this.courseSvc
            .preview({
              bookingOptionId: bookingOption.id,
              paymentMethodId: pm?.id,
              applyBalance: true,
              eventId: event.id,
              userId: user.id,
              bookedById: bookedBy?.id || user.id,
              useBookedByPerks: true,
              requirements,
              course: true,
              agreements,
              completed: bookingOptionAvailable || isComplimentary ? true : false,
            })
            .then(preview => {
              const allSigned = this.agreementData$.value.every(agreement => agreement.signed || agreement.unsigned);

              if (allSigned) {
                preview.errors = preview.errors.filter(error => error !== 'All agreements must be signed');
              }

              return {
                ...preview,
                errors: preview.errors.map(err => {
                  if (err.startsWith('Missing form submission:')) {
                    const form = err.replace('Missing form submission: ', '').replace(/'/g, '');
                    return `Please fill in the form <strong>${form}</strong>.`;
                  }

                  if (err.startsWith('Missing payment method')) {
                    return 'Please add a payment method.';
                  }

                  if (err.startsWith('Event already full')) {
                    return 'This event has already reached capacity.';
                  }

                  if (err.startsWith('Unable to book event. Past booking cutoff.')) {
                    return 'This event is not available anymore.';
                  }

                  if (err.includes('User already booked in')) {
                    return 'You are already booked into this event.';
                  }

                  if (err.includes('Booking window not reached')) {
                    return 'This event is not yet available.';
                  }

                  if (err.includes('All agreements must be signed')) {
                    return 'All agreements must be signed.';
                  }
                  if (err.includes('failed payments')) {
                    return `You're not able to complete the purchase due to failed payments!`;
                  }
                  if (err.includes('Insufficient quantity')) {
                    return `Insufficient quantity for the selected booking option or the option is non-transferable`;
                  }
                  return 'Something went wrong. Please try again.';
                }),
              };
            })
            .catch(err => {
              this.snacks.open('Error: ' + err.error.message, 'Ok', { duration: 10000, panelClass: 'mat-warn' });
              return null;
            })
        : null;
    }),
    tap(() => (this.loading = false)),
    distinctUntilChanged(),
    tap(preview => {
      if (preview) this.previewUpdated.emit(preview);
    })
  );

  readonly canUsePerks$ = combineLatest([this.bookedBy$, this.user$]).pipe(
    switchMap(async ([bookedBy, user]) => {
      if (!bookedBy) return true;
      if (!user) return false;
      if (bookedBy.id === user?.id) return true;
      const link = (await this.linkSvc.getPrivilegeLinksForAccessor(bookedBy.id))?.find(
        link => link.accountId === user.id
      );
      if (!link) return false;
      return link.canUsePerks;
    })
  );

  async isSigned() {
    const agreements = await toPromise(this.seriesAgrUsage$);
    const signedAgr = await this.usrAgreementSvc.getManyUserAgrmt(this.user?.id || '');
    const result = signedAgr.filter(signAgr => agreements.some((agr: any) => signAgr.agreementId === agr.agreementId));
    result.forEach(agr => {
      if (!result.includes(agr)) {
        result.push(agr);
      }
    });
    return result;
  }

  requirementFilledIn(requirement: BookingRequirement, value: string) {
    this.requirementValues$.next({ ...this.requirementValues$.value, [requirement.id]: value });
    this.requirementsUpdated.emit(this.requirementValues$);
  }

  setCheck(usageId: string, agreementId: string) {
    const checked = this.agreementsForm.get(usageId)?.value;
    this.agreementsForm.patchValue({ [`${usageId}`]: !checked });
    const index = this.agreementData$.value.findIndex(agreement => agreement.agreementId == agreementId);
    this.agreementData$.value[index] = { agreementId, signed: !checked };
    this.agreementData$.next(this.agreementData$.value);
  }

  setAgreementRequired(usageId: string, agreementId: string, checked: boolean) {
    const index = this.agreementData$.value.findIndex(agreement => agreement.agreementId == agreementId);
    const control = this.agreementsForm.controls[usageId];

    if (checked) {
      control.setValidators(Validators.requiredTrue);
      this.agreementData$.value[index].unsigned = false;
    } else {
      control.setErrors(null);
      this.agreementData$.value[index].unsigned = true;
      control.clearValidators();
    }

    this.agreementData$.next(this.agreementData$.value);
    this.agreementsForm.patchValue({ [`${usageId}-unsigned`]: !checked });
  }

  setSignature(signature: string, usageId: string, agreementId: string) {
    const index = this.agreementData$.value.findIndex(agreement => agreement.agreementId == agreementId);

    const linkedSignature = this.signatures.findIndex(signature => signature.agreementId === agreementId);
    if (linkedSignature !== -1) this.signatures[linkedSignature] = { agreementId, signature };
    else this.signatures.push({ agreementId, signature });

    if (signature !== '') {
      this.agreementsForm.patchValue({ [`${usageId}`]: true });
      this.agreementData$.value[index] = { agreementId, signed: true };
    } else {
      this.agreementsForm.patchValue({ [`${usageId}`]: false });
      this.agreementData$.value[index] = { agreementId, signed: false };
    }

    this.agreementData$.next(this.agreementData$.value);
    this.signaturesUpdated.emit(this.signatures);
  }

  ngOnDestroy() {
    this._selectedBookingOption$.complete();
    this._event$.complete();
  }
}
