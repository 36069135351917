<ng-container *ngIf="collections$ | async as collections">
  <greco-shop-filters
    (communitiesChanged)="communitiesChanged($event)"
    [collections]="collections || signedOutCollections"
    [userId]="user?.id"
  ></greco-shop-filters>

  <ng-container *grecoLet="userConditionContext$ | async as conditionsContext">
    <ng-container *grecoLet="linkedAccounts$ | async as linkedAccounts">
      <ng-container *grecoLet="isSignedIn$ | async as isSignedIn">
        <greco-collection-slides
          *ngFor="let collection of collections"
          [userConditionContext]="conditionsContext || !isSignedIn ? signedOutMockContext: undefined"
          [communities]="communities"
          [collection]="collection"
          [userId]="user?.id"
          [linkedAccounts]="linkedAccounts || []"
        ></greco-collection-slides>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
