import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Community } from '@greco/identity-communities';
import { ContactService } from '@greco/ngx-identity-contacts';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { CommunityService } from '../../services';

@Component({
  selector: 'greco-communities-table',
  templateUrl: './communities-table.component.html',
  styleUrls: ['./communities-table.component.scss'],
})
export class CommunitiesTableComponent implements OnChanges {
  constructor(private csvc: CommunityService, private contactSvc: ContactService, private router: Router) {}

  @Input() parent: string | undefined;
  communities: (Community & { routerLink: string; contacts: number })[] = [];

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.parent?.previousValue !== changes.parent?.currentValue) {
      this.loadCommunities();
    }
  }

  private async loadCommunities() {
    this.communities = [];

    const communities: Community[] = [];
    let meta: undefined | IPaginationMeta;

    do {
      const page = (meta?.currentPage || 0) + 1;
      const data = await this.csvc.paginateCommunities({ page, limit: 100 }, this.parent);

      meta = data.meta;
      communities.push(...data.items);
    } while (meta && communities.length < meta.totalItems);

    if (communities.length) {
      this.communities = (
        await Promise.all(
          communities.map(async com => {
            let contacts = 0;

            try {
              ({ count: contacts } = await this.contactSvc.getCount(com.id));
            } catch (err) {
              console.error(err);
              contacts = 0;
            }

            return { ...com, contacts, routerLink: com.id };
          })
        )
      ).sort((a, b) => a.name.localeCompare(b.name));
    }
  }
}
