import { Injectable } from '@angular/core';
import { UserService } from '@greco/ngx-identity-auth';
import { TilesService } from '@greco/ngx-tiles';
import { combineLatest } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { DashboardBookingsService } from './dashboard-bookings.service';

@Injectable()
export class DashboardTilesService {
  tileGroups$ = combineLatest([this.userSvc.authUser$, this.dashboardBookingSvc.community$]).pipe(
    switchMap(([_, community]) => this.tileSvc.getGroupsByCommunity(community.id)),
    // tap(groups => console.log({ groups })),
    map(groups => groups.filter(group => group.tiles.length > 0)),
    shareReplay(1),
    startWith(null)
  );

  constructor(
    private userSvc: UserService,
    private dashboardBookingSvc: DashboardBookingsService,
    private tileSvc: TilesService
  ) {}
}
