import { Component, Injectable, Input, Type, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { Calendar, CalendarSecurityResource, CalendarSecurityResourceAction } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { BuildTextFilter, Filter, ToggleFilter } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { CalendarsTableComponent } from '../../components';
import { CreateCalendarDialog } from '../../dialogs';

@Injectable({ providedIn: 'any' })
export class CalendarTitleFilter extends BuildTextFilter('CalendarTitleFilter', {
  label: 'Title',
  shortLabel: 'Title',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW],
  properties: ['title'],
}) {}
@Injectable({ providedIn: 'any' })
export class CalendarShowArchived extends ToggleFilter {
  constructor() {
    super('CalendarShowArchived', {
      label: 'Show Archived',
      shortLabel: 'Show Archived',
      description: '',
      properties: ['deleted'],
    });
  }

  getValueLabel(value: any): string {
    return value ? 'Show Archived' : 'Hide Archived';
  }
  getValueOptions(search?: string | undefined): any[] | Promise<any[]> {
    if (search?.toLowerCase() === 'archived') {
      return [true];
    } else if (search?.toLowerCase() === 'active') {
      return [false];
    } else {
      return [];
    }
  }
  serializeValue(value: any): string {
    return value.toString();
  }
  deserializeValue(serializedValue: string) {
    return new Boolean(serializedValue);
  }
}

@Component({
  selector: 'greco-calendars-page',
  templateUrl: './calendars.page.html',
  styleUrls: ['./calendars.page.scss'],
})
export class CalendarsPage {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private comSecSvc: CommunitySecurityService
  ) {}

  @ViewChild(CalendarsTableComponent) table!: CalendarsTableComponent;

  filterOptions$: Type<Filter>[] = [CalendarTitleFilter, CalendarShowArchived];
  readonly query$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());

  private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() get community() {
    return this._community$.value;
  }
  set community(community: Community | null) {
    this._community$.next(community);
    // this._community$ = community;
  }

  canCreateCalendar$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(
            community.id,
            CalendarSecurityResource.key,
            CalendarSecurityResourceAction.CREATE
          )
        : false
    ),
    shareReplay(1)
  );

  canReadCalendar$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(
            community.id,
            CalendarSecurityResource.key,
            CalendarSecurityResourceAction.READ
          )
        : false
    ),
    shareReplay(1)
  );

  async createCalendar() {
    if (this.community) {
      await toPromise(
        this.dialog.open(CreateCalendarDialog, { data: { communityId: this.community.id } }).afterClosed()
      );
      this.refresh();
    }
  }
  refresh() {
    this.table.refresh();
  }

  async openCalendar(calendar: Calendar) {
    await this.router.navigate([calendar.id], {
      relativeTo: this.route,
    });
  }
}
