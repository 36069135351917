import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { User } from '@greco/identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CalendarService } from '../../../services';

@Component({
  selector: 'greco-user-courses-page',
  templateUrl: './user-courses.page.html',
  styleUrls: ['./user-courses.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UserCoursesPage implements OnInit {
  constructor(
    private calendarSvc: CalendarService,
    private breakpoints: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.communitiesControl.valueChanges.subscribe(communities => {
      this.selectedCommunities = communities;
      this.urlCommunities$.next(communities as Community[]);
    });
  }

  @PropertyListener('user') private _user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User | null;

  @PropertyListener('selectedCalendar') _selectedCalendar$ = new BehaviorSubject<Calendar | null>(null);
  selectedCalendar: Calendar | null = null;

  isMobile$ = this.breakpoints.observe('(max-width: 600px)').pipe(
    map(({ matches }) => matches),
    shareReplay(1)
  );

  communitiesControl = new FormControl([]);
  loading = false;
  searchQuery = '';

  _selectedCommunities$ = this.communitiesControl.valueChanges.pipe(map(item => item));
  selectedCommunities?: Community[] = [];

  readonly urlCommunities$ = new BehaviorSubject<Community[]>([]);

  readonly userCalendars$ = this.urlCommunities$.pipe(
    switchMap(community => this.calendarSvc.getMany(community.map(x => x.id))),
    map(calendars => (calendars || []).filter(cal => !cal.private)),
    switchMap(calendars => {
      return this._selectedCalendar$.pipe(
        map(calendar => {
          if (calendar && !calendars.some(cal => cal.id === calendar.id)) {
            return [calendar, ...calendars];
          } else return calendars;
        })
      );
    }),
    tap(calendars => {
      if (calendars.length) {
        calendars = this.calendarSvc.sortCalendars(calendars);
        if (!this.selectedCalendar) {
          this.selectedCalendar = this.calendarSvc.loadSelectedCalendarFromCookie(calendars);
        }
      }
    })
  );

  private async loadSelectedCalendar() {
    const calendarId = this.route.snapshot.queryParams['calendar'];
    if (calendarId) {
      const calendar = await this.calendarSvc.getOne(calendarId).catch(() => null);
      if (calendar) {
        this.selectedCalendar = calendar;
      }
    }
  }

  selectCalendar(calendar: Calendar) {
    if (!this.loading && this.selectedCalendar?.id !== calendar?.id) {
      this.selectedCalendar = calendar;
      this.calendarSvc.saveSelectedCalendarCookie(calendar.id);
    }
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        calendar: this.selectedCalendar?.id ?? null,
      },
    });
  }

  async changeCommunity() {
    console.log('Selected communities', this.selectedCommunities);
  }

  async ngOnInit() {
    await this.loadSelectedCalendar();
  }
}
