import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { Account } from '@greco/finance-accounts';
import { User } from '@greco/identity-users';
import moment from 'moment';
import { CurrencyMaskConfig } from 'ngx-currency';
import { map, startWith } from 'rxjs/operators';
import { UserBalanceService } from '../../services';
import { FundUserBalanceConfimationDialog } from '../fund-user-balance-confimation-dialog/fund-user-balance-confimation.dialog';

const CurrencyValidator: ValidatorFn = (control: AbstractControl) => {
  return typeof control.value === 'number' && control.value !== 0 ? null : { currency: 'Invalid' };
};

@Component({
  selector: 'greco-adjust-balance-dialog',
  templateUrl: './adjust-balance.dialog.html',
  styleUrls: ['./adjust-balance.dialog.scss'],
})
export class AdjustBalanceDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { account: Account; user: User },
    private dialogRef: MatDialogRef<AdjustBalanceDialog>,
    private balanceSvc: UserBalanceService,
    private formBuilder: FormBuilder,
    private snacks: MatSnackBar,
    private matDialog: MatDialog
  ) {}

  form = this.formBuilder.group({
    amount: [null, [Validators.required, CurrencyValidator]],
    note: [null, [Validators.required]],
  });

  currentBalance?: number;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  newBalance$ = this.form.get('amount')!.valueChanges.pipe(
    startWith(null),
    map(amount => (typeof amount === 'number' && amount !== 0 ? (this.currentBalance || 0) + amount * 100 : null))
  );

  submitting = false;

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 0,
  };

  async ngOnInit() {
    const balances = await this.balanceSvc.getUserBalance(this.data.user.id);
    this.currentBalance = balances.find(balance => balance.accountId === this.data.account.id)?.amount || 0;
  }

  close() {
    this.dialogRef.close();
  }

  async submit() {
    const formValue = this.form.get('amount')?.value;
    if (typeof formValue !== 'number' || formValue === 0) return;

    const dialog = this.matDialog.open(FundUserBalanceConfimationDialog, {
      data: { communityId: this.data.account.name, amount: Math.round(formValue * 100) },
    });
    const result = await toPromise(dialog.afterClosed());
    if (!result) return;

    this.submitting = true;

    try {
      const now = moment();

      const transaction = await this.balanceSvc.createBalanceTransaction({
        title: `Manual Balance Adjustment - ${now.format('lll')}`,
        description: this.form.get('note')?.value || undefined,
        correlationLabel: now.format('lll'),
        amount: Math.round(formValue * 100),
        accountId: this.data.account.id,
        correlationId: now.format(),
        userId: this.data.user.id,
      });

      this.snacks.open('User balance adjusted', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.dialogRef.close(transaction);
    } catch (err) {
      console.error(err);
    }

    this.submitting = false;
  }
}
