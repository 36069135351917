import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetModule } from '@greco/ngx-let-util';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { TableModule } from '@greco/ui-table';
import { TypeformAuhtorizationSectionComponent, TypeformFormPickerComponent } from './components';
import { GrecoCommunityTypeformSelectComponent } from './components/community-specific-picker/community-typeform.component';
import { ConnectFormDialog, FormPickerDialog, ResponsePreviewDialog } from './dialogs';
import { ConnectFormDirective } from './directives';
import { TypeformEmbedPage } from './pages';
import { TypeformResponseTooltip } from './pipes';
import { TypeformAuthorizationResolver, TypeformResolver } from './resolvers';
import { TypeformService } from './services';
import { TypeformConfig, TYPEFORM_CONFIG } from './typeform.tokens';

@NgModule({
  imports: [
    // Angular
    CommonModule,

    // Angular Material
    MatIconModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatAutocompleteModule,

    // Greco
    LetModule,
    TableModule,
    CollapsibleSectionModule,
  ],
  declarations: [
    ResponsePreviewDialog,
    TypeformResponseTooltip,
    TypeformEmbedPage,
    TypeformAuhtorizationSectionComponent,
    GrecoCommunityTypeformSelectComponent,
    ConnectFormDirective,
    ConnectFormDialog,
    TypeformFormPickerComponent,
    FormPickerDialog,
  ],
  exports: [
    ResponsePreviewDialog,
    TypeformResponseTooltip,
    TypeformAuhtorizationSectionComponent,
    GrecoCommunityTypeformSelectComponent,
    ConnectFormDirective,
    TypeformFormPickerComponent,
  ],
})
export class TypeformModule {
  static forRoot(config: TypeformConfig) {
    return {
      ngModule: TypeformModule,
      providers: [
        { provide: TYPEFORM_CONFIG, useValue: config },
        TypeformAuthorizationResolver,
        TypeformResolver,
        TypeformService,
      ],
    };
  }
}
