import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AddonsService, ProductsService } from '@greco/ngx-sales-products';
import { AddonType, Product, ProductStatus, ProductVariant } from '@greco/sales-products';

@Component({
  selector: 'greco-product-variant-selection',
  templateUrl: './product-variant-selection.component.html',
  styleUrls: ['./product-variant-selection.component.scss'],
})
export class ProductVariantSelectionComponent implements OnInit {
  constructor(private productSvc: ProductsService, private fb: FormBuilder, private addonSvc: AddonsService) {}

  @Input() product!: Product;
  @Input() selectedVariant!: ProductVariant;

  @Output() selectedVariantChange = new EventEmitter<ProductVariant>();
  @Output() validSelection = new EventEmitter<boolean>();

  optionsForm: FormGroup = this.fb.group({ options: this.fb.array([]) });

  excludedVariants: ProductVariant[] = [];
  optionNames: string[] = [];
  availableOptions: string[][] = [];
  enabledOptions: boolean[][] = [];
  selectedOptions: string[] = [];
  validVariants: ProductVariant[] = [];
  variantList: string[][] = [];

  async ngOnInit() {
    this.product.variants.sort((a, b) => b.priority - a.priority || a.title?.localeCompare(b.title || '') || 0);

    const shopAddon = (await this.addonSvc.getOneByType(this.product.id, AddonType.Shop)) as any;
    if (shopAddon) {
      this.excludedVariants = shopAddon.restrictedVariants || [];
    }

    // Get option titles and individual options
    this.getOptionNames();
    this.getAvailableOptions();

    // Get a string list of all option variants
    this.product.variants
      .filter(
        v =>
          v.status === ProductStatus.Active &&
          v.isHidden === false &&
          !this.excludedVariants.find(excluded => excluded.id === v.id)
      )
      .forEach(variant => {
        const variantOptions: string[] = [];
        variant.variantOpts.forEach(option => variantOptions.push(option.value));
        this.variantList.push(variantOptions);
      });

    // Create form array to get selected options
    const formArray = this.getSelectedOptions();
    this.optionNames.forEach((name, index) =>
      formArray.push(this.fb.control(this.selectedVariant.variantOpts[index].value))
    );

    // Create a true/false map for disabled options based on current selection
    this.disableOptions();
    this.setSelectedVairant();

    // When value changes update disabled options
    this.optionsForm.valueChanges.subscribe(() => {
      this.disableOptions();
      this.setSelectedVairant();
    });
  }

  getOptionNames() {
    this.optionNames = [];
    this.product.options.forEach(option => {
      this.optionNames.push(option.label);
    });
  }

  getAvailableOptions() {
    this.product.options.forEach(option => {
      const optionValues: string[] = [];
      this.product.variants
        .filter(
          v =>
            v.status === ProductStatus.Active &&
            v.isHidden === false &&
            !this.excludedVariants.find(excluded => excluded.id === v.id)
        )
        .forEach(variant => {
          variant.variantOpts.forEach(variantOption => {
            if (!optionValues.includes(variantOption.value) && option.id === variantOption.optionId)
              optionValues.push(variantOption.value);
          });
        });
      this.availableOptions.push(optionValues);
    });
  }

  getSelectedOptions(): FormArray {
    return this.optionsForm.get('options') as FormArray;
  }

  setSelectedVairant() {
    this.validVariants = [];

    this.product.variants
      .filter(
        v =>
          v.status === ProductStatus.Active &&
          v.isHidden === false &&
          !this.excludedVariants.find(excluded => excluded.id === v.id)
      )
      .forEach(variant => {
        let i = 0;

        while (i < variant.variantOpts.length) {
          if (variant.variantOpts[i].value == this.selectedOptions[i]) {
            i++;
            if (i == variant.variantOpts.length) {
              this.validVariants.push(variant);
              break;
            }
          } else break;
        }
      });

    if (this.validVariants.length) {
      this.selectedVariantChange.emit(this.validVariants[0]);
      this.validSelection.emit(true);
    } else this.validSelection.emit(false);
  }

  checkValid(value: string, optionIndex: number) {
    const currentValue: string[] = [...this.selectedOptions];
    currentValue[optionIndex] = value;
    return this.variantList.some(v => v.join() === currentValue.join());
  }

  disableOptions() {
    this.enabledOptions = [];
    this.selectedOptions = this.getSelectedOptions().value;
    for (let i = 0; i < this.availableOptions.length; i++) {
      const enabled: boolean[] = [];
      this.availableOptions[i].forEach(option => enabled.push(this.checkValid(option, i)));
      this.enabledOptions.push(enabled);
    }
  }

  emitVariant(variant: ProductVariant) {
    this.selectedVariantChange.emit(variant);
  }
}
