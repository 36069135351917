import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppConfig, APP_CONFIG } from './services/app-config.service';

@NgModule({
  imports: [CommonModule],
})
export class AppConfigModule {
  static forRoot(config: AppConfig): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,
      providers: [
        {
          provide: APP_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
